import {
  RecordId,
  VentureQuarterRecord
} from '../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { TenantId } from '../../api/tenants/Tenant';
import { VentureId } from '../../api/ventures/types/Venture';
import { format } from 'date-fns';

export const ventureQuarterRecords = (
  ventureId: VentureId,
  tenantId: TenantId
): VentureQuarterRecord[] =>
  Array.from(Array(10).keys()).map(
    (i): VentureQuarterRecord => ({
      creationDate: format(new Date(2000 + i, 6, 12), 'yyyy/MM/dd'),
      crowdComments: 'no comments',
      crowdFunding: 3,
      equityComments: 'test',
      equityRaised: 4,
      fte: 1,
      grantsComments: 'test',
      grantsRaised: 4,
      id: `${tenantId}-${ventureId}-${i}` as RecordId,
      loans: 4,
      loansComments: 'test',
      other: 5,
      otherComments: 'test',
      quarterNumber: 1,
      rating: 3,
      revenue: 34,
      status: 'SUBMITTED',
      submitter: 'test@test.com',
      tenantId,
      valid: true,
      validStatus: true,
      ventureId,
      ventureName: `Ventrue ${i}`,
      year: 2022
    })
  );
