import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import {
  Redirect,
  Switch,
  Route,
  useParams,
  useLocation,
  Link,
} from 'react-router-dom';
import { Venture } from '../api/ventures/types/Venture';
import { PageLoader } from '../components/common';
import { UserContext } from '../contexts/user-context';
import { VentureContext, VentureProvider } from '../contexts/venture-context';
import { getRoutePath, Pages } from '../router/constants';
import { COLORS } from '../theme/variables';
import VentureAdditionalInfoPage from './venture/venture-additional-info-page';
import VentureDetailsPage from './venture/venture-details-page';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
  },
  container_loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paper: {
    padding: 0,
    width: '100%',
    maxWidth: 882,
  },
  content: {
    padding: 40,
  },
  tabs: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },
}));

function VentureExternalPage() {
  const classes = useStyles();
  const location = useLocation();
  const { ventureId } = useParams<{ ventureId: Venture['id'] }>();
  const { user, loadUser } = useContext(UserContext);
  const {
    venture,
    isVentureLoading,
    ventureSpecializations,
    ventureTags,
    loadVenture,
    loadVentureSpecializations,
    loadVentureTags,
  } = useContext(VentureContext);

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [user, loadUser]);

  // load venture's info
  useEffect(() => {
    if (!venture) {
      loadVenture(ventureId);
    }
  }, [ventureId, venture, loadVenture]);

  // load venture's specializations
  useEffect(() => {
    if (!ventureSpecializations) {
      loadVentureSpecializations(ventureId);
    }
  }, [ventureSpecializations, ventureId, loadVentureSpecializations]);

  // load venture's tags
  useEffect(() => {
    if (!ventureTags) {
      loadVentureTags(ventureId);
    }
  }, [ventureTags, ventureId, loadVentureTags]);

  if (isVentureLoading) {
    return (
      <div className={classes.container_loading}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.tabs}>
          <Tabs
            value={location.pathname}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='on'>
            <Tab
              label='Details'
              component={Link}
              to={getRoutePath(Pages.VENTURE_EXTERNAL_DETAILS, { ventureId })}
              value={getRoutePath(Pages.VENTURE_EXTERNAL_DETAILS, {
                ventureId,
              })}
            />
            <Tab
              label='Additional Info'
              component={Link}
              to={getRoutePath(Pages.VENTURE_EXTERNAL_ADDITIONAL, {
                ventureId,
              })}
              value={getRoutePath(Pages.VENTURE_EXTERNAL_ADDITIONAL, {
                ventureId,
              })}
            />
          </Tabs>
        </div>
        <div className={classes.content}>
          <Switch>
            <Route
              exact
              path={Pages.VENTURE_EXTERNAL_DETAILS}
              component={VentureDetailsPage}
            />
            <Route
              exact
              path={Pages.VENTURE_EXTERNAL_ADDITIONAL}
              component={VentureAdditionalInfoPage}
            />
            <Redirect to={Pages.VENTURE_EXTERNAL_DETAILS} />
          </Switch>
        </div>
      </Paper>
    </div>
  );
}

function withVentureProvider(Component: React.FunctionComponent) {
  return () => (
    <VentureProvider>
      <Component />
    </VentureProvider>
  );
}

export default withVentureProvider(VentureExternalPage);
