import { ThemeOptions } from '@material-ui/core';
import { COLORS, MONTSERRAT_FONT_FAMILY, INTER_FONT_FAMILY } from './variables';

const overrides: ThemeOptions['overrides'] = {
  MuiTypography: {
    h1: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 44,
      fontWeight: 700,
      lineHeight: '68px',

      '@media (min-width: 700px)': {
        fontSize: 48,
        lineHeight: '72px'
      }
    },
    h2: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 28,
      fontWeight: 700,
      lineHeight: '44px',

      '@media (min-width: 700px)': {
        fontSize: 32,
        lineHeight: '48px'
      }
    },
    h3: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '32px',

      '@media (min-width: 700px)': {
        fontSize: 24,
        lineHeight: '36px'
      }
    },
    h4: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '30px',

      '@media (min-width: 700px)': {
        fontSize: 20,
        lineHeight: '32px'
      }
    },
    h5: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',

      '@media (min-width: 700px)': {
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    subtitle1: {
      fontFamily: MONTSERRAT_FONT_FAMILY,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '22px',

      '@media (min-width: 700px)': {
        fontSize: 14,
        lineHeight: '24px'
      }
    },
    button: {
      fontSize: 9,
      fontWeight: 500,
      lineHeight: '16px',

      '@media (min-width: 700px)': {
        fontSize: 11,
        lineHeight: '14px'
      }
    },
    body2: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '22px',

      '@media (min-width: 700px)': {
        fontSize: 14,
        lineHeight: '24px'
      }
    }
  },
  MuiAvatar: {
    root: {
      width: 32,
      height: 32,

      '& img': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }
  },
  MuiPaper: {
    root: {
      color: COLORS.COLOR_TEXT_BASE
    },
    rounded: {
      borderRadius: '8px'
    },
    // central block
    elevation1: {
      padding: '24px 32px 28px',
      boxShadow:
        '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)'
    },
    // dropdown
    elevation2: {
      padding: 20,
      borderRadius: 4,
      boxShadow:
        '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)'
    },
    // modal block
    elevation3: {
      boxShadow:
        '0px 4px 8px rgba(9, 29, 61, 0.08), 0px 20px 40px rgba(9, 29, 61, 0.16)'
    },
    // dashboard block
    elevation4: {
      boxShadow:
        '0px 0px 0px 1px rgba(34, 91, 187, 0.08), 0px 0px 4px rgba(51, 126, 255, 0.08), 0px 4px 8px rgba(51, 126, 255, 0.04)'
    },
    elevation8: {
      boxShadow:
        '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)'
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: '14px',
      color: COLORS.COLOR_TEXT_LIGHTENED_20,

      '&$disabled': {
        color: COLORS.COLOR_TEXT_LIGHTENED_30
      }
    }
  },
  MuiInputLabel: {
    outlined: {
      '&$shrink': {
        transform: 'translate(14px, -6px) scale(0.85)'
      }
    }
  },
  MuiInputBase: {
    root: {
      fontSize: '14px',

      '&$disabled': {
        background: COLORS.COLOR_GRAY_LIGHTENED_50
      }
    },
    input: {
      height: 19
    }
  },
  MuiOutlinedInput: {
    root: {
      '&:hover $notchedOutline': {
        borderColor: COLORS.COLOR_GRAY_DARKENED
      }
    },
    notchedOutline: {
      borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      borderWidth: '1px !important',

      '& legend': {
        fontSize: 12
      }
    }
  },
  MuiInputAdornment: {
    root: {
      color: COLORS.COLOR_GRAY_BASE
    },
    positionStart: {
      marginRight: 15
    }
  },
  MuiFormHelperText: {
    root: {
      '&$disabled': {
        color: COLORS.COLOR_TEXT_LIGHTENED_30
      }
    }
  },
  MuiMenuItem: {
    root: {
      padding: '12px 20px 12px 18px !important',
      whiteSpace: 'normal'
    }
  },
  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45
      }
    }
  },
  MuiLink: {
    root: {
      cursor: 'pointer',

      '&.MuiTypography-caption': {
        fontSize: '13px'
      },
      '&.MuiTypography-colorPrimary': {
        color: COLORS.COLOR_BLUE_DARKENED_10
      }
    },
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
        borderBottom: '1px solid #3981FF',
        marginBottom: '-1px',
        '&.MuiTypography-colorPrimary': {
          color: COLORS.COLOR_BLUE_LIGHTENED_5
        }
      }
    }
  },
  MuiIconButton: {
    root: {
      padding: 8,
      color: COLORS.COLOR_GRAY_BASE,

      '&:hover': {
        color: COLORS.COLOR_GRAY_DARKENED,
        background: COLORS.COLOR_GRAY_LIGHTENED_40
      }
    }
  },
  MuiCheckbox: {
    root: {
      color: COLORS.COLOR_GRAY_BASE
    },
    indeterminate: {
      color: COLORS.COLOR_BLUE_BASE,

      '&:hover': {
        color: COLORS.COLOR_BLUE_BASE
      }
    }
  },
  MuiButton: {
    contained: {
      boxShadow: 'none'
    },
    outlined: {
      padding: '7px 15px'
    },
    root: {
      padding: '8px 16px',
      textTransform: 'unset'
    }
  },
  MuiBadge: {
    badge: {
      boxShadow: '0 0 0 1px #fff',
      fontWeight: 600
    },
    anchorOriginTopRightRectangle: {
      transform: 'scale(1) translate(30%, -30%)'
    }
  },
  MuiTooltip: {
    popper: {
      '&[x-placement="bottom"]': {
        marginTop: -15
      }
    },
    tooltip: {
      backgroundColor: COLORS.COLOR_TEXT_LIGHTENED_10,
      borderRadius: 4,
      color: '#fff',
      fontSize: 11,
      fontWeight: 600,
      lineHeight: '16px',
      textAlign: 'center',
      whiteSpace: 'pre-line'
    }
  },
  MuiTab: {
    root: {
      padding: '12px 0',
      textTransform: 'unset',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      color: COLORS.COLOR_TEXT_LIGHTENED_10,
      minWidth: 0,
      maxWidth: '100%',

      '@media (min-width: 600px)': {
        minWidth: 0
      },

      '& + &': {
        marginLeft: 32
      }
    },
    textColorPrimary: {
      color: COLORS.COLOR_TEXT_LIGHTENED_10
    }
  },
  // @ts-ignore
  MuiAutocomplete: {
    option: {
      fontSize: 13,
      lineHeight: '20px',
      fontWeight: 400,
      paddingTop: 12,
      paddingRight: 20,
      paddingBottom: 12,
      paddingLeft: 16,
      color: COLORS.COLOR_TEXT_BASE,

      '&:hover': {
        background: COLORS.COLOR_BLUE_LIGHTENED_45
      }
    }
  },
  MuiPickersBasePicker: {
    container: {
      fontFamily: INTER_FONT_FAMILY
    }
  },
  MuiPickersCalendarHeader: {
    dayLabel: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: COLORS.COLOR_TEXT_LIGHTENED_20,
      textTransform: 'uppercase'
    },
    transitionContainer: {
      '& .MuiTypography-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
        color: '#384864'
      }
    }
  },
  MuiPickersDay: {
    daySelected: {
      '&:hover': {
        color: 'white',
        background: COLORS.COLOR_BLUE_BASE
      }
    },
    day: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: COLORS.COLOR_TEXT_BASE,

      '&:hover': {
        color: COLORS.COLOR_TEXT_BASE,
        background: COLORS.COLOR_BLUE_LIGHTENED_35
      }
    }
  },
  MuiDialogActions: {
    root: {
      padding: 32,
      flexDirection: 'column',
      rowGap: 10,

      '@media (min-width: 510px)': {
        minWidth: 0,
        flexDirection: 'row',
        columnGap: 16
      }
    },
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: 0
      },
      '& > *': {
        width: '100%',

        '@media (min-width: 510px)': {
          width: 'auto'
        }
      }
    }
  },
  MuiChip: {
    root: {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
      borderRadius: 16
    },
    label: {
      color: COLORS.COLOR_TEXT_BASE,
      lineHeight: '20px'
    },
    deletable: {
      '&:focus': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30
      }
    },
    deleteIcon: {
      color: COLORS.COLOR_GRAY_BASE,

      '&:hover': {
        color: COLORS.COLOR_GRAY_DARKENED
      }
    }
  }
};
export default overrides;
