import { formatInTimeZone } from 'date-fns-tz';
import { TenantTimeZone } from '../tenants/types/Settings';

declare const tag: unique symbol;

export type TenantRFCDate = string & { [tag]: 'TenantRFCDate' };

export function fromDate(timeZone: TenantTimeZone, date: Date): TenantRFCDate {
  return formatInTimeZone(
    date,
    timeZone,
    "yyyy-MM-dd'T'HH:mm:ss",
  ) as TenantRFCDate;
}
