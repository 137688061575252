import { ReactElement } from 'react';
import { TestId } from '../Testing/TestId';
import { ActionsDialog } from './Confirmation/ActionsDialog';
import Button from './button';
import Text from './text';

interface ConfirmDialogProps {
  isOpen: boolean;
  title: ReactElement | string;
  body: string | React.ReactNode;
  disabled?: boolean;
  onCancel: () => any;
  onSuccess: () => any;
  children?: React.ReactNode;
  cancelProps?: {
    label: string | ReactElement;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    'data-testid'?: string;
  };
  successProps?: {
    label: string | ReactElement;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    'data-testid'?: string;
    disabled?: boolean;
  };
}

export function ConfirmDialog({
  isOpen,
  title,
  body,
  onCancel,
  onSuccess,
  disabled,
  children,
  cancelProps = {
    label: 'Cancel',
    variant: 'outlined',
  },
  successProps = {
    label: 'Yes',
    variant: 'contained',
    'data-testid': 'success-button',
  },
}: ConfirmDialogProps) {
  const Actions = () => {
    return (
      <>
        {children}
        <TestId testId={cancelProps['data-testid'] ?? 'cancel-button'}>
          <Button
            variant={cancelProps.variant}
            onClick={onCancel}
            disabled={disabled}>
            {cancelProps.label}
          </Button>
        </TestId>
        <TestId testId={successProps['data-testid'] ?? 'success-button'}>
          <Button
            variant={successProps.variant}
            onClick={onSuccess}
            disabled={successProps?.disabled ?? disabled}>
            {successProps.label}
          </Button>
        </TestId>
      </>
    );
  };

  return (
    <ActionsDialog
      open={isOpen}
      onClose={onCancel}
      title={title}
      actions={<Actions />}>
      <Text variant={'normal'}>{body}</Text>
    </ActionsDialog>
  );
}

export default ConfirmDialog;
