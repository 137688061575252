import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { Button, PageLoader, SnackMessage } from '../../components/common';
import GatheringModal, {
  RadioValue,
} from '../../components/gathering/gathering-modal';
import PageTabs from '../../components/gathering/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import {
  GatheringContext,
  GatheringProvider,
} from '../../contexts/gathering-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import GatheringAttendancePage from './gathering-attendance-page';
import GatheringDetailsPage from './gathering-details-page';
import GatheringFoundersPage from './gathering-founders-page';
import GatheringGuestsPage from './gathering-guests-page';
import GatheringMentorsPage from './gathering-mentors-page';
import GatheringNotesPage from './gathering-notes-page';

type ActionType = 'send' | 'cancel';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  autoHeight: {
    flexGrow: 'unset',
  },
  removeHorizontalPadding: {
    padding: '2px 0 0 !important',
  },
  isRemoveTopPadding: {
    padding: '0 !important',
  },
});

function GatheringPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const [prevPath] = useState(location.state?.prevPath);
  const { hasAccessToAction } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const actionType = useRef<ActionType | string>('');
  const { isGatheringLoading, gathering, loadGathering, loadGroupList, group } =
    useContext(GatheringContext);

  const isRemoveHorizontalPadding = useMemo(() => {
    const arrayPages = ['founders', 'mentors', 'attendance', 'guests'];
    return arrayPages.includes(location?.pathname?.split('/')[3]);
  }, [location]);

  const isRemoveTopPadding = useMemo(() => {
    const arrayPages = ['attendance'];
    return arrayPages.includes(location?.pathname?.split('/')[3]);
  }, [location]);

  const hasInvites = useMemo(() => {
    const listFounders =
      gathering?.listOfFounders && gathering.listOfFounders.length > 0;
    const listMentors =
      gathering?.listOfMentors && gathering.listOfMentors.length > 0;
    const listGuests = gathering?.externalInvitees;

    return listFounders || listMentors || listGuests;
  }, [gathering]);

  const handleCancel = async (type: RadioValue) => {
    const typeRecurring = type === 'all' ? 'group' : 'gathering';
    try {
      if (gathering) {
        setIsCancelled(true);
        if (typeRecurring === 'group') {
          if (hasInvites) {
            await gatheringsAPI.cancelGroup(gathering.groupId);
          }
          await gatheringsAPI.deleteGroup(gathering.groupId);
        } else {
          if (hasInvites) {
            await gatheringsAPI.cancelGathering(gathering.id);
          }
          await gatheringsAPI.deleteGathering(gathering.id);
        }

        enqueueSnackbar(`The ${typeRecurring} was successfully cancelled.`, {
          variant: 'success',
        });
        setOpenModal(false);

        history.push(Pages.GATHERINGS);
        setIsCancelled(false);
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const defaultMessageError = `An error occurred at the cancel of the ${typeRecurring}. Please, try again.`;

      enqueueSnackbar(defaultMessageError, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
      setIsCancelled(false);
    }
  };

  const handleSendInvites = async (type: RadioValue) => {
    const typeRecurring = type === 'all' ? 'group' : 'gathering';

    try {
      if (gathering) {
        setIsInviting(true);
        if (typeRecurring === 'group') {
          await gatheringsAPI.sendInvitesGroup(gathering.groupId);
        } else {
          await gatheringsAPI.sendInvitesGathering(gathering.id);
        }
        enqueueSnackbar('The invitations were sent successfully', {
          variant: 'success',
        });
        setOpenModal(false);
        setIsInviting(false);
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      setIsInviting(false);
      enqueueSnackbar(
        'An error occurred while sending invitations. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
    }
  };

  const handleActionGathering = (type: RadioValue) => {
    if (actionType.current === 'cancel') {
      handleCancel(type);
    } else {
      handleSendInvites(type);
    }
    actionType.current = '';
  };

  const handleClick = (type: ActionType) => {
    actionType.current = type;
    if (!gathering?.periodString) {
      return handleActionGathering('all');
    }
    setOpenModal(true);
  };

  useEffect(() => {
    if (!gathering || gathering.id !== gatheringId) {
      loadGathering(gatheringId);
    }
  }, [gathering, gatheringId, loadGathering]);

  useEffect(() => {
    if (gathering?.groupId && group.length === 0) {
      loadGroupList(gathering?.groupId);
    }
  }, [gathering, loadGroupList, group]);

  return (
    <BaseLayout user={user} fullHeight>
      {isGatheringLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title='Gathering'
            backLink={
              <div>
                <Tooltip title='Back to Gatherings'>
                  <IconButton
                    component={Link}
                    to={prevPath || Pages.GATHERINGS}
                    data-testid='button-back-gatherings'>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
            additional={
              !!gathering?.attendanceCode && (
                <Typography variant='h5'>
                  Attendance Code: {gathering.attendanceCode}
                </Typography>
              )
            }
            actions={[
              {
                key: 'send-invite',
                label: 'Send Invite',
                hidden: !hasAccessToAction('gathering.details.update'),
                component: (
                  <Button
                    onClick={() => handleClick('send')}
                    variant='contained'
                    data-testid='button-invite-all'
                    disabled={isInviting || isCancelled || !hasInvites}>
                    {isInviting ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Send Invite'
                    )}
                  </Button>
                ),
              },
              {
                key: 'cancel-event',
                label: 'Cancel Event',
                hidden: !hasAccessToAction('gathering.details.update'),
                component: (
                  <Button
                    onClick={() => handleClick('cancel')}
                    variant='outlined'
                    data-testid='button-cancel-event'
                    disabled={isInviting || isCancelled}>
                    {isInviting ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Cancel Event'
                    )}
                  </Button>
                ),
              },
            ]}
          />
          {hasAccessToAction('gathering.details.update') ? (
            <GatheringModal
              loading={isInviting || isCancelled}
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleSubmit={handleActionGathering}
            />
          ) : null}
          <div className={classes.wrapper}>
            <Paper
              className={cn(classes.container, {
                [classes.autoHeight]: [
                  Pages.GATHERINGS_FOUNDERS,
                  Pages.GATHERINGS_MENTORS,
                  Pages.GATHERINGS_ATTENDANCE,
                ]
                  .map((page) => getRoutePath(page, { gatheringId }))
                  .includes(currentPath),
              })}
              elevation={1}>
              <PageTabs gatheringId={gatheringId} />
              <div
                className={cn(classes.content, {
                  [classes.removeHorizontalPadding]: isRemoveHorizontalPadding,
                  [classes.isRemoveTopPadding]: isRemoveTopPadding,
                })}>
                <Switch>
                  <Route
                    exact
                    path={Pages.GATHERINGS_DETAILS}
                    component={GatheringDetailsPage}
                  />
                  <Route
                    exact
                    path={Pages.GATHERINGS_NOTES}
                    component={GatheringNotesPage}
                  />
                  <Route
                    exact
                    path={Pages.GATHERINGS_FOUNDERS}
                    component={GatheringFoundersPage}
                  />
                  <Route
                    exact
                    path={Pages.GATHERINGS_MENTORS}
                    component={GatheringMentorsPage}
                  />
                  <Route
                    exact
                    path={Pages.GATHERINGS_GUESTS}
                    component={GatheringGuestsPage}
                  />
                  <Route
                    exact
                    path={Pages.GATHERINGS_ATTENDANCE}
                    component={GatheringAttendancePage}
                  />
                  <Redirect to={Pages.GATHERINGS_DETAILS} />
                </Switch>
              </div>
            </Paper>
          </div>
        </>
      )}
    </BaseLayout>
  );
}

function withGatheringProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <GatheringProvider>
      <Component {...props} />
    </GatheringProvider>
  );
}

export default withGatheringProvider(GatheringPage);
