import { makeStyles } from '@material-ui/core';
import {
  DesktopDateTimePicker as DateTime,
  DateTimeValidationError,
  FieldSelectedSections,
} from '@mui/x-date-pickers';
import { PickerChangeHandler } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue';
import cn from 'classnames';
import { useState, useCallback, useRef } from 'react';
import { COLORS } from '../../theme/variables';
import { TestId } from '../Testing/TestId';
import { TextFieldProps } from './text-field';

interface DateInputProps {
  value: Date | null;
  onChange: PickerChangeHandler<Date | null, DateTimeValidationError>;
  onBlur: () => void;
  disablePast?: boolean;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  onClose: () => void;
  className?: string;
  error?: boolean;
  inputValue?: string;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
  onFocus?: () => void;
}

const useStyles = makeStyles({
  container: {
    '& .MuiFormLabel-root, .MuiInputBase-input': {
      fontFamily: 'Inter, Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
    '& .MuiFormLabel-root': {
      color: COLORS.COLOR_TEXT_LIGHTENED_20,
      lineHeight: '160%',
      '&.MuiInputLabel-shrink': {
        lineHeight: '100%',
      },
    },
    '& .MuiInputBase-input': {
      height: 19,
      lineHeight: '118%',
      paddingTop: '18.5px',
      paddingBottom: '18.5px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,
    },
    '& .MuiFormLabel-root': {
      color: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
});

export function DateTimePicker({
  value,
  onChange,
  disablePast,
  textFieldProps,
  disabled,
  onBlur,
  onClose,
  className,
  testId,
  error,
  onFocus,
  ...rest
}: DateInputProps) {
  const classes = useStyles();
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState(false);

  const handleSelectedSectionsChange = useCallback(
    (selectedSection: FieldSelectedSections) => {
      if (selectedSection !== null && !focus) {
        setFocus(true);
        onFocus && onFocus();
      } else if (selectedSection === null && focus) {
        setFocus(false);
        onBlur && onBlur();
      }
    },
    [focus, onBlur, onFocus],
  );

  return (
    <TestId testId={testId || ''}>
      <DateTime
        {...rest}
        disabled={disabled}
        className={cn(classes.container, className, {
          [classes.error]: error,
        })}
        aria-invalid={error}
        value={value}
        onChange={(value, context) => {
          value?.setSeconds(0, 0);
          onChange(value, context);
        }}
        onSelectedSectionsChange={handleSelectedSectionsChange}
        onClose={onClose}
        disablePast={disablePast}
        format='MM/dd/yyyy hh:mm a'
        ref={datePickerRef}
      />
    </TestId>
  );
}

export default DateTimePicker;
