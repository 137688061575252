import {
  IconButton,
  Paper,
  Tooltip,
  Button as MaterialButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useState, useContext, useCallback, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FounderId } from '../../api/founders';
import { createGoal } from '../../api/goals';
import { CreateGoal, goalStatuses } from '../../api/types/Goal';
import { PageLoader, SnackMessage } from '../../components/common';
import GoalOverviewForm, {
  GoalOverviewFormValues,
} from '../../components/forms/goal-overview';
import PageTabs from '../../components/goals/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import { GoalProvider, useGoalContext } from '../../contexts/goal-context';
import { UserContext } from '../../contexts/user-context';
import { useQuery } from '../../hooks/useQuery';
import { Pages, getRoutePath } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { decodeQuery } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
});

function NewGoalPage({ user }: ProtectedRouteProps) {
  const query = useQuery();
  const classes = useStyles();
  const location = useLocation<{ prevPath?: string }>();
  const history = useHistory();
  const { founderVentures, isFounderVentureLoading } = useGoalContext();
  const { hasAccessToAction, identityid } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isCreateGoalLoading, setCreateGoalLoading] = useState(false);
  const [prevPath] = useState(location.state?.prevPath);

  const ventureOptions = useMemo(
    () =>
      founderVentures.map((fv) => ({ value: fv.id, label: fv.ventureName })),
    [founderVentures],
  );
  const initialVentureId = useMemo(() => {
    const ventureIdEncoded = query.get('venture');
    if (ventureIdEncoded) {
      const ventureId = decodeQuery(ventureIdEncoded);
      return ventureOptions.find((v) => v.value === ventureId)?.value;
    }
    return undefined;
  }, [query, ventureOptions]);

  const handleCreateGoal = useCallback(
    async (newGoalData: CreateGoal) => {
      try {
        setCreateGoalLoading(true);
        const createdGoal = await createGoal(newGoalData);
        history.push(
          getRoutePath(Pages.GOAL_OVERVIEW, { goalId: createdGoal.id }),
        );
        enqueueSnackbar('The goal was successfully created.', {
          variant: 'success',
        });
      } catch (e: any) {
        setCreateGoalLoading(false);
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while creating the goal. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, history],
  );

  const handleSubmit = useCallback(
    async (values: GoalOverviewFormValues) => {
      if (identityid) {
        const newGoalData: CreateGoal = {
          ...values,
          tenantId: user.id,
          ownerId: identityid as FounderId,
          status: goalStatuses[0],
          progress: 0,
        };
        await handleCreateGoal(newGoalData);
      }
    },
    [identityid, user, handleCreateGoal],
  );

  return (
    <BaseLayout user={user} fullHeight>
      {isFounderVentureLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={'New Goal'}
            backLink={
              <Tooltip title='Back to Goals'>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.GOALS}
                  data-testid='button-back-goals'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            actions={[
              {
                key: 'new-goal',
                label: 'New Goal',
                hidden: !hasAccessToAction('goal.create'),
                component: (
                  <MaterialButton
                    className={CLASS_TRACKING.ENTITY_ACTION}
                    data-testid='button-new-goal'
                    component={Link}
                    to={Pages.NEW_GOAL}
                    startIcon={<AddIcon />}
                    variant='contained'
                    color='primary'>
                    Goal
                  </MaterialButton>
                ),
              },
            ]}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs />
            <div className={classes.content}>
              <GoalOverviewForm
                handleSubmit={handleSubmit}
                loading={isCreateGoalLoading}
                ventureOptions={ventureOptions}
                initialData={
                  initialVentureId && { ventureId: initialVentureId }
                }
              />
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

export default function NewGoalPageWithProvider(props: ProtectedRouteProps) {
  return (
    <GoalProvider>
      <NewGoalPage {...props} />
    </GoalProvider>
  );
}
