import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import Button from './button';
import Text from './text';
import { DialogTitle } from './modal';

export interface ButtonRendererProps {
  onClick: () => any;
}

interface ConfirmButtonProps {
  title: string;
  body: string | React.ReactNode;
  loading?: boolean;
  buttonRenderer: (props: ButtonRendererProps) => any;
  successProps: {
    btnLabel: string;
    onSuccess: (callback: () => any, event: React.MouseEvent) => any;
  };
  cancelProps?: {
    btnLabel: string;
    onCancel?: () => any;
  };
  children?: React.ReactNode;
  titleIcon?: React.ReactNode;
  classNameTitle?: string;
}

const useStyles = makeStyles({
  paper: {
    width: 480
  },
  content: {
    padding: '15px 32px 0'
  }
});

function ConfirmButton({
  title,
  body,
  loading,
  buttonRenderer,
  successProps,
  cancelProps,
  children,
  titleIcon,
  classNameTitle
}: ConfirmButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleButtonClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSuccess = (e: React.MouseEvent) => {
    successProps.onSuccess(handleModalClose, e);
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof cancelProps?.onCancel === 'function') {
      cancelProps.onCancel();
    }
    handleModalClose();
  };

  return (
    <>
      {buttonRenderer({ onClick: handleButtonClick })}
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby='alert-dialog-title'
        PaperProps={{
          className: classes.paper,
          elevation: 3
        }}>
        <DialogTitle
          id='alert-dialog-title'
          onClose={handleCancel}
          data-testid='dialog-title'
          className={classNameTitle}>
          {title}
          {titleIcon}
        </DialogTitle>
        <DialogContent className={classes.content} data-testid='dialog-content'>
          <Text variant='normal'>{body}</Text>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleCancel}
            data-testid='button-cancel'>
            {cancelProps?.btnLabel || 'Cancel'}
          </Button>
          <Button
            onClick={handleSuccess}
            disabled={loading}
            data-testid='button-success'>
            {loading ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              successProps.btnLabel
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmButton;
