export const massAsyncRequest = async (requests: Function[]) => {
  let i = 0;
  const result = [];
  while (i < requests.length) {
    try {
      const res = await requests[i]();
      result.push(res);
    } catch (e: any) {
      result.push(undefined);
      console.log('error massAsync iteration', e);
    }
    i += 1;
  }
  return result;
};
