import { ButtonRepeatEvent } from '../../components/common';
import { format } from 'date-fns';

const Test = () => {
  const date = format(new Date(), 'MM/dd/yyyy');

  return (
    <>
      <span style={{ marginLeft: 15 }}>{date}</span>
      <ButtonRepeatEvent selectDate={new Date()} />
    </>
  );
};

export default Test;
