import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TenantId } from '../api/tenants/Tenant';
import { Settings } from '../api/tenants/types/Settings';
import { getSettings } from '../pages/public/officehours/common/Api';

export interface AdvisorSettingsProviderProps {
  id: TenantId;
}

export interface AdvisorSettingsContextProps {
  settings: Settings | null;
}

export const AdvisorSettingsContext =
  createContext<AdvisorSettingsContextProps>({
    settings: null,
  });

export const AdvisorSettingsProvider: FC<
  PropsWithChildren<AdvisorSettingsProviderProps>
> = ({ children, id }) => {
  const [settings, setSettings] = useState<Settings | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getSettings(id);
      setSettings(settings);
    };

    fetchSettings();
  }, [id]);

  const contextValue: AdvisorSettingsContextProps = {
    settings,
  };

  return (
    <AdvisorSettingsContext.Provider value={contextValue}>
      {children}
    </AdvisorSettingsContext.Provider>
  );
};

export const useAdvisorSettings = () => {
  return useContext(AdvisorSettingsContext);
};
