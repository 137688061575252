import { Avatar as MaterialAvatar, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { getColorByUnicode } from '../../utils/functions';

interface AvatarProps {
  src?: string | null;
  name: string;
  size?: '32' | '40' | '64' | '80' | '120';
  variant?: 'circle' | 'rounded';
  color?: COLORS;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const useStyles = makeStyles({
  container: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
  },
  '32': {
    width: 32,
    height: 32,
    fontSize: 14,
    lineHeight: '24px',
  },
  '40': {
    width: 40,
    height: 40,
    fontSize: 14,
    lineHeight: '24px',
  },
  '64': {
    width: 64,
    height: 64,
    fontSize: 20,
    lineHeight: '32px',
  },
  '80': {
    width: 120,
    height: 120,
    fontSize: 30,
    lineHeight: '32px',
    borderRadius: '18px',
  },
  '120': {
    width: 120,
    height: 120,
    fontSize: 40,
    lineHeight: '32px',
    borderRadius: '18px',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});

function Avatar({
  src,
  children,
  name,
  size = '32',
  variant = 'circle',
  color,
  className,
  onClick,
}: AvatarProps) {
  const classes = useStyles();

  return (
    <MaterialAvatar
      onClick={onClick}
      variant={variant === 'circle' ? 'circular' : variant}
      className={cn(classes.container, className, {
        [classes[size]]: size,
      })}
      style={{ background: color || getColorByUnicode(name) }}>
      {children ? (
        children
      ) : (
        <>
          {!src ? (
            name
          ) : (
            <div
              style={{ backgroundImage: `url(${src})` }}
              className={classes.image}
            />
          )}
        </>
      )}
    </MaterialAvatar>
  );
}

export default Avatar;
