import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',

    height: '100%',
    background: '#fff',
    padding: '32px 16px',
    boxSizing: 'border-box',
    overflow: 'auto',
    minWidth: '88px',
    maxWidth: '88px',

    '& span': {
      display: 'none',

      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },

    '& svg': {
      marginRight: '0',

      [theme.breakpoints.up('lg')]: {
        marginRight: '16px',
      },
    },

    '&:hover': {
      zIndex: 3,
      minWidth: '248px',
      maxWidth: '248px',
      boxShadow:
        '0px 0px 2px rgba(34, 91, 187, 0.2), 0px 0px 8px rgba(51, 126, 255, 0.2)',
      '& span': {
        display: 'block',
      },
      '& svg': {
        marginRight: '16px',
      },
    },

    [theme.breakpoints.up('lg')]: {
      minWidth: '248px',
      maxWidth: '248px',
      position: 'initial',
      boxShadow: 'initial !important',
    },
  },
}));

export function Sidebar({ className, children }: PropsWithChildren<Props>) {
  const classes = useStyles();

  return <div className={cn(classes.container, className)}>{children}</div>;
}
