import { format } from 'date-fns';
import { FormattedDate } from './date/FormattedDate';

export type RFCDate = FormattedDate<"yyyy-MM-dd'T'HH:mm:ss">;

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const isTomorrowDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() + 1 === second.getDate();

export const formatDateToRFC = (
  date: Date,
): FormattedDate<"yyyy-MM-dd'T'HH:mm:ss"> => {
  return format(
    date,
    "yyyy-MM-dd'T'HH:mm:ss",
  ) as FormattedDate<"yyyy-MM-dd'T'HH:mm:ss">;
};

export const formatDate = (rfcDate: string | Date) => {
  return format(new Date(rfcDate), 'MM/dd/yyyy');
};

export const formatLocalizedTime = (date: Date) => {
  return format(date, 'hh:mm a');
};

export const formatDateGap = (dateStart: Date, dateEnd: Date) => {
  const data = formatDate(dateStart);
  const beginningGap = formatLocalizedTime(dateStart);
  const finalGap = formatLocalizedTime(dateEnd);
  return `${data} ${beginningGap}-${finalGap}`;
};

export const formatTimeInterval = (dateStart: Date, dateEnd: Date) => {
  const beginningGap = formatLocalizedTime(dateStart);
  const finalGap = formatLocalizedTime(dateEnd);
  return `${beginningGap} - ${finalGap}`;
};

export const formatDateInterval = (dateStart: Date, dateEnd: Date) => {
  const formatDate = 'MMM dd';
  const beginningGap = format(dateStart, formatDate);
  const finalGap = format(dateEnd, formatDate);
  const year = format(dateStart, 'yyyy');

  return `${beginningGap} - ${finalGap}, ${year}`;
};

export const getTomorrow = (date?: Date) => {
  let today = new Date();
  if (date) {
    today = date;
  }
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const formatDateTime = (date: string | null) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'MM/dd/yyyy p');
};

export const formatDateRange = (
  dateStart: Date | string,
  dateEnd: Date | string,
) => {
  const start = formatDate(dateStart);
  const end = formatDate(dateEnd);
  return `${start} - ${end}`;
};

export const formatDateApi = (rfcDate: string | Date) => {
  return format(new Date(rfcDate), 'MMddyyyy');
};

export const getDifferenceInDays = (start: Date, end: Date) => {
  const differenceInTime = end.getTime() - start.getTime();
  return Math.ceil(differenceInTime / (1000 * 3600 * 24));
};

export const dateFallsInInterval = (date: Date, start: Date, end: Date) => {
  const dateTimestamp = +date;
  const startTimestamp = +start;
  const endTimestamp = +end;
  return startTimestamp < dateTimestamp && endTimestamp > dateTimestamp;
};

export const isValidateDate = (date: string | Date) => {
  try {
    formatDateToRFC(new Date(date));
    return true;
  } catch (e: any) {
    return false;
  }
};

export const getDateRangeForReport = (start: Date, end: Date) => {
  const formatDate = 'MMM d, yyyy';
  const startDate = format(start, formatDate);
  const endDate = format(end, formatDate);

  return `${startDate} to ${endDate}`;
};

export const getFormattedFullDay = (date: Date) => {
  const formatDate = 'MMMM yyyy';
  return format(date, formatDate);
};

export const getDateForReportList = (date: Date): string => {
  const formatDate = 'MMM yyyy';
  return format(new Date(date), formatDate);
};

export const getDateForGeneralReportList = (dateStr: string): string => {
  const formatDate = 'MMM yyyy';

  return format(new Date(dateStr), formatDate);
};

export const getPreviousMonth = (date: Date, formatStr = 'MMMM yyyy') => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - 1);
  return format(newDate, formatStr);
};

export const getYearFromDate = (date: Date) => {
  return format(date, 'yyyy');
};

export const getFormattedGeneralReportDate = (rfcDate: string | Date) => {
  return format(new Date(rfcDate), 'yyyy-MM-dd');
};

export const getNextSessionDate = (date: string | Date): string => {
  const formatDate = "MM/dd/yyyy 'at' hh:mmaaa";
  return format(new Date(date), formatDate);
};

export const getVoteDate = (date: string | Date): string => {
  const formatDate = "MMMM dd, yyyy 'at' hh:mmaaa";
  return format(new Date(date), formatDate);
};

export const getCustomGatheringDate = (date: string | Date): string => {
  const formatDate = 'MMM-dd-yyyy';
  return format(new Date(date), formatDate);
};

export const getMonthByIndex = (index: number) => {
  const date = new Date(2021, index, 1);
  return format(date, 'MMM');
};

export const getGeneralReportMonthIndex = (date: Date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - 1);
  return newDate.getMonth();
};

export const getYearGeneralReport = (date: Date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - 1);
  return format(newDate, 'yyyy');
};

export const getNoteCardDate = (date: string | Date) => {
  return format(new Date(date), 'E dd hh:mmaaa');
};

export const getCommentDate = (date: string | Date) => {
  return format(new Date(date), 'MM/dd/yyyy hh:mmaaa');
};
