import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';

interface StatusBadgeProps {
  variant?: 'default' | 'success' | 'warning' | 'error' | 'primary';
  status: string;
  'data-testid'?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    fontSize: 11,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0 8px',
    borderRadius: 4,
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  default: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    background: COLORS.COLOR_GRAY_LIGHTENED_40
  },
  success: {
    background: COLORS.COLOR_GREEN_LIGHTENED_45,
    color: COLORS.COLOR_GREEN_BASE
  },
  warning: {
    background: COLORS.COLOR_ADDITIONAL_ORANGE_LIGHTENED,
    color: COLORS.COLOR_ADDITIONAL_ORANGE
  },
  primary: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,
    color: COLORS.COLOR_BLUE_BASE
  },
  error: {
    background: COLORS.COLOR_RED_LIGHTENED,
    color: COLORS.COLOR_RED_BASE
  }
});

function StatusBadge({
  variant = 'default',
  status,
  ...props
}: StatusBadgeProps) {
  const classes = useStyles();

  return (
    <div className={cn(classes.container, classes[variant])} {...props}>
      {status}
    </div>
  );
}

export default StatusBadge;
