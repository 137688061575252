import InputMask from 'react-input-mask';
import { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core';
import TextField from './text-field';

type PhoneInputProps = MaterialTextFieldProps & {
  value: string;
};

function PhoneInput({
  value,
  onChange,
  onBlur,
  onFocus,
  ...textFieldProps
}: PhoneInputProps) {
  return (
    <InputMask
      mask='(999) 999-9999'
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}>
      <TextField type='tel' {...textFieldProps} />
    </InputMask>
  );
}

export default PhoneInput;
