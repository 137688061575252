import { makeStyles, CircularProgress } from '@material-ui/core';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CancelIcon from '@material-ui/icons/Cancel';
import { Text } from '../components/common';
import { COLORS } from '../theme/variables';
import { UserContext } from '../contexts/user-context';
import { getRoutePath, Pages } from '../router/constants';
import authAPI from '../api/auth';

interface DecodedKey {
  tenantId: string;
  founderId: string;
  randomGUID: string;
}

const decodeKey = (key: string): DecodedKey => {
  const parseData = atob(key).split('|');

  return {
    tenantId: parseData[0],
    founderId: parseData[1],
    randomGUID: parseData[2],
  };
};

const useStyles = makeStyles(() => ({
  meta_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    width: '100%',
    height: '100%',
  },
  meta_block: {
    maxWidth: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
    background: '#fff',
    padding: '32px',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    boxSizing: 'border-box',
  },
  meta_text: {
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
}));

export default function FounderUpdatePage() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const key = useMemo(() => location.search.replace('?key=', ''), [location]);
  const { updateToken } = useContext(UserContext);
  const decodedKey = useMemo(() => decodeKey(key), [key]);
  const [metaState, setMetaState] = useState<'loading' | 'error' | ''>(
    'loading',
  );

  const getFounderToken = useCallback(async () => {
    try {
      const token = await authAPI.getOTLToken(key);
      updateToken(token, true);
      history.replace(
        getRoutePath(Pages.FOUNDER_EXTERNAL_DETAILS, {
          founderId: decodedKey.founderId,
        }),
      );
      setMetaState('');
    } catch (e) {
      setMetaState('error');
    }
  }, [updateToken, key, history, decodedKey.founderId]);

  useEffect(() => {
    getFounderToken();
  }, [getFounderToken]);

  if (metaState === 'error') {
    return (
      <div
        data-testid='founder-update-page-error'
        className={classes.meta_container}>
        <div className={classes.meta_block}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.meta_text}>
            <Text variant='normal'>
              Sorry. Looks like your key has expired or you used this key
              before. We cannot provide access to this information anymore.
              Please contact your program Admin
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid='founder-update-page-loading'
      className={classes.meta_container}>
      <CircularProgress size={54} color='primary' />
    </div>
  );
}
