import { Advisor } from '../../../api/Advisors/types/Advisor';
import { Availability } from '../../../api/Advisors/types/Availability';
import { isValidPeriod, ValidPeriod } from '../../../api/types/Period';

// region ValidAvailability
export interface ValidAvailability extends Availability {
  schedulingPeriod: ValidPeriod;
}

export const isValidAvailability = (a: Availability): a is ValidAvailability =>
  isValidPeriod(a.schedulingPeriod);
// endregion

// region ValidAdvisor
export interface ValidAdvisor extends Advisor {
  advisorAvailabilities: ValidAvailability[];
}

export function isValidAdvisor(a: Advisor): a is ValidAdvisor {
  return a.advisorAvailabilities.every(isValidAvailability);
}
// endregion
