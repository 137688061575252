import { createContext, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Drawer } from '../components/common';

interface DrawerProps {
  title: string;
  body: string | React.ReactNode;
}

export interface DrawerContextProps {
  createDrawer: (drawer: DrawerProps) => any;
}

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const DrawerContext = createContext<DrawerContextProps>({
  createDrawer: () => {},
});

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawer, setDrawer] = useState<DrawerProps>();

  const createDrawer = useCallback((drawer: DrawerProps) => {
    setDrawer(drawer);
  }, []);

  const removeDrawer = useCallback(() => {
    setDrawer(undefined);
  }, []);

  const contextValue: DrawerContextProps = {
    createDrawer,
  };

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      {!!drawer &&
        ReactDOM.createPortal(
          <Drawer {...drawer} onClosed={removeDrawer} />,
          document.body,
        )}
    </DrawerContext.Provider>
  );
};
