import { Button, IconButton, Link, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useState } from 'react';
import { COLORS } from '../../theme/variables';
import CustomSpecializationForm, {
  Values as FormValues,
} from '../forms/custom-specialization';
import { ActionsDialog } from './Confirmation/ActionsDialog';
import Text from './text';

interface NewSpecializationProps {
  creating?: boolean;
  isLimitExceeded?: boolean;
  limitNumber?: number;
  onCreate: (specialization: { name: string }, callback: () => any) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },
  },
});

function NewSpecialization({
  creating,
  isLimitExceeded,
  limitNumber = 0,
  onCreate,
}: NewSpecializationProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);
  const [isLimitExceededShown, setIsLimitExceededShown] = useState(false);

  const handleOpenForm = () => {
    setIsShownForm(true);
  };

  const handleCloseForm = () => {
    setIsShownForm(false);
  };

  const handleLimitExceededShow = () => {
    setIsLimitExceededShown(true);
  };

  const handleLimitExceededClose = () => {
    setIsLimitExceededShown(false);
  };

  const handleCreateSpecialization = useCallback(
    (values: FormValues) => {
      onCreate(values, handleCloseForm);
    },
    [onCreate],
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, []);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <CustomSpecializationForm
          title='New Specialization'
          loading={creating}
          onSubmit={handleCreateSpecialization}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div className={classes.main}>
          <Typography variant='h4'>New Specialization</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={isLimitExceeded ? handleLimitExceededShow : handleOpenForm}
            data-testid='button-new-specialization'>
            <AddIcon />
          </IconButton>
        </div>
      )}
      <ActionsDialog
        open={isLimitExceededShown}
        onClose={handleLimitExceededClose}
        title='Limit exceeded'
        actions={[
          <Button
            onClick={handleLimitExceededClose}
            data-testid='limit-exceeded-close-button'
            variant='contained'
            color='primary'>
            Continue
          </Button>,
        ]}>
        <Text variant='normal2'>
          We are trying to limit the number of specializations available on our
          platform. Currently the limit is {limitNumber} which is reached at the
          moment. Please delete existing specializations before trying to add
          new. Feel free to drop us a note at{' '}
          <Link href='mailto:support@tractionfive.com'>
            support@tractionfive.com
          </Link>{' '}
          should you have any questions.
        </Text>
      </ActionsDialog>
    </div>
  );
}

export default NewSpecialization;
