import { Event } from '../../../../api/events';
import { FounderId } from '../../../../api/founders';
import { SchedulingWizard } from '../../../../api/schedulingWizard';
import { VentureId } from '../../../../api/ventures/types/Venture';
import { ParsedFormValues as WizardFormValues } from '../../../../components/forms/scheduling-wizard-initial-form';
import { Email } from '../../../../utils/String/Email';

// region Loading
export interface LoadingPayload {
  id: FounderId;
  email: Email;
  filter: {
    dateRange: {
      startDate: Date;
      endDate: Date;
    };
  };
  scheduleVentureId: VentureId | undefined;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});

export const isLoading = (state: State): state is Loading =>
  state.type === 'Loading';
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});

export const isLoadError = (state: State): state is LoadError =>
  state.type === 'LoadError';
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  sessions: Event[];
  ventures: Array<{ id: VentureId; name: string }>;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});

export const isReady = (state: State): state is Ready => state.type === 'Ready';
// endregion

// region ScheduleCheck
export interface ScheduleCheckPayload extends ReadyPayload {}

export interface ScheduleCheck {
  type: 'ScheduleCheck';
  payload: ScheduleCheckPayload;
}

export const scheduleCheck = (
  payload: ScheduleCheck['payload'],
): ScheduleCheck => ({
  type: 'ScheduleCheck',
  payload,
});

export const isScheduleCheck = (state: State): state is ScheduleCheck =>
  state.type === 'ScheduleCheck';
// endregion

// region ScheduleChecking
export interface ScheduleCheckingPayload extends ScheduleCheckPayload {
  scheduleVentureId: VentureId;
}

export interface ScheduleChecking {
  type: 'ScheduleChecking';
  payload: ScheduleCheckingPayload;
}

export const scheduleChecking = (
  payload: ScheduleChecking['payload'],
): ScheduleChecking => ({
  type: 'ScheduleChecking',
  payload,
});

export const isScheduleChecking = (state: State): state is ScheduleChecking =>
  state.type === 'ScheduleChecking';
// endregion

// region ScheduleDelete
export interface ScheduleDeletePayload extends ScheduleCheckingPayload {
  scheduleVentureId: VentureId;
  currentWizzards: SchedulingWizard[];
}

export interface ScheduleDelete {
  type: 'ScheduleDelete';
  payload: ScheduleDeletePayload;
}

export const scheduleDelete = (
  payload: ScheduleDelete['payload'],
): ScheduleDelete => ({
  type: 'ScheduleDelete',
  payload,
});

export const isScheduleDelete = (state: State): state is ScheduleDelete =>
  state.type === 'ScheduleDelete';
// endregion

// region ScheduleDeleting
export interface ScheduleDeletingPayload extends ScheduleDeletePayload {}

export interface ScheduleDeleting {
  type: 'ScheduleDeleting';
  payload: ScheduleDeletingPayload;
}

export const scheduleDeleting = (
  payload: ScheduleDeleting['payload'],
): ScheduleDeleting => ({
  type: 'ScheduleDeleting',
  payload,
});

export const isScheduleDeleting = (state: State): state is ScheduleDeleting =>
  state.type === 'ScheduleDeleting';
// endregion

// region ScheduleCreate
export interface ScheduleCreatePayload extends ScheduleCheckingPayload {}

export interface ScheduleCreate {
  type: 'ScheduleCreate';
  payload: ScheduleCreatePayload;
}

export const scheduleCreate = (
  payload: ScheduleCreate['payload'],
): ScheduleCreate => ({
  type: 'ScheduleCreate',
  payload,
});

export const isScheduleCreate = (state: State): state is ScheduleCreate =>
  state.type === 'ScheduleCreate';
// endregion

// region ScheduleCreating
export interface ScheduleCreatingPayload extends ScheduleCreatePayload {
  scheduleCreateValues: WizardFormValues;
}

export interface ScheduleCreating {
  type: 'ScheduleCreating';
  payload: ScheduleCreatingPayload;
}

export const scheduleCreating = (
  payload: ScheduleCreating['payload'],
): ScheduleCreating => ({
  type: 'ScheduleCreating',
  payload,
});

export const isScheduleCreating = (state: State): state is ScheduleCreating =>
  state.type === 'ScheduleCreating';
// endregion

type loadedStates =
  | Ready
  | ScheduleCheck
  | ScheduleChecking
  | ScheduleDelete
  | ScheduleDeleting
  | ScheduleCreate
  | ScheduleCreating;
export type State = Loading | LoadError | loadedStates;

export const stateIsLoaded = (s: State): s is loadedStates =>
  s.type === 'Ready' ||
  s.type === 'ScheduleCheck' ||
  s.type === 'ScheduleChecking' ||
  s.type === 'ScheduleDelete' ||
  s.type === 'ScheduleDeleting' ||
  s.type === 'ScheduleCreate' ||
  s.type === 'ScheduleCreating';
