import { axiosRequest } from '../instance';
import { ICreateFeedback, IFeedback } from './types/Feedback';

export function createFeedback(data: ICreateFeedback): Promise<IFeedback> {
  return axiosRequest
    .post<IFeedback>({
      url: '/feedback',
      credentials: true,
      data,
    })
    .then((r) => r.data);
}
