import { AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { Item } from './Item';
import { TenantId } from '../../../../api/tenants/Tenant';

// region Loading
export interface Loading {
  type: 'Loading';
  payload: { id: AdvisorId; tenantId: TenantId };
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: {
    id: AdvisorId;
    tenantId: TenantId;
    message: string;
  };
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface Ready {
  type: 'Ready';
  payload: {
    id: AdvisorId;
    item: Item;
    tenantId: TenantId;
    enrollmentDate: Date;
    logo: File | undefined;
  };
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

export type State = Loading | LoadError | Ready;

export function init(tenantId: TenantId, id: AdvisorId): Loading {
  return loading({ id, tenantId });
}

// region Loaded
export type Loaded = Ready;

export function isLoaded(s: State): s is Loaded {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Ready':
      return true;
  }
}
// endregion
