import { Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { CohortId } from '../../../../api/Cohort/types/Cohort';
import { TestId } from '../../../../components/Testing/TestId';
import { Delete } from '../../../../components/common/ActionIcons/Delete';
import { Edit } from '../../../../components/common/ActionIcons/Edit';
import { Inline } from '../../../../components/common/Inline';
import C from './ViewItem.module.scss';

export interface ViewItemProps {
  title: string;
  description: string;
  onEdit: () => void;
  onDelete: () => void;
  id: CohortId;
}

export function ViewItem({
  title,
  description,
  onEdit,
  onDelete,
  id,
}: ViewItemProps) {
  const hasDesc = useMemo((): boolean => {
    const el = document.createElement('div');
    el.innerHTML = description;

    return !!el.textContent?.length;
  }, [description]);

  return (
    <TestId testId={'view-item'}>
      <div className={C.root}>
        <TestId testId={`view-item-content-${id}`}>
          <div>
            <Typography variant='subtitle2'>
              <strong>Name:</strong>{' '}
              <TestId testId={'title'}>
                <span>{title}</span>
              </TestId>
            </Typography>
            {hasDesc && (
              <Typography variant='subtitle2'>
                <strong>Description:</strong>
                <TestId testId={'description'}>
                  <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={C.description}
                  />
                </TestId>
              </Typography>
            )}
          </div>
        </TestId>
        <Inline gap={10}>
          <TestId testId={'edit'}>
            <Edit onClick={onEdit} />
          </TestId>
          <TestId testId={'delete'}>
            <Delete onClick={onDelete} />
          </TestId>
        </Inline>
      </div>
    </TestId>
  );
}
