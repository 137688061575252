import {
  CircularProgress,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { cancelPayment } from '../../api/Appointments';
import { Appointment } from '../../api/Appointments/types/Appointment';
import { Button, Text } from '../../components/common';
import { Pages, getRoutePath } from '../../router/constants';

enum StateTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

interface CommonState {
  type: StateTypes;
}

interface SuccessState extends CommonState {
  type: StateTypes.SUCCESS;
  appointment: Appointment;
}

interface ErrorState extends CommonState {
  type: StateTypes.ERROR;
}

interface LoadingState extends CommonState {
  type: StateTypes.LOADING;
}

type State = SuccessState | ErrorState | LoadingState;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25,
  },
  block: {
    width: '540px',
    maxWidth: '95%',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    boxSizing: 'border-box',
  },
  logo: {
    width: 300,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
  mainIcon: {
    fontSize: '48px',
  },
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 300,
    gap: '10px',
  },
}));

function parseSessionIdFromURL(search: string): any {
  return _pick(parseQuery(search), ['session_id']);
}

function PaymentCancelPage() {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = useState<State>({ type: StateTypes.LOADING });

  const sessionId = useMemo(() => {
    return parseSessionIdFromURL(location.search)?.session_id;
  }, [location]);

  useEffect(() => {
    const confirmCancel = async () => {
      setState({ type: StateTypes.LOADING });

      try {
        if (!sessionId) throw new Error();
        const appointment = await cancelPayment(sessionId);
        setState({ type: StateTypes.SUCCESS, appointment });
      } catch (error) {
        console.error(error);
        setState({ type: StateTypes.ERROR });
      }
    };

    confirmCancel();
  }, [sessionId]);

  return (
    <div
      data-testid='payment-success-page-container'
      className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      {state.type === StateTypes.LOADING && (
        <div className={classes.loaderContainer}>
          <CircularProgress color='primary' size={40} />
        </div>
      )}
      {(state.type === StateTypes.SUCCESS ||
        state.type === StateTypes.ERROR) && (
        <>
          <div className={classes.topBlock}>
            <ErrorIcon color='error' className={classes.mainIcon} />
            <Typography variant='h5' align='center'>
              Oops.. We had a hiccup processing the payment
            </Typography>
          </div>
          <div className={classes.block}>
            <Text variant='normal'>
              Unfortunately an error occurred while making an attempt to process
              your payment request and we will need to cancel the reservation
              you made. Please feel free to make another attempt to book the
              time.
              <br />
              <br />
              If you need assistance with booking, please reach out to Igor
              Gorlatov:{' '}
              <Link href='mailto:igor@tractionfive.com'>
                igor@tractionfive.com
              </Link>
            </Text>
            {state.type === StateTypes.SUCCESS && (
              <Button
                variant={'contained'}
                href={getRoutePath(Pages.PUBLIC_ADVISORS, {
                  tenantId: state.appointment.tenantId,
                })}>
                Continue
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default PaymentCancelPage;
