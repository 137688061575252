import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getLogs } from '../../api/goals';
import { GoalLog } from '../../api/types/Goal';
import { PageLoader } from '../../components/common';
import { ListLogs } from '../../components/common/Logs/List';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function GoalLogPage() {
  const classes = useStyles();
  const { goalId } = useParams<{ goalId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<GoalLog[]>([]);

  const loadLogs = async (goalId: string) => {
    try {
      setIsLoading(true);
      const responseLogs = await getLogs(goalId);

      const sortLogs = responseLogs.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setLogs(sortLogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLogs(goalId);
  }, [goalId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return <ListLogs logs={logs} />;
}

export default GoalLogPage;
