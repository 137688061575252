import { makeStyles } from '@material-ui/styles';
import {
  CircularProgress,
  InputAdornment,
  Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Form, Field } from 'react-final-form';
import { Button, FormGroup, FormButtons, BeforeUnload } from '../common';
import { FormDateInput, FormSelect, TextFieldWrapper } from './wrappers';
import { formatDateToRFC, isValidateDate } from '../../utils/date';
import { VentureRecord } from '../../api/ventures';
import { isMobile } from '../../utils/functions';

export interface Values {
  type: string;
  contents: number;
  creationDate: string;
  recordDate: string;
  comment: string;
}

type Errors = {
  [K in keyof Values]?: string;
};

interface FinanceRecordFormProps {
  title: string;
  loading?: boolean;
  record?: VentureRecord;
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const TYPES = [
  'Revenue - Monthly',
  'Revenue - Yearly',
  'Fund Raising - Equity Raise',
  'Fund Raising - Loan',
  'Fund Raising - Grant',
  'Fund Raising - Crowdfunding',
  'Fund Raising - Other'
];

const useStyles = makeStyles({
  form: {
    width: '100%'
  },
  title: {
    marginBottom: 32
  },
  formButtons: {
    justifyContent: 'flex-start'
  },
  comments: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64
    }
  }
});

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.type) {
    errors.type = 'Required';
  }
  if (!values.creationDate) {
    errors.creationDate = 'Required';
  }
  if (!values.recordDate) {
    errors.recordDate = 'Required';
  }
  if (values.creationDate && !isValidateDate(values.creationDate)) {
    errors.creationDate = 'Invalid';
  }
  if (values.recordDate && !isValidateDate(values.recordDate)) {
    errors.recordDate = 'Invalid';
  }
  if (!values.contents) {
    errors.contents = 'Required';
  }
  return errors;
};

function FinanceRecordForm({
  title,
  record,
  loading = false,
  onSubmit,
  onCancel
}: FinanceRecordFormProps) {
  const classes = useStyles();
  const initialValues: Values = {
    type: record?.type || TYPES[0],
    contents: record?.contents ? +record?.contents : 0,
    creationDate: record?.creationDate || formatDateToRFC(new Date()),
    recordDate: record?.recordDate || formatDateToRFC(new Date()),
    comment: record?.comment || ''
  };

  const handleSubmit = (values: Values) => {
    onSubmit({ ...values, contents: +values.contents });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateForm}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <FormGroup mobile={isMobile()}>
              <Field<string>
                name='contents'
                data-testid='finance-form-amount'
                component={TextFieldWrapper}
                label='Amount*'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AttachMoneyIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Field<string>
                name='type'
                component={FormSelect}
                label='Type'
                options={TYPES.map((type) => ({ label: type, value: type }))}
              />
            </FormGroup>
            <FormGroup mobile={isMobile()}>
              <Field<string>
                name='creationDate'
                component={FormDateInput}
                label='Creation Date*'
                editable
                parse={(value) => {
                  try {
                    if (value) {
                      return formatDateToRFC(value);
                    }
                    return '';
                  } catch (e: any) {
                    return 'invalid';
                  }
                }}
              />
              <Field<string>
                name='recordDate'
                component={FormDateInput}
                label='Record Date*'
                editable
                parse={(value) => {
                  try {
                    if (value) {
                      return formatDateToRFC(value);
                    }
                    return '';
                  } catch (e: any) {
                    return 'invalid';
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name='comment'
                component={TextFieldWrapper}
                label='Comment'
                multiline
                className={classes.comments}
                data-testid='finance-form-comment'
              />
            </FormGroup>
            <FormButtons className={classes.formButtons}>
              <Button
                type='submit'
                data-testid='form-finance-submit'
                disabled={loading}
                startIcon={<CheckIcon />}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='outlined' onClick={handleCancel}>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default FinanceRecordForm;
