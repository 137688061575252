import { CircularProgress, Link, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { getSettings } from '../api/tenants';
import { TenantId } from '../api/tenants/Tenant';
import LoginForm from '../components/forms/login-form';
import SecondFactorAuthForm from '../components/forms/second-factor-auth-form';
import { useAuth } from '../hooks';
import { useQuery } from '../hooks/useQuery';
import { Pages } from '../router/constants';
import { COLORS } from '../theme/variables';

enum FormTypes {
  LOGIN = 'LOGIN',
  SFA = 'SFA',
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 20,
    marginTop: -25,
  },
  block: {
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 300,
    height: 105,
  },
  logo: {
    maxWidth: 300,
    height: 105,
    objectFit: 'contain',
  },
  poweredByLink: {
    transition: 'all 0.2s linear',
    '&:hover': {
      marginBottom: 0,
      borderBottom: 'none',
      color: COLORS.COLOR_TEXT_LIGHTENED_10,
    },
  },
});

const defaultLogoUrl = '/Logo.png';

function LoginPage() {
  const classes = useStyles();
  const { login, login2FA } = useAuth();
  const query = useQuery();
  const tenantId = query.get('tenantId') as TenantId | null;
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [error, setError] = useState('');
  const [logoUrl, setLogoUrl] = useState<string | null>(
    tenantId ? null : defaultLogoUrl,
  );
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [showForm, setShowForm] = useState<FormTypes>(FormTypes.LOGIN);
  const [tempCredentials, setTempCredentials] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const handleLogin = useCallback(
    async (credentials) => {
      try {
        setError('');
        setLoading(true);
        const res = await login(credentials);

        if (res.secondFactorRequired) {
          setShowForm(FormTypes.SFA);
          setTempCredentials(credentials);
          setLoading(false);
        }
      } catch (e: any) {
        setError(e.response?.data?.message || 'Internal server error');
        setLoading(false);
      }
    },
    [login],
  );

  const handleSecondFactorAuth = useCallback(
    async ({ secondFactorCode }: { secondFactorCode: string }) => {
      try {
        setError('');

        if (!tempCredentials) {
          setShowForm(FormTypes.LOGIN);
          return;
        }

        setLoading(true);

        await login2FA({
          ...tempCredentials,
          secondFactorCode,
        });
      } catch (e: any) {
        setError(e.response?.data?.message || 'Internal server error');
        setLoading(false);
      }
    },
    [login2FA, tempCredentials],
  );

  const handleResendCode = useCallback(async () => {
    try {
      setError('');

      if (!tempCredentials) {
        setShowForm(FormTypes.LOGIN);
        return;
      }

      setResendCodeLoading(true);
      await login(tempCredentials);

      snackbar?.enqueueSnackbar(
        'Code has been sent. Please check your email.',
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
    } finally {
      setResendCodeLoading(false);
    }
  }, [tempCredentials, login, snackbar]);

  useEffect(() => {
    const getLogoUrl = async (tenantId: TenantId) => {
      setLoadingLogo(true);

      try {
        const settings = await getSettings(tenantId);
        const programLogoUrl = settings?.programLogoUrl;

        if (!programLogoUrl) {
          throw new Error('No logo url');
        }

        setLogoUrl(programLogoUrl);
      } catch (e: any) {
        console.error(e);
        setLogoUrl(defaultLogoUrl);
      } finally {
        setLoadingLogo(false);
      }
    };

    if (tenantId) {
      getLogoUrl(tenantId);
    }
  }, [tenantId]);

  return (
    <div data-testid='login-page' className={classes.container}>
      <div className={classes.logoContainer}>
        {loadingLogo ? (
          <CircularProgress size={24} color='primary' />
        ) : (
          logoUrl && <img src={logoUrl} alt='logo' className={classes.logo} />
        )}
      </div>
      <div className={classes.block}>
        {showForm === FormTypes.LOGIN ? (
          <LoginForm error={error} loading={loading} onSubmit={handleLogin} />
        ) : (
          <SecondFactorAuthForm
            error={error}
            loading={loading}
            resendLoading={resendCodeLoading}
            onSubmit={handleSecondFactorAuth}
            onResendCode={handleResendCode}
          />
        )}
      </div>
      <Link
        href={Pages.WEBSITE}
        variant='caption'
        className={classes.poweredByLink}
        color='textSecondary'>
        Powered by Traction5
      </Link>
    </div>
  );
}

export default LoginPage;
