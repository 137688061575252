import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { pipe } from 'ramda';
import { Field, Form } from 'react-final-form';
import { identity } from 'rxjs';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { apply, Either, isLeft, parseObject, right } from '../../utils/Either';
import { fromString, Sentence } from '../../utils/String/Sentence';
import {
  BUSINESS_CHALLENGES,
  FUNDRAISING_CHALLENGES,
  GENDER_MENTOR,
  lengthField,
  MARKETING_CHALLENGES,
  RACE,
  RADIO_SELECT,
  SALES_CHALLENGES,
} from '../../utils/form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  CheckboxListOther,
  FormGroup,
  StickyContent,
} from '../common';
import { FormSelect, TextFieldWrapper } from './wrappers';

interface Props {
  initialValues: FormAdditionalInfo;
  isLoading: boolean;
  onSubmit: (value: ValidFormAdditionalInfo) => void;
  hasAccessToUpdate?: boolean;
}

export interface FormAdditionalInfo {
  gender: Sentence<20> | string | null;
  race: Sentence<50> | string | null;
  veteran: 'true' | 'false' | null;
  whyBecomeMentor: Sentence<4096> | string | null;
  startedBusiness: 'true' | 'false' | null;
  salesChallenges: Sentence<1024> | string | null;
  marketingChallenges: Sentence<1024> | string | null;
  fundraisingChallenges: Sentence<1024> | string | null;
  businessChallenges: Sentence<1024> | string | null;
  comments: Sentence<4096> | string | null;
}

export interface ValidFormAdditionalInfo extends FormAdditionalInfo {
  gender: Sentence<20> | null;
  race: Sentence<50> | null;
  veteran: 'true' | 'false' | null;
  whyBecomeMentor: Sentence<4096> | null;
  startedBusiness: 'true' | 'false' | null;
  salesChallenges: Sentence<1024> | null;
  marketingChallenges: Sentence<1024> | null;
  fundraisingChallenges: Sentence<1024> | null;
  businessChallenges: Sentence<1024> | null;
  comments: Sentence<4096> | null;
}

function getValidFormAdditionalInfo(
  v: FormAdditionalInfo,
): Either<
  Partial<Record<keyof FormAdditionalInfo, string>>,
  ValidFormAdditionalInfo
> {
  return parseObject<
    FormAdditionalInfo,
    ValidFormAdditionalInfo,
    Partial<Record<keyof FormAdditionalInfo, string>>
  >(
    {
      gender: (v) => (v ? fromString(20, v) : right(null)),
      race: (v) => (v ? fromString(50, v) : right(null)),
      veteran: right,
      whyBecomeMentor: (v) => (v ? fromString(4096, v) : right(null)),
      startedBusiness: right,
      salesChallenges: (v) => (v ? fromString(1024, v) : right(null)),
      marketingChallenges: (v) => (v ? fromString(1024, v) : right(null)),
      fundraisingChallenges: (v) => (v ? fromString(1024, v) : right(null)),
      businessChallenges: (v) => (v ? fromString(1024, v) : right(null)),
      comments: (v) => (v ? fromString(4096, v) : right(null)),
    },
    v,
  );
}

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '65px 0 0 80px',
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  multilineField: {
    minHeight: 64,
  },
  checkbox: {
    paddingLeft: 0,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

const validateForm = (values: FormAdditionalInfo) => {
  const r = getValidFormAdditionalInfo(values);

  return isLeft(r) ? r.value : {};
};

function MentorAdditionalInfoForm({
  initialValues,
  isLoading,
  onSubmit,
  hasAccessToUpdate = false,
}: Props) {
  const loading = isLoading;
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const handleSubmit = pipe(
    getValidFormAdditionalInfo,
    apply(identity, onSubmit),
  );

  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <div>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.mainFormBlock}>
              <form noValidate>
                <div className={classes.sectionBlock}>
                  <FormGroup>
                    <Field<string>
                      name='gender'
                      testid='mentor-additional-gender'
                      component={FormSelect}
                      label='Gender'
                      options={GENDER_MENTOR}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field<string>
                      name='race'
                      testid='mentor-additional-race'
                      component={FormSelect}
                      label='Race'
                      options={RACE}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field<string>
                      name='whyBecomeMentor'
                      testid='mentor-additional-why-become'
                      component={TextFieldWrapper}
                      label={`Why would you like to become a ${rb('mentor')}?`}
                      multiline
                      InputProps={{
                        inputProps: {
                          className: classes.multilineField,
                          maxLength: lengthField.additionalInfo,
                          readOnly: !hasAccessToUpdate,
                        },
                      }}
                    />
                  </FormGroup>
                </div>

                <div className={classes.sectionBlock}>
                  <Typography className={classes.sectionTitle} variant='h3'>
                    Experience
                  </Typography>

                  <FormGroup>
                    <Field<string>
                      name='startedBusiness'
                      testid='mentor-additional-started-business'
                      component={FormSelect}
                      options={RADIO_SELECT}
                      label='Have you ever started a business of your own?'
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <CheckboxListOther
                    name='salesChallenges'
                    testid='sales-challenges'
                    options={SALES_CHALLENGES}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'salesChallenges',
                        value,
                      )
                    }
                    text='What of the following SALES challenges would be within your area of expertise? (select 1-3 that apply best)'
                    labelOther={`Other option sales challenges within ${rb(
                      'mentor',
                    )}’s area of expertise`}
                    value={formProps.values.salesChallenges ?? ''}
                    readOnly={!hasAccessToUpdate}
                  />

                  <CheckboxListOther
                    name='marketingChallenges'
                    testid='marketing-challenges'
                    options={MARKETING_CHALLENGES}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'marketingChallenges',
                        value,
                      )
                    }
                    text='What of the following MARKETING challenges would be within your area of expertise? (select 1-3 that apply best)'
                    labelOther='Other option marketing challenges within the area of expertise'
                    value={formProps.values.marketingChallenges ?? ''}
                    readOnly={!hasAccessToUpdate}
                  />

                  <CheckboxListOther
                    name='fundraisingChallenges'
                    testid='fundraising-challenges'
                    options={FUNDRAISING_CHALLENGES}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'fundraisingChallenges',
                        value,
                      )
                    }
                    text='What of the following FUNDRAISING challenges would be within your area of expertise? (select 1-3 that apply best)'
                    labelOther='Other option fundraising challenges within the area of expertise'
                    value={formProps.values.fundraisingChallenges ?? ''}
                    readOnly={!hasAccessToUpdate}
                  />

                  <CheckboxListOther
                    name='businessChallenges'
                    testid='business-challenges'
                    options={BUSINESS_CHALLENGES}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'businessChallenges',
                        value,
                      )
                    }
                    text='What of the following various BUSINESS challenges would be within your area of expertise? (select 1-3 that apply best)'
                    labelOther='Other option business challenges within the area of expertise'
                    value={formProps.values.businessChallenges ?? ''}
                    readOnly={!hasAccessToUpdate}
                  />

                  <FormGroup>
                    <Field<string>
                      name='comments'
                      data-testid='mentor-additional-comments'
                      component={TextFieldWrapper}
                      label='Comments'
                      multiline
                      InputProps={{
                        inputProps: {
                          className: classes.multilineField,
                          maxLength: lengthField.additionalInfo,
                          readOnly: !hasAccessToUpdate,
                        },
                      }}
                    />
                  </FormGroup>
                </div>

                {hasAccessToUpdate && (
                  <div className={classes.actionsBlock}>
                    <StickyContent>
                      <Button
                        className={CLASS_TRACKING.INTERNAL_ACTION}
                        onClick={formProps.handleSubmit}
                        disabled={loading || !formProps.valid}
                        startIcon={<CheckIcon />}>
                        {loading ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </StickyContent>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default MentorAdditionalInfoForm;
