import { isT } from 'fp-utilities';
import {
  AdvisorId,
  AdvisorWithSpecializations,
} from '../../../../../api/Advisors/types/Advisor';
import { Specialization } from '../../../../../api/specializations';

export interface Item {
  id: AdvisorId;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  description: string;
  tags: string[];
  rate: number | null;
}

export function fromAdvisor(
  a: AdvisorWithSpecializations,
  specializations: Specialization[],
): Item {
  return {
    id: a.id,
    firstName: a.firstName,
    lastName: a.lastName,
    description: a.shortDescription,
    tags: a.advisorSpecializations
      .map(
        (i) =>
          specializations.find(({ id }) => i.specializationId === id)?.name,
      )
      .filter(isT),
    avatar: a.logo ?? undefined,
    rate: a.rate,
  };
}
