import { makeStyles } from '@material-ui/core';
import { Log, Text } from '../index';
import { LogType } from './types/LogType';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

export interface Props<T extends LogType<any>> {
  logs: T[];
}

export function ListLogs<T extends LogType<any>>({ logs }: Props<T>) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        {logs.length > 0 ? (
          logs.map((log, index) => <Log key={log.id} log={log} index={index} />)
        ) : (
          <Text variant='normal'>
            This tab will show automated system messages or activity logs.
            <br /> There are no such messages currently.
          </Text>
        )}
      </div>
    </div>
  );
}
