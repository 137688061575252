import { Dialog, DialogContent, Theme, Typography } from '@material-ui/core';
import { CircularProgress, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { FC, ReactElement, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { Button, FormGroup, StickyContent } from '../../../components/common';
import { TextFieldWrapper } from '../../../components/forms/wrappers';
import { COLORS } from '../../../theme/variables';
import { CLASS_TRACKING } from '../../../utils/tracking_class';
import { yupValidate } from '../../../utils/yup';
import { DialogTitle } from '../../common/dialog';
import { Header } from './Header';

export interface AvailablityFormValues {
  availability: string;
}

export interface Props {
  open: boolean;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  rate: number | null;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (values: AvailablityFormValues) => void;
  availability?: string;
}

interface AvailabilityFormProps {
  rate: number | null;
  onSubmit: (values: AvailablityFormValues) => void;
  availability?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
    },
  },
  title: {
    textAlign: 'center',

    '& h4': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '15px',

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
      },
    },
    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  content: {
    width: '460px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px',
    padding: '16px 32px 32px 32px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  description: {
    textAlign: 'center',
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    fontSize: 13,
  },
}));

const Label: FC = ({ children }) => (
  <Typography variant={'subtitle2'}>{children}</Typography>
);

export function Availability({
  open,
  firstName,
  lastName,
  avatar,
  rate,
  onBack,
  onClose,
  onSubmit,
  availability,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle onClose={onClose} className={classes.title}>
        <ArrowBackIcon onClick={onBack} />
        <span>Provide Your Availablity</span>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        data-testid='availablity-content'>
        <Header avatar={avatar} firstName={firstName} lastName={lastName} />
        <Typography className={classes.description}>
          In the edit box below, please either provide a link to your Calendly
          or list a few dates and times when you are available for a call.
        </Typography>
        <AvailablityForm
          availability={availability}
          rate={rate}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

const useFormStyles = makeStyles((theme) => ({
  mainFormBlock: {
    width: '100%',
  },
  actionsBlock: {
    width: '100%',
    marginTop: 20,
  },
  charge: {
    marginTop: 20,
    textAlign: 'center',
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    fontSize: 13,
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

const validationSchema = yup.object().shape({
  availability: yup.string().max(2048).required(),
});

function AvailablityForm({
  availability,
  rate,
  onSubmit,
}: AvailabilityFormProps) {
  const classes = useFormStyles();

  const intitialData: AvailablityFormValues = useMemo(
    () => ({
      availability: availability || '',
    }),
    [availability],
  );

  return (
    <Form
      validate={yupValidate(validationSchema)}
      onSubmit={onSubmit}
      initialValues={intitialData}
      keepDirtyOnReinitialize={true}
      validateOnBlur
      render={(formProps) => {
        return (
          <form
            noValidate
            data-testid='availability-form'
            className={classes.mainFormBlock}>
            <FormGroup>
              <div>
                <Label>Calendly Link or Availability</Label>
                <Field<string>
                  name='availability'
                  data-testid='availability-input'
                  component={TextFieldWrapper}
                  InputProps={{
                    inputProps: {
                      maxLength: 2048,
                      className: classes.input,
                    },
                  }}
                />
              </div>
            </FormGroup>
            {rate ? (
              <Typography className={classes.charge}>
                You will be charged ${rate} USD for this consultation
              </Typography>
            ) : null}
            <div className={classes.actionsBlock}>
              <StickyContent>
                <Button
                  fullWidth
                  data-testid='availability-submit'
                  className={CLASS_TRACKING.INTERNAL_ACTION}
                  onClick={formProps.handleSubmit}
                  disabled={formProps.submitting}>
                  {formProps.submitting ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Continue'
                  )}
                </Button>
              </StickyContent>
            </div>
          </form>
        );
      }}
    />
  );
}
