import { SvgIconComponent } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import NoteIcon from '@material-ui/icons/Note';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { KnownModule } from '../../api/user/Module';
import { Role } from '../../api/user/Role';
import { LightSvg } from '../../assets/icons';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { Group, Item, SidebarMenu } from '../common/Menu/Sidebar';

interface MenuItemType {
  id: string;
  label: string;
  icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
  module?: string;
}

const MODULES_ITEMS: MenuItemType[] = [
  {
    id: 'program-materials',
    label: 'Program Materials',
    icon: QuestionAnswerIcon,
    link: Pages.EDUCATION_LIST,
    module: 'education',
  },
  {
    id: 'reports',
    label: 'Reports',
    icon: EqualizerIcon,
    link: Pages.REPORTS_LIST,
    module: 'analytics',
  },
  // {
  //   label: 'Communications',
  //   icon: QuestionAnswerIcon,
  //   link: '#'
  // },
  // {
  //   label: 'Integrations',
  //   icon: ExtensionIcon,
  //   link: '#'
  // },
  // {
  //   label: 'Analytics',
  //   icon: EqualizerIcon,
  //   link: '#'
  // }
];

export function CoreMenu() {
  const location = useLocation();
  const { rb } = useResourceBundles();

  const { hasAccessToAction, hasModules, hasRole, hasAccessToModal } =
    useContext(UserContext);
  const rootURL = location.pathname.split('/')[1];
  const isActivePage = useCallback(
    (pageURL: string) => {
      const rootPageURL = pageURL.split('/')[1];

      return rootPageURL.includes(rootURL);
    },
    [rootURL],
  );

  const menuItems = useMemo<Item[]>(() => {
    const items = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.DASHBOARD,
        visible: hasAccessToAction('dashboard.view'),
      },
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.MENTOR_DASHBOARD,
        visible: hasRole(Role.Mentor),
      },
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.FOUNDER_DASHBOARD,
        visible: hasRole(Role.Founder),
      },
      {
        id: 'mentors',
        label: rb('mentors-u'),
        icon: PeopleIcon,
        link: Pages.ACTIVE_MENTORS,
        visible: true,
      },
      {
        id: 'ventures',
        label: 'Ventures',
        icon: BusinessIcon,
        link: hasAccessToAction('venture.filters')
          ? hasRole(Role.Mentor)
            ? `${Pages.VENTURES}?mentorFilter=my-ventures`
            : Pages.ACTIVE_VENTURES
          : Pages.VENTURES,
        visible: true,
      },
      {
        id: 'founders',
        label: 'Founders',
        icon: LightSvg,
        link: hasAccessToAction('founder.filters')
          ? Pages.ACTIVE_FOUNDERS
          : Pages.FOUNDERS,
        visible: hasAccessToAction('founder.view'),
      },
      {
        id: 'sessions',
        label: 'Sessions',
        icon: EventAvailableIcon,
        link: Pages.SESSIONS,
        visible: true,
      },
      {
        id: 'gathering',
        label: 'Gatherings',
        icon: EventIcon,
        link: Pages.GATHERINGS,
        visible: hasAccessToAction('gathering.view'),
      },
      {
        id: 'goals',
        label: 'Goals',
        icon: TrendingUpIcon,
        link: Pages.GOALS,
        visible:
          hasAccessToAction('goal.view') && hasAccessToModal(KnownModule.Goals),
      },
      {
        id: 'personalNotes',
        label: 'Notes',
        icon: NoteIcon,
        link: Pages.PERSONAL_NOTES,
        visible: hasAccessToAction('personalNotes.view'),
      },
    ];

    return items
      .filter((v) => v.visible)
      .map((i) => ({
        ...i,
        active: isActivePage(i.link),
      }));
  }, [hasAccessToAction, hasAccessToModal, hasRole, isActivePage, rb]);

  const modulesItems = useMemo<Group[]>(() => {
    const items: MenuItemType[] = [];
    if (hasAccessToAction('modules.programMaterials.view')) {
      items.push(MODULES_ITEMS[0]);
    }
    if (hasAccessToAction('modules.reports.view')) {
      items.push(MODULES_ITEMS[1]);
    }
    return hasModules && items.length
      ? [
          {
            label: 'Modules',
            items: items.map((i) => ({
              ...i,
              active: isActivePage(i.link),
            })),
          },
        ]
      : [];
  }, [hasAccessToAction, isActivePage, hasModules]);

  return <SidebarMenu items={menuItems} groups={modulesItems} />;
}
