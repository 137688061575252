import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import mentorsAPI, { MentorId } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import { Button, FormGroup, Modal } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { TestId } from '../Testing/TestId';
import { FormRating, TextFieldWysiwyg } from '../forms/wrappers';

interface FormValues {
  rating: string;
  comment: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.comment) {
    errors.comment = 'Required';
  }
  if (!values.rating) {
    errors.rating = 'Required';
  }
  return errors;
};

const initialValues = { comment: '' };

interface WriteReviewProps {
  mentorName: string;
  ventureId: VentureId;
  mentorId: MentorId;
}

interface WriteReviewButtonProps extends WriteReviewProps {
  title: string;
  caption?: string;
}

interface WriteReviewFormProps extends WriteReviewProps {
  handleClose: Function;
}

const useTitleStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: 15,
    marginTop: 20,
  },
  subtitle: {
    margin: '20px 0 10px',
  },
});

function WriteReviewButton({
  title,
  ventureId,
  caption,
  mentorName,
  mentorId,
}: WriteReviewButtonProps) {
  return (
    <Modal
      title={title}
      caption={caption}
      contentRenderer={({ handleClose }) => (
        <WriteReviewForm
          mentorName={mentorName}
          handleClose={handleClose}
          ventureId={ventureId}
          mentorId={mentorId}
        />
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          onClick={onClick}
          className={CLASS_TRACKING.ENTITY_ACTION}
          data-testid='write-review-button'
          variant='outlined'
          color='primary'>
          Write a review
        </Button>
      )}
      width={720}
    />
  );
}

function WriteReviewForm({
  handleClose,
  mentorName,
  ventureId,
  mentorId,
}: WriteReviewFormProps) {
  const classes = useTitleStyles();
  const { rb } = useResourceBundles();

  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (formValues: FormValues) => {
    try {
      setLoading(true);
      await mentorsAPI.rateMentor(mentorId, ventureId, formValues);
      setLoading(false);
      handleClose();
      enqueueSnackbar(`${rb('mentor-u')} request sent successfully`, {
        variant: 'success',
      });
    } catch (error: any) {
      setLoading(false);
      const messageError = `Unfortunately, an error occurred when sending the ${rb(
        'mentor',
      )} request.\n Details: ${
        error?.response?.data?.message || 'Internal server error'
      }`;
      enqueueSnackbar(messageError, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formProps) => (
          <form noValidate>
            <Typography className={classes.subtitle} variant='h5'>
              How would you rate {mentorName} as a {rb('mentor')} on your team?
            </Typography>
            <FormGroup>
              <Field<number>
                name='rating'
                component={FormRating}
                testid='mentor-review-rating'
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name='comment'
                component={TextFieldWysiwyg}
                placeholder='Comment'
                testid='mentor-review-comment'
                maxLength={2048}
              />
            </FormGroup>
            <div className={classes.contentButton}>
              <Button onClick={() => handleClose()} variant='outlined'>
                Cancel
              </Button>
              <TestId testId='mentor-review-submit-button'>
                <Button
                  onClick={formProps.handleSubmit}
                  disabled={isLoading || !formProps.valid}>
                  {isLoading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </TestId>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default WriteReviewButton;
