import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import authAPI, { RegisterUser } from '../api/auth';
import { Text } from '../components/common';
import { ChangePasswordForm } from '../components/forms/change-password-form';
import { Values as ReportValues } from '../components/forms/registration-form';
import { Pages } from '../router/constants';
import { COLORS } from '../theme/variables';
import { isPassword } from '../utils/String/Password';

interface UserData {
  email: string;
  userTenantId: string;
  inviteCode: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25,
  },
  block: {
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
  },
  logo: {
    width: 300,
  },
  containerSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    height: '100vh',

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
    boxSizing: 'border-box',
    padding: 20,
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 20,
  },
  successText: {
    textAlign: 'center',
  },
}));

const getDataParseRegistration = (
  values: ReportValues,
  dataUser: UserData,
): RegisterUser => {
  return {
    ...dataUser,
    password: values.password,
  };
};

function ChangePasswordPage() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [password, setPassword] = useState<string>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>();

  const getDataParse = (value: string): UserData => {
    const parseData = value.split('|');
    setEmail(parseData[0]);
    return {
      email: parseData[0],
      userTenantId: parseData[1],
      inviteCode: parseData[2],
    };
  };

  const dataUser = useMemo(() => {
    const currentKeyUrl = location.search.replace('?key=', '');
    return getDataParse(atob(currentKeyUrl)) || {};
  }, [location]);

  const handleLogin = async () => {
    if (!password || !isPassword(password) || password !== passwordRepeat) {
      return;
    }

    try {
      setError('');
      setLoading(true);
      const parsData = getDataParseRegistration(
        {
          password,
          passwordConfirm: passwordRepeat,
          email,
        },
        dataUser,
      );
      await authAPI.registerUser(parsData);
      setIsSuccess(true);
      const timeRedirect = setTimeout(() => {
        history.push(Pages.LOGIN);
        clearTimeout(timeRedirect);
      }, 3000);
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <div
        data-testid='register-page-success'
        className={classes.containerSuccess}>
        <div className={classes.successBlock}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Your password has been successfully changed!
              <br />
              You will be forwarded to login page now.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid='change-password-page' className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      <div className={classes.block}>
        <ChangePasswordForm
          error={error}
          loading={loading}
          onSubmit={handleLogin}
          email={email}
          password={{
            value: password ?? '',
            onChange: setPassword,
            error: password !== undefined && !isPassword(password),
            onBlur: () => {},
          }}
          passwordRepeat={{
            value: passwordRepeat ?? '',
            onChange: setPasswordRepeat,
            error: passwordRepeat !== undefined && password !== passwordRepeat,
            onBlur: () => {},
          }}
        />
      </div>
    </div>
  );
}

export default ChangePasswordPage;
