import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../theme/variables';

export function ProgressBar({
  value,
  label,
  score,
}: {
  value: number;
  label?: string | number;
  score: 'low' | 'medium' | 'high';
}) {
  const c = useStyles();

  return (
    <div className={c.progress}>
      <div
        className={c.progressValue}
        style={{ minWidth: `${value}%`, background: getColor(score) }}>
        {label}
      </div>
    </div>
  );
}

function getColor(score: 'low' | 'medium' | 'high'): string {
  const colors = {
    low: COLORS.COLOR_RED_BASE,
    medium: COLORS.COLOR_ADDITIONAL_ORANGE,
    high: COLORS.COLOR_GREEN_BASE,
  };

  return colors[score];
}

const useStyles = makeStyles(() => ({
  content: {
    display: 'contents',
    '&:last-child > *': {
      borderBottom: 'none',
    },
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 28,
    justifyContent: 'space-between',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  tHead: {
    lineHeight: '1.2rem',
  },
  tCell: {
    borderRight: `1px dotted ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    borderBottom: `1px dotted ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    padding: 10,

    '&:first-child': {
      paddingLeft: 0,
    },

    '&:last-child': {
      borderRight: 'none',
      paddingRight: 0,
    },
  },
  name: {},
  progress: {
    width: '100%',
    boxShadow: `0 0 0 1px ${COLORS.COLOR_GRAY_LIGHTENED_20} inset`,
    background: COLORS.COLOR_GRAY_LIGHTENED_45,
    borderRadius: 10,
    overflow: 'hidden',
  },
  progressValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    height: '20px',
    borderRadius: 10,
    width: '20px',
    padding: '0 5px',
    lineHeight: '1.2rem',
    boxSizing: 'border-box',
    fontSize: 10,
    fontWeight: 700,
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  pageButton: {
    width: 16,
    height: 16,
    borderRadius: 10,
    border: `2px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    cursor: 'pointer',
    boxSizing: 'border-box',
    transition: 'all 0.2s ease-in-out',
    background: 'transparent',

    '&[aria-checked=true]': {
      background: COLORS.COLOR_BLUE_BASE,
      border: 'none',
    },
  },
}));
