import { Button as MaterialButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ExportButton } from '../components/common';
import Actions from '../components/layout/actions';
import BaseLayout from '../components/layout/base-layout';
import VenturesTable from '../components/tables/ventures-table';
import { UserContext } from '../contexts/user-context';
import { Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { isMobile } from '../utils/functions';
import { CLASS_TRACKING } from '../utils/tracking_class';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: '0 20px !important',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
      marginBottom: 25,
    },
    '& h2': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
        lineHeight: '40px',
      },
    },
  },
}));

function VenturesPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);

  return (
    <BaseLayout user={user} fullWidth>
      <div className={classes.header}>
        <Typography variant='h2'>Ventures</Typography>
        <Actions
          actions={[
            {
              key: 'new-venture',
              label: 'New Venture',
              hidden: !hasAccessToAction('venture.create'),
              component: (
                <MaterialButton
                  className={CLASS_TRACKING.ENTITY_ACTION}
                  component={Link}
                  to={Pages.NEW_VENTURE}
                  data-testid='button-new-venture'
                  startIcon={<AddIcon />}
                  variant='contained'
                  color='primary'>
                  Venture
                </MaterialButton>
              ),
            },
            {
              key: 'export',
              label: 'Export',
              hidden: !hasAccessToAction('venture.export') || isMobile(),
              component: <ExportButton alias='venture' />,
            },
          ]}
        />
      </div>
      <div>
        <VenturesTable />
      </div>
    </BaseLayout>
  );
}

export default VenturesPage;
