import * as CommunityMembers from '../../../api/CommunityMembers';
import {
  CommunityMember,
  CommunityMemberUpdate,
} from '../../../api/CommunityMembers/types/CommunityMember';
import { LoadSuccess, UpdateSuccess } from './types/Actions';
import { Filter } from './types/Filter';

function getFetcher(filter: Filter): (p: number) => Promise<CommunityMember[]> {
  switch (filter.type) {
    case 'none':
      return (p: number) => CommunityMembers.getByPage(p);
    case 'status':
      return (p: number) =>
        filter.value
          ? CommunityMembers.getByStatus(filter.value, p)
          : CommunityMembers.getByPage(p);
    case 'search':
      return (p: number) => CommunityMembers.search(filter.value);
  }
}

export function get(
  page: number,
  filter: Filter,
): Promise<LoadSuccess['payload']> {
  const fetcher = getFetcher(filter);
  return Promise.all([fetcher(page - 1), fetcher(page)])
    .then(([items, count]) => ({ items, next: !!count.length }))
    .then(async ({ next, items }) => {
      return {
        items: items,
        next: next,
      };
    });
}

export function update(
  patches: CommunityMemberUpdate[],
  page: number,
  filter: Filter,
): Promise<UpdateSuccess['payload']> {
  return Promise.all(patches.map(CommunityMembers.update)).then(() =>
    get(page, filter),
  );
}
