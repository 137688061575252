import { Theme, Typography } from '@material-ui/core';
import { AccessTime, EventAvailable } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { COLORS } from '../../../theme/variables';
import { Avatar } from '../../common';

export interface Props {
  firstName: string;
  lastName: string;
  avatar?: string;
  date?: Date;
  time?: [Date, Date];
}

export function Header({
  avatar,
  firstName,
  lastName,
  date,
  time,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Avatar
        name={`${firstName[0]}${lastName[0]}`}
        src={avatar}
        size={'80'}
        className={classes.avatar}
      />
      <Typography className={classes.name} variant={'subtitle2'}>
        Appointment with {firstName} {lastName}
      </Typography>
      {date && (
        <>
          <Typography className={classes.name}>
            In your local timezone{' '}
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </Typography>
          <div className={classes.timeIcons}>
            <span className={classes.time}>
              <EventAvailable />
              {format(date, 'LLLL dd, Y')}
            </span>
            {time ? (
              <span className={classes.time}>
                <AccessTime />
                {format(time[0], 'hh:mm a')}-{format(time[1], 'hh:mm a')}
              </span>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    alignSelf: 'center',
  },
  name: {
    textAlign: 'center',
    fontSize: 14,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '10px',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    gap: 10,
    fontSize: 12,

    '& .MuiSvgIcon-root': {
      color: COLORS.COLOR_GRAY_BASE,
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  timeIcons: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 26,
  },
}));
