import { axiosRequest } from '../instance';
import { DateFormat } from './types/DateFormat';
import { Report } from './types/Report';
import { Statistics } from './types/Statistics';

export function statistics(): Promise<Statistics> {
  return axiosRequest
    .get<Statistics>({ url: '/officehours/statistics', credentials: true })
    .then((r) => r.data);
}

export function report(start: DateFormat, end: DateFormat): Promise<Report> {
  return axiosRequest
    .get<Report>({
      url: `/reports/officehours?startDate=${start}&endDate=${end}`,
      credentials: true,
    })
    .then((r) => r.data);
}
