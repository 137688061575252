import { PropsWithChildren, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      margin: '0 60px',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      padding: '50px 0',
    },
  },
  block: {
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
}));

export function CenteredContent({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.block}>{children}</div>
    </div>
  );
}
