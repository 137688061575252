import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { Button, FormButtons } from '.';
import venturesAPI, { VentureDetails } from '../../api/ventures';
import { Status, Venture } from '../../api/ventures/types/Venture';
import { massAsyncRequest } from '../../utils/api';

interface Props {
  onCancel: () => any;
  selectedValue?: Venture[];
  status: Status;
  updateTable: () => void;
}

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

const validateVenture = (venture: Venture): VentureDetails => {
  return {
    status: venture.status,
    ventureName: venture.ventureName,
    url: venture.url,
    dateOfEnrollment: venture.dateOfEnrollment,
    dateOfCreation: venture.dateOfCreation,
    country: venture.country,
    state: venture.state,
    city: venture.city,
    zip: venture.zip,
    address: venture.address,
    numOfEmployees: venture.numOfEmployees,
    description: venture.description,
    isTech: venture.isTech,
    dateOfExit: venture.dateOfExit,
    exitReason: venture.exitReason,
    cohortId: venture.cohortId,
  };
};

function ButtonsChangeStatusVenture({
  onCancel,
  selectedValue,
  status,
  updateTable,
}: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!selectedValue) return undefined;
    setLoading(true);
    const changeStatus = selectedValue.map((value: Venture) => {
      return () =>
        venturesAPI.update(value.id, {
          ...validateVenture(value),
          status: status,
        });
    });
    await massAsyncRequest(changeStatus);
    setLoading(false);
    onCancel();
    updateTable();
  };

  return (
    <FormButtons className={classes.formButtons}>
      <Button onClick={onCancel} variant='outlined'>
        Cancel
      </Button>
      <Button disabled={loading} type='submit' onClick={handleSubmit}>
        {loading ? <CircularProgress size={24} color='inherit' /> : 'Yes'}
      </Button>
    </FormButtons>
  );
}

export default ButtonsChangeStatusVenture;
