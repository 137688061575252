import { ReactElement, useState } from 'react';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import { AppsMenu } from '../../../../assets/icons';
import { COLORS } from '../../../../theme/variables';
import { Props as ItemProps } from './Item';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  main: {
    position: 'relative',
    width: '56px',
    height: '56px',
    zIndex: 1,
  },
  active: {},
  appIcon: {
    width: '100%',
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '$main:hover &, $active &': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_45,
    },
  },
  dropdown: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    paddingTop: '100%',
    backgroundColor: '#fff',
    borderRadius: '0 0 4px 0',
    boxShadow:
      '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)',

    '$active  &': {
      display: 'block',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    padding: '20px 10px',
  },
}));

export interface Props {
  children: Array<ReactElement<ItemProps>>;
}

export function Header({ children }: Props): ReactElement<Props> {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classNames(classes.main, { [classes.active]: open })}>
      <span className={classes.appIcon} onClick={() => setOpen(!open)}>
        <AppsMenu />
      </span>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={classes.dropdown}>
            {children}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
