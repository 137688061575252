import { QuestionId } from '../../api/tenants/QuestionTemplates/Question';
import * as Description from './Description';
import { AddDraft } from './AddQuestion';

export interface EditDraft extends AddDraft {
  id: QuestionId;
}

export interface EditValid extends EditDraft {
  description: Description.Description;
}

export const isValid = (v: EditDraft): v is EditValid =>
  Description.is(v.description);
