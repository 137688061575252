import { Advisor } from '../../../../api/Advisors/types/Advisor';
import { Status } from '../../../../api/Advisors/types/Status';
import { Item } from './Item';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    items: Advisor[];
    next: boolean;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: Item['id'];
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload,
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: boolean;
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload'],
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload,
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
  payload: {
    items: Advisor[];
    next: boolean;
  };
}

export const removeSuccess = (
  payload: RemoveSuccess['payload'],
): RemoveSuccess => ({
  type: 'RemoveSuccess',
  payload,
});
// endregion

// region RemoveError
export interface RemoveError {
  type: 'RemoveError';
  payload: string;
}

export const removeError = (payload: RemoveError['payload']): RemoveError => ({
  type: 'RemoveError',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: Item['id'];
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Deselect
export interface Deselect {
  type: 'Deselect';
  payload: Item['id'];
}

export const deselect = (payload: Deselect['payload']): Deselect => ({
  type: 'Deselect',
  payload,
});
// endregion

// region ToggleAll
export interface ToggleAll {
  type: 'ToggleAll';
}

export const toggleAll = (): ToggleAll => ({
  type: 'ToggleAll',
});
// endregion

// region ChangeStatus
export interface ChangeStatus {
  type: 'ChangeStatus';
  payload: Status;
}

export const changeStatus = (
  payload: ChangeStatus['payload'],
): ChangeStatus => ({
  type: 'ChangeStatus',
  payload,
});
// endregion

// region UpdateConfirmation
export interface UpdateConfirmation {
  type: 'UpdateConfirmation';
  payload: boolean;
}

export const updateConfirmation = (
  payload: UpdateConfirmation['payload'],
): UpdateConfirmation => ({
  type: 'UpdateConfirmation',
  payload,
});
// endregion

// region UpdateSuccess
export interface UpdateSuccess {
  type: 'UpdateSuccess';
  payload: {
    items: Advisor[];
    next: boolean;
  };
}

export const updateSuccess = (
  payload: UpdateSuccess['payload'],
): UpdateSuccess => ({
  type: 'UpdateSuccess',
  payload,
});
// endregion

// region UpdateError
export interface UpdateError {
  type: 'UpdateError';
  payload: string;
}

export const updateError = (payload: UpdateError['payload']): UpdateError => ({
  type: 'UpdateError',
  payload,
});
// endregion

// region NextPage
export interface NextPage {
  type: 'NextPage';
}

export const nextPage = (): NextPage => ({
  type: 'NextPage',
});
// endregion

// region PrevPage
export interface PrevPage {
  type: 'PrevPage';
}

export const prevPage = (): PrevPage => ({
  type: 'PrevPage',
});
// endregion

// region Search
export interface Search {
  type: 'Search';
  payload: string;
}

export const search = (payload: Search['payload']): Search => ({
  type: 'Search',
  payload,
});
// endregion

// region SetStatus
export interface SetStatus {
  type: 'SetStatus';
  payload: Status;
}

export const setStatus = (payload: SetStatus['payload']): SetStatus => ({
  type: 'SetStatus',
  payload,
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: 'ClearFilters';
}

export const clearFilters = (): ClearFilters => ({
  type: 'ClearFilters',
});
// endregion

export type PublicActions =
  | Toggle
  | ToggleAll
  | Remove
  | RemoveConfirmation
  | ChangeStatus
  | NextPage
  | PrevPage
  | UpdateConfirmation
  | Search
  | SetStatus
  | ClearFilters;

export type Actions =
  | LoadError
  | LoadSuccess
  | UpdateSuccess
  | UpdateError
  | RemoveConfirmation
  | RemoveSuccess
  | RemoveError
  | PublicActions;
