import { makeStyles, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CopyIcon } from '../../assets/icons';
import { TestId } from '../../components/Testing/TestId';
import { Text, Button } from '../../components/common';
import { ActionsDialog } from '../../components/common/Confirmation/ActionsDialog';
import { COLORS } from '../../theme/variables';

interface AddIntentModalProps {
  link: string;
  isOpen: boolean;
  onClose: () => any;
}

const useStyles = makeStyles(() => ({
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',
    marginLeft: 8,

    '&:hover': {
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE,

    '&:hover': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  linkLabel: {
    color: COLORS.COLOR_BLUE_BASE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export function AddIntentModal({ link, isOpen, onClose }: AddIntentModalProps) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    timeout.current = setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return createPortal(
    <ActionsDialog
      open={isOpen}
      onClose={onClose}
      title={'Payment'}
      actions={
        <TestId testId={'add-intent-success-button'}>
          <Button variant={'contained'} onClick={onClose}>
            Ok
          </Button>
        </TestId>
      }>
      <Text variant={'normal'}>
        A new payment intent was created with Stripe. Please send the link
        provided below to Appointment Requestor to make a payment.
      </Text>
      <div className={classes.linkContainer} onClick={() => handleCopy(link)}>
        <Text variant={'normal'} className={classes.linkLabel}>
          {link}
        </Text>
        {copied ? (
          <CheckCircleIcon className={cn(classes.icon, classes.successIcon)} />
        ) : (
          <Tooltip title='Copy link'>
            <span data-testid={'button-link-copy'}>
              <CopyIcon className={classes.icon} />
            </span>
          </Tooltip>
        )}
      </div>
    </ActionsDialog>,
    document.body,
  );
}
