import { Advisor, AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { TenantId } from '../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { CustomPeriod as CP } from './CustomPeriod';
import { EditingItem } from './EditingItem';
import { ExistingItem, Item, ValidItem } from './Item';
import { ItemId } from './ItemId';

// region Loading
export interface LoadingPayload {
  tenantId: TenantId;
  timeZone: TenantTimeZone;
  advisorId: AdvisorId;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  advisor: Advisor;
  items: ExistingItem[];
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Edited
export interface EditedPayload extends Omit<ReadyPayload, 'items'> {
  items: Item[];
}

export interface Edited {
  type: 'Edited';
  payload: EditedPayload;
}

export const edited = (payload: Edited['payload']): Edited => ({
  type: 'Edited',
  payload,
});
// endregion

// region CustomPeriod
export interface CustomPeriodPayload extends EditedPayload {
  period: CP;
  id: ItemId;
}

export interface CustomPeriod {
  type: 'CustomPeriod';
  payload: CustomPeriodPayload;
}

export const customPeriod = (
  payload: CustomPeriod['payload'],
): CustomPeriod => ({
  type: 'CustomPeriod',
  payload,
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmationPayload extends EditedPayload {
  item: EditingItem;
}

export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: RemoveConfirmationPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload'],
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload,
});
// endregion

// region Saving
export interface SavingPayload extends EditedPayload {
  items: ValidItem[];
}

export interface Saving {
  type: 'Saving';
  payload: SavingPayload;
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveErrorPayload extends SavingPayload {
  message: string;
}

export interface SaveError {
  type: 'SaveError';
  payload: SaveErrorPayload;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | Edited
  | CustomPeriod
  | RemoveConfirmation
  | Saving
  | SaveError;

export type Editable = Ready | SaveError | Edited;

export function isReadyToEdit(s: State): s is Editable {
  switch (s.type) {
    case 'LoadError':
    case 'Saving':
    case 'Loading':
    case 'RemoveConfirmation':
    case 'CustomPeriod':
      return false;
    case 'Edited':
    case 'SaveError':
    case 'Ready':
      return true;
  }
}

export type Loaded = Exclude<State, Loading | LoadError>;

export function isLoaded(s: State): s is Loaded {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'SaveError':
    case 'RemoveConfirmation':
    case 'Edited':
    case 'Ready':
    case 'CustomPeriod':
    case 'Saving':
      return true;
  }
}
