import { CommunityMemberId } from './types/CommunityMember';
import { axiosRequest } from '../instance';
import { AxiosError } from 'axios';
import { Create, Note, NoteId } from './types/Note';

export function getAll(id: CommunityMemberId): Promise<Note[]> {
  return axiosRequest
    .get<Note[]>({
      url: `/communityMembers/${id}/notes`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function create(data: Create): Promise<Note> {
  return axiosRequest
    .post<Note>({
      url: `/communityMembers/notes`,
      data,
      credentials: true,
    })
    .then((i) => i.data);
}

export function update(data: Note): Promise<Note> {
  return axiosRequest
    .put<Note>({ url: `/communityMembers/notes`, data, credentials: true })
    .then((i) => i.data);
}

export function remove(memberId: CommunityMemberId, id: NoteId): Promise<Note> {
  return axiosRequest
    .delete<Note>({
      url: `/communityMembers/${memberId}/notes/${id}`,
      credentials: true,
    })
    .then((i) => i.data);
}
