import { Button, Modal } from '../../components/common';
import { makeStyles } from '@material-ui/styles';
import Text from './text';
import { Link } from '@material-ui/core';

interface Props {
  errors: object;
}

const useTitleStyles = makeStyles({
  container: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  content: {
    width: '100%'
  },
  contentButton: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

function SeeErrorsButton({ errors }: Props) {
  const classes = useTitleStyles();

  return (
    <Modal
      title='Errors'
      contentRenderer={({ handleClose }) => (
        <div className={classes.container}>
          <div className={classes.content}>
            {Object.entries(errors).map((value) => {
              return (
                <Text variant='normal'>
                  <strong>-{value[0]}</strong>: {value[1]}
                  <br />
                </Text>
              );
            })}
            <div className={classes.contentButton}>
              <Button onClick={handleClose} variant='outlined'>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      buttonRenderer={({ onClick }) => (
        <Text variant='normal'>
          <Link onClick={onClick}>See errors</Link>
        </Text>
      )}
      width={1000}
    />
  );
}

export default SeeErrorsButton;
