import { AxiosError } from 'axios';
import { axiosRequest } from '../instance';
import { Cohort, CohortCreate, CohortUpdate } from './types/Cohort';

export function getActiveCohorts(): Promise<Cohort[]> {
  return axiosRequest
    .get<Cohort[]>({
      url: '/cohorts',
      credentials: true,
    })
    .then((r) => r.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404 || e.response?.status === 401) {
        return [];
      }
      throw e;
    });
}

export function createCohort(cohort: CohortCreate): Promise<Cohort> {
  return axiosRequest
    .post<Cohort>({
      url: '/cohorts',
      credentials: true,
      data: cohort,
    })
    .then((r) => r.data);
}

export function updateCohort(cohort: CohortUpdate): Promise<Cohort> {
  return axiosRequest
    .put<Cohort>({
      url: '/cohorts',
      credentials: true,
      data: cohort,
    })
    .then((r) => r.data);
}
