import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { isMobile } from '../../utils/functions';

export interface Action {
  key: string;
  label: string;
  component: ReactNode;
  hidden?: boolean;
}

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
  hidden: {
    position: 'absolute',
    top: -9999,
    left: -9999,
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
});

const MobileActions: FC<{ actions: Action[] }> = ({ actions }) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const actionsRef = useRef<HTMLDivElement[]>([]);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <IconButton onClick={() => setShowMenu(true)} ref={anchorRef}>
        <MoreVertIcon />
      </IconButton>
      <div className={classes.hidden} id='actions-container'>
        {actions.map((action) => {
          return (
            <div
              key={action.key}
              id={`action-${action.key}`}
              ref={(el) => el && actionsRef.current?.push(el)}>
              {action.component}
            </div>
          );
        })}
      </div>
      {anchorRef.current && (
        <Menu
          id='actions-menu'
          anchorEl={anchorRef.current}
          keepMounted
          open={showMenu}
          onClose={handleCloseMenu}>
          {actions.map((action) => {
            return (
              <MenuItem
                onClick={() => {
                  const target = actionsRef.current.find(
                    (ref) => ref.id === `action-${action.key}`,
                  ) as HTMLDivElement;
                  const element = target.children[0] as HTMLElement;

                  if (!element) {
                    return;
                  }

                  const button = element.querySelector('button');

                  if (button) {
                    button.click();
                  } else {
                    element.click();
                  }

                  handleCloseMenu();
                }}
                key={action.key}>
                {action.label}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

const DesktopActions: FC<{ actions: Action[] }> = ({ actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      {actions.map((action) => action.component)}
    </div>
  );
};

const Actions: FC<{ actions: Action[] }> = ({ actions: initialActions }) => {
  const actions = useMemo(
    () => initialActions.filter((action) => !action.hidden),
    [initialActions],
  );

  if (!actions.length) {
    return null;
  }

  return isMobile() ? (
    <MobileActions actions={actions} />
  ) : (
    <DesktopActions actions={actions} />
  );
};

export default Actions;
