import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { BehaviorSubject, EMPTY, forkJoin, from, of, timer } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators';
import * as Appointments from '../../../../../api/Appointments';
import authAPI from '../../../../../api/auth';
import { fromDate } from '../../../../../api/types/TenantRFCDate';
import { useAuth } from '../../../../../hooks';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';

export function useBook(): [State.State, Dispatch<Actions.Actions>] {
  const { tokenData, login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    reducer,
    State.idle({
      user: tokenData
        ? { type: 'LoggedIn', tenantId: tokenData.tenantId }
        : { type: 'Guest' },
    }),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const state$ = useMemo(() => new BehaviorSubject(state), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => state$.next(state), [state]);

  useEffect(() => {
    const timeSlots$ = state$
      .pipe(
        filter((s): s is State.BookingLoading => s.type === 'BookingLoading'),
        switchMap(({ payload: { tenantId, advisor } }) =>
          forkJoin({
            response: from(
              Appointments.getTimeslots(tenantId, advisor.id),
            ).pipe(
              map(Actions.bookFetchSuccess),
              catchError((e: AxiosError) =>
                of(Actions.bookFetchError(e.response?.data.message)),
              ),
            ),
            timer: timer(700),
          }).pipe(map((r) => r.response)),
        ),
      )
      .subscribe(dispatch);

    const save$ = state$
      .pipe(
        filter(State.isSaving),
        map((s) => {
          switch (s.type) {
            case 'SavingGuest':
              return Appointments.create(s.payload.tenantId, {
                start:
                  'start' in s.payload && s.payload.start
                    ? fromDate(s.payload.timeZone, s.payload.start)
                    : undefined,
                end:
                  'end' in s.payload && s.payload.end
                    ? fromDate(s.payload.timeZone, s.payload.end)
                    : undefined,
                requestorTimeSlots:
                  'availability' in s.payload && s.payload.availability
                    ? s.payload.availability
                    : undefined,
                topic: s.payload.topic.value,
                agenda: s.payload.agenda.value,
                advisorId: s.payload.advisor.id,
                tenantId: s.payload.tenantId,
                requestorFullName: s.payload.name.value,
                requestorEmail: s.payload.email.value,
              });
            case 'SavingLoggedIn':
              return Appointments.createPrivate({
                start:
                  'start' in s.payload && s.payload.start
                    ? fromDate(s.payload.timeZone, s.payload.start)
                    : undefined,
                end:
                  'end' in s.payload && s.payload.end
                    ? fromDate(s.payload.timeZone, s.payload.end)
                    : undefined,
                requestorTimeSlots:
                  'availability' in s.payload && s.payload.availability
                    ? s.payload.availability
                    : undefined,
                topic: s.payload.topic.value,
                agenda: s.payload.agenda.value,
                advisorId: s.payload.advisor.id,
                tenantId: s.payload.tenantId,
              });
          }
        }),
        switchMap((p) =>
          from(p).pipe(
            map((result) => {
              if (result.paymentUrl && result.responseId) {
                return Actions.saveSuccessWithPayment({
                  paymentUrl: result.paymentUrl,
                  responseId: result.responseId,
                });
              }
              return Actions.saveSuccess();
            }),
            catchError(() =>
              of(Actions.saveError('Sorry, something went wrong!')),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    const login$ = state$
      .pipe(
        filter((s): s is State.LoggingIn => s.type === 'LoggingIn'),
        distinctUntilKeyChanged('type'),
        switchMap((s) =>
          from(
            login({
              password: s.payload.password.value,
              username: s.payload.username.value,
            }),
          ).pipe(switchMap(() => EMPTY)),
        ),
        catchError((e: AxiosError) =>
          of(Actions.loginError(e.response?.data.message)),
        ),
      )
      .subscribe(dispatch);

    const reset$ = state$
      .pipe(
        filter((s): s is State.Resetting => s.type === 'Resetting'),
        distinctUntilKeyChanged('type'),
        switchMap((s) =>
          from(authAPI.resetPassword(s.payload.username.value)).pipe(
            mapTo(Actions.resetSuccess()),
            catchError((e: AxiosError) =>
              of(Actions.resetError(e.response?.data.message)),
            ),
          ),
        ),
        catchError((e: AxiosError) =>
          of(Actions.loginError(e.response?.data.message)),
        ),
      )
      .subscribe(dispatch);

    const cancelPayment$ = state$
      .pipe(
        filter(
          (s): s is State.CancelingPayment => s.type === 'CancelingPayment',
        ),
        switchMap(
          ({
            payload: {
              appointment: { responseId },
            },
          }) =>
            from(Appointments.cancelPayment(responseId)).pipe(
              map(Actions.cancel),
              catchError(() => of(Actions.cancel())),
            ),
        ),
      )
      .subscribe(dispatch);

    const error$ = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter(State.isError),
        map((s) => s.payload.message),
      )
      .subscribe((m) =>
        enqueueSnackbar(
          m || (
            <span>
              Ouch... We hit a snag... Please try one more time later or just
              drop a note with a problem description to{' '}
              <a href={'mailto:support@tractionfive.com'}>
                support@tractionfive.com
              </a>{' '}
              and we will try to address it
            </span>
          ),
          { variant: 'error' },
        ),
      );

    return () => {
      timeSlots$.unsubscribe();
      save$.unsubscribe();
      login$.unsubscribe();
      reset$.unsubscribe();
      error$.unsubscribe();
      cancelPayment$.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenData) {
      dispatch(
        Actions.userLoggedIn({
          type: 'LoggedIn',
          tenantId: tokenData.tenantId,
        }),
      );
    } else {
      dispatch(Actions.userLoggedOut());
    }
  }, [tokenData]);

  return [state, dispatch];
}
