import {
  CircularProgress,
  makeStyles,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { MentorId } from '../../api/mentors';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { COLORS } from '../../theme/variables';
import { Text } from '../common';

interface VentureTableInfoProps {
  mentorId: MentorId;
  initialVentures?: Venture[];
  onLoaded?: (ventures: Venture[]) => any;
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    width: 320,
    boxSizing: 'border-box',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    minWidth: 20,
  },
  paragraph: {
    textAlign: 'left',

    '& + &': {
      marginTop: 12,
    },
  },
});

const STooltip = withStyles(() => ({
  tooltip: {
    width: 300,
    padding: 16,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
    boxShadow:
      '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
  },
}))(Tooltip);

function VentureTableInfoTooltip({
  mentorId,
  initialVentures,
  onLoaded,
}: VentureTableInfoProps) {
  const classes = useStyles();
  const [ventures, setVentures] = useState<Venture[]>(initialVentures || []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleOpenInfo = async () => {
      if (initialVentures?.length) {
        return;
      }

      try {
        setIsLoading(true);
        const loadedVentures = await venturesAPI.getVenturesDetailsByMentorId(
          mentorId,
        );
        setVentures(loadedVentures);
        onLoaded && onLoaded(loadedVentures);
        setIsLoading(false);
      } catch (e: any) {
        console.log('error handleOpenInfo', e);
      }
    };

    handleOpenInfo();
  }, [initialVentures?.length, mentorId, onLoaded]);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : (
        !!ventures.length && (
          <Text variant='normal2'>
            {ventures.map((venture) => (
              <p key={venture.id} className={classes.paragraph}>
                {venture.ventureName}
              </p>
            ))}
          </Text>
        )
      )}
    </div>
  );
}

function VentureTableInfo({
  mentorId,
  initialVentures,
  onLoaded = () => {},
  children,
}: VentureTableInfoProps) {
  const classes = useStyles();

  return (
    <STooltip
      title={
        <VentureTableInfoTooltip
          mentorId={mentorId}
          initialVentures={initialVentures}
          onLoaded={onLoaded}
        />
      }>
      <div className={classes.wrapper}>{children}</div>
    </STooltip>
  );
}

export default VentureTableInfo;
