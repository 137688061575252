import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState, useRef, useCallback } from 'react';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { GatheringContext } from '../../contexts/gathering-context';
import { useParams } from 'react-router';
import GuestsTable from '../../components/gathering/guests-table';
import { Button, Modal, SnackMessage, Text } from '../../components/common';
import InviteGatheringGuests, {
  SumbitGuestForm
} from '../../components/forms/gathering-guests';
import GatheringModal, {
  RadioValue
} from '../../components/gathering/gathering-modal';
import { makeStyles } from '@material-ui/core';

interface PropsButtoAdd {
  guestsList: string[];
  loading: boolean;
  handleSubmitForm: (value: SumbitGuestForm) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20
  },
  button: {
    marginTop: 15
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 599
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
    rowGap: 15
  }
}));

const ButtonAddGuest = ({
  guestsList,
  loading,
  handleSubmitForm
}: PropsButtoAdd) => {
  const classes = useStyles();

  return (
    <Modal
      title='Add Guest'
      contentRenderer={({ handleClose }) => (
        <InviteGatheringGuests
          guestsList={guestsList}
          loading={loading}
          onSuccess={handleSubmitForm}
          onCancel={handleClose}
        />
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          className={classes.button}
          onClick={onClick}
          data-testid='mentor-assigments-button'>
          Add Guest
        </Button>
      )}
      width={500}
    />
  );
};

const GatheringMentorsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { gathering, updateGathering, loadGathering, loadGroupList } =
    useContext(GatheringContext);
  const { enqueueSnackbar } = useSnackbar();
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const [openModal, setOpenModal] = useState(false);
  const currentFormValue = useRef<SumbitGuestForm>({
    guest: '',
    onCancel: () => {}
  });
  const currentRemoveGuest = useRef('');
  const typeChange = useRef('');
  const guestsList: string[] = useMemo(() => {
    if (!gathering?.externalInvitees) {
      return [];
    }
    return gathering.externalInvitees
      .split(',')
      .map((value: string) => value.trim())
      .filter((value) => !!value);
  }, [gathering]);

  const handleAddGuest = useCallback(
    async (type: RadioValue) => {
      try {
        if (gathering) {
          setLoading(true);
          const { guest, onCancel } = currentFormValue.current;

          let updatedGathering;
          const currentExternalInvitees = `${
            gathering?.externalInvitees || ''
          }${guest}, `;
          if (type === 'all') {
            updatedGathering = await gatheringsAPI.changeAllGatheringForGroup({
              ...gathering,
              externalInvitees: currentExternalInvitees
            });
            const currentGathering = await loadGathering(gatheringId);
            loadGroupList(updatedGathering.groupId);
            updateGathering(currentGathering);
          } else {
            updatedGathering = await gatheringsAPI.changeCurrentGathering({
              ...gathering,
              externalInvitees: currentExternalInvitees
            });
          }
          updateGathering(updatedGathering.gatherings[0]);

          setLoading(false);
          setOpenModal(false);
          onCancel();

          enqueueSnackbar('Guest added successfully', {
            variant: 'success',
            style: { whiteSpace: 'pre-line' }
          });
        }
      } catch (e: any) {
        setLoading(false);
        const messageError = e.response?.data?.message;
        setOpenModal(false);
        enqueueSnackbar(
          'An error occurred while adding the guest. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
      }
    },
    [
      enqueueSnackbar,
      gathering,
      gatheringId,
      loadGathering,
      loadGroupList,
      updateGathering
    ]
  );

  const handleRemoveGuest = useCallback(
    async (type: RadioValue) => {
      try {
        if (gathering) {
          setLoading(true);
          let updatedGathering;
          const currentExternalInvitees = gathering.externalInvitees.replace(
            currentRemoveGuest.current + ', ',
            ''
          );

          if (type === 'all') {
            updatedGathering = await gatheringsAPI.changeAllGatheringForGroup({
              ...gathering,
              externalInvitees: currentExternalInvitees
            });

            const currentGathering = await loadGathering(gatheringId);
            loadGroupList(updatedGathering.groupId);
            updateGathering(currentGathering);
          } else {
            updatedGathering = await gatheringsAPI.changeCurrentGathering({
              ...gathering,
              externalInvitees: currentExternalInvitees
            });
          }
          updateGathering(updatedGathering.gatherings[0]);

          setLoading(false);
          setOpenModal(false);
          typeChange.current = '';

          enqueueSnackbar('Guest removed successfully', {
            variant: 'success',
            style: { whiteSpace: 'pre-line' }
          });
        }
      } catch (e: any) {
        setLoading(false);
        const messageError = e.response?.data?.message;
        setOpenModal(false);
        enqueueSnackbar(
          'An error occurred while removing the guest. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
      }
    },
    [
      enqueueSnackbar,
      gathering,
      gatheringId,
      loadGathering,
      loadGroupList,
      updateGathering
    ]
  );

  const handleSubmitForm = useCallback(
    (value: SumbitGuestForm) => {
      currentFormValue.current = value;
      if (!gathering?.periodString) {
        return handleAddGuest('all');
      }
      setOpenModal(true);
    },
    [gathering, handleAddGuest]
  );

  const onRemove = useCallback(
    (value) => {
      currentRemoveGuest.current = value;
      if (!gathering?.periodString) {
        return handleRemoveGuest('all');
      }
      setOpenModal(true);
      typeChange.current = 'remove';
    },
    [gathering, handleRemoveGuest]
  );

  return (
    <div className={classes.container}>
      {guestsList.length > 0 ? (
        <>
          <GuestsTable guests={guestsList} onRemove={onRemove} />
          <ButtonAddGuest
            guestsList={guestsList}
            loading={loading}
            handleSubmitForm={handleSubmitForm}
          />
        </>
      ) : (
        <div className={classes.emptyBlock}>
          <div className={classes.emptyState}>
            <Text variant='normal'>
              Currently we have no guests invited to this gathering.
              <br />
              Please feel free to add new by clicking on a button below
            </Text>
            <ButtonAddGuest
              guestsList={guestsList}
              loading={loading}
              handleSubmitForm={handleSubmitForm}
            />
          </div>
        </div>
      )}

      <GatheringModal
        loading={loading}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmit={
          typeChange.current === 'remove' ? handleRemoveGuest : handleAddGuest
        }
      />
    </div>
  );
};

export default GatheringMentorsPage;
