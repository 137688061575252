import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import mentorsAPI from '../../api/mentors';
import { SnackMessage } from '../../components/common';
import MentorDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/mentor-details';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/mentors/page-tabs';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
});

function NewMentorPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { rb } = useResourceBundles();

  const [isCreating, setIsCreating] = useState(false);

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { logo, ...values } = parsedValues.values;
        setIsCreating(true);
        const createdMentor = await mentorsAPI.create({
          ...values,
          logo: null,
        });
        const setSpecializationRequests = parsedValues.specializations.map(
          (specializationId) => () =>
            mentorsAPI.setMentorSpecialization(
              createdMentor.id,
              specializationId,
            ),
        );
        const logoFile = parsedValues.logoFile;
        await Promise.all<any>([
          massAsyncRequest(setSpecializationRequests),
          ...(logoFile !== null
            ? [mentorsAPI.updateAvatar(createdMentor.id, logoFile)]
            : []),
        ]);
        history.push(
          getRoutePath(Pages.MENTOR_DETAILS, { mentorId: createdMentor.id }),
        );
        enqueueSnackbar(`The ${rb('mentor')} was successfully created`, {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          `An error occurred while creating the ${rb(
            'mentor',
          )}. Please, try again.`,
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [history, enqueueSnackbar, rb],
  );

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title={rb(`New ${rb('mentor-u')}`)}
        backLink={
          <Tooltip title={`Back to ${rb('mentors-u')}`}>
            <IconButton
              component={Link}
              to={Pages.ACTIVE_MENTORS}
              data-testid='button-back-mentors'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Paper className={classes.container} elevation={1}>
        <PageTabs />
        <div className={classes.content}>
          <MentorDetailsForm loading={isCreating} onSubmit={handleSubmitForm} />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default NewMentorPage;
