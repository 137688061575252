export const DASHBOARD_RESPONSE = {
  eventsLastMonth: 0,
  eventsNoAgenda: 3,
  eventsThisMonth: 15,
  id: '3718eb06-b5dc-4e9e-a783-de4ffabe0038',
  mentorsActive: 8,
  mentorsActiveLastMonth: 4,
  mentorsNotAssigned: 1,
  mentorsOnboardedThisMonth: 4,
  mentorsOneVenture: 5,
  mentorsThreeVentures: 0,
  mentorsTwoVentures: 2,
  ttl: 1622108130,
  venturesActive: 4,
  venturesActiveLastMonth: 0,
  venturesNoEvents: 2,
  venturesNotAssigned: 0,
  venturesOnboardedThisMonth: 4,
  venturesOneMentor: 0,
  venturesThreeMentors: 1,
  venturesTwoMentors: 3
};
