import { isValid } from 'date-fns';
import { Either, left, right } from '../Either';
import { FormattedDate } from './FormattedDate';

export type StringDate = FormattedDate<string>;

export const isStringDate = (s: string): s is StringDate =>
  isValid(new Date(s));

export const toDate = (v: StringDate): Date => new Date(v);

export function strToEitherStrDate(s: string): Either<string, StringDate> {
  return isStringDate(s) ? right(s) : left('Invalid Date');
}
