import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { FormButtons, Text, Button, Dialog } from '../common';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  formButtons: {
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    rowGap: 10,
    marginTop: 15,

    '& > button': {
      width: '100%',
      margin: '0'
    },
    [theme.breakpoints.up('xs')]: {
      alignItems: 'flex-end',
      flexDirection: 'row !important',
      width: 'auto',
      rowGap: 0,
      columnGap: 10,
      '& > button': {
        width: 'auto'
      }
    }
  },
  dialog: {
    padding: '15px 20px 20px'
  },
  radioGroup: {
    '& span': {
      fontSize: 14,
      fontFamily: INTER_FONT_FAMILY
    }
  },
  warningContainer: {
    display: 'flex',
    columnGap: 15,
    alignItems: 'center',
    padding: 12,
    borderRadius: 5,
    backgroundColor: COLORS.COLOR_ADDITIONAL_ORANGE_LIGHTENED,
    marginBottom: 10,
    marginTop: -25
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE
  },
  formControl: {
    '& span + span': {
      fontFamily: INTER_FONT_FAMILY,
      fontSize: 12,
      [theme.breakpoints.up('xs')]: {
        fontSize: 14
      }
    }
  }
}));

export type RadioValue = 'this' | 'all';

interface Props {
  loading: boolean;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  handleSubmit: (value: RadioValue) => void;
  isDisabledOnly?: boolean;
}

function GatheringModal({
  loading,
  openModal,
  setOpenModal,
  handleSubmit,
  isDisabledOnly = false
}: Props) {
  const classes = useStyles();
  const [radioGroupValue, setRadioGroupValue] = useState<RadioValue>('this');

  useEffect(() => {
    if (isDisabledOnly) {
      setRadioGroupValue('all');
    }
  }, [isDisabledOnly]);

  return (
    <Dialog
      open={openModal}
      setOpen={setOpenModal}
      className={classes.dialog}
      title='Edit recurring gathering'
      width={400}
      contentRenderer={({ handleClose }) => (
        <div>
          <RadioGroup
            className={classes.radioGroup}
            aria-label='quiz'
            name='quiz'
            value={radioGroupValue}
            onChange={(e) => {
              setRadioGroupValue(e.target.value as RadioValue);
            }}>
            {isDisabledOnly && (
              <div className={classes.warningContainer}>
                <WarningIcon className={classes.warningIcon} />
                <Text variant='normal'>
                  You are about to change the frequency of recurrence for all
                  future instances of this gathering. Are you sure you want to
                  continue?
                </Text>
              </div>
            )}
            <FormControlLabel
              value='this'
              control={<Radio color='primary' />}
              label='This gatherings only'
              disabled={isDisabledOnly}
              className={classes.formControl}
            />
            <FormControlLabel
              value='all'
              control={<Radio color='primary' />}
              label='This and following gatherings'
              className={classes.formControl}
            />
          </RadioGroup>

          <FormButtons className={classes.formButtons}>
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button
              disabled={loading}
              data-testid='mentor-assignment-apply'
              onClick={() => {
                handleSubmit(radioGroupValue);
              }}>
              {loading ? <CircularProgress size={24} color='inherit' /> : 'OK'}
            </Button>
          </FormButtons>
        </div>
      )}
    />
  );
}

export default GatheringModal;
