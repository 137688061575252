export const FOUNDERS_LIST: any = [
  {
    id: '1',
    founderNotes: [],
    firstName: 'Fist name founder',
    lastName: 'Last name founder',
    dateOfBirth: '',
    linkedInProfile: '',
    dateOfEnrollment: '',
    country: '',
    state: '',
    city: '',
    email: 'test@teac.com',
    phone: '7909413434',
    tenantId: 'asdasd',
    logo: '',
    status: 'Active',
    valid: true,
    emailValid: true,
    address: '',
    zip: '',
    primaryVenture: '',
    primaryVentureId: ''
  },
  {
    id: '2',
    founderNotes: [],
    firstName: 'Founder',
    lastName: 'Two',
    dateOfBirth: '',
    linkedInProfile: '',
    dateOfEnrollment: '',
    country: '',
    state: '',
    city: '',
    email: 'test@teac.com',
    phone: '7909413434',
    tenantId: 'asdasd',
    logo: '',
    status: 'Active',
    valid: true,
    emailValid: true,
    address: '',
    zip: '',
    primaryVenture: '',
    primaryVentureId: ''
  },
  {
    id: '3',
    founderNotes: [],
    firstName: 'Founder',
    lastName: 'Three',
    dateOfBirth: '',
    linkedInProfile: '',
    dateOfEnrollment: '',
    country: '',
    state: '',
    city: '',
    email: 'test@teac.com',
    phone: '7909413434',
    tenantId: 'asdasd',
    logo: '',
    status: 'Active',
    valid: true,
    emailValid: true,
    address: '',
    zip: '',
    primaryVenture: '',
    primaryVentureId: ''
  }
];
