import { TenantId } from '../../../../api/tenants/Tenant';
import { Types } from '../../../../utils/Either';
import { Initial, initial, Valid, Value } from '../../../../utils/FormValue';
import { Email } from '../../../../utils/String/Email';
import { Password } from '../../../../utils/String/Password';
import { fromString, Sentence } from '../../../../utils/String/Sentence';
import { decodeKey } from '../utils';

// region InvalidKey
export interface InvalidKey {
  type: 'InvalidKey';
}

export const invalidKey = (): InvalidKey => ({
  type: 'InvalidKey',
});
// endregion

// region Ready
export interface ReadyPayload {
  tenantId: TenantId;
  key: Sentence<10000>;
  email: Email;
  password: Initial<undefined>;
  passwordRepeat: Initial<undefined>;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Edited
export interface EditedPayload
  extends Omit<ReadyPayload, 'password' | 'passwordRepeat'> {
  password: Value<'required', Password, string | undefined>;
  passwordRepeat: Value<'required', Password, string | undefined>;
}

export interface Edited {
  type: 'Edited';
  payload: EditedPayload;
}

export const edited = (payload: Edited['payload']): Edited => ({
  type: 'Edited',
  payload,
});
// endregion

// region Sending
export interface SendingPayload extends EditedPayload {
  password: Valid<Password>;
  passwordRepeat: Valid<Password>;
}

export interface Sending {
  type: 'Sending';
  payload: SendingPayload;
}

export const sending = (payload: Sending['payload']): Sending => ({
  type: 'Sending',
  payload,
});
// endregion

// region SendError
export interface SendErrorPayload extends SendingPayload {
  message: string;
}

export interface SendError {
  type: 'SendError';
  payload: SendErrorPayload;
}

export const sendError = (payload: SendError['payload']): SendError => ({
  type: 'SendError',
  payload,
});
// endregion

// region SendSuccess
export interface SendSuccessPayload extends SendingPayload {}

export interface SendSuccess {
  type: 'SendSuccess';
  payload: SendSuccessPayload;
}

export const sendSuccess = (payload: SendSuccess['payload']): SendSuccess => ({
  type: 'SendSuccess',
  payload,
});
// endregion

export type State =
  | InvalidKey
  | Ready
  | Edited
  | Sending
  | SendError
  | SendSuccess;

export const init = (key: string | undefined): InvalidKey | Ready => {
  const v = fromString(10000)(key ?? '');

  switch (v.type) {
    case Types.Left:
      return invalidKey();
    case Types.Right: {
      const data = decodeKey(v.value);

      return data
        ? ready({
            key: v.value,
            email: data.email,
            tenantId: data.tenantId,
            password: initial(undefined),
            passwordRepeat: initial(undefined),
          })
        : invalidKey();
    }
  }
};
