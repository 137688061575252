import { AxiosError } from 'axios';
import { FounderPersonalNote } from '../contexts/personal-notes-context';
import { Name } from '../types';
import { Email } from '../utils/String/Email';
import { Phone } from '../utils/String/Phone';
import { Sentence } from '../utils/String/Sentence';
import { Zip } from '../utils/String/Zip';
import { PastStringDate } from '../utils/date/PastStringDate';
import { Event, EventStatusOrAll } from './events';
import { ExportJob } from './exports';
import { axiosRequest } from './instance';
import { Specialization, SpecializationId } from './specializations';
import { TenantId } from './tenants/Tenant';
import { VentureId } from './ventures/types/Venture';

// region FounderId
declare const _founderId: unique symbol;

export type FounderId = string & { [_founderId]: 'FounderId' };
// endregion

export type FounderStatus = 'Active' | 'Archived' | 'Applicant' | 'Rejected';

export interface FounderNote {
  id: string;
  founderId: FounderId;
  creationDate: string;
  attachmentRefs: string | null;
  contents: string;
}

export interface FounderNewNote {
  contents: string;
  founderId: FounderId;
  attachmentRefs?: string | null;
}

export interface FounderLog {
  id: string;
  founderId: FounderId;
  creationDate: string;
  contents: string;
}

export interface FounderSpecialization {
  id: string;
  founderId: FounderId;
  specializationId: SpecializationId;
  valid: boolean;
}

export interface FounderName {
  founderId: FounderId;
  tenantId: TenantId | null;
  firstName: string;
  lastName: string;
  logoUrl: string | null;
}

export interface Founder {
  id: FounderId;
  assigned?: number;
  founderNotes?: FounderNote[];
  primaryVenture: string | null;
  primaryVentureId: string | null;
  firstName: Name;
  lastName: Name;
  dateOfBirth: PastStringDate | null;
  linkedInProfile: Sentence<250> | null;
  dateOfEnrollment: PastStringDate;
  country: Sentence<250> | null;
  state: Sentence<250> | null;
  city: Sentence<250> | null;
  address: Sentence<1024> | null;
  zip: Zip | null;
  email: Email;
  phone: Phone | null;
  tenantId: TenantId;
  logo: string | null;
  status: FounderStatus;
  valid: boolean;
  emailValid: boolean;
  assignmentStatus?: null | 'INVITED' | 'ACCEPTED' | 'DECLINED' | 'TENTATIVE';
  foundersAdditionalInfo?: FounderAdditionalInfo;
}

export interface FounderAdditionalInfoCreate {
  gender: Sentence<20> | null;
  race: Sentence<50> | null;
  veteran: boolean;
  creationDate: PastStringDate;
}

export interface FounderAdditionalInfo extends FounderAdditionalInfoCreate {
  id: string;
  founderId: FounderId;
  tenantId: TenantId;
}

export interface FounderDetails {
  founderNotes?: Array<FounderNote | FounderNewNote>;
  address: Sentence<1024> | null;
  status: FounderStatus;
  firstName: Name;
  lastName: Name;
  dateOfBirth: PastStringDate | null;
  dateOfEnrollment: PastStringDate;
  linkedInProfile: Sentence<250> | null;
  email: Email;
  phone: Phone | null;
  logo: string | null;
  country: Sentence<250> | null;
  state: Sentence<250> | null;
  city: Sentence<250> | null;
  zip: Zip | null;
  foundersAdditionalInfo?: FounderAdditionalInfo | FounderAdditionalInfoCreate;
}

export interface FounderVentureAssignment {
  founder: Founder;
  numOfAssignments: number;
}

function normalizeFounder(founder: Founder): Founder {
  return founder;
}

function normalizeFounderDetails({
  address,
  firstName,
  lastName,
  dateOfBirth,
  dateOfEnrollment,
  email,
  phone,
  linkedInProfile,
  country,
  state,
  city,
  logo,
  status,
  zip,
  foundersAdditionalInfo,
}: Founder | FounderDetails) {
  return {
    address,
    firstName,
    lastName,
    dateOfBirth,
    dateOfEnrollment,
    email,
    phone,
    linkedInProfile,
    country,
    state,
    city,
    logo:
      typeof logo === 'string'
        ? logo.replace(`${process.env.REACT_APP_S3_URL}/`, '')
        : logo,
    status,
    zip,
    foundersAdditionalInfo,
  };
}

const foundersAPI = {
  async search(searchString: string) {
    const res = await axiosRequest.get<Founder[]>({
      url: `/founders/search/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeFounder);
  },
  async searchNonusers(searchString: string) {
    const res = await axiosRequest.get<Founder[]>({
      url: `/founders/search-nonusers/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeFounder);
  },
  async getFounder(founderId: Founder['id']) {
    const res = await axiosRequest.get<Founder>({
      url: `/founders/${founderId}`,
      credentials: true,
    });
    return normalizeFounder(res.data);
  },
  async getFounders(page = 0) {
    const res = await axiosRequest.get<Founder[]>({
      url: `/founders/pages/${page}`,
      credentials: true,
    });
    return res.data.map(normalizeFounder);
  },
  async getRecommendedFoundersByVentures() {
    try {
      const res = await axiosRequest.get<FounderVentureAssignment[]>({
        url: '/founders/assignments/sort',
        credentials: true,
      });
      return res.data.map((entity) => ({
        ...entity.founder,
        assigned: entity.numOfAssignments,
      }));
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getFoundersByStatus(status: string, page = 0) {
    const res = await axiosRequest.get<Founder[]>({
      url: `/founders/statuses/${status}/pages/${page}`,
      credentials: true,
    });
    return res.data.map(normalizeFounder);
  },
  async create(founderDetails: FounderDetails) {
    const res = await axiosRequest.post<Founder>({
      url: '/founders',
      data: {
        ...founderDetails,
      },
      credentials: true,
    });
    return normalizeFounder(res.data);
  },
  async update(founderId: FounderId, founderDetails: FounderDetails) {
    const res = await axiosRequest.put<Founder>({
      url: '/founders',
      data: { ...normalizeFounderDetails(founderDetails), id: founderId },
      credentials: true,
    });
    return normalizeFounder(res.data);
  },
  async updateAvatar(founderId: Founder['id'], logoFile: File) {
    let formData = new FormData();
    formData.append('name', logoFile.name);
    formData.append('file', logoFile);

    const res = await axiosRequest.post<Founder>({
      url: `/founders/${founderId}/logo`,
      data: formData,
      credentials: true,
    });
    return normalizeFounder(res.data);
  },
  async getFoundersByLastname(lastName: string) {
    const res = await axiosRequest.get<Founder[]>({
      url: `/founders/lastname/${lastName}`,
      credentials: true,
    });
    return res.data.map(normalizeFounder);
  },
  async getFoundersDetailsByVentureId(ventureId: VentureId) {
    try {
      const res = await axiosRequest.get<Founder[]>({
        url: `/founders/venture/${ventureId}/details`,
        credentials: true,
      });
      return res.data.map(normalizeFounder);
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getFounderSpecializations(founderId: Founder['id']) {
    try {
      const res = await axiosRequest.get<Specialization[]>({
        url: `/founders/${founderId}/specializations`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async setFounderSpecialization(
    founderId: Founder['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.post<FounderSpecialization>({
      url: `/founders/${founderId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async deleteFounderSpecialization(
    founderId: Founder['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.delete<FounderSpecialization>({
      url: `/founders/${founderId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async checkExportFounder(jobId: ExportJob['jobId']) {
    try {
      const res = await axiosRequest.get<ExportJob>({
        url: `/export/founders/${jobId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      throw e;
    }
  },
  async exportFounder() {
    const res = await axiosRequest.post<ExportJob>({
      url: '/export/founders',
      credentials: true,
    });

    return res.data;
  },
  async import(file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    try {
      const res = await axiosRequest.post<string>({
        url: '/import/founders',
        data: formData,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async attachFileToNote(founderId: string, file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/founders/${founderId}/foundernotes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async checkEmail(email: string) {
    try {
      const res = await axiosRequest.get<Founder[]>({
        url: `/founders/email/${email}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async checkGlobalEmail(email: string) {
    try {
      const res = await axiosRequest.get<FounderId>({
        url: `/founders/global/emails/${email}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }
  },
  async getNotes(founderId: string) {
    try {
      const res = await axiosRequest.get<FounderNote[]>({
        url: `/founders/${founderId}/notes`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createNote(note: FounderNewNote) {
    try {
      const res = await axiosRequest.post<FounderNote>({
        url: '/founders/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeNote(founderId: string, noteId: string) {
    try {
      const res = await axiosRequest.delete<FounderNote>({
        url: `/founders/${founderId}/notes/${noteId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateNote(note: FounderNote) {
    try {
      const res = await axiosRequest.put<FounderNote>({
        url: '/founders/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getLogs(founderId: string) {
    try {
      const res = await axiosRequest.get<FounderLog[]>({
        url: `/founders/${founderId}/log`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async globalCheckEmail(email: string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/founders/global/emails/${email}`,
        credentials: true,
      });
      return !!res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      throw e;
    }
  },
  async sendFounderUpdateInvites(foundersIds: string[]) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/founders/invites',
        data: foundersIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getAllEvents(founderId: FounderId, page: number): Promise<Event[]> {
    return axiosRequest
      .get<Event[]>({
        url: `/events/founders/${founderId}/pages/${page}`,
        credentials: true,
      })
      .then((r) => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return [];
        }

        throw e;
      });
  },
  async getEvents(
    founderId: FounderId,
    statusId: EventStatusOrAll,
  ): Promise<Event[]> {
    return axiosRequest
      .get<Event[]>({
        url: `/events/founders/${founderId}/status/${statusId}`,
        credentials: true,
      })
      .then((r) => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return [];
        }

        throw e;
      });
  },
  async getEventsDetailsByDataRange(
    founderId: FounderId,
    startDate: string,
    endDate: string,
  ) {
    try {
      const res = await axiosRequest.get<Event[]>({
        url: `/events/founders/${founderId}/dates/start/${startDate}/end/${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getEventsByVenture(
    ventureId: VentureId,
    statusId: EventStatusOrAll,
  ): Promise<Event[]> {
    return axiosRequest
      .get<Event[]>({
        url: `/events/ventures/${ventureId}/status/${statusId}`,
        credentials: true,
      })
      .then((r) => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return [];
        }

        throw e;
      });
  },
  async getPublicFounderNames(
    ventureId: VentureId,
    tenantId: TenantId,
  ): Promise<FounderName[]> {
    return axiosRequest
      .get<FounderName[]>({
        url: `/public/tenants/${tenantId}/ventures/${ventureId}/foundernames`,
        credentials: true,
      })
      .then((r) => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return [];
        }

        throw e;
      });
  },
  async updatePublicAvatar(
    tenantId: TenantId,
    founderId: FounderId,
    logoFile: File,
  ) {
    let formData = new FormData();
    formData.append('name', logoFile.name);
    formData.append('file', logoFile);

    const res = await axiosRequest.post<FounderName>({
      url: `/public/tenants/${tenantId}/founders/${founderId}/logo`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async getPersonalNotes() {
    try {
      const res = await axiosRequest.get<FounderPersonalNote[]>({
        url: '/founders/personal-notes',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async getPersonalNotesByPages(page: number) {
    try {
      const res = await axiosRequest.get<FounderPersonalNote[]>({
        url: `/founders/personal-notes/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async searchPersonalNotes(keywords: string) {
    try {
      const res = await axiosRequest.get<FounderPersonalNote[]>({
        url: `/founders/personal-notes/search/${keywords}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async createPersonalNotes(note: Omit<FounderPersonalNote, 'id' | 'date'>) {
    try {
      const res = await axiosRequest.post<FounderPersonalNote>({
        url: '/founders/personal-notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updatePersonalNotes(note: FounderPersonalNote) {
    try {
      const res = await axiosRequest.put<FounderPersonalNote>({
        url: '/founders/personal-notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removePersonalNote(note: FounderPersonalNote) {
    try {
      const res = await axiosRequest.delete<FounderPersonalNote>({
        url: `/founders/personal-notes`,
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
};

export default foundersAPI;
