import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useCallback } from 'react';
import { Audience } from '../../api/CustomQuestion/types/Audience';
import { CustomQuestionDefaultWarningPopup } from '../../components/CustomQuestions/CustomQuestionDefaultWarningPopup';
import { CustomQuestionModal } from '../../components/CustomQuestions/CustomQuestionModal';
import { CustomQuestionsList } from '../../components/CustomQuestions/CustomQuestionsList';
import { CustomSubtitleModal } from '../../components/CustomQuestions/CustomSubtitleModal';
import { TestId } from '../../components/Testing/TestId';
import { Text, Button, StickyContent } from '../../components/common';
import {
  CreateModalState,
  CustomQuestionProvider,
  useCustomQuestionContext,
} from '../../contexts/custom-questions-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  emptyContainer: {
    minHeight: '100%',
  },
  title: {
    margin: '0 0 8px',
  },
  actionsBlock: {
    marginTop: 56,
    '&> div': {
      display: 'flex',
      gap: 16,
    },
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

function TenantCustomQuestionsEdit() {
  const classes = useStyles();
  const { questions, audience, loading, showCreateModal, setShowCreateModal } =
    useCustomQuestionContext();
  const { rb } = useResourceBundles();

  const getPageTitle = useCallback(
    (audience: Audience | null): string => {
      switch (audience) {
        case Audience.FOUNDER_APPLICATION:
          return 'Customize Founder Profile';
        case Audience.MENTOR_APPLICATION:
          return `Customize ${rb('mentor-u')} Profile`;
        case Audience.VENTURE_APPLICATION:
          return 'Customize Venture Profile';
        default:
          return '';
      }
    },
    [rb],
  );

  const getPageSubitle = useCallback(
    (audience: Audience | null): string => {
      switch (audience) {
        case Audience.FOUNDER_APPLICATION:
          return 'Here you can create and modify custom questions for the Founder Profile.';
        case Audience.MENTOR_APPLICATION:
          return `Here you can create and modify custom questions for the ${rb(
            'mentor-u',
          )} Profile.`;
        case Audience.VENTURE_APPLICATION:
          return 'Here you can create and modify custom questions for the Venture Profile.';
        default:
          return '';
      }
    },
    [rb],
  );

  return (
    <div
      className={questions?.length ? classes.container : classes.emptyContainer}
      data-testid='custom-questions-container'>
      <Typography variant='h3' className={classes.title}>
        {getPageTitle(audience)}
      </Typography>
      <Text variant='normal'>{getPageSubitle(audience)}</Text>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : (
        <>
          <CustomQuestionsList />
          {questions?.length ? (
            <div className={classes.actionsBlock}>
              <StickyContent>
                <TestId testId='add-button'>
                  <Button
                    onClick={() =>
                      setShowCreateModal(
                        CreateModalState.SHOW_CREATE_QUESTION_MODAL,
                      )
                    }
                    disabled={loading}
                    variant='contained'
                    startIcon={<AddIcon />}>
                    Add Question
                  </Button>
                </TestId>
                <TestId testId='add-subtitle'>
                  <Button
                    onClick={() =>
                      setShowCreateModal(
                        CreateModalState.SHOW_CREATE_SUBTITLE_MODAL,
                      )
                    }
                    disabled={loading}
                    variant='outlined'
                    startIcon={<AddIcon />}>
                    Add Subtitle
                  </Button>
                </TestId>
              </StickyContent>
            </div>
          ) : null}
          {showCreateModal === CreateModalState.SHOW_CREATE_QUESTION_MODAL && (
            <CustomQuestionModal />
          )}
          {showCreateModal === CreateModalState.SHOW_CREATE_SUBTITLE_MODAL && (
            <CustomSubtitleModal />
          )}
        </>
      )}
      <CustomQuestionDefaultWarningPopup />
    </div>
  );
}

function TenantCustomQuestionsEditWithProvider() {
  return (
    <CustomQuestionProvider>
      <TenantCustomQuestionsEdit />
    </CustomQuestionProvider>
  );
}

export default TenantCustomQuestionsEditWithProvider;
