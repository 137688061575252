import { Typography } from '@material-ui/core';
import { Adjust } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, useState } from 'react';
import { CreateCheckin } from '../../../api/types/Checkin';
import { Goal, GoalStatus, goalStatuses } from '../../../api/types/Goal';
import { COLORS } from '../../../theme/variables';
import { Inline } from '../../common/Inline';
import Button from '../../common/button';
import Dialog from '../../common/dialog';
import Select from '../../common/select';
import TextField from '../../common/text-field';

export interface GoalModalProps {
  goal: Goal;
  onClose: () => void;
  onChange: (goal: CreateCheckin) => void;
}

export function GoalModal({ onClose, onChange, goal }: GoalModalProps) {
  const c = useStyles();

  const [progress, setProgress] = useState(goal.progress);
  const [status, setStatus] = useState(
    goal.progress === 100 ? 'COMPLETED' : goal.status,
  );
  const [comment, setComment] = useState('');

  const handleChangeProgress = (e: ChangeEvent<HTMLInputElement>) => {
    const newProgress = Number(e.target.value);
    const minValue = Number(e.target.min);
    const maxValue = Number(e.target.max);
    if (newProgress >= minValue && newProgress <= maxValue) {
      setProgress(newProgress);
      if (newProgress === maxValue) {
        setStatus('COMPLETED');
      }
    }
  };

  return (
    <Dialog
      title='Check-In'
      className={c.dialog}
      contentRenderer={() => (
        <div className={c.root}>
          <Inline gap={10}>
            <Adjust />
            <Typography variant={'h4'}>{goal.title}</Typography>
          </Inline>
          <div className={c.columns}>
            <div className={c.column1}>
              <TextField
                label={'Progress'}
                type='number'
                value={Number(progress).toString()}
                onChange={handleChangeProgress}
                variant={'outlined'}
                size={'small'}
                inputProps={{
                  min: 0,
                  max: 100,
                }}
              />
            </div>
            <div className={c.column2}>
              <Select
                className={c.select}
                value={status}
                options={goalStatuses.map((value) => ({
                  value,
                  label: (
                    <div className={c.statusOption}>
                      <div className={classNames(c.statusColor, value)} />
                      <div>{goalStatusTitle(value)}</div>
                    </div>
                  ),
                }))}
                onSelect={(v) => v && setStatus(v.value)}
                maxMenuHeight={140}
              />
            </div>
          </div>
          <div>
            <Typography variant={'subtitle1'}>Check-In Date</Typography>
            <Inline gap={0}>
              <Typography variant={'subtitle2'}>
                {format(new Date(), 'dd MMM yyyy')}
              </Typography>
            </Inline>
          </div>
          <TextField
            className={c.comment}
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            size={'small'}
            rows={5}
            placeholder={'Comment'}
          />
        </div>
      )}
      open
      setOpen={(v) => !v && onClose()}
      actions={
        <Inline gap={15}>
          <Button
            onClick={() =>
              onChange({
                goalId: goal.id,
                progress,
                status,
                contents: comment,
              })
            }
            color={'primary'}>
            Apply
          </Button>
          <Button onClick={onClose} color={'default'}>
            Cancel
          </Button>
        </Inline>
      }
    />
  );
}

function goalStatusTitle(status: GoalStatus) {
  switch (status) {
    case 'ARCHIVED':
      return 'Archived';
    case 'COMPLETED':
      return 'Completed';
    case 'NOT_STARTED':
      return 'Not Started';
    case 'AT_RISK':
      return 'At Risk';
    case 'IN_TROUBLE':
      return 'In Trouble';
    case 'ON_TRACK':
      return 'On Track';
  }
}

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      width: '564px',
    },
    '@media (max-width: 700px)': {
      '& .MuiPaper-root': {
        width: '100%',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  columns: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  column1: {
    flex: 1,
  },
  column2: {
    flex: 1,
  },
  select: {
    width: '100%',
  },
  comment: {
    flex: 1,
  },
  statusOption: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  statusColor: {
    width: 10,
    height: 10,
    borderRadius: 5,

    '&.ARCHIVED': {
      backgroundColor: COLORS.COLOR_GRAY_DARKENED,
    },
    '&.COMPLETED': {
      backgroundColor: COLORS.COLOR_GREEN_BASE,
    },
    '&.NOT_STARTED': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    },
    '&.AT_RISK': {
      backgroundColor: COLORS.COLOR_ADDITIONAL_ORANGE,
    },
    '&.IN_TROUBLE': {
      backgroundColor: COLORS.COLOR_RED_BASE,
    },
    '&.ON_TRACK': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_10,
    },
  },
});
