import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import schedulingWizardApi from '../../api/schedulingWizard';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { massAsyncRequest } from '../../utils/api';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  SnackMessage,
  TextField,
} from '../common';

interface AssignFoundersVentureFormProps {
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  checkboxGroup: {
    maxHeight: 360,
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

function AssignSchedulingWizardForm({
  onCancel,
}: AssignFoundersVentureFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialVentures, setInitialVentures] = useState<Venture[]>([]);
  const [recommended, setRecommended] = useState<Venture[]>();
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>([]);
  const [isLoading, setIsLoading] = useState<{
    ventures: boolean;
    recommended: boolean;
  }>({ ventures: false, recommended: !recommended });
  const [searchIteration, setSearchIteration] = useState(0);
  const labelsVenture = useMemo(() => {
    return displayedVentures.length === 0
      ? "Ventures that don't have sessions scheduled"
      : 'Ventures';
  }, [displayedVentures]);

  const handleVenturesSearch = useCallback((searchedVentures: Venture[]) => {
    setDisplayedVentures(searchedVentures);
  }, []);

  const handleVentureRemove = (venture: Venture) => {
    setInitialVentures((prevVentures) =>
      prevVentures.filter((prevVenture) => prevVenture.id !== venture.id),
    );
  };

  const handleVentureAdd = (venture: Venture) => {
    setInitialVentures((prevVentures) => [...prevVentures, venture]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const loadRecommended = async () => {
    try {
      const result = await venturesAPI.getVenturesByAssignment('noevents', 0);
      setRecommended(result);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    } catch (e: any) {
      console.log('error loadRecommended');
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    }
  };

  const handleSubmit = async ({
    venturesIds,
  }: {
    venturesIds: Venture['id'][];
  }) => {
    try {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        ventures: true,
      }));
      const createSchedulingWizard = venturesIds.map((ventureId: string) => {
        return () => schedulingWizardApi.create(ventureId);
      });
      await massAsyncRequest(createSchedulingWizard);
      enqueueSnackbar(
        'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
        {
          variant: 'success',
        },
      );
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        ventures: false,
      }));
      onCancel();
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar(
        'An error occurred while creating the scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );

      setIsLoading((prevLoading) => ({
        ...prevLoading,
        ventures: false,
      }));
    }
  };

  useEffect(() => {
    loadRecommended();
  }, []);

  if (isLoading.ventures || isLoading.recommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    venturesIds: initialVentures.map((venture) => venture.id),
  };

  const filteredDisplayedVentures = displayedVentures.filter(
    (venture) =>
      !initialVentures.find(
        (initialVenture) => initialVenture.id === venture.id,
      ),
  );
  const filteredRecommendedVentures = (recommended || []).filter(
    (item) => !initialVentures.find((venture) => venture.id === item.id),
  );

  const displayedList =
    filteredDisplayedVentures.length > 0
      ? filteredDisplayedVentures
      : filteredRecommendedVentures;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <VentureAutocomplete
              iteration={searchIteration}
              onSearch={handleVenturesSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label='Schedule New Session(s) for'
                  placeholder='Enter venture name'
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialVentures.map((venture) => (
              <Chip
                key={venture.id}
                label={venture.ventureName}
                onDelete={() => handleVentureRemove(venture)}
                className={classes.chipItem}
              />
            ))}
          </div>
          {displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={[labelsVenture, '']}
              rows={displayedList.map((recomItem) => ({
                name: recomItem.ventureName,
                onAdd: () => handleVentureAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button
              disabled={isLoading.ventures || initialVentures.length === 0}
              type='submit'
              data-testid='button-apply'>
              {isLoading.ventures ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default AssignSchedulingWizardForm;
