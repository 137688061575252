import * as State from './types/State';
import { Actions } from './types/Actions';
import { Item, setValue, Submitted } from '../common/types/Item';
import { validators } from '../common/validators';
import * as V from '../../../utils/FormValue';
import { isValid, validateItem } from '../common/utils';
import { invalid, isVerifying, valid } from '../../../utils/FormValue';
import { Email } from '../../../utils/String/Email';
import { Sentence } from '../../../utils/String/Sentence';

function setItem(s: State.Editable, item: Item): State.Editable {
  switch (s.type) {
    case 'Uploading':
      return State.uploading({ ...s.payload, item });
    case 'Submitted':
      return State.submitted({ ...s.payload, item: item as Submitted });
    case 'Ready':
    case 'SaveError':
    case 'VerificationError':
    case 'Edited':
      return State.edited({ ...s.payload, item });
  }
}

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'SetValue':
      return State.isEditable(s)
        ? setItem(
            s,
            setValue<typeof a.payload.key>(
              a.payload.key,
              // @ts-expect-error, need to fix this
              validators[a.payload.key](a.payload.value),
              s.payload.item,
            ),
          )
        : s;
    case 'Toggle':
      return State.isEditable(s) &&
        s.payload.item[a.payload].__typeName === 'initial'
        ? setItem(
            s,
            setValue<typeof a.payload>(
              a.payload,
              // @ts-expect-error, need to fix this
              validators[a.payload](s.payload.item[a.payload].value),
              s.payload.item,
            ),
          )
        : s;
    case 'Upload':
      return State.isEditable(s)
        ? State.uploading({ ...s.payload, logo: a.payload })
        : s;
    case 'UploadSuccess':
      return s.type === 'Uploading'
        ? State.edited({
            ...s.payload,
            item: setValue('logo', validators.logo(a.payload), s.payload.item),
          })
        : s;
    case 'UploadErr':
      return s.type === 'Uploading'
        ? State.edited({
            ...s.payload,
            item: setValue(
              'logo',
              V.invalid(a.payload, undefined),
              s.payload.item,
            ),
          })
        : s;
    case 'Save': {
      if (State.isSubmittable(s)) {
        if (s.type === 'Submitted') {
          return isValid(s.payload.item)
            ? State.saving({ ...s.payload, item: s.payload.item })
            : s;
        } else {
          const item = validateItem(s.payload.item);
          return isValid(item)
            ? State.saving({ ...s.payload, item })
            : State.submitted({ ...s.payload, item });
        }
      }

      return s;
    }
    case 'SaveError':
      return s.type === 'Saving'
        ? State.saveError({ ...s.payload, message: a.payload })
        : s;
    case 'SaveSuccess':
      return s.type === 'Saving'
        ? State.redirect({ ...s.payload, id: a.payload })
        : s;
    case 'EmailValidationError':
    case 'NameValidationError':
      return State.verificationError({ ...s.payload, message: a.payload });
    case 'EmailValidation':
      return State.isEditable(s) && isVerifying(s.payload.item.email)
        ? ({
            ...s,
            payload: {
              ...s.payload,
              item: {
                ...s.payload.item,
                email: a.payload
                  ? valid(s.payload.item.email.value as Email)
                  : invalid(
                      'Email address is already used',
                      s.payload.item.email.value,
                    ),
              },
            },
          } as State.State)
        : s;
    case 'NameValidation':
      return State.isEditable(s) &&
        [s.payload.item.firstName, s.payload.item.lastName].some(isVerifying)
        ? ({
            ...s,
            payload: {
              ...s.payload,
              item: {
                ...s.payload.item,
                firstName: a.payload
                  ? valid(s.payload.item.firstName.value as Sentence<250>)
                  : invalid('Required', s.payload.item.firstName.value),
                lastName: a.payload
                  ? valid(s.payload.item.lastName.value as Sentence<250>)
                  : invalid('Required', s.payload.item.lastName.value),
              },
            },
          } as State.State)
        : s;
  }
}
