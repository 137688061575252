import { mPipe, pass } from 'fp-utilities';
import { trim } from 'ramda';
import { Either, left, right } from '../Either';

declare const _zip: unique symbol;

export type Zip = string & { [_zip]: 'Zip' };

export const isZip = (s: string): s is Zip => new RegExp(/^.{1,12}$/).test(s);

export const stringToZip = mPipe(trim, pass(isZip));
export const stringToEitherZip = (s: string): Either<string, Zip> => {
  const z = stringToZip(s);
  return z ? right(z) : left('Invalid Zip');
};
