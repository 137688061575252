import { Specialization } from '../../../../../api/specializations';
import { TenantId } from '../../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../../api/tenants/types/Settings';
import { Filter } from './Filter';
import { Item } from './Item';

// region Loading
export interface LoadingPayload {
  tenantId: TenantId;
  page: number;
  filter: Filter;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  items: Item[];
  specializations: Specialization[];
  timeZone: TenantTimeZone;
  strictOfficeHoursScheduling: boolean;
  prev: boolean;
  next: boolean;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {}

export interface Fetching {
  type: 'Fetching';
  payload: FetchingPayload;
}

export const fetching = (payload: Fetching['payload']): Fetching => ({
  type: 'Fetching',
  payload,
});
// endregion

// region FetchError
export interface FetchErrorPayload extends ReadyPayload {
  message: string;
}

export interface FetchError {
  type: 'FetchError';
  payload: FetchErrorPayload;
}

export const fetchError = (payload: FetchError['payload']): FetchError => ({
  type: 'FetchError',
  payload,
});
// endregion

export type State = Loading | LoadError | Ready | Fetching | FetchError;
