import { createContext, useState, useCallback } from 'react';
import reportsAPI, { QuarterlyReportDate } from '../api/reports';

export interface QuarterlyReportContextProps {
  reportQuarters?: QuarterlyReportDate[];
  isLoadingQuarters: boolean;
  loadQuarters: () => any;
}

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const QuarterlyReportContext =
  createContext<QuarterlyReportContextProps>({
    reportQuarters: undefined,
    isLoadingQuarters: true,
    loadQuarters: () => {}
  });

export const QuarterlyReportProvider = ({ children }: DrawerProviderProps) => {
  const [reportQuarters, setReportQuarters] = useState<QuarterlyReportDate[]>();
  const [isLoadingQuarters, setIsLoadingQuarters] = useState(true);

  const loadQuarters = useCallback(async () => {
    try {
      setIsLoadingQuarters(true);
      const res = await reportsAPI.getQuarterlyReportDates();
      setReportQuarters(res);
      setIsLoadingQuarters(false);
    } catch (e) {
      setIsLoadingQuarters(false);
    }
  }, []);

  const contextValue: QuarterlyReportContextProps = {
    reportQuarters,
    isLoadingQuarters,
    loadQuarters
  };

  return (
    <QuarterlyReportContext.Provider value={contextValue}>
      {children}
    </QuarterlyReportContext.Provider>
  );
};
