// region PastStringDate
import { Either, left, right } from '../Either';
import { formatDateToRFC } from '../date';
import { FormattedDate } from './FormattedDate';
import { isStringDate, StringDate } from './StringDate';

declare const _pastStringDate: unique symbol;

export type PastStringDate = (StringDate | FormattedDate<any>) & {
  [_pastStringDate]: 'PastStringDate';
};
// endregion

export function strToEitherPastStrDate(
  s: string,
): Either<string, PastStringDate> {
  const isDate = isStringDate(s);

  if (!isDate) return left('Invalid Date');

  const date = new Date(s);

  return date < new Date()
    ? right(s as unknown as PastStringDate)
    : left('Date must be in the past');
}

export function dateToPastStrDate(date: Date): PastStringDate | undefined {
  return date < new Date()
    ? (formatDateToRFC(date) as PastStringDate)
    : undefined;
}
