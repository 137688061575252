import { CircularProgress } from '@material-ui/core';
import { PropsWithChildren, ReactElement } from 'react';
import { TestId } from '../../Testing/TestId';
import { Inline } from '../Inline';
import { Spacing } from '../Spacing';
import Button from '../button';
import Text from '../text';
import { ActionsDialog } from './ActionsDialog';

type ButtonState = 'active' | 'disabled' | 'loading';

export interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onLeave: () => void;
  saveButton: ButtonState;
}

export function BeforeUnloadWithSave({
  open,
  onClose,
  onSave,
  onLeave,
  saveButton,
  children,
}: PropsWithChildren<Props>): ReactElement {
  function Actions() {
    return (
      <>
        <Inline gap={10} align={'center'}>
          <TestId testId={'save'}>
            <Button
              variant={'outlined'}
              onClick={onSave}
              disabled={isButtonDisabled(saveButton)}>
              {saveButton === 'loading' ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Save the changes'
              )}
            </Button>
          </TestId>
          <TestId testId={'leave'}>
            <Button
              variant={'contained'}
              onClick={onLeave}
              disabled={saveButton === 'loading'}>
              Yes, proceed
            </Button>
          </TestId>
        </Inline>
        <TestId testId={'cancel'}>
          <Button variant={'outlined'} onClick={onClose}>
            Cancel
          </Button>
        </TestId>
      </>
    );
  }

  return (
    <ActionsDialog
      open={open}
      onClose={onClose}
      title='Leave the page'
      actions={<Actions />}>
      <Text variant={'normal'}>
        You are about to leave the page, all unsaved changes will be lost. Do
        you want to continue?
      </Text>
      {children ? (
        <>
          <Spacing size={'small'} />
          {children}
        </>
      ) : null}
    </ActionsDialog>
  );
}

function isButtonDisabled(s: ButtonState): boolean {
  switch (s) {
    case 'disabled':
    case 'loading':
      return true;
    case 'active':
      return false;
  }
}
