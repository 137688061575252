import { SyntheticEvent, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  InputAdornment,
  IconButton,
  Link as MaterialLink,
  CircularProgress
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Form, Field } from 'react-final-form';
import {
  Button,
  FormGroup,
  FormButtons,
  AlertState,
  AlertDialog,
  Link
} from '../common';
import { TextFieldWrapper } from './wrappers';
import { Pages } from '../../router/constants';

interface Values {
  email: string;
  password: string;
}
type Errors = {
  [K in keyof Values]?: string;
};

interface LoginFormProps {
  loading?: boolean;
  error?: string;
  onSubmit: (credentials: {
    username: string;
    password: string;
  }) => Promise<void>;
}

const useStyles = makeStyles({
  form: {
    width: '100%'
  },
  needHelpLink: {
    flexGrow: 1,
    display: 'flex',
    columnGap: 10
  }
});

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

function LoginForm({ loading = false, error, onSubmit }: LoginFormProps) {
  const classes = useStyles();
  const [isShownPassword, setIsShownPassword] = useState(false);
  const isSubmitDisabled = loading;

  const initialValues: Values = {
    email: '',
    password: ''
  };

  const handleSubmit = ({ email, password }: Values) => {
    onSubmit({ username: email, password });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validateForm}
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          {!!error && <AlertState type='error'>{error}</AlertState>}
          <FormGroup>
            <Field<string>
              testid='login-form-email'
              name='email'
              type='email'
              component={TextFieldWrapper}
              label='Email'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              testid='login-form-password'
              name='password'
              type={isShownPassword ? 'text' : 'password'}
              label='Password'
              component={TextFieldWrapper}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => setIsShownPassword(!isShownPassword)}>
                      {isShownPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormGroup>
          <FormButtons>
            <div className={classes.needHelpLink}>
              <AlertDialog
                buttonRenderer={({ onClick }) => (
                  <MaterialLink
                    href='#'
                    variant='caption'
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault();
                      onClick();
                    }}>
                    Need help?
                  </MaterialLink>
                )}>
                If you experience problems while logging into to Management
                Console or you need login credentials, please forward your
                request to support@tractionfive.com and describe the problem. We
                will get back to you shortly.
              </AlertDialog>

              <MaterialLink
                to={{ pathname: Pages.RESET_PASSWORD }}
                component={Link}
                variant='caption'
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                }}>
                Reset Password
              </MaterialLink>
            </div>

            <Button
              data-testid='login-form-submit'
              type='submit'
              disabled={isSubmitDisabled}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Login'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default LoginForm;
