import { AxiosError } from 'axios';
import { startOfMonth, startOfToday } from 'date-fns';
import { Dispatch, useEffect, useReducer } from 'react';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { report } from '../../api/OfficeHours';
import { fromDate } from '../../api/OfficeHours/types/DateFormat';
import { Tenant } from '../../api/auth';
import { unreachableError } from '../../utils/unreachableError';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { loading, State } from './types/State';

export function useOfficeHoursReports(
  user: Tenant,
): [State, Dispatch<Actions.Actions>] {
  const [s, d] = useReducer(
    reducer,
    loading({
      tenant: user as Tenant,
      includeLogo: true,
      includeSummary: true,
      start: startOfMonth(new Date()),
      end: startOfToday(),
    }),
  );

  useEffect(() => {
    switch (s.type) {
      case 'Loading': {
        const loading$ = from(
          report(fromDate(s.payload.start), fromDate(s.payload.end)),
        )
          .pipe(
            map(Actions.loadSuccess),
            catchError((e: AxiosError) =>
              of(Actions.loadError(e.response?.data.message)),
            ),
          )
          .subscribe(d);

        return () => loading$.unsubscribe();
      }
      case 'LoadError':
      case 'Ready':
      case 'CustomizeReady':
      case 'CustomizeEmpty':
        return;
      default:
        unreachableError(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type]);

  return [s, d];
}
