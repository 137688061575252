import { useCallback, useMemo, useState } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { COLORS } from '../theme/variables';
import {
  Text,
  TextField,
  Button,
  FormButtons,
  AlertState
} from '../components/common';
import { useHistory } from 'react-router-dom';
import { Pages } from '../router/constants';
import { validateEmail } from '../utils/functions';
import authAPI from '../api/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25
  },
  block: {
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px'
  },
  logo: {
    width: 300
  },
  containerSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    height: '100vh',

    [theme.breakpoints.up('xs')]: {
      width: '100%'
    }
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
    boxSizing: 'border-box',
    padding: 20,
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)'
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 20
  },
  successText: {
    textAlign: 'center'
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1
  },
  alert: {
    wordBreak: 'break-word'
  }
}));

function ResetPasswordPage() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isTouchedEmail, setIsTouchedEmail] = useState(false);

  const isValidEmail = useMemo(() => {
    return email && validateEmail(email);
  }, [email]);

  const handleLogin = useCallback(async () => {
    if (!isValidEmail) {
      return;
    }
    setIsTouchedEmail(false);
    try {
      setError('');
      setLoading(true);
      await authAPI.resetPassword(encodeURIComponent(email));
      setIsSuccess(true);
      const timeRedirect = setTimeout(() => {
        history.push(Pages.LOGIN);
        clearTimeout(timeRedirect);
      }, 4000);
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setLoading(false);
    }
  }, [email, history, isValidEmail]);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleBlur = () => {
    setIsTouchedEmail(true);
  };

  if (isSuccess) {
    return (
      <div
        data-testid='register-page-success'
        className={classes.containerSuccess}>
        <div className={classes.successBlock}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Your password has been reset.
              <br />
              Now you are going to be forwarded to the login page.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid='reset-password-page' className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      <div className={classes.block}>
        {!!error && (
          <AlertState type='error' className={classes.alert}>
            {error}
          </AlertState>
        )}
        <TextField
          name='email'
          type='email'
          label='Email'
          error={isTouchedEmail ? !isValidEmail : false}
          onBlur={handleBlur}
          value={email}
          onChange={handleChange}
        />
        <FormButtons className={classes.formButtons}>
          <Button onClick={handleLogin} disabled={loading || !isValidEmail}>
            {loading ? <CircularProgress size={24} color='inherit' /> : 'Send'}
          </Button>
        </FormButtons>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
