import * as AdvisorStatus from '../../../../api/Advisors/types/Status';
import { Sentence } from '../../../../utils/String/Sentence';

// region None
export interface None {
  type: 'none';
}

export const none = (): None => ({ type: 'none' });
// endregion

// region Search
export interface Search {
  type: 'search';
  value: Sentence<10000>;
}

export const search = (value: Sentence<10000>): Search => ({
  type: 'search',
  value,
});
// endregion

// region Status
export interface Status {
  type: 'status';
  value: AdvisorStatus.Status;
}

export const status = (value: AdvisorStatus.Status): Status => ({
  type: 'status',
  value,
});
// endregion

export type Filter = None | Search | Status;
