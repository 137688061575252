import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { COLORS } from '../../theme/variables';

export interface StepProgressProps {
  steps: number;
  value: number;
  className?: string;
}

export function StepProgress({ steps, value, className }: StepProgressProps) {
  const _steps = Math.max(0, steps);
  const _value = Math.min(value, _steps);
  const c = useStyles();

  return (
    <div className={classNames(c.root, className)}>
      {Array.from({ length: _steps }).map((_, index) => (
        <div
          className={classNames(c.step, { [c.active]: index <= _value - 1 })}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'stretch',
    height: 4,
    gap: 1,
    flex: 1,
  },
  step: {
    flex: 1,
    borderRadius: 1,
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,

    '&:first-child': {
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },

    '&:last-child': {
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
  active: {
    backgroundColor: COLORS.COLOR_BLUE_BASE,
  },
}));
