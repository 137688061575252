import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { KnownModule } from '../../api/user/Module';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { checkDynamicPath } from '../../utils/functions';

const useStyles = makeStyles({
  container: {
    padding: '0 40px',
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  scrollContainer: {
    '& .MuiTabs-scroller': {
      overflowX: 'auto !important',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
});

const checkIfSettings = (pathname: string) => {
  const settingsPages = [
    Pages.TENANT_SETTINGS,
    Pages.TENANT_QUESTIONS,
    Pages.TENANT_COHORTS,
    Pages.TENANT_AUTOMATION,
    Pages.TENANT_CUSTOM_SPECIALIZATIONS,
  ];

  return settingsPages.some((p) => p === pathname)
    ? pathname
    : Pages.TENANT_SETTINGS;
};

function PageTabs() {
  const location = useLocation();
  const classes = useStyles();
  const { hasAccessToAction, hasAccessToModal } = useContext(UserContext);

  // This is a hack to make settings tab active both on settings and questions page,
  // because Material-UI Tabs work by static values instead of boolean expressions.
  const selectedSettings = checkIfSettings(location.pathname);

  const selectedForms = checkDynamicPath(
    location.pathname,
    Pages.TENANT_FORMS_EDIT,
  )
    ? location.pathname
    : Pages.TENANT_FORMS;

  const selectedCustomQuestions = location.pathname.includes(
    Pages.TENANT_CUSTOM_QUESTIONS,
  )
    ? location.pathname
    : Pages.TENANT_CUSTOM_QUESTIONS;

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        className={classes.scrollContainer}>
        {hasAccessToAction('edit') && (
          <Tab
            data-testid='tenant-nav-profile'
            value={Pages.TENANT_PROFILE}
            label='Profile'
            component={Link}
            to={Pages.TENANT_PROFILE}
          />
        )}
        <Tab
          data-testid='tenant-nav-account'
          value={Pages.TENANT_ACCOUNT}
          label='Account'
          component={Link}
          to={Pages.TENANT_ACCOUNT}
        />
        {hasAccessToAction('settings.view') && (
          <Tab
            data-testid='tenant-nav-settings'
            value={selectedSettings}
            label='Settings'
            component={Link}
            to={Pages.TENANT_SETTINGS}
          />
        )}
        {hasAccessToAction('channels.view') && (
          <Tab
            data-testid='tenant-nav-channels'
            value={Pages.TENANT_CHANNELS}
            label='Channels'
            component={Link}
            to={Pages.TENANT_CHANNELS}
          />
        )}
        {hasAccessToAction('userManagement.view') && (
          <Tab
            data-testid='tenant-nav-user-management'
            value={Pages.TENANT_USER_MANAGEMENT}
            label='User Management'
            component={Link}
            to={Pages.TENANT_USER_MANAGEMENT}
          />
        )}
        {hasAccessToAction('modules.enrollment.view') &&
          hasAccessToModal(KnownModule.Enrollment) && (
            <Tab
              data-testid='tenant-nav-forms'
              value={selectedForms}
              label='Forms'
              component={Link}
              to={Pages.TENANT_FORMS}
            />
          )}
        {hasAccessToAction('consents.view') && (
          <Tab
            data-testid='tenant-nav-consents'
            value={Pages.TENANT_CONSENTS}
            label='Commitments'
            component={Link}
            to={Pages.TENANT_CONSENTS}
          />
        )}
        {hasAccessToAction('customQuestions.view') && (
          <Tab
            data-testid='tenant-nav-custom-questions'
            value={selectedCustomQuestions}
            label='Custom Forms'
            component={Link}
            to={Pages.TENANT_CUSTOM_QUESTIONS}
          />
        )}
      </Tabs>
    </div>
  );
}

export default PageTabs;
