import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { useCallback, useState, useMemo } from 'react';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../../api/CustomQuestion/types/Type';
import {
  CustomQuestionsFormValues,
  useCustomQuestionContext,
} from '../../contexts/custom-questions-context';
import { Text, TextField } from '../common';
import { Inline } from '../common/Inline';
import Button from '../common/button';
import Dialog from '../common/dialog';

export type CustomOptionsErrors = {
  [key: string]: string | null;
};

type FormErrors = {
  [key: string]: string | CustomOptionsErrors | undefined;
  optionsList?: CustomOptionsErrors;
};

const useStyles = makeStyles({
  root: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    transition: 'height 0.2s ease-out',
    '@media (max-width: 700px)': {
      width: 'calc(100vw - 104px)',
    },
  },
  inline: {
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      maxHeight: 0,
      opacity: 0,
    },
    '100%': {
      maxHeight: 'none',
      opacity: 1,
    },
  },
});

const validateForm = (
  formValues: CustomQuestionsFormValues,
): { errors: FormErrors; isValid: boolean } => {
  const withOptions =
    formValues.type === CustomQuestionType.MULTIPLE_CHOICE ||
    formValues.type === CustomQuestionType.SINGLE_CHOICE;
  const errors: FormErrors = {};
  if (!formValues.type) {
    errors.type = 'Required';
  }
  if (!formValues.label.trim()) {
    errors.label = 'Required';
  }
  if (withOptions && !formValues.options?.length) {
    errors.options = 'Required';
  }
  if (withOptions && formValues.options?.length) {
    const optionsListErrors = formValues.options.reduce(
      (acc: CustomOptionsErrors, option) => {
        if (!option.label.trim()) acc[option.id] = 'Required';
        return acc;
      },
      {},
    );
    if (!isEmpty(optionsListErrors)) {
      errors.optionsList = optionsListErrors;
    }
  }
  const isValid = isEmpty(errors);
  return { errors, isValid };
};

const getDefaultFormValues = (
  editableQuestion?: CustomQuestion | null,
): CustomQuestionsFormValues =>
  editableQuestion
    ? {
        type: editableQuestion.type,
        label: editableQuestion.label,
        isMandatory: editableQuestion.isMandatory,
        options: editableQuestion.options || [],
      }
    : {
        type: CustomQuestionType.SUBTITLE,
        label: '',
        isMandatory: false,
        options: [],
      };

export function CustomSubtitleModal() {
  const classes = useStyles();
  const {
    editableQuestion,
    handleModalClose,
    handleQuestionCreate,
    handleQuestionUpdate,
    isCreating,
  } = useCustomQuestionContext();
  const [formValues, setFormValues] = useState<CustomQuestionsFormValues>(
    getDefaultFormValues(editableQuestion),
  );
  const [showErrors, setShowErrors] = useState(false);

  const { isValid, errors } = useMemo(() => {
    return validateForm(formValues);
  }, [formValues]);

  const handleSubmit = useCallback(async () => {
    if (isValid) {
      editableQuestion
        ? handleQuestionUpdate(formValues)
        : handleQuestionCreate(formValues);
    } else {
      setShowErrors(true);
    }
  }, [
    editableQuestion,
    formValues,
    handleQuestionCreate,
    handleQuestionUpdate,
    isValid,
  ]);

  return (
    <Dialog
      title='Create Custom Subtitle'
      contentRenderer={() => (
        <div data-testid='custom-question-modal' className={classes.root}>
          <Text>Fill subtitle details:</Text>
          <TextField
            value={formValues.label}
            data-testid={'subtitle-label'}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, label: e.target.value }))
            }
            label='Subtitle*'
            disabled={isCreating}
            error={showErrors && !!errors?.label}
          />
        </div>
      )}
      open
      setOpen={(v) => !v && handleModalClose()}
      actions={
        <Inline gap={15} className={classes.inline}>
          <Inline gap={15}>
            <Button
              disabled={isCreating}
              onClick={handleSubmit}
              data-testid='apply-subtitle-button'
              color={'primary'}>
              {isCreating ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
            <Button onClick={handleModalClose} variant='outlined'>
              Cancel
            </Button>
          </Inline>
        </Inline>
      }
    />
  );
}
