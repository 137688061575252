import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../../api/CustomQuestion/types/Type';
import {
  CustomSelect,
  FormGroup,
  Text,
  TextField,
  TextWysiwyg,
} from '../../components/common';
import { MONTSERRAT_FONT_FAMILY } from '../../theme/variables';

const useStyles = makeStyles({
  label: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '24px',
    lineHeight: '36px',
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
  },
});

type CustomQuestionPreviewProps = {
  item: CustomQuestion;
};

export function CustomQuestionPreview({ item }: CustomQuestionPreviewProps) {
  switch (item.type) {
    case CustomQuestionType.TEXT:
      return <TextField label={item.label} />;
    case CustomQuestionType.SINGLE_CHOICE:
      return <SingleChoicePreview item={item} />;
    case CustomQuestionType.MULTIPLE_CHOICE:
      return <MultipleChoicePreview item={item} />;
    case CustomQuestionType.NUMBER:
      return <NumberPreview item={item} />;
    case CustomQuestionType.PARAGRAPH:
      return <TextWysiwygPreview item={item} />;
    case CustomQuestionType.SUBTITLE:
      return <SubtitlePreview item={item} />;
    default:
      return null;
  }
}

const TextWysiwygPreview = ({ item }: CustomQuestionPreviewProps) => {
  const [value, setValue] = useState('');
  return (
    <TextWysiwyg
      label={item.label}
      placeholder={item.label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const NumberPreview = ({ item }: CustomQuestionPreviewProps) => {
  return (
    <TextField
      label={item.label}
      type='number'
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      onKeyPress={(event) => {
        const numericRegex = /^[0-9]*$/;
        if (!numericRegex.test(event.key)) {
          event.preventDefault();
        }
      }}
    />
  );
};

const SubtitlePreview = ({ item }: CustomQuestionPreviewProps) => {
  const classes = useStyles();
  return <h3 className={classes.subtitle}>{item.label}</h3>;
};

const SingleChoicePreview = ({ item }: CustomQuestionPreviewProps) => {
  const [value, setValue] = useState('');
  return (
    <CustomSelect
      label={item.label}
      onChange={(e) => setValue(e.target.value)}
      options={
        item.options?.map((option) => ({
          label: option.label,
          value: option.label,
        })) || []
      }
      value={value}
    />
  );
};

const MultipleChoicePreview = ({ item }: CustomQuestionPreviewProps) => {
  const classes = useStyles();
  return (
    <FormGroup column>
      <Text variant='normal' className={classes.label}>
        {item.label}
      </Text>
      {item.options?.map((option, idx) => (
        <FormControlLabel
          control={<Checkbox color='primary' />}
          label={option.label}
          key={`checkbox-${idx}`}
        />
      ))}
    </FormGroup>
  );
};
