import { IconButton, makeStyles } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate } from '../../utils/date';
import { COLORS } from '../../theme/variables';
import { ConfirmButton, Text } from '../common';
import { useState } from 'react';
import RecordForm, { Values as FormValues } from '../forms/finance-record';
import { VentureRecord } from '../../api/ventures';

export interface VentureRecordValue {
  id: string;
  ventureId: string;
  creationDate: string;
  type: string;
  contents: number;
  recordDate: string;
}

interface FinanceRecordProps {
  ventureId: string;
  record: VentureRecord;
  updating?: boolean;
  deleting?: boolean;
  onEdit: (updatedRecord: VentureRecord, callback: () => any) => any;
  onRemove: (record: VentureRecord, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  main: {
    display: 'flex'
  },
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    marginRight: 10
  },
  body: {
    wordBreak: 'break-word',

    [theme.breakpoints.up('sm')]: {
      wordBreak: 'break-all'
    }
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px'
  },
  attachment: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px'
  },
  comment: {
    marginTop: 10
  }
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

function FinanceRecord({
  ventureId,
  record,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index
}: FinanceRecordProps) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleRecordEdit = () => {
    setIsEditing(true);
  };

  const handleRecordRemove = (callback: () => any) => {
    onRemove(record, callback);
  };

  const handleRecordEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitRecordForm = (recordDetails: FormValues) => {
    const stringContents = String(recordDetails.contents);
    onEdit(
      {
        ventureId,
        id: record.id,
        ...recordDetails,
        contents: stringContents
      },
      handleRecordEditCancel
    );
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <RecordForm
          title='Record editing'
          record={record}
          loading={updating}
          onSubmit={handleSubmitRecordForm}
          onCancel={handleRecordEditCancel}
        />
      ) : (
        <>
          <div className={classes.title} data-testid={`finance-${index}`}>
            <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
              {formatDate(record.creationDate)}
            </Text>
            {!readOnly && (
              <div>
                <IconButton
                  onClick={handleRecordEdit}
                  data-testid={`form-finance-edit-${index}`}>
                  <EditIcon />
                </IconButton>
                <ConfirmButton
                  loading={deleting}
                  title='Remove the record?'
                  body='Sure you want to remove the record? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRecordRemove
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`form-finance-delete-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}></ConfirmButton>
              </div>
            )}
          </div>
          <div className={classes.main}>
            <span className={classes.icon}>
              {record.type.includes('Revenue') ? (
                <MonetizationOnIcon />
              ) : (
                <AccountBalanceIcon />
              )}
            </span>
            <Text className={classes.body} variant='normal'>
              {record.type} Recorded:{' '}
              <Text variant='normal' bold>
                {formatter.format(+record.contents)}
              </Text>{' '}
              on{' '}
              <Text variant='normal' bold>
                {formatDate(record.recordDate)}
              </Text>
            </Text>
          </div>
          <div className={classes.comment}>
            <Text variant='normal'>{record.comment}</Text>
          </div>
        </>
      )}
    </div>
  );
}

export default FinanceRecord;
