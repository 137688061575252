import { makeStyles, Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '.';
import foundersAPI, { Founder } from '../../api/founders';
import { VentureId } from '../../api/ventures/types/Venture';
import { AssignFoundersForm } from '../../components/forms/assign-founders';
import { TestId } from '../Testing/TestId';
import FoundersTable from '../ventures/founders-table';
import { AddNewIcon } from './ActionIcons/AddNewIcon';

const useStyles = makeStyles({
  container: {
    minHeight: 70,
  },
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
  title: {
    display: 'flex',
    gap: '16px',
  },
});

interface Props {
  ventureId: VentureId | undefined;
  founders?: Founder[];
  onChange: (founders: Founder[] | undefined) => any;
  readOnly?: boolean;
}

export function VentureFounderSelect({
  ventureId,
  founders,
  onChange,
  readOnly = false,
}: Props) {
  const classes = useStyles();
  const [isAssigningVenture, setIsAssigningVenture] = useState(false);
  const isInited = useRef<boolean>(false);

  const loadFouders = async (ventureId: VentureId) => {
    try {
      setIsAssigningVenture(true);
      const loadedFounders = await foundersAPI.getFoundersDetailsByVentureId(
        ventureId,
      );
      onChange(loadedFounders);
      setIsAssigningVenture(false);
    } catch (e: any) {
      console.log('error loadVentures', e);
      setIsAssigningVenture(false);
    }
  };

  const handleRemoveFounders = useCallback(
    (founder: Founder) => {
      const currentFounders = founders?.filter(
        (value) => value.id !== founder.id,
      );
      onChange(currentFounders);
    },
    [onChange, founders],
  );

  const handleAssignSuccessForm = useCallback(
    (handleModalClose: () => void) =>
      async ({
        assigned,
        removed,
        all,
      }: {
        assigned: string[];
        removed: string[];
        all: Founder[];
      }) => {
        if (assigned.length || removed.length) {
          onChange(all);
        }
        return handleModalClose();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (ventureId && isInited.current) {
      loadFouders(ventureId);
    } else {
      isInited.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventureId]);

  return (
    <div className={classes.container}>
      <Modal
        title='Add a founder'
        contentRenderer={({ handleClose }) => (
          <AssignFoundersForm
            ventureId={ventureId}
            loading={isAssigningVenture}
            founders={founders}
            onSuccess={handleAssignSuccessForm(handleClose)}
            onCancel={handleClose}
          />
        )}
        buttonRenderer={({ onClick }) => (
          <div className={classes.title}>
            <Typography variant='h4'>Founders</Typography>
            {!readOnly && (
              <TestId testId={'button-assignment-founder'}>
                <AddNewIcon onClick={onClick} />
              </TestId>
            )}
          </div>
        )}
        width={720}
      />

      {!!founders && founders.length > 0 && (
        <div className={classes.table}>
          <FoundersTable
            founders={founders}
            showTitle={false}
            onRemove={handleRemoveFounders}
            fullWidth={true}
            readOnly={readOnly}
            isLink={!readOnly}
          />
        </div>
      )}
    </div>
  );
}
