import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useDebouncedOnChange } from '../../../hooks/useDebouncedOnChange';
import { TextField } from '../../common';

export interface Props {
  value: string;
  onChange: (v: string) => void;
  className?: string;
  small?: boolean;
}

export function Search({ value, onChange, className, small = true }: Props) {
  const [_v, handleChange, commit] = useDebouncedOnChange(value, onChange, 500);

  return (
    <TextField
      className={className}
      value={_v}
      onChange={(v) => handleChange(v.target.value)}
      placeholder='Search'
      onBlur={commit}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      small={small}
    />
  );
}
