import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo } from 'react';
import { Form, Field, useForm } from 'react-final-form';
import * as yup from 'yup';
import { Specialization } from '../../api/specializations';
import { SpecializationContext } from '../../contexts/specialization-context';
import { yupValidate } from '../../utils/yup';
import { Button, FormGroup, FormButtons, BeforeUnload } from '../common';
import { TextFieldWrapper } from './wrappers';

export interface Values {
  name: string;
}

interface CustomSpecializationFormProps {
  title: string;
  loading?: boolean;
  specialization?: Specialization;
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
}));

const SPECIALIZATION_MAX_LENGTH = 30;
const DUPLICATE_SPECIALIZATION_ERROR =
  'You cannot add a specialization with the same name that already exists';

function CustomSpecializationForm({
  title,
  specialization,
  loading = false,
  onSubmit,
  onCancel,
}: CustomSpecializationFormProps) {
  const classes = useStyles();
  const { specializations } = useContext(SpecializationContext);

  const validationSchema = useMemo(() => {
    const filteredSpecializations = specializations.filter(
      (s) => s.id !== specialization?.id,
    );
    const names = filteredSpecializations.map((s) => s.name);
    return yup.object().shape({
      name: yup
        .string()
        .max(SPECIALIZATION_MAX_LENGTH)
        .required()
        .notOneOf(names, DUPLICATE_SPECIALIZATION_ERROR),
    });
  }, [specialization?.id, specializations]);

  const initialValues: Values = {
    name: specialization?.name || '',
  };
  const handleSubmit = (values: Values) => {
    onSubmit(values);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={yupValidate(validationSchema)}
        keepDirtyOnReinitialize
        render={(formProps) => {
          return (
            <form
              className={classes.form}
              onSubmit={formProps.handleSubmit}
              noValidate>
              <NotificationError />
              <BeforeUnload
                when={formProps.dirty && !loading}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={loading}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      onConfirm();
                    }}
                    disabled={loading || !formProps.valid}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <FormGroup>
                <Field<string>
                  name='name'
                  data-testid='form-specialization-name'
                  component={TextFieldWrapper}
                  label='Name*'
                  InputProps={{
                    inputProps: {
                      maxLength: SPECIALIZATION_MAX_LENGTH,
                    },
                  }}
                />
              </FormGroup>
              <FormButtons className={classes.formButtons}>
                <Button
                  data-testid='form-specialization-submit'
                  type='submit'
                  disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                  Cancel
                </Button>
              </FormButtons>
            </form>
          );
        }}
      />
    </>
  );
}

const NotificationError = () => {
  const form = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const formState = form.getState();
  const nameError = formState.errors?.name;

  useEffect(() => {
    if (nameError === DUPLICATE_SPECIALIZATION_ERROR) {
      enqueueSnackbar(DUPLICATE_SPECIALIZATION_ERROR, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, nameError]);

  return null;
};

export default CustomSpecializationForm;
