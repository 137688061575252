import { TenantId } from '../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { Filter } from './Filter';
import { Item } from './Item';

// region Loading
export interface LoadingPayload {
  tenantId: TenantId;
  timeZone: TenantTimeZone;
  page: number;
  filter: Filter;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  items: Item[];
  next: boolean;
  prev: boolean;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmationPayload extends ReadyPayload {
  id: Item['id'];
}

export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: RemoveConfirmationPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload'],
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload,
});
// endregion

// region Removing
export interface RemovingPayload extends RemoveConfirmationPayload {}

export interface Removing {
  type: 'Removing';
  payload: RemovingPayload;
}

export const removing = (payload: Removing['payload']): Removing => ({
  type: 'Removing',
  payload,
});
// endregion

// region RemoveErr
export interface RemoveErrPayload extends RemovingPayload {
  message: string;
}

export interface RemoveErr {
  type: 'RemoveErr';
  payload: RemoveErrPayload;
}

export const removeErr = (payload: RemoveErr['payload']): RemoveErr => ({
  type: 'RemoveErr',
  payload,
});
// endregion

// region UpdateConfirmation
export interface UpdateConfirmationPayload extends ReadyPayload {
  status: Item['status'];
}

export interface UpdateConfirmation {
  type: 'UpdateConfirmation';
  payload: UpdateConfirmationPayload;
}

export const updateConfirmation = (
  payload: UpdateConfirmation['payload'],
): UpdateConfirmation => ({
  type: 'UpdateConfirmation',
  payload,
});
// endregion

// region Updating
export interface UpdatingPayload extends UpdateConfirmationPayload {}

export interface Updating {
  type: 'Updating';
  payload: UpdatingPayload;
}

export const updating = (payload: Updating['payload']): Updating => ({
  type: 'Updating',
  payload,
});
// endregion

// region UpdateErr
export interface UpdateErrPayload extends UpdatingPayload {
  message: string;
}

export interface UpdateErr {
  type: 'UpdateErr';
  payload: UpdateErrPayload;
}

export const updateErr = (payload: UpdateErr['payload']): UpdateErr => ({
  type: 'UpdateErr',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | RemoveConfirmation
  | Removing
  | RemoveErr
  | UpdateConfirmation
  | Updating
  | UpdateErr;

export function isEditable(s: State): s is Ready | RemoveErr | UpdateErr {
  switch (s.type) {
    case 'Ready':
    case 'RemoveErr':
    case 'UpdateErr':
      return true;
    case 'LoadError':
    case 'Loading':
    case 'Removing':
    case 'RemoveConfirmation':
    case 'UpdateConfirmation':
    case 'Updating':
      return false;
  }
}

export function isNotConfirmation(
  s: State,
): s is
  | Loading
  | LoadError
  | Ready
  | Removing
  | RemoveErr
  | Updating
  | UpdateErr {
  switch (s.type) {
    case 'LoadError':
    case 'Ready':
    case 'Loading':
    case 'RemoveErr':
    case 'Removing':
    case 'UpdateErr':
    case 'Updating':
      return true;
    case 'RemoveConfirmation':
    case 'UpdateConfirmation':
      return false;
  }
}
