import { Button, Theme, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement, ReactNode, useContext } from 'react';
import { TenantId } from '../../../api/tenants/Tenant';
import { useAdvisorSettings } from '../../../contexts/advisor-settings-context';
import { DrawerContext } from '../../../contexts/drawer-context';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../../theme/variables';
import { TestId } from '../../Testing/TestId';
import { Link, Text } from '../../common';
import { TenantLogo } from '../../common/TenantLogo';
import Help from '../../header/help';

export interface Props {
  to: string;
  id: TenantId;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  space: {
    width: '100%',
    height: '61px',
  },
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'grid',
    gap: '10px',
    alignItems: 'center',
    padding: '4px 28px',
    boxShadow:
      '0px 0px 2px rgb(34 91 187 / 20%), 0px 0px 8px rgb(51 126 255 / 20%)',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'start',
    background: '#fff',
    zIndex: 10,

    [theme.breakpoints.down('xs')]: {
      padding: '4px',
    },
  },
  content: {
    maxWidth: '1360px',
  },
  actions: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  image: {
    height: '51px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_BASE,
    textDecoration: 'none',
  },
}));

const useHelpButtonStyles = makeStyles((theme: Theme) => ({
  unset: {
    '& ul, ol': {
      listStyle: 'revert',
      padding: 'revert',
      maring: 'revert',
    },
  },
  contactUs: {
    width: '100%',
    marginTop: 24,
  },
}));

export function LogoOnly({ to, id, children }: Props): ReactElement {
  const classes = useStyles();
  const { settings } = useAdvisorSettings();

  return (
    <>
      <div className={classes.space}></div>
      <div className={classes.root}>
        <TestId testId={'header-logo'}>
          <Link to={to} className={classes.logo}>
            <TenantLogo id={id} className={classes.image} />
            {settings?.programName}
          </Link>
        </TestId>
        <div className={classes.actions}>
          <HelpButton />
          {children}
        </div>
      </div>
    </>
  );
}

function HelpButton() {
  const classes = useHelpButtonStyles();
  const { createDrawer } = useContext(DrawerContext);

  const handleHelpOpen = () => {
    createDrawer({
      title: 'Help Articles',
      body: (
        <div className={classes.unset}>
          <Text variant='normal'>
            Please find below a list of articles that will help you understand
            how to use Slice of Mentorship platform.
            <br />
            <br />
            <div>
              <a href='https://capricious-mangosteen-1a4.notion.site/Slice-of-Mentorship-User-Guide-100f58a1e49480e39e41ee2a2ba0992a'>
                User Guide
              </a>
            </div>
          </Text>
          <Button
            href={'mailto:igor@fractionalCMOs.com'}
            className={classes.contactUs}
            variant='contained'
            color='primary'>
            Contact Us
          </Button>
        </div>
      ),
    });
  };

  return (
    <IconButton onClick={handleHelpOpen} data-testid='menu-button-help'>
      <Help />
    </IconButton>
  );
}
