import { CommunityMemberId } from '../../../../../api/CommunityMembers/types/CommunityMember';

// region Open
export interface Open {
  type: 'Open';
}

export const open = (): Open => ({
  type: 'Open',
});
// endregion

// region Send
export interface Send {
  type: 'Send';
  payload: CommunityMemberId[];
}

export const send = (payload: Send['payload']): Send => ({
  type: 'Send',
  payload,
});
// endregion

// region Cancel
export interface Cancel {
  type: 'Cancel';
}

export const cancel = (): Cancel => ({
  type: 'Cancel',
});
// endregion

// region SendError
export interface SendError {
  type: 'SendError';
  payload: {
    id: CommunityMemberId;
    message: string;
  };
}

export const sendError = (payload: SendError['payload']): SendError => ({
  type: 'SendError',
  payload,
});
// endregion

// region SendSuccess
export interface SendSuccess {
  type: 'SendSuccess';
  payload: CommunityMemberId;
}

export const sendSuccess = (payload: SendSuccess['payload']): SendSuccess => ({
  type: 'SendSuccess',
  payload,
});
// endregion

export type Actions = Open | Send | Cancel | SendError | SendSuccess;
