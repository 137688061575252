import * as State from './types/State';
import { Actions } from './types/Actions';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Submit':
      return s.type === 'Ready'
        ? a.payload === true
          ? State.cancelling(s.payload)
          : State.declined()
        : s;
    case 'CancellingError':
      return s.type === 'Cancelling'
        ? State.cancellingError({ ...s.payload, message: a.payload })
        : s;
    case 'CancellingSuccess':
      return s.type === 'Cancelling' ? State.cancellingSuccess(s.payload) : s;
  }
}
