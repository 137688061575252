import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { identity, pipe } from 'ramda';
import { Field, Form } from 'react-final-form';
import { apply, Either, isLeft, parseObject, right } from '../../utils/Either';
import { fromString, Sentence } from '../../utils/String/Sentence';
import { GENDER_MENTOR, RACE, RADIO_SELECT } from '../../utils/form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { BeforeUnload, Button, FormGroup } from '../common';
import { FormSelect } from './wrappers';

interface Props {
  initialValues: FormAdditionalInfo;
  isLoading: boolean;
  onSubmit: (value: ValidFormAdditionalInfo) => void;
  hasAccessToUpdate?: boolean;
}

export interface FormAdditionalInfo {
  gender: string | null;
  race: string | null;
  veteran: 'true' | 'false';
}

export interface ValidFormAdditionalInfo {
  gender: Sentence<20> | null;
  race: Sentence<50> | null;
  veteran: 'true' | 'false';
}

type Errors = Partial<Record<keyof FormAdditionalInfo, string>>;

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '65px 0 0 80px',
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  multilineField: {
    minHeight: 64,
  },
  checkbox: {
    paddingLeft: 0,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

function getValidForm(
  values: FormAdditionalInfo,
): Either<Errors, ValidFormAdditionalInfo> {
  return parseObject<FormAdditionalInfo, ValidFormAdditionalInfo, Errors>(
    {
      gender: (v) => (v ? fromString(20, v) : right(null)),
      race: (v) => (v ? fromString(50, v) : right(null)),
      veteran: (v) => right(v),
    },
    values,
  );
}

const validateForm = (values: FormAdditionalInfo) => {
  const r = getValidForm(values);

  return isLeft(r) ? r.value : {};
};

function FounderAdditionalInfoForm({
  initialValues,
  isLoading,
  onSubmit,
  hasAccessToUpdate = false,
}: Props) {
  const loading = isLoading;
  const classes = useStyles();

  const handleSubmit = pipe(getValidForm, apply(identity, onSubmit));

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        validate={validateForm}
        render={(formProps) => (
          <div>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.mainFormBlock}>
              <form noValidate>
                <div className={classes.sectionBlock}>
                  <FormGroup>
                    <Field<string>
                      name='gender'
                      testid='founder-additional-gender'
                      component={FormSelect}
                      label='Gender'
                      options={GENDER_MENTOR}
                      readOnly={!hasAccessToUpdate}
                    />
                    <Field<string>
                      name='race'
                      testid='founder-additional-race'
                      component={FormSelect}
                      label='Race'
                      options={RACE}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field<string>
                      name='veteran'
                      testid='founder-additional-veteran'
                      component={FormSelect}
                      label='Founder is a veteran'
                      options={RADIO_SELECT}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>
                </div>

                {hasAccessToUpdate && (
                  <div className={classes.actionsBlock}>
                    <Button
                      className={CLASS_TRACKING.INTERNAL_ACTION}
                      onClick={formProps.handleSubmit}
                      disabled={loading || !formProps.valid}
                      startIcon={<CheckIcon />}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default FounderAdditionalInfoForm;
