import { CommunityMember } from '../../../../api/CommunityMembers/types/CommunityMember';
import { Status } from '../../../../api/CommunityMembers/types/Status';
import { Item } from './Item';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    items: CommunityMember[];
    next: boolean;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: Item['id'];
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Deselect
export interface Deselect {
  type: 'Deselect';
  payload: Item['id'];
}

export const deselect = (payload: Deselect['payload']): Deselect => ({
  type: 'Deselect',
  payload,
});
// endregion

// region ToggleAll
export interface ToggleAll {
  type: 'ToggleAll';
}

export const toggleAll = (): ToggleAll => ({
  type: 'ToggleAll',
});
// endregion

// region ChangeStatus
export interface ChangeStatus {
  type: 'ChangeStatus';
  payload: Status;
}

export const changeStatus = (
  payload: ChangeStatus['payload'],
): ChangeStatus => ({
  type: 'ChangeStatus',
  payload,
});
// endregion

// region UpdateSingleStatus
export interface UpdateSingleStatus {
  type: 'UpdateSingleStatus';
  payload: Item['id'];
}

export const updateSingleStatus = (
  payload: UpdateSingleStatus['payload'],
): UpdateSingleStatus => ({
  type: 'UpdateSingleStatus',
  payload,
});
// endregion

// region UpdateConfirmation
export interface UpdateConfirmation {
  type: 'UpdateConfirmation';
  payload: boolean;
}

export const updateConfirmation = (
  payload: UpdateConfirmation['payload'],
): UpdateConfirmation => ({
  type: 'UpdateConfirmation',
  payload,
});
// endregion

// region UpdateSuccess
export interface UpdateSuccess {
  type: 'UpdateSuccess';
  payload: {
    items: CommunityMember[];
    next: boolean;
  };
}

export const updateSuccess = (
  payload: UpdateSuccess['payload'],
): UpdateSuccess => ({
  type: 'UpdateSuccess',
  payload,
});
// endregion

// region UpdateError
export interface UpdateError {
  type: 'UpdateError';
  payload: string;
}

export const updateError = (payload: UpdateError['payload']): UpdateError => ({
  type: 'UpdateError',
  payload,
});
// endregion

// region NextPage
export interface NextPage {
  type: 'NextPage';
}

export const nextPage = (): NextPage => ({
  type: 'NextPage',
});
// endregion

// region PrevPage
export interface PrevPage {
  type: 'PrevPage';
}

export const prevPage = (): PrevPage => ({
  type: 'PrevPage',
});
// endregion

// region SearchText
export interface SearchText {
  type: 'SearchText';
  payload: string;
}

export const searchText = (payload: SearchText['payload']): SearchText => ({
  type: 'SearchText',
  payload,
});
// endregion

// region SearchStatus
export interface SearchStatus {
  type: 'SearchStatus';
  payload: Status | undefined;
}

export const searchStatus = (
  payload: SearchStatus['payload'],
): SearchStatus => ({
  type: 'SearchStatus',
  payload,
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: 'ClearFilters';
}

export const clearFilters = (): ClearFilters => ({
  type: 'ClearFilters',
});
// endregion

export type PublicActions =
  | Toggle
  | ToggleAll
  | ChangeStatus
  | NextPage
  | PrevPage
  | UpdateConfirmation
  | SearchText
  | SearchStatus
  | ClearFilters
  | UpdateSingleStatus;

export type Actions =
  | LoadError
  | LoadSuccess
  | UpdateSuccess
  | UpdateError
  | PublicActions;
