import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import {
  createCheckin,
  getCheckins,
  removeCheckin,
  updateCheckin,
} from '../../api/goals';
import { Checkin as CheckinType, CreateCheckin } from '../../api/types/Checkin';
import { Checkin, PageLoader, SnackMessage } from '../../components/common';
import NewCheckin from '../../components/common/new-checkin';
import { useGoalContext } from '../../contexts/goal-context';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: 32,
  },
});

function GoalCheckinsPage() {
  const classes = useStyles();
  const { goalId } = useParams<{ goalId: string }>();
  const { hasAccessToAction } = useContext(UserContext);
  const { goal, updateGoalProgressAndStatus } = useGoalContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [checkins, setCheckins] = useState<CheckinType[]>([]);
  const [updatingCheckinsIds, setUpdaingCheckinsIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingCheckinsIds, setDeletingCheckinsIds] = useState<{
    [x: string]: boolean;
  }>({});

  const handleCheckinUpdate = useCallback(
    async (checkin: CheckinType, callback) => {
      try {
        setUpdaingCheckinsIds((prev) => ({ ...prev, [checkin.id]: true }));
        if (goalId) {
          await updateCheckin(checkin);
          setCheckins((prev) =>
            prev.map((prevCheckin) =>
              prevCheckin.id === checkin.id ? checkin : prevCheckin,
            ),
          );
        }
        setUpdaingCheckinsIds((prev) => ({ ...prev, [checkin.id]: false }));
        callback && callback();
        enqueueSnackbar('The check-in was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the check-in. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingCheckinsIds((prev) => ({ ...prev, [checkin.id]: false }));
      }
    },
    [goalId, enqueueSnackbar],
  );

  const handleCheckinRemove = useCallback(
    async (checkin: CheckinType, callback: () => any) => {
      try {
        setDeletingCheckinsIds((prev) => ({ ...prev, [checkin.id]: true }));
        if (goalId) {
          await removeCheckin(checkin.id);
          setCheckins((prev) =>
            prev.filter((prevCheckin) => prevCheckin.id !== checkin.id),
          );
        }
        callback();
        setDeletingCheckinsIds((prev) => ({ ...prev, [checkin.id]: false }));
        enqueueSnackbar('The check-in was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the check-in. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingCheckinsIds((prev) => ({ ...prev, [checkin.id]: false }));
      }
    },
    [goalId, enqueueSnackbar],
  );

  const loadCheckins = async (goalId: string) => {
    try {
      setIsLoading(true);
      const checkins = await getCheckins(goalId);
      setCheckins(checkins);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleCreateCheckin = useCallback(
    async (goal: CreateCheckin) => {
      try {
        if (goalId) {
          const createdCheckin = await createCheckin(goal);
          updateGoalProgressAndStatus({
            progress: createdCheckin.progress,
            status: createdCheckin.status,
          });
          setCheckins((prev) => [createdCheckin, ...prev]);
        }
        enqueueSnackbar('The check-in was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the check-in. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
      }
    },
    [goalId, enqueueSnackbar, updateGoalProgressAndStatus],
  );

  useEffect(() => {
    loadCheckins(goalId);
  }, [goalId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {hasAccessToAction('goal.checkins.create') && goal && (
        <NewCheckin goal={goal} onChange={handleCreateCheckin} />
      )}
      {checkins.map((checkin, index) => (
        <Checkin<CheckinType>
          key={checkin.id}
          checkin={checkin}
          updating={updatingCheckinsIds[checkin.id]}
          deleting={deletingCheckinsIds[checkin.id]}
          onEdit={handleCheckinUpdate}
          onRemove={handleCheckinRemove}
          readOnly={!hasAccessToAction('goal.checkins.update')}
          index={index}
        />
      ))}
    </div>
  );
}

export default GoalCheckinsPage;
