import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from 'react';
import specializationsAPI, { Specialization } from '../api/specializations';
import { TenantId } from '../api/tenants/Tenant';

export interface SpecializationContextProps {
  specializations: Specialization[];
  isLoading: boolean;
  setSpecializations: Dispatch<SetStateAction<Specialization[]>>;
  loadSpecializations: (tenantId?: TenantId) => any;
}

export const SpecializationContext = createContext<SpecializationContextProps>({
  specializations: [],
  isLoading: false,
  setSpecializations: () => {},
  loadSpecializations: () => {},
});

interface SpecializationProviderProps {
  children: React.ReactNode;
}

export const SpecializationProvider = ({
  children,
}: SpecializationProviderProps) => {
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadSpecializations = useCallback(async (tenantId?: TenantId) => {
    setIsLoading(true);
    try {
      const loaded = tenantId
        ? await specializationsAPI.getPublicAll(tenantId)
        : await specializationsAPI.getAll();
      setSpecializations(loaded);
    } catch (e: any) {
      console.log('error loadSpecializations', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const contextValue: SpecializationContextProps = {
    specializations,
    isLoading,
    setSpecializations,
    loadSpecializations,
  };

  return (
    <SpecializationContext.Provider value={contextValue}>
      {children}
    </SpecializationContext.Provider>
  );
};
