import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Founder } from '../../api/founders';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  TextField,
} from '../common';

interface AssignFoundersVentureFormProps {
  founderId: Founder['id'];
  ventures?: Venture[];
  loading?: boolean;
  onLoaded?: (ventures: Venture[]) => any;
  onSuccess: (params: {
    assigned: string[];
    removed: string[];
    all: Venture[];
  }) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  checkboxGroup: {
    maxHeight: 360,
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

function AssignFoundersVentureForm({
  founderId,
  ventures,
  loading = false,
  onLoaded = () => {},
  onSuccess,
  onCancel,
}: AssignFoundersVentureFormProps) {
  const classes = useStyles();
  const [loadedVentures, setLoadedVentures] = useState<Venture[]>(
    ventures || [],
  );
  const [initialVentures, setInitialVentures] =
    useState<Venture[]>(loadedVentures);
  const [recommended, setRecommended] = useState<Venture[]>();
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>([]);
  const [isLoading, setIsLoading] = useState<{
    ventures: boolean;
    recommended: boolean;
  }>({ ventures: !ventures, recommended: !recommended });
  const [searchIteration, setSearchIteration] = useState(0);

  const handleVenturesSearch = useCallback((searchedVentures: Venture[]) => {
    setDisplayedVentures(searchedVentures);
  }, []);

  const handleVentureRemove = (venture: Venture) => {
    setInitialVentures((prevVentures) =>
      prevVentures.filter((prevVenture) => prevVenture.id !== venture.id),
    );
  };

  const handleVentureAdd = (venture: Venture) => {
    setInitialVentures((prevVentures) => [...prevVentures, venture]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const loadRecommended = async () => {
    try {
      const result = await venturesAPI.getRecommendedVenturesByFounders();
      setRecommended(result);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    } catch (e: any) {
      console.log('error loadRecommended');
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    }
  };

  const loadInitialVentures = async (_founderId: string) => {
    try {
      const loadedVentures = await venturesAPI.getVenturesDetailsByFounderId(
        _founderId,
      );
      setLoadedVentures(loadedVentures);
      setInitialVentures(loadedVentures);
      onLoaded(loadedVentures);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        ventures: false,
      }));
    } catch (e: any) {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        ventures: false,
      }));
    }
  };

  const handleSubmit = async ({
    venturesIds,
  }: {
    venturesIds: Venture['id'][];
  }) => {
    const loadedVenturesIds = loadedVentures.map((venture) => venture.id);
    const assigned = venturesIds.filter(
      (id) => !loadedVenturesIds.includes(id),
    );
    const removed = loadedVenturesIds.filter((id) => !venturesIds.includes(id));
    const all = initialVentures.filter((venture) =>
      venturesIds.includes(venture.id),
    );
    onSuccess({ assigned, removed, all });
  };

  useEffect(() => {
    if (!ventures) {
      loadInitialVentures(founderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventures]);

  useEffect(() => {
    loadRecommended();
  }, []);

  if (isLoading.ventures || isLoading.recommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    venturesIds: initialVentures.map((venture) => venture.id),
  };

  const filteredDisplayedVentures = displayedVentures.filter(
    (venture) =>
      !initialVentures.find(
        (initialVenture) => initialVenture.id === venture.id,
      ),
  );
  const filteredRecommendedVentures = (recommended || []).filter(
    (item) => !initialVentures.find((venture) => venture.id === item.id),
  );

  const displayedList =
    filteredDisplayedVentures.length > 0
      ? filteredDisplayedVentures
      : filteredRecommendedVentures;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <VentureAutocomplete
              iteration={searchIteration}
              onSearch={handleVenturesSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label='Assign to'
                  placeholder='Enter venture name'
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialVentures.map((venture) => (
              <Chip
                key={venture.id}
                label={venture.ventureName}
                onDelete={() => handleVentureRemove(venture)}
                className={classes.chipItem}
              />
            ))}
          </div>
          {displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={['Ventures', 'Assigned Founders']}
              rows={displayedList.map((recomItem) => ({
                name: recomItem.ventureName,
                onAdd: () => handleVentureAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button disabled={loading} type='submit' data-testid='button-apply'>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default AssignFoundersVentureForm;
