import { unreachableError } from '../../utils/unreachableError';
import { Actions } from './types/Actions';
import * as State from './types/State';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;

    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            officeHours: a.payload.totalNumberOfficeHours,
            sessions: a.payload.totalNumberSessions,
            feedbackForms: a.payload.totalNumberFeedbackForms,
            advisors: a.payload.advisorReportElements,
          })
        : s;
    case 'Customize': {
      switch (s.type) {
        case 'Ready':
          return State.customizeReady(s.payload);
        case 'LoadError':
          return State.customizeEmpty(s.payload);
        case 'Loading':
        case 'CustomizeEmpty':
        case 'CustomizeReady':
          return s;
        default:
          return unreachableError(s);
      }
    }
    case 'CancelCustomization': {
      switch (s.type) {
        case 'CustomizeReady':
          return State.ready(s.payload);
        case 'CustomizeEmpty':
          return State.loadError(s.payload);
        case 'Ready':
        case 'LoadError':
        case 'Loading':
          return s;
        default:
          return unreachableError(s);
      }
    }
    case 'SetDateRange':
      return State.isCustomize(s)
        ? ({
            ...s,
            payload: {
              ...s.payload,
              start: a.payload.start,
              end: a.payload.end,
            },
          } as State.State)
        : s;
    case 'SetLogo':
      return State.isCustomize(s)
        ? ({
            ...s,
            payload: { ...s.payload, includeLogo: a.payload },
          } as State.State)
        : s;
    case 'SetSummary':
      return State.isCustomize(s)
        ? ({
            ...s,
            payload: { ...s.payload, includeSummary: a.payload },
          } as State.State)
        : s;
    case 'Update':
      return State.isCustomize(s) ? State.loading(s.payload) : s;
  }
}
