import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer } from 'react';
import { EMPTY, from, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import authAPI from '../../../../../api/auth';
import { useAuth } from '../../../../../hooks/useAuth';
import { unreachableError } from '../../../../../utils/unreachableError';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { loggedIn, loggedOut, State } from './types/State';

export function useLoginButton(): [State, Dispatch<Actions.Actions>] {
  const { logout, login, isAuthorized } = useAuth();
  const [s, dispatch] = useReducer(
    reducer,
    isAuthorized ? loggedIn({}) : loggedOut({}),
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    switch (s.type) {
      case 'LoggedIn':
      case 'LoggedOut':
      case 'Ready':
      case 'ResetPassword':
      case 'ResetError':
        return;
      case 'LoginError': {
        enqueueSnackbar(s.payload.message, { variant: 'error' });
        return;
      }
      case 'LoggingIn': {
        const v$ = from(
          login({
            username: s.payload.username.value,
            password: s.payload.password.value,
          }),
        )
          .pipe(
            switchMap(() => EMPTY),
            catchError(() => of(Actions.loginError('Unable to login'))),
          )
          .subscribe(dispatch);

        return () => v$.unsubscribe();
      }
      case 'LoggingOut': {
        logout(false);
        return;
      }
      case 'Resetting': {
        const r$ = from(
          authAPI.resetPassword(encodeURIComponent(s.payload.username.value)),
        )
          .pipe(
            mapTo(Actions.resetSuccess()),
            catchError((e: AxiosError) =>
              of(Actions.resetError(e.response?.data.message)),
            ),
          )
          .subscribe(dispatch);

        return () => r$.unsubscribe();
      }
      default:
        unreachableError(s);
    }

    // eslint-disable-next-line
  }, [s.type, enqueueSnackbar, dispatch, login, logout]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(Actions.userLoggedIn());
    } else {
      dispatch(Actions.userLoggedOut());
    }
  }, [isAuthorized]);

  return [s, dispatch];
}
