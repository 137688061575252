import { useEffect, useState } from 'react';
import { createCheckin } from '../../api/goals';
import { CreateCheckin } from '../../api/types/Checkin';
import { Goal, GoalId, GoalStatus } from '../../api/types/Goal';
import { GoalModal } from '../founders/dashboard/GoalModal';
import Slider from './slider';

export interface GoalSliderProps {
  goal: Goal;
  onChange?: (onChangeProps: {
    id: GoalId;
    progress: number;
    status: GoalStatus;
  }) => void;
}

function GoalSlider({ goal, onChange }: GoalSliderProps) {
  const [value, setValue] = useState(goal);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setValue(goal);
  }, [goal]);

  function onGoalChange(checkin: CreateCheckin) {
    setValue((v) => ({
      ...v,
      progress: checkin.progress,
      status: checkin.status,
    }));
    onChange &&
      onChange({
        id: value.id,
        progress: checkin.progress,
        status: checkin.status,
      });
    createCheckin(checkin);
  }

  return (
    <>
      <Slider
        value={value.progress}
        onChange={(_, v) => setValue({ ...value, progress: v as number })}
        onChangeCommitted={() => setModalOpen(true)}
      />
      {modalOpen && (
        <GoalModal
          goal={value}
          onChange={(value) => {
            onGoalChange(value);
            setModalOpen(false);
          }}
          onClose={() => {
            setValue(goal);
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
}

export default GoalSlider;
