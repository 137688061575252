import { Typography, makeStyles } from '@material-ui/core';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '../../components/common';
import ApplicationForm from '../../components/forms/application-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  topSection: {
    marginBottom: 32,
  },
});

function TenantEditFormPage() {
  const { formId } = useParams<{ formId: 'mentor' | 'venture' }>();
  const { user: tenant } = useContext(UserContext);
  const { rb } = useResourceBundles();
  const classes = useStyles();

  const data = useMemo(() => {
    switch (formId) {
      case 'mentor':
        return {
          title: `${rb('mentor-u')} Application Form`,
          text: `Please use this edit box below to add a welcome paragraph to all the ${rb(
            'mentor-u',
          )} applications that will be appended at the top of application forms. You can leave it empty if you do not need any additional prompts.`,
          initialValues: {
            welcomeParagraph: tenant?.mentorApplicationIntro || '',
          },
        };
      case 'venture':
        return {
          title: 'Venture Application Form',
          text: 'Please use this edit box below to add a welcome paragraph to all the Venture applications that will be appended at the top of application forms. You can leave it empty if you do not need any additional prompts.',
          initialValues: {
            welcomeParagraph: tenant?.ventureApplicationIntro || '',
          },
        };
    }
  }, [formId, rb, tenant]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <Typography variant='h4'>{data.title}</Typography>
        <Text variant='normal'>{data.text}</Text>
      </div>
      <ApplicationForm formId={formId} initialValues={data.initialValues} />
    </div>
  );
}

export default TenantEditFormPage;
