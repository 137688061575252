import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { startOfToday } from 'date-fns';
import { PropsWithChildren, ReactElement } from 'react';
import { Status } from '../../../api/Advisors/types/Status';
import { UserLogo } from '../../../components/common';
import { SimpleSelect } from '../../../components/common/SimpleSelect';
import { DateInput as MUIDate } from '../../../components/common/date-input';
import { TextField as MUIText } from '../../../components/common/text-field';
import { lengthField } from '../../../utils/form';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gap: '45px',
    gridTemplateColumns: '1fr 1fr',
  },
  column: {
    display: 'grid',
    gap: '56px',
  },
  group: {
    display: 'grid',
    gap: '30px',
    alignContent: 'start',
  },
  row: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',
  },
  title: {},
}));

export interface Props {
  firstName: string | undefined;
  address: string | undefined;
  birthDate: Date | undefined;
  zip: string | undefined;
  city: string | undefined;
  country: string | undefined;
  email: string | undefined;
  lastName: string | undefined;
  linkedIn: string | undefined;
  logo: string | undefined;
  long: string | undefined;
  phone: string | undefined;
  short: string | undefined;
  state: string | undefined;
  status: Status | undefined;
  enrollmentDate: Date;
  statuses: Array<{ label: string; value: Status }>;
}

export function View({
  firstName,
  lastName,
  birthDate,
  enrollmentDate,
  status,
  statuses,
  address,
  zip,
  city,
  country,
  email,
  linkedIn,
  logo,
  long,
  phone,
  short,
  state,
}: Props): ReactElement<Props> {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <div className={classes.group}>
          <div className={classes.row}>
            <TextField value={firstName} label='First Name *' />
            <TextField value={lastName} label='Last Name *' />
          </div>
          <div className={classes.row}>
            <MUIDate
              disabled={true}
              maxDate={startOfToday()}
              value={birthDate ?? null}
              textFieldProps={{
                label: 'Date of birth',
              }}
              onChange={empty}
            />
            <MUIDate
              value={enrollmentDate}
              textFieldProps={{
                label: 'Date of enrollment',
              }}
              onChange={empty}
              disabled
            />
          </div>
        </div>
        <div className={classes.group}>
          <GroupTitle>Contacts</GroupTitle>
          <TextField value={email} label='Email *' />
          <TextField value={phone} label='Phone' />
          <TextField value={linkedIn} label='LinkedIn profile' />
        </div>
        <div className={classes.group}>
          <GroupTitle>Address</GroupTitle>
          <TextField value={address} label='Address' />
          <TextField value={city} label='City' />
          <TextField value={country} label='Country' />
          <TextField
            value={zip}
            label='Zip'
            InputProps={{ inputProps: { maxLength: lengthField.zip } }}
          />
          <TextField value={state} label='State' />
        </div>
        <div className={classes.group}>
          <GroupTitle>Description</GroupTitle>
          <TextareaField rows={7} value={short} label='Short description *' />
          <TextareaField rows={15} value={long} label='Long description *' />
        </div>
      </div>
      <div className={classes.group}>
        <UserLogo
          name={'logo'}
          logo={logo}
          readOnly
          onUpload={empty}
          onRemove={empty}
        />
        <div>
          <SimpleSelect value={status} options={statuses} disabled />
        </div>
      </div>
    </div>
  );
}

interface TPr {
  value: string | undefined;
  label: string;
  InputProps?: any;
}
function TextField({ value, label, InputProps }: TPr): ReactElement<TPr> {
  return (
    <MUIText value={value} label={label} disabled InputProps={InputProps} />
  );
}

interface T2Pr {
  value: string | undefined;
  label: string;
  rows: number;
}
function TextareaField({ value, label, rows }: T2Pr): ReactElement<T2Pr> {
  return (
    <MUIText
      rows={rows}
      multiline={true}
      value={value}
      label={label}
      disabled
    />
  );
}

function GroupTitle({ children }: PropsWithChildren<{}>) {
  return <Typography variant='h3'>{children}</Typography>;
}

const empty = (): void => {};
