import { useState, useCallback, useRef, useEffect } from 'react';
import {
  TextField,
  TextFieldProps,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';
import { useSnackbar } from 'notistack';
import authAPI from '../../api/auth';

export type FieldProps = TextFieldProps & {
  onChange: (value: any) => any;
  small?: boolean;
  name: string;
  label: string;
  value?: string;
  onValid?: (valid: boolean) => any;
  type: 'short' | 'long';
};

const useStyles = makeStyles({
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)'
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box'
    }
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE
    }
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE
  }
});

function FieldProgramName({
  onChange,
  small = false,
  name,
  label,
  value: initialValue,
  InputProps,
  onValid,
  type = 'short',
  ...props
}: FieldProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(initialValue);
  const currentValue = useRef<string>();
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | ''>();

  const searchTenants = async (tenantName: string) => {
    try {
      let tenantsRequest;
      if (type === 'short') {
        tenantsRequest = authAPI.checkShortProgramName;
      } else {
        tenantsRequest = authAPI.checkLongProgramName;
      }
      setStatus('loading');
      const foundedTenants = await tenantsRequest(tenantName);
      if (
        foundedTenants.find(
          (tenant) =>
            tenant[type === 'short' ? 'tenantName' : 'programName'] ===
            tenantName
        )
      ) {
        enqueueSnackbar('A tenant with the same name already exists', {
          variant: 'error'
        });
        setStatus('error');
      } else {
        setStatus('success');
      }
    } catch (e: any) {
      setStatus('');
    }
  };

  const handleChange = (e: any) => {
    const originalValue = e.target.value;
    setStatus('');
    onChange(originalValue);
    setValue(originalValue);
    currentValue.current = originalValue.trim();
  };

  const handleBlur = () => {
    const trimmedValue = currentValue.current;
    if (trimmedValue && trimmedValue.length > 2 && initialValue !== value) {
      setStatus('loading');
      searchTenants(trimmedValue);
    } else {
      setStatus('');
    }
  };

  const getEndAdornment = useCallback(() => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!initialValue) {
      setValue('');
    }
  }, [initialValue]);

  useEffect(() => {
    if (onValid) {
      const valid = status !== 'error' && status !== 'loading';
      onValid(valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <TextField
      {...props}
      name={name}
      label={label}
      className={cn({
        [classes.error]: status === 'error',
        [classes.small]: small
      })}
      onChange={handleChange}
      onFocus={() => {
        setStatus('');
      }}
      value={value}
      variant='outlined'
      fullWidth
      onBlur={handleBlur}
      InputProps={{
        ...(InputProps ? InputProps : {}),
        endAdornment: getEndAdornment()
      }}
    />
  );
}

export default FieldProgramName;
