import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { CustomQuestion } from '../../../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../../../api/CustomQuestion/types/Type';
import { MONTSERRAT_FONT_FAMILY } from '../../../theme/variables';
import { customFieldsMaxLength } from '../../../utils/custom-questions';
import { CheckboxListOther, FormGroup } from '../../common';
import { FormSelect, TextFieldWrapper, TextFieldWysiwyg } from '../wrappers';

interface CustomFormFieldProps {
  question: CustomQuestion;
  onChange: (id: any) => void;
  value: string;
  hasAccessToUpdate?: boolean;
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '24px',
    lineHeight: '36px',
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
    marginTop: 56,
    marginBottom: 32,
  },
  firstSectionTitle: {
    marginTop: 0,
  },
}));

function CustomFormField({
  question,
  hasAccessToUpdate,
  onChange,
  value,
}: CustomFormFieldProps) {
  const {
    type,
    label: defaultLabel,
    id,
    options,
    isMandatory,
    position,
  } = question;
  const classes = useStyles();
  const customOptions = useMemo(
    () =>
      options
        ?.sort((a, b) => a.position - b.position)
        ?.map((option) => ({
          label: option.label,
          value: option.id,
        })) || [],
    [options],
  );
  const label = defaultLabel + (isMandatory ? '*' : '');

  switch (type) {
    case CustomQuestionType.PARAGRAPH:
      return (
        <FormGroup>
          <Field<string>
            data-testid={`custom-form-field-${id}`}
            name={id}
            component={TextFieldWysiwyg}
            placeholder={label}
            readOnly={!hasAccessToUpdate}
            maxLength={customFieldsMaxLength[CustomQuestionType.PARAGRAPH]}
            label={label}
          />
        </FormGroup>
      );
    case CustomQuestionType.NUMBER:
      return (
        <FormGroup>
          <Field<string>
            data-testid={`custom-form-field-${id}`}
            name={id}
            component={TextFieldWrapper}
            label={label}
            type='number'
            InputProps={{
              inputProps: {
                onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  const numericRegex = /^[0-9]*$/;
                  if (!numericRegex.test(event.key)) {
                    event.preventDefault();
                  }
                },
                min: 0,
                readOnly: !hasAccessToUpdate,
              },
            }}
          />
        </FormGroup>
      );
    case CustomQuestionType.SINGLE_CHOICE:
      return (
        <FormGroup>
          <Field<string>
            data-testid={`custom-form-field-${id}`}
            name={id}
            component={FormSelect}
            label={label}
            options={customOptions}
            readOnly={!hasAccessToUpdate}
          />
        </FormGroup>
      );
    case CustomQuestionType.MULTIPLE_CHOICE:
      return (
        <CheckboxListOther
          data-testid={`custom-form-field-${id}`}
          name={id}
          text={label}
          options={customOptions}
          readOnly={!hasAccessToUpdate}
          onChange={onChange}
          value={value}
          labelOther='Other'
          withOther={false}
        />
      );
    case CustomQuestionType.TEXT:
      return (
        <FormGroup>
          <Field<string>
            data-testid={`custom-form-field-${id}`}
            name={id}
            component={TextFieldWrapper}
            label={label}
            readOnly={!hasAccessToUpdate}
            InputProps={{
              inputProps: {
                maxLength: customFieldsMaxLength[CustomQuestionType.TEXT],
                readOnly: !hasAccessToUpdate,
              },
            }}
          />
        </FormGroup>
      );
    case CustomQuestionType.SUBTITLE:
    default:
      return (
        <h3
          data-testid={'custom-subtitle'}
          className={cn(classes.subtitle, {
            [classes.firstSectionTitle]: position === 0,
          })}>
          {label}
        </h3>
      );
  }
}

export default CustomFormField;
