import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import commentsAPI from '../../../api/comments';
import filesAPI from '../../../api/files';
import { Text } from '../../../components/common';
import CommentForm from '../../../components/forms/comment-form';
import { usePersonalNotes } from '../../../contexts/personal-notes-context';
import { COLORS } from '../../../theme/variables';
import CommentCard from './comment-card';

const useStyles = makeStyles(() => ({
  commentsBlock: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
    minWidth: 340,
    backgroundColor: '#fff',
    transform: 'translateX(100%)',
    zIndex: 2,
    transition: 'all 0.3s ease-in-out',
    boxSizing: 'border-box',
  },
  commentsBlockOpen: {
    transform: 'translateX(0)',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    padding: '3px 10px 3px 18px',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  closeBtn: {
    padding: 4,
  },
  commentsFormWrapper: {
    width: '100%',
    padding: 20,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  commentsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    padding: '0 20px 20px',
    overflow: 'auto',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyMessage: {
    padding: 20,
    textAlign: 'center',
  },
}));

function PersonalNotesComments() {
  const classes = useStyles();
  const {
    comments,
    selectedNote,
    setLoadingComments,
    setComments,
    showComments,
    toggleComments,
  } = usePersonalNotes();

  const selectedNoteId = useRef<string | null>(null);
  const commentsBlockRef = useRef<HTMLDivElement>(null);
  const noteCommentsRef = useRef<HTMLDivElement>(null);

  const getCommentsForNote = useCallback(
    async (noteId) => {
      setLoadingComments(true);
      try {
        const comments = await commentsAPI.getCommentsByNoteId(noteId);
        if (noteId === selectedNoteId.current) {
          const logos = comments
            .map((c) => c.creatorLogo)
            .filter((c) => c) as string[];
          const uniqueLogos = Array.from(new Set(logos));

          if (!uniqueLogos.length) {
            setComments(comments);
            return;
          }

          const uploadedLogos = await filesAPI.getFileLogos(uniqueLogos);
          const newComments = comments.map((comment) => {
            const logoBefore = comment.creatorLogo;

            if (!logoBefore) {
              return comment;
            }

            const logoIdx = uniqueLogos.indexOf(logoBefore);
            const logo = uploadedLogos[logoIdx];
            return { ...comment, creatorLogo: logo };
          });
          setComments(newComments);
        }
      } catch (e: any) {
        if (noteId === selectedNoteId.current) {
          setComments([]);
        }
      } finally {
        if (noteId === selectedNoteId.current) {
          setLoadingComments(false);
        }
      }
    },
    [setComments, setLoadingComments],
  );

  useEffect(() => {
    if (!selectedNote?.parentId) {
      selectedNoteId.current = null;
      setComments([]);
      return;
    }

    selectedNoteId.current = selectedNote.parentId;
    getCommentsForNote(selectedNote.parentId);
  }, [selectedNote, getCommentsForNote, setComments]);

  if (!selectedNote) {
    return null;
  }

  return (
    <div
      key={`comments-container-${selectedNote?.parentId}`}
      ref={commentsBlockRef}
      className={cn(classes.commentsBlock, {
        [classes.commentsBlockOpen]: showComments,
      })}>
      <div className={classes.header}>
        <Text variant='normal' bold>
          Comments
        </Text>
        <IconButton className={classes.closeBtn} onClick={toggleComments}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.commentsFormWrapper}>
        <CommentForm
          note={selectedNote}
          disabled={!showComments}
          setComments={setComments}
          // reset form when note changes or comments are toggled
          key={`comment-form-${
            selectedNote?.parentId
          }-${showComments.toString()}`}
        />
      </div>
      {!!comments?.length ? (
        <div
          className={classes.commentsList}
          id='note-comments-list'
          ref={noteCommentsRef}>
          {comments.map((comment) => (
            <CommentCard
              key={comment.id}
              comment={comment}
              containerRef={noteCommentsRef}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyState}>
          <Text variant='normal' className={classes.emptyMessage}>
            There are no comments for this note yet
          </Text>
        </div>
      )}
    </div>
  );
}

export default PersonalNotesComments;
