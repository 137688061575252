import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer } from 'react';
import { from, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import * as Appointments from '../../api/Appointments';
import { useQuery } from '../../hooks/useQuery';
import { unreachableError } from '../../utils/unreachableError';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';
import { decodeKey } from './utils';

export function useOfficeHoursSurvey(): [
  State.State,
  Dispatch<Actions.Actions>,
] {
  const key = useQuery().get('key');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const v = key ? decodeKey(key) : undefined;

  const [s, d] = useReducer(
    reducer,
    v
      ? State.loading({
          tenantId: v[0],
          appointmentId: v[1],
          email: v[2],
        })
      : State.invalidKey({ key: key ?? undefined }),
  );

  useEffect(() => {
    switch (s.type) {
      case 'InvalidKey':
      case 'Ready':
      case 'Submitted':
      case 'SaveSuccess':
        return;
      case 'LoadError':
      case 'SaveError': {
        const v = enqueueSnackbar(s.payload.message, { variant: 'error' });

        return () => closeSnackbar(v);
      }
      case 'Loading': {
        const v$ = from(
          Appointments.getPublicById(
            s.payload.tenantId,
            s.payload.appointmentId,
          ),
        )
          .pipe(
            map((r) => Actions.loadSuccess(r)),
            catchError((e: AxiosError) =>
              of(Actions.loadError(e.response?.data.message)),
            ),
          )
          .subscribe(d);

        return () => v$.unsubscribe();
      }
      case 'Saving': {
        const v$ = from(
          Appointments.createSurvey({
            officeHoursId: s.payload.appointmentId,
            rating: s.payload.rating.value,
            tenantId: s.payload.tenantId,
            comments: s.payload.comment.value,
            communityMemberEmail: s.payload.email,
          }),
        )
          .pipe(
            mapTo(Actions.saveSuccess()),
            catchError((e: AxiosError) =>
              of(Actions.saveError(e.response?.data.message)),
            ),
          )
          .subscribe(d);

        return () => {
          v$.unsubscribe();
        };
      }
      default:
        unreachableError(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type]);

  return [s, d];
}
