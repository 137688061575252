// region Open
export interface Open {
  type: 'Open';
}

export const open = (): Open => ({
  type: 'Open',
});
// endregion

// region Close
export interface Close {
  type: 'Close';
}

export const close = (): Close => ({
  type: 'Close',
});
// endregion

// region Send
export interface Send {
  type: 'Send';
}

export const send = (): Send => ({
  type: 'Send',
});
// endregion

// region SendError
export interface SendError {
  type: 'SendError';
}

export const sendError = (): SendError => ({
  type: 'SendError',
});
// endregion

// region SendSuccess
export interface SendSuccess {
  type: 'SendSuccess';
}

export const sendSuccess = (): SendSuccess => ({
  type: 'SendSuccess',
});
// endregion

export type Actions = Open | Close | Send | SendError | SendSuccess;
