import {
  Link as MaterialLink,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { useState } from 'react';
import { VentureId } from '../../api/ventures/types/Venture';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Link } from '../common';
import { Paginator } from '../common/Paginator';
import { ProgressBar } from '../common/ProgressBar';

interface Item {
  ventureId: VentureId;
  ventureName: string;
  businessGoals: number;
  founderGoals: number;
}

export interface VenturesProgressProps {
  ventures: Item[];
}

export function VenturesProgress({ ventures }: VenturesProgressProps) {
  const [page, setPage] = useState(0);
  const c = useStyles();
  const items = ventures.slice(page * 5, page * 5 + 5);
  const pages = Math.ceil(ventures.length / 5);

  return (
    <div className={c.wrapper}>
      <div className={c.table}>
        <div className={c.content}>
          <Typography
            variant={'subtitle1'}
            className={classNames(c.tHead, c.tCell)}>
            Venture name
          </Typography>
          <Typography
            variant={'subtitle1'}
            className={classNames(c.tHead, c.tCell)}>
            Business Goals
          </Typography>
          <Typography
            variant={'subtitle1'}
            className={classNames(c.tHead, c.tCell)}>
            Founder Goals
          </Typography>
        </div>
        {items.map((item) => (
          <div className={c.content} key={item.ventureId}>
            <div className={classNames(c.name, c.tCell)}>
              <Tooltip title={item.ventureName}>
                <MaterialLink
                  className={classNames(
                    CLASS_TRACKING.DASHBOARD_LINK,
                    c.ellipsis,
                  )}
                  variant='caption'
                  component={Link}
                  to={getRoutePath(Pages.VENTURE_NOTES, {
                    ventureId: item.ventureId,
                  })}>
                  {item.ventureName}
                </MaterialLink>
              </Tooltip>
            </div>
            <div className={c.tCell}>
              {item.businessGoals ? (
                <ProgressBar
                  value={Math.min(100, (item.businessGoals * 100) / 5)}
                  label={item.businessGoals}
                  score={
                    item.businessGoals < 3
                      ? 'low'
                      : item.businessGoals === 3
                      ? 'medium'
                      : 'high'
                  }
                />
              ) : (
                <Typography variant='caption' className={c.noData}>
                  No data yet
                </Typography>
              )}
            </div>
            <div className={c.tCell}>
              {item.founderGoals ? (
                <ProgressBar
                  value={Math.min(100, (item.founderGoals * 100) / 5)}
                  label={item.founderGoals}
                  score={
                    item.founderGoals < 3
                      ? 'low'
                      : item.founderGoals === 3
                      ? 'medium'
                      : 'high'
                  }
                />
              ) : (
                <Typography variant='caption' className={c.noData}>
                  No data yet
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
      {pages > 1 && <Paginator onChange={setPage} value={page} pages={pages} />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'contents',
    '&:last-child > *': {
      borderBottom: 'none',
    },
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 28,
    justifyContent: 'space-between',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
  tHead: {
    lineHeight: '1.2rem',
  },
  tCell: {
    borderRight: `1px dotted ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    borderBottom: `1px dotted ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    padding: 8,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',

    '&:first-child': {
      paddingLeft: 0,
    },

    '&:last-child': {
      borderRight: 'none',
      paddingRight: 0,
    },
  },
  name: {},
  noData: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
