import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import overrides from './overrides';
import { COLORS } from './variables';

const theme = createMuiTheme({
  overrides,
  palette: {
    primary: {
      main: COLORS.COLOR_BLUE_BASE,
    },
    secondary: {
      main: COLORS.COLOR_TEXT_BASE,
    },
    error: {
      main: COLORS.COLOR_RED_BASE,
    },
  },
  breakpoints: {
    values: {
      xs: 700,
      sm: 1100,
      md: 1300,
      lg: 1600,
      xl: 1800,
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
  );
};
