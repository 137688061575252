import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FounderId } from '../../api/founders';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import {
  Button,
  Modal,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import AssignFoundersVentureForm from '../../components/forms/assign-founders-venture';
import VenturesTable from '../../components/founders/ventures-table';
import { FounderContext } from '../../contexts/founder-context';
import { UserContext } from '../../contexts/user-context';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

function FounderVenturesPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { founderId } = useParams<{ founderId: FounderId }>();
  const { founder } = useContext(FounderContext);
  const [ventures, setVentures] = useState<Venture[]>();
  const [disabledIds, setDisabledIds] = useState<{ [x: string]: boolean }>({});
  const [isAssigningVenture, setIsAssigningVenture] = useState(false);
  const { hasAccessToAction } = useContext(UserContext);

  const isLoading = !ventures;

  const loadVentures = async () => {
    try {
      const loadedVentures = await venturesAPI.getVenturesDetailsByFounderId(
        founderId,
      );
      setVentures(loadedVentures);
    } catch (e: any) {
      console.log('error loadVentures', e);
    }
  };

  const handleAssingSuccessForm = useCallback(
    (_ventureId: string, handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
      }: {
        assigned: string[];
        removed: string[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningVenture(true);
          const assignedRequests = assigned.map(
            (ventureId) => () =>
              venturesAPI.assignFounder(ventureId, founderId),
          );
          const removedRequests = removed.map(
            (ventureId) => () =>
              venturesAPI.removeFounderAssignment(ventureId, founderId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          setVentures(undefined);
          handleModalClose();
          setIsAssigningVenture(false);
        } catch (e: any) {
          const messageError = e.response?.data?.message;

          enqueueSnackbar(
            'An error occurred while assigning ventures. Please, try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
        }
      },
    [founderId, enqueueSnackbar],
  );

  const handleRemoveVenture = useCallback(
    async (venture: Venture) => {
      try {
        setDisabledIds((prev) => ({
          ...prev,
          [venture.id]: true,
        }));
        await venturesAPI.removeFounderAssignment(venture.id, founderId);
        setVentures((prevVentures) =>
          prevVentures?.filter((prevVenture) => prevVenture.id !== venture.id),
        );
        setDisabledIds((prev) => ({
          ...prev,
          [venture.id]: false,
        }));
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing a venture. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDisabledIds((prev) => ({
          ...prev,
          [venture.id]: false,
        }));
      }
    },
    [founderId, enqueueSnackbar],
  );

  useEffect(() => {
    if (!ventures) {
      loadVentures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventures]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      {!!ventures && ventures.length > 0 && (
        <div className={classes.table}>
          <VenturesTable
            ventures={ventures}
            disabledVenturesIds={disabledIds}
            onRemove={handleRemoveVenture}
            readOnly={!hasAccessToAction('founder.ventures.update')}
          />
        </div>
      )}
      {hasAccessToAction('founder.ventures.update') && (
        <Modal
          title='Assign to Venture'
          caption={`${founder?.firstName} ${founder?.lastName}`}
          contentRenderer={({ handleClose }) => (
            <AssignFoundersVentureForm
              founderId={founderId}
              loading={isAssigningVenture}
              ventures={ventures}
              onSuccess={handleAssingSuccessForm(founderId, handleClose)}
              onCancel={handleClose}
            />
          )}
          buttonRenderer={({ onClick }) => (
            <Button
              onClick={onClick}
              startIcon={<AddIcon />}
              data-testid='founder-venture-button'>
              Assign
            </Button>
          )}
          width={720}
        />
      )}
    </div>
  );
}

export default FounderVenturesPage;
