import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Button, FormGroup, FormButtons, BeforeUnload } from '../common';
import { TextFieldWysiwyg } from './wrappers';

export interface Values {
  contents: string;
}

interface CheckinFormProps {
  title: string;
  loading?: boolean;
  checkin?: {
    contents: string;
  };
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  field: {
    '& [name="contents"]': {
      minHeight: 107,
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
}));

function CheckinForm({
  title,
  checkin,
  loading = false,
  onSubmit,
  onCancel,
}: CheckinFormProps) {
  const classes = useStyles();
  const initialValues: Values = {
    contents: checkin?.contents || '',
  };

  const handleSubmit = (values: Values) => {
    onSubmit({ contents: values.contents });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <FormGroup>
              <Field<string>
                className={classes.field}
                name='contents'
                component={TextFieldWysiwyg}
                placeholder='Check-in'
                label='Check-in'
                multiline
                testid='form-checkin-text'
              />
            </FormGroup>
            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='form-checkin-submit'
                type='submit'
                disabled={loading}
                className={CLASS_TRACKING.INTERNAL_ACTION}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='outlined' onClick={handleCancel}>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default CheckinForm;
