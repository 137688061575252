import { CircularProgress, makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import _set from 'lodash/set';
import { TextField } from '.';
import { Mentor, SelectMentor } from '../../api/mentors';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import MentorAutocomplete from '../autocomplete/mentor-autocomplete';

const useStyles = makeStyles({
  containerAutocomplete: {
    position: 'relative',
  },
  selectContent: {
    width: '100%',
    position: 'absolute',
    zIndex: 100,
    top: 55,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:
      '0px 0px 2px rgb(9 29 61 / 25%), 0px 8px 16px rgb(34 91 187 / 8%), 0px 8px 24px rgb(51 126 255 / 8%)',
  },
  smallContent: {
    top: 38,
  },
  invisibleWrapper: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 99,
    opacity: 1,
    margin: '0 !important',
  },
});

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  value: any;
  onChange: (e: any) => any;
  small?: boolean;
}

function SelectMentorAutocomplete({
  className,
  value: initialValue,
  onChange: handleChange,
  small,
}: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [selectMentor, setSelectMentor] = useState<SelectMentor | undefined>(
    initialValue,
  );
  const [displayedMentors, setDisplayedMentors] = useState<Mentor[]>();
  const [showList, setShowList] = useState<boolean>(false);

  const handleMentorsSearch = useCallback((searchedMentors: Mentor[]) => {
    setDisplayedMentors(searchedMentors);
  }, []);

  useEffect(() => {
    if (!initialValue) {
      setSelectMentor(undefined);
    }
  }, [initialValue]);

  return (
    <>
      {showList && (
        <div
          className={classes.invisibleWrapper}
          onClick={() => setShowList(false)}
        />
      )}
      <MentorAutocomplete
        onSearch={handleMentorsSearch}
        selectMentor={selectMentor}
        inputRenderer={({ value, onChange, isLoading }) => (
          <div className={cn(classes.containerAutocomplete, className)}>
            <TextField
              value={value}
              label={`${rb('mentor-u')} name`}
              placeholder={`Enter ${rb('mentor')} name`}
              small={small}
              onFocus={() => {
                setShowList(true);
              }}
              onChange={(e) => {
                setDisplayedMentors([]);
                if (!selectMentor) {
                  return onChange(e);
                }
                handleChange(null);
                setSelectMentor(undefined);
                return onChange(_set(e, 'e.target.value', ''));
              }}
              InputProps={{
                endAdornment: !!isLoading && (
                  <CircularProgress color='primary' size={20} />
                ),
              }}
            />
            <div
              className={cn(classes.selectContent, {
                [classes.smallContent]: small,
              })}>
              <ul>
                {showList &&
                  !selectMentor &&
                  displayedMentors &&
                  displayedMentors.length > 0 &&
                  displayedMentors.map((mentor: Mentor) => {
                    return (
                      <MenuItem
                        key={`select-venture-${mentor.id}`}
                        value={mentor.firstName}
                        onClick={() => {
                          setSelectMentor({
                            mentorName: mentor.firstName,
                            id: mentor.id,
                          });
                          handleChange({
                            mentorName: mentor.firstName,
                            id: mentor.id,
                          });
                        }}>
                        {mentor.firstName}
                      </MenuItem>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default SelectMentorAutocomplete;
