import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IconButton } from '@material-ui/core';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { makeStyles } from '@material-ui/styles';
import { $insertNodes, $createTextNode } from 'lexical';
import { MouseEvent, RefObject, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface EmojiPickerPluginProps {
  inputRef: RefObject<HTMLDivElement>;
  showEmojiPicker: boolean;
  setShowEmojiPicker: (show: boolean) => void;
}

interface EmojiPickerPopupProps {
  inputRef: RefObject<HTMLDivElement>;
  onClose?(): void;
}

const useStyles = makeStyles({
  emojiPicker: {
    position: 'absolute',
    zIndex: 100,

    '& em-emoji-picker': {
      height: '330px',
    },
  },
  emojiBtn: {
    position: 'absolute',
    top: 3,
    right: 15,
  },
});

function EmojiPickerPopup({ inputRef, onClose }: EmojiPickerPopupProps) {
  const [editor] = useLexicalComposerContext();
  const classes = useStyles();
  const popupRef = useRef<HTMLDivElement>(null);

  const addEmoji = useCallback(
    (emoji) => {
      editor.update(() => {
        $insertNodes([$createTextNode(emoji.native)]);
      });
    },
    [editor],
  );

  useEffect(() => {
    const input = inputRef.current;
    const popup = popupRef.current;

    if (!input || !popup) {
      return;
    }

    const placePopup = () => {
      requestAnimationFrame(() => {
        const inputRect = input.getBoundingClientRect();
        const top = inputRect.bottom + 2;
        const left = inputRect.left;

        popup.style.position = 'absolute';
        popup.style.top = `${top}px`;
        popup.style.left = `${left}px`;
      });
    };

    const resizeObserver = new ResizeObserver(placePopup);
    resizeObserver.observe(input);

    placePopup();

    window.addEventListener('resize', placePopup);

    return () => {
      window.removeEventListener('resize', placePopup);
      resizeObserver.unobserve(input);
    };
  }, [inputRef, popupRef]);

  return ReactDOM.createPortal(
    <div ref={popupRef} className={classes.emojiPicker} id='emoji-popup'>
      <Picker
        data={data}
        onEmojiSelect={addEmoji}
        onClickOutside={onClose}
        theme='light'
        previewPosition='none'
        maxFrequentRows={1}
      />
    </div>,
    document.body,
  );
}

export default function EmojiPickerPlugin({
  inputRef,
  showEmojiPicker,
  setShowEmojiPicker,
}: EmojiPickerPluginProps) {
  const classes = useStyles();

  const handleOpen = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setShowEmojiPicker(true);
    },
    [setShowEmojiPicker],
  );

  return (
    <>
      <IconButton
        className={classes.emojiBtn}
        onClick={handleOpen}
        size='small'>
        <InsertEmoticonIcon />
      </IconButton>
      {showEmojiPicker && (
        <EmojiPickerPopup
          inputRef={inputRef}
          onClose={() => setShowEmojiPicker(false)}
        />
      )}
    </>
  );
}
