import {
  FilterStatus,
  statuses,
} from '../../../api/Appointments/types/FilterStatus';

export function statusFromString(s: string): FilterStatus | undefined {
  for (let i = 0; i < statuses.length; i++) {
    if (s.toLowerCase() === statuses[i].toLowerCase()) {
      return statuses[i];
    }
  }

  return undefined;
}
