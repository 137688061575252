import { useContext, useMemo } from 'react';
import { Tabs, Tab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, useLocation } from 'react-router-dom';
import { Pages, getRoutePath } from '../../router/constants';
import { UserContext } from '../../contexts/user-context';
import { COLORS } from '../../theme/variables';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  founderId?: string;
}

const useStyles = makeStyles({
  container: {
    padding: '0 40px',
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`
  },

  tooltipWrapper: {
    marginLeft: '30px'
  }
});

function PageTabs({ founderId }: PageTabsProps) {
  const location = useLocation();
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);

  const tabs: TabsProps[] = [
    {
      props: {
        label: 'Details',
        'data-testid': 'founder-nav-details',
        component: Link,
        ...(founderId
          ? {
              to: getRoutePath(Pages.FOUNDER_DETAILS, { founderId }),
              value: getRoutePath(Pages.FOUNDER_DETAILS, { founderId })
            }
          : {
              to: Pages.NEW_FOUNDER,
              value: Pages.NEW_FOUNDER
            })
      }
    },
    {
      ...(!founderId
        ? {
            tooltip: {
              title: `Please complete creation of founder\nbefore switching to Ventures`
            }
          }
        : {}),
      props: {
        label: 'Ventures',
        'data-testid': 'founder-nav-ventures',
        disabled: !founderId,
        ...(founderId
          ? {
              component: Link,
              value: getRoutePath(Pages.FOUNDER_VENTURES, { founderId }),
              to: getRoutePath(Pages.FOUNDER_VENTURES, { founderId })
            }
          : {})
      }
    },
    {
      ...(!founderId
        ? {
            tooltip: {
              title: `Please complete creation of founder\nbefore switching to Ventures`
            }
          }
        : {}),
      props: {
        label: 'Additional info',
        'data-testid': 'founder-nav-additional-info',
        disabled: !founderId,
        ...(founderId
          ? {
              component: Link,
              value: getRoutePath(Pages.FOUNDER_ADDITIONAL_INFO, { founderId }),
              to: getRoutePath(Pages.FOUNDER_ADDITIONAL_INFO, { founderId })
            }
          : {})
      }
    },
    {
      ...(!founderId
        ? {
            tooltip: {
              title: `Please complete creation of founder\nbefore switching to Notes`
            }
          }
        : {}),
      props: {
        label: 'Notes',
        'data-testid': 'founder-nav-notes',
        disabled: !founderId,
        ...(founderId
          ? {
              component: Link,
              value: getRoutePath(Pages.FOUNDER_NOTES, { founderId }),
              to: getRoutePath(Pages.FOUNDER_NOTES, { founderId })
            }
          : {})
      }
    },
    {
      ...(!founderId
        ? {
            tooltip: {
              title: `Please complete creation of founder\nbefore switching to Log`
            }
          }
        : {}),
      props: {
        'data-testid': 'founder-nav-log',
        label: 'Log',
        disabled: !founderId,
        ...(founderId
          ? {
              component: Link,
              value: getRoutePath(Pages.FOUNDER_LOG, { founderId }),
              to: getRoutePath(Pages.FOUNDER_LOG, { founderId })
            }
          : {})
      }
    }
  ];

  const currentTabs = useMemo(() => {
    let arrayTabs = tabs;
    if (!hasAccessToAction('founder.logs.view')) {
      arrayTabs.splice(4, 1);
    }
    if (!hasAccessToAction('founder.notes.view')) {
      arrayTabs.splice(3, 1);
    }
    if (!hasAccessToAction('founder.additionalInfo.view')) {
      arrayTabs.splice(2, 1);
    }
    if (!hasAccessToAction('founder.ventures.view')) {
      arrayTabs.splice(1, 1);
    }
    if (!hasAccessToAction('founder.details.view')) {
      arrayTabs.splice(0, 1);
    }
    return arrayTabs;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'>
        {currentTabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          )
        )}
      </Tabs>
    </div>
  );
}

export default PageTabs;
