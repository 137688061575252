import { makeStyles } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { FC, RefObject, useContext } from 'react';
import { NoteComment } from '../../../api/comments';
import { Text, Avatar } from '../../../components/common';
import { UserContext } from '../../../contexts/user-context';
import { COLORS } from '../../../theme/variables';
import { getCommentDate } from '../../../utils/date';
import { createMarkup } from '../../../utils/functions';
import CommentCardDropdown from './comment-card-dropdown';

interface CommentCardProps {
  comment: NoteComment;
  containerRef?: RefObject<HTMLDivElement>;
}

const useStyles = makeStyles({
  cardContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: 10,
    padding: '8px 10px',
    borderRadius: 4,
    border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  header: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
  },
  headersActions: {
    display: 'flex',
    gap: 10,
    paddingRight: 20,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
  },
  date: {
    fontSize: 12,
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
  },
});

const CommentCard: FC<CommentCardProps> = ({ comment, containerRef }) => {
  const classes = useStyles();
  const { tokenData } = useContext(UserContext);
  const isOwner =
    tokenData?.id === comment.creatorId ||
    tokenData?.identityid === comment.creatorId;

  return (
    <div className={classes.cardContainer}>
      <Avatar
        src={comment.creatorLogo}
        name={`${comment.creatorFirstName[0]}${comment.creatorLastName[0]}`}
        size='32'
      />
      <div className={classes.main}>
        <div className={classes.header}>
          <Text
            className={
              classes.name
            }>{`${comment.creatorFirstName} ${comment.creatorLastName}`}</Text>
          <div className={classes.headersActions}>
            <div className={classes.date}>
              {getCommentDate(new Date(comment.creationDate))}
            </div>
          </div>
        </div>
        <Text variant='normal'>
          <span
            dangerouslySetInnerHTML={createMarkup(
              DOMPurify.sanitize(comment.content),
            )}
          />
        </Text>
      </div>
      {isOwner && (
        <CommentCardDropdown
          commentId={comment.id}
          containerRef={containerRef}
        />
      )}
    </div>
  );
};

export default CommentCard;
