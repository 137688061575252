import { CommunityMember } from '../../../../api/CommunityMembers/types/CommunityMember';
import { Filter } from './Filter';
import { Item } from './Item';

// region Loading
export interface LoadingPayload {
  page: number;
  filter: Filter;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  members: CommunityMember[];
  items: Item[];
  next: boolean;
  prev: boolean;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region UpdateConfirmation
export interface UpdateConfirmationPayload extends ReadyPayload {
  status: Item['status'];
}

export interface UpdateConfirmation {
  type: 'UpdateConfirmation';
  payload: UpdateConfirmationPayload;
}

export const updateConfirmation = (
  payload: UpdateConfirmation['payload'],
): UpdateConfirmation => ({
  type: 'UpdateConfirmation',
  payload,
});
// endregion

// region Updating
export interface UpdatingPayload extends UpdateConfirmationPayload {}

export interface Updating {
  type: 'Updating';
  payload: UpdatingPayload;
}

export const updating = (payload: Updating['payload']): Updating => ({
  type: 'Updating',
  payload,
});
// endregion

// region UpdateErr
export interface UpdateErrPayload extends UpdatingPayload {
  message: string;
}

export interface UpdateErr {
  type: 'UpdateErr';
  payload: UpdateErrPayload;
}

export const updateErr = (payload: UpdateErr['payload']): UpdateErr => ({
  type: 'UpdateErr',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | UpdateConfirmation
  | Updating
  | UpdateErr;

export function isEditable(s: State): s is Ready | UpdateErr {
  switch (s.type) {
    case 'Ready':
    case 'UpdateErr':
      return true;
    case 'LoadError':
    case 'Loading':
    case 'UpdateConfirmation':
    case 'Updating':
      return false;
  }
}
