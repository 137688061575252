import { AvailabilityId } from '../../../../api/Advisors/types/Availability';
import { uniqueId } from 'lodash';

declare const tag: unique symbol;

export type NewItemId = string & { [tag]: 'NewItemId' };

export const newItemId = (): NewItemId => uniqueId('NewItemId-') as NewItemId;

export type ItemId = AvailabilityId | NewItemId;

export const isNewItemId = (v: ItemId): v is NewItemId =>
  v.startsWith('NewItemId-');

export const isExistingItemId = (v: ItemId): v is AvailabilityId =>
  !isNewItemId(v);
