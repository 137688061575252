import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { cancel, createAppointment } from '../../../api/Appointments';
import { Tenant } from '../../../api/auth';
import { TabItem, Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import AppointmentForm, {
  AppointmentFormValues,
} from '../../../components/forms/appointment-form';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { Email } from '../../../utils/String/Email';

interface Props {
  user: Tenant;
}

const tabs: TabItem[] = [
  {
    title: 'Details',
    link: Pages.OH_APPOINTMENTS_NEW,
  },
];

export const AddAppointment: FC<Props> = ({ user }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (data: AppointmentFormValues) => {
      try {
        const {
          topic,
          start,
          end,
          location,
          requestorFullName,
          requestorEmail,
          status,
          advisor: { value: advisorId, label: advisorFullName },
          agenda,
        } = data;

        const appointment = await createAppointment({
          topic,
          start,
          end,
          roomUrl: location,
          requestorFullName,
          requestorEmail: requestorEmail as Email,
          status,
          advisorId: advisorId as AdvisorId,
          advisorFullName,
          agenda,
        });

        history.push(
          getRoutePath(Pages.OH_APPOINTMENTS_EDIT, { id: appointment.id }),
        );
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          'Something went wrong when creating the appointment. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={'Add new appointment'}
        backButtonLink={Pages.OH_APPOINTMENTS}
        backButtonTitle={'Back to Appointments'}
        tabs={<Tabs active={Pages.OH_APPOINTMENTS_NEW} tabs={tabs} />}>
        <AppointmentForm handleSubmit={handleSubmit} />
      </WithTabs>
      {cancel}
    </BaseLayout>
  );
};
