import { ReactElement, ReactNode, useContext } from 'react';
import { UserContext } from '../../contexts/user-context';

export interface Props {
  rule: string;
  children: ReactNode;
  fallback?: ReactNode;
}

export function WithRule({
  rule,
  children,
  fallback,
}: Props): ReactElement | null {
  const { hasAccessToAction } = useContext(UserContext);

  return <>{hasAccessToAction(rule) ? children : fallback}</>;
}
