import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

interface DrawerProps {
  title: string;
  body?: string | React.ReactNode;
  onClosed: () => any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    padding: '40px 32px',
    background: 'white',
    boxShadow:
      '0px 4px 8px rgba(9, 29, 61, 0.08), 0px 20px 40px rgba(9, 29, 61, 0.16)',
    transition: 'transform 200ms ease-out',
    transform: 'translateX(411px)',
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('xs')]: {
      width: 350,
      boxSizing: 'initial'
    }
  },
  opened: {
    transform: 'translateX(0)'
  },
  closeBtn: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  title: {
    margin: '-22px 0 20px'
  },
  body: {
    flexGrow: 1,
    overflow: 'auto'
  }
}));

function Drawer({ title, body, onClosed }: DrawerProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    // for animate
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onClosed();
    }, 200);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <div
      className={cn(classes.container, {
        [classes.opened]: isOpen
      })}>
      <IconButton className={classes.closeBtn} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div className={classes.title}>
        <Typography variant='h4'>{title}</Typography>
      </div>
      <div className={classes.body}>{body}</div>
    </div>
  );
}

export default Drawer;
