// region Idle
export interface Idle {
  type: 'Idle';
}

export const idle = (): Idle => ({ type: 'Idle' });
// endregion

// region Confirmation
export interface Confirmation {
  type: 'Confirmation';
}

export const confirmation = (): Confirmation => ({
  type: 'Confirmation',
});
// endregion

// region Loading
export interface Loading {
  type: 'Loading';
}

export const loading = (): Loading => ({
  type: 'Loading',
});
// endregion

export type State = Idle | Confirmation | Loading;
