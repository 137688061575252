import { VentureId } from '../../../../api/ventures/types/Venture';
import { NonEmptyArray } from '../../../../utils/Array/NonEmptyArray';
import { SchedulingWizard } from '../../../../api/schedulingWizard';

// region Idle
export interface IdlePayload {
  ventureId: VentureId;
}

export interface Idle {
  type: 'Idle';
  payload: IdlePayload;
}

export const idle = (payload: Idle['payload']): Idle => ({
  type: 'Idle',
  payload,
});
// endregion

// region Loading
export interface Loading {
  type: 'Loading';
  payload: { ventureId: VentureId };
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadErr
export interface LoadErr {
  type: 'LoadErr';
  payload: { message: string; ventureId: VentureId };
}

export const loadErr = (payload: LoadErr['payload']): LoadErr => ({
  type: 'LoadErr',
  payload,
});
// endregion

// region Declined
export interface Declined {
  type: 'Declined';
  payload: { ventureId: VentureId };
}

export const declined = (payload: Declined['payload']): Declined => ({
  type: 'Declined',
  payload,
});
// endregion

// region Ready
export interface Ready {
  type: 'Ready';
  payload: { ventureId: VentureId };
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Reserved
export interface ReservedPayload extends IdlePayload {
  wizards: NonEmptyArray<SchedulingWizard>;
}

export interface Reserved {
  type: 'Reserved';
  payload: ReservedPayload;
}

export const reserved = (payload: Reserved['payload']): Reserved => ({
  type: 'Reserved',
  payload,
});
// endregion

// region Canceling
export interface CancelingPayload extends ReservedPayload {}

export interface Canceling {
  type: 'Canceling';
  payload: CancelingPayload;
}

export const canceling = (payload: Canceling['payload']): Canceling => ({
  type: 'Canceling',
  payload,
});
// endregion

// region CancelingError
export interface CancelingErrorPayload extends CancelingPayload {
  message: string;
}

export interface CancelingError {
  type: 'CancelingError';
  payload: CancelingErrorPayload;
}

export const cancelingError = (
  payload: CancelingError['payload'],
): CancelingError => ({
  type: 'CancelingError',
  payload,
});
// endregion

// region Creating
export interface Creating {
  type: 'Creating';
  payload: { ventureId: VentureId };
}

export const creating = (payload: Creating['payload']): Creating => ({
  type: 'Creating',
  payload,
});
// endregion

// region CreateSuccess
export interface CreateSuccess {
  type: 'CreateSuccess';
  payload: { ventureId: VentureId };
}

export const createSuccess = (
  payload: CreateSuccess['payload'],
): CreateSuccess => ({
  type: 'CreateSuccess',
  payload,
});
// endregion

// region CreateErr
export interface CreateErr {
  type: 'CreateErr';
  payload: { message: string; ventureId: VentureId };
}

export const createErr = (payload: CreateErr['payload']): CreateErr => ({
  type: 'CreateErr',
  payload,
});
// endregion

export type State =
  | Idle
  | Loading
  | LoadErr
  | Declined
  | Reserved
  | Ready
  | Canceling
  | CancelingError
  | Creating
  | CreateSuccess
  | CreateErr;
