import { Initial, Invalid, Valid } from '../../../../utils/FormValue';
import { Sentence } from '../../../../utils/String/Sentence';

export class NewItemBase {
  constructor(public readonly id: 'new-item') {}
}

export class NewItemEditing extends NewItemBase {
  constructor(
    public readonly title:
      | Valid<Sentence<100>>
      | Initial<undefined>
      | Invalid<string, string>,
    public readonly description:
      | Valid<Sentence<2048> | undefined>
      | Invalid<string, string>,
  ) {
    super('new-item');
  }
}

export class NewItemCancelEditingConfirm extends NewItemEditing {}

export class NewItemSaving extends NewItemBase {
  constructor(
    public readonly title: Valid<Sentence<100>>,
    public readonly description: Valid<Sentence<2048> | undefined>,
  ) {
    super('new-item');
  }
}

export type NewItemState =
  | NewItemEditing
  | NewItemCancelEditingConfirm
  | NewItemSaving;
