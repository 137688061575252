import { AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { ValueType } from '../../../../utils/FormValue';
import { Item } from './Item';

// region Upload
export interface Upload {
  type: 'Upload';
  payload: File;
}

export const upload = (payload: Upload['payload']): Upload => ({
  type: 'Upload',
  payload,
});
// endregion

// region UploadSuccess
export interface UploadSuccess {
  type: 'UploadSuccess';
  payload: string;
}

export const uploadSuccess = (
  payload: UploadSuccess['payload'],
): UploadSuccess => ({
  type: 'UploadSuccess',
  payload,
});
// endregion

// region UploadErr
export interface UploadErr {
  type: 'UploadErr';
  payload: string;
}

export const uploadErr = (payload: UploadErr['payload']): UploadErr => ({
  type: 'UploadErr',
  payload,
});
// endregion

// region SetValue
export interface SetValue<K extends keyof Item> {
  type: 'SetValue';
  payload: {
    key: K;
    value: ValueType<Item[K]>;
  };
}

export const setValue = <K extends keyof Item>(
  payload: SetValue<K>['payload'],
): SetValue<K> => ({
  type: 'SetValue',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: keyof Item;
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Save
export interface Save {
  type: 'Save';
}

export const save = (): Save => ({
  type: 'Save',
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: AdvisorId;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: string;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region EmailValidation
export interface EmailValidation {
  type: 'EmailValidation';
  payload: boolean;
}

export const emailValidation = (
  payload: EmailValidation['payload'],
): EmailValidation => ({
  type: 'EmailValidation',
  payload,
});
// endregion

// region EmailValidationError
export interface EmailValidationError {
  type: 'EmailValidationError';
  payload: string;
}

export const emailValidationError = (
  payload: EmailValidationError['payload'],
): EmailValidationError => ({
  type: 'EmailValidationError',
  payload,
});
// endregion

// region NameValidation
export interface NameValidation {
  type: 'NameValidation';
  payload: boolean;
}

export const nameValidation = (
  payload: NameValidation['payload'],
): NameValidation => ({
  type: 'NameValidation',
  payload,
});
// endregion

// region NameValidationError
export interface NameValidationError {
  type: 'NameValidationError';
  payload: string;
}

export const nameValidationError = (
  payload: NameValidationError['payload'],
): NameValidationError => ({
  type: 'NameValidationError',
  payload,
});
// endregion

export type Actions =
  | SetValue<keyof Item>
  | Toggle
  | Upload
  | UploadSuccess
  | UploadErr
  | Save
  | SaveError
  | SaveSuccess
  | EmailValidation
  | EmailValidationError
  | NameValidation
  | NameValidationError;
