import { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import {
  Consent,
  NewConsent,
  PageLoader,
  SnackMessage
} from '../../components/common';
import { UserContext } from '../../contexts/user-context';
import authAPI, { ConsentType } from '../../api/auth';

const useStyles = makeStyles({
  container: {
    maxWidth: 670
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }
});

function TenantConsentsPage() {
  const classes = useStyles();
  const { user, hasAccessToAction } = useContext(UserContext);
  const [updatingConsentsIds, setUpdaingConsentsIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingConsentsIds, setDeletingConsentsIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [consents, setConsents] = useState<ConsentType[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateConsent = useCallback(
    async (value, callback) => {
      try {
        setIsCreating(true);
        if (user) {
          const createdConsents = (await authAPI.createConsent({
            tenantId: user?.id,
            type: value.type,
            body: value.body
          })) as ConsentType;
          setConsents((prev) => [createdConsents, ...prev]);
        }
        setIsCreating(false);
        callback();
        enqueueSnackbar('The commitment was successfully added.', {
          variant: 'success'
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the commitment. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
        setIsCreating(false);
      }
    },
    [user, enqueueSnackbar]
  );

  const handleConsentUpdate = useCallback(
    async (value, callback) => {
      try {
        setUpdaingConsentsIds((prev) => ({ ...prev, [value.id]: true }));
        if (user) {
          await authAPI.updateConsent({
            tenantId: user?.id,
            ...value
          } as ConsentType);
          setConsents((prev) =>
            prev.map((consentsList) =>
              consentsList.id === value.id ? value : consentsList
            )
          );
        }
        setUpdaingConsentsIds((prev) => ({ ...prev, [value.id]: false }));
        callback();
        enqueueSnackbar('The commitment was successfully updated.', {
          variant: 'success'
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the commitment. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
        setUpdaingConsentsIds((prev) => ({ ...prev, [value.id]: false }));
      }
    },
    [user, enqueueSnackbar]
  );

  const handleConsentRemove = useCallback(
    async (value, callback: () => any) => {
      try {
        setDeletingConsentsIds((prev) => ({ ...prev, [value.id]: true }));
        if (user) {
          await authAPI.deleteConsent(value.id);
          setConsents((prev) =>
            prev.filter((mentorConsent) => mentorConsent.id !== value.id)
          );
        }
        callback();
        setDeletingConsentsIds((prev) => ({ ...prev, [value.id]: false }));
        enqueueSnackbar('The commitment was successfully removed.', {
          variant: 'success'
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the commitment. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
        setDeletingConsentsIds((prev) => ({ ...prev, [value.id]: false }));
      }
    },
    [user, enqueueSnackbar]
  );

  const loadConsents = async () => {
    try {
      setIsLoading(true);
      const responseConsents = await authAPI.getConsents();
      setConsents(responseConsents);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadConsents();
  }, []);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {hasAccessToAction('consents.update') && (
        <NewConsent creating={isCreating} onCreate={handleCreateConsent} />
      )}
      <div>
        {consents.map((consent, index) => (
          <Consent
            key={consent.id}
            consent={consent}
            updating={updatingConsentsIds[consent.id]}
            deleting={deletingConsentsIds[consent.id]}
            onEdit={handleConsentUpdate}
            onRemove={handleConsentRemove}
            readOnly={!hasAccessToAction('consents.update')}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default TenantConsentsPage;
