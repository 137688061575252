import { TenantId } from '../../../api/tenants/Tenant';
import { Email } from '../../../utils/String/Email';

export interface DecodedKey {
  tenantId: TenantId;
  email: Email;
}

export const decodeKey = (key: string): DecodedKey | undefined => {
  const parseData = atob(key).split('|');

  if (parseData.length !== 2) {
    return undefined;
  }

  const tenantId = parseData[0] ? (parseData[0] as TenantId) : undefined;
  const email = parseData[1] ? (parseData[1] as Email) : undefined;

  return tenantId && email
    ? {
        tenantId,
        email,
      }
    : undefined;
};
