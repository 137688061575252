import { makeStyles } from '@material-ui/styles';
import { CLEAR_EDITOR_COMMAND, LexicalEditor } from 'lexical';
import { useSnackbar } from 'notistack';
import { Dispatch, useContext, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import commentsAPI, { NoteComment } from '../../api/comments';
import filesAPI from '../../api/files';
import { Role } from '../../api/user/Role';
import { PersonalNote } from '../../contexts/personal-notes-context';
import { UserContext } from '../../contexts/user-context';
import { TestId } from '../Testing/TestId';
import { Button } from '../common';
import ChatEditorField from '../editor/chat-editor';

interface FormValues {
  content: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.content) {
    errors.content = 'Required';
  }
  return errors;
};

interface CommentFormProps {
  note: PersonalNote;
  setComments: Dispatch<React.SetStateAction<NoteComment[]>>;
  initialValues?: FormValues;
  disabled?: boolean;
}

const useFormStyles = makeStyles({
  container: {
    width: '100%',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '1fr auto',
    gap: 10,
  },
  sendBtn: {
    padding: '6.5px 16px',
  },
});

const parseContent = (content: string) => {
  const parsedContent = content
    // trimm all spaces and \n from the start and end of the string
    .replace(/^[\s|\n]+|[\s|\n]+$/g, '')
    // if more than 2 \n in a row, replace them with 2 \n
    .replace(/[\n]{3,}/g, '\n\n')
    // replace all \n with <br> tag
    .replace(/\n/g, '<br>');

  return parsedContent;
};

const defaultInitialValues: FormValues = {
  content: '',
};

function CommentForm({
  note,
  setComments,
  initialValues,
  disabled,
}: CommentFormProps) {
  const classes = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, tokenData, identityid, hasRole } = useContext(UserContext);
  const contentEditorRef = useRef<LexicalEditor | null>(null);

  const [isLoading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSubmit = async (formValues: FormValues) => {
    try {
      setShowEmojiPicker(false);

      if (isLoading || !user || !tokenData) {
        return;
      }

      const creatorId =
        hasRole(Role.Admin) || hasRole(Role.Manager)
          ? tokenData.id
          : identityid;
      if (!creatorId) {
        return;
      }

      const parsedContent = parseContent(formValues.content);

      setLoading(true);
      const newComment = await commentsAPI.createCommentForNote({
        content: parsedContent,
        creatorRole: tokenData.role || '',
        parentNoteId: note.parentId || '',
        parentType: note.type,
        creatorId: identityid || null,
      });
      const logo = newComment.creatorLogo
        ? await filesAPI.getFileLogo(newComment.creatorLogo)
        : null;

      // Clear the editor after the comment is sent
      contentEditorRef.current?.dispatchCommand(
        CLEAR_EDITOR_COMMAND,
        undefined,
      );
      setComments((comments) => [
        { ...newComment, creatorLogo: logo },
        ...comments,
      ]);

      const listElement = document.getElementById('note-comments-list');
      listElement?.scrollTo(0, 0);
    } catch (error: any) {
      const messageError = `We're sorry, but there was an error sending your comment. Please try again or contact your Program Manager for further assistance.`;
      enqueueSnackbar(messageError, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      validate={validateForm}
      onSubmit={handleSubmit}
      initialValues={initialValues || defaultInitialValues}
      render={(formProps) => (
        <form noValidate className={classes.container}>
          <ChatEditorField
            name='content'
            maxLength={4096}
            initialValue={initialValues?.content}
            disabled={disabled}
            editorRef={contentEditorRef}
            showEmojiPicker={showEmojiPicker}
            setShowEmojiPicker={setShowEmojiPicker}
            error={!!formProps.errors?.content && formProps.submitFailed}
          />
          <TestId testId='send-comment-button'>
            <Button
              onClick={formProps.handleSubmit}
              className={classes.sendBtn}>
              Send
            </Button>
          </TestId>
        </form>
      )}
    />
  );
}

export default CommentForm;
