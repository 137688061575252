import { createContext, useState, useCallback } from 'react';
import reportsAPI from '../api/reports';

export interface GeneralReportContextProps {
  reportDates: string[] | undefined;
  isLoadingDates: boolean;
  loadDates: () => any;
}

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const GeneralReportContext = createContext<GeneralReportContextProps>({
  reportDates: undefined,
  isLoadingDates: true,
  loadDates: () => {}
});

export const GeneralReportProvider = ({ children }: DrawerProviderProps) => {
  const [reportDates, setReportDates] =
    useState<string[] | undefined>(undefined);
  const [isLoadingDates, setIsLoadingDates] = useState(true);

  const loadDates = useCallback(async () => {
    try {
      setIsLoadingDates(true);
      const res = await reportsAPI.getGeneralReportDates();
      setReportDates(res);
      setIsLoadingDates(false);
    } catch (e) {
      setIsLoadingDates(false);
    }
  }, []);

  const contextValue: GeneralReportContextProps = {
    reportDates,
    isLoadingDates,
    loadDates
  };

  return (
    <GeneralReportContext.Provider value={contextValue}>
      {children}
    </GeneralReportContext.Provider>
  );
};
