import { Status } from '../../../api/CommunityMembers/types/Status';
import { OptionProps } from '../../../components/common/select';
import { Item } from './types/Item';
import { State } from './types/State';

export function statusOptions(): OptionProps<Status | 'All'>[] {
  const t: { [k in Status | 'All']: string } = {
    All: 'All',
    Active: 'Active',
    Applicant: 'Applicant',
    Archived: 'Archived',
    Rejected: 'Rejected',
  };

  return Object.entries(t).reduce((acc: OptionProps<Status>[], [k, v]) => {
    acc.push({ label: v, value: k as Status });

    return acc;
  }, []);
}

export function getSelectedItems(s: State): Item[] {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return [];
    case 'Ready':
    case 'UpdateConfirmation':
    case 'Updating':
    case 'UpdateErr':
      return s.payload.items.filter((i) => i.selected);
  }
}

export function reverseStatus(s: Status): Status {
  switch (s) {
    case 'Applicant':
    case 'Active':
    case 'Rejected':
      return 'Archived';
    case 'Archived':
      return 'Active';
  }
}
