import { Typography } from '@material-ui/core';
import { Fragment } from 'react';
import { TestId } from '../../../components/Testing/TestId';
import { AddNewIcon } from '../../../components/common/ActionIcons/AddNewIcon';
import ConfirmDialog from '../../../components/common/confirm-dialog';
import { Item } from './components/Item';
import C from './index.module.scss';
import {
  addNew,
  cancelEditConfirm,
  cancelEditDecline,
  removeConfirm,
  removeDecline,
} from './types/Actions';
import { isEditCancelConfirm, isRemoveConfirm } from './types/Item';
import { useCohortsPage } from './useCohortsPage';

export function TenantCohortsPage() {
  const [state, dispatch] = useCohortsPage();

  return (
    <div className={C.root}>
      <Typography variant='h4' className={C.title}>
        Venture Groups
        <TestId testId={'add-item'}>
          <AddNewIcon onClick={() => dispatch(addNew())} />
        </TestId>
      </Typography>

      {state.type === 'Ready' && (
        <div className={C.items}>
          {state.payload.items.map((item) => (
            <Fragment key={item.id}>
              <Item item={item} dispatch={dispatch} />
              {isRemoveConfirm(item) && (
                <ConfirmDialog
                  isOpen
                  title={'Remove Group'}
                  body={`Do you really want to remove ${item.title} group`}
                  onCancel={() => dispatch(removeDecline())}
                  onSuccess={() => dispatch(removeConfirm())}
                />
              )}
              {isEditCancelConfirm(item) && (
                <ConfirmDialog
                  isOpen
                  title={'Cancel Edit'}
                  body={`Do you really want to cancel. The changes will be lost`}
                  onCancel={() => dispatch(cancelEditDecline())}
                  onSuccess={() => dispatch(cancelEditConfirm())}
                />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
