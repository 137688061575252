import * as Advisors from '../../../api/Advisors';
import { Advisor, AdvisorId } from '../../../api/Advisors/types/Advisor';
import { Status } from '../../../api/Advisors/types/Status';
import { TenantId } from '../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../api/tenants/types/Settings';
import { matchLogo } from '../common/utils';
import { LoadSuccess, RemoveSuccess, UpdateSuccess } from './types/Actions';
import { Filter } from './types/Filter';

export function get(
  id: TenantId,
  timeZone: TenantTimeZone,
  page: number,
  filter: Filter,
): Promise<LoadSuccess['payload']> {
  const get = (): Promise<{ items: Advisor[]; next: boolean }> => {
    switch (filter.type) {
      case 'none':
        return Promise.all([
          Advisors.get(timeZone, page - 1),
          Advisors.get(timeZone, page),
        ]).then(([items, count]) => ({ items, next: !!count.length }));
      case 'status':
        return Promise.all([
          Advisors.getByStatus(id, timeZone, filter.value, page - 1),
          Advisors.getByStatus(id, timeZone, filter.value, page),
        ]).then(([items, count]) => ({ items, next: !!count.length }));
      case 'search':
        return Advisors.search(id, timeZone, filter.value).then((items) => ({
          items,
          next: false,
        }));
    }
  };

  return get().then(async ({ next, items }) => {
    const logosList = items.map((i) => i.logo).filter((i): i is string => !!i);
    const logos = logosList.length
      ? await Advisors.getLogos(id, logosList).catch(() => [])
      : [];
    const newItems = await Promise.all(
      items.map((i) => ({
        ...i,
        logo: i.logo ? matchLogo(i.logo, logos) ?? null : null,
      })),
    );

    return {
      items: newItems,
      next: next,
    };
  });
}

export function remove(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  id: AdvisorId,
  page: number,
  filter: Filter,
): Promise<RemoveSuccess['payload']> {
  return Advisors.remove(id, timeZone).then(() =>
    get(tenantId, timeZone, page, filter),
  );
}

export function changeStatus(
  id: TenantId,
  timeZone: TenantTimeZone,
  ids: AdvisorId[],
  status: Status,
  page: number,
  filter: Filter,
): Promise<UpdateSuccess['payload']> {
  const fns = {
    Active: Advisors.unarchive,
    Archived: Advisors.archive,
  };
  return fns[status](ids).then(() => get(id, timeZone, page, filter));
}
