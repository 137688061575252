import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Link as MaterialLink,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { mPipe } from 'fp-utilities';
import { capitalize } from 'lodash';
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { CommunityMemberId } from '../../../api/CommunityMembers/types/CommunityMember';
import { Status } from '../../../api/CommunityMembers/types/Status';
import { FiltersWrapper } from '../../../components/Pages/Forms/FiltersWrapper';
import { Listing } from '../../../components/Pages/Listing';
import { ArchiveFilter } from '../../../components/Pages/Listing/ArchiveFilter';
import { Search } from '../../../components/Pages/Listing/Search';
import {
  Props as TableProps,
  Table,
} from '../../../components/Pages/Listing/Table';
import { TestId } from '../../../components/Testing/TestId';
import {
  AlertState,
  ConfirmDialog,
  CopyText,
  Link,
  Select,
  StatusBadge,
  Text,
} from '../../../components/common';
import { Add } from '../../../components/common/Buttons/Add';
import { LinkedIn } from '../../../components/common/Icons/LinkedIn';
import { TableFooter } from '../../../components/common/table';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { SendInvitation } from '../common/SendInvitation';
import * as Actions from './types/Actions';
import { searchStatus, searchText } from './types/Actions';
import { Item } from './types/Item';
import { State } from './types/State';
import { useCommunityMembersList } from './useCommunityMembersList';
import { getSelectedItems, statusOptions } from './utils';

export function CommunityMembersList({
  user,
}: ProtectedRouteProps): ReactElement {
  const [state, dispatch] = useCommunityMembersList();

  const onToggleAll = useCallback(
    () => dispatch(Actions.toggleAll()),
    [dispatch],
  );
  const handleToggle = useCallback(
    (id: CommunityMemberId) => dispatch(Actions.toggle(id)),
    [dispatch],
  );
  const handleRemove = useCallback(
    (id: CommunityMemberId) => dispatch(Actions.updateSingleStatus(id)),
    [dispatch],
  );
  const handlePageChange = useCallback(
    (p: number) => {
      if (p < state.payload.page) {
        dispatch(Actions.prevPage());
      } else if (p > state.payload.page) {
        dispatch(Actions.nextPage());
      }
    },
    [state.payload.page, dispatch],
  );

  const columns = useMemo<TableProps<TableKeys>['columns']>(() => {
    const items = getItems(state);
    const selected = items.filter((i) => i.selected);

    return {
      selected: {
        width: 42,
        title: (
          <Checkbox
            checked={!!selected.length && selected.length === items.length}
            color='primary'
            indeterminate={
              selected.length > 0 && selected.length !== items.length
            }
            onChange={onToggleAll}
          />
        ),
      },
      name: {
        width: 256,
        title: 'Name',
        truncated: true,
      },
      linkedIn: {
        width: 60,
        title: null,
        alignContent: 'center',
      },
      status: {
        width: 100,
        title: 'Status',
        alignContent: 'center',
      },
      email: {
        width: 256,
        title: 'Email',
      },
      actions: {
        width: 100,
        title: null,
        hoverOnly: true,
        alignContent: 'end',
      },
    };
  }, [state, onToggleAll]);
  const items = useMemo<TableProps<TableKeys>['content']>(() => {
    const getItem = (i: Item): Record<TableKeys, ReactNode> => {
      const Icon = getArchiveIcon(i.status);
      const title = getArchiveTitle(i.status);
      return {
        selected: (
          <div>
            <TestId
              testId='community-member-check'
              data-id={i.id}
              data-email={i.email}>
              <Checkbox
                checked={i.selected}
                color='primary'
                onChange={() => handleToggle(i.id)}
              />
            </TestId>
          </div>
        ),
        name: (
          <TestId testId={'community-member-name'}>
            <MaterialLink
              component={Link}
              to={{
                pathname: getRoutePath(Pages.OH_COMMUNITY_MEMBERS_EDIT, {
                  id: i.id,
                }),
              }}>
              {i.name}
            </MaterialLink>
          </TestId>
        ),
        email: (
          <CopyText text={i.email}>
            <MaterialLink href={`mailto:${i.email}`}>{i.email}</MaterialLink>
          </CopyText>
        ),
        status: (
          <StatusBadge status={i.status} variant={statusColor(i.status)} />
        ),
        linkedIn: i.linkedIn ? (
          <div>
            <Tooltip title={`${i.name}'s LinkedIn profile`}>
              <IconButton target='_blank' href={i.linkedIn}>
                <LinkedIn />
              </IconButton>
            </Tooltip>
          </div>
        ) : null,
        actions: (
          <>
            <Tooltip title='Edit'>
              <IconButton
                component={Link}
                to={{
                  pathname: getRoutePath(Pages.OH_COMMUNITY_MEMBERS_EDIT, {
                    id: i.id,
                  }),
                }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={title}>
              <IconButton>
                <Icon onClick={() => handleRemove(i.id)} />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
    };

    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return {
          type: 'ready',
          data: [],
        };
      case 'Ready':
      case 'UpdateConfirmation':
      case 'UpdateErr':
        return {
          type: 'ready',
          data: state.payload.items.map(getItem),
        };
      case 'Updating':
        return {
          type: 'reloading',
          data: state.payload.items.map(getItem),
        };
    }
  }, [state, handleRemove, handleToggle]);
  const empty = useMemo((): ReactElement | null => {
    switch (state.type) {
      case 'LoadError':
        return <AlertState type='error'>{state.payload.message}</AlertState>;
      case 'Ready':
        return <EmptyState />;
      case 'Loading':
        return <CircularProgress size={36} color='primary' />;
      case 'UpdateConfirmation':
      case 'Updating':
      case 'UpdateErr':
        return null;
    }
  }, [state]);
  const header = useMemo((): ReactElement | null => {
    const selected = getSelectedItems(state);
    return (
      <>
        <FiltersWrapper
          showClear={state.payload.filter.type !== 'none'}
          onClear={() => dispatch(Actions.clearFilters())}>
          <TestId testId={'search-filter'}>
            <Search
              value={
                state.payload.filter.type === 'search'
                  ? state.payload.filter.value
                  : ''
              }
              onChange={mPipe(searchText, dispatch)}
            />
          </TestId>
          <div style={{ width: '160px' }}>
            <TestId testId={'status-filter'}>
              <Select<Status | 'All'>
                options={statusOptions()}
                value={
                  state.payload.filter.type === 'status'
                    ? state.payload.filter.value ?? 'All'
                    : 'All'
                }
                onSelect={(v) =>
                  v &&
                  dispatch(
                    searchStatus(v.value === 'All' ? undefined : v.value),
                  )
                }
              />
            </TestId>
          </div>
        </FiltersWrapper>
        {selected.length ? (
          <div>
            <Grid container alignItems={'center'} sm={'auto'}>
              <Grid item>
                <ArchiveFilter
                  count={selected.length}
                  archivedDisabled={
                    selected.filter((i) => i.status !== 'Archived').length === 0
                  }
                  unArchivedDisabled={
                    selected.filter((i) => i.status === 'Archived').length === 0
                  }
                  onArchive={() => dispatch(Actions.changeStatus('Archived'))}
                  onUnArchive={() => dispatch(Actions.changeStatus('Active'))}
                />
              </Grid>

              <Grid item>
                <SendInvitation ids={selected.map((i) => i.id)}>
                  {({ open, disabled }) => (
                    <Tooltip title={'Send/Re-send invite'}>
                      <IconButton onClick={open} disabled={disabled}>
                        <ExitToApp />
                      </IconButton>
                    </Tooltip>
                  )}
                </SendInvitation>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </>
    );
  }, [state, dispatch]);
  const updateConfirmation = useMemo(() => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
        return null;
      case 'UpdateConfirmation':
      case 'Updating':
        return (
          <ConfirmDialog
            isOpen={true}
            title={`${capitalize(
              statusActionTitle(state.payload.status),
            )} community member`}
            body={`Do you really want to ${statusActionTitle(
              state.payload.status,
            )} community member${state.payload.items.length === 1 ? '' : 's'}?`}
            onCancel={() => dispatch(Actions.updateConfirmation(false))}
            onSuccess={() => dispatch(Actions.updateConfirmation(true))}
            disabled={state.type === 'Updating'}
            cancelProps={{
              label: 'Cancel',
              variant: 'outlined',
              'data-testid': 'community-member-remove-cancel',
            }}
            successProps={{
              label: 'Yes, proceed',
              variant: 'contained',
              'data-testid': 'community-member-remove-approve',
            }}
          />
        );
    }
  }, [state, dispatch]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <Listing
        actions={[
          {
            key: 'new-community-member',
            label: 'New Community Member',
            component: (
              <Add
                key={'add'}
                title={'Community Member'}
                to={Pages.OH_COMMUNITY_MEMBERS_CREATE}
              />
            ),
          },
        ]}
        title={'Community Members'}>
        <TestId testId={'community-members-list'}>
          <Table<TableKeys>
            columns={columns}
            content={items}
            empty={empty}
            header={header}
            footer={
              <TableFooter
                page={state.payload.page}
                onPageChange={handlePageChange}
                disabled={isLoading(state)}
                hasNextPage={hasNextPage(state)}
              />
            }
          />
        </TestId>
      </Listing>
      {updateConfirmation}
    </BaseLayout>
  );
}

const useEmptyStyles = makeStyles(() => {
  return {
    root: {
      display: 'grid',
      gap: '20px',
      textAlign: 'center',
    },
  };
});

function EmptyState() {
  const classes = useEmptyStyles();
  return (
    <div className={classes.root}>
      <Text variant='normal'>
        This panel contains a list of community members.
        <br />
        We were not able to find information you requested,
        <br /> but feel free to add something new!
      </Text>
      <div>
        <Add
          title={'Community Member'}
          to={Pages.OH_COMMUNITY_MEMBERS_CREATE}
        />
      </div>
    </div>
  );
}

type TableKeys =
  | 'selected'
  | 'name'
  | 'linkedIn'
  | 'status'
  | 'email'
  | 'actions';

function getItems(s: State): Item[] {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return [];
    case 'Ready':
    case 'Updating':
    case 'UpdateConfirmation':
    case 'UpdateErr':
      return s.payload.items;
  }
}

function statusColor(
  s: Item['status'],
): 'success' | 'warning' | 'error' | 'primary' {
  switch (s) {
    case 'Archived':
      return 'warning';
    case 'Active':
      return 'success';
    case 'Applicant':
      return 'primary';
    case 'Rejected':
      return 'error';
  }
}

function isLoading(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'Updating':
      return true;
    case 'LoadError':
    case 'Ready':
    case 'UpdateConfirmation':
    case 'UpdateErr':
      return false;
  }
}

function hasNextPage(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Ready':
    case 'UpdateConfirmation':
    case 'Updating':
    case 'UpdateErr':
      return s.payload.next;
  }
}

function statusActionTitle(s: Status): string {
  switch (s) {
    case 'Active':
      return 'un-archive';
    case 'Archived':
      return 'archive';
    case 'Rejected':
      return 'reject';
    case 'Applicant':
      return 'applicant';
  }
}

function getArchiveTitle(s: Status): string {
  switch (s) {
    case 'Archived':
      return 'Unarchive';
    case 'Active':
    case 'Applicant':
    case 'Rejected':
      return 'Archive';
  }
}

function getArchiveIcon(
  s: Status,
): JSXElementConstructor<{ onClick: () => void }> {
  switch (s) {
    case 'Archived':
      return UnarchiveIcon;
    case 'Active':
    case 'Applicant':
    case 'Rejected':
      return ArchiveIcon;
  }
}
