import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { MentorId } from '../../api/mentors';
import venturesAPI, { VentureMentor } from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import {
  Button,
  Modal,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import AssignMentorsForm from '../../components/forms/assign-mentors';
import AssignedTable, {
  AssignmentItem,
} from '../../components/mentors/assigned-table';
import { MentorContext } from '../../contexts/mentor-context';
import { UserContext } from '../../contexts/user-context';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

function MentorAssignmentsPage() {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const { mentorId } = useParams<{ mentorId: MentorId }>();
  const { mentor } = useContext(MentorContext);
  const { enqueueSnackbar } = useSnackbar();
  const [assignments, setAssignments] = useState<VentureMentor[]>();
  const [ventures, setVentures] = useState<Venture[]>();
  const isLoading = useMemo(
    () => !assignments || !ventures,
    [assignments, ventures],
  );
  const [disabledIds, setDisabledIds] = useState<{ [x: string]: boolean }>({});
  const [isAssigningMentor, setIsAssigningMentor] = useState(false);

  const loadData = async (_mentorId: MentorId) => {
    try {
      const [loadedAssignments, loadedVentures] = await Promise.all([
        venturesAPI.getVenturesByMentorId(_mentorId),
        venturesAPI.getVenturesDetailsByMentorId(_mentorId),
      ]);
      setAssignments(loadedAssignments);
      setVentures(loadedVentures);
    } catch (e: any) {
      console.log('error loadAssignments', e);
    }
  };

  const handleAssingSuccessForm = useCallback(
    (_mentorId: string, handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
      }: {
        assigned: string[];
        removed: string[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningMentor(true);
          const assignedRequests = assigned.map(
            (ventureId) => () => venturesAPI.assignMentor(ventureId, mentorId),
          );
          const removedRequests = removed.map(
            (ventureId) => () =>
              venturesAPI.removeMentorAssignment(ventureId, mentorId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          setAssignments(undefined);
          setVentures(undefined);
          handleModalClose();
          setIsAssigningMentor(false);
        } catch (e: any) {
          console.log('error', 'e');
        }
      },
    [mentorId],
  );

  const handleLeadChange = useCallback(
    async (assignmentItem: AssignmentItem, state: boolean) => {
      try {
        setAssignments((prevAssignments) =>
          (prevAssignments || []).map((prevAssignment) =>
            assignmentItem.assignmentId === prevAssignment.id
              ? { ...prevAssignment, isPrimary: state }
              : prevAssignment,
          ),
        );
        const updatedAssignment = await venturesAPI.updateMentorPrimaryFlag(
          assignmentItem.id,
          mentorId,
          state,
        );
        setAssignments((prevAssignments) =>
          (prevAssignments || []).map((prevAssignment) =>
            prevAssignment.id === updatedAssignment.id
              ? updatedAssignment
              : prevAssignment,
          ),
        );
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while changing a lead status. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setAssignments((prevAssignments) =>
          (prevAssignments || []).map((prevAssignment) =>
            assignmentItem.assignmentId === prevAssignment.id
              ? { ...prevAssignment, isPrimary: !state }
              : prevAssignment,
          ),
        );
      }
    },
    [mentorId, enqueueSnackbar],
  );

  const handleAssignmentRemove = useCallback(
    async (assignmentItem: AssignmentItem) => {
      try {
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: true,
        }));
        await venturesAPI.removeMentorAssignment(assignmentItem.id, mentorId);
        setAssignments((prev) =>
          (prev || []).filter(
            (prevAssignment) =>
              prevAssignment.id !== assignmentItem.assignmentId,
          ),
        );
        setVentures((prev) =>
          (prev || []).filter(
            (prevVentures) => prevVentures.id !== assignmentItem.id,
          ),
        );
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: false,
        }));
      } catch (e: any) {
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: false,
        }));
      }
    },
    [mentorId],
  );

  useEffect(() => {
    if (!assignments || !ventures) {
      loadData(mentorId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventures, mentorId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  const assignmentsList = (ventures || []).reduce((res, venture) => {
    const assignment = (assignments || []).find(
      (assignment) => assignment.ventureId === venture.id,
    );
    if (!assignment) {
      return res;
    }
    res.push({
      ...venture,
      assignmentId: assignment.id,
      isPrimary: assignment.isPrimary,
    });
    return res;
  }, [] as AssignmentItem[]);

  return (
    <div>
      {!!assignmentsList.length && (
        <AssignedTable
          className={classes.table}
          assignments={assignmentsList}
          disabledAssignmentsIds={disabledIds}
          onSelect={handleLeadChange}
          onRemove={handleAssignmentRemove}
          readOnly={!hasAccessToAction('mentor.assignemnts.update')}
        />
      )}
      {hasAccessToAction('mentor.assignemnts.update') && (
        <Modal
          title='New assignment'
          caption={`${mentor?.firstName} ${mentor?.lastName}`}
          contentRenderer={({ handleClose }) => (
            <AssignMentorsForm
              mentorId={mentorId}
              loading={isAssigningMentor}
              ventures={ventures}
              onSuccess={handleAssingSuccessForm(mentorId, handleClose)}
              onCancel={handleClose}
            />
          )}
          buttonRenderer={({ onClick }) => (
            <Button
              onClick={onClick}
              startIcon={<AddIcon />}
              data-testid='mentor-assigments-button'>
              Assignment
            </Button>
          )}
          width={720}
        />
      )}
    </div>
  );
}

export default MentorAssignmentsPage;
