import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Founder } from '../../api/founders';
import gatheringsAPI from '../../api/gatherings';
import { Mentor } from '../../api/mentors';
import { SnackMessage } from '../../components/common';
import GatheringDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/gathering-details';
import PageTabs from '../../components/gathering/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
});

export interface ValueSubmit {
  parsedValues: any;
  founders: Founder[];
  mentors: Mentor[];
}

function NewGatheringPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      const valueRequest = { ...parsedValues };
      if (valueRequest.isChangePeriod) {
        delete valueRequest.isChangePeriod;
      }

      try {
        setIsCreating(true);
        const { gatherings } = await gatheringsAPI.createGatheringGroups(
          valueRequest,
        );

        history.push(
          getRoutePath(Pages.GATHERINGS_DETAILS, {
            gatheringId: gatherings[0].id,
          }),
        );
        setIsCreating(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the gathering. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
        throw e;
      }
    },
    [history, enqueueSnackbar],
  );

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='New Gathering'
        backLink={
          <Tooltip title='Back to Gathering'>
            <IconButton
              data-testid='button-back-gatherings'
              component={Link}
              to={prevPath || Pages.SESSIONS}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Paper className={classes.container} elevation={1}>
        <PageTabs />
        <div className={classes.content}>
          <GatheringDetailsForm
            loading={isCreating}
            onSubmit={handleSubmitForm}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default NewGatheringPage;
