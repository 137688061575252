import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AnnouncementProvider } from '../contexts/announcement-context';
import { DrawerProvider } from '../contexts/drawer-context';
import { GeneralReportProvider } from '../contexts/general-report';
import { ImagesContextProvider } from '../contexts/images-context';
import { IssueProvider } from '../contexts/issue-context';
import { LoadingDrawerProvider } from '../contexts/loading-drawer-context';
import { QuarterlyReportProvider } from '../contexts/quarterly-report';
import { ResourceBundlesProvider } from '../contexts/resource-bundles-context';
import { SpecializationProvider } from '../contexts/specialization-context';
import { TenantLogoProvider } from '../contexts/tenant-logo';
import { UserProvider } from '../contexts/user-context';
import { VentureTagProvider } from '../contexts/venture-tags-context';
import Router from '../router';
import { ThemeProvider } from '../theme';
import SnackbarProvider from '../theme/snackbar';

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <UserProvider>
          <ResourceBundlesProvider>
            <AnnouncementProvider>
              <LoadingDrawerProvider>
                <DrawerProvider>
                  <SpecializationProvider>
                    <VentureTagProvider>
                      <IssueProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <ImagesContextProvider>
                            <GeneralReportProvider>
                              <QuarterlyReportProvider>
                                <TenantLogoProvider>
                                  <Router />
                                </TenantLogoProvider>
                              </QuarterlyReportProvider>
                            </GeneralReportProvider>
                          </ImagesContextProvider>
                        </LocalizationProvider>
                      </IssueProvider>
                    </VentureTagProvider>
                  </SpecializationProvider>
                </DrawerProvider>
              </LoadingDrawerProvider>
            </AnnouncementProvider>
          </ResourceBundlesProvider>
        </UserProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
