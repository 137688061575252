import { makeStyles } from '@material-ui/core';
import {
  DesktopDatePicker,
  DatePicker,
  FieldSelectedSections,
} from '@mui/x-date-pickers';
import cn from 'classnames';
import { useState, useCallback, useEffect, useRef } from 'react';
import { COLORS } from '../../theme/variables';
import { TestId } from '../Testing/TestId';
import { TextFieldProps } from './text-field';

export interface DateInputProps {
  size?: 'large' | 'small';
  value: Date | null;
  onChange: (e: Date | null) => any;
  onBlur?: () => void;
  onFocus?: () => void;
  maxDate?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
  textFieldProps?: TextFieldProps;
  editable?: boolean;
  error?: boolean;
  readOnly?: boolean;
  testid?: string;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
}

const useStyles = makeStyles({
  container: {
    '& .MuiFormLabel-root, .MuiInputBase-input': {
      fontFamily: 'Inter, Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
    '& .MuiFormLabel-root': {
      color: COLORS.COLOR_TEXT_LIGHTENED_20,
      lineHeight: '160%',
      '&.MuiInputLabel-shrink': {
        lineHeight: '100%',
      },
    },
    '& .MuiInputBase-input': {
      height: 19,
      lineHeight: '118%',
      paddingTop: '18.5px',
      paddingBottom: '18.5px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-filled':
      {
        display: 'none',
      },
  },
  small: {
    '& input': {
      height: '38px',
      paddingTop: '10px',
      paddingBottom: '10px',
      boxSizing: 'border-box',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,
    },
    '& .MuiFormLabel-root': {
      color: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
});

export function DateInput({
  value,
  onChange,
  onBlur,
  maxDate,
  disablePast,
  disableFuture,
  textFieldProps,
  editable,
  readOnly = false,
  testid,
  disabled,
  className,
  size,
  onFocus,
  error,
  ...rest
}: DateInputProps) {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleSelectedSectionsChange = useCallback(
    (selectedSection: FieldSelectedSections) => {
      if (selectedSection !== null && !focus) {
        setFocus(true);
        onFocus && onFocus();
      } else if (selectedSection === null && focus) {
        setFocus(false);
        onBlur && onBlur();
      }
    },
    [focus, onBlur, onFocus],
  );

  useEffect(() => {
    const inputBase =
      datePickerRef.current?.querySelector('.MuiInputBase-root');
    if (inputBase) {
      if (error) {
        inputBase.setAttribute('aria-invalid', 'true');
      } else {
        inputBase.setAttribute('aria-invalid', 'false');
      }
    }
  }, [error]);

  return (
    <TestId testId={testid || ''}>
      {editable ? (
        <DesktopDatePicker
          {...rest}
          className={cn(classes.container, className, {
            [classes.small]: size === 'small',
            [classes.error]: error,
          })}
          format='MM/dd/yyyy'
          value={value}
          onChange={onChange}
          maxDate={maxDate}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disabled={disabled}
          readOnly={readOnly}
          label={textFieldProps?.label}
          onSelectedSectionsChange={handleSelectedSectionsChange}
          disableOpenPicker={readOnly}
          ref={datePickerRef}
        />
      ) : (
        <DatePicker
          className={cn(classes.container, className, {
            [classes.small]: size === 'small',
            [classes.error]: error,
          })}
          format='MM/dd/yyyy'
          value={value}
          onChange={onChange}
          maxDate={maxDate}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          label={textFieldProps?.label}
          onSelectedSectionsChange={handleSelectedSectionsChange}
          disableOpenPicker={readOnly}
          ref={datePickerRef}
        />
      )}
    </TestId>
  );
}

export default DateInput;
