import {
  FormLabel,
  makeStyles,
  TextFieldProps as MaterialTextFieldProps,
  Theme,
} from '@material-ui/core';
import cn from 'classnames';
import { FocusEventHandler, useState } from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { cleanHTML, length } from '../../utils/wysiwyg';

export type FieldProps = MaterialTextFieldProps & {
  onBlur?: (e: any) => void;
  value?: string;
  placeholder?: string;
  showEditor?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  testid?: string;
  error?: boolean;
  showCount?: boolean;
  maxLength?: number;
  label?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  wysiwygWrapper: {
    position: 'relative',
    width: '100%',

    '& > div': {
      borderColor: `${COLORS.COLOR_GRAY_LIGHTENED_20} !important`,
      borderRadius: 2,

      '& > :first-child': {
        boxSizing: 'border-box',
        padding: '7px 2px 7px 2px',
        backgroundColor: `${COLORS.COLOR_GRAY_LIGHTENED_45}  !important`,
        borderRadius: '4px 4px 0 0',
        borderColor: `${COLORS.COLOR_GRAY_LIGHTENED_20} !important`,
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: 0,

        [theme.breakpoints.up(400)]: {
          padding: '7px 10px 7px 10px',
        },

        [theme.breakpoints.up('xs')]: {
          columnGap: 5,
        },

        '& > *': {
          cursor: 'pointer',
        },

        '& button': {
          color: `${COLORS.COLOR_TEXT_LIGHTENED_10} !important`,
          height: 'auto !important',

          '&:hover': {
            color: `${COLORS.COLOR_TEXT_BASE} !important`,
            backgroundColor: 'rgba(149, 163, 185, 0.15) !important',
            boxShadow: '0px 0px 0px 2px rgba(149, 163, 185, 0.15) !important',
            border: '0',
            borderRadius: 10,
          },
        },

        '& [title="Styles"]': {
          borderColor: `${COLORS.COLOR_GRAY_LIGHTENED_20} !important`,
          fontFamily: 'Inter, Roboto, "Helvetica Neue", Arial, sans-serif',
          height: '26px !important',
          marginTop: '-1px !important',
          marginBottom: '-3px !important',
          padding: '0 2px',
          borderRadius: 4,
          width: 65,
          [theme.breakpoints.up(400)]: {
            width: 'auto',
          },
        },
      },

      '& > :last-child': {
        padding: '18.5px 14px !important',
        minHeight: 100,
        fontFamily: 'Inter, Roboto, "Helvetica Neue", Arial, sans-serif',
        wordBreak: 'break-word',

        '& ul, ol, li, b, i, u, h1, h2, pre': {
          margin: 'revert',
          padding: 'revert',
          fontWeight: 'revert',
          fontStyle: 'revert',
          listStyle: 'revert',
          border: 'revert',
          fontSize: 'revert',
          font: 'revert',
          verticalAlign: 'revert',
        },

        '& pre': {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        },
      },
    },
    '& div[contenteditable]:empty:after': {
      content: 'attr(placeholder)',
      color: COLORS.COLOR_GRAY_PLACEHOLDER,
    },
  },
  hideEditor: {
    '& > div > :first-child': {
      display: 'none',
    },
  },
  disabled: {
    '& > div > :last-child': {
      backgroundColor: 'rgba(149, 163, 185, 0.05)',
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  },
  readOnly: {
    '& > div': {
      pointerEvents: 'none',
    },
  },
  error: {
    color: `${COLORS.COLOR_RED_BASE} !important`,

    '& > div': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,

      '& > div[contenteditable]:empty:after': {
        color: COLORS.COLOR_RED_BASE,
      },
    },
  },
  count: {
    position: 'absolute',
    right: '15px',
    bottom: '-7px',
    fontSize: '10px',
    background: '#fff',
    padding: '2px 4px',
    borderRadius: '3px',
    minWidth: '15px',
    textAlign: 'center',
    color: '#555',
    fontWeight: 'bolder',
    border: '1px solid rgb(221, 221, 221)',
    cursor: 'default',
    transition: 'opacity .3s',
  },
  label: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '23px',
    fontWeight: 700,
    color: '#091D3D',
  },
  showLabel: {
    display: 'block',
    marginBottom: 8,
  },
}));

export const TextWysiwyg = ({
  onChange,
  onBlur,
  value,
  placeholder = '',
  showEditor = true,
  disabled = false,
  readOnly = false,
  testid,
  error,
  showCount,
  maxLength,
  label,
}: FieldProps) => {
  const classes = useStyles();
  const [placeholderValue, setPlaceholderValue] = useState(placeholder);

  const handleEditorFocus = () => {
    if (!value) {
      setPlaceholderValue('');
    }
  };

  const handleEditorBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!value && placeholder) {
      setPlaceholderValue(placeholder);
    }
    if (typeof onBlur === 'function') {
      onBlur(e);
    }

    const v = cleanHTML(e.target.innerHTML);

    if (v !== e.target.innerHTML) {
      e.target.value = v;
      onChange?.(e);
    }
  };

  return (
    <div
      className={cn(classes.wysiwygWrapper, {
        [classes.hideEditor]: !showEditor,
        [classes.disabled]: disabled,
        [classes.readOnly]: readOnly,
        [classes.error]: error,
      })}
      data-testid={testid}
      aria-invalid={error}>
      <FormLabel
        className={cn(classes.label, {
          [classes.showLabel]: !!label,
        })}>
        {label}
      </FormLabel>
      <DefaultEditor
        disabled={disabled || readOnly}
        value={value}
        onFocus={handleEditorFocus}
        onBlur={handleEditorBlur}
        onChange={onChange}
        onDrop={(e) => {
          const files = e.dataTransfer.files;
          const hasFiles = files.length > 0;

          if (hasFiles) {
            e.preventDefault();
          }
        }}
        placeholder={placeholderValue}
        onPaste={(event) => {
          const html = event.clipboardData.getData('text/html');
          const hasFiles = event.clipboardData?.files?.length;
          const hasImage = /<img .*?>/g.test(html);
          if (hasImage || hasFiles) {
            event.preventDefault();
          }
        }}
      />
      {showCount && value !== undefined ? (
        <span className={classes.count}>
          {length(value)}
          {maxLength ? `/${maxLength}` : null}
        </span>
      ) : null}
    </div>
  );
};

export default TextWysiwyg;
