import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import {
  Button,
  FormGroup,
  FormButtons,
  AttachFilesButton,
  BeforeUnload,
} from '../common';
import { TextFieldWysiwyg } from './wrappers';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import { useState } from 'react';
import { CLASS_TRACKING } from '../../utils/tracking_class';

export interface Values {
  contents: string;
  attachments: Attachment[];
}

type Errors = {
  [K in keyof Values]?: string;
};

interface NoteFormProps {
  title: string;
  loading?: boolean;
  note?: {
    contents: string;
    attachments: Attachment[];
  };
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
  onUploadFile?: (file: File) => Promise<string>;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  field: {
    '& [name="contents"]': {
      minHeight: 107,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
}));

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.contents) {
    errors.contents = 'Required';
  }
  return errors;
};

function NoteForm({
  title,
  note,
  loading = false,
  onSubmit,
  onUploadFile,
  onCancel,
}: NoteFormProps) {
  const classes = useStyles();
  const initialValues: Values = {
    contents: note?.contents || '',
    attachments: note?.attachments || [],
  };
  const [attachments, setAttachments] = useState(initialValues.attachments);

  const handleSubmit = (values: Values) => {
    onSubmit({ contents: values.contents, attachments });
  };

  const handleFileUpload = async (file: File) => {
    if (onUploadFile) {
      try {
        const fileIndex = attachments.length;
        setAttachments((prevAttachments) => [
          ...prevAttachments,
          getAttachmentFromFile(file),
        ]);
        const fileURL = await onUploadFile(file);
        setAttachments((prevAttachments) => {
          return prevAttachments.map((prevAttach, prevAttachIndex) => {
            if (prevAttachIndex !== fileIndex) {
              return prevAttach;
            }
            return {
              ...prevAttach,
              url: fileURL,
            };
          });
        });
      } catch (e: any) {}
    }
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateForm}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <FormGroup>
              <Field<string>
                className={classes.field}
                name='contents'
                component={TextFieldWysiwyg}
                placeholder='Note'
                label='Note'
                multiline
                testid='form-note-text'
              />
            </FormGroup>
            {onUploadFile ? (
              <div className={classes.attachBtn}>
                <AttachFilesButton onChange={handleSelectFile} />
              </div>
            ) : null}
            <div className={classes.attachmentsList}>
              {attachments.map((attachment, attachIndex) => (
                <div key={attachIndex} className={classes.attachment}>
                  <AttachmentCard
                    attachment={attachment}
                    loading={!attachment.url}
                    onRemove={() => handleFileRemove(attachIndex)}
                  />
                </div>
              ))}
            </div>
            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='form-note-submit'
                type='submit'
                disabled={loading}
                className={CLASS_TRACKING.INTERNAL_ACTION}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='outlined' onClick={handleCancel}>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default NoteForm;
