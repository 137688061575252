import * as Item from '../../common/types/Item';
import {
  CommunityMember,
  CommunityMemberId,
} from '../../../../api/CommunityMembers/types/CommunityMember';

// region Loading
export interface Loading {
  type: 'Loading';
  payload: { id: CommunityMemberId };
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: {
    id: CommunityMemberId;
    message: string;
  };
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface Ready {
  type: 'Ready';
  payload: {
    id: CommunityMemberId;
    member: CommunityMember;
    item: Item.Valid;
    logo: File | undefined;
  };
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Edited
interface EditedPayload {
  id: CommunityMemberId;
  member: CommunityMember;
  item: Item.Item;
  logo: File | undefined;
}

export interface Edited {
  type: 'Edited';
  payload: EditedPayload;
}

export const edited = (payload: Edited['payload']): Edited => ({
  type: 'Edited',
  payload,
});
// endregion

// region Uploading
export interface Uploading {
  type: 'Uploading';
  payload: {
    id: CommunityMemberId;
    item: Item.Item;
    member: CommunityMember;
    logo: File;
  };
}

export const uploading = (payload: Uploading['payload']): Uploading => ({
  type: 'Uploading',
  payload,
});
// endregion

// region Submitted
export interface Submitted {
  type: 'Submitted';
  payload: {
    id: CommunityMemberId;
    item: Item.Submitted;
    member: CommunityMember;
    logo: File | undefined;
  };
}

export const submitted = (payload: Submitted['payload']): Submitted => ({
  type: 'Submitted',
  payload,
});
// endregion

// region Saving
export interface Saving {
  type: 'Saving';
  payload: {
    id: CommunityMemberId;
    item: Item.Valid;
    member: CommunityMember;
    logo: File | undefined;
  };
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: {
    id: CommunityMemberId;
    item: Item.Valid;
    message: string;
    member: CommunityMember;
    logo: File | undefined;
  };
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: {
    id: CommunityMemberId;
    item: Item.Valid;
    member: CommunityMember;
    logo: File | undefined;
  };
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region VerificationError
export interface VerificationErrorPayload extends EditedPayload {
  message: string;
}

export interface VerificationError {
  type: 'VerificationError';
  payload: VerificationErrorPayload;
}

export const verificationError = (
  payload: VerificationError['payload'],
): VerificationError => ({
  type: 'VerificationError',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | Edited
  | Uploading
  | Submitted
  | Saving
  | SaveError
  | SaveSuccess
  | VerificationError;

export function init(id: CommunityMemberId): Loading {
  return loading({ id });
}

// region Loaded
export type Loaded =
  | Ready
  | Edited
  | Uploading
  | Submitted
  | SaveError
  | SaveSuccess
  | VerificationError;

export function isLoaded(s: State): s is Loaded {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Saving':
    case 'Uploading':
    case 'Edited':
    case 'Ready':
    case 'Submitted':
    case 'SaveError':
    case 'VerificationError':
    case 'SaveSuccess':
      return true;
  }
}
// endregion

// region Editable
export type Editable =
  | Ready
  | Edited
  | Uploading
  | Submitted
  | SaveError
  | SaveSuccess
  | VerificationError;

export function isEditable(s: State): s is Editable {
  switch (s.type) {
    case 'Saving':
    case 'Uploading':
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Edited':
    case 'Ready':
    case 'Submitted':
    case 'SaveSuccess':
    case 'SaveError':
    case 'VerificationError':
      return true;
  }
}
// endregion

// region Submittable
export type Submittable =
  | Edited
  | Submitted
  | SaveError
  | SaveSuccess
  | Ready
  | VerificationError;

export function isSubmittable(s: State): s is Submittable {
  switch (s.type) {
    case 'Submitted':
    case 'SaveError':
    case 'Edited':
    case 'SaveSuccess':
    case 'Ready':
    case 'VerificationError':
      return true;
    case 'Loading':
    case 'LoadError':
    case 'Uploading':
    case 'Saving':
      return false;
  }
}
// endregion
