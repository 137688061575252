// region Loading
import { TenantId } from '../../../../api/tenants/Tenant';
import { Item } from './Item';

export interface LoadingPayload {
  tenantId: TenantId;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  items: Item[];
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

export type State = Loading | Ready;
