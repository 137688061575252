import { Create, Question, QuestionId, Update } from './Question';
import { axiosRequest } from '../../instance';
import { AxiosError } from 'axios';
import { TenantId } from '../Tenant';

export function get(): Promise<Question[]> {
  return axiosRequest
    .get<Question[]>({
      url: '/tenants/ventures/quarterlyrecords/questiontemplates',
      credentials: true,
    })
    .then((res) => res.data)
    .catch((e: AxiosError) => {
      switch (e.response?.status) {
        case 404:
          return [];
        default: {
          throw e;
        }
      }
    });
}

/**
 * No need to be logged in
 */
export function getAnonymously(id: TenantId): Promise<Question[]> {
  return axiosRequest
    .get<Question[]>({
      url: `/public/tenants/${id}/ventures/quarterlyrecords/questiontemplates`,
    })
    .then((res) => res.data)
    .catch((e: AxiosError) => {
      switch (e.response?.status) {
        case 404:
          return [];
        default: {
          throw e;
        }
      }
    });
}

export function create(data: Create): Promise<Question> {
  return axiosRequest
    .post<Question>({
      url: '/tenants/ventures/quarterlyrecords/questiontemplates',
      credentials: true,
      data,
    })
    .then((res) => res.data);
}

export function update(data: Update): Promise<Question> {
  return axiosRequest
    .put<Question>({
      url: '/tenants/ventures/quarterlyrecords/questiontemplates',
      credentials: true,
      data,
    })
    .then((res) => res.data);
}

export function remove(id: QuestionId): Promise<Question> {
  return axiosRequest
    .delete<Question>({
      url: `/tenants/ventures/quarterlyrecords/questiontemplates/${id}`,
      credentials: true,
    })
    .then((res) => res.data);
}
