import { Paper, PaperProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useMemo } from 'react';
import { Specialization } from '../../api/specializations';
import { IssueContext } from '../../contexts/issue-context';
import TextField, { TextFieldProps } from './text-field';

interface SpecializationInputProps {
  value: Specialization['id'][];
  disabled?: boolean;
  textFieldProps: TextFieldProps;
  onChange: (event: any) => any;
  readOnly: boolean;
  testid?: string;
}

const useStyles = makeStyles({
  paper: {
    padding: 0,
    borderRadius: 4,
    boxShadow:
      '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)',
  },
});

function IssuesInput({
  value = [],
  disabled,
  textFieldProps,
  onChange,
  testid,
  readOnly = false,
}: SpecializationInputProps) {
  const classes = useStyles();
  const { issue, loadIssue } = useContext(IssueContext);

  const options = useMemo(() => issue || [], [issue]);

  const handleChange = (event: React.ChangeEvent<{}>, nextValue: string[]) => {
    if (readOnly) return;
    onChange(nextValue);
  };

  const getOptionLabel = (value: string) => {
    return options.find((option) => option.id === value)?.name || '';
  };

  useEffect(() => {
    if (!issue) {
      loadIssue();
    }
  }, [issue, loadIssue]);

  return (
    <Autocomplete
      multiple
      value={value}
      disableCloseOnSelect
      options={options.map((val) => {
        return val.id;
      })}
      disableClearable
      getOptionLabel={(option) => getOptionLabel(option)}
      onChange={handleChange}
      filterSelectedOptions
      data-testid={testid}
      renderInput={(params) => {
        return (
          <TextField
            data-testid='autocomplete-input'
            {...textFieldProps}
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            inputProps={{ ...(params?.inputProps || {}), readOnly }}
          />
        );
      }}
      PaperComponent={(paperProps: PaperProps) => {
        return !readOnly && value.length !== 4 ? (
          <Paper
            {...paperProps}
            className={classes.paper}
            data-testid='autocomplete-paper'
          />
        ) : (
          <></>
        );
      }}
      disabled={disabled || !Array.isArray(options)}
    />
  );
}

export default IssuesInput;
