import { AdvisorWithSpecializations } from '../../../../../api/Advisors/types/Advisor';
import { Specialization } from '../../../../../api/specializations';
import { TenantTimeZone } from '../../../../../api/tenants/types/Settings';
import { Filter } from './Filter';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: number;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    specializations: Specialization[];
    advisors: AdvisorWithSpecializations[];
    nextPage: boolean;
    strictOfficeHoursScheduling: boolean;
    timeZone: TenantTimeZone;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: 'FetchSuccess';
  payload: {
    advisors: AdvisorWithSpecializations[];
    nextPage: boolean;
  };
}

export const fetchSuccess = (
  payload: FetchSuccess['payload'],
): FetchSuccess => ({
  type: 'FetchSuccess',
  payload,
});
// endregion

// region FetchError
export interface FetchError {
  type: 'FetchError';
  payload: string;
}

export const fetchError = (payload: FetchError['payload']): FetchError => ({
  type: 'FetchError',
  payload,
});
// endregion

// region Next
export interface Next {
  type: 'Next';
}

export const next = (): Next => ({
  type: 'Next',
});
// endregion

// region Prev
export interface Prev {
  type: 'Prev';
}

export const prev = (): Prev => ({
  type: 'Prev',
});
// endregion

// region SetFilter
export interface SetFilter {
  type: 'SetFilter';
  payload: Filter;
}

export const setFilter = (payload: SetFilter['payload']): SetFilter => ({
  type: 'SetFilter',
  payload,
});
// endregion

export type Actions =
  | LoadError
  | LoadSuccess
  | FetchSuccess
  | FetchError
  | Next
  | Prev
  | SetFilter;
