import * as EmailValidator from 'email-validator';
import { Either, left, right } from '../Either';

declare const tag: unique symbol;

export type Email = string & { [tag]: 'Email' };

export const isEmail = (value: string): value is Email =>
  EmailValidator.validate(value);

export const fromString = (s: string): Email | undefined => {
  const _s = s.trim();

  return EmailValidator.validate(_s) ? (_s as Email) : undefined;
};

export const stringToEitherEmail = (s: string): Either<string, Email> => {
  const _s = s.trim();

  return _s && EmailValidator.validate(_s)
    ? right(_s as Email)
    : left('Invalid Email');
};
