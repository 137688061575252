import { startOfToday } from 'date-fns';
import { AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { TenantId } from '../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { initial } from '../../../../utils/FormValue';
import * as Item from '../../common/types/Item';

// region Ready
export interface ReadyPayload {
  timeZone: TenantTimeZone;
  item: Item.Initial;
  tenantId: TenantId;
  enrollmentDate: Date;
  logo: File | undefined;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Edited
export interface EditedPayload extends Omit<ReadyPayload, 'item'> {
  item: Item.Item;
}

export interface Edited {
  type: 'Edited';
  payload: EditedPayload;
}

export const edited = (payload: Edited['payload']): Edited => ({
  type: 'Edited',
  payload,
});
// endregion

// region Uploading
export interface UploadingPayload extends EditedPayload {
  logo: File;
}

export interface Uploading {
  type: 'Uploading';
  payload: UploadingPayload;
}

export const uploading = (payload: Uploading['payload']): Uploading => ({
  type: 'Uploading',
  payload,
});
// endregion

// region Submitted
export interface SubmittedPayload extends EditedPayload {
  item: Item.Submitted;
}

export interface Submitted {
  type: 'Submitted';
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted['payload']): Submitted => ({
  type: 'Submitted',
  payload,
});
// endregion

// region Saving
export interface SavingPayload extends EditedPayload {
  item: Item.Valid;
}

export interface Saving {
  type: 'Saving';
  payload: SavingPayload;
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveErrorPayload extends SavingPayload {
  message: string;
}

export interface SaveError {
  type: 'SaveError';
  payload: SaveErrorPayload;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region Redirect
export interface RedirectPayload extends Omit<ReadyPayload, 'item'> {
  item: Item.Valid;
  tenantId: TenantId;
  enrollmentDate: Date;
  id: AdvisorId;
}

export interface Redirect {
  type: 'Redirect';
  payload: RedirectPayload;
}

export const redirect = (payload: Redirect['payload']): Redirect => ({
  type: 'Redirect',
  payload,
});
// endregion

// region VerificationError
export interface VerificationErrorPayload extends EditedPayload {
  message: string;
}

export interface VerificationError {
  type: 'VerificationError';
  payload: VerificationErrorPayload;
}

export const verificationError = (
  payload: VerificationError['payload'],
): VerificationError => ({
  type: 'VerificationError',
  payload,
});
// endregion

export type State =
  | Ready
  | Edited
  | Uploading
  | Submitted
  | Saving
  | SaveError
  | Redirect
  | VerificationError;

export function init(tenantId: TenantId, timeZone: TenantTimeZone): Ready {
  return ready({
    tenantId,
    enrollmentDate: startOfToday(),
    logo: undefined,
    timeZone,
    item: {
      address: initial(undefined),
      birthDate: initial(undefined),
      city: initial(undefined),
      country: initial(undefined),
      email: initial(undefined),
      firstName: initial(undefined),
      lastName: initial(undefined),
      linkedIn: initial(undefined),
      logo: initial(undefined),
      long: initial(undefined),
      phone: initial(undefined),
      short: initial(undefined),
      state: initial(undefined),
      status: initial('Active'),
      zip: initial(undefined),
      specializations: initial([]),
      rate: initial(undefined),
    },
  });
}

// region Editable
export type Editable =
  | Ready
  | Edited
  | Uploading
  | Submitted
  | SaveError
  | VerificationError;

export function isEditable(s: State): s is Editable {
  switch (s.type) {
    case 'Saving':
    case 'Uploading':
    case 'Redirect':
      return false;
    case 'Edited':
    case 'Ready':
    case 'Submitted':
    case 'SaveError':
    case 'VerificationError':
      return true;
  }
}
// endregion

// region Submittable
export type Submittable =
  | Ready
  | Edited
  | Submitted
  | SaveError
  | VerificationError;

export function isSubmittable(s: State): s is Submittable {
  switch (s.type) {
    case 'Submitted':
    case 'SaveError':
    case 'Edited':
    case 'Ready':
    case 'VerificationError':
      return true;
    case 'Uploading':
    case 'Saving':
    case 'Redirect':
      return false;
  }
}
// endregion
