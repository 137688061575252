import { utcToZonedTime } from 'date-fns-tz';

export class ZonedDate<T extends string> extends Date {
  private readonly _timeZone: T;

  constructor(date: Date, zone: T) {
    super(utcToZonedTime(date.getTime(), zone));
    this._timeZone = zone;
  }

  get timeZone(): T {
    return this._timeZone;
  }
}
