import { Checkbox } from '@material-ui/core';
import React from 'react';
import { TestId } from '../../../Testing/TestId';

export interface CheckProps {
  checked: boolean;
  onChange: () => void;
  testId: string;
}

export function Check({ checked, testId, onChange }: CheckProps) {
  return (
    <TestId testId={testId}>
      <Checkbox checked={checked} color='primary' onChange={onChange} />
    </TestId>
  );
}
