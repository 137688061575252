import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { VentureId } from '../../../api/ventures/types/Venture';
import { Button } from '../../common';
import { DialogTitle } from '../../common/dialog';
import * as Actions from './types/Actions';
import { isLoading, isOpen } from './types/State';
import { useReportLinkDialog } from './useReportLinkDialog';

const useStyles = makeStyles({
  paper: {
    width: 480,
  },
  content: {
    padding: '15px 32px 0',
    overflow: 'visible',
  },
});

export interface Props {
  ventureIds: VentureId[];
  children: (data: { open: () => void }) => React.ReactNode;
}

export function ReportLinkDialog({ children, ventureIds }: Props) {
  const [state, dispatch] = useReportLinkDialog(ventureIds);
  const classes = useStyles();

  const onOpen = useCallback(() => dispatch(Actions.open()), [dispatch]);
  const onClose = useCallback(() => dispatch(Actions.close()), [dispatch]);
  const handleSend = useCallback(() => dispatch(Actions.send()), [dispatch]);
  const loading = isLoading(state);

  return (
    <>
      <Dialog
        open={isOpen(state)}
        onClose={onClose}
        PaperProps={{
          className: classes.paper,
          elevation: 3,
        }}>
        <DialogTitle onClose={onClose}>
          Request the Latest Quarterly Report
        </DialogTitle>
        <DialogContent
          data-testid='calendar-element'
          className={classes.content}>
          Your are about to send a temporary link to allow participants to
          update their profile.
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={onClose}
            data-testid='button-cancel'>
            Cancel
          </Button>
          <Button
            onClick={handleSend}
            disabled={loading}
            data-testid='button-success'>
            {loading ? <CircularProgress size={24} color='inherit' /> : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
      {children({ open: onOpen })}
    </>
  );
}
