import { TenantId } from '../../api/tenants/Tenant';
import { ReactElement, useContext, useEffect, useReducer } from 'react';
import { TenantLogoContext } from '../../contexts/tenant-logo';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface Props {
  id: TenantId;
  className?: string;
  error?: ReactElement;
  loading?: ReactElement;
}

type Value = undefined | null | string;

const reducer = (s: Value, a: null | string): Value => {
  return a;
};

export function TenantLogo({ id, error, loading, className }: Props) {
  const { getImage } = useContext(TenantLogoContext);
  const [state, dispatch] = useReducer(reducer, undefined);

  useEffect(() => {
    const o$ = from(getImage(id)).pipe(catchError(() => of(null))).subscribe(dispatch);

    return () => {
      o$.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  switch (state) {
    case undefined:
      return loading ?? null;
    case null :
      return error ?? null;
    default:
      return <img src={state} alt={''} className={className} />;
  }
}