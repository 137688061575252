import { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core';
import { useEffect } from 'react';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { TextField } from '../common';

export type FieldProps = MaterialTextFieldProps & {
  ventureName?: '';
  onChange: (e: any) => void;
};

function FieldSummary({
  onChange,
  name,
  value,
  ventureName,
  ...rest
}: FieldProps) {
  const { rb } = useResourceBundles();

  useEffect(() => {
    if (ventureName && !value && value !== ventureName) {
      const currentVentureName = ventureName
        ? `${ventureName} ${rb('mentoring-u')} Session`
        : '';
      onChange(currentVentureName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventureName]);

  return <TextField {...rest} name={name} onChange={onChange} value={value} />;
}

export default FieldSummary;
