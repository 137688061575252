import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { Fragment, JSXElementConstructor, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../../../theme/variables';
import { CLASS_TRACKING } from '../../../../utils/tracking_class';

interface MenuItemProps {
  id: string;
  className: string;
  label: string;
  icon: React.ReactNode;
  link: string;
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: MONTSERRAT_FONT_FAMILY,
      padding: '16px 8px 16px 16px',
      color: COLORS.COLOR_TEXT_LIGHTENED_10,
      borderRadius: '8px',
      textDecoration: 'none',

      '&:hover': {
        background: COLORS.COLOR_GRAY_LIGHTENED_45,
      },
    },
  },
  isActive: {
    '& a, & a:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45,
      color: COLORS.COLOR_BLUE_DARKENED_10,
    },
    '& $icon': {
      color: COLORS.COLOR_BLUE_DARKENED_10,
    },
  },

  group: {
    padding: '14px 0',
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    textTransform: 'uppercase',
    marginTop: '16px',
    fontSize: '10px',
    fontWeight: 500,
    textAlign: 'left',

    [theme.breakpoints.up('lg')]: {
      fontSize: '11px',
      padding: '14px 19px',
    },
  },
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
  },
}));

function MenuItem({ id, className, label, icon, link }: MenuItemProps) {
  return (
    <li
      className={cn(className)}
      data-testid={`menu-item-${id.toLocaleLowerCase().replace(/\s/g, '-')}`}>
      <Link to={link}>
        {icon}
        <span>{label}</span>
      </Link>
    </li>
  );
}

export interface Item {
  id: string;
  icon: JSXElementConstructor<{ className?: string }>;
  label: string;
  link: string;
  active: boolean;
}

export interface Group {
  label: string;
  items: Item[];
}

export interface Props {
  items?: Item[];
  groups?: Group[];
}

export function SidebarMenu({ items, groups }: Props): ReactElement<Props> {
  const classes = useStyles();
  const location = useLocation();
  const rootURL = location.pathname.split('/')[1];
  const isActivePage = (pageURL: string) => {
    const rootPageURL = pageURL.split('/')[1];

    return rootPageURL.includes(rootURL);
  };

  return (
    <div>
      {items && (
        <ul>
          {items.map(({ id, label, icon: IconComponent, link, active }) => (
            <MenuItem
              key={id}
              id={id}
              className={cn(
                classes.menuItem,
                CLASS_TRACKING.SIDE_NAVIGATION_CLASS,
                {
                  [classes.isActive]: active,
                },
              )}
              label={label}
              icon={<IconComponent className={classes.icon} />}
              link={link}
            />
          ))}
        </ul>
      )}
      {groups &&
        groups.map(({ items, label }, i) => {
          return (
            <Fragment key={`groups-${label}`}>
              <div className={classes.group} key={`group-label-${i}`}>
                {label}
              </div>
              <ul key={`group-items-${i}`}>
                {items.map(({ id, label, icon: IconComponent, link }) => (
                  <MenuItem
                    id={id}
                    key={label}
                    className={cn(classes.menuItem, {
                      [classes.isActive]: isActivePage(link),
                    })}
                    label={label}
                    icon={<IconComponent className={classes.icon} />}
                    link={link}
                  />
                ))}
              </ul>
            </Fragment>
          );
        })}
    </div>
  );
}
