import { Tenant } from './auth';
import { axiosRequest } from './instance';
import { TenantId } from './tenants/Tenant';

// region SpecializationId
declare const _specializationId: unique symbol;

export type SpecializationId = string & {
  [_specializationId]: 'SpecializationId';
};
// endregion

export interface Specialization {
  id: SpecializationId;
  name: string;
  tenantId: TenantId;
  valid: boolean;
}

const specializationsAPI = {
  async getAll() {
    const res = await axiosRequest.get<Specialization[]>({
      url: '/specializations',
      credentials: true,
    });
    return res.data;
  },
  async getPublicAll(tenantId: TenantId) {
    const res = await axiosRequest.get<Specialization[]>({
      url: `/public/tenants/${tenantId}/specializations`,
      credentials: false,
    });
    return res.data;
  },
  async createSpecialization({
    name,
    tenantId,
  }: {
    name: string;
    tenantId: TenantId;
  }) {
    const res = await axiosRequest.post<Specialization>({
      url: '/specializations',
      data: { name, tenantId },
      credentials: true,
    });
    return res.data;
  },
  async updateSpecialization(specialization: Specialization) {
    const res = await axiosRequest.put<Specialization>({
      url: `/specializations`,
      data: specialization,
      credentials: true,
    });
    return res.data;
  },
  async deleteSpecialization(id: SpecializationId) {
    const res = await axiosRequest.delete<Specialization>({
      url: `/specializations/${id}`,
      credentials: true,
    });
    return res.data;
  },
  async getCustomSpecializationsStatus() {
    const res = await axiosRequest.get<boolean>({
      url: '/customspecializations',
      credentials: true,
    });
    return res.data;
  },
  async enableCustomSpecializations() {
    const res = await axiosRequest.post<Tenant>({
      url: '/customspecializations',
      credentials: true,
    });
    return res.data;
  },
  async disableCustomSpecializations() {
    const res = await axiosRequest.delete<Tenant>({
      url: '/customspecializations',
      credentials: true,
    });
    return res.data;
  },
};

export default specializationsAPI;
