import { Tab, Tabs as MUITabs, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../theme/variables';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },

  tooltipWrapper: {
    marginLeft: '30px !important',

    '& > button': {
      whiteSpace: 'nowrap',
    },
  },
}));

export interface TabItem {
  title: string;
  disabled?: boolean;
  tooltip?: string;
  link: string;
  testId?: string;
}

export interface Props {
  active: string;
  tabs: TabItem[];
}

export function Tabs({ tabs, active }: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <MUITabs
        value={active}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='on'>
        {tabs.map(({ title, tooltip, link, disabled, testId }) => {
          const tab = (
            <Tab
              component={Link}
              value={link}
              to={link}
              label={title}
              disabled={disabled}
              key={link}
              data-testid={testId}
            />
          );
          return tooltip ? (
            <Tooltip title={tooltip} key={link}>
              <span className={classes.tooltipWrapper} key={link}>
                {tab}
              </span>
            </Tooltip>
          ) : (
            tab
          );
        })}
      </MUITabs>
    </div>
  );
}
