import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Settings from '@material-ui/icons/Settings';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CopyIcon } from '../../assets/icons';
import { Text } from '../../components/common';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface FormSectionProps {
  id: string;
  label: string;
  link: string;
  description: string;
  copied: string | null;
  onCopy: (text: string, id: string) => void;
}

const useStyles = makeStyles({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  topBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 8,
  },
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',
    marginLeft: 8,

    '&:hover': {
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  itemLink: {
    width: 300,
    display: 'flex',
    alignItems: 'center',
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE,

    '&:hover': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
  linkLabel: {
    fontSize: 16,
  },
});

function FormSection({
  id,
  label,
  link,
  description,
  copied,
  onCopy,
}: FormSectionProps) {
  const classes = useStyles();

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.topBlock}>
        <div className={classes.itemLink} data-testid='links-page-href'>
          <Text variant='normal' className={classes.linkLabel}>
            - {label}
          </Text>
          {copied !== id ? (
            <Tooltip title='Copy link'>
              <span
                data-link={link}
                data-testid={`button-link-copy-${id}`}
                onClick={() => onCopy(link, id)}>
                <CopyIcon className={classes.icon} />
              </span>
            </Tooltip>
          ) : (
            <CheckCircleIcon
              className={cn(classes.icon, classes.successIcon)}
            />
          )}
        </div>
        <Tooltip title={`${label} Settings`}>
          <IconButton
            component={Link}
            to={getRoutePath(Pages.TENANT_FORMS_EDIT, {
              formId: id,
            })}
            data-testid={`${id}-application-form-settings`}>
            <Settings />
          </IconButton>
        </Tooltip>
      </div>
      <Text variant='normal'>{description}</Text>
    </div>
  );
}

export default FormSection;
