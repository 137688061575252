import * as Either from '../Either';

declare const tag: unique symbol;

export type Sentence<Ln extends number> = string & { [tag]: Ln };

export function fromString<Ln extends number>(
  ln: Ln,
  str: string,
): Either.Either<'empty' | 'overflow', Sentence<Ln>>;
export function fromString<Ln extends number>(
  ln: Ln,
): (str: string) => Either.Either<'empty' | 'overflow', Sentence<Ln>>;
export function fromString<Ln extends number>(
  ...args: [ln: Ln] | [ln: Ln, str: string]
):
  | ((s: string) => Either.Either<'empty' | 'overflow', Sentence<Ln>>)
  | Either.Either<'empty' | 'overflow', Sentence<Ln>> {
  const fn = (
    str: string,
  ): Either.Either<'empty' | 'overflow', Sentence<Ln>> => {
    const s = str.trim();

    if (s.length === 0) {
      return Either.left('empty');
    }

    if (s.length > args[0]) {
      return Either.left('overflow');
    }

    return Either.right(s as Sentence<Ln>);
  };

  return args.length === 2 ? fn(args[1]) : fn;
}
