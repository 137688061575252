import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer } from 'react';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { statistics } from '../../api/OfficeHours';
import { unreachableError } from '../../utils/unreachableError';
import { reducer } from './reducer';
import { Actions, loadSuccess } from './types/Actions';
import { loading, State } from './types/State';

export function useOfficeHoursDashboard(): [State, Dispatch<Actions>] {
  const [s, d] = useReducer(reducer, loading({}));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    switch (s.type) {
      case 'Loading': {
        const v$ = from(statistics())
          .pipe(
            map((r) =>
              loadSuccess({
                communityMembers: {
                  joinedLastMonth: r.communityMembersJoinedLastMonth,
                  withAppointments: r.communityMembersWithAppointmentsLastMonth,
                  applicants: r.communityMembersApplicants,
                  total: r.communityMembersActive,
                },
                advisors: {
                  total: r.activeAdvisors,
                  thisWeek: r.advisorsAppointmentsThisWeek,
                  nextWeek: r.advisorsAppointmentsNextWeek,
                  afterNextWeek: r.advisorsAppointmentsAfterNextWeek,
                },
                appointments: {
                  total: r.activeAppointments,
                  declinedUnconfirmed:
                    r.declinedAppointments + r.pendingAppointments,
                  accepted: r.acceptedAppointments,
                },
              }),
            ),
          )
          .subscribe(d);
        return () => v$.unsubscribe();
      }
      case 'LoadError': {
        const v = enqueueSnackbar(s.payload.message, { variant: 'error' });

        return () => closeSnackbar(v);
      }
      case 'Ready':
        return;
      default:
        unreachableError(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type]);

  return [s, d];
}
