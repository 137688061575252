import { invalid, isValid, valid } from '../../../utils/FormValue';
import { isPassword } from '../../../utils/String/Password';
import { Actions } from './types/Actions';
import { edited, sendError, sending, sendSuccess, State } from './types/State';

export function reducer(s: State, a: Actions): State {
  switch (a.type) {
    case 'SetPassword':
      return s.type === 'Ready' || s.type === 'Edited' || s.type === 'SendError'
        ? edited({
            ...s.payload,
            password: isPassword(a.payload)
              ? valid(a.payload)
              : invalid('required', a.payload),
          })
        : s;
    case 'SetPasswordRepeat':
      return s.type === 'Ready' || s.type === 'Edited' || s.type === 'SendError'
        ? edited({
            ...s.payload,
            passwordRepeat: isPassword(a.payload)
              ? valid(a.payload)
              : invalid('required', a.payload),
          })
        : s;
    case 'Submit':
      return (s.type === 'Edited' || s.type === 'SendError') &&
        isValid(s.payload.password) &&
        isValid(s.payload.passwordRepeat) &&
        s.payload.password.value === s.payload.passwordRepeat.value
        ? sending({
            ...s.payload,
            password: s.payload.password,
            passwordRepeat: s.payload.passwordRepeat,
          })
        : s.type === 'Ready'
        ? edited({
            ...s.payload,
            password: invalid('required', undefined),
            passwordRepeat: invalid('required', undefined),
          })
        : s;
    case 'SendError':
      return s.type === 'Sending'
        ? sendError({ ...s.payload, message: a.payload })
        : s;
    case 'SendSuccess':
      return s.type === 'Sending' ? sendSuccess(s.payload) : s;
  }
}
