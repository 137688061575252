import { CircularProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import authAPI from '../../api/auth';
import { Role } from '../../api/user/Role';
import {
  BeforeUnload,
  Button,
  FormButtons,
  FormGroup,
  SnackMessage,
  Text,
} from '../../components/common';
import {
  FormCheckNewEmail,
  TextFieldWrapper,
} from '../../components/forms/wrappers';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { lengthField } from '../../utils/form';
import { validateEmail } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 670,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 560,
    },
  },
  sectionBlock: {
    '& + &': {
      marginTop: 26,
    },
  },
  titleDescription: {
    paddingTop: 20,
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  highlightedText: {
    fontStyle: 'italic',
    color: COLORS.COLOR_RED_BASE,
  },
  noteContainer: {
    marginTop: 20,
  },
  titleTextField: {
    marginBottom: 10,
  },
}));

interface FormValues {
  email: string;
  creationNote: string;
  firstName: string;
  lastName: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  return errors;
};

function TenantNewAdminPage() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [fieldsValidation, setFieldsValidation] =
    useState<{ email: boolean }>();
  const isSuccesRequest = useRef(false);

  const handleSubmit = async (formValues: FormValues) => {
    try {
      setLoading(true);
      await authAPI.createUsers({
        userRoleName: Role.Manager,
        email: formValues.email.trim(),
        creationNote: formValues.creationNote,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      });
      isSuccesRequest.current = true;

      setLoading(false);
      history.push(Pages.TENANT_USER_MANAGEMENT);
      enqueueSnackbar('The user with the admin role was successfully created', {
        variant: 'success',
      });
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      setLoading(false);
      enqueueSnackbar('An error occurred while creating. Please, try again.', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleFieldsValidation = useCallback(
    (field: 'email') => (valid: boolean) => {
      setFieldsValidation(
        (prevValidations) =>
          ({
            ...(prevValidations ? prevValidations : {}),
            [field]: valid,
          } as { email: boolean }),
      );
    },
    [],
  );

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className={classes.container}>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <>
            <BeforeUnload
              when={
                isSuccesRequest.current ? false : formProps.dirty && !loading
              }
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={
                    loading || !formProps.valid || !fieldsValidation?.email
                  }>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div
              className={classes.formBlocks}
              data-testid='mentor-details-form'>
              <div className={classes.mainFormBlock}>
                <form noValidate>
                  <div className={classes.sectionBlock}>
                    <div className={classes.titleTextField}>
                      <Text variant='normal' bold>
                        Who do you want add?
                      </Text>
                    </div>

                    <FormGroup>
                      <Field<string>
                        data-testid='tenant-new-admin-details-email'
                        name='email'
                        component={FormCheckNewEmail}
                        label='Email*'
                        formatOnBlur
                        format={(value: string) => {
                          return value ? value.toLowerCase() : value;
                        }}
                        entityType='tenant'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.email,
                          },
                        }}
                        onValid={handleFieldsValidation('email')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Field<string>
                        name='firstName'
                        testid='tenant-new-admin-details-first-name'
                        component={TextFieldWrapper}
                        label='First Name*'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.firstName,
                          },
                        }}
                      />

                      <Field<string>
                        name='lastName'
                        testid='tenant-new-admin-details-last-name'
                        component={TextFieldWrapper}
                        label='Last Name*'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.lastName,
                          },
                        }}
                      />
                    </FormGroup>
                  </div>

                  <div className={classes.sectionBlock}>
                    <div className={classes.titleTextField}>
                      <Text
                        className={classes.titleDescription}
                        variant='normal'
                        bold>
                        Add a personal note to the invitation (optional)
                      </Text>
                    </div>

                    <FormGroup>
                      <Field<string>
                        name='creationNote'
                        testid='tenant-new-admin-details-creation-note'
                        label='Note'
                        component={TextFieldWrapper}
                        entityType='mentor'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.description,
                          },
                        }}
                      />
                    </FormGroup>
                  </div>

                  <FormButtons className={classes.formButtons}>
                    <Button
                      data-testid='form-send-submit'
                      disabled={loading || !fieldsValidation?.email}
                      onClick={formProps.handleSubmit}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Send'
                      )}
                    </Button>
                    <Button
                      variant='outlined'
                      data-testid='form-cancel'
                      onClick={handleCancel}>
                      Cancel
                    </Button>
                  </FormButtons>
                </form>
              </div>
            </div>
          </>
        )}
      />
      <div className={classes.noteContainer}>
        <Text variant='normal' bold>
          What happens next?
        </Text>{' '}
        <Text variant='normal'>
          The person you are adding above will be immediately added to the team
          and have{' '}
          <Text variant='normal' className={classes.highlightedText}>
            full access and have full access to everything as an administrator.
          </Text>{' '}
          They'll receive an email with instructions to join Traction5.
        </Text>
      </div>
    </div>
  );
}

export default TenantNewAdminPage;
