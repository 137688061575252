import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { useEffect } from 'react';

interface Props {
  onChange: (text: string) => void;
}

function OnChangePlugin({ onChange }: Props) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unsubscribe = editor.registerUpdateListener(() => {
      editor.update(() => {
        const root = $getRoot();
        const text = root.getTextContent();
        onChange(text);
      });
    });

    return () => {
      unsubscribe();
    };
  }, [editor, onChange]);

  return null;
}

export default OnChangePlugin;
