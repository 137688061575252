import { IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { CreateCheckin } from '../../api/types/Checkin';
import { Goal } from '../../api/types/Goal';
import { COLORS } from '../../theme/variables';
import { GoalModal } from '../founders/dashboard/GoalModal';

interface NewCheckinProps {
  goal: Goal;
  onChange: (goal: CreateCheckin) => void;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },
  },
});

function NewCheckin({ goal, onChange }: NewCheckinProps) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Typography variant='h4'>New Check-in</Typography>
        <IconButton
          className={classes.addBtn}
          onClick={() => setModalOpen(true)}
          data-testid='button-new-checkin'>
          <AddIcon />
        </IconButton>
      </div>
      {modalOpen && (
        <GoalModal
          goal={goal}
          onChange={(value) => {
            onChange(value);
            setModalOpen(false);
          }}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default NewCheckin;
