import { CircularProgress, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import { startOfYesterday } from 'date-fns';
import {
  ChangeEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Status } from '../../../api/CommunityMembers/types/Status';
import { TestId } from '../../../components/Testing/TestId';
import { Button, StickyContent } from '../../../components/common';
import {
  FormItem,
  FormItem2,
} from '../../../components/common/Forms/types/FormItem';
import { SimpleSelect } from '../../../components/common/SimpleSelect';
import { DateInput as MUIDate } from '../../../components/common/date-input';
import { TextField as MUIText } from '../../../components/common/text-field';
import { useDebouncedOnChange } from '../../../hooks/useDebouncedOnChange';
import { COLORS } from '../../../theme/variables';
import { lengthField } from '../../../utils/form';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gap: '45px',
    maxWidth: 700,
    gridTemplateColumns: '1fr 160px',
  },
  column: {
    display: 'grid',
    gap: '56px',
  },
  group: {
    display: 'grid',
    gap: '30px',
    alignContent: 'start',
  },
  row: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',
  },
  title: {},
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
}));

export interface Props {
  firstName: FormItem2<string | undefined, string>;
  address: FormItem<string | undefined, string>;
  birthDate: FormItem<Date | undefined, Date | undefined>;
  zip: FormItem<string | undefined, string>;
  city: FormItem<string | undefined, string>;
  country: FormItem<string | undefined, string>;
  email: FormItem2<string | undefined, string>;
  lastName: FormItem2<string | undefined, string>;
  linkedIn: FormItem<string | undefined, string>;
  state: FormItem<string | undefined, string>;
  status: FormItem<Status, Status>;
  enrollmentDate: Date;
  statuses: Array<{ label: string; value: Status }>;
  onSubmit: () => void;
  disableSubmit: boolean;
}

export function Form({
  firstName,
  lastName,
  birthDate,
  enrollmentDate,
  status,
  statuses,
  address,
  zip,
  city,
  country,
  email,
  linkedIn,
  state,
  onSubmit,
  disableSubmit,
}: Props): ReactElement<Props> {
  const classes = useStyles();
  const [scroll, setScroll] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const handleSubmit = useCallback(() => {
    !scroll && setScroll(true);
    onSubmit();
  }, [onSubmit, scroll]);

  useEffect(() => {
    if (formRef.current && scroll) {
      const el = formRef.current?.querySelector(
        '[data-error=err]',
      ) as HTMLElement | null;

      if (el) {
        const y = el.offsetTop;

        document.getElementById('scroll-content')?.scrollTo({ top: y - 200 });

        setScroll(false);
      }
    }
  }, [scroll]);

  return (
    <div className={classes.root} ref={formRef}>
      <div className={classes.column}>
        <div className={classes.group}>
          <div className={classes.row}>
            <TestId testId={'first-name'}>
              <IconTextField item={firstName} label='First Name *' />
            </TestId>
            <TestId testId={'last-name'}>
              <IconTextField item={lastName} label='Last Name *' />
            </TestId>
          </div>
          <div className={classes.row}>
            <TestId testId={'birth-date'}>
              <MUIDate
                maxDate={startOfYesterday()}
                value={birthDate.value ?? null}
                error={birthDate.error}
                textFieldProps={{
                  label: 'Date of birth',
                }}
                onChange={(d) => birthDate.onChange(d ?? undefined)}
                data-error={birthDate.error ? 'err' : undefined}
              />
            </TestId>
            <MUIDate
              value={enrollmentDate}
              textFieldProps={{
                label: 'Date of enrollment',
              }}
              onChange={empty}
              disabled
            />
          </div>
        </div>
        <div className={classes.group}>
          <GroupTitle>Contacts</GroupTitle>
          <TestId testId={'email'}>
            <IconTextField item={email} label='Email *' />
          </TestId>
          <TestId testId={'in-profile'}>
            <TextField item={linkedIn} label='LinkedIn profile' />
          </TestId>
        </div>
        <div className={classes.group}>
          <GroupTitle>Address</GroupTitle>
          <TestId testId={'address'}>
            <TextField item={address} label='Address' />
          </TestId>
          <TestId testId={'city'}>
            <TextField item={city} label='City' />
          </TestId>
          <TestId testId={'country'}>
            <TextField item={country} label='Country' />
          </TestId>
          <TestId testId={'zip'}>
            <TextField
              item={zip}
              label='Zip'
              InputProps={{ inputProps: { maxLength: lengthField.zip } }}
            />
          </TestId>
          <TestId testId={'state'}>
            <TextField item={state} label='State' />
          </TestId>
        </div>
      </div>
      <div className={classes.group}>
        <TestId testId={'status'}>
          <SimpleSelect
            value={status.value}
            onChange={status.onChange}
            onBlur={status.onBlur}
            options={statuses}
          />
        </TestId>
      </div>
      <div>
        <StickyContent>
          <TestId testId={'submit'}>
            <Button
              onClick={handleSubmit}
              disabled={disableSubmit}
              startIcon={<CheckIcon />}>
              Save
            </Button>
          </TestId>
        </StickyContent>
      </div>
    </div>
  );
}

interface TPr {
  item: FormItem<string | undefined, string>;
  label: string;
  InputProps?: any;
}
function TextField({
  item: { value, onChange, onBlur, error, errorText },
  label,
  InputProps,
}: TPr): ReactElement<TPr> {
  const [v, debounce] = useDebouncedOnChange(value ?? '', onChange, 500);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debounce(e.target.value),
    [debounce],
  );
  return (
    <MUIText
      data-error={error ? 'err' : undefined}
      value={v}
      error={error}
      errorText={errorText}
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      InputProps={InputProps}
    />
  );
}

function GroupTitle({ children }: PropsWithChildren<{}>) {
  return <Typography variant='h3'>{children}</Typography>;
}

const empty = (): void => {};

interface IconTextFieldProps {
  item: FormItem2<string | undefined, string>;
  label: string;
}
function IconTextField({
  item: { value, onChange, onBlur, status, errorText },
  label,
}: IconTextFieldProps): ReactElement<TPr> {
  const [v, debounce] = useDebouncedOnChange(value ?? '', onChange, 500);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debounce(e.target.value),
    [debounce],
  );
  const classes = useStyles();

  const icon = useMemo(() => {
    switch (status) {
      case 'normal':
        return null;
      case 'loading':
        return (
          <TestId testId={'loading-icon'}>
            <CircularProgress color='primary' size={20} />
          </TestId>
        );
      case 'error':
        return (
          <TestId testId={'error-icon'}>
            <CancelIcon className={classes.errorIcon} />
          </TestId>
        );
      case 'success':
        return (
          <TestId testId={'success-icon'}>
            <CheckCircleIcon className={classes.checkIcon} />
          </TestId>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <MUIText
      data-error={status === 'error' ? 'err' : undefined}
      value={v}
      error={status === 'error'}
      errorText={errorText}
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: icon,
      }}
    />
  );
}
