import { useEffect, useState, useRef } from 'react';
import { CircularProgress, makeStyles, Popover } from '@material-ui/core';
import mentorsAPI, { Mentor } from '../../api/mentors';
import { Text } from '../common';

interface MentorTableInfoProps {
  ventureId: string;
  initialMentors?: Mentor[];
  onLoaded?: (mentors: Mentor[]) => any;
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 320,
    boxSizing: 'border-box'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    '& + &': {
      marginTop: 12
    }
  }
});

function MentorTableInfo({
  ventureId,
  initialMentors,
  onLoaded = () => {},
  children
}: MentorTableInfoProps) {
  const classes = useStyles();
  const [mentors, setMentors] = useState<Mentor[]>(initialMentors || []);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const debouncedTimer = useRef<number>();

  const handleOpenInfo = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.target as HTMLElement);
    if (mentors.length === 0 && !isLoading) {
      try {
        setIsLoading(true);
        setIsOpen(true);
        const loadedMentors = await mentorsAPI.getMentorsDetailsByVentureId(
          ventureId
        );
        setMentors(loadedMentors);
        onLoaded(loadedMentors);
        setIsLoading(false);
      } catch (e: any) {
        console.log('error handleOpenInfo', e);
      }
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      handleOpenInfo(event);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    setIsOpen(false);
  };

  useEffect(() => {
    if (initialMentors) {
      setMentors(initialMentors);
    }
  }, [initialMentors]);

  return (
    <>
      <div
        className={classes.wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={24} color='primary' />
          </div>
        ) : (
          !!mentors.length && (
            <Text variant='normal2'>
              {mentors.map((mentor) => (
                <p key={mentor.id} className={classes.paragraph}>
                  {`${mentor.firstName} ${mentor.lastName}`}
                </p>
              ))}
            </Text>
          )
        )}
      </Popover>
    </>
  );
}

export default MentorTableInfo;
