import { AxiosError } from 'axios';
import { isT } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators';
import {
  getByEmailGlobal,
  getById,
  getByLastName,
  update,
} from '../../../api/CommunityMembers';
import { CommunityMemberId } from '../../../api/CommunityMembers/types/CommunityMember';
import { isVerifying } from '../../../utils/FormValue';
import { isEmail } from '../../../utils/String/Email';
import { Item } from '../common/types/Item';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';
import { savingToUpdate } from './utils';

export function useCommunityMemberEdit(): [
  State.State,
  Dispatch<Actions.SetValue<keyof Item> | Actions.Toggle | Actions.Save>,
] {
  const params = useParams<{ id: CommunityMemberId }>();
  const [state, dispatch] = useReducer(reducer, State.init(params.id));
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const state$ = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    state$.next(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const get$ = state$
      .pipe(
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap(({ payload: { id } }) =>
          from(getById(id)).pipe(
            map(Actions.loadSuccess),
            catchError(() =>
              of(Actions.loadError('Unable to load community member')),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    const checkEmail$ = state$
      .pipe(
        filter(State.isEditable),
        distinctUntilChanged(
          (s1, s2) => s1.payload.item.email === s2.payload.item.email,
        ),
        switchMap((s) => {
          return of(s).pipe(
            map((s) => s.payload.item.email),
            filter(isVerifying),
            map((v) => v.value),
            filter(isT),
            distinctUntilChanged(),
            switchMap((v) => {
              if (!isEmail(v)) {
                return of(
                  Actions.emailValidation({
                    isValid: false,
                    message: 'Email address is invalid',
                  }),
                );
              }

              return from(getByEmailGlobal(v)).pipe(
                mapTo(
                  Actions.emailValidation({
                    isValid: false,
                    message: 'Email address is already used',
                  }),
                ),
                catchError((e: AxiosError) => {
                  return e.response?.status === 404
                    ? of(
                        Actions.emailValidation({
                          isValid: true,
                        }),
                      )
                    : of(Actions.emailValidationError(e.response?.data));
                }),
              );
            }),
          );
        }),
      )
      .subscribe(dispatch);

    const checkName$ = state$
      .pipe(
        filter(State.isEditable),
        distinctUntilChanged(
          (s1, s2) =>
            s1.payload.item.firstName === s2.payload.item.firstName &&
            s1.payload.item.lastName === s2.payload.item.lastName,
        ),
        switchMap((s) => {
          return of(s).pipe(
            filter(
              (s) =>
                isVerifying(s.payload.item.firstName) ||
                isVerifying(s.payload.item.lastName),
            ),
            map(
              ({
                payload: {
                  item: { firstName, lastName },
                },
              }) =>
                firstName.value && lastName.value
                  ? { firstName: firstName.value, lastName: lastName.value }
                  : undefined,
            ),
            filter(isT),
            switchMap((v) => {
              return from(getByLastName(v.lastName)).pipe(
                map((r) =>
                  Actions.nameValidation(
                    r.length === 0 ||
                      r.every((i) => i.id === s.payload.member.id),
                  ),
                ),
                catchError((e: AxiosError) => {
                  return of(Actions.nameValidationError(e.response?.data));
                }),
              );
            }),
          );
        }),
      )
      .subscribe(dispatch);

    const save$ = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is State.Saving => s.type === 'Saving'),
        switchMap((s) => {
          return from(update(savingToUpdate(s))).pipe(
            map((r) => r.id),
            map(Actions.saveSuccess),
            catchError(() =>
              of(Actions.saveError('Unable to save community member')),
            ),
          );
        }),
      )
      .subscribe(dispatch);

    return () => {
      save$.unsubscribe();
      get$.unsubscribe();
      checkName$.unsubscribe();
      checkEmail$.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.type === 'SaveSuccess') {
      enqueueSnackbar('Community member saved successfully', {
        variant: 'success',
      });
    }
    // eslint-disable-next-line
  }, [state.type === 'SaveSuccess']);

  useEffect(() => {
    if (state.type === 'SaveError') {
      enqueueSnackbar(state.payload.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [state.type === 'SaveError']);

  return [state, dispatch];
}
