export const getStatusVariant = (status: string) => {
  switch (status) {
    case 'INVITED':
      return 'primary';
    case 'ACCEPTED':
      return 'success';
    case 'DECLINED':
      return 'error';
    case 'TENTATIVE':
      return 'warning';
    default:
      return undefined;
  }
};
