import { format } from 'date-fns';
import React from 'react';
import { COLORS } from '../../../../theme/variables';
import {
  datesAreOnSameDay,
  formatDateInterval,
  formatTimeInterval,
} from '../../../../utils/date';
import { TestId } from '../../../Testing/TestId';
import { Text } from '../../index';
import classes from './TimeRange.module.scss';

export interface TimeRangeProps {
  range: [Date, Date];
  testId: string;
}

export function TimeRange({ range, testId }: TimeRangeProps) {
  const [startDate, endDate] = range;
  const similarDates = datesAreOnSameDay(startDate, endDate);
  const date = similarDates
    ? formatDate(startDate)
    : formatDateInterval(startDate, endDate);

  const time = formatTimeInterval(startDate, endDate);

  return (
    <TestId testId={testId}>
      <div className={classes.root}>
        <Text className={classes.date}>{date}</Text>
        <Text variant='upper1' color={COLORS.COLOR_GRAY_BASE}>
          {time}
        </Text>
      </div>
    </TestId>
  );
}

function formatDate(date: Date) {
  return format(date, 'E, LLL dd, y');
}
