import {
  createContext,
  useCallback,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { getGoal } from '../api/goals';
import { Goal, GoalId, GoalStatus } from '../api/types/Goal';
import venturesAPI from '../api/ventures';
import { Venture } from '../api/ventures/types/Venture';
import { Attachment } from '../components/common/attachment-card';
import { UserContext } from './user-context';

export interface GoalContextProps {
  goal: Goal | null;
  founderVentures: Venture[];
  goalAttachments: Attachment[];
  isGoalLoading: boolean;
  isFounderVentureLoading: boolean;
  loadGoal: (goalId: GoalId) => Promise<void>;
  loadFounderVentures: () => Promise<void>;
  setGoal: Dispatch<SetStateAction<Goal | null>>;
  setGoalAttachments: Dispatch<SetStateAction<Attachment[]>>;
  updateGoalProgressAndStatus: Function;
}

export const GoalContext = createContext<GoalContextProps>({
  goal: null,
  founderVentures: [],
  goalAttachments: [],
  isGoalLoading: false,
  isFounderVentureLoading: false,
  loadGoal: async () => {},
  loadFounderVentures: async () => {},
  setGoal: () => {},
  setGoalAttachments: () => {},
  updateGoalProgressAndStatus: () => {},
});

interface GoalProviderProps {
  children: React.ReactNode;
}

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    return JSON.parse(attachmentRefs);
  } catch (e: any) {
    return [];
  }
};

const getInitialAttachments = (goal: Goal | null) => {
  if (goal?.attachmentRefs) {
    return parseAttachments(goal?.attachmentRefs);
  }
  return [];
};

export const GoalProvider = ({ children }: GoalProviderProps) => {
  const [goal, setGoal] = useState<Goal | null>(null);
  const [founderVentures, setFounderVentures] = useState<Venture[]>([]);
  const [isGoalLoading, setGoalLoading] = useState(true);
  const [isFounderVentureLoading, setFounderVentureLoading] = useState(true);
  const [goalAttachments, setGoalAttachments] = useState<Attachment[]>(
    getInitialAttachments(goal),
  );

  const { identityid } = useContext(UserContext);

  const loadFounderVentures = useCallback(async () => {
    if (identityid) {
      try {
        setFounderVentureLoading(true);
        const ventures = await venturesAPI.getVenturesDetailsByFounderId(
          identityid,
        );
        setFounderVentures(ventures);
        setFounderVentureLoading(false);
      } catch (e: any) {
        setFounderVentureLoading(false);
        console.error('error loadFounderVentures', e);
      }
    }
  }, [identityid]);

  const loadGoal = useCallback(async (goalId: GoalId) => {
    try {
      setGoalLoading(true);
      const loadedGoal = await getGoal(goalId);
      setGoal(loadedGoal);
      setGoalAttachments(getInitialAttachments(loadedGoal));
      setGoalLoading(false);
    } catch (e: any) {
      setGoalLoading(false);
      console.error('error loadGoal', e);
    }
  }, []);

  const updateGoalProgressAndStatus = useCallback(
    ({ progress, status }: { progress: number; status: GoalStatus }) => {
      setGoal((prev) => (prev ? { ...prev, progress, status } : null));
    },
    [],
  );

  useEffect(() => {
    loadFounderVentures();
  }, [loadFounderVentures]);

  const contextValue: GoalContextProps = {
    goal,
    goalAttachments,
    founderVentures,
    isGoalLoading,
    isFounderVentureLoading,
    loadGoal,
    loadFounderVentures,
    setGoal,
    setGoalAttachments,
    updateGoalProgressAndStatus,
  };

  return (
    <GoalContext.Provider value={contextValue}>{children}</GoalContext.Provider>
  );
};

export const useGoalContext = () => useContext(GoalContext);
