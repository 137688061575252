import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/styles';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _pick from 'lodash/pick';
import reportsAPI, {
  MentorAttendanceReportNumber,
  MentorMonthlyReportItem,
} from '../../api/reports';
import { Button, ModalCustomizeReport } from '../../components/common';
import { FormValues } from '../../components/common/modal-customize-report';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import ReportsMentorsScreen from '../../components/reports/reports-details-mentor';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { formatDate, formatDateApi } from '../../utils/date';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,

    '@media print': {
      boxShadow: 'none',
    },
  },
  content: {
    padding: 40,

    '@media print': {
      padding: 0,
    },
  },
});

export interface DateRangeValueType {
  startDate: string;
  endDate: string;
}

interface DateRange {
  startDate?: string;
  endDate?: string;
}

interface DateMonth {
  startDate: Date;
  endDate: Date;
}

export interface ParamsReport {
  dataRange: DateRange;
  includeLogo: boolean;
  includeSummary: boolean;
  includeAssessment: boolean;
}

export interface ReportTable {
  great?: MentorMonthlyReportItem[];
  ok?: MentorMonthlyReportItem[];
  bad?: MentorMonthlyReportItem[];
}

export type Status = 'ok' | 'great' | 'bad';

const getPeriodDate = (date: Date): DateMonth => {
  const today = new Date();

  return {
    startDate: startOfMonth(date),
    endDate: endOfMonth(addMonths(today, -1)),
  };
};

const getDatesRange6Month = () => {
  const today = new Date();
  const month = getPeriodDate(addMonths(today, -6));

  return {
    startDate: formatDate(month.startDate),
    endDate: formatDate(month.endDate),
  };
};

function parseDataRangeFromURL(search: string): DateRangeValueType | undefined {
  const parsedDataRange = _pick(parseQuery(search), [
    'startDate',
    'endDate',
  ]) as DateRangeValueType;
  if (parsedDataRange.startDate && parsedDataRange.endDate) {
    return parsedDataRange;
  }

  return undefined;
}

const getInitialValues = (dataRangeValue?: DateRangeValueType) => {
  if (!dataRangeValue) {
    return { dataRange: {} };
  }
  return {
    dataRange: {
      startDate: new Date(dataRangeValue.startDate),
      endDate: new Date(dataRangeValue.endDate),
    },
  };
};

function ReportsList({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const history = useHistory();
  const { rb } = useResourceBundles();

  const [loading, setLoading] = useState(false);
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const printContent = () => {
    window.print();
  };
  const dataRangeValue = useMemo(
    () => parseDataRangeFromURL(location.search),
    [location.search],
  );
  const [report, setReport] = useState<MentorAttendanceReportNumber>();
  const [additionalReport, setAdditionalReport] =
    useState<MentorAttendanceReportNumber>();

  const [status, setStatus] = useState<Status | ''>('');
  const [reportTable, setReportTable] = useState<ReportTable>({
    great: undefined,
    ok: undefined,
    bad: undefined,
  });

  const submitCustomizeReport = (params: FormValues) => {
    const startDate =
      params.dataRange.startDate && formatDate(params.dataRange.startDate);
    const endDate =
      params.dataRange.endDate && formatDate(params.dataRange.endDate);
    if (startDate && endDate) {
      history.replace(
        `${location.pathname}?startDate=${startDate}&endDate=${endDate}`,
      );
    }
  };

  const isEmpty = useMemo(() => {
    return !report?.great && !report?.ok && !report?.bad;
  }, [report]);

  const isEmptyAdditional = useMemo(() => {
    return (
      !additionalReport?.great &&
      !additionalReport?.ok &&
      !additionalReport?.bad
    );
  }, [additionalReport]);

  const getDataRange = () => {
    const dates = getDatesRange6Month();
    history.replace(
      `${location.pathname}?startDate=${dates.startDate}&endDate=${dates.endDate}`,
    );
  };

  const initialValues = getInitialValues(dataRangeValue);

  const loadReports = async (dataRangeValue: DateRangeValueType) => {
    try {
      setLoading(true);
      const start = dataRangeValue.startDate;
      const end = dataRangeValue.endDate;

      if (!!start && !!end) {
        const [responseReport, responseAdditionalReport] = await Promise.all([
          reportsAPI.getMentorReportAttendance(
            formatDateApi(start),
            formatDateApi(end),
          ),
          reportsAPI.getMentorAdditionalReportAttendance(
            formatDateApi(start),
            formatDateApi(end),
          ),
        ]);

        setReport({
          bad: responseReport?.bad ? +responseReport.bad : 0,
          ok: responseReport?.ok ? +responseReport?.ok : 0,
          great: responseReport?.great ? +responseReport?.great : 0,
        });

        setAdditionalReport({
          bad: responseAdditionalReport?.bad
            ? +responseAdditionalReport.bad
            : 0,
          ok: responseAdditionalReport?.ok ? +responseAdditionalReport?.ok : 0,
          great: responseAdditionalReport?.great
            ? +responseAdditionalReport?.great
            : 0,
        });
      }

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  const selectApi = useMemo(() => {
    switch (status) {
      case 'ok':
        return reportsAPI.getMentorReportAttendanceOk;
      case 'bad':
        return reportsAPI.getMentorReportAttendanceBad;
      case 'great':
        return reportsAPI.getMentorReportAttendanceGreat;
      default:
        return reportsAPI.getMentorReportAttendanceOk;
    }
  }, [status]);

  const loadReportsMentor = async (
    status: Status,
    dataRangeValue: DateRangeValueType,
  ) => {
    try {
      setLoading(true);
      const start = dataRangeValue.startDate;
      const end = dataRangeValue.endDate;

      const res = await selectApi(formatDateApi(start), formatDateApi(end));
      const sortReports =
        res && res.length > 1
          ? res.sort((a: any, b: any) => b.attendance - a.attendance)
          : res;

      setReportTable((prev) => ({ ...prev, [status]: sortReports }));
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataRangeValue && status && !reportTable[status]) {
      loadReportsMentor(status, dataRangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (dataRangeValue) {
      setReportTable({ great: undefined, ok: undefined, bad: undefined });
      loadReports(dataRangeValue);
    } else {
      getDataRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRangeValue]);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title={`${rb('mentor-u')} attendance report`}
        backLink={
          <Tooltip title='Back'>
            <IconButton
              component={Link}
              to={prevPath || Pages.DASHBOARD}
              data-testid='button-back-reports'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
        actions={[
          {
            key: 'print',
            label: 'Print',
            hidden: !report,
            component: (
              <Button
                variant='outlined'
                data-testid='print-button'
                className={CLASS_TRACKING.ENTITY_ACTION}
                startIcon={<PrintIcon />}
                onClick={printContent}>
                Print
              </Button>
            ),
          },
          {
            key: 'customize',
            label: 'Customize Report',
            component: (
              <ModalCustomizeReport
                initialValues={initialValues}
                onSubmit={submitCustomizeReport}
                loading={loading}
                onlyDateInput
              />
            ),
          },
        ]}
      />
      <Paper className={classes.container} elevation={1}>
        <div className={classes.content}>
          <ReportsMentorsScreen
            dataRangeValue={dataRangeValue}
            report={report}
            additionalReport={additionalReport}
            loading={loading}
            status={status}
            setStatus={setStatus}
            reportTable={reportTable}
            currentReportTable={status ? reportTable[status] : undefined}
            isEmpty={isEmpty}
            isEmptyAdditional={isEmptyAdditional}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default ReportsList;
