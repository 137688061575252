import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import mentorsAPI, { Mentor } from '../../api/mentors';
import venturesAPI, { VentureMentor } from '../../api/ventures';
import { VentureId } from '../../api/ventures/types/Venture';
import {
  Button,
  MentorManagementRequestButton,
  Modal,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import AssignedTable, {
  AssignmentItem,
} from '../../components/common/assigned-table';
import AssignVenturesForm from '../../components/forms/assign-ventures';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { VentureContext } from '../../contexts/venture-context';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

function VentureAssignmentsPage() {
  const classes = useStyles();
  const { ventureId } = useParams<{ ventureId: VentureId }>();
  const { venture } = useContext(VentureContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const [assignments, setAssignments] = useState<VentureMentor[]>();
  const [mentors, setMentors] = useState<Mentor[]>();
  const isLoading = useMemo(
    () => !assignments || !mentors,
    [assignments, mentors],
  );
  const { hasAccessToAction } = useContext(UserContext);
  const [disabledIds, setDisabledIds] = useState<{ [x: string]: boolean }>({});
  const [isAssigningVenture, setIsAssigningVenture] = useState(false);

  const loadData = async (_ventureId: string) => {
    try {
      const [loadedAssignedMentors, loadedMentorsDetails] = await Promise.all([
        mentorsAPI.getMentorsByVentureId(_ventureId),
        mentorsAPI.getMentorsDetailsByVentureId(_ventureId),
      ]);
      setAssignments(loadedAssignedMentors);
      setMentors(loadedMentorsDetails);
    } catch (e: any) {
      console.log('error loadAssignments', e);
    }
  };

  const handleAssingSuccessForm = useCallback(
    (_ventureId: string, handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
      }: {
        assigned: string[];
        removed: string[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningVenture(true);
          const assignedRequests = assigned.map(
            (mentorId) => () => venturesAPI.assignMentor(ventureId, mentorId),
          );
          const removedRequests = removed.map(
            (mentorId) => () =>
              venturesAPI.removeMentorAssignment(ventureId, mentorId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          setAssignments(undefined);
          setMentors(undefined);
          handleModalClose();
          setIsAssigningVenture(false);
        } catch (e: any) {
          console.log('error', 'e');
        }
      },
    [ventureId],
  );

  const handleLeadChange = useCallback(
    async (assignmentItem: AssignmentItem, state: boolean) => {
      const currentLead = assignments?.find(
        (assignment) => assignment.isPrimary,
      );
      try {
        setAssignments((prevAssignments) =>
          (prevAssignments || []).map((prevAssignment) => {
            if (assignmentItem.assignmentId === prevAssignment.id) {
              return { ...prevAssignment, isPrimary: true };
            }
            return { ...prevAssignment, isPrimary: false };
          }),
        );

        await Promise.all<any>([
          venturesAPI.updateMentorPrimaryFlag(
            ventureId,
            assignmentItem.id,
            true,
          ),
          ...(currentLead
            ? [
                venturesAPI.updateMentorPrimaryFlag(
                  ventureId,
                  currentLead.mentorId,
                  false,
                ),
              ]
            : []),
        ]);
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while changing a lead status. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setAssignments((prevAssignments) =>
          (prevAssignments || []).map((prevAssignment) => {
            if (assignmentItem.assignmentId === prevAssignment.id) {
              return { ...prevAssignment, isPrimary: false };
            }
            if (currentLead?.id === prevAssignment.id) {
              return { ...prevAssignment, isPrimary: true };
            }
            return prevAssignment;
          }),
        );
      }
    },
    [ventureId, assignments, enqueueSnackbar],
  );

  const handleAssignmentRemove = useCallback(
    async (assignmentItem: AssignmentItem) => {
      try {
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: true,
        }));
        await venturesAPI.removeMentorAssignment(ventureId, assignmentItem.id);
        setAssignments((prev) =>
          (prev || []).filter(
            (prevAssignment) =>
              prevAssignment.id !== assignmentItem.assignmentId,
          ),
        );
        setMentors((prev) =>
          (prev || []).filter(
            (prevMentors) => prevMentors.id !== assignmentItem.id,
          ),
        );
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: false,
        }));
      } catch (e: any) {
        setDisabledIds((prev) => ({
          ...prev,
          [assignmentItem.assignmentId]: false,
        }));
      }
    },
    [ventureId],
  );

  useEffect(() => {
    if (!assignments || !mentors) {
      loadData(ventureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentors, ventureId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  const assignmentsList = (mentors || []).reduce((res, mentor) => {
    const assignment = (assignments || []).find(
      (assignment) => assignment.mentorId === mentor.id,
    );
    if (!assignment) {
      return res;
    }
    res.push({
      ...mentor,
      assignmentId: assignment.id,
      isPrimary: assignment.isPrimary,
      name: `${mentor.firstName} ${mentor.lastName}`,
    });
    return res;
  }, [] as AssignmentItem[]);

  return (
    <div>
      {assignmentsList.length > 0 && (
        <AssignedTable
          className={classes.table}
          assignments={assignmentsList}
          disabledAssignmentsIds={disabledIds}
          onSelect={handleLeadChange}
          onRemove={handleAssignmentRemove}
          multi={false}
          readOnly={!hasAccessToAction('venture.assignemnts.update')}
          ventureId={ventureId}
          manageMentorMode={hasAccessToAction('mentor.details.manageMentor')}
        />
      )}
      {hasAccessToAction('venture.assignemnts.update') && (
        <Modal
          title='New assignment'
          caption={`${venture?.ventureName}`}
          contentRenderer={({ handleClose }) => (
            <AssignVenturesForm
              ventureId={ventureId}
              loading={isAssigningVenture}
              mentors={mentors}
              onSuccess={handleAssingSuccessForm(ventureId, handleClose)}
              onCancel={handleClose}
            />
          )}
          buttonRenderer={({ onClick }) => (
            <Tooltip
              title={`Click assign to see other ${rb(
                'mentors',
              )} who expressed interest`}
              placement='top'>
              <Button
                onClick={onClick}
                startIcon={<AddIcon />}
                data-testid='venture-assignment-button'>
                Assign
              </Button>
            </Tooltip>
          )}
          width={720}
        />
      )}
      {hasAccessToAction('mentor.mentorRequest') ? (
        <MentorManagementRequestButton
          title={`Add ${rb('mentor-u')}`}
          caption={`Send a request to your programm admin to add a ${rb(
            'mentor',
          )}`}
          ventureId={ventureId}
          buttonText={rb('mentor-u')}
          defaultType='ADD'
          startIcon={<AddIcon />}
          mentorId={null}
          disabledButton={false}
        />
      ) : null}
    </div>
  );
}

export default VentureAssignmentsPage;
