export const SESSION_LIST: any[] = [
  {
    description: 'Description',
    end: 'Wed May 05 2021 14:39:56 GMT+0300',
    followupActions: 'followupActions',
    followupReminder: 'Wed May 05 2021 11:39:56 GMT+0300',
    id: '1',
    start: 'Wed May 05 2021 11:39:56 GMT+0300',
    status: 'CREATED',
    summary: 'Summary',
    tenantEventChannelId: '1',
    tenantId: '1',
    valid: true,
    ventureId: '1',
    ventureName: 'Name',
    eventNotes: [],
    emailList: 'emailList'
  },
  {
    description: 'Description',
    end: 'Wed May 05 2021 14:39:56 GMT+0300',
    followupActions: 'followupActions',
    followupReminder: 'Wed May 05 2021 11:39:56 GMT+0300',
    id: '2',
    start: 'Wed May 05 2021 11:39:56 GMT+0300',
    status: 'ARCHIVED',
    summary: 'Summary',
    tenantEventChannelId: '2',
    tenantId: '2',
    valid: true,
    ventureId: '2',
    ventureName: 'Name',
    eventNotes: [],
    emailList: 'emailList'
  },
  {
    description: 'Description',
    end: 'Wed May 05 2021 14:39:56 GMT+0300',
    followupActions: 'followupActions',
    followupReminder: 'Wed May 05 2021 11:39:56 GMT+0300',
    id: '3',
    start: 'Wed May 05 2021 11:39:56 GMT+0300',
    status: 'AGENDA_SET',
    summary: 'Summary',
    tenantEventChannelId: '3',
    tenantId: '3',
    valid: true,
    ventureId: '3',
    ventureName: 'Name',
    eventNotes: [],
    emailList: 'emailList'
  }
];
