import { TenantId } from '../../../api/tenants/Tenant';
import { Invalid, Valid, Value } from '../../../utils/FormValue';
import { Sentence } from '../../../utils/String/Sentence';
import { Email } from '../../../utils/String/Email';

// region Ready
export interface ReadyPayload {
  tenantId: TenantId;
  firstName: Value<'required', Sentence<100>, string | undefined>;
  lastName: Value<'required', Sentence<100>, string | undefined>;
  email: Value<'required', Email, string | undefined>;
  note: Value<'required', Sentence<1000>, string | undefined>;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Submitted
export interface SubmittedPayload extends ReadyPayload {
  firstName: Invalid<'required', string | undefined> | Valid<Sentence<100>>;
  lastName: Invalid<'required', string | undefined> | Valid<Sentence<100>>;
  note: Invalid<'required', string | undefined> | Valid<Sentence<1000>>;
  email: Invalid<'required', string | undefined> | Valid<Email>;
}

export interface Submitted {
  type: 'Submitted';
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted['payload']): Submitted => ({
  type: 'Submitted',
  payload,
});
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  firstName: Valid<Sentence<100>>;
  lastName: Valid<Sentence<100>>;
  note: Valid<Sentence<1000>>;
  email: Valid<Email>;
}

export interface Saving {
  type: 'Saving';
  payload: SavingPayload;
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveErrorPayload extends SavingPayload {
  message: string;
}

export interface SaveError {
  type: 'SaveError';
  payload: SaveErrorPayload;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccessPayload extends SavingPayload {}

export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: SaveSuccessPayload;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

export type State = Ready | Submitted | Saving | SaveError | SaveSuccess;

export type Editable = Ready | Submitted | SaveError;

export function isEditable(s: State): s is Editable {
  switch (s.type) {
    case 'Ready':
    case 'Submitted':
    case 'SaveError':
      return true;
    case 'SaveSuccess':
    case 'Saving':
      return false;
  }
}
