import { Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

export interface Props {
  header: ReactNode;
  children: ReactNode;
  footer: ReactNode | undefined;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  content: {
    paddingTop: '36px',

    [theme.breakpoints.down('xs')]: {
      paddingTop: 10,
    },
  },
}));

export function PageWrapper({
  header,
  children,
  footer,
  className,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      {header}
      <Container
        maxWidth={'md'}
        className={classNames(classes.content, 'content')}>
        <>{children}</>
      </Container>
      {footer ? <>{footer}</> : null}
    </div>
  );
}
