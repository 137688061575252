import { makeStyles } from '@material-ui/core';
import { SyntheticEvent } from 'react';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: 10,
    justifyContent: 'center',
    columnGap: 0,

    [theme.breakpoints.up(480)]: {
      flexDirection: 'row',
      columnGap: 32,
      rowGap: 0,
    },
  },

  link: {
    fontSize: '13px',
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

function Footer() {
  const classes = useStyles();

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div className={classes.noPrint}>
      <ul className={classes.menu}>
        <li>
          <a
            onClick={stopPropagation}
            className={classes.link}
            href={Pages.WEBSITE}>
            Our website
          </a>
        </li>
        <li>
          <a
            className={classes.link}
            href={Pages.OUR_BLOG}
            onClick={stopPropagation}>
            Our blog
          </a>
        </li>
        <li>
          <a
            className={classes.link}
            href={Pages.PRIVACY}
            onClick={stopPropagation}>
            Privacy
          </a>
        </li>
        <li>
          <a className={classes.link} href={Pages.TERMS}>
            Terms
          </a>
        </li>
        <li>
          <a
            className={classes.link}
            href={Pages.CONTACT_US}
            onClick={stopPropagation}>
            Contact us
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
