import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Tenant } from '../../api/auth';
import { Pages } from '../../router/constants';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { LogoImage } from '../common';

interface Props {
  mobile?: boolean;
  user: Tenant;
  logo?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&:first-child': {
      marginLeft: '28px',

      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
      },
    },
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 40,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_BASE,
    textDecoration: 'none',
  },
  mobileLogo: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
}));

export function Logo({ mobile = false, user, logo }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link className={classes.link} to={Pages.DASHBOARD}>
        {!mobile ? (
          <>
            {logo && (
              <span className={classes.logo}>
                <LogoImage
                  className={classes.image}
                  src={logo}
                  alt={user.programName}
                />
              </span>
            )}
            {user.programName}
          </>
        ) : (
          <div className={classes.mobileLogo}>
            {logo && (
              <span className={classes.logo}>
                <LogoImage
                  className={classes.image}
                  src={logo}
                  alt={user.programName}
                />
              </span>
            )}
            <span>{user.programName}</span>
          </div>
        )}
      </Link>
    </div>
  );
}
