import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { ReactElement, useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { AnswerId } from '../../../api/ventures/quarterlyrecords/customanswers/Answer';
import { VentureQuarterRecord } from '../../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { AlertState, FormGroup, Text } from '../../common';
import { Delete } from '../../common/ActionIcons/Delete';
import { Loading } from '../../common/ActionIcons/Loading';
import { Description } from '../../common/Description';
import { FormInitialValues } from '../../forms/report-venture-quarter-form';
import { FormRating, TextFieldSummary } from '../../forms/wrappers';
import { remove } from './types/Actions';
import { useReportItem } from './useReportItem';
import { isLoading } from './utils';

export interface Props {
  report: VentureQuarterRecord;
}

const useStyles = makeStyles(() => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  formContainer: {
    width: '100%',
  },
  ul_list: {
    '& ul, li': {
      listStyle: 'unset',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  alert: {
    margin: '20px 0',
  },
  title: {
    margin: '20px 0',
  },
  subtitle: {
    margin: '20px 0 10px',
  },
  fields_line: {
    display: 'flex',
    alignItems: 'center',

    '& + &': {
      marginTop: 20,
    },
  },
  field_amount: {
    width: '33%',
  },
  field_comment: {
    width: 'calc(67% - 20px)',
    marginLeft: 20,
  },
  questionItem: {
    display: 'flex',
  },
}));

const getInitialValues = (details: VentureQuarterRecord): FormInitialValues => {
  let rating = details.rating;

  if (details.status === 'SUBMITTED' && typeof rating === 'number') {
    rating = rating + 1;
  }

  return {
    ventureName: details.ventureName || '',
    submitter: String(details.submitter || ''),
    fte: String(details.fte || ''),
    revenue: String(details.revenue || ''),
    equityRaised: String(details.equityRaised || ''),
    equityComments: String(details.equityComments || ''),
    grantsRaised: String(details.grantsRaised || ''),
    grantsComments: String(details.grantsComments || ''),
    crowdFunding: String(details.crowdFunding || ''),
    crowdComments: String(details.crowdComments || ''),
    loans: String(details.loans || ''),
    loansComments: String(details.loansComments || ''),
    other: String(details.other || ''),
    otherComments: String(details.otherComments || ''),
    rating: String(rating || ''),
    questions: {},
  };
};

const getMonthsByQuarter = (quarter: number) => {
  switch (quarter) {
    case 1: {
      return '(Jan, Feb, March)';
    }
    case 2: {
      return '(April, May, June)';
    }
    case 3: {
      return '(July, August, Sept)';
    }
    default: {
      return '(Oct, Nov, Dec)';
    }
  }
};

export function ReportItem({ report }: Props): ReactElement {
  const [state, dispatch] = useReportItem(report);
  const { rb } = useResourceBundles();
  const initialValues = useMemo(() => getInitialValues(report), [report]);
  const classes = useStyles();
  const onRemove = useCallback(
    (id: AnswerId) => dispatch(remove(id)),
    [dispatch],
  );

  if (isLoading(state)) {
    return <CircularProgress size={24} color='inherit' />;
  }

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={() => (
        <div className={classes.formBlocks}>
          <form noValidate className={classes.formContainer}>
            <FormGroup>
              <Field<string>
                name='ventureName'
                component={TextFieldSummary}
                label='Venture'
                disabled
              />
            </FormGroup>
            <Typography className={classes.title} variant='h4'>
              Q{state.payload.record.quarterNumber} Report{' '}
              {state.payload.record.year}{' '}
              {getMonthsByQuarter(state.payload.record.quarterNumber)}
            </Typography>
            <FormGroup>
              <Field<string>
                name='submitter'
                component={TextFieldSummary}
                label='Email*'
                InputProps={{
                  inputProps: {
                    readOnly: true,
                  },
                }}
              />
            </FormGroup>
            <FormGroup>
              <Typography variant='h5'>
                1. What is your Full Time Employee Equivalent number? Your FTE
                number = (Total average of part-time hours worked per week / 30)
                + # of full-time employees
              </Typography>
            </FormGroup>
            <AlertState className={classes.alert} type='info'>
              <Text className={classes.ul_list} variant='normal'>
                It’s pretty easy. Grab a pencil, open your notebook, and we’ll
                show you exactly how to calculate your FTE count.
                <br />
                <br />
                <ul>
                  <li>
                    <b>Step 1:</b> How many part-time workers do you have?{' '}
                    <i>
                      According to the IRS, part-timers are those who work less
                      than 130 hours a month, or 30 hours a week.
                    </i>
                  </li>
                  <li>
                    <b>Step 2:</b> Write down the average number of hours each
                    part-time employee works per week. Now, add them all
                    together.
                  </li>
                  <li>
                    <b>Step 3:</b> Take the result and divide it by 30.
                  </li>
                  <li>
                    <b>Step 4:</b> Round down to the nearest whole number.
                  </li>
                </ul>
              </Text>
            </AlertState>
            <FormGroup>
              <Field<string>
                name='fte'
                component={TextFieldSummary}
                label='Number of Full-Time Employees*'
                format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                InputProps={{
                  inputProps: {
                    readOnly: true,
                  },
                }}
              />
            </FormGroup>
            <Typography className={classes.subtitle} variant='h5'>
              2. What was your total revenue in the last quarter?
            </Typography>
            <FormGroup>
              <Field<string>
                name='revenue'
                component={TextFieldSummary}
                label='Revenue last quarter*'
                format={(val = '') =>
                  val
                    // Allow only numbers
                    .replace(/[^0-9]+/g, '')
                    // Prevent number starting with 0
                    .replace(/^0/g, '')
                    // Number mus be less then 10.000.000
                    .slice(0, 7)
                }
                InputProps={{
                  inputProps: {
                    readOnly: true,
                  },
                }}
              />
            </FormGroup>
            <Typography className={classes.subtitle} variant='h5'>
              3. Share funds raised or borrowed last quarter. Please fill out
              and comment on relevant fields
            </Typography>
            <div>
              <div className={classes.fields_line}>
                <div className={classes.field_amount}>
                  <Field<string>
                    name='equityRaised'
                    component={TextFieldSummary}
                    label='Amount of equity raised'
                    format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
                <div className={classes.field_comment}>
                  <Field<string>
                    name='equityComments'
                    component={TextFieldSummary}
                    label='Please provide some details'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields_line}>
                <div className={classes.field_amount}>
                  <Field<string>
                    name='grantsRaised'
                    component={TextFieldSummary}
                    label='Amount of grants received'
                    format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
                <div className={classes.field_comment}>
                  <Field<string>
                    name='grantsComments'
                    component={TextFieldSummary}
                    label='Please provide some details'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields_line}>
                <div className={classes.field_amount}>
                  <Field<string>
                    name='crowdFunding'
                    component={TextFieldSummary}
                    label='Amount of crowdfunding'
                    format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
                <div className={classes.field_comment}>
                  <Field<string>
                    name='crowdComments'
                    component={TextFieldSummary}
                    label='Please provide some details'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields_line}>
                <div className={classes.field_amount}>
                  <Field<string>
                    name='loans'
                    component={TextFieldSummary}
                    label='Loans received'
                    format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
                <div className={classes.field_comment}>
                  <Field<string>
                    name='loansComments'
                    component={TextFieldSummary}
                    label='Please provide some details'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields_line}>
                <div className={classes.field_amount}>
                  <Field<string>
                    name='other'
                    component={TextFieldSummary}
                    label='Other funding'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
                <div className={classes.field_comment}>
                  <Field<string>
                    name='otherComments'
                    component={TextFieldSummary}
                    label='Please provide some details'
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <Typography className={classes.subtitle} variant='h5'>
              4. How likely would you recommend our {rb('mentorship')} program
              to a friend?
            </Typography>
            <FormGroup>
              <Field<number>
                name='rating'
                component={FormRating}
                max={11}
                readOnly={true}
                withCaption
              />
            </FormGroup>

            {state.payload.answers.map((answer, i) => {
              return (
                <div
                  key={answer.id}
                  data-testid='question-item'
                  className={classes.questionItem}>
                  <div>
                    <Description>
                      {i + 5}. {answer.description}
                    </Description>
                    <FormGroup>
                      <Text variant={'normal'}>{answer.answer}</Text>
                    </FormGroup>
                  </div>
                  <Description>
                    {state.type === 'Removing' &&
                    state.payload.answerId === answer.id ? (
                      <Loading />
                    ) : (
                      <Delete onClick={() => onRemove(answer.id)} />
                    )}
                  </Description>
                </div>
              );
            })}
          </form>
        </div>
      )}
    />
  );
}
