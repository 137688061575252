import {
  IconButton,
  Paper,
  Tooltip,
  Button as MaterialButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useState, useContext, useEffect } from 'react';
import {
  Link,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Goal } from '../../api/types/Goal';
import { PageLoader, RouteACL } from '../../components/common';
import PageTabs from '../../components/goals/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import { GoalProvider, useGoalContext } from '../../contexts/goal-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import GoalCheckinsPage from './goal-checkins-page';
import GoalLogPage from './goal-log-page';
import GoalOverviewPage from './goal-overview-page';
import GoalTasksPage from './goal-tasks-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
});

function GoalPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { goalId } = useParams<{ goalId: Goal['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const { goal, isGoalLoading, isFounderVentureLoading, loadGoal } =
    useGoalContext();
  const { hasAccessToAction } = useContext(UserContext);

  useEffect(() => {
    loadGoal(goalId);
  }, [loadGoal, goalId]);

  return (
    <BaseLayout user={user} fullHeight>
      {isGoalLoading || isFounderVentureLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={goal?.title || 'Unknown'}
            backLink={
              <Tooltip title='Back to Goals'>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.GOALS}
                  data-testid='button-back-goals'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            actions={[
              {
                key: 'new-goal',
                label: 'New Goal',
                hidden: !hasAccessToAction('goal.create'),
                component: (
                  <MaterialButton
                    className={CLASS_TRACKING.ENTITY_ACTION}
                    data-testid='button-new-goal'
                    component={Link}
                    to={Pages.NEW_GOAL}
                    startIcon={<AddIcon />}
                    variant='contained'
                    color='primary'>
                    Goal
                  </MaterialButton>
                ),
              },
            ]}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs goalId={goalId} />
            <div className={classes.content}>
              <Switch>
                <RouteACL
                  exact
                  path={Pages.GOAL_OVERVIEW}
                  component={GoalOverviewPage}
                  rule='goal.overview.view'
                  redirectUrl={Pages.GOALS}
                />
                <RouteACL
                  exact
                  path={Pages.GOAL_CHECK_INS}
                  component={GoalCheckinsPage}
                  rule='goal.checkins.view'
                  redirectUrl={Pages.GOAL_CHECK_INS}
                />
                <RouteACL
                  exact
                  path={Pages.GOAL_TASKS}
                  component={GoalTasksPage}
                  rule='goal.tasks.view'
                  redirectUrl={Pages.GOALS}
                />
                <RouteACL
                  exact
                  path={Pages.GOAL_LOG}
                  component={GoalLogPage}
                  rule='goal.log.view'
                  redirectUrl={Pages.GOALS}
                />
                <Redirect to={Pages.GOAL_OVERVIEW} />
              </Switch>
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

export default function GoalPageWithProvider(props: ProtectedRouteProps) {
  return (
    <GoalProvider>
      <GoalPage {...props} />
    </GoalProvider>
  );
}
