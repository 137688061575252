import { makeStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ReactElement, useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { Button, FormButtons } from '../common';
import { TextField, TextFieldProps as FieldProps } from '../common/text-field';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '25px'
  },
  editor: {
    marginTop: '15px'
  }
}));

export interface Props<T extends string | number> {
  mandatory: boolean;
  types: { id: T; title: string }[];
  type: T;
  description: string;
  onSave: () => void;
  onCancel: () => void;
  setDescription: (s: string) => void;
  setMandatory: (s: boolean) => void;
  setType: (s: T) => void;
  saving: boolean;
  error: string | undefined;
}

export function QuestionEditor<T extends string | number>({
  setDescription,
  mandatory,
  setMandatory,
  type,
  types,
  setType,
  description,
  saving,
  onSave,
  onCancel,
  error
}: Props<T>): ReactElement {
  const classes = useStyles();
  const handleDescription = useCallback<
    Exclude<FieldProps['onChange'], undefined>
  >((e) => setDescription(e.target.value), [setDescription]);
  const handleMandatory = useCallback(
    () => setMandatory(!mandatory),

    [mandatory, setMandatory]
  );
  return (
    <div data-testid='question-form' className={classes.container}>
      <div>
        <FormControl>
          <InputLabel id='question-type-label'>Type</InputLabel>
          <Select
            labelId='question-type-label'
            id='question-type'
            data-testid='question-type'
            value={type}
            label='Type'
            onChange={(e) => setType(e.target.value as T)}>
            {types.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.editor} data-testid='question-editor'>
          <TextField
            error={!!error}
            value={description}
            onChange={handleDescription}
            multiline
            rows={5}
            inputProps={{ 'data-testid': 'question-description' }}
          />
          {error ? <FormHelperText>{error}</FormHelperText> : null}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              data-testid={'question-mandatory'}
              checked={mandatory}
              onChange={handleMandatory}
            />
          }
          label='Required'
        />
      </div>

      <FormButtons>
        <Button
          data-testid='form-channel-submit'
          className={CLASS_TRACKING.INTERNAL_ACTION}
          disabled={saving}
          onClick={onSave}>
          Save
        </Button>
        <Button
          variant='outlined'
          onClick={onCancel}
          data-testid='form-channel-cancel'>
          Cancel
        </Button>
      </FormButtons>
    </div>
  );
}
