import { useLocation } from 'react-router';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { parse } from 'query-string';
import { reducer } from './reducer';
import { invalidKey, ready, State } from './types/State';
import * as Actions from './types/Actions';
import { unreachableError } from '../../../utils/unreachableError';
import { from, of } from 'rxjs';
import schedulingWizardApi from '../../../api/schedulingWizard';
import { catchError, map } from 'rxjs/operators';
import { AxiosError } from 'axios';

export function useCancelPage(): [State, Dispatch<Actions.Actions>] {
  const { search } = useLocation();
  const key: string | undefined = useMemo(() => {
    const v = parse(search).key;

    return typeof v === 'string' ? v : undefined;
  }, [search]);

  const [state, dispatch] = useReducer(
    reducer,
    key ? ready({ key }) : invalidKey(),
  );

  useEffect(() => {
    switch (state.type) {
      case 'Cancelling': {
        const loading$ = from(
          schedulingWizardApi.cancelWithKey(state.payload.key),
        )
          .pipe(
            map(Actions.cancellingSuccess),
            catchError((e: AxiosError) =>
              of(Actions.cancellingError(e.response?.data.message)),
            ),
          )
          .subscribe(dispatch);
        return () => {
          loading$.unsubscribe();
        };
      }
      case 'Ready':
      case 'Declined':
      case 'CancellingSuccess':
      case 'InvalidKey':
      case 'CancellingError':
        break;
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  return [state, dispatch];
}
