import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { LexicalEditor } from 'lexical';
import { RefObject, useRef } from 'react';
import { useField } from 'react-final-form';
import { COLORS } from '../../theme/variables';
import EmojiPickerPlugin from './plugins/emoji-picker-plugin';
// import InitialValuePlugin from './plugins/initial-value-plagin';
import { MaxLengthPlugin } from './plugins/max-length-plugin';
import OnChangePlugin from './plugins/on-change-plugin';
import RefPlugin from './plugins/ref-plugin';
import ExampleTheme from './theme';

interface ChatEditorFieldProps {
  showEmojiPicker: boolean;
  setShowEmojiPicker: (show: boolean) => void;
  name: string;
  disabled?: boolean;
  error?: boolean;
  maxLength?: number;
  initialValue?: string;
  editorRef?: RefObject<LexicalEditor>;
}

interface ChartEditorProps {
  showEmojiPicker: boolean;
  setShowEmojiPicker: (show: boolean) => void;
  onChange(html: string): void;
  disabled?: boolean;
  onFocus?(): void;
  onBlur?(): void;
  maxLength?: number;
  initialValue?: string;
  error?: boolean;
  editorRef?: RefObject<LexicalEditor>;
}

const useStyles = makeStyles({
  editorContainer: {
    width: '100%',
    position: 'relative',
    fontWeight: 400,
    textAlign: 'left',
    borderRadius: '4px',
    border: '1px solid #BEC6D3',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    transition: 'border-color 0.2s ease',
  },
  disabled: {
    backgroundColor: '#F5F6F8',
    color: '#BEC6D3',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  error: {
    borderColor: COLORS.COLOR_RED_BASE,

    '& .editor-placeholder': {
      color: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
  editorInner: {
    position: 'relative',

    '& .editor-placeholder': {
      color: 'rgba(117, 117, 117, 0.42)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      top: '10px',
      left: '15px',
      fontSize: '14px',
      lineHeight: '16px',
      userSelect: 'none',
      display: 'inline-block',
      pointerEvents: 'none',
    },

    '& pre::-webkit-scrollbar': {
      background: 'transparent',
      width: '10px',
    },

    '& pre::-webkit-scrollbar-thumb': {
      background: '#999',
    },
  },
  editorInput: {
    maxHeight: 130,
    padding: '10px 35px 10px 15px',
    resize: 'none',
    fontSize: '14px',
    lineHeight: '16px',
    caretColor: 'rgb(5, 5, 5)',
    position: 'relative',
    tabSize: 1,
    outline: 0,
    overflow: 'auto',
    scrollbarGutter: 'stable',
  },
});

function Placeholder() {
  return <div className='editor-placeholder'>Add a comment...</div>;
}

const editorConfig: InitialConfigType = {
  namespace: 'editor',
  theme: ExampleTheme,
  onError(error: any) {
    throw error;
  },
  nodes: [],
};

const ChatEditor = ({
  onChange,
  disabled,
  onFocus,
  onBlur,
  maxLength,
  editorRef,
  showEmojiPicker,
  setShowEmojiPicker,
  error,
}: // initialValue,
ChartEditorProps) => {
  const classes = useStyles();
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div
        ref={wrapperRef}
        className={cn(classes.editorContainer, {
          [classes.disabled]: disabled,
          [classes.error]: error,
        })}>
        <div className={classes.editorInner}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                onFocus={onFocus}
                onBlur={onBlur}
                className={classes.editorInput}
                disabled={disabled}
              />
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <EmojiPickerPlugin
            inputRef={wrapperRef}
            showEmojiPicker={showEmojiPicker}
            setShowEmojiPicker={setShowEmojiPicker}
          />
          <OnChangePlugin onChange={onChange} />
          <ClearEditorPlugin />
          {editorRef && <RefPlugin editorRef={editorRef} />}
          {/* !TODO need to fix */}
          {/* <InitialValuePlugin initialValue={initialValue} /> */}
          {maxLength && <MaxLengthPlugin maxLength={maxLength} />}
        </div>
      </div>
    </LexicalComposer>
  );
};

export default function ChatEditorField({
  name,
  error,
  disabled,
  maxLength,
  initialValue,
  editorRef,
  showEmojiPicker,
  setShowEmojiPicker,
}: ChatEditorFieldProps) {
  const {
    input: { value, onChange, onFocus, onBlur },
    // meta: { touched, error },
  } = useField(name, {
    subscription: { value: true, touched: true, error: true },
  });

  const handleChange = (html: string) => {
    onChange(html);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    onBlur();
  };

  return (
    <ChatEditor
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      maxLength={maxLength}
      initialValue={initialValue || value}
      editorRef={editorRef}
      showEmojiPicker={showEmojiPicker}
      setShowEmojiPicker={setShowEmojiPicker}
      error={error}
    />
  );
}
