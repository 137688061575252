import { startOfDay, endOfDay } from 'date-fns';
import { isWithinInterval } from 'date-fns';
import { TimeRange } from './types/TimeRange';

export const shouldDisableDate =
  (ranges: TimeRange[]) =>
  (d: Date): boolean => {
    const clientStartOfDay = startOfDay(d);
    const clientEndOfDay = endOfDay(d);

    return !ranges.some((interval) => {
      return isWithinInterval(interval.start, {
        start: clientStartOfDay,
        end: clientEndOfDay,
      });
    });
  };
