import { IconButton, Link as MaterialLink } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useMemo, SyntheticEvent } from 'react';
import { useLocation } from 'react-router';
import { Mentor } from '../../api/mentors';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { getStatusVariant } from '../../utils/status';
import { ConfirmButton, StatusBadge, Text, Link } from '../common';

interface MentorsTableProps {
  mentors: Mentor[];
  disabledMentorsIds?: {
    [x: string]: boolean;
  };
  onRemove: (mentors: Mentor) => any;
  showTitle?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
  isLink?: boolean;
}

const useStyles = makeStyles({
  table: {
    maxWidth: 480,
  },
  title: {
    width: '100%',
    padding: '14px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  name: {
    width: 420,
    marginRight: 10,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  text: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
  },
});

function MentorsTable({
  mentors,
  disabledMentorsIds,
  onRemove,
  showTitle = true,
  fullWidth = false,
  readOnly = false,
  isLink = false,
}: MentorsTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const handleRemove = (mentor: Mentor) => () => {
    onRemove(mentor);
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div
      className={cn(classes.table, {
        [classes.fullWidth]: fullWidth,
      })}>
      {showTitle && (
        <div className={classes.title}>
          <Text variant='normal2' bold>
            {rb('mentors-u')}
          </Text>
        </div>
      )}
      {mentors.map((mentor, index) => {
        const isDisabled = disabledMentorsIds
          ? disabledMentorsIds[mentor.id]
          : false;

        return (
          <div
            key={mentor.id}
            className={classes.row}
            data-testid={`assigned-mentor-${index}`}>
            <div
              className={cn(classes.name, { [classes.fullWidth]: fullWidth })}>
              {isLink ? (
                <MaterialLink
                  onClick={stopPropagation}
                  component={Link}
                  to={{
                    pathname: getRoutePath(Pages.MENTOR_DETAILS, {
                      mentorId: mentor.id,
                    }),
                    state: {
                      prevPath: currentPath,
                    },
                  }}>
                  <Text variant='normal' className={classes.text}>
                    {`${mentor.firstName} ${mentor.lastName}`}
                  </Text>
                </MaterialLink>
              ) : (
                <Text variant='normal'>
                  {`${mentor.firstName} ${mentor.lastName}`}
                </Text>
              )}
            </div>
            {!!mentor.assignmentStatus && (
              <div>
                <StatusBadge
                  status={mentor.assignmentStatus}
                  variant={getStatusVariant(mentor.assignmentStatus)}
                />
              </div>
            )}
            {!readOnly && (
              <div>
                <ConfirmButton
                  title='Remove the assignment?'
                  body='Sure you want to remove the assignment?'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRemove(mentor),
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      disabled={isDisabled}
                      data-testid={`assigned-delete-button-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default MentorsTable;
