import { isT } from 'fp-utilities';
import {
  Dispatch,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Availabilities as Form } from '../../../components/Advisors/Availabilities';
import { Edit } from '../../../components/Advisors/Availabilities/Edit';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { ConfirmDialog, PageLoader } from '../../../components/common';
import { BeforeUnload } from '../../../components/common/Confirmation/BeforeUnload';
import { CustomPeriodDialog } from '../../../components/common/CustomPeriodDialog';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { isInvalid, isValid } from '../../../utils/FormValue';
import { getTabs } from '../common/utils';
import * as Actions from './types/Actions';
import * as ItemType from './types/Item';
import * as State from './types/State';
import { isLoaded, Loaded } from './types/State';
import { useAvailabilities } from './useAvailabilities';
import {
  canAddItem,
  customPeriodItem,
  isEditing,
  itemErrors,
  itemErrorText,
  periods,
} from './utils';

export function Availabilities({ user }: ProtectedRouteProps) {
  const [s, dispatch] = useAvailabilities(user);
  const { hasAccessToAction } = useContext(UserContext);
  const { rb } = useResourceBundles();

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <BeforeUnload when={isEditing(s)} />
      <WithTabs
        title={`Edit ${rb('advisor')}`}
        backButtonLink={Pages.OH_ADVISORS}
        backButtonTitle={`Back to ${rb('advisors-u')}`}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_ADVISORS_AVAILABILITIES, {
              id: s.payload.advisorId,
            })}
            tabs={getTabs(s.payload.advisorId, hasAccessToAction)}
          />
        }>
        {isLoaded(s) ? (
          <LoadedState state={s} dispatch={dispatch} />
        ) : (
          <PageLoader />
        )}
      </WithTabs>
    </BaseLayout>
  );
}

interface LoadedStateProps {
  state: Loaded;
  dispatch: Dispatch<Actions.Actions>;
}

function LoadedState({ state, dispatch }: LoadedStateProps): ReactElement {
  const onAdd = useCallback(() => dispatch(Actions.addNew()), [dispatch]);
  const content = useMemo((): ReactElement[] => {
    switch (state.type) {
      case 'Ready':
      case 'SaveError':
      case 'RemoveConfirmation':
      case 'Edited':
      case 'Saving':
      case 'CustomPeriod': {
        return state.payload.items.map((i) => (
          <EditItem
            key={i.id as string}
            item={i}
            dispatch={dispatch}
            disabled={state.type === 'Saving'}
          />
        ));
      }
    }
  }, [state, dispatch]);
  const dialogs = useMemo((): ReactElement | null => {
    switch (state.type) {
      case 'RemoveConfirmation':
        return (
          <ConfirmDialog
            isOpen={true}
            onSuccess={() => dispatch(Actions.removeAccept())}
            onCancel={() => dispatch(Actions.removeReject())}
            title={'Remove availability'}
            body={'Do you really want to remove availability?'}
          />
        );
      case 'CustomPeriod': {
        const item = state.payload.items.find((i) => i.id === state.payload.id);
        const date =
          item && isValid(item.start) ? item.start.value : new Date();
        return (
          <CustomPeriodDialog
            date={date}
            value={state.payload.period}
            onChange={(v) => dispatch(Actions.setCustomPeriod(v))}
            onCancel={() => dispatch(Actions.cancelCustomPeriod())}
            onSave={() => dispatch(Actions.saveCustomPeriod())}
            open={true}
          />
        );
      }
      case 'Ready':
      case 'SaveError':
      case 'Saving':
      case 'Edited':
        return null;
    }
  }, [state, dispatch]);

  return (
    <>
      <Form
        onAdd={onAdd}
        addButton={addButtonStatus(state)}
        onSubmit={() => dispatch(Actions.submit())}>
        {content}
      </Form>
      {dialogs}
    </>
  );
}

interface EditItemProps {
  item: ItemType.Item;
  dispatch: Dispatch<Actions.Actions>;
  disabled: boolean;
}
function EditItem({ item, disabled, dispatch }: EditItemProps) {
  const date = item.start.value ?? new Date();
  const customItem = customPeriodItem(date, item.period.value);
  return (
    <Edit
      onRemove={() => dispatch(Actions.remove(item.id))}
      periods={[customItem, ...Object.values(periods(date))].filter(isT)}
      period={{
        disabled,
        error: isInvalid(item.period),
        value: customItem?.value ?? item.period.value.periodType,
        onChange: (v) =>
          v !== '-1' && dispatch(Actions.setPeriod({ value: v, id: item.id })),
        onBlur: () => undefined,
      }}
      start={{
        disabled,
        error: isInvalid(item.start),
        value: item.start.value,
        onChange: (v) => dispatch(Actions.setStart({ value: v, id: item.id })),
        onBlur: () => undefined,
      }}
      end={{
        disabled,
        error: isInvalid(item.end),
        value: item.end.value,
        onChange: (v) => dispatch(Actions.setEnd({ id: item.id, value: v })),
        onBlur: () => undefined,
      }}
      errors={itemErrors(item).map(itemErrorText).filter(isT)}
    />
  );
}

function addButtonStatus(s: State.State): 'active' | 'disabled' | 'hidden' {
  if (State.isLoaded(s) && !canAddItem(s)) {
    return 'hidden';
  }

  switch (s.type) {
    case 'Loading':
    case 'LoadError':
    case 'Saving':
      return 'disabled';
    case 'Ready':
    case 'SaveError':
    case 'RemoveConfirmation':
    case 'Edited':
    case 'CustomPeriod':
      return 'active';
  }
}
