import { startOfToday } from 'date-fns';
import * as Status from '../../../api/CommunityMembers/types/Status';
import * as Either from '../../../utils/Either';
import * as V from '../../../utils/FormValue';
import { invalid, verifying } from '../../../utils/FormValue';
import * as S from '../../../utils/String/Sentence';
import { Item } from './types/Item';

type SentenceValidator<N extends number> =
  | V.Valid<S.Sentence<N>>
  | V.Invalid<string, string>;

const optional =
  <T, X extends T, E>(validator: (t: T) => V.Valid<X> | V.Invalid<E, T>) =>
  (v: T | undefined): V.Invalid<E, T> | V.Valid<X | undefined> =>
    v === undefined || (v as unknown) === ''
      ? V.valid(undefined)
      : validator(v);

// region Validators
const sentence =
  <N extends number>(n: N) =>
  (s: string): SentenceValidator<N> => {
    return Either.apply<string, S.Sentence<N>, SentenceValidator<N>>(
      () => V.invalid(`String should be less then ${n} characters`, s),
      V.valid,
      S.fromString(n)(s),
    );
  };

const status = (s: Status.Status) => V.valid(s);

const birthDate = (d: Date) =>
  d <= startOfToday()
    ? V.valid(d)
    : V.invalid('It seems you were born in future', d);
// endregion

type SyncValidators = {
  [K in keyof Omit<Item, 'email' | 'firstName' | 'lastName'>]: (
    v: V.ValueType<Item[K]>,
  ) => V.SubmittedValue<Item[K]>;
};

type AsyncValidator<T extends keyof Item> = (
  v: V.ValueType<Item[T]>,
) => V.SubmittedAsyncValue<Item[T]>;

interface Validators extends SyncValidators {
  firstName: AsyncValidator<'firstName'>;
  lastName: AsyncValidator<'lastName'>;
  email: AsyncValidator<'email'>;
}

export const validators: Validators = {
  firstName: (v) => (v ? verifying(v) : invalid('required', v)),
  lastName: (v) => (v ? verifying(v) : invalid('required', v)),
  email: (v) => (v ? verifying(v) : invalid('required', v)),
  country: optional(sentence(250)),
  state: optional(sentence(250)),
  city: optional(sentence(250)),
  linkedIn: optional(sentence(250)),
  address: optional(sentence(1024)),
  zip: optional(sentence(12)),
  status: status,
  birthDate: optional(birthDate),
};
