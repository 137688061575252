import { makeStyles, Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '.';
import mentorsAPI, { Mentor } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import AssignVenturesForm from '../../components/forms/assign-ventures';
import MentorsTable from '../../components/sessions/mentors-table';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { TestId } from '../Testing/TestId';
import { AddNewIcon } from './ActionIcons/AddNewIcon';

const useStyles = makeStyles({
  container: {
    minHeight: 70,
  },
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
  title: {
    display: 'flex',
    gap: 16,
  },
});

interface Props {
  ventureId: VentureId | undefined;
  mentors?: Mentor[];
  onChange: (mentors: Mentor[] | undefined) => any;
  readOnly?: boolean;
}

export function VentureMentorSelect({
  ventureId,
  mentors,
  onChange,
  readOnly = false,
}: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [isAssigningVenture, setIsAssigningVenture] = useState(false);
  const isInited = useRef<boolean>(false);

  const loadMentors = async (ventureId: string) => {
    try {
      setIsAssigningVenture(true);
      const mentor = await mentorsAPI.getMentorsDetailsByVentureId(ventureId);
      onChange(mentor);
      setIsAssigningVenture(false);
    } catch (e: any) {
      console.log('error loadVentures', e);
      setIsAssigningVenture(false);
    }
  };

  const handleRemoveMentors = useCallback(
    (mentor: Mentor) => {
      const currentMentors = mentors?.filter((value) => value.id !== mentor.id);
      onChange(currentMentors);
    },
    [onChange, mentors],
  );

  const handleAssingSuccessForm = useCallback(
    (handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
        all,
      }: {
        assigned: string[];
        removed: string[];
        all: Mentor[];
      }) => {
        if (assigned.length || removed.length) {
          onChange(all);
        }
        return handleModalClose();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (ventureId && isInited.current) {
      loadMentors(ventureId);
    } else {
      isInited.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventureId]);

  return (
    <div className={classes.container}>
      <Modal
        title={`Add a ${rb('mentors')}`}
        contentRenderer={({ handleClose }) => (
          <AssignVenturesForm
            ventureId={ventureId}
            loading={isAssigningVenture}
            mentors={mentors}
            onSuccess={handleAssingSuccessForm(handleClose)}
            onCancel={handleClose}
          />
        )}
        buttonRenderer={({ onClick }) => (
          <div className={classes.title}>
            <Typography variant='h4'>{rb('mentors-u')}</Typography>
            {!readOnly && (
              <TestId testId={'button-assignment-mentor'}>
                <AddNewIcon onClick={onClick} />
              </TestId>
            )}
          </div>
        )}
        width={720}
      />

      {!!mentors && mentors.length > 0 && (
        <div className={classes.table}>
          <MentorsTable
            mentors={mentors}
            showTitle={false}
            onRemove={handleRemoveMentors}
            fullWidth={true}
            readOnly={readOnly}
            isLink={!readOnly}
          />
        </div>
      )}
    </div>
  );
}
