import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { useDebouncedOnChange } from '../../../hooks/useDebouncedOnChange';
import { BookEmailFormItems } from '../../../pages/public/officehours/common/Book/Book';
import { Pages } from '../../../router/constants';
import { COLORS } from '../../../theme/variables';
import { TestId } from '../../Testing/TestId';
import { Button, TextField } from '../../common';
import { FormItem } from '../../common/Forms/types/FormItem';
import { DialogTitle } from '../../common/dialog';
import { Header } from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
      backgroundColor: '#FEFEFF',
    },
  },
  title: {
    textAlign: 'center',

    '& h4': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '15px',

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
      },
    },
    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  content: {
    position: 'relative',
    width: '476px',
    padding: '32px 32px 15px',

    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },

    '&::-webkit-scrollbar': {
      width: 12,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,

      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '15px',
    width: '100%',
  },
  textField: {
    '& input, .MuiInputBase-multiline': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
  invalid: {
    '&, &:hover': {
      color: COLORS.COLOR_RED_BASE,
    },
  },
  checkboxWrapper: {
    position: 'relative',
    left: '-11px',
    display: 'flex',
    alignItems: 'center',
  },
  submitWrapper: {
    padding: 32,
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& button': {
      width: '100%',
    },
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  saving: boolean;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  date?: Date;
  time?: [Date, Date];
  name?: FormItem<string>;
  email?: BookEmailFormItems;
  topic: FormItem<string>;
  agenda: FormItem<string>;
  terms: FormItem<string>;
}

export function Details({
  open,
  onClose,
  name,
  email,
  agenda,
  terms,
  topic,
  firstName,
  lastName,
  avatar,
  date,
  time,
  saving,
  onSubmit,
  onBack,
}: Props): ReactElement {
  const classes = useStyles();
  const contentId = 'details-element';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle onClose={onClose} className={classes.title}>
        <ArrowBackIcon onClick={onBack} />
        <span>Confirm Your Booking</span>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        id={contentId}
        data-testid='details-element'>
        <div className={classes.contentContainer}>
          <Header
            firstName={firstName}
            avatar={avatar}
            lastName={lastName}
            date={date}
            time={time}
          />

          {name ? (
            <div>
              <Label>Full Name*</Label>
              <TestId testId='full-name'>
                <Text field={name} />
              </TestId>
            </div>
          ) : null}

          {email ? (
            <>
              <div>
                <Label>Email*</Label>
                <TestId testId='email'>
                  <Text field={email.email} showEndAdornment />
                </TestId>
              </div>
              <div>
                <Label>Confirm Email*</Label>
                <TestId testId='confirm-email'>
                  <Text field={email.confirmEmail} showEndAdornment />
                </TestId>
              </div>
            </>
          ) : null}

          <div>
            <Label>
              In 3-5 words, what type of question do you want addressed?*
            </Label>
            <TestId testId='topic'>
              <Text field={topic} />
            </TestId>
          </div>

          <div>
            <Label>
              What do you hope to accomplish during this appointment?*
            </Label>
            <TestId testId='agenda'>
              <Text field={agenda} multiline rows={5} />
            </TestId>
          </div>

          <div>
            <TestId testId='terms'>
              <CheckboxField
                field={terms}
                label={
                  <>
                    By checking this box you are accepting{' '}
                    <a href={Pages.TERMS} target='_blank' rel='noreferrer'>
                      Terms of Service
                    </a>
                    .
                  </>
                }
              />
            </TestId>
          </div>
        </div>
      </DialogContent>
      <div className={classes.submitWrapper}>
        <Button onClick={onSubmit} disabled={saving} data-testid='submit'>
          {saving ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Request Appointment'
          )}
        </Button>
      </div>
    </Dialog>
  );
}

const Label: FC = ({ children }) => (
  <Typography variant={'subtitle2'}>{children}</Typography>
);

function Text({
  field,
  multiline,
  rows,
  showEndAdornment,
}: {
  field: FormItem<string>;
  multiline?: boolean;
  rows?: number;
  showEndAdornment?: boolean;
}) {
  const classes = useStyles();

  const [value, onChange, commit] = useDebouncedOnChange(
    field.value,
    field.onChange,
    500,
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange],
  );

  const getEndAdornment = useCallback(() => {
    if (field.error) {
      return <CancelIcon className={classes.errorIcon} />;
    } else if (field.value) {
      return <CheckCircleIcon className={classes.checkIcon} />;
    } else {
      return null;
    }
  }, [classes.checkIcon, classes.errorIcon, field.error, field.value]);

  return (
    <TextField
      variant='outlined'
      error={field.error}
      value={value}
      onBlur={() => {
        commit();
        field.onBlur();
      }}
      onChange={handleOnChange}
      multiline={multiline}
      rows={rows}
      className={classes.textField}
      InputProps={{
        endAdornment: showEndAdornment ? getEndAdornment() : undefined,
      }}
    />
  );
}

function CheckboxField({
  field,
  label,
}: {
  field: FormItem<string>;
  label: ReactNode;
}) {
  const classes = useStyles();

  return (
    <div className={classes.checkboxWrapper}>
      <Checkbox
        checked={field.value === 'true'}
        onChange={(e) => field.onChange(`${e.target.checked}`)}
        className={cn({
          [classes.invalid]: field.error,
        })}
        color='primary'
      />
      <Label>{label}</Label>
    </div>
  );
}
