import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FormGroup, TextField, FormButtons, Button } from '../common';
import { Gathering } from '../../api/gatherings';
import { validateEmail } from '../../utils/functions';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';

export interface SumbitGuestForm {
  guest: string;
  onCancel: () => any;
}

interface Props {
  loading?: boolean;
  onSuccess: (params: SumbitGuestForm) => any;
  onCancel: () => any;
  guestsList: Gathering['externalInvitees'][];
}

const useStyles = makeStyles((theme) => ({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE
    }
  }
}));

function InviteGatheringGuests({
  guestsList,
  loading = false,
  onSuccess,
  onCancel
}: Props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);

  const checkValidGuestEmail = (value: string) => {
    let validEmail = true;
    if (!validateEmail(value)) {
      enqueueSnackbar('This email is not valid', {
        variant: 'error'
      });

      validEmail = false;
    }
    if (guestsList.includes(value)) {
      enqueueSnackbar('This guest has already been added', {
        variant: 'error'
      });
      validEmail = false;
    }
    setError(!validEmail);
    return validEmail;
  };

  const handleGuestAdd = (value: string) => {
    if (!checkValidGuestEmail(value)) {
      return false;
    }

    onSuccess({ guest: value, onCancel });
  };

  return (
    <>
      <FormGroup>
        <TextField
          value={searchValue}
          className={cn({ [classes.error]: error })}
          label='Add a guest'
          placeholder="Enter guest's email"
          onChange={(e) => {
            if (error) {
              setError(false);
            }
            setSearchValue(e.target.value.toLowerCase());
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </FormGroup>

      <FormButtons className={classes.formButtons}>
        <Button onClick={onCancel} variant='outlined'>
          Cancel
        </Button>
        <Button
          onClick={() => handleGuestAdd(searchValue)}
          disabled={loading || error || !searchValue}
          data-testid='button-apply'>
          {loading ? <CircularProgress size={24} color='inherit' /> : 'Apply'}
        </Button>
      </FormButtons>
    </>
  );
}

export default InviteGatheringGuests;
