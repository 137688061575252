declare const desc: unique symbol;

/**
 * Description is a none empty string shorter then 2048 characters, trimmed at the ends,
 * and that contains other characters, other then white spaces.
 */
export type Description = string & { [desc]: 'description' };

export const is = (s: string): s is Description => {
  const _s = s.trim();

  return s.length === _s.length && _s.length > 0 && _s.length < 2048;
};

export const fromString = (s: string): Description | undefined => {
  const _s = s.trim();

  return _s.length > 0 && _s.length < 2048 ? (_s as Description) : undefined;
};
