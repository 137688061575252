import {
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import authAPI from '../../api/auth';
import { Mentor } from '../../api/mentors';
import { AddAllButton } from '../../components/AddAllButton';
import MentorAutocomplete from '../../components/autocomplete/mentor-autocomplete';
import {
  Button,
  Dialog,
  FormButtons,
  FormGroup,
  SnackMessage,
  Text,
  TextField,
} from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 560,
    maxWidth: '100%',
  },
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  formButtonsModal: {
    justifyContent: 'flex-end',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tbody: {
    maxHeight: 155,
    overflow: 'auto',
    paddingRight: 20,
    marginRight: -20,
  },
  tr: {
    display: 'flex',
    alignItems: 'center',
  },
  td: {
    padding: '5px 0',
  },
  btnCell: {
    minWidth: 40,
    marginRight: 20,
  },
  nameCell: {
    width: '65%',
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  input: {
    width: '100%',
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
  },
}));

function TenantNewMentorPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const [mentorInputError, setMentorInputError] = useState(false);
  const [initialMentors, setInitialMentors] = useState<Mentor[]>([]);
  const [displayedMentors, setDisplayedMentors] = useState<Mentor[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchIteration, setSearchIteration] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const handleMentorsSearch = useCallback((searchedMentors: Mentor[]) => {
    setDisplayedMentors(searchedMentors);
  }, []);

  const handleMentorRemove = (mentor: Mentor) => {
    setInitialMentors((prevMentors) =>
      prevMentors.filter((prevMentor) => prevMentor.id !== mentor.id),
    );
  };

  const handleMentorAdd = (mentor: Mentor) => {
    setInitialMentors((prevMentors) => [...prevMentors, mentor]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const handleSubmit = async ({
    mentorsIds,
  }: {
    mentorsIds: Mentor['id'][];
  }) => {
    try {
      if (mentorsIds.length > 1) {
        setOpenModal(true);
      } else if (mentorsIds.length === 1) {
        setIsLoading(true);
        await authAPI.createMentors(mentorsIds);
        setInitialMentors([]);
        enqueueSnackbar('The invitation was successfully sent!', {
          variant: 'success',
        });
        history.push(Pages.TENANT_USER_MANAGEMENT);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setMentorInputError(true);
      }
    } catch (error: any) {
      const messageError = error.response?.data?.message;
      enqueueSnackbar(
        'An error occurred when sending the invitation. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      setIsLoading(false);
    }
  };

  const handleCreateMentorAll = async (callback: () => any) => {
    try {
      setIsLoading(true);

      const mentorsIds = initialMentors.map(({ id }) => id);
      await authAPI.createMentors(mentorsIds);

      enqueueSnackbar('The invitation was sent successfully!', {
        variant: 'success',
      });
      history.push(Pages.TENANT_USER_MANAGEMENT);
      setInitialMentors([]);
      callback();
      setIsLoading(false);
    } catch (error: any) {
      const messageError = error.response?.data?.message;
      enqueueSnackbar(
        'An error occurred when sending invitations. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (isLoading) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons className={cn(classes.container, classes.formButtons)}>
          <Button onClick={() => {}} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    mentorsIds: initialMentors.map((mentor) => mentor.id),
  };

  const filteredDisplayedMentors = displayedMentors.filter(
    (mentor) =>
      !initialMentors.find((initialMentor) => initialMentor.id === mentor.id),
  );

  const displayedList =
    filteredDisplayedMentors.length > 0 ? filteredDisplayedMentors : [];

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <div className={classes.group}>
              <FormGroup>
                <MentorAutocomplete
                  iteration={searchIteration}
                  onSearch={handleMentorsSearch}
                  nonusers
                  inputRenderer={({
                    value,
                    onChange,
                    onSubmit,
                    isLoading,
                    error,
                  }) => (
                    <TextField
                      data-testid='tenant-new-mentor-input'
                      value={value}
                      label='Assign to'
                      placeholder={rb(`Enter ${rb('mentor')} name`)}
                      className={classes.input}
                      error={mentorInputError || error}
                      onChange={(currentValue) => {
                        onChange(currentValue);
                        setMentorInputError(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          onSubmit();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: isLoading && (
                          <CircularProgress color='primary' size={20} />
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
              <Tooltip
                title={`Here you can create an account to access Traction5 for an existing ${rb(
                  'mentor',
                )}. The ${rb(
                  'mentor',
                )} must first be created or added through an application form. Having an account is option for ${rb(
                  'mentors',
                )} but allows ${rb(
                  'mentors',
                )} to review notes from past sessions, including notes collected from founders, express interest in new ventures, and view contacts of other ${rb(
                  'mentors',
                )}.`}>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
            <div className={classes.chips}>
              {initialMentors.map((mentor, index) => (
                <Chip
                  data-testid={`mentor-chip-${index}`}
                  key={mentor.id}
                  label={`${mentor.firstName} ${mentor.lastName}`}
                  onDelete={() => handleMentorRemove(mentor)}
                  className={classes.chipItem}
                />
              ))}
            </div>

            <div className={classes.table}>
              {displayedList.length > 0 && (
                <>
                  <div className={classes.tr}>
                    <div className={classes.btnCell} />
                    <div className={cn(classes.td, classes.nameCell)}>
                      <Text variant='normal' bold>
                        {rb('mentors-u')}
                      </Text>
                    </div>
                  </div>
                  <div className={classes.tbody}>
                    {displayedList.map((recomItem, index) => (
                      <div className={classes.tr}>
                        <div className={classes.btnCell}>
                          <IconButton
                            onClick={() => handleMentorAdd(recomItem)}
                            data-testid={`add-${index}`}>
                            <AddCircleIcon />
                          </IconButton>
                        </div>
                        <div className={cn(classes.td, classes.nameCell)}>
                          <Text variant='normal'>{`${recomItem.firstName} ${recomItem.lastName}`}</Text>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            <FormButtons className={classes.formButtons}>
              <Button type='submit' data-testid='button-apply'>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Apply'
                )}
              </Button>

              <AddAllButton type='mentors' />

              <Button
                onClick={handleCancel}
                data-testid='button-cancel'
                variant='outlined'>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />

      <Dialog
        open={openModal}
        setOpen={setOpenModal}
        title='Sending an invitation'
        width={500}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              You are going to send the invitation with login instructions to
              the selected users. Are you sure you want to continue?
            </Text>

            <FormButtons className={classes.formButtonsModal}>
              <Button onClick={handleClose} variant='outlined'>
                No
              </Button>
              <Button
                disabled={isLoading}
                onClick={async () => await handleCreateMentorAll(handleClose)}>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Yes'
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
    </div>
  );
}

export default TenantNewMentorPage;
