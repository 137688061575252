import { LinkedIn as In } from '@material-ui/icons';
import { ComponentProps } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({ 
  root: { 
    color: '#0077b5', 
    fontSize: "20px" 
  } 
}));

export function LinkedIn({ className, ...props }: ComponentProps<typeof In>) {
  const classes = useStyles();

  return <In {...props} className={classNames(className, classes.root)} />;
}
