import { State, idle } from './types/State';
import { Dispatch, useEffect, useReducer } from 'react';
import { Actions, sendError, sendSuccess } from './types/Actions';
import { reducer } from './reducer';
import { VentureId } from '../../../api/ventures/types/Venture';
import { unreachableError } from '../../../utils/unreachableError';
import venturesAPI from '../../../api/ventures';
import { useSnackbar } from 'notistack';

export function useReportLinkDialog(
  ventureIds: VentureId[],
): [State, Dispatch<Actions>] {
  const [state, dispatch] = useReducer(reducer, idle({ ventureIds }));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    switch (state.type) {
      case 'Sending': {
        venturesAPI
          .sendVentureQuarterlyReport(ventureIds)
          .then(sendSuccess)
          .catch(sendError)
          .then(dispatch);
        break;
      }
      case 'SendSuccess': {
        enqueueSnackbar(state.payload.message, { variant: 'success' });
        break;
      }
      case 'SendError': {
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        break;
      }
      case 'Idle':
      case 'Open': {
        break;
      }
      default: {
        unreachableError(state);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type, dispatch, enqueueSnackbar]);

  return [state, dispatch];
}
