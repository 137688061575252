import { format } from 'date-fns';
import { mPipe, optional, or, parse, pass } from 'fp-utilities';
import { ItemError } from '../../pages/Advisors/Availabilities/types/Item';
import * as NextDate from '../../pages/Advisors/Availabilities/types/NextDate';
import {
  Invalid,
  invalid,
  isValid,
  valid,
  Valid,
  Value,
} from '../../utils/FormValue';
import { isNumber } from '../../utils/Num';
import { fromNumber, Natural } from '../../utils/Num/Natural';
import { nullify } from '../../utils/object';
import { isMonthDay, MonthDay } from './MonthDay';
import { isWeekDay, WeekDay } from './WeekDay';

interface Base {
  stopAfterIterations: number | undefined;
  stopAfterDate: Value<
    'required',
    NextDate.NextDate | undefined,
    Date | undefined
  >;
}

const stopAfterDate = (
  v: Record<any, any>,
):
  | Valid<NextDate.NextDate | undefined>
  | Invalid<'required', Date | undefined> =>
  v.stopAfterDate
    ? NextDate.is(new Date(v.stopAfterDate), new Date())
      ? valid(new NextDate.NextDate(v.stopAfterDate))
      : invalid('required', new Date(v.stopAfterDate))
    : valid(undefined);

const stopAfterIterations = optional((v: Record<any, any>) =>
  isNumber(v.stopAfterIterations) ? v.stopAfterIterations : undefined,
);
// region None
export interface None {
  periodType: '';
}

export const noneFromRecord = parse<Record<any, any>, None>({
  periodType: (v) => (v.periodType === '' ? '' : undefined),
});
// endregion

// region Daily
export interface Daily extends Base {
  periodType: 'DAILY';
  skipDays: number;
}

export const dailyFromRecord = parse<Record<any, any>, Daily>({
  periodType: (v) => (v.periodType === 'DAILY' ? 'DAILY' : undefined),
  skipDays: (v) => (typeof v.skipDays === 'number' ? v.skipDays : undefined),
  stopAfterDate,
  stopAfterIterations,
});
// endregion

// region AllWorkDays
export interface AllWorkDays extends Base {
  periodType: 'ALL_WORK_DAYS';
}

export const allWorkDaysFromRecord = parse<Record<any, any>, AllWorkDays>({
  periodType: (v) =>
    v.periodType === 'ALL_WORK_DAYS' ? 'ALL_WORK_DAYS' : undefined,
  stopAfterDate,
  stopAfterIterations,
});
// endregion

// region CustomWeekDays
export interface CustomWeekDays extends Base {
  periodType: 'CUSTOM_WEEK_DAYS';
  dayOfWeek: WeekDay;
  skipWeeks: number;
  ordinalNumber: Natural | undefined;
}

export const customWeekDaysFromRecord = parse<Record<any, any>, CustomWeekDays>(
  {
    periodType: (v) =>
      v.periodType === 'CUSTOM_WEEK_DAYS' ? 'CUSTOM_WEEK_DAYS' : undefined,
    dayOfWeek: mPipe(
      (v: Record<any, any>) => v.dayOfWeek,
      pass(isNumber),
      pass(isWeekDay),
    ),
    skipWeeks: mPipe((v: Record<any, any>) => v.skipWeeks, pass(isNumber)),
    ordinalNumber: optional(
      mPipe(
        (v: Record<any, any>) => v.ordinalNumber,
        pass(isNumber),
        fromNumber,
      ),
    ),
    stopAfterDate,
    stopAfterIterations,
  },
);
// endregion

// region Monthly
export interface Monthly extends Base {
  periodType: 'MONTHLY';
  dayOfMonth: MonthDay;
}

export const monthlyFromRecord = parse<Record<any, any>, Monthly>({
  periodType: (v) => (v.periodType === 'MONTHLY' ? 'MONTHLY' : undefined),
  dayOfMonth: mPipe(
    (v: Record<any, any>) => v.dayOfMonth,
    pass(isNumber),
    pass(isMonthDay),
  ),
  stopAfterDate,
  stopAfterIterations,
});
// endregion

// region Period
export type Period = None | Daily | AllWorkDays | CustomWeekDays | Monthly;

interface ValidDaily extends Daily {
  stopAfterDate: Valid<NextDate.NextDate | undefined>;
}
interface ValidAllWorkDays extends AllWorkDays {
  stopAfterDate: Valid<NextDate.NextDate | undefined>;
}
interface ValidCustomWeekDays extends CustomWeekDays {
  stopAfterDate: Valid<NextDate.NextDate | undefined>;
}
interface ValidMonthly extends Monthly {
  stopAfterDate: Valid<NextDate.NextDate | undefined>;
}

export type ValidPeriod =
  | None
  | ValidDaily
  | ValidAllWorkDays
  | ValidCustomWeekDays
  | ValidMonthly;

export function isValidPeriod(v: Period): v is ValidPeriod {
  switch (v.periodType) {
    case '':
      return true;
    case 'DAILY':
    case 'CUSTOM_WEEK_DAYS':
    case 'MONTHLY':
    case 'ALL_WORK_DAYS':
      return isValid(v.stopAfterDate);
  }
}

export const periodFromRecord = or(
  noneFromRecord,
  dailyFromRecord,
  allWorkDaysFromRecord,
  customWeekDaysFromRecord,
  monthlyFromRecord,
);

export function validatePeriod(
  p: Period,
): Valid<ValidPeriod> | Invalid<ItemError, Period> {
  return isValidPeriod(p) ? valid(p) : invalid(ItemError.Invalid, p);
}
// endregion

// region PeriodString
declare const periodString: unique symbol;

export type PeriodString = null | (string & { [periodString]: 'PeriodString' });

export function toString(v: Period): PeriodString {
  switch (v.periodType) {
    case '':
      return null;
    case 'CUSTOM_WEEK_DAYS':
    case 'ALL_WORK_DAYS':
    case 'MONTHLY':
    case 'DAILY':
      return JSON.stringify(
        nullify({
          ...v,
          stopAfterDate: v.stopAfterDate.value
            ? format(v.stopAfterDate.value, 'yyyy-MM-dd')
            : undefined,
        }),
      ) as PeriodString;
  }
}
// endregion
