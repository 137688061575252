import { Checkbox, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { createMarkup } from '../../utils/functions';
import { ConfirmButton, Text } from '../common';
import TaskForm, { Values as FormValues } from '../forms/task';

interface TaskProps<T> {
  task: T & {
    title: string;
    status: string;
  };
  updating?: boolean;
  deleting?: boolean;
  onEdit: (updateTask: T, callback?: () => any) => any;
  onRemove: (task: T, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  card: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    width: '100%',
  },
  body: {
    margin: 'auto 0',
    wordBreak: 'break-all',
  },
  bodyText: {
    wordBreak: 'break-word',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

function Task<T>({
  task,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index,
}: TaskProps<T>) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleTaskEdit = () => {
    setIsEditing(true);
  };

  const handleTaskRemove = (callback: () => any) => {
    onRemove(task, callback);
  };

  const handleTaskEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitTaskForm = ({ title, status }: FormValues) => {
    onEdit(
      { ...task, title, status },
      handleTaskEditCancel,
    );
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <TaskForm
          title='Task editing'
          task={{
            title: task.title,
            status: task.status,
          }}
          loading={updating}
          onSubmit={handleSubmitTaskForm}
          onCancel={handleTaskEditCancel}
        />
      ) : (
        <>
          <div className={classes.card} data-testid={`task-${index}`}>
            <div>
              <Checkbox
                checked={task.status === 'COMPLETED'}
                onChange={(e) =>
                  onEdit({
                    ...task,
                    status: e.target?.checked ? 'COMPLETED' : 'NOT_STARTED',
                  })
                }
                name='status'
                color='primary'
                disabled={updating}
              />
            </div>
            <Text className={classes.body} variant='normal'>
              <div
                className={classes.bodyText}
                dangerouslySetInnerHTML={createMarkup(task.title)}
              />
            </Text>
            {!readOnly && (
              <div>
                <IconButton
                  onClick={handleTaskEdit}
                  data-testid={`form-task-edit-${index}`}>
                  <EditIcon />
                </IconButton>
                <ConfirmButton
                  loading={deleting}
                  title='Remove the task?'
                  body='Sure you want to remove the task? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleTaskRemove,
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`form-task-delete-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Task;
