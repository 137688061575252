import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import reportsAPI, { VentureFullReport } from '../../api/reports';
import { Button, ModalCustomizeReport } from '../../components/common';
import { FormValues } from '../../components/common/modal-customize-report';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import ReportsVentureScreen from '../../components/reports/reports-details-venture';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { formatDateApi, formatDateToRFC } from '../../utils/date';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,

    '@media print': {
      boxShadow: 'none',
    },
  },
  content: {
    padding: 40,

    '@media print': {
      padding: 0,
    },
  },
});

interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface ParamsReport {
  dataRange: DateRange;
  includeLogo: boolean;
  includeSummary: boolean;
  includeAssessment: boolean;
}

const getInitialValues = (params: ParamsReport): FormValues => {
  return {
    dataRange: {
      startDate: params.dataRange.startDate
        ? new Date(params.dataRange.startDate)
        : new Date(),
      endDate: params.dataRange.endDate
        ? new Date(params.dataRange.endDate)
        : new Date(),
    },
    includeLogo: params.includeLogo ? 'true' : 'false',
    includeSummary: params.includeSummary ? 'true' : 'false',
    includeAssessment: params.includeAssessment ? 'true' : 'false',
  };
};

const getValidatedParams = (params: FormValues): ParamsReport => {
  return {
    dataRange: {
      startDate: params.dataRange.startDate
        ? formatDateToRFC(params.dataRange.startDate)
        : '',
      endDate: params.dataRange.endDate
        ? formatDateToRFC(params.dataRange.endDate)
        : '',
    },
    includeLogo: params.includeLogo === 'true',
    includeSummary: params.includeSummary === 'true',
    includeAssessment: params.includeAssessment === 'true',
  };
};

function ReportsList({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const [loading, isLoading] = useState(false);
  const location =
    useLocation<{ prevPath?: string; start?: string; end?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const [startDate] = useState(location.state?.start);
  const [endDate] = useState(location.state?.end);
  const [paramsReport, setParamsReport] = useState<ParamsReport>({
    dataRange: { startDate: startDate, endDate: endDate },
    includeLogo: true,
    includeSummary: true,
    includeAssessment: true,
  });
  const printContent = () => {
    window.print();
  };

  const [report, setReport] = useState<VentureFullReport>();

  const initialValuesForm = getInitialValues(paramsReport);

  const submitCustomizeReport = (params: FormValues) => {
    const validParams = getValidatedParams(params);
    setParamsReport(validParams);
  };

  const loadReports = async (paramsReport: ParamsReport) => {
    try {
      isLoading(true);
      const start = paramsReport.dataRange.startDate;
      const end = paramsReport.dataRange.endDate;

      if (start && end) {
        const responseReport = await reportsAPI.getVentureReportItem(
          formatDateApi(start),
          formatDateApi(end),
        );
        setReport(responseReport);
      }

      isLoading(false);
    } catch (e: any) {
      isLoading(false);
    }
  };

  useEffect(() => {
    loadReports(paramsReport);
  }, [paramsReport]);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='Venture progress report'
        backLink={
          <Tooltip title='Back'>
            <IconButton
              component={Link}
              to={prevPath || Pages.DASHBOARD}
              data-testid='button-back-reports'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
        actions={[
          {
            key: 'print',
            label: 'Print',
            hidden: !report,
            component: (
              <Button
                variant='outlined'
                data-testid='print-button'
                className={CLASS_TRACKING.ENTITY_ACTION}
                startIcon={<PrintIcon />}
                onClick={printContent}>
                Print
              </Button>
            ),
          },
          {
            key: 'customize',
            label: 'Customize Report',
            hidden: false,
            component: (
              <ModalCustomizeReport
                initialValues={initialValuesForm}
                onSubmit={submitCustomizeReport}
                loading={loading}
              />
            ),
          },
        ]}
      />
      <Paper className={classes.container} elevation={1}>
        <div className={classes.content}>
          <ReportsVentureScreen
            paramsReport={paramsReport}
            report={report}
            loading={loading}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default ReportsList;
