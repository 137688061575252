import { addDays, startOfToday } from 'date-fns';
import { AdvisorId } from '../../api/Advisors/types/Advisor';
import {
  Appointment,
  AppointmentId,
  AppointmentPaymentStatus,
} from '../../api/Appointments/types/Appointment';
import { Status } from '../../api/Appointments/types/Status';
import { TenantId } from '../../api/tenants/Tenant';
import { TenantRFCDate } from '../../api/types/TenantRFCDate';
import { Email } from '../../utils/String/Email';
import { formatDateToRFC } from '../../utils/date';

export const getAppointments = (
  tenantId: TenantId,
  advisorId: AdvisorId,
  page: number,
): Appointment[] => {
  return Array.from(Array(10).keys()).map(
    (i): Appointment => ({
      id: `${tenantId}-${advisorId}-${i}-${page}` as AppointmentId,
      requestorEmail: `email${i}${page}@mail.com` as Email,
      requestorFullName: `Requester ${i}${page}`,
      advisorFullName: `Advisor ${i}${page}`,
      advisorId,
      tenantId,
      status:
        Object.values(Status)[
          Math.floor(Math.random() * Object.values(Status).length)
        ],
      agenda: 'Some agenda',
      topic: 'Some topic',
      start: formatDateToRFC(
        addDays(startOfToday(), i),
      ) as unknown as TenantRFCDate,
      end: formatDateToRFC(
        addDays(startOfToday(), i + 10),
      ) as unknown as TenantRFCDate,
      valid: true,
      creationDate: formatDateToRFC(new Date()),
      paymentStatus: AppointmentPaymentStatus.NOT_STARTED,
    }),
  );
};
