import { useCallback, useMemo } from 'react';
import { Tenant } from '../../../api/auth';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { BeforeUnload } from '../../../components/common/Confirmation/BeforeUnload';
import { FormItem2 } from '../../../components/common/Forms/types/FormItem';
import BaseLayout from '../../../components/layout/base-layout';
import { Pages } from '../../../router/constants';
import { Form } from '../common/Form';
import {
  createField,
  formValueToInput2,
  getTabs,
  statusOptions,
} from '../common/utils';
import * as Actions from './types/Actions';
import { useCommunityMemberAdd } from './useCommunityMemberAdd';
import { formEdited, isSubmittable } from './utils';

export interface Props {
  user: Tenant;
}

export function AddCommunityMember({ user }: Props) {
  const [s, dispatch] = useCommunityMemberAdd(user);
  const { item, enrollmentDate } = s.payload;
  const {
    firstName,
    lastName,
    email,
    state,
    linkedIn,
    status,
    country,
    address,
    birthDate,
    zip,
    city,
  } = item;

  const _firstName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(firstName),
      onBlur: () => dispatch(Actions.toggle('firstName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'firstName', value: v })),
    }),
    [firstName, dispatch],
  );
  const _lastName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(lastName),
      onBlur: () => dispatch(Actions.toggle('lastName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'lastName', value: v })),
    }),
    [lastName, dispatch],
  );
  const _email = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(email),
      onBlur: () => dispatch(Actions.toggle('email')),
      onChange: (v) => dispatch(Actions.setValue({ key: 'email', value: v })),
    }),
    [email, dispatch],
  );
  const _state = useMemo(
    () => createField(state, 'state', dispatch),
    [state, dispatch],
  );
  const _linkedIn = useMemo(
    () => createField(linkedIn, 'linkedIn', dispatch),
    [linkedIn, dispatch],
  );

  const _status = useMemo(
    () => createField(status, 'status', dispatch),
    [status, dispatch],
  );
  const _country = useMemo(
    () => createField(country, 'country', dispatch),
    [country, dispatch],
  );
  const _birthDate = useMemo(
    () => createField(birthDate, 'birthDate', dispatch),
    [birthDate, dispatch],
  );
  const _zip = useMemo(
    () => createField(zip, 'zip', dispatch),
    [zip, dispatch],
  );
  const _city = useMemo(
    () => createField(city, 'city', dispatch),
    [city, dispatch],
  );
  const _address = useMemo(
    () => createField(address, 'address', dispatch),
    [address, dispatch],
  );
  const handleSubmit = useCallback(() => dispatch(Actions.save()), [dispatch]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <BeforeUnload when={formEdited(s)} />
      <WithTabs
        title={'Add new community member'}
        backButtonLink={Pages.OH_COMMUNITY_MEMBERS}
        backButtonTitle={'Back to Community Member'}
        tabs={
          <Tabs
            active={Pages.OH_COMMUNITY_MEMBERS_CREATE}
            tabs={getTabs(undefined)}
          />
        }>
        <Form
          address={_address}
          birthDate={_birthDate}
          city={_city}
          country={_country}
          email={_email}
          enrollmentDate={enrollmentDate}
          firstName={_firstName}
          lastName={_lastName}
          linkedIn={_linkedIn}
          state={_state}
          status={_status}
          statuses={statusOptions}
          zip={_zip}
          onSubmit={handleSubmit}
          disableSubmit={!isSubmittable(s)}
        />
      </WithTabs>
    </BaseLayout>
  );
}
