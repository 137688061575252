import {
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import authAPI from '../../api/auth';
import { Founder } from '../../api/founders';
import { AddAllButton } from '../../components/AddAllButton';
import FounderAutocomplete from '../../components/autocomplete/founder-autocomplete';
import {
  Button,
  Dialog,
  FormButtons,
  FormGroup,
  SnackMessage,
  Text,
  TextField,
} from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 560,
    maxWidth: '100%',
  },
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  formButtonsModal: {
    justifyContent: 'flex-end',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tbody: {
    maxHeight: 155,
    overflow: 'auto',
    paddingRight: 20,
    marginRight: -20,
  },
  tr: {
    display: 'flex',
    alignItems: 'center',
  },
  td: {
    padding: '5px 0',
  },
  btnCell: {
    minWidth: 40,
    marginRight: 20,
  },
  nameCell: {
    width: '65%',
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  input: {
    width: '100%',
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
  },
}));

export function TenantNewFounderPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const [founderInputError, setFounderInputError] = useState(false);
  const [initial, setInitial] = useState<Founder[]>([]);
  const [displayed, setDisplayed] = useState<Founder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchIteration, setSearchIteration] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const handleSearch = useCallback((searched: Founder[]) => {
    setDisplayed(searched);
  }, []);

  const handleRemove = (item: Founder) => {
    setInitial((prevs) => prevs.filter((prev) => prev.id !== item.id));
  };

  const handleAdd = (item: Founder) => {
    setInitial((prev) => [...prev, item]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const handleSubmit = async ({ ids }: { ids: Founder['id'][] }) => {
    try {
      if (!ids?.length) {
        setFounderInputError(true);
        return;
      }
      setIsLoading(true);
      if (ids?.length > 1) {
        setOpenModal(true);
      } else {
        await authAPI.createFounders(ids);
        setInitial([]);
        enqueueSnackbar('The invitation was successfully sent!', {
          variant: 'success',
        });
        history.push(Pages.TENANT_USER_MANAGEMENT);
      }
      setIsLoading(false);
    } catch (error: any) {
      const messageError = error.response?.data?.message;
      enqueueSnackbar(
        'An error occurred when sending the invitation. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      setIsLoading(false);
    }
  };

  const handleCreateAll = async (callback: () => any) => {
    try {
      setIsLoading(true);
      const ids = initial.map(({ id }) => id);
      await authAPI.createFounders(ids);
      enqueueSnackbar('The invitation was sent successfully!', {
        variant: 'success',
      });
      history.push(Pages.TENANT_USER_MANAGEMENT);
      setInitial([]);
      callback();
      setIsLoading(false);
    } catch (error: any) {
      const messageError = error.response?.data?.message;
      enqueueSnackbar(
        'An error occurred when sending invitations. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (isLoading) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons className={cn(classes.container, classes.formButtons)}>
          <Button onClick={() => {}} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    ids: initial.map((item) => item.id),
  };

  const filteredDisplayed = displayed.filter(
    (item) => !initial.find((initial) => initial.id === item.id),
  );

  const displayedList = filteredDisplayed.length > 0 ? filteredDisplayed : [];

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <div className={classes.group}>
              <FormGroup>
                <FounderAutocomplete
                  iteration={searchIteration}
                  onSearch={handleSearch}
                  nonusers
                  inputRenderer={({ value, onChange, isLoading }) => (
                    <TextField
                      data-testid='tenant-new-founder-input'
                      value={value}
                      label='Assign to'
                      placeholder='Enter founder name'
                      className={classes.input}
                      error={founderInputError}
                      onChange={(currentValue) => {
                        onChange(currentValue);
                        setFounderInputError(false);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: !!isLoading && (
                          <CircularProgress color='primary' size={20} />
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
              <Tooltip
                title={`Here you can create an account to access Traction5 for an existing founder. The founder must first be created or added through application form. Having an account is option but allows founders to request sessions, review their ${rb(
                  'mentors',
                )}, and manage their goals.`}>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
            <div className={classes.chips}>
              {initial.map((item, index) => (
                <Chip
                  data-testid={`founder-chip-${index}`}
                  key={item.id}
                  label={`${item.firstName} ${item.lastName}`}
                  onDelete={() => handleRemove(item)}
                  className={classes.chipItem}
                />
              ))}
            </div>

            <div className={classes.table}>
              {displayedList.length > 0 && (
                <>
                  <div className={classes.tr}>
                    <div className={classes.btnCell} />
                    <div className={cn(classes.td, classes.nameCell)}>
                      <Text variant='normal' bold>
                        Founders
                      </Text>
                    </div>
                  </div>
                  <div className={classes.tbody}>
                    {displayedList.map((recomItem, index) => (
                      <div className={classes.tr}>
                        <div className={classes.btnCell}>
                          <IconButton
                            onClick={() => handleAdd(recomItem)}
                            data-testid={`add-${index}`}>
                            <AddCircleIcon />
                          </IconButton>
                        </div>
                        <div className={cn(classes.td, classes.nameCell)}>
                          <Text variant='normal'>{`${recomItem.firstName} ${recomItem.lastName}`}</Text>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            <FormButtons className={classes.formButtons}>
              <Button type='submit' data-testid='button-apply'>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Apply'
                )}
              </Button>

              <AddAllButton type='founders' />

              <Button
                onClick={handleCancel}
                data-testid='button-cancel'
                variant='outlined'>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />

      <Dialog
        open={openModal}
        setOpen={setOpenModal}
        title='Sending an invitation'
        width={500}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              You are going to send the invitation with login instructions to
              the selected users. Are you sure you want to continue?
            </Text>

            <FormButtons className={classes.formButtonsModal}>
              <Button onClick={handleClose} variant='outlined'>
                No
              </Button>
              <Button
                disabled={isLoading}
                onClick={async () => await handleCreateAll(handleClose)}>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Yes'
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
    </div>
  );
}
