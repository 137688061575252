import {
  AsyncValue,
  InitialEntity,
  InvalidEntity,
  SubmittedAsyncValue,
  SubmittedValue,
  ValidEntity,
  Value,
} from '../../../../utils/FormValue';
import { Status } from '../../../../api/CommunityMembers/types/Status';
import { Sentence } from '../../../../utils/String/Sentence';
import { Email } from '../../../../utils/String/Email';

export interface Item {
  address: Value<string, Sentence<1024> | undefined, string | undefined>;
  birthDate: Value<string, Date | undefined, Date | undefined>;
  zip: Value<string, Sentence<12> | undefined, string | undefined>;
  city: Value<string, Sentence<250> | undefined, string | undefined>;
  country: Value<string, Sentence<250> | undefined, string | undefined>;
  email: AsyncValue<string, Email, string | undefined>;
  firstName: AsyncValue<string, Sentence<250>, string | undefined>;
  lastName: AsyncValue<string, Sentence<250>, string | undefined>;
  linkedIn: Value<string, Sentence<250> | undefined, string | undefined>;
  state: Value<string, Sentence<250> | undefined, string | undefined>;
  status: Value<string, Status, Status>;
}

export type Initial = InitialEntity<Item>;
export type Invalid = InvalidEntity<Item>;
export type Valid = ValidEntity<Item>;
export type Submitted = {
  address: SubmittedValue<Item['address']>;
  birthDate: SubmittedValue<Item['birthDate']>;
  zip: SubmittedValue<Item['zip']>;
  city: SubmittedValue<Item['city']>;
  country: SubmittedValue<Item['country']>;
  email: SubmittedAsyncValue<Item['email']>;
  firstName: SubmittedAsyncValue<Item['firstName']>;
  lastName: SubmittedAsyncValue<Item['lastName']>;
  linkedIn: SubmittedValue<Item['linkedIn']>;
  state: SubmittedValue<Item['state']>;
  status: SubmittedValue<Item['status']>;
};

export function setValue<K extends keyof Item>(
  k: K,
  v: Item[K],
  item: Item,
): Item {
  return { ...item, [k]: v };
}
