import { apply } from '../../utils/Either';
import { initial, invalid, isValid, valid } from '../../utils/FormValue';
import { fromString } from '../../utils/String/Sentence';
import * as Actions from './types/Actions';
import * as State from './types/State';

export function reducer(s: State.State, a: Actions.Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            email: a.payload.requestorEmail,
            advisorName: a.payload.advisorFullName,
            start: new Date(a.payload.start),
            end: new Date(a.payload.end),
            rating: initial(undefined),
            comment: valid(undefined),
          })
        : s;
    case 'SetRating': {
      if (State.isEditable(s)) {
        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, rating: valid(a.payload) });
          case 'SaveError':
          case 'Submitted':
            return State.submitted({ ...s.payload, rating: valid(a.payload) });
        }
      }

      return s;
    }
    case 'SetComment': {
      if (State.isEditable(s)) {
        const comment = apply(
          (v) => {
            switch (v) {
              case 'overflow':
                return invalid('required' as const, a.payload);
              case 'empty':
                return valid(undefined);
            }
          },
          valid,
          fromString(500)(a.payload),
        );

        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, comment });
          case 'SaveError':
          case 'Submitted':
            return State.submitted({ ...s.payload, comment });
        }
      }

      return s;
    }
    case 'Submit': {
      if (State.isEditable(s)) {
        const rating = isValid(s.payload.rating)
          ? s.payload.rating
          : invalid('required' as const, undefined);
        const comment = isValid(s.payload.comment)
          ? s.payload.comment
          : invalid<'required', string>('required', s.payload.comment.value);

        switch (s.type) {
          case 'Ready':
        }

        return isValid(rating) && isValid(comment)
          ? State.saving({ ...s.payload, rating, comment })
          : State.submitted({ ...s.payload, rating, comment });
      }
      return s;
    }
    case 'SaveError':
      return s.type === 'Saving'
        ? State.saveError({ ...s.payload, message: a.payload })
        : s;
    case 'SaveSuccess':
      return s.type === 'Saving' ? State.saveSuccess({}) : s;
  }
}
