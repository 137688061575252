import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import foundersAPI from '../../api/founders';
import { SnackMessage } from '../../components/common';
import FounderDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/founder-details';
import PageTabs from '../../components/founders/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
});

function NewFounderPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { logo, ...values } = parsedValues.values;
        setIsCreating(true);
        const createdFounder = await foundersAPI.create({
          ...values,
          logo: null,
        });
        const setSpecializationRequests = parsedValues.specializations.map(
          (specializationId) => () =>
            foundersAPI.setFounderSpecialization(
              createdFounder.id,
              specializationId,
            ),
        );
        const logoFile = parsedValues.logoFile;
        await Promise.all<any>([
          massAsyncRequest(setSpecializationRequests),
          ...(logoFile !== null
            ? [foundersAPI.updateAvatar(createdFounder.id, logoFile)]
            : []),
        ]);
        history.push(
          getRoutePath(Pages.FOUNDER_DETAILS, { founderId: createdFounder.id }),
        );
        enqueueSnackbar('The founder was successfully created', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the founder. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [history, enqueueSnackbar],
  );

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='New Founder'
        backLink={
          <Tooltip title='Back to Founders'>
            <IconButton
              component={Link}
              to={Pages.ACTIVE_FOUNDERS}
              data-testid='button-back-founders'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Paper className={classes.container} elevation={1}>
        <PageTabs />
        <div className={classes.content}>
          <FounderDetailsForm
            loading={isCreating}
            onSubmit={handleSubmitForm}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default NewFounderPage;
