import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';
import { classnames } from '@material-ui/data-grid';
import { COLORS } from '../../theme/variables';
import { Text } from '../common';
import { Edit } from '../common/ActionIcons/Edit';
import { Loading } from '../common/ActionIcons/Loading';
import { Delete } from '../common/ActionIcons/Delete';
import { Description } from '../common/Description';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateAreas: '"type req r1 r1 edit rm" \n "desc desc desc r2 r2 r2"',
    gridTemplateColumns: '1fr 1fr 1fr 35px 35px 35px',
    marginTop: '25px'
  },
  type: {
    gridArea: 'type',
    color: COLORS.COLOR_GRAY_PLACEHOLDER
  },
  mandatory: {
    gridArea: 'req',
    color: COLORS.COLOR_GRAY_PLACEHOLDER
  },
  description: {
    gridArea: 'desc'
  },
  loading: { cursor: 'default' },
  edit: { gridArea: 'edit' },
  delete: { gridArea: 'rm' }
}));

export interface Props {
  mandatory: boolean;
  type: string;
  description: string;
  removing: boolean;
  onRemove: () => void;
  onEdit: () => void;
}

export function Question(props: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid='question-item'>
      <Text variant={'normal'} className={classes.type}>
        Type: {props.type}
      </Text>
      <Text variant={'normal'} className={classes.mandatory}>
        Mandatory: {props.mandatory ? 'Yes' : 'No'}
      </Text>
      <Description className={classes.description}>
        {props.description}
      </Description>
      <Edit
        data-testid='question-edit'
        onClick={props.onEdit}
        className={classes.edit}
      />
      {props.removing ? (
        <Loading className={classnames(classes.delete, classes.loading)} />
      ) : (
        <Delete
          data-testid='question-remove'
          onClick={props.onRemove}
          className={classes.delete}
        />
      )}
    </div>
  );
}
