import {
  Link as MaterialLink,
  makeStyles,
  Paper,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
  Button as MaterialButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import cn from 'classnames';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import {
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
  useCallback,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation, useParams } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { Mentor } from '../../api/mentors';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import {
  Text,
  AlertState,
  Link,
  Button,
  Modal,
  ConfirmButton,
  FormButtons,
  StickyContent,
} from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TableFooter,
} from '../common/table';
import InviteGatheringMentors, {
  AssignMentors,
} from '../forms/invite-gathering-mentors';

interface Props {
  loading: boolean;
  handleAssingMentors: (assigned: AssignMentors, callback: () => any) => any;
  handleRemoveMentors: (value: any, cb: () => any) => any;
}

export interface TableValue {
  name: string;
  id: string;
  email: string;
}

const useStyles = makeStyles({
  container: {
    padding: 0,
    boxShadow: 'none',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px 0 16px',
    minHeight: 50,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2,
    flexWrap: 'wrap',
    rowGap: 15,
  },
  filterList: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginRight: 15,
    padding: '0 10px',
    justifyContent: 'flex-end',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    display: 'flex',

    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  summary: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  dialog: {
    padding: '20px 32px 25px',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  loadingBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
    columnGap: 10,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 599,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
    rowGap: 15,
  },
  iconRemove: {
    marginRight: 25,
  },
  formButtonsFooter: {
    marginTop: 0,
  },
  stickyFooter: {
    padding: '0 !important',
    minHeight: '60px !important',
  },
});

async function gatheringsRequest(gatheringId: Gathering['id'], page: number) {
  const currentPage = page < 0 ? 0 : page;
  return await gatheringsAPI.getGatheringMentorList(gatheringId, currentPage);
}

const parseMentors = (value: Mentor[]): TableValue[] => {
  if (value.length === 0) {
    return [];
  }

  return value.map((item) => ({
    name: `${item.firstName} ${item.lastName}`,
    id: item.id,
    email: item.email,
  }));
};

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

const GatheringMentorsManually = ({
  loading,
  handleRemoveMentors,
  handleAssingMentors,
}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { rb } = useResourceBundles();

  const [mentors, setMentors] = useState<TableValue[]>([]);
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const [loadError, setLoadError] = useState();
  const [selectedMentorsIds, setSelectedMentorsIds] = useState<
    TableValue['id'][]
  >([]);

  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );
  const perPage = 10;

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, mentorId: TableValue['id']) => {
      if (checked) {
        return setSelectedMentorsIds(_uniq([...selectedMentorsIds, mentorId]));
      }
      return setSelectedMentorsIds(
        selectedMentorsIds.filter((id) => id !== mentorId),
      );
    },
    [selectedMentorsIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const mentorId = rowData.id;
      handleRowSelect(!selectedMentorsIds.includes(mentorId), mentorId);
    },
    [selectedMentorsIds, handleRowSelect],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const mentorsId = mentors.map((mentor) => mentor.id);
        return setSelectedMentorsIds([...mentorsId]);
      }
      return setSelectedMentorsIds([]);
    },
    [mentors],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const handleRemove = async (callback: () => any, mentorIds: string[]) => {
    await handleRemoveMentors(mentorIds, () => loadGatherings(page - 1));
    setSelectedMentorsIds([]);
    callback();
  };

  const loadGatherings = async (currentPage: number) => {
    try {
      setMentors([]);
      setIsLoading(true);
      setIsEmptyState(false);

      const loadedGatherings = await gatheringsRequest(
        gatheringId,
        currentPage - 1,
      );
      if (loadedGatherings.length === 0) {
        throw new Error('404');
      }

      const resParse = parseMentors(loadedGatherings);
      setMentors(resParse);
      setIsLoading(false);

      if (loadedGatherings.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextGatherings = await gatheringsRequest(
            gatheringId,
            currentPage,
          );
          setIsNextPageLoading(false);
          setHasNextPage(nextGatherings.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const handleReplication = async (cb: () => any) => {
    try {
      setIsLoading(true);
      await gatheringsAPI.mentorsReplication(gatheringId);
      cb();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [location.pathname, history],
  );

  useEffect(() => {
    loadGatherings(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Paper className={classes.container} data-testid='gathering-table'>
      <div className={classes.filterContainer}>
        <div className={classes.filterList}>
          {selectedMentorsIds.length > 0 && (
            <div className={classes.filterActions}>
              <Text variant='normal'>{selectedMentorsIds.length} selected</Text>
              <ConfirmButton
                title={`Remove the assignment${
                  selectedMentorsIds.length > 1 ? 's' : ''
                }?`}
                body={`Sure you want to remove the assignment${
                  selectedMentorsIds.length > 1 ? 's' : ''
                }? Changes can't be undone`}
                successProps={{
                  btnLabel: 'Remove',
                  onSuccess: (callback) =>
                    handleRemove(callback, selectedMentorsIds),
                }}
                cancelProps={{
                  btnLabel: 'Cancel',
                }}
                buttonRenderer={({ onClick }) => (
                  <Tooltip title='Remove'>
                    <IconButton onClick={onClick} disabled={loading}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
      <div ref={containerRef}>
        {mentors?.length > 0 ? (
          <div>
            <Table
              onRowClick={handleRowClick}
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (mentors.length > 10 ? mentors.length : 10) + 56}
              rowHeight={64}
              rowCount={mentors.length}
              rowGetter={({ index }) => mentors[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => <TableRow {...rowProps} />}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={50}
                minWidth={50}
                headerRenderer={() => (
                  <Checkbox
                    checked={selectedMentorsIds.length === mentors.length}
                    color='primary'
                    indeterminate={
                      selectedMentorsIds.length > 0 &&
                      selectedMentorsIds.length !== mentors.length
                    }
                    onChange={(e) => handleAllRowSelect(e.target.checked)}
                  />
                )}
                cellRenderer={({ cellData }) => (
                  <Checkbox
                    data-testid='session-table-row-checkbox'
                    checked={selectedMentorsIds.includes(cellData)}
                    color='primary'
                    onChange={(e) =>
                      handleRowSelect(e.target.checked, cellData)
                    }
                  />
                )}
              />
              <Column
                dataKey='name'
                className={cn(classes.column, classes.summary)}
                headerClassName={classes.summary}
                width={100}
                minWidth={100}
                flexGrow={1}
                headerRenderer={() => (
                  <TableHeadCell>{rb('mentor-u')} Name</TableHeadCell>
                )}
                cellRenderer={({ cellData, rowData }) => (
                  <TableCell truncated>
                    <MaterialLink
                      data-testid='gathering-table-name'
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.GATHERINGS_DETAILS, {
                          gatheringId: rowData.id,
                          groupId: rowData.groupId,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {cellData}
                    </MaterialLink>
                  </TableCell>
                )}
              />

              <Column
                dataKey='email'
                className={classes.column}
                width={200}
                minWidth={200}
                maxWidth={500}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  return (
                    <TableCell truncated>
                      <Text variant='normal'>{cellData}</Text>
                    </TableCell>
                  );
                }}
              />

              <Column
                dataKey='actions'
                className={cn(classes.column, classes.justifyContentEnd)}
                minWidth={120}
                width={120}
                headerRenderer={() => <div></div>}
                cellRenderer={({ rowData }) => (
                  <TableCell onClick={stopPropagation}>
                    <div className={classes.actionsCell}>
                      <ConfirmButton
                        title='Remove the assignment?'
                        body='Sure you want to remove the assignment? Changes can’t be undone'
                        successProps={{
                          btnLabel: 'Remove',
                          onSuccess: (callback) =>
                            handleRemove(callback, [rowData.id]),
                        }}
                        cancelProps={{
                          btnLabel: 'Cancel',
                        }}
                        buttonRenderer={({ onClick }) => (
                          <Tooltip title='Remove'>
                            <IconButton
                              onClick={onClick}
                              disabled={loading}
                              className={classes.iconRemove}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        loading={loading}
                      />
                    </div>
                  </TableCell>
                )}
              />
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='mentor-table-no-items'>
                    Currently we have no {rb('mentors')} invited to this
                    gathering.
                    <br />
                    Please feel free to add new by clicking on a button below
                  </Text>
                  <Modal
                    title={`Add a ${rb('mentor')}`}
                    contentRenderer={({ handleClose }) => (
                      <InviteGatheringMentors
                        gatheringId={gatheringId}
                        loading={loading}
                        onSuccess={(value) => {
                          handleAssingMentors(value, handleClose);
                        }}
                        onCancel={handleClose}
                      />
                    )}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={onClick}
                        variant='contained'
                        disabled={loading}>
                        Add {rb('mentor-u')}
                      </Button>
                    )}
                    width={720}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.loadingBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <StickyContent
            className={classes.stickyFooter}
            inaccuracy={36}
            isShow={mentors?.length > 0}>
            <TableFooter
              page={page}
              onPageChange={handlePageChange}
              disabled={isLoading}
              isLoading={isNextPageLoading}
              hasNextPage={mentors.length > 0 && hasNextPage}>
              {mentors?.length > 0 && (
                <FormButtons className={classes.formButtonsFooter}>
                  <Modal
                    title={`Add a ${rb('mentor')}`}
                    contentRenderer={({ handleClose }) => (
                      <InviteGatheringMentors
                        gatheringId={gatheringId}
                        loading={loading}
                        onSuccess={(value) => {
                          handleAssingMentors(value, handleClose);
                        }}
                        onCancel={handleClose}
                      />
                    )}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={onClick}
                        variant='contained'
                        disabled={loading}>
                        Add {rb('mentor-u')}
                      </Button>
                    )}
                    width={720}
                  />
                  <ConfirmButton
                    title='Replicate'
                    body={`We will replicate this instance ${rb(
                      'mentors',
                    )} subscription list to the whole gathering group. Do you want to proceed?`}
                    successProps={{
                      btnLabel: 'Apply',
                      onSuccess: handleReplication,
                    }}
                    cancelProps={{
                      btnLabel: 'Cancel',
                    }}
                    loading={isLoading}
                    buttonRenderer={({ onClick }) => (
                      <Tooltip
                        title={`All ${rb(
                          'mentors',
                        )} that currently assigned to the current gathering instance will be assigned to the whole gathering group`}>
                        <MaterialButton
                          onClick={onClick}
                          variant='contained'
                          color='primary'
                          disabled={isLoading}>
                          Replicate Invitees
                        </MaterialButton>
                      </Tooltip>
                    )}
                  />
                </FormButtons>
              )}
            </TableFooter>
          </StickyContent>
        )}
      </div>
    </Paper>
  );
};

export default GatheringMentorsManually;
