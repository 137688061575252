import { AxiosError } from 'axios';
import { Dispatch, useEffect, useReducer, useRef } from 'react';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Reload } from '../pages/CommunityMembers/Logs/types/Actions';

export function useLoadData<T, P>(
  fetch: (p: P) => Promise<T>,
): [LoadDataState<T, P>, Dispatch<Load<P> | Reload | Disable>] {
  const [state, dispatch] = useReducer<
    (state: LoadDataState<T, P>, action: Actions<T, P>) => LoadDataState<T, P>
  >(reducer, idle({}));
  const fetchFn = useRef({ fetch });
  fetchFn.current = { fetch };

  useEffect(() => {
    switch (state.type) {
      case 'Loading':
      case 'Reloading': {
        const o$ = from(fetchFn.current.fetch(state.payload.params))
          .pipe(
            map((data) => fetchSuccess(data)),
            catchError((error: AxiosError) =>
              of(fetchError(error.response?.data.message ?? error.message)),
            ),
          )
          .subscribe(dispatch);

        return () => o$.unsubscribe();
      }
    }
  }, [state]);

  return [state, dispatch as Dispatch<Load<P> | Reload | Disable>];
}

function reducer<T, P>(
  state: LoadDataState<T, P>,
  action: Actions<T, P>,
): LoadDataState<T, P> {
  switch (action.type) {
    case 'Load':
      return loading({ params: action.payload });
    case 'FetchError':
      return state.type === 'Loading'
        ? loadError({
            params: state.payload.params,
            message: action.payload,
          })
        : state.type === 'Reloading'
        ? reloadError({ ...state.payload, message: action.payload })
        : state;
    case 'FetchSuccess':
      return state.type === 'Loading' || state.type === 'Reloading'
        ? success({ data: action.payload, params: state.payload.params })
        : state;
    case 'ReFetch':
      return state.type === 'Success' ? reloading(state.payload) : state;
    case 'Disable':
      return idle({});
  }
}

// region State
// region Idle
interface IdlePayload {}

interface Idle {
  type: 'Idle';
  payload: IdlePayload;
}

const idle = (payload: Idle['payload']): Idle => ({
  type: 'Idle',
  payload,
});
// endregion

// region Loading<P>
interface LoadingPayload<P> {
  params: P;
}

interface Loading<P> {
  type: 'Loading';
  payload: LoadingPayload<P>;
}

const loading = <P>(payload: Loading<P>['payload']): Loading<P> => ({
  type: 'Loading',
  payload,
});
// endregion

// region Success
interface SuccessPayload<T, P> {
  params: P;
  data: T;
}

interface Success<T, P> {
  type: 'Success';
  payload: SuccessPayload<T, P>;
}

const success = <T, P>(payload: Success<T, P>['payload']): Success<T, P> => ({
  type: 'Success',
  payload,
});
// endregion

// region LoadError
interface LoadErrorPayload<P> {
  params: P;
  message: string;
}

interface LoadError<P> {
  type: 'LoadError';
  payload: LoadErrorPayload<P>;
}

const loadError = <P>(payload: LoadError<P>['payload']): LoadError<P> => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Reloading
export interface ReloadingPayload<T, P> {
  params: P;
  data: T;
}

export interface Reloading<T, P> {
  type: 'Reloading';
  payload: ReloadingPayload<T, P>;
}

export const reloading = <T, P>(
  payload: Reloading<T, P>['payload'],
): Reloading<T, P> => ({
  type: 'Reloading',
  payload,
});
// endregion

// region ReloadError
export interface ReloadErrorPayload<T, P> {
  params: P;
  data: T;
  message: string;
}

export interface ReloadError<T, P> {
  type: 'ReloadError';
  payload: ReloadErrorPayload<T, P>;
}

export const reloadError = <T, P>(
  payload: ReloadError<T, P>['payload'],
): ReloadError<T, P> => ({
  type: 'ReloadError',
  payload,
});
// endregion

export type LoadDataState<T, P> =
  | Idle
  | Loading<P>
  | Success<T, P>
  | LoadError<P>
  | Reloading<T, P>
  | ReloadError<T, P>;
// endregion

// region Actions
// region Load
export interface Load<P> {
  type: 'Load';
  payload: P;
}

export const load = <P>(payload: Load<P>['payload']): Load<P> => ({
  type: 'Load',
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess<T> {
  type: 'FetchSuccess';
  payload: T;
}

export const fetchSuccess = <T>(
  payload: FetchSuccess<T>['payload'],
): FetchSuccess<T> => ({
  type: 'FetchSuccess',
  payload,
});
// endregion

// region FetchError
export interface FetchError {
  type: 'FetchError';
  payload: string;
}

export const fetchError = (payload: FetchError['payload']): FetchError => ({
  type: 'FetchError',
  payload,
});
// endregion

// region ReFetch
export interface ReFetch {
  type: 'ReFetch';
}

export const reFetch = (): ReFetch => ({
  type: 'ReFetch',
});
// endregion

// region Disable
export interface Disable {
  type: 'Disable';
}

export const disable = (): Disable => ({
  type: 'Disable',
});
// endregion

type Actions<T, P> = Load<P> | FetchSuccess<T> | FetchError | ReFetch | Disable;
// endregion
