import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../theme/variables';

export interface PaginatorProps {
  value: number;
  pages: number;
  onChange: (value: number) => void;
}

export function Paginator({ value, pages, onChange }: PaginatorProps) {
  const c = useStyles();

  return (
    <div className={c.root}>
      {Array.from({ length: pages }).map((_, i) => (
        <span
          role={'radio'}
          aria-checked={i === value}
          className={c.page}
          onClick={() => onChange(i)}
          key={i}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  page: {
    width: 16,
    height: 16,
    borderRadius: 10,
    border: `2px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    cursor: 'pointer',
    boxSizing: 'border-box',
    transition: 'all 0.2s ease-in-out',
    background: 'transparent',

    '&[aria-checked=true]': {
      background: COLORS.COLOR_BLUE_BASE,
      border: 'none',
    },
  },
});
