import { useState, useEffect } from 'react';
import { FormGroup, CheckboxList, Text, TextField } from '../common';
import { makeStyles } from '@material-ui/core';
import { lengthField } from '../../utils/form';
import { MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { useField } from 'react-final-form';

const OTHER = ',OTHER:';

interface Props {
  name: string;
  options: OptionProps[];
  onChange: (id: any) => void;
  text: string;
  labelOther: string;
  value?: string;
  readOnly?: boolean;
  testid?: string;
  withOther?: boolean;
  testIndex?: boolean;
}

interface OptionProps {
  value: string;
  label: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  multilineField: {
    minHeight: 64
  },
  checkbox: {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 0
  },
  textOther: {
    marginTop: '10px !important',
    width: 'calc(100% - 16px)'
  },
  text: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14
    }
  }
});

function CheckboxListOther({
  name,
  onChange,
  options,
  text,
  labelOther,
  value,
  readOnly = false,
  testid,
  withOther = true,
  testIndex = false
}: Props) {
  const [selected, setSelected] = useState<string[]>([]);
  const [isOther, setIsOther] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>('');
  const classes = useStyles();
  const field = useField(name);
  const invalid =
    !!field &&
    !!field.meta.error &&
    (field.meta.submitFailed || field.meta.touched);

  const handleChange = (value: string) => {
    if (readOnly) return;
    let currentValue: string[];
    let selectedValue;
    if (selected?.includes(value)) {
      currentValue = selected.filter((data) => data !== value);
    } else {
      currentValue = [...selected, value];
    }
    setSelected(currentValue);

    selectedValue = currentValue.join();
    if (isOther && otherValue) {
      selectedValue = selectedValue + `${OTHER}${otherValue}`;
    }

    return onChange(selectedValue);
  };

  const handleChangeOther = (value: string) => {
    if (readOnly) return;
    let selectedValue;
    setOtherValue(value);

    if (value) {
      selectedValue = selected.join() + `${OTHER}${value}`;
    } else {
      selectedValue = selected.join();
    }

    return onChange(selectedValue);
  };

  const parsedInitialValue = (value: string) => {
    let selectedValue;
    if (value.includes(OTHER)) {
      const valueArrayOther = value.split(OTHER);

      selectedValue = valueArrayOther[0];
      setIsOther(true);
      setOtherValue(valueArrayOther[1].trim());
    } else {
      selectedValue = value;
    }

    if (!selectedValue?.trim()) {
      return setSelected([]);
    }

    const arraySelectedValue = selectedValue
      .split(',')
      .map((option) => option.trim());
    setSelected(arraySelectedValue);
  };

  useEffect(() => {
    if (value) {
      parsedInitialValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGroup column>
      <Text variant='normal' className={classes.text}>
        {text}
      </Text>
      {options.length > 0 &&
        options.map((option, optionIndex) => {
          return (
            <CheckboxList
              key={option.value}
              value={option.value}
              label={option.label}
              name={name}
              checked={selected.includes(option.value)}
              onChange={handleChange}
              className={classes.checkbox}
              classNameLabel={classes.checkboxLabel}
              testid={`${testid}-${testIndex ? optionIndex : option.value}`}
            />
          );
        })}

      {withOther && (
        <CheckboxList
          value={`${isOther}`}
          label='Other...'
          testid={`${testid}-other`}
          name={`${name}-other`}
          checked={isOther}
          onChange={() => {
            if (readOnly) return;
            if (isOther) {
              handleChangeOther('');
            }
            setIsOther(!isOther);
          }}
          className={classes.checkbox}
          classNameLabel={classes.checkboxLabel}
          showError={invalid}
        />
      )}

      {isOther && (
        <div className={classes.textOther}>
          <TextField
            data-testid={`${testid}-text-other`}
            name={`${name}-text-other`}
            label={labelOther}
            value={otherValue}
            multiline
            error={invalid}
            onChange={(e) => handleChangeOther(e.target.value)}
            InputProps={{
              inputProps: {
                className: classes.multilineField,
                maxLength: lengthField.additionalInfo,
                readOnly
              }
            }}
          />
        </div>
      )}
    </FormGroup>
  );
}

export default CheckboxListOther;
