import { useCallback, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import ConsentForm, {
  Values as FormValues
} from '../../components/forms/consent';
import { COLORS } from '../../theme/variables';

interface NewConsentProps {
  creating?: boolean;
  onCreate: (
    consent: { body: string; type: string },
    callback: () => any
  ) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56
  },
  main: {
    display: 'flex',
    alignItems: 'center'
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)'
    }
  }
});

function NewConsent({ creating, onCreate }: NewConsentProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);

  const handleOpenForm = () => {
    setIsShownForm(true);
  };

  const handleCloseForm = () => {
    setIsShownForm(false);
  };

  const handleCreateConsent = useCallback(
    (values: FormValues) => {
      onCreate(values, handleCloseForm);
    },
    [onCreate]
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, []);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <ConsentForm
          title='New Commitment'
          loading={creating}
          onSubmit={handleCreateConsent}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div className={classes.main}>
          <Typography variant='h4'>New Commitment</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={handleOpenForm}
            data-testid='button-new-consent'>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default NewConsent;
