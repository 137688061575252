export type FilterStatus =
  | 'nonaccepted'
  | 'all'
  | 'Accepted'
  | 'Requested'
  | 'Rejected'
  | 'Completed'
  | 'Archived'
  | 'Cancelled';

export const statuses: FilterStatus[] = [
  'nonaccepted',
  'all',
  'Accepted',
  'Requested',
  'Rejected',
  'Completed',
  'Archived',
  'Cancelled',
];
