import {
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link as MaterialLink,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import authAPI, { TractionUser } from '../../api/auth';
import { Role } from '../../api/user/Role';
import {
  AlertState,
  Button,
  CopyText,
  FormButtons,
  Modal,
  Select,
  SnackMessage,
  StatusBadge,
  Text,
  TextField,
} from '../../components/common';
import {
  TableCell,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../../components/common/table';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { isMobile, isTablet } from '../../utils/functions';

export interface FormValue {
  channelName: string;
  channelLocation: string;
  description: string;
}

type FilterValueType =
  | {
      key: 'search' | 'role';
      value: string;
    }
  | undefined;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    borderRadius: 0,
  },
  filterContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  nameColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  emailColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  text: {
    cursor: 'pointer',
    color: COLORS.COLOR_BLUE_DARKENED_10,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  buttonResendInvite: {
    marginLeft: 11,
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  iconRemoveFilter: {
    width: 20,
  },
  warningIcon: {
    fill: '#FFC107',
    width: 18,
    height: 18,
  },
}));

const getRoleValue = (label: string): Role | '' => {
  switch (label.toLowerCase()) {
    case 'admin':
      return Role.Manager;
    case 'mentor':
      return Role.Mentor;
    case 'founder':
      return Role.Founder;
    default:
      return '';
  }
};

async function usersRequest(filter: FilterValueType, page: number) {
  if (filter) {
    let loadedUsers;
    switch (filter.key) {
      case 'search':
        loadedUsers = await authAPI.getSearchUsers(filter.value);
        break;
      case 'role':
        loadedUsers = await authAPI.getUsersByRole(
          getRoleValue(filter.value),
          page,
        );
        break;
      default:
        return [];
    }
    return loadedUsers;
  }
  return authAPI.getUsers(page);
}

function getFilterItemByFilter(filter: {
  key: 'search' | 'role';
  value: string;
}) {
  const keyAliases = {
    search: 'Search',
    role: 'Role',
  };
  let value;
  if (filter.key === 'search') {
    value = filter.value;
  }

  return {
    label: keyAliases[filter.key],
    value,
  };
}

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

function TenantUserManagementPage() {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const getLabelStatus = useCallback(
    (role: Role): string => {
      switch (role) {
        case Role.Manager:
          return 'Admin';
        case Role.Mentor:
          return rb('mentor-u');
        case Role.Founder:
          return 'Founder';
        case Role.Admin:
        case Role.CommunityMember:
          return '';
      }
    },
    [rb],
  );

  const filters = useMemo(
    () => ({
      role: [
        {
          label: 'All types',
          value: 'none',
        },
        {
          label: rb('mentor-u'),
          value: 'Mentor',
        },
        {
          label: 'Founder',
          value: 'Founder',
        },
        {
          label: 'Admin',
          value: 'Admin',
        },
      ],
    }),
    [rb],
  );

  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [users, setUsers] = useState<TractionUser[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [loadError, setLoadError] = useState();
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingRevoke, setLoadingRevoke] = useState(false);

  const [selectedIds, setSelectedIds] = useState<TractionUser['id'][]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const filterValue = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );
  const [searchValue, setSearchValue] = useState(
    filterValue?.key === 'search' ? filterValue.value : '',
  );
  const perPage = useMemo(
    () => (filterValue?.key !== 'search' ? 10 : undefined),
    [filterValue],
  );

  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );

  const showClearFilter = useMemo(() => {
    return !!filterValue || !!searchValue;
  }, [filterValue, searchValue]);

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
        ...(filterValue
          ? {
              [filterValue.key]: filterValue.value,
            }
          : {}),
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [filterValue, location.pathname, history],
  );

  const handleRevokeUser = async (id: string, callback: () => any) => {
    try {
      setLoadingRevoke(true);
      await authAPI.userRevoke(id);
      setLoadingRevoke(false);
      callback();
      loadUsers(filterValue, page);
      enqueueSnackbar('Success!', {
        variant: 'success',
      });
    } catch (error: any) {
      const messageError = error.response?.data?.message;
      setLoadingRevoke(false);

      enqueueSnackbar(
        'An error occurred while revoke user. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
    }
  };

  const handleClearFilters = useCallback(() => {
    if (searchValue) {
      setSearchValue('');
    }
    history.replace(location.pathname);
  }, [history, location, searchValue]);

  const handleInvitesUser = async (id: string, callback: () => any) => {
    try {
      const currentUser = users.find((user) => user.id === id);
      setLoadingInvite(true);
      if (currentUser?.userRoleName !== Role.Manager) {
        await authAPI.multipleUsersInvites([id]);
      } else {
        await authAPI.userInvites(id);
      }
      setLoadingInvite(false);
      callback();
      loadUsers(filterValue, page);
      enqueueSnackbar('Success!', {
        variant: 'success',
      });
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      setLoadingInvite(false);
      enqueueSnackbar(
        'An error occurred while sending the invite. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
    }
  };

  const handleRevokeMultipleUser = async (callback: () => any) => {
    try {
      setLoadingRevoke(true);
      await authAPI.multipleRevokeInvites(selectedIds);
      setLoadingRevoke(false);
      callback();
      loadUsers(filterValue, page);
      enqueueSnackbar('Success!', {
        variant: 'success',
      });
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      setLoadingRevoke(false);
      enqueueSnackbar(
        'An error occurred while revoke users. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
    }
  };

  const handleInvitesMultipleUser = async (callback: () => any) => {
    try {
      setLoadingInvite(true);
      await authAPI.multipleUsersInvites(selectedIds);
      setLoadingInvite(false);
      callback();
      loadUsers(filterValue, page);
      enqueueSnackbar('Success!', {
        variant: 'success',
      });
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      setLoadingInvite(false);
      enqueueSnackbar(
        'An error occurred while sending the invite. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      callback();
    }
  };

  const handleRowSelect = useCallback(
    (checked: boolean, selectedId: TractionUser['id']) => {
      if (checked) {
        return setSelectedIds(_uniq([...selectedIds, selectedId]));
      }
      return setSelectedIds(selectedIds.filter((id) => id !== selectedId));
    },
    [selectedIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const mentorId = rowData.id;
      handleRowSelect(!selectedIds.includes(mentorId), mentorId);
    },
    [selectedIds, handleRowSelect],
  );

  const debouncedTimer = useRef<number>();

  function parseFilterFromURL(search: string): FilterValueType {
    const parsedFilter = _pick(parseQuery(search), ['search', 'role']);
    const filterName = Object.keys(parsedFilter)[0] as 'search' | 'role';

    const filterValue = parsedFilter[filterName as keyof typeof parsedFilter];
    return typeof filterValue === 'string'
      ? { key: filterName, value: filterValue }
      : undefined;
  }

  const handleFilterUpdate = useCallback(
    (field, value) => {
      if (!value || value === 'none') {
        history.replace(location.pathname);
      } else {
        history.replace(
          `${location.pathname}?${stringifyQuery({ [field]: value })}`,
        );
      }
    },
    [history, location.pathname],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate('search', e.target.value.trim());
        } else {
          handleFilterUpdate('search', null);
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const mentorsIds = users.map((user) => user.id);
        return setSelectedIds([...mentorsIds]);
      }
      return setSelectedIds([]);
    },
    [users],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };
  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  const loadUsers = async (filter: FilterValueType, currentPage: number) => {
    try {
      setUsers([]);
      setSelectedIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (filter?.key !== 'search' && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedUsers = await usersRequest(filter, currentPage - 1);
      if (loadedUsers.length === 0) {
        throw new Error('404');
      }
      setUsers(loadedUsers);
      setIsLoading(false);
      setIsLoading(false);

      if (loadedUsers.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextUsers = await usersRequest(filter, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextUsers.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  useEffect(() => {
    loadUsers(filterValue, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, page]);

  return (
    <Paper className={classes.container} data-testid='mentors-table'>
      <div className={classes.filterContainer}>
        <div className={classes.filterList}>
          <div
            className={cn(classes.filterItem, classes.mobileNone)}
            data-testid={getDataTestId(
              'user-management-filter-search',
              !!searchValue,
            )}>
            <TextField
              className={cn(classes.searchInput)}
              value={searchValue}
              onChange={handleSearch}
              placeholder='Search by Email'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              small
            />
          </div>
          <div
            className={cn(classes.filterItem, classes.mobileNone)}
            data-testid={getDataTestId(
              'user-management-filter-status',
              filterValue?.key === 'role' &&
                filterValue.value !== filters.role[0].value,
            )}>
            <Select
              className={cn(classes.filterSelect)}
              value={
                filterValue?.key === 'role'
                  ? filterValue.value
                  : filters.role[0].value
              }
              options={filters.role}
              onSelect={(item) => handleFilterUpdate('role', item?.value)}
              isActive={
                filterValue?.key === 'role' &&
                filterValue.value !== filters.role[0].value
              }
            />
          </div>
          {showClearFilter && (
            <Tooltip title='Clear all filters'>
              <IconButton
                data-testid='button-remove-filter'
                className={classes.containerRemoveFilter}
                onClick={() => handleClearFilters()}>
                <img
                  src='/filter.svg'
                  alt='Remove filter'
                  className={classes.iconRemoveFilter}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {selectedIds.length > 0 && (
          <div className={classes.filterActions}>
            <Text variant='normal'>{selectedIds.length} selected</Text>
            <Modal
              title='Resending an Invite'
              caption='You are going to resend the invitation with login instructions to the selected users. Are you sure you want to continue?'
              contentRenderer={({ handleClose }) => (
                <FormButtons className={classes.formButtons}>
                  <Button onClick={handleClose} variant='outlined'>
                    Cancel
                  </Button>
                  <Button
                    data-testid='mentor-assignment-apply'
                    onClick={() => handleInvitesMultipleUser(handleClose)}>
                    {loadingInvite ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Yes'
                    )}
                  </Button>
                </FormButtons>
              )}
              buttonRenderer={({ onClick }) => (
                <Tooltip title='Resend invite'>
                  <IconButton
                    data-testid='multiple-resend-invite'
                    onClick={onClick}
                    className={classes.buttonResendInvite}>
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              )}
              width={400}
            />

            <Modal
              title='Revoking Access'
              caption='You are going to revoke access from the selected users. Are you sure you want to continue?'
              contentRenderer={({ handleClose }) => (
                <FormButtons className={classes.formButtons}>
                  <Button onClick={handleClose} variant='outlined'>
                    Cancel
                  </Button>
                  <Button
                    data-testid='mentor-assignment-apply'
                    onClick={() => handleRevokeMultipleUser(handleClose)}>
                    {loadingRevoke ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Yes'
                    )}
                  </Button>
                </FormButtons>
              )}
              buttonRenderer={({ onClick }) => (
                <Tooltip title='Revoke access'>
                  <IconButton
                    onClick={onClick}
                    data-testid='multiple-revoke-access'>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
              width={400}
            />
          </div>
        )}
      </div>

      <div ref={containerRef}>
        {users.length > 0 ? (
          <div>
            <Table
              onRowClick={handleRowClick}
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (users.length > 10 ? users.length : 10) + 56}
              rowHeight={64}
              rowCount={users.length}
              rowGetter={({ index }) => users[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`user-table-row-${rowProps.index}`}
                  selected={selectedIds.includes(rowProps.rowData.id)}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={!isTablet() ? 72 : 20}
                minWidth={!isTablet() ? 72 : 20}
                headerRenderer={() => (
                  <div>
                    {!isTablet() && (
                      <Checkbox
                        checked={selectedIds.length === users.length}
                        color='primary'
                        indeterminate={
                          selectedIds.length > 0 &&
                          selectedIds.length !== users.length
                        }
                        onChange={(e) => handleAllRowSelect(e.target.checked)}
                      />
                    )}
                  </div>
                )}
                cellRenderer={({ cellData }) => (
                  <div>
                    {!isTablet() && (
                      <Checkbox
                        data-testid='user-table-row-checkbox'
                        checked={selectedIds.includes(cellData)}
                        color='primary'
                        onChange={(e) =>
                          handleRowSelect(e.target.checked, cellData)
                        }
                      />
                    )}
                  </div>
                )}
              />

              <Column
                dataKey='name'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={172}
                minWidth={isMobile() ? 120 : 172}
                maxWidth={357}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ rowData, rowIndex }) => (
                  <TableCell truncated>
                    <Text
                      variant='normal'
                      data-testid={`user-management-${rowIndex}`}>
                      {rowData.firstName} {rowData.lastName}
                    </Text>
                  </TableCell>
                )}
              />

              <Column
                dataKey='inviteCode'
                className={classes.column}
                width={20}
                minWidth={20}
                maxWidth={30}
                flexGrow={1}
                cellRenderer={({ cellData }) => {
                  return cellData ? (
                    <Tooltip title={'Not Activated'} placement={'top'}>
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  ) : null;
                }}
              />

              <Column
                dataKey='userRoleName'
                className={classes.column}
                width={100}
                minWidth={100}
                maxWidth={150}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Role</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const labelStatus = getLabelStatus(cellData);

                  return (
                    <StatusBadge
                      status={labelStatus}
                      variant={
                        cellData === Role.Manager ? 'success' : undefined
                      }
                    />
                  );
                }}
              />

              {!isTablet() && (
                <Column
                  dataKey='email'
                  className={cn(classes.column, classes.emailColumn)}
                  headerClassName={classes.emailColumn}
                  width={276}
                  minWidth={276}
                  maxWidth={435}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                  cellRenderer={({ cellData }) => (
                    <CopyText onClick={stopPropagation} text={cellData}>
                      <TableCell onClick={stopPropagation} truncated>
                        <MaterialLink href={`mailto:${cellData}`}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                    </CopyText>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='actions'
                  className={cn(classes.column, classes.justifyContentEnd)}
                  minWidth={40}
                  width={120}
                  headerRenderer={() => <div />}
                  cellRenderer={({ rowData }) => (
                    <TableCell onClick={stopPropagation}>
                      <div className={classes.actionsCell}>
                        <Modal
                          title='Resending an Invite'
                          caption='You are about to resend an invite with login instructions to this user. Are you sure you want to continue?'
                          contentRenderer={({ handleClose }) => (
                            <FormButtons className={classes.formButtons}>
                              <Button onClick={handleClose} variant='outlined'>
                                No
                              </Button>
                              <Button
                                onClick={() =>
                                  handleInvitesUser(rowData.id, handleClose)
                                }>
                                {loadingInvite ? (
                                  <CircularProgress size={24} color='inherit' />
                                ) : (
                                  'Yes'
                                )}
                              </Button>
                            </FormButtons>
                          )}
                          buttonRenderer={({ onClick }) => (
                            <Tooltip title='Resend invite'>
                              <IconButton onClick={onClick}>
                                <ExitToAppIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          width={400}
                        />
                        <Modal
                          title='Revoking Access'
                          caption='You are about to revoke access from this user. Are you sure you want to continue?'
                          contentRenderer={({ handleClose }) => (
                            <FormButtons className={classes.formButtons}>
                              <Button onClick={handleClose} variant='outlined'>
                                No
                              </Button>
                              <Button
                                data-testid='mentor-assignment-apply'
                                onClick={() =>
                                  handleRevokeUser(rowData.id, handleClose)
                                }>
                                {loadingRevoke ? (
                                  <CircularProgress size={24} color='inherit' />
                                ) : (
                                  'Yes'
                                )}
                              </Button>
                            </FormButtons>
                          )}
                          buttonRenderer={({ onClick }) => (
                            <Tooltip title='Revoke access'>
                              <IconButton onClick={onClick}>
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          width={400}
                        />
                      </div>
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div
                className={classes.emptyBlock}
                data-testid='user-management-empty-container'>
                <div className={classes.emptyState}>
                  <Text variant='normal'>
                    This panel contains a list of users
                    {!!filterValue &&
                      (() => {
                        const filter = getFilterItemByFilter(filterValue);
                        return (
                          <>
                            {' '}
                            filtered by{' '}
                            <Text variant='normal' bold>
                              {filter.label}: {filter.value}
                            </Text>
                          </>
                        );
                      })()}
                    .<br />
                    We were not able to find information you requested
                  </Text>
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <TableFooter
            page={page}
            onPageChange={handlePageChange}
            disabled={isLoading}
            isLoading={isNextPageLoading}
            hasNextPage={users.length > 0 && hasNextPage}
          />
        )}
      </div>
    </Paper>
  );
}

export default TenantUserManagementPage;
