import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../theme/variables';
import { TestId } from '../Testing/TestId';
import { FormItem } from './Forms/types/FormItem';
import AlertState from './alert-state';
import { FormButtons, FormGroup } from './form-layout';
import { Button, Dialog, TextField } from './index';

export interface Props {
  title: string;
  error: string | undefined;
  username: FormItem<string>;
  password: FormItem<string>;
  showPassword: boolean;
  onTogglePassword: () => void;
  onSubmit: () => void;
  loading: boolean;
  onCancel: () => void;
  signUpUrl: string;
  onReset: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: 350,
  },
  title: {
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginBottom: '1rem',
  },
}));

export function LoginModal({
  showPassword,
  onTogglePassword,
  loading,
  onSubmit,
  username,
  password,
  onCancel,
  title,
  signUpUrl,
  onReset,
  error,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      title={''}
      setOpen={(v) => !v && onCancel()}
      open={true}
      contentRenderer={() => (
        <TestId testId={'login-modal'}>
          <div className={classes.dialog}>
            <Typography
              variant={'h3'}
              align={'center'}
              color={'textPrimary'}
              className={classes.title}>
              {title}
            </Typography>
            {error && <AlertState type={'error'}>{error}</AlertState>}
            <FormGroup>
              <TestId testId='login-form-email'>
                <TextField
                  type='email'
                  label='Email'
                  error={username.error}
                  value={username.value}
                  disabled={username.disabled}
                  onChange={(e) => username.onChange(e.target.value)}
                />
              </TestId>
            </FormGroup>
            <FormGroup>
              <TestId testId='login-form-password'>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  label='Password'
                  error={password.error}
                  value={password.value}
                  disabled={password.disabled}
                  onChange={(e) => password.onChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={onTogglePassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </TestId>
            </FormGroup>
            <FormButtons justify={'left'}>
              <Typography variant={'subtitle2'}>
                <span>Don't you have an account? </span>

                <Typography
                  component={'a'}
                  color={'primary'}
                  variant={'subtitle2'}
                  href={signUpUrl}>
                  Sign Up
                </Typography>
              </Typography>
            </FormButtons>
            <FormButtons justify={'space-between'}>
              <Typography
                color={'primary'}
                component={'a'}
                variant={'subtitle2'}
                onClick={onReset}>
                Reset Password
              </Typography>

              <Button
                data-testid='login-form-submit'
                disabled={loading}
                onClick={onSubmit}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Login'
                )}
              </Button>
            </FormButtons>
          </div>
        </TestId>
      )}
    />
  );
}
