import { MentorStatus } from '../../api/mentors';

export const MENTORS_LIST: any = [
  {
    assigned: 3,
    city: 'City',
    country: 'Country',
    dateOfBirth: 'date',
    dateOfEnrollment: 'date',
    deactivationDate: 'date',
    email: 'email@email.com',
    emailValid: true,
    firstName: 'Test',
    id: '1',
    lastName: 'Testov',
    linkedInProfile: 'https://linkedin.com/test',
    logo: null,
    mentorNotes: [],
    phone: '9504320923',
    state: 'state',
    status: MentorStatus.ACTIVE,
    tenantId: '1',
    valid: true,
    address: '',
    zip: '',
  },
  {
    assigned: 0,
    city: 'City',
    country: 'Country',
    dateOfBirth: 'date',
    dateOfEnrollment: 'date',
    deactivationDate: 'date',
    email: 'testov@email.com',
    emailValid: true,
    firstName: 'John',
    id: '2',
    lastName: 'Duglas',
    linkedInProfile: null,
    logo: null,
    mentorNotes: [],
    phone: '9104320923',
    state: 'state',
    status: MentorStatus.ACTIVE,
    tenantId: '1',
    valid: true,
    address: '',
    zip: '',
  },
  {
    assigned: 1,
    city: 'City',
    country: 'Country',
    dateOfBirth: 'date',
    dateOfEnrollment: 'date',
    deactivationDate: 'date',
    email: 'email@emaiasdasdl.com',
    emailValid: true,
    firstName: 'Abc',
    id: '3',
    lastName: 'Zwd',
    linkedInProfile: 'https://linkedin.com/test1',
    logo: null,
    mentorNotes: [],
    phone: '9504312923',
    state: 'state',
    status: MentorStatus.ARCHIVED,
    tenantId: '2',
    valid: true,
    address: '',
    zip: '',
  },
];
