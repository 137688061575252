import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { Button, FormButtons } from '.';
import mentorsAPI, {
  Mentor,
  MentorDetails,
  MentorStatus,
} from '../../api/mentors';
import { massAsyncRequest } from '../../utils/api';

interface Props {
  onCancel: () => any;
  selectedValue?: Mentor[];
  status: MentorStatus;
  updateTable: () => void;
}

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

const validateMentor = (mentor: Mentor): MentorDetails => {
  return {
    address: mentor.address,
    firstName: mentor.firstName,
    lastName: mentor.lastName,
    dateOfBirth: mentor.dateOfBirth,
    dateOfEnrollment: mentor.dateOfEnrollment,
    email: mentor.email,
    phone: mentor.phone,
    linkedInProfile: mentor.linkedInProfile,
    country: mentor.country,
    state: mentor.state,
    city: mentor.city,
    logo: mentor.logo,
    status: mentor.status,
    zip: mentor.zip,
  };
};

function ButtonsChangeStatusMentor({
  onCancel,
  selectedValue,
  status,
  updateTable,
}: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!selectedValue) return undefined;
    setLoading(true);
    const changeStatus = selectedValue.map((value: Mentor) => {
      return () =>
        mentorsAPI.update(value.id, {
          ...validateMentor(value),
          status: status,
        });
    });
    await massAsyncRequest(changeStatus);
    setLoading(false);
    onCancel();
    updateTable();
  };

  return (
    <FormButtons className={classes.formButtons}>
      <Button onClick={onCancel} variant='outlined'>
        Cancel
      </Button>
      <Button disabled={loading} type='submit' onClick={handleSubmit}>
        {loading ? <CircularProgress size={24} color='inherit' /> : 'Yes'}
      </Button>
    </FormButtons>
  );
}

export default ButtonsChangeStatusMentor;
