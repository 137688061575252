import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Button, FormButtons, PageLoader } from '.';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import authAPI, { ConsentType, ICheckMentorValidate } from '../../api/auth';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { Address, City, Country, Name, State, URL } from '../../types';
import { Email } from '../../utils/String/Email';
import { Phone, isPhone } from '../../utils/String/Phone';
import { Zip } from '../../utils/String/Zip';
import { getCustomQuestionsValidationSchema } from '../../utils/custom-questions';
import { isValidURL, validateEmail } from '../../utils/functions';
import { yupValidate } from '../../utils/yup';

interface Props {
  onSubmit: (values: FormValues) => void;
  tenantId: string;
  initialValues?: any;
  children: React.ReactNode[];
  isLoading: boolean;
  consents: ConsentType[];
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
}

interface PropsPage {
  children: React.ReactNode;
}

export interface FormValues {
  // step 1
  gender: string;
  race: string;
  firstName: string | Name;
  lastName: string | Name;
  email: string | Email;
  emailConfirm: string;
  dateOfBirth: string;
  linkedInProfile: string | URL;
  phone: string | Phone;
  veteran: string;
  whyBecomeMentor: string;

  // step 2
  address: string | Address;
  city: string | City;
  state: string | State;
  zip: string | Zip;
  country: string | Country;

  // step 3
  businessIndustries: string; //?
  salesChallenges: string;
  marketingChallenges: string;
  fundraisingChallenges: string;
  businessChallenges: string;

  //step4
  [x: string]: string;
}

export interface ValidFormValues extends FormValues {
  firstName: Name;
  lastName: Name;
  email: Email;
  linkedInProfile: URL;
  phone: Phone;
  address: Address;
  city: City;
  state: State;
  zip: Zip;
  country: Country;
  howDidYouFind: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const getValidValue = (value: any, tenantId: string): ICheckMentorValidate => {
  return {
    mentor: {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      tenantId: tenantId,
    },
  };
};

const useStyles = makeStyles((theme) => ({
  formButtons: {
    justifyContent: 'flex-start',
  },
  loader: {
    margin: '100px 0',
  },
}));

function Wizard({
  onSubmit,
  initialValues,
  children,
  tenantId,
  isLoading,
  consents,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const setSubmitValidationFailed = useScrollOnValidation();
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(
    initialValues || {
      businessIndustries: [],
    },
  );

  const activePage: any = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const next = (values: any) => {
    setPage(Math.min(page + 1, children.length - 1));
    setValues(values);
    const wizardScroll = document.getElementById('wizard-scroll');
    wizardScroll?.scrollTo(0, 0);
  };

  const previous = () => {
    setPage((prev) => Math.max(prev - 1, 0));
    const wizardScroll = document.getElementById('wizard-scroll');
    wizardScroll?.scrollTo(0, 0);
  };

  const handleSubmit = async (values: any) => {
    const isLastPage = page === React.Children.count(children) - 1;
    if (page === 0) {
      try {
        await authAPI.checkMentorValidate(getValidValue(values, tenantId));
      } catch (e: any) {
        const messageError = e.response?.data?.message || 'Error';
        enqueueSnackbar(messageError, {
          variant: 'error',
        });

        return;
      }
    }

    if (isLastPage) {
      return onSubmit(values);
    } else {
      next(values);
    }
  };

  const validateForm = (values: FormValues) => {
    const errors: Errors = {};
    switch (page) {
      case 0: {
        if (!values.gender) {
          errors.gender = 'Required';
        }
        if (!values.race) {
          errors.race = 'Required';
        }
        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        }
        if (!validateEmail(values.email)) {
          errors.email = 'Required';
        }
        if (!!values.linkedInProfile && !isValidURL(values.linkedInProfile)) {
          errors.linkedInProfile = 'invalid';
        }
        if (!values.phone) {
          errors.phone = 'Required';
        } else if (!isPhone(values.phone)) {
          errors.phone = 'invalid';
        }
        if (!values.emailConfirm) {
          errors.emailConfirm = 'Required';
        }
        if (values.emailConfirm !== values.email) {
          errors.emailConfirm = 'Required';
        }
        if (!values.businessIndustries || !values.businessIndustries?.length) {
          errors.businessIndustries = 'Required';
        }
        break;
      }
      case 1: {
        if (!values.salesChallenges) {
          errors.salesChallenges = 'Required';
        }
        if (!values.marketingChallenges) {
          errors.marketingChallenges = 'Required';
        }
        if (!values.fundraisingChallenges) {
          errors.fundraisingChallenges = 'Required';
        }
        if (!values.businessChallenges) {
          errors.businessChallenges = 'Required';
        }
        break;
      }
      case 2: {
        if (consents.length) {
          consents.forEach((consent) => {
            if (
              !values[`field-${consent.id}`] ||
              values[`field-${consent.id}`] === 'false'
            ) {
              errors[`field-${consent.id}`] = 'Required';
            }
          });
        }
        break;
      }
    }
    return errors;
  };

  const customQuestionValidationSchema = useMemo(
    () =>
      additionalInfoCustomQuestions?.length
        ? getCustomQuestionsValidationSchema(additionalInfoCustomQuestions)
        : null,
    [additionalInfoCustomQuestions],
  );

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <Form
      initialValues={values}
      onSubmit={handleSubmit}
      validate={
        page === 1 && showCustomAdditionalInfo && customQuestionValidationSchema
          ? yupValidate(customQuestionValidationSchema)
          : validateForm
      }>
      {({ handleSubmit, submitting, submitFailed, dirtySinceLastSubmit }) => {
        setSubmitValidationFailed(
          submitFailed && !dirtySinceLastSubmit && !submitting,
        );
        return (
          <form onSubmit={handleSubmit}>
            {activePage}

            <FormButtons className={classes.formButtons}>
              {page > 0 && (
                <Button
                  data-testid='mentor-wizard-form-btn-prev'
                  type='button'
                  onClick={previous}>
                  Previous
                </Button>
              )}
              {!isLastPage && (
                <Button
                  data-testid='mentor-wizard-form-btn-next'
                  type='button'
                  onClick={handleSubmit}>
                  Next
                </Button>
              )}
              {isLastPage && (
                <Button
                  type='button'
                  onClick={handleSubmit}
                  disabled={submitting}
                  data-testid='mentor-wizard-form-btn-submit'>
                  Submit
                </Button>
              )}
            </FormButtons>
          </form>
        );
      }}
    </Form>
  );
}

export const WizardPage = ({ children }: PropsPage) => children;

export default Wizard;
