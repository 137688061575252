import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

const Link = forwardRef<any, RouterLinkProps>((linkProps, ref) => (
  <RouterLink ref={ref} {...linkProps} />
));

export default Link;
