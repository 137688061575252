import { Actions } from './types/Actions';
import { loadError, ready, State } from './types/State';

export function reducer(s: State, a: Actions): State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading' ? loadError({ message: a.payload }) : s;
    case 'LoadSuccess':
      return s.type === 'Loading' ? ready(a.payload) : s;
  }
}
