import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import cn from 'classnames';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { Text } from '../common';
import { InfoTooltip } from './InfoTooltip';

interface Props {
  onChange: (e: any) => void;
  name: string;
  options: Options[];
  label: string;
  testid?: string;
  readOnly?: boolean;
  value: string;
  invalid?: boolean;
  info?: string;
}

interface Options {
  value: string;
  label: string;
  info?: string;
}

const useStyles = makeStyles({
  title: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  radioboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
  labelInfo: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  invalid: {
    '&, &:hover': {
      color: COLORS.COLOR_RED_BASE,
    },
  },
});

function RadioboxGroup({
  onChange,
  name,
  testid,
  options,
  label,
  value,
  readOnly = false,
  invalid = false,
  info,
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Text variant='normal' className={classes.title}>
        {label}
        {info ? <InfoTooltip message={info} placement={'right-end'} /> : null}
      </Text>
      <RadioGroup
        name={name}
        value={value}
        onChange={(e) => (readOnly ? () => {} : onChange(e))}
        aria-invalid={invalid}
        data-testid={testid}>
        {options.map(({ value, label, info }, idx) => {
          const _label = info ? (
            <span className={classes.labelInfo}>
              {label} <InfoTooltip message={info} placement={'right-end'} />
            </span>
          ) : (
            label
          );

          return (
            <FormControlLabel
              className={classes.radioboxLabel}
              value={value}
              key={`rado-group-label-${idx}`}
              control={
                <Radio
                  className={cn({
                    [classes.invalid]: invalid,
                  })}
                  color='primary'
                  inputProps={{ readOnly: readOnly }}
                  data-testid={`option-${testid}-${value}`}
                />
              }
              label={_label}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
}

export default RadioboxGroup;
