import {
  CommunityMember,
  CommunityMemberId,
} from '../../../../api/CommunityMembers/types/CommunityMember';
import { Status } from '../../../../api/CommunityMembers/types/Status';
import { Email } from '../../../../utils/String/Email';

export interface Item {
  id: CommunityMemberId;
  initials: string;
  name: string;
  linkedIn: string | undefined;
  email: Email;
  status: Status;
  selected: boolean;
}

export function fromCommunityMember(v: CommunityMember): Item {
  return {
    id: v.id,
    email: v.email,
    name: [v.firstName, v.lastName].join(' ').trim() || v.email,
    linkedIn: v.linkedInProfile ?? undefined,
    status: v.status,
    selected: false,
    initials: [v.firstName[0], v.lastName[0]].join('').trim(),
  };
}
