import { createContext, useState, useCallback } from 'react';
import venturesAPI, { VentureTag } from '../api/ventures';

export interface VentureTagContextProps {
  tags?: VentureTag[];
  loadTags: () => any;
}

export const VentureTagContext = createContext<VentureTagContextProps>({
  loadTags: () => {}
});

interface VentureTagProviderProps {
  children: React.ReactNode;
}

export const VentureTagProvider = ({ children }: VentureTagProviderProps) => {
  const [ventureTags, setVentureTags] = useState<VentureTag[]>();

  const loadVentureTags = useCallback(async () => {
    try {
      const loaded = await venturesAPI.loadVentureTags();
      setVentureTags(loaded);
    } catch (e: any) {
      console.log('error loadVentureTags', e);
    }
  }, []);

  const contextValue: VentureTagContextProps = {
    tags: ventureTags,
    loadTags: loadVentureTags
  };

  return (
    <VentureTagContext.Provider value={contextValue}>
      {children}
    </VentureTagContext.Provider>
  );
};
