import { FormItem } from './Forms/types/FormItem';
import { FormButtons, FormGroup } from './form-layout';
import { CircularProgress, Typography } from '@material-ui/core';
import { Button, TextField, Dialog } from './index';
import { TestId } from '../Testing/TestId';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../theme/variables';
import AlertState from './alert-state';

export interface Props {
  title: string;
  error?: string;
  username: FormItem<string>;
  onSubmit: () => void;
  loading: boolean;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: 350,
  },
  title: {
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginBottom: '1rem',
  },
}));

export function ResetPasswordModal({
  error,
  loading,
  onSubmit,
  username,
  onCancel,
  title,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      title={''}
      setOpen={(v) => !v && onCancel()}
      open={true}
      contentRenderer={() => (
        <div className={classes.dialog}>
          <Typography
            variant={'h3'}
            align={'center'}
            color={'textPrimary'}
            className={classes.title}>
            {title}
          </Typography>
          {error && <AlertState type={'error'}>{error}</AlertState>}
          <FormGroup>
            <TestId testId='login-form-email'>
              <TextField
                type='email'
                label='Email'
                error={username.error}
                value={username.value}
                disabled={username.disabled}
                onChange={(e) => username.onChange(e.target.value)}
              />
            </TestId>
          </FormGroup>
          <FormButtons justify={'right'}>
            <Button
              data-testid='login-form-submit'
              disabled={loading}
              onClick={onSubmit}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Reset'
              )}
            </Button>
          </FormButtons>
        </div>
      )}
    />
  );
}
