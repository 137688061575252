import {
  Card,
  Link as MaterialLink,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import eventsAPI, { Event } from '../api/events';
import reportsAPI, { DashboardMentorsReport } from '../api/reports';
import { MentorsDashboardImg1, MentorsDashboardImg2 } from '../assets/images';
import { Link, Text } from '../components/common';
import { InfoTooltip } from '../components/common/InfoTooltip';
import { Inline } from '../components/common/Inline';
import { StepProgress } from '../components/common/StepProgress';
import { UpcomingEvents } from '../components/dashboard/upcoming-events';
import BaseLayout from '../components/layout/base-layout';
import { VenturesProgress } from '../components/mentors/VenturesProgress';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { CLASS_TRACKING } from '../utils/tracking_class';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 28,
    justifyContent: 'stretch',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  card: {
    flex: 1,
    height: 352,
  },
  links: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    columnGap: 8,
  },
  link: {
    display: 'flex',
    justifyContent: 'right',
  },
  firstCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  secondCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 28,
  },
  thirdCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'flex-end',
  },
  cardImage: {
    width: '100%',
  },
  mentorProgress: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 120,
    flex: 1,
  },
  mentorProgressWrapper: {
    flex: 1,
  },
  mentorLevelDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'end',
    alignItems: 'center',
    columnGap: 8,
    '& > span': {
      fontSize: 13,
    },
  },
  mentorLevel: {
    lineHeight: '50px',
  },
  mentorProgressNumber: {
    lineHeight: '1.2rem',
  },
}));

export function MentorDashboardPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const [values, setValues] = useState<DashboardMentorsReport>();
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    reportsAPI
      .getDashboardMentors()
      .catch((): DashboardMentorsReport => {
        return {
          newApplications: 0,
          activelyRecruiting: 0,
          votedFor: 0,
          ventureProgress: [],
          numberOfSessionsAttended: 0,
          leadMentorReportsSubmitted: 0,
          mentorAssessmentsSubmitted: 0,
          mentorLevel: 0,
          mentorScore: 0,
          mentorXp: 0,
        };
      })
      .then((v) => setValues(v));

    eventsAPI.getMentorUpcomingEvents().then((v) => setEvents(v));
  }, []);

  if (values === undefined) {
    return <BaseLayout user={user} isLoading />;
  }

  return (
    <BaseLayout user={user}>
      <div className={classes.cards}>
        <DashboardCard className={classNames(classes.card, classes.firstCard)}>
          <MentorsDashboardImg1 className={classes.cardImage} />
          <Typography variant='subtitle1'>Sign up for new ventures</Typography>
          <div className={classes.links}>
            <StyledLink
              label='New applications'
              to={`${Pages.VENTURES}?mentorFilter=applicants`}
            />
            <Counter count={values.newApplications} />
            <StyledLink
              label='Actively recruiting'
              to={`${Pages.VENTURES}?mentorFilter=recruiting`}
            />
            <Counter count={values.activelyRecruiting} />
            <StyledLink
              label='I showed interest in'
              to={`${Pages.VENTURES}?mentorFilter=interested`}
            />
            <Counter count={values.votedFor} />
          </div>
        </DashboardCard>
        <DashboardCard className={classNames(classes.card, classes.secondCard)}>
          <Typography variant='subtitle1'>
            Progress of my ventures ({values.ventureProgress.length})
          </Typography>
          <VenturesProgress ventures={values.ventureProgress} />
        </DashboardCard>
        <DashboardCard className={classNames(classes.card, classes.thirdCard)}>
          <MentorsDashboardImg2 className={classes.cardImage} />
          <Inline gap={5}>
            <Typography variant='subtitle1'>
              T5 {rb('mentor-u')} level
            </Typography>
            <InfoTooltip
              enterTouchDelay={0}
              leaveTouchDelay={20000}
              placement={'top'}
              message={`You get Traction5 ${rb(
                'mentor',
              )} experience points when the lead ${rb(
                'mentor',
              )} marks your session attendance (20 pts), when you submit your lead ${rb(
                'mentor',
              )} report (10 pts), and when you submit your ${rb(
                'mentor',
              )} assessment (5 pts). Each level stands for 50 points`}
            />
          </Inline>
          <Inline align={'end'} justify={'space-between'} gap={28}>
            <div className={classes.mentorProgress}>
              <Typography
                variant='h1'
                align={'center'}
                className={classes.mentorLevel}>
                {values.mentorLevel}
              </Typography>
              <Tooltip
                title={`xp ${values.mentorXp}`}
                placement={'bottom'}
                enterTouchDelay={0}>
                <div className={classes.mentorProgressWrapper}>
                  <Inline gap={5}>
                    <Typography
                      variant='subtitle1'
                      className={classes.mentorProgressNumber}>
                      {values.mentorLevel}
                    </Typography>
                    <StepProgress
                      steps={10}
                      value={Math.trunc(values.mentorScore / 5)}
                    />
                    <Typography
                      variant='subtitle1'
                      className={classes.mentorProgressNumber}>
                      {values.mentorLevel + 1}
                    </Typography>
                  </Inline>
                </div>
              </Tooltip>
            </div>
            <div className={classes.mentorLevelDetails}>
              <Typography variant={'caption'} align={'right'}>
                Sessions
              </Typography>
              <Typography variant={'caption'}>
                {values.numberOfSessionsAttended}
              </Typography>
              <Typography variant={'caption'}>
                Lead {rb('mentor-u')} Reports
              </Typography>
              <Typography variant={'caption'}>
                {values.leadMentorReportsSubmitted}
              </Typography>
              <Typography variant={'caption'}>
                {rb('mentor-u')} Assessments
              </Typography>
              <Typography variant={'caption'}>
                {values.mentorAssessmentsSubmitted}
              </Typography>
            </div>
          </Inline>
        </DashboardCard>
      </div>
      {events.length ? (
        <UpcomingEvents events={events} title={'My Upcoming Sessions'} />
      ) : null}
    </BaseLayout>
  );
}

function DashboardCard({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  const { card } = useStyles();
  return <Card className={classNames(card, className)}>{children}</Card>;
}

interface StyledLinkProps {
  to: string;
  label: string;
}
function StyledLink({ label, to }: StyledLinkProps) {
  const { link } = useStyles();
  return (
    <MaterialLink
      className={classNames(CLASS_TRACKING.DASHBOARD_LINK, link)}
      variant='caption'
      underline='none'
      component={Link}
      to={to}>
      {label}
    </MaterialLink>
  );
}
function Counter({ count }: { count: number }) {
  return <Text variant='normal2'>{count}</Text>;
}
