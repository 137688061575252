import { addSeconds, format, max, min } from 'date-fns';
import React, { Dispatch, ReactElement } from 'react';
import { Availability } from '../../../../../components/Advisors/Dialogs/Availability';
import { Booked } from '../../../../../components/Advisors/Dialogs/Booked';
import { BookedWithPayment } from '../../../../../components/Advisors/Dialogs/BookedWithPayment';
import { Day } from '../../../../../components/Advisors/Dialogs/Day';
import { Details } from '../../../../../components/Advisors/Dialogs/Details';
import { Time } from '../../../../../components/Advisors/Dialogs/Time';
import { FormItem } from '../../../../../components/common/Forms/types/FormItem';
import { LoginModal } from '../../../../../components/common/LoginModal';
import { ResetPasswordModal } from '../../../../../components/common/ResetPasswordModal';
import { getRoutePath, Pages } from '../../../../../router/constants';
import { getError, isInvalid } from '../../../../../utils/FormValue';
import { shouldDisableDate } from '../../Profile/utils';
import * as Actions from './types/Actions';
import * as State from './types/State';

export interface Props {
  state: State.State;
  dispatch: Dispatch<Actions.Actions>;
}

export interface BookEmailFormItems {
  email: FormItem<string>;
  confirmEmail: FormItem<string>;
}

export function Book({ state, dispatch }: Props): ReactElement | null {
  const confirmation = ((): ReactElement | null => {
    switch (state.type) {
      case 'SavingLoggedIn':
      case 'SavingGuest':
      case 'BookingLoading':
      case 'BookingLoadingError':
      case 'BookingGuestDetails':
      case 'BookingLoggedInDetails':
      case 'BookingTime':
      case 'ProvidingAvailability':
      case 'BookingDay':
      case 'SaveErrorLoggedIn':
      case 'SaveErrorGuest':
      case 'SaveSuccess':
      case 'SaveSuccessWithPayment':
      case 'Idle':
      case 'LogIn':
      case 'LoggingIn':
      case 'LoginError':
      case 'ResetError':
      case 'ResetPassword':
      case 'Resetting':
      case 'CancelingPayment':
        return null;
    }
  })();
  const dialog = (() => {
    switch (state.type) {
      case 'ProvidingAvailability': {
        return (
          <Availability
            open={true}
            firstName={state.payload.advisor.firstName}
            lastName={state.payload.advisor.lastName}
            avatar={state.payload.advisor.avatar ?? undefined}
            rate={state.payload.advisor.rate}
            availability={state.payload.availability}
            onClose={() => dispatch(Actions.cancel())}
            onSubmit={(v) => dispatch(Actions.setAvailability(v))}
            onBack={() => dispatch(Actions.back())}
          />
        );
      }
      case 'BookingDay': {
        const minDate = min(state.payload.timeslots.map(({ start }) => start));
        const maxDate = max(state.payload.timeslots.map(({ end }) => end));
        return (
          <Day
            shouldDisableDate={shouldDisableDate(state.payload.timeslots)}
            value={state.payload.day ?? addSeconds(minDate, 1)}
            open={true}
            min={minDate}
            max={maxDate}
            onClose={() => dispatch(Actions.cancel())}
            onChange={(v) => dispatch(Actions.bookDay(v))}
            onAvailability={() => dispatch(Actions.chooseAvailability())}
          />
        );
      }
      case 'BookingTime': {
        const hours = state.payload.hours.map((v) =>
          format(v.start, 'hh:mm a'),
        );

        return (
          <Time
            open={true}
            onClose={() => dispatch(Actions.cancel())}
            onChange={(v) => dispatch(Actions.bookTime(v))}
            onAvailability={() => dispatch(Actions.chooseAvailability())}
            onBack={() => dispatch(Actions.back())}
            value={
              state.payload.start
                ? format(state.payload.start, 'hh:mm a')
                : undefined
            }
            hours={hours}
            firstName={state.payload.advisor.firstName}
            lastName={state.payload.advisor.lastName}
            avatar={state.payload.advisor.avatar ?? undefined}
            date={state.payload.day}
            rate={state.payload.advisor.rate}
          />
        );
      }
      case 'BookingLoggedInDetails':
      case 'BookingGuestDetails':
      case 'SavingLoggedIn':
      case 'SavingGuest':
      case 'SaveErrorLoggedIn':
      case 'SaveErrorGuest': {
        const getName = (): FormItem<string> | undefined => {
          switch (state.type) {
            case 'SaveErrorLoggedIn':
            case 'SavingLoggedIn':
            case 'BookingLoggedInDetails':
              return undefined;
            case 'BookingGuestDetails':
            case 'SavingGuest':
            case 'SaveErrorGuest':
              return {
                value: state.payload.name.value,
                onChange: (value) =>
                  dispatch(Actions.setValue({ key: 'name', value })),
                error: !!getError(state.payload.name),
                errorText: getError(state.payload.name),
                onBlur: () => dispatch(Actions.toggle('name')),
              };
          }
        };
        const getEmail = (): BookEmailFormItems | undefined => {
          switch (state.type) {
            case 'SaveErrorLoggedIn':
            case 'SavingLoggedIn':
            case 'BookingLoggedInDetails':
              return undefined;
            case 'BookingGuestDetails':
            case 'SavingGuest':
            case 'SaveErrorGuest':
              return {
                email: {
                  value: state.payload.email.value,
                  onChange: (value) =>
                    dispatch(Actions.setValue({ key: 'email', value })),
                  error: !!getError(state.payload.email),
                  errorText: getError(state.payload.email),
                  onBlur: () => dispatch(Actions.toggle('email')),
                },
                confirmEmail: {
                  value: state.payload.confirmEmail.value,
                  onChange: (value) =>
                    dispatch(Actions.setValue({ key: 'confirmEmail', value })),
                  error: !!getError(state.payload.confirmEmail),
                  errorText: getError(state.payload.confirmEmail),
                  onBlur: () => dispatch(Actions.toggle('confirmEmail')),
                },
              };
          }
        };
        const date = 'start' in state.payload ? state.payload.start : undefined;
        const time =
          'start' in state.payload && state.payload.start && state.payload.end
            ? ([state.payload.start, state.payload.end] as [Date, Date])
            : undefined;
        return (
          <Details
            open={true}
            onClose={() => dispatch(Actions.cancel())}
            onBack={() => dispatch(Actions.back())}
            onSubmit={() => dispatch(Actions.submit())}
            firstName={state.payload.advisor.firstName}
            lastName={state.payload.advisor.lastName}
            avatar={state.payload.advisor.avatar ?? undefined}
            date={date}
            time={time}
            name={getName()}
            email={getEmail()}
            topic={{
              value: state.payload.inputs.topic,
              onChange: (value) =>
                dispatch(Actions.setValue({ key: 'topic', value })),
              error: !!getError(state.payload.topic),
              errorText: getError(state.payload.topic),
              onBlur: () => dispatch(Actions.toggle('topic')),
            }}
            agenda={{
              value: state.payload.inputs.agenda,
              onChange: (value) =>
                dispatch(Actions.setValue({ key: 'agenda', value })),
              error: !!getError(state.payload.agenda),
              errorText: getError(state.payload.agenda),
              onBlur: () => dispatch(Actions.toggle('agenda')),
            }}
            terms={{
              value: state.payload.terms.value,
              onChange: (value) =>
                dispatch(Actions.setValue({ key: 'terms', value })),
              error: !!getError(state.payload.terms),
              errorText: getError(state.payload.terms),
              onBlur: () => dispatch(Actions.toggle('terms')),
            }}
            saving={State.isSaving(state)}
          />
        );
      }
      case 'SaveSuccess':
        return (
          <Booked open={true} onClose={() => dispatch(Actions.cancel())} />
        );
      case 'SaveSuccessWithPayment':
        return (
          <BookedWithPayment
            open={true}
            onPay={() => {
              dispatch(Actions.cancel());
              window.open(state.payload.appointment.paymentUrl, '_self');
            }}
            onCancel={() => {
              dispatch(Actions.cancelPayment());
            }}
          />
        );
      case 'Idle':
      case 'BookingLoading':
      case 'BookingLoadingError':
        return null;
      case 'LogIn':
      case 'LoggingIn':
      case 'LoginError':
        return (
          <LoginModal
            title='Office Hours Login'
            error={
              state.type === 'LoginError' ? state.payload.message : undefined
            }
            loading={state.type === 'LoggingIn'}
            signUpUrl={getRoutePath(Pages.OH_COMMUNITY_MEMBER_SIGN_UP, {
              tenantId: state.payload.tenantId,
            })}
            onCancel={() => dispatch(Actions.cancel())}
            username={{
              value: state.payload.username.value ?? '',
              onChange: (v) => dispatch(Actions.setName(v)),
              error: isInvalid(state.payload.username),
              onBlur: () => {},
              disabled: state.type === 'LoggingIn',
            }}
            password={{
              value: state.payload.password.value ?? '',
              onChange: (v) => dispatch(Actions.setPass(v)),
              error: isInvalid(state.payload.password),
              disabled: state.type === 'LoggingIn',
              onBlur: () => {},
            }}
            showPassword={state.payload.showPassword}
            onTogglePassword={() => dispatch(Actions.togglePassword())}
            onSubmit={() => dispatch(Actions.login())}
            onReset={() => dispatch(Actions.goToReset())}
          />
        );
      case 'Resetting':
      case 'ResetPassword':
      case 'ResetError':
        return (
          <ResetPasswordModal
            title='Office Hours Login'
            error={
              state.type === 'ResetError' ? state.payload.message : undefined
            }
            username={{
              value: state.payload.username.value ?? '',
              onChange: (v) => dispatch(Actions.setName(v)),
              error: isInvalid(state.payload.username),
              onBlur: () => {},
              disabled: state.type === 'Resetting',
            }}
            onSubmit={() => dispatch(Actions.resetPassword())}
            loading={state.type === 'Resetting'}
            onCancel={() => dispatch(Actions.cancel())}
          />
        );
    }
  })();

  return (
    <>
      {dialog}
      {confirmation}
    </>
  );
}
