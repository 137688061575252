import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement, useState } from 'react';
import eventsAPI, { Event, EventDetails } from '../../api/events';
import { massAsyncRequest } from '../../utils/api';
import { Button, FormButtons, Select } from '../common';

interface AssignFoundersFormProps {
  onCancel: () => any;
  selectedSessionsIds: Event['id'][];
  sessions: Event[];
  updateSession: () => any;
}

interface StatusProps {
  value: string;
  label: string | ReactElement;
}

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

const status = [
  {
    value: 'CREATED',
    label: 'Created',
  },
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
  {
    value: 'ARCHIVED',
    label: 'Archived',
  },
];

const validationSession = (session: Event, status: string): EventDetails => {
  return {
    description: session.description,
    end: session.end,
    followupReminder: session.followupReminder,
    id: session.id,
    summary: session.summary,
    start: session.start,
    status,
    tenantEventChannelId: session.tenantEventChannelId,
    ventureId: session.ventureId,
    ventureName: session.ventureName,
    emailList: session.emailList,
    physicalLocation: session.physicalLocation,
  };
};

function SessionChangeStatus({
  onCancel,
  selectedSessionsIds,
  sessions,
  updateSession,
}: AssignFoundersFormProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectStatus, setSelectStatus] = useState<StatusProps>(status[0]);

  const handleSubmit = () => {
    setLoading(true);

    let changeStatus;
    if (selectStatus.value !== 'ARCHIVED') {
      changeStatus = selectedSessionsIds.map((sessionId) => {
        const currentSession = sessions.find(
          ({ id }) => id === sessionId,
        ) as Event;
        const currentSessionValid = validationSession(
          currentSession,
          selectStatus.value,
        );
        return () => eventsAPI.update(sessionId, currentSessionValid);
      });
    } else {
      changeStatus = [
        () => eventsAPI.postArchivingSessions(selectedSessionsIds),
      ];
    }

    massAsyncRequest(changeStatus);
    const timeUpdateTable = setTimeout(() => {
      setLoading(false);
      onCancel();
      updateSession();
    }, 500);

    return () => {
      clearTimeout(timeUpdateTable);
    };
  };

  return (
    <div>
      <div data-testid='select-change-status'>
        <Select
          value={selectStatus.value}
          options={status}
          onSelect={(value) => {
            if (value) setSelectStatus(value);
          }}
        />
      </div>
      <FormButtons className={classes.formButtons}>
        <Button onClick={onCancel} variant='outlined' data-testid='form-cancel'>
          Cancel
        </Button>
        <Button
          disabled={loading}
          type='submit'
          onClick={handleSubmit}
          data-testid='form-apply'>
          {loading ? <CircularProgress size={24} color='inherit' /> : 'Yes'}
        </Button>
      </FormButtons>
    </div>
  );
}

export default SessionChangeStatus;
