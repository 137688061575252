import { Dispatch, memo, ReactElement } from 'react';
import { unreachableError } from '../../../../utils/unreachableError';
import {
  Actions,
  cancelEdit,
  edit,
  remove,
  save,
  setDescription,
  setName,
} from '../types/Actions';
import {
  ExistingCancelEditingConfirm,
  ExistingEditing,
  ExistingRemoveConfirm,
  ExistingRemoving,
  ExistingSaving,
  ExistingView,
} from '../types/ExistingItemState';
import { isExistingItemState, Item as ItemType } from '../types/Item';
import {
  NewItemCancelEditingConfirm,
  NewItemEditing,
  NewItemSaving,
} from '../types/NewItemState';
import { EditItem } from './EditItem';
import { ViewItem } from './ViewItem';

export interface ItemProps {
  item: ItemType;
  dispatch: Dispatch<Actions>;
}

export const Item = memo(
  ({ item, dispatch }: ItemProps): ReactElement | null => {
    if (isView(item))
      return (
        <ViewItem
          title={item.title}
          description={item.description || ''}
          onEdit={() => dispatch(edit(item.id))}
          onDelete={() => dispatch(remove(item.id))}
          id={item.id}
        />
      );

    if (isEditing(item))
      return (
        <EditItem
          isNew={!isExistingItemState(item)}
          title={item.title}
          description={item.description}
          onTitleChange={(name) => dispatch(setName({ id: item.id, name }))}
          onDescriptionChange={(description) =>
            dispatch(setDescription({ id: item.id, description }))
          }
          isLoading={isLoading(item)}
          onSave={() => dispatch(save(item.id))}
          onCancel={() => dispatch(cancelEdit(item.id))}
        />
      );

    unreachableError(item);
  },
);

const isView = (
  item: ItemType,
): item is ExistingView | ExistingRemoveConfirm | ExistingRemoving => {
  return (
    item instanceof ExistingView ||
    item instanceof ExistingRemoveConfirm ||
    item instanceof ExistingRemoving
  );
};

const isEditing = (
  item: ItemType,
): item is
  | ExistingEditing
  | ExistingSaving
  | ExistingCancelEditingConfirm
  | NewItemEditing
  | NewItemSaving
  | NewItemCancelEditingConfirm => {
  return (
    item instanceof ExistingEditing ||
    item instanceof ExistingSaving ||
    item instanceof ExistingCancelEditingConfirm ||
    item instanceof NewItemEditing ||
    item instanceof NewItemSaving ||
    item instanceof NewItemCancelEditingConfirm
  );
};

const isLoading = (item: ItemType): item is NewItemSaving | ExistingSaving => {
  return item instanceof NewItemSaving || item instanceof ExistingSaving;
};
