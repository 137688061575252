import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { Goal } from '../../api/types/Goal';
import { VentureId } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { getFormattedGeneralReportDate } from '../../utils/date';
import { StringDate } from '../../utils/date/StringDate';
import { checkSizeTablet } from '../../utils/functions';
import { removeFalsyValues } from '../../utils/object';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { length } from '../../utils/wysiwyg';
import { yupValidate } from '../../utils/yup';
import { BeforeUnload, Button, FormGroup, StickyContent } from '../common';
import {
  FormDateInput,
  FormSelect,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from './wrappers';

export interface GoalOverviewFormValues {
  title: string;
  ventureId: VentureId;
  startDate: StringDate | null;
  targetDate: StringDate | null;
  description?: string;
}

interface GoalOverviewProps {
  loading: boolean;
  ventureOptions: { value: VentureId; label: string }[];
  handleSubmit: (values: GoalOverviewFormValues) => Promise<void>;
  initialData?: Partial<Goal> | null;
}

const useStyles = makeStyles((theme) => ({
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
}));

const validationSchema = yup.object().shape({
  title: yup.string().max(250).required(),
  ventureId: yup.string().required(),
  startDate: yup.date().nullable(),
  targetDate: yup.date().required(),
  description: yup.string().test('max', 'Max 2048 characters', (value) => {
    return !!value && length(value) <= 2048;
  }),
});

const getInitialData = (
  goal?: Partial<Goal> | null,
): GoalOverviewFormValues => {
  const nonFiltredInitialData: GoalOverviewFormValues = {
    title: goal?.title || '',
    ventureId: (goal?.ventureId || null) as VentureId,
    startDate: goal?.startDate || null,
    targetDate: goal?.targetDate || null,
    description: goal?.description || '',
  };

  return removeFalsyValues(nonFiltredInitialData);
};

function GoalOverviewForm({
  initialData,
  ventureOptions,
  loading,
  handleSubmit,
}: GoalOverviewProps) {
  const { hasAccessToAction } = useContext(UserContext);
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();

  return (
    <Form
      validate={yupValidate(validationSchema)}
      onSubmit={handleSubmit}
      initialValues={getInitialData(initialData)}
      keepDirtyOnReinitialize
      render={(formProps) => {
        setSubmitValidationFailed(
          formProps.submitFailed &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting,
        );
        return (
          <div>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <form
              noValidate
              data-testid='goal-overview-form'
              className={classes.mainFormBlock}>
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  name='title'
                  data-testid='goal-overview-title'
                  component={TextFieldWrapper}
                  label='Title*'
                  InputProps={{
                    inputProps: {
                      maxLength: 250,
                      readOnly: !hasAccessToAction('goal.overview.update'),
                    },
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Field<VentureId>
                  testid='goal-overview-venture'
                  name='ventureId'
                  component={FormSelect}
                  label='Venture*'
                  options={ventureOptions}
                  readOnly={!hasAccessToAction('goal.overview.update')}
                />
              </FormGroup>
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  testid='goal-overview-start-date'
                  name='startDate'
                  component={FormDateInput}
                  label='Start Date'
                  editable
                  parse={(value) => {
                    try {
                      if (value) {
                        return getFormattedGeneralReportDate(value);
                      }
                      return '';
                    } catch (e: any) {
                      return 'invalid';
                    }
                  }}
                  isReadOnly={!hasAccessToAction('goal.overview.update')}
                />
                <Field<string>
                  testid='goal-overview-target-date'
                  name='targetDate'
                  component={FormDateInput}
                  label='Target Date*'
                  editable
                  parse={(value) => {
                    try {
                      if (value) {
                        return getFormattedGeneralReportDate(value);
                      }
                      return '';
                    } catch (e: any) {
                      return 'invalid';
                    }
                  }}
                  isReadOnly={!hasAccessToAction('goal.overview.update')}
                />
              </FormGroup>
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  name='description'
                  component={TextFieldWysiwyg}
                  placeholder='Description'
                  testid='goal-overview-description'
                  maxLength={2048}
                  showCount
                />
              </FormGroup>
              {hasAccessToAction('goal.overview.update') && (
                <div className={classes.actionsBlock}>
                  <StickyContent>
                    <Button
                      data-testid='goal-overview-button-submit'
                      className={CLASS_TRACKING.INTERNAL_ACTION}
                      onClick={formProps.handleSubmit}
                      disabled={loading}
                      startIcon={<CheckIcon />}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </StickyContent>
                </div>
              )}
            </form>
          </div>
        );
      }}
    />
  );
}

export default GoalOverviewForm;
