import { addDays, format } from 'date-fns';
import { TenantId } from '../../api/tenants/Tenant';
import { Venture, VentureId } from '../../api/ventures/types/Venture';
import { VenturesAdditionalInfoId } from '../../api/ventures/types/VenturesAdditionalInfo';
import { Name } from '../../types';
import { Sentence } from '../../utils/String/Sentence';
import {
  dateToPastStrDate,
  PastStringDate,
} from '../../utils/date/PastStringDate';

export const VENTURES_LIST = (tenantId: TenantId): Venture[] =>
  Array.from(Array(10).keys()).map(
    (i): Venture => ({
      id: `${tenantId}-${i}` as VentureId,
      ventureNotes: [],
      ventureRecords: [],
      status: 'Active',
      assigned: 0,
      ventureName: 'Venture 1name' as Name,
      url: 'https://localhost:3001/' as Sentence<1024>,
      dateOfEnrollment: dateToPastStrDate(
        addDays(new Date(), -10),
      ) as PastStringDate,
      dateOfCreation: dateToPastStrDate(
        addDays(new Date(), -10),
      ) as PastStringDate,
      deactivationDate: 'date',
      country: 'Russia' as Sentence<1024>,
      state: 'Rostov' as Sentence<1024>,
      city: 'City' as Sentence<1024>,
      description: 'description' as Sentence<250>,
      isTech: true,
      tenantId,
      valid: true,
      primaryFounder: '',
      address: null,
      zip: null,
      numOfEmployees: 1,
      dateOfExit: format(new Date(2022, 4, 22), 'yyyy/MM/dd'),
      exitReason: "Don't know the reason",
      mentorsVoted: null,
      venturesAdditionalInfo: {
        id: `${tenantId}-${i}-additional-ifo` as VenturesAdditionalInfoId,
        creationDate: format(new Date(2022, 4, 22), 'yyyy/MM/dd'),
        ventureId: `${tenantId}-${i}` as VentureId,
        tenantId,
        businessDescription: 'desc' as Sentence<4096>,
        currentProgress: 'string' as Sentence<1024>,
        requiredSupport: 'string' as Sentence<1024>,
        marketSize: 'string' as Sentence<256>,
        reasonToStartNow: 'string' as Sentence<4096>,
        plans: 'string' as Sentence<4096>,
        growthRatio: 'string' as Sentence<256>,
        dedication: 'string' as Sentence<4096>,
        yearPlans: 'string' as Sentence<4096>,
        obstacles: 'string' as Sentence<4096>,
        mentorshipPlans: 'string' as Sentence<4096>,
        comments: 'string' as Sentence<4096>,
        isLegallyRegistered: true,
        howDidYouFind: 'string' as Sentence<4096>,
      },
      tags: ['a', 'b', 'c'],
      cohortId: null,
    }),
  );
