import {
  TextField,
  makeStyles,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Radio } from '@material-ui/core';
import cn from 'classnames';
import { add, getDate, format } from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import {
  Button,
  Text,
  Dialog,
  CustomSelect,
  FormButtons,
  DateInput,
} from '../common';

export interface FormValue {
  numberRepetitions: number;
  typeRepetitions: string;
  week: string;
  ends: string;
  periodMonths: string;
  endsDate: Date | string | null;
  endsCount: number;
  dayOfMonth: number;
  numberWeek: number;
}
interface Props {
  selectDate?: Date | string;
  handleSubmit?: (value: FormValue) => void;
  isButton?: boolean;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

interface RepeatParameters {
  count: number;
  date: Date | null;
}

const useStyles = makeStyles({
  inputNumber: {
    padding: '7px 10px 10px !important',
    width: '39px !important',
  },
  selectTypeRepetitions: {
    '& > div': {
      padding: '9px !important',
    },
  },
  selectPeriodMonths: {
    marginTop: 10,
    '& > div': {
      padding: '9px 30px 9px 9px !important',
    },
  },
  containerRepeatEvery: {
    display: 'flex',
    columnGap: 25,
    alignItems: 'center',
  },
  containerRepeatOn: {
    marginTop: 15,
  },
  itemWeek: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
    borderRadius: 13,
    cursor: 'pointer',
    '&> span': {
      color: COLORS.COLOR_GRAY_DARKENED_15,
      fontSize: 12,
    },

    '&:hover': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_40,
    },
  },
  listWeek: {
    display: 'flex',
    columnGap: 10,
    marginTop: 10,
  },
  activeItemWeek: {
    backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_10,
    '&> span': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_25,
    },
  },
  radioboxWrapper: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
  },
  dateInput: {
    marginLeft: 15,

    '& > div > input': {
      maxWidth: 80,
      padding: '12.5px 13px',
    },
  },
  radioGroup: {
    rowGap: 10,
    '& span': {
      fontSize: 14,
      fontFamily: INTER_FONT_FAMILY,
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
});

const getInitialWeek = (date: string | Date) => {
  let week = '';
  const numberDayOfWeek = new Date(date).getDay();
  switch (numberDayOfWeek) {
    case 1:
      week = 'mon';
      break;
    case 2:
      week = 'tue';
      break;
    case 3:
      week = 'wen';
      break;
    case 4:
      week = 'thu';
      break;
    case 5:
      week = 'fri';
      break;
    case 6:
      week = 'sat';
      break;
    default:
      week = 'sun';
      break;
  }

  return week;
};

const paramsInitialDate = {
  days: {
    months: 1,
    days: -1,
    count: 30,
  },
  weeks: {
    months: 3,
    days: -1,
    count: 13,
  },
  months: {
    years: 1,
    count: 12,
  },
  years: {
    years: 5,
    count: 5,
  },
};

const optionType = [
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
  // { label: 'years', value: 'years' }
];

const optionWeek = [
  { label: 'Sun', value: 'sun' },
  { label: 'Mon', value: 'mon' },
  { label: 'Tue', value: 'tue' },
  { label: 'Wen', value: 'wen' },
  { label: 'Thu', value: 'thu' },
  { label: 'Fri', value: 'fri' },
  { label: 'Sat', value: 'sat' },
];

const arrayOrdinalNumbers = ['first', 'second', 'third', 'fourth', 'fifth'];

const ButtonRepeatEvent = ({
  selectDate: _selectDate,
  handleSubmit,
  isButton = true,
  isOpen = false,
  setIsOpen = () => {},
}: Props) => {
  const classes = useStyles();
  const selectDate = useMemo(
    () => (!_selectDate ? new Date() : _selectDate),
    [_selectDate],
  );
  const currentDay = getDate(new Date(selectDate));
  const numberWeek = Math.ceil(currentDay / 7);
  const numberWeekString = arrayOrdinalNumbers[numberWeek - 1];

  const additionalOptionsMonths = useMemo(() => {
    return [
      {
        label: `Monthly on day ${currentDay}`,
        value: `MD${currentDay}`,
      },
      {
        label: `Monthly on the ${numberWeekString} ${format(
          new Date(selectDate),
          'eeee',
        )}`,
        value: `M${numberWeek}${format(new Date(selectDate), 'eee')}`,
      },
    ];
  }, [currentDay, numberWeekString, numberWeek, selectDate]);

  const [open, setOpen] = useState(false);
  const [valueForm, setValueForm] = useState({
    numberRepetitions: 1,
    typeRepetitions: optionType[0].value,
    week: getInitialWeek(selectDate),
    ends: 'after100',
  });
  const [repeatParameters, setRepeatParameters] = useState<RepeatParameters>({
    count: 1,
    date: new Date(),
  });

  const [periodMonths, setPeriodMonths] = useState<string>(
    additionalOptionsMonths[0].value,
  );

  const handleSave = () => {
    let endsCount = 0;
    if (valueForm.ends === 'after') {
      endsCount = repeatParameters.count;
    } else if (valueForm.ends === 'after100') {
      endsCount = 100;
    }
    const res = {
      ...valueForm,
      numberRepetitions:
        valueForm.typeRepetitions !== 'months'
          ? valueForm.numberRepetitions
          : 0,
      week:
        valueForm.typeRepetitions === 'weeks' ||
        (valueForm.typeRepetitions === 'months' &&
          additionalOptionsMonths[1].value === periodMonths)
          ? valueForm.week
          : '',
      periodMonths: valueForm.typeRepetitions === 'months' ? periodMonths : '',
      endsDate: valueForm.ends === 'on' ? repeatParameters.date : '',
      endsCount: endsCount,
      dayOfMonth:
        valueForm.typeRepetitions === 'months' &&
        additionalOptionsMonths[0].value === periodMonths
          ? currentDay
          : 0,
      numberWeek:
        valueForm.typeRepetitions === 'months' &&
        additionalOptionsMonths[1].value === periodMonths
          ? numberWeek
          : 0,
    };
    if (isOpen) {
      setIsOpen(false);
    }

    if (typeof handleSubmit === 'function') {
      handleSubmit(res);
    }
  };

  const handleChange = (field: string, value: any) => {
    setValueForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleCloseModal = () => {
    setOpen(false);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (selectDate && !valueForm.week) {
      handleChange('week', getInitialWeek(selectDate));
    }
  }, [selectDate, valueForm.week]);

  useEffect(() => {
    handleChange('week', getInitialWeek(selectDate));
    setPeriodMonths(additionalOptionsMonths[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  return (
    <>
      <Dialog
        open={open || isOpen}
        setOpen={handleCloseModal}
        title='Custom recurrence'
        width={400}
        contentRenderer={({ handleClose }) => (
          <div>
            <div className={classes.containerRepeatEvery}>
              <div>
                <Text variant='normal'>Repeat every</Text>
              </div>
              <TextField
                name='numberRepetitions'
                value={valueForm.numberRepetitions}
                disabled={valueForm.typeRepetitions === 'months'}
                type='number'
                variant='outlined'
                onChange={(e) =>
                  handleChange('numberRepetitions', e.target.value)
                }
                InputProps={{
                  inputProps: {
                    className: classes.inputNumber,
                    min: 0,
                    max: 100,
                  },
                }}
              />
              <CustomSelect
                value={valueForm.typeRepetitions}
                options={optionType}
                onChange={(e) => {
                  const value =
                    (e.target.value as 'days') ||
                    'weeks' ||
                    'months' ||
                    'years';
                  handleChange('typeRepetitions', e.target.value);
                  handleChange('numberRepetitions', 1);
                  const resData = add(new Date(), paramsInitialDate?.[value]);
                  setRepeatParameters({
                    count: paramsInitialDate?.[value].count,
                    date: resData,
                  });
                }}
                className={classes.selectTypeRepetitions}
              />
            </div>
            {valueForm.typeRepetitions === 'months' && (
              <div>
                <CustomSelect
                  value={periodMonths}
                  options={additionalOptionsMonths}
                  onChange={(e) => {
                    setPeriodMonths(e.target.value);
                  }}
                  className={classes.selectPeriodMonths}
                />
              </div>
            )}

            {valueForm.typeRepetitions === 'weeks' && (
              <div className={classes.containerRepeatOn}>
                <div>
                  <Text variant='normal'>Repeat on</Text>
                </div>
                <div className={classes.listWeek}>
                  {optionWeek.map((option) => {
                    return (
                      <div
                        className={cn(classes.itemWeek, {
                          [classes.activeItemWeek]: valueForm.week.includes(
                            option.value,
                          ),
                        })}
                        onClick={() => handleChange('week', option.value)}>
                        <Text variant='normal'>{option.label}</Text>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className={classes.containerRepeatOn}>
              <div>
                <Text variant='normal'>Ends</Text>
              </div>

              <RadioGroup
                className={classes.radioGroup}
                aria-label='quiz'
                name='quiz'
                value={valueForm.ends}
                onChange={(e) => {
                  handleChange('ends', e.target.value);
                }}>
                <FormControlLabel
                  value='after100'
                  control={<Radio color='primary' />}
                  label='After 100 occurrences'
                />
                <div className={classes.radioboxWrapper}>
                  <FormControlLabel
                    value='on'
                    control={<Radio color='primary' />}
                    label='On'
                  />
                  <DateInput
                    disabled={valueForm.ends !== 'on'}
                    className={classes.dateInput}
                    editable
                    value={repeatParameters.date}
                    onChange={(value) => {
                      setRepeatParameters({ count: 0, date: value });
                    }}
                    minDate={new Date()}
                    maxDate={add(new Date(), { years: 3 })}
                  />
                </div>
                <div className={classes.radioboxWrapper}>
                  <FormControlLabel
                    value='after'
                    control={<Radio color='primary' />}
                    label='After'
                  />
                  <TextField
                    name='repeatCount'
                    value={repeatParameters.count}
                    type='number'
                    variant='outlined'
                    disabled={valueForm.ends !== 'after'}
                    onChange={(e) => {
                      setRepeatParameters({
                        date: null,
                        count: +e.target.value,
                      });
                    }}
                    InputProps={{
                      inputProps: {
                        className: classes.inputNumber,
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                  <Text variant='normal'>occurrences</Text>
                </div>
              </RadioGroup>
            </div>

            <FormButtons className={classes.formButtons}>
              <Button data-testid='button-submit-form' onClick={handleSave}>
                Save
              </Button>
              <Button variant='outlined' onClick={handleCloseModal}>
                Cancel
              </Button>
            </FormButtons>
          </div>
        )}
      />
      {isButton && (
        <Button onClick={() => setOpen(true)} disabled={!selectDate}>
          Repeat the event
        </Button>
      )}
    </>
  );
};

export default ButtonRepeatEvent;
