import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getActiveCohorts } from '../../api/Cohort';
import { CohortId } from '../../api/Cohort/types/Cohort';
import venturesAPI from '../../api/ventures';
import { SnackMessage } from '../../components/common';
import VentureDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/venture-details';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/ventures/page-tabs';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
});

function NewVenturePage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [cohorts, setCohorts] = useState<
    Array<{ value: CohortId; label: string }>
  >([]);

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { values, specializations } = parsedValues;
        setIsCreating(true);
        const createdVenture = await venturesAPI.create(values);
        const setSpecializationRequests = specializations.map(
          (specializationId) => () =>
            venturesAPI.setVentureSpecialization(
              createdVenture.id,
              specializationId,
            ),
        );
        await massAsyncRequest(setSpecializationRequests);
        history.push(
          getRoutePath(Pages.VENTURE_DETAILS, { ventureId: createdVenture.id }),
        );
        enqueueSnackbar('The venture was successfully created', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the venture. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [history, enqueueSnackbar],
  );

  useEffect(() => {
    getActiveCohorts().then((cohorts) =>
      setCohorts(cohorts.map((c) => ({ value: c.id, label: c.name }))),
    );
  }, []);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='New Venture'
        backLink={
          <Tooltip title='Back to Ventures'>
            <IconButton
              component={Link}
              to={Pages.ACTIVE_VENTURES}
              data-testid='button-back-ventures'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Paper className={classes.container} elevation={1}>
        <PageTabs />
        <div className={classes.content}>
          <VentureDetailsForm
            loading={isCreating}
            onSubmit={handleSubmitForm}
            cohorts={cohorts}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default NewVenturePage;
