import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { PageLoader } from '../../components/common';
import LinksScreen from '../../components/sessions/links-screen';
import { SessionContext } from '../../contexts/session-context';

const useStyles = makeStyles({
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function SessionLinksPage() {
  const classes = useStyles();
  const { sessionKey } = useContext(SessionContext);

  if (!sessionKey) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return <LinksScreen linkKey={sessionKey} />;
}

export default SessionLinksPage;
