import { makeStyles } from '@material-ui/core';
import { Text } from '../../common';
import { Add } from '../../common/Buttons/Add';
import React from 'react';
import { capitalize } from 'lodash';

const useEmptyStyles = makeStyles(() => {
  return {
    root: {
      display: 'grid',
      gap: '20px',
      textAlign: 'center',
    },
  };
});

export interface Props {
  singular: string;
  plural: string;
  to?: string;
}

export function EmptyState({ singular, plural, to }: Props) {
  const classes = useEmptyStyles();
  return (
    <div className={classes.root}>
      <Text variant='normal'>
        This panel contains a list of {plural.toLowerCase()}.
        <br />
        We were not able to find information you requested,
        <br /> but feel free to add something new!
      </Text>
      {to ? (
        <div>
          <Add title={capitalize(singular)} to={to} />
        </div>
      ) : null}
    </div>
  );
}
