import { Log } from '../../../../api/CommunityMembers/types/Log';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Log[];
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Reload
export interface Reload {
  type: 'Reload';
}

export const reload = (): Reload => ({
  type: 'Reload',
});
// endregion

export type Actions = LoadError | LoadSuccess | Reload;
