import {
  ButtonGroup,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { format } from 'date-fns';
import { mPipe, pass } from 'fp-utilities';
import { ReactElement } from 'react';
import { CenteredContent } from '../../components/Pages/CenteredContent';
import { TestId } from '../../components/Testing/TestId';
import { FormGroup } from '../../components/common';
import { RatingField } from '../../components/common/RatingField';
import Button from '../../components/common/button';
import TextField from '../../components/common/text-field';
import TextWysiwyg from '../../components/common/text-wysiwyg';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { isInvalid } from '../../utils/FormValue';
import { setComment, setRating, submit } from './types/Actions';
import { is } from './types/Rating';
import { useOfficeHoursSurvey } from './useOfficeHoursSurvey';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 25,
  },
  content: {
    maxWidth: 500,
  },
  message: {
    display: 'grid',
    justifyContent: 'center',
    gap: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    margin: 'auto',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    margin: 'auto',
  },
  formGroup: {
    marginTop: '20px !important',

    '& input': {
      paddingTop: 12,
      paddingBottom: 12,
    },

    '& div[contenteditable="true"]': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
    },
  },
}));

export function OfficeHoursSurvey(): ReactElement {
  const classes = useStyles();
  const [s, dispatch] = useOfficeHoursSurvey();
  const { rb } = useResourceBundles();

  switch (s.type) {
    case 'InvalidKey':
      return (
        <CenteredContent>
          <div className={classes.message}>
            <CancelIcon className={classes.errorIcon} />
            <Typography align={'center'} component={'p'} variant='subtitle2'>
              Unfortunately, you cannot access this page.
            </Typography>
          </div>
        </CenteredContent>
      );
    case 'Loading':
      return (
        <CenteredContent>
          <CircularProgress size={54} color='primary' />
        </CenteredContent>
      );
    case 'LoadError':
      return (
        <CenteredContent>
          <div className={classes.message}>
            <WarningIcon className={classes.errorIcon} />
            <Typography align={'center'} component={'p'} variant='subtitle2'>
              {s.payload.message}
            </Typography>
          </div>
        </CenteredContent>
      );
    case 'Ready':
    case 'Submitted':
    case 'Saving':
    case 'SaveError':
      return (
        <CenteredContent>
          <div className={classes.content}>
            <Typography variant='h3' className={classes.title} align={'center'}>
              Office Hours Evaluation Form for the Community Member
            </Typography>
            <FormGroup className={classes.formGroup}>
              <TestId testId={'advisor-name'}>
                <TextField
                  label={'Summary'}
                  value={`Office hours with ${s.payload.advisorName}`}
                  disabled
                />
              </TestId>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TestId testId={'requester-email'}>
                <TextField label={'Email'} value={s.payload.email} disabled />
              </TestId>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TestId testId={'start-time'}>
                <TextField
                  label={'Start'}
                  value={format(s.payload.start, 'MM/dd/yyyy hh:mm a')}
                  disabled
                />
              </TestId>
              <TestId testId={'end-time'}>
                <TextField
                  label={'End'}
                  value={format(s.payload.end, 'MM/dd/yyyy hh:mm')}
                  disabled
                />
              </TestId>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TestId testId={'rating'}>
                <RatingField
                  label={`How would you rate the Office Hours Meeting with this ${rb(
                    'advisor',
                  )}?`}
                  onChange={mPipe(pass(is), setRating, dispatch)}
                  value={s.payload.rating.value}
                  stars={5}
                  invalid={isInvalid(s.payload.rating)}
                  readOnly={s.type === 'Saving'}
                  name={'rating'}
                />
              </TestId>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TestId testId={'notes'}>
                <TextWysiwyg
                  value={s.payload.comment.value || ''}
                  onChange={(e) => dispatch(setComment(e.target.value))}
                  showCount
                  maxLength={500}
                  placeholder={'Please share your feedback here...'}
                  error={isInvalid(s.payload.comment)}
                  disabled={s.type === 'Saving'}
                />
              </TestId>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <ButtonGroup>
                <TestId testId={'submit-button'}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    disabled={s.type === 'Saving'}
                    type={'button'}
                    onClick={() => dispatch(submit())}>
                    Send
                  </Button>
                </TestId>
              </ButtonGroup>
            </FormGroup>
          </div>
        </CenteredContent>
      );
    case 'SaveSuccess':
      return (
        <CenteredContent>
          <div className={classes.message}>
            <CheckCircleIcon className={classes.successIcon} />
            <TestId testId={'success-message'}>
              <Typography align={'center'} component={'p'} variant='subtitle2'>
                Your form was submitted successfully!
              </Typography>
            </TestId>
          </div>
        </CenteredContent>
      );
  }
}
