import { makeStyles } from '@material-ui/core';
import { ReactElement } from 'react';

export interface Props {
  size: 'small';
}

export function Spacing({ size }: Props): ReactElement {
  const classes = useStyles();

  return <div className={classes[size]} />;
}

const useStyles = makeStyles(() => ({
  small: {
    paddingTop: 10,
  },
}));
