import { AxiosError } from 'axios';
import { isT, mPipe } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { BehaviorSubject, EMPTY, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { CommunityMemberUpdate as Update } from '../../../api/CommunityMembers/types/CommunityMember';
import { fromString } from '../../../api/CommunityMembers/types/Status';
import { useQuery } from '../../../hooks/useQuery';
import { unreachableError } from '../../../utils/unreachableError';
import * as Api from './Api';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';

export function useCommunityMembersList(): [
  State.State,
  Dispatch<Actions.PublicActions>,
] {
  const query = useQuery();
  const status = mPipe(fromString)(query.get('status'));
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    reducer,
    State.loading({
      page: 1,
      filter: status ? { type: 'status', value: status } : { type: 'none' },
    }),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subject = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    subject.next(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const s = subject
      .pipe(
        distinctUntilKeyChanged('type'),
        mergeMap((s) => {
          switch (s.type) {
            case 'Loading': {
              return of(s).pipe(
                switchMap((s) => Api.get(s.payload.page, s.payload.filter)),
                map(Actions.loadSuccess),
                catchError((e: AxiosError) =>
                  of(
                    Actions.loadError(
                      e.response?.data.message ??
                        'Unable to load community members',
                    ),
                  ),
                ),
              );
            }
            case 'Updating': {
              return of(
                s.payload.items
                  .filter((i) => i.selected && i.status !== s.payload.status)
                  .map((i) => s.payload.members.find(({ id }) => i.id === id))
                  .filter(isT)
                  .map(
                    (i): Update => ({
                      ...i,
                      status: s.payload.status,
                    }),
                  ),
              ).pipe(
                switchMap((data) =>
                  from(Api.update(data, s.payload.page, s.payload.filter)),
                ),
                map(Actions.updateSuccess),
                catchError((e: AxiosError) =>
                  of(
                    Actions.updateError(
                      e.response?.data.message ??
                        'Unable to update community members',
                    ),
                  ),
                ),
              );
            }
            case 'LoadError':
            case 'Ready':
            case 'UpdateConfirmation':
            case 'UpdateErr':
              return EMPTY;
          }
        }),
      )
      .subscribe(dispatch);

    const router$ = subject
      .pipe(
        map((s) => s.payload.filter),
        map((f): { search: string | undefined; status: string | undefined } => {
          return {
            status: f.type === 'status' ? f.value : undefined,
            search: f.type === 'search' ? f.value : undefined,
          };
        }),
      )
      .subscribe(query.set);

    return () => {
      s.unsubscribe();
      router$.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (state.type) {
      case 'LoadError':
      case 'UpdateErr': {
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        break;
      }
      case 'Ready':
      case 'Loading':
      case 'UpdateConfirmation':
      case 'Updating': {
        break;
      }
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type, enqueueSnackbar]);

  return [state, dispatch];
}
