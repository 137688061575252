import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Audience } from '../../api/CustomQuestion/types/Audience';
import foundersAPI, {
  FounderAdditionalInfo,
  FounderDetails,
  FounderId,
} from '../../api/founders';
import { PageLoader, SnackMessage } from '../../components/common';
import CustomForm from '../../components/forms/custom-form';
import FounderAdditionalInfoForm, {
  FormAdditionalInfo,
  ValidFormAdditionalInfo,
} from '../../components/forms/founder-additional-info';
import { FounderContext } from '../../contexts/founder-context';
import { UserContext } from '../../contexts/user-context';
import { formatDateToRFC } from '../../utils/date';
import { PastStringDate } from '../../utils/date/PastStringDate';

interface FounderAdditionalInfoDefaultProps {
  hasAccessToUpdate?: boolean;
}

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

const getInitialValues = (
  value?: FounderAdditionalInfo,
): FormAdditionalInfo => {
  return {
    gender: value?.gender || '',
    race: value?.race || '',
    veteran: value?.veteran === true ? 'true' : 'false',
  };
};

const getParsedValues = (value: ValidFormAdditionalInfo) => {
  return {
    gender: value.gender,
    race: value.race,
    veteran: value.veteran === 'true',
  };
};

function FounderAdditionalInfoPage() {
  const location = useLocation();
  const { founderId } = useParams<{ founderId: FounderId }>();
  const { user, hasAccessToAction } = useContext(UserContext);

  const hasAccessToUpdate = useMemo(() => {
    const isExternalPage = location.pathname.includes('external');
    return isExternalPage
      ? true
      : hasAccessToAction('founder.additionalInfo.update');
  }, [hasAccessToAction, location.pathname]);

  return (
    <div>
      {user?.customQuestionsFounderAdditionalInfoEnabled ? (
        <CustomForm
          audience={Audience.FOUNDER_ADDITIONAL_INFO}
          responderId={founderId}
          successMessage='Additional information successfully updated.'
          hasAccessToUpdate={hasAccessToUpdate}
        />
      ) : (
        <FounderAdditionalInfoDefault hasAccessToUpdate={hasAccessToUpdate} />
      )}
    </div>
  );
}

function FounderAdditionalInfoDefault({
  hasAccessToUpdate,
}: FounderAdditionalInfoDefaultProps) {
  const classes = useStyles();
  const { founderId } = useParams<{ founderId: FounderId }>();
  const { founder, updateFounder } = useContext(FounderContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitForm = async (value: ValidFormAdditionalInfo) => {
    try {
      setIsLoading(true);
      if (founder) {
        const parsedValues = getParsedValues(value);
        const founderAdditionalInfo: FounderDetails['foundersAdditionalInfo'] =
          founder?.foundersAdditionalInfo &&
          founder?.foundersAdditionalInfo.founderId
            ? {
                ...founder.foundersAdditionalInfo,
                ...parsedValues,
              }
            : {
                ...parsedValues,
                creationDate: formatDateToRFC(new Date()) as PastStringDate,
              };
        const updatedFounder = await foundersAPI.update(founderId, {
          ...founder,
          foundersAdditionalInfo: founderAdditionalInfo,
        });
        updateFounder(updatedFounder);
      }
      setIsLoading(false);
      enqueueSnackbar('Additional information successfully updated.', {
        variant: 'success',
      });
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar('An error occurred while saving. Please, try again.', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const initialValues = getInitialValues(founder?.foundersAdditionalInfo);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <FounderAdditionalInfoForm
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={handleSubmitForm}
        hasAccessToUpdate={hasAccessToUpdate}
      />
    </div>
  );
}

export default FounderAdditionalInfoPage;
