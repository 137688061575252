import {
  Answer,
  AnswerId
} from '../../api/ventures/quarterlyrecords/customanswers/Answer';
import { RecordId } from '../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';

export const ventureQuarterRecordsAnswers = (recordId: RecordId): Answer[] =>
  Array.from(Array(10).keys()).map(
    (i): Answer => ({
      id: `r${i}` as AnswerId,
      description: `Description of the question that was described to be little shorter, with the number ${i}?`,
      answer: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. ${i}`,
      ventureQuarterlyReportRecordId: recordId
    })
  );
