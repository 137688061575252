import { Report } from '../../../api/OfficeHours/types/Report';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Report;
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Customize
export interface Customize {
  type: 'Customize';
}

export const customize = (): Customize => ({
  type: 'Customize',
});
// endregion

// region CancelCustomization
export interface CancelCustomization {
  type: 'CancelCustomization';
}

export const cancelCustomization = (): CancelCustomization => ({
  type: 'CancelCustomization',
});
// endregion

// region Update
export interface Update {
  type: 'Update';
}

export const update = (): Update => ({
  type: 'Update',
});
// endregion

// region SetDateRange
export interface SetDateRange {
  type: 'SetDateRange';
  payload: {
    start: Date;
    end: Date;
  };
}

export const setDateRange = (
  payload: SetDateRange['payload'],
): SetDateRange => ({
  type: 'SetDateRange',
  payload,
});
// endregion

// region SetLogo
export interface SetLogo {
  type: 'SetLogo';
  payload: boolean;
}

export const setLogo = (payload: SetLogo['payload']): SetLogo => ({
  type: 'SetLogo',
  payload,
});
// endregion

// region SetSummary
export interface SetSummary {
  type: 'SetSummary';
  payload: boolean;
}

export const setSummary = (payload: SetSummary['payload']): SetSummary => ({
  type: 'SetSummary',
  payload,
});
// endregion

export type Actions =
  | LoadError
  | LoadSuccess
  | Customize
  | CancelCustomization
  | SetDateRange
  | SetLogo
  | SetSummary
  | Update;
