import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSnackbar } from 'notistack';
import {
  FILE_SIZE_LIMIT,
  FILE_SIZE_LIMIT_MB,
  lengthField,
} from '../../utils/form';

interface AttachFilesButtonProps {
  accept?: string;
  className?: string;
  text?: string;
  variant?: 'text' | 'outlined' | 'contained';
  onChange: (event: React.ChangeEvent<HTMLInputElement>, file: File) => any;
}

function AttachFilesButton({
  accept,
  onChange,
  className,
  text = 'Attach files',
  variant = 'text',
}: AttachFilesButtonProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      if (file?.size > FILE_SIZE_LIMIT) {
        enqueueSnackbar(
          `The file you are trying to upload is bigger than ${FILE_SIZE_LIMIT_MB}MB.\nPlease compress the file or try to upload another one`,
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          },
        );
      } else if (file?.name?.length > lengthField.fileName) {
        enqueueSnackbar(
          'The file name is too long.\nPlease rename the file and try to upload again',
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          },
        );
      } else {
        onChange(e, file);
      }
    }
  };

  // This is a hack to validate the same file again
  const handleFileUploadClick = (e: React.MouseEvent<HTMLInputElement>) =>
    ((e.target as HTMLInputElement).value = '');

  return (
    <Button
      className={className}
      variant={variant}
      component='label'
      color='primary'
      startIcon={<AttachFileIcon fontSize='inherit' />}>
      {text}
      <input
        type='file'
        accept={accept}
        hidden
        onChange={handleFileUpload}
        onClick={handleFileUploadClick}
      />
    </Button>
  );
}

export default AttachFilesButton;
