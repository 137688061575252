import { makeStyles, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { Text } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import FormSection from './form-section';

interface Props {
  links: {
    mentors: string;
    ventures: string;
  };
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  aboutSection: {
    marginBottom: 25,
  },
  titleAbout: {
    marginTop: 0,
    marginBottom: 8,
  },
});

function FormsScreen({ links }: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [copied, setCopied] = useState<string | null>(null);

  const linkList = useMemo(
    () => [
      {
        id: 'mentor',
        label: `${rb('mentor-u')} Application Form`,
        link: links.mentors,
        description: `Click on the gear icon to tailor the ${rb(
          'mentor-u',
        )} Application Form's description, ensuring applicants are aware they're applying to your organization.`,
      },
      {
        id: 'venture',
        label: 'Venture Application Form',
        link: links.ventures,
        description:
          "Click on the gear icon to tailor the Venture Application Form's description, ensuring applicants are aware they're applying to your organization.",
      },
    ],
    [links.mentors, links.ventures, rb],
  );

  const handleCopy = (text: string, id: string) => {
    navigator.clipboard.writeText(text);
    setCopied(id);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (copied) {
      timeout = setTimeout(() => {
        setCopied(null);
      }, 4000);
    }

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className={classes.container}>
      <div className={classes.aboutSection}>
        <Typography
          variant='h3'
          className={classes.titleAbout}
          data-testid='links-page-href'>
          About these forms
        </Typography>
        <Text variant='normal'>
          These links to forms can be added to your website or sent out manually
          to collect applications.
        </Text>
      </div>

      {linkList.map((link) => (
        <FormSection
          key={link.id}
          onCopy={handleCopy}
          copied={copied}
          {...link}
        />
      ))}
    </div>
  );
}

export default FormsScreen;
