import * as State from './types/State';
import { Actions } from './types/Actions';
import { isOpen, isSubmittable } from './types/State';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Open':
      return !isOpen(s) ? State.open(s.payload) : s;
    case 'Close':
      return s.type !== 'Idle' ? State.idle(s.payload) : s;
    case 'Send':
      return isSubmittable(s) ? State.sending(s.payload) : s;
    case 'SendError':
      return s.type === 'Sending'
        ? State.sendError({
            ...s.payload,
            message:
              'An error occurred while sending profile update request. Please, try again.',
          })
        : s;
    case 'SendSuccess':
      return s.type === 'Sending'
        ? State.sendSuccess({
            ...s.payload,
            message: 'The profile update request was successfully sent',
          })
        : s;
  }
}
