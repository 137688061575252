import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactElement } from 'react';
import { LogoImage, Text } from '../../components/common';
import Link from '../../components/common/link';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { getDateRangeForReport } from '../../utils/date';
import { Advisor } from './types/Advisor';

export interface Props {
  programName: string;
  start: Date;
  end: Date;
  logo: string | undefined;
  summary:
    | {
        officeHours: number;
        sessions: number;
        feedbackForms: number;
      }
    | undefined;
  advisors: Advisor[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    width: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  defaultStyles: {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 70,
  },
  logoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: 70,

    '@media print': {
      position: 'relative',
    },
  },
  confidentialText: {
    fontSize: 10,

    '@media print': {
      whiteSpace: 'nowrap',
    },
  },
  contentEmpty: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentEmptyText: {
    width: 500,
    textAlign: 'center',
  },
  table: {
    '& td': {
      padding: 10,
    },
    '& a': {
      textDecoration: 'none',

      '@media print': {
        color: '#000',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  printFirefox: {
    '@media print': {
      marginLeft: -100,
    },
  },
}));

export function Report({
  start,
  end,
  programName,
  logo,
  summary,
  advisors,
}: Props): ReactElement {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  return (
    <div data-testid='report-container-list' className={classes.container}>
      <div className={classes.border}>
        <div className={classes.title}>
          <div>
            <Typography variant='h4'>{programName}</Typography>
            <Text variant='normal'>
              For the period from {getDateRangeForReport(start, end)}
            </Text>
          </div>
          <div className={classes.logoContent}>
            <Text variant='normal' className={classes.confidentialText}>
              Confidential. Not for Distribution
            </Text>

            {logo && (
              <span
                className={classes.logo}
                data-testid='venture-progress-report-include-logo'>
                <LogoImage
                  className={classes.image}
                  src={logo}
                  alt={programName}
                />
              </span>
            )}
          </div>
        </div>
      </div>

      {summary && (
        <div className={classes.border} data-testid='report-include-summary'>
          <div>
            <Text variant='normal'>
              Total Number of Hours: {summary.officeHours}
            </Text>
          </div>
          <div>
            <Text variant='normal'>
              Number of feedbacks received: {summary.feedbackForms}
            </Text>
          </div>
          <div>
            <Text variant='normal'>
              Total Number of Sessions: {summary.sessions}
            </Text>
          </div>
        </div>
      )}

      {advisors.length > 0 && (
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={'subtitle1'}>
                    {rb('advisor-u')} Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'subtitle1'} align={'center'}>
                    Number of Office Hours
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'subtitle1'} align={'center'}>
                    Average Score
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {advisors.map(
                ({ advisorId, fullName, numberOfHours, averageScore }) => {
                  return (
                    <TableRow key={advisorId}>
                      <TableCell>
                        <Typography
                          component={Link}
                          to={getRoutePath(Pages.OH_ADVISORS_EDIT, {
                            id: advisorId,
                          })}
                          variant={'subtitle2'}>
                          {fullName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'subtitle2'} align={'center'}>
                          {numberOfHours}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'subtitle2'} align={'center'}>
                          {averageScore?.toFixed(1) ?? 'N/A'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
