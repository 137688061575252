// region Url
import { mPipe, pass } from 'fp-utilities';
import { trim } from 'ramda';

declare const _url: unique symbol;

export type Url = string & { [_url]: 'Url' };

export const urlRegex =
  /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const isUrl = (s: string): s is Url =>
  !!s.match(
    // eslint-disable-next-line no-useless-escape
    urlRegex,
  );

export const fromString = mPipe(trim, pass(isUrl));
// endregion

export function urlfy(s: string): string {
  if (s.startsWith('http://') || s.startsWith('https://')) {
    return s;
  }

  return `http://${s}`;
}
