// region Submit
export interface Submit<T> {
  type: 'Submit';
  payload: T;
}

export const submit = <T>(payload: Submit<T>['payload']): Submit<T> => ({
  type: 'Submit',
  payload,
});
// endregion

// region Cancel
export interface Cancel {
  type: 'Cancel';
}

export const cancel = (): Cancel => ({
  type: 'Cancel',
});
// endregion

// region Confirm
export interface Confirm {
  type: 'Confirm';
}

export const confirm = (): Confirm => ({
  type: 'Confirm',
});
// endregion

export type Actions<T> = Submit<T> | Cancel | Confirm;
