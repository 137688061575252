import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { EventChannel } from '../../api/auth';
import { COLORS } from '../../theme/variables';
import { createMarkup } from '../../utils/functions';
import { ConfirmButton, Text } from '../common';
import ChannelForm, {
  NewChanelName,
  Values as FormValues,
} from '../forms/channel';

interface ChannelProps {
  channel: EventChannel;
  updating?: boolean;
  deleting?: boolean;
  onEdit: (updateChannel: EventChannel, callback: () => any) => any;
  onRemove: (channel: EventChannel, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  text: {
    wordBreak: 'break-all',
  },
  textLink: {
    wordBreak: 'break-all',
    color: COLORS.COLOR_BLUE_DARKENED_10,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  bodyText: {
    wordBreak: 'break-word',
    marginTop: 32,

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
  textBold: {
    fontWeight: 500,
  },
}));

function Channel({
  channel,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index,
}: ChannelProps) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleChannelEdit = () => {
    setIsEditing(true);
  };

  const handleChannelRemove = (callback: () => any) => {
    onRemove(channel, callback);
  };

  const handleChannelEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitChannelForm = (value: FormValues) => {
    onEdit(
      { ...channel, ...value, channelName: value.channelName ?? '' },
      handleChannelEditCancel,
    );
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <ChannelForm
          title='Channel editing'
          channel={{
            channelName: channel.channelName as NewChanelName,
            channelLocation: channel.channelLocation,
            description: channel.description,
          }}
          loading={updating}
          onSubmit={handleSubmitChannelForm}
          onCancel={handleChannelEditCancel}
        />
      ) : (
        <>
          <div className={classes.title} data-testid={`channel-${index}`}>
            {!readOnly && (
              <div>
                <IconButton
                  onClick={handleChannelEdit}
                  data-testid={`form-channel-edit-${index}`}>
                  <EditIcon />
                </IconButton>
                <ConfirmButton
                  loading={deleting}
                  title='Remove the channel?'
                  body='Sure you want to remove the channel? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleChannelRemove,
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`form-channel-delete-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}></ConfirmButton>
              </div>
            )}
          </div>
          <div>
            <Text className={classes.textBold} variant='normal'>
              Name:{' '}
            </Text>
            <Text className={classes.text} variant='normal'>
              {channel.channelName}
            </Text>
            <br />
            <Text className={classes.textBold} variant='normal'>
              Location:{' '}
            </Text>
            <Text className={classes.text} variant='normal'>
              {channel.channelLocation}
            </Text>

            <div
              className={classes.bodyText}
              dangerouslySetInnerHTML={createMarkup(channel.description)}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Channel;
