import * as State from './type/State';
import * as Actions from './type/Actions';
import * as Sentence from '../../utils/String/Sentence';
import * as Email from '../../utils/String/Email';
import { apply } from '../../utils/Either';
import { invalid, isValid, valid } from '../../utils/FormValue';
import { unreachableError } from '../../utils/unreachableError';

export function reducer(s: State.State, a: Actions.Actions): State.State {
  switch (a.type) {
    case 'SetFirst': {
      if (State.isEditable(s)) {
        const firstName = apply(
          () => invalid<'required', string>('required', a.payload),
          valid,
          Sentence.fromString(100)(a.payload),
        );
        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, firstName });
          case 'Submitted':
          case 'SaveError':
            return State.submitted({ ...s.payload, firstName });
          default:
            return unreachableError(s);
        }
      }

      return s;
    }
    case 'SetLast': {
      if (State.isEditable(s)) {
        const lastName = apply(
          () => invalid<'required', string>('required', a.payload),
          valid,
          Sentence.fromString(100)(a.payload),
        );
        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, lastName });
          case 'Submitted':
          case 'SaveError':
            return State.submitted({ ...s.payload, lastName });
          default:
            return unreachableError(s);
        }
      }

      return s;
    }
    case 'SetNote': {
      if (State.isEditable(s)) {
        const note = apply(
          () => invalid<'required', string>('required', a.payload),
          valid,
          Sentence.fromString(1000)(a.payload),
        );
        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, note });
          case 'Submitted':
          case 'SaveError':
            return State.submitted({ ...s.payload, note });
          default:
            return unreachableError(s);
        }
      }

      return s;
    }
    case 'SetEmail': {
      if (State.isEditable(s)) {
        const v = Email.fromString(a.payload);
        const email = v
          ? valid(v)
          : invalid('required' as 'required', a.payload);
        switch (s.type) {
          case 'Ready':
            return State.ready({ ...s.payload, email });
          case 'Submitted':
          case 'SaveError':
            return State.submitted({ ...s.payload, email });
          default:
            return unreachableError(s);
        }
      }

      return s;
    }
    case 'Submit': {
      const firstName = isValid(s.payload.firstName)
        ? s.payload.firstName
        : invalid('required' as 'required', s.payload.firstName.value);
      const lastName = isValid(s.payload.lastName)
        ? s.payload.lastName
        : invalid('required' as 'required', s.payload.lastName.value);
      const email = isValid(s.payload.email)
        ? s.payload.email
        : invalid('required' as 'required', s.payload.email.value);
      const note = isValid(s.payload.note)
        ? s.payload.note
        : invalid('required' as 'required', s.payload.note.value);

      return isValid(firstName) &&
        isValid(lastName) &&
        isValid(email) &&
        isValid(note)
        ? State.saving({ ...s.payload, firstName, lastName, email, note })
        : State.submitted({ ...s.payload, firstName, lastName, note, email });
    }
    case 'SaveError':
      return s.type === 'Saving'
        ? State.saveError({ ...s.payload, message: a.payload })
        : s;
    case 'SaveSuccess':
      return s.type === 'Saving' ? State.saveSuccess(s.payload) : s;
  }
}
