import { createContext, useState, useCallback, useMemo } from 'react';
import gatheringsAPI, { Gathering } from '../api/gatherings';

export interface GatheringContextProps {
  isGatheringLoading: boolean;
  gathering?: Gathering;
  loadGathering: (gatheringId: string) => Promise<any>;
  updateGathering: (gatheringId: Gathering) => any;
  loadGroupList: (groupId: string) => Promise<any>;
  currentIndexGathering: number;
  group: string[];
  updateDynamicInvite: (value: boolean) => void;
}

export const GatheringContext = createContext<GatheringContextProps>({
  isGatheringLoading: false,
  loadGathering: async () => {},
  updateGathering: () => {},
  loadGroupList: async () => {},
  currentIndexGathering: -1,
  group: [],
  updateDynamicInvite: () => {}
});

interface GatheringProviderProps {
  children: React.ReactNode;
}

export const GatheringProvider = ({ children }: GatheringProviderProps) => {
  const [isGatheringLoading, setIsGatheringLoading] = useState(false);
  const [gathering, setGathering] = useState<Gathering>();
  const [group, setGroup] = useState<string[]>([]);

  const currentIndexGathering = useMemo(() => {
    if (gathering && group.length > 0) {
      return (
        group?.findIndex((gatheringId) => gatheringId === gathering.id) ?? -1
      );
    }
    return -1;
  }, [gathering, group]);

  const updateDynamicInvite = useCallback((value: boolean) => {
    setGroup((prev) => ({ ...prev, dynamicInvite: value }));
  }, []);

  const updateGathering = useCallback((value: Gathering) => {
    setGathering(value);
  }, []);

  const handleGatheringFullDetails = (gathering: Gathering) => {
    setGathering(gathering);
  };

  const loadGathering = useCallback(async (gatheringId: string) => {
    try {
      setIsGatheringLoading(true);
      const loadedGathering = await gatheringsAPI.getGathering(gatheringId);

      handleGatheringFullDetails(loadedGathering);
      setIsGatheringLoading(false);
      return loadedGathering;
    } catch (e: any) {
      setIsGatheringLoading(false);
      console.log('error loadSession', e);
    }
  }, []);

  const loadGroupList = useCallback(async (groupId) => {
    try {
      const groupRespnse = await gatheringsAPI.getGroupList(groupId);
      setGroup(groupRespnse);
    } catch (e: any) {
      console.log('error loadGroup', e);
    }
  }, []);

  const contextValue: GatheringContextProps = {
    isGatheringLoading,
    loadGathering,
    gathering,
    updateGathering,
    loadGroupList,
    currentIndexGathering,
    group,
    updateDynamicInvite
  };

  return (
    <GatheringContext.Provider value={contextValue}>
      {children}
    </GatheringContext.Provider>
  );
};
