import { Actions } from './types/Actions';
import { confirmation, idle, loading, State } from './types/State';

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'Submit':
      return state.type === 'Idle' ? confirmation() : state;
    case 'Confirm':
      return state.type === 'Confirmation' ? loading() : state;
    case 'Decline':
      return state.type === 'Confirmation' || state.type === 'Loading'
        ? idle()
        : state;
    case 'Fail':
      return state.type === 'Loading' ? confirmation() : state;
    case 'Success':
      return state.type === 'Loading' ? idle() : state;
  }
}
