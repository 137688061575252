import { useCallback, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import FinanceRecordForm, {
  Values as FormValues
} from '../../components/forms/finance-record';
import { COLORS } from '../../theme/variables';
import { VentureRecordValue } from '../../pages/venture/venture-finance-page';

interface NewFinanceRecordProps {
  creating?: boolean;
  ventureId: string;
  onCreate: (newRecord: VentureRecordValue, callback: () => any) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56
  },
  main: {
    display: 'flex',
    alignItems: 'center'
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)'
    }
  }
});

function NewFinanceRecord({
  creating,
  ventureId,
  onCreate
}: NewFinanceRecordProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);

  const handleOpenForm = () => {
    setIsShownForm(true);
  };

  const handleCloseForm = () => {
    setIsShownForm(false);
  };

  const handleCreateRecord = useCallback(
    (values: FormValues) => {
      onCreate({ ventureId, ...values }, handleCloseForm);
    },
    [onCreate, ventureId]
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, []);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <FinanceRecordForm
          title='New Record'
          loading={creating}
          onSubmit={handleCreateRecord}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div className={classes.main}>
          <Typography variant='h4'>New Record</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={handleOpenForm}
            data-testid='button-create-fanance'>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default NewFinanceRecord;
