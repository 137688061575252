import { makeStyles, Avatar, Menu, MenuItem, Fade } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import cn from 'classnames';
import { useState, useContext } from 'react';
import {
  // useHistory,
  useLocation,
} from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { Pages } from '../../router/constants';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';

const useStyles = makeStyles({
  avatar: {
    width: 32,
    height: 32,
    cursor: 'pointer',

    '&:hover': {
      boxShadow: `0 0 0 4px ${COLORS.COLOR_BLUE_LIGHTENED_40}`,
    },
  },
  isOpenMenu: {
    boxShadow: `0 0 0 4px ${COLORS.COLOR_BLUE_LIGHTENED_40}`,
  },
  divider: {
    margin: '8px 0',
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  icon: {
    marginRight: '15px',
    color: COLORS.COLOR_GRAY_BASE,
  },
  linkItem: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    margin: '-12px -20px -12px -18px',
    padding: '12px 20px 12px 18px',
    width: 'calc(100% + 20px)',
    alignItems: 'center',
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: MONTSERRAT_FONT_FAMILY,
  },
  menuItemLogout: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: MONTSERRAT_FONT_FAMILY,
  },
});

function UserAvatar() {
  const { hasAccessToAction } = useContext(UserContext);
  const classes = useStyles();
  // const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const hangleChangeRoute = (url: string) => {
  //   history.push(url);
  // };

  const handleLogout = () => {
    logout(true);
  };

  return (
    <div>
      <Avatar
        onClick={handleClick}
        data-testid='button-open-menu'
        className={cn(classes.avatar, {
          [classes.isOpenMenu]: open,
        })}>
        {user?.managerFirstName ? user?.managerFirstName[0] : 'U'}
      </Avatar>
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {hasAccessToAction('edit') && (
          <MenuItem data-testid='tenant-menu-profile'>
            <Link
              className={classes.linkItem}
              to={{
                pathname: Pages.TENANT_PROFILE,
                state: {
                  prevPath: location.pathname,
                },
              }}>
              <AccountCircleIcon className={classes.icon} />
              Profile
            </Link>
          </MenuItem>
        )}
        <MenuItem data-testid='tenant-menu-settings'>
          <Link
            className={classes.linkItem}
            to={{
              pathname: Pages.TENANT_ACCOUNT,
              state: {
                prevPath: location.pathname,
              },
            }}>
            <SettingsIcon className={classes.icon} />
            Settings
          </Link>
        </MenuItem>
        <div className={classes.divider}></div>
        <MenuItem
          onClick={handleLogout}
          className={classes.menuItemLogout}
          data-testid='nav-logout'>
          <ExitToAppIcon className={classes.icon} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserAvatar;
