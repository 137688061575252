import { makeStyles } from '@material-ui/core';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DOMPurify from 'dompurify';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useAnnouncements } from '../../contexts/announcement-context';
import { COLORS } from '../../theme/variables';
import { createMarkup } from '../../utils/functions';
import { TestId } from '../Testing/TestId';
import { ActionsDialog } from './Confirmation/ActionsDialog';
import Button from './button';

const useStyles = makeStyles({
  modal: {
    '& .MuiDialogContent-root': {
      maxHeight: 500,

      '&::-webkit-scrollbar': {
        width: 12,
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,

        '&:hover': {
          backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
        },
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
      },
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    paddingRight: 16,
    boxSizing: 'border-box',
  },
  annoucementContainer: {
    display: 'flex',
    gap: 16,
    width: '100%',
  },
  text: {
    wordBreak: 'break-word',
    fontWeight: 400,
    fontSize: 14,
    color: COLORS.COLOR_TEXT_LIGHTENED_20,

    '& h2': {
      marginBottom: 4,
      fontWeight: 600,
      fontSize: 18,
      color: COLORS.COLOR_TEXT_BASE,
    },

    '& a': {
      color: COLORS.COLOR_BLUE_DARKENED_10,
      textDecoration: 'none',
    },
  },
});

function AnnouncementsModal() {
  const { announcements, showAnnouncements, setShowAnnouncements } =
    useAnnouncements();
  const classes = useStyles();

  const onClose = useCallback(() => {
    setShowAnnouncements(false);
  }, [setShowAnnouncements]);

  if (!announcements?.length) {
    return null;
  }

  return ReactDOM.createPortal(
    <ActionsDialog
      title={'Platform Updates'}
      open={showAnnouncements}
      className={classes.modal}
      onClose={onClose}
      actions={
        <TestId testId={'ok'}>
          <Button variant={'contained'} onClick={onClose}>
            Continue
          </Button>
        </TestId>
      }>
      <div className={classes.list}>
        {announcements.map((announcement) => (
          <div key={announcement.id} className={classes.annoucementContainer}>
            <NewReleasesIcon color='primary' />
            <div
              className={classes.text}
              dangerouslySetInnerHTML={createMarkup(
                DOMPurify.sanitize(announcement.content),
              )}
            />
          </div>
        ))}
      </div>
    </ActionsDialog>,
    document.body,
  );
}

export default AnnouncementsModal;
