import { Tabs, Tab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link as MaterialLink } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import cn from 'classnames';
import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GatheringContext } from '../../contexts/gathering-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { isMobile } from '../../utils/functions';
import { Text } from '../common';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  gatheringId?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 10px 0 0',
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  tooltipWrapper: {
    marginLeft: '30px',
  },
  buttonGroup: {
    height: 48,
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    marginLeft: 40,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: COLORS.COLOR_BLUE_BASE,
    border: `1px solid ${COLORS.COLOR_BLUE_LIGHTENED_25}`,
    padding: '4px 9px',
    borderRadius: 5,

    '&:hover': {
      marginBottom: 0,
      textDecoration: 'none',
      border: `1px solid ${COLORS.COLOR_BLUE_BASE}`,
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_50,
    },

    '& > svg': {
      fontSize: 16,
      color: COLORS.COLOR_BLUE_BASE,
    },

    '& > span': {
      fontSize: 13,
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  disabledLink: {
    border: `1px solid ${COLORS.COLOR_GRAY_BASE}`,
    cursor: 'initial',
    '& > svg': {
      color: COLORS.COLOR_GRAY_BASE,
    },

    '& > span': {
      color: COLORS.COLOR_GRAY_BASE,
    },

    '&:hover': {
      border: `1px solid ${COLORS.COLOR_GRAY_BASE}`,
      backgroundColor: 'unset',
    },
  },
}));

function PageTabs({ gatheringId }: PageTabsProps) {
  const location = useLocation();
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { currentIndexGathering, group, gathering } =
    useContext(GatheringContext);
  const { hasAccessToAction } = useContext(UserContext);

  const tabs: TabsProps[] = [
    {
      props: {
        label: 'Details',
        'data-testid': 'gathering-nav-details',
        component: Link,
        ...(gatheringId
          ? {
              to: getRoutePath(Pages.GATHERINGS_DETAILS, { gatheringId }),
              value: getRoutePath(Pages.GATHERINGS_DETAILS, { gatheringId }),
            }
          : {
              to: Pages.NEW_GATHERINGS,
              value: Pages.NEW_GATHERINGS,
            }),
      },
    },
    ...(hasAccessToAction('gathering.founders.view')
      ? [
          {
            ...(!gatheringId
              ? {
                  tooltip: {
                    title: `Please complete creation of gathering\nbefore switching to Founders`,
                  },
                }
              : {}),
            props: {
              label: 'Founders',
              'data-testid': 'gathering-nav-founders',
              disabled: !gatheringId,
              ...(gatheringId
                ? {
                    component: Link,
                    value: getRoutePath(Pages.GATHERINGS_FOUNDERS, {
                      gatheringId,
                    }),
                    to: getRoutePath(Pages.GATHERINGS_FOUNDERS, {
                      gatheringId,
                    }),
                  }
                : {}),
            },
          },
        ]
      : []),
    ...(hasAccessToAction('gathering.mentors.view')
      ? [
          {
            ...(!gatheringId
              ? {
                  tooltip: {
                    title: `Please complete creation of gathering\nbefore switching to ${rb(
                      'mentors-u',
                    )}`,
                  },
                }
              : {}),
            props: {
              label: rb('mentors-u'),
              'data-testid': 'gathering-nav-mentors',
              disabled: !gatheringId,
              ...(gatheringId
                ? {
                    component: Link,
                    value: getRoutePath(Pages.GATHERINGS_MENTORS, {
                      gatheringId,
                    }),
                    to: getRoutePath(Pages.GATHERINGS_MENTORS, { gatheringId }),
                  }
                : {}),
            },
          },
        ]
      : []),
    ...(hasAccessToAction('gathering.guests.view')
      ? [
          {
            ...(!gatheringId
              ? {
                  tooltip: {
                    title: `Please complete creation of gathering\nbefore switching to Guests`,
                  },
                }
              : {}),
            props: {
              label: 'Guests',
              'data-testid': 'gathering-nav-guests',
              disabled: !gatheringId,
              ...(gatheringId
                ? {
                    component: Link,
                    value: getRoutePath(Pages.GATHERINGS_GUESTS, {
                      gatheringId,
                    }),
                    to: getRoutePath(Pages.GATHERINGS_GUESTS, { gatheringId }),
                  }
                : {}),
            },
          },
        ]
      : []),
    ...(hasAccessToAction('gathering.attendees.view')
      ? [
          {
            ...(!gatheringId
              ? {
                  tooltip: {
                    title: `Please complete creation of gathering\nbefore switching to Attendance`,
                  },
                }
              : {}),
            props: {
              label: 'Attendees',
              'data-testid': 'gathering-nav-attendees',
              disabled: !gatheringId,
              ...(gatheringId
                ? {
                    component: Link,
                    value: getRoutePath(Pages.GATHERINGS_ATTENDANCE, {
                      gatheringId,
                    }),
                    to: getRoutePath(Pages.GATHERINGS_ATTENDANCE, {
                      gatheringId,
                    }),
                  }
                : {}),
            },
          },
        ]
      : []),
    ...(hasAccessToAction('gathering.notes.view')
      ? [
          {
            ...(!gatheringId
              ? {
                  tooltip: {
                    title: `Please complete creation of gathering\nbefore switching to Notes`,
                  },
                }
              : {}),
            props: {
              label: 'Notes',
              'data-testid': 'gathering-nav-notes',
              disabled: !gatheringId,
              ...(gatheringId
                ? {
                    component: Link,
                    value: getRoutePath(Pages.GATHERINGS_NOTES, {
                      gatheringId,
                    }),
                    to: getRoutePath(Pages.GATHERINGS_NOTES, { gatheringId }),
                  }
                : {}),
            },
          },
        ]
      : []),
  ];

  const currentTabs = useMemo(() => {
    let arrayTabs = tabs;
    if (
      gathering &&
      hasAccessToAction('gathering.founders.view') &&
      gathering.audience === 'FOUNDERS'
    ) {
      arrayTabs.splice(2, 1);
    }
    if (
      gathering &&
      hasAccessToAction('gathering.mentors.view') &&
      gathering.audience === 'MENTORS'
    ) {
      arrayTabs.splice(1, 1);
    }

    return arrayTabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gathering, gatheringId, hasAccessToAction]);

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='on'>
        {currentTabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          ),
        )}
      </Tabs>
      {gatheringId && (
        <div className={classes.buttonGroup}>
          <MaterialLink
            to={
              currentIndexGathering > 0
                ? {
                    pathname: getRoutePath(Pages.GATHERINGS_DETAILS, {
                      gatheringId: group[currentIndexGathering - 1],
                    }),
                  }
                : '#'
            }
            className={cn(classes.link, {
              [classes.disabledLink]:
                currentIndexGathering === -1 || currentIndexGathering === 0,
            })}
            component={Link}>
            <ArrowBackIcon />
            {!isMobile() && (
              <Text variant='normal'>Previous Event Instance</Text>
            )}
          </MaterialLink>
          <MaterialLink
            to={
              currentIndexGathering < group.length - 1
                ? {
                    pathname: getRoutePath(Pages.GATHERINGS_DETAILS, {
                      gatheringId: group[currentIndexGathering + 1],
                    }),
                  }
                : '#'
            }
            className={cn(classes.link, {
              [classes.disabledLink]:
                currentIndexGathering === -1 ||
                currentIndexGathering === group.length - 1,
            })}
            component={Link}>
            {!isMobile() && <Text variant='normal'>Next Event Instance</Text>}
            <ArrowForwardIcon />
          </MaterialLink>
        </div>
      )}
    </div>
  );
}

export default PageTabs;
