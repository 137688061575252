import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Listing } from '../../components/Pages/Listing';
import { RouteACL } from '../../components/common';
import BaseLayout from '../../components/layout/base-layout';
import { ReportsProvider } from '../../contexts/reports-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import ReportsList from './reports-list-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
    '@media print': {
      boxShadow: 'none',
    },
  },
});

function EducationPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();

  return (
    <BaseLayout user={user} fullHeight>
      <Listing title='Reports'>
        <Paper className={classes.container} elevation={1}>
          <Switch>
            <RouteACL
              exact
              path={Pages.REPORTS_LIST}
              component={ReportsList}
              rule='modules.reports.view'
            />
            <Redirect to={Pages.DASHBOARD} />
          </Switch>
        </Paper>
      </Listing>
    </BaseLayout>
  );
}

function withReportsProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <ReportsProvider>
      <Component {...props} />
    </ReportsProvider>
  );
}

export default withReportsProvider(EducationPage);
