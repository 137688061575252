import { Audience } from '../../../api/CustomQuestion/types/Audience';
import { Tenant } from '../../../api/auth';

interface ICustomQuestionSettingsItem {
  key: keyof Tenant;
  title: string;
  tooltip: string;
  testId: string;
  text: string;
}

type ICustomQuestionsBlocksSettings = {
  [key in Audience]: ICustomQuestionSettingsItem;
};

export const customQuestionsBlocksSettings: ICustomQuestionsBlocksSettings = {
  [Audience.FOUNDER_ADDITIONAL_INFO]: {
    key: 'customQuestionsFounderAdditionalInfoEnabled',
    title: 'Founder Additional Info',
    tooltip: 'Custom Founder Additional Info',
    testId: 'custom-founder-additional-info',
    text: 'Choosing Custom Founder Additional Info, allows you to modify additional info questions used for founders.',
  },
  [Audience.MENTOR_ADDITIONAL_INFO]: {
    key: 'customQuestionsMentorAdditionalInfoEnabled',
    title: 'mentor-additional-info',
    tooltip: 'custom-mentor-additional-info',
    testId: 'custom-mentor-additional-info',
    text: 'choosing-custom-mentor-additional-info',
  },
  [Audience.VENTURE_ADDITIONAL_INFO]: {
    key: 'customQuestionsVentureAdditionalInfoEnabled',
    title: 'Venture Additional Info',
    tooltip: 'Custom Venture Additional Info',
    testId: 'custom-venture-additional-info',
    text: 'Choosing Custom Venture Additional Info, allows you to modify additional info questions used for ventures.',
  },
  [Audience.FOUNDER_APPLICATION]: {
    key: 'customQuestionsFounderAppEnabled',
    title: 'Founder Application',
    tooltip: 'Custom Founder Application',
    testId: 'custom-founder-application',
    text: 'Choosing Custom Founder Application, allows you to modify application questions used for founders.',
  },
  [Audience.MENTOR_APPLICATION]: {
    key: 'customQuestionsMentorAppEnabled',
    title: 'mentor-application',
    tooltip: 'custom-mentor-application',
    testId: 'custom-mentor-application',
    text: 'choosing-custom-mentor-application',
  },
  [Audience.VENTURE_APPLICATION]: {
    key: 'customQuestionsVentureAppEnabled',
    title: 'Venture Application',
    tooltip: 'Custom Venture Application',
    testId: 'custom-venture-application',
    text: 'Choosing Custom Venture Application, allows you to modify application questions used for ventures.',
  },
};
