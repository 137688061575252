import { Appointment } from '../../../api/Appointments/types/Appointment';
import { Rating } from './Rating';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Appointment;
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region SetRating
export interface SetRating {
  type: 'SetRating';
  payload: Rating;
}

export const setRating = (payload: SetRating['payload']): SetRating => ({
  type: 'SetRating',
  payload,
});
// endregion

// region SetComment
export interface SetComment {
  type: 'SetComment';
  payload: string;
}

export const setComment = (payload: SetComment['payload']): SetComment => ({
  type: 'SetComment',
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: string;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
}

export const saveSuccess = (): SaveSuccess => ({
  type: 'SaveSuccess',
});
// endregion

export type Actions =
  | LoadError
  | LoadSuccess
  | SetRating
  | SetComment
  | Submit
  | SaveSuccess
  | SaveError;
