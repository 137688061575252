import { makeStyles, Typography } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';

export interface DashboardCardLinkProps {
  to: string;
}

export function DashboardCardLink({
  children,
  to,
}: PropsWithChildren<DashboardCardLinkProps>) {
  const classes = useStyles();

  return (
    <Typography
      component={Link}
      to={to}
      className={classes.root}
      variant='caption'>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'none',
    color: COLORS.COLOR_BLUE_DARKENED_10,
    fontFamily: MONTSERRAT_FONT_FAMILY,
  },
}));
