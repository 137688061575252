import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useMemo } from 'react';
import { GoalStatus, goalStatuses } from '../../api/types/Goal';
import { COLORS } from '../../theme/variables';
import Select, { OptionProps } from './select';

export type SelectGoalStatus = GoalStatus | 'ALL';

interface GoalStatusSelectProps {
  status: SelectGoalStatus;
  onChange: (goalStatus: SelectGoalStatus) => void;
  className?: string;
  isDisabled?: boolean;
}

function goalStatusTitle(status: GoalStatus) {
  switch (status) {
    case 'ARCHIVED':
      return 'Archived';
    case 'COMPLETED':
      return 'Completed';
    case 'NOT_STARTED':
      return 'Not Started';
    case 'AT_RISK':
      return 'At Risk';
    case 'IN_TROUBLE':
      return 'In Trouble';
    case 'ON_TRACK':
      return 'On Track';
  }
}

const useStyles = makeStyles({
  statusOption: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  statusColor: {
    width: 10,
    height: 10,
    borderRadius: 5,

    '&.ARCHIVED': {
      backgroundColor: COLORS.COLOR_GRAY_DARKENED,
    },
    '&.COMPLETED': {
      backgroundColor: COLORS.COLOR_GREEN_BASE,
    },
    '&.NOT_STARTED': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    },
    '&.AT_RISK': {
      backgroundColor: COLORS.COLOR_ADDITIONAL_ORANGE,
    },
    '&.IN_TROUBLE': {
      backgroundColor: COLORS.COLOR_RED_BASE,
    },
    '&.ON_TRACK': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_10,
    },
  },
});

function GoalStatusSelect({
  status,
  onChange,
  className,
  isDisabled,
}: GoalStatusSelectProps) {
  const statusOptions: OptionProps<SelectGoalStatus>[] = useMemo(
    () => [
      {
        value: 'ALL',
        label: <GoalStatusOption value={'ALL'} />,
      },
      ...goalStatuses.map((value) => ({
        value,
        label: <GoalStatusOption value={value} />,
      })),
    ],
    [],
  );

  return (
    <Select
      className={className}
      value={status}
      options={statusOptions}
      onSelect={(v) => v && onChange(v.value)}
      isDisabled={isDisabled}
    />
  );
}

export function GoalStatusOption({ value }: { value: SelectGoalStatus }) {
  const classes = useStyles();
  return (
    <div className={classes.statusOption}>
      {value !== 'ALL' && (
        <div className={classNames(classes.statusColor, value)} />
      )}
      <div>{value === 'ALL' ? 'All Active' : goalStatusTitle(value)}</div>
    </div>
  );
}

export default GoalStatusSelect;
