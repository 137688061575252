import {
  Button as MaterialButton,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link as MaterialLink,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import { getActiveCohorts } from '../../api/Cohort';
import { CohortId } from '../../api/Cohort/types/Cohort';
import filesAPI from '../../api/files';
import mentorsAPI, {
  CohortAttendance,
  Mentor,
  MentorStatus,
} from '../../api/mentors';
import specializationsAPI, {
  Specialization,
  SpecializationId,
} from '../../api/specializations';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { massAsyncRequest } from '../../utils/api';
import { formatDate } from '../../utils/date';
import {
  decodeQuery,
  encodeQuery,
  getColorByUnicode,
  getColorStatus,
  isMobile,
  isTablet,
} from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { TestId } from '../Testing/TestId';
import {
  AlertState,
  Avatar,
  ButtonsChangeStatusMentor,
  ConfirmButton,
  CopyText,
  Link,
  Modal,
  Select,
  SnackMessage,
  StatusBadge,
  Text,
  TextField,
} from '../common';
import { Dot } from '../common/Dot';
import SelectVenturePopover from '../common/select-venture-popover';
import { SpecializationInput } from '../common/specialization-input';
import {
  TableCell,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';
import AssignMentorsForm from '../forms/assign-mentors';
import VentureTableInfo from '../mentors/venture-table-info';

type FilterType =
  | 'search'
  | 'status'
  | 'assigned'
  | 'venture'
  | 'specialization'
  | 'cohort';

type Filters = { [key in FilterType]: string };
type VentureFilter = { ventureName: string; id: string };

type CohortOption = { label: string; value: CohortId | null };

const filters = {
  status: [
    {
      label: 'All Statuses',
      value: 'none',
    },
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Archived',
      value: 'Archived',
    },
    {
      label: 'Applicant',
      value: 'Applicant',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
  ],
  assigned: [
    {
      label: 'Any assigned',
      value: 'none',
    },
    {
      label: 'Not assigned',
      value: '0',
    },
    {
      label: 'One venture',
      value: '1',
    },
    {
      label: 'Two ventures',
      value: '2',
    },
    {
      label: '3+ ventures',
      value: '3',
    },
  ],
  venture: [
    {
      label: 'empty',
      value: '',
    },
  ],
};

const STooltip = withStyles(() => ({
  tooltip: {
    width: 300,
    padding: 16,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
    boxShadow:
      '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  appliedOnColumn: {
    minWidth: 100,
    justifyContent: 'start',
  },
  filterContainer: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  specializationsFilter: {
    width: 200,
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
  },
  filterActionsAssignArchived: {
    marginLeft: 11,
  },
  filterActionsAssignUnArchive: {
    marginLeft: 2,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  linkedinButton: {
    padding: 10,
  },
  linkedinIcon: {
    color: '#0A66C2',
    fontSize: 20,
  },
  nameColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  assignedColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 32px 0 10px',
    boxSizing: 'border-box',
    textAlign: 'right',
  },
  emailColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  phoneColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  ventureInput: {
    width: 180,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  text: {
    cursor: 'pointer',
    color: COLORS.COLOR_BLUE_DARKENED_10,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  ipadNone: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  iconRemoveFilter: {
    width: 20,
  },
  specializationInput: {
    '& input': {
      '&::placeholder': {
        color: 'currentColor',
        opacity: 1,
      },
    },
  },
}));

const useActivityStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr 40px',
    alignItems: 'center',
    gap: 10,
    width: '100%',
  },
  name: {
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

const getFilterAlias = (filter: FilterType) => {
  const aliases = {
    search: 'Search',
    status: 'Status',
    assigned: 'Assignment',
    venture: 'Venture',
    specialization: 'Specialization',
    cohort: 'Cohort',
  };

  return aliases[filter];
};

function parseFiltersFromURL(search: string): Filters {
  const keys: FilterType[] = [
    'search',
    'status',
    'assigned',
    'venture',
    'specialization',
    'cohort',
  ];
  const parsedFilter = _pick(parseQuery(search), keys);
  const parsedFilterKeys = Object.keys(parsedFilter);
  const filters = parsedFilterKeys.reduce((acc: Filters, k) => {
    const key = k as FilterType;
    const value = parsedFilter[key];
    if (typeof value === 'string') {
      acc[key] = value;
    }
    return acc;
  }, {} as Filters);

  return filters;
}

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

function ActivityTooltip({ rowData }: { rowData: Mentor }) {
  const classes = useActivityStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [cohortAttendance, setCohortAttendance] = useState<CohortAttendance[]>(
    [],
  );

  useEffect(() => {
    const fetchCohortAttendance = async () => {
      try {
        setIsError(false);
        setIsLoading(true);
        const statistics = await mentorsAPI.getMentorStatistics(rowData.id);
        const filteredStatistics = statistics?.cohortAttendance?.length
          ? statistics.cohortAttendance
              .filter((c) => c.totalSessions > 0)
              .sort((a, b) => {
                const prevDate = +new Date(a.cohortCreationDate);
                const nextDate = +new Date(b.cohortCreationDate);
                return nextDate - prevDate;
              })
              .slice(0, 10)
          : [];
        setCohortAttendance(filteredStatistics);
      } catch (e) {
        setIsError(true);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCohortAttendance();
  }, [rowData.id]);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : isError ? (
        <Text variant='normal'>
          Something went wrong... Please try again later.
        </Text>
      ) : !cohortAttendance?.length ? (
        <Text variant='normal'>No statistics yet</Text>
      ) : (
        <div className={classes.listWrapper}>
          {cohortAttendance.map((cohort) => {
            const getPercent = (amount: number, total: number) => {
              if (total === 0) {
                return 0;
              }

              const percent = Math.round((amount / total) * 100);

              return isNaN(percent) ? 0 : percent;
            };

            return (
              <div key={cohort.cohortId} className={classes.list}>
                <Dot color={getColorByUnicode(cohort.cohortName)} />
                <Text variant='normal' className={classes.name}>
                  {cohort.cohortName}
                </Text>
                <Text variant='normal'>
                  {getPercent(cohort.sessionsAttended, cohort.totalSessions)}%
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function MentorsTable() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const { user, hasAccessToAction } = useContext(UserContext);
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const filtersFromURL = useMemo(
    () => parseFiltersFromURL(location.search),
    [location.search],
  );

  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [selectedMentorIds, setSelectedMentorIds] = useState<Mentor['id'][]>(
    [],
  );
  const [cohorts, setCohorts] = useState<Array<CohortOption>>([]);

  const mentorsRequest = useCallback(
    async (filters: Filters, page: number) => {
      if (!user?.id) {
        throw new Error('User is not defined');
      }

      if (filters.search) {
        return await mentorsAPI.search(filters.search);
      } else {
        const assignedMoreThanNum = filters.assigned === '3' ? 2 : -1;
        const assignedNum = [undefined, 'none', '3'].includes(filters.assigned)
          ? -1
          : +filters.assigned;
        const venture = filters.venture
          ? (decodeQuery(filters.venture) as VentureFilter)
          : null;
        const ventureId = venture ? venture.id : undefined;
        return await mentorsAPI.getMentorsAdvancedFilters(
          {
            tenantId: user.id,
            status: filters.status,
            specializationId: filters.specialization as SpecializationId,
            assignedNum,
            assignedMoreThanNum,
            ventureId,
            cohortId: filters.cohort,
          },
          page,
        );
      }
    },
    [user],
  );

  const statusSelectedMentors = useMemo(() => {
    if (!selectedMentorIds) {
      return undefined;
    }
    const selectedMentors = mentors.filter((mentor) =>
      selectedMentorIds.includes(mentor.id),
    );

    const activeSelectedMentors = selectedMentors.filter(
      (mentor) => mentor.status === 'Active',
    );

    const archivedSelectedMentors = selectedMentors.filter(
      (mentor) => mentor.status === 'Archived',
    );

    let disabledButton;
    if (
      activeSelectedMentors.length > 0 &&
      archivedSelectedMentors.length === 0
    ) {
      disabledButton = 'active';
    }
    if (
      archivedSelectedMentors.length > 0 &&
      activeSelectedMentors.length === 0
    ) {
      disabledButton = 'archived';
    }

    return {
      activeSelectedMentors,
      archivedSelectedMentors,
      disabledButton,
    };
  }, [selectedMentorIds, mentors]);

  const [loadedVenturesById, setLoadedVenturesById] = useState<{
    [x: string]: Venture[];
  }>({});
  const [mentorsLogos, setMentorsLogos] = useState<{
    [x: string]: string | null;
  }>({});
  // TODO: use it when sort will be implemented
  // const [sortBy, setSortBy] = useState<SortByValueType>();
  const [searchValue, setSearchValue] = useState(filtersFromURL.search || '');
  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const perPage = useMemo(
    () => (!filtersFromURL?.search ? 10 : undefined),
    [filtersFromURL],
  );
  const [isAssigningMentor, setIsAssigningMentor] = useState<{
    [x: string]: boolean;
  }>({});
  const [isSendingProfileUpdate, setIsSendingProfileUpdate] = useState(false);
  const debouncedTimer = useRef<number>();
  const { enqueueSnackbar } = useSnackbar();

  // TODO: use it when sort will be implemented
  // const sortedList = useMemo(() => {
  //   if (sortBy) {
  //     const sorted = _sortBy(mentors, [sortBy?.field]);
  //     return sortBy.direction === 'desc' ? sorted.reverse() : sorted;
  //   }
  //   return mentors;
  // }, [mentors, sortBy]);

  const showClearFilter = useMemo(() => {
    return !!searchValue || Object.values(filtersFromURL).some((v) => !!v);
  }, [filtersFromURL, searchValue]);

  const showActivityTooltip = useMemo(() => {
    const hasCohorts = cohorts.length > 1;
    const groupStatisticsEnabled = user?.groupStatisticsEnabled;
    const hasAccess = hasAccessToAction('mentor.statistics');
    return hasCohorts && groupStatisticsEnabled && hasAccess;
  }, [cohorts.length, user?.groupStatisticsEnabled, hasAccessToAction]);

  const loadMentors = async (filter: Filters, currentPage: number) => {
    try {
      setMentors([]);
      setSelectedMentorIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (!filter?.search && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedMentors = await mentorsRequest(filter, currentPage - 1);
      if (loadedMentors.length === 0) {
        throw new Error('404');
      }
      setMentors(loadedMentors);
      setIsLoading(false);

      if (loadedMentors.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextMentors = await mentorsRequest(filter, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextMentors.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const loadMentorsLogos = async (mentorsList: Mentor[]) => {
    try {
      const mentorImages = mentorsList.map((mentor: Mentor) => ({
        id: mentor.id,
        logo: mentor.logo,
      }));
      setMentorsLogos(() => {
        const sources = mentorImages.reduce((res, mentor) => {
          res[mentor.id] = mentor.logo ? '' : null;
          return res;
        }, {} as { [x: string]: string | null });
        return sources;
      });
      const shouldBeLoaded = mentorImages.filter(
        (mentorImage) => !!mentorImage.logo,
      );
      const logos = shouldBeLoaded.map((mentorImage) => mentorImage.logo);
      if (!logos.length) {
        return false;
      }
      const loadedLogos = await filesAPI.getFileLogos(logos as string[]);

      const nextState = shouldBeLoaded.reduce(
        (res, mentorImage, mentorImageIndex) => {
          res[mentorImage.id] = loadedLogos[mentorImageIndex];
          return res;
        },
        {} as { [x: string]: string },
      );
      return setMentorsLogos((prevMentors) => ({
        ...prevMentors,
        ...nextState,
      }));
    } catch (e: any) {
      console.log('loadMentorsLogos error');
      return false;
    }
  };

  const handleFilterUpdate = useCallback(
    (field: FilterType, value: string | undefined) => {
      if (!value || value === 'none') {
        history.replace(
          `${location.pathname}?${stringifyQuery({
            ...filtersFromURL,
            [field]: undefined,
          })}`,
        );
      } else if (field === 'search') {
        history.replace(
          `${location.pathname}?${stringifyQuery({ [field]: value })}`,
        );
      } else {
        history.replace(
          `${location.pathname}?${stringifyQuery({
            ...filtersFromURL,
            [field]: value,
            search: undefined,
          })}`,
        );
      }
    },
    [filtersFromURL, history, location.pathname],
  );

  const handleClearFilters = useCallback(() => {
    if (searchValue) {
      setSearchValue('');
    }
    history.replace(location.pathname);
  }, [history, location, searchValue]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate('search', e.target.value.trim());
        } else {
          handleFilterUpdate('search', undefined);
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  // TODO: use it when sort will be implemented
  // const handleSortTable = useCallback(
  //   (field: string, direction: 'asc' | 'desc') => {
  //     console.log('sort');
  //     setSortBy({ field, direction });
  //   },
  //   []
  // );

  const handleVenturesLoaded = useCallback(
    (mentorId: Mentor['id']) => (loadedVentures: Venture[]) => {
      setMentors((prevMentors) =>
        prevMentors.map((mentor) =>
          mentor.id === mentorId
            ? { ...mentor, assigned: loadedVentures.length }
            : mentor,
        ),
      );
      setLoadedVenturesById((prevState) => ({
        ...prevState,
        [mentorId]: loadedVentures,
      }));
    },
    [],
  );

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
        ...filtersFromURL,
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [filtersFromURL, location.pathname, history],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const mentorsIds = mentors.map((mentor) => mentor.id);
        return setSelectedMentorIds([...mentorsIds]);
      }
      return setSelectedMentorIds([]);
    },
    [mentors],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, mentorId: Mentor['id']) => {
      if (checked) {
        return setSelectedMentorIds(_uniq([...selectedMentorIds, mentorId]));
      }
      return setSelectedMentorIds(
        selectedMentorIds.filter((id) => id !== mentorId),
      );
    },
    [selectedMentorIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const mentorId = rowData.id;
      handleRowSelect(!selectedMentorIds.includes(mentorId), mentorId);
    },
    [selectedMentorIds, handleRowSelect],
  );

  const handleAssingSuccessForm = useCallback(
    (mentorId: Mentor['id'], handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
        all,
      }: {
        assigned: string[];
        removed: string[];
        all: Venture[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningMentor((prev) => ({ ...prev, [mentorId]: true }));
          const assignedRequests = assigned.map(
            (ventureId) => () => venturesAPI.assignMentor(ventureId, mentorId),
          );
          const removedRequests = removed.map(
            (ventureId) => () =>
              venturesAPI.removeMentorAssignment(ventureId, mentorId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          handleVenturesLoaded(mentorId)(all);
          handleModalClose();
          setIsAssigningMentor((prev) => ({ ...prev, [mentorId]: false }));
        } catch (e: any) {
          console.log('error', 'e');
        }
      },
    [handleVenturesLoaded],
  );

  const handleSendMentorUpdateProfiles = async (onClose: () => {}) => {
    try {
      setIsSendingProfileUpdate(true);
      await mentorsAPI.sendMentorUpdateInvites(selectedMentorIds);
      enqueueSnackbar('The profile update requests were successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
      setSelectedMentorIds([]);
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const handleSendMentorUpdate = async (
    onClose: () => any,
    mentorId: string,
  ) => {
    try {
      setIsSendingProfileUpdate(true);
      await mentorsAPI.sendMentorUpdateInvites([mentorId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  useEffect(() => {
    loadMentors(filtersFromURL, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersFromURL, page]);

  useEffect(() => {
    if (mentors.length) {
      loadMentorsLogos(mentors);
    }
  }, [mentors]);

  useEffect(() => {
    specializationsAPI.getAll().then(setSpecializations);
    getActiveCohorts()
      .then((vs) =>
        vs.map((v) => ({
          value: v.id,
          label: v.name,
        })),
      )
      .then((vs) => setCohorts([{ label: 'All groups', value: null }, ...vs]))
      .catch(() =>
        enqueueSnackbar('Could not load groups details', { variant: 'error' }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.container} data-testid='mentors-table'>
      {hasAccessToAction('mentor.filters') && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            <div
              className={cn(classes.filterItem, classes.mobileNone)}
              data-testid={getDataTestId(
                'mentor-filter-search',
                !!searchValue,
              )}>
              <TextField
                className={cn(
                  classes.searchInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={searchValue}
                onChange={handleSearch}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                small
              />
            </div>
            <div
              className={cn(classes.filterItem, classes.mobileNone)}
              data-testid={getDataTestId(
                'mentor-filter-status',
                !!filtersFromURL.status &&
                  filtersFromURL.status !== filters.status[0].value,
              )}>
              <TestId testId={'mentor-status-filter'}>
                <Select
                  className={cn(
                    classes.filterSelect,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={filtersFromURL.status || filters.status[0].value}
                  options={filters.status}
                  onSelect={(item) => handleFilterUpdate('status', item?.value)}
                  isActive={
                    !!filtersFromURL.status &&
                    filtersFromURL.status !== filters.status[0].value
                  }
                />
              </TestId>
            </div>
            <div
              className={cn(
                classes.filterItem,

                classes.mobileNone,
              )}>
              <TestId testId={'mentor-filter-specialization'}>
                <SpecializationInput
                  disableSearch
                  allSpecializations
                  multiple={false}
                  size={'small'}
                  className={cn(
                    classes.filterSelect,
                    classes.specializationsFilter,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={filtersFromURL.specialization as SpecializationId}
                  onChange={(value) =>
                    handleFilterUpdate('specialization', value)
                  }
                  textFieldProps={{
                    className: classes.specializationInput,
                    placeholder: 'Specialization',
                  }}
                  isActive={!!filtersFromURL.specialization}
                />
              </TestId>
            </div>
            <div
              className={cn(classes.filterItem, classes.mobileNone)}
              data-testid={getDataTestId(
                'mentor-filter-assigned',
                !!filtersFromURL.assigned &&
                  filtersFromURL.assigned !== filters.assigned[0].value,
              )}>
              <Select
                className={cn(
                  classes.filterSelect,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={filtersFromURL.assigned || filters.assigned[0].value}
                options={filters.assigned}
                onSelect={(item) => handleFilterUpdate('assigned', item?.value)}
                isActive={
                  !!filtersFromURL.assigned &&
                  filtersFromURL.assigned !== filters.assigned[0].value
                }
              />
            </div>
            <div
              className={cn(classes.filterItem, classes.ipadNone)}
              data-testid={getDataTestId(
                'mentor-filter-venture',
                !!filtersFromURL.venture,
              )}>
              <SelectVenturePopover
                className={cn(
                  classes.ventureInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                onChange={(val) =>
                  handleFilterUpdate('venture', encodeQuery(val))
                }
                value={
                  filtersFromURL.venture
                    ? decodeQuery(filtersFromURL.venture)
                    : undefined
                }
                isActive={!!filtersFromURL.venture}
                small
              />
            </div>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'session-filter-cohorts',
                !!filtersFromURL.cohort,
              )}>
              <Select
                className={cn(classes.filterSelect)}
                value={
                  filtersFromURL.cohort
                    ? filtersFromURL.cohort
                    : cohorts?.[0]?.value
                }
                options={cohorts}
                onSelect={(item) => {
                  handleFilterUpdate('cohort', item?.value || undefined);
                }}
                isActive={!!filtersFromURL.cohort}
              />
            </div>
            {showClearFilter && (
              <Tooltip title='Clear all filters'>
                <IconButton
                  data-testid='button-remove-filter'
                  className={classes.containerRemoveFilter}
                  onClick={() => handleClearFilters()}>
                  <img
                    src='/filter.svg'
                    alt='Remove filter'
                    className={classes.iconRemoveFilter}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {selectedMentorIds.length > 0 && (
            <div className={classes.filterActions}>
              <Text variant='normal'>{selectedMentorIds.length} selected</Text>
              {hasAccessToAction('mentor.details.update') && (
                <Modal
                  title={`Archive ${rb('mentors')}`}
                  caption={`Do you really want to archive ${
                    statusSelectedMentors?.activeSelectedMentors.length
                  } ${
                    statusSelectedMentors?.activeSelectedMentors.length === 1
                      ? rb('mentor')
                      : rb('mentors')
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusMentor
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedMentors?.activeSelectedMentors
                      }
                      status={MentorStatus.ARCHIVED}
                      updateTable={() => loadMentors(filtersFromURL, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignArchived,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedMentors?.disabledButton === 'archived'
                        }>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('mentor.details.update') && (
                <Modal
                  title={`Un-Archive ${rb('mentors')}`}
                  caption={`Do you really want to un-archive ${
                    statusSelectedMentors?.archivedSelectedMentors.length
                  } ${
                    statusSelectedMentors?.activeSelectedMentors.length === 1
                      ? rb('mentor')
                      : rb('mentors')
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusMentor
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedMentors?.archivedSelectedMentors
                      }
                      status={MentorStatus.ACTIVE}
                      updateTable={() => loadMentors(filtersFromURL, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Un-Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignUnArchive,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedMentors?.disabledButton === 'active'
                        }>
                        <UnarchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('mentor.details.update') && (
                <ConfirmButton
                  title='Send Mass Profile Update Requests'
                  loading={isSendingProfileUpdate}
                  body='Your are about to send a number of temporary links to selected participants, so that they update their profiles. Each link can work only 1 time, and is available for 48 hours only.'
                  successProps={{
                    btnLabel: 'Apply',
                    onSuccess: handleSendMentorUpdateProfiles,
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Send a link to request a profile update'>
                      <IconButton onClick={onClick}>
                        <AccountCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div ref={containerRef}>
        {mentors.length > 0 ? (
          <div>
            <Table
              onRowClick={
                hasAccessToAction('mentor.details.update')
                  ? handleRowClick
                  : () => {}
              }
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (mentors.length > 10 ? mentors.length : 10) + 56}
              rowHeight={64}
              rowCount={mentors.length}
              rowGetter={({ index }) => mentors[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`mentor-table-row-${rowProps.index}`}
                  selected={selectedMentorIds.includes(rowProps.rowData.id)}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={
                  !isTablet() && hasAccessToAction('mentor.details.update')
                    ? 72
                    : 20
                }
                minWidth={
                  !isTablet() && hasAccessToAction('mentor.details.update')
                    ? 72
                    : 20
                }
                headerRenderer={() => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('mentor.details.update') && (
                        <Checkbox
                          checked={selectedMentorIds.length === mentors.length}
                          color='primary'
                          indeterminate={
                            selectedMentorIds.length > 0 &&
                            selectedMentorIds.length !== mentors.length
                          }
                          onChange={(e) => handleAllRowSelect(e.target.checked)}
                        />
                      )}
                  </div>
                )}
                cellRenderer={({ cellData }) => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('mentor.details.update') && (
                        <Checkbox
                          data-testid='mentor-table-row-checkbox'
                          checked={selectedMentorIds.includes(cellData)}
                          color='primary'
                          onChange={(e) =>
                            handleRowSelect(e.target.checked, cellData)
                          }
                        />
                      )}
                  </div>
                )}
              />
              {!isMobile() && (
                <Column
                  dataKey='logo'
                  className={cn(classes.column, classes.mobileNone)}
                  width={44}
                  minWidth={44}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData }) => {
                    return (
                      <div>
                        <Avatar
                          src={mentorsLogos[rowData.id]}
                          name={`${rowData.firstName[0]}${rowData.lastName[0]}`}
                          size='32'>
                          {typeof mentorsLogos[rowData.id] === 'string' &&
                          !mentorsLogos[rowData.id] ? (
                            <CircularProgress size={18} color='inherit' />
                          ) : undefined}
                        </Avatar>
                      </div>
                    );
                  }}
                />
              )}
              <Column
                dataKey='name'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={172}
                minWidth={isMobile() ? 120 : 172}
                maxWidth={357}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ rowData }) => (
                  <TableCell truncated>
                    <STooltip
                      title={<ActivityTooltip rowData={rowData as Mentor} />}
                      disableHoverListener={!showActivityTooltip}
                      disableFocusListener={!showActivityTooltip}
                      disableTouchListener={!showActivityTooltip}>
                      <MaterialLink
                        data-testid='mentor-table-name'
                        onClick={stopPropagation}
                        component={Link}
                        className={CLASS_TRACKING.MENTOR_LINK}
                        to={{
                          pathname: getRoutePath(Pages.MENTOR_DETAILS, {
                            mentorId: rowData.id,
                          }),
                          state: {
                            prevPath: currentPath,
                          },
                        }}>
                        {rowData.firstName} {rowData.lastName}
                      </MaterialLink>
                    </STooltip>
                  </TableCell>
                )}
              />
              {!isMobile() && (
                <Column
                  dataKey='linkedInProfile'
                  className={cn(classes.column)}
                  width={60}
                  minWidth={60}
                  maxWidth={76}
                  flexGrow={1}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData, cellData }) =>
                    cellData ? (
                      <div>
                        <Tooltip
                          title={`${rowData.firstName} ${rowData.lastName}'s LinkedIn profile`}>
                          <IconButton
                            onClick={stopPropagation}
                            target='_blank'
                            href={cellData}
                            className={classes.linkedinButton}>
                            <LinkedInIcon className={classes.linkedinIcon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <div></div>
                    )
                  }
                />
              )}
              <Column
                dataKey='status'
                className={classes.column}
                width={100}
                minWidth={100}
                maxWidth={150}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const currentVariant = getColorStatus(cellData);

                  return (
                    <StatusBadge status={cellData} variant={currentVariant} />
                  );
                }}
              />
              {hasAccessToAction('mentor.assignemnts.viewTable') &&
                (filtersFromURL.status === 'Applicant' ? (
                  <Column
                    dataKey='dateOfCreation'
                    className={cn(classes.column, classes.appliedOnColumn)}
                    headerClassName={classes.appliedOnColumn}
                    width={120}
                    minWidth={100}
                    headerRenderer={() => (
                      <TestId testId={'applied-on-column'}>
                        <TableHeadCell>Applied On</TableHeadCell>
                      </TestId>
                    )}
                    cellRenderer={({ cellData, rowData }) => (
                      <TableCell onClick={stopPropagation}>
                        <Typography variant={'body2'}>
                          {cellData ? formatDate(cellData) : '-'}
                        </Typography>
                      </TableCell>
                    )}
                  />
                ) : (
                  <Column
                    dataKey='assigned'
                    className={cn(classes.column, classes.assignedColumn)}
                    headerClassName={classes.assignedColumn}
                    width={101}
                    minWidth={60}
                    headerRenderer={() => (
                      <TestId testId={'assigned-column'}>
                        <TableHeadCell>Assigned</TableHeadCell>
                      </TestId>
                    )}
                    cellRenderer={({ cellData, rowData }) => (
                      <TableCell onClick={stopPropagation}>
                        {cellData ? (
                          <VentureTableInfo
                            mentorId={rowData.id}
                            initialVentures={loadedVenturesById[rowData.id]}
                            onLoaded={handleVenturesLoaded(rowData.id)}>
                            {hasAccessToAction('venture.filters') ? (
                              <MaterialLink
                                onClick={stopPropagation}
                                component={Link}
                                to={getRoutePath(Pages.VENTURES_MENTOR, {
                                  mentorFilter: encodeQuery({
                                    id: rowData.id,
                                    mentorName: `${rowData.firstName} ${rowData.lastName}`,
                                  }),
                                })}>
                                {cellData}
                              </MaterialLink>
                            ) : (
                              <TableCell truncated className={classes.text}>
                                {cellData}
                              </TableCell>
                            )}
                          </VentureTableInfo>
                        ) : (
                          <div>–</div>
                        )}
                      </TableCell>
                    )}
                  />
                ))}
              {!isTablet() && (
                <Column
                  dataKey='email'
                  className={cn(classes.column, classes.emailColumn)}
                  headerClassName={classes.emailColumn}
                  width={256}
                  minWidth={256}
                  maxWidth={415}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                  cellRenderer={({ cellData }) => (
                    <CopyText onClick={stopPropagation} text={cellData}>
                      <TableCell onClick={stopPropagation} truncated>
                        <MaterialLink href={`mailto:${cellData}`}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                    </CopyText>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='phone'
                  className={cn(classes.column, classes.phoneColumn)}
                  headerClassName={classes.phoneColumn}
                  width={124}
                  minWidth={124}
                  maxWidth={205}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Phone</TableHeadCell>}
                  cellRenderer={({ cellData }) =>
                    cellData ? (
                      <CopyText onClick={stopPropagation} text={cellData}>
                        <TableCell truncated>{cellData}</TableCell>
                      </CopyText>
                    ) : (
                      <div>–</div>
                    )
                  }
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='actions'
                  className={cn(classes.column, classes.justifyContentEnd)}
                  minWidth={40}
                  width={160}
                  headerRenderer={() => <div />}
                  cellRenderer={({ rowData }) => (
                    <TableCell onClick={stopPropagation}>
                      <div className={classes.actionsCell}>
                        {hasAccessToAction('mentor.details.update') && (
                          <Modal
                            title='New assignment'
                            caption={`${rowData.firstName} ${rowData.lastName}`}
                            contentRenderer={({ handleClose }) => (
                              <AssignMentorsForm
                                mentorId={rowData.id}
                                loading={isAssigningMentor[rowData.id]}
                                onLoaded={handleVenturesLoaded(rowData.id)}
                                ventures={loadedVenturesById[rowData.id]}
                                onSuccess={handleAssingSuccessForm(
                                  rowData.id,
                                  handleClose,
                                )}
                                onCancel={handleClose}
                              />
                            )}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Assign'>
                                <IconButton
                                  data-testid='icon-button-assign'
                                  onClick={onClick}
                                  className={CLASS_TRACKING.LIST_QUICK_ACTION}>
                                  <HowToRegIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            width={720}
                          />
                        )}
                        {hasAccessToAction('mentor.details.update') && (
                          <Tooltip title='Edit'>
                            <IconButton
                              data-testid='icon-button-edit'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.MENTOR_DETAILS, {
                                  mentorId: rowData.id,
                                }),
                                state: {
                                  prevPath: currentPath,
                                },
                              }}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('mentor.notes.view') && (
                          <Tooltip title='Notes'>
                            <IconButton
                              data-testid='icon-button-notes'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={getRoutePath(Pages.MENTOR_NOTES, {
                                mentorId: rowData.id,
                              })}>
                              <PlaylistAddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('mentor.details.update') && (
                          <ConfirmButton
                            title='Send Single Profile Update Request'
                            loading={isSendingProfileUpdate}
                            body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                            successProps={{
                              btnLabel: 'Ok',
                              onSuccess: (onClose) =>
                                handleSendMentorUpdate(onClose, rowData.id),
                            }}
                            cancelProps={{
                              btnLabel: 'Cancel',
                            }}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Send a link to request a profile update'>
                                <IconButton onClick={onClick}>
                                  <AccountCircleIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                        )}
                      </div>
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  {filtersFromURL.venture &&
                  Object.keys(filtersFromURL).length === 1 ? (
                    <Text variant='normal' testid='mentor-table-no-items'>
                      Currently, your venture does not have any assigned
                      {rb('mentors')}. Pease don't hesitate to contact your
                      Program Administrator to find out more about new{' '}
                      {rb('mentor')}
                      assignments to your team.
                    </Text>
                  ) : (
                    <Text variant='normal' testid='mentor-table-no-items'>
                      This panel contains a list of {rb('mentors')}
                      {!isEmpty(filtersFromURL) &&
                        (() => {
                          const filterItems = Object.entries(filtersFromURL);
                          return (
                            <>
                              {' '}
                              filtered by{' '}
                              {filterItems.map(([key, value], index) => {
                                const getReadableValue = (
                                  key: FilterType,
                                  value: string,
                                ) => {
                                  switch (key) {
                                    case 'specialization':
                                      return (
                                        specializations.find(
                                          (item) => item.id === value,
                                        )?.name ?? value
                                      );
                                    case 'cohort':
                                      return (
                                        cohorts.find(
                                          (item) => item.value === value,
                                        )?.label ?? value
                                      );
                                    case 'venture':
                                      const decodedValue = decodeQuery(
                                        value,
                                      ) as VentureFilter;
                                      const ventureName =
                                        decodedValue?.ventureName ?? '';
                                      return ventureName;
                                    default:
                                      return value;
                                  }
                                };
                                const filterAlias = getFilterAlias(
                                  key as FilterType,
                                );
                                const filterReadableValue = getReadableValue(
                                  key as FilterType,
                                  value,
                                );
                                return (
                                  <Text variant='normal' bold key={index}>
                                    {filterAlias}: {filterReadableValue}
                                    {index === filterItems.length - 1
                                      ? ''
                                      : ', '}
                                  </Text>
                                );
                              })}
                            </>
                          );
                        })()}
                      .<br />
                      {hasAccessToAction('mentor.create')
                        ? 'We were not able to find information you requested, but feel free to add something new!'
                        : ''}
                    </Text>
                  )}
                  {hasAccessToAction('mentor.create') && (
                    <MaterialButton
                      component={Link}
                      to={Pages.NEW_MENTOR}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      {rb('mentor-u')}
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <TableFooter
            page={page}
            onPageChange={handlePageChange}
            disabled={isLoading}
            isLoading={isNextPageLoading}
            hasNextPage={mentors.length > 0 && hasNextPage}
          />
        )}
      </div>
    </Paper>
  );
}

export default MentorsTable;
