export enum Role {
  Admin = 'ROLE_Admin',
  Manager = 'ROLE_Manager',
  Mentor = 'ROLE_Mentor',
  Founder = 'ROLE_Founder',
  CommunityMember = 'ROLE_CommunityMember',
}

export function isRole(s: string): s is Role {
  return Object.values(Role).includes(s as Role);
}
