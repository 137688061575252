import React, { ReactElement, useMemo } from 'react';
import { AdvisorProfile } from '../../../../components/Advisors/AdvisorProfile';
import { WithTitle } from '../../../../components/Pages/Content/WithTitle';
import { PageLoader } from '../../../../components/common';
import { getRoutePath, Pages } from '../../../../router/constants';
import { Book } from '../common/Book/Book';
import * as BookActions from '../common/Book/types/Actions';
import { useBook } from '../common/Book/useBook';
import { PageWrapper } from '../common/PageWrapper';
import { useProfile } from './useProfile';

export function Profile() {
  const [state] = useProfile();
  const [bookState, bookDispatch] = useBook();
  const path = getRoutePath(Pages.PUBLIC_ADVISORS, {
    tenantId: state.payload.tenantId,
  });

  const content = useMemo((): ReactElement => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return <PageLoader />;

      case 'Ready': {
        const advisor = state.payload.advisor;
        const tags = state.payload.specializations.map((i) => i.name);
        return (
          <AdvisorProfile
            avatar={advisor.logo ?? undefined}
            firstName={advisor.firstName}
            lastName={advisor.lastName}
            profileUrl={window.location.href.split('#')[0]}
            description={advisor.longDescription}
            excerpt={advisor.shortDescription}
            tags={tags}
            linkedIn={advisor.linkedInProfile ?? undefined}
            rate={advisor.rate}
            onBook={() => {
              bookDispatch(
                BookActions.book({
                  advisor: {
                    avatar: state.payload.advisor.logo ?? undefined,
                    lastName: state.payload.advisor.lastName,
                    firstName: state.payload.advisor.firstName,
                    id: state.payload.advisor.id,
                    rate: state.payload.advisor.rate,
                  },
                  tenantId: state.payload.tenantId,
                  timeZone: state.payload.timeZone,
                  strictOfficeHoursScheduling:
                    state.payload.strictOfficeHoursScheduling,
                }),
              );
            }}
            bookLoading={bookState.type === 'BookingLoading'}
          />
        );
      }
    }
  }, [state, bookState.type, bookDispatch]);

  return (
    <PageWrapper tenantId={state.payload.tenantId}>
      <WithTitle back={path} title={''}>
        {content}
      </WithTitle>
      <Book state={bookState} dispatch={bookDispatch} />
    </PageWrapper>
  );
}
