import { LoadErr, Loading, State } from './types/State';

export const isLoading = (s: State): s is Loading | LoadErr => {
  switch (s.type) {
    case 'Loading':
    case 'LoadErr':
      return true;
    case 'Ready':
    case 'RemoveConfirmation':
    case 'Removing':
    case 'RemoveErr':
    case 'RemoveSuccess':
      return false;
  }
};
