import { SpecializationId } from '../../../../../api/specializations';

export interface None {
  type: 'None';
}

export const none = (): None => ({ type: 'None' });

export interface Search {
  type: 'Search';
  value: string;
}

export const search = (value: string): Search => ({ type: 'Search', value });

export interface Specialization {
  type: 'Specialization';
  value: SpecializationId;
}

export const specialization = (value: SpecializationId): Specialization => ({
  type: 'Specialization',
  value,
});

export type Filter = None | Search | Specialization;
