import { Email } from '../../utils/String/Email';
import { Zip } from '../../utils/String/Zip';
import { RFCDate } from '../../utils/date';
import { StringDate } from '../../utils/date/StringDate';
import { FounderId } from '../founders';
import { MentorAdditionalInfo } from '../mentors';
import { TenantId } from '../tenants/Tenant';
import { VentureId } from '../ventures/types/Venture';

// region GoalLikeId
declare const _goalLikeId: unique symbol;

export type GoalLikeId = string & { [_goalLikeId]: 'GoalLikeId' };
// endregion

// region GoalId
declare const _goalId: unique symbol;

export type GoalId = string & { [_goalId]: 'GoalId' };
// endregion

export interface GoalLike {
  id: GoalLikeId;
  mentorAdditionalInfo: MentorAdditionalInfo;
  status: string;
  assigned: number;
  voted: boolean;
  firstName: string;
  lastName: string;
  dateOfBirth: StringDate;
  linkedInProfile: string;
  dateOfEnrollment: StringDate;
  deactivationDate: StringDate;
  country: string;
  state: string;
  city: string;
  address: string;
  zip: Zip;
  email: Email;
  phone: string | undefined;
  tenantId: TenantId;
  logo: string | undefined;
  valid: boolean;
  emailValid: boolean;
}

export interface CreateGoal {
  tenantId: TenantId;
  ownerId: FounderId;
  ventureId: VentureId;
  title: string;
  status: GoalStatus;
  description?: string;
  startDate: StringDate | null;
  targetDate: StringDate | null;
  progress: number;
  attachmentRefs?: string;
}

export interface Goal extends CreateGoal {
  id: GoalId;
  creationDate: RFCDate;
  goalLikes: GoalLike[];
  valid: boolean;
}

export type GoalStatus =
  | 'NOT_STARTED'
  | 'ON_TRACK'
  | 'AT_RISK'
  | 'IN_TROUBLE'
  | 'COMPLETED'
  | 'ARCHIVED';

const _statuses: { [k in GoalStatus]: GoalStatus } = {
  NOT_STARTED: 'NOT_STARTED',
  ON_TRACK: 'ON_TRACK',
  AT_RISK: 'AT_RISK',
  IN_TROUBLE: 'IN_TROUBLE',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'ARCHIVED',
};

export const goalStatuses: GoalStatus[] = Object.values(_statuses);

export interface NewGoalTask {
  goalId: string;
  title: string;
  status: string;
}

export interface GoalTask extends NewGoalTask {
  id: string;
  description: string;
  startDate: string;
  targetDate: string;
  creationDate: string;
  attachmentRefs: string | null;
}

export interface GoalLog {
  id: string;
  goalId: string;
  creationDate: string;
  contents: string;
}
