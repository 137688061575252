import { CircularProgress, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useContext, useMemo } from 'react';
import {
  MentorAttendanceReportNumber,
  MentorMonthlyReportItem,
} from '../../api/reports';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import {
  DateRangeValueType,
  ReportTable,
  Status,
} from '../../pages/reports/reports-details-mentor-page';
import { COLORS } from '../../theme/variables';
import { getDateRangeForReport } from '../../utils/date';
import { LogoImage, Button, ChartPie, Text, Toggler } from '../common';
import { DataPieProps } from '../common/chart-pie';
import MentorAttendanceTable from '../tables/table-mentor-attendance';

interface Props {
  dataRangeValue?: DateRangeValueType;
  report?: MentorAttendanceReportNumber;
  additionalReport?: MentorAttendanceReportNumber;
  loading: boolean;
  status: Status | '';
  setStatus: (value: Status | '') => void;
  reportTable: ReportTable;
  currentReportTable?: MentorMonthlyReportItem[];
  isEmpty: boolean;
  isEmptyAdditional: boolean;
}

const useStyles = makeStyles({
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,

    '@media print': {
      width: 'calc(100% + 300px)',
    },
  },
  borderChart: {
    paddingTop: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,

    '@media print': {
      width: 'calc(100% + 300px)',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  content: {
    width: 670,
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 70,
  },
  logoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: 70,

    '@media print': {
      position: 'relative',
    },
  },
  contentEmpty: {
    minHeight: 250,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentEmptyText: {
    width: 500,
    textAlign: 'center',
  },
  tooltipColor: {
    width: 15,
    height: 15,
    borderRadius: '50%',
  },
  optionTogglerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
  },
  buttonBackCharts: {
    marginRight: 20,

    '@media print': {
      display: 'none',
    },
  },
  container: {
    '@media print': {
      '-webkit-print-color-adjust': 'exact',
    },
  },
  actionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 40,
    margin: '10px 0 20px',
    rowGap: 10,
  },
});

const getLabelToggler = (value: string) => {
  switch (value.toLowerCase()) {
    case 'great':
      return 'Great';
    case 'ok':
      return 'Acceptable';
    case 'bad':
      return 'Unacceptable';
    default:
      return value;
  }
};

const getTitleTable = (value: string) => {
  switch (value.toLowerCase()) {
    case 'great':
      return 'Great Attendance 100% to 90%';
    case 'ok':
      return 'Acceptable Attendance 89% to 76%';
    case 'bad':
      return 'Unacceptable Attendance 75% to less';
    default:
      return '';
  }
};

function ReportsMentorScreen({
  dataRangeValue,
  report,
  additionalReport,
  loading,
  status,
  setStatus,
  reportTable,
  currentReportTable,
  isEmpty,
  isEmptyAdditional,
}: Props) {
  const { user } = useAuth();
  const { logo } = useContext(UserContext);
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const startDate = dataRangeValue?.startDate;
  const endDate = dataRangeValue?.endDate;
  const textRangeDate = useMemo(() => {
    if (startDate && endDate) {
      return getDateRangeForReport(new Date(startDate), new Date(endDate));
    }
    return '';
  }, [startDate, endDate]);

  const innovatePieData: DataPieProps[] = useMemo(() => {
    return [
      {
        id: 'great',
        label: 'Great',
        value: report ? +report.great : 0,
        color: COLORS.COLOR_GREEN_LIGHTENED_10,
      },
      {
        id: 'ok',
        label: 'Acceptable',
        value: report ? +report.ok : 0,
        color: COLORS.COLOR_ADDITIONAL_GRAY_BLUE_TEXT,
      },
      {
        id: 'bad',
        label: 'Unacceptable',
        value: report ? +report.bad : 0,
        color: COLORS.COLOR_RED_BASE,
      },
    ].filter(({ value }) => value !== 0) as DataPieProps[];
  }, [report]);

  const innovatePieAdditionalData: DataPieProps[] = useMemo(() => {
    return [
      {
        id: 'great',
        label: 'Great',
        value: additionalReport ? +additionalReport.great : 0,
        color: COLORS.COLOR_GREEN_LIGHTENED_10,
      },
      {
        id: 'ok',
        label: 'Acceptable',
        value: additionalReport ? +additionalReport.ok : 0,
        color: COLORS.COLOR_ADDITIONAL_GRAY_BLUE_TEXT,
      },
      {
        id: 'bad',
        label: 'Unacceptable',
        value: additionalReport ? +additionalReport.bad : 0,
        color: COLORS.COLOR_RED_BASE,
      },
    ].filter(({ value }) => value !== 0) as DataPieProps[];
  }, [additionalReport]);

  const handleClickPie = (data: DataPieProps) => {
    setStatus(data.id || '');
  };

  const handleToggle = (value: string) => {
    const curretValue = value as 'bad' | 'great' | 'ok';
    setStatus(curretValue || '');
  };

  const optionToggler =
    innovatePieData.length > 0
      ? innovatePieData.map((data: DataPieProps) => ({
          value: data.id,
          label: (
            <div className={classes.optionTogglerContainer}>
              <div
                className={classes.tooltipColor}
                style={{ backgroundColor: data.color }}
              />
              <Text variant='normal'>{getLabelToggler(data.label)}</Text>
            </div>
          ),
        }))
      : [];

  if (loading || !dataRangeValue) {
    return (
      <div className={classes.contentEmpty}>
        <CircularProgress size={40} color='primary' />
      </div>
    );
  }

  return (
    <div
      data-testid='mentor-progress-report-container'
      className={classes.container}>
      <div data-testid='report-container-list'>
        <div className={classes.border}>
          <div className={cn(classes.title, classes.content)}>
            <div data-testid='report-mentor-attendance-title'>
              <Typography variant='h4'>
                {rb('mentor-u')} Attendance Report of Founder Sessions
              </Typography>
              {textRangeDate && (
                <Text variant='normal'>
                  For the period from {textRangeDate}
                </Text>
              )}
            </div>
            {logo && status && (
              <div className={classes.logoContent}>
                <span
                  className={classes.logo}
                  data-testid='venture-progress-report-include-logo'>
                  <LogoImage
                    className={classes.image}
                    src={logo}
                    alt={user?.programName}
                  />
                </span>
              </div>
            )}
          </div>
        </div>

        {!status ? (
          <>
            {isEmpty && innovatePieData.length === 0 ? (
              <div
                className={classes.contentEmpty}
                data-testid='report-empty-container'>
                <Text variant='normal' className={classes.contentEmptyText}>
                  Unfortunately we were not able to extract analytical data for
                  the dates you selected. Please get in touch with program admin
                  or support@tractionfive.com if you believe this is an
                  unexpected result.
                </Text>
              </div>
            ) : (
              <>
                <div className={classes.borderChart} data-testid='report-chart'>
                  {user?.programName && (
                    <Text variant='normal'>
                      {user?.programName} Performance
                    </Text>
                  )}
                  <ChartPie
                    data={innovatePieData}
                    handleClick={handleClickPie}
                  />
                </div>
                {!isEmptyAdditional && innovatePieAdditionalData.length > 0 && (
                  <div
                    className={classes.borderChart}
                    data-testid='report-chart'>
                    <Text variant='normal'>Average Accross Traction5</Text>
                    <ChartPie data={innovatePieAdditionalData} />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className={classes.actionContainer}>
              <Button
                data-testid='button-back-chart'
                className={classes.buttonBackCharts}
                onClick={() => {
                  setStatus('');
                }}
                variant='outlined'
                startIcon={<ArrowBackIcon />}>
                Back to charts
              </Button>
              <Toggler
                value={status}
                onChange={handleToggle}
                options={optionToggler}
                withIcon={false}
              />
            </div>
            {typeof currentReportTable !== 'undefined' &&
            currentReportTable?.length > 0 ? (
              <div data-testid='mentor-attendance-report-table'>
                <Typography variant='h4'>{getTitleTable(status)}</Typography>
                <MentorAttendanceTable
                  status={status}
                  mentorsReport={reportTable[status]}
                />
              </div>
            ) : (
              <div
                className={classes.contentEmpty}
                data-testid='report-empty-container'>
                <Text variant='normal' className={classes.contentEmptyText}>
                  Unfortunately we were not able to extract analytical data for
                  the dates you selected. Please get in touch with program admin
                  or support@tractionfive.com if you believe this is an
                  unexpected result.
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ReportsMentorScreen;
