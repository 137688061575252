// region KnownRole
export enum KnownModule {
  Analytics = 'analytics',
  Core = 'core',
  Education = 'education',
  Enrollment = 'enrollment',
  OfficeHours = 'officehours',
  Slack = 'slack',
  Goals = 'goals',
}

export const isKnownModule = (v: Module): v is KnownModule =>
  Object.values(KnownModule).includes(v as KnownModule);
// endregion

// region UnknownRole
declare const _tag: unique symbol;
export type UnknownModule = string & { [_tag]: 'UnknownModule' };

export const isUnknownModule = (v: Module): v is UnknownModule =>
  !isKnownModule(v);
// endregion

export type Module = KnownModule | UnknownModule;
