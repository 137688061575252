import { createContext, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import eventsAPI, {
  Event,
  EventFounder,
  EventFullDetails,
  EventId,
  EventMentor,
  EventTag,
} from '../api/events';
import { Founder } from '../api/founders';
import { Mentor } from '../api/mentors';

export interface SessionContextProps {
  session?: Event;
  scheduled: boolean;
  founders: Founder[];
  mentors: Mentor[];
  sessionKey: string;
  updateSession: (
    session: Event,
    updatedFounders?: {
      assigned: { founders: Founder[]; eventFounderList: EventFounder[] };
      removed: string[];
    },
    updatedMentors?: {
      assigned: { mentors: Mentor[]; eventMentorList: EventMentor[] };
      removed: string[];
    },
  ) => any;
  updateScheduled: (state: boolean) => any;
  replaceSession: (fullDetails: EventFullDetails) => any;
  loadSession: (sessionId: EventId) => Promise<any>;
  isSessionLoading: boolean;
  isCancelEvent: boolean;
  setIsCancelEvent: (event: boolean) => any;
  sessionIssues?: EventTag['tagId'][];
  loadSessionIssues: (eventId: string) => Promise<any>;
  updateIssues: (specializations: EventTag['tagId'][]) => any;
  isIssuesLoad: boolean;
  sessionIssuesFull?: EventTag[];
}

export const SessionContext = createContext<SessionContextProps>({
  scheduled: true,
  founders: [],
  mentors: [],
  sessionKey: '',
  updateSession: () => {},
  updateScheduled: () => {},
  replaceSession: () => {},
  loadSession: async () => {},
  isSessionLoading: true,
  isCancelEvent: false,
  setIsCancelEvent: () => {},
  loadSessionIssues: async () => {},
  updateIssues: () => {},
  isIssuesLoad: false,
});

interface SessionProviderProps {
  children: React.ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const { sessionId } = useParams<{ sessionId: string }>();

  const [session, setSession] = useState<Event>();
  const [scheduled, setScheduled] = useState(true);
  const [founders, setFounders] = useState<Founder[]>([]);
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [isSessionLoading, setIsSessionLoading] = useState(true);
  const [isCancelEvent, setIsCancelEvent] = useState(false);
  const [sessionIssues, setSessionIssues] = useState<EventTag['tagId'][]>();
  const [sessionIssuesFull, setSessionIssuesFull] = useState<EventTag[]>();
  const [isIssuesLoad, setIsIssuesLoad] = useState(false);
  const [sessionKey, setSessionKey] = useState('');

  const updateIssues = useCallback((issue: EventTag['tagId'][]) => {
    setSessionIssues(issue);
  }, []);

  const handleEventFullDetails = (fullDetails: EventFullDetails) => {
    setSession(fullDetails.event);
    const assignedFounders = (fullDetails.eventFounderList || []).map(
      (eventFounder) => {
        return {
          ...fullDetails.founderList.find(
            (founder) => founder.id === eventFounder.founderId,
          ),
          assignmentStatus: eventFounder.status,
        } as Founder;
      },
    );
    const assignedMentors = (fullDetails.eventMentorList || []).map(
      (eventMentor) => {
        return {
          ...fullDetails.mentorList.find(
            (mentor) => mentor.id === eventMentor.mentorId,
          ),
          assignmentStatus: eventMentor.status,
        } as Mentor;
      },
    );
    setFounders(assignedFounders);
    setMentors(assignedMentors);
  };

  const replaceSession = useCallback((fullDetails: EventFullDetails) => {
    handleEventFullDetails(fullDetails);
  }, []);

  const updateSession = useCallback(
    (
      session: Event,
      updatedFounders?: {
        assigned: { founders: Founder[]; eventFounderList: EventFounder[] };
        removed: string[];
      },
      updatedMentors?: {
        assigned: { mentors: Mentor[]; eventMentorList: EventMentor[] };
        removed: string[];
      },
    ) => {
      setSession(session);
      if (updatedFounders) {
        setFounders((prevFounders) => {
          const filtered = updatedFounders.removed.length
            ? prevFounders.filter(
                (prevFounder) =>
                  !updatedFounders.removed.includes(prevFounder.id),
              )
            : prevFounders;
          return [
            ...filtered,
            ...updatedFounders.assigned.eventFounderList.map((eventFounder) => {
              return {
                ...updatedFounders.assigned.founders.find(
                  (founder) => founder.id === eventFounder.founderId,
                ),
                assignmentStatus: eventFounder.status,
              } as Founder;
            }),
          ];
        });
      }
      if (updatedMentors) {
        setMentors((prevMentors) => {
          const filtered = updatedMentors.removed.length
            ? prevMentors.filter(
                (prevMentor) => !updatedMentors.removed.includes(prevMentor.id),
              )
            : prevMentors;
          return [
            ...filtered,
            ...updatedMentors.assigned.eventMentorList.map((eventMentor) => {
              return {
                ...updatedMentors.assigned.mentors.find(
                  (mentor) => mentor.id === eventMentor.mentorId,
                ),
                assignmentStatus: eventMentor.status,
              } as Mentor;
            }),
          ];
        });
      }
    },
    [],
  );

  const updateScheduled = useCallback((state: boolean) => {
    setScheduled(state);
  }, []);

  const loadSession = useCallback(async (sessionId: EventId) => {
    try {
      setIsSessionLoading(true);
      const loadedSession = await eventsAPI.getFullEvent(sessionId);
      handleEventFullDetails(loadedSession);
      setIsSessionLoading(false);
    } catch (e: any) {
      setIsSessionLoading(false);
      console.log('error loadSession', e);
    }
  }, []);

  const loadSessionIssues = useCallback(async (eventId: string) => {
    try {
      setIsIssuesLoad(true);
      const issues = await eventsAPI.getTags(eventId);

      setSessionIssuesFull(issues);
      setSessionIssues(issues.map((value) => value.tagId));
      setIsIssuesLoad(false);
    } catch (e: any) {
      setIsIssuesLoad(false);
      console.log('error loadSessionIssies', e);
    }
  }, []);

  useEffect(() => {
    const loadLinks = async (sessionId: string) => {
      const response = await eventsAPI.getSessionKey(sessionId);
      setSessionKey(response);
    };

    loadLinks(sessionId);
  }, [sessionId]);

  const contextValue: SessionContextProps = {
    founders,
    mentors,
    session,
    sessionKey,
    scheduled,
    isSessionLoading,
    loadSession,
    updateSession,
    replaceSession,
    updateScheduled,
    isCancelEvent,
    setIsCancelEvent,
    loadSessionIssues,
    updateIssues,
    sessionIssues,
    sessionIssuesFull,
    isIssuesLoad,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};
