import { Typography } from '@material-ui/core';
import { TestId } from '../../../../components/Testing/TestId';
import { Loading } from '../../../../components/common/ActionIcons/Loading';
import { Inline } from '../../../../components/common/Inline';
import Button from '../../../../components/common/button';
import TextField from '../../../../components/common/text-field';
import { TextWysiwyg } from '../../../../components/common/text-wysiwyg';
import { useDebouncedOnChange } from '../../../../hooks/useDebouncedOnChange';
import { isInvalid, Value } from '../../../../utils/FormValue';
import C from './EditItem.module.scss';

export interface EditItemProps {
  isNew?: boolean;
  title: Value<string, string | undefined, string | undefined>;
  description: Value<string, string | undefined, string | undefined>;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (title: string) => void;
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function EditItem({
  isNew,
  title,
  description,
  onTitleChange,
  onDescriptionChange,
  onSave,
  onCancel,
  isLoading,
}: EditItemProps) {
  const [_title, setTitle] = useDebouncedOnChange(
    title.value ?? '',
    onTitleChange,
    150,
  );
  const [_description, setDescription] = useDebouncedOnChange(
    description.value ?? '',
    onDescriptionChange,
    150,
  );

  return (
    <TestId testId='edit-item'>
      <div className={C.root}>
        <Typography variant='h4'>
          {isNew ? 'Create group' : 'Edit group'}
        </Typography>
        <TestId testId={'title'}>
          <TextField
            value={_title}
            error={isInvalid(title)}
            onChange={(e) => setTitle(e.target.value)}
            label='Name *'
          />
        </TestId>
        <TestId testId={'description'}>
          <TextWysiwyg
            value={_description}
            onChange={(e) => setDescription(e.target.value)}
            error={isInvalid(description)}
            placeholder='Description'
          />
        </TestId>
        <Inline gap={10}>
          <TestId testId={'save'}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={onSave}
              disabled={isLoading}>
              {isLoading ? <Loading /> : 'Save'}
            </Button>
          </TestId>
          <TestId testId={'cancel'}>
            <Button color={'default'} variant={'outlined'} onClick={onCancel}>
              Cancel
            </Button>
          </TestId>
        </Inline>
      </div>
    </TestId>
  );
}
