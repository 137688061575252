import { makeStyles, Typography } from '@material-ui/core';
import { Rating as RatingComponent } from '@material-ui/lab';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { Text } from '.';

interface Props {
  value?: number | null;
  onChange: (event: React.ChangeEvent<{}>, value: number | null) => void;
  label?: string;
  name: string;
  className?: string;
  testid?: string;
  max?: number;
  readOnly?: boolean;
  withCaption?: boolean;
  invalid?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-15px',

    '& > span': {
      width: 24,
      textAlign: 'center',
    },
  },
  invalid: {
    '& .MuiRating-icon': {
      color: '#ffb3b3',
    },
  },
});

function Rating({
  value = 0,
  onChange,
  label,
  name,
  className,
  testid,
  max,
  readOnly,
  withCaption = false,
  invalid = false,
}: Props) {
  const classes = useStyles();
  const arrayStarsCount = useMemo(() => {
    return new Array(max || 5).fill(undefined);
  }, [max]);

  return (
    <div className={cn(classes.container, className)}>
      {label && <Typography variant='h4'>{label}</Typography>}
      <RatingComponent
        className={cn({
          [classes.invalid]: invalid,
        })}
        name={name}
        value={value}
        onChange={onChange}
        data-testid={testid}
        max={max}
        readOnly={readOnly}
        aria-invalid={invalid ? true : undefined}
      />
      {withCaption && (
        <div className={classes.caption}>
          {arrayStarsCount.map((_, starIndex) => (
            <Text key={`star-${starIndex}`} variant='normal'>
              {starIndex}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
}

export default Rating;
