import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import eventsAPI, { Event, EventDetails } from '../../api/events';
import { Role } from '../../api/user/Role';
import {
  Button,
  ConfirmButton,
  PageLoader,
  SnackMessage,
  TextField,
} from '../../components/common';
import AddToCalendarButton from '../../components/common/add-to-calendar-button';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import AcceptanceToggle from '../../components/sessions/acceptance-toggle';
import PageTabs from '../../components/sessions/page-tabs';
import {
  SessionContext,
  SessionProvider,
} from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { ProtectedRoute } from '../../router';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { BR_TEXT, CANCELLATION_REASON } from '../../utils/form';
import { encodeQuery, isMobile } from '../../utils/functions';
import SessionDetailsPage from './session-details-page';
import SessionLinksPage from './session-links-page';
import SessionLogPage from './session-log-page';
import SessionNotesPage from './session-notes-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  inputDescription: {
    marginTop: 15,
  },
});

const getEventDetails = (
  status: string,
  session: Event,
  reason?: string,
): EventDetails => {
  let currentDescription;
  if (reason) {
    currentDescription = `${CANCELLATION_REASON}${reason}${BR_TEXT}${session?.description}`;
  } else {
    currentDescription = session.description;
  }
  return {
    description: currentDescription,
    end: session.end,
    followupReminder: session?.followupReminder,
    id: session.id,
    start: session.start,
    status: status,
    summary: session.summary,
    tenantEventChannelId: session.tenantEventChannelId,
    ventureId: session.ventureId,
    ventureName: session.ventureName,
    emailList: session.emailList,
    physicalLocation: session.physicalLocation,
  };
};

function SessionPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { sessionId } = useParams<{ sessionId: Event['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const { enqueueSnackbar } = useSnackbar();
  const {
    session,
    sessionKey,
    scheduled,
    isSessionLoading,
    sessionIssues,
    loadSession,
    replaceSession,
    setIsCancelEvent,
    loadSessionIssues,
    founders,
    mentors,
  } = useContext(SessionContext);
  const [isInviting, setIsInviting] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { tokenData, hasAccessToAction, hasRole } = useContext(UserContext);
  const [reason, setReason] = useState('');
  const history = useHistory();

  const assignmentStatus = useMemo(() => {
    if (hasRole(Role.Founder)) {
      return (
        founders?.find((f) => f.id === tokenData?.identityid)
          ?.assignmentStatus || null
      );
    } else if (hasRole(Role.Mentor)) {
      return (
        mentors?.find((m) => m.id === tokenData?.identityid)
          ?.assignmentStatus || null
      );
    } else {
      return null;
    }
  }, [founders, hasRole, mentors, tokenData?.identityid]);

  const handleSendInvites = useCallback(
    async (cb: () => any) => {
      try {
        setIsInviting(true);
        const updatedSession = await eventsAPI.sendInvites(sessionId);
        replaceSession(updatedSession);
        enqueueSnackbar('The invitations were sent successfully', {
          variant: 'success',
        });
        setIsInviting(false);
        cb();
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        setIsInviting(false);
        enqueueSnackbar(
          'An error occurred while sending invitations. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        cb();
      }
    },
    [enqueueSnackbar, replaceSession, sessionId],
  );

  const handleCancelSession = useCallback(
    async (cb: () => any) => {
      if (!session) {
        return;
      }
      try {
        setIsCancelled(true);
        await eventsAPI.update(
          sessionId,
          getEventDetails('ARCHIVED', session, reason),
        );
        await eventsAPI.cancelEvent(sessionId);
        await eventsAPI.deleteEvent(sessionId);
        enqueueSnackbar('The meeting was successfully cancelled.', {
          variant: 'success',
        });
        cb();
        history.push(Pages.SESSIONS);
        setIsCancelled(false);
      } catch (error: any) {
        const messageError = error.response?.data?.message;

        enqueueSnackbar(
          'An error occurred at the cancel of the session. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCancelled(false);
        cb();
      }
    },
    [enqueueSnackbar, history, reason, session, sessionId],
  );

  // load session's info
  useEffect(() => {
    if (!session) {
      loadSession(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, !session, loadSession]);

  // load session's issues
  useEffect(() => {
    if (!sessionIssues) {
      loadSessionIssues(sessionId);
    }
  }, [sessionIssues, loadSessionIssues, sessionId]);

  return (
    <BaseLayout user={user} fullHeight>
      {isSessionLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title='Session'
            backLink={
              <Tooltip title='Back to Sessions'>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.SESSIONS}
                  data-testid='button-back-sessions'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            additionalContent={
              isMobile() &&
              hasAccessToAction('session.details.acceptance') && (
                <AcceptanceToggle
                  session={session}
                  assignmentStatus={assignmentStatus}
                />
              )
            }
            actions={[
              {
                key: 'submit-agenda',
                label: 'Submit Agenda',
                hidden: !hasAccessToAction('session.details.submitAgenda'),
                component: (
                  <Button
                    disabled={!sessionKey}
                    onClick={() => {
                      window.open(
                        `${
                          Pages.REPORT_AGENDA_REQUEST
                        }?key=${sessionKey}&email=${encodeQuery(
                          tokenData?.email,
                        )}`,
                        '_blank',
                      );
                    }}
                    data-testid='button-submit-agenda'>
                    Submit Agenda
                  </Button>
                ),
              },
              {
                key: 'submit-founder-notes',
                label: 'Submit Founder Notes',
                hidden: !hasAccessToAction(
                  'session.details.submitFounderNotes',
                ),
                component: (
                  <Button
                    disabled={!sessionKey}
                    onClick={() => {
                      window.open(
                        `${
                          Pages.REPORT_FOUNDER_SESSION_NOTES
                        }?key=${sessionKey}&email=${encodeQuery(
                          tokenData?.email,
                        )}`,
                        '_blank',
                      );
                    }}
                    data-testid='button-submit-founder-notes'>
                    Submit Founder Notes
                  </Button>
                ),
              },
              {
                key: 'invite-all',
                label: 'Invite All',
                hidden: !hasAccessToAction('session.details.inviteAll'),
                component: (
                  <ConfirmButton
                    title='Session'
                    loading={isInviting || isCancelled}
                    body='You are about to send a significant number of invites... Are you ready to notify everyone about this session?'
                    successProps={{
                      btnLabel: 'Yes',
                      onSuccess: handleSendInvites,
                    }}
                    cancelProps={{
                      btnLabel: 'No',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={onClick}
                        variant='contained'
                        data-testid='button-invite-all'
                        disabled={!scheduled || isInviting || isCancelled}>
                        {isInviting ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Invite All'
                        )}
                      </Button>
                    )}
                  />
                ),
              },
              ...(session?.id
                ? [
                    {
                      key: 'add-to-calendar',
                      label: 'Add to Calendar',
                      hidden: !hasAccessToAction(
                        'session.details.addToCalendar',
                      ),
                      component: (
                        <AddToCalendarButton
                          eventId={session.id}
                          eventName={session.summary}
                        />
                      ),
                    },
                  ]
                : []),
              {
                key: 'cancel-event',
                label: 'Cancel Event',
                hidden: !hasAccessToAction('session.details.cancel'),
                component: (
                  <ConfirmButton
                    title='Do you really want to cancel this session?'
                    loading={isInviting || isCancelled}
                    body='We will send a cancellation note to all session participants and move this session to Archive. Do you want to proceed?'
                    successProps={{
                      btnLabel: 'Yes',
                      onSuccess: handleCancelSession,
                    }}
                    cancelProps={{
                      btnLabel: 'No',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={() => {
                          setIsCancelEvent(true);
                          onClick();
                        }}
                        variant='outlined'
                        data-testid='button-cancel-event'
                        disabled={!scheduled || isInviting || isCancelled}>
                        {isInviting ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Cancel Event'
                        )}
                      </Button>
                    )}>
                    <TextField
                      small
                      name='reason'
                      label='Reason'
                      data-testid='modal-reason-input'
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      className={classes.inputDescription}
                    />
                  </ConfirmButton>
                ),
              },
            ]}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs sessionId={sessionId}>
              {!isMobile() &&
                hasAccessToAction('session.details.acceptance') && (
                  <AcceptanceToggle
                    session={session}
                    assignmentStatus={assignmentStatus}
                  />
                )}
            </PageTabs>
            <div className={classes.content}>
              <Router />
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

function withSessionProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  );
}

export default withSessionProvider(SessionPage);

const Router = memo(() => {
  return (
    <Switch>
      <Route
        exact
        path={Pages.SESSIONS_DETAILS}
        component={ProtectedRoute(SessionDetailsPage)}
      />
      <Route exact path={Pages.SESSIONS_NOTES} component={SessionNotesPage} />
      <Route exact path={Pages.SESSION_LOG} component={SessionLogPage} />
      <Route exact path={Pages.SESSIONS_LINKS} component={SessionLinksPage} />
      <Redirect to={Pages.SESSIONS_DETAILS} />
    </Switch>
  );
});
