import { FounderId } from './founders';
import { axiosRequest } from './instance';
import { Checkin, CreateCheckin } from './types/Checkin';
import {
  CreateGoal,
  Goal,
  GoalStatus,
  GoalId,
  NewGoalTask,
  GoalTask,
  GoalLog,
} from './types/Goal';
import { VentureId } from './ventures/types/Venture';

export function createGoal(goal: CreateGoal): Promise<Goal> {
  return axiosRequest
    .post<Goal>({
      url: '/goals',
      data: goal,
      credentials: true,
    })
    .then((r) => r.data);
}

export function updateGoal(goal: Goal): Promise<Goal> {
  return axiosRequest
    .put<Goal>({
      url: '/goals',
      data: goal,
      credentials: true,
    })
    .then((r) => r.data);
}

export function createCheckin(checkin: CreateCheckin): Promise<Checkin> {
  return axiosRequest
    .post<Checkin>({
      url: '/goals/checkins',
      data: checkin,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getOwnerGoals(ownerId: FounderId): Promise<Goal[]> {
  return axiosRequest
    .get<Goal[]>({
      url: `/goals/owners/${ownerId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getActiveOwnerGoals(ownerId: FounderId): Promise<Goal[]> {
  return axiosRequest
    .get<Goal[]>({
      url: `/goals/owners/${ownerId}/all-active`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getOwnerGoalsByStatus(
  ownerId: FounderId,
  status: GoalStatus,
): Promise<Goal[]> {
  return axiosRequest
    .get<Goal[]>({
      url: `/goals/owners/${ownerId}/statuses/${status}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getVentureGoals(ventureId: VentureId): Promise<Goal[]> {
  return axiosRequest
    .get<Goal[]>({
      url: `/goals/ventures/${ventureId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getVentureGoalsByStatus(
  ventureId: VentureId,
  status: GoalStatus,
): Promise<Goal[]> {
  return axiosRequest
    .get<Goal[]>({
      url: `/goals/ventures/${ventureId}/status/${status}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getGoal(goalId: GoalId): Promise<Goal> {
  return axiosRequest
    .get<Goal>({
      url: `/goals/${goalId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function attachFileToGoal(goalId: string, file: File): Promise<string> {
  const formData = new FormData();
  formData.append('name', file.name);
  formData.append('file', file);

  return axiosRequest
    .post<string>({
      url: `/files/goals/${goalId}`,
      data: formData,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getTasks(goalId: string) {
  return axiosRequest
    .get<GoalTask[]>({
      url: `/goals/${goalId}/tasks`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function createTask(task: NewGoalTask) {
  return axiosRequest
    .post<GoalTask>({
      url: '/goals/tasks',
      data: task,
      credentials: true,
    })
    .then((r) => r.data);
}

export function removeTask(taskId: string) {
  return axiosRequest
    .delete<GoalTask>({
      url: `/goals/tasks/${taskId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function updateTask(task: GoalTask) {
  return axiosRequest
    .put<GoalTask>({
      url: '/goals/tasks',
      data: task,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getLogs(goalId: string) {
  return axiosRequest
    .get<GoalLog[]>({
      url: `/goals/${goalId}/logs`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getCheckins(goalId: string) {
  return axiosRequest
    .get<Checkin[]>({
      url: `/goals/${goalId}/checkins`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function updateCheckin(checkin: Checkin) {
  return axiosRequest
    .put<Checkin>({
      url: '/goals/checkins',
      data: checkin,
      credentials: true,
    })
    .then((r) => r.data);
}

export function removeCheckin(checkinId: string) {
  return axiosRequest
    .delete<Checkin>({
      url: `/goals/checkins/${checkinId}`,
      credentials: true,
    })
    .then((r) => r.data);
}
