import { Alert, AlertTitle, AlertProps } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';

interface AlertStateProps extends AlertProps {
  type: AlertProps['severity'];
  title?: string;
  className?: string;
}

const useStyles = makeStyles({
  alertState: {
    fontSize: '13px',
    marginBottom: '24px'
  },
  error: {
    color: COLORS.COLOR_GRAY_DARKENED_15,
    background: COLORS.COLOR_RED_LIGHTENED
  },
  success: {},
  warning: {},
  info: {
    color: COLORS.COLOR_GRAY_DARKENED_15,
    background: COLORS.COLOR_BLUE_LIGHTENED_45
  },
  infoIcon: {
    color: COLORS.COLOR_BLUE_BASE
  }
});

function AlertState({
  type = 'error',
  title,
  children,
  className
}: AlertStateProps) {
  const classes = useStyles();

  return (
    <Alert
      data-testid='alert-state'
      className={cn(classes.alertState, classes[type], className)}
      severity={type}
      iconMapping={{
        error: <InfoIcon fontSize='inherit' />,
        info: <InfoIcon className={classes.infoIcon} fontSize='inherit' />
      }}>
      {!!title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
}

export default AlertState;
