import * as Advisors from '../../../../api/Advisors';
import { Specialization } from '../../../../api/specializations';
import * as Tenant from '../../../../api/tenants';
import { TenantId } from '../../../../api/tenants/Tenant';
import { Settings } from '../../../../api/tenants/types/Settings';

let settings: Promise<Settings> | undefined = undefined;

export function getSettings(tenantId: TenantId): Promise<Settings> {
  if (settings) {
    return settings;
  }

  return (settings = Tenant.getSettings(tenantId).catch((e) => {
    settings = undefined;

    throw e;
  }));
}

let specializations: Promise<Specialization[]> | undefined = undefined;

export function getAllSpecializations(
  tenantId: TenantId,
): Promise<Specialization[]> {
  if (specializations) {
    return specializations;
  }

  return (specializations = Advisors.getAllSpecializations(tenantId).catch(
    (e) => {
      specializations = undefined;

      throw e;
    },
  ));
}
