import { ReactElement, useCallback, useMemo } from 'react';
import { CommunityMemberId } from '../../../../api/CommunityMembers/types/CommunityMember';
import { ConfirmDialog } from '../../../../components/common';
import * as Actions from './types/Actions';
import { isOpen, isSubmittable } from './types/State';
import { useSendInvitation } from './useSendInvitation';

export interface Props {
  ids: CommunityMemberId[];
  children: (props: { open: () => void; disabled: boolean }) => ReactElement;
}

export function SendInvitation({ ids, children }: Props) {
  const [state, dispatch] = useSendInvitation();
  const successProps = useMemo(
    () =>
      ({
        label: 'Send the invite',
        variant: 'contained',
        'data-testid': 'send-button',
      } as const),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.type === 'Sending'],
  );

  const handleButtonClick = useCallback(
    () => dispatch(Actions.open()),
    [dispatch],
  );
  const handleCancel = useCallback(
    () => dispatch(Actions.cancel()),
    [dispatch],
  );
  const handleSend = useCallback(
    () => dispatch(Actions.send(ids)),
    [dispatch, ids],
  );

  return (
    <>
      <ConfirmDialog
        disabled={!isSubmittable(state)}
        isOpen={isOpen(state)}
        title={'Sending an Invite to Office Hours'}
        body={`You are about to send an invite to this community member${
          ids.length === 1 ? '' : 's'
        } to create or update the password to their account.`}
        onCancel={handleCancel}
        onSuccess={handleSend}
        successProps={successProps}
        cancelProps={cancelProps}
      />
      {children({ open: handleButtonClick, disabled: isOpen(state) })}
    </>
  );
}

const cancelProps = {
  label: 'Cancel',
  'data-testid': 'cancel-button',
} as const;
