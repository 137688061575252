import { getDate, getDay, getWeekOfMonth } from 'date-fns';
import { MonthDay } from '../../../../api/types/MonthDay';
import * as Period from '../../../../api/types/Period';
import { fromDateFnsWeekDay, WeekDay } from '../../../../api/types/WeekDay';
import { invalid, valid, Value } from '../../../../utils/FormValue';
import { Natural } from '../../../../utils/Num/Natural';
import * as ValidDate from './NextDate';
import { is } from './NextDate';

interface Base {
  endType: {
    ends: 'hundredOccurrences' | 'onDate' | 'occurrences';
    date: Date;
    occurrences: number;
  };
}

export interface Daily extends Base {
  type: 'Daily';
  every: number;
}

export interface Weekly extends Base {
  type: 'Weekly';
  every: number;
  weekDay: WeekDay;
}

export interface Monthly extends Base {
  type: 'Monthly';
  on: 'fixedDate' | 'nthDay';
}

export type CustomPeriod = Daily | Weekly | Monthly;

export function toPeriod(
  currentDate: Date,
  v: CustomPeriod,
): Period.Daily | Period.CustomWeekDays | Period.Monthly {
  switch (v.type) {
    case 'Daily':
      return {
        periodType: 'DAILY',
        skipDays: v.every,
        stopAfterIterations: stopAfterIterations(v.endType),
        stopAfterDate: stopAfterDate(v.endType),
      };
    case 'Weekly':
      return {
        periodType: 'CUSTOM_WEEK_DAYS',
        ordinalNumber: undefined,
        dayOfWeek: v.weekDay,
        skipWeeks: v.every,
        stopAfterIterations: stopAfterIterations(v.endType),
        stopAfterDate: stopAfterDate(v.endType),
      };
    case 'Monthly': {
      switch (v.on) {
        case 'fixedDate':
          return {
            periodType: 'MONTHLY',
            dayOfMonth: getDate(currentDate) as MonthDay,
            stopAfterIterations: stopAfterIterations(v.endType),
            stopAfterDate: stopAfterDate(v.endType),
          };
        case 'nthDay':
          return {
            periodType: 'CUSTOM_WEEK_DAYS',
            dayOfWeek: fromDateFnsWeekDay(getDay(currentDate)),
            ordinalNumber: getWeekOfMonth(currentDate) as Natural,
            skipWeeks: -1,
            stopAfterIterations: stopAfterIterations(v.endType),
            stopAfterDate: stopAfterDate(v.endType),
          };
      }
    }
  }
}

function stopAfterIterations(v: CustomPeriod['endType']): number {
  switch (v.ends) {
    case 'hundredOccurrences':
      return 100;
    case 'occurrences':
      return v.occurrences;
    case 'onDate':
      return -1;
  }
}
function stopAfterDate(
  v: CustomPeriod['endType'],
): Value<'required', ValidDate.NextDate | undefined, Date | undefined> {
  switch (v.ends) {
    case 'hundredOccurrences':
    case 'occurrences':
      return valid(undefined);
    case 'onDate':
      return is(v.date, new Date())
        ? valid(v.date)
        : invalid('required', v.date);
  }
}
