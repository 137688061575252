import { useState } from 'react';
import { FormGroup, Text, TextField } from '.';
import { makeStyles } from '@material-ui/core';
import { lengthField } from '../../utils/form';
import { MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { FormRadioboxGroup } from '../forms/wrappers';
import { Field } from 'react-final-form';

const OTHER = 'OTHER:';

interface Props {
  name: string;
  options: OptionProps[];
  onChange: (id: any) => void;
  text: string;
  labelOther: string;
  readOnly?: boolean;
  testid?: string;
}

interface OptionProps {
  value: string;
  label: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  multilineField: {
    minHeight: 64
  },
  checkbox: {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 0
  },
  textOther: {
    marginTop: '10px !important',
    width: 'calc(100% - 16px)'
  },
  text: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14
    }
  }
});

function RadioboxListOther({
  name,
  onChange,
  options,
  text,
  labelOther,
  readOnly = false
}: Props) {
  const [selected, setSelected] = useState<string>('');
  const [otherValue, setOtherValue] = useState<string>('');
  const classes = useStyles();

  const handleChange = (e: any) => {
    let currentValue = e.target.value;
    let selectedValue = currentValue;

    setSelected(currentValue);

    if (selected === 'other') {
      selectedValue = `${OTHER}${otherValue}`;
    }

    return onChange(selectedValue);
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    setOtherValue(value);

    if (selected === 'other') {
      const currentValue = `${OTHER}${value}`;
      return onChange(currentValue);
    }
  };

  return (
    <FormGroup column>
      <Text variant='normal' className={classes.text}>
        {text}
      </Text>

      <Field<string>
        name={name}
        testid='mentor-additional-commit-time'
        component={FormRadioboxGroup}
        options={options}
        onChange={handleChange}
      />

      {selected === 'other' && (
        <div className={classes.textOther}>
          <TextField
            name={`${name}-text-other`}
            label={labelOther}
            value={otherValue}
            multiline
            onChange={handleChangeInput}
            InputProps={{
              inputProps: {
                className: classes.multilineField,
                maxLength: lengthField.additionalInfo,
                readOnly
              }
            }}
          />
        </div>
      )}
    </FormGroup>
  );
}

export default RadioboxListOther;
