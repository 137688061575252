import { axiosRequest } from './instance';

export interface Education {
  id: string;
  tenantId: string;
  attachmentRefs: string | null;
  contents: string;
  valid: string;
}

export interface EducationNew {
  attachmentRefs?: string | null;
  contents: string;
}

const educationAPI = {
  async getEducation() {
    try {
      const res = await axiosRequest.get<Education[]>({
        url: '/education',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createEducation(education: EducationNew) {
    try {
      const res = await axiosRequest.post<Education>({
        url: '/education',
        data: education,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async changeEducation(education: Education) {
    try {
      const res = await axiosRequest.put<Education>({
        url: '/education',
        data: education,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeEducation(id: string) {
    try {
      const res = await axiosRequest.delete<Education>({
        url: `/education/${id}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getEducationForFounder() {
    try {
      const res = await axiosRequest.get<Education[]>({
        url: '/education/founders',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createEducationForFounder(education: EducationNew) {
    try {
      const res = await axiosRequest.post<Education>({
        url: '/education/founders',
        data: education,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async changeEducationForFounder(education: Education) {
    try {
      const res = await axiosRequest.put<Education>({
        url: '/education/founders',
        data: education,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeEducationForFounder(id: string) {
    try {
      const res = await axiosRequest.delete<Education>({
        url: `/education/founders/${id}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async attachFileToEducation(file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: '/files/tenants/education',
      data: formData,
      credentials: true,
    });
    return res.data;
  },
};

export default educationAPI;
