import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/styles';
import { parse as parseQuery } from 'query-string';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import reportsAPI, { QuarterlyReportRecord } from '../../api/reports';
import { Button, ModalCustomizeQuarterlyReport } from '../../components/common';
import { FormValues } from '../../components/common/modal-customize-quarterly-report';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import ReportsQuarterlyScreen, {
  ParamsReport,
} from '../../components/reports/reports-details-quarterly';
import { QuarterlyReportContext } from '../../contexts/quarterly-report';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,

    '@media print': {
      boxShadow: 'none',
    },
  },
  content: {
    padding: 40,

    '@media print': {
      padding: 0,
    },
  },
});

const getInitialValues = (params: ParamsReport): FormValues => {
  return {
    includeLogo: params.includeLogo ? 'true' : 'false',
    includeMissing: params.includeMissing ? 'true' : 'false',
    includeNPS: params.includeNPS ? 'true' : 'false',
    includeAnnualTable: params.includeAnnualTable ? 'true' : 'false',
  };
};

const getValidatedParams = (params: FormValues): ParamsReport => {
  return {
    includeLogo: params.includeLogo === 'true',
    includeMissing: params.includeMissing === 'true',
    includeNPS: params.includeNPS === 'true',
    includeAnnualTable: params.includeAnnualTable === 'true',
  };
};

function ReportsQuarterlyPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const history = useHistory();
  const { reportQuarters, isLoadingQuarters, loadQuarters } = useContext(
    QuarterlyReportContext,
  );
  const [reportLoading, setReportLoading] = useState(false);
  const isLoading = reportLoading || isLoadingQuarters;
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const [report, setReport] = useState<QuarterlyReportRecord>();
  const selectedDate = useMemo(() => {
    const parsedQuery = parseQuery(location.search);
    return !parsedQuery.year || !parsedQuery.quarter
      ? undefined
      : {
          year: Number(parsedQuery.year),
          quarter: Number(parsedQuery.quarter),
        };
  }, [location.search]);
  const reportDate = useMemo(() => {
    if (selectedDate) {
      return selectedDate;
    }
    return report
      ? {
          quarter: report.quarterNumber,
          year: report.year,
        }
      : undefined;
  }, [report, selectedDate]);

  const [paramsReport, setParamsReport] = useState<ParamsReport>({
    includeLogo: true,
    includeMissing: true,
    includeNPS: true,
    includeAnnualTable: true,
  });
  const printContent = () => {
    window.print();
  };

  const initialValuesForm = getInitialValues(paramsReport);

  const submitCustomizeReport = (params: FormValues) => {
    const validParams = getValidatedParams(params);
    setParamsReport(validParams);
  };

  const handleChangeDate = (_date: string) => {
    const [quarter, year] = _date.split('.');
    if (
      Number(quarter) !== selectedDate?.quarter ||
      Number(year) !== selectedDate?.year
    ) {
      history.replace(`${location.pathname}?quarter=${quarter}&year=${year}`);
    }
  };

  const loadReports = async (date?: { quarter: number; year: number }) => {
    try {
      setReportLoading(true);
      const responseReport = await reportsAPI.getQuarterlyReportItem(date);
      setReport(responseReport);
      setReportLoading(false);
    } catch (e: any) {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedDate) {
      loadReports(reportDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reportQuarters) {
      loadQuarters();
    }
  }, [reportQuarters, loadQuarters]);

  useEffect(() => {
    if (selectedDate) {
      loadReports(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='Venture Quarterly Reports'
        backLink={
          <Tooltip title='Back'>
            <IconButton
              component={Link}
              to={prevPath || Pages.DASHBOARD}
              data-testid='button-back-reports'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
        actions={[
          {
            key: 'print',
            label: 'Print',
            hidden: !report,
            component: (
              <Button
                variant='outlined'
                data-testid='print-button'
                startIcon={<PrintIcon />}
                onClick={printContent}>
                Print
              </Button>
            ),
          },
          {
            key: 'customize',
            label: 'Customize Report',
            hidden: false,
            component: (
              <ModalCustomizeQuarterlyReport
                initialValues={initialValuesForm}
                selectedDate={
                  reportDate
                    ? `${reportDate.quarter}.${reportDate.year}`
                    : undefined
                }
                reportDates={reportQuarters}
                onSubmit={submitCustomizeReport}
                onChangeDate={handleChangeDate}
                loading={isLoading}
              />
            ),
          },
        ]}
      />
      <Paper className={classes.container} elevation={1}>
        <div className={classes.content}>
          <ReportsQuarterlyScreen
            paramsReport={paramsReport}
            report={report}
            loading={isLoading}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default ReportsQuarterlyPage;
