import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ConfirmDialog from './confirm-dialog';

interface ConfirmButtonRendererProps {
  onConfirm: () => any;
}

interface BeforeChangeProps {
  when: boolean;
  title: string;
  body: string;
  disabled?: boolean;
  active?: boolean;
  change?: () => void;
  cancel?: () => void;
  confirmButtonRenderer?: (
    props: ConfirmButtonRendererProps,
  ) => React.ReactNode;
}

function BeforeChange({
  when,
  title,
  body,
  change,
  cancel,
  disabled,
  active,
  confirmButtonRenderer,
}: BeforeChangeProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirm = useCallback(() => {
    setIsDialogOpen(false);
    change && change();
  }, [change]);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
    cancel && cancel();
  }, [cancel]);

  useEffect(() => {
    if (when && active) {
      setIsDialogOpen(true);
    }
  }, [when, active]);

  return ReactDOM.createPortal(
    <ConfirmDialog
      isOpen={isDialogOpen}
      title={title}
      body={body}
      onCancel={handleCancel}
      onSuccess={handleConfirm}
      disabled={disabled}
      cancelProps={{
        label: 'Cancel',
        variant: 'outlined',
        'data-testid': 'cancel-leave-page',
      }}
      successProps={{
        label: 'Yes, proceed',
        variant: 'contained',
        'data-testid': 'leave-page-form-proceed',
      }}>
      {typeof confirmButtonRenderer === 'function' &&
        confirmButtonRenderer({ onConfirm: handleConfirm })}
    </ConfirmDialog>,
    document.body,
  );
}

export default BeforeChange;
