import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { getAllCustomQuestions } from '../api/CustomQuestion';
import { Audience } from '../api/CustomQuestion/types/Audience';
import { CustomQuestion } from '../api/CustomQuestion/types/CustomQuestion';
import authAPI, { ConsentType, IMentorRegistrationForm } from '../api/auth';
import mentorsAPI from '../api/mentors';
import { getSettings } from '../api/tenants';
import { TenantId } from '../api/tenants/Tenant';
import { Settings } from '../api/tenants/types/Settings';
import { SnackMessage, Text } from '../components/common';
import { FormValues } from '../components/common/wizard';
import MentorsApplicationsWizardForm from '../components/forms/report-mentors-applications-wizard';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { COLORS } from '../theme/variables';
import { handleSubmitCustomForm } from '../utils/custom-questions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
}));

function parseFilterFromURL(search: string): any {
  return _pick(parseQuery(search), ['key']);
}

const formatValuesRequest = (
  values: FormValues,
  tenantId: string,
): IMentorRegistrationForm => {
  return {
    mentor: {
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      dateOfBirth: values.dateOfBirth || null,
      linkedInProfile: values.linkedInProfile || '',
      country: values.country || '',
      state: values.state || '',
      city: values.city || '',
      address: values.address || '',
      zip: values.zip || '',
      email: values.email || '',
      phone: values.phone || '',
      tenantId: tenantId || '',
      mentorAdditionalInfo: {
        tenantId: tenantId || '',
        gender: values.gender || '',
        race: values.race || '',
        veteran: values.veteran === 'Yes',
        whyBecomeMentor: values.whyBecomeMentor || '',
        salesChallenges: values.salesChallenges || '',
        marketingChallenges: values.marketingChallenges || '',
        fundraisingChallenges: values.fundraisingChallenges || '',
        businessChallenges: values.businessChallenges || '',
      },
    },
    mentorSpecializationList: values.businessIndustries
      ? // @ts-expect-error
        values.businessIndustries.map((id) => ({ specializationId: id }))
      : [],
  };
};

function MentorsApplicationsWizardPage() {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();

  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | ''
  >('loading');
  const [consents, setConsents] = useState<ConsentType[]>([]);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [logoBlob, setLogoBlob] = useState<string>('');
  const [additionalInfoCustomQuestions, setAdditionalInfoCustomQuestions] =
    useState<CustomQuestion[] | null>(null);
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadLogoError, setUploadLogoError] = useState(false);

  const { key } = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );

  const getParseTenantId = (value: string) => {
    const parseData = value.split('|');
    return parseData[0];
  };

  const tenantId = useMemo(() => {
    return getParseTenantId(atob(key)) || '';
  }, [key]);

  const loadData = async () => {
    try {
      const [loadedConsents, settings] = await Promise.all([
        authAPI.getMentorConsents(tenantId),
        getSettings(tenantId as TenantId),
      ]);
      if (settings?.customQuestionsMentorAdditionalInfoEnabled) {
        const customQuestions = await getAllCustomQuestions(
          tenantId as TenantId,
          Audience.MENTOR_ADDITIONAL_INFO,
        );
        setAdditionalInfoCustomQuestions(customQuestions);
      }
      setSettings(settings);
      setConsents(loadedConsents);
      setStatusForm('');
    } catch (e) {
      setStatusForm('error');
    }
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoadingSaveForm(true);
      setUploadLogoError(false);
      const file = (values.file as unknown as File) || undefined;
      const validValue = formatValuesRequest(values, tenantId);
      const mentor = await authAPI.mentorRegistration(validValue);

      if (file) {
        try {
          await mentorsAPI.updateAvatarPublic(
            tenantId as TenantId,
            mentor.id,
            file,
          );
        } catch (error) {
          setUploadLogoError(true);
        }
      }

      if (
        settings?.customQuestionsMentorAdditionalInfoEnabled &&
        additionalInfoCustomQuestions?.length
      ) {
        await handleSubmitCustomForm({
          values,
          questions: additionalInfoCustomQuestions,
          responderId: mentor.id,
          tenantId: tenantId as TenantId,
          audience: Audience.MENTOR_ADDITIONAL_INFO,
        });
      }
      setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';

      enqueueSnackbar('An error occurred while enrollment', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='mentor-wizard-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              {uploadLogoError
                ? 'We received your application, thank you. There was a hiccup with the photo upload, but this does not affect your application status, program admin will reach out to you shortly.'
                : 'Success! Your application has been submitted, and a copy of it has been sent to your email address.'}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='wizard-scroll' className={classes.wrapper}>
      <div data-testid='report-page' className={classes.container}>
        <div className={classes.block}>
          <Typography variant='h2' className={classes.title}>
            New {rb('mentor-u')} Application Form
          </Typography>
          <MentorsApplicationsWizardForm
            onSubmit={handleSubmit}
            tenantId={tenantId}
            isLoading={isLoadingSaveForm}
            consents={consents}
            showCustomAdditionalInfo={
              !!settings?.customQuestionsMentorAdditionalInfoEnabled
            }
            additionalInfoCustomQuestions={additionalInfoCustomQuestions}
            intro={settings?.mentorApplicationIntro || ''}
            logoBlob={logoBlob}
            setLogoBlob={setLogoBlob}
          />
        </div>
      </div>
    </div>
  );
}

export default MentorsApplicationsWizardPage;
