import { ComponentProps, PropsWithChildren } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';

export type Props = ComponentProps<typeof Typography>;

const useStyles = makeStyles(() => ({
  main: {
    margin: '20px 0 10px'
  }
}));

export function Description({
  children,
  className,
  ...props
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <Typography
      {...props}
      variant={'h5'}
      className={classNames(classes.main, className)}>
      {children}
    </Typography>
  );
}
