import { useState, ReactNode, useCallback, SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Text } from '../common';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { COLORS } from '../../theme/variables';
import { Link as MaterialLink } from '@material-ui/core';

interface Props {
  key: string | number;
  message: ReactNode | string;
  variant?: string;
  additionalMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'pre-line',
    maxWidth: '700px',
    right: 0,

    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    boxSizing: 'border-box',
    padding: '8px 10px'
  },
  actionRoot: {
    padding: '8px 22px 8px 0',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    rowGap: 5
  },
  icons: {
    marginLeft: 'auto'
  },
  buttonMore: {
    whiteSpace: 'nowrap',
    border: 'none !important',
    fontSize: 14,

    '&:hover': {
      border: 'none !important'
    }
  },
  collapse: {
    padding: 16,
    whiteSpace: 'pre-line',
    maxWidth: '700px'
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE
  },
  successIcon: {
    color: COLORS.COLOR_GREEN_BASE
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE
  },
  infoIcon: {
    color: COLORS.COLOR_BLUE_BASE
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,

    '& svg': {
      fontSize: 17
    },

    '& .MuiSvgIcon-root': {
      marginRight: 0
    }
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    columnGap: '20px',
    alignItems: 'center'
  },
  containerTextMoreInfo: {
    maxWidth: 'fit-content'
  }
}));
const SnackMessage = ({
  key,
  message,
  variant = 'info',
  additionalMessage
}: Props) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(key);
  }, [closeSnackbar, key]);

  const getStartIcon = (variant: string) => {
    switch (variant) {
      case 'error':
        return (
          <InfoIcon className={classes.errorIcon} data-testid='toast-error' />
        );
      case 'success':
        return (
          <CheckCircleIcon
            className={classes.successIcon}
            data-testid='toast-success'
          />
        );
      case 'warning':
        return (
          <WarningIcon
            className={classes.warningIcon}
            data-testid='toast-warning'
          />
        );
      default:
        return (
          <InfoIcon className={classes.infoIcon} data-testid='toast-info' />
        );
    }
  };

  return (
    <SnackbarContent className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.cardContent}>
            {getStartIcon(variant)}
            <Text variant='normal'>{message}</Text>
          </div>
          {additionalMessage && (
            <div className={classes.icons}>
              <MaterialLink
                href='#'
                variant='caption'
                onClick={handleExpandClick}
                className={classes.buttonMore}>
                {!expanded ? 'More Info' : 'Hide'}
              </MaterialLink>
            </div>
          )}
          <IconButton
            onClick={handleDismiss}
            className={classes.closeButton}
            aria-label='close'
            data-testid='toast-close'>
            <CloseIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Paper className={classes.collapse}>
            <div className={classes.containerTextMoreInfo}>
              <Text variant='normal'>{additionalMessage}</Text>
            </div>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
};

export default SnackMessage;
