import { useContext } from 'react';
import { Role } from '../../api/user/Role';
import { UserContext } from '../../contexts/user-context';
import { ProtectedRouteProps } from '../../router/type';
import { FounderSessionsPage } from './founder-sessions-page';
import { SessionsPage } from './sessions';

export function Sessions({ user }: ProtectedRouteProps) {
  const { hasRole } = useContext(UserContext);

  if (hasRole(Role.Founder)) {
    return <FounderSessionsPage user={user} />;
  }

  return <SessionsPage user={user} />;
}
