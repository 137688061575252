import { ComponentProps, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { actionIcon } from '../styles';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';

export type Props = ComponentProps<typeof DeleteIcon>;

const useStyles = makeStyles(() => ({
  main: actionIcon()
}));

export function Delete({ className, ...props }: Props): ReactElement<Props> {
  const { main } = useStyles();
  return <DeleteIcon {...props} className={classNames(main, className)} />;
}
