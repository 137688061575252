import { useState, useEffect, useRef, useCallback } from 'react';

export const useTimer = () => {
  const [seconds, setSeconds] = useState(0);
  const [running, setRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = (timeout: number) => {
    setSeconds(timeout);
    clearInterval(intervalRef.current!);
    setRunning(true);
    intervalRef.current = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
  };

  const stopTimer = useCallback(() => {
    setRunning(false);
    clearInterval(intervalRef.current!);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      stopTimer();
    }
  }, [seconds, stopTimer]);

  return { running, seconds, startTimer, stopTimer };
};
