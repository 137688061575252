import { TenantId } from '../../api/tenants/Tenant';
import {
  Question,
  QuestionId,
  Type
} from '../../api/tenants/QuestionTemplates/Question';
import { format } from 'date-fns';

export const ventureQuarterRecordsQuestions = (
  tenantId: TenantId
): Question[] =>
  Array.from(Array(10).keys()).map(
    (i): Question => ({
      id: `r${i}` as QuestionId,
      tenantId,
      description: `Description ${i}`,
      type: i % 2 ? Type.NUMBER : Type.PARAGRAPH,
      required: !(i % 2),
      creationDate: format(new Date(2022, 6, 12), 'yyyy/MM/dd')
    })
  );
