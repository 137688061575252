import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import foundersAPI, { Founder } from '../../api/founders';
import { Venture, VentureId } from '../../api/ventures/types/Venture';
import FounderAutocomplete from '../autocomplete/founder-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  TextField,
} from '../common';

interface AssignFoundersFormProps {
  ventureId?: Venture['id'];
  founders?: Founder[];
  loading?: boolean;
  onLoaded?: (founders: Founder[]) => any;
  onSuccess: (params: {
    assigned: string[];
    removed: string[];
    all: Founder[];
  }) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  checkboxGroup: {
    maxHeight: 360,
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

export function AssignFoundersForm({
  ventureId,
  founders,
  loading = false,
  onLoaded = () => {},
  onSuccess,
  onCancel,
}: AssignFoundersFormProps) {
  const classes = useStyles();
  const [loadedFounders, setLoadedFounders] = useState<Founder[]>(
    founders || [],
  );
  const [initialFounders, setInitialFounders] =
    useState<Founder[]>(loadedFounders);
  const [recommended, setRecommended] = useState<Founder[]>();
  const [displayedFounders, setDisplayedFounders] = useState<Founder[]>([]);
  const [isLoading, setIsLoading] = useState<{
    founders: boolean;
    recommended: boolean;
  }>({ founders: !founders, recommended: !recommended });
  const [searchIteration, setSearchIteration] = useState(0);

  const handleFoundersSearch = useCallback((searchedFounders: Founder[]) => {
    setDisplayedFounders(searchedFounders);
  }, []);

  const handleFounderRemove = (founder: Founder) => {
    setInitialFounders((prevFounders) =>
      prevFounders.filter((prevFounder) => prevFounder.id !== founder.id),
    );
  };

  const handleFounderAdd = (founder: Founder) => {
    setInitialFounders((prevFounders) => [...prevFounders, founder]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const loadRecommended = async () => {
    try {
      const result = await foundersAPI.getRecommendedFoundersByVentures();
      setRecommended(result);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    } catch (e: any) {
      console.log('error loadRecommended');
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    }
  };

  const loadInitialFounders = async (_ventureId: VentureId) => {
    try {
      const loadedFounders = await foundersAPI.getFoundersDetailsByVentureId(
        _ventureId,
      );
      setLoadedFounders(loadedFounders);
      setInitialFounders(loadedFounders);
      onLoaded(loadedFounders);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        founders: false,
      }));
    } catch (e: any) {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        founders: false,
      }));
    }
  };

  const handleSubmit = async ({
    foundersIds,
  }: {
    foundersIds: Founder['id'][];
  }) => {
    const loadedFoundersIds = loadedFounders.map((founder) => founder.id);
    const assigned = foundersIds.filter(
      (id) => !loadedFoundersIds.includes(id),
    );
    const removed = loadedFoundersIds.filter((id) => !foundersIds.includes(id));
    const all = initialFounders.filter((founder) =>
      foundersIds.includes(founder.id),
    );
    onSuccess({ assigned, removed, all });
  };

  useEffect(() => {
    if (!founders && ventureId) {
      // @ts-ignore
      loadInitialFounders(ventureId);
    } else {
      setIsLoading((prev) => ({ ...prev, founders: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [founders]);

  useEffect(() => {
    loadRecommended();
  }, []);

  if (isLoading.founders || isLoading.recommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    foundersIds: initialFounders.map((founder) => founder.id),
  };

  const filteredDisplayedFounders = displayedFounders.filter(
    (founder) =>
      !initialFounders.find(
        (initialFounder) => initialFounder.id === founder.id,
      ),
  );
  const filteredRecommendedFounders = (recommended || []).filter(
    (item) => !initialFounders.find((founder) => founder.id === item.id),
  );

  const displayedList =
    filteredDisplayedFounders.length > 0
      ? filteredDisplayedFounders
      : filteredRecommendedFounders;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <FounderAutocomplete
              iteration={searchIteration}
              onSearch={handleFoundersSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label='Add a founder'
                  placeholder='Enter founder name'
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialFounders.map((founder) => (
              <Chip
                key={founder.id}
                label={`${founder.firstName} ${founder.lastName}`}
                onDelete={() => handleFounderRemove(founder)}
                className={classes.chipItem}
              />
            ))}
          </div>
          {displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={['Founders', 'Assigned Ventures']}
              rows={displayedList.map((recomItem) => ({
                name: `${recomItem.firstName} ${recomItem.lastName}`,
                onAdd: () => handleFounderAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button disabled={loading} type='submit' data-testid='button-apply'>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}
