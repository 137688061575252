import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { useResourceBundles } from '../../../../contexts/resource-bundles-context';
import { ZonedDate } from '../../../../utils/date/ZonedDate';
import { TestId } from '../../../Testing/TestId';
import { Inline } from '../../Inline';
import Button from '../../button';
import { ActionsDialog } from '../ActionsDialog';
import { createReducer } from './reducer';
import { cancel, confirm, submit } from './types/Actions';
import { idle } from './types/State';

export interface DatesOutOfRangeProps<T> {
  dates?: ZonedDate<TenantTimeZone>[];
  getDates: (data: T) => ZonedDate<TenantTimeZone>[];
  children: (props: { onSubmit: (t: T) => void }) => ReactNode;
  onSubmit: (t: T) => void;
}

export function DatesOutOfRange<T>({
  getDates,
  onSubmit,
  children,
  dates,
}: DatesOutOfRangeProps<T>) {
  const { rb } = useResourceBundles();
  const getDatesRef = useRef({ fn: getDates });
  getDatesRef.current.fn = getDates;
  const reducer = useMemo(
    () => createReducer<T>((v) => getDatesRef.current.fn(v)),
    [],
  );
  const [state, dispatch] = useReducer(reducer, idle({ dates: dates ?? [] }));
  const handleSubmit = useCallback((v) => {
    dispatch(submit(v));
  }, []);
  const actions = useMemo(() => {
    return (
      <>
        <Inline gap={10} align={'center'}>
          <TestId testId='proceed-with-out-range-dates'>
            <Button variant={'contained'} onClick={() => dispatch(confirm())}>
              Yes, proceed
            </Button>
          </TestId>
          <TestId testId={'cancel-out-range-dates'}>
            <Button variant={'outlined'} onClick={() => dispatch(cancel())}>
              No
            </Button>
          </TestId>
        </Inline>
      </>
    );
  }, []);

  useEffect(() => {
    if (state.type === 'Confirmed') {
      onSubmit(state.payload.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      {children({ onSubmit: handleSubmit })}
      <ActionsDialog
        open={state.type === 'Confirmation'}
        onClose={() => dispatch(cancel())}
        title='Date range confirmation'
        actions={actions}>
        It appears that the timeframe you've chosen for the session falls
        outside the conventional working hours of your {rb('mentorship')}{' '}
        program, which are from 8AM to 6PM, Monday through Friday. Could you
        please confirm if you'd still like to proceed with your chosen time
        slot?
      </ActionsDialog>
    </>
  );
}
