import {
  CircularProgress,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import authAPI, { Tenant } from '../../api/auth';
import foundersAPI from '../../api/founders';
import mentorsAPI from '../../api/mentors';
import { COLORS } from '../../theme/variables';
import { unreachableError } from '../../utils/unreachableError';

export type FieldProps = TextFieldProps & {
  onChange: (value: any) => any;
  onBlur: (value: any) => any;
  small?: boolean;
  name: string;
  label: string;
  value?: string;
  valid?: boolean;
  type: 'mentor' | 'founder' | 'tenant';
  'data-testid'?: string;
};

const useStyles = makeStyles({
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
});

type StatusType = 'success' | 'error' | 'loading' | 'none';

export const hasDuplicateEmail = async (
  type: 'mentor' | 'founder' | 'tenant',
  email: string,
): Promise<boolean> => {
  switch (type) {
    case 'mentor':
      const mentor = await mentorsAPI.checkGlobalEmail(email);
      return !!mentor;
    case 'founder':
      const founder = await foundersAPI.checkGlobalEmail(email);
      return !!founder;
    case 'tenant':
    default:
      const entites: Tenant[] = await authAPI.checkEmail(email);
      const emails: string[] = entites.map((item: Tenant) => item.email);
      return !!emails.find((entityEmail: string) => entityEmail === email);
  }
};

function FieldEmail({
  onChange,
  onBlur,
  small = false,
  name,
  label,
  value: initialValue,
  type,
  valid,
  InputProps,
  setValid,
  ...props
}: FieldProps & { setValid?: (isValid: boolean) => void }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(initialValue);
  const currentValue = useRef<string>(initialValue || '');
  const prevValue = useRef<string>(initialValue || '');
  const [status, setStatus] = useState<StatusType>('none');
  const form = useForm();

  const handleStatusUpdate = useCallback(
    (status: StatusType) => {
      setStatus(status);
      if (setValid) {
        setValid(status === 'success' || status === 'none');
      }
    },
    [setValid],
  );

  const validateEmail = async (email: string) => {
    try {
      handleStatusUpdate('loading');
      const isDuplicate = await hasDuplicateEmail(type, email);

      if (isDuplicate) {
        enqueueSnackbar('Email you provided is already in use', {
          variant: 'error',
        });
        handleStatusUpdate('error');
      } else {
        handleStatusUpdate('success');
      }
    } catch (e: any) {
      handleStatusUpdate('none');
    }
  };

  const handleChange = (e: any) => {
    const originalValue = e.target.value;
    handleStatusUpdate('none');
    onChange(originalValue);
    setValue(originalValue);
    currentValue.current = originalValue.trim();
  };

  const handleBlur = (e: any) => {
    onBlur(e);

    const trimmedValue = currentValue.current;
    const validTrimmedValue = trimmedValue?.toLowerCase();
    if (
      validTrimmedValue &&
      validTrimmedValue.length > 2 &&
      (initialValue !== value || value !== validTrimmedValue) &&
      valid
    ) {
      if (trimmedValue !== validTrimmedValue) {
        setValue(validTrimmedValue);
      }
      handleStatusUpdate('loading');
      validateEmail(validTrimmedValue);
    } else {
      handleStatusUpdate('none');
    }
    if (!valid && validTrimmedValue !== trimmedValue) {
      setValue(validTrimmedValue);
    }
    prevValue.current = validTrimmedValue;
  };

  const getEndAdornment = useCallback(() => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!initialValue) {
      setValue('');
    }
  }, [initialValue]);

  useEffect(() => {
    const fieldValue = form.getState().values.verifiedEmail;
    switch (status) {
      case 'none':
      case 'error':
      case 'loading':
        if (fieldValue === true) {
          form.change('verifiedEmail', false);
        }
        break;
      case 'success':
        if (fieldValue === false) {
          form.change('verifiedEmail', true);
        }
        break;
      default:
        unreachableError(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <TextField
      {...props}
      name={name}
      label={label}
      className={cn({
        [classes.error]: status === 'error' || !valid,
        [classes.small]: small,
      })}
      onChange={handleChange}
      value={value}
      variant='outlined'
      fullWidth
      onBlur={handleBlur}
      aria-invalid={status === 'error' || !valid}
      InputProps={{
        ...(InputProps ? InputProps : {}),
        endAdornment: getEndAdornment(),
      }}
    />
  );
}

export default FieldEmail;
