import {
  CommunityMemberUpdate as Update,
  CommunityMember,
} from '../../../api/CommunityMembers/types/CommunityMember';
import { Ready, Saving, State } from './types/State';
import { valid } from '../../../utils/FormValue';
import { Sentence } from '../../../utils/String/Sentence';
import { Email } from '../../../utils/String/Email';
import { mPipe } from 'fp-utilities';
import { formatDateToRFC } from '../../../utils/date';

export function memberToReady(a: CommunityMember): Ready['payload'] {
  return {
    id: a.id,
    logo: undefined,
    member: a,
    item: {
      address: valid((a.address as Sentence<1024>) ?? undefined),
      email: valid(a.email as Email),
      country: valid((a.country as Sentence<250>) ?? undefined),
      birthDate: valid(a.dateOfBirth ? new Date(a.dateOfBirth) : undefined),
      city: valid((a.city as Sentence<250>) ?? undefined),
      state: valid((a.state as Sentence<250>) ?? undefined),
      linkedIn: valid((a.linkedInProfile as Sentence<250>) ?? undefined),
      lastName: valid(a.lastName as Sentence<250>),
      zip: valid((a.zip as Sentence<12>) ?? undefined),
      status: valid(a.status),
      firstName: valid(a.firstName as Sentence<250>),
    },
  };
}

export function savingToUpdate({
  payload: { item, id, member },
}: Saving): Update {
  return {
    id,
    address: item.address.value,
    country: item.country.value,
    city: item.city.value,
    state: item.state.value,
    status: item.status.value,
    email: item.email.value,
    zip: item.zip.value,
    lastName: item.lastName.value,
    firstName: item.firstName.value,
    dateOfBirth: mPipe(formatDateToRFC)(item.birthDate.value),
    linkedInProfile: item.linkedIn.value,
    tenantId: member.tenantId,
    dateOfEnrollment: member.dateOfEnrollment,
    emailValid: member.emailValid,
    valid: member.valid,
  };
}

export function formEdited(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
    case 'SaveError':
    case 'Ready':
    case 'Saving':
    case 'SaveSuccess':
      return false;
    case 'Uploading':
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
      return true;
  }
}
