import {
  Button as MaterialButton,
  CircularProgress,
  IconButton,
  Link as MaterialLink,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import cn from 'classnames';
import { SyntheticEvent, useContext, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import { Mentor } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { getColorStatus, isMobile, isTablet } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { TestId } from '../Testing/TestId';
import {
  AlertState,
  Avatar,
  CopyText,
  Link,
  StatusBadge,
  Text,
  Select,
} from '../common';
import { OptionProps } from '../common/select';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';

type MyMentorsTableProps = {
  selectedVenture: VentureId | null;
  setSelectedVenture: Function;
  ventureOptions: OptionProps<VentureId>[];
  isVenturesLoading: boolean;
  isLoading: boolean;
  mentors: Mentor[];
  mentorsLogos: {
    [x: string]: string | null;
  };
  loadError: any;
  isEmptyState: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  linkedinButton: {
    padding: 10,
  },
  linkedinIcon: {
    color: '#0A66C2',
    fontSize: 20,
  },
  nameColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  emailColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  phoneColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function MyMentorsTable({
  selectedVenture,
  setSelectedVenture,
  ventureOptions,
  isLoading,
  isVenturesLoading,
  mentors,
  mentorsLogos,
  loadError,
  isEmptyState,
}: MyMentorsTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const { hasAccessToAction } = useContext(UserContext);
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <Paper className={classes.container} data-testid='founder-my-mentors-table'>
      <div className={classes.filterContainer}>
        <div className={classes.filterList}>
          <div className={classes.filterItem}>
            <TestId testId={'founder-my-mentors-table-ventures-dropdown'}>
              <Select
                className={cn(
                  classes.filterSelect,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={selectedVenture}
                options={ventureOptions}
                onSelect={(item) => item && setSelectedVenture(item.value)}
                isDisabled={isVenturesLoading || !ventureOptions.length}
              />
            </TestId>
          </div>
        </div>
      </div>
      <div ref={containerRef}>
        {mentors.length > 0 ? (
          <div>
            <Table
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (mentors.length > 10 ? mentors.length : 10) + 56}
              rowHeight={64}
              rowCount={mentors.length}
              rowGetter={({ index }) => mentors[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`founder-my-mentor-table-row-${rowProps.index}`}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              {!isMobile() && (
                <Column
                  dataKey='logo'
                  className={cn(classes.column, classes.mobileNone)}
                  width={66}
                  minWidth={66}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData }) => {
                    return (
                      <div className={classes.avatarContainer}>
                        <Avatar
                          src={mentorsLogos[rowData.id]}
                          name={`${rowData.firstName[0]}${rowData.lastName[0]}`}
                          size='32'>
                          {typeof mentorsLogos[rowData.id] === 'string' &&
                          !mentorsLogos[rowData.id] ? (
                            <CircularProgress size={18} color='inherit' />
                          ) : undefined}
                        </Avatar>
                      </div>
                    );
                  }}
                />
              )}
              <Column
                dataKey='name'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={172}
                minWidth={isMobile() ? 120 : 172}
                maxWidth={357}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ rowData, rowIndex }) => (
                  <TableCell truncated>
                    <MaterialLink
                      data-testid={`founder-my-mentor-table-name-${rowIndex}`}
                      onClick={stopPropagation}
                      component={Link}
                      className={CLASS_TRACKING.MENTOR_LINK}
                      to={{
                        pathname: getRoutePath(Pages.MY_MENTOR_DETAILS, {
                          mentorId: rowData.id,
                          ventureId: selectedVenture,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {rowData.firstName} {rowData.lastName}
                    </MaterialLink>
                  </TableCell>
                )}
              />
              {!isMobile() && (
                <Column
                  dataKey='linkedInProfile'
                  className={cn(classes.column)}
                  width={60}
                  minWidth={60}
                  maxWidth={76}
                  flexGrow={1}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData, cellData }) =>
                    cellData ? (
                      <div>
                        <Tooltip
                          title={`${rowData.firstName} ${rowData.lastName}'s LinkedIn profile`}>
                          <IconButton
                            onClick={stopPropagation}
                            target='_blank'
                            href={cellData}
                            className={classes.linkedinButton}>
                            <LinkedInIcon className={classes.linkedinIcon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <div></div>
                    )
                  }
                />
              )}
              <Column
                dataKey='status'
                className={classes.column}
                width={100}
                minWidth={100}
                maxWidth={150}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const currentVariant = getColorStatus(cellData);

                  return (
                    <StatusBadge status={cellData} variant={currentVariant} />
                  );
                }}
              />
              {!isTablet() && (
                <Column
                  dataKey='email'
                  className={cn(classes.column, classes.emailColumn)}
                  headerClassName={classes.emailColumn}
                  width={256}
                  minWidth={256}
                  maxWidth={415}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                  cellRenderer={({ cellData }) => (
                    <CopyText onClick={stopPropagation} text={cellData}>
                      <TableCell onClick={stopPropagation} truncated>
                        <MaterialLink href={`mailto:${cellData}`}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                    </CopyText>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='phone'
                  className={cn(classes.column, classes.phoneColumn)}
                  headerClassName={classes.phoneColumn}
                  width={124}
                  minWidth={124}
                  maxWidth={205}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Phone</TableHeadCell>}
                  cellRenderer={({ cellData }) =>
                    cellData ? (
                      <CopyText onClick={stopPropagation} text={cellData}>
                        <TableCell truncated>{cellData}</TableCell>
                      </CopyText>
                    ) : (
                      <div>–</div>
                    )
                  }
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {!ventureOptions.length && !isVenturesLoading ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text
                    variant='normal'
                    testid='founder-my-mentor-table-no-ventures'>
                    There are currently no active {rb('mentors')} assigned to
                    you as you are not assigned to a venture. It could be simply
                    a work in progress. If this is unexpected or lasts longer
                    than necessary, please reach out to your Program
                    Administrator to clarify.
                  </Text>
                </div>
              </div>
            ) : isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text
                    variant='normal'
                    testid='founder-my-mentor-table-no-items'>
                    Currently, your venture does not have any assigned{' '}
                    {rb('mentors')}. Pease don't hesitate to contact your
                    Program Administrator to find out more about new{' '}
                    {rb('mentor')} assignments to your team.
                  </Text>
                  {hasAccessToAction('mentor.create') && (
                    <MaterialButton
                      component={Link}
                      to={Pages.NEW_MENTOR}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      {rb('mentor-u')}
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Paper>
  );
}

export default MyMentorsTable;
