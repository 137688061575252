import { useState, useCallback, useContext, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import GatheringDetailsForm, {
  ParsedFormValues
} from '../../components/forms/gathering-details';
import gatheringsAPI, {
  GatheringOperationResponse
} from '../../api/gatherings';
import { GatheringContext } from '../../contexts/gathering-context';
import { SnackMessage } from '../../components/common';
import GatheringModal from '../../components/gathering/gathering-modal';
import { UserContext } from '../../contexts/user-context';

type RadioValue = 'this' | 'all';

function GatheringDetailsPage() {
  const { gatheringId } = useParams<{ gatheringId: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const { gathering, updateGathering, loadGroupList } =
    useContext(GatheringContext);
  const currentValue =
    useRef<{ valueForm: ParsedFormValues; handleLeavePage?: () => {} }>();
  const [openModal, setOpenModal] = useState(false);
  const [isDisabledOnly, setIsDisabledOnly] = useState(false);
  const { channels } = useContext(UserContext);

  const handleChangeGathering = useCallback(
    async (type: RadioValue) => {
      const { valueForm, handleLeavePage } = currentValue?.current || {};
      try {
        if (gathering?.id && gathering?.groupId && valueForm) {
          setUpdating(true);
          let updatedGathering;
          if (type === 'all') {
            updatedGathering = await gatheringsAPI.changeAllGatheringForGroup({
              ...valueForm,
              id: gatheringId,
              groupId: gathering.groupId,
              tenantId: gathering.tenantId,
              externalInvitees: gathering.externalInvitees || ''
            });

            const currentGathering = await gatheringsAPI.getGathering(
              gatheringId
            );
            await loadGroupList(updatedGathering.groupId);

            updateGathering(currentGathering);
          } else {
            updatedGathering = (await gatheringsAPI.changeCurrentGathering({
              ...valueForm,
              id: gatheringId,
              groupId: gathering.groupId,
              tenantId: gathering.tenantId,
              externalInvitees: gathering.externalInvitees || ''
            })) as GatheringOperationResponse;

            updateGathering(updatedGathering.gatherings[0]);
          }
          if (typeof handleLeavePage === 'function') {
            handleLeavePage();
          }
          setUpdating(false);
          setOpenModal(false);

          const currentChanelId =
            updatedGathering?.gatherings?.[0]?.tenantEventChannelId;

          const selectedChannel = channels?.find(
            (channel) => channel.id === currentChanelId
          );

          const selectedChannelName = selectedChannel?.channelName || '';
          enqueueSnackbar(
            `Your gathering was updated successfully.\nThe channel that will be used for this session is ${selectedChannelName}`,
            {
              variant: 'success',
              style: { whiteSpace: 'pre-line' }
            }
          );
        }
      } catch (e: any) {
        setUpdating(false);
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while updating the gathering. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError
              }),
            variant: 'error'
          }
        );
      }
    },
    [
      gathering,
      enqueueSnackbar,
      updateGathering,
      gatheringId,
      loadGroupList,
      channels
    ]
  );

  const handleSubmitForm = useCallback(
    (parsedValues: ParsedFormValues, handleLeavePage?: () => any) => {
      const { isChangePeriod } = parsedValues;
      currentValue.current = { valueForm: parsedValues, handleLeavePage };
      if (!parsedValues.periodString) {
        return handleChangeGathering('all');
      }
      setIsDisabledOnly(!isChangePeriod);
      setOpenModal(true);
    },
    [handleChangeGathering]
  );

  return (
    <div>
      <GatheringDetailsForm
        gatheringDetails={gathering}
        loading={isUpdating}
        onSubmit={handleSubmitForm}
      />
      <GatheringModal
        loading={isUpdating}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmit={handleChangeGathering}
        isDisabledOnly={isDisabledOnly}
      />
    </div>
  );
}

export default GatheringDetailsPage;
