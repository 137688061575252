import { IconButton, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import cn from 'classnames';
import StatusBadge from './status-badge';
import Text from './text';

interface AssignmentRecommendedTableProps {
  labels: [string, string];
  rows: Array<{
    name: string;
    assigned?: string | number;
    interested?: boolean;
    onAdd: () => any;
  }>;
}

const useStyles = makeStyles({
  table: {
    display: 'grid',
    columnGap: 20,
    alignItems: 'center',
    maxHeight: 175,
    overflowY: 'scroll',
    marginTop: 10,
  },
  table1: { gridTemplateColumns: '40px auto' },
  table2: { gridTemplateColumns: '40px minmax(auto, 65%) auto' },
  table3: { gridTemplateColumns: '40px minmax(auto, 65%) min-content' },
  table4: { gridTemplateColumns: '40px minmax(auto, 45%) min-content auto' },
  tHead: {
    display: 'contents',
    '& > *': {
      position: 'sticky',
      top: 0,
      background: '#fff',
      zIndex: 1,
    },
  },
  tbody: {
    display: 'contents',
  },
  nameCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  empty: {
    alignSelf: 'stretch',
  },
});

function AssignmentRecommendedTable({
  labels,
  rows,
}: AssignmentRecommendedTableProps) {
  const classes = useStyles();
  const showAssigned = rows.some((row) => row.assigned !== undefined);
  const showInterested = rows.some((row) => row.interested);

  return (
    <div
      className={cn({
        [classes.table]: true,
        [classes.table1]: !showAssigned && !showInterested,
        [classes.table2]: showAssigned && !showInterested,
        [classes.table3]: !showAssigned && showInterested,
        [classes.table4]: showAssigned && showInterested,
      })}>
      <div className={classes.tHead}>
        <div className={classes.empty} />
        <Text variant='normal' bold>
          {labels[0]}
        </Text>
        {showInterested && <div className={classes.empty} />}
        {showAssigned && (
          <Text variant='normal' bold>
            {labels[1]}
          </Text>
        )}
      </div>
      <div className={classes.tbody}>
        {rows.map((row, index) => (
          <>
            <IconButton onClick={row.onAdd} data-testid={`add-${index}`}>
              <AddCircleIcon />
            </IconButton>
            <Text
              variant='normal'
              className={classes.nameCell}
              title={row.name}>
              {row.name}
            </Text>
            {showInterested ? (
              row.interested ? (
                <StatusBadge status='interested' variant='warning' />
              ) : (
                <div />
              )
            ) : null}
            {showAssigned && <Text variant='normal'>{row.assigned}</Text>}
          </>
        ))}
      </div>
    </div>
  );
}

export default AssignmentRecommendedTable;
