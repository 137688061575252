import { Link as MaterialLink, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import Bowser from 'bowser';
import cn from 'classnames';
import { format } from 'date-fns';
import { useMemo, SyntheticEvent, ReactNode } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import { Event } from '../../api/events';
import IssueTableInfo from '../../components/sessions/issue-table-info';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import {
  formatTimeInterval,
  formatDateInterval,
  datesAreOnSameDay,
} from '../../utils/date';
import { isMobile, isTablet } from '../../utils/functions';
import { Text, Link } from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';

interface StatsSessionsTableProps {
  invitedEvents?: Event[];
  attendedEvents?: Event[];
}

const useStyles = makeStyles(() => ({
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  date: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  ventureName: {
    display: 'flex',
    padding: '0 32px 0 10px',
    boxSizing: 'border-box',
  },
  summary: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE,
    cursor: 'pointer',
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '& a': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  attendedCell: {
    paddingLeft: 17,
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
}));

function formatSessionDate(date: Date) {
  return format(date, 'E, LLL dd, y');
}

function StatsSessionsTable({
  invitedEvents = [],
  attendedEvents = [],
}: StatsSessionsTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const attendedEventsIds = useMemo(
    () => attendedEvents.map((e) => e.id),
    [attendedEvents],
  );

  return invitedEvents.length > 0 ? (
    <div ref={containerRef}>
      <Table
        gridClassName={classes.table}
        headerHeight={56}
        height={64 * invitedEvents.length + 56}
        rowHeight={64}
        rowCount={invitedEvents.length}
        rowGetter={({ index }) => invitedEvents[index]}
        rowClassName={classes.row}
        headerRowRenderer={(headRowProps) => <TableHeadRow {...headRowProps} />}
        rowRenderer={(rowProps) => (
          <TableRow
            data-testid={`session-table-row-${rowProps.index}`}
            {...rowProps}
          />
        )}
        width={containerWidth || 500}>
        <Column
          dataKey='summary'
          className={cn(classes.column, classes.summary)}
          headerClassName={classes.summary}
          width={180}
          minWidth={isTabletVersion ? 100 : 180}
          flexGrow={1}
          headerRenderer={() => <TableHeadCell>Session Name</TableHeadCell>}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            rowData: Event;
            cellData?: ReactNode;
          }) => (
            <div className={classes.summaryContainer}>
              <TableCell truncated>
                <MaterialLink
                  data-testid='session-table-name'
                  onClick={stopPropagation}
                  component={Link}
                  to={{
                    pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
                      sessionId: rowData.id,
                    }),
                    state: {
                      prevPath: currentPath,
                    },
                  }}>
                  {cellData}
                </MaterialLink>
              </TableCell>
              {rowData.requiresAttention && (
                <IssueTableInfo sessionId={rowData.id}>
                  <WarningIcon
                    className={classes.warningIcon}
                    data-testid='session-table-issue-icon'
                  />
                </IssueTableInfo>
              )}
            </div>
          )}
        />
        <Column
          dataKey='attended'
          className={classes.column}
          width={100}
          headerRenderer={() => <TableHeadCell>Attended</TableHeadCell>}
          cellRenderer={({ rowData }: { rowData: Event }) => {
            const isAttended = attendedEventsIds.includes(rowData.id);
            return (
              <TableCell className={classes.attendedCell}>
                {isAttended ? (
                  <CheckCircleIcon className={classes.checkIcon} />
                ) : (
                  <CancelIcon className={classes.errorIcon} />
                )}
              </TableCell>
            );
          }}
        />
        <Column
          dataKey='date'
          className={cn(classes.column, classes.date)}
          headerClassName={classes.date}
          width={204}
          minWidth={isTabletVersion ? 180 : 204}
          maxWidth={230}
          flexGrow={1}
          headerRenderer={() => <TableHeadCell>Date</TableHeadCell>}
          cellRenderer={({ rowData }: { rowData: Event }) => {
            const startDate = new Date(rowData.start);
            const endDate = new Date(rowData.end);

            const similarDates = datesAreOnSameDay(startDate, endDate);
            const date = similarDates
              ? formatSessionDate(startDate)
              : formatDateInterval(startDate, endDate);

            const time = formatTimeInterval(startDate, endDate);

            return (
              <TableCell>
                <Text bold>{date}</Text>
                <br />
                <Text variant='upper1' color={COLORS.COLOR_GRAY_BASE}>
                  {time}
                </Text>
              </TableCell>
            );
          }}
        />
        {!isMobile() && (
          <Column
            dataKey='ventureName'
            className={cn(classes.column, classes.ventureName)}
            headerClassName={classes.ventureName}
            width={240}
            minWidth={isTabletVersion ? 100 : 240}
            maxWidth={320}
            headerRenderer={() => <TableHeadCell>Venture</TableHeadCell>}
            cellRenderer={({
              cellData,
              rowData,
            }: {
              rowData: Event;
              cellData?: ReactNode;
            }) => (
              <TableCell truncated onClick={stopPropagation}>
                <MaterialLink
                  onClick={stopPropagation}
                  data-testid='session-table-venture-name'
                  component={Link}
                  to={{
                    pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                      ventureId: rowData.ventureId,
                    }),
                    state: {
                      prevPath: currentPath,
                    },
                  }}>
                  {cellData}
                </MaterialLink>
              </TableCell>
            )}
          />
        )}
      </Table>
    </div>
  ) : null;
}

export default StatsSessionsTable;
