import {
  Link as MaterialLink,
  makeStyles,
  Paper,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Bowser from 'bowser';
import cn from 'classnames';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import {
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
  useCallback,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation, useParams } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import filesAPI from '../../api/files';
import gatheringsAPI, {
  Gathering,
  GatheringMentorConfirmation,
} from '../../api/gatherings';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { formatDate, formatLocalizedTime } from '../../utils/date';
import { isTablet } from '../../utils/functions';
import {
  Text,
  AlertState,
  Link,
  Button,
  Modal,
  ConfirmButton,
  Avatar,
  StickyContent,
} from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TableFooter,
} from '../common/table';
import GatheringAttendanceFormModal, {
  SumbitAttendanceForm,
} from '../forms/gathering-attendance';

interface Props {
  loading?: boolean;
  handleAddAttendance: (value: SumbitAttendanceForm, reload: () => any) => any;
  handleRemoveAttendance: (ids: string[], cb: () => any) => any;
}

export interface TableValue {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  type: 'mentor' | 'founder';
  logo: string | null;
  confirmationDate: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    boxShadow: 'none',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px 0 16px',
    minHeight: 50,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2,
    flexWrap: 'wrap',
    rowGap: 15,
  },
  filterList: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginRight: 0,
    padding: '0 0 0 10px',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('xs')]: {
      marginRight: 15,
      padding: '0 10px',
    },
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    display: 'flex',

    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  summary: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  loadingBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
    columnGap: 10,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 652,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
    rowGap: 15,
  },
  iconRemove: {
    marginRight: 0,

    [theme.breakpoints.up('xs')]: {
      marginRight: 25,
    },
  },
  stickyFooter: {
    padding: '0 !important',
    minHeight: '60px !important',
  },
}));

async function gatheringsRequest(gatheringId: Gathering['id'], page: number) {
  const currentPage = page < 0 ? 0 : page;
  return await gatheringsAPI.getMentorsAttendanceList(gatheringId, currentPage);
}

const parseMentors = (value: GatheringMentorConfirmation[]): TableValue[] => {
  if (value.length === 0) {
    return [];
  }

  return value.map((item) => ({
    firstName: item.mentor?.firstName || '',
    lastName: item.mentor?.lastName || '',
    id: item.mentor?.id || '',
    email: item.mentor?.email || '',
    type: 'mentor',
    logo: item.mentor?.logo || '',
    confirmationDate: item.confirmationDate || '',
  }));
};

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

const GatheringAttendance = ({
  loading,
  handleAddAttendance,
  handleRemoveAttendance,
}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { rb } = useResourceBundles();

  const [mentors, setMentors] = useState<TableValue[]>([]);
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const [loadError, setLoadError] = useState();
  const [selectedMentorsIds, setSelectedMentorsIds] = useState<
    TableValue['id'][]
  >([]);

  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [mentorsLogos, setMentorsLogos] = useState<{
    [x: string]: string | null;
  }>({});
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();

  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );
  const perPage = 10;

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, mentorId: TableValue['id']) => {
      if (checked) {
        return setSelectedMentorsIds(_uniq([...selectedMentorsIds, mentorId]));
      }
      return setSelectedMentorsIds(
        selectedMentorsIds.filter((id) => id !== mentorId),
      );
    },
    [selectedMentorsIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const mentorId = rowData.id;
      handleRowSelect(!selectedMentorsIds.includes(mentorId), mentorId);
    },
    [selectedMentorsIds, handleRowSelect],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const mentorsId = mentors.map((mentor) => mentor.id);
        return setSelectedMentorsIds([...mentorsId]);
      }
      return setSelectedMentorsIds([]);
    },
    [mentors],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const handleRemove = async (callback: () => any, ids: string[]) => {
    const emailsCurrentMentors = ids.map(
      (id: string) => mentors.find((mentor) => mentor.id === id)?.email || '',
    );
    await handleRemoveAttendance(emailsCurrentMentors, () =>
      loadGatherings(page - 1),
    );
    setSelectedMentorsIds([]);
    callback();
  };

  const loadGatherings = async (currentPage: number) => {
    try {
      setMentors([]);
      setIsLoading(true);
      setIsEmptyState(false);

      const loadedMentors = await gatheringsRequest(
        gatheringId,
        currentPage - 1,
      );
      if (loadedMentors.length === 0) {
        throw new Error('404');
      }

      const resParse = parseMentors(loadedMentors);
      setMentors(resParse);
      setIsLoading(false);

      if (loadedMentors.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextGatherings = await gatheringsRequest(
            gatheringId,
            currentPage,
          );
          setIsNextPageLoading(false);
          setHasNextPage(nextGatherings.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [location.pathname, history],
  );

  const loadMentorsLogos = async (mentorsList: TableValue[]) => {
    try {
      const mentorImages = mentorsList.map((mentor: TableValue) => ({
        id: mentor.id,
        logo: mentor.logo,
      }));
      setMentorsLogos(() => {
        const sources = mentorImages.reduce((res, mentor) => {
          res[mentor.id] = mentor.logo ? '' : null;
          return res;
        }, {} as { [x: string]: string | null });
        return sources;
      });
      const shouldBeLoaded = mentorImages.filter(
        (mentorImage) => !!mentorImage.logo,
      );
      const logos = shouldBeLoaded.map((mentorImage) => mentorImage.logo);
      if (!logos.length) {
        return false;
      }
      const loadedLogos = await filesAPI.getFileLogos(logos as string[]);

      const nextState = shouldBeLoaded.reduce(
        (res, mentorImage, mentorImageIndex) => {
          res[mentorImage.id] = loadedLogos[mentorImageIndex];
          return res;
        },
        {} as { [x: string]: string },
      );
      return setMentorsLogos((prevMentors) => ({
        ...prevMentors,
        ...nextState,
      }));
    } catch (e: any) {
      console.log('loadMentorsLogos error');
      return false;
    }
  };

  useEffect(() => {
    loadGatherings(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (mentors.length) {
      loadMentorsLogos(mentors);
    }
  }, [mentors]);

  return (
    <Paper className={classes.container} data-testid='gathering-table'>
      <div ref={containerRef}>
        {mentors?.length > 0 ? (
          <div>
            <Table
              onRowClick={handleRowClick}
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (mentors.length > 10 ? mentors.length : 10) + 56}
              rowHeight={64}
              rowCount={mentors.length}
              rowGetter={({ index }) => mentors[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => <TableRow {...rowProps} />}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={50}
                minWidth={50}
                headerRenderer={() => (
                  <Checkbox
                    checked={selectedMentorsIds.length === mentors.length}
                    color='primary'
                    indeterminate={
                      selectedMentorsIds.length > 0 &&
                      selectedMentorsIds.length !== mentors.length
                    }
                    onChange={(e) => handleAllRowSelect(e.target.checked)}
                  />
                )}
                cellRenderer={({ cellData }) => (
                  <Checkbox
                    data-testid='session-table-row-checkbox'
                    checked={selectedMentorsIds.includes(cellData)}
                    color='primary'
                    onChange={(e) =>
                      handleRowSelect(e.target.checked, cellData)
                    }
                  />
                )}
              />
              <Column
                dataKey='confirmationDate'
                className={cn(classes.column)}
                width={200}
                minWidth={200}
                headerRenderer={() => (
                  <TableHeadCell>Confirmation Date</TableHeadCell>
                )}
                cellRenderer={({ cellData }: { cellData?: string }) => {
                  return (
                    cellData && (
                      <TableCell truncated>
                        <Text>
                          {formatDate(cellData)}{' '}
                          {formatLocalizedTime(new Date(cellData))}
                        </Text>
                      </TableCell>
                    )
                  );
                }}
              />
              <Column
                dataKey='logo'
                className={cn(classes.column)}
                width={44}
                minWidth={44}
                headerRenderer={() => <div></div>}
                cellRenderer={({ rowData }) => {
                  return (
                    <div>
                      <Avatar
                        src={mentorsLogos[rowData.id]}
                        name={`${rowData.firstName[0]}${rowData.lastName[0]}`}
                        size='32'>
                        {typeof mentorsLogos[rowData.id] === 'string' &&
                        !mentorsLogos[rowData.id] ? (
                          <CircularProgress size={18} color='inherit' />
                        ) : undefined}
                      </Avatar>
                    </div>
                  );
                }}
              />
              <Column
                dataKey='name'
                className={cn(classes.column, classes.summary)}
                headerClassName={classes.summary}
                width={100}
                minWidth={100}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ rowData }) => (
                  <TableCell truncated>
                    <MaterialLink
                      data-testid='gathering-table-name'
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.MENTOR_DETAILS, {
                          mentorId: rowData.id,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {rowData.firstName} {rowData.lastName}
                    </MaterialLink>
                  </TableCell>
                )}
              />

              <Column
                dataKey='email'
                className={classes.column}
                width={isTabletVersion ? 100 : 200}
                minWidth={isTabletVersion ? 100 : 200}
                maxWidth={isTabletVersion ? 200 : 500}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  return (
                    <TableCell truncated>
                      <Text variant='normal'>{cellData}</Text>
                    </TableCell>
                  );
                }}
              />

              <Column
                dataKey='actions'
                className={cn(classes.column, classes.justifyContentEnd)}
                minWidth={60}
                width={60}
                headerRenderer={() => <div></div>}
                cellRenderer={({ rowData }) => (
                  <TableCell onClick={stopPropagation}>
                    <div className={classes.actionsCell}>
                      <ConfirmButton
                        title='Remove the attendance?'
                        body='Sure you want to remove the attendance? Changes can’t be undone'
                        successProps={{
                          btnLabel: 'Remove',
                          onSuccess: (callback) =>
                            handleRemove(callback, [rowData.id]),
                        }}
                        cancelProps={{
                          btnLabel: 'Cancel',
                        }}
                        buttonRenderer={({ onClick }) => (
                          <Tooltip title='Remove'>
                            <IconButton
                              onClick={onClick}
                              disabled={loading}
                              className={classes.iconRemove}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        loading={loading}
                      />
                    </div>
                  </TableCell>
                )}
              />
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='mentor-table-no-items'>
                    You can mark {rb('mentor')} attendance on this tab by
                    clicking on a button below. {rb('mentors-u')} must be
                    invited to the gathering prior to that - check{' '}
                    {rb('mentors-u')} tab.
                  </Text>
                  <Modal
                    title={`Enter email address of a ${rb(
                      'mentor',
                    )} who attended`}
                    contentRenderer={({ handleClose }) => (
                      <GatheringAttendanceFormModal
                        loading={loading}
                        onSuccess={(value) =>
                          handleAddAttendance(value, () => loadGatherings(page))
                        }
                        onCancel={handleClose}
                      />
                    )}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={onClick}
                        variant='contained'
                        disabled={loading}>
                        Add Attendance
                      </Button>
                    )}
                    width={500}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.loadingBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <StickyContent
            className={classes.stickyFooter}
            inaccuracy={36}
            isShow={mentors.length > 0}>
            <TableFooter
              page={page}
              onPageChange={handlePageChange}
              disabled={isLoading}
              isLoading={isNextPageLoading}
              hasNextPage={mentors.length > 0 && hasNextPage}>
              {mentors?.length > 0 && (
                <Modal
                  title={`Enter email address of a ${rb(
                    'mentor',
                  )} who attended`}
                  contentRenderer={({ handleClose }) => (
                    <GatheringAttendanceFormModal
                      loading={loading}
                      onSuccess={(value) =>
                        handleAddAttendance(value, () => loadGatherings(page))
                      }
                      onCancel={handleClose}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Button
                      onClick={onClick}
                      variant='contained'
                      disabled={loading}>
                      Add Attendance
                    </Button>
                  )}
                  width={500}
                />
              )}
            </TableFooter>
          </StickyContent>
        )}
      </div>
    </Paper>
  );
};

export default GatheringAttendance;
