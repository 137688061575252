import * as State from './types/State';
import { Actions } from './types/Actions';
import { advisorToReady } from './utils';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading' ? State.ready(advisorToReady(a.payload)) : s;
  }
}
