import { rest } from 'msw';
import {
  USER_INFO_RESPONSE,
  SUCCESS_LOGIN_RESPONSE,
  INCORRECT_PASSWORD_RESPONSE,
} from './data/auth';
import { DASHBOARD_RESPONSE } from './data/reports';
import { UPCOMING_EVENTS } from './data/events';
import { MENTORS_LIST } from './data/mentors';
import { VENTURES_LIST } from './data/ventures';
import { FOUNDERS_LIST } from './data/founders';
import { SESSION_LIST } from './data/session';
import { EVENT_CHANNELS } from './data/event-channels';
import { LOGOS_SIGNEDURL } from './data/logos_signedurl';
import { SPECIALIZATIONS } from './data/specializations';
import { ventureQuarterRecords } from './data/ventureQuarterRecords';
import { ventureQuarterRecordsQuestions } from './data/ventureQuarterRecordsQuestions';
import { ventureQuarterRecordsAnswers } from './data/ventureQuarterRecordsAnswers';
import { getAdvisors } from './data/advisors';
import { getAppointments } from './data/appointments';

const getAPIURL = (url: string): string => {
  return `${process.env.REACT_APP_API_URL}${url}`;
};

export const handlers = [
  // auth
  rest.post(getAPIURL('/login'), (req, res, ctx) => {
    const { username } = req.body as { username: string };
    if (username !== 'test@test.com') {
      return res(ctx.status(401), ctx.json(INCORRECT_PASSWORD_RESPONSE));
    }
    return res(ctx.status(200), ctx.json(SUCCESS_LOGIN_RESPONSE));
  }),
  rest.get(getAPIURL('/tenants'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(USER_INFO_RESPONSE));
  }),
  // reports
  rest.get(getAPIURL('/reports/dashboards/main'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(DASHBOARD_RESPONSE));
  }),
  // // events
  rest.get(getAPIURL('/events/future/pages/:id'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(UPCOMING_EVENTS));
  }),
  // // mentors
  rest.get(getAPIURL('/mentors/pages/:page'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(MENTORS_LIST));
  }),
  // // ventures
  rest.get(getAPIURL('/ventures/venturename/:query'), (req, res, ctx) => {
    const { query } = req.params as { query: string };

    return res(
      ctx.status(200),
      ctx.json(
        VENTURES_LIST(USER_INFO_RESPONSE.id).filter((venture) =>
          venture.ventureName.includes(query),
        ),
      ),
      ctx.delay(1000),
    );
  }),
  rest.get(getAPIURL('/ventures/mentors/:mentorId'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([...VENTURES_LIST(USER_INFO_RESPONSE.id)].slice(0, 3)),
      ctx.delay(1000),
    );
  }),
  rest.get(getAPIURL('/ventures/:ventureId/founders'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([...FOUNDERS_LIST].slice(0, 3)),
      ctx.delay(1000),
    );
  }),
  rest.get(
    getAPIURL('/founders/ventures/:ventureId/details'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([...FOUNDERS_LIST].slice(0, 3)),
        ctx.delay(1000),
      );
    },
  ),
  // status
  rest.get(getAPIURL('/events/pages/:page'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(SESSION_LIST));
  }),
  rest.get(getAPIURL('/tenants/eventchannels'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(EVENT_CHANNELS));
  }),
  rest.post(getAPIURL('/files/logos/signedurl'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(LOGOS_SIGNEDURL));
  }),
  // founder
  rest.get(getAPIURL('/founders/pages/:page'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(FOUNDERS_LIST));
  }),
  // founder
  rest.get(getAPIURL('specializations'), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(SPECIALIZATIONS));
  }),
  // ventures
  rest.get(getAPIURL('/ventures/quarterlyrecords/:key'), (req, res, ctx) => {
    const venture = VENTURES_LIST(USER_INFO_RESPONSE.id)[0];
    return res(
      ctx.status(200),
      ctx.json(ventureQuarterRecords(venture.id, venture.tenantId)[0]),
    );
  }),
  rest.get(
    getAPIURL('/ventures/:ventureId/quarterlyrecords/submitted'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(
          ventureQuarterRecords(req.params.ventureId, USER_INFO_RESPONSE.id),
        ),
      );
    },
  ),
  rest.get(
    getAPIURL('/ventures/quarterlyrecords/:recordId/customanswers'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(ventureQuarterRecordsAnswers(req.params.recordId)),
      );
    },
  ),
  rest.delete(
    getAPIURL('/ventures/quarterlyrecords/:recordId/customanswers/:answerId'),
    (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),
  // ventures quarterly records questions templates
  rest.get(
    getAPIURL('/tenants/:tenantId/ventures/quarterlyrecords/questiontemplates'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(ventureQuarterRecordsQuestions(req.params.tenantId)),
      );
    },
  ),

  // ventures quarterly reports questions answers
  rest.post(
    getAPIURL('/ventures/quarterlyrecords/customanswers'),
    (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),

  // Advisors
  rest.get(
    getAPIURL('/public/tenants/:tenantId/advisors/active/pages/:page'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getAdvisors(req.params.tenantId, req.params.page)),
      );
    },
  ),

  // Appointments
  rest.get(
    getAPIURL('/officehours/dates/:date/pages/:page'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(
          getAppointments(
            USER_INFO_RESPONSE.id,
            getAdvisors(USER_INFO_RESPONSE.id, 0)[0].id,
            req.params.page,
          ),
        ),
      );
    },
  ),
  rest.get(getAPIURL('/officehours/:id'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getAppointments(
          USER_INFO_RESPONSE.id,
          getAdvisors(USER_INFO_RESPONSE.id, 0)[0].id,
          req.params.page,
        )[0],
      ),
    );
  }),
];
