import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Field, Form } from 'react-final-form';
import authAPI from '../../api/auth';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  FormButtons,
  FormGroup,
  SnackMessage,
} from '../common';
import { TextFieldWrapper } from './wrappers';

interface FormValues {
  welcomeParagraph: string;
}

interface ApplicationFormProps {
  formId: 'mentor' | 'venture';
  initialValues?: FormValues;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  formButtons: {
    justifyContent: 'flex-end',
  },
  welcomeParagraphField: {
    minHeight: 64,
  },
}));

function ApplicationForm({ formId, initialValues }: ApplicationFormProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateUser, hasAccessToAction } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const handleUpdateApplicationFormTitle = async (
    id: 'mentor' | 'venture',
    welcomeParagraph: string,
  ) => {
    try {
      setLoading(true);

      if (user) {
        const key =
          id === 'mentor'
            ? 'mentorApplicationIntro'
            : 'ventureApplicationIntro';
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          [key]: welcomeParagraph?.trim() || '',
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message =
          id === 'mentor'
            ? `${rb('mentor-u')} Application Form has been updated`
            : 'Venture Application Form has been updated';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message =
        id === 'mentor'
          ? `An error occurred while updating ${rb(
              'mentor-u',
            )} Application Form. Please, try again.`
          : 'An error occurred while updating Venture Application Form. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<FormValues>
      onSubmit={(values) =>
        handleUpdateApplicationFormTitle(formId, values.welcomeParagraph)
      }
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <BeforeUnload
            when={formProps.dirty && !loading}
            title='Leave the page'
            body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
            disabled={loading}
            confirmButtonRenderer={({ onConfirm }) => (
              <Button
                variant='outlined'
                onClick={async () => {
                  try {
                    await formProps.handleSubmit();
                    onConfirm();
                  } catch (e: any) {}
                }}
                disabled={loading || !formProps.valid}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save the changes'
                )}
              </Button>
            )}
          />
          <FormGroup>
            <Field<string>
              testid='application-form-welcome-paragraph-field'
              name='welcomeParagraph'
              component={TextFieldWrapper}
              label='Welcome Paragraph'
              multiline
              parse={(value: string) => value}
              InputProps={{
                inputProps: {
                  className: classes.welcomeParagraphField,
                  maxLength: 1024,
                  readOnly: !hasAccessToAction('modules.enrollment.update'),
                },
              }}
            />
          </FormGroup>

          <FormButtons className={classes.formButtons}>
            <Button
              data-testid='application-form-submit'
              type='submit'
              disabled={loading}
              className={CLASS_TRACKING.INTERNAL_ACTION}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Save'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default ApplicationForm;
