export const cleanHTML = (s: string): string => {
  const el = document.createElement('div');
  el.innerHTML = s;

  el.querySelectorAll('script, style, *:not(br):empty').forEach((e) =>
    e.remove(),
  );
  el.querySelectorAll('*').forEach((e) => {
    const tagName = e.tagName.toLowerCase();

    Array.from(e.attributes)
      .filter((i) => tagName !== 'a' || i.name !== 'href')
      .forEach((attr) => e.removeAttribute(attr.name));
  });

  return el.innerHTML;
};

export function length(s: string): number {
  const node = document.createElement('div');
  node.innerHTML = s;

  return node.innerText.length;
}
