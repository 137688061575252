import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import { createFeedback } from '../../api/Feedback';
import { lengthField } from '../../utils/form';
import { TestId } from '../Testing/TestId';
import { Button, FormGroup } from '../common';
import MoodInput from '../common/mood-input';
import { TextFieldWrapper } from '../forms/wrappers';

interface FormValues {
  body: string;
  rating: number;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.rating) {
    errors.rating = 'Required';
  }
  return errors;
};

interface FeedbackFormProps {
  handleClose: Function;
}

const useFormStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: 15,
    marginTop: 20,
  },
  descriptionField: {
    minHeight: 64,
  },
});

function FeedbackForm({ handleClose }: FeedbackFormProps) {
  const initialValues: FormValues = {
    body: '',
    rating: 0,
  };
  const classes = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (formValues: FormValues) => {
    try {
      handleClose();
      await createFeedback({
        body: formValues.body,
        rating: formValues.rating,
        pageName: window.location.pathname,
      });
      enqueueSnackbar(
        'Your feedback has been successfully submitted. Thank you for your valuable input.',
        {
          variant: 'success',
        },
      );
    } catch (error: any) {
      const messageError = `We're sorry, but there was an error submitting your feedback. Please try again or contact your Program Manager for further assistance.`;
      enqueueSnackbar(messageError, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formProps) => (
          <form noValidate>
            <FormGroup>
              <Field<number>
                name='rating'
                component={MoodInput}
                label='How did we do?*'
                testid='feedback-rating'
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name='body'
                component={TextFieldWrapper}
                label='Care to share more about it?'
                testid='feedback-body'
                multiline
                InputProps={{
                  inputProps: {
                    className: classes.descriptionField,
                    maxLength: lengthField.feedback,
                  },
                }}
              />
            </FormGroup>
            <div className={classes.contentButton}>
              <Button onClick={() => handleClose()} variant='outlined'>
                Cancel
              </Button>
              <TestId testId='feedback-submit-button'>
                <Button onClick={formProps.handleSubmit}>Send</Button>
              </TestId>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default FeedbackForm;
