import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getAdvisorLogs } from '../../../api/Advisors';
import { AdvisorId } from '../../../api/Advisors/types/Advisor';
import { AdvisorLog } from '../../../api/Advisors/types/AdvisorLog';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { PageLoader } from '../../../components/common';
import { ListLogs } from '../../../components/common/Logs/List';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { Pages, getRoutePath } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { getTabs } from '../common/utils';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function AdvisorLogsContent() {
  const classes = useStyles();
  const { id: advisorId } = useParams<{ id: AdvisorId }>();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<AdvisorLog[]>([]);

  const loadLogs = async (advisorId: AdvisorId) => {
    try {
      setIsLoading(true);
      const responseLogs = await getAdvisorLogs(advisorId);

      const sortLogs = responseLogs.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setLogs(sortLogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLogs(advisorId);
  }, [advisorId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return <ListLogs logs={logs} />;
}

export function AdvisorLogs({ user }: ProtectedRouteProps) {
  const { id } = useParams<{ id: AdvisorId }>();
  const { hasAccessToAction } = useContext(UserContext);
  const { rb } = useResourceBundles();

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={`Edit ${rb('advisor')}`}
        backButtonLink={Pages.OH_ADVISORS}
        backButtonTitle={`Back to ${rb('advisors-u')}`}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_ADVISORS_LOGS, {
              id,
            })}
            tabs={getTabs(id, hasAccessToAction)}
          />
        }>
        <AdvisorLogsContent />
      </WithTabs>
    </BaseLayout>
  );
}
