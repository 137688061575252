import { pass } from 'fp-utilities';
import { Either, left, right } from '../Either';

declare const natural: unique symbol;

export type Natural = 1 | 10 | (number & { [natural]: 'Natural' });

export const isNatural = (n: number): n is Natural =>
  Number.isInteger(n) && n >= 0;

export const fromNumber = pass(isNatural);

export const numberToEitherNatural = (n: number): Either<string, Natural> => {
  return isNatural(n) ? right(n) : left('Not a natural number');
};
