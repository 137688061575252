import { useSnackbar } from 'notistack';
import { useMemo, useCallback, useContext, useState } from 'react';
import educationAPI, { Education } from '../../api/education';
import { Role } from '../../api/user/Role';
import { Attachment } from '../../components/common/attachment-card';
import EducationListScreen from '../../components/education/education-list';
import { EducationContext } from '../../contexts/eduation-context';
import { UserContext } from '../../contexts/user-context';

export interface InitialValues {
  materials: string;
  attachments: Attachment[];
  id: string;
}

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    const attachments: Attachment[] = JSON.parse(attachmentRefs);
    return attachments;
  } catch (e: any) {
    return [];
  }
};

const noteAttachments = (attachmentRefs?: string | null) => {
  if (attachmentRefs) {
    return parseAttachments(attachmentRefs);
  }
  return [];
};
const getInitialValues = (education?: Education): InitialValues => {
  const formatedAttachments = noteAttachments(education?.attachmentRefs);
  return {
    materials: education?.contents || '',
    attachments: formatedAttachments || [],
    id: education?.id || '',
  };
};

function EducationList() {
  const [loadingSave, setLoadingSave] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { education, updateEducation } = useContext(EducationContext);
  const { hasRole } = useContext(UserContext);

  const createEducation = useCallback(
    async (materials: string, attachments: string) => {
      try {
        setLoadingSave(true);
        const createEducationData = {
          contents: materials || '',
          attachmentRefs: attachments || '',
        };
        const response = hasRole(Role.Founder)
          ? await educationAPI.createEducationForFounder(createEducationData)
          : await educationAPI.createEducation(createEducationData);
        enqueueSnackbar('The material was successfully created', {
          variant: 'success',
        });
        updateEducation(response);
        setLoadingSave(false);
      } catch (e: any) {
        const errorMessange =
          e.response?.data?.message || 'Internal server error';
        enqueueSnackbar(errorMessange, {
          variant: 'error',
        });
        setLoadingSave(false);
      }
    },
    [enqueueSnackbar, hasRole, updateEducation],
  );

  const changeEducation = useCallback(
    async (materials: string, attachments: string) => {
      try {
        setLoadingSave(true);
        if (education) {
          if (materials) {
            const changeEducationData = {
              ...education,
              contents: materials || '',
              attachmentRefs: attachments || '',
            };
            const response = hasRole(Role.Founder)
              ? await educationAPI.changeEducationForFounder(
                  changeEducationData,
                )
              : await educationAPI.changeEducation(changeEducationData);
            updateEducation(response);
          } else {
            hasRole(Role.Founder)
              ? await educationAPI.removeEducationForFounder(education.id)
              : await educationAPI.removeEducation(education.id);
            updateEducation();
          }
          enqueueSnackbar('The material was successfully updated', {
            variant: 'success',
          });
        }
        setLoadingSave(false);
      } catch (e: any) {
        const errorMessange =
          e.response?.data?.message || 'Internal server error';
        enqueueSnackbar(errorMessange, {
          variant: 'error',
        });
        setLoadingSave(false);
      }
    },
    [education, enqueueSnackbar, updateEducation, hasRole],
  );

  const initialValues = useMemo(() => {
    return getInitialValues(education);
  }, [education]);

  return (
    <EducationListScreen
      changeEducation={changeEducation}
      createEducation={createEducation}
      initialValues={initialValues}
      loading={loadingSave}
    />
  );
}

export default EducationList;
