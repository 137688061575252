import { Type } from '../../api/tenants/QuestionTemplates/Question';
import { Actions } from './Actions';
import * as State from './State';
import * as AddQuestion from './AddQuestion';
import * as EditQuestion from './EditQuestion';
import { canAddNewQuestion } from './utils';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadFail':
      return s.type === 'Loading'
        ? State.fail({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({ ...s.payload, items: a.payload })
        : s;
    case 'AddNew':
      return s.type === 'Ready' && canAddNewQuestion(s)
        ? State.addNew({
            ...s.payload,
            item: {
              tenantId: s.payload.tenantId,
              type: Type.PARAGRAPH,
              description: '',
              required: false
            }
          })
        : s;
    case 'Edit': {
      if (s.type === 'Ready') {
        const index = s.payload.items.findIndex((i) => i.id === a.payload);

        if (index === -1) {
          return s;
        }

        return State.edit({
          ...s.payload,
          before: s.payload.items.slice(0, index),
          after: s.payload.items.slice(index + 1),
          original: s.payload.items[index],
          item: s.payload.items[index]
        });
      }
      return s;
    }
    case 'Remove': {
      if (s.type === 'Ready') {
        const index = s.payload.items.findIndex((i) => i.id === a.payload);

        if (index === -1) {
          return s;
        }

        return State.removeConfirmation({
          tenantId: s.payload.tenantId,
          before: s.payload.items.slice(0, index),
          after: s.payload.items.slice(index + 1),
          item: s.payload.items[index]
        });
      }
      return s;
    }
    case 'RemoveDeny':
      return s.type === 'RemoveConfirmation'
        ? State.ready({
            ...s.payload,
            items: [...s.payload.before, s.payload.item, ...s.payload.after]
          })
        : s;
    case 'RemoveApprove':
      return s.type === 'RemoveConfirmation'
        ? State.removing({
            ...s.payload,
            before: s.payload.before,
            after: s.payload.after,
            item: s.payload.item
          })
        : s;
    case 'RemoveFail':
      return s.type === 'Removing'
        ? State.ready({
            ...s.payload,
            items: [...s.payload.before, s.payload.item, ...s.payload.after]
          })
        : s;
    case 'RemoveSuccess':
      return s.type === 'Removing'
        ? State.ready({
            ...s.payload,
            items: [...s.payload.before, ...s.payload.after]
          })
        : s;
    case 'SetType':
      return s.type === 'AddNew' || s.type === 'AddNewErr'
        ? State.addNew({
            ...s.payload,
            item: { ...s.payload.item, type: a.payload }
          })
        : s.type === 'Edit' || s.type === 'EditErr'
        ? State.edit({
            ...s.payload,
            before: s.payload.before,
            after: s.payload.after,
            original: s.payload.original,
            item: {
              ...s.payload.item,
              type: a.payload
            }
          })
        : s;
    case 'SetDescription':
      return s.type === 'AddNew' || s.type === 'AddNewErr'
        ? State.addNew({
            ...s.payload,
            item: { ...s.payload.item, description: a.payload }
          })
        : s.type === 'Edit' || s.type === 'EditErr'
        ? State.edit({
            ...s.payload,
            item: {
              ...s.payload.item,
              description: a.payload
            }
          })
        : s;
    case 'SetRequired':
      return s.type === 'AddNew' || s.type === 'AddNewErr'
        ? State.addNew({
            ...s.payload,
            item: { ...s.payload.item, required: a.payload }
          })
        : s.type === 'Edit' || s.type === 'EditErr'
        ? State.edit({
            ...s.payload,
            item: {
              ...s.payload.item,
              required: a.payload
            }
          })
        : s;
    case 'Save':
      return s.type === 'AddNew' || s.type === 'AddNewErr'
        ? AddQuestion.isValid(s.payload.item)
          ? State.adding({ ...s.payload, item: s.payload.item })
          : State.addNewErr({
              ...s.payload,
              message:
                'Value cannot not be empty and must be shorter than 2048 characters'
            })
        : s.type === 'Edit' || s.type === 'EditErr'
        ? EditQuestion.isValid(s.payload.item)
          ? State.updating({ ...s.payload, item: s.payload.item })
          : State.editErr({
              ...s.payload,
              message:
                'Value cannot not be empty and must be shorter than 2048 characters'
            })
        : s;
    case 'SaveFail':
      return s.type === 'Adding'
        ? State.addNew(s.payload)
        : s.type === 'Updating'
        ? State.edit(s.payload)
        : s;
    case 'SaveSuccess': {
      return s.type === 'Adding'
        ? State.ready({ ...s.payload, items: [...s.payload.items, a.payload] })
        : s.type === 'Updating'
        ? State.ready({
            ...s.payload,
            items: [...s.payload.before, a.payload, ...s.payload.after]
          })
        : s;
    }
    case 'Cancel':
      return s.type === 'AddNew' || s.type === 'AddNewErr'
        ? State.ready(s.payload)
        : s.type === 'Edit' || s.type === 'EditErr'
        ? State.ready({
            ...s.payload,
            items: [...s.payload.before, s.payload.original, ...s.payload.after]
          })
        : s;
  }
}
