import { Cohort, CohortId } from '../../../../api/Cohort/types/Cohort';
import { TenantId } from '../../../../api/tenants/Tenant';
import { ItemId } from './Item';

// region ChangeTenant

export interface ChangeTenant {
  type: 'ChangeTenant';
  payload: TenantId;
}

export const changeTenant = (
  payload: ChangeTenant['payload'],
): ChangeTenant => ({
  type: 'ChangeTenant',
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: 'FetchSuccess';
  payload: Cohort[];
}

export const fetchSuccess = (
  payload: FetchSuccess['payload'],
): FetchSuccess => ({
  type: 'FetchSuccess',
  payload,
});
// endregion

// region FetchError
export interface FetchError {
  type: 'FetchError';
  payload: string;
}

export const fetchError = (payload: FetchError['payload']): FetchError => ({
  type: 'FetchError',
  payload,
});
// endregion

// region AddNew
export interface AddNew {
  type: 'AddNew';
}

export const addNew = (): AddNew => ({
  type: 'AddNew',
});
// endregion

// region Edit
export interface Edit {
  type: 'Edit';
  payload: CohortId;
}

export const edit = (payload: Edit['payload']): Edit => ({
  type: 'Edit',
  payload,
});
// endregion

// region SetName
export interface SetName {
  type: 'SetName';
  payload: {
    id: ItemId;
    name: string;
  };
}

export const setName = (payload: SetName['payload']): SetName => ({
  type: 'SetName',
  payload,
});
// endregion

// region SetDescription
export interface SetDescription {
  type: 'SetDescription';
  payload: {
    id: ItemId;
    description: string;
  };
}

export const setDescription = (
  payload: SetDescription['payload'],
): SetDescription => ({
  type: 'SetDescription',
  payload,
});
// endregion

// region CancelEdit
export interface CancelEdit {
  type: 'CancelEdit';
  payload: ItemId;
}

export const cancelEdit = (payload: CancelEdit['payload']): CancelEdit => ({
  type: 'CancelEdit',
  payload,
});
// endregion

// region CancelEditConfirm
export interface CancelEditConfirm {
  type: 'CancelEditConfirm';
}

export const cancelEditConfirm = (): CancelEditConfirm => ({
  type: 'CancelEditConfirm',
});
// endregion

// region CancelEditDecline
export interface CancelEditDecline {
  type: 'CancelEditDecline';
}

export const cancelEditDecline = (): CancelEditDecline => ({
  type: 'CancelEditDecline',
});
// endregion

// region Save
export interface Save {
  type: 'Save';
  payload: ItemId;
}

export const save = (payload: Save['payload']): Save => ({
  type: 'Save',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: {
    id: ItemId;
    cohort: Cohort;
  };
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: {
    id: ItemId;
    error: string;
  };
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: CohortId;
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload,
});
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: 'RemoveConfirm';
}

export const removeConfirm = (): RemoveConfirm => ({
  type: 'RemoveConfirm',
});
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: 'RemoveDecline';
}

export const removeDecline = (): RemoveDecline => ({
  type: 'RemoveDecline',
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
  payload: CohortId;
}

export const removeSuccess = (
  payload: RemoveSuccess['payload'],
): RemoveSuccess => ({
  type: 'RemoveSuccess',
  payload,
});
// endregion

// region RemoveError
export interface RemoveError {
  type: 'RemoveError';
  payload: {
    id: CohortId;
    error: string;
  };
}

export const removeError = (payload: RemoveError['payload']): RemoveError => ({
  type: 'RemoveError',
  payload,
});
// endregion

export type Actions =
  | ChangeTenant
  | FetchSuccess
  | FetchError
  | AddNew
  | Edit
  | SetName
  | SetDescription
  | CancelEdit
  | CancelEditConfirm
  | CancelEditDecline
  | Save
  | SaveSuccess
  | SaveError
  | Remove
  | RemoveConfirm
  | RemoveDecline
  | RemoveSuccess
  | RemoveError;
