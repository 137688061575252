import { AdvisorId } from '../../../../api/Advisors/types/Advisor';
import {
  Appointment,
  AppointmentId,
} from '../../../../api/Appointments/types/Appointment';

export interface Item {
  advisorId: AdvisorId;
  advisorName: string;
  end: Date;
  id: AppointmentId;
  selected: boolean;
  start: Date;
  status: Appointment['status'];
  topic: string;
}

export function fromAppointment(v: Appointment): Item {
  return {
    id: v.id,
    start: new Date(v.start),
    end: new Date(v.end),
    topic: v.topic,
    status: v.status,
    advisorId: v.advisorId,
    advisorName: v.advisorFullName,
    selected: false,
  };
}
