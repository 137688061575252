// region Open
export interface Open {
  type: 'Open';
}

export const open = (): Open => ({
  type: 'Open',
});
// endregion

// region Close
export interface Close {
  type: 'Close';
}

export const close = (): Close => ({
  type: 'Close',
});
// endregion

// region SetName
export interface SetName {
  type: 'SetName';
  payload: string;
}

export const setName = (payload: SetName['payload']): SetName => ({
  type: 'SetName',
  payload,
});
// endregion

// region SetPassword
export interface SetPassword {
  type: 'SetPassword';
  payload: string;
}

export const setPassword = (payload: SetPassword['payload']): SetPassword => ({
  type: 'SetPassword',
  payload,
});
// endregion

// region TogglePassword
export interface TogglePassword {
  type: 'TogglePassword';
}

export const togglePassword = (): TogglePassword => ({
  type: 'TogglePassword',
});
// endregion

// region LoginError
export interface LoginError {
  type: 'LoginError';
  payload: string;
}

export const loginError = (payload: LoginError['payload']): LoginError => ({
  type: 'LoginError',
  payload,
});
// endregion

// region LoginSuccess
export interface LoginSuccess {
  type: 'LoginSuccess';
}

export const loginSuccess = (): LoginSuccess => ({
  type: 'LoginSuccess',
});
// endregion

// region Login
export interface Login {
  type: 'Login';
}

export const login = (): Login => ({
  type: 'Login',
});
// endregion

// region Logout
export interface Logout {
  type: 'Logout';
}

export const logout = (): Logout => ({
  type: 'Logout',
});
// endregion

// region UserLoggedIn
export interface UserLoggedIn {
  type: 'UserLoggedIn';
}

export const userLoggedIn = (): UserLoggedIn => ({
  type: 'UserLoggedIn',
});
// endregion

// region UserLoggedOut
export interface UserLoggedOut {
  type: 'UserLoggedOut';
}

export const userLoggedOut = (): UserLoggedOut => ({
  type: 'UserLoggedOut',
});
// endregion

// region GoToReset
export interface GoToReset {
  type: 'GoToReset';
}

export const goToReset = (): GoToReset => ({
  type: 'GoToReset',
});
// endregion

// region ResetPassword
export interface ResetPassword {
  type: 'ResetPassword';
}

export const resetPassword = (): ResetPassword => ({
  type: 'ResetPassword',
});
// endregion

// region ResetSuccess
export interface ResetSuccess {
  type: 'ResetSuccess';
}

export const resetSuccess = (): ResetSuccess => ({
  type: 'ResetSuccess',
});
// endregion

// region ResetError
export interface ResetError {
  type: 'ResetError';
  payload: string;
}

export const resetError = (payload: ResetError['payload']): ResetError => ({
  type: 'ResetError',
  payload,
});
// endregion

export type Actions =
  | Open
  | Close
  | SetName
  | SetPassword
  | TogglePassword
  | LoginError
  | Login
  | Logout
  | UserLoggedIn
  | UserLoggedOut
  | GoToReset
  | ResetPassword
  | ResetSuccess
  | ResetError;
