import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import eventsAPI, { Event } from '../api/events';
import reportsAPI, { DashboardReportResult } from '../api/reports';
import {
  CardEventsSvg,
  CardMentorsSvg,
  CardVenturesSvg,
} from '../assets/images';
import DashboardCard, {
  DashboardCardProps,
} from '../components/dashboard/card';
import { UpcomingEvents } from '../components/dashboard/upcoming-events';
import BaseLayout from '../components/layout/base-layout';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { UserContext } from '../contexts/user-context';
import { useSafeInvoke } from '../hooks';
import { getRoutePath, Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { encodeQuery } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    width: 'calc(100% - 40px)',
    marginLeft: '-28px',
    flexDirection: 'column',
    padding: '0 20px',
    rowGap: 20,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },

    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% + 28px)',
      flexDirection: 'row',
      rowGap: 0,
    },

    '& > *': {
      marginLeft: '28px',
      maxWidth: 'auto',
      width: '100%',
      minHeight: '352px',
      boxSizing: 'border-box',

      [theme.breakpoints.up('sm')]: {
        maxWidth: '33.3333%',
      },
    },
  },
}));

function DashboardPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [report, setReport] = useState<DashboardReportResult>();
  const [events, setEvents] = useState<Event[]>();
  const [loading, setIsLoading] = useState(true);
  const { hasAccessToAction } = useContext(UserContext);

  const safeInvoke = useSafeInvoke();

  const loadReports = async () => {
    const [reportResult, eventsResult] = await Promise.all([
      reportsAPI.dashboardReport(),
      eventsAPI.upcomingEvents(),
    ]);
    safeInvoke(() => {
      setReport(reportResult);
      setEvents(eventsResult);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !report || !events) {
    return <BaseLayout user={user} isLoading />;
  }

  const getCardProps = (
    cardType: keyof DashboardReportResult,
  ): DashboardCardProps => {
    switch (cardType) {
      case 'mentors': {
        return {
          logo: <CardMentorsSvg />,
          title: `Active ${rb('mentors-u')}`,
          statHelp: 'Were added / removed in the last month',
          cardType: cardType,
          statistic: {
            total: report[cardType].active,
          },
          addLink: hasAccessToAction('dashboard.clickCreateButtonMentor')
            ? Pages.NEW_MENTOR
            : undefined,
          link: Pages.ACTIVE_MENTORS,
          menuItems: [
            {
              count: report[cardType].applicants,
              label: 'Applicants',
              link: Pages.MENTORS_APPLICANTS,
              'data-testid': 'dashboard-mentors-applicants',
            },
            {
              count: report[cardType].notAssigned,
              label: 'Not assigned',
              link: Pages.NOT_ASSIGNED_MENTORS,
              'data-testid': 'dashboard-mentors-not-assigned',
            },
            {
              count: report[cardType].oneVenture,
              label: 'One venture',
              link: Pages.ONE_MENTOR,
              'data-testid': 'dashboard-mentors-one-venture',
            },
            {
              count: report[cardType].twoVentures,
              label: 'Two ventures',
              link: Pages.TWO_MENTORS,
              'data-testid': 'dashboard-mentors-two-venture',
            },
            {
              count: report[cardType].threeVentures,
              label: '3+ ventures',
              link: Pages.MORE_MENTORS,
              'data-testid': 'dashboard-mentors-more-venture',
            },
          ],
        };
      }
      case 'ventures': {
        return {
          logo: <CardVenturesSvg />,
          title: 'Active Ventures',
          cardType: cardType,
          statHelp: 'Were added / removed in the last month',
          statistic: {
            total: report[cardType].active,
          },
          addLink: hasAccessToAction('dashboard.clickCreateButtonVentue')
            ? Pages.NEW_VENTURE
            : undefined,
          link: Pages.ACTIVE_VENTURES,
          menuItems: [
            {
              count: report[cardType].applicants,
              label: 'Applicants',
              link: Pages.VENURE_APPLICANTS,
              'data-testid': 'dashboard-ventures-applicants',
            },
            {
              count: report[cardType].notAssigned,
              label: 'Not assigned',
              link: Pages.NOT_ASSIGNED_VENTURES,
              'data-testid': 'dashboard-ventures-not-assigned',
            },
            {
              count: report[cardType].oneMentor,
              label: `One ${rb('mentor')}`,
              link: Pages.ONE_VENTURE,
              'data-testid': 'dashboard-ventures-one-mentor',
            },
            {
              count: report[cardType].twoMentors,
              label: `Two ${rb('mentors')}`,
              link: Pages.TWO_VENTURES,
              'data-testid': 'dashboard-ventures-two-mentors',
            },
            {
              count: report[cardType].noEvents,
              label: 'No sessions',
              link: Pages.NO_SESSIONS_VENTURES,
              'data-testid': 'dashboard-ventures-no-sessions',
            },
          ],
        };
      }
      default: {
        const monthName = format(new Date(), 'MMMM');
        return {
          logo: <CardEventsSvg />,
          title: `${monthName} Sessions`,
          cardType: cardType,
          help: 'The total number of sessions expected in the current month (both past and future)',
          statistic: {
            total: report[cardType].active,
          },
          addLink: Pages.NEW_SESSIONS,
          link: Pages.SESSIONS,
          replaceableCounter: true,
          menuItems: [
            {
              count: report[cardType].needAttention,
              label: 'Attention Required',
              link: getRoutePath(Pages.NO_AGENDA_SESSIONS, {
                filter: encodeQuery({ status: 'REQUIRE_ATTENTION' }),
              }),
              'data-testid': 'dashboard-sessions-need-attention',
            },
          ],
        };
      }
    }
  };

  return (
    <BaseLayout user={user}>
      <div className={classes.cards}>
        {Object.keys(report).map((reportType) => (
          <DashboardCard
            key={reportType}
            {...getCardProps(reportType as keyof DashboardReportResult)}
          />
        ))}
      </div>
      {!!events.length && (
        <UpcomingEvents events={events} title='Upcoming Sessions' />
      )}
    </BaseLayout>
  );
}

export default DashboardPage;
