import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  TableCell,
  TableHeadCell,
  TableHeader,
  TableHeadRow,
  TableRow,
} from '../../common/table';
import { makeStyles, Paper } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';
import { Column, Table as RTable } from 'react-virtualized';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  headerCol: {
    display: 'flex',
  },
  hoverCol: {
    visibility: 'hidden',

    '$row:hover &': {
      visibility: 'visible',
    },
  },
  row: {
    display: 'flex',
    columnGap: '10px',
  },
  linkedinButton: {
    padding: 10,
  },
  linkedinIcon: {
    color: '#0A66C2',
    fontSize: 20,
  },
  alignStart: { justifyContent: 'start' },
  alignCenter: { justifyContent: 'center' },
  alignEnd: { justifyContent: 'end' },
}));

type Col = {
  title: ReactNode;
  width: number;
  hoverOnly?: boolean;
  alignContent?: 'start' | 'center' | 'end';
  truncated?: boolean;
};

type Content<T> =
  | { type: 'reloading'; data: Array<T> }
  | { type: 'ready'; data: Array<T> };

export interface Props<K extends string> {
  columns: { [k in K]: Col };
  content: Content<{ [k in K]: ReactNode }>;
  onRowClick?: (index: number) => void;
  header?: ReactNode;
  footer?: ReactNode;
  empty: ReactNode;
}

export function Table<K extends string>({
  onRowClick,
  content,
  columns,
  header,
  footer,
  empty,
}: Props<K>): ReactElement {
  const classes = useStyles();
  const handleRowClick = useCallback(
    ({ index }: { index: number }) => onRowClick?.(index),
    [onRowClick],
  );

  const allItemsCount = allCount(content);

  return (
    <Paper className={classes.container}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width: width }}>
            <TableHeader>{header}</TableHeader>
            {isEmpty(content) ? (
              <div className={classes.emptyBlock}>{empty}</div>
            ) : (
              <RTable
                onRowClick={handleRowClick}
                gridClassName={classes.table}
                rowClassName={classes.row}
                height={64 * 10 + 56}
                rowHeight={64}
                headerHeight={56}
                rowCount={allItemsCount}
                width={width}
                rowGetter={({ index }) => content.data[index]}
                headerRowRenderer={(headRowProps) => (
                  <TableHeadRow {...headRowProps} />
                )}
                rowRenderer={(rowProps) => <TableRow {...rowProps} />}>
                {/* @ts-expect-error, The Object.entries is too dumb to infer properly the types */}
                {Object.entries(columns).map(([k, v]: [K, Col]) => {
                  return (
                    <Column
                      dataKey={k}
                      headerClassName={classNames({
                        [classes.headerCol]: true,
                        [classes.alignStart]: v.alignContent === 'start',
                        [classes.alignCenter]: v.alignContent === 'center',
                        [classes.alignEnd]: v.alignContent === 'end',
                      })}
                      className={classNames(classes.column, {
                        [classes.hoverCol]: v.hoverOnly,
                        [classes.alignStart]: v.alignContent === 'start',
                        [classes.alignCenter]: v.alignContent === 'center',
                        [classes.alignEnd]: v.alignContent === 'end',
                      })}
                      width={v.width}
                      minWidth={v.width}
                      flexGrow={1}
                      headerRenderer={() => (
                        <TableHeadCell>{v.title}</TableHeadCell>
                      )}
                      cellRenderer={({ cellData }) => (
                        <TableCell truncated={v.truncated}>
                          {cellData}
                        </TableCell>
                      )}
                    />
                  );
                })}
              </RTable>
            )}
            {footer}
          </div>
        )}
      </AutoSizer>
    </Paper>
  );
}

function allCount(c: Content<any>) {
  switch (c.type) {
    case 'ready':
    case 'reloading': {
      return c.data.length;
    }
  }
}

function isEmpty(c: Content<any>): boolean {
  return c.data.length === 0;
}
