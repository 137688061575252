import { makeStyles, Link as MaterialLink } from '@material-ui/core';
import cn from 'classnames';
import { useMemo, SyntheticEvent } from 'react';
import { useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import { MentorMonthlyReportItem } from '../../api/reports';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages, getRoutePath } from '../../router/constants';
import { Link, Text } from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';

interface Props {
  status: 'great' | 'ok' | 'bad';
  mentorsReport?: MentorMonthlyReportItem[];
}

const useStyles = makeStyles((theme) => ({
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  date: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  summary: {
    paddingRight: 20,
    paddingLeft: 20,
    boxSizing: 'border-box',
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeadRow: {
    borderTop: 'none',
  },
}));

function MentorAttendanceTable({ status, mentorsReport }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div>
      {mentorsReport && mentorsReport.length > 0 && (
        <div>
          <Table
            gridClassName={classes.table}
            headerHeight={56}
            height={
              64 * (mentorsReport.length > 10 ? mentorsReport.length : 10) + 56
            }
            rowHeight={64}
            rowCount={mentorsReport.length}
            rowGetter={({ index }) => mentorsReport[index]}
            rowClassName={classes.row}
            headerRowRenderer={(headRowProps) => (
              <TableHeadRow
                {...headRowProps}
                className={classes.tableHeadRow}
              />
            )}
            rowRenderer={(rowProps) => (
              <TableRow
                data-testid={`reports-table-row-${rowProps.index}`}
                {...rowProps}
              />
            )}
            width={450}>
            <Column
              dataKey='name'
              className={cn(classes.column, classes.summary)}
              headerClassName={classes.summary}
              width={50}
              minWidth={50}
              flexGrow={1}
              cellRenderer={({ rowIndex }) => {
                return (
                  <div className={classes.summaryContainer}>
                    <TableCell truncated>
                      <Text variant='normal'>{rowIndex + 1}</Text>
                    </TableCell>
                  </div>
                );
              }}
            />

            <Column
              dataKey='name'
              className={cn(classes.column, classes.date)}
              headerClassName={classes.date}
              width={204}
              minWidth={204}
              maxWidth={400}
              flexGrow={1}
              headerRenderer={() => (
                <TableHeadCell>{rb('mentor-u')} Name</TableHeadCell>
              )}
              cellRenderer={({ rowData }) => {
                return (
                  <Text variant='normal'>
                    <MaterialLink
                      data-testid='mentor-table-name'
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.MENTOR_DETAILS, {
                          mentorId: rowData.mentor.id,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {rowData.mentor.firstName} {rowData.mentor.lastName}
                    </MaterialLink>
                  </Text>
                );
              }}
            />

            <Column
              dataKey='attendance'
              className={cn(classes.column, classes.date)}
              headerClassName={classes.date}
              width={100}
              minWidth={100}
              flexGrow={1}
              headerRenderer={() => <TableHeadCell>Attendance</TableHeadCell>}
              cellRenderer={({ cellData }) => {
                return <TableCell>{(cellData * 100).toFixed(2)}%</TableCell>;
              }}
            />
          </Table>
        </div>
      )}
    </div>
  );
}

export default MentorAttendanceTable;
