import { makeStyles, Typography } from '@material-ui/core';
import { Audience } from '../../../api/CustomQuestion/types/Audience';
import { Text } from '../../../components/common';
import TenantCustomQuestionsBlock from './tenant-custom-question-block';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  title: {
    margin: '0 0 8px',
  },
  subtitle: {
    margin: '0 0 20px',
  },
  toggler: {
    margin: '10px 0',
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0',
  },
});

function TenantCustomQuestionsPage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant='h3'
        className={classes.title}
        data-testid='links-page-href'>
        Customize Your Forms
      </Typography>
      <Text variant='normal' className={classes.subtitle}>
        Change forms used in different parts of Traction5. Note that the custom
        form needs to be created and completed first before it becomes
        available.
      </Text>

      <TenantCustomQuestionsBlock audience={Audience.MENTOR_ADDITIONAL_INFO} />
      <TenantCustomQuestionsBlock audience={Audience.FOUNDER_ADDITIONAL_INFO} />
      <TenantCustomQuestionsBlock audience={Audience.VENTURE_ADDITIONAL_INFO} />
    </div>
  );
}

export default TenantCustomQuestionsPage;
