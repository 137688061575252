import { Advisor } from '../../../../../api/Advisors/types/Advisor';
import { Specialization } from '../../../../../api/specializations';
import { TenantTimeZone } from '../../../../../api/tenants/types/Settings';

// region LoadError

export interface LoadError {
  type: 'LoadError';
  payload: number;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    advisor: Advisor;
    specializations: Specialization[];
    strictOfficeHoursScheduling: boolean;
    timeZone: TenantTimeZone;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

export type Actions = LoadError | LoadSuccess;
