import { makeStyles, Slider, Typography, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CreateCheckin } from '../../../api/types/Checkin';
import { Goal } from '../../../api/types/Goal';
import { COLORS } from '../../../theme/variables';
import { GoalModal } from './GoalModal';

export interface GoalItemProps {
  order: number;
  goal: Goal;
  onChange: (goal: CreateCheckin) => void;
}

export function GoalItem({ order, onChange, goal }: GoalItemProps) {
  const c = useStyles();
  const [value, setValue] = useState(goal);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setValue(goal);
  }, [goal]);

  return (
    <div className={c.root}>
      <Tooltip title={goal.title}>
        <Typography variant={'caption'} className={c.title}>
          {order}. {goal.title}
        </Typography>
      </Tooltip>
      <Slider
        className={c.slider}
        value={value.progress}
        onChange={(_, v) => setValue({ ...value, progress: v as number })}
        onChangeCommitted={() => setModalOpen(true)}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '0%' },
          { value: 100, label: '100%' },
        ]}
        valueLabelDisplay='on'
      />
      {modalOpen && (
        <GoalModal
          goal={value}
          onChange={(value) => {
            onChange(value);
            setModalOpen(false);
          }}
          onClose={() => {
            setValue(goal);
            setModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    color: COLORS.COLOR_GRAY_DARKENED,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'default',
  },
  slider: {
    padding: '13px 0',
    '& .MuiSlider-markLabel': {
      fontSize: '8px',
      fontWeight: 500,
      top: '20px',
      transform: 'none',

      "&[data-index='1']": {
        right: '0',
        left: 'unset !important',
      },
    },

    '& .MuiSlider-valueLabel': {
      transform: 'none',
      top: '-11px',

      '&  *': {
        color: COLORS.COLOR_GRAY_DARKENED,
        backgroundColor: 'transparent',
        transform: 'none',
        fontSize: '8px',
        fontWeight: 700,
      },
    },
  },
});
