import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Module } from '../../api/user/Module';
import { Role } from '../../api/user/Role';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { Pages } from '../../router/constants';
import { useRedirectRoute } from '../../router/useRedirectRoute';

interface Props {
  rule?: string;
  module?: Module;
  role?: Role;
  exact?: boolean;
  path: string;
  component: React.FunctionComponent;
  isProtected?: boolean;
  redirectUrl?: string;
}

const RouteACL = ({
  rule,
  role,
  module,
  redirectUrl,
  ...routeProps
}: Props) => {
  const auth = useAuth();
  const { hasAccessToAction, hasAccessToModal, hasRole } =
    useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const isRule = rule ? hasAccessToAction(rule) : true;
  const isModule = module ? hasAccessToModal(module) : true;
  const isRole = role ? hasRole(role) : true;

  const redirect = useRedirectRoute(true, true);

  useEffect(() => {
    if (!auth.isAuthorized) {
      return;
    }

    if (isRule && !isModule) {
      enqueueSnackbar(
        'You do not have access to this module, please contact sales@traction5.com',
        { variant: 'error' },
      );
    } else if (isRule && !isRole) {
      enqueueSnackbar(
        'You do not have enough permissions, please contact sales@traction5.com',
        { variant: 'error' },
      );
    }
  }, [isRule, isModule, isRole, enqueueSnackbar, auth.isAuthorized]);

  if (!auth.isAuthorized) {
    return <Redirect to={Pages.LOGIN} />;
  }

  if (isRule && isModule && isRole) {
    return <Route {...routeProps} />;
  }

  if (redirectUrl && redirectUrl !== routeProps.path) {
    return <Redirect to={redirectUrl} />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return null;
};
export default RouteACL;
