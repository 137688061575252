import { ReactElement, ReactNode } from 'react';
import { TenantId } from '../../../../api/tenants/Tenant';
import { Footer1 } from '../../../../components/Pages/Footers/Footer1';
import { LogoOnly } from '../../../../components/Pages/Headers/LogoOnly';
import { PageWrapper as Component } from '../../../../components/Pages/PageWrapper';
import { AdvisorSettingsProvider } from '../../../../contexts/advisor-settings-context';
import { getRoutePath, Pages } from '../../../../router/constants';
import { LoginButton } from './LoginButton';

export interface Props {
  tenantId: TenantId;
  children: ReactNode;
  className?: string;
}

export function PageWrapper({
  tenantId,
  children,
  className,
}: Props): ReactElement {
  return (
    <AdvisorSettingsProvider id={tenantId}>
      <Component
        className={className}
        header={
          <LogoOnly
            id={tenantId}
            to={getRoutePath(Pages.PUBLIC_OH_LANDING, { tenantId })}>
            <LoginButton />
          </LogoOnly>
        }
        footer={<Footer1 />}>
        {children}
      </Component>
    </AdvisorSettingsProvider>
  );
}
