import { axiosRequest } from '../instance';
import { TenantId } from './Tenant';
import { Settings } from './types/Settings';

export function getLogo(tenantId: TenantId): Promise<string> {
  return axiosRequest
    .get<string>({ url: `/public/tenants/${tenantId}/logo` })
    .then((v) => v.data)
    .catch(() => '');
}

export function getSettings(id: TenantId): Promise<Settings> {
  return axiosRequest
    .get<Settings>({ url: `/public/tenants/${id}/settings` })
    .then((r) => r.data);
}
