import { Text } from '../../common';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import React, { ReactElement } from 'react';
import { TestId } from '../../Testing/TestId';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
  },
}));

export interface Props {
  count: number;
  archivedDisabled?: boolean;
  unArchivedDisabled?: boolean;
  onArchive: () => void;
  onUnArchive: () => void;
}

export function ArchiveFilter({
  count,
  archivedDisabled,
  unArchivedDisabled,
  onArchive,
  onUnArchive,
}: Props): ReactElement<Props> {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text variant='normal'>{count} selected</Text>
      <Tooltip title='Archive'>
        <TestId testId='archive'>
          <IconButton onClick={onArchive} disabled={archivedDisabled}>
            <ArchiveIcon />
          </IconButton>
        </TestId>
      </Tooltip>
      <Tooltip title='Un-Archive'>
        <TestId testId='unarchive'>
          <IconButton onClick={onUnArchive} disabled={unArchivedDisabled}>
            <UnarchiveIcon />
          </IconButton>
        </TestId>
      </Tooltip>
    </div>
  );
}
