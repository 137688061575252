import {
  TextField,
  TextFieldProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useState, useCallback, useRef, useEffect } from 'react';
import venturesAPI from '../../api/ventures';
import { COLORS } from '../../theme/variables';

export type FieldProps = TextFieldProps & {
  onChange: (value: any) => any;
  small?: boolean;
  name: string;
  label: string;
  value?: string;
  onValid?: (valid: boolean) => any;
  testid?: string;
};

const useStyles = makeStyles({
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,
    },
    '& .MuiFormLabel-root': {
      color: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
});

export const checkVentureForDuplicates = async (ventureName: string) => {
  const venturesRequest = await venturesAPI.getVenturesByName(ventureName);
  const targetVenture = venturesRequest.some(
    (venture) => venture.ventureName === ventureName,
  );
  return targetVenture;
};

function FieldNameVenture({
  onChange,
  small = false,
  name,
  label,
  value: initialValue,
  InputProps,
  onValid,
  testid,
  error,
}: FieldProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(initialValue);
  const currentValue = useRef<string>();
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | ''>();

  const searchVentures = async (ventureName: string) => {
    try {
      const hasDublicateName = await checkVentureForDuplicates(ventureName);
      setStatus('loading');
      if (hasDublicateName) {
        enqueueSnackbar('A venture with the same name already exists', {
          variant: 'error',
        });
        setStatus('error');
      } else {
        setStatus('success');
      }
    } catch (e: any) {
      setStatus('');
    }
  };

  const handleChange = (e: any) => {
    const originalValue = e.target.value;
    setStatus('');
    onChange(originalValue);
    setValue(originalValue);
    currentValue.current = originalValue.trim();
  };

  const handleBlur = () => {
    const trimmedValue = currentValue.current;
    if (trimmedValue && trimmedValue.length > 2 && initialValue !== value) {
      setStatus('loading');
      searchVentures(trimmedValue);
    } else {
      setStatus('');
    }
  };

  const getEndAdornment = useCallback(() => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!initialValue) {
      setValue('');
    }
  }, [initialValue]);

  useEffect(() => {
    if (onValid) {
      const valid = status !== 'error' && status !== 'loading';
      onValid(valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <TextField
      name={name}
      label={label}
      className={cn({
        [classes.error]: error || status === 'error',
        [classes.small]: small,
      })}
      onChange={handleChange}
      onFocus={() => {
        setStatus('');
      }}
      value={value}
      variant='outlined'
      fullWidth
      onBlur={handleBlur}
      aria-invalid={!!error || status === 'error'}
      InputProps={{
        ...(InputProps ? InputProps : {}),
        endAdornment: getEndAdornment(),
      }}
      data-testid={testid}
    />
  );
}

export default FieldNameVenture;
