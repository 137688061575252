import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { TestId } from '../../components/Testing/TestId';
import { Text, Button } from '../../components/common';
import { ActionsDialog } from '../../components/common/Confirmation/ActionsDialog';
import { COLORS } from '../../theme/variables';

interface RefundPaymentModalProps {
  isOpen: boolean;
  onClose: () => any;
  onSubmit: (onSuccess: () => void, onError: () => void) => Promise<void>;
  isLoading?: boolean;
}

enum RefundPaymentStep {
  CONFIRMATION = 'CONFIRMATION',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const useStyles = makeStyles(() => ({
  text: {
    textWrap: 'pretty',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    margin: '16px 0 0',
  },
  successIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  mainIcon: {
    fontSize: '48px',
  },
}));

const initialStep = RefundPaymentStep.CONFIRMATION;

export function RefundPaymentModal({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: RefundPaymentModalProps) {
  const classes = useStyles();

  const [step, setStep] = useState<RefundPaymentStep>(initialStep);

  const handleRefund = useCallback(async () => {
    const onSucces = () => setStep(RefundPaymentStep.SUCCESS);
    const onError = () => setStep(RefundPaymentStep.ERROR);

    await onSubmit(onSucces, onError);
  }, [onSubmit]);

  useEffect(() => {
    // Reset step when dialog is opened
    if (isOpen) {
      setStep(initialStep);
    }
  }, [isOpen]);

  const Actions = useMemo(() => {
    switch (step) {
      case RefundPaymentStep.CONFIRMATION:
        return () => (
          <>
            <TestId testId={'confirmation-cancel-button'}>
              <Button
                variant={'outlined'}
                onClick={onClose}
                disabled={isLoading}>
                Cancel
              </Button>
            </TestId>
            <TestId testId={'confirmation-ok-button'}>
              <Button
                variant={'contained'}
                onClick={handleRefund}
                disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Ok'
                )}
              </Button>
            </TestId>
          </>
        );
      case RefundPaymentStep.SUCCESS:
      case RefundPaymentStep.ERROR:
        return () => (
          <TestId testId={'confirmation-ok-button'}>
            <Button variant={'contained'} onClick={onClose}>
              Ok
            </Button>
          </TestId>
        );
    }
  }, [step, onClose, isLoading, handleRefund]);

  const Content = useMemo(() => {
    switch (step) {
      case RefundPaymentStep.CONFIRMATION:
        return () => (
          <Text variant={'normal'} className={classes.text}>
            This action will make an attempt to refund the payment made. Do you
            agree?
          </Text>
        );
      case RefundPaymentStep.SUCCESS:
        return () => (
          <div className={classes.contentContainer}>
            <CheckCircleIcon
              color='error'
              className={cn(classes.mainIcon, classes.successIcon)}
            />
            <Text variant={'normal'} className={classes.text}>
              The payment was successfully refunded. Thank you.
            </Text>
          </div>
        );
      case RefundPaymentStep.ERROR:
        return () => (
          <div className={classes.contentContainer}>
            <ErrorIcon color='error' className={classes.mainIcon} />
            <Text variant={'normal'} className={classes.text}>
              We had a hiccup while trying to refund the payment, please login
              to Stripe to address the problem.
            </Text>
          </div>
        );
    }
  }, [classes, step]);

  return createPortal(
    <ActionsDialog
      open={isOpen}
      onClose={onClose}
      title={'Refund'}
      actions={<Actions />}>
      <Content />
    </ActionsDialog>,
    document.body,
  );
}
