import { useSnackbar } from 'notistack';
import {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { AdvisorsList as List } from '../../../../components/Advisors/AdvisorsList';
import { WithTitle } from '../../../../components/Pages/Content/WithTitle';
import { PageLoader } from '../../../../components/common';
import { useCachedAdvisors } from '../../../../contexts/cached-advisors-context';
import { useResourceBundles } from '../../../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../../../router/constants';
import { Book } from '../common/Book/Book';
import { book } from '../common/Book/types/Actions';
import { useBook } from '../common/Book/useBook';
import { PageWrapper } from '../common/PageWrapper';
import { FiltersHeader } from './FiltersHeader';
import { next, prev, setFilter } from './types/Actions';
import { usePublicAdvisors } from './usePublicAdvisors';
import { getSpecializations } from './utils';

export function AdvisorsList(): ReactElement {
  const { scrollPositionY, setScrollPositionY } = useCachedAdvisors();
  const [state, dispatch] = usePublicAdvisors();
  const { enqueueSnackbar } = useSnackbar();
  const [bookState, bookDispatch] = useBook();
  const { rb } = useResourceBundles();

  const handleNext = useCallback(() => dispatch(next()), [dispatch]);
  const handlePrev = useCallback(() => dispatch(prev()), [dispatch]);

  const content = useMemo((): ReactElement => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return <PageLoader />;
      case 'Fetching':
      case 'FetchError':
      case 'Ready':
        return (
          <List
            loading={state.type === 'Fetching'}
            onBook={(id) => {
              const advisor = state.payload.items.find((i) => i.id === id);

              advisor &&
                bookDispatch(
                  book({
                    advisor,
                    timeZone: state.payload.timeZone,
                    strictOfficeHoursScheduling:
                      state.payload.strictOfficeHoursScheduling,
                    tenantId: state.payload.tenantId,
                  }),
                );
            }}
            items={state.payload.items.map((i) => ({
              ...i,
              bookStatus:
                bookState.type === 'BookingLoading' &&
                bookState.payload.advisor.id === i.id
                  ? 'disabled'
                  : 'active',
              view: getRoutePath(Pages.PUBLIC_ADVISOR_PROFILE, {
                tenantId: state.payload.tenantId,
                advisorId: i.id,
              }),
            }))}
            onNext={handleNext}
            onPrev={handlePrev}
            disableNext={!state.payload.next}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, handleNext, handlePrev, bookDispatch, bookState.type]);

  useEffect(() => {
    if (state.type === 'LoadError') {
      enqueueSnackbar(state.payload.message, { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type === 'LoadError']);

  const updateScrollPosition = () => {
    setScrollPositionY(window.scrollY);
  };

  useLayoutEffect(() => {
    if (scrollPositionY) {
      window.scrollTo(0, scrollPositionY);
    }

    return () => {
      updateScrollPosition();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper tenantId={state.payload.tenantId}>
      <WithTitle title={`Our ${rb('advisors-u')}`}>
        <FiltersHeader
          specializations={getSpecializations(state)}
          filter={state.payload.filter}
          onChange={(f) => dispatch(setFilter(f))}
        />
        {content}
      </WithTitle>
      <Book state={bookState} dispatch={bookDispatch} />
    </PageWrapper>
  );
}
