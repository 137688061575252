import { createContext, ReactNode, useCallback, useState } from 'react';
import { MentorId } from '../api/mentors';
import { Specialization } from '../api/specializations';
import venturesAPI from '../api/ventures';
import { Venture, VentureId } from '../api/ventures/types/Venture';
import { LoadDataState, useLoadData } from '../hooks/useLoadData';

export interface VentureContextProps {
  venture?: Venture;
  assignedMentors: LoadDataState<MentorId[], VentureId>;
  loadMentors: (id: VentureId) => void;
  ventureSpecializations?: Specialization['id'][];
  ventureTags?: string[];
  updateVenture: (venture: Venture) => any;
  updateSpecializations: (specializations: Specialization['id'][]) => any;
  updateVentureTags: (tags: string[]) => any;
  loadVenture: (ventureId: VentureId) => Promise<any>;
  loadVentureSpecializations: (ventureId: VentureId) => Promise<any>;
  loadVentureTags: (ventureId: VentureId) => Promise<any>;
  isVentureLoading: boolean;
  isSpecializationsLoading: boolean;
  isTagsLoading: boolean;
}

export const VentureContext = createContext<VentureContextProps>({
  updateVenture: () => {},
  updateSpecializations: () => {},
  updateVentureTags: () => {},
  loadVenture: async () => {},
  loadVentureSpecializations: async () => {},
  loadVentureTags: async () => {},
  isVentureLoading: true,
  isSpecializationsLoading: true,
  isTagsLoading: true,
  assignedMentors: { type: 'Idle', payload: {} },
  loadMentors: () => {},
});

interface VentureProviderProps {
  children: ReactNode;
}

export const VentureProvider = ({ children }: VentureProviderProps) => {
  const [venture, setVenture] = useState<Venture>();
  const [ventureSpecializations, setVentureSpecializations] =
    useState<Specialization['id'][]>();
  const [ventureTags, setVentureTags] = useState<string[]>();
  const [isVentureLoading, setIsVentureLoading] = useState(true);
  const [isSpecializationsLoading, setIsSpecializationsLoading] =
    useState(true);
  const [isTagsLoading, setIsTagsLoading] = useState(true);
  const [mentorsState, mentorsDispatch] = useLoadData((id: VentureId) =>
    venturesAPI.getMentors(id).then((r) => r.map((m) => m.mentorId)),
  );

  const updateVenture = useCallback((venture: Venture) => {
    setVenture(venture);
  }, []);

  const updateSpecializations = useCallback(
    (specializations: Specialization['id'][]) => {
      setVentureSpecializations(specializations);
    },
    [],
  );

  const updateVentureTags = useCallback((tags: string[]) => {
    setVentureTags(tags);
  }, []);

  const loadVenture = useCallback(async (ventureId: VentureId) => {
    try {
      setIsVentureLoading(true);
      const loadedVenture = await venturesAPI.getVenture(ventureId);
      setVenture(loadedVenture);
      setIsVentureLoading(false);
    } catch (e: any) {
      setIsVentureLoading(false);
      console.log('error loadVenture', e);
    }
  }, []);

  const loadVentureSpecializations = useCallback(
    async (ventureId: VentureId) => {
      try {
        setIsSpecializationsLoading(true);
        const specializations = await venturesAPI.getVentureSpecializations(
          ventureId,
        );
        setVentureSpecializations(specializations.map((spec) => spec.id));
        setIsSpecializationsLoading(false);
      } catch (e: any) {
        setIsSpecializationsLoading(false);
        console.log('error loadVentureSpecializations', e);
      }
    },
    [],
  );

  const loadVentureTags = useCallback(async (ventureId: VentureId) => {
    try {
      setIsTagsLoading(true);
      const loadedTags = await venturesAPI.getVentureTagsById(ventureId);
      setVentureTags(loadedTags.map((tag) => tag.tagId));
      setIsTagsLoading(false);
    } catch (e: any) {
      setIsTagsLoading(false);
      console.log('error loadVentureTags', e);
    }
  }, []);

  const loadMentors = useCallback(
    async (ventureId: VentureId) =>
      mentorsDispatch({ type: 'Load', payload: ventureId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const contextValue: VentureContextProps = {
    venture,
    isVentureLoading,
    loadVenture,
    updateVenture,
    ventureSpecializations,
    isSpecializationsLoading,
    loadVentureSpecializations,
    updateSpecializations,
    ventureTags,
    isTagsLoading,
    loadVentureTags,
    updateVentureTags,
    loadMentors,
    assignedMentors: mentorsState,
  };

  return (
    <VentureContext.Provider value={contextValue}>
      {children}
    </VentureContext.Provider>
  );
};
