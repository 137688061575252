import { IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useState } from 'react';
import TaskForm, { Values as FormValues } from '../../components/forms/task';
import { COLORS } from '../../theme/variables';

interface NewTaskProps {
  creating?: boolean;
  onCreate: (
    newTask: { title: string; status: string; },
    callback: () => any,
  ) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },
  },
});

function NewTask({ creating, onCreate }: NewTaskProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);

  const handleOpenForm = () => {
    setIsShownForm(true);
  };

  const handleCloseForm = () => {
    setIsShownForm(false);
  };

  const handleCreateTask = useCallback(
    (values: FormValues) => {
      onCreate(
        { title: values.title, status: values.status },
        handleCloseForm,
      );
    },
    [onCreate],
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, []);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <TaskForm
          title='New Task'
          loading={creating}
          onSubmit={handleCreateTask}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div className={classes.main}>
          <Typography variant='h4'>New Task</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={handleOpenForm}
            data-testid='button-new-task'>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default NewTask;
