import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { ReactNode } from 'react';

const HTMLTooltip = withStyles(() => ({
  tooltip: {
    background: 'none',
    border: 'none',
    padding: '0',
  },
  tooltipPlacementTop: {
    right: '1px',
    top: '8px',
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'start',
    textAlign: 'left',
  },
}));

export interface TooltipMenuProps extends Omit<TooltipProps, 'title'> {
  items: ReactNode[];
}

export function TooltipMenu(props: TooltipMenuProps) {
  const { children, items, ...rest } = props;
  const classes = useStyles();

  return items.length ? (
    <HTMLTooltip
      {...rest}
      placement={'bottom'}
      title={<Card className={classes.itemsWrapper}>{items}</Card>}
      interactive={true}>
      {children}
    </HTMLTooltip>
  ) : (
    children
  );
}
