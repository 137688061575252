import { apply } from '../../../utils/Either';
import * as Sentence from '../../../utils/String/Sentence';
import { Actions } from './types/Actions';
import { none, search, status } from './types/Filter';
import { fromAdvisor } from './types/Item';
import * as State from './types/State';

const searchText = Sentence.fromString(10000);

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAdvisor),
            prev: s.payload.page > 1,
            next: a.payload.next,
          })
        : s;
    case 'Remove':
      return State.isEditable(s) &&
        s.payload.items.find((i) => i.id === a.payload)
        ? State.removeConfirmation({ ...s.payload, id: a.payload })
        : s;
    case 'RemoveConfirmation':
      return s.type === 'RemoveConfirmation'
        ? a.payload
          ? State.removing(s.payload)
          : State.ready(s.payload)
        : s;
    case 'RemoveError':
      return s.type === 'Removing'
        ? State.removeErr({ ...s.payload, message: a.payload })
        : s;
    case 'RemoveSuccess':
      return s.type === 'Removing'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAdvisor),
            next: a.payload.next,
          })
        : s;
    case 'ChangeStatus': {
      return State.isEditable(s) &&
        s.payload.items.filter((i) => i.selected).length > 0
        ? State.updateConfirmation({ ...s.payload, status: a.payload })
        : s;
    }
    case 'UpdateConfirmation':
      return s.type === 'UpdateConfirmation'
        ? a.payload
          ? State.updating(s.payload)
          : State.ready(s.payload)
        : s;
    case 'ToggleAll':
      return State.isEditable(s)
        ? s.payload.items.some((i) => !i.selected)
          ? State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: true })),
            })
          : State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: false })),
            })
        : s;
    case 'Toggle':
      return State.isEditable(s)
        ? State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, selected: !i.selected } : i,
            ),
          })
        : s;
    case 'UpdateError':
      return s.type === 'Updating'
        ? State.updateErr({ ...s.payload, message: a.payload })
        : s;
    case 'UpdateSuccess':
      return s.type === 'Updating'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAdvisor),
            next: a.payload.next,
          })
        : s;
    case 'NextPage':
      return State.isEditable(s) && s.payload.next
        ? State.loading({ ...s.payload, page: s.payload.page + 1 })
        : s;
    case 'PrevPage':
      return State.isEditable(s) && s.payload.page > 1
        ? State.loading({ ...s.payload, page: s.payload.page - 1 })
        : s;
    case 'Search': {
      return State.isNotConfirmation(s)
        ? State.loading({
            ...s.payload,
            filter: apply(none, search, searchText(a.payload)),
            page: 1,
          })
        : s;
    }
    case 'SetStatus': {
      return State.isNotConfirmation(s)
        ? State.loading({
            ...s.payload,
            filter: status(a.payload),
            page: 1,
          })
        : s;
    }
    case 'ClearFilters':
      return State.isNotConfirmation(s) && s.payload.filter.type !== 'none'
        ? State.loading({
            ...s.payload,
            filter: none(),
            page: 1,
          })
        : s;
  }
}
