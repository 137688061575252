export enum Pages {
  HOME = '/',
  LOGIN = '/login',
  REGISTRATION = '/register',
  RESET_PASSWORD = '/resetPassword',
  CHANGE_PASSWORD = '/changePassword',
  FILE_LINKS = '/fileLinks',
  UNSUBSCRIBE = '/unsubscribe',

  DASHBOARD = '/dashboard',
  // tenant page
  TENANT = '/tenant',
  TENANT_ACCOUNT = '/tenant/account',
  TENANT_PROFILE = '/tenant/profile',
  TENANT_SETTINGS = '/tenant/settings',
  TENANT_QUESTIONS = '/tenant/settings/questions',
  TENANT_AUTOMATION = '/tenant/settings/automation',
  TENANT_CHANNELS = '/tenant/channels',
  TENANT_USER_MANAGEMENT = '/tenant/userManagement',
  TENANT_FORMS = '/tenant/forms',
  TENANT_FORMS_EDIT = '/tenant/forms/:formId/edit',
  TENANT_NEW_ADMIN = '/tenant/userManagement/admin/new',
  TENANT_NEW_MENTOR = '/tenant/userManagement/mentor/new',
  TENANT_NEW_FOUNDER = '/tenant/userManagement/founder/new',
  TENANT_CONSENTS = '/tenant/consents',
  TENANT_CUSTOM_SPECIALIZATIONS = '/tenant/settings/customSpecializations',
  TENANT_CUSTOM_QUESTIONS = '/tenant/customQuestions',
  TENANT_CUSTOM_QUESTIONS_EDIT = '/tenant/customQuestions/audience/:audience/edit',
  TENANT_COHORTS = '/tenant/settings/cohorts',

  // mentor pages
  MENTOR_DASHBOARD = '/mentor-dashboard',
  MENTORS = '/mentors',
  ACTIVE_MENTORS = '/mentors?status=Active',
  NEW_MENTOR = '/mentors/new',
  NOT_ASSIGNED_MENTORS = '/mentors?assigned=0&status=Active',
  ONE_MENTOR = '/mentors?assigned=1&status=Active',
  TWO_MENTORS = '/mentors?assigned=2&status=Active',
  MORE_MENTORS = '/mentors?assigned=3&status=Active',
  MENTORS_APPLICANTS = '/mentors?status=Applicant',
  MENTORS_ARCHIVED = '/mentors?status=Archived',
  MENTORS_VENTURES = '/mentors?venture=:ventureFilter',
  MENTOR_DETAILS = '/mentors/:mentorId',
  MENTOR_ASSIGNMENTS = '/mentors/:mentorId/assignments',
  MENTOR_NOTES = '/mentors/:mentorId/notes',
  MENTOR_STATS = '/mentors/:mentorId/stats',
  MENTOR_LOG = '/mentors/:mentorId/log',
  MENTOR_ADDITIONAL_INFO = '/mentors/:mentorId/additionalInfo',
  MENTORS_APPLICATIONS_WIZARD = '/mentors/applications/wizard',
  MENTOR_UPDATE = '/mentors/updates',
  MENTOR_EXTERNAL = '/mentors/external/:mentorId',
  MENTOR_EXTERNAL_DETAILS = '/mentors/external/:mentorId/details',
  MENTOR_EXTERNAL_ADDITIONAL = '/mentors/external/:mentorId/additionalInfo',
  MY_MENTOR_DETAILS = '/mentors/:mentorId/ventures/:ventureId',
  MY_MENTOR_ADDITIONAL_INFO = '/mentors/:mentorId/ventures/:ventureId/additionalInfo',

  // venture pages
  VENTURES = '/ventures',
  ACTIVE_VENTURES = '/ventures?status=Active',
  NEW_VENTURE = '/ventures/new',
  NOT_ASSIGNED_VENTURES = '/ventures?assigned=0&status=Active',
  ONE_VENTURE = '/ventures?assigned=1&status=Active',
  TWO_VENTURES = '/ventures?assigned=2&status=Active',
  MORE_VENTURES = '/ventures?assigned=3&status=Active',
  VENTURE_ARCHIVED = '/ventures?status=Archived',
  VENURE_APPLICANTS = '/ventures?status=Applicant',
  NO_SESSIONS_VENTURES = '/ventures?sessions=noevents&status=Active',
  WITH_ISSUES_VENTURE = '/ventures?sessions=issues',
  VENTURES_MENTOR = '/ventures?mentor=:mentorFilter',
  VENTURE_DETAILS = '/ventures/:ventureId',
  VENTURE_FOUNDERS = '/ventures/:ventureId/founders',
  VENTURE_ASSIGNMENTS = '/ventures/:ventureId/assignments',
  VENTURE_NOTES = '/ventures/:ventureId/notes',
  VENTURE_LOG = '/ventures/:ventureId/log',
  VENTURE_FINANCE = '/ventures/:ventureId/finance',
  VENTURE_QUARTERLY_REPORT = '/ventures/:ventureId/quarterlyReport',
  VENTURE_ADDITIONAL_INFO = '/ventures/:ventureId/additionalInfo',
  VENTURE_APPLICATIONS_WIZARD = '/ventures/applications/wizard',
  VENTURE_UPDATE = '/ventures/updates',
  VENTURE_EXTERNAL = '/ventures/external/:ventureId',
  VENTURE_EXTERNAL_DETAILS = '/ventures/external/:ventureId/details',
  VENTURE_EXTERNAL_ADDITIONAL = '/ventures/external/:ventureId/additionalInfo',

  // founder pages
  FOUNDER_DASHBOARD = '/founder-dashboard',
  FOUNDERS = '/founders',
  ACTIVE_FOUNDERS = '/founders?status=Active',
  FOUNDERS_ARCHIVED = '/founders?status=Archived',
  FOUNDERS_APPLICANTS = '/founders?status=Applicant',
  NEW_FOUNDER = '/founders/new',
  NOT_ASSIGNED_FOUNDERS = '/founders?assigned=no',
  ONE_FOUNDER = '/founders?assigned=one',
  TWO_FOUNDERS = '/founders?assigned=two',
  MORE_FOUNDERS = '/founders?assigned=more',
  FOUNDER_DETAILS = '/founders/:founderId',
  FOUNDER_VENTURES = '/founders/:founderId/ventures',
  FOUNDER_ADDITIONAL_INFO = '/founders/:founderId/additionalInfo',
  FOUNDER_NOTES = '/founders/:founderId/notes',
  FOUNDER_LOG = '/founders/:founderId/log',
  FOUNDER_UPDATE = '/founders/updates',
  FOUNDER_EXTERNAL = '/founders/external/:founderId',
  FOUNDER_EXTERNAL_DETAILS = '/founders/external/:founderId/details',
  FOUNDER_EXTERNAL_ADDITIONAL = '/founders/external/:founderId/additionalInfo',

  // sessions pages
  SESSIONS = '/sessions',
  NEW_SESSIONS = '/sessions/new',
  SESSIONS_DETAILS = '/sessions/:sessionId',
  SESSIONS_NOTES = '/sessions/:sessionId/notes',
  SESSION_LOG = '/sessions/:sessionId/log',
  SESSIONS_LINKS = '/sessions/:sessionId/links',
  ACTIVE_SESSIONS = '/sessions/status=active',
  NOT_ASSIGNED_SESSIONS = '/sessions?assigned=no',
  NO_AGENDA_SESSIONS = '/sessions?filter=:filter',
  NEED_ATTENTION_SESSIONS = '/sessions?assigned=attention',

  // gatherings pages
  GATHERINGS = '/gatherings',
  NEW_GATHERINGS = '/gatherings/new',
  GATHERINGS_DETAILS = '/gatherings/:gatheringId',
  GATHERINGS_NOTES = '/gatherings/:gatheringId/notes',
  GATHERINGS_FOUNDERS = '/gatherings/:gatheringId/founders',
  GATHERINGS_MENTORS = '/gatherings/:gatheringId/mentors',
  GATHERINGS_GUESTS = '/gatherings/:gatheringId/guests',
  GATHERINGS_ATTENDANCE = '/gatherings/:gatheringId/attendance',

  //group

  // report pages
  REPORT_LEAD_MENTOR = '/report/leadMentor',
  REPORT_MENTOR_ASSESSMENT = '/report/mentorAssessment',
  REPORT_FOUNDER_SESSION_NOTES = '/report/founderSessionNotes',
  REPORT_AGENDA_REQUEST = '/report/agendaRequest',
  REPORT_VENTURE_QUARTER = '/ventures/quarterreport',

  //education pages
  EDUCATION_LIST = '/education/list',
  EDUCATION_EDIT = '/education/edit',

  //reports pages
  REPORTS_LIST = '/reports/list',
  REPORTS_DETAILS_VENTURE = '/reports/list/venture',
  REPORTS_DETAILS_MENTOR = '/reports/list/mentor',
  REPORTS_GENERAL = '/reports/list/general',
  REPORTS_QUARTERLY = '/reports/list/quarterly',

  //scheduling pages
  SCHEDULUNG_INITIAL = '/scheduling/initial',
  SCHEDULUNG_VOTE = '/scheduling/vote',
  SCHEDULING_CANCELLATION = '/scheduling/cancellation',

  //helpers
  WEBSITE = 'https://www.tractionfive.com/',
  OUR_BLOG = 'https://www.tractionfive.com/blog',
  PRIVACY = 'https://www.tractionfive.com/privacy',
  TERMS = 'https://www.tractionfive.com/terms',
  CONTACT_US = 'https://www.tractionfive.com/contact-us',

  TEST = '/calendar-test',

  // office hours
  PUBLIC_OH_LANDING = '/tenants/:tenantId/officehours/landing',
  OH_DASHBOARD = '/officehours',
  OH_APPOINTMENTS_NEW = '/appointments/new',
  OH_APPOINTMENTS = '/appointments',
  OH_APPOINTMENTS_EDIT = '/appointments/:id',
  OH_APPOINTMENTS_LOGS = '/appointments/:id/logs',

  // Advisors
  OH_ADVISORS = '/advisors',
  OH_ADVISORS_NEW = '/advisors/new',
  OH_ADVISORS_INFO = '/advisors/info',
  OH_ADVISORS_EDIT = '/advisors/:id',
  OH_ADVISORS_VIEW = '/advisors/view/:id',
  OH_ADVISORS_AVAILABILITIES = '/advisors/view/:id/availabilities',
  OH_ADVISORS_LOGS = '/advisors/view/:id/logs',
  PUBLIC_ADVISORS = '/tenants/:tenantId/officehours',
  PUBLIC_ADVISOR_PROFILE = '/tenants/:tenantId/officehours/:advisorId',

  // Community members
  OH_COMMUNITY_MEMBERS = '/community-members',
  OH_COMMUNITY_MEMBERS_CREATE = '/community-members/new',
  OH_COMMUNITY_MEMBERS_EDIT = '/community-members/:id',
  OH_COMMUNITY_MEMBERS_LOGS = '/community-members/:id/logs',
  OH_COMMUNITY_MEMBERS_NOTES = '/community-members/:id/notes',
  OH_REPORT_SUBMISSION = '/officehours/report-submission',
  OH_REPORTS = '/officehours/reports',
  OH_COMMUNITY_MEMBER_SIGN_UP = '/tenants/:tenantId/community-members/sign-up',
  OH_COMMUNITY_MEMBER_ENROLL = '/communitymembers/enroll',

  // Payments
  PAYMENT_SUCCESS = '/officehours/payment-success',
  PAYMENT_CANCEL = '/officehours/payment-cancel',
  OH_CANCEL_SESSION = '/officehours/cancel-session',

  // Goals
  GOALS = '/goals',
  NEW_GOAL = '/goals/new',
  GOAL_OVERVIEW = '/goals/:goalId',
  GOAL_TASKS = '/goals/:goalId/tasks',
  GOAL_LOG = '/goals/:goalId/log',
  GOAL_CHECK_INS = '/goals/:goalId/checkins',

  // Personal Notes
  PERSONAL_NOTES = '/personal-notes',
}

export function getRoutePath(
  URL: Pages,
  params: { [x: string]: any } | undefined = {},
) {
  let parsedURL: string = URL;
  for (let key in params) {
    parsedURL = parsedURL.replace(`:${key}`, params[key]);
  }
  return parsedURL;
}
