import { CommunityMemberId } from '../../../../api/CommunityMembers/types/CommunityMember';
import { ValueType } from '../../../../utils/FormValue';
import { Item } from './Item';

// region SetValue
export interface SetValue<K extends keyof Item> {
  type: 'SetValue';
  payload: {
    key: K;
    value: ValueType<Item[K]>;
  };
}

export const setValue = <K extends keyof Item>(
  payload: SetValue<K>['payload'],
): SetValue<K> => ({
  type: 'SetValue',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: keyof Item;
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Save
export interface Save {
  type: 'Save';
}

export const save = (): Save => ({
  type: 'Save',
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: CommunityMemberId;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: string;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region EmailValidation
export interface EmailValidation {
  type: 'EmailValidation';
  payload:
    | {
        isValid: true;
      }
    | {
        isValid: false;
        message: string;
      };
}

export const emailValidation = (
  payload: EmailValidation['payload'],
): EmailValidation => ({
  type: 'EmailValidation',
  payload,
});
// endregion

// region EmailValidationError
export interface EmailValidationError {
  type: 'EmailValidationError';
  payload: string;
}

export const emailValidationError = (
  payload: EmailValidationError['payload'],
): EmailValidationError => ({
  type: 'EmailValidationError',
  payload,
});
// endregion

// region NameValidation
export interface NameValidation {
  type: 'NameValidation';
  payload: boolean;
}

export const nameValidation = (
  payload: NameValidation['payload'],
): NameValidation => ({
  type: 'NameValidation',
  payload,
});
// endregion

// region NameValidationError
export interface NameValidationError {
  type: 'NameValidationError';
  payload: string;
}

export const nameValidationError = (
  payload: NameValidationError['payload'],
): NameValidationError => ({
  type: 'NameValidationError',
  payload,
});
// endregion

export type Actions =
  | SetValue<keyof Item>
  | Toggle
  | Save
  | SaveError
  | SaveSuccess
  | EmailValidation
  | EmailValidationError
  | NameValidation
  | NameValidationError;
