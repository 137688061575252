// region InvalidKey
import { TenantId } from '../../../api/tenants/Tenant';
import { AppointmentId } from '../../../api/Appointments/types/Appointment';
import { Email } from '../../../utils/String/Email';
import { Invalid, Valid, Value } from '../../../utils/FormValue';
import { Rating } from './Rating';
import { Sentence } from '../../../utils/String/Sentence';

export interface InvalidKeyPayload {
  key: string | undefined;
}

export interface InvalidKey {
  type: 'InvalidKey';
  payload: InvalidKeyPayload;
}

export const invalidKey = (payload: InvalidKey['payload']): InvalidKey => ({
  type: 'InvalidKey',
  payload,
});
// endregion

// region Loading
export interface LoadingPayload {
  tenantId: TenantId;
  appointmentId: AppointmentId;
  email: Email;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  advisorName: string;
  start: Date;
  end: Date;
  rating: Value<'required', Rating, Rating | undefined>;
  comment: Value<'required', Sentence<500> | undefined, string>;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Submitted
export interface SubmittedPayload extends ReadyPayload {
  rating: Invalid<'required', undefined> | Valid<Rating>;
  comment: Invalid<'required', string> | Valid<Sentence<500> | undefined>;
}

export interface Submitted {
  type: 'Submitted';
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted['payload']): Submitted => ({
  type: 'Submitted',
  payload,
});
// endregion

// region Saving
export interface SavingPayload extends SubmittedPayload {
  rating: Valid<Rating>;
  comment: Valid<Sentence<500> | undefined>;
}

export interface Saving {
  type: 'Saving';
  payload: SavingPayload;
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveErrorPayload extends SubmittedPayload {
  message: string;
}

export interface SaveError {
  type: 'SaveError';
  payload: SaveErrorPayload;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccessPayload {}

export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: SaveSuccessPayload;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

export type State =
  | InvalidKey
  | Loading
  | LoadError
  | Ready
  | Submitted
  | Saving
  | SaveError
  | SaveSuccess;

export function isEditable(s: State): s is Ready | Submitted | SaveError {
  switch (s.type) {
    case 'Ready':
    case 'Submitted':
    case 'SaveError':
      return true;
    case 'Saving':
    case 'SaveSuccess':
    case 'InvalidKey':
    case 'LoadError':
    case 'Loading':
      return false;
  }
}
