import { useCallback, useContext, useMemo } from 'react';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { BeforeUnload } from '../../../components/common/Confirmation/BeforeUnload';
import { FormItem2 } from '../../../components/common/Forms/types/FormItem';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { Form } from '../common/Form';
import {
  createField,
  formValueToInput,
  formValueToInput2,
  getTabs,
  statusOptions,
} from '../common/utils';
import * as Actions from './types/Actions';
import { useAdvisorAdd } from './useAdvisorAdd';
import { formEdited, isSubmittable } from './utils';

export function AddAdvisor({ user }: ProtectedRouteProps) {
  const [s, dispatch] = useAdvisorAdd(user);
  const { hasAccessToAction } = useContext(UserContext);
  const { rb } = useResourceBundles();
  const { item, enrollmentDate } = s.payload;
  const {
    firstName,
    lastName,
    email,
    state,
    linkedIn,
    long,
    status,
    logo,
    country,
    address,
    birthDate,
    phone,
    zip,
    city,
    short,
    specializations,
    rate,
  } = item;

  const _firstName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(firstName),
      onBlur: () => dispatch(Actions.toggle('firstName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'firstName', value: v })),
    }),
    [firstName, dispatch],
  );
  const _lastName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(lastName),
      onBlur: () => dispatch(Actions.toggle('lastName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'lastName', value: v })),
    }),
    [lastName, dispatch],
  );
  const _email = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(email),
      onBlur: () => dispatch(Actions.toggle('email')),
      onChange: (v) => dispatch(Actions.setValue({ key: 'email', value: v })),
    }),
    [email, dispatch],
  );
  const _state = useMemo(
    () => createField(state, 'state', dispatch),
    [state, dispatch],
  );
  const _linkedIn = useMemo(
    () => createField(linkedIn, 'linkedIn', dispatch),
    [linkedIn, dispatch],
  );
  const _long = useMemo(
    () => createField(long, 'long', dispatch),
    [long, dispatch],
  );
  const _short = useMemo(
    () => createField(short, 'short', dispatch),
    [short, dispatch],
  );
  const _status = useMemo(
    () => createField(status, 'status', dispatch),
    [status, dispatch],
  );
  const _country = useMemo(
    () => createField(country, 'country', dispatch),
    [country, dispatch],
  );
  const _birthDate = useMemo(
    () => createField(birthDate, 'birthDate', dispatch),
    [birthDate, dispatch],
  );
  const _phone = useMemo(
    () => createField(phone, 'phone', dispatch),
    [phone, dispatch],
  );
  const _zip = useMemo(
    () => createField(zip, 'zip', dispatch),
    [zip, dispatch],
  );
  const _city = useMemo(
    () => createField(city, 'city', dispatch),
    [city, dispatch],
  );
  const _address = useMemo(
    () => createField(address, 'address', dispatch),
    [address, dispatch],
  );
  const _specializations = useMemo(
    () => createField(specializations, 'specializations', dispatch),
    [specializations, dispatch],
  );
  const _logo = useMemo(() => {
    return {
      ...formValueToInput(logo),
      onBlur: () => dispatch(Actions.toggle('logo')),
      onChange: (value: File | undefined) =>
        dispatch(
          value
            ? Actions.upload(value)
            : Actions.setValue({ key: 'logo', value: undefined }),
        ),
    };
  }, [logo, dispatch]);
  const _rate = useMemo(
    () => createField(rate, 'rate', dispatch),
    [rate, dispatch],
  );
  const handleSubmit = useCallback(() => dispatch(Actions.save()), [dispatch]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <BeforeUnload when={formEdited(s)} />
      <WithTabs
        title={`Add new ${rb('advisor')}`}
        backButtonLink={Pages.OH_ADVISORS}
        backButtonTitle={`Back to ${rb('advisors-u')}`}
        tabs={
          <Tabs
            active={Pages.OH_ADVISORS_NEW}
            tabs={getTabs(undefined, hasAccessToAction)}
          />
        }>
        <Form
          address={_address}
          birthDate={_birthDate}
          city={_city}
          country={_country}
          email={_email}
          enrollmentDate={enrollmentDate}
          firstName={_firstName}
          lastName={_lastName}
          linkedIn={_linkedIn}
          logo={_logo}
          long={_long}
          phone={_phone}
          short={_short}
          state={_state}
          status={_status}
          specializations={_specializations}
          statuses={statusOptions}
          zip={_zip}
          rate={_rate}
          onSubmit={handleSubmit}
          disableSubmit={!isSubmittable(s)}
        />
      </WithTabs>
    </BaseLayout>
  );
}
