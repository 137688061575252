import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import authAPI from '../../api/auth';
import foundersAPI, { FounderId } from '../../api/founders';
import { SnackMessage } from '../../components/common';
import FounderDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/founder-details';
import { FounderContext } from '../../contexts/founder-context';
import { massAsyncRequest } from '../../utils/api';

function FounderDetailsPage() {
  const { founderId } = useParams<{ founderId: FounderId }>();
  const location = useLocation();
  const {
    founder,
    founderSpecializations,
    updateFounder,
    updateSpecializations,
    setTenantUser,
  } = useContext(FounderContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const isExternalPage = useMemo(
    () => location.pathname.includes('external'),
    [location],
  );

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { logo, ...values } = parsedValues.values;
        setUpdating(true);
        let updatedLogo = logo;

        if (parsedValues.logoFile) {
          const updateLogoResult = await foundersAPI.updateAvatar(
            founderId,
            parsedValues.logoFile,
          );
          updatedLogo = updateLogoResult.logo;
        }
        const updatedFounder = await foundersAPI.update(founderId, {
          ...founder,
          ...values,
          logo: updatedLogo,
        });

        const loadedSpecializationsIds = founderSpecializations || [];
        const addedSpecializationsIds = parsedValues.specializations.filter(
          (specializationId) =>
            !loadedSpecializationsIds.includes(specializationId),
        );
        const removedSpecializationsIds = loadedSpecializationsIds.filter(
          (specializationId) =>
            !parsedValues.specializations.includes(specializationId),
        );

        const addSpecializationsRequests = addedSpecializationsIds.map(
          (specializationId) => () =>
            foundersAPI.setFounderSpecialization(founderId, specializationId),
        );
        const removeSpecializationsRequests = removedSpecializationsIds.map(
          (specializationId) => () =>
            foundersAPI.deleteFounderSpecialization(
              founderId,
              specializationId,
            ),
        );
        await Promise.all([
          massAsyncRequest(addSpecializationsRequests),
          massAsyncRequest(removeSpecializationsRequests),
        ]);
        setUpdating(false);
        const loadedTenantUser = await authAPI.getUserByEmail(
          updatedFounder.email,
        );
        setTenantUser(loadedTenantUser);
        updateFounder(updatedFounder);
        updateSpecializations(parsedValues.specializations);
        enqueueSnackbar('The founder was successfully updated and saved.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the founder. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdating(false);
      }
    },
    [
      founderId,
      founder,
      founderSpecializations,
      setTenantUser,
      updateFounder,
      updateSpecializations,
      enqueueSnackbar,
    ],
  );

  return (
    <div>
      <FounderDetailsForm
        founderDetails={founder}
        founderSpecializations={founderSpecializations}
        loading={isUpdating}
        onSubmit={handleSubmitForm}
        isExternal={isExternalPage}
      />
    </div>
  );
}

export default FounderDetailsPage;
