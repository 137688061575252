import React, { SyntheticEvent, useMemo } from 'react';
import { IconButton, Checkbox, Link as MaterialLink } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { ConfirmButton, Text, Link } from '../common';
import { COLORS } from '../../theme/variables';
import { Venture } from '../../api/ventures/types/Venture';
import { getRoutePath, Pages } from '../../router/constants';
import { useLocation } from 'react-router';

export interface AssignmentItem extends Venture {
  assignmentId: string;
  isPrimary: boolean;
}

interface AssignedTableProps {
  className?: string;
  assignments: AssignmentItem[];
  disabledAssignmentsIds: {
    [x: string]: boolean;
  };
  onSelect: (assignment: AssignmentItem, state: boolean) => any;
  onRemove: (assignment: AssignmentItem) => any;
  readOnly?: boolean;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 560
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    padding: '11px 12px 11px 0',

    '&:hover:not($headRow)': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45
    }
  },
  headRow: {
    padding: '13px 0 15px',

    '& $leadTd': {
      padding: '0 16px'
    }
  },
  disabledRow: {
    background: COLORS.COLOR_GRAY_LIGHTENED_45,

    '& $text': {
      color: COLORS.COLOR_TEXT_LIGHTENED_20
    }
  },
  td: {
    padding: '0 10px',
    boxSizing: 'border-box'
  },
  leadTd: {
    minWidth: 70
  },
  nameTd: {
    width: '100%'
  },
  text: {
    color: COLORS.COLOR_BLUE_DARKENED_10
  }
});

function AssignedTable({
  className,
  assignments,
  disabledAssignmentsIds,
  onSelect,
  onRemove,
  readOnly
}: AssignedTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location]
  );

  const handleSelect =
    (assignment: AssignmentItem) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSelect(assignment, e.target.checked);
    };

  const handleRemove = (assignment: AssignmentItem) => () => {
    onRemove(assignment);
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div className={cn(classes.container, className)}>
      <div className={cn(classes.row, classes.headRow)}>
        {!readOnly && (
          <div className={cn(classes.td, classes.leadTd)}>
            <Text variant='normal2' bold>
              Lead
            </Text>
          </div>
        )}
        <div className={cn(classes.td, classes.nameTd)}>
          <Text variant='normal2' bold>
            Name
          </Text>
        </div>
      </div>
      {assignments.map((assignment, index) => {
        const isDisabled = disabledAssignmentsIds[assignment.assignmentId];

        return (
          <div
            key={assignment.assignmentId}
            className={cn(classes.row, {
              [classes.disabledRow]: isDisabled
            })}
            data-testid={`assigned-venture-${index}`}>
            {!readOnly && (
              <div className={cn(classes.td, classes.leadTd)}>
                <Checkbox
                  checked={assignment.isPrimary}
                  onChange={handleSelect(assignment)}
                  color='primary'
                  disabled={isDisabled}
                />
              </div>
            )}
            <div className={cn(classes.td, classes.nameTd)}>
              {!readOnly ? (
                <MaterialLink
                  onClick={stopPropagation}
                  component={Link}
                  to={{
                    pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                      ventureId: assignment.id
                    }),
                    state: {
                      prevPath: currentPath
                    }
                  }}>
                  <Text className={classes.text} variant='normal'>
                    {assignment.ventureName}
                  </Text>
                </MaterialLink>
              ) : (
                <Text className={classes.text} variant='normal'>
                  {assignment.ventureName}
                </Text>
              )}
            </div>
            {!readOnly && (
              <div>
                <ConfirmButton
                  title='Remove the assignment?'
                  body='Sure you want to remove the assignment? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRemove(assignment)
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel'
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      disabled={isDisabled}
                      data-testid={`assigned-delete-button-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default AssignedTable;
