import { createContext } from 'react';
import * as Tenants from '../api/tenants';
import { TenantId } from '../api/tenants/Tenant';

export interface TenantLogoProps {
  getImage: (id: TenantId) => Promise<string>;
}

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const TenantLogoContext = createContext<TenantLogoProps>({
  getImage: async () => '',
});

export const TenantLogoProvider = ({ children }: DrawerProviderProps) => {
  const logos: Record<string, Promise<string>> = {};

  const contextValue: TenantLogoProps = {
    getImage: (id) => logos[id] ?? (logos[id] = Tenants.getLogo(id)),
  };

  return (
    <TenantLogoContext.Provider value={contextValue}>
      {children}
    </TenantLogoContext.Provider>
  );
};
