import { useEffect, useState } from 'react';
import { SpecializationId } from '../../../../api/specializations';
import { TenantId } from '../../../../api/tenants/Tenant';
import { getAllSpecializations, getSettings } from '../common/Api';

interface Tag {
  id: SpecializationId;
  title: string;
}

export function useLanding(id: TenantId): { programName: string; tags: Tag[] } {
  const [{ programName, tags }, setState] = useState<{
    programName: string;
    tags: Tag[];
  }>({ tags: [], programName: '' });

  useEffect(() => {
    const settings = getSettings(id);
    const tags = getAllSpecializations(id).then((vs) =>
      vs.map((i): Tag => ({ id: i.id, title: i.name })),
    );

    Promise.all([settings, tags]).then(([settings, tags]) =>
      setState({ programName: settings.programName, tags }),
    );
  }, [id]);

  return { tags, programName };
}
