import { makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TestId } from '../../../components/Testing/TestId';
import { Text } from '../../../components/common';
import { ChangePasswordForm } from '../../../components/forms/change-password-form';
import { COLORS } from '../../../theme/variables';
import { isInvalid } from '../../../utils/FormValue';
import * as Actions from './types/Actions';
import { useEnroll } from './useEnroll';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 300,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
    textAlign: 'center',
  },
  title: {
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginBottom: '2rem',
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 20,
  },
}));

export function Enroll() {
  const classes = useStyles();
  const [s, dispatch] = useEnroll();

  switch (s.type) {
    case 'InvalidKey':
      return (
        <TestId testId={s.type}>
          <div className={classes.root}>
            <div className={classes.form}>
              <CancelIcon className={classes.errorIcon} />
              <Text variant='normal'>
                Sorry. Looks like your key has expired or you used this key
                before. We cannot provide access to this information anymore.
                Please contact your program Admin
              </Text>
            </div>
          </div>
        </TestId>
      );
    case 'SendSuccess':
      return (
        <div className={classes.root}>
          <TestId testId={s.type}>
            <div className={classes.form}>
              <CheckCircleIcon className={classes.successIcon} />
              <Text variant='normal'>
                Your password has been successfully changed!
                <br />
                You will be forwarded to login page now.
              </Text>
            </div>
          </TestId>
        </div>
      );
    case 'Ready':
    case 'Edited':
    case 'Sending':
    case 'SendError':
      return (
        <TestId testId={s.type}>
          <div data-testid={s.type} className={classes.root}>
            <img src='/Logo.png' alt='logo' className={classes.logo} />
            <div className={classes.form}>
              <ChangePasswordForm
                loading={s.type === 'Sending'}
                error={s.type === 'SendError' ? s.payload.message : undefined}
                onSubmit={() => dispatch(Actions.submit())}
                email={s.payload.email}
                password={{
                  onChange: (v) => dispatch(Actions.setPassword(v)),
                  onBlur: () => {},
                  error: isInvalid(s.payload.password),
                  value: s.payload.password.value ?? '',
                }}
                passwordRepeat={{
                  onChange: (v) => dispatch(Actions.setPasswordRepeat(v)),
                  onBlur: () => {},
                  error: isInvalid(s.payload.passwordRepeat),
                  value: s.payload.passwordRepeat.value ?? '',
                }}
              />
            </div>
          </div>
        </TestId>
      );
  }
}
