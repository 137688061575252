import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  button: {
    aspectRatio: '1',
  },
}));

export interface Props {
  onClick: () => void;
  size?: 'normal' | 'small';
  className?: string;
}

export function ClearFilters({
  onClick,
  className,
  size = 'small',
}: Props): ReactElement {
  const classes = useStyles();

  return (
    <Tooltip title='Clear all filters'>
      <IconButton
        data-testid='button-remove-filter'
        onClick={onClick}
        className={classNames(classes.button, className)}>
        <img src='/filter.svg' width={sizes[size]} alt='Remove filter' />
      </IconButton>
    </Tooltip>
  );
}

const sizes = {
  small: 20,
  normal: 24,
};
