export type Nullify<T extends object> = {
  [k in keyof T]: undefined extends T[k]
    ? Exclude<T[k], undefined> | null
    : T[k];
};

type NestedObject = { [key: string]: any };

export function nullify<T extends object>(t: T): Nullify<T> {
  return Object.entries(t).reduce((acc, [k, v]) => {
    // @ts-expect-error, we have to force Ts to believe us
    acc[k] = v ?? null;

    return acc;
  }, {} as Nullify<T>);
}

export function removeFalsyValues<T extends NestedObject>(obj: T): T {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeFalsyValues(obj[key]);
    } else if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
}
