import { useState, useMemo, useEffect } from 'react';
import { makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { parse as parseQuery } from 'query-string';
import ReportVentureQuarterForm, {
  FormValues,
  FormInitialValues,
} from '../../components/forms/report-venture-quarter-form';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SnackMessage, Text } from '../../components/common';
import venturesAPI from '../../api/ventures';
import * as Answers from '../../api/ventures/quarterlyrecords/customanswers';
import { useSnackbar } from 'notistack';
import CancelIcon from '@material-ui/icons/Cancel';
import { Question } from '../../api/tenants/QuestionTemplates/Question';
import { getAnonymously } from '../../api/tenants/QuestionTemplates';
import { Create } from '../../api/ventures/quarterlyrecords/customanswers/Answer';
import { VentureQuarterRecord } from '../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { isNonEmptyArray } from '../../utils/Array/NonEmptyArray';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  title: {
    marginBottom: 15,
  },
}));

interface Data {
  report: VentureQuarterRecord;
  questions: Question[];
}

function parseFilterFromURL(search: string): any {
  return _pick(parseQuery(search), ['key']);
}

const formatValuesRequest = (
  values: FormValues,
  details: Data,
): {
  record: VentureQuarterRecord;
  answers: Create[];
} => {
  return {
    record: {
      ...details.report,
      submitter: values.submitter,
      fte: Number(values.fte),
      revenue: Number(values.revenue),
      equityRaised: Number(values.equityRaised),
      equityComments: values.equityComments,
      grantsRaised: Number(values.grantsRaised),
      grantsComments: values.grantsComments,
      crowdFunding: Number(values.crowdFunding),
      crowdComments: values.crowdComments,
      loans: Number(values.loans),
      loansComments: values.loansComments,
      other: Number(values.other),
      otherComments: values.otherComments,
      rating: Number(values.rating) - 1,
    },
    answers: details.questions
      .map((q) => [q, values.questions[q.id as string]] as const)
      .filter((v): v is [Question, string | number] => v[1] !== undefined)
      .map(([q, v]): [Question, string] => [q, v.toString()])
      .map(
        ([q, v]): Create => ({
          answer: v,
          description: q.description,
          ventureQuarterlyReportRecordId: details.report.id,
        }),
      ),
  };
};

const getInitialValues = ({ report, questions }: Data): FormInitialValues => {
  let rating = report.rating;

  if (report.status === 'SUBMITTED' && typeof rating === 'number') {
    rating = rating + 1;
  }

  return {
    ventureName: String(report.ventureName || ''),
    submitter: String(report.submitter || ''),
    fte: String(report.fte || ''),
    revenue: String(report.revenue || ''),
    equityRaised: String(report.equityRaised || ''),
    equityComments: String(report.equityComments || ''),
    grantsRaised: String(report.grantsRaised || ''),
    grantsComments: String(report.grantsComments || ''),
    crowdFunding: String(report.crowdFunding || ''),
    crowdComments: String(report.crowdComments || ''),
    loans: String(report.loans || ''),
    loansComments: String(report.loansComments || ''),
    other: String(report.other || ''),
    otherComments: String(report.otherComments || ''),
    rating: String(rating || ''),
    questions: questions.reduce((acc: Record<string, undefined>, q) => {
      acc[q.id] = undefined;
      return acc;
    }, {}),
  };
};

function ReportVentureQuarterPage() {
  const classes = useStyles();
  const location = useLocation();
  const [details, setDetails] = useState<Data>();
  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | ''
  >('loading');
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  const { key } = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );

  const initialValues = useMemo(() => {
    if (details) {
      return getInitialValues(details);
    }
    return undefined;
  }, [details]);

  const handleSubmit = async (_values: FormValues) => {
    if (!details) {
      return;
    }

    const values = formatValuesRequest(_values, details);

    try {
      setIsLoadingSaveForm(true);
      await venturesAPI.sendVentureQuarterReport(values.record);
      // Should not use Promise.all, as answers must be submitted after the report submit
      await (isNonEmptyArray(values.answers)
        ? Answers.create(values.answers)
        : Promise.resolve([]));

      setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';
      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const loadDetails = async (key: string) => {
    try {
      setStatusForm('loading');

      const report = await venturesAPI.getQuarterlyRecordByKey(key);
      const questions = await getAnonymously(report.tenantId);

      setDetails({ report, questions });
      setStatusForm('');
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setStatusForm('error');
    }
  };

  useEffect(() => {
    loadDetails(key);
  }, [key]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='report-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>Success!</Text>
          </div>
        </div>
      </div>
    );
  }

  if (statusForm === 'error' || !details) {
    return (
      <div
        data-testid='report-page-error'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Unfortunately an error occurred while loading the report. Please
              try again in a couple of minutes. If the problem persists please
              share the URL with the support team at support@tractionfive.com
              <br />
              Details: {error}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid='report-page' className={classes.container}>
      <div className={classes.block}>
        <Typography variant='h2' className={classes.title}>
          Venture Quarterly Report
        </Typography>
        <ReportVentureQuarterForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoadingSaveForm}
          reportDate={{
            quarter: details.report.quarterNumber,
            year: details.report.year,
          }}
          questions={details.questions}
        />
      </div>
    </div>
  );
}

export default ReportVentureQuarterPage;
