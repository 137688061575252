import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { Button as MaterialButton } from '@material-ui/core';

export interface Props {
  title: string;
  to: string;
}

export function Add({ to, title }: Props): ReactElement {
  return (
    <MaterialButton
      component={Link}
      to={to}
      startIcon={<AddIcon />}
      variant='contained'
      color='primary'>
      {title}
    </MaterialButton>
  );
}
