import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from './button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

interface ButtonRendererProps {
  onClick: () => void;
}

interface AlertDialogProps {
  buttonRenderer: (props: ButtonRendererProps) => React.ReactNode | void;
  children: React.ReactNode;
}

export default function AlertDialog({
  buttonRenderer = () => {},
  children
}: AlertDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {buttonRenderer({
        onClick: handleOpen
      })}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
