import { useEffect, useState } from 'react';

export function useScrollOnValidation() {
  const [submitValidationFailed, setSubmitValidationFailed] = useState(false);

  useEffect(() => {
    if (submitValidationFailed) {
      const scrollToFirstError = () => {
        const firstInvalidElement = document.querySelector(
          '[aria-invalid="true"]',
        );
        if (firstInvalidElement) {
          firstInvalidElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      };

      scrollToFirstError();
    }
  }, [submitValidationFailed]);

  return setSubmitValidationFailed;
}
