import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import { SyntheticEvent, useMemo, useState } from 'react';
import { COLORS } from '../../theme/variables';
import {
  checkCapitalCharacters,
  checkNumber,
  checkSpecialSymbols,
} from '../../utils/functions';
import { TestId } from '../Testing/TestId';
import {
  AlertDialog,
  AlertState,
  Button,
  FormButtons,
  FormGroup,
  Text,
} from '../common';
import { FormItem } from '../common/Forms/types/FormItem';
import TextField from '../common/text-field';

export interface Values {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface ValidPasswordValue {
  icon: boolean;
  label: string;
}

interface ValidPassword {
  isMinimalLength: ValidPasswordValue;
  isSpecialSymbol: ValidPasswordValue;
  isNumbers: ValidPasswordValue;
  isCapitalCharacters: ValidPasswordValue;
}

interface LoginFormProps {
  loading: boolean;
  error: string | undefined;
  onSubmit: () => void;
  email: string;
  password: FormItem<string>;
  passwordRepeat: FormItem<string>;
}

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  popper: {
    background: 'white',
    minWidth: 280,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:
      '0px 0px 2px rgb(9 29 61 / 25%), 0px 8px 16px rgb(34 91 187 / 8%), 0px 8px 24px rgb(51 126 255 / 8%)',
    borderRadius: 5,
    padding: '5px 10px',
    marginTop: 7,
  },
  popperItems: {
    width: '100%',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
    marginRight: 7,
    fontSize: 20,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
    marginRight: 7,
    fontSize: 20,
  },
  popperContainer: {
    zIndex: 2,
  },
});

export function ChangePasswordForm({
  loading,
  error,
  onSubmit,
  email,
  password,
  passwordRepeat,
}: LoginFormProps) {
  const classes = useStyles();
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [isShownPasswordConfirm, setIsShownPasswordConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const validNewPassword: ValidPassword = useMemo(
    () => ({
      isMinimalLength: {
        icon: password.value.length > 8,
        label: 'Minimal length 8 symbols',
      },
      isSpecialSymbol: {
        icon: checkSpecialSymbols(password.value),
        label: 'Special Symbols',
      },
      isNumbers: { icon: checkNumber(password.value), label: 'Numbers' },
      isCapitalCharacters: {
        icon: checkCapitalCharacters(password.value),
        label: 'Capital characters',
      },
    }),
    [password.value],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      noValidate>
      {!!error && <AlertState type='error'>{error}</AlertState>}
      <FormGroup>
        <TestId testId={'login-form-email'}>
          <TextField
            label='Email'
            value={email}
            inputProps={{ readOnly: true }}
          />
        </TestId>
      </FormGroup>
      <FormGroup>
        <div>
          <TestId testId={'login-form-password'}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => setIsShownPassword(!isShownPassword)}>
                      {isShownPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={password.error}
              aria-describedby={id}
              type={isShownPassword ? 'text' : 'password'}
              label='Password'
              onChange={(e) => password.onChange(e.target.value)}
              onFocus={(e) => setAnchorEl(e.currentTarget)}
              onBlur={() => setAnchorEl(null)}
            />
          </TestId>
          <Popper
            id={id}
            className={classes.popperContainer}
            anchorEl={anchorEl}
            open={open}
            disablePortal={false}
            placement='bottom-start'>
            <div className={classes.popper}>
              {Object.values(validNewPassword).map(
                (value: ValidPasswordValue) => {
                  return (
                    <div className={classes.popperItems}>
                      {value.icon ? (
                        <CheckCircleIcon className={classes.checkIcon} />
                      ) : (
                        <CancelIcon className={classes.errorIcon} />
                      )}
                      <Text variant='normal'>{value.label} </Text>
                    </div>
                  );
                },
              )}
            </div>
          </Popper>
        </div>
      </FormGroup>
      <FormGroup>
        <TestId testId={'login-form-password-confirm'}>
          <TextField
            label='Repeat Password'
            type={isShownPasswordConfirm ? 'text' : 'password'}
            value={passwordRepeat.value}
            onChange={(e) => passwordRepeat.onChange(e.target.value)}
            error={passwordRepeat.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    edge='end'
                    onClick={() =>
                      setIsShownPasswordConfirm(!isShownPasswordConfirm)
                    }>
                    {isShownPasswordConfirm ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </TestId>
      </FormGroup>
      <FormButtons justify={'space-between'}>
        <AlertDialog
          buttonRenderer={({ onClick }) => (
            <Link
              href='#'
              variant='caption'
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                onClick();
              }}>
              Need help?
            </Link>
          )}>
          If you experience problems while logging into to Management Console or
          you need login credentials, please forward your request to
          support@tractionfive.com and describe the problem. We will get back to
          you shortly.
        </AlertDialog>

        <Button
          data-testid='login-form-submit'
          type='submit'
          disabled={loading}>
          {loading ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Change password'
          )}
        </Button>
      </FormButtons>
    </form>
  );
}
