import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { forwardRef, ReactElement, ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  gap: number;
  align?: 'center' | 'start' | 'end';
  justify?: 'start' | 'end' | 'space-between';
  className?: string;
}

export const Inline = forwardRef<HTMLDivElement, Props>(
  ({ children, gap, align, justify, className }, ref): ReactElement => {
    const classes = useStyles({
      gap,
      justify: justify ?? 'start',
      align: align ?? 'center',
    });

    return (
      <div className={classNames(classes.root, className)} ref={ref}>
        {children}
      </div>
    );
  },
);

const useStyles = makeStyles<
  Theme,
  {
    gap: number;
    justify: 'start' | 'end' | 'space-between';
    align: 'center' | 'start' | 'end';
  }
>(() => ({
  root: {
    display: 'flex',
    gap: ({ gap }) => gap,
    justifyContent: ({ justify }) => justify,
    alignItems: ({ align }) => align,
  },
}));
