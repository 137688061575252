import { Button as MaterialButton } from '@material-ui/core';
import { Dispatch, useCallback, useMemo } from 'react';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { PageLoader } from '../../../components/common';
import { BeforeUnload } from '../../../components/common/Confirmation/BeforeUnload';
import { FormItem2 } from '../../../components/common/Forms/types/FormItem';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { toDate } from '../../../utils/date/StringDate';
import { Form } from '../common/Form';
import { SendInvitation } from '../common/SendInvitation';
import {
  createField,
  formValueToInput2,
  getTabs,
  statusOptions,
} from '../common/utils';
import * as Actions from './types/Actions';
import { isLoaded, Loaded } from './types/State';
import { useCommunityMemberEdit } from './useCommunityMemberEdit';
import { formEdited } from './utils';

function LoadedState({ s, dispatch }: { s: Loaded; dispatch: Dispatch<any> }) {
  const {
    item,
    member: { dateOfEnrollment },
  } = s.payload;
  const {
    firstName,
    lastName,
    email,
    state,
    linkedIn,
    status,
    country,
    address,
    birthDate,
    zip,
    city,
  } = item;

  const _firstName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(firstName),
      onBlur: () => dispatch(Actions.toggle('firstName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'firstName', value: v })),
    }),
    [firstName, dispatch],
  );
  const _lastName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(lastName),
      onBlur: () => dispatch(Actions.toggle('lastName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'lastName', value: v })),
    }),
    [lastName, dispatch],
  );
  const _email = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(email),
      onBlur: () => dispatch(Actions.toggle('email')),
      onChange: (v) => dispatch(Actions.setValue({ key: 'email', value: v })),
    }),
    [email, dispatch],
  );

  const _state = useMemo(
    () => createField(state, 'state', dispatch),
    [state, dispatch],
  );
  const _linkedIn = useMemo(
    () => createField(linkedIn, 'linkedIn', dispatch),
    [linkedIn, dispatch],
  );

  const _status = useMemo(
    () => createField(status, 'status', dispatch),
    [status, dispatch],
  );
  const _country = useMemo(
    () => createField(country, 'country', dispatch),
    [country, dispatch],
  );
  const _birthDate = useMemo(
    () => createField(birthDate, 'birthDate', dispatch),
    [birthDate, dispatch],
  );

  const _zip = useMemo(
    () => createField(zip, 'zip', dispatch),
    [zip, dispatch],
  );
  const _city = useMemo(
    () => createField(city, 'city', dispatch),
    [city, dispatch],
  );
  const _address = useMemo(
    () => createField(address, 'address', dispatch),
    [address, dispatch],
  );
  const handleSubmit = useCallback(() => dispatch(Actions.save()), [dispatch]);

  return (
    <Form
      address={_address}
      birthDate={_birthDate}
      city={_city}
      country={_country}
      email={_email}
      enrollmentDate={toDate(dateOfEnrollment)}
      firstName={_firstName}
      lastName={_lastName}
      linkedIn={_linkedIn}
      state={_state}
      status={_status}
      statuses={statusOptions}
      zip={_zip}
      onSubmit={handleSubmit}
      disableSubmit={false}
    />
  );
}

export function EditCommunityMember({ user }: ProtectedRouteProps) {
  const [s, dispatch] = useCommunityMemberEdit();

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <BeforeUnload when={formEdited(s)} />
      <WithTabs
        title={'Edit community member'}
        backButtonLink={Pages.OH_COMMUNITY_MEMBERS}
        backButtonTitle={'Back to Community Members'}
        actions={
          isLoaded(s)
            ? [
                {
                  key: 'send-invite',
                  label: 'Send an invite',
                  component: (
                    <SendInvitation ids={[s.payload.member.id]}>
                      {({ open, disabled }) => (
                        <MaterialButton
                          data-testid={'open-button'}
                          variant='contained'
                          onClick={open}
                          color='primary'
                          disabled={disabled}>
                          Send an invite
                        </MaterialButton>
                      )}
                    </SendInvitation>
                  ),
                },
              ]
            : []
        }
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_COMMUNITY_MEMBERS_EDIT, {
              id: s.payload.id,
            })}
            tabs={getTabs(s.payload.id)}
          />
        }>
        {isLoaded(s) ? (
          <LoadedState s={s} dispatch={dispatch} />
        ) : (
          <PageLoader />
        )}
      </WithTabs>
    </BaseLayout>
  );
}
