import { makeStyles } from '@material-ui/styles';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import { Event } from '../../api/events';
import { Text } from '../common';
import { datesAreOnSameDay, isTomorrowDay } from '../../utils/date';
import DashboardEvent, { DashboardEventType } from './dashboard-event';
import { format } from 'date-fns';
import { COLORS } from '../../theme/variables';

interface DashboardEventListProps {
  events: Event[];
}

const useStyles = makeStyles({
  container: {
    '& + &': {
      marginTop: 24
    }
  },
  label: {
    display: 'block',
    marginBottom: 10
  },
  list: {
    display: 'flex',
    flexDirection: 'column-reverse'
  }
});

function generateEventInfo(event: Event, startDate: Date, endDate: Date) {
  return {
    id: event.id,
    startTime: format(startDate, 'h:mm a'),
    endTime: format(endDate, 'h:mm a'),
    title: event.summary,
    ventureName: event.ventureName
  };
}

function groupEventsByDay(_events: Event[]) {
  const today = new Date();
  const events = [..._events];
  events.sort((prevEvent, nextEvent) => {
    const prevDate = new Date(prevEvent.start);
    const nextDate = new Date(nextEvent.start);
    if (isBefore(prevDate, nextDate)) {
      return -1;
    }
    if (isAfter(prevDate, nextDate)) {
      return 1;
    }
    return 0;
  });
  let grouped: { [k: string]: DashboardEventType[] } = {};

  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const monthName = format(startDate, 'MMMM d');
    const isToday = datesAreOnSameDay(today, startDate);
    const isTomorrow = isTomorrowDay(today, startDate);

    if (isToday) {
      grouped.Today = [
        ...(grouped.Today || []),
        generateEventInfo(event, startDate, endDate)
      ];
      continue;
    }
    if (isTomorrow) {
      grouped.Tomorrow = [
        ...(grouped.Tomorrow || []),
        generateEventInfo(event, startDate, endDate)
      ];
      continue;
    }
    grouped[monthName] = [
      ...(grouped[monthName] || []),
      generateEventInfo(event, startDate, endDate)
    ];
  }

  return grouped;
}

function DashboardEventList({ events }: DashboardEventListProps) {
  const classes = useStyles();
  const groupedEventsByDay = groupEventsByDay(events);

  return (
    <div>
      {Object.keys(groupedEventsByDay).map((dayName) => (
        <div
          data-testid='dashboard-event-list-day'
          key={dayName}
          className={classes.container}>
          <Text
            className={classes.label}
            color={COLORS.COLOR_ADDITIONAL_GRAY_BLUE_TEXT}
            variant='upper1'>
            {dayName}
          </Text>
          <div className={classes.list}>
            {groupedEventsByDay[dayName].reverse().map((event) => (
              <DashboardEvent key={event.title} event={event} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default DashboardEventList;
