import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { ReactElement, useContext, useMemo } from 'react';
import { Tenant } from '../../api/auth';
import { KnownModule } from '../../api/user/Module';
import { UserContext } from '../../contexts/user-context';
import { TestId } from '../Testing/TestId';
import { PageLoader } from '../common';
import FeedbackButton from '../common/feedback-button';
import { CoreSidebar } from './CoreSidebar';
import { OfficeHoursSidebar } from './OfficeHoursSidebar';
import Footer from './footer';
import Header from './header';

interface BaseLayoutProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  sidebar?: 'core' | 'officehours';
  user: Tenant;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      minWidth: '1280px',
    },
  },
  main: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  contentContainer: {
    padding: '10px 0 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    scrollbarGutter: 'stable',

    '@media print': {
      overflow: 'initial',
    },

    [theme.breakpoints.up('xs')]: {
      paddingLeft: '128px',
      padding: '40px 40px 0',
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: '40px',
    },
  },
  baseContent: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 24,
    gap: '24px',
  },
  pageContent: {
    width: '100%',
    maxWidth: '1200px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  isFullWidth: {
    maxWidth: '1592px',
  },
  isFullHeight: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

function BaseLayout({
  fullHeight = false,
  fullWidth = false,
  isLoading = false,
  children,
  sidebar = 'core',
  user,
}: BaseLayoutProps) {
  const classes = useStyles();
  const { hasAccessToModal } = useContext(UserContext);
  const _sidebar = useMemo(():
    | KnownModule.Core
    | KnownModule.OfficeHours
    | null => {
    const sidebars = (
      [KnownModule.Core, KnownModule.OfficeHours] as const
    ).filter(hasAccessToModal);

    return sidebars.find((i) => i === sidebar) ?? sidebars[0] ?? null;
  }, [sidebar, hasAccessToModal]);

  const sidebarElement = useMemo((): ReactElement => {
    switch (_sidebar) {
      case KnownModule.Core:
        return (
          <TestId testId={'core-sidebar'}>
            <CoreSidebar className={cn(classes.mobileNone, classes.noPrint)} />
          </TestId>
        );
      case KnownModule.OfficeHours:
        return (
          <TestId testId={'officehours-sidebar'}>
            <OfficeHoursSidebar
              className={cn(classes.mobileNone, classes.noPrint)}
            />
          </TestId>
        );
      case null:
        return <></>;
    }
  }, [_sidebar, classes.mobileNone, classes.noPrint]);

  return (
    <div
      id='base-layout'
      data-testid='base-layout'
      className={classes.container}>
      <Header user={user} />
      <div className={classes.main}>
        {sidebarElement}
        <div className={classes.contentContainer} id='scroll-content'>
          <div className={classes.baseContent}>
            {isLoading ? (
              <PageLoader />
            ) : (
              <div
                id='page-content'
                className={cn(classes.pageContent, {
                  [classes.isFullHeight]: fullHeight,
                  [classes.isFullWidth]: fullWidth,
                })}>
                {children}
              </div>
            )}
            <Footer />
          </div>
        </div>
      </div>
      {_sidebar !== KnownModule.OfficeHours && (
        <div className={cn(classes.noPrint, classes.mobileNone)}>
          <FeedbackButton />
        </div>
      )}
    </div>
  );
}

export default BaseLayout;
