import { makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Field, useForm } from 'react-final-form';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { ConsentType } from '../../api/auth';
import { TenantId } from '../../api/tenants/Tenant';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { formatDateToRFC } from '../../utils/date';
import {
  BUSINESS_MENTOR_WIZARD,
  FUNDRAISING_MENTOR_WIZARD,
  GENDER_WIZARD,
  lengthField,
  MARKETING_MENTOR_WIZARD,
  RACE_WIZARD,
  SALES_MENTOR_WIZARD,
} from '../../utils/form';
import { createMarkup } from '../../utils/functions';
import { CheckboxListOther, FormGroup, Text, UserLogo } from '../common';
import RadioboxListOther from '../common/radiobox-list-other';
import Wizard, { FormValues } from '../common/wizard';
import CustomFormField from './custom-form/custom-form-field';
import {
  FormCheckbox,
  FormDateInput,
  FormSpecializationInput,
  TextFieldWrapper,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  multilineField: {
    minHeight: 64,
  },
  title: {
    marginBottom: 20,
  },
  intro: {
    display: 'block',
    marginBottom: 20,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  consent: {
    marginBottom: 20,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
  semi_title: {
    margin: '20px 0',
  },
}));

interface Props {
  onSubmit: (values: FormValues) => void;
  tenantId: string;
  isLoading: boolean;
  consents: ConsentType[];
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
  intro: string;
  logoBlob: string;
  setLogoBlob: (blob: string) => void;
}

interface StepProps {
  countSteps: number;
}

interface Step1Props extends StepProps {
  intro: string;
  logoBlob: string;
  tenantId: TenantId;
  setLogoBlob: (blob: string) => void;
}

const Step1 = ({
  countSteps,
  intro,
  logoBlob,
  tenantId,
  setLogoBlob,
}: Step1Props) => {
  const classes = useStyles();
  const formAPI = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  const validateLogoFile = (logo: File) => {
    const fileName = logo.name;
    if (fileName.length > lengthField.logoFileName) {
      enqueueSnackbar('The maximum length of the file name is 75 characters', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <div>
      {intro && (
        <Text variant='normal' className={classes.intro}>
          {intro}
        </Text>
      )}
      <Typography variant='h3' className={classes.title}>
        General Information - Section 1 out of {countSteps}
      </Typography>
      <FormGroup>
        <Field<string>
          name='file'
          parse={(value) => (!value ? '' : value)}
          component={({ input }) => (
            <UserLogo
              logo={logoBlob}
              name={' '}
              onUpload={(photoFile) => {
                if (validateLogoFile(photoFile)) {
                  const blobFile = URL.createObjectURL(photoFile);
                  setLogoBlob(blobFile);
                  input.onChange(photoFile);
                }
              }}
              onRemove={() => {
                setLogoBlob('');
                input.onChange(null);
              }}
              showLabelIfEmpty
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='firstName'
          component={TextFieldWrapper}
          label='First name*'
          testid='mentor-wizard-step1-firstName'
          InputProps={{
            inputProps: {
              maxLength: lengthField.firstName,
            },
          }}
        />
        <Field<string>
          name='lastName'
          component={TextFieldWrapper}
          testid='mentor-wizard-step1-lastName'
          label='Last name*'
          InputProps={{
            inputProps: {
              maxLength: lengthField.lastName,
            },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='email'
          component={TextFieldWrapper}
          testid='mentor-wizard-step1-email'
          label='Email*'
          formatOnBlur
          format={(value: string) => {
            return value ? value.toLowerCase() : value;
          }}
        />
        <Field<string>
          name='emailConfirm'
          component={TextFieldWrapper}
          label='Email (confirm)*'
          testid='mentor-wizard-step1-emailConfirm'
          formatOnBlur
          format={(value: string) => {
            return value ? value.toLowerCase() : value;
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='dateOfBirth'
          component={FormDateInput}
          label='Date of birth'
          testid='mentor-wizard-step1-dateOfBirth'
          editable
          parse={(value) => {
            try {
              if (value) {
                return formatDateToRFC(value);
              }
              return '';
            } catch (e: any) {
              return 'invalid';
            }
          }}
        />
        <Field<string>
          name='linkedInProfile'
          component={TextFieldWrapper}
          label='LinkedIn profile (link)'
          testid='mentor-wizard-step1-linkedInProfile'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='phone'
          label='Phone*'
          component={TextFieldWrapper}
          testid='mentor-wizard-step1-phone'
        />
      </FormGroup>

      <FormGroup>
        <Field
          name='businessIndustries'
          component={FormSpecializationInput}
          label='Specialization*'
          data-testid='mentor-wizard-step1-businessIndustries'
          tenantId={tenantId}
        />
      </FormGroup>

      <RadioboxListOther
        name='gender'
        options={GENDER_WIZARD}
        onChange={(value) => handleChangeField(formAPI.change, 'gender', value)}
        text='Gender*'
        labelOther='Other'
      />
      <RadioboxListOther
        name='race'
        options={RACE_WIZARD}
        onChange={(value) => handleChangeField(formAPI.change, 'race', value)}
        text='Race*'
        labelOther='Other'
      />
      <Typography variant='h4' className={classes.semi_title}>
        Address
      </Typography>
      <FormGroup>
        <Field<string>
          name='address'
          component={TextFieldWrapper}
          label='Address'
          testid='mentor-wizard-step1-address'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='city'
          component={TextFieldWrapper}
          label='City'
          testid='mentor-wizard-step1-city'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='state'
          component={TextFieldWrapper}
          label='State'
          testid='mentor-wizard-step1-state'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='zip'
          component={TextFieldWrapper}
          label='Zip code'
          testid='mentor-wizard-step1-zip'
          InputProps={{
            inputProps: {
              maxLength: lengthField.zip,
            },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='country'
          component={TextFieldWrapper}
          label='Country'
          testid='mentor-wizard-step1-country'
        />
      </FormGroup>
    </div>
  );
};

interface Step2Props extends StepProps {
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
}

const Step2 = ({
  countSteps,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
}: Step2Props) => {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const formAPI = useForm();
  const stateForm = formAPI.getState();
  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Additional Info - Section 2 out of {countSteps}
      </Typography>
      {showCustomAdditionalInfo && additionalInfoCustomQuestions?.length ? (
        additionalInfoCustomQuestions.map((question) => (
          <CustomFormField
            key={question.id}
            question={question}
            hasAccessToUpdate={true}
            onChange={(value) => formAPI.change(question.id, value)}
            value={stateForm.values[question.id] || ''}
          />
        ))
      ) : (
        <>
          <FormGroup>
            <Field<string>
              name='whyBecomeMentor'
              component={TextFieldWrapper}
              label={`Why would you like to become a ${rb('mentor')}?`}
              testid='mentor-wizard-step2-whyBecomeMentor'
              multiline
              InputProps={{
                inputProps: {
                  className: classes.multilineField,
                  maxLength: lengthField.whyBecomeMentor,
                },
              }}
            />
          </FormGroup>

          <CheckboxListOther
            name='salesChallenges'
            options={SALES_MENTOR_WIZARD}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'salesChallenges', value)
            }
            text='What of the following SALES challenges would be within your area of expertise? (select 1-3 that apply best)*'
            labelOther='Other'
            value={stateForm.values.salesChallenges}
            testid='mentor-wizard-step2-salesChallenges'
          />

          <CheckboxListOther
            name='marketingChallenges'
            options={MARKETING_MENTOR_WIZARD}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'marketingChallenges', value)
            }
            text='What of the following MARKETING challenges would be within your area of expertise? (select 1-3 that apply best)*'
            labelOther='Other'
            value={stateForm.values.marketingChallenges}
            testid='mentor-wizard-step2-marketingChallenges'
          />

          <CheckboxListOther
            name='fundraisingChallenges'
            options={FUNDRAISING_MENTOR_WIZARD}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'fundraisingChallenges', value)
            }
            text='What of the following FUNDRASING challenges would be within your area of expertise? (select 1-3 that apply best)*'
            labelOther='Other'
            value={stateForm.values.fundraisingChallenges}
            testid='mentor-wizard-step2-fundraisingChallenges'
          />

          <CheckboxListOther
            name='businessChallenges'
            options={BUSINESS_MENTOR_WIZARD}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'businessChallenges', value)
            }
            text='What of the following BUSINESS challenges would be within your area of expertise? (select 1-3 that apply best)*'
            labelOther='Other'
            value={stateForm.values.businessChallenges}
            testid='mentor-wizard-step2-businessChallenges'
          />
        </>
      )}
    </div>
  );
};

interface Step3Props extends StepProps {
  consents: ConsentType[];
}

const Step3 = ({ countSteps, consents }: Step3Props) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Commitment - Section 3 out of {countSteps}
      </Typography>
      {consents.map((consent) => (
        <div className={classes.consent}>
          <div
            className={classes.consent}
            dangerouslySetInnerHTML={createMarkup(consent.body)}
          />
          <Field<string>
            name={`field-${consent.id}`}
            component={FormCheckbox}
            label='I accept'
            classNameLabel={classes.checkboxLabel}
            testid='mentor-wizard-step3-accept'
          />
        </div>
      ))}
    </div>
  );
};

function MentorsApplicationsWizardForm({
  onSubmit,
  tenantId,
  isLoading,
  consents,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
  intro,
  logoBlob,
  setLogoBlob,
}: Props) {
  const countSteps = consents.length > 0 ? 3 : 2;

  if (countSteps === 3) {
    return (
      <Wizard
        consents={consents}
        onSubmit={onSubmit}
        tenantId={tenantId}
        showCustomAdditionalInfo={showCustomAdditionalInfo}
        additionalInfoCustomQuestions={additionalInfoCustomQuestions}
        isLoading={isLoading}>
        <Step1
          countSteps={countSteps}
          intro={intro}
          logoBlob={logoBlob}
          setLogoBlob={setLogoBlob}
          tenantId={tenantId as TenantId}
        />
        <Step2
          countSteps={countSteps}
          showCustomAdditionalInfo={showCustomAdditionalInfo}
          additionalInfoCustomQuestions={additionalInfoCustomQuestions}
        />
        <Step3 countSteps={countSteps} consents={consents} />
      </Wizard>
    );
  }
  return (
    <Wizard
      consents={consents}
      onSubmit={onSubmit}
      tenantId={tenantId}
      showCustomAdditionalInfo={showCustomAdditionalInfo}
      additionalInfoCustomQuestions={additionalInfoCustomQuestions}
      isLoading={isLoading}>
      <Step1
        countSteps={countSteps}
        intro={intro}
        logoBlob={logoBlob}
        tenantId={tenantId as TenantId}
        setLogoBlob={setLogoBlob}
      />
      <Step2
        countSteps={countSteps}
        showCustomAdditionalInfo={showCustomAdditionalInfo}
        additionalInfoCustomQuestions={additionalInfoCustomQuestions}
      />
    </Wizard>
  );
}

export default MentorsApplicationsWizardForm;
