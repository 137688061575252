import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { add } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';
import eventsAPI, {
  EventFounder,
  EventId,
  EventMentor,
} from '../../api/events';
import { Founder } from '../../api/founders';
import { Mentor } from '../../api/mentors';
import {
  Button,
  Dialog,
  FormButtons,
  SnackMessage,
  Text,
  TextField,
} from '../../components/common';
import SessionDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/session-details';
import { IssueContext } from '../../contexts/issue-context';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { ProtectedRouteProps } from '../../router/type';
import { COLORS } from '../../theme/variables';
import { massAsyncRequest } from '../../utils/api';
import { formatDateTime, formatDateToRFC } from '../../utils/date';
import { BR_TEXT, RESCHEDULING_REASON, TYPE_ISSUE } from '../../utils/form';
import {
  getMessangeCreateIssue,
  getMessangeResolvedIssue,
} from '../../utils/functions';
import { ValueSubmit } from './new-session';

interface PrevValue extends ValueSubmit {
  reasonRescheduling?: string;
}

interface UpdatedAttendees {
  founders: {
    assigned: Founder[];
    removed: Founder[];
  };
  mentors: {
    assigned: Mentor[];
    removed: Mentor[];
  };
}

type IssueType = 'NO_AGENDA' | 'NO_REPORT' | 'NO_NOTES' | 'UNCONFIRMED';
const sessionStatusArchived = 'ARCHIVED';

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  rescheduleText: {
    marginTop: '20px !important',
  },
  contentAdditionalModal: {
    paddingTop: 10,
  },
});

function getAssignedAndRemoved<T extends { id: string }>(
  itemsBefore: T[],
  itemsAfter: T[],
) {
  const addedItems = itemsAfter.filter(
    ({ id }) => !itemsBefore.some(({ id: idBefore }) => idBefore === id),
  );
  const removedItems = itemsBefore.filter(
    ({ id }) => !itemsAfter.some(({ id: idAfter }) => idAfter === id),
  );
  return [addedItems, removedItems];
}

function SessionDetailsPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const {
    session,
    founders,
    mentors,
    sessionIssues,
    sessionIssuesFull,
    updateSession,
    updateScheduled,
    updateIssues,
  } = useContext(SessionContext);
  const { channels, currentEmail } = useContext(UserContext);
  const { issue: issueFullParams } = useContext(IssueContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAdditionModal, setOpenAdditionModal] = useState(false);
  const [openNotifyModal, setOpenNotifyModal] = useState(false);
  const [prevValue, setPrevValue] = useState<PrevValue>();
  const [additionalValue, setAdditionalValue] = useState('');
  const [updatedAttendees, setUpdatedAttendees] =
    useState<UpdatedAttendees | null>(null);

  const handleChangeAdditionalInfo = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAdditionalValue(e.target.value);
  };

  const handleSubmitForm = useCallback(
    async (
      parsedValues: ParsedFormValues,
      formFounders: Founder[],
      formMentors: Mentor[],
      reasonRescheduling?: string,
      notify?: boolean,
    ) => {
      try {
        const values = parsedValues.values;
        const isStatusArchived = values.status === sessionStatusArchived;
        const issues = isStatusArchived ? [] : parsedValues.issues;

        setUpdating(true);
        const foundersIds = founders.map((founder) => founder.id);
        const mentorsIds = mentors.map((mentor) => mentor.id);
        const formFoundersIds = formFounders.map(
          (formFounder) => formFounder.id,
        );
        const formMentorsIds = formMentors.map((formMentor) => formMentor.id);
        const assignedFounders = formFoundersIds.filter(
          (id) => !foundersIds.includes(id),
        );
        const assignedMentors = formMentorsIds.filter(
          (id) => !mentorsIds.includes(id),
        );
        const removedFounders = foundersIds.filter(
          (id) => !formFoundersIds.includes(id),
        );
        const removedMentors = mentorsIds.filter(
          (id) => !formMentorsIds.includes(id),
        );
        const assignedFoundersRequests = assignedFounders.map(
          (founderId) => () =>
            notify
              ? eventsAPI.addFounderAndNotify(sessionId, founderId)
              : eventsAPI.addFounder(sessionId, founderId),
        );
        const assignedMentorsRequests = assignedMentors.map(
          (mentorId) => () =>
            notify
              ? eventsAPI.addMentorAndNotify(sessionId, mentorId)
              : eventsAPI.addMentor(sessionId, mentorId),
        );
        const removedFoundersRequests = removedFounders.map(
          (founderId) => () =>
            notify
              ? eventsAPI.removeFounderAndNotify(sessionId, founderId)
              : eventsAPI.removeFounder(sessionId, founderId),
        );
        const removedMentorsRequests = removedMentors.map(
          (mentorId) => () =>
            notify
              ? eventsAPI.removeMentorAndNotify(sessionId, mentorId)
              : eventsAPI.removeMentor(sessionId, mentorId),
        );
        const loadedIssueIds = sessionIssues || [];

        const addIssueIds = issues.filter(
          (issue) => !loadedIssueIds.includes(issue),
        );
        const removedIssueIds = loadedIssueIds.filter(
          (issue) => !issues.includes(issue),
        );

        const notesAddIssueRequest = addIssueIds.map((issueId) => {
          const codeIssue = issueFullParams?.find(
            ({ id }) => id === issueId,
          )?.code;
          if (codeIssue && TYPE_ISSUE.includes(codeIssue)) {
            const contents = getMessangeCreateIssue(
              codeIssue as IssueType,
              currentEmail || '',
            );
            return () => eventsAPI.createNote({ contents, eventId: sessionId });
          }
          return () => {};
        });

        const notesRemoveIssueRequest = removedIssueIds.map((issueId) => {
          const codeIssue = issueFullParams?.find(
            ({ id }) => id === issueId,
          )?.code;
          if (codeIssue && TYPE_ISSUE.includes(codeIssue)) {
            const contents = getMessangeResolvedIssue(
              codeIssue as IssueType,
              currentEmail || '',
            );
            return () => eventsAPI.createNote({ contents, eventId: sessionId });
          }
          return () => {};
        });

        const addIssueRequests = addIssueIds.map(
          (issueId) => () =>
            eventsAPI.setTags({
              eventId: sessionId,
              creationDate: formatDateToRFC(new Date()),
              tagId: issueId,
            }),
        );
        const removeIssueRequests = removedIssueIds.map((issueTagId) => {
          const issueId = sessionIssuesFull?.find(
            (value) => value.tagId === issueTagId,
          )?.id;
          if (issueId) {
            return () => eventsAPI.removeTags(sessionId, issueId);
          } else {
            return () => {};
          }
        });

        const description = reasonRescheduling
          ? `${RESCHEDULING_REASON}${reasonRescheduling}${BR_TEXT}${
              values.description || ''
            }`
          : values.description;

        const [updatedSession, assignedFoundersResult, assignedMentorsResult] =
          await Promise.all([
            eventsAPI.update(sessionId, {
              ...session,
              ...values,
              description,
            }),
            massAsyncRequest(assignedFoundersRequests),
            massAsyncRequest(assignedMentorsRequests),
            massAsyncRequest(removedFoundersRequests),
            massAsyncRequest(removedMentorsRequests),
            massAsyncRequest(addIssueRequests),
            massAsyncRequest(removeIssueRequests),
            massAsyncRequest(notesAddIssueRequest),
            massAsyncRequest(notesRemoveIssueRequest),
          ]);

        setUpdating(false);
        updateIssues(issues);
        let updatedFounders;
        let updatedMentors;
        if (assignedFounders.length || removedFounders.length) {
          updatedFounders = {
            assigned: {
              founders: assignedFounders.map((founderId) =>
                formFounders.find(
                  (formFounder) => formFounder.id === founderId,
                ),
              ) as Founder[],
              eventFounderList: assignedFoundersResult as EventFounder[],
            },
            removed: removedFounders,
          };
        }
        if (assignedMentors.length || removedMentors.length) {
          updatedMentors = {
            assigned: {
              mentors: assignedMentors.map((mentorId) =>
                formMentors.find((formMentor) => formMentor.id === mentorId),
              ) as Mentor[],
              eventMentorList: assignedMentorsResult as EventMentor[],
            },
            removed: removedMentors,
          };
        }
        updateSession(updatedSession.event, updatedFounders, updatedMentors);
        const selectedChannel = channels?.find(
          (channel) => channel.id === updatedSession.eventChannelId,
        );
        const preName = selectedChannel?.channelName || '';
        const name =
          preName.length > 30 ? `${preName.slice(0, 30)}...` : preName;
        const message = [
          'Your session was updated successfully.',
          `${
            name
              ? `The channel that will be used for this session is ${name}`
              : ''
          }`,
        ]
          .join('\n')
          .trim();

        updateScheduled(updatedSession.scheduled);
        if (updatedSession.scheduled) {
          enqueueSnackbar(message, {
            variant: 'success',
            style: { whiteSpace: 'pre-line' },
          });
        } else {
          setUpdating(false);

          const startDate = formatDateToRFC(
            new Date(updatedSession.suggestedEventDateTime),
          );
          const endDate = formatDateToRFC(
            add(new Date(updatedSession.suggestedEventDateTime), {
              hours: 1,
              minutes: 30,
            }),
          );

          const newValue = {
            values: { ...parsedValues.values, start: startDate, end: endDate },
            issues,
          };

          setAdditionalValue('');

          setPrevValue({
            parsedValues: newValue,
            founders,
            mentors,
          });
          setOpenModal(true);
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        setAdditionalValue('');
        enqueueSnackbar(
          'An error occurred while updating the session. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdating(false);
        throw e;
      }
    },
    [
      session,
      sessionId,
      founders,
      mentors,
      channels,
      updateSession,
      enqueueSnackbar,
      updateScheduled,
      updateIssues,
      sessionIssues,
      sessionIssuesFull,
      issueFullParams,
      currentEmail,
    ],
  );

  const handlePreSubmitForm = useCallback(
    async (
      parsedValues: ParsedFormValues,
      formFounders: Founder[],
      formMentors: Mentor[],
      reasonRescheduling?: string,
    ) => {
      const [assignedFounders, removedFounders] = getAssignedAndRemoved(
        founders,
        formFounders,
      );
      const [assignedMentors, removedMentors] = getAssignedAndRemoved(
        mentors,
        formMentors,
      );

      const showInviteModal =
        assignedFounders.length ||
        removedFounders.length ||
        assignedMentors.length ||
        removedMentors.length;

      if (showInviteModal) {
        setUpdatedAttendees({
          founders: {
            assigned: assignedFounders,
            removed: removedFounders,
          },
          mentors: {
            assigned: assignedMentors,
            removed: removedMentors,
          },
        });
        setPrevValue({
          parsedValues,
          founders: formFounders,
          mentors: formMentors,
          reasonRescheduling,
        });
        setOpenNotifyModal(true);
      } else {
        handleSubmitForm(
          parsedValues,
          formFounders,
          formMentors,
          reasonRescheduling,
        );
      }
    },
    [founders, mentors, handleSubmitForm],
  );

  const resendingForm = (additionalValue?: string) => {
    if (prevValue) {
      handlePreSubmitForm(
        prevValue.parsedValues,
        prevValue.founders,
        prevValue.mentors,
        additionalValue,
      );
      setPrevValue(undefined);
    }
  };

  const handleOpenAdditionalModal = (
    parsedValues: ParsedFormValues,
    formFounders: Founder[],
    formMentors: Mentor[],
  ) => {
    setPrevValue({
      parsedValues,
      founders: formFounders,
      mentors: formMentors,
    });
    setOpenAdditionModal(true);
  };

  const handleNotifyModalSubmit = (notify?: boolean) => {
    setOpenNotifyModal(false);
    if (prevValue) {
      handleSubmitForm(
        prevValue.parsedValues,
        prevValue.founders,
        prevValue.mentors,
        prevValue.reasonRescheduling,
        notify,
      );
    }
    setPrevValue(undefined);
  };

  return (
    <div>
      <SessionDetailsForm
        sessionDetails={session}
        founders={founders}
        mentors={mentors}
        loading={isUpdating}
        onSubmit={handlePreSubmitForm}
        issues={sessionIssues}
        handleOpenAdditionalModal={handleOpenAdditionalModal}
        defaultLocation={undefined}
        timeZone={user.timeZone}
      />
      <Dialog
        open={openModal}
        setOpen={setOpenModal}
        title='Suggested time'
        width={500}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              We were not able to schedule the event at the time you requested,
              but we suggest to start this event at the next available slot
              which is{' '}
              {prevValue?.parsedValues?.values?.start &&
                formatDateTime(prevValue?.parsedValues?.values?.start)}
              . Apply recommended changes?
            </Text>

            <FormButtons className={classes.formButtons}>
              <Button onClick={handleClose} variant='outlined'>
                Cancel
              </Button>
              <Button
                disabled={isUpdating}
                data-testid='mentor-assignment-apply'
                onClick={() => resendingForm()}>
                {isUpdating ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Apply'
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
      <Dialog
        open={openAdditionModal}
        setOpen={setOpenAdditionModal}
        title='Reschedule'
        width={500}
        classNameContent={classes.contentAdditionalModal}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              Please let us know why you decided to reschedule
            </Text>

            <TextField
              small
              className={classes.rescheduleText}
              name='additionalInfo'
              value={additionalValue}
              onChange={handleChangeAdditionalInfo}
              placeholder='Reason'
            />

            <FormButtons className={classes.formButtons}>
              <Button
                onClick={() => {
                  resendingForm();
                  handleClose();
                }}
                variant='outlined'
                disabled={isUpdating}>
                Skip
              </Button>
              <Button
                disabled={isUpdating}
                data-testid='mentor-assignment-apply'
                onClick={() => {
                  resendingForm(additionalValue);
                  handleClose();
                }}>
                {isUpdating ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'OK'
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
      <Dialog
        open={openNotifyModal}
        setOpen={setOpenNotifyModal}
        title='Attendee list was updated'
        width={500}
        contentRenderer={({ handleClose }) => {
          if (!updatedAttendees) return null;

          const allAssignments = [
            ...updatedAttendees.founders.assigned,
            ...updatedAttendees.mentors.assigned,
          ];
          const allRemovals = [
            ...updatedAttendees.founders.removed,
            ...updatedAttendees.mentors.removed,
          ];

          return (
            <div>
              <Text variant='normal'>
                Choose an option below to proceed:
                <br />
                <br />
                <strong>SAVE</strong>: Save the updated invitees without
                notifying anyone. You can send out updated invites later by
                clicking “Invite All” in the sessions interface.
                <br />
                <br />
                <strong>SAVE & NOTIFY</strong>: Save your changes and
                immediately send updated invites/cancellations to the newly
                added or removed invitees:
                <br />
              </Text>
              <ul>
                {allAssignments.map((assign) => (
                  <li>
                    <Text
                      key={assign.id}
                      variant='normal'
                      color={COLORS.COLOR_GREEN_BASE}>
                      {assign.firstName} {assign.lastName}
                    </Text>
                  </li>
                ))}
                {allRemovals.map((remove) => (
                  <li>
                    <Text
                      key={remove.id}
                      variant='normal'
                      color={COLORS.COLOR_RED_BASE}>
                      {remove.firstName} {remove.lastName}
                    </Text>
                  </li>
                ))}
              </ul>
              <FormButtons className={classes.formButtons}>
                <Button onClick={handleClose} variant='outlined'>
                  Cancel
                </Button>
                <Button
                  disabled={isUpdating}
                  data-testid='notify-modal-save-button'
                  onClick={() => handleNotifyModalSubmit()}>
                  {isUpdating ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  disabled={isUpdating}
                  data-testid='notify-modal-save-and-notify-button'
                  onClick={() => handleNotifyModalSubmit(true)}>
                  {isUpdating ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save & Notify'
                  )}
                </Button>
              </FormButtons>
            </div>
          );
        }}
      />
    </div>
  );
}

export default SessionDetailsPage;
