import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import authAPI, { TenantDetails } from '../../api/auth';
import * as tenantsAPI from '../../api/tenants';
import { TenantId } from '../../api/tenants/Tenant';
import { SnackMessage } from '../../components/common';
import TenantProfileForm, {
  ParsedFormValues,
} from '../../components/forms/tenant-profile';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
});

function TenantProfilePage() {
  const classes = useStyles();
  const { user: tenant, logo, updateUser, setLogo } = useContext(UserContext);
  const tenantId = tenant?.id || '';
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const _tenant = useMemo(
    (): TenantDetails | undefined =>
      tenant
        ? {
            ...tenant,
            timeZone: tenant?.timeZone ?? 'US/Eastern',
          }
        : undefined,
    [tenant],
  );

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { logo, ...values } = parsedValues.values;
        setUpdating(true);
        let updatedLogo = logo;

        if (parsedValues.logoFile) {
          const updateLogoResult = await authAPI.updateAvatar(
            parsedValues.logoFile,
          );
          updatedLogo = updateLogoResult.logo;
          const logoUrl = await tenantsAPI.getLogo(tenantId as TenantId);
          setLogo(logoUrl);
        } else if (!logo) {
          setLogo(undefined);
        }

        const updatedTenant = await authAPI.updateTenant(tenantId, {
          ...tenant,
          ...values,
          logo: updatedLogo,
        });
        updateUser(updatedTenant);
        setUpdating(false);
        // updateMentor(updatedMentor);
        enqueueSnackbar('The page saved successfully.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the profile. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdating(false);
      }
    },
    [tenantId, tenant, updateUser, enqueueSnackbar, setLogo],
  );

  if (!_tenant) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <TenantProfileForm
        tenantDetails={_tenant}
        logo={logo}
        loading={isUpdating}
        onSubmit={handleSubmitForm}
      />
    </div>
  );
}

export default TenantProfilePage;
