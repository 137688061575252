import { Tab, Tabs, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MentorId } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { VentureContext } from '../../contexts/venture-context';
import { useAuth } from '../../hooks';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  ventureId?: VentureId;
}

const useStyles = makeStyles({
  container: {
    padding: '0',
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },

  tooltipWrapper: {
    marginLeft: '30px',
    '& .MuiTab-wrapper': {
      whiteSpace: 'nowrap',
    },
    '& > button': {
      whiteSpace: 'nowrap',
    },
  },
});

function PageTabs({ ventureId }: PageTabsProps) {
  const location = useLocation();
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { hasAccessToAction } = useContext(UserContext);
  const { tokenData } = useAuth();
  const [canViewAllTabs, setCanViewAllTabs] = useState(false);
  const { loadMentors, assignedMentors } = useContext(VentureContext);

  useEffect(() => {
    if (!ventureId) {
      return;
    }

    if (tokenData?.role === 'ROLE_Mentor') {
      loadMentors(ventureId);
    } else {
      setCanViewAllTabs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData?.role, ventureId]);

  useEffect(() => {
    if (
      tokenData?.identityid &&
      assignedMentors.type === 'Success' &&
      assignedMentors.payload.data.includes(tokenData.identityid as MentorId)
    ) {
      setCanViewAllTabs(true);
    }

    if (assignedMentors.type === 'LoadError') {
      setCanViewAllTabs(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedMentors.type, tokenData?.identityid]);

  const tabs: TabsProps[] = [
    {
      props: {
        label: 'Details',
        'data-testid': 'venture-nav-details',
        component: Link,
        ...(ventureId
          ? {
              to: getRoutePath(Pages.VENTURE_DETAILS, { ventureId }),
              value: getRoutePath(Pages.VENTURE_DETAILS, { ventureId }),
            }
          : {
              to: Pages.NEW_VENTURE,
              value: Pages.NEW_VENTURE,
            }),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to Founders`,
            },
          }
        : {}),
      props: {
        label: 'Founders',
        'data-testid': 'venture-nav-founders',
        disabled: !ventureId,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_FOUNDERS, { ventureId }),
              to: getRoutePath(Pages.VENTURE_FOUNDERS, { ventureId }),
            }
          : {}),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to ${rb(
                'mentors-u',
              )}`,
            },
          }
        : {}),
      props: {
        label: rb('mentors-u'),
        'data-testid': 'venture-nav-assignments',
        disabled: !ventureId,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_ASSIGNMENTS, { ventureId }),
              to: getRoutePath(Pages.VENTURE_ASSIGNMENTS, { ventureId }),
            }
          : {}),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to Additional info`,
            },
          }
        : {}),
      props: {
        label: 'Additional info',
        'data-testid': 'venture-nav-additional-info',
        disabled: !ventureId,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_ADDITIONAL_INFO, { ventureId }),
              to: getRoutePath(Pages.VENTURE_ADDITIONAL_INFO, { ventureId }),
            }
          : {}),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to Notes`,
            },
          }
        : !canViewAllTabs
        ? {
            tooltip: {
              title: `This tab is available only for ${rb(
                'mentors',
              )} assigned to this venture`,
            },
          }
        : {}),
      props: {
        label: 'Notes',
        'data-testid': 'venture-nav-notes',
        disabled: !ventureId || !canViewAllTabs,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_NOTES, { ventureId }),
              to: getRoutePath(Pages.VENTURE_NOTES, { ventureId }),
            }
          : {}),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to Finance`,
            },
          }
        : !canViewAllTabs
        ? {
            tooltip: {
              title: `This tab is available only for ${rb(
                'mentors',
              )} assigned to this venture`,
            },
          }
        : {}),
      props: {
        label: 'Quarterly Reports',
        'data-testid': 'venture-nav-quarterly-report',
        disabled: !ventureId || !canViewAllTabs,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_QUARTERLY_REPORT, {
                ventureId,
              }),
              to: getRoutePath(Pages.VENTURE_QUARTERLY_REPORT, { ventureId }),
            }
          : {}),
      },
    },
    {
      ...(!ventureId
        ? {
            tooltip: {
              title: `Please complete creation of venture\nbefore switching to Log`,
            },
          }
        : !canViewAllTabs
        ? {
            tooltip: {
              title: `This tab is available only for ${rb(
                'mentors',
              )} assigned to this venture`,
            },
          }
        : {}),
      props: {
        label: 'Log',
        'data-testid': 'venture-nav-log',
        disabled: !ventureId || !canViewAllTabs,
        ...(ventureId
          ? {
              component: Link,
              value: getRoutePath(Pages.VENTURE_LOG, { ventureId }),
              to: getRoutePath(Pages.VENTURE_LOG, { ventureId }),
            }
          : {}),
      },
    },
  ];

  const currentTabs = useMemo(() => {
    const arrayTabs = [...tabs];
    if (!hasAccessToAction('venture.logs.view')) {
      arrayTabs.splice(6, 1);
    }
    if (!hasAccessToAction('venture.finance.view')) {
      arrayTabs.splice(5, 1);
    }
    if (!hasAccessToAction('venture.notes.view')) {
      arrayTabs.splice(4, 1);
    }
    if (!hasAccessToAction('venture.additionalInfo.view')) {
      arrayTabs.splice(3, 1);
    }
    if (!hasAccessToAction('venture.assignemnts.view')) {
      arrayTabs.splice(2, 1);
    }
    if (!hasAccessToAction('venture.founders.view')) {
      arrayTabs.splice(1, 1);
    }
    if (!hasAccessToAction('venture.details.view')) {
      arrayTabs.splice(0, 1);
    }
    return arrayTabs;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='on'>
        {currentTabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          ),
        )}
      </Tabs>
    </div>
  );
}

export default PageTabs;
