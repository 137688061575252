import { Advisor, AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { SpecializationId } from '../../../../api/specializations';
import { TenantId } from '../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { ValidAdvisor } from '../../common/ValidAdvisor';
import * as Item from '../../common/types/Item';

// region Loading
export interface LoadingPayload {
  id: AdvisorId;
  tenantId: TenantId;
  timeZone: TenantTimeZone;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  advisor: Advisor;
  item: Item.Valid;
  initialSpecializations: SpecializationId[];
  logo: File | undefined;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Edited
interface EditedPayload extends Omit<ReadyPayload, 'item'> {
  item: Item.Item;
}

export interface Edited {
  type: 'Edited';
  payload: EditedPayload;
}

export const edited = (payload: Edited['payload']): Edited => ({
  type: 'Edited',
  payload,
});
// endregion

// region Uploading
export interface UploadingPayload extends EditedPayload {
  logo: File;
}

export interface Uploading {
  type: 'Uploading';
  payload: UploadingPayload;
}

export const uploading = (payload: Uploading['payload']): Uploading => ({
  type: 'Uploading',
  payload,
});
// endregion

// region Submitted
export interface SubmittedPayload extends EditedPayload {
  item: Item.Submitted;
}

export interface Submitted {
  type: 'Submitted';
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted['payload']): Submitted => ({
  type: 'Submitted',
  payload,
});
// endregion

// region InvalidAvailabilities
export interface InvalidAvailabilitiesPayload
  extends Omit<SavingPayload, 'advisor'> {
  advisor: Advisor;
}

export interface InvalidAvailabilities {
  type: 'InvalidAvailabilities';
  payload: InvalidAvailabilitiesPayload;
}

export const invalidAvailabilities = (
  payload: InvalidAvailabilities['payload'],
): InvalidAvailabilities => ({
  type: 'InvalidAvailabilities',
  payload,
});
// endregion

// region Saving
export interface SavingPayload extends Omit<EditedPayload, 'advisor'> {
  item: Item.Valid;
  advisor: ValidAdvisor;
}

export interface Saving {
  type: 'Saving';
  payload: SavingPayload;
}

export const saving = (payload: Saving['payload']): Saving => ({
  type: 'Saving',
  payload,
});
// endregion

// region SaveError
export interface SaveErrorPayload
  extends Omit<SavingPayload, 'addSpecializations' | 'removeSpecializations'> {
  message: string;
}

export interface SaveError {
  type: 'SaveError';
  payload: SaveErrorPayload;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccessPayload
  extends Omit<SavingPayload, 'addSpecializations' | 'removeSpecializations'> {}

export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: SaveSuccessPayload;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region VerificationError
export interface VerificationErrorPayload extends EditedPayload {
  message: string;
}

export interface VerificationError {
  type: 'VerificationError';
  payload: VerificationErrorPayload;
}

export const verificationError = (
  payload: VerificationError['payload'],
): VerificationError => ({
  type: 'VerificationError',
  payload,
});
// endregion

// region UnloadConfirm
export interface UnloadConfirmPayload extends SavingPayload {}

export interface UnloadConfirm {
  type: 'UnloadConfirm';
  payload: UnloadConfirmPayload;
}

export const unloadConfirm = (
  payload: UnloadConfirm['payload'],
): UnloadConfirm => ({
  type: 'UnloadConfirm',
  payload,
});
// endregion

// region UnloadSubmitted
export interface UnloadSubmittedPayload extends SubmittedPayload {}

export interface UnloadSubmitted {
  type: 'UnloadSubmitted';
  payload: UnloadSubmittedPayload;
}

export const unloadSubmitted = (
  payload: UnloadSubmitted['payload'],
): UnloadSubmitted => ({
  type: 'UnloadSubmitted',
  payload,
});
// endregion

// region UnloadInvalidAvailabilities
export interface UnloadInvalidAvailabilitiesPayload
  extends Omit<UnloadConfirmPayload, 'advisor'> {
  advisor: Advisor;
}

export interface UnloadInvalidAvailabilities {
  type: 'UnloadInvalidAvailabilities';
  payload: UnloadInvalidAvailabilitiesPayload;
}

export const unloadInvalidAvailabilities = (
  payload: UnloadInvalidAvailabilities['payload'],
): UnloadInvalidAvailabilities => ({
  type: 'UnloadInvalidAvailabilities',
  payload,
});
// endregion

// region UnloadSaving
export interface UnloadSavingPayload extends SavingPayload {}

export interface UnloadSaving {
  type: 'UnloadSaving';
  payload: UnloadSavingPayload;
}

export const unloadSaving = (
  payload: UnloadSaving['payload'],
): UnloadSaving => ({
  type: 'UnloadSaving',
  payload,
});
// endregion

// region UnloadError
export interface UnloadErrorPayload extends UnloadSavingPayload {
  message: string;
}

export interface UnloadError {
  type: 'UnloadError';
  payload: UnloadErrorPayload;
}

export const unloadError = (payload: UnloadError['payload']): UnloadError => ({
  type: 'UnloadError',
  payload,
});
// endregion

// region Unloading
export interface UnloadingPayload extends Omit<EditedPayload, 'advisor'> {
  advisor: Advisor;
}

export interface Unloading {
  type: 'Unloading';
  payload: UnloadingPayload;
}

export const unloading = (payload: Unloading['payload']): Unloading => ({
  type: 'Unloading',
  payload,
});
// endregion

// region RemoveAndUpdate
export interface RemoveAndUpdatePayload extends InvalidAvailabilitiesPayload {}

export interface RemoveAndUpdate {
  type: 'RemoveAndUpdate';
  payload: RemoveAndUpdatePayload;
}

export const removeAndUpdate = (
  payload: RemoveAndUpdate['payload'],
): RemoveAndUpdate => ({
  type: 'RemoveAndUpdate',
  payload,
});
// endregion

// region RemoveAndUpdateError
export interface RemoveAndUpdateErrorPayload extends RemoveAndUpdatePayload {
  message: string;
}

export interface RemoveAndUpdateError {
  type: 'RemoveAndUpdateError';
  payload: RemoveAndUpdateErrorPayload;
}

export const removeAndUpdateError = (
  payload: RemoveAndUpdateError['payload'],
): RemoveAndUpdateError => ({
  type: 'RemoveAndUpdateError',
  payload,
});
// endregion

// region UnloadRemoveAnUpdate
export interface UnloadRemoveAnUpdatePayload
  extends UnloadInvalidAvailabilitiesPayload {}

export interface UnloadRemoveAnUpdate {
  type: 'UnloadRemoveAnUpdate';
  payload: UnloadRemoveAnUpdatePayload;
}

export const unloadRemoveAnUpdate = (
  payload: UnloadRemoveAnUpdate['payload'],
): UnloadRemoveAnUpdate => ({
  type: 'UnloadRemoveAnUpdate',
  payload,
});
// endregion

// region UnloadRemoveAnUpdateError
export interface UnloadRemoveAnUpdateErrorPayload
  extends UnloadRemoveAnUpdatePayload {
  message: string;
}

export interface UnloadRemoveAnUpdateError {
  type: 'UnloadRemoveAnUpdateError';
  payload: UnloadRemoveAnUpdateErrorPayload;
}

export const unloadRemoveAnUpdateError = (
  payload: UnloadRemoveAnUpdateError['payload'],
): UnloadRemoveAnUpdateError => ({
  type: 'UnloadRemoveAnUpdateError',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | Edited
  | Uploading
  | Submitted
  | InvalidAvailabilities
  | Saving
  | SaveError
  | SaveSuccess
  | VerificationError
  | UnloadConfirm
  | UnloadSubmitted
  | UnloadInvalidAvailabilities
  | UnloadSaving
  | UnloadError
  | Unloading
  | RemoveAndUpdate
  | RemoveAndUpdateError
  | UnloadRemoveAnUpdate
  | UnloadRemoveAnUpdateError;

export function init(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  id: AdvisorId,
): Loading {
  return loading({ id, tenantId, timeZone });
}

// region Loaded
export type Loaded = Exclude<State, Loading | LoadError>;

export function isLoaded(s: State): s is Loaded {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Saving':
    case 'Uploading':
    case 'Edited':
    case 'Ready':
    case 'Submitted':
    case 'SaveError':
    case 'VerificationError':
    case 'UnloadError':
    case 'UnloadConfirm':
    case 'UnloadSaving':
    case 'UnloadSubmitted':
    case 'Unloading':
    case 'InvalidAvailabilities':
    case 'UnloadInvalidAvailabilities':
    case 'RemoveAndUpdate':
    case 'RemoveAndUpdateError':
    case 'UnloadRemoveAnUpdate':
    case 'UnloadRemoveAnUpdateError':
    case 'SaveSuccess':
      return true;
  }
}
// endregion

// region Editable
export type Editable =
  | Ready
  | Edited
  | Uploading
  | Submitted
  | SaveError
  | SaveSuccess
  | VerificationError;

export function isEditable(s: State): s is Editable {
  switch (s.type) {
    case 'Saving':
    case 'Uploading':
    case 'Loading':
    case 'LoadError':
    case 'UnloadSaving':
    case 'UnloadConfirm':
    case 'UnloadSubmitted':
    case 'UnloadError':
    case 'UnloadInvalidAvailabilities':
    case 'InvalidAvailabilities':
    case 'Unloading':
    case 'UnloadRemoveAnUpdateError':
    case 'UnloadRemoveAnUpdate':
    case 'RemoveAndUpdateError':
    case 'RemoveAndUpdate':
      return false;
    case 'Edited':
    case 'Ready':
    case 'Submitted':
    case 'SaveSuccess':
    case 'SaveError':
    case 'VerificationError':
      return true;
  }
}
// endregion

// region Submittable
export type Submittable =
  | Edited
  | Submitted
  | SaveError
  | SaveSuccess
  | Ready
  | VerificationError
  | UnloadConfirm
  | UnloadError;

export function isSubmittable(s: State): s is Submittable {
  switch (s.type) {
    case 'Submitted':
    case 'SaveError':
    case 'Edited':
    case 'SaveSuccess':
    case 'Ready':
    case 'UnloadConfirm':
    case 'UnloadError':
    case 'VerificationError':
    case 'RemoveAndUpdateError':
    case 'UnloadRemoveAnUpdateError':
      return true;
    case 'Loading':
    case 'LoadError':
    case 'Uploading':
    case 'UnloadSaving':
    case 'Saving':
    case 'UnloadSubmitted':
    case 'Unloading':
    case 'UnloadInvalidAvailabilities':
    case 'InvalidAvailabilities':
    case 'RemoveAndUpdate':
    case 'UnloadRemoveAnUpdate':
      return false;
  }
}
// endregion

// region UnloadDialog
export type UnloadDialog =
  | UnloadConfirm
  | UnloadError
  | UnloadInvalidAvailabilities
  | UnloadSaving
  | UnloadSubmitted;

export function isUnloadDialog(s: State): s is UnloadDialog {
  switch (s.type) {
    case 'UnloadConfirm':
    case 'UnloadError':
    case 'UnloadInvalidAvailabilities':
    case 'UnloadSaving':
    case 'UnloadRemoveAnUpdateError':
    case 'UnloadRemoveAnUpdate':
    case 'UnloadSubmitted':
      return true;
    case 'Unloading':
    case 'SaveError':
    case 'SaveSuccess':
    case 'Uploading':
    case 'Submitted':
    case 'VerificationError':
    case 'Saving':
    case 'LoadError':
    case 'Ready':
    case 'Loading':
    case 'InvalidAvailabilities':
    case 'RemoveAndUpdateError':
    case 'RemoveAndUpdate':
    case 'Edited':
      return false;
  }
}
// endregion

export function isEdited(
  s: Loaded,
): s is Submitted | Uploading | UnloadSubmitted | UnloadConfirm | Edited {
  switch (s.type) {
    case 'UnloadError':
    case 'VerificationError':
    case 'UnloadSaving':
    case 'SaveSuccess':
    case 'SaveError':
    case 'Ready':
    case 'Unloading':
    case 'Saving':
      return false;
    case 'Submitted':
    case 'Uploading':
    case 'UnloadSubmitted':
    case 'UnloadConfirm':
    case 'Edited':
    case 'UnloadInvalidAvailabilities':
    case 'InvalidAvailabilities':
    case 'RemoveAndUpdate':
    case 'RemoveAndUpdateError':
    case 'UnloadRemoveAnUpdate':
    case 'UnloadRemoveAnUpdateError':
      return true;
  }
}

// region Dialog
type Dialog =
  | UnloadRemoveAnUpdateError
  | UnloadRemoveAnUpdate
  | UnloadInvalidAvailabilities
  | InvalidAvailabilities
  | Unloading
  | UnloadSaving
  | UnloadConfirm
  | UnloadError
  | UnloadSubmitted
  | RemoveAndUpdate
  | RemoveAndUpdateError;

export function isDialog(s: State): s is Dialog {
  switch (s.type) {
    case 'UnloadRemoveAnUpdateError':
    case 'UnloadRemoveAnUpdate':
    case 'UnloadInvalidAvailabilities':
    case 'InvalidAvailabilities':
    case 'Unloading':
    case 'UnloadSaving':
    case 'UnloadConfirm':
    case 'UnloadError':
    case 'UnloadSubmitted':
    case 'RemoveAndUpdate':
    case 'RemoveAndUpdateError':
      return true;
    case 'Edited':
    case 'Loading':
    case 'Ready':
    case 'LoadError':
    case 'Saving':
    case 'VerificationError':
    case 'Submitted':
    case 'Uploading':
    case 'SaveSuccess':
    case 'SaveError':
      return false;
  }
}

// endregion

// region IsSaving
export type IsSaving =
  | Saving
  | UnloadSaving
  | UnloadRemoveAnUpdate
  | RemoveAndUpdate;

export function isSaving(s: State): s is IsSaving {
  switch (s.type) {
    case 'UnloadSaving':
    case 'Saving':
    case 'UnloadRemoveAnUpdate':
    case 'RemoveAndUpdate':
      return true;
    case 'UnloadSubmitted':
    case 'UnloadError':
    case 'UnloadConfirm':
    case 'InvalidAvailabilities':
    case 'UnloadInvalidAvailabilities':
    case 'SaveError':
    case 'SaveSuccess':
    case 'Unloading':
    case 'UnloadRemoveAnUpdateError':
    case 'VerificationError':
    case 'LoadError':
    case 'Uploading':
    case 'Submitted':
    case 'Edited':
    case 'RemoveAndUpdateError':
    case 'Loading':
    case 'Ready':
      return false;
  }
}
// endregion
