import { useState, ReactElement } from 'react';

interface LogoImageProps {
  src: string;
  className?: string;
  alt?: string;
}

function LogoImage({
  className,
  src,
  alt,
}: LogoImageProps): ReactElement | null {
  const [brokenImage, setBrokenImage] = useState(false);

  return brokenImage ? null : (
    <img
      className={className}
      src={src}
      alt={alt || src}
      onError={() => setBrokenImage(true)}
    />
  );
}

export default LogoImage;
