import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import { RefObject, useEffect } from 'react';

interface Props {
  editorRef: RefObject<LexicalEditor>;
}

export default function RefPlugin({ editorRef }: Props) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // @ts-ignore
    editorRef.current = editor;
  }, [editor, editorRef]);

  return null;
}
