import { Either, left, right } from '../../../../../utils/Either';
import * as Email from '../../../../../utils/String/Email';

export function validateEmail(v: string): Either<string, Email.Email> {
  const email = Email.fromString(v);

  return email ? right(email) : left('Invalid email');
}

export function validateConfirmEmail(
  confirmEmail: string,
  email: string,
): Either<string, Email.Email> {
  const confirmEmailFromStr = Email.fromString(confirmEmail);

  if (!confirmEmailFromStr) {
    return left('Invalid email');
  }

  return email === confirmEmail
    ? validateEmail(email)
    : left('Emails do not match');
}

export const validateTerms = (terms: string): Either<string, string> =>
  terms === 'true'
    ? right(terms)
    : left('Please accept the terms and conditions');
