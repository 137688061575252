import * as Advisors from '../../../../api/Advisors';
import { AdvisorWithSpecializations } from '../../../../api/Advisors/types/Advisor';
import { TenantId } from '../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { matchLogo } from '../../../Advisors/common/utils';
import { getAllSpecializations } from '../common/Api';
import { Filter } from './types/Filter';

export function get(
  id: TenantId,
  timeZone: TenantTimeZone,
  filter: Filter,
  page: number,
): Promise<{ advisors: AdvisorWithSpecializations[]; nextPage: boolean }> {
  return getClient(id, filter, timeZone, page).then(async ({ next, items }) => {
    const logosList = items.map((i) => i.logo).filter((i): i is string => !!i);
    const logos = logosList.length
      ? await Advisors.getLogos(id, logosList).catch(() => [])
      : [];
    const newItems = await Promise.all(
      items.map((i) => ({
        ...i,
        logo: i.logo ? matchLogo(i.logo, logos) ?? null : null,
        specializations: i.advisorSpecializations,
      })),
    );

    return {
      advisors: newItems,
      nextPage: next,
    };
  });
}

function getClient(
  id: TenantId,
  filter: Filter,
  timeZone: TenantTimeZone,
  page: number,
): Promise<{ items: AdvisorWithSpecializations[]; next: boolean }> {
  switch (filter.type) {
    case 'None':
      return Promise.all([
        Advisors.getByStatus(id, timeZone, 'Active', page - 1),
        Advisors.getByStatus(id, timeZone, 'Active', page),
      ]).then(([items, count]) => ({ items, next: !!count.length }));
    case 'Search':
      return Advisors.search(id, timeZone, filter.value).then((items) => ({
        items,
        next: false,
      }));
    case 'Specialization':
      return Promise.all([
        Advisors.getBySpecialization(id, timeZone, filter.value, page - 1),
        Advisors.getBySpecialization(id, timeZone, filter.value, page),
      ]).then(([items, count]) => ({ items, next: !!count.length }));
  }
}

export const getSpecializations = getAllSpecializations;
