import { TabItem, Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { PageLoader } from '../../../components/common';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { View } from '../common/View';
import { statusOptions } from '../common/utils';
import { isLoaded, Loaded } from './types/State';
import { useAdvisorView } from './useAdvisorView';

const tabs: TabItem[] = [
  {
    title: 'Details',
    link: Pages.OH_ADVISORS_NEW,
  },
  {
    title: 'Additional Info',
    link: Pages.OH_ADVISORS_INFO,
    disabled: true,
  },
];

function LoadedState({ s }: { s: Loaded }) {
  const { item, enrollmentDate } = s.payload;
  return (
    <View enrollmentDate={enrollmentDate} statuses={statusOptions} {...item} />
  );
}

export function ViewAdvisor({ user }: ProtectedRouteProps) {
  const [s] = useAdvisorView(user);
  const { rb } = useResourceBundles();

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={`View ${rb('advisor')}`}
        backButtonLink={Pages.OH_ADVISORS}
        backButtonTitle={`Back to ${rb('advisors-u')}`}
        tabs={<Tabs active={Pages.OH_ADVISORS_NEW} tabs={tabs} />}>
        {isLoaded(s) ? <LoadedState s={s} /> : <PageLoader />}
      </WithTabs>
    </BaseLayout>
  );
}
