import { makeStyles } from '@material-ui/core';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '52px',
    height: '52px',
    background: `rgba(255, 255, 255, 0.2)`,
    border: `1px solid ${COLORS.COLOR_BLUE_LIGHTENED_25}`,
    color: COLORS.COLOR_BLUE_DARKENED_10,
    cursor: 'pointer',
    borderRadius: '27px',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 25px',
    textDecoration: 'none',
    transition: 'all .2s ease',

    '&:hover': {
      background: COLORS.COLOR_BLUE_DARKENED_30,
      borderColor: COLORS.COLOR_BLUE_DARKENED_30,
      color: '#fff',
      textDecoration: 'none',
    },
  },
}));

export interface Props {
  title: string;
  to: string;
}

export function RoundedTag({ title, to }: Props): ReactElement {
  const classes = useStyles();

  return (
    <Link className={classes.root} to={to}>
      {title}
    </Link>
  );
}
