import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer } from 'react';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as Answers from '../../../api/ventures/quarterlyrecords/customanswers';
import { VentureQuarterRecord } from '../../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { loading, State } from './types/State';

type A = Actions.Remove | Actions.RemoveApprove | Actions.RemoveSuccess;

export function useReportItem(
  report: VentureQuarterRecord,
): [State, Dispatch<A>] {
  const { enqueueSnackbar } = useSnackbar();
  const [s, dispatch] = useReducer(reducer, loading({ record: report }));

  useEffect(() => {
    if (s.type === 'Loading') {
      const o = from(Answers.get(s.payload.record.id))
        .pipe(
          map(Actions.fetchSuccess),
          catchError(() => of(Actions.fetchFail('Unable to load answers'))),
        )
        .subscribe(dispatch);

      return () => {
        o.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'Loading']);
  useEffect(() => {
    if (s.type === 'LoadErr') {
      enqueueSnackbar(s.payload.message, { variant: 'error' });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'LoadErr']);
  useEffect(() => {
    if (s.type === 'RemoveConfirmation') {
      // eslint-disable-next-line no-restricted-globals
      const r = confirm('Do you really want to remove?');

      dispatch(r ? Actions.removeApprove() : Actions.removeDecline());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'RemoveConfirmation']);
  useEffect(() => {
    if (s.type === 'Removing') {
      const o = from(Answers.remove(s.payload.record.id, s.payload.answerId))
        .pipe(
          map(Actions.removeSuccess),
          catchError(() => of(Actions.removeFail('Unable to remove answer'))),
        )
        .subscribe(dispatch);

      return () => {
        o.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'Removing']);
  useEffect(() => {
    if (s.type === 'RemoveErr') {
      enqueueSnackbar(s.payload.message, { variant: 'error' });
      dispatch(Actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'RemoveErr']);
  useEffect(() => {
    if (s.type === 'RemoveSuccess') {
      enqueueSnackbar(s.payload.message, { variant: 'success' });
      dispatch(Actions.reset());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'RemoveSuccess']);

  return [s, dispatch];
}
