import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';
import { Success } from '../../../assets/icons';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { TestId } from '../../Testing/TestId';
import { Button } from '../../common';
import { DialogTitle } from '../../common/dialog';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
    },
  },
  title: {
    textAlign: 'center',

    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
  },
  content: {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    padding: 32,
  },
}));

export interface Props {
  open: boolean;
  onClose: () => void;
}

export function Booked({ open, onClose }: Props): ReactElement {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle onClose={onClose} className={classes.title}>
        <Success />
        <Typography variant={'h3'}>Appointment requested</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.subtitle} variant={'body1'}>
          Once the {rb('advisor')} confirms the meeting, <br />
          we’ll send you an invite.
        </Typography>

        <TestId testId={'close-button'}>
          <Button onClick={onClose}>Close</Button>
        </TestId>
      </DialogContent>
    </Dialog>
  );
}
