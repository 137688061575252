import { Item } from './Item';
import { NonEmptyArray } from '../../../../../utils/Array/NonEmptyArray';

// region Idle
export interface IdlePayload {}

export interface Idle {
  type: 'Idle';
}

export const idle = (): Idle => ({
  type: 'Idle',
});
// endregion

// region Ready

export interface Ready {
  type: 'Ready';
}

export const ready = (): Ready => ({
  type: 'Ready',
});
// endregion

// region Sending
export interface SendingPayload {
  items: NonEmptyArray<Item>;
}

export interface Sending {
  type: 'Sending';
  payload: SendingPayload;
}

export const sending = (payload: Sending['payload']): Sending => ({
  type: 'Sending',
  payload,
});
// endregion

export type State = Idle | Ready | Sending;

// region Open
export type Open = Ready | Sending;

export function isOpen(s: State): s is Open {
  switch (s.type) {
    case 'Idle':
      return false;
    case 'Ready':
    case 'Sending':
      return true;
  }
}
// endregion

// region Submittable
export type Submittable = Ready | Sending;

export function isSubmittable(s: State): s is Submittable {
  switch (s.type) {
    case 'Ready':
      return true;
    case 'Idle':
      return false;
    case 'Sending':
      return s.payload.items.every((i) => i.type === 'sendError');
  }
}
// endregion
