import { useCustomQuestionContext } from '../../contexts/custom-questions-context';
import { AlertState, Button, Dialog } from '../common';

export function CustomQuestionDefaultWarningPopup() {
  const { showDefaultModeWarningPopup, setShowDefaultModeWarningPopup } =
    useCustomQuestionContext();

  const handleModalClose = () => {
    setShowDefaultModeWarningPopup(false);
  };

  return (
    <Dialog
      title='Custom Questions Deleted: Default Mode'
      contentRenderer={() => (
        <AlertState type='warning'>
          Warning: Since you have deleted all custom questions, you have been
          switched to default mode. To switch back to custom mode, please add
          custom questions first.
        </AlertState>
      )}
      open={showDefaultModeWarningPopup}
      setOpen={(v) => !v && handleModalClose()}
      width={500}
      actions={
        <Button
          onClick={handleModalClose}
          variant='outlined'
          data-testid='custom-question-default-warning-popup-ok-button'>
          Ok
        </Button>
      }
    />
  );
}
