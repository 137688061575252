import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Bowser from 'bowser';
import cn from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import reportsAPI, { GeneralReport } from '../../api/reports';
import {
  Text,
  LogoImage,
  GeneralReportDetailsPopup,
} from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { COLORS } from '../../theme/variables';
import {
  getGeneralReportMonthIndex,
  getMonthByIndex,
  getPreviousMonth,
  getYearFromDate,
  getYearGeneralReport,
} from '../../utils/date';

export interface ParamsReport {
  includeLogo: boolean;
  includeSummary: boolean;
}

interface Props {
  paramsReport: ParamsReport;
  report?: GeneralReport;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    width: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  defaultStyles: {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 70,
  },
  logoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: 70,

    '@media print': {
      position: 'relative',
    },
  },
  confidentialText: {
    fontSize: 10,

    '@media print': {
      whiteSpace: 'nowrap',
    },
  },
  contentEmpty: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentEmptyText: {
    width: 500,
    textAlign: 'center',
  },
  printFirefox: {
    '@media print': {
      marginLeft: -100,
    },
  },
  summary: {
    maxWidth: 670,
    marginTop: 30,
  },
  summary_item: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  reportDate: {
    marginTop: 10,
  },
  summary_item_title: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  summary_link: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  summary_item_label: {
    width: '100%',
  },
  demographic: {
    marginTop: 30,
    maxWidth: 670,
  },
  demographic_item: {
    display: 'flex',
    marginTop: 8,
  },
  demographic_item_block: {
    marginTop: 20,
  },
  demographic_item_label: {
    width: '100%',
  },
  demographic_item_count: {
    whiteSpace: 'nowrap',
  },
  summaryTable: {
    width: '100%',
    maxWidth: 670,
    marginTop: 10,

    '& td': {
      padding: '3px 5px',
      border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
      height: 30,
      boxSizing: 'border-box',
    },
  },
  cellData: {
    textAlign: 'right',
  },
  cellLabel: {
    width: '100%',
  },
  summaryBlock: {
    marginTop: 30,
  },
}));

function getPercent(source = 0, total = 0) {
  let result = (source / total) * 100;
  if (isNaN(result) || result === Infinity) {
    result = 0;
  }
  return Math.round(result);
}

function ReportsGeneralScreen({ paramsReport, report, loading }: Props) {
  const { user } = useAuth();
  const { logo } = useContext(UserContext);
  const { rb } = useResourceBundles();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isFirefox = browser.getBrowser().name === 'Firefox';
  const [yearSummary, setYearSummary] = useState<GeneralReport[]>([]);

  const classes = useStyles();
  const currentMonth = report
    ? getPreviousMonth(new Date(report.creationDate))
    : '';

  const [loadedList, setLoadedList] = useState<{
    mentor: {
      new?: string[];
      archived?: string[];
      applicants?: string[];
    };
    venture: {
      new?: string[];
      archived?: string[];
      applicants?: string[];
    };
  }>({ mentor: {}, venture: {} });

  const summary = useMemo(
    () => [
      {
        alias: 'mentor',
        total: {
          label: rb('mentors-u'),
          count: report?.mentorsActive || 0,
        },
        new: {
          label: `New ${rb('mentors-u')}`,
          link: () =>
            reportsAPI.getGeneralReportMentors(report?.id || '', 'new'),
          count: report?.mentorsJoined || 0,
        },
        archived: {
          label: `Exited ${rb('mentors-u')}`,
          link: () =>
            reportsAPI.getGeneralReportMentors(report?.id || '', 'archived'),
          count: report?.mentorsLeft || 0,
        },
        applicants: {
          label: `${rb(
            'mentor-u',
          )} applications received (excludes manually added)`,
          link: () =>
            reportsAPI.getGeneralReportMentors(report?.id || '', 'applied'),
          count: report?.mentorsApplicants || 0,
        },
      },
      {
        alias: 'venture',
        total: {
          label: 'Ventures',
          count: report?.venturesActive || 0,
        },
        new: {
          label: 'New Accepted Ventures',
          link: () =>
            reportsAPI.getGeneralReportVentures(report?.id || '', 'new'),
          count: report?.venturesJoined || 0,
        },
        archived: {
          label: 'Exited Ventures',
          link: () =>
            reportsAPI.getGeneralReportVentures(report?.id || '', 'archived'),
          count: report?.venturesLeft || 0,
        },
        applicants: {
          label: 'Venture applications received (excludes manually added)',
          link: () =>
            reportsAPI.getGeneralReportVentures(report?.id || '', 'applied'),
          count: report?.venturesApplicants || 0,
        },
      },
      {
        total: {
          label: 'Founders',
          count: report?.foundersActive || 0,
        },
      },
    ],
    [report, rb],
  );

  const demographics = useMemo(
    () => [
      {
        title: `${rb('mentor-u')} Demographics`,
        male: {
          label: `Male ${rb('mentors-u')}`,
          count: `${getPercent(report?.mentorsMale, report?.mentorsActive)}% (${
            report?.mentorsMale || 0
          })`,
        },
        female: {
          label: `Female ${rb('mentors-u')}`,
          count: `${getPercent(
            report?.mentorsFemale,
            report?.mentorsActive,
          )}% (${report?.mentorsFemale || 0})`,
        },
        gender: {
          label: 'Gender not provided',
          count: `${getPercent(
            (report?.mentorsActive || 0) -
              ((report?.mentorsFemale || 0) + (report?.mentorsMale || 0)),
            report?.mentorsActive,
          )}% (${
            (report?.mentorsActive || 0) -
            ((report?.mentorsFemale || 0) + (report?.mentorsMale || 0))
          })`,
        },
        white: {
          label: `White ${rb('mentors')}`,
          count: `${getPercent(
            report?.mentorsWhite,
            report?.mentorsActive,
          )}% (${report?.mentorsWhite || 0})`,
        },
        nonWhite: {
          label: `Non-white ${rb('mentors')}`,
          count: `${getPercent(
            report?.mentorsNonWhite,
            report?.mentorsActive,
          )}% (${report?.mentorsNonWhite || 0})`,
        },
        race: {
          label: 'Race not provided',
          count: `${getPercent(
            (report?.mentorsActive || 0) -
              ((report?.mentorsWhite || 0) + (report?.mentorsNonWhite || 0)),
            report?.mentorsActive,
          )}% (${
            (report?.mentorsActive || 0) -
            ((report?.mentorsWhite || 0) + (report?.mentorsNonWhite || 0))
          })`,
        },
        femaleNonWhite: {
          label: `Female + non-white male ${rb('mentors')}`,
          count: `${getPercent(
            (report?.mentorsActive || 0) - (report?.mentorsWhiteMale || 0),
            report?.mentorsActive,
          )}% (${
            (report?.mentorsActive || 0) - (report?.mentorsWhiteMale || 0)
          })`,
        },
        whiteMale: {
          label: `White male ${rb('mentors')}`,
          count: `${getPercent(
            report?.mentorsWhiteMale,
            report?.mentorsActive,
          )}% (${report?.mentorsWhiteMale || 0})`,
        },
      },
      {
        title: 'Founder Demographics',
        male: {
          label: 'Male Founders',
          count: `${getPercent(
            report?.foundersMale,
            report?.foundersActive,
          )}% (${report?.foundersMale || 0})`,
        },
        female: {
          label: 'Female Founders',
          count: `${getPercent(
            report?.foundersFemale,
            report?.foundersActive,
          )}% (${report?.foundersFemale || 0})`,
        },
        gender: {
          label: 'Gender not provided',
          count: `${getPercent(
            (report?.foundersActive || 0) -
              ((report?.foundersFemale || 0) + (report?.foundersMale || 0)),
            report?.foundersActive,
          )}% (${
            (report?.foundersActive || 0) -
            ((report?.foundersFemale || 0) + (report?.foundersMale || 0))
          })`,
        },
        white: {
          label: 'White founders',
          count: `${getPercent(
            report?.foundersWhite,
            report?.foundersActive,
          )}% (${report?.foundersWhite || 0})`,
        },
        nonWhite: {
          label: 'Non-white founders',
          count: `${getPercent(
            report?.foundersNonWhite,
            report?.foundersActive,
          )}% (${report?.foundersNonWhite || 0})`,
        },
        race: {
          label: 'Race not provided',
          count: `${getPercent(
            (report?.foundersActive || 0) -
              ((report?.foundersWhite || 0) + (report?.foundersNonWhite || 0)),
            report?.foundersActive,
          )}% (${
            (report?.foundersActive || 0) -
            ((report?.foundersWhite || 0) + (report?.foundersNonWhite || 0))
          })`,
        },
        femaleNonWhite: {
          label: 'Female + non-white male founders',
          count: `${getPercent(
            (report?.foundersActive || 0) - (report?.foundersWhiteMale || 0),
            report?.foundersActive,
          )}% (${
            (report?.foundersActive || 0) - (report?.foundersWhiteMale || 0)
          })`,
        },
        whiteMale: {
          label: 'White male founders',
          count: `${getPercent(
            report?.foundersWhiteMale,
            report?.foundersActive,
          )}% (${report?.foundersWhiteMale || 0})`,
        },
      },
    ],
    [report, rb],
  );

  const handleOnLoadList = useCallback(
    (
      list: any,
      entity: 'mentor' | 'venture',
      type: 'new' | 'archived' | 'applicants',
    ) => {
      return setLoadedList((prevList) => ({
        ...prevList,
        [entity]: {
          ...prevList[entity],
          [type]: list.map((item: any) => {
            if (entity === 'mentor') {
              return `${item.firstName} ${item.lastName}`;
            }
            return item.ventureName;
          }),
        },
      }));
    },
    [],
  );

  const loadYearSummary = useCallback(async (loadedReport: GeneralReport) => {
    try {
      const currentYear = getYearFromDate(new Date(loadedReport.creationDate));
      const loadedSummary = await reportsAPI.getGeneralReportYear(currentYear);
      setYearSummary(loadedSummary);
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (!!report) {
      loadYearSummary(report);
    }
  }, [report, loadYearSummary]);

  if (loading) {
    return (
      <div className={classes.contentEmpty}>
        <CircularProgress size={40} color='primary' />
      </div>
    );
  }

  return (
    <div
      data-testid='venture-progress-report-container'
      className={cn({
        [classes.printFirefox]: isFirefox,
      })}>
      {!!report ? (
        <div data-testid='report-container-list'>
          <div className={classes.border}>
            <div className={classes.title}>
              <div>
                <Typography variant='h5'>{user?.programName}</Typography>
              </div>
              <div className={classes.logoContent}>
                <Text variant='normal' className={classes.confidentialText}>
                  Confidential. Not for Distribution
                </Text>

                {paramsReport.includeLogo && logo && (
                  <span
                    className={classes.logo}
                    data-testid='venture-progress-report-include-logo'>
                    <LogoImage
                      className={classes.image}
                      src={logo}
                      alt={user?.programName}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.reportDate}>
            <Typography variant='h4'>
              {currentMonth} {rb('mentors-u')} and Ventures Update
            </Typography>
          </div>
          {summary.map((summaryItem) => {
            return (
              <div key={summaryItem.total.label} className={classes.summary}>
                <Text bold className={classes.summary_item_title}>
                  <Text className={classes.summary_item_label}>
                    {summaryItem.total.label}
                  </Text>
                  <Text>{summaryItem.total.count}</Text>
                </Text>
                {!!summaryItem.new && (
                  <Text variant='normal'>
                    {summaryItem.new.count ? (
                      <GeneralReportDetailsPopup
                        loadList={summaryItem.new.link}
                        list={
                          loadedList[summaryItem.alias as 'mentor' | 'venture']
                            ?.new
                        }
                        onLoaded={(list) =>
                          handleOnLoadList(
                            list,
                            summaryItem.alias as 'mentor' | 'venture',
                            'new',
                          )
                        }
                        className={cn(
                          classes.summary_item,
                          classes.summary_link,
                        )}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.new.label}
                        </span>
                        <span>{summaryItem.new.count}</span>
                      </GeneralReportDetailsPopup>
                    ) : (
                      <span className={classes.summary_item}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.new.label}
                        </span>
                        <span>{summaryItem.new.count}</span>
                      </span>
                    )}
                  </Text>
                )}
                {!!summaryItem.archived && (
                  <Text variant='normal'>
                    {summaryItem.archived.count ? (
                      <GeneralReportDetailsPopup
                        loadList={summaryItem.archived.link}
                        list={
                          loadedList[summaryItem.alias as 'mentor' | 'venture']
                            ?.archived
                        }
                        onLoaded={(list) =>
                          handleOnLoadList(
                            list,
                            summaryItem.alias as 'mentor' | 'venture',
                            'archived',
                          )
                        }
                        className={cn(
                          classes.summary_item,
                          classes.summary_link,
                        )}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.archived.label}
                        </span>
                        <span>{summaryItem.archived.count}</span>
                      </GeneralReportDetailsPopup>
                    ) : (
                      <span className={classes.summary_item}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.archived.label}
                        </span>
                        <span>{summaryItem.archived.count}</span>
                      </span>
                    )}
                  </Text>
                )}
                {!!summaryItem.applicants && (
                  <Text variant='normal'>
                    {summaryItem.applicants.count ? (
                      <GeneralReportDetailsPopup
                        loadList={summaryItem.applicants.link}
                        list={
                          loadedList[summaryItem.alias as 'mentor' | 'venture']
                            ?.applicants
                        }
                        onLoaded={(list) =>
                          handleOnLoadList(
                            list,
                            summaryItem.alias as 'mentor' | 'venture',
                            'applicants',
                          )
                        }
                        className={cn(
                          classes.summary_item,
                          classes.summary_link,
                        )}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.applicants.label}
                        </span>
                        <span>{summaryItem.applicants.count}</span>
                      </GeneralReportDetailsPopup>
                    ) : (
                      <span className={classes.summary_item}>
                        <span className={classes.summary_item_label}>
                          {summaryItem.applicants.label}
                        </span>
                        <span>{summaryItem.applicants.count}</span>
                      </span>
                    )}
                  </Text>
                )}
              </div>
            );
          })}

          {demographics.map((demographic) => (
            <div className={classes.demographic} key={demographic.title}>
              <Typography variant='h4'>{demographic.title}</Typography>
              <Text
                className={cn(
                  classes.demographic_item_block,
                  classes.demographic_item,
                )}
                variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.male.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.male.count}
                </span>
              </Text>
              <Text className={classes.demographic_item} variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.female.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.female.count}
                </span>
              </Text>
              <Text className={classes.demographic_item} variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.gender.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.gender.count}
                </span>
              </Text>
              <Text
                className={cn(
                  classes.demographic_item_block,
                  classes.demographic_item,
                )}
                variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.white.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.white.count}
                </span>
              </Text>
              <Text className={classes.demographic_item} variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.nonWhite.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.nonWhite.count}
                </span>
              </Text>
              <Text className={classes.demographic_item} variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.race.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.race.count}
                </span>
              </Text>
              <Text
                className={cn(
                  classes.demographic_item_block,
                  classes.demographic_item,
                )}
                variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.femaleNonWhite.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.femaleNonWhite.count}
                </span>
              </Text>
              <Text className={classes.demographic_item} variant='normal'>
                <span className={classes.demographic_item_label}>
                  {demographic.whiteMale.label}
                </span>
                <span className={classes.demographic_item_count}>
                  {demographic.whiteMale.count}
                </span>
              </Text>
            </div>
          ))}
          {yearSummary.length > 0 && paramsReport.includeSummary && (
            <YearSummary
              year={getYearGeneralReport(new Date(report.creationDate))}
              summary={yearSummary}
            />
          )}
        </div>
      ) : (
        <div
          className={classes.contentEmpty}
          data-testid='report-empty-container'>
          <Text variant='normal' className={classes.contentEmptyText}>
            Unfortunately we were not able to extract analytical data for the
            dates you selected. Please get in touch with program admin or
            support@tractionfive.com if you believe this is an unexpected
            result.
          </Text>
        </div>
      )}
    </div>
  );
}

function YearSummary({
  year,
  summary,
}: {
  year: string;
  summary: GeneralReport[];
}) {
  const classes = useStyles();
  const emptyArrayForMonths = new Array(12).fill(undefined);
  const months = emptyArrayForMonths.map((_, itemIndex) =>
    getMonthByIndex(itemIndex),
  );
  const { rb } = useResourceBundles();

  const accumulatedSummary = emptyArrayForMonths.map((_, monthIndex) => {
    return summary.find(
      (summaryItem: GeneralReport) =>
        monthIndex ===
        getGeneralReportMonthIndex(new Date(summaryItem.creationDate)),
    );
  });

  return (
    <div className={classes.summaryBlock}>
      <Typography variant='h4'>Available Summary Data for {year}</Typography>
      <table className={classes.summaryTable}>
        <tbody>
          <tr>
            <td />
            {months.map((month) => (
              <td key={month}>
                <Text variant='normal' bold>
                  {month}
                </Text>
              </td>
            ))}
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal' bold>
                {rb('mentors-u')}
              </Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.mentorsActive}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Joined</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.mentorsJoined}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Left</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.mentorsLeft}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Male (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsMale,
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Female (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsFemale,
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Gender not provided (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsActive -
                      (item.mentorsFemale + item.mentorsMale),
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Non-white (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsNonWhite,
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>White male (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsWhiteMale,
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Race not provided (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.mentorsActive -
                      (item.mentorsWhite + item.mentorsNonWhite),
                    item.mentorsActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Applications</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.mentorsApplicants}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td />
            {accumulatedSummary.map((_, itemIndex) => (
              <td key={itemIndex} />
            ))}
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal' bold>
                Ventures
              </Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.venturesActive}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Joined</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.venturesJoined}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Left</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.venturesLeft}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Applications</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.venturesApplicants}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td />
            {accumulatedSummary.map((_, itemIndex) => (
              <td key={itemIndex} />
            ))}
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal' bold>
                Founders
              </Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.foundersActive}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Male (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersMale,
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Female (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersFemale,
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Gender not provided (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersActive -
                      (item.foundersFemale + item.foundersMale),
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Non-white (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersNonWhite,
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>White male (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersWhiteMale,
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className={cn(classes.cellLabel, classes.cellData)}>
              <Text variant='normal'>Race not provided (%)</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{`${getPercent(
                    item.foundersActive -
                      (item.foundersWhite + item.foundersNonWhite),
                    item.foundersActive,
                  )}%`}</Text>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ReportsGeneralScreen;
