import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer } from 'react';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { enroll } from '../../../api/CommunityMembers';
import { useQuery } from '../../../hooks/useQuery';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { init, State } from './types/State';

export function useEnroll(): [State, Dispatch<Actions.Actions>] {
  const key = useQuery().get('key') ?? undefined;
  const [s, dispatch] = useReducer(reducer, init(key));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (s.type === 'Sending') {
      const s$ = from(
        enroll(s.payload.tenantId, s.payload.key, s.payload.password.value),
      )
        .pipe(
          map(Actions.sendSuccess),
          catchError((e: AxiosError) =>
            of(Actions.sendError(e.response?.data.message)),
          ),
        )
        .subscribe(dispatch);

      return () => s$.unsubscribe();
    }

    if (s.type === 'SendError') {
      enqueueSnackbar(s.payload.message, {
        variant: 'error',
        id: 'send-error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type, dispatch]);

  return [s, dispatch];
}
