import * as V from '../../../utils/FormValue';
import { invalid, isVerifying, valid } from '../../../utils/FormValue';
import { Email } from '../../../utils/String/Email';
import { Sentence } from '../../../utils/String/Sentence';
import { unreachableError } from '../../../utils/unreachableError';
import { isValidAdvisor } from '../common/ValidAdvisor';
import { Item, setValue, Submitted } from '../common/types/Item';
import { isValid, validateItem } from '../common/utils';
import { validators } from '../common/validators';
import { Actions } from './types/Actions';
import * as State from './types/State';
import { advisorToReady, validateAdvisor } from './utils';

function setItem(s: State.Editable, item: Item): State.Editable {
  switch (s.type) {
    case 'Uploading':
      return State.uploading({ ...s.payload, item });
    case 'Submitted':
      return State.submitted({ ...s.payload, item: item as Submitted });
    case 'Ready':
    case 'SaveError':
    case 'Edited':
    case 'SaveSuccess':
    case 'VerificationError':
      return State.edited({ ...s.payload, item });
  }
}

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s.type === 'RemoveAndUpdate'
        ? State.removeAndUpdateError({ ...s.payload, message: a.payload })
        : s.type === 'UnloadRemoveAnUpdate'
        ? State.unloadRemoveAnUpdateError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading' || s.type === 'RemoveAndUpdate'
        ? State.ready(
            advisorToReady(
              a.payload.advisor,
              a.payload.specializations,
              s.payload.timeZone,
            ),
          )
        : s.type === 'UnloadRemoveAnUpdate'
        ? State.unloading(s.payload)
        : s;
    case 'SetValue':
      return State.isEditable(s)
        ? setItem(
            s,
            setValue<typeof a.payload.key>(
              a.payload.key,
              // @ts-expect-error, need to fix this
              validators[a.payload.key](a.payload.value),
              s.payload.item,
            ),
          )
        : s;
    case 'Toggle':
      return State.isEditable(s) &&
        s.payload.item[a.payload].__typeName === 'initial'
        ? setItem(
            s,
            setValue<typeof a.payload>(
              a.payload,
              // @ts-expect-error, need to fix this
              validators[a.payload](s.payload.item[a.payload].value),
              s.payload.item,
            ),
          )
        : s;
    case 'Upload':
      return State.isEditable(s)
        ? State.uploading({
            ...s.payload,
            logo: a.payload,
          })
        : s;
    case 'UploadSuccess':
      return s.type === 'Uploading'
        ? State.edited({
            ...s.payload,
            item: setValue('logo', validators.logo(a.payload), s.payload.item),
          })
        : s;
    case 'UploadErr':
      return s.type === 'Uploading'
        ? State.edited({
            ...s.payload,
            item: setValue(
              'logo',
              V.invalid(a.payload, undefined),
              s.payload.item,
            ),
          })
        : s;
    case 'Save': {
      if (State.isSubmittable(s)) {
        if (s.type === 'Submitted') {
          return isValid(s.payload.item)
            ? isValidAdvisor(s.payload.advisor)
              ? State.saving({
                  ...s.payload,
                  advisor: s.payload.advisor,
                  item: s.payload.item,
                })
              : State.invalidAvailabilities({
                  ...s.payload,
                  item: s.payload.item,
                })
            : s;
        } else {
          const item = validateItem(s.payload.item);
          return isValid(item)
            ? State.isUnloadDialog(s)
              ? State.unloadSaving({
                  ...s.payload,
                  advisor: s.payload.advisor,
                  item,
                })
              : isValidAdvisor(s.payload.advisor)
              ? State.saving({ ...s.payload, advisor: s.payload.advisor, item })
              : State.invalidAvailabilities({
                  ...s.payload,
                  item,
                })
            : State.submitted({
                ...s.payload,
                item,
              });
        }
      }

      return s;
    }
    case 'SaveError': {
      if (State.isSaving(s)) {
        switch (s.type) {
          case 'Saving':
            return State.saveError({ ...s.payload, message: a.payload });
          case 'RemoveAndUpdate':
            return State.removeAndUpdateError({
              ...s.payload,
              message: a.payload,
            });
          case 'UnloadSaving':
            return State.unloadError({ ...s.payload, message: a.payload });
          case 'UnloadRemoveAnUpdate':
            return State.unloadRemoveAnUpdateError({
              ...s.payload,
              message: a.payload,
            });
          default:
            unreachableError(s);
        }
      }

      return s;
    }
    case 'SaveSuccess': {
      if (State.isSaving(s)) {
        switch (s.type) {
          case 'Saving':
          case 'RemoveAndUpdate':
            return State.saveSuccess({
              ...s.payload,
              advisor: validateAdvisor(s.payload.advisor),
              initialSpecializations: s.payload.item.specializations.value,
            });
          case 'UnloadSaving':
          case 'UnloadRemoveAnUpdate':
            return State.unloading(s.payload);
          default:
            unreachableError(s);
        }
      }

      return s;
    }
    case 'EmailValidationError':
    case 'NameValidationError':
      return State.isEditable(s)
        ? State.verificationError({
            ...s.payload,
            message: a.payload,
          })
        : s;
    case 'EmailValidation':
      return State.isEditable(s) && isVerifying(s.payload.item.email)
        ? ({
            ...s,
            payload: {
              ...s.payload,
              item: {
                ...s.payload.item,
                email: a.payload
                  ? valid(s.payload.item.email.value as Email)
                  : invalid(
                      'Email address is already used',
                      s.payload.item.email.value,
                    ),
              },
            },
          } as State.State)
        : s;
    case 'NameValidation':
      return State.isEditable(s) &&
        [s.payload.item.firstName, s.payload.item.lastName].some(isVerifying)
        ? ({
            ...s,
            payload: {
              ...s.payload,
              item: {
                ...s.payload.item,
                firstName: a.payload
                  ? valid(s.payload.item.firstName.value as Sentence<250>)
                  : invalid('Required', s.payload.item.firstName.value),
                lastName: a.payload
                  ? valid(s.payload.item.lastName.value as Sentence<250>)
                  : invalid('Required', s.payload.item.lastName.value),
              },
            },
          } as State.State)
        : s;
    case 'Unload':
      if (State.isLoaded(s) && State.isEdited(s)) {
        const item = validateItem(s.payload.item);
        return isValid(item)
          ? isValidAdvisor(s.payload.advisor)
            ? State.unloadConfirm({
                ...s.payload,
                advisor: s.payload.advisor,
                item,
              })
            : State.unloadInvalidAvailabilities({
                ...s.payload,
                item,
              })
          : State.unloadSubmitted({
              ...s.payload,
              item,
            });
      }

      return s;
    case 'Confirm':
      return State.isUnloadDialog(s) ? State.unloading(s.payload) : s;
    case 'Decline':
      return State.isDialog(s)
        ? State.edited({
            ...s.payload,
          })
        : s;
    case 'RemoveAndUpdate': {
      return s.type === 'InvalidAvailabilities'
        ? State.removeAndUpdate(s.payload)
        : s.type === 'UnloadInvalidAvailabilities'
        ? State.unloadRemoveAnUpdate(s.payload)
        : s;
    }
  }
}
