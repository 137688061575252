import { makeStyles, Typography } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TenantId } from '../../../../api/tenants/Tenant';
import { TestId } from '../../../../components/Testing/TestId';
import { RoundedSearch } from '../../../../components/common/RoundedSearch';
import { RoundedTag } from '../../../../components/common/RoundedTag';
import { getRoutePath, Pages } from '../../../../router/constants';
import {
  COLORS,
  INTER_FONT_FAMILY,
  MONTSERRAT_FONT_FAMILY,
} from '../../../../theme/variables';
import { PageWrapper } from '../common/PageWrapper';
import { useLanding } from './useLanding';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: 0,

    '& > .content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '150px',
      paddingBottom: '42px',
      position: 'relative',
      overflow: 'hidden',
      maxWidth: 'unset',
      background: '#fff',

      [theme.breakpoints.down('xs')]: {
        paddingTop: '50px',
      },

      '&::before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        width: '150%',
        height: '150%',
        top: '-75%',
        left: '-75%',
        background:
          'radial-gradient(50% 50% at 50% 50%, rgba(16, 97, 255, 0.16) 0%, rgba(51, 126, 255, 0) 100%)',
        zIndex: 0,
      },

      '&::after': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        width: '75%',
        height: '100%',
        right: '-25%',
        bottom: '-25%',
        zIndex: 0,
        background:
          'radial-gradient(50% 50% at 50% 50%, rgba(16, 83, 255, 0.08) 0%, rgba(51, 126, 255, 0) 100%)',
      },
    },
  },
  title: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    color: COLORS.COLOR_BLUE_BASE,
    fontSize: '64px',
    lineHeight: '2.5rem',
    fontWeight: 800,
    textAlign: 'center',
    marginBottom: '25px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '42px',
    },
  },
  subTitle: {
    fontFamily: INTER_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_BASE,
    opacity: 0.5,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '25px',
  },
  search: {
    maxWidth: '100%',
    width: '450px',
    marginBottom: '90px',
    zIndex: 1,
  },
  browseAll: {
    marginBottom: '45px',
  },
  tags: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '900px',

    '& > a': {
      flex: '0 1 fit-content',
    },
  },
}));

export function Landing(): ReactElement {
  const { push } = useHistory();
  const { tenantId } = useParams<{ tenantId: TenantId }>();
  const [search, setSearch] = useState('');
  const { tags } = useLanding(tenantId);
  const classes = useStyles();

  return (
    <PageWrapper tenantId={tenantId} className={classes.root}>
      <Typography component='h1' className={classes.title}>
        Slice of Mentorship
      </Typography>
      <Typography component='h2' className={classes.subTitle}>
        Connect with experts and peers in our community to expand your network,
        seek guidance, and offer support.
      </Typography>
      <TestId testId={'advisor-search'}>
        <RoundedSearch
          className={classes.search}
          value={search}
          onChange={setSearch}
          onSubmit={() =>
            search &&
            push(
              `${getRoutePath(Pages.PUBLIC_ADVISORS, {
                tenantId,
              })}?search=${search}`,
            )
          }
          placeholder='Search by Topic or Name'
        />
      </TestId>
      <TestId testId={'tags-list'}>
        <div className={classes.tags}>
          <TestId testId={'browse-all-tag'}>
            <RoundedTag
              title='Browse all'
              to={`${getRoutePath(Pages.PUBLIC_ADVISORS, { tenantId })}`}
            />
          </TestId>
          {tags.map((tag) => (
            <TestId testId={'tag-item'}>
              <RoundedTag
                title={tag.title}
                to={`${getRoutePath(Pages.PUBLIC_ADVISORS, {
                  tenantId,
                })}?specialization=${tag.id}`}
              />
            </TestId>
          ))}
        </div>
      </TestId>
    </PageWrapper>
  );
}
