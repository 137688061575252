import {
  Typography,
  IconButton,
  DialogContent,
  makeStyles,
  DialogActions,
} from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { COLORS } from '../../theme/variables';
import Text from './text';

interface ContentRendererProps {
  handleClose: () => void;
}

interface DialogTitleProps {
  id?: string;
  children: React.ReactNode;
  caption?: string;
  onClose?: (event: React.MouseEvent) => any;
  className?: string;
}

interface ModalProps {
  title: ReactElement | string;
  caption?: string;
  className?: string;
  classNameContent?: string;
  width?: number;
  overflowInitial?: boolean;
  contentRenderer: (props: ContentRendererProps) => React.ReactNode;
  actions?: ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const useTitleStyles = makeStyles({
  container: {
    padding: '20px 40px 0 32px',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 8,
  },
});

export const DialogTitle = ({ className, ...props }: DialogTitleProps) => {
  const { children, onClose, caption, ...other } = props;
  const classes = useTitleStyles();

  return (
    <MuiDialogTitle
      disableTypography
      className={classNames(classes.container, className)}
      {...other}>
      <div>
        <Typography variant='h4'>{children}</Typography>
        {!!caption && (
          <Text variant='normal' color={COLORS.COLOR_TEXT_LIGHTENED_20}>
            {caption}
          </Text>
        )}
      </div>
      {onClose ? (
        <IconButton
          className={classes.closeButton}
          aria-label='close'
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const useModalStyles = makeStyles((theme) => ({
  content: {
    padding: '20px',

    [theme.breakpoints.up('xs')]: {
      padding: '40px 32px 32px',
    },
  },
  removeSpacing: {
    paddingBottom: 0,
  },
  overflowInitial: {
    overflow: 'initial',
  },
}));

export function Dialog({
  title,
  caption,
  width,
  className,
  classNameContent,
  overflowInitial = false,
  contentRenderer,
  open,
  setOpen,
  actions,
}: ModalProps) {
  const classes = useModalStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      className={className}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        elevation: 3,
        style: {
          width,
          maxWidth: width,
          overflow: overflowInitial ? 'initial' : 'auto',
        },
      }}>
      <DialogTitle
        id='alert-dialog-title'
        caption={caption}
        onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent
        className={cn(classes.content, classNameContent, {
          [classes.overflowInitial]: overflowInitial,
          [classes.removeSpacing]: !!actions,
        })}>
        {contentRenderer({ handleClose })}
      </DialogContent>
      {actions ? <DialogActions disableSpacing>{actions}</DialogActions> : null}
    </MuiDialog>
  );
}

export default Dialog;
