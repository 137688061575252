import { useContext } from 'react';
import { useLocation } from 'react-router';
import { RouterContext } from '../contexts/router-context';

export function useCurrentPath(): string {
  const { pathname, search } = useLocation();

  return `${pathname}${search}`;
}

export function useParentPath() {
  return useContext(RouterContext).parentPath;
}

export function usePrevPath() {
  return useContext(RouterContext).prevPath;
}
