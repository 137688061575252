import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import React, { MouseEventHandler, SyntheticEvent } from 'react';
import { COLORS } from '../../theme/variables';
import Text from './text';

interface ContentRendererProps {
  handleClose: () => any;
}

interface ButtonRendererProps {
  onClick: MouseEventHandler;
}

interface DialogTitleProps {
  id?: string;
  children: React.ReactNode;
  caption?: string;
  onClose: (event: React.MouseEvent) => any;
  className?: string;
}

interface ModalProps {
  title: string;
  caption?: string;
  className?: string;
  classNameContent?: string;
  classNameTitle?: string;
  width?: number;
  height?: number;
  overflowInitial?: boolean;
  contentRenderer: (props: ContentRendererProps) => React.ReactNode;
  buttonRenderer: (props: ButtonRendererProps) => React.ReactNode;
  titleIcon?: React.ReactNode;
  noPadding?: boolean;
  margin?: string;
}

const useTitleStyles = makeStyles({
  container: {
    padding: '20px 40px 0 32px',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 8,
  },
});

export const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, caption, ...other } = props;
  const classes = useTitleStyles();

  return (
    <MuiDialogTitle
      disableTypography
      className={cn(classes.container, props.className)}
      {...other}>
      <div>
        <Typography variant='h4'>{children}</Typography>
        {!!caption && (
          <Text variant='normal' color={COLORS.COLOR_TEXT_LIGHTENED_20}>
            {caption}
          </Text>
        )}
      </div>
      {onClose ? (
        <IconButton
          className={classes.closeButton}
          aria-label='close'
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const useModalStyles = makeStyles({
  container: {},
  content: {
    padding: '40px 32px 32px',
  },
  noPadding: {
    padding: 0,
  },
  overflowInitial: {
    overflow: 'initial',
  },
});

export default function Modal({
  title,
  caption,
  width,
  height,
  className,
  classNameContent,
  classNameTitle,
  overflowInitial = false,
  contentRenderer = () => <></>,
  buttonRenderer = () => <></>,
  titleIcon,
  noPadding = false,
  margin,
}: ModalProps) {
  const classes = useModalStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {buttonRenderer({
        onClick: handleOpen,
      })}
      <Dialog
        className={className}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-dialog-title'
        PaperProps={{
          elevation: 3,
          style: {
            width,
            height,
            maxWidth: width,
            overflow: overflowInitial ? 'initial' : 'auto',
            margin,
          },
        }}>
        <DialogTitle
          id='customized-dialog-title'
          caption={caption}
          onClose={handleClose}
          className={classNameTitle}>
          {title}
          {titleIcon}
        </DialogTitle>
        <DialogContent
          className={cn(classes.content, classNameContent, {
            [classes.overflowInitial]: overflowInitial,
            [classes.noPadding]: noPadding,
          })}>
          {contentRenderer({ handleClose })}
        </DialogContent>
      </Dialog>
    </div>
  );
}
