import * as State from './types/State';
import { Actions } from './types/Actions';

export const reducer = (s: State.State, a: Actions): State.State => {
  switch (a.type) {
    case 'FetchFail':
      return s.type === 'Loading'
        ? State.loadErr({ ...s.payload, message: a.payload })
        : s;
    case 'FetchSuccess':
      return s.type === 'Loading'
        ? State.ready({ ...s.payload, answers: a.payload })
        : s;
    case 'Remove':
      return s.type === 'Ready'
        ? State.removeConfirmation({ ...s.payload, answerId: a.payload })
        : s;
    case 'RemoveDecline':
      return s.type === 'RemoveConfirmation' ? State.ready(s.payload) : s;
    case 'RemoveApprove':
      return s.type === 'RemoveConfirmation' ? State.removing(s.payload) : s;
    case 'RemoveFail':
      return s.type === 'Removing'
        ? State.removeErr({ ...s.payload, message: a.payload })
        : s;
    case 'RemoveSuccess':
      return s.type === 'Removing'
        ? State.removeSuccess({
            ...s.payload,
            message: 'Answer was removed succesfully',
            answers: s.payload.answers.filter(
              (i) => i.id !== s.payload.answerId
            )
          })
        : s;
    case 'Reset':
      return s.type === 'RemoveErr' || s.type === 'RemoveSuccess'
        ? State.ready(s.payload)
        : s;
  }
};
