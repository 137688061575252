import { parse as parseQuery } from 'query-string';
import _pick from 'lodash/pick';
import { TenantId } from '../../api/tenants/Tenant';

export function getTenantId(key: string): TenantId | undefined {
  try {
    const id = atob(key).split('|')[0] as TenantId;

    return id ? id : undefined;
  } catch {
    return undefined;
  }
}

export const getTenantIdFromParams = (path: string): TenantId | undefined => {
  const parts = path.split('/');
  const index = parts.indexOf('tenants');
  const id = index !== -1 ? parts[index + 1] : undefined;

  return id && id.length > 30 ? (id as TenantId) : undefined;
};

export function parseKey(search: string): string | undefined {
  const { key } = _pick(parseQuery(search), ['key']);

  return typeof key === 'string' ? key : undefined;
}
