import { StringDate } from '../utils/date/StringDate';
import { axiosRequest } from './instance';

export interface Announcement {
  id: string;
  content: string;
  creationDate: StringDate;
  valid: boolean;
}

const announcementsAPI = {
  async getAnnouncements(): Promise<Announcement[]> {
    try {
      const res = await axiosRequest.get<Announcement[]>({
        url: '/announcements',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
};

export default announcementsAPI;
