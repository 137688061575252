export const CLASS_TRACKING = {
  SIDE_NAVIGATION_CLASS: 'SideNavigationClass',
  DASHBOARD_QUICK_CREATE: 'DashboardQuickCreate',
  DASHBOARD_LINK: 'DashboardLink',
  MENTOR_LINK: 'MentorLink',
  ENTITY_ACTION: 'EntityAction',
  LIST_QUICK_ACTION: 'ListQuickAction',
  SESSION_DETAILED_LIST: 'SessionDetailedList',
  SESSION_CALENDAR_LIST: 'SessionCalendarList',
  SESSION_CALENDAR_MONTH_LIST: 'SessionCalendarMonthList',
  FILTER_ELEMENT: 'FilterElement',
  INTERNAL_ACTION: 'InternalAction'
};
