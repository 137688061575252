import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

function PageLoader() {
  const classes = useStyles();

  return (
    <div data-testid='page-loader' className={classes.container}>
      <CircularProgress size={36} color='primary' />
    </div>
  );
}

export default PageLoader;
