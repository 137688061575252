import * as State from './types/State';
import { Actions } from './types/Actions';
import {
  initial,
  Invalid,
  invalid,
  isValid,
  Valid,
  valid,
} from '../../../../../utils/FormValue';
import * as Sentence from '../../../../../utils/String/Sentence';
import * as Email from '../../../../../utils/String/Email';
import { apply } from '../../../../../utils/Either';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Open':
      return s.type === 'LoggedOut'
        ? State.ready({
            username: initial(undefined),
            password: initial(undefined),
            showPassword: false,
          })
        : s;
    case 'Close':
      return State.canClose(s) ? State.loggedOut({}) : s;
    case 'SetName': {
      if (State.isEditable(s)) {
        const username = Email.fromString(a.payload);

        switch (s.type) {
          case 'Ready':
          case 'LoginError':
            return State.ready({
              ...s.payload,
              username: username
                ? valid(username)
                : invalid('required', a.payload),
            });
          case 'ResetError':
          case 'ResetPassword':
            return State.resetPassword({
              ...s.payload,
              username: username
                ? valid(username)
                : invalid('required', a.payload),
            });
        }
      }
      return s;
    }
    case 'SetPassword': {
      return State.isLoginEditable(s)
        ? State.ready({
            ...s.payload,
            password: apply<
              string,
              Sentence.Sentence<100>,
              Invalid<'required', string>,
              Valid<Sentence.Sentence<100>>
            >(
              () => invalid('required', a.payload),
              valid,
              Sentence.fromString(100)(a.payload),
            ),
          })
        : s;
    }
    case 'TogglePassword':
      return State.isLoginEditable(s)
        ? State.ready({ ...s.payload, showPassword: !s.payload.showPassword })
        : s;
    case 'Login':
      return State.isLoginEditable(s) &&
        isValid(s.payload.username) &&
        isValid(s.payload.password)
        ? State.loggingIn({
            ...s.payload,
            username: s.payload.username,
            password: s.payload.password,
          })
        : s;
    case 'LoginError':
      return s.type === 'LoggingIn'
        ? State.loginError({ ...s.payload, message: a.payload })
        : s;
    case 'UserLoggedIn':
      return State.loggedIn({});
    case 'Logout':
      return s.type === 'LoggedIn' ? State.loggingOut({}) : s;
    case 'UserLoggedOut':
      return State.loggedOut({});
    case 'GoToReset':
      return State.isLoginEditable(s)
        ? State.resetPassword({ username: initial(undefined) })
        : s;
    case 'ResetPassword':
      return State.isResetEditable(s) && isValid(s.payload.username)
        ? State.resetting({ ...s.payload, username: s.payload.username })
        : s;
    case 'ResetError':
      return s.type === 'Resetting'
        ? State.resetError({ ...s.payload, message: a.payload })
        : s;
    case 'ResetSuccess':
      return s.type === 'Resetting'
        ? State.ready({
            username: initial(undefined),
            password: initial(undefined),
            showPassword: false,
          })
        : s;
  }
}
