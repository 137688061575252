import {
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  makeStyles,
} from '@material-ui/core';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import cn from 'classnames';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';

const useStyle = makeStyles({
  label: {
    display: 'flex',
    justifyContent: 'center',
    color: COLORS.COLOR_TEXT_BASE,
    fontSize: '22px',
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 700,
    transition: 'color 0.3s ease-in-out',
  },
  labelError: {
    color: COLORS.COLOR_RED_BASE,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiFormControlLabel-root': {
      margin: '0',
    },
    '& .MuiFormControlLabel-label': {
      display: 'none',
    },
    '& .MuiRadio-colorSecondary': {
      transition: 'color 0.3s ease-in-out',
    },
    '& .bad-mood .MuiButtonBase-root:hover': {
      backgroundColor: COLORS.COLOR_RED_LIGHTENED,
    },
    '& .neutral-mood .MuiButtonBase-root:hover': {
      backgroundColor: COLORS.COLOR_ADDITIONAL_ORANGE_LIGHTENED,
    },
    '& .good-mood .MuiButtonBase-root:hover': {
      backgroundColor: COLORS.COLOR_GREEN_LIGHTENED_45,
    },
    '& .bad-mood .MuiRadio-colorSecondary.Mui-checked': {
      color: COLORS.COLOR_RED_BASE,
    },
    '& .neutral-mood .MuiRadio-colorSecondary.Mui-checked': {
      color: COLORS.COLOR_ADDITIONAL_ORANGE,
    },
    '& .good-mood .MuiRadio-colorSecondary.Mui-checked': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
  icons: {
    width: '100px',
    height: '100px',
  },
});

const MoodInput: FC<FieldRenderProps<number, HTMLElement>> = ({
  input,
  label,
  meta,
}) => {
  const { value, onChange, onBlur, onFocus } = input;
  const { error, submitFailed, touched } = meta;
  const classes = useStyle();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    onBlur(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    onFocus(event);
  };

  return (
    <div>
      <FormLabel
        className={cn(classes.label, {
          [classes.labelError]: error && (submitFailed || touched),
        })}>
        {label}
      </FormLabel>
      <RadioGroup
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.radioGroup}
        onFocus={handleFocus}>
        <FormControlLabel
          value={1}
          className='bad-mood'
          control={
            <Radio
              icon={<SentimentVeryDissatisfied className={classes.icons} />}
              checkedIcon={
                <SentimentVeryDissatisfied className={classes.icons} />
              }
            />
          }
          label='Bad'
        />
        <FormControlLabel
          value={2}
          className='neutral-mood'
          control={
            <Radio
              icon={<SentimentSatisfiedIcon className={classes.icons} />}
              checkedIcon={<SentimentSatisfiedIcon className={classes.icons} />}
            />
          }
          label='Neutral'
        />
        <FormControlLabel
          value={3}
          className='good-mood'
          control={
            <Radio
              icon={<SentimentVerySatisfied className={classes.icons} />}
              checkedIcon={<SentimentVerySatisfied className={classes.icons} />}
            />
          }
          label='Good'
        />
      </RadioGroup>
    </div>
  );
};

export default MoodInput;
