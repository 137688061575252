import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { identity, pipe } from 'ramda';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Field, Form } from 'react-final-form';
import { CohortId } from '../../api/Cohort/types/Cohort';
import { Specialization } from '../../api/specializations';
import { Role } from '../../api/user/Role';
import { VentureDetails } from '../../api/ventures';
import { Status, Venture } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { Address, Name } from '../../types';
import {
  apply,
  Either,
  isLeft,
  left,
  parseObject,
  right,
} from '../../utils/Either';
import {
  NaturalString,
  naturalStringToNatural,
  naturalToNaturalString,
  strToEitherNaturalString,
} from '../../utils/String/NaturalString';
import { fromString, Sentence } from '../../utils/String/Sentence';
import { stringToEitherZip, Zip } from '../../utils/String/Zip';
import { formatDateToRFC } from '../../utils/date';
import {
  PastStringDate,
  strToEitherPastStrDate,
} from '../../utils/date/PastStringDate';
import {
  lengthField,
  REASON_FOR_EXIT,
  STATUSES_VENTURES,
} from '../../utils/form';
import { checkSizeTablet, isMobile } from '../../utils/functions';
import { removeFalsyValues } from '../../utils/object';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  Dialog,
  FormGroup,
  StickyContent,
  Text,
} from '../common';
import { checkVentureForDuplicates } from '../common/field-name-venture';
import {
  FormCheckbox,
  FormDateInput,
  FormNameVenture,
  FormSelect,
  FormSpecializationInput,
  FormVentureTagsInput,
  TextFieldWrapper,
} from './wrappers';

interface VentureDetailsFormProps {
  ventureId?: Venture['id'];
  ventureDetails?: VentureDetails;
  ventureSpecializations?: Specialization['id'][];
  ventureTags?: string[];
  loading?: boolean;
  onSubmit: (parsedFormValues: ParsedFormValues) => Promise<any>;
  isExternal?: boolean;
  cohorts: Array<{
    label: string;
    value: CohortId;
  }>;
}

interface ArchiveFormValues {
  exitReason: string;
  dateOfExit: string;
}

interface ArchiveDialogProps {
  open: boolean;
  initialValues?: ArchiveFormValues;
  setOpen: (value: boolean) => void;
  onSubmit: (values: ArchiveFormValues) => void;
}

interface ActiveDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: () => void;
}

interface FormValues {
  ventureName: Name | string;
  url: Sentence<1024> | string | null;
  dateOfEnrollment: PastStringDate | string;
  dateOfCreation: PastStringDate | string;
  country: string | Sentence<1024> | null;
  state: string | Sentence<1024> | null;
  city: string | Sentence<1024> | null;
  address: string | Sentence<4096> | null;
  zip: string | Zip | null;
  numOfEmployees: NaturalString | string | null;
  description: Sentence<250> | string | null;
  isTech: 'true' | 'false';
  specializations: Specialization['id'][];
  ventureTags: string[];
  dateOfExit: string;
  exitReason: string;
  status: Status;
  cohortId: CohortId | 'no-group';
}

interface ValidFormValues extends FormValues {
  ventureName: Name;
  url: Sentence<1024> | null;
  dateOfEnrollment: PastStringDate;
  dateOfCreation: PastStringDate;
  country: Sentence<1024> | null;
  state: Sentence<1024> | null;
  city: Sentence<1024> | null;
  address: Address | null;
  zip: Zip | null;
  numOfEmployees: NaturalString | null;
  description: Sentence<250> | null;
  isTech: 'true' | 'false';
  specializations: Specialization['id'][];
  ventureTags: string[];
  dateOfExit: string;
  exitReason: string;
  status: Status;
  cohortId: CohortId | 'no-group';
}

function getValidForm(form: FormValues): Either<Errors, ValidFormValues> {
  return parseObject<FormValues, ValidFormValues, Errors>(
    {
      ventureName: (v) => (v ? fromString(256, v) : left('Required')),
      url: (v) => (v ? fromString(1024, v) : right(null)),
      dateOfEnrollment: strToEitherPastStrDate,
      dateOfCreation: strToEitherPastStrDate,
      country: (v) => (v ? fromString(1024, v) : right(null)),
      state: (v) => (v ? fromString(1024, v) : right(null)),
      city: (v) => (v ? fromString(1024, v) : right(null)),
      address: (v) => (v ? fromString(1024, v) : right(null)),
      zip: (v) => (v ? stringToEitherZip(v) : right(null)),
      numOfEmployees: (n) =>
        n === null || n === undefined
          ? right(null)
          : strToEitherNaturalString(n),
      description: (v) => (v ? fromString(250, v) : right(null)),
      isTech: right,
      specializations: right,
      ventureTags: right,
      dateOfExit: right,
      exitReason: right,
      status: right,
      cohortId: right,
    },
    form,
  );
}

export interface ParsedFormValues {
  values: VentureDetails;
  specializations: Specialization['id'][];
  ventureTags: string[];
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up(850)]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  descriptionField: {
    minHeight: 64,
  },
  checkbox: {
    paddingLeft: 0,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    gap: 16,
  },
}));

const validateForm = (values: FormValues) => {
  const errors = getValidForm(values);
  return isLeft(errors) ? errors.value : {};
};

function getInitialValues(
  ventureDetails?: VentureDetails,
  specializations: Specialization['id'][] = [],
  ventureTags: string[] = [],
): FormValues {
  const country = ventureDetails?.country || '';

  const nonFiltredInitialData: FormValues = {
    ventureName: ventureDetails?.ventureName || '',
    url: ventureDetails?.url || '',
    dateOfEnrollment:
      ventureDetails?.dateOfEnrollment || formatDateToRFC(new Date()),
    dateOfCreation:
      ventureDetails?.dateOfCreation || formatDateToRFC(new Date()),
    country,
    state: ventureDetails?.state || '',
    city: ventureDetails?.city || '',
    address: ventureDetails?.address || '',
    zip: ventureDetails?.zip || '',
    numOfEmployees:
      ventureDetails?.numOfEmployees || ventureDetails?.numOfEmployees === 0
        ? naturalToNaturalString(ventureDetails?.numOfEmployees)
        : '',
    description: ventureDetails?.description || '',
    isTech:
      typeof ventureDetails?.isTech === 'boolean' && ventureDetails?.isTech
        ? 'true'
        : 'false',
    specializations,
    ventureTags,
    dateOfExit: ventureDetails?.dateOfExit || '',
    exitReason: ventureDetails?.exitReason || '',
    status: ventureDetails?.status || STATUSES_VENTURES[0].value,
    cohortId: ventureDetails?.cohortId || 'no-group',
  };

  return removeFalsyValues(nonFiltredInitialData);
}

function getParsedValues(formValues: ValidFormValues): ParsedFormValues {
  return {
    values: {
      ventureName: formValues.ventureName,
      url: formValues.url,
      dateOfEnrollment: formValues.dateOfEnrollment,
      dateOfCreation: formValues.dateOfCreation,
      country: formValues.country || null,
      state: formValues.state || null,
      city: formValues.city || null,
      address: formValues.address || null,
      zip: formValues.zip || null,
      numOfEmployees: formValues.numOfEmployees
        ? naturalStringToNatural(formValues.numOfEmployees)
        : null,
      description: formValues.description || null,
      isTech: formValues.isTech === 'true',
      dateOfExit: formValues.dateOfExit || '',
      exitReason: formValues.exitReason || '',
      status: formValues?.status || STATUSES_VENTURES[0].value,
      cohortId:
        formValues?.cohortId === 'no-group' ? null : formValues?.cohortId,
    },
    specializations: formValues.specializations,
    ventureTags: formValues.ventureTags,
  };
}

function ArchiveDialog({
  open,
  initialValues,
  setOpen,
  onSubmit,
}: ArchiveDialogProps) {
  const classes = useDialogStyles();

  return (
    <Dialog
      title='Reason for archiving'
      width={480}
      contentRenderer={() => (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={(formProps) => (
            <form noValidate>
              <FormGroup mobile={isMobile()}>
                <Field<string>
                  testid='venture-details-exit-reason'
                  name='exitReason'
                  component={FormSelect}
                  label='Reason for exit'
                  options={REASON_FOR_EXIT}
                  onChange={() => {
                    if (!formProps?.values?.dateOfExit) {
                      const currentDate = new Date();
                      const currentDateStr = formatDateToRFC(currentDate);
                      formProps.form.change('dateOfExit', currentDateStr);
                    }
                  }}
                />
                <Field<string>
                  testid='venture-details-date-exit'
                  name='dateOfExit'
                  component={FormDateInput}
                  label='Exit Date'
                  editable
                  parse={(value) => {
                    try {
                      if (value) {
                        return formatDateToRFC(value);
                      }
                      return '';
                    } catch (e: any) {
                      return 'invalid';
                    }
                  }}
                />
              </FormGroup>
              <div className={classes.actions}>
                <Button
                  variant='outlined'
                  onClick={() => setOpen(false)}
                  data-testid='button-cancel'>
                  Cancel
                </Button>
                <Button
                  data-testid='button-success'
                  onClick={formProps.handleSubmit}>
                  Ok
                </Button>
              </div>
            </form>
          )}
        />
      )}
      open={open}
      setOpen={setOpen}
    />
  );
}

function ActiveDialog({ open, setOpen, onSubmit }: ActiveDialogProps) {
  const classes = useDialogStyles();

  return (
    <Dialog
      title='Please Note'
      width={480}
      contentRenderer={() => (
        <div>
          <Text variant='normal'>
            You are changing the venture's status to Active. When you save the
            change:
            <ul>
              <li>1. All linked founders will be updated to an Active status</li>
              <li>2. System users will be created for linked founders</li>
              <li>3. We will send invites to founders' emails to join the program</li>
            </ul>
            <br/>
            If you want stop the process, press on Cancel now.
          </Text>
          <div className={classes.actions}>
            <Button
              variant='outlined'
              onClick={() => setOpen(false)}
              data-testid='button-cancel'>
              Cancel
            </Button>
            <Button data-testid='button-success' onClick={onSubmit}>
              Ok
            </Button>
          </div>
        </div>
      )}
      open={open}
      setOpen={setOpen}
    />
  );
}

function VentureDetailsForm({
  ventureDetails,
  ventureSpecializations,
  ventureTags,
  loading = false,
  onSubmit,
  isExternal = false,
  cohorts,
}: VentureDetailsFormProps) {
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const [fieldsValidation, setFieldsValidation] = useState<{ name: boolean }>();
  const [isAsyncValidating, setIsAsyncValidating] = useState(false);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [showActiveDialog, setShowActiveDialog] = useState(false);
  const { hasAccessToAction: checkAccess, hasRole } = useContext(UserContext);
  const hasAccessToAction = useMemo(
    () => (isExternal ? () => true : checkAccess),
    [checkAccess, isExternal],
  );

  const handleSubmit = async (values: FormValues) => {
    if (isAsyncValidating) {
      return;
    }

    try {
      setIsAsyncValidating(true);
      const hasDublicateName =
        ventureDetails?.ventureName !== values.ventureName &&
        (await checkVentureForDuplicates(values.ventureName));

      if (hasDublicateName) {
        throw new Error('A venture with the same name already exists');
      }

      await pipe(
        getValidForm,
        apply(identity, pipe(getParsedValues, onSubmit)),
      )(values);
      setIsAsyncValidating(false);
    } catch (error) {
      setIsAsyncValidating(false);
    }
  };

  const initialValues = getInitialValues(
    ventureDetails,
    ventureSpecializations,
    ventureTags,
  );

  const handleFieldsValidation = useCallback(
    (field: 'email' | 'name') => (valid: boolean) => {
      setFieldsValidation(
        (prevValidations) =>
          ({
            ...(prevValidations ? prevValidations : {}),
            [field]: valid,
          } as { name: boolean }),
      );
    },
    [],
  );

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formProps) => {
          setSubmitValidationFailed(
            formProps.submitFailed &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting,
          );
          return (
            <>
              <BeforeUnload
                when={formProps.dirty && !loading}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={loading}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      onConfirm();
                    }}
                    disabled={
                      loading || !formProps.valid || !fieldsValidation?.name
                    }>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <div
                className={classes.mainFormBlock}
                data-testid='venture-details-form'>
                <form noValidate>
                  <div className={classes.sectionBlock}>
                    <ActiveDialog
                      open={showActiveDialog}
                      setOpen={setShowActiveDialog}
                      onSubmit={() => {
                        formProps.form.change('status', 'Active');
                        setShowActiveDialog(false);
                      }}
                    />
                    <ArchiveDialog
                      initialValues={{
                        exitReason: formProps?.values?.exitReason,
                        dateOfExit: formProps?.values?.dateOfExit,
                      }}
                      open={showArchiveDialog}
                      setOpen={setShowArchiveDialog}
                      onSubmit={(values) => {
                        formProps.form.change('exitReason', values.exitReason);
                        formProps.form.change('dateOfExit', values.dateOfExit);
                        setShowArchiveDialog(false);
                      }}
                    />
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-venture-name'
                        name='ventureName'
                        component={FormNameVenture}
                        label='Venture Name*'
                        onValid={handleFieldsValidation('name')}
                        inputProps={{
                          readOnly: !checkAccess('venture.details.update'),
                        }}
                        error={
                          (formProps?.touched?.ventureName ||
                            formProps?.submitFailed) &&
                          formProps?.errors?.ventureName
                        }
                      />

                      <Field<string>
                        testid='venture-details-venture-status'
                        name='status'
                        component={FormSelect}
                        label='Venture Status'
                        options={STATUSES_VENTURES}
                        readOnly={!hasRole(Role.Manager)}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          const value = e.target.value;
                          if (value === 'Active') {
                            e.target.value = formProps?.values?.status || '';
                            setShowActiveDialog(true);
                          } else if (value === 'Archived') {
                            setShowArchiveDialog(true);
                          }
                        }}
                      />
                    </FormGroup>

                    <FormGroup mobile={isMobile()}>
                      <Field<string>
                        testid='venture-details-date-creation'
                        name='dateOfCreation'
                        component={FormDateInput}
                        label='Application Date'
                        editable
                        isReadOnly={!checkAccess('venture.details.update')}
                        parse={(value) => {
                          try {
                            if (value) {
                              return formatDateToRFC(value);
                            }
                            return '';
                          } catch (e: any) {
                            return 'invalid';
                          }
                        }}
                      />
                      <Field<string>
                        testid='venture-details-date-enrollemnt'
                        name='dateOfEnrollment'
                        component={FormDateInput}
                        label='Date Of Enrollment*'
                        maxDate={new Date()}
                        isReadOnly={!checkAccess('venture.details.update')}
                        editable
                        parse={(value) => {
                          try {
                            if (value) {
                              return formatDateToRFC(value);
                            }
                            return '';
                          } catch (e: any) {
                            return 'invalid';
                          }
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Field<string[]>
                        data-testid='venture-details-tags'
                        name='ventureTags'
                        component={FormVentureTagsInput}
                        label='Issues'
                        disabled={!ventureTags}
                        readOnly={!checkAccess('venture.details.update')}
                      />
                    </FormGroup>

                    {!hasRole(Role.Founder) && (
                      <FormGroup>
                        <Field<CohortId | 'no-group'>
                          testid='venture-details-venture-cohort'
                          name='cohortId'
                          component={FormSelect}
                          label='Venture Group'
                          options={[
                            {
                              value: 'no-group',
                              label: 'No group',
                            },
                            ...cohorts,
                          ]}
                          readOnly={!hasRole(Role.Manager)}
                        />
                      </FormGroup>
                    )}

                    {!isExternal && !hasRole(Role.Founder) && (
                      <FormGroup mobile={isMobile()}>
                        <Field<string>
                          testid='venture-details-exit-reason'
                          name='exitReason'
                          component={FormSelect}
                          label='Reason for exit'
                          options={REASON_FOR_EXIT}
                          readOnly={
                            !hasAccessToAction('venture.details.update')
                          }
                          onChange={() => {
                            if (!formProps?.values?.dateOfExit) {
                              const currentDate = new Date();
                              const currentDateStr =
                                formatDateToRFC(currentDate);
                              formProps.form.change(
                                'dateOfExit',
                                currentDateStr,
                              );
                            }
                          }}
                        />
                        <Field<string>
                          testid='venture-details-date-exit'
                          name='dateOfExit'
                          component={FormDateInput}
                          label='Exit Date'
                          editable
                          isReadOnly={
                            !hasAccessToAction('venture.details.update')
                          }
                          parse={(value) => {
                            try {
                              if (value) {
                                return formatDateToRFC(value);
                              }
                              return '';
                            } catch (e: any) {
                              return 'invalid';
                            }
                          }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup mobile={checkSizeTablet(450)}>
                      <Field<string>
                        testid='venture-details-number-employees'
                        name='numOfEmployees'
                        component={TextFieldWrapper}
                        label='Number Of Employees'
                        type='number'
                        InputProps={{
                          inputProps: {
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                            min: 0,
                            onKeyPress: handleKeyPress,
                          },
                        }}
                      />
                      <Field<string>
                        testid='venture-details-checkbox'
                        name='isTech'
                        component={FormCheckbox}
                        label='Is Tech'
                        readOnly={
                          !hasAccessToAction(
                            'venture.details.additional.update',
                          )
                        }
                        className={classes.checkbox}
                        classNameLabel={classes.checkboxLabel}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-website'
                        name='url'
                        component={TextFieldWrapper}
                        label='Website'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.url,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-description'
                        name='description'
                        component={TextFieldWrapper}
                        label='Description'
                        multiline
                        InputProps={{
                          inputProps: {
                            className: classes.descriptionField,
                            maxLength: lengthField.description,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<Specialization['id'][]>
                        data-testid='venture-details-specializations'
                        name='specializations'
                        component={FormSpecializationInput}
                        label='Specialization'
                        disabled={!ventureSpecializations}
                        readOnly={
                          !hasAccessToAction(
                            'venture.details.additional.update',
                          )
                        }
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.sectionBlock}>
                    <Typography className={classes.sectionTitle} variant='h3'>
                      Address
                    </Typography>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-address'
                        name='address'
                        component={TextFieldWrapper}
                        label='Address'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.address,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-city'
                        name='city'
                        component={TextFieldWrapper}
                        label='City'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.city,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-state'
                        name='state'
                        component={TextFieldWrapper}
                        label='State'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.state,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        testid='venture-details-zip'
                        name='zip'
                        component={TextFieldWrapper}
                        label='Zip code'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.zip,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        data-testid='venture-details-button-submit'
                        name='country'
                        component={TextFieldWrapper}
                        label='Country'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.country,
                            readOnly: !hasAccessToAction(
                              'venture.details.additional.update',
                            ),
                          },
                        }}
                      />
                    </FormGroup>
                  </div>
                  {(hasAccessToAction('venture.details.update') ||
                    hasAccessToAction('venture.details.additional.update')) && (
                    <div className={classes.actionsBlock}>
                      <StickyContent>
                        <Button
                          onClick={formProps.handleSubmit}
                          className={CLASS_TRACKING.INTERNAL_ACTION}
                          disabled={loading}
                          startIcon={<CheckIcon />}>
                          {loading ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </StickyContent>
                    </div>
                  )}
                </form>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

export default VentureDetailsForm;
