import { add, Duration } from 'date-fns';
import { useState, useEffect, useCallback } from 'react';
import { Field, useField } from 'react-final-form';
import { FormDateTimeInput } from '../../components/forms/wrappers';
import { formatDateToRFC } from '../../utils/date';
import { isMobile } from '../../utils/functions';
import { FormGroup } from './form-layout';

interface Props {
  readOnly?: boolean;
  onChangeStartDate?: (value: Date | undefined) => void;
  minStartDate?: Date;
  minStartDateTime?: Date;
  minEndDate?: Date;
  defaultEndDuration?: Duration;
}

const validFormatDate = (value: string) => {
  if (!value) {
    return false;
  }
  try {
    formatDateToRFC(new Date(value));
    return true;
  } catch (error: any) {
    return false;
  }
};

function FieldDatesDetails({
  readOnly = false,
  onChangeStartDate,
  minStartDate,
  minStartDateTime,
  minEndDate,
  defaultEndDuration = { hours: 1, minutes: 30 },
}: Props) {
  const startField = useField('start');
  const endField = useField('end');
  const [isChanged, setIsChanged] = useState(false);

  const handleCloseStart = () => {
    startField.input.onBlur();
    setIsChanged(true);
  };

  useEffect(() => {
    if (isChanged) {
      if (typeof onChangeStartDate === 'function') {
        const isValid = validFormatDate(startField.input.value);
        onChangeStartDate(
          isValid ? new Date(startField.input.value) : undefined,
        );
      }

      setIsChanged(false);
    }
  }, [isChanged, startField, onChangeStartDate]);

  const handleEndFocus = useCallback(() => {
    const isValid = validFormatDate(startField.input.value);
    if (isValid && !endField.input.value) {
      const newData = add(new Date(startField.input.value), defaultEndDuration);

      endField?.input?.onChange(formatDateToRFC(newData));
    }
  }, [startField, endField, defaultEndDuration]);

  return (
    <FormGroup mobile={isMobile()}>
      <Field<string>
        name='start'
        testId='generateTomorrowDate'
        component={FormDateTimeInput}
        onBlur={handleCloseStart}
        onClose={handleCloseStart}
        label='Start*'
        parse={(value) => {
          try {
            if (value) {
              if (minStartDateTime && value < minStartDateTime) {
                return formatDateToRFC(minStartDateTime);
              }
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
        readOnly={readOnly}
        minDate={minStartDate}
      />
      <Field<string>
        name='end'
        testId='date-details-end'
        component={FormDateTimeInput}
        label='End*'
        onFocus={handleEndFocus}
        parse={(value) => {
          try {
            if (value) {
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
        readOnly={readOnly}
        minDate={minEndDate}
        onBlur={endField.input.onBlur}
      />
    </FormGroup>
  );
}

export default FieldDatesDetails;
