import { Button as MaterialButton, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useReducer } from 'react';
import { from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import authAPI from '../../api/auth';
import { Text } from '../common';
import { Loading } from '../common/ActionIcons/Loading';
import ConfirmDialog from '../common/confirm-dialog';
import { reducer } from './reducer';
import { confirm, decline, fail, submit, success } from './types/Actions';
import { idle } from './types/State';

const useStyles = makeStyles({
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

export type AddAllButtonTypes = 'mentors' | 'founders';

const getTestIdByType = (type: AddAllButtonTypes) => {
  switch (type) {
    case 'founders':
      return 'tenant-add-all-founders';
    case 'mentors':
    default:
      return 'tenant-add-all-mentors';
  }
};

const getApiMethodByType = (type: AddAllButtonTypes) => {
  switch (type) {
    case 'founders':
      return authAPI.createFoundersAll();
    case 'mentors':
    default:
      return authAPI.createMentorsAll();
  }
};

export function AddAllButton({
  type = 'mentors',
}: {
  type?: AddAllButtonTypes;
}) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, idle());
  const { enqueueSnackbar } = useSnackbar();
  const isDialogOpen =
    state.type === 'Confirmation' || state.type === 'Loading';

  useEffect(() => {
    switch (state.type) {
      case 'Loading': {
        const s$ = from(getApiMethodByType(type))
          .pipe(
            map(success),
            tap(() =>
              enqueueSnackbar('The invitations were successfully sent!', {
                variant: 'success',
              }),
            ),
            catchError(() =>
              of(fail()).pipe(
                tap(() =>
                  enqueueSnackbar('Failed to send invitations', {
                    variant: 'error',
                  }),
                ),
              ),
            ),
          )
          .subscribe(dispatch);

        return () => s$.unsubscribe();
      }
      case 'Idle':
      case 'Confirmation': {
        return;
      }
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  return (
    <>
      <MaterialButton
        data-testid={getTestIdByType(type)}
        onClick={() => dispatch(submit())}
        disabled={state.type === 'Loading'}
        startIcon={state.type === 'Loading' && <Loading />}
        className={classes.noWrap}
        variant='contained'
        color='primary'>
        Add All
      </MaterialButton>
      <ConfirmDialog
        isOpen={isDialogOpen}
        title='Sending invitations'
        body={
          <Text variant='normal'>
            You are going to send the invitation with login instructions to the
            selected users. Are you sure you want to continue?
          </Text>
        }
        successProps={{
          label: state.type === 'Loading' ? <Loading /> : 'Yes',
          'data-testid': 'confirm-invitations',
          variant: 'contained',
          disabled: state.type === 'Loading',
        }}
        cancelProps={{
          label: 'No',
          'data-testid': 'cancel-invitations',
          variant: 'outlined',
        }}
        onCancel={() => dispatch(decline())}
        onSuccess={() => dispatch(confirm())}
      />
    </>
  );
}
