import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { SnackMessage } from '../../components/common';
import { SumbitAttendanceForm } from '../../components/forms/gathering-attendance';
import GatheringAttendance from '../../components/gathering/gathering-attendance';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { massAsyncRequest } from '../../utils/api';

const GatheringAttendancePage = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();

  const handleAddAttendance = async (
    { value, onCancel }: SumbitAttendanceForm,
    reload: () => any,
  ) => {
    try {
      setLoading(true);
      await gatheringsAPI.addAttendance(gatheringId, value);
      onCancel();
      await reload();
      setLoading(false);
    } catch (e: any) {
      if (e.response.status === 404) {
        enqueueSnackbar(
          `We were not able to find the email of a ${rb(
            'mentor',
          )} or founder you provided`,
          {
            variant: 'error',
          },
        );
      } else {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while adding the attendance. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
      }

      setLoading(false);
    }
  };

  const handleRemoveAttendance = async (emails: string[], cb: () => any) => {
    try {
      setLoading(true);
      const removedRequests = emails.map(
        (email) => () => gatheringsAPI.removeAttendance(gatheringId, email),
      );

      await massAsyncRequest(removedRequests);
      cb();
      setLoading(false);
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      enqueueSnackbar(
        'An error occurred while removing the attendance. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );

      setLoading(false);
    }
  };

  return (
    <GatheringAttendance
      loading={loading}
      handleAddAttendance={handleAddAttendance}
      handleRemoveAttendance={handleRemoveAttendance}
    />
  );
};

export default GatheringAttendancePage;
