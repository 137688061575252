import { ButtonGroup, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';
import { TestId } from '../../Testing/TestId';
import { AddNewIcon } from '../../common/ActionIcons/AddNewIcon';
import Button from '../../common/button';
import { Props as EditProps } from './Edit';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '22px',
  },
  addNew: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
  },
  addNewSubtitle: {
    width: '100%',
  },
}));

export interface Props<T extends string> {
  children: Array<ReactElement<EditProps<T>>>;
  onAdd: () => void;
  addButton: 'active' | 'hidden' | 'disabled';
  onSubmit: (() => void) | undefined;
}

export function Availabilities<T extends string>({
  children,
  onAdd,
  addButton,
  onSubmit,
}: Props<T>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h4' className={classes.addNew}>
        <span>Add new availability</span>
        {addButton !== 'hidden' ? (
          <TestId testId='add-new'>
            <AddNewIcon onClick={onAdd} status={addButton} />
          </TestId>
        ) : (
          <Typography variant='subtitle2' className={classes.addNewSubtitle}>
            Max number of availability records is reached. Please delete some
            old slots to create new ones.
          </Typography>
        )}
      </Typography>

      {children}

      <ButtonGroup>
        <TestId testId={'submit'}>
          <Button
            onClick={onSubmit}
            disabled={!onSubmit}
            color={'primary'}
            variant={'contained'}
            startIcon={<CheckIcon />}>
            Save
          </Button>
        </TestId>
      </ButtonGroup>
    </div>
  );
}
