import { ComponentProps, ReactElement } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { actionIcon } from '../styles';
import classNames from 'classnames';

export type Props = ComponentProps<typeof CircularProgress>;

const useStyles = makeStyles(() => ({
  main: {
    ...actionIcon(),
    cursor: 'default'
  }
}));

export function Loading({ className, ...props }: Props): ReactElement<Props> {
  const { main } = useStyles();
  return (
    <CircularProgress
      size={22}
      {...props}
      className={classNames(main, className)}
    />
  );
}
