import { pass } from 'fp-utilities';
import {
  checkCapitalCharacters,
  checkNumber,
  checkSpecialSymbols,
} from '../functions';

declare const tag: unique symbol;

export type Password = string & { [tag]: 'Password' };

export const isPassword = (pw: string): pw is Password => {
  return (
    checkSpecialSymbols(pw) &&
    checkNumber(pw) &&
    checkCapitalCharacters(pw) &&
    pw.length > 8
  );
};

export const fromString = pass(isPassword);
