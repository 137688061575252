export const isNumber = (v: unknown): v is number =>
  typeof v === 'number' && !isNaN(v);

export function order(n: number): 'st' | 'nd' | 'rd' | 'th' {
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
