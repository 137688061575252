import { Tabs, Tab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '../../api/user/Role';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  mentorId?: string;
  ventureId?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },

  tooltipWrapper: {
    marginLeft: '30px !important',

    '& > button': {
      whiteSpace: 'nowrap',
    },
  },
}));

function PageTabs({ mentorId, ventureId }: PageTabsProps) {
  const location = useLocation();
  const classes = useStyles();
  const { hasAccessToAction, identityid, hasRole } = useContext(UserContext);
  const { rb } = useResourceBundles();

  const tabs: TabsProps[] = [
    {
      props: {
        label: 'Details',
        'data-testid': 'mentor-nav-details',
        component: Link,
        ...(mentorId
          ? {
              to:
                hasRole(Role.Founder) && ventureId
                  ? getRoutePath(Pages.MY_MENTOR_DETAILS, {
                      mentorId,
                      ventureId,
                    })
                  : getRoutePath(Pages.MENTOR_DETAILS, { mentorId }),
              value:
                hasRole(Role.Founder) && ventureId
                  ? getRoutePath(Pages.MY_MENTOR_DETAILS, {
                      mentorId,
                      ventureId,
                    })
                  : getRoutePath(Pages.MENTOR_DETAILS, { mentorId }),
            }
          : {
              to: Pages.NEW_MENTOR,
              value: Pages.NEW_MENTOR,
            }),
      },
    },
    {
      ...(!mentorId
        ? {
            tooltip: {
              title: `Please complete creation of ${rb(
                'mentor',
              )}\nbefore switching to Assignments`,
            },
          }
        : {}),
      props: {
        'data-testid': 'mentor-nav-assignments',
        label: 'Assignments',
        disabled: !mentorId,
        ...(mentorId
          ? {
              component: Link,
              value: getRoutePath(Pages.MENTOR_ASSIGNMENTS, { mentorId }),
              to: getRoutePath(Pages.MENTOR_ASSIGNMENTS, { mentorId }),
            }
          : {}),
      },
    },
    {
      ...(!mentorId
        ? {
            tooltip: {
              title: `Please complete creation of ${rb(
                'mentor',
              )}\nbefore switching to Additional info`,
            },
          }
        : {}),
      props: {
        'data-testid': 'mentor-nav-additional-info',
        label: 'Additional info',
        disabled: !mentorId,
        ...(mentorId
          ? {
              component: Link,
              value:
                hasRole(Role.Founder) && ventureId
                  ? getRoutePath(Pages.MY_MENTOR_ADDITIONAL_INFO, {
                      mentorId,
                      ventureId,
                    })
                  : getRoutePath(Pages.MENTOR_ADDITIONAL_INFO, { mentorId }),
              to:
                hasRole(Role.Founder) && ventureId
                  ? getRoutePath(Pages.MY_MENTOR_ADDITIONAL_INFO, {
                      mentorId,
                      ventureId,
                    })
                  : getRoutePath(Pages.MENTOR_ADDITIONAL_INFO, { mentorId }),
            }
          : {}),
      },
    },
    {
      ...(!mentorId
        ? {
            tooltip: {
              title: `Please complete creation of ${rb(
                'mentor',
              )}\nbefore switching to Notes`,
            },
          }
        : {}),
      props: {
        'data-testid': 'mentor-nav-notes',
        label: 'Notes',
        disabled: !mentorId,
        ...(mentorId
          ? {
              component: Link,
              value: getRoutePath(Pages.MENTOR_NOTES, { mentorId }),
              to: getRoutePath(Pages.MENTOR_NOTES, { mentorId }),
            }
          : {}),
      },
    },
    {
      ...(!mentorId
        ? {
            tooltip: {
              title: `Please complete creation of ${rb(
                'mentor',
              )}\nbefore switching to Stats`,
            },
          }
        : {}),
      props: {
        'data-testid': 'mentor-nav-stats',
        label: 'Stats',
        disabled: !mentorId,
        ...(mentorId
          ? {
              component: Link,
              value: getRoutePath(Pages.MENTOR_STATS, { mentorId }),
              to: getRoutePath(Pages.MENTOR_STATS, { mentorId }),
            }
          : {}),
      },
    },
    {
      ...(!mentorId
        ? {
            tooltip: {
              title: `Please complete creation of ${rb(
                'mentor',
              )}\nbefore switching to Log`,
            },
          }
        : {}),
      props: {
        'data-testid': 'mentor-nav-log',
        label: 'Log',
        disabled: !mentorId,
        ...(mentorId
          ? {
              component: Link,
              value: getRoutePath(Pages.MENTOR_LOG, { mentorId }),
              to: getRoutePath(Pages.MENTOR_LOG, { mentorId }),
            }
          : {}),
      },
    },
  ];

  const currentTabs = useMemo(() => {
    let arrayTabs = tabs;
    if (!hasAccessToAction('mentor.logs.view')) {
      arrayTabs.splice(5, 1);
    }
    if (!hasAccessToAction('mentor.statistics')) {
      arrayTabs.splice(4, 1);
    }
    if (!hasAccessToAction('mentor.notes.view')) {
      arrayTabs.splice(3, 1);
    }
    if (
      !hasAccessToAction('mentor.additionalInfo.view') &&
      identityid !== mentorId
    ) {
      arrayTabs.splice(2, 1);
    }
    if (!hasAccessToAction('mentor.assignemnts.view')) {
      arrayTabs.splice(1, 1);
    }
    if (!hasAccessToAction('mentor.details.view')) {
      arrayTabs.splice(0, 1);
    }
    return arrayTabs;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, identityid, mentorId]);

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='on'>
        {currentTabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          ),
        )}
      </Tabs>
    </div>
  );
}

export default PageTabs;
