import { State } from './State';

export const canAddNewQuestion = (s: State): boolean => {
  switch (s.type) {
    case 'Loading':
    case 'Fail':
      return false;
    case 'Ready':
      return s.payload.items.length < 5;
    case 'AddNew':
    case 'AddNewErr':
    case 'Adding':
      return s.payload.items.length < 4;
    case 'Updating':
    case 'Removing':
    case 'RemoveConfirmation':
    case 'Edit':
    case 'EditErr':
      return s.payload.before.length + s.payload.after.length < 4;
  }
};
