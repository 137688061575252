import { Dispatch, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import * as State from './type/State';
import * as Actions from './type/Actions';
import { TenantId } from '../../api/tenants/Tenant';
import { reducer } from './reducer';
import { initial } from '../../utils/FormValue';
import { unreachableError } from '../../utils/unreachableError';
import * as CommunityMembers from '../../api/CommunityMembers/index';
import { from, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

export function useCommunityMemberSignup(): [
  State.State,
  Dispatch<Actions.Actions>,
] {
  const { tenantId } = useParams<{ tenantId: TenantId }>();
  const [s, dispatch] = useReducer(
    reducer,
    State.ready({
      tenantId,
      firstName: initial(undefined),
      lastName: initial(undefined),
      note: initial(undefined),
      email: initial(undefined),
    }),
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    switch (s.type) {
      case 'Saving': {
        from(
          CommunityMembers.apply({
            tenantId: s.payload.tenantId,
            note: s.payload.note.value,
            firstName: s.payload.firstName.value,
            lastName: s.payload.lastName.value,
            email: s.payload.email.value,
          }),
        )
          .pipe(
            mapTo(Actions.saveSuccess()),
            catchError((e: AxiosError) =>
              of(Actions.saveError(e.response?.data.message)),
            ),
          )
          .subscribe(dispatch);
        break;
      }
      case 'Ready':
      case 'Submitted':
      case 'SaveSuccess':
      case 'SaveError':
        break;
      default:
        unreachableError(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type, dispatch]);

  useEffect(() => {
    switch (s.type) {
      case 'Saving':
      case 'Ready':
      case 'Submitted':
      case 'SaveSuccess':
        break;
      case 'SaveError': {
        enqueueSnackbar(s.payload.message, { variant: 'error' });
        break;
      }
      default:
        unreachableError(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type]);

  return [s, dispatch];
}
