import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import { CustomSelect, Props as SelectProps } from './custom-select';

export interface Props<T extends string>
  extends Omit<SelectProps, 'onChange' | 'value'> {
  value?: T;
  onChange?: (v: T) => void;
  options: Array<{ label: string; value: T }>;
  disabled?: boolean;
}

export function SimpleSelect<T extends string>({
  onChange,
  value,
  options,
  ...props
}: Props<T>): ReactElement {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) =>
      onChange?.(convertValue(e.target.value)),
    [onChange],
  );

  /**
   * --------------------------
   * MUY Select doesn't recognize '' as value
   * so we replace it internal with a unique value (i think it is unique)
   * and back replace that value with ''
   */
  const _value = value === '' ? emptyValueHack : value;
  const _options = useMemo(
    () =>
      options.map((i) =>
        i.value === '' ? { ...i, value: emptyValueHack } : i,
      ),
    [options],
  );
  /**
   * --------------------------
   */

  return (
    <CustomSelect
      onChange={handleOnChange}
      value={_value}
      options={_options}
      {...props}
    />
  );
}

const convertValue = <T extends string>(v: string): T =>
  (v === emptyValueHack ? '' : v) as T;
const emptyValueHack = `${new Date()}+${new Date()}`;
