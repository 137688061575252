import { makeStyles, CircularProgress } from '@material-ui/core';
import {
  Button,
  TextWysiwyg,
  FormButtons
  // AttachFilesButton
} from '../../components/common';
import CheckIcon from '@material-ui/icons/Check';
import AttachmentCard, {
  Attachment
} from '../../components/common/attachment-card';

interface PropsEditInput {
  materials?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File
  ) => void;
  handleSave: () => void;
  loading: boolean;
  attachments: Attachment[];
  handleFileRemove: (index: number) => void;
  handleCancel: () => void;
}

const useStyles = makeStyles({
  attachBtn: {
    marginTop: 24
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px'
  },
  attachment: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px'
  },
  formButtons: {
    justifyContent: 'flex-start'
  }
});

const EducationForm = ({
  materials,
  handleChange,
  handleSave,
  handleSelectFile,
  loading,
  attachments,
  handleFileRemove,
  handleCancel
}: PropsEditInput) => {
  const classes = useStyles();

  return (
    <>
      <TextWysiwyg
        onChange={handleChange}
        value={materials || ''}
        placeholder='Materials'
        testid='education-form-materials-input'
      />
      {/* <div className={classes.attachBtn}>
        <AttachFilesButton onChange={handleSelectFile} />
      </div> */}
      <div className={classes.attachmentsList}>
        {attachments.map((attachment, attachIndex) => (
          <div key={attachIndex} className={classes.attachment}>
            <AttachmentCard
              attachment={attachment}
              loading={!attachment.url}
              onRemove={() => handleFileRemove(attachIndex)}
            />
          </div>
        ))}
      </div>
      <FormButtons className={classes.formButtons}>
        <Button
          data-testid='button-submit-form'
          onClick={handleSave}
          disabled={loading}
          startIcon={<CheckIcon />}>
          {loading ? <CircularProgress size={24} color='inherit' /> : 'Save'}
        </Button>
        <Button
          variant='outlined'
          onClick={handleCancel}
          data-testid='button-cancel-form'>
          Cancel
        </Button>
      </FormButtons>
    </>
  );
};

export default EducationForm;
