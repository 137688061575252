export type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export function isWeekDay(n: number): n is WeekDay {
  const t: { [k in WeekDay]: k } = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
  };

  return t[n as WeekDay] !== undefined;
}

export const fromDateFnsWeekDay = (d: 0 | 1 | 2 | 3 | 4 | 5 | 6): WeekDay => {
  switch (d) {
    case 0:
      return 7;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
  }
};

export const toDateFnsWeekDay = (d: WeekDay): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  switch (d) {
    case 7:
      return 0;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 4;
    case 6:
      return 6;
  }
};
