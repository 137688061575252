import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { Button, FormButtons } from '.';
import foundersAPI, {
  Founder,
  FounderDetails,
  FounderStatus,
} from '../../api/founders';
import { massAsyncRequest } from '../../utils/api';

interface Props {
  onCancel: () => any;
  selectedValue?: Founder[];
  status: FounderStatus;
  updateTable: () => void;
}

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

const validateFounder = (founder: Founder): FounderDetails => {
  return {
    founderNotes: founder.founderNotes,
    address: founder.address,
    status: founder.status,
    firstName: founder.firstName,
    lastName: founder.lastName,
    dateOfBirth: founder.dateOfBirth,
    dateOfEnrollment: founder.dateOfEnrollment,
    linkedInProfile: founder.linkedInProfile,
    email: founder.email,
    phone: founder.phone,
    logo: founder.logo,
    country: founder.country,
    state: founder.state,
    city: founder.city,
    zip: founder.zip,
  };
};

function ButtonsChangeStatusFounder({
  onCancel,
  selectedValue,
  status,
  updateTable,
}: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!selectedValue) return undefined;
    setLoading(true);
    const changeStatus = selectedValue.map((value: Founder) => {
      return () =>
        foundersAPI.update(value.id, {
          ...validateFounder(value),
          status: status,
        });
    });
    await massAsyncRequest(changeStatus);
    setLoading(false);
    onCancel();
    updateTable();
  };

  return (
    <FormButtons className={classes.formButtons}>
      <Button onClick={onCancel} variant='outlined'>
        Cancel
      </Button>
      <Button disabled={loading} type='submit' onClick={handleSubmit}>
        {loading ? <CircularProgress size={24} color='inherit' /> : 'Yes'}
      </Button>
    </FormButtons>
  );
}

export default ButtonsChangeStatusFounder;
