import { addMinutes, isAfter } from 'date-fns';
import { AvailabilityId } from '../../../../api/Advisors/types/Availability';
import {
  Period,
  validatePeriod,
  ValidPeriod,
} from '../../../../api/types/Period';
import {
  Invalid,
  invalid,
  isValid,
  Valid,
  valid,
  Value,
} from '../../../../utils/FormValue';
import { isExistingItemId, isNewItemId, NewItemId } from './ItemId';
import { ValidDate } from './ValidDate';

export enum ItemError {
  Required = 'Required',
  Past = 'Past',
  Invalid = 'Invalid',
  Low = 'Low',
}

// region NewItem
export interface NewItem extends Item {
  id: NewItemId;
}

export const isNewItem = (v: Item): v is NewItem => isNewItemId(v.id);
// endregion

// region ExistingItem
export interface ExistingItem extends Item {
  id: AvailabilityId;
}

export const isExistingItem = (v: Item): v is ExistingItem =>
  isExistingItemId(v.id);
// endregion

// region ValidItem
export interface ValidItem extends Item {
  start: Valid<ValidDate>;
  end: Valid<ValidDate>;
  period: Valid<ValidPeriod>;
}

export function validateItem(item: Item): ValidItem | SubmittedItem {
  switch (item.period.value.periodType) {
    case '': {
      const start =
        item.start.value &&
        isAfter(item.start.value, addMinutes(new Date(), 30))
          ? valid(item.start.value)
          : invalid(ItemError.Past, item.start.value);
      const end = item.end.value
        ? isValid(start)
          ? isAfter(item.end.value, start.value)
            ? valid(item.end.value)
            : invalid(ItemError.Low, item.end.value)
          : invalid(ItemError.Required, item.end.value)
        : invalid(ItemError.Required, item.end.value);

      return { ...item, start, end, period: valid({ periodType: '' }) };
    }
    case 'ALL_WORK_DAYS':
    case 'CUSTOM_WEEK_DAYS':
    case 'DAILY':
    case 'MONTHLY':
      return {
        ...item,
        period: validatePeriod(item.period.value),
        start: item.start.value
          ? valid(item.start.value)
          : invalid(ItemError.Required, item.start.value),
        end: item.end.value
          ? valid(item.end.value)
          : invalid(ItemError.Required, item.end.value),
      };
  }
}
// endregion

// region SubmittedItem
export interface SubmittedItem extends Item {
  start: Valid<ValidDate> | Invalid<ItemError, ValidDate | undefined>;
  end: Valid<ValidDate> | Invalid<ItemError, ValidDate | undefined>;
  period: Valid<ValidPeriod> | Invalid<ItemError, Period>;
}
// endregion

export type Item = {
  id: AvailabilityId | NewItemId;
  start: Value<ItemError, ValidDate, undefined | ValidDate>;
  end: Value<ItemError, ValidDate, undefined | ValidDate>;
  period: Value<ItemError, ValidPeriod, Period>;
};
