import { FilterStatus } from '../../../../api/Appointments/types/FilterStatus';
import { Item } from './Item';

// region Loading
export interface LoadingPayload {
  dateRange: [Date, Date] | undefined;
  status: FilterStatus | undefined;
  page: number;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  items: Item[];
  next: boolean;
  prev: boolean;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmationPayload extends ReadyPayload {
  id: Item['id'];
}

export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: RemoveConfirmationPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload'],
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload,
});
// endregion

// region Removing
export interface RemovingPayload extends ReadyPayload {
  id: Item['id'];
}

export interface Removing {
  type: 'Removing';
  payload: RemovingPayload;
}

export const removing = (payload: Removing['payload']): Removing => ({
  type: 'Removing',
  payload,
});
// endregion

// region BulkRemoveConfirm
export interface BulkRemoveConfirmPayload extends ReadyPayload {}

export interface BulkRemoveConfirm {
  type: 'BulkRemoveConfirm';
  payload: BulkRemoveConfirmPayload;
}

export const bulkRemoveConfirm = (
  payload: BulkRemoveConfirm['payload'],
): BulkRemoveConfirm => ({
  type: 'BulkRemoveConfirm',
  payload,
});
// endregion

// region BulkRemove
export interface BulkRemovePayload extends ReadyPayload {}

export interface BulkRemove {
  type: 'BulkRemove';
  payload: BulkRemovePayload;
}

export const bulkRemove = (payload: BulkRemove['payload']): BulkRemove => ({
  type: 'BulkRemove',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | RemoveConfirmation
  | Removing
  | BulkRemoveConfirm
  | BulkRemove;
