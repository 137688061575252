import { mPipe, pass } from 'fp-utilities';
import { trim } from 'ramda';
import { Either, left, right } from '../Either';

declare const _phone: unique symbol;

export type Phone = string & { [_phone]: 'Phone' };

export const isPhone = (s: string): s is Phone =>
  new RegExp(/^\+?[0-9|\-|.| |(|)]{3,20}$/gi).test(s);

export const stringToPhone = mPipe(trim, pass(isPhone));
export const stringToEitherPhone = (s: string): Either<string, Phone> => {
  const p = stringToPhone(s);
  return p ? right(p) : left('Invalid Phone');
};
