import { codeMessage } from '../List/utils';
import { Actions } from './types/Actions';
import * as State from './types/State';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: codeMessage(a.payload) })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            advisor: a.payload.advisor,
            specializations: a.payload.specializations,
            strictOfficeHoursScheduling: a.payload.strictOfficeHoursScheduling,
            timeZone: a.payload.timeZone,
          })
        : s;
  }
}
