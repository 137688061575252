// region SetPassword
export interface SetPassword {
  type: 'SetPassword';
  payload: string;
}

export const setPassword = (payload: SetPassword['payload']): SetPassword => ({
  type: 'SetPassword',
  payload,
});
// endregion

// region SetPasswordRepeat
export interface SetPasswordRepeat {
  type: 'SetPasswordRepeat';
  payload: string;
}

export const setPasswordRepeat = (
  payload: SetPasswordRepeat['payload'],
): SetPasswordRepeat => ({
  type: 'SetPasswordRepeat',
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region SendError
export interface SendError {
  type: 'SendError';
  payload: string;
}

export const sendError = (payload: SendError['payload']): SendError => ({
  type: 'SendError',
  payload,
});
// endregion

// region SendSuccess
export interface SendSuccess {
  type: 'SendSuccess';
}

export const sendSuccess = (): SendSuccess => ({
  type: 'SendSuccess',
});
// endregion

export type Actions =
  | SetPassword
  | SetPasswordRepeat
  | Submit
  | SendError
  | SendSuccess;
