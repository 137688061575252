import { Location } from 'history';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

interface CbRef {
  when: () => boolean;
  unBlock: () => void;
  onUnload: () => void;
  redirect: string | undefined;
}

export function useBeforeUnload(
  when: () => boolean,
  onUnload: () => void,
): { confirm: (v: boolean) => void } {
  const history = useHistory();
  const cbRef = useRef<CbRef>({
    when,
    onUnload,
    unBlock: () => {},
    redirect: undefined,
  });
  cbRef.current.when = when;
  cbRef.current.onUnload = onUnload;

  const handleConfirm = useCallback(
    (v: boolean) => {
      if (v && cbRef.current.redirect !== undefined) {
        cbRef.current.unBlock();
        history.push(cbRef.current.redirect);
      }
    },
    [history],
  );

  useEffect(() => {
    cbRef.current.unBlock = history.block((v) => {
      if (cbRef.current.when()) {
        cbRef.current.onUnload();
        cbRef.current.redirect = getPath(v);
        return false;
      }
    });

    return cbRef.current.unBlock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { confirm: handleConfirm };
}

function getPath(location: Location): string {
  return `${location.pathname}${location.search}${location.hash}`;
}
