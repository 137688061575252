// region SetFirst
export interface SetFirst {
  type: 'SetFirst';
  payload: string;
}

export const setFirst = (payload: SetFirst['payload']): SetFirst => ({
  type: 'SetFirst',
  payload,
});
// endregion

// region SetLast
export interface SetLast {
  type: 'SetLast';
  payload: string;
}

export const setLast = (payload: SetLast['payload']): SetLast => ({
  type: 'SetLast',
  payload,
});
// endregion

// region SetEmail
export interface SetEmail {
  type: 'SetEmail';
  payload: string;
}

export const setEmail = (payload: SetEmail['payload']): SetEmail => ({
  type: 'SetEmail',
  payload,
});
// endregion

// region SetNote
export interface SetNote {
  type: 'SetNote';
  payload: string;
}

export const setNote = (payload: SetNote['payload']): SetNote => ({
  type: 'SetNote',
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: string;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
}

export const saveSuccess = (): SaveSuccess => ({
  type: 'SaveSuccess',
});
// endregion

export type Actions =
  | SetFirst
  | SetLast
  | SetEmail
  | SetNote
  | Submit
  | SaveSuccess
  | SaveError;
