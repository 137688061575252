import { CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useState, useContext, ReactNode } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, FormGroup, Modal } from '.';
import { FounderId } from '../../api/founders';
import mentorsAPI, { MentorId } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { TestId } from '../Testing/TestId';
import {
  FormRadioboxGroup,
  FormSelect,
  TextFieldWysiwyg,
} from '../forms/wrappers';
import { OptionProps } from './select';

type ManagementRequestType = 'ADD' | 'REMOVE' | 'CHANGE';

interface FormValues {
  description: string;
  type: ManagementRequestType;
  ventureId: VentureId | null;
  mentorId: MentorId | null;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

type ValidateFormOptions = {
  isMentorRequired?: boolean;
};

const validateForm = (options?: ValidateFormOptions) => {
  return (values: FormValues) => {
    const errors: Errors = {};
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.ventureId) {
      errors.ventureId = 'Required';
    }
    if (!values.mentorId && options?.isMentorRequired) {
      errors.mentorId = 'Required';
    }
    return errors;
  };
};

interface MentorManagementRequestProps {
  ventureId: VentureId | null;
  mentorId: MentorId | null;
  defaultType: ManagementRequestType;
  selectableType?: boolean;
  ventureOptions?: OptionProps<VentureId>[];
  mentorOptions?: OptionProps<MentorId>[];
}

interface MentorManagementRequestIconButtonProps
  extends MentorManagementRequestProps {
  title: string;
  caption?: string;
}

interface MentorManagementRequestButtonProps
  extends MentorManagementRequestProps {
  buttonText: string;
  title: string;
  caption?: string;
  startIcon?: ReactNode;
  disabledButton?: boolean;
  testid?: string;
}

interface MentorManagementRequestFormProps
  extends MentorManagementRequestProps {
  handleClose: Function;
}

const useTitleStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: 15,
    marginTop: 20,
  },
});

function MentorManagementRequestButton({
  buttonText,
  title,
  caption,
  ventureId,
  mentorId,
  startIcon,
  selectableType,
  defaultType,
  ventureOptions,
  mentorOptions,
  disabledButton,
  testid,
}: MentorManagementRequestButtonProps) {
  return (
    <Modal
      title={title}
      caption={caption}
      contentRenderer={({ handleClose }) => (
        <MentorManagementRequestForm
          handleClose={handleClose}
          ventureId={ventureId}
          mentorId={mentorId}
          selectableType={selectableType}
          defaultType={defaultType}
          ventureOptions={ventureOptions}
          mentorOptions={mentorOptions}
        />
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          onClick={onClick}
          className={CLASS_TRACKING.ENTITY_ACTION}
          data-testid={testid || 'mentor-management-request-button'}
          variant='contained'
          startIcon={startIcon}
          disabled={disabledButton}
          color='primary'>
          {buttonText}
        </Button>
      )}
      width={720}
    />
  );
}

export function MentorManagementRequestIconButton({
  title,
  caption,
  ventureId,
  mentorId,
  selectableType,
  defaultType,
  ventureOptions,
}: MentorManagementRequestIconButtonProps) {
  return (
    <Modal
      title={title}
      caption={caption}
      contentRenderer={({ handleClose }) => (
        <MentorManagementRequestForm
          handleClose={handleClose}
          ventureId={ventureId}
          mentorId={mentorId}
          selectableType={selectableType}
          defaultType={defaultType}
          ventureOptions={ventureOptions}
        />
      )}
      buttonRenderer={({ onClick }) => (
        <IconButton
          onClick={onClick}
          data-testid={'mentor-management-request-icon'}>
          <EditIcon />
        </IconButton>
      )}
      width={720}
    />
  );
}

function MentorManagementRequestForm({
  handleClose,
  ventureId,
  mentorId,
  defaultType,
  selectableType,
  ventureOptions,
  mentorOptions,
}: MentorManagementRequestFormProps) {
  const initialValues: FormValues = {
    description: '',
    type: defaultType,
    mentorId,
    ventureId,
  };
  const classes = useTitleStyles();
  const { rb } = useResourceBundles();

  const [isLoading, setLoading] = useState(false);
  const { user, identityid } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (formValues: FormValues) => {
    try {
      setLoading(true);
      await mentorsAPI.createMentorManagementRequest({
        description: formValues.description,
        tenantId: user?.id || null,
        ventureId: formValues.ventureId,
        founderId: identityid as FounderId,
        mentorId: formValues.mentorId,
        type: formValues.type,
      });
      setLoading(false);
      handleClose();
      enqueueSnackbar(`${rb('mentor-u')} request sent successfully`, {
        variant: 'success',
      });
    } catch (error: any) {
      setLoading(false);
      const messageError = `Unfortunately, an error occurred when sending the ${rb(
        'mentor',
      )} request.\n Details: ${
        error?.response?.data?.message || 'Internal server error'
      }`;
      enqueueSnackbar(messageError, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  return (
    <div>
      <Form
        validate={validateForm({ isMentorRequired: !!mentorOptions })}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formProps) => (
          <form noValidate>
            {selectableType && (
              <FormGroup>
                <Field<string>
                  name='type'
                  component={FormRadioboxGroup}
                  label={`What would you like to change about this ${rb(
                    'mentor',
                  )}?`}
                  info={`It is totally fine to make adjustments to your ${rb(
                    'mentor',
                  )} team when necessary. Please provide some details and we'll send an email to your program administrator on your behalf.`}
                  options={[
                    {
                      value: 'REMOVE',
                      label: `Request that this ${rb('mentor')} be removed`,
                    },
                    {
                      value: 'CHANGE',
                      label: `Request that this ${rb('mentor')} be replaced`,
                    },
                  ]}
                  testid='mentors-management-request-type'
                />
              </FormGroup>
            )}
            {mentorOptions && (
              <FormGroup>
                <Field<string>
                  name='mentorId'
                  component={FormSelect}
                  label={`${rb('mentor-u')}*`}
                  options={mentorOptions}
                  testid='mentors-management-request-mentor'
                />
              </FormGroup>
            )}
            {ventureOptions && (
              <FormGroup>
                <Field<string>
                  name='ventureId'
                  component={FormSelect}
                  label='Venture*'
                  options={ventureOptions}
                  testid='mentors-management-request-venture'
                />
              </FormGroup>
            )}
            <FormGroup>
              <Field<string>
                name='description'
                component={TextFieldWysiwyg}
                placeholder='Description*'
                testid='mentors-management-request-description'
                maxLength={2048}
              />
            </FormGroup>
            <div className={classes.contentButton}>
              <Button onClick={() => handleClose()} variant='outlined'>
                Cancel
              </Button>
              <TestId testId='mentors-management-request-submit-button'>
                <Button onClick={formProps.handleSubmit} disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </TestId>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default MentorManagementRequestButton;
