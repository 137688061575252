import * as yup from 'yup';
import { createCustomQuestionResponse } from '../api/CustomQuestion';
import { Audience } from '../api/CustomQuestion/types/Audience';
import {
  CustomQuestion,
  RespondersCustomQuestions,
} from '../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../api/CustomQuestion/types/Type';
import { TenantId } from '../api/tenants/Tenant';

export const customFieldsMaxLength = {
  [CustomQuestionType.PARAGRAPH]: 2048,
  [CustomQuestionType.TEXT]: 1024,
};

export const getCustomQuestionsDefaultValues = (
  questions: CustomQuestion[],
) => {
  return questions.reduce((acc, question) => {
    switch (question.type) {
      case CustomQuestionType.SINGLE_CHOICE:
        const singleChoiceValue =
          question?.options?.find(
            (option) => option?.optionResponse?.value === 'selected',
          )?.id || '';
        return {
          ...acc,
          [question.id]: singleChoiceValue,
        };
      case CustomQuestionType.MULTIPLE_CHOICE:
        const multipleChoiceValue = question?.options?.length
          ? question.options
              .filter((option) => option.optionResponse?.value === 'selected')
              .map((option) => option.id)
              .join(',')
          : '';
        return {
          ...acc,
          [question.id]: multipleChoiceValue,
        };
      case CustomQuestionType.TEXT:
      case CustomQuestionType.PARAGRAPH:
      case CustomQuestionType.NUMBER:
      default:
        return {
          ...acc,
          [question.id]: question?.response?.value || '',
        };
    }
  }, {});
};

export const getCustomQuestionsValidationSchema = (
  questions?: CustomQuestion[],
) => {
  if (questions?.length) {
    return yup.object().shape(
      questions.reduce((acc, question) => {
        const { id, type, isMandatory } = question;
        const fieldValidation = getCustomQuestionsValidation(type, isMandatory);
        return {
          ...acc,
          [id]: fieldValidation,
        };
      }, {}),
    );
  }
  return undefined;
};

export const getCustomQuestionsValidation = (
  type: CustomQuestionType,
  isMandatory: boolean,
) => {
  switch (type) {
    case CustomQuestionType.PARAGRAPH:
      return isMandatory
        ? yup
            .string()
            .max(customFieldsMaxLength[CustomQuestionType.PARAGRAPH])
            .trim()
            .required('Required field')
        : yup
            .string()
            .max(customFieldsMaxLength[CustomQuestionType.PARAGRAPH])
            .trim();
    case CustomQuestionType.TEXT:
      return isMandatory
        ? yup
            .string()
            .max(customFieldsMaxLength[CustomQuestionType.TEXT])
            .trim()
            .required('Required field')
        : yup
            .string()
            .max(customFieldsMaxLength[CustomQuestionType.TEXT])
            .trim();
    case CustomQuestionType.SINGLE_CHOICE:
    case CustomQuestionType.MULTIPLE_CHOICE:
    case CustomQuestionType.NUMBER:
    default:
      return isMandatory
        ? yup.string().trim().required('Required field')
        : yup.string().trim();
  }
};

export const handleSubmitCustomForm = async ({
  questions,
  values,
  tenantId,
  audience,
  responderId,
}: {
  questions: CustomQuestion[];
  values: Record<string, any>;
  tenantId: TenantId;
  audience: Audience;
  responderId: string;
}) => {
  const questionsWithNewResponses = questions.map((question) => {
    if (
      (question.type === CustomQuestionType.MULTIPLE_CHOICE ||
        question.type === CustomQuestionType.SINGLE_CHOICE) &&
      question.options?.length
    ) {
      const value = values[question.id];
      const selectedValues = typeof value === 'string' ? value?.split(',') : [];
      const newOptions = question.options.map((option) => {
        const isSelected = selectedValues.includes(option.id.toString());
        if (!isSelected && question.type === CustomQuestionType.SINGLE_CHOICE) {
          return {
            ...option,
            optionResponse: null,
          };
        } else {
          const oldOptionResponse = option.optionResponse || {};
          const newOptionResponse = {
            questionId: question.id,
            optionId: option.id,
            responderId: responderId,
            value: isSelected ? 'selected' : 'unselected',
          };
          return {
            ...option,
            optionResponse: {
              ...oldOptionResponse,
              ...newOptionResponse,
            },
          };
        }
      });
      return {
        ...question,
        options: newOptions,
      };
    } else {
      const preValue = values[question.id];
      const value = typeof preValue === 'string' ? preValue.trim() : preValue;
      if (!value) {
        return {
          ...question,
          response: null,
        };
      }
      const oldResponse = question.response || {};
      const newResponse = {
        questionId: question.id,
        responderId: responderId,
        value,
      };
      return {
        ...question,
        response: {
          ...oldResponse,
          ...newResponse,
        },
      };
    }
  });
  const newRepondersCustomQuestions: RespondersCustomQuestions = {
    tenantId,
    audience,
    responderId,
    questions: questionsWithNewResponses,
  };
  const res = await createCustomQuestionResponse(
    tenantId,
    newRepondersCustomQuestions,
  );
  return res;
};
