import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import mentorsAPI, { Mentor, MentorId } from '../../api/mentors';
import { GatheringContext } from '../../contexts/gathering-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Email } from '../../utils/String/Email';
import MentorAutocomplete from '../autocomplete/mentor-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  TextField,
} from '../common';

export interface AssignMentors {
  assigned: MentorId[];
  removed: MentorId[];
  all: InviteMentor[];
  isAll: boolean;
}

interface AssignMentorsFormProps {
  gatheringId: Gathering['id'];
  loading?: boolean;
  onSuccess: (params: AssignMentors) => any;
  onCancel: () => any;
}

export interface InviteMentor {
  name: string;
  id: MentorId;
  email: Email;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

const parseMentors = (value: Mentor[]): InviteMentor[] => {
  if (value.length === 0) {
    return [];
  }

  return value.map((item) => ({
    name: `${item.firstName} ${item.lastName}`,
    id: item.id,
    email: item.email,
  }));
};

function InviteGatheringMentors({
  gatheringId,
  loading = false,
  onSuccess,
  onCancel,
}: AssignMentorsFormProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { gathering } = useContext(GatheringContext);

  const [loadedMentors, setLoadedMentors] = useState<InviteMentor[]>([]);
  const [initialMentors, setInitialMentors] =
    useState<InviteMentor[]>(loadedMentors);
  const [recommended, setRecommended] = useState<InviteMentor[]>();
  const [displayedMentors, setDisplayedMentors] = useState<InviteMentor[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState<{
    mentors: boolean;
    recommended: boolean;
  }>({ mentors: false, recommended: !recommended });
  const [searchIteration, setSearchIteration] = useState(0);

  const handleSelectedAll = () => {
    setInitialMentors([]);
    setIsSelectedAll(true);
  };

  const handleMentorsSearch = useCallback((searchedMentors: Mentor[]) => {
    setDisplayedMentors(parseMentors(searchedMentors));
  }, []);

  const handleMentorRemove = (mentor: InviteMentor) => {
    setInitialMentors((prevMentors) =>
      prevMentors.filter((prevMentor) => prevMentor.id !== mentor.id),
    );
  };

  const handleMentorAdd = (mentor: InviteMentor) => {
    setInitialMentors((prevMentors) => [...prevMentors, mentor]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const loadInitialMentors = async (gatheringId: Gathering['id']) => {
    try {
      const loadedMentors = await gatheringsAPI.getGatheringMentorList(
        gatheringId,
      );
      const parsedMentors = parseMentors(loadedMentors);
      setLoadedMentors(parsedMentors);
      setInitialMentors([]);

      let i = 0;
      let loaded: Mentor[] = [];
      const requests = [
        () => mentorsAPI.getMentorsByAssignment('notassigned', 0),
        () => mentorsAPI.getMentorsByAssignment('1', 0),
        () => mentorsAPI.getMentorsByAssignment('2', 0),
        () => mentorsAPI.getMentorsByAssignment('morethanthree', 0),
      ];
      while (i < requests.length) {
        try {
          const entities = await requests[i]();
          loaded = [...loaded, ...entities];
        } catch (e: any) {}
        if (loaded.length >= 10) {
          i = requests.length;
        } else {
          i++;
        }
      }

      const loadedMentorsIds = loadedMentors.map(({ id }) => id);
      const parsedRecommendedMentors = parseMentors(loaded).filter(
        (value) => !loadedMentorsIds.includes(value.id),
      );

      setRecommended(parsedRecommendedMentors);
      setIsLoading({
        mentors: false,
        recommended: false,
      });
    } catch (e: any) {
      setIsLoading({
        mentors: false,
        recommended: false,
      });
    }
  };

  const handleSubmit = async ({
    mentorsIds,
  }: {
    mentorsIds: Mentor['id'][];
  }) => {
    const loadedMentorsIds = loadedMentors.map((mentor) => mentor.id);
    const assigned = mentorsIds.filter((id) => !loadedMentorsIds.includes(id));
    const all = initialMentors.filter((mentor) =>
      mentorsIds.includes(mentor.id),
    );

    onSuccess({ assigned, removed: [], all, isAll: isSelectedAll });
  };

  useEffect(() => {
    if (gathering) {
      loadInitialMentors(gathering.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gathering]);

  if (isLoading.mentors || isLoading.recommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    mentorsIds: initialMentors.map((mentor) => mentor.id),
  };

  const filteredDisplayedMentors = displayedMentors.filter(
    (mentor) =>
      !initialMentors.find((initialMentor) => initialMentor.id === mentor.id),
  );
  const filteredRecommendedMentors = (recommended || []).filter(
    (item) => !initialMentors.find((mentor) => mentor.id === item.id),
  );

  const displayedList =
    filteredDisplayedMentors.length > 0
      ? filteredDisplayedMentors
      : filteredRecommendedMentors;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <MentorAutocomplete
              iteration={searchIteration}
              onSearch={handleMentorsSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label={`Add a ${rb('mentor')}`}
                  placeholder={`Enter ${rb('mentor')} name`}
                  onChange={(curretnVakue) => {
                    onChange(curretnVakue);
                    setIsSelectedAll(false);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialMentors.map((mentor) => (
              <Chip
                key={mentor.id}
                label={mentor.name}
                onDelete={() => handleMentorRemove(mentor)}
                className={classes.chipItem}
              />
            ))}
            {isSelectedAll && (
              <Chip
                data-testid='mentor-chip-all'
                key='selected-all'
                label='All'
                onDelete={() => setIsSelectedAll(false)}
                className={classes.chipItem}
              />
            )}
          </div>
          {!isSelectedAll && displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={[rb('mentors-u'), '']}
              rows={displayedList.map((recomItem) => ({
                name: recomItem.name,
                onAdd: () => handleMentorAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>

            <Button data-testid='button-add-all' onClick={handleSelectedAll}>
              Add all
            </Button>
            <Button disabled={loading} type='submit' data-testid='button-apply'>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default InviteGatheringMentors;
