import { createContext, useState, useCallback, useRef } from 'react';
import filesAPI from '../api/files';

export interface ImageProp {
  url: string;
  expiredAt: Date;
}

export interface ImagesContextProps {
  getImage: (imagePath: string) => Promise<string>;
}

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const ImagesContext = createContext<ImagesContextProps>({
  getImage: async () => ''
});

export const ImagesContextProvider = ({ children }: DrawerProviderProps) => {
  const [images, setImages] = useState<{ [x: string]: ImageProp }>({});
  const loadingImages = useRef<{ [x: string]: boolean }>({});

  const getImage = useCallback(
    async (imagePath: string) => {
      const currentDate = new Date();
      if (
        loadingImages.current[imagePath] ||
        (images[imagePath] && currentDate < images[imagePath].expiredAt)
      ) {
        return (images[imagePath] || '').url;
      }
      loadingImages.current[imagePath] = true;
      const url = await filesAPI.getFileLogo(imagePath);
      setImages((prevImages) => ({
        ...prevImages,
        [imagePath]: {
          url,
          expiredAt: new Date(currentDate.getTime() + 10 * 60000)
        }
      }));
      loadingImages.current[imagePath] = false;
      return url;
    },
    [images]
  );

  const contextValue: ImagesContextProps = {
    getImage
  };

  return (
    <ImagesContext.Provider value={contextValue}>
      {children}
    </ImagesContext.Provider>
  );
};
