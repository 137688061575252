// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    advisors: {
      total: number;
      thisWeek: number;
      nextWeek: number;
      afterNextWeek: number;
    };
    communityMembers: {
      total: number;
      joinedLastMonth: number;
      withAppointments: number;
      applicants: number;
    };
    appointments: {
      total: number;
      accepted: number;
      declinedUnconfirmed: number;
    };
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

export type Actions = LoadError | LoadSuccess;
