import { Actions as CommonActions } from '../../common/types/Actions';
import { CommunityMember } from '../../../../api/CommunityMembers/types/CommunityMember';
export * from '../../common/types/Actions';

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: CommunityMember;
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

export type Actions = LoadSuccess | LoadError | CommonActions;
