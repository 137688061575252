import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import { TestId } from '../Testing/TestId';

const useStyles = makeStyles(() => ({
  tags: {
    display: 'flex',
    rowGap: '5px',
    columnGap: '5px',
    flexWrap: 'wrap',
  },
  tag: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    fontFamily: INTER_FONT_FAMILY,
  },
}));

export interface Props {
  className?: string;
  tags: string[];
}

export function Tags({ tags, className }: Props) {
  const classes = useStyles();
  return (
    <TestId testId={'tags-list'}>
      <div className={classNames(classes.tags, className)}>
        {tags.map((v) => (
          <TestId key={v} testId={'tag-item'}>
            <Chip label={<span className={classes.tag}>{v}</span>} />
          </TestId>
        ))}
      </div>
    </TestId>
  );
}
