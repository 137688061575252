import { Field } from 'react-final-form';
import { FormCheckboxList } from '../forms/wrappers';

interface Props {
  value: string;
  name: string;
  label: string;
  onChange: (id: any) => void;
  checked: boolean;
  className?: string;
  classNameLabel?: string;
  testid?: string;
  showError?: boolean;
}

function CheckboxList({
  value,
  name,
  label,
  onChange,
  checked,
  className,
  testid,
  showError,
  ...rest
}: Props) {
  return (
    <Field<string>
      name={name}
      component={FormCheckboxList}
      valueNative={value}
      label={label}
      onChange={onChange}
      checked={checked}
      className={className}
      testid={testid}
      showError={showError}
      {...rest}
    />
  );
}

export default CheckboxList;
