import { ReactElement, ReactNode, useContext } from 'react';
import { UserContext } from '../../contexts/user-context';
import { Role } from '../../api/user/Role';

export interface Props {
  roles: Role[];
  children: ReactNode;
  fallback?: ReactNode;
}

export function WithRole({
  roles,
  children,
  fallback,
}: Props): ReactElement | null {
  const { hasRole } = useContext(UserContext);

  return <>{roles.some(hasRole) ? children : fallback}</>;
}
