import {
  TextField,
  TextFieldProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useState, useCallback, useRef, useEffect } from 'react';
import authAPI from '../../api/auth';
import { COLORS } from '../../theme/variables';

export type FieldProps = TextFieldProps & {
  onChange: (value: any) => any;
  onBlur: (value: any) => any;
  small?: boolean;
  name: string;
  label: string;
  value?: string;
  valid?: boolean;
  type: 'mentor' | 'founder' | 'tenant';
  onValid?: (valid: boolean) => any;
  'data-testid'?: string;
  error: boolean;
};

const useStyles = makeStyles({
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
});

function FieldCheckNewEmail({
  onChange,
  small = false,
  name,
  label,
  value: initialValue,
  type,
  valid,
  InputProps,
  onValid,
  error,
  onBlur,
  ...props
}: FieldProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(initialValue);
  const currentValue = useRef<string>(initialValue || '');
  const prevValue = useRef<string>(initialValue || '');
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | ''>();

  const validateEmail = async (email: string) => {
    try {
      setStatus('loading');
      const entites = await authAPI.checkUsersValidate(email);

      if (entites) {
        setStatus('success');
      } else {
        enqueueSnackbar('Email is already in use', {
          variant: 'error',
        });
        setStatus('error');
      }
    } catch (e: any) {
      setStatus('error');
    }
  };

  const handleChange = (e: any) => {
    const originalValue = e.target.value;
    setStatus('');
    onChange(originalValue);
    setValue(originalValue);
    currentValue.current = originalValue.trim();
  };

  const handleBlur = (e: any) => {
    onBlur(e);
    const trimmedValue = currentValue.current;
    const validTrimmedValue = trimmedValue?.toLowerCase();
    if (
      validTrimmedValue &&
      validTrimmedValue.length > 2 &&
      (initialValue !== value || value !== validTrimmedValue) &&
      valid
    ) {
      if (trimmedValue !== validTrimmedValue) {
        setValue(validTrimmedValue);
      }
      setStatus('loading');
      validateEmail(validTrimmedValue);
    } else {
      setStatus('');
    }
    if (!valid && validTrimmedValue !== trimmedValue) {
      setValue(validTrimmedValue);
    }
    prevValue.current = validTrimmedValue;
  };

  const getEndAdornment = useCallback(() => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!initialValue) {
      setValue('');
    }
  }, [initialValue]);

  useEffect(() => {
    if (onValid) {
      const valid = status !== 'error' && status !== 'loading';
      onValid(valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <TextField
      {...props}
      name={name}
      label={label}
      error={error}
      className={cn({
        [classes.error]: status === 'error' || !valid,
        [classes.small]: small,
      })}
      onChange={handleChange}
      onFocus={() => {
        setStatus('');
      }}
      value={value}
      variant='outlined'
      fullWidth
      onBlur={handleBlur}
      InputProps={{
        ...(InputProps ? InputProps : {}),
        endAdornment: getEndAdornment(),
      }}
    />
  );
}

export default FieldCheckNewEmail;
