import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import {
  Redirect,
  Switch,
  Route,
  useParams,
  useLocation,
  Link,
} from 'react-router-dom';
import { Founder } from '../api/founders';
import { PageLoader } from '../components/common';
import { FounderContext, FounderProvider } from '../contexts/founder-context';
import { UserContext } from '../contexts/user-context';
import { getRoutePath, Pages } from '../router/constants';
import { COLORS } from '../theme/variables';
import FounderAdditionalInfoPage from './founder/founder-additional-info-page';
import FounderDetailsPage from './founder/founder-details-page';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
  },
  container_loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paper: {
    padding: 0,
    width: '100%',
    maxWidth: 882,
  },
  content: {
    padding: 40,
  },
  tabs: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },
}));

function FounderExternalPage() {
  const classes = useStyles();
  const location = useLocation();
  const { founderId } = useParams<{ founderId: Founder['id'] }>();
  const { user, loadUser } = useContext(UserContext);
  const {
    founder,
    isFounderLoading,
    founderSpecializations,
    loadFounder,
    loadFounderSpecializations,
  } = useContext(FounderContext);

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [user, loadUser]);

  // load founder's info
  useEffect(() => {
    if (!founder) {
      loadFounder(founderId);
    }
  }, [founderId, founder, loadFounder]);

  // load founder's specializations
  useEffect(() => {
    if (!founderSpecializations) {
      loadFounderSpecializations(founderId);
    }
  }, [founderSpecializations, founderId, loadFounderSpecializations]);

  if (isFounderLoading) {
    return (
      <div className={classes.container_loading}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.tabs}>
          <Tabs
            value={location.pathname}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='on'>
            <Tab
              label='Details'
              component={Link}
              to={getRoutePath(Pages.FOUNDER_EXTERNAL_DETAILS, { founderId })}
              value={getRoutePath(Pages.FOUNDER_EXTERNAL_DETAILS, {
                founderId,
              })}
            />
            <Tab
              label='Additional Info'
              component={Link}
              to={getRoutePath(Pages.FOUNDER_EXTERNAL_ADDITIONAL, {
                founderId,
              })}
              value={getRoutePath(Pages.FOUNDER_EXTERNAL_ADDITIONAL, {
                founderId,
              })}
            />
          </Tabs>
        </div>
        <div className={classes.content}>
          <Switch>
            <Route
              exact
              path={Pages.FOUNDER_EXTERNAL_DETAILS}
              component={FounderDetailsPage}
            />
            <Route
              exact
              path={Pages.FOUNDER_EXTERNAL_ADDITIONAL}
              component={FounderAdditionalInfoPage}
            />
            <Redirect to={Pages.FOUNDER_EXTERNAL_DETAILS} />
          </Switch>
        </div>
      </Paper>
    </div>
  );
}

function withFounderProvider(Component: React.FunctionComponent) {
  return () => (
    <FounderProvider>
      <Component />
    </FounderProvider>
  );
}

export default withFounderProvider(FounderExternalPage);
