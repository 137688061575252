// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region Confirm
export interface Confirm {
  type: 'Confirm';
}

export const confirm = (): Confirm => ({
  type: 'Confirm',
});
// endregion

// region Decline
export interface Decline {
  type: 'Decline';
}

export const decline = (): Decline => ({
  type: 'Decline',
});
// endregion

// region Fail
export interface Fail {
  type: 'Fail';
}

export const fail = (): Fail => ({
  type: 'Fail',
});
// endregion

// region Success
export interface Success {
  type: 'Success';
}

export const success = (): Success => ({
  type: 'Success',
});
// endregion

export type Actions = Submit | Confirm | Decline | Fail | Success;
