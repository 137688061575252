import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import eventsAPI, {
  EventId,
  EventNote as EventNoteType,
} from '../../api/events';
import {
  NewNote,
  Note,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import { SessionContext } from '../../contexts/session-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function FounderNotesPage() {
  const classes = useStyles();
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const { session } = useContext(SessionContext);

  const [updatingNotesIds, setUpdaingNotesIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingNotesIds, setDeletingNotesIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<EventNoteType[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateNote = useCallback(
    async (note: { contents: string; attachmentRefs: string }, callback) => {
      try {
        setIsCreating(true);
        if (session) {
          const createdNotes = (await eventsAPI.createNote({
            eventId: session.id,
            contents: note.contents,
            attachmentRefs: note.attachmentRefs,
          })) as EventNoteType;
          setNotes((prev) => [createdNotes, ...prev]);
        }
        setIsCreating(false);
        callback();
        enqueueSnackbar('The note was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [session, enqueueSnackbar],
  );

  const handleNoteUpdate = useCallback(
    async (note: EventNoteType, callback) => {
      try {
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: true }));
        if (session) {
          await eventsAPI.updateNote(note);
          setNotes((prev) =>
            prev.map((sessionNotes) =>
              sessionNotes.id === note.id ? note : sessionNotes,
            ),
          );
        }
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: false }));
        callback();
        enqueueSnackbar('The note was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: false }));
      }
    },
    [session, enqueueSnackbar],
  );

  const handleNoteRemove = useCallback(
    async (note: EventNoteType, callback: () => any) => {
      try {
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: true }));
        if (session) {
          await eventsAPI.removeNote(session.id, note.id);
          setNotes((prev) =>
            prev.filter((sessionNote) => sessionNote.id !== note.id),
          );
        }
        callback();
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: false }));
        enqueueSnackbar('The note was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: false }));
      }
    },
    [session, enqueueSnackbar],
  );

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        const loadedFileURL = await eventsAPI.attachFileToNote(sessionId, file);
        return loadedFileURL;
      } catch (e: any) {
        return '';
      }
    },
    [sessionId],
  );

  const loadNotes = async (sessionId: string) => {
    try {
      setIsLoading(true);
      const responseNotes = await eventsAPI.getNotes(sessionId);

      const sortNotes = responseNotes.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setNotes(sortNotes);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadNotes(sessionId);
  }, [sessionId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <NewNote
        creating={isCreating}
        onUploadFile={handleUploadFile}
        onCreate={handleCreateNote}
      />
      <div>
        {notes.map((note, index) => (
          <Note<EventNoteType>
            key={note.id}
            note={note}
            updating={updatingNotesIds[note.id]}
            deleting={deletingNotesIds[note.id]}
            onEdit={handleNoteUpdate}
            onUploadFile={handleUploadFile}
            onRemove={handleNoteRemove}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default FounderNotesPage;
