import {
  makeStyles,
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';

export type TextFieldProps = MaterialTextFieldProps & {
  small?: boolean;
  readOnly?: boolean;
  errorText?: string;
};

const useStyles = makeStyles({
  errorText: {
    color: COLORS.COLOR_RED_BASE,
  },
  root: {
    '&[aria-invalid="true"] .MuiInputLabel-root': {
      color: COLORS.COLOR_RED_BASE,
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root fieldset': {
      borderColor: COLORS.COLOR_RED_BASE,
    },
  },
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box',
    },
  },
});

export function TextField({
  small,
  errorText,
  className,
  error,
  InputProps,
  ...props
}: TextFieldProps) {
  const classes = useStyles();

  return (
    <div>
      <MaterialTextField
        className={cn(className, classes.root, {
          [classes.small]: small,
        })}
        variant='outlined'
        fullWidth
        aria-invalid={error}
        InputProps={InputProps}
        {...props}
      />
      {error && errorText ? (
        <Typography variant={'caption'} className={classes.errorText}>
          {errorText}
        </Typography>
      ) : null}
    </div>
  );
}

export default TextField;
