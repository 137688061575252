import { Component, ReactElement } from 'react';
import { findDOMNode } from 'react-dom';

type Data = {
  [k in `data-${string | number}`]: string;
};

interface Props<T extends ReactElement> extends Data {
  children: T;
  testId: string;
}

export class TestId<T extends ReactElement> extends Component<Props<T>> {
  componentDidMount() {
    if ('Cypress' in window) {
      const node = findDOMNode(this);

      if (node && 'setAttribute' in node) {
        node.setAttribute('data-testid', this.props.testId);

        Object.entries(this.props).forEach(([a, b]) => {
          if (a.startsWith('data-')) {
            node.setAttribute(a, b as string);
          }
        });
      }
    }
  }

  render(): T {
    return this.props.children;
  }
}
