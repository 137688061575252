import { TenantId } from '../../../../../../api/tenants/Tenant';
import { Guest, LoggedIn, User } from './User';

const _key: unique symbol = Symbol();

// region Public
export class _Public<U extends User> {
  constructor(private key: typeof _key, public readonly user: U) {}
}

export type PublicGuest = _Public<Guest>;
export type PublicLoggedIn = _Public<LoggedIn>;

export type Public = PublicGuest | PublicLoggedIn;

export const isPublic = (v: Strategy): v is Public => v instanceof _Public;

export const isGuest = (v: Public): v is PublicGuest => v.user.type === 'Guest';
export const isLoggedIn = (v: Public): v is PublicLoggedIn =>
  v.user.type === 'LoggedIn';
// endregion

// region Private
export class Private {
  constructor(private key: typeof _key, public readonly user: LoggedIn) {}
}

export const isPrivate = (v: Strategy): v is Private => v instanceof Private;
// endregion

// region Strategy
export type Strategy = Public | Private;

export function strategy(
  user: User,
  tenantId: TenantId,
  strictOfficeHoursScheduling: boolean,
): Strategy | undefined {
  switch (strictOfficeHoursScheduling) {
    case false:
      return new _Public(_key, user) as Public;
    case true: {
      switch (user.type) {
        case 'LoggedIn':
          return user.tenantId === tenantId
            ? new Private(_key, user)
            : undefined;
        case 'Guest':
          return undefined;
      }
    }
  }
}
// endregion
