import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/styles';
import { parse as parseQuery } from 'query-string';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import reportsAPI, { GeneralReport } from '../../api/reports';
import { Button, ModalCustomizeGeneralReport } from '../../components/common';
import { FormValues } from '../../components/common/modal-customize-general-report';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import ReportsGeneralScreen, {
  ParamsReport,
} from '../../components/reports/reports-details-general';
import { GeneralReportContext } from '../../contexts/general-report';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { getFormattedGeneralReportDate } from '../../utils/date';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,

    '@media print': {
      boxShadow: 'none',
    },
  },
  content: {
    padding: 40,

    '@media print': {
      padding: 0,
    },
  },
});

const getInitialValues = (params: ParamsReport): FormValues => {
  return {
    includeLogo: params.includeLogo ? 'true' : 'false',
    includeSummary: params.includeSummary ? 'true' : 'false',
  };
};

const getValidatedParams = (params: FormValues): ParamsReport => {
  return {
    includeLogo: params.includeLogo === 'true',
    includeSummary: params.includeSummary === 'true',
  };
};

function ReportsGeneralPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const history = useHistory();
  const { reportDates, isLoadingDates, loadDates } =
    useContext(GeneralReportContext);
  const [reportLoading, setReportLoading] = useState(false);
  const isLoading = reportLoading || isLoadingDates;
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const [report, setReport] = useState<GeneralReport>();
  const selectedDate = useMemo(
    () => parseQuery(location.search).date || undefined,
    [location.search],
  );
  const reportDate = useMemo(() => {
    if (selectedDate) {
      return selectedDate;
    }
    return report
      ? getFormattedGeneralReportDate(report.creationDate)
      : undefined;
  }, [report, selectedDate]);
  const [paramsReport, setParamsReport] = useState<ParamsReport>({
    includeLogo: true,
    includeSummary: true,
  });
  const printContent = () => {
    window.print();
  };

  const initialValuesForm = getInitialValues(paramsReport);

  const submitCustomizeReport = (params: FormValues) => {
    const validParams = getValidatedParams(params);
    setParamsReport(validParams);
  };

  const handleChangeDate = (date: string) => {
    if (date !== selectedDate) {
      history.replace(`${location.pathname}?date=${date}`);
    }
  };

  const loadReports = async (date?: string | string[]) => {
    try {
      setReportLoading(true);
      const selectedDate = date ? (date as string) : undefined;
      const responseReport = await reportsAPI.getGeneralReportItem(
        selectedDate,
      );
      setReport(responseReport);
      setReportLoading(false);
    } catch (e: any) {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedDate) {
      loadReports(reportDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reportDates) {
      loadDates();
    }
  }, [reportDates, loadDates]);

  useEffect(() => {
    if (selectedDate) {
      loadReports(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='Monthly program report'
        backLink={
          <Tooltip title='Back'>
            <IconButton
              component={Link}
              to={prevPath || Pages.DASHBOARD}
              data-testid='button-back-reports'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
        actions={[
          {
            key: 'print',
            label: 'Print',
            hidden: !report,
            component: (
              <Button
                variant='outlined'
                data-testid='print-button'
                className={CLASS_TRACKING.ENTITY_ACTION}
                startIcon={<PrintIcon />}
                onClick={printContent}>
                Print
              </Button>
            ),
          },
          {
            key: 'customize',
            label: 'Customize Report',
            component: (
              <ModalCustomizeGeneralReport
                initialValues={initialValuesForm}
                selectedDate={reportDate as string}
                reportDates={reportDates}
                onSubmit={submitCustomizeReport}
                onChangeDate={handleChangeDate}
                loading={isLoading}
              />
            ),
          },
        ]}
      />
      <Paper className={classes.container} elevation={1}>
        <div className={classes.content}>
          <ReportsGeneralScreen
            paramsReport={paramsReport}
            report={report}
            loading={isLoading}
          />
        </div>
      </Paper>
    </BaseLayout>
  );
}

export default ReportsGeneralPage;
