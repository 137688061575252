import { TenantId } from '../../api/tenants/Tenant';
import { Advisor, AdvisorId } from '../../api/Advisors/types/Advisor';

export const getAdvisors = (id: TenantId, page: number): Advisor[] => {
  return Array.from(Array(10).keys()).map(
    (i): Advisor =>
      ({
        id: `${id}-${i}-${page}` as AdvisorId,
        email: `email${i}${page}@mail.com`,
        lastName: `Last ${i}${page}`,
        firstName: `First ${i}${page}`,
        address: `Address ${i}${page}`,
        status: 'Archived',
        phone: `${i}6666-7567-${page}`,
      } as Advisor),
  );
};
