import { useState, useRef } from 'react';
import cn from 'classnames';
import { makeStyles, Popover } from '@material-ui/core';
import { Text } from '../common';

interface VentureRatingPopupProps {
  list: string;
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 320,
    boxSizing: 'border-box'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    '& + &': {
      marginTop: 12
    }
  }
});

function VentureRatingPopup({
  list,
  children,
  className
}: VentureRatingPopupProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const debouncedTimer = useRef<number>();

  const handleOpenInfo = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.target as HTMLElement);
    if (list) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      handleOpenInfo(event);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={cn(classes.wrapper, className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        <Text variant='normal2'>{list}</Text>
      </Popover>
    </>
  );
}

export default VentureRatingPopup;
