import { FounderStatus } from '../api/founders';
import { MentorStatus } from '../api/mentors';
import { Status } from '../api/ventures/types/Venture';

export const lengthField = {
  firstName: 250,
  lastName: 250,
  name: 250,
  linkedIn: 250,
  address: 1024,
  country: 250,
  zip: 12,
  state: 250,
  city: 250,
  email: 250,
  phone: 250,
  logoFileName: 75,
  url: 1024,
  description: 250,
  tenantPhone: 50,
  programName: 250,
  tenantDescription: 512,
  ventureUpdate: 1024,
  sessionNotes: 2048,
  additionalInfo: 4096,
  summary: 512,
  whyBecomeMentor: 500,
  location: 2048,
  fileName: 128,
  feedback: 2048,
};

export const FILE_SIZE_LIMIT_MB = 20;
export const FILE_SIZE_LIMIT = FILE_SIZE_LIMIT_MB * 1024 * 1024; // in bytes

export const GENDER_MENTOR = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'PNS',
    label: 'Prefer not to say',
  },
];

export const GENDER_VENTURE = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'O',
    label: 'Other',
  },
];

export const RACE = [
  {
    value: '',
    label: 'None',
  },
  {
    label: 'American Indian or Alaska Native',
    value: 'AIOAN',
  },
  {
    label: 'Asian',
    value: 'A',
  },
  {
    label: 'Black or African American',
    value: 'BOAA',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NWOOPS',
  },
  {
    label: 'White',
    value: 'W',
  },
  {
    label: 'Hispanic or Latino',
    value: 'HOL',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const SALES_CHALLENGES = [
  {
    label: 'Obtaining first 1-10 paying customers',
    value: 'OBT10CUST',
  },
  {
    label: 'Building a winning sales team',
    value: 'BWST',
  },
  {
    label: 'Winning a contract with a big retailer (Walmart, Target, etc)',
    value: 'WCWBR',
  },
  {
    label: 'Winning a government contract',
    value: 'WGC',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const MARKETING_CHALLENGES = [
  {
    label: 'Fixing a business model for a better product/market fit',
    value: 'FBM',
  },
  {
    label: 'Creating an effective brand story',
    value: 'CEBS',
  },
  {
    label: 'Scaling / automating digital marketing efforts',
    value: 'SADME',
  },
  {
    label: 'Organizing a PR campaign',
    value: 'OPRC',
  },
  {
    label: 'Launching a SaaS product',
    value: 'LSP',
  },
  {
    label: 'Launching a physical product',
    value: 'LPP',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const FUNDRAISING_CHALLENGES = [
  {
    label: 'Bootstrapping a startup',
    value: 'BS',
  },
  {
    label: 'Raising money from investors',
    value: 'RMFI',
  },
  {
    label: 'Writing a winning application for a grant',
    value: 'WWAG',
  },
  {
    label: 'Fundraising for a non-profit',
    value: 'FNP',
  },
  {
    label: 'Forecasting needs or Runway management',
    value: 'FNORM',
  },
  {
    label: 'Crowdfunding',
    value: 'CROW',
  },
  {
    label: 'Raising debt',
    value: 'RB',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const BUSINESS_CHALLENGES = [
  {
    label: 'Hiring first employees',
    value: 'HFE',
  },
  {
    label: 'Assembling a winning team',
    value: 'AWT',
  },
  {
    label: 'Dealing with offshore manufacturers',
    value: 'DWOM',
  },
  {
    label: 'Assessing tax consequences of business decisions',
    value: 'ATCBD',
  },
  {
    label: 'Streamlining operational processes',
    value: 'SOP',
  },
  {
    label: 'Selling the business',
    value: 'SB',
  },
  {
    label: 'Resolving legal questions',
    value: 'RLQ',
  },
  {
    label: 'Scaling operations / Organizational development',
    value: 'SOOD',
  },
  {
    label: 'Financial modeling',
    value: 'FM',
  },
  {
    label: 'Managing change',
    value: 'MC',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const DONE_ALREADY = [
  {
    label: 'Created a business plan / pitch deck',
    value: 'CBP/PD',
  },
  {
    label: 'Interviewed potential clients',
    value: 'IPC',
  },
  {
    label: 'Created a product / service that customers can use',
    value: 'CP/STCCU',
  },
  {
    label: 'Attracted non-paying customers',
    value: 'ANPC',
  },
  {
    label: 'Attracted paying customers',
    value: 'APC',
  },
  {
    label: 'Raised money from friends/family',
    value: 'RMFF',
  },
  {
    label:
      'Raised money from grants / professional angel investors / VCs / crowdfunding',
    value: 'RMFG',
  },
];

export const SUPPORT_INTERESTED_RECEIVING = [
  {
    label: 'Introductions to other founders',
    value: 'IOF',
  },
  {
    label: 'Introductions to prospective customers',
    value: 'IPC',
  },
  {
    label: 'Introductions to potential investors',
    value: 'IPI',
  },
  {
    label: 'Connections to professional advisors (tax, legal, marketing, etc)',
    value: 'CPA',
  },
  {
    label: 'Business education courses',
    value: 'BEC',
  },
  {
    label: 'Information on grants and loans',
    value: 'IGL',
  },
  {
    label: 'Coworking spaces',
    value: 'CS',
  },
  {
    label: 'Pitch Competitions / Events',
    value: 'PC/E',
  },
  {
    label: 'Accelerator / Incubator programs',
    value: 'A/IP',
  },
  {
    label: 'Mentorship',
    value: 'M',
  },
];

export const COUNT_PEOPLE = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3-5',
    value: '3-5',
  },
  {
    label: '5-20',
    value: '5-20',
  },
  {
    label: '21 and more',
    value: '21M',
  },
];

export const MARKET_SIZE = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: '$100,000 - $500,000',
    value: '100K+',
  },
  {
    label: '$500,001 - $5,000,000',
    value: '500K+',
  },
  {
    label: '$6M - $50M',
    value: '6M+',
  },
  {
    label: '$51M - $200M',
    value: '51M+',
  },
  {
    label: '$201M - $1B',
    value: '201M+',
  },
  {
    label: 'Over $1B',
    value: '1B+',
  },
];

export const REASON_TO_START_NOW = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'It should create stable income for me and my family',
    value: 'SCSIFMAMF',
  },
  {
    label: 'It should become the next big thing',
    value: 'SBNBT',
  },
];

export const GROWTH_RATIO = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: "I'm not looking to grow it",
    value: 'NLGI',
  },
  {
    label: '1-15% a year',
    value: '1-15',
  },
  {
    label: '16-50% a year',
    value: '16-50',
  },
  {
    label: 'Over 50% a year',
    value: '50+',
  },
];

export const DEDICATION = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'I work only on this business, no other jobs, gigs, etc.',
    value: 'WOB',
  },
  {
    label: 'I also have a full-time job',
    value: 'HFTJ',
  },
  {
    label: 'I also have a part-time job',
    value: 'HPTJ',
  },
  {
    label: 'I also have a different business / or businesses',
    value: 'HDB',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const RADIO_SELECT = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const SELECT_NONE = {
  value: '',
  label: 'None',
};

export const BR_TEXT = '<div><br></div>';

export const REPORT_VENTURE_UPDATE = `<b>Venture Progress Report:</b>${BR_TEXT}`;
export const REPORT_BEFORE_NEXT_SESSION = `<b>What are the agreed upon things for you to do before the next session?</b>${BR_TEXT}`;
export const REPORT_FOUNDER_OTHER = `<b>Other notes / takeaways</b>${BR_TEXT}`;

export const ACHIEVE_MENTORING_SESSION = `<b>What do you want to achieve from this mentoring session?</b>${BR_TEXT}`;
export const HAPPENED_LAST_SESSION = `<b>What 3 things happened since the last session?</b>${BR_TEXT}`;
export const GOING_HAPPEN = `<b>What 3 things are going to happen in the next 30 days?</b>${BR_TEXT}`;
export const ADDITIONAL_COMMENTS = `<b>Any additional comments</b>${BR_TEXT}`;

export const TYPE_ISSUE = ['NO_AGENDA', 'NO_REPORT', 'NO_NOTES', 'UNCONFIRMED'];

export const ISSUES = {
  startCreateMessange: '$name added $issue Issue to the session on $date. ',
  startResolvedMessange: '$name resolved $issue Issue on $date.',
  issuesMessanges: {
    NO_AGENDA: {
      type: 'Agenda',
      text: 'Agenda Issue means that no agenda was submitted 48 hours before the session.',
    },
    NO_REPORT: {
      type: 'Mentor Report',
      text: 'Mentor Issue means that no Lead Mentor report was submitted 48 hours after the session.',
    },
    NO_NOTES: {
      type: 'Founder Notes',
      text: 'Founder Issue means that no Founder Notes were submitted 48 hours after the session.',
    },
    UNCONFIRMED: {
      type: 'Unconfirmed',
      text: "Unconfirmed Issue means that either the founder didn't accept the meeting, or less than 2 mentors did.",
    },
  },
};

export const CANCELLATION_REASON = '<b>Cancellation Reason:</b> ';
export const RESCHEDULING_REASON = '<b>Rescheduling Reason:</b> ';

export const REASON_FOR_EXIT = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'M&A',
    value: 'MNA',
  },
  {
    label: 'Graduation',
    value: 'GRADUATION',
  },
  {
    label: 'Not a fit',
    value: 'NOT_A_FIT',
  },
  {
    label: 'Shut down',
    value: 'SHUT_DOWN',
  },
];

export const RATE_SESSION = `<b>How would you rate this session?</b>${BR_TEXT}`;
export const RATE_PROGRESS_VENTURE = `<b>How would you rate the progress of the venture?</b>${BR_TEXT}`;
export const NOTES_TITLE = `<b>Notes</b>${BR_TEXT}`;

export const IMPACTFUL_MENTOR_LEAD_MENTOR = `<b>The most impactful mentor during the session:</b>${BR_TEXT}`;
export const IMPACTFUL_MENTOR = `<b>Most impactful mentor:</b>${BR_TEXT}`;

export const NOT_SELECTED = 'not selected';

export const RATE_PROGRESS_VENTURE_AGENDA = `<b>How would you rate your progress as a founder in relation to your personal goals?</b>${BR_TEXT}`;
export const TITLE_LEAD_MENTOR = 'Lead Mentor Report by';
export const TITLE_MENTOR_ASSESSMENT = 'Mentor Note by';
export const TITLE_FOUNDER_NOTE = 'Founder Notes by';
export const TITLE_AGENDA = 'Agenda by';

export const FOUNDERS_PRESENT = `<b>Founders who were present:</b>${BR_TEXT}`;
export const MENTORS_PRESENT = `<b>Mentors who were present:</b>${BR_TEXT}`;

export const NEXT_SESSION_DATE = `<b>Next session date:</b>${BR_TEXT}`;

export const GATHERING_AUDIENCE = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'FOUNDERS',
    label: 'Founders',
  },
  {
    value: 'MENTORS',
    label: 'Mentors',
  },
];

export const GATHERING_STATUSES = [
  {
    value: 'CREATED',
    label: 'Created',
  },
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
];

export const STATUSES_VENTURES: Array<{ label: string; value: Status }> = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Archived',
    value: 'Archived',
  },
  {
    label: 'Applicant',
    value: 'Applicant',
  },
  {
    label: 'Rejected',
    value: 'Rejected',
  },
];

export const STATUSES_MENTORS: Array<{ label: string; value: MentorStatus }> = [
  {
    label: 'Active',
    value: MentorStatus.ACTIVE,
  },
  {
    label: 'Archived',
    value: MentorStatus.ARCHIVED,
  },
  {
    label: 'Applicant',
    value: MentorStatus.APPLICANT,
  },
  {
    label: 'Rejected',
    value: MentorStatus.REJECTED,
  },
];

export const STATUSES_FOUNDERS: Array<{
  label: string;
  value: FounderStatus;
}> = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Archived',
    value: 'Archived',
  },
  {
    label: 'Applicant',
    value: 'Applicant',
  },
  {
    label: 'Rejected',
    value: 'Rejected',
  },
];

export const GENDER_WIZARD = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Prefer not to say',
    value: 'PNS',
  },
];

export const RACE_WIZARD = [
  {
    label: 'American Indian on Alaska Native',
    value: 'AIOAN',
  },
  {
    label: 'Asian',
    value: 'A',
  },
  {
    label: 'Black or African American',
    value: 'BOAA',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NWOOPS',
  },
  {
    label: 'White',
    value: 'W',
  },
  {
    label: 'Hispanic or Latino',
    value: 'HOL',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const VETERAN_MENTOR_WIZARD = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const INDUSTRIES_MENTOR_WIZARD = [
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Energy',
    value: 'Energy',
  },
  {
    label: 'Transport',
    value: 'Transport',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Food & Beverage',
    value: 'Food & Beverage',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    label: 'Professional services (legal, marketing, consulting, web, etc)',
    value: 'Agriculture',
  },
];

export const SALES_MENTOR_WIZARD = [
  {
    label: 'Obtaining first 1-10 paying customers',
    value: 'OBT10CUST',
  },
  {
    label: 'Building a winning sales team',
    value: 'BWST',
  },
  {
    label: 'Winning a contract with a big retailer (Walmart, Target, etc)',
    value: 'WCWBR',
  },
  {
    label: 'Winning a government contract',
    value: 'WGC',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const MARKETING_MENTOR_WIZARD = [
  {
    label: 'Fixing a business model for a better product/market fit',
    value: 'FBM',
  },
  {
    label: 'Creating an effective brand story',
    value: 'CEBS',
  },
  {
    label: 'Scaling / automating digital marketing efforts',
    value: 'SADME',
  },
  {
    label: 'Organizing a PR campaign',
    value: 'OPRC',
  },
  {
    label: 'Launching a SaaS product',
    value: 'LSP',
  },
  {
    label: 'Launching a physical product',
    value: 'LPP',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const BUSINESS_MENTOR_WIZARD = [
  {
    label: 'Hiring first employees',
    value: 'HFE',
  },
  {
    label: 'Assembling a winning team',
    value: 'AWT',
  },
  {
    label: 'Dealing with offshore manufacturers',
    value: 'DWOM',
  },
  {
    label: 'Assessing tax consequences of business decisions',
    value: 'ATCBD',
  },
  {
    label: 'Streamlining operational processes',
    value: 'SOP',
  },
  {
    label: 'Selling the business',
    value: 'SB',
  },
  {
    label: 'Resolving legal questions',
    value: 'RLQ',
  },
  {
    label: 'Scaling operations / Organizational development',
    value: 'SOOD',
  },
  {
    label: 'Financial modeling',
    value: 'FM',
  },
  {
    label: 'Managing change',
    value: 'MC',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

export const FUNDRAISING_MENTOR_WIZARD = [
  {
    label: 'Bootstrapping a startup',
    value: 'BS',
  },
  {
    label: 'Raising money from investors',
    value: 'RMFI',
  },
  {
    label: 'Writing a winning application for a grant',
    value: 'WWAG',
  },
  {
    label: 'Fundraising for a non-profit',
    value: 'FNP',
  },
  {
    label: 'Forecasting needs or Runway management',
    value: 'FNORM',
  },
  {
    label: 'Crowdfunding',
    value: 'CROW',
  },
  {
    label: 'Raising debt',
    value: 'RB',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];
