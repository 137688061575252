import ChatIcon from '@material-ui/icons/Chat';
import { makeStyles } from '@material-ui/styles';
import { Button, Modal } from '.';
import FeedbackForm from '../forms/feedback-form';

const useStyles = makeStyles({
  feedbackButton: {
    position: 'fixed',
    bottom: 24,
    left: 24,
    zIndex: 10,
    width: 56,
    height: 56,
    minWidth: 56,
    padding: 0,
    borderRadius: '50%',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  chatIcon: {
    width: 32,
    height: 32,
  },
});

function FeedbackButton() {
  const classes = useStyles();
  return (
    <Modal
      title={'Give feedback'}
      caption='Help us improve Traction5!'
      contentRenderer={({ handleClose }) => (
        <FeedbackForm handleClose={handleClose} />
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          onClick={onClick}
          className={classes.feedbackButton}
          data-testid='feedback-button'
          variant='contained'
          color='primary'
          startIcon={<ChatIcon className={classes.chatIcon} />}
        />
      )}
      width={500}
    />
  );
}

export default FeedbackButton;
