import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import authAPI from '../../api/auth';
import { TenantId } from '../../api/tenants/Tenant';
import { lengthField } from '../../utils/form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { yupValidate } from '../../utils/yup';
import { Button, FormGroup } from '../common';
import { TextFieldWrapper } from './wrappers';

interface UnsubscribeFormProps {
  tenantId: TenantId;
}

interface UnsubscribeFormValues {
  email: string;
}

const useStyles = makeStyles({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
  actionsBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});

const initialValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().max(256).email().required(),
});

function UnsubscribeForm({ tenantId }: UnsubscribeFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: UnsubscribeFormValues) => {
    const { email } = values;
    try {
      await authAPI.unsubscribeUser(tenantId, email);
      enqueueSnackbar('You have successfully unsubscribed.', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again later.', {
        variant: 'error',
      });
    }
  };

  return (
    <Form
      validate={yupValidate(validationSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
      render={(formProps) => {
        return (
          <form noValidate>
            <FormGroup>
              <Field<string>
                name='email'
                disabled={!!initialValues?.email}
                component={TextFieldWrapper}
                label='Email*'
                testid='unsubscribe-email-input'
                formatOnBlur
                format={(value: string) => {
                  return value ? value.toLowerCase() : value;
                }}
                InputProps={{
                  inputProps: {
                    maxLength: lengthField.email,
                  },
                }}
              />
            </FormGroup>
            <div className={classes.actionsBlock}>
              <Button
                onClick={formProps.handleSubmit}
                className={CLASS_TRACKING.INTERNAL_ACTION}
                disabled={formProps.submitting}
                startIcon={<CheckIcon />}>
                {formProps.submitting ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
}

export default UnsubscribeForm;
