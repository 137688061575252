// region FetchFail
import {
  Answer,
  AnswerId
} from '../../../../api/ventures/quarterlyrecords/customanswers/Answer';

export interface FetchFail {
  type: 'FetchFail';
  payload: string;
}

export const fetchFail = (payload: FetchFail['payload']): FetchFail => ({
  type: 'FetchFail',
  payload
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: 'FetchSuccess';
  payload: Answer[];
}

export const fetchSuccess = (
  payload: FetchSuccess['payload']
): FetchSuccess => ({
  type: 'FetchSuccess',
  payload
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: AnswerId;
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload
});
// endregion

// region RemoveApprove
export interface RemoveApprove {
  type: 'RemoveApprove';
}

export const removeApprove = (): RemoveApprove => ({
  type: 'RemoveApprove'
});
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: 'RemoveDecline';
}

export const removeDecline = (): RemoveDecline => ({
  type: 'RemoveDecline'
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
}

export const removeSuccess = (): RemoveSuccess => ({
  type: 'RemoveSuccess'
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: 'RemoveFail';
  payload: string;
}

export const removeFail = (payload: RemoveFail['payload']): RemoveFail => ({
  type: 'RemoveFail',
  payload
});
// endregion

// region Reset
export interface Reset {
  type: 'Reset';
}

export const reset = (): Reset => ({
  type: 'Reset'
});
// endregion

export type Actions =
  | FetchFail
  | FetchSuccess
  | Remove
  | RemoveApprove
  | RemoveDecline
  | RemoveSuccess
  | RemoveFail
  | Reset;
