import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Form, Field } from 'react-final-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getTextWithoutTags } from '../../utils/functions';
import { Button, FormGroup, FormButtons, BeforeUnload } from '../common';
import { FormSelect, TextFieldWysiwyg } from './wrappers';

export interface Values {
  type: 'Mentor' | 'Founder';
  body: string;
}

type Errors = {
  [K in keyof Values]?: string;
};

interface ConsentFormProps {
  title: string;
  loading?: boolean;
  consent?: {
    type: 'Mentor' | 'Founder';
    body: string;
  };
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  field: {
    '& [name="contents"]': {
      minHeight: 107,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
}));

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.body) {
    errors.body = 'Required';
  }
  return errors;
};

function ConsentForm({
  title,
  consent,
  loading = false,
  onSubmit,
  onCancel,
}: ConsentFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const TYPES = [
    {
      label: 'Founder',
      value: 'Founder',
    },
    {
      label: rb('mentor-u'),
      value: 'Mentor',
    },
  ];

  const initialValues: Values = {
    body: consent?.body || '',
    type: consent?.type || (TYPES[0].value as 'Founder'),
  };
  const handleSubmit = (values: Values) => {
    const cleanText = getTextWithoutTags(values.body);

    if (cleanText.length > 5000) {
      enqueueSnackbar('Body should not exceed 5000 characters.', {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      return;
    }

    onSubmit(values);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateForm}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <FormGroup>
              <Field<string>
                className={classes.field}
                name='type'
                component={FormSelect}
                label='Type'
                options={TYPES}
                testid='form-consent-type'
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                className={classes.field}
                name='body'
                component={TextFieldWysiwyg}
                placeholder='Body'
                label='Body'
                multiline
                testid='form-consent-text'
              />
            </FormGroup>
            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='form-consent-submit'
                type='submit'
                disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='outlined' onClick={handleCancel}>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default ConsentForm;
