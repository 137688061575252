import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { memo } from 'react';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import {
  CustomOption,
  NewCustomOption,
} from '../../api/CustomQuestion/types/CustomOption';
import { useCustomQuestionContext } from '../../contexts/custom-questions-context';
import { COLORS } from '../../theme/variables';
import { Text } from '../common';
import { CustomOptionCard } from './CustomOptionCard';
import { CustomOptionsErrors } from './CustomQuestionModal';

type CustomOptionsListProps = {
  items: (CustomOption | NewCustomOption)[];
  onDragEnd: OnDragEndResponder;
  onRemove: (id: string) => void;
  onChange: (id: string, label: string) => void;
  disabled?: boolean;
  showErrors?: boolean;
  errors?: CustomOptionsErrors;
};

const useStyles = makeStyles({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 16,
  },
  noOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  noOptionsText: {
    textAlign: 'center',
  },
  noOptionsTextError: {
    color: COLORS.COLOR_RED_BASE,
  },
});

export const CustomOptionsList = memo(
  ({
    items,
    onDragEnd,
    onRemove,
    onChange,
    disabled,
    showErrors,
    errors,
  }: CustomOptionsListProps) => {
    const classes = useStyles();
    const { editableQuestion } = useCustomQuestionContext();
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable-list'>
          {(provided) => (
            <div
              className={classes.listContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {!items?.length ? (
                <div className={classes.noOptionsContainer}>
                  <Text
                    variant='normal'
                    testid='custom-options-list-no-items'
                    className={cn(classes.noOptionsText, {
                      [classes.noOptionsTextError]: showErrors,
                    })}>
                    There is no options.
                    <br />
                    {editableQuestion
                      ? 'Add some options to update the question.'
                      : 'Add some options to create the question.'}
                  </Text>
                </div>
              ) : (
                items.map((item, index) => (
                  <CustomOptionCard
                    item={item}
                    index={index}
                    key={item.id}
                    onRemove={onRemove}
                    onChange={onChange}
                    disabled={disabled}
                    error={showErrors && errors ? errors[item.id] : null}
                  />
                ))
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  },
);
