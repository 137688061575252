import { IconButton, makeStyles } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import classNames from 'classnames';
import { ComponentProps, ReactElement } from 'react';
import { COLORS } from '../../../theme/variables';

export interface LikeProps extends ComponentProps<typeof ThumbUp> {
  liked: boolean;
  onChange: () => void;
}

const useStyles = makeStyles(() => ({
  main: {
    color: 'transparent',
    stroke: COLORS.COLOR_GRAY_PLACEHOLDER,
    strokeWidth: 2,
    transform: 'rotateY(180deg)',
  },
  liked: {
    stroke: 'transparent',
    color: COLORS.COLOR_BLUE_BASE,
  },
}));

export function Like({
  className,
  onChange,
  liked,
  ...props
}: LikeProps): ReactElement<LikeProps> {
  const classes = useStyles();
  return (
    <IconButton onClick={onChange}>
      <ThumbUp
        {...props}
        className={classNames(classes.main, className, {
          [classes.liked]: liked,
        })}
      />
    </IconButton>
  );
}
