import { LoginResult, Tenant } from '../../api/auth';
import { TenantId } from '../../api/tenants/Tenant';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import { JWTToken } from '../../api/user/JWTToken';

export interface IncorrectPasswordResponse {
  status: string;
  message: string;
  errors: string[];
}

export const SUCCESS_LOGIN_RESPONSE: LoginResult = {
  jwttoken: 'qwerty' as JWTToken,
};
export const INCORRECT_PASSWORD_RESPONSE: IncorrectPasswordResponse = {
  status: 'UNAUTHORIZED',
  message: 'Invalid login credentials are provided',
  errors: [
    '54e3e925-2150-4bf5-bb43-5190d09297a9:Invalid login credentials are provided',
  ],
};

export const USER_INFO_RESPONSE: Tenant = {
  city: '',
  country: '',
  description: '',
  email: 'test@traction5.com',
  id: '3718eb06-b5dc-4e9e-a783-de4ffabe0038' as TenantId,
  logo: 'tenants-3718eb06-b5dc-4e9e-a783-de4ffabe0038/resized_1fa39efb-6ae7-44f9-9288-9e4d7123a3f3--Lenna_(test_image).png',
  managerFirstName: 'Some',
  managerLastName: 'Name',
  parentName: '',
  phone: '',
  programName: 'Charlotte VMS Program Test',
  state: '',
  tenantName: 'clt-vms-test',
  valid: true,
  timeZone: 'US/Eastern' as TenantTimeZone,
  mostImpactfulMentorEnabled: true,
  defaultPhysicalLocation: null,
  websiteUrl: null,
  additionalEmails: null,
  automationProfileUpdatesEnabled: false,
  automationIssuesCleanupEnabled: false,
  automationAgendaReminderEnabled: false,
};
