import { Field } from 'react-final-form';
import { FormRadioboxList } from '../forms/wrappers';

interface Props {
  value: string;
  name: string;
  label: string;
  onChange: (id: any) => void;
  className?: string;
  classNameLabel?: string;
  testid?: string;
  readOnly?: boolean;
}

function RadioBoxList({
  value,
  name,
  label,
  onChange,
  className,
  testid,
  ...rest
}: Props) {
  return (
    <Field<string>
      name={name}
      component={FormRadioboxList}
      value={value}
      label={label}
      onChange={onChange}
      className={className}
      testid={testid}
      {...rest}
    />
  );
}

export default RadioBoxList;
