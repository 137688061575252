import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { getRespondersCustomQuestionByTenantId } from '../../../api/CustomQuestion';
import { Audience } from '../../../api/CustomQuestion/types/Audience';
import { RespondersCustomQuestions } from '../../../api/CustomQuestion/types/CustomQuestion';
import { UserContext } from '../../../contexts/user-context';
import { useScrollOnValidation } from '../../../hooks/useScrollOnValidation';
import {
  getCustomQuestionsDefaultValues,
  getCustomQuestionsValidationSchema,
  handleSubmitCustomForm,
} from '../../../utils/custom-questions';
import { CLASS_TRACKING } from '../../../utils/tracking_class';
import { yupValidate } from '../../../utils/yup';
import {
  AlertState,
  BeforeUnload,
  Button,
  PageLoader,
  SnackMessage,
  StickyContent,
} from '../../common';
import CustomFormField from './custom-form-field';

interface CustomFormsProps {
  audience: Audience;
  responderId: string;
  successMessage?: string;
  hasAccessToUpdate?: boolean;
}

enum CustomFormStates {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
  EMPTY = 'EMPTY',
}

const useCustomFormsStyles = makeStyles((theme) => ({
  loader: {
    marginTop: 200,
  },
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
}));

function CustomForm({
  audience,
  responderId,
  successMessage,
  hasAccessToUpdate,
}: CustomFormsProps) {
  const classes = useCustomFormsStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const setSubmitValidationFailed = useScrollOnValidation();

  const [state, setState] = useState<CustomFormStates>(
    CustomFormStates.LOADING,
  );
  const [respondersCustomQuestions, setRespondersCustomQuestions] =
    useState<RespondersCustomQuestions | null>(null);
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({});
  const [submitting, setSubmitting] = useState(false);

  const validationSchema = useMemo(
    () =>
      getCustomQuestionsValidationSchema(respondersCustomQuestions?.questions),
    [respondersCustomQuestions],
  );

  const handleSubmit = async (values: Record<string, any>) => {
    try {
      if (respondersCustomQuestions && responderId && user?.id) {
        setSubmitting(true);
        const { questions } = respondersCustomQuestions;
        await handleSubmitCustomForm({
          values,
          questions,
          responderId,
          tenantId: user.id,
          audience,
        });
        setDefaultValues(values);
        setSubmitting(false);
        enqueueSnackbar(successMessage || 'Successfully updated.', {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar('An error occurred while saving. Please, try again.', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const getCustomQuestions = async () => {
      if (user && responderId) {
        try {
          setState(CustomFormStates.LOADING);
          const newRespondersCustomQuestions =
            await getRespondersCustomQuestionByTenantId(
              user.id,
              responderId,
              audience,
            );
          if (!newRespondersCustomQuestions.questions?.length) {
            setState(CustomFormStates.EMPTY);
            return;
          }
          const sortedRespondersCustomQuestions = {
            ...newRespondersCustomQuestions,
            questions: newRespondersCustomQuestions.questions.sort(
              (a, b) => a.position - b.position,
            ),
          };
          setRespondersCustomQuestions(sortedRespondersCustomQuestions);
          const defaultValues = getCustomQuestionsDefaultValues(
            sortedRespondersCustomQuestions.questions,
          );
          setDefaultValues(defaultValues);
          setState(CustomFormStates.READY);
        } catch (error: any) {
          setState(CustomFormStates.ERROR);
          const messageError = error?.response?.data?.message;
          enqueueSnackbar(
            'An error occurred while loading the form. Please, try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
        }
      }
    };
    getCustomQuestions();
  }, [audience, user, responderId, enqueueSnackbar]);

  if (state === CustomFormStates.LOADING) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  if (state === CustomFormStates.ERROR) {
    return (
      <div className={classes.error}>
        <AlertState type='error'>
          An error occurred while loading the form. Please, try again.
        </AlertState>
      </div>
    );
  }

  if (state === CustomFormStates.EMPTY) {
    return (
      <div className={classes.error}>
        <AlertState type='warning'>
          The form is currently unavailable as it is not configured. Please
          contact the administration for assistance.
        </AlertState>
      </div>
    );
  }

  return (
    <div>
      <Form
        validate={
          validationSchema && hasAccessToUpdate
            ? yupValidate(validationSchema)
            : undefined
        }
        onSubmit={handleSubmit}
        initialValues={defaultValues}
        keepDirtyOnReinitialize
        render={(formProps) => {
          setSubmitValidationFailed(
            formProps.submitFailed &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting,
          );
          return (
            <div>
              <BeforeUnload
                when={formProps.dirty && !submitting}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={submitting}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      onConfirm();
                    }}
                    disabled={submitting || !formProps.valid}>
                    {submitting ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <div className={classes.mainFormBlock}>
                <form noValidate>
                  <div className={classes.sectionBlock}>
                    {respondersCustomQuestions?.questions.map((question) => (
                      <CustomFormField
                        key={question.id}
                        question={question}
                        hasAccessToUpdate={hasAccessToUpdate}
                        onChange={(value) =>
                          formProps.form.change(question.id, value)
                        }
                        value={formProps.values[question.id] ?? ''}
                      />
                    ))}
                  </div>

                  {hasAccessToUpdate && (
                    <div className={classes.actionsBlock}>
                      <StickyContent>
                        <Button
                          className={CLASS_TRACKING.INTERNAL_ACTION}
                          onClick={formProps.handleSubmit}
                          disabled={submitting}
                          startIcon={<CheckIcon />}>
                          {submitting ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </StickyContent>
                    </div>
                  )}
                </form>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

export default CustomForm;
