import { format, isMatch } from 'date-fns';
import { StringDate } from './StringDate';

declare const _formattedDate: unique symbol;

/**
 * Distinct type for string dates with specific formats
 */
export type FormattedDate<T extends string> = string & {
  [_formattedDate]: T;
};

export function fromDate<T extends string>(
  f: string,
  date: Date,
): FormattedDate<T>;
export function fromDate<T extends string>(
  f: string,
): (date: Date) => FormattedDate<T>;
export function fromDate<T extends string>(
  ...args: [format: string] | [format: string, date: Date]
): ((date: Date) => FormattedDate<T>) | FormattedDate<T> {
  return args[1]
    ? (format(args[1], args[0]) as FormattedDate<T>)
    : (date) => format(date, args[0]) as FormattedDate<T>;
}

export const is =
  <T extends string>(format: T) =>
  (s: StringDate): s is FormattedDate<T> =>
    isMatch(s, format);
