import { CircularProgress, Link, makeStyles } from '@material-ui/core';
import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { getPublicFileLink } from '../api/fileLinks';
import { TenantId } from '../api/tenants/Tenant';
import { Text } from '../components/common';

interface UserData {
  tenantId: TenantId | null;
  urlCode: string | null;
}

enum FileLinksPageState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25,
  },
  block: {
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
  },
  logo: {
    width: 300,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
}));

function FileLinksPage() {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = useState<FileLinksPageState>(
    FileLinksPageState.LOADING,
  );
  const [fileLink, setFileLink] = useState<string>('');

  const getDataParse = (value: string): UserData => {
    const parseData = value.split('|');
    return {
      tenantId: (parseData[0] as TenantId) || null,
      urlCode: parseData[1] || null,
    };
  };

  const { tenantId, urlCode } = useMemo(() => {
    const currentKeyUrl = location.search.replace('?key=', '');
    return getDataParse(atob(currentKeyUrl)) || {};
  }, [location]);

  useEffect(() => {
    const getFileLink = async () => {
      try {
        setState(FileLinksPageState.LOADING);
        if (!tenantId || !urlCode) {
          throw new Error('Invalid link');
        }

        const newFileLink = await getPublicFileLink(tenantId, urlCode);
        setFileLink(newFileLink);
        setState(FileLinksPageState.SUCCESS);
      } catch (error) {
        setState(FileLinksPageState.ERROR);
      }
    };

    getFileLink();
  }, [tenantId, urlCode]);

  const handleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(fileLink, '_blank');
  };

  return (
    <div data-testid='change-password-page' className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      <div className={classes.block}>
        {state === FileLinksPageState.LOADING && (
          <div className={classes.loaderContainer}>
            <CircularProgress color='primary' size={40} />
          </div>
        )}
        {state === FileLinksPageState.ERROR && (
          <Text variant='normal'>
            You have a link to a file that was shared with you, but we were not
            able to find this file. Please reach out to a person who shared a
            link with you or to a program admin for support.
          </Text>
        )}
        {state === FileLinksPageState.SUCCESS && fileLink && (
          <Text variant='normal'>
            You have a file that was shared with you. In order to download the
            file, please click on{' '}
            <Link onClick={handleDownload}>this link.</Link>
          </Text>
        )}
      </div>
    </div>
  );
}

export default FileLinksPage;
