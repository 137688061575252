import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { VentureReportItem } from '../../api/reports';
import { Text } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { formatDate } from '../../utils/date';
import { createMarkup } from '../../utils/functions';

interface Props {
  ventureReport: VentureReportItem;
  isAssessment: boolean;
}

const getNameString = (value: string[]) => {
  if (!value || value.length === 0) {
    return '';
  }
  return value.map((name, index) => (index === 0 ? name : ', ' + name));
};

const useStyles = makeStyles((theme) => ({
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    width: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  defaultStyles: {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  assessment: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    flexDirection: 'column',
    rowGap: 10,

    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
    },
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
  },
  bodyText: {
    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
  noteList: {
    marginTop: 10,
  },
}));

function CardVentureReport({ ventureReport, isAssessment }: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const foundersListName = getNameString(ventureReport.founderList);
  const mentorsListName = getNameString(ventureReport.mentorList);

  const getTextRating = (rating: number) => {
    return rating ? `${rating.toFixed(2)} out 5.00` : 'No Data';
  };

  return (
    <div className={classes.border}>
      <div>
        <Text variant='normal' bold>
          {ventureReport.ventureName}
        </Text>{' '}
        <Text variant='normal'>{ventureReport.ventureDescription}</Text>
      </div>
      {((foundersListName && foundersListName[0]) ||
        (mentorsListName && mentorsListName[0])) && (
        <ul className={classes.defaultStyles}>
          <li>
            {foundersListName && foundersListName[0] && (
              <>
                <Text variant='normal' bold>
                  Founder:
                </Text>{' '}
                <Text variant='normal'>{foundersListName}</Text>{' '}
              </>
            )}
            {mentorsListName && mentorsListName[0] && (
              <>
                <Text variant='normal' bold>
                  {rb('mentors-u')}:
                </Text>{' '}
                <Text variant='normal'>{mentorsListName}</Text>
              </>
            )}
          </li>
        </ul>
      )}
      <ul className={cn(classes.noteList, classes.defaultStyles)}>
        {ventureReport?.ventureNoteList?.length > 0 &&
          ventureReport.ventureNoteList.map((note) => {
            return (
              <li>
                <Text variant='normal' bold>
                  Status as of {formatDate(note.creationDate)}:
                </Text>{' '}
                <Text variant='normal'>
                  <span
                    className={classes.bodyText}
                    data-testid='material-content'
                    dangerouslySetInnerHTML={createMarkup(note.contents)}
                  />
                </Text>
              </li>
            );
          })}
      </ul>
      {isAssessment && (
        <div
          className={classes.assessment}
          data-testid='venture-progress-report-assessment'>
          <div className={classes.rating}>
            <Text variant='normal' bold>
              Session(s) assessment
            </Text>
            <div>
              <Text variant='normal'>
                Founders(s): {getTextRating(ventureReport.founderSessionRating)}
              </Text>
            </div>
            <div>
              <Text variant='normal'>
                {rb('mentors-u')}:{' '}
                {getTextRating(ventureReport.mentorSessionRating)}
              </Text>
            </div>
          </div>

          <div className={classes.rating}>
            <Text variant='normal' bold>
              Progress assessment
            </Text>
            <div>
              <Text variant='normal'>
                Founders(s):{' '}
                {getTextRating(ventureReport.founderProgressRating)}
              </Text>
            </div>
            <div>
              <Text variant='normal'>
                {rb('mentors-u')}:{' '}
                {getTextRating(ventureReport.mentorProgressRating)}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardVentureReport;
