import { makeStyles } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import { pipe } from 'ramda';
import React, { useState, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, FormButtons, PageLoader } from '.';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import authAPI, { ConsentType, ICheckVentureValidate } from '../../api/auth';
import { TenantId } from '../../api/tenants/Tenant';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { Address, City, Country, Name, State, URL } from '../../types';
import { apply, isLeft, left, parseObject, right } from '../../utils/Either';
import * as Email from '../../utils/String/Email';
import { stringToEitherEmail } from '../../utils/String/Email';
import { Phone, stringToEitherPhone } from '../../utils/String/Phone';
import * as Sentance from '../../utils/String/Sentence';
import { fromString, Sentence } from '../../utils/String/Sentence';
import { stringToZip, Zip } from '../../utils/String/Zip';
import { getCustomQuestionsValidationSchema } from '../../utils/custom-questions';
import { isValidURL } from '../../utils/functions';
import { yupValidate } from '../../utils/yup';

interface Props {
  onSubmit: (values: FormValues) => void;
  onDone: () => Promise<void>;
  tenantId: TenantId;
  initialValues?: any;
  children: React.ReactNode[];
  isLoading: boolean;
  isUploading: boolean;
  consents: ConsentType[];
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
  showSuccess: () => void;
}

interface PropsPage {
  children: React.ReactNode;
}

interface FounderForm {
  firstName: string | Name;
  lastName: string | Name;
  email: string | Email.Email;
  emailConfirm: string | Email.Email;
  phone: string | null | Phone;
  linkedInProfile: string | null | Sentence<250>;
  gender: string | Sentence<20>;
  race: string | Sentence<50>;
  veteran: string;
}

interface ValidFounderForm extends FounderForm {
  firstName: Name;
  lastName: Name;
  email: Email.Email;
  emailConfirm: Email.Email;
  phone: Phone | null;
  linkedInProfile: null | Sentence<250>;
  gender: Sentence<20>;
  race: Sentence<50>;
  veteran: string;
}

export interface FormValues {
  // step 1
  ventureName: string;
  url: string | URL;
  address: string | Address;
  city: string | City;
  state: string | State;
  zip: string | Zip;
  country: string | Country;
  specializations: string;

  // step 2
  businessDescription: string;
  currentProgress: string;
  requiredSupport: string;

  // step 3
  numOfEmployees: string;
  founders: Array<FounderForm>;

  //step4
  howDidYouFind: string;
  legally: string;
  marketSize: string;
  reasonToStartNow: string;
  plans: string;
  growthRatio: string;
  dedication: string;
  yearPlans: string;
  obstacles: string;
  mentorshipPlans: string;
  comments: string;
}

type Errors = {
  [x: string]: string | Array<FounderErrors | null>;
};

type FounderErrors = {
  firstName?: string;
  lastName?: string;
  email?: string;
  emailConfirm?: string;
  race?: string;
  gender?: string;
  veteran?: string;
};

const validateFounder = (founder: FounderForm) => {
  const r = parseObject<
    FounderForm,
    ValidFounderForm,
    Partial<Record<keyof FounderForm, string>>
  >(
    {
      email: (v) => stringToEitherEmail(v || ''),
      emailConfirm: pipe(
        (v) => v || '',
        stringToEitherEmail,
        apply(left, (email) =>
          email === founder.email ? right(email) : left('Emails do not match'),
        ),
      ),
      firstName: (v) => fromString(256, v || ''),
      lastName: (v) => fromString(256, v || ''),
      phone: (v) => (v ? stringToEitherPhone(v) : right(null)),
      linkedInProfile: (v) => (v ? fromString(250, v) : right(null)),
      gender: (v) => fromString(20, v || ''),
      race: (v) => fromString(50, v || ''),
      veteran: right,
    },
    founder,
  );

  return isLeft(r) ? r.value : null;
};

const getValidValue = (
  value: any,
  tenantId: TenantId,
): ICheckVentureValidate => {
  return {
    venture: {
      ventureName: value.ventureName,
      tenantId,
    },
    founderList: value.founders
      .filter((founder: any) => Object.keys(founder).length)
      .map(
        (founder: {
          firstName: string;
          lastName: string;
          email: string;
          emailConfirm: string;
          phone: string;
          linkedInProfile: string;
        }) => ({
          firstName: founder.firstName,
          lastName: founder.lastName,
          email: founder.email,
          tenantId,
        }),
      ),
  };
};

const useStyles = makeStyles(() => ({
  formButtons: {
    justifyContent: 'flex-start',
  },
  loader: {
    margin: '100px 0',
  },
}));

function VentureWizard({
  onSubmit,
  onDone,
  children,
  tenantId,
  isLoading,
  isUploading,
  consents,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
  showSuccess,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const setSubmitValidationFailed = useScrollOnValidation();
  const [page, setPage] = useState(0);
  const [values, setValues] = useState({
    founders: [{ firstName: '', active: true }],
    specializations: [],
  });

  const activePage: any = React.Children.toArray(children)[page];
  const isSubmitPage = page === React.Children.count(children) - 2;
  const isLastPage = page === React.Children.count(children) - 1;

  const next = (values: any) => {
    setPage(Math.min(page + 1, children.length - 1));
    setValues(values);
    const wizardScroll = document.getElementById('wizard-scroll');
    wizardScroll?.scrollTo(0, 0);
  };

  const previous = () => {
    setPage((prev) => Math.max(prev - 1, 0));
    const wizardScroll = document.getElementById('wizard-scroll');
    wizardScroll?.scrollTo(0, 0);
  };

  const handleSubmit = async (values: any) => {
    if (page === 0) {
      try {
        const checkVentureNameResponse =
          await authAPI.checkVentureNameValidation({
            ventureName: values.ventureName,
            tenantId,
          });

        if (checkVentureNameResponse !== null) {
          enqueueSnackbar(
            'The venture name you have provided already exists on our platform. Please reach out to the admin to address the problem.',
            {
              variant: 'error',
            },
          );
          return;
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message || 'Error';
        enqueueSnackbar(messageError, {
          variant: 'error',
        });

        return;
      }
    }

    if (page === 1) {
      try {
        await authAPI.checkVentureValidate(getValidValue(values, tenantId));
      } catch (e: any) {
        const messageError = e.response?.data?.message || 'Error';
        enqueueSnackbar(messageError, {
          variant: 'error',
        });

        return;
      }
    }

    if (isSubmitPage) {
      await onSubmit(values);
      next(values);
    } else if (isLastPage) {
      await onDone();
      showSuccess();
    } else {
      next(values);
    }
  };

  const validateForm = (values: FormValues) => {
    const errors: Errors = {};
    switch (page) {
      case 0: {
        if (!values.ventureName) {
          errors.ventureName = 'Required';
        }
        if (!values.numOfEmployees) {
          errors.numOfEmployees = 'Required';
        }
        if (values.url && !isValidURL(values.url)) {
          errors.url = 'Invalid';
        }
        if (values.specializations.length === 0) {
          errors.specializations = 'Required';
        }
        if (
          values.country &&
          isLeft(Sentance.fromString(250, values.country))
        ) {
          errors.country = 'Required';
        }
        if (
          values.address &&
          isLeft(Sentance.fromString(250, values.address))
        ) {
          errors.address = 'Required';
        }
        if (values.city && isLeft(Sentance.fromString(250, values.city))) {
          errors.city = 'Required';
        }
        if (values.state && isLeft(Sentance.fromString(250, values.state))) {
          errors.state = 'Required';
        }
        if (values.zip && !stringToZip(values.zip)) {
          errors.zip = 'Invalid';
        }
        break;
      }
      case 1: {
        errors.founders = [];

        values.founders.forEach((founder, founderIndex) => {
          if (Object.values(founder).length || founderIndex === 0) {
            const founderErrors = validateFounder(founder);
            (errors.founders as Array<FounderErrors | null>).push(
              founderErrors,
            );
          } else {
            (errors.founders as Array<FounderErrors | null>).push(null);
          }
        });

        if (errors.founders.every((founder) => founder === null)) {
          delete errors.founders;
        }
        break;
      }
      case 2: {
        if (!values.legally) {
          errors.legally = 'Required';
        }
        if (!values.businessDescription) {
          errors.businessDescription = 'Required';
        }
        if (!values.currentProgress) {
          errors.currentProgress = 'Required';
        }
        if (!values.requiredSupport) {
          errors.requiredSupport = 'Required';
        }
        if (!values.marketSize) {
          errors.marketSize = 'Required';
        }
        if (!values.reasonToStartNow) {
          errors.reasonToStartNow = 'Required';
        }
        if (!values.plans) {
          errors.plans = 'Required';
        }
        if (!values.growthRatio) {
          errors.growthRatio = 'Required';
        }
        if (!values.dedication) {
          errors.dedication = 'Required';
        }
        if (!values.yearPlans) {
          errors.yearPlans = 'Required';
        }
        if (!values.obstacles) {
          errors.obstacles = 'Required';
        }
        if (!values.mentorshipPlans) {
          errors.mentorshipPlans = 'Required';
        }
        break;
      }
      case 3: {
        if (consents.length) {
          consents.forEach((consent) => {
            if (
              // @ts-expect-error
              !values[`field-${consent.id}`] ||
              // @ts-expect-error
              values[`field-${consent.id}`] === 'false'
            ) {
              errors[`field-${consent.id}`] = 'Required';
            }
          });
        }
        break;
      }
    }
    return errors;
  };

  const customQuestionValidationSchema = useMemo(
    () =>
      additionalInfoCustomQuestions?.length
        ? getCustomQuestionsValidationSchema(additionalInfoCustomQuestions)
        : null,
    [additionalInfoCustomQuestions],
  );

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <Form
      initialValues={values}
      onSubmit={handleSubmit}
      validate={
        page === 2 && showCustomAdditionalInfo && customQuestionValidationSchema
          ? yupValidate(customQuestionValidationSchema)
          : validateForm
      }
      mutators={{ ...arrayMutators }}>
      {({ handleSubmit, submitting, submitFailed, dirtySinceLastSubmit }) => {
        setSubmitValidationFailed(
          submitFailed && !dirtySinceLastSubmit && !submitting,
        );
        return (
          <form onSubmit={handleSubmit}>
            {activePage}

            <FormButtons className={classes.formButtons}>
              {page > 0 && !isLastPage && (
                <Button
                  type='button'
                  onClick={previous}
                  data-testid='venture-wizard-form-btn-prev'>
                  Previous
                </Button>
              )}
              {isSubmitPage ? (
                <Button
                  type='button'
                  onClick={handleSubmit}
                  disabled={submitting}
                  data-testid='venture-wizard-form-btn-submit'>
                  Submit
                </Button>
              ) : isLastPage ? (
                <Button
                  type='button'
                  onClick={handleSubmit}
                  disabled={isUploading}
                  data-testid='venture-wizard-form-btn-done'>
                  Done
                </Button>
              ) : (
                <Button
                  type='button'
                  onClick={handleSubmit}
                  data-testid='venture-wizard-form-btn-next'>
                  Next
                </Button>
              )}
            </FormButtons>
          </form>
        );
      }}
    </Form>
  );
}

export const WizardPage = ({ children }: PropsPage) => children;

export default VentureWizard;
