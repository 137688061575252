import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { add } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Field, useField } from 'react-final-form';
import eventsAPI, { Event } from '../../api/events';
import { FormDateTimeInput } from '../../components/forms/wrappers';
import { COLORS } from '../../theme/variables';
import { formatDateToRFC } from '../../utils/date';
import { FormGroup } from './form-layout';
import SnackMessage from './snack-message';

interface Props {
  event?: Event;
  onValid?: (valid: boolean) => any;
  errorVaildDate?: boolean;
}

const useStyles = makeStyles({
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
  success: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_GREEN_BASE} !important`,
    },
  },
});

const validFormatDate = (value: string) => {
  if (!value) {
    return false;
  }
  try {
    formatDateToRFC(new Date(value));
    return true;
  } catch (error: any) {
    return false;
  }
};

function FieldDates({ event, onValid, errorVaildDate }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const startField = useField('nextEventStart');
  const endField = useField('nextEventEnd');
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | ''>();
  const [isChanged, setIsChanged] = useState(false);

  const handleCloseEventStart = () => {
    const isValid = validFormatDate(startField.input.value);
    if (isValid && !endField.input.value) {
      const newData = add(new Date(startField.input.value), {
        hours: 1,
        minutes: 30,
      });
      endField?.input?.onChange(formatDateToRFC(newData));
    }
    setIsChanged(true);
  };

  const handleCloseEventEnd = () => {
    setIsChanged(true);
  };

  const checkValidDates = async (
    startDate: string,
    endDate: string,
    event: Event,
  ) => {
    try {
      setIsChanged(false);
      setIsChanged(false);
      setStatus('loading');
      const validationRequest = await eventsAPI.eventsValidations({
        ...event,
        start: startDate,
        end: endDate,
      });
      if (!validationRequest?.scheduled) {
        enqueueSnackbar(
          `We were not able to schedule a meeting at the\n proposed time since all communication channels are reserved.\nPlease consider scheduling a meeting at the proposed time instead\nor get in touch with the program manager for assistance`,
          { variant: 'error', style: { whiteSpace: 'pre-line' } },
        );
        setStatus('error');
      }
      setStatus('success');
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      enqueueSnackbar(
        `We were not able to schedule a meeting at the\n proposed time since all communication channels are reserved.\nPlease consider scheduling a meeting at the proposed time instead\nor get in touch with the program manager for assistance`,
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setStatus('error');
    }
  };

  useEffect(() => {
    const isValidDates =
      validFormatDate(startField.input.value) &&
      validFormatDate(endField.input.value);
    const isInvalidDates = startField.meta.invalid || endField.meta.invalid;
    if (isValidDates && !isInvalidDates && isChanged && event) {
      checkValidDates(startField.input.value, endField.input.value, event);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChanged, startField.input.value, endField.input.value]);

  useEffect(() => {
    if (onValid) {
      const valid = status !== 'error' && status !== 'loading';
      onValid(valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (errorVaildDate) {
      setStatus('error');
    }
  }, [errorVaildDate]);

  return (
    <FormGroup mobile>
      <Field<string>
        className={cn({
          [classes.error]: status === 'error',
          [classes.success]: status === 'success',
        })}
        name='nextEventStart'
        testId='date-start'
        component={FormDateTimeInput}
        label='Next Session Date Start'
        onChange={() => setStatus('')}
        onBlur={handleCloseEventStart}
        onClose={handleCloseEventStart}
        parse={(value) => {
          try {
            if (value) {
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
      />
      <Field<string>
        className={cn({
          [classes.error]: status === 'error',
          [classes.success]: status === 'success',
        })}
        name='nextEventEnd'
        testId='date-end'
        onChange={() => setStatus('')}
        onBlur={handleCloseEventEnd}
        onClose={handleCloseEventEnd}
        component={FormDateTimeInput}
        label='Next Session Date End'
        currentValue={startField?.input?.value}
        parse={(value) => {
          try {
            if (value) {
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
      />
    </FormGroup>
  );
}

export default FieldDates;
