import { IconButton, ListItem } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useRef, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  CustomOption,
  NewCustomOption,
} from '../../api/CustomQuestion/types/CustomOption';
import { useCustomQuestionContext } from '../../contexts/custom-questions-context';
import { COLORS } from '../../theme/variables';
import { TextField } from '../common';

type CustomOptionCardProps = {
  item: CustomOption | NewCustomOption;
  index: number;
  onRemove: (id: string) => void;
  onChange: (id: string, label: string) => void;
  disabled?: boolean;
  error?: string | null;
};

const useStyles = makeStyles({
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'start',
    width: '100%',
    padding: 0,
  },
  draggingListItemContainer: {
    background: 'rgb(235,235,235)',
  },
  listItemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: `solid 1px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    borderRadius: 4,
    padding: 16,
    paddingTop: 40,
  },
  dragHandleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.2s ease-out',
  },
  preventDrag: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  itemActions: {
    paddingLeft: 16,
  },
  optionInput: {
    width: '100%',
  },
});

export function CustomOptionCard({
  item,
  index,
  onRemove,
  onChange,
  disabled,
  error,
}: CustomOptionCardProps) {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { handleOptionRefs } = useCustomQuestionContext();

  useEffect(() => {
    handleOptionRefs(ref, item.id);
  }, [item.id, handleOptionRefs]);
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          className={classes.listItem}
          {...provided.draggableProps}>
          <div
            ref={ref}
            className={classNames(
              snapshot.isDragging ? classes.draggingListItemContainer : '',
              classes.listItemContainer,
            )}>
            <div
              className={classNames(classes.dragHandleContainer, {
                [classes.preventDrag]: disabled,
              })}
              {...provided.dragHandleProps}>
              <DragHandle />
            </div>
            <div className={classes.optionInput}>
              <TextField
                value={item.label}
                onChange={(e) => onChange(item.id, e.target.value)}
                label='Option Name*'
                data-testid={'form-question-option-name'}
                disabled={disabled}
                error={!!error}
              />
            </div>
          </div>
          <div className={classes.itemActions}>
            <IconButton
              onClick={() => {
                onRemove(item.id);
              }}
              data-testid={`form-question-option-delete-${index}`}>
              <DeleteIcon />
            </IconButton>
          </div>
        </ListItem>
      )}
    </Draggable>
  );
}
