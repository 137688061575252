import { VentureId } from '../../../api/ventures/types/Venture';
import { Actions } from './types/Actions';
import {
  isScheduleChecking,
  isScheduleCreate,
  isScheduleDelete,
  isScheduleDeleting,
  loadError,
  loading,
  ready,
  scheduleCheck,
  scheduleChecking,
  scheduleCreate,
  scheduleCreating,
  scheduleDelete,
  scheduleDeleting,
  State,
  stateIsLoaded,
} from './types/State';

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'LoadError':
      return state.type === 'Loading'
        ? loadError({ ...state.payload, message: action.payload })
        : state;
    case 'LoadSuccess':
      return state.type === 'Loading'
        ? ready({
            ...state.payload,
            sessions: action.payload.sessions,
            ventures: action.payload.ventures,
          })
        : state;
    case 'ResetFilters':
      return loading({
        ...state.payload,
        filter: {
          dateRange: {
            startDate: new Date(),
            endDate: new Date(),
          },
        },
      });
    case 'SetFilterDateRange':
      return loading({
        ...state.payload,
        filter: {
          ...state.payload.filter,
          dateRange: action.payload,
        },
      });
    case 'ScheduleCheck':
      if (stateIsLoaded(state)) {
        return scheduleCheck({
          ...state.payload,
        });
      }
      return state;
    case 'SetScheduleVentureId':
      if (stateIsLoaded(state)) {
        return scheduleCheck({
          ...state.payload,
          scheduleVentureId: action.payload,
        });
      }
      return state;
    case 'ConfirmScheduleCheck':
      if (stateIsLoaded(state)) {
        return scheduleChecking({
          ...state.payload,
          scheduleVentureId: state.payload.scheduleVentureId as VentureId,
        });
      }
      return state;
    case 'ScheduleCheckSuccess': {
      if (isScheduleChecking(state) && action.payload.length) {
        return scheduleDelete({
          ...state.payload,
          currentWizzards: action.payload,
        });
      } else if (isScheduleChecking(state)) {
        return scheduleCreate(state.payload);
      } else if (stateIsLoaded(state)) {
        return ready(state.payload);
      } else {
        return state;
      }
    }
    case 'ScheduleDeleteSuccess': {
      if (isScheduleDeleting(state)) {
        return scheduleCreate(state.payload);
      }
      return state;
    }
    case 'ConfirmScheduleDelete': {
      if (isScheduleDelete(state)) {
        return scheduleDeleting(state.payload);
      }
      return state;
    }
    case 'ConfirmScheduleCreate': {
      if (isScheduleCreate(state)) {
        return scheduleCreating({
          ...state.payload,
          scheduleCreateValues: action.payload,
        });
      }
      return state;
    }
    case 'CancelSchedule':
    case 'ScheduleError':
    case 'ScheduleCreateSuccess': {
      if (stateIsLoaded(state)) {
        return ready(state.payload);
      }
      return state;
    }
  }
}
