import { ReactElement, useContext } from 'react';
import { KnownModule } from '../../api/user/Module';
import { Role } from '../../api/user/Role';
import { MentorApp, OfficeApp } from '../../assets/icons';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { Header } from '../common/Menu/Header';
import { Item } from '../common/Menu/Header/Item';

export function ModuleMenu(): ReactElement | null {
  const { hasRole, hasAccessToModal } = useContext(UserContext);
  const { rb } = useResourceBundles();

  const has =
    hasRole(Role.Manager) &&
    hasAccessToModal(KnownModule.Core) &&
    hasAccessToModal(KnownModule.OfficeHours);

  return has ? (
    <Header>
      <Item
        icon={<MentorApp />}
        active={true}
        label={`${rb('mentorship-u')} Program`}
        path={Pages.DASHBOARD}
      />
      <Item
        icon={<OfficeApp />}
        active={false}
        label={'Office Hours'}
        path={Pages.OH_DASHBOARD}
      />
    </Header>
  ) : null;
}
