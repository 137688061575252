import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import schedulingWizardApi from '../../../api/schedulingWizard';
import venturesAPI from '../../../api/ventures';
import { VentureId } from '../../../api/ventures/types/Venture';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { COLORS } from '../../../theme/variables';
import { TestId } from '../../Testing/TestId';
import { Button, ConfirmDialog, Dialog, SnackMessage } from '../index';
import Text from '../text';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { idle } from './types/State';

const useStyles = makeStyles(() => ({
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  buttonScheduling: {
    minWidth: 102,
  },
}));

export interface Props {
  ventureId: VentureId;
}

export function CreateSchedulingWizardButton({
  ventureId,
}: Props): ReactElement {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const { enqueueSnackbar } = useSnackbar();
  const [s, dispatch] = useReducer(reducer, idle({ ventureId }));

  const handleApprove = useCallback(() => dispatch(Actions.approve()), []);
  const handleDeny = useCallback(() => dispatch(Actions.deny()), []);
  const handleClose = useCallback(() => dispatch(Actions.reset()), []);
  const handleClick = useCallback(() => dispatch(Actions.open()), []);

  const modal = useMemo((): ReactElement | null => {
    switch (s.type) {
      case 'Idle':
      case 'Loading':
      case 'LoadErr':
        return null;
      case 'Declined':
        return (
          <Dialog
            title={<Title />}
            contentRenderer={() => (
              <TestId testId='dialog-body-text'>
                <Text variant={'normal'}>
                  Wizard cannot be started. Please make sure that at least 1
                  founder <br /> and 2 {rb('mentors')} are assigned to the
                  venture.
                </Text>
              </TestId>
            )}
            actions={
              <Button onClick={handleClose} data-testId={'close-button'}>
                I understand
              </Button>
            }
            open={true}
            setOpen={handleClose}
          />
        );
      case 'Ready':
      case 'Creating':
      case 'CreateErr':
      case 'CreateSuccess':
        return (
          <ConfirmDialog
            title={<Title />}
            body={
              <span data-testid={'dialog-body-text'}>
                Would you like to schedule a session for this venture
                automatically?
              </span>
            }
            isOpen={true}
            onSuccess={handleApprove}
            onCancel={handleDeny}
            successProps={{
              'data-testid': 'approve-button',
              label:
                s.type === 'Creating' ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Yes'
                ),
            }}
            cancelProps={{ label: 'No', 'data-testid': 'cancel-button' }}
          />
        );
      case 'Reserved':
      case 'Canceling':
      case 'CancelingError': {
        return (
          <ConfirmDialog
            title={<Title />}
            body={
              <TestId testId={'dialog-body-text'}>
                <Text>
                  There is an active Scheduling Wizard currently at work. Do you
                  want to cancel the existing Scheduling Wizard for this venture
                  and start a new one?
                </Text>
              </TestId>
            }
            isOpen={true}
            onSuccess={handleApprove}
            onCancel={handleDeny}
            successProps={{
              'data-testid': 'approve-button',
              label:
                s.type === 'Canceling' ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Yes'
                ),
            }}
            cancelProps={{ label: 'No', 'data-testid': 'cancel-button' }}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s, handleApprove, handleClose]);

  useEffect(() => {
    if (s.type === 'Loading') {
      const o = from(
        Promise.all([
          venturesAPI.getFounders(s.payload.ventureId),
          venturesAPI.getMentors(s.payload.ventureId),
          schedulingWizardApi.ventureActiveWizards(s.payload.ventureId),
        ]),
      )
        .pipe(
          map(([a, b, c]) => ({
            founders: a.length,
            mentors: b.length,
            wizards: c,
          })),
        )
        .subscribe({
          next: (v) => dispatch(Actions.loadSuccess(v)),
          error: (e) => dispatch(Actions.createFail(e.response?.data?.message)),
        });

      return () => {
        o.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'Loading']);
  useEffect(() => {
    if (s.type === 'LoadErr') {
      enqueueSnackbar(
        'An error occurred while loading the scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: s.payload.message,
            }),
          variant: 'error',
        },
      );
      dispatch(Actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'LoadErr', enqueueSnackbar]);
  useEffect(() => {
    if (s.type === 'Creating') {
      const o = from(schedulingWizardApi.create(ventureId)).subscribe({
        next: () => dispatch(Actions.createSuccess()),
        error: (e) => dispatch(Actions.createFail(e.response?.data?.message)),
      });

      return () => {
        o.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'Creating']);
  useEffect(() => {
    if (s.type === 'Canceling') {
      const o = from(
        Promise.all(
          s.payload.wizards.map(({ id }) => schedulingWizardApi.cancel(id)),
        ),
      )
        .pipe(
          switchMap(() =>
            from(schedulingWizardApi.create(s.payload.ventureId)).pipe(
              map(Actions.createSuccess),
              catchError((e: AxiosError) =>
                of(Actions.createFail(e.response?.data?.message)),
              ),
            ),
          ),
        )
        .subscribe(dispatch);

      return () => {
        o.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'Canceling']);
  useEffect(() => {
    if (s.type === 'CancelingError') {
      enqueueSnackbar(
        'An error occurred while canceling previous scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: s.payload.message,
            }),
          variant: 'error',
        },
      );
      dispatch(Actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'CancelingError', enqueueSnackbar]);
  useEffect(() => {
    if (s.type === 'CreateErr') {
      enqueueSnackbar(
        'An error occurred while creating the scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: s.payload.message,
            }),
          variant: 'error',
        },
      );
      dispatch(Actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'CreateErr', enqueueSnackbar]);
  useEffect(() => {
    if (s.type === 'CreateSuccess') {
      enqueueSnackbar(
        'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
        {
          variant: 'success',
        },
      );
      dispatch(Actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.type === 'CreateSuccess', enqueueSnackbar]);

  return (
    <>
      {modal}
      <Button
        onClick={handleClick}
        variant='contained'
        data-testid='button-invite-all'
        className={classes.buttonScheduling}
        startIcon={<AddIcon />}>
        Wizard
      </Button>
    </>
  );
}

function Title(): ReactElement {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  return (
    <>
      Scheduling Wizard
      <Tooltip
        title={`This wizard schedules new sessions automatically. It will send a request to founders to provide meeting date options, and then will ask ${rb(
          'mentors',
        )} to vote on the date that works for most. The new session(s) will be scheduled in 4 days.`}>
        <IconButton className={classes.buttonInfoIcon}>
          <InfoIcon className={classes.infoIcon} />
        </IconButton>
      </Tooltip>
    </>
  );
}
