import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import venturesAPI, { VentureLog as VentureLogType } from '../../api/ventures';
import { Log, PageLoader, Text } from '../../components/common';
import { useParams } from 'react-router';

const useStyles = makeStyles({
  container: {
    maxWidth: 670
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }
});

function VentureLogsPage() {
  const classes = useStyles();
  const { ventureId } = useParams<{ ventureId: string }>();
  const [logs, setLogs] = useState<VentureLogType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadLogs = async (ventureId: string) => {
    try {
      setIsLoading(true);
      const responseLogs = await venturesAPI.getLogs(ventureId);

      const sortLogs = responseLogs.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setLogs(sortLogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLogs(ventureId);
  }, [ventureId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div>
        {logs.length > 0 ? (
          logs.map((log, index) => (
            <Log<VentureLogType> key={log.id} log={log} index={index} />
          ))
        ) : (
          <Text variant='normal'>
            This tab will show automated system messages or activity logs.
            <br /> There are no such messages currently.
          </Text>
        )}
      </div>
    </div>
  );
}

export default VentureLogsPage;
