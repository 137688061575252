import { SyntheticEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { CopyIcon } from '../../assets/icons';
import { COLORS } from '../../theme/variables';

interface CopyTextProps {
  onClick?: (e: SyntheticEvent<HTMLElement>) => any;
  text: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    paddingRight: 24,
    position: 'relative',
    boxSizing: 'border-box',

    '&:hover $icon': {
      display: 'block'
    }
  },
  icon: {
    position: 'absolute',
    top: 5,
    right: 0,
    display: 'none',
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',
    marginLeft: 8
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE
  }
});

function CopyText({ onClick, text, children }: CopyTextProps) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const handleCopy = (e: SyntheticEvent<any>) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    if (typeof onClick === 'function') {
      onClick(e);
    }
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <span className={classes.container}>
      {children}
      {!copied ? (
        <CopyIcon className={classes.icon} onClick={handleCopy} />
      ) : (
        <CheckCircleIcon className={cn(classes.icon, classes.successIcon)} />
      )}
    </span>
  );
}

export default CopyText;
