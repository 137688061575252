import {
  Button as MaterialButton,
  CircularProgress,
  Link as MaterialLink,
  makeStyles,
  Paper,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import cn from 'classnames';
import { format } from 'date-fns';
import { SyntheticEvent, useContext, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import { Goal, GoalId, GoalStatus } from '../../api/types/Goal';
import { Venture } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import {
  encodeQuery,
  getColorGoalStatus,
  isMobile,
  isTablet,
} from '../../utils/functions';
import { TestId } from '../Testing/TestId';
import {
  AlertState,
  Link,
  StatusBadge,
  Text,
  GoalStatusSelect,
  GoalSlider,
  Select,
} from '../common';
import { SelectGoalStatus } from '../common/goal-status-select';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';

interface GoalsFilters {
  venture: string;
  status: SelectGoalStatus;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  nameColumn: {
    paddingLeft: 16,
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  progressColumn: {
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20,
    boxSizing: 'border-box',
  },
  startDateColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  completionDateColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  select: {
    minWidth: 160,
    width: '100%',
  },
}));

interface GoalsTableProps {
  goals: Goal[];
  ventureOptions: { label: string; value: string }[];
  filters: GoalsFilters;
  handleFilterUpdate: (key: keyof GoalsFilters, value: string) => void;
  isFounderVentureLoading: boolean;
  founderVentures: Venture[];
  handleGoalSliderChange: (onChangeProps: {
    id: GoalId;
    progress: number;
    status: GoalStatus;
  }) => void;
  isLoading: boolean;
  isEmptyState: boolean;
  loadError?: string;
}

function GoalsTable({
  goals,
  ventureOptions,
  filters,
  handleFilterUpdate,
  isFounderVentureLoading,
  founderVentures,
  handleGoalSliderChange,
  isLoading,
  isEmptyState,
  loadError,
}: GoalsTableProps) {
  const classes = useStyles();
  const location = useLocation();

  const { hasAccessToAction } = useContext(UserContext);
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const formatGoalDate = (stringDate: string | null) => {
    if (!stringDate) return '-';
    const date = new Date(stringDate);
    return format(date, 'LLL dd, y');
  };

  return (
    <Paper className={classes.container} data-testid='goals-table'>
      <div className={classes.filterContainer}>
        <div className={classes.filterList}>
          <div className={classes.filterItem}>
            <TestId testId={'goals-table-status-dropdown'}>
              <GoalStatusSelect
                status={filters.status}
                onChange={(status) => handleFilterUpdate('status', status)}
                className={classes.select}
                isDisabled={!founderVentures.length}
              />
            </TestId>
          </div>
          <div className={classes.filterItem}>
            <TestId testId={'goals-table-venture-dropdown'}>
              <Select
                value={filters.venture}
                options={ventureOptions}
                onSelect={(venture) =>
                  venture && handleFilterUpdate('venture', venture.value)
                }
                isDisabled={isFounderVentureLoading || !founderVentures.length}
                className={classes.select}
              />
            </TestId>
          </div>
        </div>
      </div>
      <div ref={containerRef}>
        {goals.length > 0 ? (
          <div>
            <Table
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (goals.length > 10 ? goals.length : 10) + 56}
              rowHeight={64}
              rowCount={goals.length}
              rowGetter={({ index }) => goals[index]}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`goals-table-row-${rowProps.index}`}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='title'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={172}
                minWidth={isMobile() ? 120 : 172}
                maxWidth={357}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ cellData, rowIndex, rowData }) => (
                  <TableCell truncated>
                    <MaterialLink
                      data-testid={`goals-table-name-${rowIndex}`}
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.GOAL_OVERVIEW, {
                          goalId: rowData.id,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {cellData}
                    </MaterialLink>
                  </TableCell>
                )}
              />
              <Column
                dataKey='status'
                className={classes.column}
                width={100}
                minWidth={100}
                maxWidth={150}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const currentVariant = getColorGoalStatus(cellData);
                  const readableStatus = cellData
                    ? cellData.replace(/_/g, ' ')
                    : '';
                  return (
                    <StatusBadge
                      status={readableStatus}
                      variant={currentVariant}
                    />
                  );
                }}
              />
              {!isTablet() && (
                <Column
                  dataKey='progress'
                  className={cn(classes.column, classes.progressColumn)}
                  headerClassName={classes.progressColumn}
                  width={256}
                  minWidth={256}
                  maxWidth={415}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Progress</TableHeadCell>}
                  cellRenderer={({ rowData }) => (
                    <div style={{ flex: 1 }}>
                      <GoalSlider
                        goal={rowData}
                        onChange={handleGoalSliderChange}
                      />
                    </div>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='startDate'
                  className={cn(classes.column, classes.startDateColumn)}
                  headerClassName={classes.startDateColumn}
                  width={256}
                  minWidth={256}
                  maxWidth={415}
                  flexGrow={1}
                  headerRenderer={() => (
                    <TableHeadCell>Start Date</TableHeadCell>
                  )}
                  cellRenderer={({ cellData }) => (
                    <TableCell onClick={stopPropagation} truncated>
                      {formatGoalDate(cellData)}
                    </TableCell>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='targetDate'
                  className={cn(classes.column, classes.completionDateColumn)}
                  headerClassName={classes.completionDateColumn}
                  width={124}
                  minWidth={124}
                  maxWidth={205}
                  flexGrow={1}
                  headerRenderer={() => (
                    <TableHeadCell>Completion Date</TableHeadCell>
                  )}
                  cellRenderer={({ cellData }) => (
                    <TableCell onClick={stopPropagation} truncated>
                      {formatGoalDate(cellData)}
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {!founderVentures.length &&
            !isFounderVentureLoading &&
            !isLoading ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='goals-table-no-ventures'>
                    Goals are unavailable as there are no ventures assigned to
                    you. If this is unexpected, please reach out to your Program
                    Administrator to clarify.
                  </Text>
                </div>
              </div>
            ) : isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='goals-table-no-items'>
                    This panel contains a list of goals filtered by{' '}
                    {filters.venture !== 'ALL' ? 'venture' : 'status'}{' '}
                    <Text variant='normal' bold>
                      {filters.venture !== 'ALL'
                        ? ventureOptions.find(
                            (vo) => vo.value === filters.venture,
                          )?.label
                        : filters.status.replace(/_/g, ' ')}
                    </Text>
                    .
                    <br />
                    {hasAccessToAction('goal.create')
                      ? 'We were not able to find information you requested, but feel free to add something new!'
                      : ''}
                  </Text>
                  {hasAccessToAction('goal.create') && (
                    <MaterialButton
                      component={Link}
                      to={{
                        pathname: Pages.NEW_GOAL,
                        state: {
                          prevPath: currentPath,
                        },
                        search:
                          filters.venture !== 'ALL'
                            ? `?venture=${encodeQuery(filters.venture)}`
                            : '',
                      }}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      Goal
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Paper>
  );
}

export default GoalsTable;
