export const EVENT_CHANNELS: any[] = [
  {
    id: '240e82c0-36cb-4452-8de2-90076332bf7f',
    tenantId: '3718eb06-b5dc-4e9e-a783-de4ffabe0038',
    channelName: 'Zoom1',
    description: 'Zoom1 Long Description Here with some links potentially ',
    valid: true
  },
  {
    id: '5723a0a8-7fc1-4002-b3c8-a4a3b04c6abd',
    tenantId: '3718eb06-b5dc-4e9e-a783-de4ffabe0038',
    channelName: 'Zoom2',
    description: 'Zoom2 Long Description Here with some links potentially ',
    valid: true
  },
  {
    id: 'fd6f996d-5792-47c2-ba72-aab28943bdbc',
    tenantId: '3718eb06-b5dc-4e9e-a783-de4ffabe0038',
    channelName: 'Zoom3',
    description: 'Zoom3 Long Description Here with some links potentially ',
    valid: true
  }
];
