import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import mentorsAPI, { Mentor } from '../../api/mentors';
import { Venture, VentureId } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import MentorAutocomplete from '../autocomplete/mentor-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  TextField,
} from '../common';

interface AssignVenturesFormProps {
  ventureId?: Venture['id'];
  mentors?: Mentor[];
  loading?: boolean;
  onLoaded?: (mentors: Mentor[]) => any;
  onSuccess: (params: {
    assigned: string[];
    removed: string[];
    all: Mentor[];
  }) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  checkboxGroup: {
    maxHeight: 360,
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',
    maxWidth: '100%',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

function AssignVenturesForm({
  ventureId,
  mentors,
  loading = false,
  onLoaded = () => {},
  onSuccess,
  onCancel,
}: AssignVenturesFormProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [loadedMentors, setLoadedMentors] = useState<Mentor[]>(mentors || []);
  const [initialMentors, setInitialMentors] = useState<Mentor[]>(loadedMentors);
  const [recommended, setRecommended] = useState<Mentor[]>();
  const [displayedMentors, setDisplayedMentors] = useState<Mentor[]>([]);
  const [isLoading, setIsLoading] = useState<{
    mentors: boolean;
    recommended: boolean;
  }>({ mentors: !mentors, recommended: !recommended });
  const [searchIteration, setSearchIteration] = useState(0);

  const handleMentorsSearch = useCallback((searchedMentors: Mentor[]) => {
    setDisplayedMentors(searchedMentors);
  }, []);

  const handleMentorRemove = (mentor: Mentor) => {
    setInitialMentors((prevMentors) =>
      prevMentors.filter((prevMentor) => prevMentor.id !== mentor.id),
    );
  };

  const handleMentorAdd = (mentor: Mentor) => {
    setInitialMentors((prevMentors) => [...prevMentors, mentor]);
    setSearchIteration((prevIteration) => prevIteration + 1);
  };

  const loadRecommended = async (ventureId: VentureId) => {
    try {
      const loaded: Mentor[] = await mentorsAPI.getMentorsRecommendedForVenture(
        ventureId,
      );

      setRecommended(loaded);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    } catch (e: any) {
      console.log('error loadRecommended');
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        recommended: false,
      }));
    }
  };

  const loadInitialMentors = async (_ventureId: VentureId) => {
    try {
      const loadedMentors = await mentorsAPI.getMentorsDetailsByVentureId(
        _ventureId,
      );
      setLoadedMentors(loadedMentors);
      setInitialMentors(loadedMentors);
      onLoaded(loadedMentors);
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        mentors: false,
      }));
    } catch (e: any) {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        mentors: false,
      }));
    }
  };

  const handleSubmit = async ({
    mentorsIds,
  }: {
    mentorsIds: Mentor['id'][];
  }) => {
    const loadedMentorsIds = loadedMentors.map((mentor) => mentor.id);
    const assigned = mentorsIds.filter((id) => !loadedMentorsIds.includes(id));
    const removed = loadedMentorsIds.filter((id) => !mentorsIds.includes(id));
    const all = initialMentors.filter((mentor) =>
      mentorsIds.includes(mentor.id),
    );
    onSuccess({ assigned, removed, all });
  };

  useEffect(() => {
    if (!mentors && ventureId) {
      loadInitialMentors(ventureId);
    } else {
      setIsLoading((prev) => ({ ...prev, mentors: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentors]);

  useEffect(() => {
    ventureId && loadRecommended(ventureId);
  }, [ventureId]);

  if (isLoading.mentors || isLoading.recommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    mentorsIds: initialMentors.map((mentor) => mentor.id),
  };

  const filteredDisplayedMentors = displayedMentors.filter(
    (mentor) =>
      !initialMentors.find((initialMentor) => initialMentor.id === mentor.id),
  );
  const filteredRecommendedMentors = (recommended || []).filter(
    (item) => !initialMentors.find((mentor) => mentor.id === item.id),
  );

  const displayedList =
    filteredDisplayedMentors.length > 0
      ? filteredDisplayedMentors
      : filteredRecommendedMentors;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <MentorAutocomplete
              iteration={searchIteration}
              onSearch={handleMentorsSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label='Assign to'
                  placeholder={`Enter ${rb('mentor')} name`}
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialMentors.map((mentor) => (
              <Chip
                key={mentor.id}
                label={`${mentor.firstName} ${mentor.lastName}`}
                onDelete={() => handleMentorRemove(mentor)}
                className={classes.chipItem}
              />
            ))}
          </div>
          {displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={[rb('mentors-u'), 'Assigned Ventures']}
              rows={displayedList.map((recomItem) => ({
                name: `${recomItem.firstName} ${recomItem.lastName}`,
                assigned: recomItem.assigned,
                interested: recomItem.voted,
                onAdd: () => handleMentorAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button disabled={loading} type='submit' data-testid='button-apply'>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default AssignVenturesForm;
