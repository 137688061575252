import { Link as MaterialLink } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link as LinkComponent } from '../../index';
import classes from './Link.module.scss';

export interface LinkProps {
  label: string;
  link: string;
  testId: string;
  prevPath?: string;
}

export function Link({ label, link, testId, prevPath }: LinkProps) {
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  return (
    <MaterialLink
      data-testid={testId}
      className={classes.root}
      component={LinkComponent}
      to={{
        pathname: link,
        state: {
          prevPath: prevPath || currentPath,
        },
      }}>
      {label}
    </MaterialLink>
  );
}
