import {
  Checkbox,
  CircularProgress,
  IconButton,
  Link as MaterialLink,
  Tooltip,
} from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import { format } from 'date-fns';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Range } from 'react-date-range';
import { AppointmentId } from '../../../api/Appointments/types/Appointment';
import {
  FilterStatus,
  statuses,
} from '../../../api/Appointments/types/FilterStatus';
import { Status } from '../../../api/Appointments/types/Status';
import { Role } from '../../../api/user/Role';
import { FiltersWrapper } from '../../../components/Pages/Forms/FiltersWrapper';
import { Listing } from '../../../components/Pages/Listing';
import { EmptyState } from '../../../components/Pages/Listing/EmptyState';
import {
  Props as TableProps,
  Table,
} from '../../../components/Pages/Listing/Table';
import { TestId } from '../../../components/Testing/TestId';
import {
  AlertState,
  ConfirmDialog,
  DateRangePickerInput,
  Link,
  StatusBadge,
  Text,
} from '../../../components/common';
import { Add } from '../../../components/common/Buttons/Add';
import { Inline } from '../../../components/common/Inline';
import { Select } from '../../../components/common/select';
import { TableFooter } from '../../../components/common/table';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { COLORS } from '../../../theme/variables';
import {
  datesAreOnSameDay,
  formatDateInterval,
  formatTimeInterval,
} from '../../../utils/date';
import * as Actions from './types/Actions';
import { Item } from './types/Item';
import { State } from './types/State';
import { useAppointmentsList } from './useAppointmentsList';

export function AppointmentsList({ user }: ProtectedRouteProps): ReactElement {
  const [state, dispatch] = useAppointmentsList();
  const { hasRole } = useContext(UserContext);
  const { rb } = useResourceBundles();

  const onToggleAll = useCallback(
    () => dispatch(Actions.toggleAll()),
    [dispatch],
  );
  const handleToggle = useCallback(
    (id: AppointmentId) => dispatch(Actions.toggle(id)),
    [dispatch],
  );
  const handleRemove = useCallback(
    (id: AppointmentId) => dispatch(Actions.remove(id)),
    [dispatch],
  );
  const handlePageChange = useCallback(
    (p: number) => {
      if (p < state.payload.page) {
        dispatch(Actions.prevPage());
      } else if (p > state.payload.page) {
        dispatch(Actions.nextPage());
      }
    },
    [state.payload.page, dispatch],
  );
  const handleStatusChange = useCallback(
    (v: FilterStatus) => {
      dispatch(Actions.setStatus(v));
    },
    [dispatch],
  );

  const handleDateChange = (v: Range | null) =>
    v && dispatch(Actions.setDate(v));

  const columns = useMemo<TableProps<TableKeys>['columns']>(() => {
    const items = getItems(state);
    const selected = items.filter((i) => i.selected);

    return {
      selected: {
        width: 72,
        title: (
          <Checkbox
            checked={!!selected.length && selected.length === items.length}
            color='primary'
            indeterminate={
              selected.length > 0 && selected.length !== items.length
            }
            onChange={onToggleAll}
          />
        ),
      },
      topic: {
        width: 256,
        title: 'Topic',
      },
      date: {
        width: 200,
        title: 'Date',
      },
      advisor: {
        width: 256,
        title: rb('advisor-u'),
      },
      status: {
        width: 150,
        title: 'Status',
      },
      actions: {
        width: 100,
        title: null,
        hoverOnly: true,
        alignContent: 'end',
      },
    };
  }, [state, rb, onToggleAll]);

  const items = useMemo<TableProps<TableKeys>['content']>(() => {
    const getItem = (i: Item): Record<TableKeys, ReactNode> => {
      const startDate = new Date(i.start);
      const endDate = new Date(i.end);

      const similarDates = datesAreOnSameDay(startDate, endDate);
      const date = similarDates
        ? format(i.start, 'E, LLL dd, y')
        : formatDateInterval(startDate, endDate);

      const time = formatTimeInterval(i.start, i.end);

      return {
        selected: (
          <div>
            <Checkbox
              checked={i.selected}
              color='primary'
              onChange={() => handleToggle(i.id)}
            />
          </div>
        ),
        topic: (
          <MaterialLink
            component={Link}
            to={{
              pathname: getRoutePath(Pages.OH_APPOINTMENTS_EDIT, {
                id: i.id,
              }),
            }}>
            {i.topic}
          </MaterialLink>
        ),
        date: (
          <>
            <Text bold>{date}</Text>
            <br />
            <Text variant='upper1' color={COLORS.COLOR_GRAY_BASE}>
              {time}
            </Text>
          </>
        ),
        advisor: (
          <MaterialLink
            component={Link}
            to={{
              pathname:
                hasRole(Role.Admin) || hasRole(Role.Manager)
                  ? getRoutePath(Pages.OH_ADVISORS_EDIT, {
                      id: i.advisorId,
                    })
                  : getRoutePath(Pages.OH_ADVISORS_VIEW, {
                      id: i.advisorId,
                    }),
            }}>
            {i.advisorName}
          </MaterialLink>
        ),
        status: (
          <StatusBadge
            status={statusTitle(i.status)}
            variant={statusColor(i.status)}
          />
        ),
        actions: (
          <>
            <Tooltip title='Edit'>
              <IconButton
                component={Link}
                to={{
                  pathname: getRoutePath(Pages.OH_APPOINTMENTS_EDIT, {
                    id: i.id,
                  }),
                }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {i.status !== Status.Archived ? (
              <Tooltip title='Archive'>
                <IconButton onClick={() => handleRemove(i.id)}>
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        ),
      };
    };

    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return {
          type: 'ready',
          data: [],
        };
      case 'Ready':
      case 'BulkRemoveConfirm':
      case 'RemoveConfirmation':
        return {
          type: 'ready',
          data: state.payload.items.map(getItem),
        };
      case 'BulkRemove':
      case 'Removing':
        return {
          type: 'reloading',
          data: state.payload.items.map(getItem),
        };
    }
  }, [state.type, state.payload, hasRole, handleToggle, handleRemove]);
  const empty = useMemo((): ReactElement | null => {
    switch (state.type) {
      case 'LoadError':
        return <AlertState type='error'>{state.payload.message}</AlertState>;
      case 'Ready':
        return <EmptyState singular={'Appointment'} plural={'Appointments'} />;
      case 'Loading':
        return <CircularProgress size={36} color='primary' />;
      case 'RemoveConfirmation':
      case 'Removing':
      case 'BulkRemoveConfirm':
      case 'BulkRemove':
        return null;
    }
  }, [state]);
  const filterActions = useMemo((): ReactElement | null => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return null;

      case 'Ready':
      case 'RemoveConfirmation':
      case 'Removing':
      case 'BulkRemove':
      case 'BulkRemoveConfirm': {
        const selected = state.payload.items.filter(
          (i) => i.selected && i.status !== Status.Archived,
        ).length;

        return selected ? (
          <>
            <Text variant='normal'>{selected} selected</Text>
            <Tooltip title={'Archive'}>
              <IconButton onClick={() => dispatch(Actions.bulkRemove())}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : null;
      }
    }
  }, [state, dispatch]);
  const remove = useMemo(() => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
      case 'BulkRemoveConfirm':
      case 'BulkRemove':
        return null;
      case 'RemoveConfirmation':
      case 'Removing':
        return (
          <ConfirmDialog
            isOpen={true}
            disabled={state.type === 'Removing'}
            title={'Archive appointment'}
            body={'Do you really want to archive appointment?'}
            onCancel={() => dispatch(Actions.removeConfirmation(false))}
            onSuccess={() => dispatch(Actions.removeConfirmation(true))}
            cancelProps={{
              label: 'Cancel',
              variant: 'outlined',
              'data-testid': 'appointment-remove-cancel',
            }}
            successProps={{
              label: 'Yes, proceed',
              variant: 'contained',
              'data-testid': 'appointment-remove-approve',
            }}
          />
        );
    }
  }, [state, dispatch]);
  const removeBulk = useMemo(() => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
      case 'RemoveConfirmation':
      case 'Removing':
        return null;
      case 'BulkRemoveConfirm':
      case 'BulkRemove':
        return (
          <ConfirmDialog
            isOpen={true}
            disabled={state.type === 'BulkRemove'}
            title={'Archive appointments'}
            body={'Do you really want to archive appointments?'}
            onCancel={() => dispatch(Actions.bulkConfirmation(false))}
            onSuccess={() => dispatch(Actions.bulkConfirmation(true))}
            cancelProps={{
              label: 'Cancel',
              variant: 'outlined',
              'data-testid': 'appointment-remove-cancel',
            }}
            successProps={{
              label: 'Yes, proceed',
              variant: 'contained',
              'data-testid': 'appointment-remove-approve',
            }}
          />
        );
    }
  }, [state, dispatch]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <Listing
        actions={[
          {
            key: 'new-appointment',
            label: 'New Appointment',
            component: (
              <Add
                key={'add'}
                title={'Appointment'}
                to={Pages.OH_APPOINTMENTS_NEW}
              />
            ),
          },
        ]}
        title={'Appointments'}>
        <Table<TableKeys>
          columns={columns}
          content={items}
          empty={empty}
          header={
            <>
              <FiltersWrapper
                showClear={
                  !!(state.payload.status || state.payload.dateRange) &&
                  state.payload.status !== 'all'
                }
                onClear={() => dispatch(Actions.clearFilters())}>
                <div style={{ width: '160px' }}>
                  <TestId testId={'status-filter'}>
                    <Select<FilterStatus>
                      options={options}
                      value={state.payload.status}
                      onSelect={(v) => v && handleStatusChange(v.value)}
                    />
                  </TestId>
                </div>
                <DateRangePickerInput
                  value={
                    state.payload.dateRange
                      ? {
                          startDate: state.payload.dateRange[0],
                          endDate: state.payload.dateRange[1],
                        }
                      : undefined
                  }
                  onChange={handleDateChange}
                />
              </FiltersWrapper>
              <Inline gap={15} align={'center'}>
                {filterActions}
              </Inline>
            </>
          }
          footer={
            <TableFooter
              page={state.payload.page}
              onPageChange={handlePageChange}
              disabled={isLoading(state)}
              hasNextPage={hasNextPage(state)}
            />
          }
        />
      </Listing>
      {remove}
      {removeBulk}
    </BaseLayout>
  );
}

type TableKeys =
  | 'selected'
  | 'topic'
  | 'advisor'
  | 'date'
  | 'status'
  | 'actions';

function getItems(s: State): Item[] {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return [];
    case 'Ready':
    case 'BulkRemove':
    case 'Removing':
    case 'BulkRemoveConfirm':
    case 'RemoveConfirmation':
      return s.payload.items;
  }
}

function statusColor(
  s: Item['status'],
): 'success' | 'warning' | 'error' | 'default' {
  switch (s) {
    case Status.Requested:
      return 'warning';
    case Status.Rejected:
      return 'error';
    case Status.Accepted:
      return 'success';
    case Status.Archived:
      return 'default';
    case Status.Completed:
      return 'success';
    case Status.Cancelled:
      return 'error';
  }
}

function isLoading(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'BulkRemove':
    case 'Removing':
      return true;
    case 'LoadError':
    case 'Ready':
    case 'BulkRemoveConfirm':
    case 'RemoveConfirmation':
      return false;
  }
}

function hasNextPage(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Ready':
    case 'Removing':
    case 'BulkRemoveConfirm':
    case 'BulkRemove':
    case 'RemoveConfirmation':
      return s.payload.next;
  }
}

function statusTitle(s: Status): string {
  switch (s) {
    case Status.Accepted:
      return 'Accepted';
    case Status.Rejected:
      return 'Rejected';
    case Status.Requested:
      return 'Requested';
    case Status.Archived:
      return 'Archived';
    case Status.Completed:
      return 'Completed';
    case Status.Cancelled:
      return 'Cancelled';
  }
}

function filterStatusTitle(s: FilterStatus): string {
  switch (s) {
    case 'Accepted':
      return 'Accepted';
    case 'Rejected':
      return 'Rejected';
    case 'Requested':
      return 'Requested';
    case 'Archived':
      return 'Archived';
    case 'Completed':
      return 'Completed';
    case 'Cancelled':
      return 'Cancelled';
    case 'nonaccepted':
      return 'Requested/Rejected';
    case 'all':
      return 'All Statuses';
  }
}

const options = [
  ...statuses.map((value) => ({
    value,
    label: filterStatusTitle(value),
  })),
];
