import {
  Button as MaterialButton,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link as MaterialLink,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Visibility from '@material-ui/icons/Visibility';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import filesAPI from '../../api/files';
import foundersAPI, { Founder } from '../../api/founders';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { massAsyncRequest } from '../../utils/api';
import { getColorStatus, isMobile, isTablet } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  AlertState,
  Avatar,
  ButtonsChangeStatusFounder,
  ConfirmButton,
  CopyText,
  Link,
  Modal,
  Select,
  SnackMessage,
  StatusBadge,
  Text,
  TextField,
} from '../common';
import {
  TableCell,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';
import AssignFoundersVentureForm from '../forms/assign-founders-venture';

const filters = {
  status: [
    {
      label: 'All Statuses',
      value: 'none',
    },
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Archived',
      value: 'Archived',
    },
    {
      label: 'Applicant',
      value: 'Applicant',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
  ],
};

type FilterValueType =
  | {
      key: 'search' | 'status';
      value: string;
    }
  | undefined;

const useStyles = makeStyles({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
  },
  filterActionsAssignArchived: {
    marginLeft: 11,
  },
  filterActionsAssignUnArchive: {
    marginLeft: 2,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  linkedinButton: {
    padding: 10,
  },
  linkedinIcon: {
    color: '#0A66C2',
    fontSize: 20,
  },
  nameColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  assignedColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 32px 0 10px',
    boxSizing: 'border-box',
    textAlign: 'right',
  },
  emailColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  phoneColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  iconRemoveFilter: {
    width: 20,
  },
});

function getFilterItemByFilter(filter: {
  key: 'search' | 'status';
  value: string;
}) {
  const keyAliases = {
    search: 'Search',
    status: 'Status',
  };
  return {
    label: keyAliases[filter.key],
    value:
      filter.key === 'search'
        ? filter.value
        : filters[filter.key].find(
            (filterVal) => filterVal.value === filter.value,
          )?.label,
  };
}

function parseFilterFromURL(search: string): FilterValueType {
  const parsedFilter = _pick(parseQuery(search), ['search', 'status']);
  const filterName = Object.keys(parsedFilter)[0] as 'search' | 'status';
  const filterValue = parsedFilter[filterName as keyof typeof parsedFilter];
  return typeof filterValue === 'string'
    ? { key: filterName, value: filterValue }
    : undefined;
}

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

async function foundersRequest(filter: FilterValueType, page: number) {
  if (filter) {
    let loadedFounders;
    switch (filter.key) {
      case 'search':
        loadedFounders = await foundersAPI.search(filter.value);
        break;
      case 'status':
        loadedFounders = await foundersAPI.getFoundersByStatus(
          filter.value,
          page,
        );
        break;
      default:
        return [];
    }
    return loadedFounders;
  }
  return foundersAPI.getFounders(page);
}

function FoundersTable() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { hasAccessToAction } = useContext(UserContext);
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const filterValue = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );
  const [founders, setFounders] = useState<Founder[]>([]);
  const [selectedFounderIds, setSelectedFounderIds] = useState<Founder['id'][]>(
    [],
  );
  const [loadedVenturesById, setLoadedVenturesById] = useState<{
    [x: string]: Venture[];
  }>({});
  const [foundersLogos, setFoundersLogos] = useState<{
    [x: string]: string | null;
  }>({});
  // TODO: use it when sort will be implemented
  // const [sortBy, setSortBy] = useState<SortByValueType>();
  const [searchValue, setSearchValue] = useState(
    filterValue?.key === 'search' ? filterValue.value : '',
  );
  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const perPage = useMemo(
    () => (filterValue?.key !== 'search' ? 10 : undefined),
    [filterValue],
  );
  const [isAssigningFounder, setIsAssigningFounder] = useState<{
    [x: string]: boolean;
  }>({});
  const [isSendingProfileUpdate, setIsSendingProfileUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const debouncedTimer = useRef<number>();

  const statusSelectedFounders = useMemo(() => {
    if (!selectedFounderIds) {
      return undefined;
    }
    const selectedFounders = founders.filter((founder) =>
      selectedFounderIds.includes(founder.id),
    );

    const activeSelectedFounders = selectedFounders.filter(
      (founder) => founder.status === 'Active',
    );

    const archivedSelectedFounders = selectedFounders.filter(
      (founder) => founder.status === 'Archived',
    );

    let disabledButton;
    if (
      activeSelectedFounders.length > 0 &&
      archivedSelectedFounders.length === 0
    ) {
      disabledButton = 'active';
    }
    if (
      archivedSelectedFounders.length > 0 &&
      activeSelectedFounders.length === 0
    ) {
      disabledButton = 'archived';
    }

    return {
      activeSelectedFounders,
      archivedSelectedFounders,
      disabledButton,
    };
  }, [selectedFounderIds, founders]);

  // TODO: use it when sort will be implemented
  // const sortedList = useMemo(() => {
  //   if (sortBy) {
  //     const sorted = _sortBy(founders, [sortBy?.field]);
  //     return sortBy.direction === 'desc' ? sorted.reverse() : sorted;
  //   }
  //   return founders;
  // }, [founders, sortBy]);

  const showClearFilter = useMemo(() => {
    return !!filterValue || !!searchValue;
  }, [filterValue, searchValue]);

  const loadFounders = async (filter: FilterValueType, currentPage: number) => {
    try {
      setFounders([]);
      setSelectedFounderIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (filter?.key !== 'search' && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedFounders = await foundersRequest(filter, currentPage - 1);
      setFounders(loadedFounders);
      setIsLoading(false);

      if (loadedFounders.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextFounders = await foundersRequest(filter, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextFounders.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const loadFoundersLogos = async (foundersList: Founder[]) => {
    try {
      const founderImages = foundersList.map((founder: Founder) => ({
        id: founder.id,
        logo: founder.logo,
      }));
      setFoundersLogos(() => {
        const sources = founderImages.reduce((res, founder) => {
          res[founder.id] = founder.logo ? '' : null;
          return res;
        }, {} as { [x: string]: string | null });
        return sources;
      });
      const shouldBeLoaded = founderImages.filter(
        (founderImage) => !!founderImage.logo,
      );
      const logos = shouldBeLoaded.map((founderImage) => founderImage.logo);
      if (!logos.length) {
        return false;
      }
      if (!logos.every((logo): logo is string => !!logo)) {
        return false;
      }

      const loadedLogos = await filesAPI.getFileLogos(logos);

      const nextState = shouldBeLoaded.reduce(
        (res, founderImage, founderImageIndex) => {
          res[founderImage.id] = loadedLogos[founderImageIndex];
          return res;
        },
        {} as { [x: string]: string },
      );
      return setFoundersLogos((prevFounders) => ({
        ...prevFounders,
        ...nextState,
      }));
    } catch (e: any) {
      console.log('loadFoundersLogos error');
      return false;
    }
  };

  const handleFilterUpdate = useCallback(
    (field, value) => {
      if (!value || value === 'none') {
        history.replace(location.pathname);
      } else {
        history.replace(
          `${location.pathname}?${stringifyQuery({ [field]: value })}`,
        );
      }
    },
    [history, location.pathname],
  );

  const handleClearFilters = useCallback(() => {
    if (searchValue) {
      setSearchValue('');
    }
    history.replace(location.pathname);
  }, [history, location, searchValue]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate('search', e.target.value.trim());
        } else {
          handleFilterUpdate('search', null);
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  // TODO: use it when sort will be implemented
  // const handleSortTable = useCallback(
  //   (field: string, direction: 'asc' | 'desc') => {
  //     console.log('sort');
  //     setSortBy({ field, direction });
  //   },
  //   []
  // );

  const handleVenturesLoaded = useCallback(
    (founderId: Founder['id']) => (loadedVentures: Venture[]) => {
      setFounders((prevFounders) =>
        prevFounders.map((founder) =>
          founder.id === founderId
            ? { ...founder, assigned: loadedVentures.length }
            : founder,
        ),
      );
      setLoadedVenturesById((prevState) => ({
        ...prevState,
        [founderId]: loadedVentures,
      }));
    },
    [],
  );

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
        ...(filterValue
          ? {
              [filterValue.key]: filterValue.value,
            }
          : {}),
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [filterValue, location.pathname, history],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const foundersIds = founders.map((founder) => founder.id);
        return setSelectedFounderIds([...foundersIds]);
      }
      return setSelectedFounderIds([]);
    },
    [founders],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, founderId: Founder['id']) => {
      if (checked) {
        return setSelectedFounderIds(_uniq([...selectedFounderIds, founderId]));
      }
      return setSelectedFounderIds(
        selectedFounderIds.filter((id) => id !== founderId),
      );
    },
    [selectedFounderIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const founderId = rowData.id;
      handleRowSelect(!selectedFounderIds.includes(founderId), founderId);
    },
    [selectedFounderIds, handleRowSelect],
  );

  const handleAssingSuccessForm = useCallback(
    (founderId: Founder['id'], handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
        all,
      }: {
        assigned: string[];
        removed: string[];
        all: Venture[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningFounder((prev) => ({ ...prev, [founderId]: true }));
          const assignedRequests = assigned.map(
            (ventureId) => () =>
              venturesAPI.assignFounder(ventureId, founderId),
          );
          const removedRequests = removed.map(
            (ventureId) => () =>
              venturesAPI.removeFounderAssignment(ventureId, founderId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          handleVenturesLoaded(founderId)(all);
          handleModalClose();
          setIsAssigningFounder((prev) => ({ ...prev, [founderId]: false }));
        } catch (e: any) {
          console.log('error', 'e');
        }
      },
    [handleVenturesLoaded],
  );

  const handleSendFounderUpdateProfiles = async (onClose: () => {}) => {
    try {
      setIsSendingProfileUpdate(true);
      await foundersAPI.sendFounderUpdateInvites(selectedFounderIds);
      enqueueSnackbar('The profile update requests were successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
      setSelectedFounderIds([]);
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const handleSendFounderUpdate = async (
    onClose: () => any,
    founderId: string,
  ) => {
    try {
      setIsSendingProfileUpdate(true);
      await foundersAPI.sendFounderUpdateInvites([founderId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  useEffect(() => {
    loadFounders(filterValue, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, page]);

  useEffect(() => {
    if (founders.length) {
      loadFoundersLogos(founders);
    }
  }, [founders]);

  return (
    <Paper className={classes.container} data-testid='founders-table'>
      {!isTablet() && hasAccessToAction('founder.filters') && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'founder-filter-search',
                !!searchValue,
              )}>
              <TextField
                className={cn(
                  classes.searchInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={searchValue}
                onChange={handleSearch}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                small
              />
            </div>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'founder-filter-status',
                filterValue?.key === 'status' &&
                  filterValue.value !== filters.status[0].value,
              )}>
              <Select
                className={cn(
                  classes.filterSelect,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={
                  filterValue?.key === 'status'
                    ? filterValue.value
                    : filters.status[0].value
                }
                options={filters.status}
                onSelect={(item) => handleFilterUpdate('status', item?.value)}
                isActive={
                  filterValue?.key === 'status' &&
                  filterValue.value !== filters.status[0].value
                }
              />
            </div>
            {showClearFilter && (
              <Tooltip title='Clear all filters'>
                <IconButton
                  data-testid='button-remove-filter'
                  className={classes.containerRemoveFilter}
                  onClick={() => handleClearFilters()}>
                  <img
                    src='/filter.svg'
                    alt='Remove filter'
                    className={classes.iconRemoveFilter}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>

          {selectedFounderIds.length > 0 && (
            <div className={classes.filterActions}>
              <Text variant='normal'>{selectedFounderIds.length} selected</Text>
              {hasAccessToAction('founder.details.update') && (
                <Modal
                  title='Archive founders'
                  caption={`Do you really want to archive ${
                    statusSelectedFounders?.activeSelectedFounders.length
                  } ${
                    statusSelectedFounders?.activeSelectedFounders.length === 1
                      ? 'founder'
                      : 'founders'
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusFounder
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedFounders?.activeSelectedFounders
                      }
                      status='Archived'
                      updateTable={() => loadFounders(filterValue, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignArchived,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedFounders?.disabledButton === 'archived'
                        }>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('founder.details.update') && (
                <Modal
                  title='Un-Archive founders'
                  caption={`Do you really want to un-archive ${
                    statusSelectedFounders?.archivedSelectedFounders.length
                  } ${
                    statusSelectedFounders?.activeSelectedFounders.length === 1
                      ? 'founder'
                      : 'founders'
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusFounder
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedFounders?.archivedSelectedFounders
                      }
                      status='Active'
                      updateTable={() => loadFounders(filterValue, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Un-Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignUnArchive,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedFounders?.disabledButton === 'active'
                        }>
                        <UnarchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('founder.updateProfile') && (
                <ConfirmButton
                  title='Send Mass Profile Update Requests'
                  loading={isSendingProfileUpdate}
                  body='Your are about to send a number of temporary links to selected participants, so that they update their profiles. Each link can work only 1 time, and is available for 48 hours only.'
                  successProps={{
                    btnLabel: 'Apply',
                    onSuccess: handleSendFounderUpdateProfiles,
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Send a link to request a profile update'>
                      <IconButton onClick={onClick}>
                        <AccountCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div ref={containerRef}>
        {founders.length > 0 ? (
          <div>
            <Table
              onRowClick={
                hasAccessToAction('founder.details.update')
                  ? handleRowClick
                  : () => {}
              }
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (founders.length > 10 ? founders.length : 10) + 56}
              rowHeight={64}
              rowCount={founders.length}
              rowGetter={({ index }) => founders[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`founder-table-row-${rowProps.index}`}
                  selected={selectedFounderIds.includes(rowProps.rowData.id)}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={
                  !isTablet() && hasAccessToAction('founder.details.update')
                    ? 72
                    : 30
                }
                minWidth={
                  !isTablet() && hasAccessToAction('founder.details.update')
                    ? 72
                    : 30
                }
                headerRenderer={() => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('founder.details.update') && (
                        <Checkbox
                          checked={
                            selectedFounderIds.length === founders.length
                          }
                          color='primary'
                          indeterminate={
                            selectedFounderIds.length > 0 &&
                            selectedFounderIds.length !== founders.length
                          }
                          onChange={(e) => handleAllRowSelect(e.target.checked)}
                        />
                      )}
                  </div>
                )}
                cellRenderer={({ cellData }) => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('founder.details.update') && (
                        <Checkbox
                          data-testid='founder-table-row-checkbox'
                          checked={selectedFounderIds.includes(cellData)}
                          color='primary'
                          onChange={(e) =>
                            handleRowSelect(e.target.checked, cellData)
                          }
                        />
                      )}
                  </div>
                )}
              />
              {!isMobile() && (
                <Column
                  dataKey='logo'
                  className={classes.column}
                  width={44}
                  minWidth={44}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData }) => {
                    return (
                      <div>
                        <Avatar
                          src={foundersLogos[rowData.id]}
                          name={`${rowData.firstName[0]}${rowData.lastName[0]}`}
                          size='32'>
                          {typeof foundersLogos[rowData.id] === 'string' &&
                          !foundersLogos[rowData.id] ? (
                            <CircularProgress size={18} color='inherit' />
                          ) : undefined}
                        </Avatar>
                      </div>
                    );
                  }}
                />
              )}
              <Column
                dataKey='name'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={172}
                minWidth={172}
                maxWidth={357}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ rowData }) => (
                  <TableCell truncated>
                    <MaterialLink
                      onClick={stopPropagation}
                      data-testid='founder-table-name'
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.FOUNDER_DETAILS, {
                          founderId: rowData.id,
                        }),
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {`${rowData.firstName} ${rowData.lastName}`}
                    </MaterialLink>
                  </TableCell>
                )}
              />
              <Column
                dataKey='linkedInProfile'
                className={classes.column}
                width={60}
                minWidth={60}
                maxWidth={76}
                flexGrow={1}
                headerRenderer={() => <div></div>}
                cellRenderer={({ rowData, cellData }) =>
                  cellData ? (
                    <div>
                      <Tooltip
                        title={`${rowData.firstName} ${rowData.lastName}'s LinkedIn profile`}>
                        <IconButton
                          onClick={stopPropagation}
                          target='_blank'
                          href={cellData}
                          className={classes.linkedinButton}>
                          <LinkedInIcon className={classes.linkedinIcon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              />
              <Column
                dataKey='status'
                className={classes.column}
                width={100}
                minWidth={100}
                maxWidth={120}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const currentVariant = getColorStatus(cellData);
                  return (
                    <StatusBadge status={cellData} variant={currentVariant} />
                  );
                }}
              />
              {!isMobile() && (
                <Column
                  dataKey='venture'
                  className={classes.column}
                  width={160}
                  minWidth={isTablet() ? 100 : 200}
                  maxWidth={395}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Venture</TableHeadCell>}
                  cellRenderer={({ rowData }) => (
                    <TableCell truncated>
                      {rowData.primaryVenture ? (
                        <MaterialLink
                          onClick={stopPropagation}
                          component={Link}
                          to={{
                            pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                              ventureId: rowData.primaryVentureId,
                            }),
                            state: {
                              prevPath: currentPath,
                            },
                          }}>
                          {rowData.primaryVenture}
                        </MaterialLink>
                      ) : (
                        <div>–</div>
                      )}
                    </TableCell>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='email'
                  className={cn(classes.column, classes.emailColumn)}
                  headerClassName={classes.emailColumn}
                  width={175}
                  minWidth={175}
                  maxWidth={435}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Email</TableHeadCell>}
                  cellRenderer={({ cellData }) => (
                    <CopyText onClick={stopPropagation} text={cellData}>
                      <TableCell onClick={stopPropagation} truncated>
                        <MaterialLink href={`mailto:${cellData}`}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                    </CopyText>
                  )}
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='phone'
                  className={cn(classes.column, classes.phoneColumn)}
                  headerClassName={classes.phoneColumn}
                  width={120}
                  minWidth={120}
                  maxWidth={225}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Phone</TableHeadCell>}
                  cellRenderer={({ cellData }) =>
                    cellData ? (
                      <CopyText onClick={stopPropagation} text={cellData}>
                        <TableCell truncated>{cellData}</TableCell>
                      </CopyText>
                    ) : (
                      <div>–</div>
                    )
                  }
                />
              )}
              {!isTablet() && (
                <Column
                  dataKey='actions'
                  className={cn(classes.column, classes.justifyContentEnd)}
                  minWidth={160}
                  width={160}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData }) => (
                    <TableCell onClick={stopPropagation}>
                      <div className={classes.actionsCell}>
                        {hasAccessToAction('founder.details.update') && (
                          <Modal
                            title='New assignment'
                            caption={`${rowData.firstName} ${rowData.lastName}`}
                            contentRenderer={({ handleClose }) => (
                              <AssignFoundersVentureForm
                                founderId={rowData.id}
                                loading={isAssigningFounder[rowData.id]}
                                onLoaded={handleVenturesLoaded(rowData.id)}
                                ventures={loadedVenturesById[rowData.id]}
                                onSuccess={handleAssingSuccessForm(
                                  rowData.id,
                                  handleClose,
                                )}
                                onCancel={handleClose}
                              />
                            )}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Assign' onClick={onClick}>
                                <IconButton
                                  data-testid='icon-button-assign'
                                  className={CLASS_TRACKING.LIST_QUICK_ACTION}>
                                  <HowToRegIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            width={720}
                          />
                        )}
                        {hasAccessToAction('founder.details.update') ? (
                          <Tooltip title='Edit'>
                            <IconButton
                              data-testid='icon-button-edit'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.FOUNDER_DETAILS, {
                                  founderId: rowData.id,
                                }),
                                state: {
                                  prevPath: currentPath,
                                },
                              }}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='View'>
                            <IconButton
                              data-testid='icon-button-view'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.FOUNDER_DETAILS, {
                                  founderId: rowData.id,
                                }),
                                state: {
                                  prevPath: currentPath,
                                },
                              }}>
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('founder.notes.view') && (
                          <Tooltip title='Notes'>
                            <IconButton
                              data-testid='icon-button-notes'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={getRoutePath(Pages.FOUNDER_NOTES, {
                                founderId: rowData.id,
                              })}>
                              <PlaylistAddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('founder.updateProfile') && (
                          <ConfirmButton
                            title='Send Single Profile Update Request'
                            loading={isSendingProfileUpdate}
                            body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                            successProps={{
                              btnLabel: 'Ok',
                              onSuccess: (onClose) =>
                                handleSendFounderUpdate(onClose, rowData.id),
                            }}
                            cancelProps={{
                              btnLabel: 'Cancel',
                            }}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Send a link to request a profile update'>
                                <IconButton onClick={onClick}>
                                  <AccountCircleIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                        )}
                      </div>
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='founder-table-no-items'>
                    This panel contains a list of founders
                    {!!filterValue &&
                      (() => {
                        const filter = getFilterItemByFilter(filterValue);
                        return (
                          <>
                            {' '}
                            filtered by{' '}
                            <Text variant='normal' bold>
                              {filter.label}: {filter.value}
                            </Text>
                          </>
                        );
                      })()}
                    .<br />
                    {hasAccessToAction('founder.create') &&
                      'We were not able to find information you requested, but feel free to add something new!'}
                  </Text>
                  {hasAccessToAction('founder.create') && (
                    <MaterialButton
                      component={Link}
                      to={Pages.NEW_FOUNDER}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      Add Founder
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <TableFooter
            page={page}
            onPageChange={handlePageChange}
            disabled={isLoading}
            isLoading={isNextPageLoading}
            hasNextPage={founders.length > 0 && hasNextPage}
          />
        )}
      </div>
    </Paper>
  );
}

export default FoundersTable;
