import { ComponentProps, ReactElement } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core';
import { actionIcon } from '../styles';
import classNames from 'classnames';

export type Props = ComponentProps<typeof EditIcon>;

const useStyles = makeStyles(() => ({
  main: actionIcon()
}));

export function Edit({ className, ...props }: Props): ReactElement<Props> {
  const { main } = useStyles();
  return <EditIcon {...props} className={classNames(main, className)} />;
}
