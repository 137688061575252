import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import foundersAPI, { Founder } from '../../api/founders';
import venturesAPI from '../../api/ventures';
import { VentureId } from '../../api/ventures/types/Venture';
import {
  Button,
  Modal,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import { AssignFoundersForm } from '../../components/forms/assign-founders';
import FoundersTable from '../../components/ventures/founders-table';
import { UserContext } from '../../contexts/user-context';
import { VentureContext } from '../../contexts/venture-context';
import { massAsyncRequest } from '../../utils/api';

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

function VentureFoundersPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ventureId } = useParams<{ ventureId: VentureId }>();
  const { venture } = useContext(VentureContext);
  const [founders, setFounders] = useState<Founder[]>();
  const [disabledIds, setDisabledIds] = useState<{ [x: string]: boolean }>({});
  const [isAssigningFounder, setIsAssigningFounder] = useState(false);
  const { hasAccessToAction } = useContext(UserContext);

  const isLoading = !founders;

  const loadFounders = async () => {
    try {
      const loadedFounders = await foundersAPI.getFoundersDetailsByVentureId(
        ventureId,
      );
      setFounders(loadedFounders);
    } catch (e: any) {
      console.log('error loadFounders', e);
    }
  };

  const handleAssingSuccessForm = useCallback(
    (_ventureId: string, handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
      }: {
        assigned: string[];
        removed: string[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningFounder(true);
          const assignedRequests = assigned.map(
            (founderId) => () =>
              venturesAPI.assignFounder(ventureId, founderId),
          );
          const removedRequests = removed.map(
            (founderId) => () =>
              venturesAPI.removeFounderAssignment(ventureId, founderId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          setFounders(undefined);
          handleModalClose();
          setIsAssigningFounder(false);
        } catch (e: any) {
          const messageError = e.response?.data?.message;

          enqueueSnackbar(
            'An error occurred while assigning founders. Please, try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
        }
      },
    [ventureId, enqueueSnackbar],
  );

  const handleRemoveFounder = useCallback(
    async (founder: Founder) => {
      try {
        setDisabledIds((prev) => ({
          ...prev,
          [founder.id]: true,
        }));
        await venturesAPI.removeFounderAssignment(ventureId, founder.id);
        setFounders((prevFounders) =>
          prevFounders?.filter((prevFounder) => prevFounder.id !== founder.id),
        );
        setDisabledIds((prev) => ({
          ...prev,
          [founder.id]: false,
        }));
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing a founder. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDisabledIds((prev) => ({
          ...prev,
          [founder.id]: false,
        }));
      }
    },
    [ventureId, enqueueSnackbar],
  );

  useEffect(() => {
    if (!founders) {
      loadFounders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [founders]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      {!!founders && founders.length > 0 && (
        <div className={classes.table}>
          <FoundersTable
            founders={founders}
            disabledFoundersIds={disabledIds}
            onRemove={handleRemoveFounder}
            isLink
            readOnly={!hasAccessToAction('venture.founders.update')}
          />
        </div>
      )}
      {hasAccessToAction('venture.founders.update') && (
        <Modal
          title='Add a founder'
          caption={venture?.ventureName}
          contentRenderer={({ handleClose }) => (
            <AssignFoundersForm
              ventureId={ventureId}
              loading={isAssigningFounder}
              founders={founders}
              onSuccess={handleAssingSuccessForm(ventureId, handleClose)}
              onCancel={handleClose}
            />
          )}
          buttonRenderer={({ onClick }) => (
            <Button
              onClick={onClick}
              startIcon={<AddIcon />}
              data-testid='venture-founder-button'>
              Assign
            </Button>
          )}
          width={720}
        />
      )}
    </div>
  );
}

export default VentureFoundersPage;
