import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import eventsAPI, { EventId, EventLog as EventLogType } from '../../api/events';
import { Log, PageLoader, Text } from '../../components/common';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function SessionLogPage() {
  const classes = useStyles();
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<EventLogType[]>([]);

  const loadLogs = async (eventId: string) => {
    try {
      setIsLoading(true);
      const responseLogs = await eventsAPI.getLogs(eventId);

      const sortLogs = responseLogs.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setLogs(sortLogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLogs(sessionId);
  }, [sessionId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div>
        {logs.length > 0 ? (
          logs.map((log, index) => (
            <Log<EventLogType> key={log.id} log={log} index={index} />
          ))
        ) : (
          <Text variant='normal'>
            This tab will show automated system messages or activity logs.
            <br /> There are no such messages currently.
          </Text>
        )}
      </div>
    </div>
  );
}

export default SessionLogPage;
