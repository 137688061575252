import { useEffect } from 'react';

function HomePage() {
  useEffect(() => {
    window.location.href =
      process.env.REACT_APP_HOME_PAGE || 'https://tractionfive.com/';
  }, []);

  return <div />;
}

export default HomePage;
