import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cn from 'classnames';
import { PropsWithChildren, ReactElement, SyntheticEvent } from 'react';
import {
  TableHeaderRowProps as VirtualizedTableHeaderRowProps,
  TableRowProps as VirtualizedTableRowProps,
} from 'react-virtualized';
import { COLORS } from '../../theme/variables';
import { Text } from '../common';

interface TableHeadRowProps extends VirtualizedTableHeaderRowProps {
  selected?: boolean;
}

const useHeadRowStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 12px 5px 5px !important',
    boxSizing: 'border-box',
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
});

export function TableHeadRow({ className, columns, style }: TableHeadRowProps) {
  const classes = useHeadRowStyles();
  return (
    <div className={cn(classes.container, className)} style={style}>
      {columns}
    </div>
  );
}

interface TableRowProps extends VirtualizedTableRowProps {
  selected?: boolean;
}

const useRowStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 12px 5px 5px !important',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    maxWidth: '100%',
    width: '100% !important',

    '&:not($active):hover': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  active: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,
  },
});

export function TableRow({
  selected,
  className,
  columns,
  index,
  isScrolling,
  rowData,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  ...domProps
}: TableRowProps) {
  const classes = useRowStyles();

  const a11Props = {
    onClick: (event: React.MouseEvent<any>) =>
      !!onRowClick && onRowClick({ rowData, index, event }),
    onDoubleClick: (event: React.MouseEvent<any>) =>
      !!onRowDoubleClick && onRowDoubleClick({ rowData, index, event }),
    onMouseOut: (event: React.MouseEvent<any>) =>
      !!onRowMouseOut && onRowMouseOut({ rowData, index, event }),
    onMouseOver: (event: React.MouseEvent<any>) =>
      !!onRowMouseOver && onRowMouseOver({ rowData, index, event }),
    onContextMenu: (event: React.MouseEvent<any>) =>
      !!onRowRightClick && onRowRightClick({ rowData, index, event }),
  };

  return (
    <div
      className={cn(classes.container, className, {
        [classes.active]: selected,
      })}
      {...domProps}
      {...a11Props}>
      {columns}
    </div>
  );
}

interface TableCellProps {
  onClick?: (event: SyntheticEvent) => any;
  truncated?: boolean;
  className?: string;
  children: React.ReactNode;
}

const useCellStyles = makeStyles({
  container: {},
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 1,
    paddingBottom: 1,
  },
});

export function TableCell({
  onClick = () => {},
  truncated,
  className,
  children,
}: TableCellProps) {
  const classes = useCellStyles();

  return (
    <Text
      onClick={onClick}
      className={cn(classes.container, className, {
        [classes.truncated]: truncated,
      })}
      variant='normal'>
      {children}
    </Text>
  );
}

interface TableHeadCellProps {
  truncated?: boolean;
  field?: string;
  onClick?: (field: string, direction: 'asc' | 'desc') => any;
  sortDirection?: 'asc' | 'desc';
  children: React.ReactNode;
  className?: string;
}

const useTableHeadStyles = makeStyles({
  container: {},
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export function TableHeadCell({
  field,
  onClick,
  truncated,
  children,
  sortDirection,
  className,
}: TableHeadCellProps) {
  const classes = useTableHeadStyles();

  const handleClick = () => {
    if (typeof onClick === 'function' && field) {
      const nextDirection = sortDirection === 'desc' ? 'asc' : 'desc';
      onClick(field, nextDirection);
    }
  };

  return (
    <Text
      className={cn(classes.container, className, {
        [classes.truncated]: truncated,
      })}
      variant='normal2'
      bold
      onClick={handleClick}>
      {children}
    </Text>
  );
}

interface TableFooterProps {
  page: number;
  onPageChange: (page: number) => any;
  isLoading?: boolean;
  hasNextPage: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const useFooterStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 8px',
    minHeight: '60px',
    boxSizing: 'border-box',
  },
  footerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  arrows: {
    marginLeft: 25,
  },
  buttonArrow: {
    minWidth: 0,

    '&:not(:disabled)': {
      color: COLORS.COLOR_GRAY_BASE,
    },
  },
});

export function TableHeader({ children }: PropsWithChildren<{}>): ReactElement {
  const classes = useFooterStyles();
  return <div className={cn(classes.container)}>{children}</div>;
}

export function TableFooter({
  page,
  onPageChange,
  isLoading,
  hasNextPage,
  disabled,
  children,
}: TableFooterProps) {
  const classes = useFooterStyles();

  const handlePrevPage = () => {
    onPageChange(page - 1);
  };
  const handleNextPage = () => {
    onPageChange(page + 1);
  };

  return (
    <div className={cn(classes.container)}>
      <div>{children}</div>
      <div className={classes.footerContent}>
        <Text variant='normal2'>Page {page}</Text>
        <div className={classes.arrows}>
          <Button
            className={classes.buttonArrow}
            disabled={page === 1 || disabled}
            onClick={handlePrevPage}>
            <ChevronLeftIcon />
          </Button>
          <Button
            className={classes.buttonArrow}
            disabled={disabled || isLoading || !hasNextPage}
            onClick={handleNextPage}>
            {!isLoading ? (
              <ChevronRightIcon />
            ) : (
              <CircularProgress size={16} color='inherit' />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
