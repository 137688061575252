import { Valid, Value } from '../../../../../../utils/FormValue';
import { Sentence } from '../../../../../../utils/String/Sentence';
import { Email } from '../../../../../../utils/String/Email';

// region LoggedOut
export interface LoggedOutPayload {}

export interface LoggedOut {
  type: 'LoggedOut';
  payload: LoggedOutPayload;
}

export const loggedOut = (payload: LoggedOut['payload']): LoggedOut => ({
  type: 'LoggedOut',
  payload,
});
// endregion

// region LoggedIn
export interface LoggedInPayload {}

export interface LoggedIn {
  type: 'LoggedIn';
  payload: LoggedInPayload;
}

export const loggedIn = (payload: LoggedIn['payload']): LoggedIn => ({
  type: 'LoggedIn',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload {
  username: Value<'required', Email, string | undefined>;
  password: Value<'required', Sentence<100>, string | undefined>;
  showPassword: boolean;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region LoggingIn
export interface LoggingInPayload {
  username: Valid<Email>;
  password: Valid<Sentence<100>>;
  showPassword: boolean;
}

export interface LoggingIn {
  type: 'LoggingIn';
  payload: LoggingInPayload;
}

export const loggingIn = (payload: LoggingIn['payload']): LoggingIn => ({
  type: 'LoggingIn',
  payload,
});
// endregion

// region LoginError
export interface LoginErrorPayload extends ReadyPayload {
  message: string;
}

export interface LoginError {
  type: 'LoginError';
  payload: LoginErrorPayload;
}

export const loginError = (payload: LoginError['payload']): LoginError => ({
  type: 'LoginError',
  payload,
});
// endregion

// region LoggingOut
export interface LoggingOutPayload {}

export interface LoggingOut {
  type: 'LoggingOut';
  payload: LoggingOutPayload;
}

export const loggingOut = (payload: LoggingOut['payload']): LoggingOut => ({
  type: 'LoggingOut',
  payload,
});
// endregion

// region ResetPassword
export interface ResetPasswordPayload {
  username: Value<'required', Email, string | undefined>;
}

export interface ResetPassword {
  type: 'ResetPassword';
  payload: ResetPasswordPayload;
}

export const resetPassword = (
  payload: ResetPassword['payload'],
): ResetPassword => ({
  type: 'ResetPassword',
  payload,
});
// endregion

// region Resetting
export interface ResettingPayload {
  username: Valid<Email>;
}

export interface Resetting {
  type: 'Resetting';
  payload: ResettingPayload;
}

export const resetting = (payload: Resetting['payload']): Resetting => ({
  type: 'Resetting',
  payload,
});
// endregion

// region ResetError
export interface ResetErrorPayload extends ResettingPayload {
  message: string;
}

export interface ResetError {
  type: 'ResetError';
  payload: ResetErrorPayload;
}

export const resetError = (payload: ResetError['payload']): ResetError => ({
  type: 'ResetError',
  payload,
});
// endregion

export type State =
  | Ready
  | LoggingIn
  | LoginError
  | LoggedIn
  | LoggedOut
  | LoggingOut
  | ResetPassword
  | Resetting
  | ResetError;

export function canClose(
  s: State,
): s is
  | Ready
  | LoggingIn
  | LoginError
  | ResetPassword
  | Resetting
  | ResetError {
  switch (s.type) {
    case 'Ready':
    case 'LoggingIn':
    case 'LoginError':
    case 'ResetPassword':
    case 'Resetting':
    case 'ResetError':
      return true;
    case 'LoggedIn':
    case 'LoggedOut':
    case 'LoggingOut':
      return false;
  }
}

export function isEditable(
  s: State,
): s is Ready | LoginError | ResetError | ResetPassword {
  switch (s.type) {
    case 'Ready':
    case 'LoginError':
    case 'ResetError':
    case 'ResetPassword':
      return true;
    case 'LoggedIn':
    case 'LoggedOut':
    case 'LoggingIn':
    case 'LoggingOut':
    case 'Resetting':
      return false;
  }
}

export function isLoginEditable(s: State): s is Ready | LoginError {
  switch (s.type) {
    case 'Ready':
    case 'LoginError':
      return true;
    case 'ResetError':
    case 'ResetPassword':
    case 'LoggedIn':
    case 'LoggedOut':
    case 'LoggingIn':
    case 'LoggingOut':
    case 'Resetting':
      return false;
  }
}

export function isResetEditable(s: State): s is ResetError | ResetPassword {
  switch (s.type) {
    case 'ResetError':
    case 'ResetPassword':
      return true;
    case 'Ready':
    case 'LoginError':
    case 'LoggedIn':
    case 'LoggedOut':
    case 'LoggingIn':
    case 'LoggingOut':
    case 'Resetting':
      return false;
  }
}
