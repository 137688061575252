import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  RefObject,
  useRef,
} from 'react';
import { DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import {
  createCustomQuestion,
  getAllCustomQuestions,
  updateCustomQuestion,
  updateCustomQuestionsPositions,
} from '../api/CustomQuestion';
import { Audience } from '../api/CustomQuestion/types/Audience';
import {
  CustomOption,
  NewCustomOption,
} from '../api/CustomQuestion/types/CustomOption';
import { CustomQuestion } from '../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionStatus } from '../api/CustomQuestion/types/Status';
import { CustomQuestionType } from '../api/CustomQuestion/types/Type';
import authAPI from '../api/auth';
import { SnackMessage } from '../components/common';
import { customQuestionsBlocksSettings } from '../pages/tenant/tenant-custom-questions-page/custom-question-blocks-settings';
import { reorder } from '../utils/Array/reorder';
import { UserContext } from './user-context';

export enum CreateModalState {
  SHOW_CREATE_QUESTION_MODAL = 'SHOW_CREATE_QUESTION_MODAL',
  SHOW_CREATE_SUBTITLE_MODAL = 'SHOW_CREATE_SUBTITLE_MODAL',
  HIDDEN = 'HIDDEN',
}

export interface CustomQuestionsFormValues {
  type?: CustomQuestionType | '';
  label: string;
  isMandatory: boolean;
  options: (CustomOption | NewCustomOption)[];
}

export interface CustomQuestionContextProps {
  questions: CustomQuestion[];
  loading: boolean;
  showCreateModal: CreateModalState;
  editableQuestion: CustomQuestion | null;
  deleting: boolean;
  onDragEnd: OnDragEndResponder;
  audience: Audience | null;
  isCreating: boolean;
  dragLoading: boolean;
  showDefaultModeWarningPopup: boolean;
  optionRefs: { [key: string]: React.RefObject<any> };
  handleQuestionRemove: (id: string) => void;
  handleQuestionCreate: (values: CustomQuestionsFormValues) => void;
  handleQuestionUpdate: (values: CustomQuestionsFormValues) => void;
  openEdit: (item: CustomQuestion) => void;
  setShowCreateModal: (value: CreateModalState) => void;
  handleModalClose: () => void;
  handleOptionRefs: (ref: RefObject<any>, id: string) => void;
  setShowDefaultModeWarningPopup: (value: boolean) => void;
}

const defaultContextValues = {
  questions: [],
  loading: false,
  showCreateModal: CreateModalState.HIDDEN,
  editableQuestion: null,
  deleting: false,
  isCreating: false,
  dragLoading: false,
  showDefaultModeWarningPopup: false,
  audience: null,
  optionRefs: {},
  onDragEnd: () => {},
  handleQuestionRemove: () => {},
  handleQuestionCreate: () => {},
  handleQuestionUpdate: () => {},
  openEdit: () => {},
  setShowCreateModal: () => {},
  handleModalClose: () => {},
  handleOptionRefs: () => {},
  setShowDefaultModeWarningPopup: () => {},
};

export const CustomQuestionContext =
  createContext<CustomQuestionContextProps>(defaultContextValues);

interface CustomQuestionProviderProps {
  children: React.ReactNode;
}

export const CustomQuestionProvider = ({
  children,
}: CustomQuestionProviderProps) => {
  const { user, updateUser } = useContext(UserContext);
  const { audience } = useParams<{ audience: Audience }>();
  const { enqueueSnackbar } = useSnackbar();

  const optionRefs = useRef<{ [key: string]: React.RefObject<any> }>({});

  const [questions, setQuestions] = useState<CustomQuestion[]>(
    defaultContextValues.questions,
  );
  const [editableQuestion, setEditableQuestion] =
    useState<CustomQuestion | null>(defaultContextValues.editableQuestion);

  const [showCreateModal, setShowCreateModal] = useState(
    defaultContextValues.showCreateModal,
  );
  const [loading, setLoading] = useState(defaultContextValues.loading);
  const [dragLoading, setDragLoading] = useState(defaultContextValues.deleting);
  const [deleting, setDeleting] = useState(defaultContextValues.deleting);
  const [isCreating, setCreating] = useState(false);
  const [showDefaultModeWarningPopup, setShowDefaultModeWarningPopup] =
    useState(false);

  const onDragEnd = useCallback(
    async ({ destination, source }: DropResult) => {
      try {
        setDragLoading(true);
        if (!destination) {
          setDragLoading(false);
          return;
        }
        const reorderedQuestions = reorder(
          questions,
          source.index,
          destination.index,
        ).map((question, index) => ({ ...question, position: index }));
        setQuestions(reorderedQuestions);
        const reorderedData = reorderedQuestions.map(
          ({ id: questionId, position }) => ({
            questionId,
            position,
          }),
        );
        await updateCustomQuestionsPositions(reorderedData);
        setDragLoading(false);
        enqueueSnackbar(
          'The custom questions order update request was successfully sent',
          {
            variant: 'success',
          },
        );
      } catch (error) {
        console.error(error);
        setDragLoading(false);
        const messageError = (error as AxiosError).response?.data?.message;
        enqueueSnackbar(
          'An error occurred while sending custom questions order update request. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, questions],
  );

  const handleQuestionRemove = useCallback(
    async (itemId: string) => {
      if (user) {
        try {
          setDeleting(true);
          const currentQuestion = questions.find(
            (question) => question.id === itemId,
          );
          if (currentQuestion) {
            const updatedQuestionData: CustomQuestion = {
              ...currentQuestion,
              status: CustomQuestionStatus.INACTIVE,
            };
            await updateCustomQuestion(updatedQuestionData);
            const filtredQuestions = questions.filter(
              (question) => question.id !== itemId,
            );
            const filtredQuestionsWithoutSubtitles = filtredQuestions.filter(
              (question) => question.type !== CustomQuestionType.SUBTITLE,
            );
            if (
              !filtredQuestionsWithoutSubtitles.length &&
              currentQuestion.type !== CustomQuestionType.SUBTITLE
            ) {
              const updatedTenant = await authAPI.updateTenant(user?.id, {
                ...user,
                [customQuestionsBlocksSettings[audience].key]: false,
              });
              updateUser(updatedTenant);
              setShowDefaultModeWarningPopup(true);
            }
            setQuestions(filtredQuestions);
          }
          setDeleting(false);
        } catch (error) {
          console.error(error);
          setDeleting(false);
        }
      }
    },
    [audience, questions, updateUser, user],
  );

  const handleOptionRefs = useCallback(
    (ref: React.RefObject<any>, id: string) => {
      if (ref && ref.current) {
        optionRefs.current[id] = ref;
      }
    },
    [],
  );

  const handleModalClose = useCallback(() => {
    setShowCreateModal(CreateModalState.HIDDEN);
    setEditableQuestion(null);
  }, []);

  const openEdit = useCallback((item: CustomQuestion) => {
    const isSubtitle = item.type === CustomQuestionType.SUBTITLE;
    const modalType = isSubtitle
      ? CreateModalState.SHOW_CREATE_SUBTITLE_MODAL
      : CreateModalState.SHOW_CREATE_QUESTION_MODAL;
    setShowCreateModal(modalType);
    setEditableQuestion(item);
  }, []);

  const handleQuestionCreate = useCallback(
    async (values: CustomQuestionsFormValues) => {
      if (user?.id && values.type) {
        try {
          setCreating(true);
          const result = await createCustomQuestion({
            type: values.type,
            label: values.label,
            isMandatory: values.isMandatory,
            status: CustomQuestionStatus.ACTIVE,
            audience: audience,
            tenantId: user.id,
            options: values.options.map(({ label }, idx) => ({
              label,
              status: CustomQuestionStatus.ACTIVE,
              position: idx,
            })),
            position: questions.length,
          });
          setQuestions((prev) => [...prev, result]);
          setCreating(false);
          handleModalClose();
          enqueueSnackbar(
            'The custom question create request was successfully sent',
            {
              variant: 'success',
            },
          );
        } catch (error) {
          const messageError = (error as AxiosError).response?.data?.message;
          enqueueSnackbar(
            'An error occurred while sending custom question create request. Please, try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          setCreating(false);
        }
      }
    },
    [audience, enqueueSnackbar, handleModalClose, questions?.length, user],
  );

  const handleQuestionUpdate = useCallback(
    async (values: CustomQuestionsFormValues) => {
      if (user?.id && values.type && editableQuestion) {
        try {
          setCreating(true);
          const result = await updateCustomQuestion({
            ...editableQuestion,
            type: values.type,
            label: values.label,
            isMandatory: values.isMandatory,
            options: values.options.map(({ label }, idx) => ({
              label,
              status: CustomQuestionStatus.ACTIVE,
              position: idx,
            })),
          });
          setQuestions((prev) => {
            for (let i = 0; i < prev.length; i++) {
              if (prev[i].id === result.id) {
                prev[i] = result;
                break;
              }
            }
            return prev;
          });
          setCreating(false);
          handleModalClose();
          enqueueSnackbar(
            'The custom question edit request was successfully sent',
            {
              variant: 'success',
            },
          );
        } catch (error) {
          const messageError = (error as AxiosError).response?.data?.message;
          enqueueSnackbar(
            'An error occurred while sending custom question edit request. Please, try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          setCreating(false);
        }
      }
    },
    [editableQuestion, enqueueSnackbar, handleModalClose, user],
  );

  const getCustomQuestions = useCallback(async () => {
    if (user?.id && audience) {
      try {
        setLoading(true);
        const customQuestions = await getAllCustomQuestions(user.id, audience);
        setQuestions(customQuestions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }, [audience, user]);

  useEffect(() => {
    getCustomQuestions();
  }, [getCustomQuestions]);

  const contextValue: CustomQuestionContextProps = {
    questions,
    loading,
    showCreateModal,
    editableQuestion,
    deleting,
    isCreating,
    dragLoading,
    showDefaultModeWarningPopup,
    onDragEnd,
    handleQuestionRemove,
    handleQuestionCreate,
    handleQuestionUpdate,
    openEdit,
    setShowCreateModal,
    handleModalClose,
    audience,
    handleOptionRefs,
    optionRefs: optionRefs.current,
    setShowDefaultModeWarningPopup,
  };

  return (
    <CustomQuestionContext.Provider value={contextValue}>
      {children}
    </CustomQuestionContext.Provider>
  );
};

export const useCustomQuestionContext = () => useContext(CustomQuestionContext);
