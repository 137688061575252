import { Event } from '../../../../api/events';
import { SchedulingWizard } from '../../../../api/schedulingWizard';
import { VentureId } from '../../../../api/ventures/types/Venture';
import { ParsedFormValues as WizardFormValues } from '../../../../components/forms/scheduling-wizard-initial-form';
import { LoadingPayload } from './State';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    sessions: Event[];
    ventures: Array<{ id: VentureId; name: string }>;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region SetFilterDateRange
export interface SetFilterDateRange {
  type: 'SetFilterDateRange';
  payload: LoadingPayload['filter']['dateRange'];
}

export const setFilterDateRange = (
  payload: SetFilterDateRange['payload'],
): SetFilterDateRange => ({
  type: 'SetFilterDateRange',
  payload,
});
// endregion

// region ResetFilters
export interface ResetFilters {
  type: 'ResetFilters';
}

export const resetFilters = (): ResetFilters => ({
  type: 'ResetFilters',
});
// endregion

// region ShowScheduleCheck
export interface ShowScheduleCheck {
  type: 'ScheduleCheck';
}

export const showScheduleCheck = (): ShowScheduleCheck => ({
  type: 'ScheduleCheck',
});
// endregion

// region CancelSchedule
export interface CancelSchedule {
  type: 'CancelSchedule';
}

export const cancelSchedule = (): CancelSchedule => ({
  type: 'CancelSchedule',
});
// endregion

// region SetScheduleVentureId
export interface SetScheduleVentureId {
  type: 'SetScheduleVentureId';
  payload: VentureId;
}

export const setScheduleVentureId = (
  payload: SetScheduleVentureId['payload'],
): SetScheduleVentureId => ({
  type: 'SetScheduleVentureId',
  payload,
});
// endregion

// region ConfirmScheduleCheck
export interface ConfirmScheduleCheck {
  type: 'ConfirmScheduleCheck';
}

export const confirmScheduleCheck = (): ConfirmScheduleCheck => ({
  type: 'ConfirmScheduleCheck',
});
// endregion

// region ConfirmScheduleDelete
export interface ConfirmScheduleDelete {
  type: 'ConfirmScheduleDelete';
}

export const confirmScheduleDelete = (): ConfirmScheduleDelete => ({
  type: 'ConfirmScheduleDelete',
});
// endregion

// region ConfirmScheduleCreate
export interface ConfirmScheduleCreate {
  type: 'ConfirmScheduleCreate';
  payload: WizardFormValues;
}

export const confirmScheduleCreate = (
  formValues: WizardFormValues,
): ConfirmScheduleCreate => ({
  type: 'ConfirmScheduleCreate',
  payload: formValues,
});
// endregion

// region CheckSuccess
export interface ScheduleCheckSuccess {
  type: 'ScheduleCheckSuccess';
  payload: SchedulingWizard[];
}

export const scheduleCheckSuccess = (
  payload: ScheduleCheckSuccess['payload'],
): ScheduleCheckSuccess => ({
  type: 'ScheduleCheckSuccess',
  payload,
});
// endregion

// region DeleteSuccess
export interface ScheduleDeleteSuccess {
  type: 'ScheduleDeleteSuccess';
}

export const scheduleDeleteSuccess = (): ScheduleDeleteSuccess => ({
  type: 'ScheduleDeleteSuccess',
});
// endregion

// region CreateSuccess
export interface ScheduleCreateSuccess {
  type: 'ScheduleCreateSuccess';
}

export const scheduleCreateSuccess = (): ScheduleCreateSuccess => ({
  type: 'ScheduleCreateSuccess',
});
// endregion

// region ScheduleError
export interface ScheduleError {
  type: 'ScheduleError';
  payload: string;
}

export const scheduleError = (
  payload: ScheduleError['payload'],
): ScheduleError => ({
  type: 'ScheduleError',
  payload,
});
// endregion

export type Actions =
  | LoadError
  | LoadSuccess
  | ResetFilters
  | ShowScheduleCheck
  | CancelSchedule
  | SetScheduleVentureId
  | ConfirmScheduleCheck
  | ScheduleError
  | ScheduleCheckSuccess
  | ConfirmScheduleDelete
  | ScheduleDeleteSuccess
  | ConfirmScheduleCreate
  | ScheduleCreateSuccess
  | SetFilterDateRange;
