import { useCallback, useContext } from 'react';
import authAPI, { Login2FAParams, LoginParams, Tenant } from '../api/auth';
import { JWTData } from '../api/user/JWTData';
import { UserContext } from '../contexts/user-context';
import { useAnnouncements } from './../contexts/announcement-context';

interface Base {
  user: Tenant | undefined;
  isUserLoading: boolean;
  loadUser: () => Promise<void>;
  isAuthorized: boolean;
  login: (ata: LoginParams) => Promise<{ secondFactorRequired: boolean }>;
  login2FA: (data: Login2FAParams) => Promise<void>;
  logout: (redirect: boolean) => void;
  tokenData: JWTData | undefined;
}

export interface Authorized extends Base {
  user: Tenant;
  isUserLoading: false;
  isAuthorized: true;
  tokenData: JWTData;
}

export interface Guest extends Base {
  user: undefined;
  isUserLoading: boolean;
  isAuthorized: false;
  tokenData: undefined;
}

export type Return = Authorized | Guest;

export const useAuth = (): Return => {
  const { user, isUserLoading, loadUser, token, updateToken, tokenData } =
    useContext(UserContext);
  const { loadAnnouncements } = useAnnouncements();

  const isAuthorized = !!token;

  const handleLogin = useCallback(
    async (credentials: LoginParams) => {
      const data = await authAPI.login(credentials);

      if (data.jwttoken) {
        updateToken(data.jwttoken, false);
        loadAnnouncements();
      }

      return { secondFactorRequired: data.secondFactorRequired };
    },
    [updateToken, loadAnnouncements],
  );

  const handleLogin2FA = useCallback(
    async (credentials: Login2FAParams) => {
      const data = await authAPI.login2FA(credentials);

      if (!data.jwttoken) {
        throw new Error('No JWT Token');
      }

      updateToken(data.jwttoken, false);
    },
    [updateToken],
  );

  const handleLogout = useCallback(
    (redirect: boolean) => {
      updateToken(null, redirect);
    },
    [updateToken],
  );

  return {
    user,
    isUserLoading,
    loadUser,
    isAuthorized,
    login: handleLogin,
    login2FA: handleLogin2FA,
    logout: handleLogout,
    tokenData,
  } as Return;
};
