import { useCallback, useEffect, useRef, useState } from 'react';
import mentorsAPI, { Mentor, SelectMentor } from '../../api/mentors';

interface InputRendererProps {
  value: string;
  onChange: (event: React.ChangeEvent<any>) => any;
  onSubmit: () => void;
  isLoading: boolean;
  error: boolean;
}

interface MentorAutocompleteProps {
  inputRenderer: (props: InputRendererProps) => React.ReactNode;
  onSearch: (mentors: Mentor[]) => any;
  selectMentor?: SelectMentor;
  iteration?: number;
  nonusers?: boolean;
}

function MentorAutocomplete({
  inputRenderer,
  onSearch,
  selectMentor,
  iteration,
  nonusers,
}: MentorAutocompleteProps) {
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const debouncedTimer = useRef<number>();
  const currentValue = useRef<string>(value);

  const searchMentors = useCallback(
    async (mentorName: string) => {
      try {
        const foundedMentors = nonusers
          ? await mentorsAPI.searchNonusers(mentorName)
          : await mentorsAPI.search(mentorName);
        setIsLoading(false);
        setError(false);
        if (currentValue.current === mentorName) {
          onSearch(foundedMentors);
        }
      } catch (e: any) {
        setIsLoading(false);
        if (currentValue.current === mentorName) {
          setError(true);
          onSearch([]);
        }
      }
    },
    [onSearch, nonusers],
  );

  const submit = useCallback(() => {
    if (currentValue.current.length > 2) {
      setIsLoading(true);
      searchMentors(currentValue.current);
    } else {
      onSearch([]);
    }
  }, [searchMentors, onSearch]);

  const handleChange = useCallback(
    (e) => {
      const originalValue = e.target.value;
      clearTimeout(debouncedTimer.current);
      setIsLoading(false);
      setValue(originalValue);
      currentValue.current = originalValue.trim();

      debouncedTimer.current = setTimeout(submit, 800) as unknown as number;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSearch, submit],
  );

  const handleSubmit = useCallback(() => {
    clearTimeout(debouncedTimer.current);

    submit();
  }, [submit]);

  useEffect(() => {
    setValue(selectMentor?.mentorName || '');
  }, [selectMentor]);

  useEffect(() => {
    if (iteration) {
      setValue('');
      currentValue.current = '';
      onSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration]);

  return (
    <>
      {inputRenderer({
        value,
        onChange: handleChange,
        onSubmit: handleSubmit,
        isLoading,
        error,
      })}
    </>
  );
}

export default MentorAutocomplete;
