import {
  Typography,
  CircularProgress,
  Link as MaterialLink,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ManIcon from '@material-ui/icons/EmojiPeople';
import Bowser from 'bowser';
import cn from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import reportsAPI, { QuarterlyReportRecord } from '../../api/reports';
import {
  Text,
  LogoImage,
  Link,
  VentureRatingPopup,
} from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

export interface ParamsReport {
  includeLogo: boolean;
  includeMissing: boolean;
  includeNPS: boolean;
  includeAnnualTable: boolean;
}

interface Props {
  paramsReport: ParamsReport;
  report?: QuarterlyReportRecord;
  loading: boolean;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles((theme) => ({
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    width: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  defaultStyles: {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 70,
  },
  logoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: 70,

    '@media print': {
      position: 'relative',
    },
  },
  confidentialText: {
    fontSize: 10,

    '@media print': {
      whiteSpace: 'nowrap',
    },
  },
  contentEmpty: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentEmptyText: {
    width: 500,
    textAlign: 'center',
  },
  printFirefox: {
    '@media print': {
      marginLeft: -100,
    },
  },
  summary: {
    maxWidth: 670,
    marginTop: 30,
  },
  summary_item: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  reportDate: {
    margin: '10px 0 20px',
  },
  summary_item_title: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  summary_link: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  summary_item_label: {
    width: '100%',
  },
  demographic: {
    marginTop: 30,
    maxWidth: 670,
  },
  demographic_item: {
    display: 'flex',
    marginTop: 8,
  },
  demographic_item_block: {
    marginTop: 20,
  },
  demographic_item_label: {
    width: '100%',
  },
  demographic_item_count: {
    whiteSpace: 'nowrap',
  },
  ventureHighlightsTable: {
    width: '100%',
    maxWidth: 450,
    marginTop: 10,

    '& td': {
      padding: '3px 5px',
      border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
      height: 30,
      boxSizing: 'border-box',
      minWidth: 60,
      whiteSpace: 'nowrap',
    },
  },
  summaryTable: {
    width: '100%',
    maxWidth: 670,
    marginTop: 10,

    '& td': {
      padding: '3px 5px',
      border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
      height: 30,
      boxSizing: 'border-box',
      minWidth: 60,
      whiteSpace: 'nowrap',
    },
  },
  cellData: {
    textAlign: 'right',
  },
  cellLabel: {
    width: '100%',
  },
  summaryBlock: {
    marginTop: 30,
  },
  missingReport: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0 0 20px',

    '& * + *': {
      marginTop: 5,
    },
  },
  colored_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  colored_section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  colored_blocks: {
    display: 'flex',
    alignItems: 'center',
  },
  color_block: {
    position: 'relative',
    width: 67,
    textAlign: 'center',
    marginLeft: 1,
  },
  color_block_container: {
    padding: '10px 5px',
    color: 'white',
  },
  colored_caption: {
    display: 'block',
    marginTop: 10,
  },
  nps_block: {
    margin: '20px 0 30px',
    maxWidth: 750,
  },
  rating_group: {
    display: 'flex',
  },
  rating_group_item: {
    textAlign: 'center',

    '& + &': {
      marginLeft: 20,
    },
  },
  detractorsIcon: {
    fontSize: 60,
    color: COLORS.COLOR_RED_BASE,
  },
  passiveIcon: {
    fontSize: 60,
    color: COLORS.COLOR_GRAY_BASE,
  },
  promotersIcon: {
    fontSize: 60,
    color: COLORS.COLOR_GREEN_BASE,
  },
  nps_label: {
    fontSize: 48,
  },
  positive: {
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 10,
  },
  negative: {
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 10,
  },
  nps_stat_container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  nps_container: {
    textAlign: 'center',
  },
  popup: {
    cursor: 'pointer',
  },
}));

function getPercent(source = 0, total = 0) {
  let result = (source / total) * 100;
  if (isNaN(result) || result === Infinity) {
    result = 0;
  }
  return Math.round(result);
}

function ColoredBlock({
  label,
  color,
  count,
  percent,
  ventures,
}: {
  label: number;
  color: string;
  count?: number;
  percent: number;
  ventures: string;
}) {
  const classes = useStyles();

  return (
    <VentureRatingPopup
      className={ventures ? classes.popup : undefined}
      list={ventures}>
      <div className={classes.color_block}>
        <Text variant='normal' bold>
          {label}
        </Text>
        <div
          className={classes.color_block_container}
          style={{ background: color }}>
          <Text variant='normal' inherit>
            {count}
            <br />({percent}%)
          </Text>
        </div>
      </div>
    </VentureRatingPopup>
  );
}

function ReportsQuarterlyScreen({ paramsReport, report, loading }: Props) {
  const classes = useStyles();
  const { user } = useAuth();
  const { logo } = useContext(UserContext);
  const { rb } = useResourceBundles();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isFirefox = browser.getBrowser().name === 'Firefox';
  const [yearSummary, setYearSummary] = useState<QuarterlyReportRecord[]>([]);

  const loadYearSummary = useCallback(
    async (loadedReport: QuarterlyReportRecord) => {
      try {
        const loadedSummary = await reportsAPI.getQuarterlyReportYear(
          loadedReport?.year,
        );
        setYearSummary(loadedSummary);
      } catch (e) {}
    },
    [],
  );

  useEffect(() => {
    if (!!report) {
      loadYearSummary(report);
    }
  }, [report, loadYearSummary]);

  if (loading) {
    return (
      <div className={classes.contentEmpty}>
        <CircularProgress size={40} color='primary' />
      </div>
    );
  }

  const ratingSum =
    (report?.detractors || 0) +
    (report?.passive || 0) +
    (report?.promoters || 0);

  const detractorsBlocks = [
    {
      label: 0,
      count: report?.ratingZero,
      percent: getPercent(report?.ratingZero, ratingSum),
      color: 'rgb(244, 54, 85)',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 0,
        )?.companyNames || '',
    },
    {
      label: 1,
      count: report?.ratingOne,
      percent: getPercent(report?.ratingOne, ratingSum),
      color: 'rgb(244 54 85 / 82%)',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 1,
        )?.companyNames || '',
    },
    {
      label: 2,
      count: report?.ratingTwo,
      percent: getPercent(report?.ratingTwo, ratingSum),
      color: 'rgb(244 54 85 / 65%)',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 2,
        )?.companyNames || '',
    },
    {
      label: 3,
      count: report?.ratingThree,
      percent: getPercent(report?.ratingThree, ratingSum),
      color: '#F78500',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 3,
        )?.companyNames || '',
    },
    {
      label: 4,
      count: report?.ratingFour,
      percent: getPercent(report?.ratingFour, ratingSum),
      color: '#f78500c7',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 4,
        )?.companyNames || '',
    },
    {
      label: 5,
      count: report?.ratingFive,
      percent: getPercent(report?.ratingFive, ratingSum),
      color: '#f7850085',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 5,
        )?.companyNames || '',
    },
    {
      label: 6,
      count: report?.ratingSix,
      percent: getPercent(report?.ratingSix, ratingSum),
      color: '#f785004f',
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 6,
        )?.companyNames || '',
    },
  ];
  const passiveBlocks = [
    {
      label: 7,
      count: report?.ratingSeven,
      percent: getPercent(report?.ratingSeven, ratingSum),
      color: COLORS.COLOR_GRAY_BASE,
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 7,
        )?.companyNames || '',
    },
    {
      label: 8,
      count: report?.ratingEight,
      percent: getPercent(report?.ratingEight, ratingSum),
      color: COLORS.COLOR_GRAY_LIGHTENED_20,
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 8,
        )?.companyNames || '',
    },
  ];
  const promotersBlocks = [
    {
      label: 9,
      count: report?.ratingNine,
      percent: getPercent(report?.ratingNine, ratingSum),
      color: COLORS.COLOR_GREEN_LIGHTENED_10,
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 9,
        )?.companyNames || '',
    },
    {
      label: 10,
      count: report?.ratingTen,
      percent: getPercent(report?.ratingTen, ratingSum),
      color: COLORS.COLOR_GREEN_BASE,
      ventures:
        report?.ventureQuarterlyReportRatingDetails.find(
          (item) => item.ratingValue === 10,
        )?.companyNames || '',
    },
  ];

  return (
    <div
      data-testid='venture-quarterly-report-container'
      className={cn({
        [classes.printFirefox]: isFirefox,
      })}>
      {!!report ? (
        <div data-testid='report-container-list'>
          <div className={classes.border}>
            <div className={classes.title}>
              <div>
                <Typography variant='h5'>{user?.programName}</Typography>
              </div>
              <div className={classes.logoContent}>
                <Text variant='normal' className={classes.confidentialText}>
                  Confidential. Not for Distribution
                </Text>

                {paramsReport.includeLogo && logo && (
                  <span
                    className={classes.logo}
                    data-testid='venture-progress-report-include-logo'>
                    <LogoImage
                      className={classes.image}
                      src={logo}
                      alt={user?.programName}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.reportDate}>
            <Typography variant='h4'>
              Q{report.quarterNumber} of {report.year}
            </Typography>
          </div>

          {paramsReport.includeMissing && (
            <div className={classes.missingReport}>
              <Text variant='normal'>
                Number of reports collected: {report.recordsNumber}
              </Text>
              <Text variant='normal'>
                <MaterialLink component={Link} to={Pages.WITH_ISSUES_VENTURE}>
                  Missing reports
                </MaterialLink>
              </Text>
            </div>
          )}

          {paramsReport.includeNPS && (
            <div>
              <Typography variant='h4'>
                How likely would you recommend our {rb('mentorship')} program to
                a friend?
              </Typography>
              <div className={classes.nps_block}>
                <div className={classes.colored_container}>
                  <div className={classes.colored_section}>
                    <div className={classes.colored_blocks}>
                      {detractorsBlocks.map((block, blockIndex) => (
                        <ColoredBlock key={`detr-${blockIndex}`} {...block} />
                      ))}
                    </div>
                    <Text className={classes.colored_caption} variant='normal'>
                      Detractors (0 - 6)
                    </Text>
                  </div>
                  <div className={classes.colored_section}>
                    <div className={classes.colored_blocks}>
                      {passiveBlocks.map((block, blockIndex) => (
                        <ColoredBlock
                          key={`passive-${blockIndex}`}
                          {...block}
                        />
                      ))}
                    </div>
                    <Text className={classes.colored_caption} variant='normal'>
                      Passive (7 - 8)
                    </Text>
                  </div>
                  <div className={classes.colored_section}>
                    <div className={classes.colored_blocks}>
                      {promotersBlocks.map((block, blockIndex) => (
                        <ColoredBlock key={`promo-${blockIndex}`} {...block} />
                      ))}
                    </div>
                    <Text className={classes.colored_caption} variant='normal'>
                      Promoters (9 - 10)
                    </Text>
                  </div>
                </div>
                <div className={classes.nps_stat_container}>
                  <div className={classes.rating_group}>
                    <div className={classes.rating_group_item}>
                      <ManIcon className={classes.detractorsIcon} />
                      <div>
                        <Text variant='normal'>
                          Detractors
                          <br />
                          <Text variant='normal' bold>
                            {report?.detractors}
                          </Text>{' '}
                          ({getPercent(report?.detractors, ratingSum)}%)
                        </Text>
                      </div>
                    </div>
                    <div className={classes.rating_group_item}>
                      <ManIcon className={classes.passiveIcon} />
                      <div>
                        <Text variant='normal'>
                          Passive
                          <br />
                          <Text variant='normal' bold>
                            {report?.passive}
                          </Text>{' '}
                          ({getPercent(report?.passive, ratingSum)}%)
                        </Text>
                      </div>
                    </div>
                    <div className={classes.rating_group_item}>
                      <ManIcon className={classes.promotersIcon} />
                      <div>
                        <Text variant='normal'>
                          Promoters
                          <br />
                          <Text variant='normal' bold>
                            {report?.promoters}
                          </Text>{' '}
                          ({getPercent(report?.promoters, ratingSum)}%)
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className={classes.nps_container}>
                    <div
                      className={cn({
                        [classes.positive]: report.netPromotersScore >= 0,
                        [classes.negative]: report.netPromotersScore < 0,
                      })}>
                      <Text
                        className={classes.nps_label}
                        variant='normal'
                        bold
                        inherit>
                        {report.netPromotersScore > 0
                          ? `+${report.netPromotersScore}`
                          : report.netPromotersScore}
                        %
                      </Text>
                    </div>
                    <Text variant='normal' bold>
                      Net Promoter Score
                      <br />
                      (NPS)
                    </Text>
                  </div>
                  <ReactSpeedometer
                    maxValue={100}
                    minValue={-100}
                    height={150}
                    width={250}
                    value={report.netPromotersScore}
                    segmentColors={[
                      COLORS.COLOR_RED_BASE,
                      COLORS.COLOR_RED_BASE,
                      COLORS.COLOR_RED_BASE,
                      COLORS.COLOR_RED_BASE,
                      COLORS.COLOR_GREEN_BASE,
                      COLORS.COLOR_GREEN_BASE,
                      COLORS.COLOR_GREEN_BASE,
                      COLORS.COLOR_GREEN_BASE,
                    ]}
                    textColor={COLORS.COLOR_TEXT_BASE}
                    needleColor={COLORS.COLOR_TEXT_BASE}
                    segments={8}
                  />
                </div>
                <div>
                  <Typography variant='h5'>
                    NPS = Promoters ({getPercent(report?.promoters, ratingSum)}
                    %) - Detractors ({getPercent(report?.detractors, ratingSum)}
                    %)
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <div>
            <Typography variant='h4'>Venture Highlights</Typography>
            <table className={classes.ventureHighlightsTable}>
              <tbody>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Full Time Employees</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>{report.fte}</Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Revenue Generated</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.revenue)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Equity Raised</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.equityRaised)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Grants Received</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.grantsRaised)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Loans Acquired</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.loans)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Crowdfunding Raised</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.crowdFunding)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td className={classes.cellLabel}>
                    <Text variant='normal'>Other Funding Acquired</Text>
                  </td>
                  <td className={classes.cellData}>
                    <Text variant='normal'>
                      {formatter.format(report.other)}
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {yearSummary.length > 0 && paramsReport.includeAnnualTable && (
            <YearSummary year={report.year} summary={yearSummary} />
          )}
        </div>
      ) : (
        <div
          className={classes.contentEmpty}
          data-testid='report-empty-container'>
          <Text variant='normal' className={classes.contentEmptyText}>
            Unfortunately we were not able to extract analytical data for the
            dates you selected. Please get in touch with program admin or
            support@tractionfive.com if you believe this is an unexpected
            result.
          </Text>
        </div>
      )}
    </div>
  );
}

function YearSummary({
  year,
  summary,
}: {
  year: number;
  summary: QuarterlyReportRecord[];
}) {
  const classes = useStyles();
  const emptyArrayForQuarters = new Array(4).fill(undefined);
  const quarters = emptyArrayForQuarters.map((_, itemIndex) => itemIndex + 1);

  const accumulatedSummary = quarters.map((quarterNumber) => {
    return summary.find(
      (summaryItem: QuarterlyReportRecord) =>
        quarterNumber === summaryItem.quarterNumber,
    );
  });

  return (
    <div className={classes.summaryBlock}>
      <Typography variant='h4'>{year}</Typography>
      <table className={classes.summaryTable}>
        <tbody>
          <tr>
            <td />
            {quarters.map((quarter) => (
              <td key={quarter}>
                <Text variant='normal' bold>
                  Q{quarter}
                </Text>
              </td>
            ))}
            <td>
              <Text variant='normal' bold>
                Overall in {year}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Net Promoter Score</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.netPromotersScore}</Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {accumulatedSummary.reduce((res, item) => {
                  if (!item) {
                    return res;
                  }
                  return res + item.netPromotersScore;
                }, 0) / accumulatedSummary.filter((item) => !!item).length}{' '}
                on average
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Full Time Employees</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{item.fte}</Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {(() => {
                  const filteredValues = accumulatedSummary.filter(
                    (item) => !!item,
                  );

                  if (filteredValues.length < 2) {
                    return '-';
                  }
                  const firstValue = filteredValues[0];
                  const lastValue = filteredValues[filteredValues.length - 1];

                  const result =
                    Number(lastValue?.fte) - Number(firstValue?.fte);
                  return result < 0 ? `${result} jobs` : `${result} new jobs`;
                })()}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Revenue Generated</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{formatter.format(item.revenue)}</Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.revenue;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Equity Raised</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>
                    {formatter.format(item.equityRaised)}
                  </Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.equityRaised;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Grants Received</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>
                    {formatter.format(item.grantsRaised)}
                  </Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.grantsRaised;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Loans Acquired</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{formatter.format(item.loans)}</Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.loans;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Crowdfunding Raised</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>
                    {formatter.format(item.crowdFunding)}
                  </Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.crowdFunding;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={classes.cellLabel}>
              <Text variant='normal'>Other Funding Acquired</Text>
            </td>
            {accumulatedSummary.map((item, itemIndex) => {
              if (!item) {
                return (
                  <td key={itemIndex} className={classes.cellData}>
                    <Text variant='normal'>-</Text>
                  </td>
                );
              }
              return (
                <td key={itemIndex} className={classes.cellData}>
                  <Text variant='normal'>{formatter.format(item.other)}</Text>
                </td>
              );
            })}
            <td className={classes.cellData}>
              <Text variant='normal'>
                {formatter.format(
                  accumulatedSummary.reduce((res, item) => {
                    if (!item) {
                      return res;
                    }
                    return res + item.other;
                  }, 0),
                )}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ReportsQuarterlyScreen;
