import { Advisor } from '../../../../api/Advisors/types/Advisor';
import { SpecializationId } from '../../../../api/specializations';
import { Actions as CommonActions } from '../../common/types/Actions';

export * from '../../common/types/Actions';

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    advisor: Advisor;
    specializations: SpecializationId[];
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Unload
export interface Unload {
  type: 'Unload';
}

export const unload = (): Unload => ({
  type: 'Unload',
});
// endregion

// region Confirm
export interface Confirm {
  type: 'Confirm';
}

export const confirm = (): Confirm => ({
  type: 'Confirm',
});
// endregion

// region Decline
export interface Decline {
  type: 'Decline';
}

export const decline = (): Decline => ({
  type: 'Decline',
});
// endregion

// region RemoveAndUpdate
export interface RemoveAndUpdate {
  type: 'RemoveAndUpdate';
}

export const removeAndUpdate = (): RemoveAndUpdate => ({
  type: 'RemoveAndUpdate',
});
// endregion

export type Actions =
  | LoadSuccess
  | LoadError
  | CommonActions
  | Unload
  | Confirm
  | Decline
  | RemoveAndUpdate;
