import { Types } from '../../../utils/Either';
import * as Sentence from '../../../utils/String/Sentence';
import { unreachableError } from '../../../utils/unreachableError';
import { Actions } from './types/Actions';
import { fromCommunityMember } from './types/Item';
import * as State from './types/State';
import { reverseStatus } from './utils';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            members: a.payload.items,
            items: a.payload.items.map(fromCommunityMember),
            prev: s.payload.page > 1,
            next: a.payload.next,
          })
        : s;
    case 'ChangeStatus': {
      return State.isEditable(s) &&
        s.payload.items.filter((i) => i.selected).length > 0
        ? State.updateConfirmation({ ...s.payload, status: a.payload })
        : s;
    }
    case 'UpdateConfirmation':
      return s.type === 'UpdateConfirmation'
        ? a.payload
          ? State.updating(s.payload)
          : State.ready(s.payload)
        : s;
    case 'ToggleAll':
      return State.isEditable(s)
        ? s.payload.items.some((i) => !i.selected)
          ? State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: true })),
            })
          : State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: false })),
            })
        : s;
    case 'Toggle':
      return State.isEditable(s)
        ? State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, selected: !i.selected } : i,
            ),
          })
        : s;
    case 'UpdateSingleStatus': {
      if (State.isEditable(s)) {
        const item = s.payload.items.find((i) => i.id === a.payload);

        return item
          ? State.updateConfirmation({
              ...s.payload,
              items: s.payload.items.map((i) => ({
                ...i,
                selected: i.id === a.payload,
              })),
              status: reverseStatus(item.status),
            })
          : s;
      }

      return s;
    }
    case 'UpdateError':
      return s.type === 'Updating'
        ? State.updateErr({ ...s.payload, message: a.payload })
        : s;
    case 'UpdateSuccess':
      return s.type === 'Updating'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromCommunityMember),
            next: a.payload.next,
          })
        : s;
    case 'NextPage':
      return State.isEditable(s) && s.payload.next
        ? State.loading({ ...s.payload, page: s.payload.page + 1 })
        : s;
    case 'PrevPage':
      return State.isEditable(s) && s.payload.page > 1
        ? State.loading({ ...s.payload, page: s.payload.page - 1 })
        : s;
    case 'SearchStatus':
      return State.isEditable(s)
        ? State.loading({
            ...s.payload,
            filter: a.payload
              ? { type: 'status', value: a.payload }
              : { type: 'none' },
          })
        : s;
    case 'SearchText': {
      if (State.isEditable(s)) {
        const v = Sentence.fromString(100)(a.payload);

        switch (v.type) {
          case Types.Left:
            return State.loading({ ...s.payload, filter: { type: 'none' } });
          case Types.Right:
            return v.value.length > 2
              ? State.loading({
                  ...s.payload,
                  filter: { type: 'search', value: v.value },
                })
              : ({
                  ...s,
                  payload: {
                    ...s.payload,
                    filter: { type: 'search', value: v.value },
                  },
                } as State.State);
          default:
            return unreachableError(v);
        }
      } else {
        return s;
      }
    }
    case 'ClearFilters':
      return State.isEditable(s) && s.payload.filter.type !== 'none'
        ? State.loading({ ...s.payload, filter: { type: 'none' } })
        : s;
  }
}
