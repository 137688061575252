import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import ConfirmDialog from './confirm-dialog';

interface ConfirmButtonRendererProps {
  onConfirm: () => any;
}

interface BeforeUnloadProps {
  when: boolean;
  title: string;
  body: string;
  disabled?: boolean;
  confirmButtonRenderer?: (
    props: ConfirmButtonRendererProps,
  ) => React.ReactNode;
}

function BeforeUnload({
  when,
  title,
  body,
  disabled,
  confirmButtonRenderer,
}: BeforeUnloadProps) {
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const blockedUrl = useRef<string>('');
  const unblock = useRef(() => {});

  const handleConfirm = useCallback(() => {
    setIsDialogOpen(false);
    unblock.current();
    history.push(blockedUrl.current);
    blockedUrl.current = '';
  }, [history]);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
    blockedUrl.current = '';
  }, []);

  useEffect(() => {
    if (when) {
      unblock.current = history.block((location) => {
        const pathname = `${location.pathname}${location.search}`;
        blockedUrl.current = pathname;
        setIsDialogOpen(true);
        return false;
      });
    }
    return () => unblock.current();
  }, [history, when]);

  return ReactDOM.createPortal(
    <ConfirmDialog
      isOpen={isDialogOpen}
      title={title}
      body={body}
      onCancel={handleCancel}
      onSuccess={handleConfirm}
      disabled={disabled}
      cancelProps={{
        label: 'Cancel',
        variant: 'outlined',
        'data-testid': 'cancel-leave-page',
      }}
      successProps={{
        label: 'Yes, proceed',
        variant: 'contained',
        'data-testid': 'leave-page-form-proceed',
      }}>
      {typeof confirmButtonRenderer === 'function' &&
        confirmButtonRenderer({ onConfirm: handleConfirm })}
    </ConfirmDialog>,
    document.body,
  );
}

export default BeforeUnload;
