import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import * as Advisors from '../../../../api/Advisors';
import { AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { TenantId } from '../../../../api/tenants/Tenant';
import { useAuth } from '../../../../hooks';
import { getAllSpecializations, getSettings } from '../common/Api';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';

export function useProfile(): [State.State, Dispatch<Actions.Actions>] {
  const { tenantId, advisorId } =
    useParams<{ tenantId: TenantId; advisorId: AdvisorId }>();
  const { tokenData } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    reducer,
    State.loading({
      tenantId,
      advisorId,
      user: tokenData
        ? { type: 'LoggedIn', tenantId: tokenData.tenantId }
        : { type: 'Guest' },
    }),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const state$ = useMemo(() => new BehaviorSubject(state), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => state$.next(state), [state]);

  useEffect(() => {
    const loading$ = state$
      .pipe(
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap(({ payload: { tenantId, advisorId } }) =>
          from(getSettings(tenantId)).pipe(
            switchMap(({ timeZone, strictOfficeHoursScheduling }) => {
              return forkJoin({
                advisor: from(
                  Advisors.getById(tenantId, timeZone, advisorId),
                ).pipe(
                  switchMap((a) => {
                    return a.logo
                      ? from(Advisors.getLogos(tenantId, [a.logo])).pipe(
                          map((r) => ({ ...a, logo: r[0] })),
                          catchError(() => of(a)),
                        )
                      : of(a);
                  }),
                ),
                specializations: from(getAllSpecializations(tenantId)).pipe(
                  catchError(() => of([])),
                ),
              }).pipe(
                map(({ advisor, specializations }) =>
                  Actions.loadSuccess({
                    advisor,
                    specializations: specializations.filter((s) =>
                      advisor.advisorSpecializations
                        .map((v) => v.specializationId)
                        .includes(s.id),
                    ),
                    strictOfficeHoursScheduling,
                    timeZone,
                  }),
                ),
                catchError((e: AxiosError) =>
                  of(Actions.loadError(e.response?.data.message)),
                ),
              );
            }),
          ),
        ),
      )
      .subscribe(dispatch);

    const error$ = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is State.LoadError => s.type === 'LoadError'),
        map((s) => s.payload.message),
      )
      .subscribe((m) => enqueueSnackbar(m, { variant: 'error' }));

    return () => {
      loading$.unsubscribe();
      error$.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, dispatch];
}
