import { Create } from '../../../api/Advisors/types/Advisor';
import { Status } from '../../../api/Advisors/types/Status';
import { Saving, State } from './types/State';

export function savingToAdvisorCreate({
  payload: { item, tenantId, enrollmentDate },
}: Saving): Create {
  return {
    address: item.address.value ?? null,
    country: item.country.value ?? null,
    city: item.city.value ?? null,
    state: item.state.value ?? null,
    status: item.status.value,
    email: item.email.value,
    zip: item.zip.value ?? null,
    phone: item.phone.value ?? null,
    lastName: item.lastName.value,
    tenantId,
    firstName: item.firstName.value,
    dateOfBirth: item.birthDate.value ?? null,
    linkedInProfile: item.linkedIn.value ?? null,
    longDescription: item.long.value,
    shortDescription: item.short.value,
    advisorAvailabilities: [],
    dateOfEnrollment: enrollmentDate,
    rate: item.rate.value ?? null,
  };
}

export function formEdited(s: State): boolean {
  switch (s.type) {
    case 'Redirect':
    case 'SaveError':
    case 'Ready':
    case 'Saving':
      return false;
    case 'Uploading':
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
      return true;
  }
}

export function statusTitle(s: Status): string {
  switch (s) {
    case 'Archived':
      return 'Archived';
    case 'Active':
      return 'Active';
  }
}

export function isSubmittable(s: State): boolean {
  switch (s.type) {
    case 'Uploading':
    case 'Redirect':
    case 'Saving':
      return false;
    case 'Ready':
    case 'SaveError':
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
      return true;
  }
}
