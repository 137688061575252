import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authAPI from '../../api/auth';
import specializationsAPI from '../../api/specializations';
import { Role } from '../../api/user/Role';
import { TestId } from '../../components/Testing/TestId';
import { SnackMessage, Text, Toggler } from '../../components/common';
import { WithRole } from '../../components/common/WithRole';
import { WithRule } from '../../components/common/WithRule';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { SpecializationContext } from '../../contexts/specialization-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  title: {
    margin: '0 0 20px',
  },
  subtitle: {
    margin: '20px 0',
  },
  toggler: {
    margin: '10px 0',
  },
  moduleSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0',
  },
  button: {
    display: 'flex',
    margin: '10px 0',
  },
});

function CustomSpecializationsSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { loadSpecializations } = useContext(SpecializationContext);

  const [value, setValue] = useState<'on' | 'off'>('off');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showGear = !isLoading && value === 'on';

  const handleChangeCustomSpecializations = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (value) {
        await specializationsAPI.enableCustomSpecializations();
      } else {
        await specializationsAPI.disableCustomSpecializations();
      }

      await loadSpecializations();
      setValue(val);
      const message = value
        ? 'Custom Specializations are enabled'
        : 'Custom Specializations are disabled';
      enqueueSnackbar(message, {
        variant: 'success',
      });
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating custom specializations module. Please, try again.'
        : 'An error occurred while deactivating custom specializations module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const getStatus = async () => {
      try {
        setIsLoading(true);
        const status =
          await specializationsAPI.getCustomSpecializationsStatus();
        setValue(status ? 'on' : 'off');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getStatus();
  }, []);

  return (
    <>
      <Typography
        className={showGear ? classes.moduleSettings : classes.subtitle}
        variant='h5'>
        Specialization Tags{' '}
        {showGear ? (
          <Tooltip title='Custom Specialization Settings'>
            <IconButton
              component={Link}
              to={Pages.TENANT_CUSTOM_SPECIALIZATIONS}
              data-testid='custom-specializations-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        ) : null}
      </Typography>
      <TestId testId={'custom-specializations-enable'}>
        <Toggler<'on' | 'off'>
          className={classes.toggler}
          value={value}
          options={customSpecializationsOptions}
          onChange={handleChangeCustomSpecializations}
          disabled={isLoading}
        />
      </TestId>
      <div>
        <Text variant='normal'>
          Custom Specializations are experience tags that highlight the
          expertise of experts in your program, making them easy to search and
          reference. You can edit existing specializations or add new ones by
          clicking on the gear icon.
        </Text>
      </div>
    </>
  );
}

function TenantSettingsPage() {
  const classes = useStyles();
  const { user, updateUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const handleChangeModuleStatus = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          quarterlyReportEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Automated quarterly reports module is enabled'
          : 'Automated quarterly reports module is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating automated quarterly reports module. Please, try again.'
        : 'An error occurred while deactivating automated quarterly reports module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeOfficeHours = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          strictOfficeHoursScheduling: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Strict office hours booking is enabled'
          : 'Strict office hours booking is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating strict office hours booking module. Please, try again.'
        : 'An error occurred while deactivating strict office hours booking module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeImpactfulMentor = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          mostImpactfulMentorEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? `Most Impactful ${rb('mentor-u')} question is enabled`
          : `Most Impactful ${rb('mentor-u')} question is disabled`;
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? `An error occurred while activating most impactful ${rb(
            'mentor',
          )} module. Please, try again.`
        : `An error occurred while deactivating most impactful ${rb(
            'mentor',
          )} module. Please, try again.`;

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeReportBasedScheduling = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          reportBasedScheduleEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? `Lead ${rb('mentor-u')} Report Scheduling is enabled`
          : `Lead ${rb('mentor-u')} Report Scheduling is disabled`;
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? `An error occurred while activating Lead ${rb(
            'mentor-u',
          )} Report Scheduling module. Please, try again.`
        : `An error occurred while deactivating Lead ${rb(
            'mentor-u',
          )} Report Scheduling module. Please, try again.`;

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeSecondFactorAuth = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          secondFactorEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Second Factor Authentication is enabled'
          : 'Second Factor Authentication is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating Second Factor Authentication module. Please, try again.'
        : 'An error occurred while deactivating Second Factor Authentication module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeGroupStatistics = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          groupStatisticsEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Group statistics are enabled'
          : 'Group statistics are disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating group statistics module. Please, try again.'
        : 'An error occurred while deactivating group statistics module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant='h4'>
        Optional modules
      </Typography>
      <WithRole roles={[Role.Admin, Role.Manager]}>
        <Typography className={classes.moduleSettings} variant='h5'>
          Automation Workflows{' '}
          <Tooltip title='Module automation'>
            <IconButton
              component={Link}
              to={Pages.TENANT_AUTOMATION}
              data-testid='automation-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        </Typography>

        <div>
          <Text variant='normal'>
            Traction5, by default, automates numerous aspects of program
            management to save time and ensure smooth operation. Simultaneously,
            we provide the flexibility to disable some or all automations based
            on your specific needs.
          </Text>
        </div>

        <Typography className={classes.moduleSettings} variant='h5'>
          Venture Groups{' '}
          <Tooltip title='Module groups'>
            <IconButton
              component={Link}
              to={Pages.TENANT_COHORTS}
              data-testid='cohorts-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        </Typography>

        <div>
          <Text variant='normal'>
            Venture Groups allow program admins to combine ventures registered
            on Traction5 platform into logical groups. Although these groups do
            not have any functional impact, they can be used to further enhance
            processes owned by admin, e.g. onboarding or scheduling.
          </Text>
        </div>
      </WithRole>
      <WithRule rule={'modules.reports.view'}>
        <Typography className={classes.moduleSettings} variant='h5'>
          Automated Quarterly Reports{' '}
          <Tooltip title='Module Settings'>
            <IconButton
              component={Link}
              to={Pages.TENANT_QUESTIONS}
              data-testid='questions-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        </Typography>
        <TestId testId={'report-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.quarterlyReportEnabled ?? false)}
            options={quarterlyModuleStatuses}
            onChange={handleChangeModuleStatus}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            When enabled, Traction5 will send out automated requests to founders
            on January 1, April 1, July 1, and October 1 to submit updates on
            the number of full-time employees, revenue generated over the last 3
            months, funds raised, and likelihood the founders will recommend the
            {rb('mentorship')} program to peers.
          </Text>
        </div>
      </WithRule>
      <WithRole roles={[Role.Admin, Role.Manager]}>
        <Typography className={classes.subtitle} variant='h5'>
          Office Hours Access Control
        </Typography>
        <TestId testId={'office-hours-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.strictOfficeHoursScheduling ?? false)}
            options={officeHoursStatuses}
            onChange={handleChangeOfficeHours}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            <a
              target='_blank'
              rel='noreferrer'
              href={getRoutePath(Pages.PUBLIC_OH_LANDING, {
                tenantId: user?.id,
              })}>
              Here is the the link to the public page of Office Hours
            </a>
            . When registration and approval requirement is turned on, visitors
            to Office Hours will need to register and be approved by
            administrator before they are able to book advisors.
          </Text>
          <br />
          <br />
          <Text variant='normal'>
            Not Needed option means that Office Hours visitors can book advisors
            without registration. They will still be added to Traction5 as
            applicants, and their meetings with advisors will be tracked for
            reporting proposes.
          </Text>
        </div>

        <Typography className={classes.subtitle} variant='h5'>
          Most Impactful {rb('mentor-u')} Assessment
        </Typography>
        <TestId testId={'impactful-mentor-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.mostImpactfulMentorEnabled ?? false)}
            options={mostImpactfulMentor}
            onChange={handleChangeImpactfulMentor}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            When this option is enabled, then there will be a question for{' '}
            {rb('mentors')} and founders to select the most impactful{' '}
            {rb('mentor')}. This option affects the following forms: Lead{' '}
            {rb('mentor-u')} Report, {rb('mentor-u')} Assessments and Founder
            Notes forms, as well as Ventures Notes.
          </Text>
          <br />
          <br />
          <Text variant={'normal'}>
            Information collected through this form is used by Traction5 to
            identify {rb('mentors')} who are seen as most impactful by both
            founder and {rb('mentors')} to better understand how we can improve{' '}
            {rb('mentorship')}.
          </Text>
        </div>

        <Typography className={classes.subtitle} variant='h5'>
          Lead {rb('mentor-u')} Report Scheduling
        </Typography>
        <TestId testId={'report-based-scheduling-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.reportBasedScheduleEnabled ?? true)}
            options={reportBasedSchedulingOptions}
            onChange={handleChangeReportBasedScheduling}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            Disabling this feature removes the ability to select the next
            meeting date from the Lead {rb('mentor-u')} Report. It's generally
            advised to enable this to facilitate choosing the next session date
            with all participants present. Programs with pre-scheduled sessions
            often disable this to prevent overlapping bookings.
          </Text>
        </div>

        <Typography className={classes.subtitle} variant='h5'>
          Second Factor Authentication
        </Typography>
        <TestId testId={'second-factor-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.secondFactorEnabled ?? false)}
            options={secondFactorAuthOptions}
            onChange={handleChangeSecondFactorAuth}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            Second Factor authentication enables enhanced security for program
            members. Additional random code is sent to user email during the
            login process to confirm user identity.
          </Text>
        </div>
        <Typography className={classes.subtitle} variant='h5'>
          Group Statistics
        </Typography>
        <TestId testId={'group-statistics-enable'}>
          <Toggler<'on' | 'off'>
            className={classes.toggler}
            value={boolToToggle(user?.groupStatisticsEnabled ?? false)}
            options={groupStatisticsOptions}
            onChange={handleChangeGroupStatistics}
          />
        </TestId>
        <div>
          <Text variant='normal'>
            This setting will enable generation of {rb('mentor')} participation
            statistics that is based on existing groups. This high-level
            statistics will be shown in {rb('mentor')} list and contain a
            break-down of {rb('mentor')} participation by groups they are
            assigned to.
          </Text>
        </div>
        <CustomSpecializationsSettings />
      </WithRole>
      {/* {hasAccessToModal(KnownModule.Slack) && (
        <WithRule rule={'modules.reports.view'}>
          <Typography className={classes.subtitle} variant='h5'>
            Slack Integration
          </Typography>
          <TestId testId={'slack-integration'}>
            <SlackIntegrationButton className={classes.button} />
          </TestId>
          <div>
            <Text variant='normal'>
              When Slack integration is enabled you will start receiving all
              important program notifications in a selected slack channel so
              that you can react to those events in near-real-time fashion. When
              you click on a button you will be forwarded to a Slack workspace
              that will request your permission to install Traction5 Slack App
              into your Slack workspace. You must have permissions to install
              Slack Apps in order to complete the integration process.
            </Text>
          </div>
        </WithRule>
      )} */}
    </div>
  );
}

export default TenantSettingsPage;

const quarterlyModuleStatuses = [
  { label: 'Enabled', value: 'on' as const },
  { label: 'Disabled', value: 'off' as const },
];

const officeHoursStatuses = [
  {
    label: 'Authorized Only',
    value: 'on' as const,
    testId: 'authorized-only',
  },
  { label: 'Open to Public', value: 'off' as const, testId: 'open-to-public' },
];

const mostImpactfulMentor = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'disabled',
  },
];

const reportBasedSchedulingOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'report-based-scheduling-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'report-based-scheduling-disabled',
  },
];

const secondFactorAuthOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'second-factor-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'second-factor-disabled',
  },
];

const groupStatisticsOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'group-statistics-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'group-statistics-disabled',
  },
];

const customSpecializationsOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'custom-specializations-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'custom-specializations-disabled',
  },
];

function boolToToggle(v: boolean): 'on' | 'off' {
  switch (v) {
    case true:
      return 'on';
    case false:
      return 'off';
  }
}

function toggleToBool(v: 'on' | 'off'): boolean {
  switch (v) {
    case 'on':
      return true;
    case 'off':
      return false;
  }
}
