import {
  Question,
  QuestionId,
  Type
} from '../../api/tenants/QuestionTemplates/Question';

// region LoadFail

export interface LoadFail {
  type: 'LoadFail';
  payload: string;
}

export const loadFail = (payload: string): LoadFail => ({
  type: 'LoadFail',
  payload
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Question[];
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: QuestionId;
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload
});
// endregion

// region RemoveApprove
export interface RemoveApprove {
  type: 'RemoveApprove';
}

export const removeApprove = (): RemoveApprove => ({
  type: 'RemoveApprove'
});
// endregion

// region RemoveDeny
export interface RemoveDeny {
  type: 'RemoveDeny';
}

export const removeDeny = (): RemoveDeny => ({
  type: 'RemoveDeny'
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: 'RemoveFail';
  payload: {
    id: QuestionId;
    message: string;
  };
}

export const removeFail = (payload: RemoveFail['payload']): RemoveFail => ({
  type: 'RemoveFail',
  payload
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
  payload: QuestionId;
}

export const removeSuccess = (
  payload: RemoveSuccess['payload']
): RemoveSuccess => ({
  type: 'RemoveSuccess',
  payload
});
// endregion

// region AddNew
export interface AddNew {
  type: 'AddNew';
}

export const addNew = (): AddNew => ({ type: 'AddNew' });
// endregion

// region Edit
export interface Edit {
  type: 'Edit';
  payload: QuestionId;
}

export const edit = (payload: Edit['payload']): Edit => ({
  type: 'Edit',
  payload
});
// endregion

// region SetType
export interface SetType {
  type: 'SetType';
  payload: Type;
}

export const setType = (payload: SetType['payload']): SetType => ({
  type: 'SetType',
  payload
});
// endregion

// region SetDescription
export interface SetDescription {
  type: 'SetDescription';
  payload: string;
}

export const setDescription = (
  payload: SetDescription['payload']
): SetDescription => ({
  type: 'SetDescription',
  payload
});
// endregion

// region SetRequired
export interface SetRequired {
  type: 'SetRequired';
  payload: boolean;
}

export const setRequired = (payload: SetRequired['payload']): SetRequired => ({
  type: 'SetRequired',
  payload
});
// endregion

// region Save
export interface Save {
  type: 'Save';
}

export const save = (): Save => ({
  type: 'Save'
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: Question;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload
});
// endregion

// region SaveFail
export interface SaveFail {
  type: 'SaveFail';
  payload: string;
}

export const saveFail = (payload: SaveFail['payload']): SaveFail => ({
  type: 'SaveFail',
  payload
});
// endregion

// region Cancel
export interface Cancel {
  type: 'Cancel';
}

export const cancel = (): Cancel => ({
  type: 'Cancel'
});
// endregion

export type Actions =
  | LoadFail
  | LoadSuccess
  | Remove
  | RemoveApprove
  | RemoveDeny
  | RemoveFail
  | RemoveSuccess
  | AddNew
  | Edit
  | SetType
  | SetDescription
  | SetRequired
  | Save
  | SaveSuccess
  | SaveFail
  | Cancel;
