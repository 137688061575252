import { StylesProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

const client = () => {
  ReactDOM.render(
    <React.StrictMode>
      <StylesProvider injectFirst>
        <App />
      </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export default client;
