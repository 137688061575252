import { useState, useCallback, useEffect } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { TextField } from '../common';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import { Venture } from '../../api/ventures/types/Venture';
import { SelectVenture } from '../../api/ventures';
import MenuItem from '@material-ui/core/MenuItem';
import _set from 'lodash/set';
import cn from 'classnames';

const useStyles = makeStyles({
  containerAutocomplete: {
    position: 'relative'
  },
  selectContent: {
    width: '100%',
    position: 'absolute',
    zIndex: 100,
    top: 55,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:
      '0px 0px 2px rgb(9 29 61 / 25%), 0px 8px 16px rgb(34 91 187 / 8%), 0px 8px 24px rgb(51 126 255 / 8%)'
  },
  smallContent: {
    top: 38
  },
  invisibleWrapper: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 99,
    opacity: 1,
    margin: '0 !important'
  }
});

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  value: any;
  onChange: (e: any) => any;
  error?: boolean;
  small?: boolean;
}

function SelectVentureAutocomplete({
  className,
  value: initialValue,
  onChange: handleChange,
  error,
  small
}: Props) {
  const classes = useStyles();
  const [selectVenture, setSelectVenture] = useState<SelectVenture | undefined>(
    initialValue
  );
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>();
  const [showList, setShowList] = useState<boolean>(false);

  const handleVenturesSearch = useCallback((searchedVentures: Venture[]) => {
    setDisplayedVentures(searchedVentures);
  }, []);

  useEffect(() => {
    if (!initialValue) {
      setSelectVenture(undefined);
    }
  }, [initialValue]);

  return (
    <>
      {showList && (
        <div
          className={classes.invisibleWrapper}
          onClick={() => setShowList(false)}
        />
      )}
      <VentureAutocomplete
        onSearch={handleVenturesSearch}
        selectVenture={selectVenture}
        inputRenderer={({ value, onChange, isLoading }) => (
          <div className={cn(classes.containerAutocomplete, className)}>
            <TextField
              value={value}
              label='Venture name'
              placeholder='Enter venture name'
              small={small}
              error={error}
              onFocus={() => {
                setShowList(true);
              }}
              onChange={(e) => {
                setDisplayedVentures([]);
                if (!selectVenture) {
                  return onChange(e);
                }
                handleChange(null);
                setSelectVenture(undefined);
                return onChange(_set(e, 'e.target.value', ''));
              }}
              InputProps={{
                endAdornment: !!isLoading && (
                  <CircularProgress color='primary' size={20} />
                )
              }}
            />
            <div
              className={cn(classes.selectContent, {
                [classes.smallContent]: small
              })}>
              <ul>
                {showList &&
                  !selectVenture &&
                  displayedVentures &&
                  displayedVentures.length > 0 &&
                  displayedVentures.map((venture: Venture) => {
                    return (
                      <MenuItem
                        key={`select-venture-${venture.id}`}
                        value={venture.ventureName}
                        onClick={() => {
                          setSelectVenture({
                            ventureName: venture.ventureName,
                            id: venture.id
                          });
                          handleChange({
                            ventureName: venture.ventureName,
                            id: venture.id
                          });
                          setShowList(false);
                        }}>
                        {venture.ventureName}
                      </MenuItem>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default SelectVentureAutocomplete;
