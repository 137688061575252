import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import authAPI, { EventChannel, EventNewChannel } from '../../api/auth';
import { NewChannel, Channel, SnackMessage } from '../../components/common';
import { UserContext } from '../../contexts/user-context';

export interface FormValue {
  channelName: string;
  channelLocation: string;
  description: string;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
});

function TenantChannelsPage() {
  const classes = useStyles();
  const { channels, updateChannels } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isCreating, setIsCreating] = useState(false);
  const [updatingChannelIds, setUpdaingChannelIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingChannelIds, setDeletingChannelIds] = useState<{
    [x: string]: boolean;
  }>({});

  const handleCreate = useCallback(
    async (value: EventNewChannel, callback) => {
      try {
        setIsCreating(true);
        const createdNotes = await authAPI.createChannel(value);
        updateChannels([createdNotes, ...(channels || [])]);
        setIsCreating(false);
        callback();
        enqueueSnackbar('The channel was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the channel. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [enqueueSnackbar, channels, updateChannels],
  );

  const handleNoteUpdate = useCallback(
    async (channel: EventChannel, callback) => {
      try {
        setUpdaingChannelIds((prev) => ({ ...prev, [channel.id]: true }));
        await authAPI.updateChannel(channel);

        updateChannels(
          channels?.map((prevChannel) =>
            prevChannel.id === channel.id ? channel : prevChannel,
          ) || [],
        );
        setUpdaingChannelIds((prev) => ({ ...prev, [channel.id]: false }));
        callback();
        enqueueSnackbar('The channel was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the channel. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingChannelIds((prev) => ({ ...prev, [channel.id]: false }));
      }
    },
    [channels, updateChannels, enqueueSnackbar],
  );

  const handleNoteRemove = useCallback(
    async (channel: EventChannel, callback: () => any) => {
      try {
        setDeletingChannelIds((prev) => ({ ...prev, [channel.id]: true }));
        await authAPI.removeChannel(channel.id);
        updateChannels(
          channels?.filter((prev) => prev.id !== channel.id) || [],
        );
        callback();
        setDeletingChannelIds((prev) => ({ ...prev, [channel.id]: false }));
        enqueueSnackbar('The channel was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        callback();
        enqueueSnackbar(
          'An error occurred while removing the channel. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingChannelIds((prev) => ({ ...prev, [channel.id]: false }));
      }
    },
    [channels, updateChannels, enqueueSnackbar],
  );

  return (
    <div className={classes.container}>
      <NewChannel creating={isCreating} onCreate={handleCreate} />
      <div>
        {channels?.map((channel, index) => (
          <Channel
            key={channel.id}
            channel={channel}
            updating={updatingChannelIds[channel.id]}
            deleting={deletingChannelIds[channel.id]}
            onEdit={handleNoteUpdate}
            onRemove={handleNoteRemove}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default TenantChannelsPage;
