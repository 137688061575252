import { PropsWithChildren, ReactElement } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

export interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    height: '100vh',

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
}));

export function Container({
  children,
  className,
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();

  return (
    <div data-testid='report-page' className={classes.container}>
      <div className={cn(classes.block, className)}>{children}</div>
    </div>
  );
}
