import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useCommunityMemberSignup } from './useCommunityMemberSignup';
import TextField from '../../components/common/text-field';
import { isInvalid } from '../../utils/FormValue';
import * as Actions from './type/Actions';
import TextWysiwyg from '../../components/common/text-wysiwyg';
import { Button, FormButtons, FormGroup } from '../../components/common';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '350px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
  },
  title: {
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginBottom: '2rem',
  },
}));

export function CommunityMemberSignup() {
  const classes = useStyles();
  const [s, dispatch] = useCommunityMemberSignup();
  const loading = s.type === 'Saving';

  switch (s.type) {
    case 'Ready':
    case 'SaveError':
    case 'Submitted':
    case 'Saving':
      return (
        <div className={classes.root}>
          <div className={classes.form}>
            <Typography
              variant={'h3'}
              align={'center'}
              color={'textPrimary'}
              className={classes.title}>
              Sign up for Office Hours
            </Typography>

            <FormGroup>
              <TextField
                label='First Name'
                error={isInvalid(s.payload.firstName)}
                value={s.payload.firstName.value}
                disabled={loading}
                onChange={(e) => dispatch(Actions.setFirst(e.target.value))}
              />
            </FormGroup>

            <FormGroup>
              <TextField
                label='Last Name'
                error={isInvalid(s.payload.lastName)}
                value={s.payload.lastName.value}
                disabled={loading}
                onChange={(e) => dispatch(Actions.setLast(e.target.value))}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label='Email'
                error={isInvalid(s.payload.email)}
                value={s.payload.email.value}
                disabled={loading}
                onChange={(e) => dispatch(Actions.setEmail(e.target.value))}
              />
            </FormGroup>

            <FormGroup>
              <TextWysiwyg
                error={isInvalid(s.payload.note)}
                value={s.payload.note.value}
                disabled={loading}
                onChange={(e) => dispatch(Actions.setNote(e.target.value))}
                placeholder={
                  'Say a few words about yourself, and if you are a member of our organization'
                }
              />
            </FormGroup>

            <FormGroup>
              <Typography variant={'subtitle2'} align={'center'}>
                Your application will need to be reviewed. Once you are
                approved, you get sign up link.
              </Typography>
            </FormGroup>

            <FormButtons justify={'center'}>
              <Button
                disabled={loading}
                onClick={() => dispatch(Actions.submit())}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Submit'
                )}
              </Button>
            </FormButtons>
          </div>
        </div>
      );
    case 'SaveSuccess':
      return (
        <div className={classes.root}>
          <div className={classes.form}>
            <Typography
              variant={'h3'}
              align={'center'}
              color={'textPrimary'}
              className={classes.title}>
              Sign up for Office Hours
            </Typography>

            <Typography variant={'subtitle2'} align={'center'}>
              You application was submitted successfully. The administrator will
              review your application and reply with an email.
            </Typography>
          </div>
        </div>
      );
  }
}
