export const SPECIALIZATIONS = [
  { id: '9088ea7b-6dcb-45fb-bf43-b2b4d1d7ae0a', name: 'Finance', valid: true },
  { id: 'aabb5c05-c415-4b41-af24-7505e117d1fd', name: 'Energy', valid: true },
  {
    id: 'aa8ed509-d473-4038-8966-c6bada8307ad',
    name: 'Transport',
    valid: true
  },
  { id: '84726392-31ab-4cc7-9761-3458c3eb7f0f', name: 'Media', valid: true },
  { id: 'c1f86e46-17b0-4f04-bc4f-af241ea36129', name: 'Health', valid: true },
  {
    id: 'a817bb6d-0863-4ce5-af7b-df735398f90a',
    name: 'Manufacturing',
    valid: true
  },
  {
    id: 'd8e12cd9-89df-4168-9acb-da7b685ad352',
    name: 'Education',
    valid: true
  },
  {
    id: '10a13c3a-acc9-4125-bcd0-e92c2e27f828',
    name: 'Agriculture',
    valid: true
  },
  {
    id: '16d63888-abe4-43e8-a0be-fec78a3a3094',
    name: 'Food & Beverage',
    valid: true
  },
  {
    id: '8cfc8705-87bf-4c3e-b4a6-b7d2b3c52ae2',
    name: 'Construction',
    valid: true
  },
  {
    id: 'f810cd1d-b2da-4ac3-844c-f10352aa073b',
    name: 'Telecommunications',
    valid: true
  },
  {
    id: '03be5421-ec18-4b88-bca6-bdc7fd6cd386',
    name: 'Professional Services',
    valid: true
  },
  {
    id: 'a5965e48-2286-49db-9e83-856e21ddc540',
    name: 'Technology',
    valid: true
  },
  { id: '689a5d1d-d23c-44f8-8202-c6caafb08c12', name: 'Other', valid: true }
];
