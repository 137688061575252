import { Tabs, Tab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  goalId?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },

  tooltipWrapper: {
    marginLeft: '30px !important',

    '& > button': {
      whiteSpace: 'nowrap',
    },
  },
}));

function PageTabs({ goalId }: PageTabsProps) {
  const location = useLocation();
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);

  const currentTabs = useMemo(() => {
    const tabs: TabsProps[] = [];
    if (hasAccessToAction('goal.overview.view')) {
      tabs.push(
        {
          props: {
            label: 'Overview',
            'data-testid': 'goal-nav-overview',
            component: Link,
            ...(goalId
              ? {
                  to: getRoutePath(Pages.GOAL_OVERVIEW, { goalId }),
                  value: getRoutePath(Pages.GOAL_OVERVIEW, { goalId }),
                }
              : {
                  to: Pages.NEW_GOAL,
                  value: Pages.NEW_GOAL,
                }),
          },
        },
        {
          ...(!goalId
            ? {
                tooltip: {
                  title: `Please complete creation of goal\nbefore switching to Status Updates`,
                },
              }
            : {}),
          props: {
            'data-testid': 'goal-nav-checkins',
            label: 'Status Updates',
            disabled: !goalId,
            ...(goalId
              ? {
                  component: Link,
                  value: getRoutePath(Pages.GOAL_CHECK_INS, { goalId }),
                  to: getRoutePath(Pages.GOAL_CHECK_INS, { goalId }),
                }
              : {}),
          },
        },
        {
          ...(!goalId
            ? {
                tooltip: {
                  title: `Please complete creation of goal\nbefore switching to Tasks`,
                },
              }
            : {}),
          props: {
            'data-testid': 'goal-nav-tasks',
            label: 'Tasks',
            disabled: !goalId,
            ...(goalId
              ? {
                  component: Link,
                  value: getRoutePath(Pages.GOAL_TASKS, { goalId }),
                  to: getRoutePath(Pages.GOAL_TASKS, { goalId }),
                }
              : {}),
          },
        },
        {
          ...(!goalId
            ? {
                tooltip: {
                  title: `Please complete creation of goal\nbefore switching to Log`,
                },
              }
            : {}),
          props: {
            'data-testid': 'goal-nav-log',
            label: 'Log',
            disabled: !goalId,
            ...(goalId
              ? {
                  component: Link,
                  value: getRoutePath(Pages.GOAL_LOG, { goalId }),
                  to: getRoutePath(Pages.GOAL_LOG, { goalId }),
                }
              : {}),
          },
        },
      );
    }
    return tabs;
  }, [goalId, hasAccessToAction]);

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='on'>
        {currentTabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          ),
        )}
      </Tabs>
    </div>
  );
}

export default PageTabs;
