import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import specializationsAPI from '../../api/specializations';
import {
  NewSpecialization,
  PageLoader,
  SnackMessage,
} from '../../components/common';
import CustomSpecialization from '../../components/common/custom-specialization';
import { SpecializationContext } from '../../contexts/specialization-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const SPECIALIZATIONS_LIMIT = 30;

function TenantCustomSpecializationsPage() {
  const classes = useStyles();
  const history = useHistory();
  const { user, hasAccessToAction } = useContext(UserContext);
  const {
    isLoading: isSpecializationsLoading,
    specializations,
    setSpecializations,
    loadSpecializations,
  } = useContext(SpecializationContext);
  const [updatingSpecializationsIds, setUpdaingSpecializationsIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingSpecializationsIds, setDeletingSpecializationsIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [isCreating, setIsCreating] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateSpecialization = useCallback(
    async (value, callback) => {
      try {
        if (!user) {
          return;
        }

        setIsCreating(true);
        const createdSpecialization =
          await specializationsAPI.createSpecialization({
            ...value,
            tenantId: user.id,
          });
        setSpecializations((prev) => [createdSpecialization, ...prev]);
        setIsCreating(false);
        callback();
        enqueueSnackbar('The specialization was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the specialization. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [enqueueSnackbar, setSpecializations, user],
  );

  const handleCustomSpecializationUpdate = useCallback(
    async (value, callback) => {
      try {
        setUpdaingSpecializationsIds((prev) => ({ ...prev, [value.id]: true }));
        await specializationsAPI.updateSpecialization(value);
        setSpecializations((prev) =>
          prev.map((specialization) =>
            specialization.id === value.id ? value : specialization,
          ),
        );
        setUpdaingSpecializationsIds((prev) => ({
          ...prev,
          [value.id]: false,
        }));
        callback();
        enqueueSnackbar('The specialization was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the specialization. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingSpecializationsIds((prev) => ({
          ...prev,
          [value.id]: false,
        }));
      }
    },
    [enqueueSnackbar, setSpecializations],
  );

  const handleCustomSpecializationRemove = useCallback(
    async (value, callback: () => any) => {
      try {
        setDeletingSpecializationsIds((prev) => ({
          ...prev,
          [value.id]: true,
        }));
        if (user) {
          await specializationsAPI.deleteSpecialization(value.id);
          setSpecializations((prev) =>
            prev.filter((specialization) => specialization.id !== value.id),
          );
        }
        callback();
        setDeletingSpecializationsIds((prev) => ({
          ...prev,
          [value.id]: false,
        }));
        enqueueSnackbar('The specialization was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the specialization. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingSpecializationsIds((prev) => ({
          ...prev,
          [value.id]: false,
        }));
      }
    },
    [user, enqueueSnackbar, setSpecializations],
  );

  useEffect(() => {
    const loadCustomSpecializationsStatus = async () => {
      try {
        setIsLoadingStatus(true);
        const status =
          await specializationsAPI.getCustomSpecializationsStatus();

        if (!status) {
          history.push(Pages.TENANT_SETTINGS);
        }
      } catch (error) {
        console.error(error);
        history.push(Pages.TENANT_SETTINGS);
      } finally {
        setIsLoadingStatus(false);
      }
    };

    loadCustomSpecializationsStatus();
    loadSpecializations();
  }, [history, loadSpecializations]);

  const isLoading = isSpecializationsLoading || isLoadingStatus;

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {hasAccessToAction('specialization.create') && (
        <NewSpecialization
          creating={isCreating}
          isLimitExceeded={specializations.length >= SPECIALIZATIONS_LIMIT}
          limitNumber={SPECIALIZATIONS_LIMIT}
          onCreate={handleCreateSpecialization}
        />
      )}
      <div>
        {specializations.map((specialization, index) => (
          <CustomSpecialization
            key={specialization.id}
            specialization={specialization}
            updating={updatingSpecializationsIds[specialization.id]}
            deleting={deletingSpecializationsIds[specialization.id]}
            onEdit={handleCustomSpecializationUpdate}
            onRemove={handleCustomSpecializationRemove}
            readOnly={!hasAccessToAction('specialization.update')}
            index={index}
            isLast={specializations.length === 1}
          />
        ))}
      </div>
    </div>
  );
}

export default TenantCustomSpecializationsPage;
