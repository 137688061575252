import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { useRef, useEffect, useState } from 'react';
import { COLORS } from '../../theme/variables';
import { checkSizeTablet } from '../../utils/functions';

interface Props {
  children: React.ReactNode;
  className?: string;
  inaccuracy?: number;
  isShow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  stickyWrapper: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFFFFF',
    minHeight: 88,
    zIndex: -1,
    opacity: 0,
    boxSizing: 'border-box',
    padding: '24px 0 24px 40px',
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },

  isShowStickyContent: {
    zIndex: 1,
    opacity: 1,
  },

  isHiddenStaticContent: {
    opacity: 0,
  },
}));

const StickyContent = ({
  children,
  className,
  inaccuracy = 0,
  isShow = true,
}: Props) => {
  const [mentorOffset, setMentorOffset] = useState(0);
  const [mentorWidth, setMentorWidth] = useState(0);

  const stickyRef = useRef<HTMLDivElement>(null);
  const [isShowStickyContent, setIsShowStickyContent] = useState(false);
  const classes = useStyles({ mentorOffset, mentorWidth });

  useEffect(() => {
    const baseLayout = document.getElementById('scroll-content');

    const handleScroll = (e: any) => {
      const scrollContent = stickyRef?.current?.offsetTop;
      if (scrollContent && e?.target) {
        const sizeDifferenceHeight = checkSizeTablet(480) ? 215 : 112;
        const contentLocation =
          e.target?.scrollHeight -
          e.target?.scrollTop -
          sizeDifferenceHeight +
          inaccuracy;
        if (contentLocation <= scrollContent) {
          setIsShowStickyContent(false);
        } else {
          setIsShowStickyContent(true);
        }
      }
    };

    baseLayout?.addEventListener('scroll', handleScroll);

    return () => {
      baseLayout?.removeEventListener('scroll', handleScroll);
    };
  }, [inaccuracy]);

  useEffect(() => {
    const mentorContent = document.getElementById('page-content');
    setMentorOffset(mentorContent?.offsetLeft || 0);
    setMentorWidth(mentorContent?.offsetWidth || 0);

    const handleResize = () => {
      if (mentorContent) {
        setMentorOffset(mentorContent?.offsetLeft);
        setMentorWidth(mentorContent?.offsetWidth);
      }
    };

    window?.addEventListener('resize', handleResize);

    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isShow && (
        <div
          ref={stickyRef}
          className={cn(classes.stickyWrapper, className, {
            [classes.isShowStickyContent]: isShowStickyContent,
          })}
          style={{
            width: `${mentorWidth}px`,
            left: `${mentorOffset}px`,
          }}>
          {children}
        </div>
      )}
      <div
        className={cn({
          [classes.isHiddenStaticContent]: isShowStickyContent,
        })}
        id='button-submit-form'>
        {children}
      </div>
    </>
  );
};

export default StickyContent;
