// region NaturalString
import { Either, left, right } from '../Either';
import { isNumber } from '../Num';
import { isNatural, Natural } from '../Num/Natural';

declare const _naturalString: unique symbol;

export type NaturalString = string & { [_naturalString]: 'NaturalString' };
// endregion

export function strToEitherNaturalString(
  s: string,
): Either<string, NaturalString> {
  const n = Number(s);

  return isNumber(n) && isNatural(n)
    ? right(s as NaturalString)
    : left('Not a natural number');
}

export function naturalStringToNatural(s: NaturalString): Natural {
  return Number(s) as Natural;
}

export function naturalToNaturalString(n: Natural): NaturalString {
  return n.toString() as NaturalString;
}
