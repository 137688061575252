import { IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import filesAPI from '../../api/files';
import { ImgFileIcon, PdfFileIcon, TxtFileIcon } from '../../assets/icons';
import { COLORS } from '../../theme/variables';
import { humanFileSize } from '../../utils/functions';
import ConfirmButton from './confirm-button';
import Text from './text';

export interface Attachment {
  name: string;
  extension: string;
  size: number;
  url?: string;
}

export interface AttachmentCardProps {
  attachment: Attachment;
  loading?: boolean;
  onRemove?: () => any;
  classesFileName?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px 45px 10px 16px',
    position: 'relative',
    border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    background: 'white',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  link: {
    textDecoration: 'none',

    '&:hover': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  loader: {
    padding: '8px 0',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,

    '& svg': {
      fontSize: 19,
    },
  },
  fileInfoContainer: {
    paddingLeft: 16,
    width: 'calc(100% - 32px)',
    boxSizing: 'border-box',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  fileName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export function getAttachmentFromFile(file: File): Attachment {
  const splittedFilename = file.name.split('.');
  const extension = splittedFilename.pop();

  return {
    name: splittedFilename.join('.'),
    extension: extension || '',
    size: file.size,
  };
}

function AttachmentCard({
  attachment,
  loading,
  onRemove,
  classesFileName,
}: AttachmentCardProps) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const timer = useRef<number>();

  const handleDownloadFile = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const signed = await filesAPI.getFile(attachment?.url || '');
      window.open(signed, '_blank');
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (loading) {
      timer.current = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500) as unknown as number;

      return () => {
        clearInterval(timer.current);
      };
    }
  }, [loading]);

  const handleRemove = (cbSuccess: () => any, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof onRemove === 'function') {
      onRemove();
      cbSuccess();
    }
  };

  const getIconByExtension = (extension: string) => {
    switch (extension) {
      case 'pdf':
        return <PdfFileIcon />;
      case 'bmp':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        return <ImgFileIcon />;
      default:
        return <TxtFileIcon />;
    }
  };

  const content = (
    <>
      {!!onRemove && (
        <ConfirmButton
          title='Remove the attachment?'
          body='Are you sure you want to remove the attachment?'
          successProps={{
            btnLabel: 'Remove',
            onSuccess: handleRemove,
          }}
          cancelProps={{
            btnLabel: 'Cancel',
          }}
          buttonRenderer={({ onClick }) => (
            <IconButton
              className={classes.closeButton}
              aria-label='close'
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                onClick();
              }}>
              <CloseIcon />
            </IconButton>
          )}
        />
      )}
      {getIconByExtension(attachment.extension)}
      <span className={classes.fileInfoContainer}>
        <span className={classes.fileInfo}>
          <Text
            className={cn(classes.fileName, classesFileName)}
            variant='normal'>
            {attachment.name}
          </Text>
          <Text variant='normal'>.{attachment.extension}</Text>
        </span>
        {loading ? (
          <div className={classes.loader}>
            <LinearProgress variant='determinate' value={progress} />
          </div>
        ) : (
          <Text variant='normal2' color={COLORS.COLOR_GRAY_DARKENED}>
            {humanFileSize(attachment.size)}
          </Text>
        )}
      </span>
    </>
  );

  return attachment.url ? (
    <a
      className={cn(classes.container, classes.link)}
      href={`${process.env.REACT_APP_S3_URL}/files/41d2a1f2-998a-41a3-814a-d5d77226a072--avatar2.png`}
      target='_blank'
      rel='noreferrer'
      onClick={handleDownloadFile}>
      {content}
    </a>
  ) : (
    <span className={classes.container}>{content}</span>
  );
}

export default AttachmentCard;
