import * as Appointments from '../../../api/Appointments';
import { AppointmentId } from '../../../api/Appointments/types/Appointment';
import { FilterStatus } from '../../../api/Appointments/types/FilterStatus';
import { Status } from '../../../api/Appointments/types/Status';
import { BulkSuccess, LoadSuccess, RemoveSuccess } from './types/Actions';

export function get(
  date: [Date, Date] | undefined,
  status: FilterStatus | undefined,
  page: number,
): Promise<LoadSuccess['payload']> {
  const withDate = date !== undefined;
  const allStatuses = status === undefined || status === 'all';
  const promises =
    !allStatuses && withDate
      ? [
          Appointments.getByStatusAndRange(status, date[0], date[1], page - 1),
          Appointments.getByStatusAndRange(status, date[0], date[1], page),
        ]
      : withDate
      ? [
          Appointments.getByRange(date[0], date[1], page - 1),
          Appointments.getByRange(date[0], date[1], page),
        ]
      : !allStatuses
      ? [
          Appointments.getByStatus(status, new Date(), page - 1),
          Appointments.getByStatus(status, new Date(), page),
        ]
      : [
          Appointments.get(new Date(), page - 1),
          Appointments.get(new Date(), page),
        ];

  return Promise.all(promises).then(([items, count]) => ({
    items,
    next: !!count.length,
  }));
}

export function archive(
  id: AppointmentId,
  date: [Date, Date] | undefined,
  status: FilterStatus | undefined,
  page: number,
): Promise<RemoveSuccess['payload']> {
  return Appointments.updateStatus([id], Status.Archived).then(() =>
    get(date, status, page),
  );
}

export function bulkArchive(
  ids: AppointmentId[],
  date: [Date, Date] | undefined,
  status: FilterStatus | undefined,
  page: number,
): Promise<BulkSuccess['payload']> {
  return Appointments.updateStatus(ids, Status.Archived).then(() =>
    get(date, status, page),
  );
}
