import * as State from './types/State';
import { Actions } from './types/Actions';
import {
  isNonEmptyArray,
  NonEmptyArray,
} from '../../../../utils/Array/NonEmptyArray';
import { Item } from './types/Item';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Open':
      return s.type === 'Idle' ? State.ready() : s;
    case 'Cancel':
      return State.isOpen(s) ? State.idle() : s;
    case 'Send': {
      const items = a.payload.map((i): Item => ({ type: 'sending', id: i }));
      return State.isSubmittable(s) && isNonEmptyArray(items)
        ? State.sending({ items })
        : s;
    }
    case 'SendSuccess': {
      if (s.type === 'Sending') {
        const newList = s.payload.items.filter((i) => i.id !== a.payload);

        if (newList.length === s.payload.items.length) {
          return s;
        }

        if (isNonEmptyArray(newList)) {
          return State.sending({ items: newList });
        }

        return State.idle();
      }

      return s;
    }
    case 'SendError':
      return s.type === 'Sending' &&
        s.payload.items.find(
          (i) => i.id === a.payload.id && i.type === 'sending',
        )
        ? State.sending({
            items: s.payload.items.map((i) =>
              i.id === a.payload.id && i.type === 'sending'
                ? { ...i, type: 'sendError', message: a.payload.message }
                : i,
            ) as NonEmptyArray<Item>,
          })
        : s;
  }
}
