import { Range } from 'react-date-range';
import { Appointment } from '../../../../api/Appointments/types/Appointment';
import { FilterStatus } from '../../../../api/Appointments/types/FilterStatus';
import { Item } from './Item';

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    items: Appointment[];
    next: boolean;
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: Item['id'];
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload,
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: boolean;
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload'],
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload,
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
  payload: {
    items: Appointment[];
    next: boolean;
  };
}

export const removeSuccess = (
  payload: RemoveSuccess['payload'],
): RemoveSuccess => ({
  type: 'RemoveSuccess',
  payload,
});
// endregion

// region RemoveError
export interface RemoveError {
  type: 'RemoveError';
  payload: string;
}

export const removeError = (payload: RemoveError['payload']): RemoveError => ({
  type: 'RemoveError',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: Item['id'];
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Deselect
export interface Deselect {
  type: 'Deselect';
  payload: Item['id'];
}

export const deselect = (payload: Deselect['payload']): Deselect => ({
  type: 'Deselect',
  payload,
});
// endregion

// region ToggleAll
export interface ToggleAll {
  type: 'ToggleAll';
}

export const toggleAll = (): ToggleAll => ({
  type: 'ToggleAll',
});
// endregion

// region BulkRemove
export interface BulkRemove {
  type: 'BulkRemove';
}

export const bulkRemove = (): BulkRemove => ({
  type: 'BulkRemove',
});
// endregion

// region BulkConfirmation
export interface BulkConfirmation {
  type: 'BulkConfirmation';
  payload: boolean;
}

export const bulkConfirmation = (
  payload: BulkConfirmation['payload'],
): BulkConfirmation => ({
  type: 'BulkConfirmation',
  payload,
});
// endregion

// region BulkSuccess
export interface BulkSuccess {
  type: 'BulkSuccess';
  payload: {
    items: Appointment[];
    next: boolean;
  };
}

export const bulkSuccess = (payload: BulkSuccess['payload']): BulkSuccess => ({
  type: 'BulkSuccess',
  payload,
});
// endregion

// region BulkError
export interface BulkError {
  type: 'BulkError';
  payload: string;
}

export const bulkError = (payload: BulkError['payload']): BulkError => ({
  type: 'BulkError',
  payload,
});
// endregion

// region NextPage
export interface NextPage {
  type: 'NextPage';
}

export const nextPage = (): NextPage => ({
  type: 'NextPage',
});
// endregion

// region PrevPage
export interface PrevPage {
  type: 'PrevPage';
}

export const prevPage = (): PrevPage => ({
  type: 'PrevPage',
});
// endregion

// region SetStatus
export interface SetStatus {
  type: 'SetStatus';
  payload: FilterStatus | undefined;
}

export const setStatus = (payload: SetStatus['payload']): SetStatus => ({
  type: 'SetStatus',
  payload,
});
// endregion

// region SetDate
export interface SetDate {
  type: 'SetDate';
  payload: Range;
}

export const setDate = (payload: SetDate['payload']): SetDate => ({
  type: 'SetDate',
  payload,
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: 'ClearFilters';
}

export const clearFilters = (): ClearFilters => ({
  type: 'ClearFilters',
});
// endregion

export type PublicActions =
  | Toggle
  | ToggleAll
  | Remove
  | RemoveConfirmation
  | BulkRemove
  | NextPage
  | PrevPage
  | BulkConfirmation
  | SetStatus
  | SetDate
  | ClearFilters;

export type Actions =
  | LoadError
  | LoadSuccess
  | BulkSuccess
  | BulkError
  | RemoveConfirmation
  | RemoveSuccess
  | RemoveError
  | PublicActions;
