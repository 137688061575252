import { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import venturesAPI from '../../api/ventures';
import { PageLoader, Text } from '../../components/common';
import { useParams } from 'react-router';
import { COLORS } from '../../theme/variables';
import { VentureQuarterRecord } from '../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { ReportItem } from '../../components/ventures/report-item';
import { ReportLinkDialog } from '../../components/ventures/ReportLinkDialog';
import { VentureId } from '../../api/ventures/types/Venture';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  report_link: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.COLOR_BLUE_BASE,
    cursor: 'pointer',
  },
  reports: {
    marginTop: 20,
  },
  report_form: {
    margin: '20px 0',
  },
  report_item: {
    '& + &': {
      marginTop: 10,
    },
  },
});

function VentureLogsPage() {
  const classes = useStyles();
  const { ventureId } = useParams<{ ventureId: VentureId }>();
  const [reports, setReports] = useState<VentureQuarterRecord[]>();
  const [openedReport, setOpenedReport] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const loadReports = async (ventureId: string) => {
    try {
      setIsLoading(true);
      const loadedReports = await venturesAPI.getQuarterlyRecords(ventureId);
      setReports(loadedReports.filter((rep) => rep.status === 'SUBMITTED'));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleOpenAccordion = (reportId: string) => () =>
    setOpenedReport(openedReport !== reportId ? reportId : undefined);

  useEffect(() => {
    loadReports(ventureId);
  }, [ventureId]);

  return (
    <div className={classes.container}>
      <Typography variant='h4'>Quarterly Reports</Typography>
      <br />
      <br />
      <div>
        <Text variant='normal'>
          Traction5 automatically sends this venture a link to submit its
          quarterly report at the end of each quarter. Submitted reports are
          available below. Each report has a link as well to manually request
          submission, or to submit corrected data.
        </Text>
        <br />
        <Text variant='normal'>
          Request the venture to submit{' '}
          <ReportLinkDialog ventureIds={[ventureId]}>
            {({ open }) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={(e) => {
                  e.preventDefault();
                  open();
                }}
                href={'#'}>
                its quarterly report for the previous quarter
              </a>
            )}
          </ReportLinkDialog>
          .
        </Text>
      </div>
      <div className={classes.reports}>
        {isLoading ? (
          <PageLoader />
        ) : (
          reports?.map((report) => (
            <div className={classes.report_item} key={report.id}>
              <div
                onClick={handleOpenAccordion(report.id)}
                className={classes.report_link}>
                {openedReport === report.id ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
                <Typography variant='h5'>
                  Q{report.quarterNumber} {report.year}
                </Typography>
              </div>
              {openedReport === report.id && (
                <div className={classes.report_form}>
                  <ReportItem report={report} />
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default VentureLogsPage;
