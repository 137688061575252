import { idle, State } from './types/State';
import { Dispatch, useEffect, useReducer } from 'react';
import * as Actions from './types/Actions';
import { reducer } from './reducer';
import { useSnackbar } from 'notistack';
import { unreachableError } from '../../../../utils/unreachableError';
import { from, of } from 'rxjs';
import { invite } from '../../../../api/CommunityMembers';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { AxiosError } from 'axios';

export function useSendInvitation(): [State, Dispatch<Actions.Actions>] {
  const [state, dispatch] = useReducer(reducer, idle());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    switch (state.type) {
      case 'Idle':
      case 'Ready':
        return;
      case 'Sending': {
        const invite$ = from(state.payload.items)
          .pipe(
            distinctUntilChanged((a, b) => a.type === b.type && a.id === b.id),
            filter((i) => i.type === 'sending'),
            mergeMap(({ id }) => {
              return from(invite(id)).pipe(
                map(() => Actions.sendSuccess(id)),
                catchError((e: AxiosError) =>
                  of(
                    Actions.sendError({
                      id,
                      message: e.response?.data.message,
                    }),
                  ),
                ),
              );
            }),
          )
          .subscribe(dispatch);

        return () => invite$.unsubscribe();
      }
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state.type === 'Sending') {
      state.payload.items.forEach((i) => {
        if (i.type === 'sendError') {
          enqueueSnackbar(i.message, {
            variant: 'error',
            key: i.id,
            persist: false,
            preventDuplicate: true,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type === 'Sending' && state.payload.items]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => closeSnackbar(), []);

  return [state, dispatch];
}
