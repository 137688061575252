import { IconButton, Paper, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import authAPI from '../../api/auth';
import mentorsAPI, { Mentor, MentorStatus } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import {
  Button,
  ConfirmButton,
  MentorManagementRequestButton,
  PageLoader,
  RouteACL,
  SnackMessage,
  WriteReviewButton,
} from '../../components/common';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/mentors/page-tabs';
import { MentorContext, MentorProvider } from '../../contexts/mentor-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import MentorAdditionalInfoPage from './mentor-additional-info-page';
import MentorAssignmentsPage from './mentor-assignments-page';
import MentorDetailsPage from './mentor-details-page';
import MentorLogPage from './mentor-log-page';
import MentorNotesPage from './mentor-notes-page';
import MentorStatsPage from './mentor-stats-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  requestLink: {
    minWidth: 187,
    whiteSpace: 'nowrap',
  },
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
});

function MentorPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { mentorId, ventureId } =
    useParams<{ mentorId: Mentor['id']; ventureId: VentureId }>();
  const location = useLocation<{ prevPath?: string }>();
  const { rb } = useResourceBundles();

  const [prevPath] = useState(location.state?.prevPath);
  const {
    mentor,
    tenantUser,
    isMentorLoading,
    mentorSpecializations,
    loadMentor,
    loadMentorSpecializations,
    updateMentor,
    setTenantUser,
  } = useContext(MentorContext);
  const { hasAccessToAction, identityid } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);
  const [isLoadingUserInvite, setLoadingUserInvite] = useState(false);

  const handleStatusChange = useCallback(
    async (status: MentorStatus) => {
      if (mentor) {
        const prevStatus = mentor.status;
        try {
          updateMentor({ ...mentor, status });
          await mentorsAPI.update(mentor.id, { ...mentor, status });
          enqueueSnackbar(
            `The ${rb(
              'mentor',
            )} status was successfully changed to '${status}'`,
            {
              variant: 'success',
            },
          );
        } catch (e: any) {
          const messageError = e.response?.data?.message;

          updateMentor({ ...mentor, status: prevStatus });
          enqueueSnackbar(
            `An error occurred while changing the ${rb(
              'mentor',
            )}'s status. Please, try again.`,
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          console.error('error status change', e);
        }
      }
    },
    [mentor, updateMentor, enqueueSnackbar, rb],
  );

  const handleSendMentorUpdate = async (onClose: () => any) => {
    try {
      setSending(true);
      await mentorsAPI.sendMentorUpdateInvites([mentorId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setSending(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setSending(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const handleSendInvite = useCallback(
    async (onClose: () => any) => {
      if (mentor) {
        try {
          setLoadingUserInvite(true);
          tenantUser?.inviteCode
            ? await authAPI.userInvites(tenantUser.id)
            : await authAPI.createMentors([mentorId]);
          const loadedTenantUser = await authAPI.getUserByEmail(mentor.email);
          setTenantUser(loadedTenantUser);
          onClose();
          enqueueSnackbar('The invitation was successfully sent!', {
            variant: 'success',
          });
          setLoadingUserInvite(false);
        } catch (error: any) {
          const messageError = error.response?.data?.message;
          enqueueSnackbar(
            'An error occurred when sending the invitation. Please try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          setLoadingUserInvite(false);
        }
      }
    },
    [
      enqueueSnackbar,
      mentor,
      mentorId,
      setTenantUser,
      tenantUser?.id,
      tenantUser?.inviteCode,
    ],
  );

  // load mentor's info
  useEffect(() => {
    if (!mentor) {
      loadMentor(mentorId);
    }
  }, [mentorId, mentor, loadMentor]);

  // load mentor's specializations
  useEffect(() => {
    if (!mentorSpecializations) {
      loadMentorSpecializations(mentorId);
    }
  }, [mentorSpecializations, mentorId, loadMentorSpecializations]);

  return (
    <BaseLayout user={user} fullHeight>
      {isMentorLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={`${mentor?.firstName} ${mentor?.lastName}`}
            onStatusChange={handleStatusChange}
            backLink={
              <Tooltip title={rb(`Back to ${rb('mentors-u')}`)}>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.ACTIVE_MENTORS}
                  data-testid='button-back-mentors'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            actions={[
              {
                key: 'send-invite',
                label: tenantUser?.inviteCode ? 'Resend Invite' : 'New User',
                hidden:
                  !hasAccessToAction('userManagement.mentor') ||
                  tenantUser?.inviteCode === null ||
                  !mentor?.email,
                component: (
                  <ConfirmButton
                    title={`Give ${rb('mentor-u')} Access to Traction5`}
                    loading={isLoadingUserInvite}
                    body={`An invite to join Traction5 will be sent to this ${rb(
                      'mentor-u',
                    )}. They will be able to create an account, login, and review materials on their ventures and session. If they already have an account, the invite will be re-sent.`}
                    successProps={{
                      btnLabel: 'Send Invite',
                      onSuccess: handleSendInvite,
                    }}
                    cancelProps={{
                      btnLabel: 'Cancel',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        onClick={onClick}
                        variant='contained'
                        data-testid='button-invite-user'
                        startIcon={tenantUser?.inviteCode ? null : <AddIcon />}>
                        {tenantUser?.inviteCode ? 'Resend Invite' : 'User'}
                      </Button>
                    )}
                  />
                ),
              },
              {
                key: 'send-update',
                label: 'Request Profile Update',
                hidden: !hasAccessToAction('mentor.details.update'),
                component: (
                  <ConfirmButton
                    title='Send Single Profile Update Request'
                    loading={sending}
                    body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                    successProps={{
                      btnLabel: 'Ok',
                      onSuccess: handleSendMentorUpdate,
                    }}
                    cancelProps={{
                      btnLabel: 'Cancel',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        className={classes.requestLink}
                        onClick={onClick}
                        variant='contained'
                        data-testid='button-invite-mentor-update'>
                        Request Profile Update
                      </Button>
                    )}
                  />
                ),
              },
              {
                key: 'manage-mentor',
                label: `Manage ${rb('mentor-u')}`,
                hidden: !hasAccessToAction('mentor.details.manageMentor'),
                component: (
                  <MentorManagementRequestButton
                    title={`Manage ${mentor?.fullName}`}
                    mentorId={mentor?.id || null}
                    ventureId={ventureId}
                    buttonText={`Manage ${rb('mentor-u')}`}
                    defaultType='REMOVE'
                    selectableType
                  />
                ),
              },
              {
                key: 'write-review',
                label: 'Write a review',
                hidden: !hasAccessToAction('mentor.details.writeReview'),
                component: (
                  <WriteReviewButton
                    title='Write a review'
                    mentorName={mentor?.fullName || 'unknown'}
                    ventureId={ventureId}
                    mentorId={mentorId}
                  />
                ),
              },
            ]}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs mentorId={mentorId} ventureId={ventureId} />
            <div className={classes.content}>
              <Switch>
                <RouteACL
                  exact
                  path={Pages.MY_MENTOR_DETAILS}
                  component={MentorDetailsPage}
                  rule='mentor.details.view'
                  redirectUrl={Pages.MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MY_MENTOR_ADDITIONAL_INFO}
                  component={MentorAdditionalInfoPage}
                  rule={
                    identityid !== mentorId
                      ? 'mentor.additionalInfo.view'
                      : undefined
                  }
                  redirectUrl={Pages.MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_DETAILS}
                  component={MentorDetailsPage}
                  rule='mentor.details.view'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_ADDITIONAL_INFO}
                  component={MentorAdditionalInfoPage}
                  rule={
                    identityid !== mentorId
                      ? 'mentor.additionalInfo.view'
                      : undefined
                  }
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_ASSIGNMENTS}
                  component={MentorAssignmentsPage}
                  rule='mentor.assignemnts.view'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_NOTES}
                  component={MentorNotesPage}
                  rule='mentor.notes.view'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_STATS}
                  component={MentorStatsPage}
                  rule='mentor.statistics'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.MENTOR_LOG}
                  component={MentorLogPage}
                  rule='mentor.logs.view'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <Redirect to={Pages.MENTOR_DETAILS} />
              </Switch>
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

function withMentorProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <MentorProvider>
      <Component {...props} />
    </MentorProvider>
  );
}

export default withMentorProvider(MentorPage);
