import {
  ListItem,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { Draggable } from 'react-beautiful-dnd';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../../api/CustomQuestion/types/Type';
import { ConfirmButton, Text } from '../../components/common';
import { useCustomQuestionContext } from '../../contexts/custom-questions-context';
import { COLORS } from '../../theme/variables';
import { CustomQuestionPreview } from './CustomQuestionPreview';

const useStyles = makeStyles({
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
    padding: 0,
    '@media (max-width: 700px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
      gap: 8,
    },
  },
  draggingListItemContainer: {
    background: 'rgb(235,235,235)',
  },
  listItemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    border: `solid 1px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    borderRadius: 4,
    padding: 16,
    paddingTop: 40,
    boxSizing: 'border-box',
  },
  dragHandleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.2s ease-out',
  },
  preventDrag: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  itemActions: {
    display: 'flex',
  },
  previewContainer: {
    width: '100%',
    marginTop: 16,
    '& > div': {
      width: '100%',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type CustomQuestionCardProps = {
  item: CustomQuestion;
  index: number;
};

const getReadableType = (type: CustomQuestionType) =>
  type
    .split('_')
    .map((v) => capitalize(v))
    .join(' ');

export function CustomQuestionCard({ item, index }: CustomQuestionCardProps) {
  const classes = useStyles();
  const isSubtitle = item.type === CustomQuestionType.SUBTITLE;

  const { deleting, dragLoading, handleQuestionRemove, openEdit } =
    useCustomQuestionContext();

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          className={classes.listItem}
          data-testid={
            isSubtitle ? 'custom-subtitle-card' : 'custom-question-card'
          }
          {...provided.draggableProps}>
          <div
            className={classNames(
              snapshot.isDragging ? classes.draggingListItemContainer : '',
              classes.listItemContainer,
            )}>
            <div
              className={classNames(classes.dragHandleContainer, {
                [classes.preventDrag]: dragLoading,
              })}
              {...provided.dragHandleProps}>
              <DragHandle />
            </div>
            <div className={classes.headerContainer}>
              <Typography variant='h5'>{getReadableType(item.type)}</Typography>
              {item.isMandatory && (
                <Text variant='normal2' color={COLORS.COLOR_GRAY_DARKENED}>
                  Required
                </Text>
              )}
            </div>
            <div className={classes.previewContainer}>
              <CustomQuestionPreview item={item} />
            </div>
          </div>
          <div className={classes.itemActions}>
            <IconButton
              onClick={() => openEdit(item)}
              data-testid={`form-consent-edit-${index}`}>
              <EditIcon />
            </IconButton>
            <ConfirmButton
              loading={deleting}
              title={
                isSubtitle ? 'Remove the subtitle?' : 'Remove the question?'
              }
              body={
                isSubtitle
                  ? 'Sure you want to remove the subtitle? Changes can’t be undone.'
                  : 'Sure you want to remove the question? Changes can’t be undone.'
              }
              successProps={{
                btnLabel: 'Remove',
                onSuccess: () => {
                  handleQuestionRemove(item.id);
                },
              }}
              buttonRenderer={({ onClick }) => (
                <IconButton
                  onClick={onClick}
                  data-testid={
                    isSubtitle ? 'form-subtitle-delete' : 'form-question-delete'
                  }>
                  <DeleteIcon />
                </IconButton>
              )}
            />
          </div>
        </ListItem>
      )}
    </Draggable>
  );
}
