import { makeStyles, CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import venturesAPI from '../api/ventures';
import { VentureId } from '../api/ventures/types/Venture';
import { Text } from '../components/common';
import { UserContext } from '../contexts/user-context';
import { useQuery } from '../hooks/useQuery';
import { getRoutePath, Pages } from '../router/constants';
import { COLORS } from '../theme/variables';

const useStyles = makeStyles(() => ({
  meta_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    width: '100%',
    height: '100%',
  },
  meta_block: {
    maxWidth: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
    background: '#fff',
    padding: '32px',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    boxSizing: 'border-box',
  },
  meta_text: {
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
}));

export default function VentureUpdatePage() {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const key = query.get('key') || '';
  const ventureId = (query.get('ventureId') as VentureId) || null;
  const { updateToken } = useContext(UserContext);
  const [metaState, setMetaState] = useState<'loading' | 'error' | ''>(
    'loading',
  );

  const getVentureToken = useCallback(async () => {
    try {
      const token = await venturesAPI.getVentureToken(key);
      updateToken(token, true);
      history.replace(
        getRoutePath(Pages.VENTURE_EXTERNAL_DETAILS, {
          ventureId: ventureId,
        }),
      );
      setMetaState('');
    } catch (e) {
      setMetaState('error');
    }
  }, [updateToken, key, history, ventureId]);

  useEffect(() => {
    getVentureToken();
  }, [getVentureToken]);

  if (metaState === 'error') {
    return (
      <div
        data-testid='venture-update-page-error'
        className={classes.meta_container}>
        <div className={classes.meta_block}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.meta_text}>
            <Text variant='normal'>
              Sorry. Looks like your key has expired or you used this key
              before. We cannot provide access to this information anymore.
              Please contact your program Admin
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid='venture-update-page-loading'
      className={classes.meta_container}>
      <CircularProgress size={54} color='primary' />
    </div>
  );
}
