import {
  Checkbox,
  CircularProgress,
  IconButton,
  Link as MaterialLink,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { mPipe } from 'fp-utilities';
import { capitalize } from 'lodash';
import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { AdvisorId } from '../../../api/Advisors/types/Advisor';
import { Status, statuses } from '../../../api/Advisors/types/Status';
import { FiltersWrapper } from '../../../components/Pages/Forms/FiltersWrapper';
import { Listing } from '../../../components/Pages/Listing';
import { ArchiveFilter } from '../../../components/Pages/Listing/ArchiveFilter';
import { Search } from '../../../components/Pages/Listing/Search';
import {
  Props as TableProps,
  Table,
} from '../../../components/Pages/Listing/Table';
import { TestId } from '../../../components/Testing/TestId';
import {
  AlertState,
  Avatar,
  ConfirmDialog,
  CopyText,
  Link,
  Select,
  StatusBadge,
  Text,
} from '../../../components/common';
import { Add } from '../../../components/common/Buttons/Add';
import { LinkedIn } from '../../../components/common/Icons/LinkedIn';
import { TableFooter } from '../../../components/common/table';
import BaseLayout from '../../../components/layout/base-layout';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import * as Actions from './types/Actions';
import { Item } from './types/Item';
import { State } from './types/State';
import { useAdvisorsList } from './useAdvisorsList';

export function AdvisorsList({ user }: ProtectedRouteProps): ReactElement {
  const [state, dispatch] = useAdvisorsList(user);
  const { rb } = useResourceBundles();

  const onToggleAll = useCallback(
    () => dispatch(Actions.toggleAll()),
    [dispatch],
  );
  const handleToggle = useCallback(
    (id: AdvisorId) => dispatch(Actions.toggle(id)),
    [dispatch],
  );
  const handleRemove = useCallback(
    (id: AdvisorId) => dispatch(Actions.remove(id)),
    [dispatch],
  );
  const handlePageChange = useCallback(
    (p: number) => {
      if (p < state.payload.page) {
        dispatch(Actions.prevPage());
      } else if (p > state.payload.page) {
        dispatch(Actions.nextPage());
      }
    },
    [state.payload.page, dispatch],
  );

  const columns = useMemo<TableProps<TableKeys>['columns']>(() => {
    const items = getItems(state);
    const selected = items.filter((i) => i.selected);

    return {
      selected: {
        width: 42,
        title: (
          <Checkbox
            checked={!!selected.length && selected.length === items.length}
            color='primary'
            indeterminate={
              selected.length > 0 && selected.length !== items.length
            }
            onChange={onToggleAll}
          />
        ),
      },
      avatar: {
        width: 32,
        title: null,
      },
      name: {
        width: 256,
        title: 'Name',
        truncated: true,
      },
      linkedIn: {
        width: 60,
        title: null,
        alignContent: 'center',
      },
      status: {
        width: 100,
        title: 'Status',
        alignContent: 'center',
      },
      email: {
        width: 256,
        title: 'Email',
      },
      phone: {
        width: 200,
        title: 'Phone',
      },
      actions: {
        width: 100,
        title: null,
        hoverOnly: true,
        alignContent: 'end',
      },
    };
  }, [state, onToggleAll]);
  const items = useMemo<TableProps<TableKeys>['content']>(() => {
    const getItem = (i: Item): Record<TableKeys, ReactNode> => {
      return {
        selected: (
          <div>
            <TestId testId='advisor-check' data-id={i.id} data-email={i.email}>
              <Checkbox
                checked={i.selected}
                color='primary'
                onChange={() => handleToggle(i.id)}
              />
            </TestId>
          </div>
        ),
        avatar: (
          <TestId testId={'advisor-edit'}>
            <MaterialLink
              component={Link}
              to={{
                pathname: getRoutePath(Pages.OH_ADVISORS_EDIT, {
                  id: i.id,
                }),
              }}>
              <Avatar src={i.logo} name={i.initials} size='32' />
            </MaterialLink>
          </TestId>
        ),
        name: (
          <MaterialLink
            component={Link}
            to={{
              pathname: getRoutePath(Pages.OH_ADVISORS_EDIT, {
                id: i.id,
              }),
            }}>
            {i.name}
          </MaterialLink>
        ),
        email: (
          <CopyText text={i.email}>
            <MaterialLink href={`mailto:${i.email}`}>{i.email}</MaterialLink>
          </CopyText>
        ),
        phone: i.phone ? <CopyText text={i.phone}>{i.phone}</CopyText> : '-',
        status: (
          <TestId testId={`advisor-status-${i.status}`}>
            <StatusBadge status={i.status} variant={statusColor(i.status)} />
          </TestId>
        ),
        linkedIn: i.linkedIn ? (
          <div>
            <Tooltip title={`${i.name}'s LinkedIn profile`}>
              <IconButton target='_blank' href={i.linkedIn}>
                <LinkedIn />
              </IconButton>
            </Tooltip>
          </div>
        ) : null,
        actions: (
          <>
            <Tooltip title='Edit'>
              <IconButton
                component={Link}
                to={{
                  pathname: getRoutePath(Pages.OH_ADVISORS_EDIT, {
                    id: i.id,
                  }),
                }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete'>
              <IconButton>
                <Delete onClick={() => handleRemove(i.id)} />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
    };

    switch (state.type) {
      case 'Loading':
      case 'LoadError':
        return {
          type: 'ready',
          data: [],
        };
      case 'Ready':
      case 'UpdateConfirmation':
      case 'RemoveConfirmation':
      case 'RemoveErr':
      case 'UpdateErr':
        return {
          type: 'ready',
          data: state.payload.items.map(getItem),
        };
      case 'Updating':
      case 'Removing':
        return {
          type: 'reloading',
          data: state.payload.items.map(getItem),
        };
    }
  }, [state, handleRemove, handleToggle]);
  const empty = useMemo((): ReactElement | null => {
    switch (state.type) {
      case 'LoadError':
        return <AlertState type='error'>{state.payload.message}</AlertState>;
      case 'Ready':
        return <EmptyState />;
      case 'Loading':
        return <CircularProgress size={36} color='primary' />;
      case 'RemoveConfirmation':
      case 'Removing':
      case 'UpdateConfirmation':
      case 'Updating':
      case 'UpdateErr':
      case 'RemoveErr':
        return null;
    }
  }, [state]);
  const header = useMemo((): ReactNode => {
    const selected =
      state.type !== 'Loading' && state.type !== 'LoadError'
        ? state.payload?.items?.filter((i) => i.selected)
        : [];

    return (
      <>
        <FiltersWrapper
          showClear={state.payload.filter.type !== 'none'}
          onClear={() => dispatch(Actions.clearFilters())}>
          <Search
            value={
              state.payload.filter.type === 'search'
                ? state.payload.filter.value
                : ''
            }
            onChange={mPipe(Actions.search, dispatch)}
          />
          <div style={{ width: '160px' }}>
            <TestId testId={'status-select'}>
              <Select<Status>
                options={options}
                placeholder={'All Statuses'}
                value={
                  state.payload.filter.type === 'status'
                    ? state.payload.filter.value
                    : undefined
                }
                onSelect={(v) =>
                  v !== null && dispatch(Actions.setStatus(v.value))
                }
              />
            </TestId>
          </div>
        </FiltersWrapper>
        {state.type !== 'Loading' &&
          state.type !== 'LoadError' &&
          !!selected?.length && (
            <ArchiveFilter
              count={selected.length}
              archivedDisabled={
                selected.filter((i) => i.status === 'Active').length === 0
              }
              unArchivedDisabled={
                selected.filter((i) => i.status === 'Archived').length === 0
              }
              onArchive={() => dispatch(Actions.changeStatus('Archived'))}
              onUnArchive={() => dispatch(Actions.changeStatus('Active'))}
            />
          )}
      </>
    );
  }, [state, dispatch]);
  const removeConfirmation = useMemo(() => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
      case 'UpdateConfirmation':
      case 'Updating':
        return null;
      case 'RemoveConfirmation':
      case 'Removing':
        return (
          <ConfirmDialog
            isOpen={true}
            title={`Remove ${rb('advisor')}`}
            body={`Do you really want to remove ${rb('advisor')}?`}
            onCancel={() => dispatch(Actions.removeConfirmation(false))}
            onSuccess={() => dispatch(Actions.removeConfirmation(true))}
            disabled={state.type === 'Removing'}
            cancelProps={{
              label: 'Cancel',
              variant: 'outlined',
              'data-testid': 'advisor-remove-cancel',
            }}
            successProps={{
              label: 'Yes, proceed',
              variant: 'contained',
              'data-testid': 'advisor-remove-approve',
            }}
          />
        );
    }
  }, [state, rb, dispatch]);
  const updateConfirmation = useMemo(() => {
    switch (state.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
      case 'RemoveConfirmation':
      case 'Removing':
        return null;
      case 'UpdateConfirmation':
      case 'Updating':
        return (
          <ConfirmDialog
            isOpen={true}
            title={`${capitalize(statusActionTitle(state.payload.status))} ${rb(
              'advisors',
            )}`}
            body={`Do you really want to ${statusActionTitle(
              state.payload.status,
            )} ${rb('advisors')}?`}
            onCancel={() => dispatch(Actions.updateConfirmation(false))}
            onSuccess={() => dispatch(Actions.updateConfirmation(true))}
            disabled={state.type === 'Updating'}
            cancelProps={{
              label: 'Cancel',
              variant: 'outlined',
              'data-testid': 'advisor-remove-cancel',
            }}
            successProps={{
              label: 'Yes, proceed',
              variant: 'contained',
              'data-testid': 'advisor-remove-approve',
            }}
          />
        );
    }
  }, [state, rb, dispatch]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <Listing
        actions={[
          {
            key: 'new-advisor',
            label: `New ${rb('advisor-u')}`,
            component: (
              <Add
                key={'add'}
                title={rb('advisor-u')}
                to={Pages.OH_ADVISORS_NEW}
              />
            ),
          },
        ]}
        title={rb('advisors-u')}>
        <Table<TableKeys>
          columns={columns}
          content={items}
          empty={empty}
          header={header}
          footer={
            <TableFooter
              page={state.payload.page}
              onPageChange={handlePageChange}
              disabled={isLoading(state)}
              hasNextPage={hasNextPage(state)}
            />
          }
        />
      </Listing>
      {removeConfirmation}
      {updateConfirmation}
    </BaseLayout>
  );
}

const useEmptyStyles = makeStyles(() => {
  return {
    root: {
      display: 'grid',
      gap: '20px',
      textAlign: 'center',
    },
  };
});

function EmptyState() {
  const classes = useEmptyStyles();
  const { rb } = useResourceBundles();

  return (
    <div className={classes.root}>
      <Text variant='normal'>
        This panel contains a list of {rb('advisors')}.
        <br />
        We were not able to find information you requested,
        <br /> but feel free to add something new!
      </Text>
      <div>
        <Add title={rb('advisor-u')} to={Pages.OH_ADVISORS_NEW} />
      </div>
    </div>
  );
}

type TableKeys =
  | 'selected'
  | 'avatar'
  | 'name'
  | 'linkedIn'
  | 'status'
  | 'email'
  | 'phone'
  | 'actions';

function getItems(s: State): Item[] {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return [];
    case 'Ready':
    case 'Updating':
    case 'Removing':
    case 'UpdateConfirmation':
    case 'RemoveConfirmation':
    case 'RemoveErr':
    case 'UpdateErr':
      return s.payload.items;
  }
}

function statusColor(s: Item['status']): 'success' | 'warning' {
  switch (s) {
    case 'Archived':
      return 'warning';
    case 'Active':
      return 'success';
  }
}

function isLoading(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'Updating':
    case 'Removing':
      return true;
    case 'LoadError':
    case 'Ready':
    case 'UpdateConfirmation':
    case 'UpdateErr':
    case 'RemoveErr':
    case 'RemoveConfirmation':
      return false;
  }
}

function hasNextPage(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return false;
    case 'Ready':
    case 'Removing':
    case 'UpdateConfirmation':
    case 'Updating':
    case 'RemoveConfirmation':
    case 'RemoveErr':
    case 'UpdateErr':
      return s.payload.next;
  }
}

function statusActionTitle(s: Status): string {
  switch (s) {
    case 'Active':
      return 'un-archive';
    case 'Archived':
      return 'archive';
  }
}

function statusTitle(s: Status): string {
  switch (s) {
    case 'Archived':
      return 'Archived';
    case 'Active':
      return 'Active';
  }
}

const options = statuses.map((value) => ({
  value,
  label: statusTitle(value),
}));
