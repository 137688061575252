import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { FormButtons, Button, Text } from '../common';
import eventsAPI, { Event } from '../../api/events';

interface AssignFoundersFormProps {
  onCancel: () => any;
  selectedSessionsIds: Event['id'][];
  updateSession: () => any;
}

const useStyles = makeStyles({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1
  }
});

function SessionResolveIssues({
  onCancel,
  selectedSessionsIds,
  updateSession
}: AssignFoundersFormProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    eventsAPI.postSessionResolutions(selectedSessionsIds);

    const timeUpdateTable = setTimeout(() => {
      setLoading(false);
      onCancel();
      updateSession();
    }, 500);

    return () => {
      clearTimeout(timeUpdateTable);
    };
  };

  return (
    <>
      <Text variant='normal'>
        Do you really want to resolve all selected issues?
      </Text>
      <FormButtons className={classes.formButtons}>
        <Button onClick={onCancel} variant='outlined' data-testid='form-cancel'>
          Cancel
        </Button>
        <Button
          disabled={loading}
          type='submit'
          onClick={handleSubmit}
          data-testid='form-apply'>
          {loading ? <CircularProgress size={24} color='inherit' /> : 'Yes'}
        </Button>
      </FormButtons>
    </>
  );
}

export default SessionResolveIssues;
