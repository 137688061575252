import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import foundersAPI from '../../api/founders';
import mentorsAPI from '../../api/mentors';
import venturesAPI from '../../api/ventures';
import { ExportIcon } from '../../assets/icons';
import {
  Button,
  Modal,
  AttachFilesButton,
  Text,
  SeeErrorsButton,
} from '../../components/common';
import { LoadingDrawerContext } from '../../contexts/loading-drawer-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import AttachmentCard, { getAttachmentFromFile } from './attachment-card';

interface ImportButtonProps {
  alias: string;
  title: string;
  caption: string;
}

const ACCEPT_CSV_FILE =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

const TIMER_REMOVED_LOADING_DRAWER = 30000;

const useTitleStyles = makeStyles({
  container: {
    minHeight: '320px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  attachmentFile: {
    maxWidth: 350,
  },
  attachButton: {
    margin: '20px 0 24px',
  },
  body: {
    minHeight: 180,
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: 15,
    marginTop: 20,
  },
});

function ImportButton({ alias, title, caption }: ImportButtonProps) {
  const classes = useTitleStyles();
  const { rb } = useResourceBundles();
  const [file, setFile] = useState<File>();
  const { aliasLoadingExport, operation, createLoadingDrawer } =
    useContext(LoadingDrawerContext);
  const { enqueueSnackbar } = useSnackbar();

  const getListNameByAlias = (alias: string) => {
    switch (alias) {
      case 'mentor':
        return `The ${rb('mentors-u')} list`;
      case 'venture':
        return 'The Ventures list';
      default:
        return 'The Founders list';
    }
  };

  const importRequest = useMemo(() => {
    switch (alias) {
      case 'mentor':
        return mentorsAPI.import;
      case 'venture':
        return venturesAPI.import;
      default:
        return foundersAPI.import;
    }
  }, [alias]);

  const handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    setFile(file);
    e.target.value = '';
  };

  const handleRemoveFile = () => {
    setFile(undefined);
  };

  const handleImportFile = async (file: File, cb: () => any) => {
    cb();
    setFile(undefined);
    if (aliasLoadingExport) {
      return operation === 'export'
        ? enqueueSnackbar(
            `Currently, ${getListNameByAlias(
              aliasLoadingExport,
            )} is being exported.\n\nPlease wait for the export operation to complete and try again.`,
            { variant: 'error' },
          )
        : enqueueSnackbar(
            `Currently, ${getListNameByAlias(
              aliasLoadingExport,
            )} is being imported.\n\nPlease wait for the import operation to complete and try again.`,
            { variant: 'error' },
          );
    }

    createLoadingDrawer(
      {
        status: 'loading',
        body: (
          <Text variant='normal'>
            {getListNameByAlias(alias)} is being imported...
          </Text>
        ),
        alias,
      },
      'import',
    );

    try {
      await importRequest(file);
      createLoadingDrawer(
        {
          status: 'success',
          body: (
            <div>
              <Text variant='normal'>
                The import of {getListNameByAlias(alias)} was completed
                successfully. Please, reload the page in 30 seconds
              </Text>
            </div>
          ),
          timer: TIMER_REMOVED_LOADING_DRAWER,
        },
        'import',
      );
    } catch (e: any) {
      let errors: object | null;
      try {
        errors = JSON.parse(e.response.data.errors);
      } catch (error: any) {
        errors = null;
      }

      createLoadingDrawer(
        {
          status: 'error',
          body: (
            <div>
              <Text variant='normal'>
                An error occurred while importing {getListNameByAlias(alias)}
              </Text>
              <br />
              {errors && <SeeErrorsButton errors={errors} />}
            </div>
          ),
        },
        'import',
      );
    }
  };

  return (
    <Modal
      title={title}
      caption={caption}
      contentRenderer={({ handleClose }) => (
        <div className={classes.container}>
          <div className={classes.body}>
            <Text variant='normal'>
              You are about to import existing founders/{rb('mentors')}/ventures
              into Traction5 system. <br />
              Please import founders and {rb('mentors')} prior to importing
              ventures. Please make sure that people names in venture references
              are the same as in {rb('mentors')}/founders lists.
              <br />
              Your files must be presented in CSV format, columns must follow
              the naming convention outlined below. Columns may be presented in
              different order. <br />- <strong>Founder fields</strong>: #,First
              Name,Last Name,Date of Birth,LinkedIn,Date of
              Enrollment,Country,State,City,Address,Zip,Email,Phone,Specialization
              <br />- <strong>{rb('mentor-u')} fields</strong>: #,First
              Name,Last Name,Date of Birth,LinkedIn,Date of
              Enrollment,Country,State,City,Email,Phone,Specialization
              <br />- <strong>Venture fields</strong>: #,Venture Name,Venture
              Description,Founder,Emails,Website,Country,State,City,Address,Zip,Lead
              {rb('mentor-u')},{rb('mentor-u')} 2,{rb('mentor-u')} 3,
              {rb('mentor-u')} 4,Is Tech
              <br />
              Select a file to upload using "Attach Files" button, then click
              "Upload". Import process may take some time.
            </Text>
          </div>
          <AttachFilesButton
            className={classes.attachButton}
            onChange={handleUploadFile}
            accept={ACCEPT_CSV_FILE}
          />
          {!!file && (
            <div className={classes.attachmentFile}>
              <AttachmentCard
                attachment={getAttachmentFromFile(file)}
                onRemove={handleRemoveFile}
              />
            </div>
          )}
          <div className={classes.contentButton}>
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (file) {
                  handleImportFile(file, handleClose);
                }
              }}
              disabled={!file}>
              Upload
            </Button>
          </div>
        </div>
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          onClick={onClick}
          className={CLASS_TRACKING.ENTITY_ACTION}
          variant='outlined'
          data-testid='import-button'
          startIcon={
            aliasLoadingExport === alias && operation === 'import' ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <ExportIcon />
            )
          }
          disabled={aliasLoadingExport === alias && operation === 'import'}>
          Import
        </Button>
      )}
      width={1200}
    />
  );
}

export default ImportButton;
