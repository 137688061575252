import { makeStyles, Typography, Switch, FormLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext } from 'react';
import authAPI, { Tenant } from '../../api/auth';
import { SnackMessage } from '../../components/common';
import { InfoTooltip } from '../../components/common/InfoTooltip';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { COLORS } from '../../theme/variables';

type TenantKeyType = keyof Tenant;

interface ToggleProps {
  name: TenantKeyType;
  value: boolean;
  label: string;
  moduleName: string;
  handleChange: (
    key: TenantKeyType,
    value: boolean,
    moduleName: string,
  ) => void;
  disabled?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 420,
  },
  title: {
    margin: '0 0 20px',
  },
  toggleList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  label: {
    color: COLORS.COLOR_TEXT_BASE,
  },
});

const Toggle: FC<ToggleProps> = ({
  name,
  value,
  label,
  moduleName,
  disabled,
  tooltip,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toggleWrapper}>
      <div className={classes.toggleContainer}>
        <FormLabel htmlFor={name} className={classes.label}>
          {label}
        </FormLabel>
        {tooltip && <InfoTooltip message={tooltip} />}
      </div>
      <Switch
        checked={value}
        color='primary'
        onChange={(_, checked) => handleChange(name, checked, moduleName)}
        disabled={disabled}
        id={name}
        name={name}
      />
    </div>
  );
};

function TenantAutomationPage() {
  const classes = useStyles();
  const { user, updateUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const handleChange = useCallback(
    async (key: TenantKeyType, value: boolean, moduleName: string) => {
      try {
        if (user) {
          const updatedTenant = await authAPI.updateTenant(user?.id, {
            ...user,
            [key]: value,
            timeZone: user.timeZone ?? 'US/Eastern',
          });
          updateUser(updatedTenant);
          const message = value
            ? `${moduleName} is enabled`
            : `${moduleName} is disabled`;
          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        const message = value
          ? `An error occurred while activating ${moduleName} module. Please, try again.`
          : `An error occurred while deactivating ${moduleName} module. Please, try again.`;

        enqueueSnackbar(message, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, updateUser, user],
  );

  if (!user) return null;

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant='h4'>
        Automation Workflows
      </Typography>
      <div className={classes.toggleList}>
        <Toggle
          name='automationProfileUpdatesEnabled'
          value={user.automationProfileUpdatesEnabled}
          label={'Profile update requests'}
          moduleName={'Automatic Profile update requests'}
          handleChange={handleChange}
          tooltip={`We encourage founders and ${rb(
            'mentors',
          )} to keep their profiles up to date by sending update requests via e-mail. We will only reach out to those who do not have Additional Info section filled out. Founders and ${rb(
            'mentors',
          )} do not need Traction5 account to update their profiles.`}
        />
        <Toggle
          name='automationIssuesCleanupEnabled'
          value={user.automationIssuesCleanupEnabled}
          label={'Unresolved issue management'}
          moduleName={'Automatic Unresolved issue management'}
          handleChange={handleChange}
          tooltip={`In order to reduce the amount of workload on program admins we remove some of the Session issues automatically after certain period of time, when issue relevance is lost.`}
        />
        <Toggle
          name='automationAgendaReminderEnabled'
          value={user.automationAgendaReminderEnabled}
          label={'Agenda reminders'}
          moduleName={'Automatic Agenda reminders'}
          handleChange={handleChange}
          tooltip={`Every Thursday we will send a friendly reminder to Founders about upcoming sessions for the next week and a need to create/update agenda for those sessions.`}
        />
        <Toggle
          name='automaticSchedulingEnabled'
          value={!!user.automaticSchedulingEnabled}
          label={'Automatic Scheduling'}
          moduleName={'Automatic Scheduling'}
          handleChange={handleChange}
          tooltip={`If this option is enabled the system will find all the ventures that do not have upcoming events and will send out Scheduling Wizard invites to founders to initiate the process of scheduling. Automatic scheduling is done on the first day of each month.`}
        />
        <Toggle
          name='inviteReminderWeekEnabled'
          value={!!user.inviteReminderWeekEnabled}
          label={'Invite Reminder Week'}
          moduleName={'Invite Reminder Week'}
          handleChange={handleChange}
          tooltip={`When this capability is enabled, our system will send invite reminders to all founders and ${rb(
            'mentors',
          )} who were invited to a session that was created a week ago and did not respond with either confirmation or decline of the session invite.`}
        />
        <Toggle
          name='inviteReminderTwoDaysEnabled'
          value={!!user.inviteReminderTwoDaysEnabled}
          label={'Invite Reminder Two Days'}
          moduleName={'Invite Reminder Two Days'}
          handleChange={handleChange}
          tooltip={`We will send reminders about session acceptance to all founders and ${rb(
            'mentors',
          )} invited to a session if they have not yet responded to the initial invite and the session is scheduled to take place in 48 hours.`}
        />
      </div>
    </div>
  );
}

export default TenantAutomationPage;
