import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { add } from 'date-fns';
import { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { COLORS } from '../../theme/variables';
import { formatDateToRFC } from '../../utils/date';
import { FormDateTimeInput } from '../forms/wrappers';
import { FormGroup } from './form-layout';

interface Props {
  onValid?: (valid: boolean) => any;
  errorVaildDate?: boolean;
}

const useStyles = makeStyles({
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_RED_BASE} !important`,
    },
  },
  success: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.COLOR_GREEN_BASE} !important`,
    },
  },
});

const validFormatDate = (value: string) => {
  if (!value) {
    return false;
  }
  try {
    formatDateToRFC(new Date(value));
    return true;
  } catch (error: any) {
    return false;
  }
};

function FieldDates({ onValid, errorVaildDate }: Props) {
  const classes = useStyles();
  const startField = useField('nextEventStart');
  const endField = useField('nextEventEnd');
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | ''>();

  const handleEndFocus = () => {
    const isValid = validFormatDate(startField.input.value);
    if (isValid && !endField.input.value) {
      const newData = add(new Date(startField.input.value), {
        hours: 1,
        minutes: 30,
      });
      endField?.input?.onChange(formatDateToRFC(newData));
    }
  };

  useEffect(() => {
    if (onValid) {
      const valid = status !== 'error' && status !== 'loading';
      onValid(valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (errorVaildDate) {
      setStatus('error');
    }
  }, [errorVaildDate]);

  return (
    <FormGroup mobile>
      <Field<string>
        className={cn({
          [classes.error]: status === 'error',
          [classes.success]: status === 'success',
        })}
        name='nextEventStart'
        testId='date-start'
        component={FormDateTimeInput}
        label='Next Session Date Start'
        onChange={() => setStatus('')}
        parse={(value) => {
          try {
            if (value) {
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
        minDate={new Date()}
        maxDate={endField.input.value || undefined}
      />
      <Field<string>
        className={cn({
          [classes.error]: status === 'error',
          [classes.success]: status === 'success',
        })}
        name='nextEventEnd'
        testId='date-end'
        onChange={() => setStatus('')}
        component={FormDateTimeInput}
        label='Next Session Date End'
        currentValue={startField?.input?.value}
        parse={(value) => {
          try {
            if (value) {
              return formatDateToRFC(value);
            }
            return '';
          } catch (e: any) {
            return 'invalid';
          }
        }}
        disablePast
        minDate={startField.input.value || new Date()}
        onFocus={handleEndFocus}
      />
    </FormGroup>
  );
}

export default FieldDates;
