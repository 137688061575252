import { useContext, useCallback, useState } from 'react';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { Text, Button } from '../../components/common';
import educationAPI from '../../api/education';
import EditIcon from '@material-ui/icons/Edit';
import { createMarkup } from '../../utils/functions';
import AddIcon from '@material-ui/icons/Add';
import cn from 'classnames';
import { InitialValues } from '../../pages/education/education-list-page';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile
} from '../../components/common/attachment-card';
import EducationForm from '../forms/education-form';
import { UserContext } from '../../contexts/user-context';

interface Props {
  changeEducation: (materials: string, attachments: string) => void;
  createEducation: (materials: string, attachments: string) => void;
  initialValues: InitialValues;
  loading: boolean;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670
  },
  readText: {
    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert'
    }
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  emptyContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 20
  },
  emptyContainer: {
    maxWidth: '100%',
    height: 500
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px'
  },
  attachment: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px'
  }
});

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function EducationListScreen({
  changeEducation,
  createEducation,
  initialValues,
  loading
}: Props) {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const initialValuesForm = initialValues;
  const [materials, setMaterials] = useState<string>(
    initialValuesForm.materials
  );
  const [attachments, setAttachments] = useState(initialValuesForm.attachments);

  const [isEdit, setIsEdit] = useState<boolean>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaterials(e.target.value);
  };

  const handleCancel = () => {
    setMaterials(initialValues.materials);
    setIsEdit(false);
  };

  const handleSave = useCallback(() => {
    const currentMaterials = materials || '';
    const formatedAttachments = formatAttachments(attachments);
    if (initialValues?.id) {
      changeEducation(currentMaterials, formatedAttachments);
    } else {
      createEducation(currentMaterials, formatedAttachments);
    }
    setIsEdit(false);
  }, [initialValues, materials, createEducation, changeEducation, attachments]);

  const handleUploadFile = useCallback(async (file: File) => {
    try {
      const loadedFileURL = await educationAPI.attachFileToEducation(file);
      return loadedFileURL;
    } catch (e: any) {
      return '';
    }
  }, []);

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index)
    );
  };

  const handleFileUpload = async (file: File) => {
    try {
      const fileIndex = attachments.length;
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        getAttachmentFromFile(file)
      ]);
      const fileURL = await handleUploadFile(file);
      setAttachments((prevAttachments) => {
        return prevAttachments.map((prevAttach, prevAttachIndex) => {
          if (prevAttachIndex !== fileIndex) {
            return prevAttach;
          }
          return {
            ...prevAttach,
            url: fileURL
          };
        });
      });
    } catch (e: any) {}
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  if (isEdit) {
    return (
      <div className={classes.container}>
        <EducationForm
          materials={materials}
          handleChange={handleChange}
          handleSave={handleSave}
          loading={loading}
          handleSelectFile={handleSelectFile}
          attachments={attachments}
          handleFileRemove={handleFileRemove}
          handleCancel={handleCancel}
        />
      </div>
    );
  }

  return (
    <div
      data-testid='education-page'
      className={cn(classes.container, {
        [classes.emptyContainer]: !materials
      })}>
      {materials ? (
        <>
          {hasAccessToAction('education.edit') && (
            <div className={classes.iconContainer}>
              <Tooltip title='Edit'>
                <IconButton
                  data-testid='material-content-button-edit'
                  onClick={() => {
                    setIsEdit(true);
                  }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div
            data-testid='material-content'
            className={classes.readText}
            dangerouslySetInnerHTML={createMarkup(materials)}
          />
          <div className={classes.attachmentsList}>
            {attachments.map((attachment, attachmentIndex) => (
              <div key={attachmentIndex} className={classes.attachment}>
                <AttachmentCard attachment={attachment} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div
          className={classes.emptyContent}
          data-testid='education-empty-container'>
          {hasAccessToAction('education.edit') ? (
            <>
              <Text variant='normal'>
                If you would like to add such materials, please click here.
              </Text>
              <Button
                data-testid='education-add-materials'
                onClick={() => {
                  setIsEdit(true);
                }}
                startIcon={<AddIcon />}
                variant='contained'
                color='primary'>
                Training Materials
              </Button>
            </>
          ) : (
            <Text variant='normal'>
              This page is empty now but Program Admin will prepare some
              materials shortly.
              <br />
              Please stay tuned!
            </Text>
          )}
        </div>
      )}
    </div>
  );
}

export default EducationListScreen;
