import { Dispatch, Reducer, useEffect, useReducer } from 'react';
import { loading, State } from './types/State';
import * as Actions from './types/Actions';
import { reducer } from './reducer';
import { from, of } from 'rxjs';
import { getLogs } from '../../../api/CommunityMembers';
import { useParams } from 'react-router-dom';
import { CommunityMemberId } from '../../../api/CommunityMembers/types/CommunityMember';
import { catchError, map } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { unreachableError } from '../../../utils/unreachableError';
import { useSnackbar } from 'notistack';

export function useLogs(): [State, Dispatch<Actions.Actions>] {
  const { id } = useParams<{ id: CommunityMemberId }>();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer<Reducer<State, Actions.Actions>>(
    reducer,
    loading({ id }),
  );

  useEffect(() => {
    switch (state.type) {
      case 'Loading': {
        const o$ = from(getLogs(state.payload.id))
          .pipe(
            map(Actions.loadSuccess),
            catchError((e: AxiosError) =>
              of(Actions.loadError(e.response?.data?.message)),
            ),
          )
          .subscribe(dispatch);
        return () => {
          o$.unsubscribe();
        };
      }
      case 'LoadError': {
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        return undefined;
      }
      case 'Ready':
        return undefined;
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  return [state, dispatch];
}
