import { OfficeHours } from '../sidebar/OfficeHoursMenu';
import { Sidebar } from '../common/Sidebar';

export interface Props {
  className?: string;
}

export function OfficeHoursSidebar({ className }: Props) {
  return (
    <Sidebar className={className}>
      <OfficeHours />
    </Sidebar>
  );
}
