import { Advisor } from '../../../api/Advisors/types/Advisor';
import { Ready } from './types/State';

export function advisorToReady(a: Advisor): Ready['payload'] {
  return {
    id: a.id,
    logo: undefined,
    enrollmentDate: new Date(a.dateOfEnrollment),
    tenantId: a.tenantId,
    item: {
      address: a.address ?? undefined,
      logo: a.logo ?? undefined,
      long: a.longDescription,
      short: a.shortDescription ?? undefined,
      email: a.email,
      country: a.country ?? undefined,
      phone: a.phone ?? undefined,
      birthDate: a.dateOfBirth ? new Date(a.dateOfBirth) : undefined,
      city: a.city ?? undefined,
      state: a.state ?? undefined,
      linkedIn: a.linkedInProfile ?? undefined,
      lastName: a.lastName,
      zip: a.zip ?? undefined,
      status: a.status,
      firstName: a.firstName,
    },
  };
}
