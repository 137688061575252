import { IconButton, Link as MaterialLink } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useMemo, SyntheticEvent } from 'react';
import { useLocation } from 'react-router';
import { Founder } from '../../api/founders';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { getStatusVariant } from '../../utils/status';
import { ConfirmButton, Text, Link, StatusBadge } from '../common';

interface FoundersTableProps {
  founders: Founder[];
  disabledFoundersIds?: {
    [x: string]: boolean;
  };
  onRemove: (founder: Founder) => any;
  showTitle?: boolean;
  fullWidth?: boolean;
  isLink?: boolean;
  readOnly?: boolean;
}

const useStyles = makeStyles({
  table: {
    maxWidth: 480,
  },
  title: {
    width: '100%',
    padding: '14px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  name: {
    width: 420,
    marginRight: 10,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  text: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
  },
});

function FoundersTable({
  founders,
  disabledFoundersIds,
  onRemove,
  showTitle = true,
  fullWidth = false,
  isLink = false,
  readOnly = false,
}: FoundersTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const handleRemove = (founder: Founder) => () => {
    onRemove(founder);
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div
      className={cn(classes.table, {
        [classes.fullWidth]: fullWidth,
      })}>
      {showTitle && (
        <div className={classes.title}>
          <Text variant='normal2' bold>
            Founders
          </Text>
        </div>
      )}
      {founders.map((founder, index) => {
        const isDisabled = disabledFoundersIds
          ? disabledFoundersIds[founder.id]
          : false;

        return (
          <div
            key={founder.id}
            className={classes.row}
            data-testid={`assigned-founder-${index}`}>
            <div
              className={cn(classes.name, { [classes.fullWidth]: fullWidth })}>
              {isLink ? (
                <MaterialLink
                  onClick={stopPropagation}
                  component={Link}
                  to={{
                    pathname: getRoutePath(Pages.FOUNDER_DETAILS, {
                      founderId: founder.id,
                    }),
                    state: {
                      prevPath: currentPath,
                    },
                  }}>
                  <Text className={classes.text} variant='normal'>
                    {`${founder.firstName} ${founder.lastName}`}
                  </Text>
                </MaterialLink>
              ) : (
                <Text variant='normal'>
                  {`${founder.firstName} ${founder.lastName}`}
                </Text>
              )}
            </div>
            {!!founder.assignmentStatus && (
              <div>
                <StatusBadge
                  status={founder.assignmentStatus}
                  variant={getStatusVariant(founder.assignmentStatus)}
                />
              </div>
            )}
            {!readOnly && (
              <div>
                <ConfirmButton
                  title='Remove the assignment?'
                  body='Sure you want to remove the assignment? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRemove(founder),
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      disabled={isDisabled}
                      data-testid={`assigned-delete-button-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default FoundersTable;
