import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { FC, Fragment, useMemo } from 'react';
import { Text } from '../../../components/common';
import {
  NoteType,
  PersonalNote,
} from '../../../contexts/personal-notes-context';
import { COLORS } from '../../../theme/variables';
import { getNoteCardDate } from '../../../utils/date';

interface PersonalNoteCardProps {
  note: PersonalNote;
  selected?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'flex',
    gap: 20,
    padding: 20,
    transition: 'all 0.3s',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    },
  },
  activeCard: {
    backgroundColor: COLORS.COLOR_BLUE_BASE,

    '& *': {
      color: '#FFFFFF !important',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    justifyContent: 'space-between',
    width: '100%',
  },
  bodyTitle: {
    display: 'block',
    width: '210px',
    marginBottom: 4,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2',
  },
  bodyText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    lineHeight: '1.2',
  },
  cardBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardDetails: {
    display: 'block',
    width: '210px',
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    fontSize: 12,
    lineHeight: '1.2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    width: 37,
    minWidth: 37,
    padding: '12px 10px 14px',
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_40,
    border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    borderRadius: 4,
    overflow: 'hidden',
  },
  weekDay: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 28,
    fontWeight: 600,
  },
}));

function extractFirstThreeLines(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const allNodes = doc.body.childNodes;
  const nodesArray = Array.from(allNodes || []);
  const textContent = nodesArray.map((node) =>
    node.textContent ? node.textContent.trim() : '',
  );
  return textContent.filter((text) => text.length > 0).slice(0, 3);
}

const CardDateLabel = ({ week, date }: { week: string; date: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardDateContainer}>
      <Text variant='normal' className={classes.weekDay}>
        {week}
      </Text>
      <Text variant='normal' className={classes.date}>
        {date}
      </Text>
    </div>
  );
};

const PersonalNoteCard: FC<PersonalNoteCardProps> = ({
  note,
  selected,
  onClick,
}) => {
  const classes = useStyles();

  const { week, date, details, title, content } = useMemo(() => {
    const dateString = getNoteCardDate(note.date);
    const [week, date, time] = dateString.split(' ');
    const firstThreeLines = note.content
      ? extractFirstThreeLines(note.content)
      : [];
    const title = firstThreeLines[0] || '';
    const content = firstThreeLines.slice(1);
    const details = [time, NoteType[note.type], note.ventureName]
      .filter((detail) => detail)
      .join(', ');
    return {
      week,
      date,
      title,
      content,
      details,
    };
  }, [note]);

  return (
    <div
      id={`note-${note.parentId}`}
      className={cn(classes.cardContainer, {
        [classes.activeCard]: selected,
      })}
      onClick={onClick}>
      <CardDateLabel week={week} date={date} />
      <div className={classes.body}>
        <div>
          {title && (
            <Text className={classes.bodyTitle} variant='normal'>
              {title}
            </Text>
          )}
          {content.length > 0 && (
            <Text className={classes.bodyText} variant='normal'>
              {content.map((line, idx) => (
                <Fragment key={idx}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </Text>
          )}
        </div>
        <div className={classes.cardBottom}>
          <Text variant='normal' className={classes.cardDetails}>
            {details}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PersonalNoteCard;
