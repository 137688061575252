import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export function useQuery(): {
  get: (s: string) => string | null;
  set: (r: Record<string, string | undefined>) => void;
} {
  const history = useHistory();
  const historyRef = useRef(history);
  historyRef.current = history;

  return useMemo(
    () => ({
      get: (key: string) =>
        new URLSearchParams(historyRef.current.location.search).get(key),
      set: (r) => {
        const t = new URLSearchParams(historyRef.current.location.search);
        const initial = t.toString();

        Object.entries(r).forEach(([key, value]) => {
          if (value) {
            t.set(key, value);
          } else {
            t.delete(key);
          }
        });

        const search = t.toString();

        if (initial !== search) {
          historyRef.current.push({
            pathname: historyRef.current.location.pathname,
            search: t.toString(),
          });
        }
      },
    }),
    [],
  );
}
