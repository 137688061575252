import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { ConfirmButton, Text } from '../common';
import { COLORS } from '../../theme/variables';

interface Props {
  className?: string;
  guests: string[];
  onRemove: (assignment: string) => any;
  readOnly?: boolean;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 560
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    padding: '11px 12px 11px 0',

    '&:hover:not($headRow)': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45
    }
  },
  headRow: {
    padding: '13px 0 15px',

    '& $leadTd': {
      padding: '0 16px'
    }
  },
  td: {
    padding: '0 10px',
    boxSizing: 'border-box'
  },
  leadTd: {
    minWidth: 70
  },
  nameTd: {
    width: '100%'
  },
  text: {
    color: COLORS.COLOR_BLUE_DARKENED_10
  }
});

function GuestsTable({ className, guests, onRemove, readOnly }: Props) {
  const classes = useStyles();

  const handleRemove = (value: string) => () => {
    onRemove(value);
  };

  return (
    <div className={cn(classes.container, className)}>
      {guests.map((guest: string, index: number) => {
        return (
          <div
            key={`guest-item-${guest}`}
            className={classes.row}
            data-testid={`guests-item-${index}`}>
            <div className={cn(classes.td, classes.nameTd)}>
              <Text className={classes.text} variant='normal'>
                {guest}
              </Text>
            </div>
            {!readOnly && (
              <div>
                <ConfirmButton
                  title='Remove the guest?'
                  body='Sure you want to remove the guest? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRemove(guest)
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel'
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`assigned-delete-button-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default GuestsTable;
