import { TenantId } from '../Tenant';

declare const questionId: unique symbol;

export type QuestionId = string & { [questionId]: 'QuestionId' };

export enum Type {
  PARAGRAPH = 'PARAGRAPH',
  NUMBER = 'NUMBER'
}

export interface Create {
  type: Type;
  tenantId: TenantId;
  description: string;
  required: boolean;
}

export interface Question extends Create {
  id: QuestionId;
  creationDate: string;
}
export interface Update extends Partial<Create> {
  id: QuestionId;
}
