import { CircularProgress, Link } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useRef, useCallback } from 'react';
import { LoadingDrawerContext } from '../../../src/contexts/loading-drawer-context';
import foundersAPI from '../../api/founders';
import mentorsAPI from '../../api/mentors';
import venturesAPI from '../../api/ventures';
import { DownloadIcon } from '../../assets/icons';
import { Text } from '../../components/common';
import { Button } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { CLASS_TRACKING } from '../../utils/tracking_class';

interface ExportButtonProps {
  alias: string;
}

function ExportButton({ alias }: ExportButtonProps) {
  const { aliasLoadingExport, operation, createLoadingDrawer } =
    useContext(LoadingDrawerContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();
  const interval = useRef<any>();

  const getListNameByAlias = (alias: string) => {
    switch (alias) {
      case 'mentor':
        return `The ${rb('mentors-u')} list`;
      case 'venture':
        return 'The Ventures list';
      default:
        return 'The Founders list';
    }
  };

  const chooseAlias = useMemo(() => {
    switch (alias) {
      case 'mentor':
        return {
          export: mentorsAPI.exportMentors,
          check: mentorsAPI.checkExportMentors,
        };
      case 'venture':
        return {
          export: venturesAPI.exportVentures,
          check: venturesAPI.checkExportVentures,
        };
      case 'founder':
        return {
          export: foundersAPI.exportFounder,
          check: foundersAPI.checkExportFounder,
        };
      default:
        return {
          export: () => {},
          check: () => {},
        };
    }
  }, [alias]);

  const handleCancel = useCallback(() => {
    clearInterval(interval.current);
  }, []);

  const onExport = async () => {
    if (aliasLoadingExport) {
      return operation === 'export'
        ? enqueueSnackbar(
            `Currently, ${getListNameByAlias(
              aliasLoadingExport,
            )} is being exported.\n\nPlease wait for the export operation to complete and try again.`,
            { variant: 'error' },
          )
        : enqueueSnackbar(
            `Currently, ${getListNameByAlias(
              aliasLoadingExport,
            )} is being imported.\n\nPlease wait for the import operation to complete and try again.`,
            { variant: 'error' },
          );
    }

    createLoadingDrawer(
      {
        status: 'loading',
        body: (
          <Text variant='normal'>
            {getListNameByAlias(alias)} is being exported...
          </Text>
        ),
        alias,
        onClosed: handleCancel,
      },
      'export',
    );

    const loadedExport: any = await chooseAlias.export();

    interval.current = setInterval(async () => {
      let check = await chooseAlias.check(loadedExport?.jobId);

      if (check) {
        createLoadingDrawer(
          {
            status: 'success',
            body: (
              <div>
                <Text variant='normal'>
                  The export of {getListNameByAlias(alias)} was completed
                  successfully. Click on the link to download the file
                </Text>
                <br />
                <Text variant='normal'>
                  <Link href={check.signedUrl}>Download file</Link>
                </Text>
              </div>
            ),
          },
          'export',
        );
        clearInterval(interval.current);
      }
    }, 5000);
  };

  return (
    <Button
      variant='outlined'
      data-testid='export-button'
      className={CLASS_TRACKING.ENTITY_ACTION}
      startIcon={
        aliasLoadingExport === alias && operation === 'export' ? (
          <CircularProgress size={24} color='inherit' />
        ) : (
          <DownloadIcon />
        )
      }
      disabled={aliasLoadingExport === alias && operation === 'export'}
      onClick={onExport}>
      Export
    </Button>
  );
}

export default ExportButton;
