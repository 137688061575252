import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { ReactElement } from 'react';
import { useAsyncLogo } from '../../hooks/useAsyncLogo';

interface AsyncLogoImageProps {
  className?: string;
  src: string;
  alt?: string;
  loading?: ReactElement;
  error?: ReactElement;
}

function AsyncLogoImage({
  className,
  src,
  alt,
  loading,
  error,
}: AsyncLogoImageProps): ReactElement | null {
  const [state] = useAsyncLogo(src);
  const [brokenImage, setBrokenImage] = useState(false);

  switch (state.type) {
    case 'Loading':
      return loading ?? <CircularProgress size={24} className={className} />;
    case 'LoadErr':
      return error ?? <>error</>;
    case 'Success':
      return brokenImage ? null : (
        <img
          className={className}
          src={state.url}
          alt={alt}
          onError={() => setBrokenImage(true)}
        />
      );
  }
}

export default AsyncLogoImage;
