import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Field } from 'react-final-form';
import { QuarterlyReportDate } from '../../api/reports';
import {
  Button,
  Modal,
  FormButtons,
  CustomSelect,
} from '../../components/common';
import { FormCheckbox } from '../../components/forms/wrappers';

interface Props {
  selectedDate?: string;
  reportDates?: QuarterlyReportDate[];
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  loading: boolean;
  onChangeDate: (date: string) => any;
  onlyDateInput?: boolean;
}

const useTitleStyles = makeStyles({
  container: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  filterItem: {
    marginBottom: 10,

    '& + &': {
      marginLeft: 15,
    },
  },
  dateRangePicker: {
    position: 'fixed',
  },
  formButtons: {
    width: '100%',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

export interface FormValues {
  includeLogo?: string;
  includeMissing?: string;
  includeNPS?: string;
  includeAnnualTable?: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};

  return errors;
};

function ModalCustomizeQuarterlyReport({
  selectedDate,
  reportDates = [],
  initialValues,
  onSubmit,
  loading,
  onChangeDate = () => {},
  onlyDateInput,
}: Props) {
  const classes = useTitleStyles();

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  const options = reportDates.map((date) => ({
    label: `Q${date.quarter} ${date.year}`,
    value: `${date.quarter}.${date.year}`,
  }));

  const selectedValue = selectedDate || options[0]?.value;

  const handleChangeDate = (e: any) => {
    onChangeDate(e.target.value);
  };

  return (
    <Modal
      title='Customize report'
      caption=''
      contentRenderer={({ handleClose }) => (
        <div className={classes.container}>
          <Form
            validate={validateForm}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize={false}
            render={(formProps) => (
              <>
                <div className={classes.filterItem}></div>
                {!onlyDateInput && (
                  <>
                    <CustomSelect
                      options={options}
                      value={selectedValue}
                      onChange={handleChangeDate}
                    />
                    <Field<string>
                      testid='customize-report-include-logo'
                      name='includeLogo'
                      component={FormCheckbox}
                      label='Include logo'
                    />
                    <Field<string>
                      testid='customize-report-include-missing-reports'
                      name='includeMissing'
                      component={FormCheckbox}
                      label='Include missing reports reference'
                    />
                    <Field<string>
                      testid='customize-report-include-nps'
                      name='includeNPS'
                      component={FormCheckbox}
                      label='Include Net Promoter Score'
                    />
                    <Field<string>
                      testid='customize-report-include-annual-table'
                      name='includeAnnualTable'
                      component={FormCheckbox}
                      label='Include annual table'
                    />
                  </>
                )}
                <FormButtons className={classes.formButtons}>
                  <Button
                    disabled={loading}
                    type='submit'
                    onClick={async () => {
                      try {
                        await formProps.handleSubmit();
                        handleClose();
                      } catch (e: any) {}
                    }}
                    data-testid='form-apply'>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Generate'
                    )}
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant='outlined'
                    data-testid='form-cancel'>
                    Cancel
                  </Button>
                </FormButtons>
              </>
            )}
          />
        </div>
      )}
      buttonRenderer={({ onClick }) => (
        <Button onClick={onClick} data-testid='customize-report-button'>
          Customize Report
        </Button>
      )}
      width={!onlyDateInput ? 600 : 310}
    />
  );
}

export default ModalCustomizeQuarterlyReport;
