import { useSnackbar } from 'notistack';
import { useCallback, useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { MentorId } from '../../api/mentors';
import { SnackMessage } from '../../components/common';
import { AssignMentors } from '../../components/forms/invite-gathering-mentors';
import GatheringMentorsManually from '../../components/gathering/gathering-mentors-manually';
import GatheringModal, {
  RadioValue,
} from '../../components/gathering/gathering-modal';
import { GatheringContext } from '../../contexts/gathering-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { massAsyncRequest } from '../../utils/api';

const GatheringMentorsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { rb } = useResourceBundles();

  const [loading, setLoading] = useState(false);
  const { loadGathering, gathering } = useContext(GatheringContext);
  const { enqueueSnackbar } = useSnackbar();
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const [openModalAssing, setOpenModalAssing] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const intermediateAssingValue = useRef<{ value: AssignMentors }>();
  const intermediateRemoveValue =
    useRef<{ mentorIds: MentorId[]; cb: () => any }>();

  const handleAssingMentors = useCallback(
    async (type: RadioValue) => {
      if (!intermediateAssingValue.current || !gathering) {
        return;
      }

      const { value } = intermediateAssingValue.current;
      const { assigned, removed, isAll } = value;

      try {
        setLoading(true);
        if (isAll) {
          if (type === 'all') {
            await gatheringsAPI.assignsAllMentorsGroup(gathering.groupId);
          } else {
            await gatheringsAPI.assignsAllMentors(gatheringId);
          }
        } else {
          let assignedRequests = [];
          let removedRequests = [];
          if (type === 'all') {
            assignedRequests = assigned.map(
              (mentorId) => () =>
                gatheringsAPI.assignsMentorGroup(gathering.groupId, mentorId),
            );
            removedRequests = removed.map(
              (mentorId) => () =>
                gatheringsAPI.removeMentorAssignmentGroup(
                  gathering.groupId,
                  mentorId,
                ),
            );
          } else {
            assignedRequests = assigned.map(
              (mentorId) => () =>
                gatheringsAPI.assignsMentor(gatheringId, mentorId),
            );
            removedRequests = removed.map(
              (mentorId) => () =>
                gatheringsAPI.removeMentorAssignment(gatheringId, mentorId),
            );
          }

          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
        }

        await loadGathering(gatheringId);
        setOpenModalAssing(false);
        setLoading(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          `An error occurred while assign the ${rb(
            'mentors',
          )}. Please, try again.`,
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setOpenModalAssing(false);
        setLoading(false);
      }
    },
    [enqueueSnackbar, gathering, gatheringId, loadGathering, rb],
  );

  const handleRemoveMentors = useCallback(
    async (type: RadioValue) => {
      if (!intermediateRemoveValue.current || !gathering) {
        return;
      }
      const { mentorIds, cb } = intermediateRemoveValue.current;

      try {
        setLoading(true);

        let removedRequests = [];
        if (type === 'all') {
          removedRequests = mentorIds.map(
            (mentorId) => () =>
              gatheringsAPI.removeMentorAssignmentGroup(
                gathering.groupId,
                mentorId,
              ),
          );
        } else {
          removedRequests = mentorIds.map(
            (mentorId) => () =>
              gatheringsAPI.removeMentorAssignment(gatheringId, mentorId),
          );
        }

        await massAsyncRequest(removedRequests);
        await loadGathering(gatheringId);
        await cb();
        history.replace(location.pathname);
        setOpenModalRemove(false);
        setLoading(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          `An error occurred while remove the assignment${
            mentorIds.length > 1 ? 's' : ''
          }. Please, try again.`,
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setOpenModalRemove(false);
        setLoading(false);
      }
    },
    [enqueueSnackbar, gathering, gatheringId, history, loadGathering, location],
  );

  const handleOpenModalAssing = useCallback(
    (value: AssignMentors, handleModalClose: () => any) => {
      handleModalClose();
      intermediateAssingValue.current = { value };
      if (!gathering?.periodString) {
        return handleAssingMentors('all');
      }
      setOpenModalAssing(true);
    },
    [handleAssingMentors, gathering],
  );

  const handleOpenModalRemove = useCallback(
    (mentorIds: MentorId[], cb: () => any) => {
      intermediateRemoveValue.current = { mentorIds, cb };
      if (!gathering?.periodString) {
        return handleRemoveMentors('all');
      }
      setOpenModalRemove(true);
    },
    [handleRemoveMentors, gathering],
  );

  return (
    <>
      <GatheringMentorsManually
        loading={loading}
        handleRemoveMentors={handleOpenModalRemove}
        handleAssingMentors={handleOpenModalAssing}
      />
      <GatheringModal
        loading={loading}
        openModal={openModalAssing}
        setOpenModal={setOpenModalAssing}
        handleSubmit={handleAssingMentors}
      />
      <GatheringModal
        loading={loading}
        openModal={openModalRemove}
        setOpenModal={setOpenModalRemove}
        handleSubmit={handleRemoveMentors}
      />
    </>
  );
};

export default GatheringMentorsPage;
