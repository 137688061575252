import { CohortId } from '../../../../api/Cohort/types/Cohort';
import { TenantId } from '../../../../api/tenants/Tenant';
import { Invalid, Valid } from '../../../../utils/FormValue';
import { Sentence } from '../../../../utils/String/Sentence';

export class ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
  ) {}
}

export class ExistingView extends ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
    public readonly title: Sentence<100>,
    public readonly description: Sentence<2048> | undefined,
  ) {
    super(id, tenantId);
  }
}

export class ExistingEditing extends ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
    public readonly title: Valid<Sentence<100>> | Invalid<string, string>,
    public readonly description:
      | Valid<Sentence<2048> | undefined>
      | Invalid<string, string>,

    public readonly initialTitle: Valid<Sentence<100>>,
    public readonly initialDescription: Valid<Sentence<2048> | undefined>,
  ) {
    super(id, tenantId);
  }
}

export class ExistingCancelEditingConfirm extends ExistingEditing {}

export class ExistingSaving extends ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
    public readonly title: Valid<Sentence<100>>,
    public readonly description: Valid<Sentence<2048> | undefined>,

    public readonly initialTitle: Valid<Sentence<100>>,
    public readonly initialDescription: Valid<Sentence<2048> | undefined>,
  ) {
    super(id, tenantId);
  }
}

export class ExistingRemoving extends ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
    public readonly title: Sentence<100>,
    public readonly description: Sentence<2048> | undefined,
  ) {
    super(id, tenantId);
  }
}

export class ExistingRemoveConfirm extends ExistingItemBase {
  constructor(
    public readonly id: CohortId,
    public readonly tenantId: TenantId,
    public readonly title: Sentence<100>,
    public readonly description: Sentence<2048> | undefined,
  ) {
    super(id, tenantId);
  }
}

export type ExistingItemState =
  | ExistingView
  | ExistingEditing
  | ExistingCancelEditingConfirm
  | ExistingSaving
  | ExistingRemoveConfirm
  | ExistingRemoving;
