import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { differenceInCalendarDays, format, isBefore } from 'date-fns';
import { drop, take } from 'ramda';
import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import eventsAPI, { Event } from '../api/events';
import { createCheckin } from '../api/goals';
import reportsAPI from '../api/reports';
import { CreateCheckin } from '../api/types/Checkin';
import { Dashlet } from '../api/types/FoundersDashbaord';
import { VentureId } from '../api/ventures/types/Venture';
import { CardAdvisors, CardCalendar, CardMentorsSvg } from '../assets/images';
import { AlertState, Text } from '../components/common';
import { DashboardCardLink } from '../components/common/DashboardCardLink';
import { Paginator } from '../components/common/Paginator';
import { GoalItem } from '../components/founders/dashboard/GoalItem';
import BaseLayout from '../components/layout/base-layout';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { UserContext } from '../contexts/user-context';
import { getRoutePath, Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../theme/variables';
import {
  datesAreOnSameDay,
  formatDateInterval,
  formatTimeInterval,
} from '../utils/date';
import { formatLocalizedTime } from '../utils/date';
import { encodeQuery } from '../utils/functions';

interface ReportLinksProps {
  upcomingEvent: Event;
}

const useStyles = makeStyles((theme) => ({
  rows: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 28,
    justifyContent: 'stretch',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 265,
  },
  mentorsList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 5,
    minHeight: 180,
    position: 'relative',

    '& > a': {
      zIndex: 1,
    },
  },
  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 0,
    width: '60%',

    '& svg': {
      maxWidth: '100%',
    },
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'start',
  },
  eventSubtitle: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    marginBottom: 5,
  },
  eventDate: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 14,
    color: '#000000',
    marginTop: 16,
    marginBottom: 5,
  },
  eventTime: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 14,
    color: COLORS.COLOR_GRAY_BASE,
  },
  daysLeft: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: 24,
    color: '#000000',
    margin: '10px 0',
  },
  submitted: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    color: '#72bb53',
    fontSize: 14,
    marginBottom: 5,
  },
  submitNotes: {
    border: 'none',
    background: 'none',
    color: COLORS.COLOR_BLUE_DARKENED_10,
    cursor: 'pointer',
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontSize: 14,
    padding: 0,

    '&[disabled]': {
      cursor: 'default',
      color: COLORS.COLOR_GRAY_PLACEHOLDER,
    },
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
    marginTop: 12,
  },
  noSessionsImage: {
    marginLeft: 'auto',
  },
  goalsWrapper: {
    flex: 1,
  },
  noMentors: {
    fontFamily: MONTSERRAT_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 12,
    color: '#000000',
  },
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  emptyText: {
    maxWidth: 500,
  },
}));

const getSessionTimeAndDate = (start?: string, end?: string) => {
  const startDate = start ? new Date(start) : null;
  const endDate = end ? new Date(end) : null;
  if (startDate && endDate) {
    const similarDates = datesAreOnSameDay(startDate, endDate);
    const date = similarDates
      ? format(startDate, 'E, LLL dd, y')
      : formatDateInterval(startDate, endDate);
    const time = formatTimeInterval(startDate, endDate);
    return { date, time };
  } else if (startDate) {
    const date = format(startDate, 'E, LLL dd, y');
    const time = formatLocalizedTime(startDate);
    return { date, time };
  }
  return { date: null, time: null };
};

const getDaysLeft = (start: string) => {
  const dif = differenceInCalendarDays(new Date(start), new Date());
  return dif === 0 ? 'Today' : `In ${dif} ${dif === 1 ? 'Day' : 'Days'}`;
};

const ReportLinks: FC<ReportLinksProps> = ({ upcomingEvent }) => {
  const classes = useStyles();
  const { tokenData } = useContext(UserContext);
  const [sessionKey, setSessionKey] = useState<string>('');

  const encodedEmail = useMemo(
    () => tokenData?.email && encodeQuery(tokenData?.email),
    [tokenData?.email],
  );

  useEffect(() => {
    const loadLinks = async (sessionId: string) => {
      const response = await eventsAPI.getSessionKey(sessionId);
      setSessionKey(response);
    };

    loadLinks(upcomingEvent.id);
  }, [upcomingEvent?.id]);

  return (
    <div className={classes.submitButtonsContainer}>
      {!upcomingEvent.agendaReceived && (
        <button
          type={'button'}
          disabled={!sessionKey}
          onClick={() => {
            window.open(
              `${Pages.REPORT_AGENDA_REQUEST}?key=${sessionKey}&email=${encodedEmail}`,
              '_blank',
            );
          }}
          className={classes.submitNotes}>
          Submit Agenda
        </button>
      )}
      {!upcomingEvent.founderReportReceived && (
        <button
          type={'button'}
          disabled={!sessionKey}
          onClick={() => {
            window.open(
              `${Pages.REPORT_FOUNDER_SESSION_NOTES}?key=${sessionKey}&email=${encodedEmail}`,
              '_blank',
            );
          }}
          className={classes.submitNotes}>
          Submit Founder Notes
        </button>
      )}
    </div>
  );
};

export function FounderDashboardPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [values, setValues] = useState<Dashlet[]>();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<Record<VentureId, number>>({});
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getDashboardFounders = async () => {
      try {
        setLoading(true);
        const res = await reportsAPI.getDashboardFounders();
        setValues(res.dashlets);
        setLoading(false);
      } catch (error: any) {
        if (error?.response?.status === 404 || error?.message === '404') {
          setLoading(false);
          return setValues([]);
        }
        const errorMessage =
          error?.response?.data?.message || 'Internal server error';
        setLoading(false);
        setError(errorMessage);
      }
    };

    getDashboardFounders();
  }, []);

  if (loading) {
    return <BaseLayout user={user} isLoading />;
  }

  return (
    <BaseLayout user={user}>
      {error ? (
        <AlertState type='error'>{error}</AlertState>
      ) : values?.length ? (
        <div className={classes.rows}>
          {values.map(
            ({
              venture,
              mentors,
              upcomingEvents,
              daysSinceLastEvent,
              goals: g,
            }) => {
              const goals = g ?? [];
              const page = pages[venture.id] ?? 0;
              const goalsToRender = take(3, drop(page * 3, goals));
              const upcomingEvent = upcomingEvents.length
                ? upcomingEvents.reduce((acc, curr) =>
                    isBefore(new Date(curr.start), new Date(acc.start))
                      ? curr
                      : acc,
                  )
                : undefined;

              const { date, time } = getSessionTimeAndDate(
                upcomingEvent?.start,
                upcomingEvent?.end,
              );
              return (
                <div className={classes.row} key={venture.id}>
                  <Text
                    color={COLORS.COLOR_ADDITIONAL_GRAY_BLUE_TEXT}
                    variant='upper1'>
                    {venture.ventureName}
                  </Text>
                  <div className={classes.cards} key={venture.id}>
                    <DashboardCard className={classes.card}>
                      <Typography variant='subtitle1'>
                        My {rb('mentors-u')}
                      </Typography>
                      <div className={classes.mentorsList}>
                        <div className={classes.image}>
                          <CardAdvisors />
                        </div>
                        {mentors?.length ? (
                          mentors?.slice(0, 5)?.map((mentor) => (
                            <DashboardCardLink
                              key={mentor.id}
                              to={getRoutePath(Pages.MENTOR_DETAILS, {
                                mentorId: mentor.id,
                              })}>
                              {[mentor.firstName, mentor.lastName].join(' ')}
                            </DashboardCardLink>
                          ))
                        ) : (
                          <div className={classes.noMentors}>
                            No {rb('mentors')} currently assigned
                          </div>
                        )}
                        {mentors?.length > 5 && (
                          <DashboardCardLink
                            key={'other-mentors'}
                            to={`${getRoutePath(
                              Pages.MENTORS,
                            )}?filter=${encodeQuery({
                              venture: venture.id,
                            })}`}>
                            ...
                          </DashboardCardLink>
                        )}
                      </div>
                      <DashboardCardLink
                        to={`${getRoutePath(
                          Pages.MENTORS,
                        )}?filter=${encodeQuery({
                          venture: venture.id,
                        })}`}>
                        Manage {rb('mentors')}
                      </DashboardCardLink>
                    </DashboardCard>
                    <DashboardCard>
                      <Typography variant='subtitle1'>My Goals</Typography>
                      {!goalsToRender?.length ? (
                        <div className={classes.event}>
                          <CardMentorsSvg className={classes.noSessionsImage} />
                          <div className={classes.daysLeft}>No Goals Yet</div>
                        </div>
                      ) : (
                        <div className={classes.goalsWrapper}>
                          {goalsToRender.map((goal, i) => (
                            <GoalItem
                              order={page * 3 + i + 1}
                              goal={goal}
                              key={goal.id}
                              onChange={onGoalChange}
                            />
                          ))}
                        </div>
                      )}
                      {goals.length > 3 && (
                        <Paginator
                          value={page}
                          pages={Math.ceil(goals.length / 3)}
                          onChange={(page) =>
                            setPages((v) => ({ ...v, [venture.id]: page }))
                          }
                        />
                      )}
                      <DashboardCardLink
                        to={`${getRoutePath(Pages.GOALS)}?filter=${encodeQuery({
                          venture: venture.id,
                        })}`}>
                        Manage goals
                      </DashboardCardLink>
                    </DashboardCard>
                    <DashboardCard>
                      <Typography variant='subtitle1'>My Sessions</Typography>
                      {upcomingEvent && (
                        <div className={classes.event}>
                          <div className={classes.daysLeft}>
                            {getDaysLeft(upcomingEvent.start)}
                          </div>
                          {upcomingEvent.agendaReceived && (
                            <div className={classes.submitted}>
                              Agenda Submitted
                            </div>
                          )}
                          {upcomingEvent.founderReportReceived && (
                            <div className={classes.submitted}>
                              Founder Notes Submitted
                            </div>
                          )}

                          <div className={classes.eventDate}>{date}</div>
                          <div className={classes.eventTime}>{time}</div>

                          {(!upcomingEvent.agendaReceived ||
                            !upcomingEvent.founderReportReceived) && (
                            <ReportLinks upcomingEvent={upcomingEvent} />
                          )}
                        </div>
                      )}
                      {!upcomingEvent && daysSinceLastEvent !== -1 && (
                        <div className={classes.event}>
                          <CardCalendar className={classes.noSessionsImage} />
                          <div className={classes.daysLeft}>
                            No Sessions for {daysSinceLastEvent} Day
                            {daysSinceLastEvent !== 1 && 's'}
                          </div>
                        </div>
                      )}
                      {!upcomingEvent && daysSinceLastEvent === -1 && (
                        <div className={classes.event}>
                          <CardCalendar className={classes.noSessionsImage} />
                          <div className={classes.daysLeft}>
                            No Sessions Yet
                          </div>
                        </div>
                      )}
                      <DashboardCardLink to={getRoutePath(Pages.SESSIONS)}>
                        Manage sessions
                      </DashboardCardLink>
                    </DashboardCard>
                  </div>
                </div>
              );
            },
          )}
        </div>
      ) : (
        <div className={classes.empty}>
          <Text className={classes.emptyText} variant='normal'>
            There are currently no active ventures assigned to you. It could be
            simply a work in progress. If this is unexpected, please reach out
            to your Program Administrator to clarify.
          </Text>
        </div>
      )}
    </BaseLayout>
  );

  function onGoalChange(checkin: CreateCheckin) {
    setValues((v) => {
      return v?.map((v) => {
        const index = v.goals?.findIndex((g) => g.id === checkin.goalId);
        if (index !== undefined && index !== -1) {
          const goals = v.goals?.slice();

          if (goals) {
            goals[index] = {
              ...goals[index],
              progress: checkin.progress,
              status: checkin.status,
            };

            return { ...v, goals };
          }

          return v;
        }

        return v;
      });
    });
    createCheckin(checkin);
  }
}

function DashboardCard({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  const { card } = useStyles();
  return <Card className={classNames(card, className)}>{children}</Card>;
}
