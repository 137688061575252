export enum Types {
  Left = 'Left',
  Right = 'Right',
}

// region Left
export type Left<E> = {
  type: Types.Left;
  value: E;
};

export const left = <L>(value: L): Left<L> => ({ type: Types.Left, value });

export const isLeft = <L, R>(v: Either<L, R>): v is Left<L> =>
  v.type === Types.Left;
// endregion

// region Right
export type Right<V> = {
  type: Types.Right;
  value: V;
};

export const right = <L>(value: L): Right<L> => ({ type: Types.Right, value });

export const isRight = <L, R>(v: Either<L, R>): v is Right<R> =>
  v.type === Types.Right;
// endregion

export type Either<L, R> = Left<L> | Right<R>;

const _apply = <L, R, T, T2 = T>(
  left: (v: L) => T,
  right: (v: R) => T2,
  e: Either<L, R>,
): T | T2 => {
  switch (e.type) {
    case Types.Left:
      return left(e.value);
    case Types.Right:
      return right(e.value);
  }
};

export function apply<L, R, T, T2 = T>(
  left: (v: L) => T,
  right: (v: R) => T2,
): (e: Either<L, R>) => T | T2;
export function apply<L, R, T, T2 = T>(
  left: (v: L) => T,
  right: (v: R) => T2,
  e: Either<L, R>,
): T | T2;
export function apply<L, R, T, T2 = T>(
  ...a:
    | [left: (v: L) => T, right: (v: R) => T2]
    | [left: (v: L) => T, right: (v: R) => T2, e: Either<L, R>]
): (T | T2) | ((e: Either<L, R>) => T | T2) {
  return a[2] ? _apply(a[0], a[1], a[2]) : (e) => _apply(a[0], a[1], e);
}

export function applyR<L, R, T, T2 = T>(
  e: Either<L, R>,
  left: (v: L) => T,
  right: (v: R) => T2,
): T | T2 {
  return _apply(left, right, e);
}

export const eitherToValue = <T>(e: Either<unknown, T>): T | undefined =>
  isRight(e) ? e.value : undefined;

export function parseObject<
  T extends object,
  T2 extends T,
  E extends Partial<Record<keyof T, any>>,
>(
  parser: { [k in keyof T]: (v: T[k]) => Either<E[k], T2[k]> },
  obj: T,
): Either<E, T2> {
  const v = Object.entries(parser).map(
    // @ts-ignore
    ([k, fn]) => [k as keyof T, fn(obj[k])] as const,
  );

  const lefts = v.filter(([_, e]) => isLeft(e)) as [
    keyof T,
    Left<E[keyof T]>,
  ][];

  if (lefts.length > 0) {
    return left(
      lefts.reduce((acc, [k, e]) => ({ ...acc, [k]: e.value }), {} as any),
    );
  }

  return right(
    v.reduce((acc, [k, e]) => ({ ...acc, [k]: e.value }), {} as any),
  );
}
