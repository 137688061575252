import { Theme, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement, useCallback, useState } from 'react';
import { CopyIcon } from '../../assets/icons';
import { Avatar } from '../common';
import { Tags } from '../common/Tags';
import { RateBox } from '../common/rate-box';
import { BookButton } from './BookButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: '"avatar name sidebar" \n "avatar details sidebar"',
    gridTemplateColumns: '120px 1fr auto',
    gridTemplateRows: 'auto 1fr',
    gap: '20px 40px',
    alignItems: 'start',
    flex: '1 1 auto',

    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas:
        '"avatar name" \n "details details" \n "sidebar sidebar"',
      gridTemplateColumns: '50px 1fr',
      gap: 15,
    },
  },
  imageWrapper: {
    gridArea: 'avatar',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  details: {
    gridArea: 'details',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',

    [theme.breakpoints.down('xs')]: {
      gap: 15,
    },
  },
  sidebar: {
    gridArea: 'sidebar',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',

    [theme.breakpoints.down('xs')]: {
      gap: 15,
    },
  },
  title: {
    gridArea: 'name',
    display: 'flex',
    alignItems: 'center',
    columnGap: '15px',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      flexDirection: 'column',
      fontSize: 16,
      lineHeight: '1.6em',
      alignSelf: 'center',
    },
  },
  link: {
    fontSize: 14,
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  linkIcon: {
    color: '#9f9d9d',
    fontSize: 16,
  },
  linkText: {
    color: '#0A66C2',
  },
  linkedIn: {
    color: '#0077b5',
  },
  avatar: {
    gridArea: 'avatar',

    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50,
      borderRadius: '50%',
    },
  },
}));

export interface Props {
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  tags: string[];
  excerpt: string;
  description: string;
  linkedIn: string | undefined;
  profileUrl: string;
  onBook: () => void;
  rate: number | null;
  bookLoading: boolean;
}

export function AdvisorProfile({
  avatar,
  firstName,
  lastName,
  excerpt,
  description,
  tags,
  onBook,
  profileUrl,
  linkedIn,
  rate,
  bookLoading,
}: Props): ReactElement {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(profileUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }, [profileUrl]);

  return (
    <Card className={classes.root}>
      <div className={classes.imageWrapper}>
        <Avatar
          name={`${firstName[0]}${lastName[0]}`}
          src={avatar}
          variant={'rounded'}
          size={'120'}
          className={classes.avatar}
        />
      </div>
      <Typography variant={'h2'} className={classes.title}>
        {`${firstName} ${lastName}`}
      </Typography>
      <div className={classes.details}>
        <div
          className={'inner-html'}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <div
          className={'inner-html'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Tags tags={tags} />
      </div>
      <div className={classes.sidebar}>
        <RateBox rate={rate} />
        <BookButton
          disabled={bookLoading}
          onClick={onBook}
          loading={bookLoading}
        />
        <Typography className={classes.link} onClick={handleCopy}>
          {!copied ? (
            <CopyIcon className={classes.linkIcon} />
          ) : (
            <CheckCircleIcon className={classes.linkIcon} />
          )}
          <span className={classes.linkText}>Copy link to the profile</span>
        </Typography>

        {linkedIn ? (
          <a
            className={classes.link}
            href={linkedIn}
            target={'_blank'}
            rel='noreferrer'>
            <LinkedInIcon className={classes.linkedIn} />
            <span className={classes.linkText}>View LinkedIn profile</span>
          </a>
        ) : null}
      </div>
    </Card>
  );
}
