import { Tabs, Tab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface TabsProps {
  tooltip?: {
    title: string;
  };
  props: {
    label: string;
    value?: any;
    component?: React.ReactNode;
    to?: any;
    disabled?: boolean;
    style?: React.CSSProperties;
    ['data-testid']?: string;
  };
}

interface PageTabsProps {
  sessionId?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '0 40px',
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },

  tooltipWrapper: {
    marginLeft: '30px',
  },
});

function PageTabs({ sessionId, children }: PropsWithChildren<PageTabsProps>) {
  const location = useLocation();
  const classes = useStyles();

  const tabs: TabsProps[] = [
    {
      props: {
        label: 'Details',
        'data-testid': 'session-nav-details',
        component: Link,
        ...(sessionId
          ? {
              to: getRoutePath(Pages.SESSIONS_DETAILS, { sessionId }),
              value: getRoutePath(Pages.SESSIONS_DETAILS, { sessionId }),
            }
          : {
              to: Pages.NEW_SESSIONS,
              value: Pages.NEW_SESSIONS,
            }),
      },
    },
    {
      ...(!sessionId
        ? {
            tooltip: {
              title: `Please complete creation of session\nbefore switching to Notes`,
            },
          }
        : {}),
      props: {
        label: 'Notes',
        'data-testid': 'session-nav-notes',
        disabled: !sessionId,
        ...(sessionId
          ? {
              component: Link,
              value: getRoutePath(Pages.SESSIONS_NOTES, { sessionId }),
              to: getRoutePath(Pages.SESSIONS_NOTES, { sessionId }),
            }
          : {}),
      },
    },
    {
      ...(!sessionId
        ? {
            tooltip: {
              title: `Please complete creation of session\nbefore switching to Links`,
            },
          }
        : {}),
      props: {
        label: 'Links',
        'data-testid': 'session-nav-links',
        disabled: !sessionId,
        ...(sessionId
          ? {
              component: Link,
              value: getRoutePath(Pages.SESSIONS_LINKS, { sessionId }),
              to: getRoutePath(Pages.SESSIONS_LINKS, { sessionId }),
            }
          : {}),
      },
    },
    {
      ...(!sessionId
        ? {
            tooltip: {
              title: `Please complete creation of session\nbefore switching to Log`,
            },
          }
        : {}),
      props: {
        label: 'Log',
        'data-testid': 'session-nav-log',
        disabled: !sessionId,
        ...(sessionId
          ? {
              component: Link,
              value: getRoutePath(Pages.SESSION_LOG, { sessionId }),
              to: getRoutePath(Pages.SESSION_LOG, { sessionId }),
            }
          : {}),
      },
    },
  ];

  return (
    <div className={classes.container}>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'>
        {tabs.map((tab) =>
          tab.tooltip ? (
            <Tooltip title={tab.tooltip.title} key={tab.props.label}>
              <span className={classes.tooltipWrapper}>
                <Tab {...tab.props} />
              </span>
            </Tooltip>
          ) : (
            <Tab {...tab.props} key={tab.props.label} />
          ),
        )}
      </Tabs>
      {children}
    </div>
  );
}

export default PageTabs;
