import { makeStyles, Tooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { ReactElement } from 'react';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles(() => ({
  icon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
}));

export interface Props {
  message: string;
  placement?: TooltipProps['placement'];
  leaveTouchDelay?: number;
  enterTouchDelay?: number;
}

export function InfoTooltip({
  message,
  placement,
  enterTouchDelay,
  leaveTouchDelay,
}: Props): ReactElement {
  const classes = useStyles();

  return (
    <Tooltip
      title={message}
      placement={placement}
      enterTouchDelay={enterTouchDelay}
      leaveTouchDelay={leaveTouchDelay}>
      <InfoIcon className={classes.icon} />
    </Tooltip>
  );
}
