import {
  CircularProgress,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { TenantDetails } from '../../api/auth';
import { COLORS } from '../../theme/variables';
import { isPassword } from '../../utils/String/Password';
import {
  checkCapitalCharacters,
  checkNumber,
  checkSpecialSymbols,
  isMobile,
} from '../../utils/functions';
import { BeforeUnload, Button, FormGroup, Text } from '../common';
import { TextFieldWrapper } from './wrappers';

interface TenantAccountFormProps {
  loading?: boolean;
  onSubmit: (formValues: any) => any;
  initialValue: FormValues;
}

interface ValidPasswordValue {
  icon: boolean;
  label: string;
}
interface ValidPassword {
  isMinimalLength: ValidPasswordValue;
  isSpecialSymbol: ValidPasswordValue;
  isNumbers: ValidPasswordValue;
  isCapitalCharacters: ValidPasswordValue;
}

export interface FormValues {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface ParsedFormValues {
  values: TenantDetails;
  logoFile: File | null;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '65px 0 0 80px',
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  descriptionField: {
    minHeight: 64,
  },
  popper: {
    minWidth: 250,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:
      '0px 0px 2px rgb(9 29 61 / 25%), 0px 8px 16px rgb(34 91 187 / 8%), 0px 8px 24px rgb(51 126 255 / 8%)',
    borderRadius: 5,
    padding: '5px 10px',
    marginTop: 7,
  },
  popperItems: {
    width: '100%',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
    marginRight: 7,
    fontSize: 20,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
    marginRight: 7,
    fontSize: 20,
  },
}));

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (values.newPassword && !values.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (values.oldPassword && !values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (values.newPassword && !isPassword(values.newPassword)) {
    errors.newPassword = 'Not valid';
  }
  return errors;
};

function TenantAccountForm({
  loading = false,
  onSubmit,
  initialValue,
}: TenantAccountFormProps) {
  const classes = useStyles();
  const [isShownOldPassword, setIsShownOldPassword] = useState(false);
  const [isShownNewPassword, setIsShownNewPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueNewPassword, setValueNewPassword] = useState<string>('');
  const validNewPassword: ValidPassword = useMemo(() => {
    const valid = {
      isMinimalLength: { icon: false, label: 'Minimal length 8 symbols' },
      isSpecialSymbol: { icon: false, label: 'Special Symbols' },
      isNumbers: { icon: false, label: 'Numbers' },
      isCapitalCharacters: { icon: false, label: 'Capital characters' },
    };

    if (checkNumber(valueNewPassword)) {
      valid.isNumbers.icon = true;
    }
    if (checkSpecialSymbols(valueNewPassword)) {
      valid.isSpecialSymbol.icon = true;
    }
    if (valueNewPassword.length > 8) {
      valid.isMinimalLength.icon = true;
    }
    if (checkCapitalCharacters(valueNewPassword)) {
      valid.isCapitalCharacters.icon = true;
    }
    return valid;
  }, [valueNewPassword]);

  const handleSubmit = (formValues: FormValues) => {
    return onSubmit(formValues);
  };

  const handleFocus = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangeNewPassword = (e: any) => {
    const value = e.target.value;
    setValueNewPassword(value);
  };

  const handleBlur = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValue}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <div>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={
                    loading ||
                    !formProps.valid ||
                    !formProps.values.newPassword ||
                    !formProps.values.oldPassword
                  }>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.mainFormBlock}>
              <form noValidate>
                <div className={classes.sectionBlock}>
                  <FormGroup>
                    <Field<string>
                      name='email'
                      label='Email*'
                      testid='settings-form-email'
                      component={TextFieldWrapper}
                      InputProps={{ readOnly: true }}
                    />
                  </FormGroup>
                  <FormGroup mobile={isMobile()}>
                    <Field<string>
                      name='oldPassword'
                      testid='settings-form-old-password'
                      label='Current password*'
                      autoComplete='new-password'
                      component={TextFieldWrapper}
                      type={isShownOldPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              edge='end'
                              onClick={() =>
                                setIsShownOldPassword(!isShownOldPassword)
                              }>
                              {isShownOldPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div>
                      <Field<string>
                        name='newPassword'
                        component={TextFieldWrapper}
                        label='New password'
                        testid='settings-form-new-password'
                        autoComplete='new-password'
                        type={isShownNewPassword ? 'text' : 'password'}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChangeNewPassword}
                        aria-describedby={id}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                edge='end'
                                onClick={() =>
                                  setIsShownNewPassword(!isShownNewPassword)
                                }>
                                {isShownNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Popper
                        id={id}
                        anchorEl={anchorEl}
                        open={open}
                        placement='bottom-start'>
                        <div className={classes.popper}>
                          {Object.values(validNewPassword).map(
                            (value: ValidPasswordValue, idx) => {
                              return (
                                <div key={idx} className={classes.popperItems}>
                                  {value.icon ? (
                                    <CheckCircleIcon
                                      className={classes.checkIcon}
                                    />
                                  ) : (
                                    <CancelIcon className={classes.errorIcon} />
                                  )}
                                  <Text variant='normal'>{value.label} </Text>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </Popper>
                    </div>
                  </FormGroup>
                </div>
                <div className={classes.actionsBlock}>
                  <Button
                    data-testid='settings-form-submit'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      formProps.form.change('oldPassword', '');
                      formProps.form.change('newPassword', '');
                      formProps.form.resetFieldState('newPassword');
                    }}
                    disabled={
                      loading ||
                      !formProps.valid ||
                      !formProps.values.newPassword ||
                      !formProps.values.oldPassword
                    }
                    startIcon={<CheckIcon />}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default TenantAccountForm;
