import React from 'react';
import { TestId } from '../../../Testing/TestId';
import { StatusBadge } from '../../index';

export interface BadgeProps {
  label: string;
  variant: 'default' | 'success' | 'warning' | 'error' | 'primary';
  testId: string;
}

export function Badge({ label, variant, testId }: BadgeProps) {
  return (
    <TestId testId={testId}>
      <StatusBadge status={label} variant={variant} />
    </TestId>
  );
}
