import { InputAdornment, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import React from 'react';
import { useDebouncedOnChange } from '../../hooks/useDebouncedOnChange';
import { COLORS } from '../../theme/variables';
import { TestId } from '../Testing/TestId';
import TextField from './text-field';

const useStyles = makeStyles(() => ({
  root: {
    padding: '5px 5px 5px 23px',
    borderRadius: '32px',
  },
  input: {
    '& fieldset': {
      border: 'none',
      outline: 'none',
    },

    '& .MuiInputBase-input': {
      fontSize: '16px',
    },

    '& .MuiInputBase-root': {
      paddingRight: '0px',
    },

    '& .MuiInputAdornment-positionStart': {
      margin: 0,
      height: '50px',
      width: '78px',
      maxHeight: 'unset',
      backgroundColor: COLORS.COLOR_BLUE_BASE,
      borderRadius: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
}));

export interface Props {
  value: string;
  onChange: (v: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  className?: string;
  small?: boolean;
}

export function RoundedSearch({
  value,
  onChange,
  className,
  placeholder,
  small = true,
  onSubmit,
}: Props) {
  const classes = useStyles();
  const [_v, handleChange, commit] = useDebouncedOnChange(value, onChange, 500);

  return (
    <Card className={classNames(className, classes.root)}>
      <TextField
        className={classes.input}
        value={_v}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit?.();
          }
        }}
        onChange={(v) => handleChange(v.target.value)}
        placeholder={placeholder}
        onBlur={commit}
        InputProps={{
          endAdornment: (
            <TestId testId={'search-submit'}>
              <InputAdornment position='start' onClick={onSubmit}>
                <SearchIcon />
              </InputAdornment>
            </TestId>
          ),
        }}
        small={small}
      />
    </Card>
  );
}
