import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { COLORS } from '../../../theme/variables';

const useStyles = makeStyles({
  root: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    transition: 'box-shadow 0.2s',

    '&:hover:not(:disabled)': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },

    '&:disabled': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
      pointerEvents: 'auto',
    },
  },
  disabled: {
    background: COLORS.COLOR_GRAY_BASE,
  },
  active: {},
});

export interface Props {
  onClick?: MouseEventHandler;
  status?: 'active' | 'disabled';
}

export function AddNewIcon({ onClick, status }: Props) {
  const classes = useStyles();

  return (
    <IconButton
      disabled={status === 'disabled'}
      className={classNames(classes.root, classes[status ?? 'active'])}
      onClick={onClick}>
      <AddIcon />
    </IconButton>
  );
}
