import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';
import { Email, isEmail } from '../../utils/String/Email';
import { TestId } from '../Testing/TestId';
import TextField from './text-field';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-formControl': {
      gap: 5,
    },
    '&[aria-invalid=true] .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 20px)',
    },
    '& .MuiAutocomplete-endAdornment button': {
      padding: 8,
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
  emailChip: {
    '&[aria-invalid=true]': {
      background: 'rgba(255, 0, 0, 0.4)',
    },
  },
}));

export interface EmailListProps {
  value?: (Email | string)[];
  options?: (Email | string)[];
  onChange: (value: (Email | string)[] | undefined) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  size?: 'small' | 'medium';
  error?: boolean;
  label?: string;
}

export function EmailList({
  value,
  disabled = false,
  onChange,
  readOnly = false,
  className,
  size,
  options,
  label,
  error,
}: EmailListProps) {
  const classes = useStyles();

  return (
    <Autocomplete<Email | string, true, false, true>
      multiple
      freeSolo
      className={classNames(classes.root, className)}
      aria-invalid={error ? true : undefined}
      size={size}
      disabled={disabled}
      value={value}
      onChange={(e, v) => onChange(v)}
      options={options ?? []}
      open={false}
      onClose={(e) => {
        e.stopPropagation();
        return false;
      }}
      closeIcon={
        <Tooltip title='Please enter a comma-separated list of email addresses to continue'>
          <HelpIcon
            onClick={(e) => {
              e.stopPropagation();
              return false;
            }}
          />
        </Tooltip>
      }
      renderTags={(value) => (
        <>
          {value.map((v, i) => {
            const isInvalid = !isEmail(v);
            return (
              <TestId testId={'email-chip'} key={v}>
                <Chip
                  label={v}
                  onDelete={() => onChange(value.filter((v, idx) => idx !== i))}
                  className={classes.emailChip}
                  aria-invalid={isInvalid ? true : undefined}
                />
              </TestId>
            );
          })}
        </>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...(params?.inputProps || {}),
              readOnly,
              onKeyDown: (e) => {
                if (e.key === ' ') {
                  onChange(
                    [...(value ?? []), e.currentTarget.value.trim()].filter(
                      Boolean,
                    ),
                  );
                }
              },
              onBlur: (e) => {
                if (e.currentTarget.value) {
                  onChange(
                    [...(value ?? []), e.currentTarget.value.trim()].filter(
                      Boolean,
                    ),
                  );
                }
              },
            }}
          />
        );
      }}
    />
  );
}
