import {
  makeStyles,
  Slider as MaterialSlider,
  SliderProps as MaterialSliderProps,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import { COLORS } from '../../theme/variables';

export interface SliderProps extends Omit<MaterialSliderProps, 'onChange'> {
  onChange?: (event: ChangeEvent<{}>, value: number | number[]) => void;
}

export function Slider(props: SliderProps) {
  const c = useStyles();
  return (
    <MaterialSlider
      className={c.slider}
      min={0}
      max={100}
      marks={[
        { value: 0, label: '0%' },
        { value: 100, label: '100%' },
      ]}
      valueLabelDisplay='on'
      {...props}
    />
  );
}

const useStyles = makeStyles({
  slider: {
    padding: '20px 0',
    marginBottom: 0,
    '& .MuiSlider-markLabel': {
      fontSize: '8px',
      fontWeight: 500,
      transform: 'none',

      "&[data-index='1']": {
        right: '0',
        left: 'unset !important',
      },
    },

    '& .MuiSlider-valueLabel': {
      transform: 'none',
      top: '-11px',

      '&  *': {
        color: COLORS.COLOR_GRAY_DARKENED,
        backgroundColor: 'transparent',
        transform: 'none',
        fontSize: '8px',
        fontWeight: 700,
      },
    },
  },
});

export default Slider;
