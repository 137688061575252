import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { ReactElement } from 'react';
import ReactSelect, { components } from 'react-select';
import { COLORS } from '../../theme/variables';
import { TestId } from '../Testing/TestId';

const useStyles = makeStyles({
  icon: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
  },
  focused: {
    color: COLORS.COLOR_BLUE_BASE,
  },
});

export interface OptionProps<T> {
  label: string | ReactElement;
  value: T;
}

export interface SelectProps<T> {
  className?: string;
  value?: T;
  options: OptionProps<T>[];
  onSelect: (selectedItem: OptionProps<T> | null) => any;
  isActive?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  maxMenuHeight?: number;
}

export function Select<T>({
  className,
  value,
  options,
  onSelect,
  isActive = false,
  placeholder,
  isDisabled,
  maxMenuHeight,
}: SelectProps<T>) {
  const classes = useStyles();

  return (
    <ReactSelect
      className={className}
      placeholder={placeholder}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          minHeight: 40,
          borderColor:
            isFocused || isActive
              ? COLORS.COLOR_BLUE_BASE
              : COLORS.COLOR_GRAY_LIGHTENED_20,
          boxShadow: isFocused || isActive ? 'none' : styles.boxShadow,

          ':hover': {
            borderColor:
              isFocused || isActive
                ? COLORS.COLOR_BLUE_BASE
                : COLORS.COLOR_GRAY_DARKENED,
          },
        }),
        option: (styles, { isSelected }) => ({
          ...styles,
          background: isSelected ? COLORS.COLOR_BLUE_LIGHTENED_45 : '#fff',
          color: COLORS.COLOR_TEXT_BASE,
          fontSize: 13,
          lineHeight: '20px',
          padding: '12px 16px',
          cursor: 'pointer',

          ':hover': {
            background: COLORS.COLOR_BLUE_LIGHTENED_45,
          },
        }),
        container: (styles) => ({
          ...styles,
          outline: 'none',
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: '2px 16px',
        }),
        singleValue: (styles) => ({
          ...styles,
          fontSize: 14,
          lineHeight: '24px',
          color: COLORS.COLOR_TEXT_BASE,
          fontWeight: 400,
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          paddingRight: 12,
        }),
        menu: (styles) => ({
          ...styles,
          padding: '8px 0',
          border: 0,
          boxShadow:
            '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)',
        }),
      }}
      components={{
        Control: (props) => {
          const Control = components.Control;
          return (
            <TestId testId={'select-control'}>
              <Control {...props} />
            </TestId>
          );
        },
        Option: (props) => {
          const Option = components.Option;
          return (
            <TestId testId={'select-option'}>
              <Option {...props} />
            </TestId>
          );
        },
        IndicatorSeparator: undefined,
        DropdownIndicator: ({ isFocused, selectProps }) => {
          const { menuIsOpen } = selectProps;
          return (
            <div data-testid='option'>
              {menuIsOpen ? (
                <ArrowDropUpIcon
                  className={cn(classes.icon, {
                    [classes.focused]: isFocused || isActive,
                  })}
                />
              ) : (
                <ArrowDropDownIcon
                  className={cn(classes.icon, {
                    [classes.focused]: isFocused || isActive,
                  })}
                />
              )}
            </div>
          );
        },
      }}
      value={options.find((option) => option.value === value)}
      options={options}
      isSearchable={false}
      onChange={(selectedItem) => {
        onSelect(selectedItem);
      }}
      isDisabled={isDisabled}
      maxMenuHeight={maxMenuHeight}
    />
  );
}

export default Select;
