import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import authAPI, { Passwords } from '../../api/auth';
import TenantAccountForm, {
  FormValues,
} from '../../components/forms/tenant-account';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
});

const getInitialValues = (currentEmail?: string): FormValues => {
  return {
    email: currentEmail || '',
    oldPassword: '',
    newPassword: '',
  };
};

const getValidatePassword = (value: FormValues): Passwords => {
  return {
    oldPassword: value.oldPassword,
    newPassword: value.newPassword,
  };
};

function TenantAccountPage() {
  const classes = useStyles();
  const { currentEmail } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = useCallback(
    async (formValues: any) => {
      try {
        setIsLoading(true);
        const validateData = getValidatePassword(formValues);
        await authAPI.updatePassword(validateData);

        enqueueSnackbar('Password changed successfully', {
          variant: 'success',
        });
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        const messageError = `Unfortunately, an error occurred when changing the password.\n Details: ${
          error.response?.data?.message || 'Internal server error'
        }`;
        enqueueSnackbar(messageError, {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        });
        throw error;
      }
    },
    [enqueueSnackbar],
  );

  const initialValue = getInitialValues(currentEmail);

  if (!currentEmail) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <TenantAccountForm
        loading={isLoading}
        onSubmit={handleSubmitForm}
        initialValue={initialValue}
      />
    </div>
  );
}

export default TenantAccountPage;
