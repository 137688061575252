import { BeforeUnload as Confirmation } from '../index';

export interface Props {
  when: boolean;
}

export function BeforeUnload({ when }: Props) {
  return (
    <Confirmation
      when={when}
      title='Leave the page'
      body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
    />
  );
}
