import { Advisor } from '../../../../api/Advisors/types/Advisor';

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Advisor;
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

export type Actions = LoadSuccess | LoadError;
