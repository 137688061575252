import { Advisor } from '../../../../api/Advisors/types/Advisor';
import { AvailabilityId } from '../../../../api/Advisors/types/Availability';
import { Period } from '../../../../api/types/Period';
import { Either } from '../../../../utils/Either';
import { CustomPeriod as CP } from './CustomPeriod';
import { ItemId, NewItemId } from './ItemId';
import { ValidDate } from './ValidDate';

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: Advisor;
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region LoadError
export interface LoadError {
  type: 'LoadError';
  payload: string;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region AddNew
export interface AddNew {
  type: 'AddNew';
}

export const addNew = (): AddNew => ({
  type: 'AddNew',
});
// endregion

// region SaveCustomPeriod
export interface SaveCustomPeriod {
  type: 'SaveCustomPeriod';
}

export const saveCustomPeriod = (): SaveCustomPeriod => ({
  type: 'SaveCustomPeriod',
});
// endregion

// region CancelCustomPeriod
export interface CancelCustomPeriod {
  type: 'CancelCustomPeriod';
}

export const cancelCustomPeriod = (): CancelCustomPeriod => ({
  type: 'CancelCustomPeriod',
});
// endregion

// region Remove
export interface Remove {
  type: 'Remove';
  payload: AvailabilityId | NewItemId;
}

export const remove = (payload: Remove['payload']): Remove => ({
  type: 'Remove',
  payload,
});
// endregion

// region RemoveAccept
export interface RemoveAccept {
  type: 'RemoveAccept';
}

export const removeAccept = (): RemoveAccept => ({
  type: 'RemoveAccept',
});
// endregion

// region RemoveReject
export interface RemoveReject {
  type: 'RemoveReject';
}

export const removeReject = (): RemoveReject => ({
  type: 'RemoveReject',
});
// endregion

// region SetStart
export interface SetStart {
  type: 'SetStart';
  payload: {
    id: ItemId;
    value: Either<ValidDate | undefined, ValidDate>;
  };
}

export const setStart = (payload: SetStart['payload']): SetStart => ({
  type: 'SetStart',
  payload,
});
// endregion

// region SetEnd
export interface SetEnd {
  type: 'SetEnd';
  payload: {
    id: ItemId;
    value: Either<ValidDate | undefined, ValidDate>;
  };
}

export const setEnd = (payload: SetEnd['payload']): SetEnd => ({
  type: 'SetEnd',
  payload,
});
// endregion

// region SetPeriod
export interface SetPeriod {
  type: 'SetPeriod';
  payload: {
    id: ItemId;
    value: Period['periodType'] | 'CustomPeriod';
  };
}

export const setPeriod = (payload: SetPeriod['payload']): SetPeriod => ({
  type: 'SetPeriod',
  payload,
});
// endregion

// region SetCustomPeriod
export interface SetCustomPeriod {
  type: 'SetCustomPeriod';
  payload: CP;
}

export const setCustomPeriod = (
  payload: SetCustomPeriod['payload'],
): SetCustomPeriod => ({
  type: 'SetCustomPeriod',
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region SubmitError
export interface SubmitError {
  type: 'SubmitError';
  payload: string;
}

export const submitError = (payload: SubmitError['payload']): SubmitError => ({
  type: 'SubmitError',
  payload,
});
// endregion

// region SubmitSuccess
export interface SubmitSuccess {
  type: 'SubmitSuccess';
  payload: Advisor;
}

export const submitSuccess = (
  payload: SubmitSuccess['payload'],
): SubmitSuccess => ({
  type: 'SubmitSuccess',
  payload,
});
// endregion

export type Actions =
  | LoadSuccess
  | LoadError
  | AddNew
  | SaveCustomPeriod
  | CancelCustomPeriod
  | Remove
  | RemoveAccept
  | RemoveReject
  | SetStart
  | SetEnd
  | SetPeriod
  | SetCustomPeriod
  | Submit
  | SubmitError
  | SubmitSuccess;
