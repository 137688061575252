import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps, useForm } from 'react-final-form';
import { Mentor, MentorDetails, MentorStatus } from '../../api/mentors';
import { Specialization } from '../../api/specializations';
import { Role } from '../../api/user/Role';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import {
  apply,
  Either,
  isLeft,
  left,
  parseObject,
  right,
} from '../../utils/Either';
import { Email, stringToEitherEmail } from '../../utils/String/Email';
import { Phone, stringToEitherPhone } from '../../utils/String/Phone';
import { fromString, Sentence } from '../../utils/String/Sentence';
import { stringToEitherZip, Zip } from '../../utils/String/Zip';
import { formatDateToRFC } from '../../utils/date';
import {
  PastStringDate,
  strToEitherPastStrDate,
} from '../../utils/date/PastStringDate';
import { lengthField, STATUSES_MENTORS } from '../../utils/form';
import {
  checkSizeTablet,
  getAbbreviationByName,
  isMobile,
} from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  FieldNames,
  FormGroup,
  StickyContent,
  UserLogo,
} from '../common';
import { hasDuplicateEmail } from '../common/field-email';
import { checkNameForDublicates } from '../common/field-names';
import {
  FormDateInput,
  FormEmail,
  FormSelect,
  FormSpecializationInput,
  TextFieldWrapper,
} from './wrappers';

interface MentorDetailsFormProps {
  mentorId?: Mentor['id'];
  mentorDetails?: MentorDetails;
  mentorSpecializations?: Specialization['id'][];
  loading?: boolean;
  onSubmit: (parsedFormValues: ParsedFormValues) => any;
  isExternal?: boolean;
}

interface FormValues {
  address: null | string | Sentence<1024>;
  city: Sentence<250> | string | null;
  country: Sentence<250> | string | null;
  dateOfBirth: PastStringDate | string | null;
  dateOfEnrollment: PastStringDate | string;
  email: Email | string | null;
  firstName: Sentence<250> | string;
  lastName: Sentence<250> | string;
  linkedInProfile: Sentence<250> | string | null;
  logo: Sentence<250> | string | null;
  phone: Phone | string | null;
  specializations: Specialization['id'][];
  state: Sentence<250> | string | null;
  status: MentorStatus;
  verifiedEmail: boolean;
  verifiedName: boolean;
  zip: Zip | string | null;
}

interface ValidFormValues extends FormValues {
  address: Sentence<1024> | null;
  city: Sentence<250> | null;
  country: Sentence<250> | null;
  dateOfBirth: PastStringDate | null;
  dateOfEnrollment: PastStringDate;
  email: Email;
  firstName: Sentence<250>;
  lastName: Sentence<250>;
  linkedInProfile: Sentence<250> | null;
  logo: Sentence<250> | null;
  phone: Phone | null;
  specializations: Specialization['id'][];
  state: Sentence<250> | null;
  status: MentorStatus;
  verifiedEmail: boolean;
  verifiedName: boolean;
  zip: Zip | null;
}

export interface ParsedFormValues {
  values: MentorDetails;
  specializations: Specialization['id'][];
  logoFile: File | null;
}

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '0 0 32px 0',

    [theme.breakpoints.up('xs')]: {
      margin: '65px 0 0 30px',
    },

    [theme.breakpoints.up(1000)]: {
      marginLeft: 80,
    },
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  statusField: {
    marginTop: 0,

    [theme.breakpoints.up('xs')]: {
      marginTop: 15,
    },
  },
}));

function getValidForm(
  form: Partial<FormValues>,
): Either<Partial<Record<keyof FormValues, string>>, ValidFormValues> {
  return parseObject<
    Partial<FormValues>,
    ValidFormValues,
    Partial<Record<keyof FormValues, string>>
  >(
    {
      address: (v) => (v ? fromString(1024, v) : right(null)),
      city: (v) => (v ? fromString(250, v) : right(null)),
      country: (v) => (v ? fromString(250, v) : right(null)),
      dateOfBirth: (v) => (v ? strToEitherPastStrDate(v) : right(null)),
      dateOfEnrollment: (v) =>
        v ? strToEitherPastStrDate(v) : left('Invalid date'),
      email: (v) => (v ? stringToEitherEmail(v) : left('Required')),
      firstName: (v) => fromString(250, v ?? ''),
      lastName: (v) => fromString(250, v ?? ''),
      linkedInProfile: (v) => (v ? fromString(250, v) : right(null)),
      logo: (v) => (v ? fromString(250, v) : right(null)),
      phone: (v) => (v ? stringToEitherPhone(v) : right(null)),
      specializations: (v) => right(v ?? []),
      state: (v) => (v ? fromString(250, v) : right(null)),
      status: (v) => right(v ?? MentorStatus.ACTIVE),
      verifiedEmail: (v) => right(v ?? false),
      verifiedName: (v) => right(v ?? false),
      zip: (v) => (v ? stringToEitherZip(v) : right(null)),
    },
    form,
  );
}

const validateForm = (
  values: FormValues,
): { [k in keyof FormValues]?: string } => {
  const validForm = getValidForm(values);

  return isLeft(validForm) ? validForm.value : {};
};

function getInitialValues(
  mentorDetails?: MentorDetails,
  specializations: Specialization['id'][] = [],
): FormValues {
  const country = mentorDetails?.country || '';

  return {
    address: mentorDetails?.address || '',
    firstName: mentorDetails?.firstName || '',
    lastName: mentorDetails?.lastName || '',
    verifiedName: !!(mentorDetails?.firstName && mentorDetails.lastName),
    dateOfBirth: mentorDetails?.dateOfBirth || '',
    dateOfEnrollment:
      mentorDetails?.dateOfEnrollment || formatDateToRFC(new Date()),
    specializations,
    email: mentorDetails?.email || '',
    verifiedEmail: !!mentorDetails?.email,
    phone: mentorDetails?.phone || '',
    linkedInProfile: mentorDetails?.linkedInProfile || '',
    country,
    state: mentorDetails?.state || '',
    city: mentorDetails?.city || '',
    logo: mentorDetails?.logo || '',
    zip: mentorDetails?.zip || '',
    status: mentorDetails?.status || STATUSES_MENTORS[0].value,
  };
}

function getParsedValues(
  formValues: FormValues,
  logoFile: File | null,
): Either<undefined, ParsedFormValues> {
  return apply(
    () => left(undefined),
    (formValues) =>
      right({
        values: {
          address: formValues.address,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          dateOfBirth: formValues.dateOfBirth,
          dateOfEnrollment: formValues.dateOfEnrollment,
          email: formValues.email,
          phone: formValues.phone,
          linkedInProfile: formValues.linkedInProfile,
          country: formValues.country,
          state: formValues.state,
          city: formValues.city,
          logo: formValues.logo,
          zip: formValues.zip,
          status: formValues.status,
        },
        specializations: formValues.specializations,
        logoFile,
      }),
    getValidForm(formValues),
  );
}

function parseEmptyString(value: string) {
  return value === '' ? '' : value;
}

function MentorDetailsForm({
  mentorDetails,
  mentorSpecializations,
  loading = false,
  onSubmit,
  isExternal = false,
}: MentorDetailsFormProps) {
  const { hasAccessToAction: checkAccess, hasRole } = useContext(UserContext);
  const hasAccessToAction = useMemo(
    () => (isExternal ? () => true : checkAccess),
    [checkAccess, isExternal],
  );
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [isAsyncValidating, setIsAsyncValidating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (formValues: FormValues) => {
    if (isAsyncValidating) {
      return;
    }

    const parsedFormValues = getParsedValues(formValues, logoFile);

    if (isLeft(parsedFormValues)) {
      return;
    }

    const values = parsedFormValues.value.values;

    try {
      setIsAsyncValidating(true);

      const isEmailDublicate =
        values.email !== mentorDetails?.email &&
        (await hasDuplicateEmail('mentor', values.email));

      if (isEmailDublicate) {
        throw new Error('Email is dublicate');
      }

      const nameCheckStatus =
        values.firstName === mentorDetails?.firstName &&
        values.lastName === mentorDetails?.lastName
          ? 'success'
          : await checkNameForDublicates(
              'mentor',
              values.firstName,
              values.lastName,
            );

      if (nameCheckStatus !== 'success') {
        throw new Error('Name is dublicate');
      }

      setIsAsyncValidating(false);
    } catch (error) {
      setIsAsyncValidating(false);
      return;
    }

    return onSubmit(parsedFormValues.value);
  };

  const initialValues = useMemo(
    () => getInitialValues(mentorDetails, mentorSpecializations),
    [mentorDetails, mentorSpecializations],
  );

  const validateLogoFile = (logo: File) => {
    const fileName = logo.name;
    if (fileName.length > lengthField.logoFileName) {
      enqueueSnackbar('The maximum length of the file name is 75 characters', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formProps) => {
          setSubmitValidationFailed(
            formProps.submitFailed &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting,
          );
          return (
            <FormWrapper formProps={formProps}>
              <BeforeUnload
                when={formProps.dirty && !loading}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={loading}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      onConfirm();
                    }}
                    disabled={loading || !formProps.valid}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <div
                className={classes.formBlocks}
                data-testid='mentor-details-form'>
                <div className={classes.mainFormBlock}>
                  <form noValidate>
                    <div className={classes.sectionBlock}>
                      {isMobile() && (
                        <div className={classes.logoFormBlock}>
                          <Field<string>
                            name='logo'
                            parse={(value) => (!value ? '' : value)}
                            component={({ input }) => (
                              <UserLogo
                                logo={input.value}
                                name={getAbbreviationByName(
                                  formProps.values.firstName,
                                  formProps.values.lastName,
                                )}
                                onUpload={(photoFile) => {
                                  if (validateLogoFile(photoFile)) {
                                    setLogoFile(photoFile);
                                    const blobFile =
                                      URL.createObjectURL(photoFile);
                                    input.onChange(blobFile);
                                  }
                                }}
                                onRemove={() => {
                                  setLogoFile(null);
                                  input.onChange('');
                                }}
                                readOnly={
                                  !hasAccessToAction('mentor.details.update')
                                }
                              />
                            )}
                          />
                        </div>
                      )}

                      {hasAccessToAction('mentor.details.field.firstName') &&
                        hasAccessToAction('mentor.details.field.lastName') && (
                          <FieldNames
                            entityType='mentor'
                            isReadOnly={
                              !hasAccessToAction('mentor.details.update')
                            }
                          />
                        )}
                      {hasAccessToAction('mentor.details.field.dateOfBirth') &&
                        hasAccessToAction(
                          'mentor.details.field.dateOfEnrollment',
                        ) && (
                          <FormGroup mobile={checkSizeTablet(800)}>
                            <Field<string>
                              testid='mentor-details-date-birth'
                              name='dateOfBirth'
                              component={FormDateInput}
                              label='Date of birth'
                              editable
                              disableFuture
                              parse={(value) => {
                                try {
                                  if (value) {
                                    return formatDateToRFC(value);
                                  }
                                  return '';
                                } catch (e: any) {
                                  return 'invalid';
                                }
                              }}
                              isReadOnly={
                                !hasAccessToAction('mentor.details.update')
                              }
                            />
                            <Field<string>
                              name='dateOfEnrollment'
                              testid='mentor-details-date-enrollment'
                              component={FormDateInput}
                              label='Date of enrollment*'
                              maxDate={new Date()}
                              editable
                              parse={(value) => {
                                try {
                                  if (value) {
                                    return formatDateToRFC(value);
                                  }
                                  return '';
                                } catch (e: any) {
                                  return 'invalid';
                                }
                              }}
                              isReadOnly={
                                !hasAccessToAction('mentor.details.update')
                              }
                            />
                          </FormGroup>
                        )}
                      {hasAccessToAction(
                        'mentor.details.field.specializations',
                      ) && (
                        <FormGroup>
                          <Field<Specialization['id'][]>
                            name='specializations'
                            data-testid='mentor-details-specializations'
                            component={FormSpecializationInput}
                            label='Specialization'
                            disabled={!mentorSpecializations}
                          />
                        </FormGroup>
                      )}

                      {isMobile() && (
                        <FormGroup className={classes.statusField}>
                          <Field<string>
                            testid='mentor-details-status'
                            name='status'
                            component={FormSelect}
                            label='Status'
                            options={STATUSES_MENTORS}
                            readOnly={!hasRole(Role.Manager)}
                          />
                        </FormGroup>
                      )}
                    </div>
                    <div className={classes.sectionBlock}>
                      <Typography className={classes.sectionTitle} variant='h3'>
                        Contacts
                      </Typography>
                      {hasAccessToAction('mentor.details.field.email') && (
                        <FormGroup>
                          <Field<string>
                            name='email'
                            data-testid='mentor-details-email'
                            component={FormEmail}
                            label='Email*'
                            formatOnBlur
                            format={(value: string) => {
                              return value ? value.toLowerCase() : value;
                            }}
                            entityType='mentor'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.email,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                      {hasAccessToAction('mentor.details.field.phone') && (
                        <FormGroup>
                          <Field<string>
                            name='phone'
                            data-testid='mentor-details-phone'
                            label='Phone'
                            component={TextFieldWrapper}
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.phone,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                      {hasAccessToAction('mentor.details.field.linkedIn') && (
                        <FormGroup>
                          <Field<string>
                            name='linkedInProfile'
                            data-testid='mentor-details-linkedin'
                            component={TextFieldWrapper}
                            label='LinkedIn profile'
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.linkedIn,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                    </div>
                    {hasAccessToAction('mentor.details.field.address') && (
                      <div className={classes.sectionBlock}>
                        <Typography
                          className={classes.sectionTitle}
                          variant='h3'>
                          Address
                        </Typography>
                        <FormGroup>
                          <Field<string>
                            name='address'
                            data-testid='mentor-details-address'
                            component={TextFieldWrapper}
                            label='Address'
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.address,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            name='city'
                            data-testid='mentor-details-city'
                            component={TextFieldWrapper}
                            label='City'
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.city,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            name='state'
                            data-testid='mentor-details-state'
                            component={TextFieldWrapper}
                            label='State'
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.state,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            name='zip'
                            data-testid='mentor-details-zip'
                            component={TextFieldWrapper}
                            label='Zip code'
                            parse={parseEmptyString}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.zip,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            name='country'
                            data-testid='mentor-details-country'
                            component={TextFieldWrapper}
                            parse={parseEmptyString}
                            label='Country'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.country,
                                readOnly: !hasAccessToAction(
                                  'mentor.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      </div>
                    )}
                    {hasAccessToAction('mentor.details.update') && (
                      <div className={classes.actionsBlock}>
                        <StickyContent>
                          <Button
                            data-testid='mentor-details-button-submit'
                            className={CLASS_TRACKING.INTERNAL_ACTION}
                            onClick={formProps.handleSubmit}
                            disabled={loading}
                            startIcon={<CheckIcon />}>
                            {loading ? (
                              <CircularProgress size={24} color='inherit' />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </StickyContent>
                      </div>
                    )}
                  </form>
                </div>
                {!isMobile() && (
                  <div className={classes.logoFormBlock}>
                    <Field<string>
                      name='logo'
                      parse={(value) => (!value ? '' : value)}
                      component={({ input }) => (
                        <UserLogo
                          logo={input.value}
                          name={getAbbreviationByName(
                            formProps.values.firstName,
                            formProps.values.lastName,
                          )}
                          onUpload={(photoFile) => {
                            if (validateLogoFile(photoFile)) {
                              setLogoFile(photoFile);
                              const blobFile = URL.createObjectURL(photoFile);
                              input.onChange(blobFile);
                            }
                          }}
                          onRemove={() => {
                            setLogoFile(null);
                            input.onChange('');
                          }}
                          readOnly={!hasAccessToAction('mentor.details.update')}
                        />
                      )}
                    />

                    <FormGroup className={classes.statusField}>
                      <Field<string>
                        testid='mentor-details-status'
                        name='status'
                        component={FormSelect}
                        label='Status'
                        options={STATUSES_MENTORS}
                        readOnly={!hasRole(Role.Manager)}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>
            </FormWrapper>
          );
        }}
      />
    </div>
  );
}

function FormWrapper({
  formProps,
  children,
}: {
  formProps: FormRenderProps<FormValues, FormValues>;
  children: any;
}) {
  const formAPI = useForm();

  useEffect(() => {
    formAPI.change('logo', formProps.initialValues.logo);
  }, [formAPI, formProps.initialValues.logo]);

  return children;
}

export default MentorDetailsForm;
