import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { SchedulingWizard } from '../../api/schedulingWizard';
import { getVoteDate } from '../../utils/date';
import { lengthField } from '../../utils/form';
import { validateEmail } from '../../utils/functions';
import { Button, CheckboxList, FormButtons, FormGroup } from '../common';
import { TextFieldSummary, TextFieldWrapper } from './wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  formContainer: {
    width: '100%',
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  marginTopField: {
    marginTop: '32px !important',
  },
  dateList: {
    maxHeight: '33vh',
    overflowY: 'scroll',
  },
  checkbox: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: -10,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
  location: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export interface FormValues {
  email: string;
  venture: string;
}

export interface FormInitialValues {
  venture: string;
}

export interface ParsedFormValues {
  email: string;
  venture: string;
  selectedTimeSlotIds: string[];
}

export interface OptionTypes {
  label: string;
  value: string;
}

interface Props {
  onSubmit: (values: ParsedFormValues) => void;
  loading: boolean;
  initialValues?: FormInitialValues;
  schedulingWizardDates: SchedulingWizard['schedulingWizardDates'];
  location: 'in-person' | 'virtual';
  adminEmail: string;
}

type Errors = {
  [K in keyof any]?: string;
};

const parsedFormValues = (
  value: FormValues,
  selectedDateIds: string[],
): ParsedFormValues => {
  return {
    email: value.email,
    venture: value.venture,
    selectedTimeSlotIds: selectedDateIds,
  };
};

const validateForm = (values: any) => {
  const errors: Errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Invalid';
  }
  return errors;
};

function SchedulingWizardInitialForm({
  onSubmit,
  loading,
  initialValues,
  schedulingWizardDates,
  location,
  adminEmail,
}: Props) {
  const classes = useStyles();
  const isSubmitDisabled = loading;
  const [selected, setSelected] = useState<string[]>([]);
  const [isSelectedNone, setIsSelectedNone] = useState(false);

  const options: OptionTypes[] = schedulingWizardDates.map((value) => ({
    value: value.id || '',
    label: getVoteDate(value.startDateTime),
  }));

  const handleSubmit = (values: FormValues) => {
    if (selected.length === 0 && !isSelectedNone) {
      return;
    }
    const parsedValues = parsedFormValues(values, selected);
    onSubmit(parsedValues);
  };

  const handleChange = (value: string) => {
    let currentValue: string[];
    if (selected?.includes(value)) {
      currentValue = selected.filter((data) => data !== value);
    } else {
      currentValue = [...selected, value];
    }
    if (isSelectedNone) {
      setIsSelectedNone(false);
    }
    setSelected(currentValue);
  };

  return (
    <Form
      validate={validateForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <div className={classes.formBlocks}>
          <form noValidate className={classes.formContainer}>
            <FormGroup>
              <Field<string>
                className={classes.marginTopField}
                name='venture'
                component={TextFieldSummary}
                label='Venture'
                disabled
                testid='scheduling-wizard-initial-form-venture'
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name='email'
                component={TextFieldWrapper}
                label='Email*'
                formatOnBlur
                format={(value: string) => {
                  return value ? value.toLowerCase() : value;
                }}
                testid='scheduling-wizard-initial-form-email'
                InputProps={{
                  inputProps: {
                    maxLength: lengthField.email,
                  },
                }}
              />
            </FormGroup>

            <Typography variant='h4' className={classes.marginTopField}>
              Please select ALL options that could work for you:*
              <Alert severity='info' className={classes.location}>
                {getLocationText(location)}
              </Alert>
            </Typography>

            <FormGroup className={classes.dateList} column>
              {options.length > 0 &&
                options.map((option: OptionTypes) => {
                  return (
                    <CheckboxList
                      value={option.value}
                      label={option.label}
                      name='dateId'
                      checked={selected.includes(option.value)}
                      onChange={handleChange}
                      className={classes.checkbox}
                      classNameLabel={classes.checkboxLabel}
                    />
                  );
                })}

              <CheckboxList
                value='none'
                label='None work for me'
                name='dateId'
                checked={isSelectedNone}
                onChange={() => {
                  setSelected([]);
                  setIsSelectedNone((prev) => !prev);
                }}
                className={classes.checkbox}
                classNameLabel={classes.checkboxLabel}
              />
            </FormGroup>

            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='button-submit-form'
                onClick={formProps.handleSubmit}
                disabled={isSubmitDisabled}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Submit'
                )}
              </Button>
              <Button
                variant='outlined'
                data-testid='button-contact-admin'
                href={`mailto:${adminEmail}`}>
                Contact admin
              </Button>
            </FormButtons>
          </form>
        </div>
      )}
    />
  );
}

export default SchedulingWizardInitialForm;

function getLocationText(location: 'in-person' | 'virtual'): string {
  switch (location) {
    case 'virtual':
      return 'This will be a virtual meeting';
    case 'in-person':
      return 'This will be a in-person meeting';
  }
}
