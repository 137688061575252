import { Address, City, Country, Name, State } from '../types';
import { Email } from '../utils/String/Email';
import { EmailsList } from '../utils/String/EmailsList';
import { Phone } from '../utils/String/Phone';
import { Url } from '../utils/String/Url';
import { Zip } from '../utils/String/Zip';
import { FounderId } from './founders';
import { axiosRequest } from './instance';
import { Mentor, MentorId } from './mentors';
import { SpecializationId } from './specializations';
import { TenantId, TractionUserId } from './tenants/Tenant';
import { TenantTimeZone } from './tenants/types/Settings';
import { JWTToken } from './user/JWTToken';
import { Role } from './user/Role';
import { VentureDetails } from './ventures';
import { Venture } from './ventures/types/Venture';

export interface LoginParams {
  username: string;
  password: string;
}

export interface Login2FAParams extends LoginParams {
  secondFactorCode: string;
}

export interface LoginResult {
  jwttoken: JWTToken | null;
  secondFactorRequired?: boolean;
}

export interface Tenant {
  city: string | null;
  country: string | null;
  defaultPhysicalLocation: string | null;
  description: string | null;
  email: string;
  id: TenantId;
  logo: string | null;
  managerFirstName: string;
  managerLastName: string;
  mostImpactfulMentorEnabled: boolean;
  automaticSchedulingEnabled?: boolean;
  reportBasedScheduleEnabled?: boolean;
  parentName: string | null;
  phone: string | null;
  programName: string;
  quarterlyReportEnabled?: boolean;
  state: string | null;
  strictOfficeHoursScheduling?: boolean;
  tenantName: string;
  timeZone: TenantTimeZone;
  websiteUrl: Url | null;
  additionalEmails: EmailsList | null;
  valid: boolean;
  automationProfileUpdatesEnabled: boolean;
  automationIssuesCleanupEnabled: boolean;
  automationAgendaReminderEnabled: boolean;
  customQuestionsVentureAppEnabled?: boolean;
  customQuestionsMentorAppEnabled?: boolean;
  customQuestionsFounderAppEnabled?: boolean;
  customQuestionsVentureAdditionalInfoEnabled?: boolean;
  customQuestionsMentorAdditionalInfoEnabled?: boolean;
  customQuestionsFounderAdditionalInfoEnabled?: boolean;
  inviteReminderWeekEnabled?: boolean;
  inviteReminderTwoDaysEnabled?: boolean;
  mentorApplicationIntro?: string | null;
  ventureApplicationIntro?: string | null;
  secondFactorEnabled?: boolean;
  groupStatisticsEnabled?: boolean;
}

export interface TenantDetails {
  tenantName: string;
  programName: string;
  description: string | null;
  parentName: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  email: string;
  phone: string | null;
  managerFirstName: string | null;
  managerLastName: string | null;
  defaultPhysicalLocation: string | null;
  logo: string | null;
  quarterlyReportEnabled?: boolean;
  strictOfficeHoursScheduling?: boolean;
  mostImpactfulMentorEnabled?: boolean;
  automaticSchedulingEnabled?: boolean;
  reportBasedScheduleEnabled?: boolean;
  timeZone: TenantTimeZone;
  websiteUrl: Url | null;
  additionalEmails: EmailsList | null;
  secondFactorEnabled?: boolean;
  groupStatisticsEnabled?: boolean;
  inviteReminderWeekEnabled?: boolean;
  inviteReminderTwoDaysEnabled?: boolean;
}

export interface EventChannel {
  id: string;
  tenantId: string;
  channelName: string;
  channelLocation: string;
  description: string;
  valid: boolean;
}

export interface EventNewChannel {
  channelName: string;
  channelLocation: string;
  description: string;
}

export interface Passwords {
  oldPassword: string;
  newPassword: string;
}

export interface TractionUser {
  id: TractionUserId;
  userRoleName: Role;
  userTenantId: TenantId;
  email: string;
  password: string;
  identityId: string;
  modules: string;
  inviteCode: string;
  firstName: string;
  lastName: string;
  creationNote: string;
  valid: boolean;
  tenantId: string;
  mentor: boolean;
  manager: boolean;
  founder: boolean;
  admin: boolean;
}

export interface UserNew {
  userRoleName: Role;
  email: string;
  firstName: string;
  lastName: string;
  identityId?: string;
  creationNote?: string;
}

export interface RegisterUser {
  email: string;
  userTenantId: string;
  inviteCode: string;
  password: string;
}

export interface ICheckMentorValidate {
  mentor: {
    firstName: string;
    lastName: string;
    email: string;
    tenantId: string;
  };
}

export interface ICheckVentureValidate {
  venture: {
    ventureName: string;
    tenantId: string;
  };
  founderList: {
    firstName: string;
    lastName: string;
    email: string;
    tenantId: string;
  };
}

export interface IMentorRegistrationAdditionalInfo {
  tenantId?: string;
  gender: string;
  race: string;
  veteran: boolean;
  whyBecomeMentor: string;
  salesChallenges: string;
  marketingChallenges: string;
  fundraisingChallenges: string;
  businessChallenges: string;
}

export interface IMentorRegistrationSpecialization {
  specializationId: string;
}

export interface IMentorRegistrationForm {
  mentor: {
    firstName: string;
    lastName: string;
    dateOfBirth: string | null;
    linkedInProfile: string;
    country: string;
    state: string;
    city: string;
    address: string;
    zip: string;
    email: string;
    phone: string;
    tenantId?: string;
    mentorAdditionalInfo: IMentorRegistrationAdditionalInfo;
  };
  mentorSpecializationList?: IMentorRegistrationSpecialization[];
}

export interface IVentureRegistrationForm {
  venture: {
    ventureName: string;
    url: string;
    country: Country;
    state: State;
    city: City;
    address: Address;
    zip: Zip;
    email: Email;
    phone: Phone;
    tenantId: TenantId;
    venturesAdditionalInfo: {
      howDidYouFind: string;
      businessDescription: string;
      comments: string;
      currentProgress: string;
      dedication: string;
      growthRatio: string;
      isLegallyRegistered: boolean;
      marketSize: string;
      mentorshipPlans: string;
      obstacles: string;
      plans: string;
      reasonToStartNow: string;
      requiredSupport: string;
      tenantId: string;
      yearPlans: string;
    };
  };
  founderList: Array<{
    tenantId: string;
    firstName: Name;
    lastName: Name;
    linkedInProfile: URL;
    phone: Phone;
    foundersAdditionalInfo: {
      race: string;
      gender: string;
      veteran: boolean;
    };
  }>;
  ventureSpecializationList: Array<{
    specializationId: SpecializationId;
  }>;
}

export interface NewConsent {
  tenantId: string;
  type: 'Founder' | 'Mentor';
  body: string;
}

export interface ConsentType extends NewConsent {
  id: string;
}

const authAPI = {
  async login(credentials: LoginParams) {
    const res = await axiosRequest.post<LoginResult>({
      url: '/login',
      data: credentials,
    });
    return res.data;
  },
  async login2FA(credentials: Login2FAParams) {
    const res = await axiosRequest.post<LoginResult>({
      url: '/2fa',
      data: credentials,
    });
    return res.data;
  },
  async getTenant() {
    const res = await axiosRequest.get<Tenant>({
      url: '/tenants',
      credentials: true,
    });
    return res.data;
  },
  async getChannels() {
    try {
      const res = await axiosRequest.get<EventChannel[]>({
        url: '/tenants/eventchannels',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async updateAvatar(logoFile: File) {
    let formData = new FormData();
    formData.append('name', logoFile.name);
    formData.append('file', logoFile);

    const res = await axiosRequest.post<Tenant>({
      url: '/tenants/logo',
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async updateTenant(tenantId: string, tenantDetails: TenantDetails) {
    const res = await axiosRequest.put<Tenant>({
      url: '/tenants',
      data: { ...tenantDetails, id: tenantId },
      credentials: true,
    });
    return res.data;
  },
  async checkEmail(email: string) {
    try {
      const res = await axiosRequest.get<Tenant>({
        url: `/tenants/emails/${email}`,
        credentials: true,
      });
      return [res.data];
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async checkShortProgramName(programName: string) {
    try {
      const res = await axiosRequest.get<Tenant>({
        url: `/tenants/names/${programName}`,
        credentials: true,
      });
      return [res.data];
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async checkLongProgramName(programName: string) {
    try {
      const res = await axiosRequest.get<Tenant>({
        url: `/tenants/programnames/${programName}`,
        credentials: true,
      });
      return [res.data];
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async updatePassword(passwords: Passwords) {
    try {
      const res = await axiosRequest.put<string>({
        url: '/tenants/users/passwords',
        data: passwords,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createChannel(channel: EventNewChannel) {
    try {
      const res = await axiosRequest.post<EventChannel>({
        url: '/tenants/eventchannels',
        data: channel,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateChannel(channel: EventChannel) {
    try {
      const res = await axiosRequest.put<EventChannel>({
        url: '/tenants/eventchannels',
        data: channel,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeChannel(channelId: string) {
    try {
      const res = await axiosRequest.delete<EventChannel>({
        url: `/tenants/eventchannels/${channelId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getUsers(page: number) {
    try {
      const res = await axiosRequest.get<TractionUser[]>({
        url: `/tenants/users/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getSearchUsers(searchString: string) {
    try {
      const res = await axiosRequest.get<TractionUser[]>({
        url: `/tenants/users/search/${searchString}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createUsers(userData: UserNew) {
    try {
      const res = await axiosRequest.post<TractionUser>({
        url: '/tenants/users',
        data: userData,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async userInvites(userId: string) {
    try {
      const res = await axiosRequest.post<TractionUser>({
        url: `/tenants/users/invites/${userId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async userRevoke(userId: string) {
    try {
      const res = await axiosRequest.delete<TractionUser>({
        url: `/tenants/users/${userId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async multipleUsersInvites(usersIds: string[]) {
    try {
      const res = await axiosRequest.post<TractionUser[]>({
        url: '/tenants/users/invites',
        data: usersIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async multipleRevokeInvites(usersIds: string[]) {
    try {
      const res = await axiosRequest.delete<TractionUser[]>({
        url: '/tenants/users',
        data: usersIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createMentors(mentorsIds: MentorId[]) {
    try {
      const res = await axiosRequest.post<TractionUser[]>({
        url: '/tenants/users/mentors',
        data: mentorsIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createMentorsAll() {
    try {
      const res = await axiosRequest.post<TractionUser[]>({
        url: '/tenants/users/mentors/all',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createFounders(ids: FounderId[]) {
    try {
      const res = await axiosRequest.post<TractionUser[]>({
        url: '/tenants/users/founders',
        data: ids,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createFoundersAll() {
    try {
      const res = await axiosRequest.post<TractionUserId[]>({
        url: '/tenants/users/founders/all',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async registerUser(dataUser: RegisterUser) {
    try {
      const res = await axiosRequest.post<TractionUser[]>({
        url: '/tenants/users/activations',
        data: dataUser,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async resetPassword(email: string) {
    try {
      const res = await axiosRequest.post<TractionUser>({
        url: `/tenants/users/reset/${email}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getUsersByRole(rolename: string, page: number) {
    try {
      const res = await axiosRequest.get<TractionUser[]>({
        url: `/tenants/users/roles/${rolename}/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async checkUsersValidate(login: string) {
    try {
      const res = await axiosRequest.get<boolean>({
        url: `/tenants/users/validate/${login}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async checkMentorValidate(data: ICheckMentorValidate) {
    try {
      const res = await axiosRequest.post<Mentor>({
        url: `/mentors/applications/validations`,
        data: {
          ...data,
          mentor: {
            ...data.mentor,
            status: 'Applicant',
          },
        },
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async mentorRegistration(data: IMentorRegistrationForm) {
    try {
      const { mentor, ...newData } = data;
      const res = await axiosRequest.post<Mentor>({
        url: '/mentors/applications',
        data: {
          ...newData,
          mentor: {
            ...mentor,
            status: 'Applicant',
          },
        },
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async checkVentureNameValidation({
    tenantId,
    ventureName,
  }: {
    tenantId: string;
    ventureName: string;
  }) {
    try {
      const res = await axiosRequest.get<VentureDetails>({
        url: `/ventures/applications/validations/tenants/${tenantId}/venturename/${ventureName}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return null;
      } else {
        throw e;
      }
    }
  },
  async checkVentureValidate(data: ICheckVentureValidate) {
    try {
      const res = await axiosRequest.post<Mentor>({
        url: `/ventures/applications/validations`,
        data: {
          ...data,
          venture: {
            ...data.venture,
            status: 'Applicant',
          },
        },
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async ventureRegistration(data: IVentureRegistrationForm) {
    try {
      const res = await axiosRequest.post<Venture>({
        url: '/ventures/applications',
        data: {
          ...data,
          venture: {
            ...data.venture,
            status: 'Applicant',
          },
          founderList: data.founderList.map((founder) => ({
            ...founder,
            status: 'Applicant',
          })),
        },
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorApplicationLink(tenantId: string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/mentors/applications/url/${tenantId}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getVentureApplicationLink(tenantId: string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/ventures/applications/url/${tenantId}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getConsents() {
    try {
      const res = await axiosRequest.get<ConsentType[]>({
        url: '/consents',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async updateConsent(consent: ConsentType) {
    try {
      const res = await axiosRequest.put<ConsentType>({
        url: '/consents',
        data: consent,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createConsent(consent: NewConsent) {
    try {
      const res = await axiosRequest.post<ConsentType>({
        url: '/consents',
        data: consent,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async deleteConsent(consentId: string) {
    try {
      const res = await axiosRequest.delete<ConsentType>({
        url: `/consents/${consentId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorConsents(tenantId: string) {
    try {
      const res = await axiosRequest.get<ConsentType[]>({
        url: `/consents/tenants/${tenantId}/mentors`,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getFounderConsents(tenantId: string) {
    try {
      const res = await axiosRequest.get<ConsentType[]>({
        url: `/consents/tenants/${tenantId}/founders`,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getOTLToken(key: string) {
    try {
      const res = await axiosRequest.post<{ jwttoken: JWTToken }>({
        url: '/otl',
        data: key,
      });
      return res.data.jwttoken;
    } catch (e: any) {
      throw e;
    }
  },
  getChannelByName(channelName: string): Promise<EventChannel> {
    return axiosRequest
      .get<EventChannel>({
        url: `/tenants/eventchannelsnames/${channelName}`,
        credentials: true,
      })
      .then((r) => r.data);
  },
  async getUserByEmail(email: string): Promise<TractionUser | null> {
    try {
      const res = await axiosRequest.get<TractionUser>({
        url: `/tenants/users/emails/${email}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return null;
      }
      throw e;
    }
  },
  async unsubscribeUser(tenantId: TenantId, email: string) {
    try {
      const res = await axiosRequest.delete<string>({
        url: `/public/tenants/${tenantId}/subscriptions/${email}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
};

export default authAPI;
