import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Bowser from 'bowser';
import cn from 'classnames';
import { useContext, useMemo } from 'react';
import { VentureFullReport } from '../../api/reports';
import { LogoImage, CardVentureReport, Text } from '../../components/common';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { ParamsReport } from '../../pages/reports/reports-details-venture-page';
import { COLORS } from '../../theme/variables';
import { getDateRangeForReport } from '../../utils/date';

interface Props {
  paramsReport: ParamsReport;
  report?: VentureFullReport;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  border: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    width: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: 670,
    },
  },
  defaultStyles: {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  logo: {
    marginRight: '15px',
  },
  image: {
    maxHeight: 70,
  },
  logoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: 70,

    '@media print': {
      position: 'relative',
    },
  },
  confidentialText: {
    fontSize: 10,

    '@media print': {
      whiteSpace: 'nowrap',
    },
  },
  contentEmpty: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentEmptyText: {
    width: 500,
    textAlign: 'center',
  },
  printFirefox: {
    '@media print': {
      marginLeft: -100,
    },
  },
  missingReports: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  icon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
}));

function ReportsVentureScreen({ paramsReport, report, loading }: Props) {
  const { user } = useAuth();
  const { logo } = useContext(UserContext);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isFirefox = browser.getBrowser().name === 'Firefox';

  const classes = useStyles();
  const startDate = paramsReport.dataRange?.startDate;
  const endDate = paramsReport.dataRange?.endDate;
  const textRangeDate = useMemo(() => {
    if (startDate && endDate) {
      return getDateRangeForReport(new Date(startDate), new Date(endDate));
    }
    return '';
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div className={classes.contentEmpty}>
        <CircularProgress size={40} color='primary' />
      </div>
    );
  }

  return (
    <div
      data-testid='venture-progress-report-container'
      className={cn({
        [classes.printFirefox]: isFirefox,
      })}>
      {!!report ? (
        <div data-testid='report-container-list'>
          <div className={classes.border}>
            <div className={classes.title}>
              <div>
                <Typography variant='h4'>{user?.programName}</Typography>
                {textRangeDate && (
                  <Text variant='normal'>
                    For the period from {textRangeDate}
                  </Text>
                )}
              </div>
              <div className={classes.logoContent}>
                <Text variant='normal' className={classes.confidentialText}>
                  Confidential. Not for Distribution
                </Text>

                {paramsReport.includeLogo && logo && (
                  <span
                    className={classes.logo}
                    data-testid='venture-progress-report-include-logo'>
                    <LogoImage
                      className={classes.image}
                      src={logo}
                      alt={user?.programName}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>

          {paramsReport.includeSummary && (
            <div
              className={classes.border}
              data-testid='venture-progress-report-include-summary'>
              <div>
                <Text variant='normal'>
                  Total Number of session for the period:{' '}
                  {report.totalNumberOfSessions}
                </Text>
              </div>
              <div>
                <Text variant='normal'>
                  Number of reports: {report.totalNumberOfReports}
                </Text>
              </div>
              <div className={classes.missingReports}>
                <Text variant='normal'>
                  {report.venturesWithNoReports.length > 0
                    ? `Missing ${report.venturesWithNoReports.length} ${
                        report.venturesWithNoReports.length > 1
                          ? 'reports'
                          : 'report'
                      }:`
                    : 'No Missing Reports'}
                </Text>
                <Tooltip
                  title={
                    "The following ventures had sessions during the selected period but lead didn't submit reports"
                  }>
                  <InfoIcon className={classes.icon} />
                </Tooltip>
              </div>
              <ol className={classes.defaultStyles}>
                {report?.venturesWithNoReports?.length > 0 &&
                  report.venturesWithNoReports.map((reportItem) => {
                    return (
                      <li>
                        <Text variant='normal'>{reportItem} </Text>
                      </li>
                    );
                  })}
              </ol>
            </div>
          )}

          {report?.ventureReportItems?.length > 0 &&
            report.ventureReportItems.map((value) => {
              return (
                <CardVentureReport
                  ventureReport={value}
                  isAssessment={paramsReport.includeAssessment}
                />
              );
            })}
        </div>
      ) : (
        <div
          className={classes.contentEmpty}
          data-testid='report-empty-container'>
          <Text variant='normal' className={classes.contentEmptyText}>
            Unfortunately we were not able to extract analytical data for the
            dates you selected. Please get in touch with program admin or
            support@tractionfive.com if you believe this is an unexpected
            result.
          </Text>
        </div>
      )}
    </div>
  );
}

export default ReportsVentureScreen;
