import {
  AsyncValue,
  InvalidType,
  SubmittedAsyncValue,
  SubmittedValue,
  Type,
  ValidType,
  Value,
  ValueType,
} from '../../../utils/FormValue';
import { Item, Submitted, Valid } from './types/Item';
import { validators } from './validators';
import { Dispatch } from 'react';
import * as Actions from '../Edit/types/Actions';
import { statuses } from '../../../api/CommunityMembers/types/Status';
import { statusTitle } from '../Add/utils';
import { TabItem } from '../../../components/Pages/Forms/Tabs';
import { getRoutePath, Pages } from '../../../router/constants';
import {
  FormItem,
  FormItem2,
} from '../../../components/common/Forms/types/FormItem';
import { CommunityMemberId } from '../../../api/CommunityMembers/types/CommunityMember';

export function validateField<T extends Value<any, any, any>>(
  validator: (v: ValueType<T>) => SubmittedValue<T>,
  v: T,
): SubmittedValue<T> {
  switch (v.__typeName) {
    case Type.initial:
      return validator(v.value);
    case Type.invalid:
    case Type.valid:
      return v as SubmittedValue<T>;
  }
}

export function validateAsyncField<T extends AsyncValue<any, any, any>>(
  validator: (v: ValueType<T>) => SubmittedAsyncValue<T>,
  v: T,
): SubmittedAsyncValue<T> {
  switch (v.__typeName) {
    case Type.initial:
    case Type.invalid:
      return validator(v.value);
    case Type.verifying:
    case Type.valid:
      return v as SubmittedAsyncValue<T>;
  }
}

export function validateItem(item: Item): Submitted {
  return {
    email: validateAsyncField(validators.email, item.email),
    firstName: validateAsyncField(validators.firstName, item.firstName),
    lastName: validateAsyncField(validators.lastName, item.lastName),
    birthDate: validateField(validators.birthDate, item.birthDate),
    country: validateField(validators.country, item.country),
    state: validateField(validators.state, item.state),
    city: validateField(validators.city, item.city),
    linkedIn: validateField(validators.linkedIn, item.linkedIn),
    address: validateField(validators.address, item.address),
    status: validateField(validators.status, item.status),
    zip: validateField(validators.zip, item.zip),
  };
}

export function isValid(v: Submitted): v is Valid {
  return Object.values(v).every((i) => i.__typeName === 'valid');
}

export function createField<K extends keyof Item>(
  item: Item[K],
  key: K,
  dispatch: Dispatch<Actions.SetValue<K> | Actions.Toggle>,
): FormItem<ValueType<Item[K]>> {
  return {
    // @ts-expect-error, need to identify why compiler complains
    ...formValueToInput<ValidType<Item[K]>, InvalidType<Item[K]>>(item),
    onBlur: () => dispatch(Actions.toggle(key)),
    onChange: (value: ValueType<Item[K]>) =>
      dispatch(Actions.setValue({ key, value })),
  };
}

export function formValueToInput<T extends T2, T2>(
  t: Value<string, T, T2>,
): { value: T2; error: boolean; errorText?: string } {
  switch (t.__typeName) {
    case Type.initial:
    case Type.valid:
      return {
        value: t.value,
        error: false,
      };
    case Type.invalid:
      return {
        value: t.value,
        error: !!t.error,
        errorText: t.error !== 'Required' ? t.error : undefined,
      };
  }
}

export function formValueToInput2<T extends T2, T2>(
  t: AsyncValue<string, T, T2>,
): { value: T2; status: FormItem2<T>['status']; errorText?: string } {
  switch (t.__typeName) {
    case Type.initial:
      return {
        value: t.value,
        status: 'normal',
      };
    case Type.valid:
      return {
        value: t.value,
        status: 'success',
      };
    case Type.verifying:
      return {
        value: t.value,
        status: 'loading',
      };
    case Type.invalid:
      return {
        value: t.value,
        status: 'error',
        errorText: t.error.toLowerCase() !== 'required' ? t.error : undefined,
      };
  }
}

export const statusOptions = statuses.map((value) => ({
  label: statusTitle(value),
  value,
}));

export const getTabs = (id: CommunityMemberId | undefined): TabItem[] => [
  {
    title: 'Details',
    link: id
      ? getRoutePath(Pages.OH_COMMUNITY_MEMBERS_EDIT, { id })
      : Pages.OH_COMMUNITY_MEMBERS_CREATE,
  },
  ...(id
    ? [
        {
          title: 'Notes',
          link: getRoutePath(Pages.OH_COMMUNITY_MEMBERS_NOTES, { id }),
        },
        {
          title: 'Logs',
          link: getRoutePath(Pages.OH_COMMUNITY_MEMBERS_LOGS, { id }),
        },
      ]
    : []),
];
