import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Button as MaterialButton, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Role } from '../api/user/Role';
import {
  ConfirmButton,
  PageLoader,
  Text,
  TextField,
  TextWysiwyg,
  BeforeUnload,
} from '../components/common';
import PersonalNoteCard from '../components/common/PersonalNotes/note-card';
import PersonalNotesAttachments from '../components/common/PersonalNotes/personal-notes-attachments';
import PersonalNotesComments from '../components/common/PersonalNotes/personal-notes-comments';
import { Attachment } from '../components/common/attachment-card';
import BeforeChange from '../components/common/before-change';
import BaseLayout from '../components/layout/base-layout';
import {
  PersonalNote,
  PersonalNotesProvider,
  usePersonalNotes,
} from '../contexts/personal-notes-context';
import { UserContext } from '../contexts/user-context';
import { ProtectedRouteProps } from '../router/type';
import { COLORS } from '../theme/variables';
import { getFormattedFullDay } from '../utils/date';
import { CLASS_TRACKING } from '../utils/tracking_class';

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
    padding: 0,
    overflow: 'hidden',

    '& * ::-webkit-scrollbar': {
      width: 12,
    },

    '& * ::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,

      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },

    '& * ::-webkit-scrollbar-track': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '340px 1fr',
    height: '100%',
  },
  cardBlock: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 160px)',
    borderRight: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  cardTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 0 20px',
    gap: 10,

    '& > button': {
      minWidth: 'min-content',
    },
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // maxHeight: 'calc(100vh - 325px)',
    padding: '0 0 20px',
    overflow: 'auto',

    '&::-webkit-scrollbar-track': {
      marginTop: '62px',
      borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    },
  },
  addNoteBtn: {
    padding: '6.5px 16px',
  },
  closeBtnWrapper: {
    position: 'relative',
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    '& .MuiOutlinedInput-input': {
      paddingRight: '36px',
    },
  },
  noteBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100vh - 160px)',
  },
  noteBottomSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    gap: 20,
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  inputWrapper: {
    position: 'relative',
    zIndex: 2,
    flexGrow: 1,
    overflow: 'hidden',
  },
  inputContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      pointerEvents: 'none',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },

    '&.mask': {
      '&::before': {
        opacity: 1,
      },
    },

    '& > div:first-child': {
      height: '100%',

      '& > div': {
        height: '100%',
        overflow: 'auto',
        border: 'none !important',
        borderRadius: '0 !important',
        boxSizing: 'border-box',

        '&::-webkit-scrollbar-track': {
          marginTop: '39px',
        },

        '& > div:first-child': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgb(244, 246, 248) !important',
        },

        '& > div:last-child': {
          paddingTop: '47.5px !important',
        },
      },
    },
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyMessage: {
    padding: 20,
    textAlign: 'center',
  },
  nonSelectedIcon: {
    fontSize: 80,
    color: COLORS.COLOR_GRAY_LIGHTENED_30,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    marginTop: 0,
  },
  additionalActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  groupTitle: {
    position: 'sticky',
    top: 0,
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 10,
    margin: 0,
    backgroundColor: '#fff',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
  },
  attachmentsBtn: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
}));

const groupNotesByMonth = (notes: PersonalNote[]) => {
  const groupedNotes: {
    name: string;
    notes: PersonalNote[];
  }[] = [];
  const sortedNotes = notes.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  sortedNotes.forEach((note) => {
    const d = new Date(note.date);
    const date = getFormattedFullDay(d);
    const foundGroup = groupedNotes.find((group) => group.name === date);
    if (foundGroup) {
      foundGroup.notes.push(note);
    } else {
      groupedNotes.push({ name: date, notes: [note] });
    }
  });
  return groupedNotes;
};

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    return JSON.parse(attachmentRefs);
  } catch (e: any) {
    return [];
  }
};

const getEncodedSearchValue = (search: string): string => {
  // remove http(s):// part from the string
  const filteredSearch = search
    .replace(/https?:\/\/[^ ]+/g, '')
    .replace(/[^a-zA-Z0-9\s\-_.!~*'(),@?]/g, '');
  const encodedValue = encodeURIComponent(filteredSearch);
  return encodedValue;
};

function PersonalNotesPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { tokenData, hasRole, hasAccessToAction } = useContext(UserContext);
  const {
    notes,
    selectedNote,
    attachments,
    comments,
    activeBlock,
    loading,
    saving,
    deleting,
    loadingComments,
    searchFilter,
    setSelectedNote,
    setAttachments,
    toggleAttachments,
    toggleComments,
    closeAllBlocks,
    createNote,
    updateNote,
    deleteNote,
    fetchNextPage,
    setSearchFilter,
  } = usePersonalNotes();

  const [nextSelectedNote, setNextSelectedNote] = useState<PersonalNote | null>(
    null,
  );
  const [clickedOnNewNote, setClickedOnNewNote] = useState(false);
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const cardListRef = useRef<HTMLDivElement>(null);
  const debouncedTimer = useRef<number>();

  const groupNotes = useMemo(() => groupNotesByMonth(notes), [notes]);

  const isDirty = useMemo(() => {
    if (!selectedNote) {
      return false;
    }
    return selectedNote?.content !== value;
  }, [selectedNote, value]);

  const handleSaveNote = useCallback(async () => {
    if (selectedNote) {
      const updatedNoteData: PersonalNote = {
        ...selectedNote,
        content: value,
        attachmentRefs: JSON.stringify(attachments),
      };
      const updatedNote = await updateNote(updatedNoteData);

      if (updatedNote) {
        setSelectedNote(updatedNote);

        enqueueSnackbar('Note updated successfully', {
          variant: 'success',
        });
      }
    }
  }, [
    attachments,
    enqueueSnackbar,
    selectedNote,
    setSelectedNote,
    updateNote,
    value,
  ]);

  const confirmButtonRenderer = useCallback(
    ({ onConfirm }: { onConfirm: () => any }) => (
      <MaterialButton
        variant='outlined'
        onClick={async () => {
          await handleSaveNote();
          onConfirm();
        }}
        disabled={saving}>
        {loading ? (
          <CircularProgress size={24} color='inherit' />
        ) : (
          'Save the changes'
        )}
      </MaterialButton>
    ),
    [handleSaveNote, loading, saving],
  );

  const handleNoteRemove = useCallback(
    async (callback: Function) => {
      if (!selectedNote) {
        setSelectedNote(notes[0]);
        return;
      }

      const res = await deleteNote(selectedNote);

      if (res) {
        enqueueSnackbar('Note removed successfully', {
          variant: 'success',
        });
      }

      callback();
    },
    [deleteNote, enqueueSnackbar, notes, selectedNote, setSelectedNote],
  );

  const handleCardSelect = (note: PersonalNote) => {
    if (note.parentId === selectedNote?.parentId) {
      return;
    }

    if (isDirty) {
      setNextSelectedNote(note);
    } else {
      setNextSelectedNote(null);
      setSelectedNote(note);
    }
  };

  const resetSearch = useCallback(() => {
    setSearchValue('');
    setSearchFilter(undefined);
  }, [setSearchFilter]);

  const addNewNote = useCallback(async () => {
    const newNoteData: PersonalNote = hasRole(Role.Founder)
      ? {
          content: '',
          attachmentRefs: '[]',
          type: 'GeneralNote',
          tenantId: user.id,
          founderId: tokenData?.identityid || null,
          date: new Date().toISOString(),
        }
      : {
          content: '',
          attachmentRefs: '[]',
          type: 'GeneralNote',
          tenantId: user.id,
          mentorId: tokenData?.identityid || null,
          date: new Date().toISOString(),
        };
    const newNote = await createNote(newNoteData);

    if (newNote) {
      setSelectedNote(newNote);
      resetSearch();
    }

    // scroll card list to the top
    if (cardListRef.current) {
      cardListRef.current.scrollTop = 0;
    }
  }, [
    createNote,
    hasRole,
    resetSearch,
    setSelectedNote,
    tokenData?.identityid,
    user.id,
  ]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          const encodedValue = getEncodedSearchValue(e.target.value);
          setSearchFilter(encodedValue);
        } else {
          setSearchFilter(undefined);
        }
      }, 800) as unknown as number;
    },
    [setSearchFilter],
  );

  useEffect(() => {
    // Update note content and attachments when selected note is changed
    if (selectedNote) {
      setValue(selectedNote.content);
      const parsedAttachments = parseAttachments(
        selectedNote.attachmentRefs || '',
      );
      setAttachments(parsedAttachments || []);
    } else {
      setValue('');
      setAttachments([]);
    }
  }, [selectedNote, setAttachments]);

  useEffect(() => {
    const onScroll = (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (
        target &&
        target.scrollHeight - target.scrollTop <= target.clientHeight + 2000
      ) {
        fetchNextPage();
      }
    };

    const currentRef = cardListRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', onScroll);

      return () => {
        currentRef.removeEventListener('scroll', onScroll);
      };
    }
  }, [fetchNextPage, loading]);

  return (
    <BaseLayout user={user}>
      <Paper data-testid='notes-paper' className={classes.paper}>
        <BeforeUnload
          when={isDirty && !(saving || deleting)}
          title={'Leave the page'}
          body={
            'You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
          }
          disabled={saving || deleting}
          confirmButtonRenderer={confirmButtonRenderer}
        />
        <BeforeChange
          when={isDirty && !(saving || deleting)}
          title={'Leave the note'}
          body={
            'You are about to leave the note, all unsaved changes will be lost. Do you want to continue?'
          }
          disabled={saving || deleting}
          confirmButtonRenderer={confirmButtonRenderer}
          active={
            clickedOnNewNote ||
            (!!selectedNote &&
              !!nextSelectedNote &&
              nextSelectedNote.parentId !== selectedNote.parentId)
          }
          change={() => {
            if (clickedOnNewNote) {
              setClickedOnNewNote(false);
              addNewNote();
            } else {
              setSelectedNote(nextSelectedNote);
              setNextSelectedNote(null);
            }
          }}
          cancel={() => {
            setNextSelectedNote(null);
            setClickedOnNewNote(false);
          }}
        />
        <div className={classes.container}>
          <div className={classes.cardBlock}>
            <div className={classes.cardTopSection}>
              {hasAccessToAction('personalNotes.create') ? (
                <MaterialButton
                  className={cn(
                    CLASS_TRACKING.ENTITY_ACTION,
                    classes.addNoteBtn,
                  )}
                  data-testid='button-new-personal-note'
                  startIcon={<AddIcon />}
                  variant='contained'
                  onClick={
                    isDirty ? () => setClickedOnNewNote(true) : addNewNote
                  }
                  disabled={loading}
                  color='primary'>
                  Note
                </MaterialButton>
              ) : null}
              <div className={classes.closeBtnWrapper}>
                <TextField
                  className={cn(
                    classes.searchInput,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='Search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  small
                />
                <IconButton
                  className={classes.closeBtn}
                  size='small'
                  onClick={resetSearch}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.cardList} ref={cardListRef} id='cardList'>
              {groupNotes.map((group, groupIdx) => (
                <div key={groupIdx} className={classes.cardGroup}>
                  <Typography variant='h4' className={classes.groupTitle}>
                    {group.name}
                  </Typography>
                  {group.notes.map((note, idx) => (
                    <PersonalNoteCard
                      key={idx}
                      note={note}
                      selected={selectedNote?.parentId === note.parentId}
                      onClick={() => handleCardSelect(note)}
                    />
                  ))}
                </div>
              ))}
              {loading && <PageLoader />}
              {!loading && !notes.length && (
                <div className={classes.emptyState}>
                  <Text variant='normal' className={classes.emptyMessage}>
                    {searchFilter
                      ? 'We were not able to find any notes with a keyword provided. Please adjust your search prompt and try again.'
                      : 'We do not have any notes here yet. Please feel free to create a new one!'}
                  </Text>
                </div>
              )}
            </div>
          </div>
          <div className={classes.noteBlock}>
            {selectedNote ? (
              <>
                <div
                  className={classes.inputWrapper}
                  style={{
                    maxHeight: `calc(100vh - ${
                      hasAccessToAction('personalNotes.update') ? 240.5 : 160
                    }px)`,
                  }}>
                  <div
                    className={cn(classes.inputContainer, {
                      mask: !!activeBlock,
                    })}
                    onClick={closeAllBlocks}>
                    <TextWysiwyg
                      placeholder={'Add a note'}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      disabled={!hasAccessToAction('personalNotes.update')}
                    />
                  </div>
                  {hasAccessToAction('personalNotes.update') && (
                    <PersonalNotesAttachments />
                  )}
                  <PersonalNotesComments />
                </div>
                {hasAccessToAction('personalNotes.update') && (
                  <div className={classes.noteBottomSection}>
                    <div className={classes.actions}>
                      <div className={classes.actions}>
                        <MaterialButton
                          className={CLASS_TRACKING.ENTITY_ACTION}
                          data-testid='button-save-personal-note'
                          variant='contained'
                          onClick={handleSaveNote}
                          disabled={saving || deleting}
                          color='primary'>
                          Save
                        </MaterialButton>
                        <ConfirmButton
                          loading={deleting}
                          title='Remove the note?'
                          body='Sure you want to remove the note? Changes can’t be undone'
                          successProps={{
                            btnLabel: 'Remove',
                            onSuccess: handleNoteRemove,
                          }}
                          buttonRenderer={({ onClick }) => (
                            <MaterialButton
                              onClick={onClick}
                              className={CLASS_TRACKING.ENTITY_ACTION}
                              data-testid='button-remove-personal-note'
                              variant='outlined'
                              disabled={saving || deleting}
                              color='primary'>
                              Remove
                            </MaterialButton>
                          )}
                        />
                      </div>
                      {selectedNote && (
                        <div className={classes.additionalActions}>
                          <MaterialButton
                            className={classes.attachmentsBtn}
                            component='label'
                            color='primary'
                            onClick={toggleAttachments}
                            startIcon={<AttachFileIcon fontSize='inherit' />}>
                            ({attachments?.length || 0})
                          </MaterialButton>
                          <MaterialButton
                            className={classes.attachmentsBtn}
                            component='label'
                            color='primary'
                            onClick={toggleComments}
                            disabled={loadingComments}
                            startIcon={
                              <ChatBubbleOutlineIcon fontSize='inherit' />
                            }>
                            {loadingComments ? (
                              <CircularProgress size={20} color='inherit' />
                            ) : (
                              `(${comments?.length || 0})`
                            )}
                          </MaterialButton>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={classes.emptyState}>
                <SpeakerNotesOffIcon className={classes.nonSelectedIcon} />
              </div>
            )}
          </div>
        </div>
      </Paper>
    </BaseLayout>
  );
}

function withPersonalNotesProvider(Component: FC<ProtectedRouteProps>) {
  return (props: ProtectedRouteProps) => (
    <PersonalNotesProvider>
      <Component {...props} />
    </PersonalNotesProvider>
  );
}

export default withPersonalNotesProvider(PersonalNotesPage);
