import { makeStyles } from '@material-ui/core';
import { TimePicker as Time } from '@mui/x-date-pickers';
import { COLORS } from '../../theme/variables';
import { TextFieldProps } from './text-field';

interface DateInputProps {
  value: Date | null;
  onChange: (e: any) => any;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
}

const useStyles = makeStyles({
  container: {
    '& .MuiPickersCalendarHeader-dayLabel': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: COLORS.COLOR_TEXT_LIGHTENED_20,
      textTransform: 'uppercase',
    },
  },
});

function TimePicker({
  value,
  onChange,
  textFieldProps,
  disabled = false,
}: DateInputProps) {
  const classes = useStyles();

  return (
    <Time
      disabled={disabled}
      className={classes.container}
      label={textFieldProps?.label}
      value={value}
      onChange={onChange}
    />
  );
}

export default TimePicker;
