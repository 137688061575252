import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { capitalize, isEmpty } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { CustomQuestionType } from '../../api/CustomQuestion/types/Type';
import {
  CustomQuestionsFormValues,
  useCustomQuestionContext,
} from '../../contexts/custom-questions-context';
import { reorder } from '../../utils/Array/reorder';
import { TestId } from '../Testing/TestId';
import { CustomSelect, Text, TextField } from '../common';
import { Inline } from '../common/Inline';
import Button from '../common/button';
import Dialog from '../common/dialog';
import { CustomOptionsList } from './CustomOptionsList';

export type CustomOptionsErrors = {
  [key: string]: string | null;
};

type FormErrors = {
  [key: string]: string | CustomOptionsErrors | undefined;
  optionsList?: CustomOptionsErrors;
};

const useStyles = makeStyles({
  root: {
    width: 500,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    transition: 'height 0.2s ease-out',
    '@media (max-width: 700px)': {
      width: 'calc(100vw - 104px)',
    },
  },
  rootExpanded: {
    height: 500,

    '@media (max-width: 700px)': {
      height: '100%',
    },
  },
  select: {
    width: '100%',
    zIndex: 2,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 15,
    animation: '$fadeIn 0.2s ease-out',
  },
  inline: {
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      maxHeight: 0,
      opacity: 0,
    },
    '100%': {
      maxHeight: 'none',
      opacity: 1,
    },
  },
});

const typeEnumValues = Object.values(CustomQuestionType).filter(
  (val) => val !== CustomQuestionType.SUBTITLE,
);
const typeOptions = typeEnumValues.map((value) => ({
  value,
  label: value
    .split('_')
    .map((v) => capitalize(v))
    .join(' '),
}));

const getDefaultFormValues = (
  editableQuestion?: CustomQuestion | null,
): CustomQuestionsFormValues =>
  editableQuestion
    ? {
        type: editableQuestion.type,
        label: editableQuestion.label,
        isMandatory: editableQuestion.isMandatory,
        options: editableQuestion.options || [],
      }
    : {
        type: '',
        label: '',
        isMandatory: false,
        options: [],
      };

const validateForm = (
  formValues: CustomQuestionsFormValues,
): { errors: FormErrors; isValid: boolean } => {
  const withOptions =
    formValues.type === CustomQuestionType.MULTIPLE_CHOICE ||
    formValues.type === CustomQuestionType.SINGLE_CHOICE;
  const errors: FormErrors = {};
  if (!formValues.type) {
    errors.type = 'Required';
  }
  if (!formValues.label.trim()) {
    errors.label = 'Required';
  }
  if (withOptions && !formValues.options?.length) {
    errors.options = 'Required';
  }
  if (withOptions && formValues.options?.length) {
    const optionsListErrors = formValues.options.reduce(
      (acc: CustomOptionsErrors, option) => {
        if (!option.label.trim()) acc[option.id] = 'Required';
        return acc;
      },
      {},
    );
    if (!isEmpty(optionsListErrors)) {
      errors.optionsList = optionsListErrors;
    }
  }
  const isValid = isEmpty(errors);
  return { errors, isValid };
};

export function CustomQuestionModal() {
  const classes = useStyles();
  const {
    editableQuestion,
    handleModalClose,
    handleQuestionCreate,
    handleQuestionUpdate,
    isCreating,
    optionRefs,
  } = useCustomQuestionContext();
  const topElementRef = useRef<HTMLDivElement>(null);

  const [formValues, setFormValues] = useState<CustomQuestionsFormValues>(
    getDefaultFormValues(editableQuestion),
  );
  const [newOptionsCounter, setNewOptionsCounter] = useState(0);
  const [prevOptionsCounter, setPrevOptionsCounter] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const withOptions = useMemo(
    () =>
      formValues.type === CustomQuestionType.MULTIPLE_CHOICE ||
      formValues.type === CustomQuestionType.SINGLE_CHOICE,
    [formValues.type],
  );
  const { isValid, errors } = useMemo(() => {
    return validateForm(formValues);
  }, [formValues]);

  const handleSubmit = useCallback(async () => {
    if (isValid) {
      editableQuestion
        ? handleQuestionUpdate(formValues)
        : handleQuestionCreate(formValues);
    } else {
      if (errors.type || errors.label) {
        topElementRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      } else if (errors?.optionsList && !isEmpty(errors?.optionsList)) {
        const keys = Object.keys(errors.optionsList);
        const firstElementId = keys[0];
        const targetElement = optionRefs[firstElementId];
        targetElement.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
      setShowErrors(true);
    }
  }, [
    isValid,
    editableQuestion,
    handleQuestionUpdate,
    formValues,
    handleQuestionCreate,
    errors.type,
    errors.label,
    errors.optionsList,
    optionRefs,
  ]);

  const onDragEnd = useCallback(
    ({ destination, source }: DropResult) => {
      if (!destination) return;
      const reorderedQuestions = reorder(
        formValues.options,
        source.index,
        destination.index,
      );
      setFormValues((prev) => ({ ...prev, options: reorderedQuestions }));
    },
    [formValues],
  );

  const addOption = useCallback(() => {
    const currentOptionsCounter = newOptionsCounter + 1;
    setNewOptionsCounter(currentOptionsCounter);
    setFormValues((prev) => ({
      ...prev,
      options: [
        ...prev.options,
        { id: String(currentOptionsCounter), label: '' },
      ],
    }));
  }, [newOptionsCounter]);

  useEffect(() => {
    if (
      newOptionsCounter > prevOptionsCounter &&
      optionRefs[newOptionsCounter]
    ) {
      setPrevOptionsCounter(newOptionsCounter);
      optionRefs[newOptionsCounter].current?.scrollIntoView({
        behavior: formValues.options.length >= 9 ? 'auto' : 'smooth',
        block: 'end',
      });
    }
  }, [
    newOptionsCounter,
    prevOptionsCounter,
    optionRefs,
    formValues?.options?.length,
  ]);

  useEffect(() => {
    if (formValues.type) setShowErrors(false);
  }, [formValues.type]);

  return (
    <Dialog
      title='Create Custom Question'
      contentRenderer={() => (
        <div
          data-testid='custom-question-modal'
          className={cn(classes.root, { [classes.rootExpanded]: withOptions })}>
          <div ref={topElementRef} />
          <Text>Select your question type:</Text>
          <TestId testId='question-type'>
            <CustomSelect
              className={classes.select}
              value={formValues.type}
              options={typeOptions}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, type: e.target.value }))
              }
              disabled={isCreating || !!editableQuestion}
              label='Select type*'
              error={showErrors && !!errors?.type}
            />
          </TestId>
          {formValues.type && (
            <>
              <Text>Fill question details:</Text>
              <TextField
                value={formValues.label}
                data-testid={'question-label'}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, label: e.target.value }))
                }
                label='Label*'
                disabled={isCreating}
                error={showErrors && !!errors?.label}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid={'question-mandatory'}
                    checked={formValues.isMandatory}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFormValues((prev) => ({
                        ...prev,
                        isMandatory: e.target.checked,
                      }))
                    }
                    color='primary'
                    disabled={isCreating}
                  />
                }
                label='Required'
              />
            </>
          )}
          {withOptions && (
            <div className={classes.optionsContainer}>
              <CustomOptionsList
                onDragEnd={onDragEnd}
                onRemove={(itemId) =>
                  setFormValues((prev) => ({
                    ...prev,
                    options: prev.options.filter((item) => item.id !== itemId),
                  }))
                }
                onChange={(itemId, label) => {
                  setFormValues((prev) => ({
                    ...prev,
                    options: prev.options.map((item) =>
                      item.id === itemId ? { ...item, label: label } : item,
                    ),
                  }));
                }}
                items={formValues.options}
                disabled={isCreating}
                showErrors={showErrors}
                errors={errors?.optionsList}
              />
            </div>
          )}
        </div>
      )}
      open
      setOpen={(v) => !v && handleModalClose()}
      actions={
        <Inline gap={15} className={classes.inline}>
          {withOptions && (
            <Button
              onClick={addOption}
              color='primary'
              startIcon={<AddIcon />}
              data-testid='add-option-button'
              disabled={isCreating || formValues.options.length >= 10}>
              Add Option
            </Button>
          )}
          <Inline gap={15}>
            <Button
              disabled={isCreating}
              onClick={handleSubmit}
              data-testid='apply-question-button'
              color={'primary'}>
              {isCreating ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
            <Button onClick={handleModalClose} variant='outlined'>
              Cancel
            </Button>
          </Inline>
        </Inline>
      }
    />
  );
}
