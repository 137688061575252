import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { TenantId } from '../api/tenants/Tenant';
import { Text } from '../components/common';
import UnsubscribeForm from '../components/forms/unsubscribe-form';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25,
  },
  block: {
    width: '300px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  logo: {
    width: 300,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
}));

function UnsubscribePage() {
  const classes = useStyles();
  const location = useLocation();

  const getDataParse = (value: string): TenantId | null => {
    const parseData = value.split('|');
    return (parseData[0] as TenantId) || null;
  };

  const tenantId = useMemo(() => {
    const currentKeyUrl = location.search.replace('?key=', '');
    return getDataParse(atob(currentKeyUrl));
  }, [location]);

  return (
    <div data-testid='unsubscribe-page-container' className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      {tenantId ? (
        <div className={classes.block}>
          <Text variant='normal'>
            We are sorry you decided to unsubscribe, but we will definitely
            remove your e-mail from all the distribution lists on our platform.
            Please confirm your choice by typing your full e-mail address in the
            box below and click on the "Confirm" button after that.
          </Text>
          <UnsubscribeForm tenantId={tenantId} />
        </div>
      ) : (
        <div className={classes.block}>
          <Text variant='normal'>
            This link is invalid. Please contact the administrator of your
            organization.
          </Text>
        </div>
      )}
    </div>
  );
}

export default UnsubscribePage;
