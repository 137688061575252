import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmButton, Text } from '.';
import { Specialization } from '../../api/specializations';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import CustomSpecializationForm, {
  Values as FormValues,
} from '../forms/custom-specialization';

interface CustomSpecializationProps {
  specialization: Specialization;
  updating?: boolean;
  deleting?: boolean;
  onEdit: (values: FormValues & { id: string }, callback: () => any) => any;
  onRemove: (values: FormValues, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
  isLast?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

function CustomSpecialization({
  specialization,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index,
  isLast,
}: CustomSpecializationProps) {
  const classes = useStyles();
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);

  const handleCustomSpecializationEdit = () => {
    setIsEditing(true);
  };

  const handleCustomSpecializationRemove = (_callback: () => any) => {
    const callback = () => {
      _callback();

      if (isLast) {
        history.push(Pages.TENANT_SETTINGS);
      }
    };

    onRemove(specialization, callback);
  };

  const handleCustomSpecializationEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitCustomSpecializationForm = (values: FormValues) => {
    onEdit(
      { id: specialization.id, ...values },
      handleCustomSpecializationEditCancel,
    );
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <CustomSpecializationForm
          title='Specialization editing'
          specialization={specialization}
          loading={updating}
          onSubmit={handleSubmitCustomSpecializationForm}
          onCancel={handleCustomSpecializationEditCancel}
        />
      ) : (
        <div
          className={classes.title}
          data-testid={`custom-specialization-${index}`}>
          <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
            {specialization.name}
          </Text>
          {!readOnly && (
            <div>
              <IconButton
                onClick={handleCustomSpecializationEdit}
                data-testid={`form-custom-specialization-edit-${index}`}>
                <EditIcon />
              </IconButton>
              <ConfirmButton
                loading={deleting}
                title={
                  isLast
                    ? 'Remove the Last Specialization?'
                    : 'Remove the Specialization?'
                }
                body={
                  isLast
                    ? 'Are you sure you want to remove the last custom specialization? This action will automatically disable custom specializations and revert to default specializations. Changes can’t be undone.'
                    : 'Sure you want to remove the specialization? Changes can’t be undone'
                }
                successProps={{
                  btnLabel: 'Remove',
                  onSuccess: handleCustomSpecializationRemove,
                }}
                buttonRenderer={({ onClick }) => (
                  <IconButton
                    onClick={onClick}
                    data-testid={`form-custom-specialization-delete-${index}`}>
                    <DeleteIcon />
                  </IconButton>
                )}></ConfirmButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomSpecialization;
