import { useState, useCallback, useEffect } from 'react';
import {
  makeStyles,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import { TextField, Text } from '../common';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import { Venture } from '../../api/ventures/types/Venture';
import { SelectVenture } from '../../api/ventures';
import MenuItem from '@material-ui/core/MenuItem';
import cn from 'classnames';
import Button from './button';
import Popover from '@material-ui/core/Popover';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles({
  buttonPopover: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    padding: '6px 12px 6px 18px',
    cursor: 'auto',

    '&:hover': {
      background: 'white',

      '&:not($buttonPopoverActive)': {
        borderColor: COLORS.COLOR_GRAY_LIGHTENED_20
      }
    }
  },
  buttonPopoverActive: {
    borderColor: COLORS.COLOR_BLUE_BASE
  },
  searchIconBase: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20
  },
  searchIconFocused: {
    color: COLORS.COLOR_BLUE_BASE
  },
  popover: {
    minWidth: 300,
    height: 300,
    padding: '10px 0',
    overflow: 'hidden'
  },
  btnLabel: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  searchBox: {
    padding: '0 10px 10px'
  },
  selectList: {
    maxHeight: 'calc(300px - 38px)',
    overflow: 'auto'
  },
  selectContent: {
    width: '100%',
    zIndex: 100,
    top: 55,
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden'
  },
  smallContent: {
    top: 38
  },
  searchIconOpen: {
    transform: 'rotate(180deg)'
  },
  menuItem: {
    '&:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45
    }
  },
  activeItem: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45
  }
});

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  value: any;
  onChange: (e: any) => any;
  isActive?: boolean;
  small?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

function SelectVenturePopover({
  className,
  value: initialValue,
  onChange: handleChange,
  isActive,
  small,
  required,
  readOnly = false
}: Props) {
  const classes = useStyles();
  const [selectVenture, setSelectVenture] = useState<SelectVenture | undefined>(
    initialValue
  );
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleVenturesSearch = useCallback(
    (searchedVentures: Venture[]) => {
      const unionSearchedVentures = searchedVentures.filter(
        (searchedVenture) => {
          return searchedVenture.id !== selectVenture?.id;
        }
      );

      setDisplayedVentures(unionSearchedVentures);
    },
    [selectVenture]
  );

  const open = Boolean(anchorEl);
  const isFocused = open || isActive;
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!initialValue) {
      setSelectVenture(undefined);
    }
  }, [initialValue]);

  return (
    <>
      <Button
        data-testid='session-button-venture-select'
        aria-describedby={id}
        variant='outlined'
        onClick={!readOnly ? handleClick : () => {}}
        className={cn(className, classes.buttonPopover, {
          [classes.buttonPopoverActive]: isFocused
        })}>
        <div className={classes.btnLabel}>
          <Text variant='normal'>
            {initialValue?.ventureName ||
              (!required ? 'Any venture' : 'Select venture')}
          </Text>
        </div>
        <InputAdornment position='end'>
          <ArrowDropDownIcon
            className={cn(classes.searchIconBase, {
              [classes.searchIconOpen]: open,
              [classes.searchIconFocused]: isFocused
            })}
          />
        </InputAdornment>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.popover}>
          <div className={classes.searchBox}>
            <VentureAutocomplete
              onSearch={handleVenturesSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  data-testid='session-input-venture-select'
                  placeholder='Enter venture name'
                  small={small}
                  onChange={(e) => {
                    setDisplayedVentures([]);
                    return onChange(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    )
                  }}
                />
              )}
            />
          </div>
          <div
            className={cn(classes.selectContent, {
              [classes.smallContent]: small
            })}>
            <ul className={classes.selectList}>
              {!required && (
                <MenuItem
                  key={`select-venture-any`}
                  data-testid='select-venture-any'
                  value='Any'
                  className={cn(classes.menuItem, {
                    [classes.activeItem]: !selectVenture
                  })}
                  onClick={() => {
                    setSelectVenture(undefined);
                    handleChange(null);
                    handleClose();
                    setDisplayedVentures([]);
                  }}>
                  <Text variant='normal2'>Any venture</Text>
                </MenuItem>
              )}

              {selectVenture && (
                <MenuItem
                  key={`select-venture-current`}
                  value={selectVenture.id}
                  className={cn(classes.menuItem, classes.activeItem)}
                  onClick={() => {
                    setDisplayedVentures([]);
                    handleClose();
                  }}>
                  <Text variant='normal2'>{selectVenture.ventureName}</Text>
                </MenuItem>
              )}

              {displayedVentures &&
                displayedVentures.length > 0 &&
                displayedVentures.map((venture: Venture) => {
                  return (
                    <MenuItem
                      data-testid='session-item-venture-select'
                      key={`select-venture-${venture.id}`}
                      className={classes.menuItem}
                      value={venture.ventureName}
                      onClick={() => {
                        setSelectVenture({
                          ventureName: venture.ventureName,
                          id: venture.id
                        });
                        handleChange({
                          ventureName: venture.ventureName,
                          id: venture.id
                        });
                        setDisplayedVentures([]);

                        handleClose();
                      }}>
                      <Text variant='normal2'>{venture.ventureName}</Text>
                    </MenuItem>
                  );
                })}
            </ul>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default SelectVenturePopover;
