import { TenantId } from '../../api/tenants/Tenant';
import { AppointmentId } from '../../api/Appointments/types/Appointment';
import { Email } from '../../utils/String/Email';

export function decodeKey(
  k: string,
): [TenantId, AppointmentId, Email] | undefined {
  try {
    const v = decodeURIComponent(atob(k)).split('|');

    return v.length === 3 ? (v as [TenantId, AppointmentId, Email]) : undefined;
  } catch {
    return undefined;
  }
}
