import { createContext, useState, useCallback, useContext } from 'react';
import educationAPI, { Education } from '../api/education';
import { Role } from '../api/user/Role';
import { UserContext } from './user-context';

export interface EducationContextProps {
  education?: Education;
  updateEducation: (mentor?: Education) => any;
  loadEducation: () => Promise<any>;
  isEducationLoading: boolean;
}

export const EducationContext = createContext<EducationContextProps>({
  updateEducation: () => {},
  loadEducation: async () => {},
  isEducationLoading: true,
});

interface EducationProviderProps {
  children: React.ReactNode;
}

export const EducationProvider = ({ children }: EducationProviderProps) => {
  const [education, setEducation] = useState<Education>();
  const [isEducationLoading, setIsEducationLoading] = useState(true);
  const { hasRole } = useContext(UserContext);

  const updateEducation = useCallback((mentor?: Education) => {
    setEducation(mentor || undefined);
  }, []);

  const loadEducation = useCallback(async () => {
    try {
      setIsEducationLoading(true);
      const loadedEducation = hasRole(Role.Founder)
        ? await educationAPI.getEducationForFounder()
        : await educationAPI.getEducation();
      if (loadedEducation.length > 0) {
        setEducation(loadedEducation[0]);
      }
      setIsEducationLoading(false);
    } catch (e: any) {
      setIsEducationLoading(false);
      console.log('error loadEducation', e);
    }
  }, [hasRole]);

  const contextValue: EducationContextProps = {
    education,
    updateEducation,
    loadEducation,
    isEducationLoading,
  };

  return (
    <EducationContext.Provider value={contextValue}>
      {children}
    </EducationContext.Provider>
  );
};
