import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { getActiveCohorts } from '../../api/Cohort';
import { CohortId } from '../../api/Cohort/types/Cohort';
import venturesAPI from '../../api/ventures';
import { VentureId } from '../../api/ventures/types/Venture';
import { SnackMessage } from '../../components/common';
import VentureDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/venture-details';
import { VentureContext } from '../../contexts/venture-context';
import { massAsyncRequest } from '../../utils/api';

function VentureDetailsPage() {
  const { ventureId } = useParams<{ ventureId: VentureId }>();
  const location = useLocation();
  const {
    venture,
    ventureSpecializations,
    ventureTags: tags,
    updateVenture,
    updateSpecializations,
    updateVentureTags,
  } = useContext(VentureContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const isExternalPage = useMemo(
    () => location.pathname.includes('external'),
    [location],
  );
  const [cohorts, setCohorts] = useState<
    Array<{ value: CohortId; label: string }>
  >([]);

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { values, specializations, ventureTags } = parsedValues;
        setUpdating(true);
        const updatedVenture = await venturesAPI.update(ventureId, {
          ...venture,
          ...values,
          venturesAdditionalInfo: venture?.venturesAdditionalInfo,
        });

        const loadedSpecializationsIds = ventureSpecializations || [];
        const addedSpecializationsIds = specializations.filter(
          (specializationId) =>
            !loadedSpecializationsIds.includes(specializationId),
        );
        const removedSpecializationsIds = loadedSpecializationsIds.filter(
          (specializationId) => !specializations.includes(specializationId),
        );

        const loadedVentureTagsIds = tags || [];
        const addedVentureTagsIds = ventureTags.filter(
          (ventureTagId) => !loadedVentureTagsIds.includes(ventureTagId),
        );
        const removedVentureTagsIds = loadedVentureTagsIds.filter(
          (ventureTagId) => !ventureTags.includes(ventureTagId),
        );

        const addSpecializationsRequests = addedSpecializationsIds.map(
          (specializationId) => () =>
            venturesAPI.setVentureSpecialization(ventureId, specializationId),
        );
        const removeSpecializationsRequests = removedSpecializationsIds.map(
          (specializationId) => () =>
            venturesAPI.deleteVentureSpecialization(
              ventureId,
              specializationId,
            ),
        );
        const addVentureTagsRequests = addedVentureTagsIds.map(
          (ventureTagId) => () =>
            venturesAPI.setVentureTags(ventureId, ventureTagId),
        );
        const removeVentureTagsRequests = removedVentureTagsIds.map(
          (ventureTagId) => () =>
            venturesAPI.deleteVentureTags(ventureId, ventureTagId),
        );
        await Promise.all([
          massAsyncRequest(addSpecializationsRequests),
          massAsyncRequest(removeSpecializationsRequests),
          massAsyncRequest(addVentureTagsRequests),
          massAsyncRequest(removeVentureTagsRequests),
        ]);
        setUpdating(false);
        updateVenture(updatedVenture);
        updateSpecializations(specializations);
        updateVentureTags(ventureTags);
        enqueueSnackbar('The venture was successfully updated and saved.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the venture. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdating(false);
      }
    },
    [
      ventureId,
      venture,
      ventureSpecializations,
      tags,
      updateVenture,
      updateSpecializations,
      updateVentureTags,
      enqueueSnackbar,
    ],
  );
  useEffect(() => {
    getActiveCohorts().then((cohorts) =>
      setCohorts(cohorts.map((c) => ({ value: c.id, label: c.name }))),
    );
  }, []);

  return (
    <div>
      <VentureDetailsForm
        cohorts={cohorts}
        ventureDetails={venture}
        ventureSpecializations={ventureSpecializations}
        ventureTags={tags}
        loading={isUpdating}
        onSubmit={handleSubmitForm}
        isExternal={isExternalPage}
      />
    </div>
  );
}

export default VentureDetailsPage;
