import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { SnackbarProvider as Provider, useSnackbar } from 'notistack';
import { COLORS } from './variables';

interface SnackbarProviderProps {
  children: React.ReactNode;
  closeSnackbar?: any;
}

const useStyles = makeStyles((theme) => ({
  toast: {
    maxWidth: '100vw',
    fontSize: 13,
    lineHeight: '20px',
    background: 'white !important',
    padding: '16px 26px',
    color: `${COLORS.COLOR_TEXT_BASE} !important`,
    borderRadius: 8,
    boxShadow:
      '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 32px rgba(34, 91, 187, 0.15)',
    whiteSpace: 'normal',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '75vw',
      whiteSpace: 'pre',
    },

    '& .MuiSvgIcon-root': {
      marginRight: 18,
    },
    '& > #notistack-snackbar': {
      alignItems: 'start !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
  successIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE,
  },
  infoIcon: {
    colors: COLORS.COLOR_BLUE_BASE,
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,

    '& svg': {
      fontSize: 17,
    },

    '& .MuiSvgIcon-root': {
      marginRight: 0,
    },
  },
}));

type Id = string | number | undefined;

const CloseButton = ({ id }: { id: Id }) => {
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <IconButton
      onClick={() => closeSnackbar(id)}
      className={classes.closeButton}
      aria-label='close'
      data-testid='toast-close'>
      <CloseIcon />
    </IconButton>
  );
};

function SnackbarProvider({ children }: SnackbarProviderProps) {
  const classes = useStyles();

  return (
    <Provider
      action={(key) => <CloseButton id={key} />}
      classes={{
        variantInfo: classes.toast,
        variantWarning: classes.toast,
        variantSuccess: classes.toast,
        variantError: classes.toast,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid='toast'
      iconVariant={{
        error: (
          <InfoIcon className={classes.errorIcon} data-testid='toast-error' />
        ),
        success: (
          <CheckCircleIcon
            className={classes.successIcon}
            data-testid='toast-success'
          />
        ),
        warning: (
          <WarningIcon
            className={classes.warningIcon}
            data-testid='toast-warning'
          />
        ),
        info: (
          <InfoIcon className={classes.infoIcon} data-testid='toast-info' />
        ),
      }}>
      {children}
    </Provider>
  );
}

export default SnackbarProvider;
