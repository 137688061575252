import { useSnackbar } from 'notistack';
import { useCallback, useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { FounderId } from '../../api/founders';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { SnackMessage } from '../../components/common';
import { AssignFounders } from '../../components/forms/invite-gathering-founders';
import GatheringFoundersManually from '../../components/gathering/gathering-founders-manually';
import GatheringModal, {
  RadioValue,
} from '../../components/gathering/gathering-modal';
import { GatheringContext } from '../../contexts/gathering-context';
import { massAsyncRequest } from '../../utils/api';

const GatheringFoundersPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { loadGathering, gathering } = useContext(GatheringContext);
  const { enqueueSnackbar } = useSnackbar();
  const { gatheringId } = useParams<{ gatheringId: Gathering['id'] }>();
  const [openModalAssing, setOpenModalAssing] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const intermediateAssingValue = useRef<{ value: AssignFounders }>();
  const intermediateRemoveValue =
    useRef<{ founderIds: FounderId[]; cb: () => any }>();

  const handleAssingFounders = useCallback(
    async (type: RadioValue) => {
      if (!intermediateAssingValue.current || !gathering) {
        return;
      }
      const { value } = intermediateAssingValue.current;
      const { assigned, removed, isAll } = value;

      try {
        setLoading(true);

        if (isAll) {
          if (type === 'all') {
            await gatheringsAPI.assignsAllFoundersGroup(gathering.groupId);
          } else {
            await gatheringsAPI.assignsAllFounders(gatheringId);
          }
        } else {
          let assignedRequests = [];
          let removedRequests = [];
          if (type === 'all') {
            assignedRequests = assigned.map(
              (founderId) => () =>
                gatheringsAPI.assignsFounderGroup(gathering.groupId, founderId),
            );
            removedRequests = removed.map(
              (founderId) => () =>
                gatheringsAPI.removeFounderAssignmentGroup(
                  gathering.groupId,
                  founderId,
                ),
            );
          } else {
            assignedRequests = assigned.map(
              (founderId) => () =>
                gatheringsAPI.assignsFounder(gatheringId, founderId),
            );
            removedRequests = removed.map(
              (founderId) => () =>
                gatheringsAPI.removeFounderAssignment(gatheringId, founderId),
            );
          }

          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
        }

        await loadGathering(gatheringId);
        setOpenModalAssing(false);
        setLoading(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while assign the founders. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setOpenModalAssing(false);
        setLoading(false);
      }
    },
    [enqueueSnackbar, gathering, gatheringId, loadGathering],
  );

  const handleRemoveFounders = useCallback(
    async (type: RadioValue) => {
      if (!intermediateRemoveValue.current || !gathering) {
        return;
      }
      const { founderIds, cb } = intermediateRemoveValue.current;

      try {
        setLoading(true);

        let removedRequests = [];
        if (type === 'all') {
          removedRequests = founderIds.map(
            (founderId) => () =>
              gatheringsAPI.removeFounderAssignmentGroup(
                gathering.groupId,
                founderId,
              ),
          );
        } else {
          removedRequests = founderIds.map(
            (founderId) => () =>
              gatheringsAPI.removeFounderAssignment(gatheringId, founderId),
          );
        }

        await massAsyncRequest(removedRequests);
        await loadGathering(gatheringId);
        await cb();
        history.replace(location.pathname);
        setOpenModalRemove(false);
        setLoading(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          `An error occurred while remove the assignment${
            founderIds.length > 1 ? 's' : ''
          }. Please, try again.`,
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setOpenModalRemove(false);
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      gathering,
      gatheringId,
      history,
      loadGathering,
      location.pathname,
    ],
  );

  const handleOpenModalAssing = useCallback(
    (value: AssignFounders, handleModalClose: () => any) => {
      handleModalClose();
      intermediateAssingValue.current = { value };
      if (!gathering?.periodString) {
        return handleAssingFounders('all');
      }
      setOpenModalAssing(true);
    },
    [gathering, handleAssingFounders],
  );

  const handleOpenModalRemove = useCallback(
    (founderIds: FounderId[], cb: () => any) => {
      intermediateRemoveValue.current = { founderIds, cb };
      if (!gathering?.periodString) {
        return handleRemoveFounders('all');
      }
      setOpenModalRemove(true);
    },
    [gathering, handleRemoveFounders],
  );

  return (
    <>
      <GatheringFoundersManually
        loading={loading}
        handleAssingFounders={handleOpenModalAssing}
        handleRemoveFounders={handleOpenModalRemove}
      />
      <GatheringModal
        loading={loading}
        openModal={openModalAssing}
        setOpenModal={setOpenModalAssing}
        handleSubmit={handleAssingFounders}
      />
      <GatheringModal
        loading={loading}
        openModal={openModalRemove}
        setOpenModal={setOpenModalRemove}
        handleSubmit={handleRemoveFounders}
      />
    </>
  );
};

export default GatheringFoundersPage;
