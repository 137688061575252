import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { AdvisorId } from '../Advisors/types/Advisor';
import { axiosRequest } from '../instance';
import { TenantId } from '../tenants/Tenant';
import {
  Appointment,
  AppointmentId,
  Create,
  CreateAppointment,
  CreatePrivate,
  AppointmentLog,
} from './types/Appointment';
import { FilterStatus } from './types/FilterStatus';
import { Status } from './types/Status';
import * as Survey from './types/Survey';
import { Timeslot } from './types/Timeslot';

const dateToStr = (d: Date): string => format(d, 'MMddyyyy');

export function get(date: Date, page: number): Promise<Appointment[]> {
  return axiosRequest
    .get<Appointment[]>({
      url: `/officehours/dates/${dateToStr(date)}/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e?.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByRange(
  startDate: Date,
  endDate: Date,
  page: number,
): Promise<Appointment[]> {
  return axiosRequest
    .get<Appointment[]>({
      url: `/officehours/startdate/${dateToStr(startDate)}/enddate/${dateToStr(
        endDate,
      )}/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e?.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getById(id: AppointmentId): Promise<Appointment> {
  return axiosRequest
    .get<Appointment>({
      url: `/officehours/${id}`,
      credentials: true,
    })
    .then((i) => i.data);
}
export function cancel(id: AppointmentId): Promise<Appointment> {
  return axiosRequest
    .delete<Appointment>({
      url: `/officehours/${id}`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function getByStatus(
  status: FilterStatus,
  date: Date,
  page: number,
): Promise<Appointment[]> {
  const dateStr = dateToStr(date);
  return axiosRequest
    .get<Appointment[]>({
      url: `/officehours/statuses/${status}/dates/${dateStr}/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e?.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByStatusAndRange(
  status: FilterStatus,
  startDate: Date,
  endDate: Date,
  page: number,
): Promise<Appointment[]> {
  return axiosRequest
    .get<Appointment[]>({
      url: `/officehours/statuses/${status}/startdate/${dateToStr(
        startDate,
      )}/enddate/${dateToStr(endDate)}/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e?.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function remove(id: AppointmentId): Promise<Appointment> {
  return axiosRequest
    .delete<Appointment>({ url: `/officehours/${id}`, credentials: true })
    .then((i) => i.data);
}

export function updateStatus(
  data: AppointmentId[],
  status: Status,
): Promise<Appointment[]> {
  return axiosRequest
    .post<Appointment[]>({
      url: `/officehours/bulk-update/statuses/${status}`,
      data,
      credentials: true,
    })
    .then((i) => i.data);
}

export function create(tenantId: TenantId, c: Create): Promise<Appointment> {
  return axiosRequest
    .post<Appointment>({
      url: `/public/tenants/${tenantId}/officehours/submissions`,
      data: c,
    })
    .then((i) => i.data);
}

export function createPrivate(c: CreatePrivate): Promise<Appointment> {
  return axiosRequest
    .post<Appointment>({
      url: `/officehours/submissions`,
      data: c,
      credentials: true,
    })
    .then((i) => i.data);
}

export function getTimeslots(
  tenantId: TenantId,
  advisorId: AdvisorId,
): Promise<Timeslot[]> {
  return axiosRequest
    .get<Timeslot[]>({
      url: `/public/tenants/${tenantId}/officehours/advisors/${advisorId}/timeslots`,
    })
    .then((d) => d.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function createSurvey(data: Survey.Create): Promise<void> {
  return axiosRequest
    .post<void>({
      url: `/public/tenants/${data.tenantId}/officehours/ratings`,
      data,
    })
    .then((i) => i.data);
}

export function getPublicById(
  tenantId: TenantId,
  id: AppointmentId,
): Promise<Appointment> {
  return axiosRequest
    .get<Appointment>({
      url: `/public/tenants/${tenantId}/officehours/${id}`,
    })
    .then((d) => d.data);
}

export function successPayment(responseId: string): Promise<Appointment> {
  return axiosRequest
    .post<Appointment>({
      url: `/public/officehours/payments/${responseId}`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function cancelPayment(responseId: string): Promise<Appointment> {
  return axiosRequest
    .delete<Appointment>({
      url: `/public/officehours/payments/${responseId}`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function cancelAppointment(officeHoursId: string): Promise<Appointment> {
  return axiosRequest
    .delete<Appointment>({
      url: `/public/officehours/${officeHoursId}`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function createAppointment(
  data: CreateAppointment,
): Promise<Appointment> {
  return axiosRequest
    .post<Appointment>({
      url: `/officehours`,
      data,
      credentials: true,
    })
    .then((i) => i.data);
}

export function updateAppointment(data: Appointment): Promise<Appointment> {
  return axiosRequest
    .put<Appointment>({
      url: `/officehours`,
      data,
      credentials: true,
    })
    .then((i) => i.data);
}

export function sendInvites(appointmentId: AppointmentId) {
  return axiosRequest
    .post<Appointment>({
      url: `/officehours/${appointmentId}/invites`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function addMeetingRoom(appointmentId: AppointmentId) {
  return axiosRequest
    .post<string>({
      url: `/officehours/${appointmentId}/meeting-room`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function createPaymentIntent(appointmentId: AppointmentId) {
  return axiosRequest
    .post<string>({
      url: `/officehours/${appointmentId}/payment-intent`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function refundPayment(appointmentId: AppointmentId) {
  return axiosRequest
    .post<boolean>({
      url: `/officehours/${appointmentId}/payment-intent/refund`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function confirmPayment(appointmentId: AppointmentId) {
  return axiosRequest
    .post<Appointment>({
      url: `/officehours/${appointmentId}/payment-intent/confirmation`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function getLogs(appointmentId: AppointmentId) {
  return axiosRequest
    .get<AppointmentLog[]>({
      url: `/officehours/${appointmentId}/logs`,
      credentials: true,
    })
    .then((i) => i.data);
}
