import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Listing } from '../../components/Pages/Listing';
import { PageLoader, RouteACL } from '../../components/common';
import BaseLayout from '../../components/layout/base-layout';
import {
  EducationContext,
  EducationProvider,
} from '../../contexts/eduation-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import EducationList from './education-list-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
});

function EducationPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { loadEducation, isEducationLoading } = useContext(EducationContext);

  useEffect(() => {
    loadEducation();
  }, [loadEducation]);

  return (
    <BaseLayout user={user} fullHeight>
      <Listing title='Education'>
        <Paper className={classes.container} elevation={1}>
          {isEducationLoading ? (
            <PageLoader />
          ) : (
            <div className={classes.content}>
              <Switch>
                <RouteACL
                  exact
                  path={Pages.EDUCATION_LIST}
                  component={EducationList}
                  redirectUrl={Pages.DASHBOARD}
                />
                <Redirect to={Pages.DASHBOARD} />
              </Switch>
            </div>
          )}
        </Paper>
      </Listing>
    </BaseLayout>
  );
}

function withEducationProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <EducationProvider>
      <Component {...props} />
    </EducationProvider>
  );
}

export default withEducationProvider(EducationPage);
