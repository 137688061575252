export const UPCOMING_EVENTS: any[] = [
  {
    id: '614eb22c-b3e1-4c7c-a969-873d685d492c',
    eventNotes: [],
    ventureId: '4b9bfc78-1db5-4917-b747-f4342217ffb2',
    ventureName: 'at105',
    tenantId: '2a41c4cf-ffe8-419b-ae4f-8f9c790a1267',
    start: '2021-06-17T06:00:00',
    end: '2021-06-17T07:30:00',
    status: 'CREATED',
    followupActions: '',
    followupReminder: '',
    summary: 'at105 Mentoring Session',
    description: '',
    emailList: '',
    tenantEventChannelId: '78ecc5b0-cc23-42cd-81ca-d173dde0f575',
    valid: true
  },
  {
    id: 'bb8b8d10-229e-4cb4-bb71-35f794cdb69a',
    eventNotes: [],
    ventureId: 'e297ee8d-3a06-429d-a9c5-a282c86c5215',
    ventureName: 'cause105',
    tenantId: '2a41c4cf-ffe8-419b-ae4f-8f9c790a1267',
    start: '2021-06-18T00:00:00',
    end: '2021-06-20T01:30:00',
    status: 'CREATED',
    followupActions: '',
    followupReminder: '',
    summary: 'test-venture Mentoring Session',
    description: '',
    emailList: '',
    tenantEventChannelId: 'd04791e9-fcb8-40e5-b25a-4772423bb001',
    valid: true
  },
  {
    id: '88169d86-abfb-4b12-84ea-7bb9a3069517',
    eventNotes: [
      {
        id: '34c3a3b0-4f73-4ac6-b905-bd115010252b',
        eventId: '88169d86-abfb-4b12-84ea-7bb9a3069517',
        creationDate: '2021-06-15T09:51:52.947',
        isPrimaryMentor: false,
        attachmentRefs: '',
        contents: 'Test venture'
      }
    ],
    ventureId: 'd3c126c5-adcf-4d8c-ab71-842cdec5980f',
    ventureName: 'naturally155',
    tenantId: '2a41c4cf-ffe8-419b-ae4f-8f9c790a1267',
    start: '2021-06-19T13:39:00',
    end: '2021-06-19T15:09:00',
    status: 'CREATED',
    followupActions: '',
    followupReminder: '',
    summary: 'naturally155 Mentoring Session',
    description: '',
    emailList: '',
    tenantEventChannelId: '78ecc5b0-cc23-42cd-81ca-d173dde0f575',
    valid: true
  }
];
