import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { COLORS } from '../../theme/variables';
import { isPassword } from '../../utils/String/Password';
import {
  checkCapitalCharacters,
  checkNumber,
  checkSpecialSymbols,
} from '../../utils/functions';
import {
  AlertDialog,
  AlertState,
  Button,
  FormButtons,
  FormGroup,
  Text,
} from '../common';
import { TextFieldWrapper } from './wrappers';

export interface Values {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface ValidPasswordValue {
  icon: boolean;
  label: string;
}

interface ValidPassword {
  isMinimalLength: ValidPasswordValue;
  isSpecialSymbol: ValidPasswordValue;
  isNumbers: ValidPasswordValue;
  isCapitalCharacters: ValidPasswordValue;
}

type Errors = {
  [K in keyof Values]?: string;
};

interface LoginFormProps {
  loading?: boolean;
  error?: string;
  onSubmit: (values: Values) => Promise<void>;
  email: string;
}

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  needHelpLink: {
    flexGrow: 1,
  },
  popper: {
    background: 'white',
    minWidth: 280,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:
      '0px 0px 2px rgb(9 29 61 / 25%), 0px 8px 16px rgb(34 91 187 / 8%), 0px 8px 24px rgb(51 126 255 / 8%)',
    borderRadius: 5,
    padding: '5px 10px',
    marginTop: 7,
  },
  popperItems: {
    width: '100%',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
    marginRight: 7,
    fontSize: 20,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
    marginRight: 7,
    fontSize: 20,
  },
  popperContainer: {
    zIndex: 2,
  },
});

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'Required';
  }
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Invalid';
  }
  if (!isPassword(values.password)) {
    errors.password = 'Invalid';
  }
  if (!isPassword(values.passwordConfirm)) {
    errors.passwordConfirm = 'Invalid';
  }
  return errors;
};

function RegistrationForm({
  loading = false,
  error,
  onSubmit,
  email,
}: LoginFormProps) {
  const classes = useStyles();
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [isShownPasswordConfirm, setIsShownPasswordConfirm] = useState(false);
  const [password, setPassword] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const validNewPassword: ValidPassword = useMemo(() => {
    const valid = {
      isMinimalLength: { icon: false, label: 'Minimal length 8 symbols' },
      isSpecialSymbol: { icon: false, label: 'Special Symbols' },
      isNumbers: { icon: false, label: 'Numbers' },
      isCapitalCharacters: { icon: false, label: 'Capital characters' },
    };

    if (checkNumber(password)) {
      valid.isNumbers.icon = true;
    }
    if (checkSpecialSymbols(password)) {
      valid.isSpecialSymbol.icon = true;
    }
    if (password.length > 8) {
      valid.isMinimalLength.icon = true;
    }
    if (checkCapitalCharacters(password)) {
      valid.isCapitalCharacters.icon = true;
    }
    return valid;
  }, [password]);

  const isSubmitDisabled = loading;

  const initialValues: Values = {
    email: email,
    password: '',
    passwordConfirm: '',
  };

  const handleChangePassword = (e: any) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleFocus = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleBlur = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (values: Values) => {
    onSubmit(values);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validateForm}
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          {!!error && <AlertState type='error'>{error}</AlertState>}
          <FormGroup>
            <Field<string>
              testid='login-form-email'
              name='email'
              type='email'
              component={TextFieldWrapper}
              label='Email'
              InputProps={{
                inputProps: {
                  readOnly: true,
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <div>
              <Field<string>
                testid='login-form-password'
                name='password'
                type={isShownPassword ? 'text' : 'password'}
                label='Password'
                component={TextFieldWrapper}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChangePassword}
                aria-describedby={id}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => setIsShownPassword(!isShownPassword)}>
                        {isShownPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Popper
                id={id}
                className={classes.popperContainer}
                anchorEl={anchorEl}
                open={open}
                disablePortal={false}
                placement='bottom-start'>
                <div className={classes.popper}>
                  {Object.values(validNewPassword).map(
                    (value: ValidPasswordValue) => {
                      return (
                        <div className={classes.popperItems}>
                          {value.icon ? (
                            <CheckCircleIcon className={classes.checkIcon} />
                          ) : (
                            <CancelIcon className={classes.errorIcon} />
                          )}
                          <Text variant='normal'>{value.label} </Text>
                        </div>
                      );
                    },
                  )}
                </div>
              </Popper>
            </div>
          </FormGroup>
          <FormGroup>
            <Field<string>
              testid='login-form-password-confirm'
              name='passwordConfirm'
              type={isShownPasswordConfirm ? 'text' : 'password'}
              label='Repeat Password'
              component={TextFieldWrapper}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() =>
                        setIsShownPasswordConfirm(!isShownPasswordConfirm)
                      }>
                      {isShownPasswordConfirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>
          <FormButtons>
            <div className={classes.needHelpLink}>
              <AlertDialog
                buttonRenderer={({ onClick }) => (
                  <Link
                    href='#'
                    variant='caption'
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault();
                      onClick();
                    }}>
                    Need help?
                  </Link>
                )}>
                If you experience problems while logging into to Management
                Console or you need login credentials, please forward your
                request to support@tractionfive.com and describe the problem. We
                will get back to you shortly.
              </AlertDialog>
            </div>

            <Button
              data-testid='login-form-submit'
              type='submit'
              disabled={isSubmitDisabled}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Register'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default RegistrationForm;
