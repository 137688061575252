import {
  Calendar,
  dateFnsLocalizer,
  EventWrapperProps,
  View,
} from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { Event } from '../../api/events';
import { UserContext } from '../../contexts/user-context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EventChannel } from '../../api/auth';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import { Text } from '../common';
import { getRoutePath, Pages } from '../../router/constants';
import { Link, useLocation } from 'react-router-dom';
import {
  dateFallsInInterval,
  formatTimeInterval,
  getDifferenceInDays,
} from '../../utils/date';

interface CalendarViewProps {
  events: Event[];
  lastInterval: {
    start: Date;
    end: Date;
  };
  onRangeChange: (dates: { startDate: Date; endDate?: Date }) => any;
  onInit: (dates: { start: Date; end: Date }) => any;
}

interface CalendarEventType {
  title: string;
  start: Date;
  end: Date;
  resource: {
    id: string;
    status: string;
    channel?: EventChannel;
    channelIndex?: number;
    startString: string;
    endString: string;
  };
}

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const getCalendarEvents = (
  events: Event[],
  channels?: EventChannel[],
): CalendarEventType[] => {
  return events.map((event) => {
    const channel = (channels || []).find(
      (channel) => channel.id === event.tenantEventChannelId,
    );

    return {
      title: event.summary,
      start: new Date(event.start),
      end: new Date(event.end),
      resource: {
        id: event.id,
        status: event.status,
        startString: event.start,
        endString: event.end,
        channel,
        channelIndex:
          channel && channels?.findIndex((el) => el.id === channel.id),
      },
    };
  });
};

const getEventVariant = (channelIndex: number) => {
  const ordinalChanel = channelIndex >= 0 ? channelIndex + 1 : -1;
  const numberColor = ordinalChanel > 5 ? ordinalChanel % 5 : ordinalChanel;

  switch (numberColor) {
    case 1:
      return 'success';
    case 2:
      return 'primary';
    case 3:
      return 'warning';
    case 4:
      return 'default';
    case 5:
      return 'error';
    default:
      return 'default';
  }
};

const useStyles = makeStyles({
  container: {
    padding: '12px 16px 16px 16px',
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    position: 'relative',
    zIndex: 1,

    '& *': {
      fontFamily: INTER_FONT_FAMILY,
    },

    '& .rbc-toolbar': {
      '& .rbc-toolbar-label': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
      },

      '& .rbc-btn-group': {
        display: 'flex',

        '& button': {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          display: 'flex',
          alignItems: 'center',
          color: COLORS.COLOR_TEXT_LIGHTENED_10,
          cursor: 'pointer',
          borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
          background: 'white',

          '&:hover': {
            background: COLORS.COLOR_GRAY_LIGHTENED_50,
          },

          '&.rbc-active': {
            position: 'relative',
            color: COLORS.COLOR_GREEN_BASE,
            boxShadow: 'none',
            background: COLORS.COLOR_GREEN_LIGHTENED_45,
            borderColor: COLORS.COLOR_GREEN_LIGHTENED_10,
          },
        },
      },
    },

    '&$longInterval': {
      '& .rbc-month-row': {
        minHeight: '15.8024692%',
      },
    },

    '& .rbc-month-view': {
      display: 'block',
      overflow: 'auto',
    },

    '& .rbc-month-row': {
      minHeight: '18.962963%',
      overflow: 'unset',
      height: 'auto',
    },

    '& .rbc-off-range-bg': {
      background: COLORS.COLOR_GRAY_LIGHTENED_50,
    },

    '& .rbc-today': {
      background: COLORS.COLOR_ADDITIONAL_GENERAL_BACKGROUND,
    },

    '& .rbc-header': {
      padding: 5,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      minHeight: 35,
    },

    '& .rbc-date-cell > a': {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },

    '& .rbc-time-gutter': {
      '& .rbc-time-slot:empty': {
        display: 'none',
      },
    },

    '& .rbc-time-slot': {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    '& .rbc-date-cell a, & .rbc-header a': {
      pointerEvents: 'none',
    },

    '& .rbc-row-segment a': {
      position: 'static',
    },
  },
  event: {
    width: '100%',
    maxWidth: '100%',
    position: 'absolute',
    overflow: 'hidden',
    maxHeight: '100%',
    minHeight: '20px',
    boxSizing: 'border-box',
    padding: 5,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',

    '&$day': {
      padding: '0 5px',
    },

    '&$week': {
      padding: '0 5px',

      '&:hover': {
        zIndex: 1,
        height: 'fit-content !important',
      },
    },
  },
  longInterval: {},
  eventText: {},
  week: {},
  day: {},
  static: {
    position: 'static',
  },
  default: {
    background: 'rgb(240 241 244)',
    border: '1px solid rgb(220 220 220)',
  },
  success: {
    background: 'rgb(235 246 244)',
    border: '1px solid rgb(191 232 225)',
  },
  warning: {
    background: 'rgb(255 242 233)',
    border: '1px solid rgb(255 215 187)',
  },
  error: {
    background: 'rgb(255 235 238)',
    border: '1px solid rgb(255 208 221)',
  },
  primary: {
    background: 'rgb(233, 241, 255)',
    border: '1px solid rgb(201, 218, 249)',
  },
});

function CalendarEvent({
  event,
  style,
  viewMode,
}: EventWrapperProps<CalendarEventType> & { viewMode: View }) {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const [isHovered, setIsHovered] = useState(false);
  const channelIndex = event.resource?.channelIndex;

  const normalizedStyles = {
    left: `${style?.xOffset}%`,
    top: `${style?.top}%`,
    width: `${style?.width}%`,
    ...(isHovered
      ? { minHeight: `${style?.height}%` }
      : { height: `${style?.height}%` }),
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to={{
        pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
          sessionId: event.resource.id,
        }),
        state: {
          prevPath: currentPath,
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        classes.event,
        classes[
          getEventVariant(channelIndex !== undefined ? channelIndex : -1)
        ],
        {
          [classes.static]: viewMode === 'month',
          [classes.week]: viewMode === 'week',
          [classes.day]: viewMode === 'day',
        },
      )}
      style={normalizedStyles}>
      {viewMode === 'month' && (
        <Text variant='upper1'>
          {formatTimeInterval(
            new Date(event.resource.startString),
            new Date(event.resource.endString),
          )}
        </Text>
      )}
      <Text className={classes.eventText} variant='normal2'>
        {event.title} [{event.resource.channel?.channelName}]
      </Text>
    </Link>
  );
}

function parseLastInterval(lastInterval: { start: Date; end: Date }): {
  date: Date;
  view: View;
} {
  const differencesInDays = getDifferenceInDays(
    lastInterval.start,
    lastInterval.end,
  );

  if (differencesInDays > 6) {
    const selectedDay = new Date(lastInterval.start);
    selectedDay.setDate(
      selectedDay.getDate() + Math.round(differencesInDays / 2),
    );
    selectedDay.setDate(1);
    const currentDay = dateFallsInInterval(
      new Date(),
      selectedDay,
      lastInterval.end,
    )
      ? new Date()
      : selectedDay;
    return {
      date: currentDay,
      view: 'month',
    };
  } else if (differencesInDays === 6) {
    const currentDay = dateFallsInInterval(
      new Date(),
      lastInterval.start,
      lastInterval.end,
    )
      ? new Date()
      : lastInterval.start;
    return {
      date: currentDay,
      view: 'week',
    };
  } else if (differencesInDays === 1) {
    return {
      date: lastInterval.start,
      view: 'day',
    };
  }
  return {
    date: new Date(),
    view: 'day',
  };
}

function CalendarView({
  events,
  lastInterval,
  onInit,
  onRangeChange,
}: CalendarViewProps) {
  const initialInterval = parseLastInterval(lastInterval);
  const { channels } = useContext(UserContext);
  const currentIntervalCount = useMemo(() => {
    return getDifferenceInDays(lastInterval.start, lastInterval.end);
  }, [lastInterval]);
  const [viewMode, setViewMode] = useState<View>(initialInterval.view);
  const classes = useStyles();

  const eventList = getCalendarEvents(events, channels);

  const handleView = (state: View) => {
    setViewMode(state);
  };

  const handleRangeChange = (
    interval: Date[] | { start: Date | string; end: Date | string },
  ) => {
    if (Array.isArray(interval) && interval.length === 1) {
      onRangeChange({ startDate: (interval as Date[])[0] });
    } else if (Array.isArray(interval) && interval.length === 7) {
      const arrayInterval = interval as Date[];
      onRangeChange({
        startDate: arrayInterval[0],
        endDate: arrayInterval[arrayInterval.length - 1],
      });
    } else {
      onRangeChange({
        startDate: (interval as { start: Date; end: Date }).start,
        endDate: (interval as { start: Date; end: Date }).end,
      });
    }
  };

  useEffect(() => {
    onInit(lastInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(classes.container, {
        [classes.longInterval]: currentIntervalCount === 42,
      })}>
      <Calendar
        localizer={localizer}
        events={eventList}
        date={initialInterval.date}
        startAccessor='start'
        endAccessor='end'
        onView={handleView}
        defaultView={viewMode}
        views={['month', 'week', 'day']}
        style={{ height: 723 }}
        onRangeChange={handleRangeChange}
        components={{
          eventWrapper: (props) => (
            <CalendarEvent {...props} viewMode={viewMode} />
          ),
        }}
      />
    </div>
  );
}
export default CalendarView;
