import { Paper } from '@material-ui/core';
import { ReactNode } from 'react';
import classes from './index.module.scss';

export interface TableWrapperProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

export function TableWrapper({ children, header, footer }: TableWrapperProps) {
  return (
    <Paper className={classes.root}>
      {header && <div className={classes.header}>{header}</div>}
      <div className={classes.content}>{children}</div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Paper>
  );
}
