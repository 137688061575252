import {
  makeStyles,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { Mentor, SelectMentor } from '../../api/mentors';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import MentorAutocomplete from '../autocomplete/mentor-autocomplete';
import { TextField, Text } from '../common';
import Button from './button';

const useStyles = makeStyles({
  buttonPopover: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    padding: '6px 12px 6px 18px',
    cursor: 'auto',

    '&:hover': {
      background: 'white',

      '&:not($buttonPopoverActive)': {
        borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },
  },
  buttonPopoverActive: {
    borderColor: COLORS.COLOR_BLUE_BASE,
  },
  searchIconBase: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
  },
  searchIconFocused: {
    color: COLORS.COLOR_BLUE_BASE,
  },
  popover: {
    minWidth: 300,
    height: 300,
    padding: '10px 0',
    overflow: 'hidden',
  },
  btnLabel: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  searchBox: {
    padding: '0 10px 10px',
  },
  selectList: {
    maxHeight: 'calc(300px - 38px)',
    overflow: 'auto',
  },
  selectContent: {
    width: '100%',
    zIndex: 100,
    top: 55,
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
  },
  smallContent: {
    top: 38,
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  menuItem: {
    '&:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45,
    },
  },
  activeItem: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,
  },
});

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  value: any;
  onChange: (e: any) => any;
  isActive?: boolean;
  small?: boolean;
}

function SelectMentorPopover({
  className,
  value: initialValue,
  onChange: handleChange,
  isActive,
  small,
}: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [selectMentor, setSelectMentor] = useState<SelectMentor | undefined>(
    initialValue,
  );
  const [displayedMentors, setDisplayedMentors] = useState<Mentor[]>();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMentorsSearch = useCallback(
    (searchedMentors: Mentor[]) => {
      const unionSearchedMentors = searchedMentors.filter((searchedMentor) => {
        return searchedMentor.id !== selectMentor?.id;
      });

      setDisplayedMentors(unionSearchedMentors);
    },
    [selectMentor],
  );

  const open = Boolean(anchorEl);
  const isFocused = open || isActive;
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!initialValue) {
      setSelectMentor(undefined);
    }
  }, [initialValue]);

  return (
    <>
      <Button
        aria-describedby={id}
        variant='outlined'
        onClick={handleClick}
        className={cn(className, classes.buttonPopover, {
          [classes.buttonPopoverActive]: isFocused,
        })}>
        <div className={classes.btnLabel}>
          <Text variant='normal'>
            {initialValue?.mentorName || `Any ${rb('mentor')}`}
          </Text>
        </div>
        <InputAdornment position='end'>
          <ArrowDropDownIcon
            className={cn(classes.searchIconBase, {
              [classes.searchIconOpen]: open,
              [classes.searchIconFocused]: isFocused,
            })}
          />
        </InputAdornment>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={classes.popover}>
          <div className={classes.searchBox}>
            <MentorAutocomplete
              onSearch={handleMentorsSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  data-testid='venture-input-mentor-select'
                  placeholder={`Enter ${rb('mentor')} name`}
                  small={small}
                  onChange={(e) => {
                    setDisplayedMentors([]);
                    return onChange(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </div>
          <div
            className={cn(classes.selectContent, {
              [classes.smallContent]: small,
            })}>
            <ul className={classes.selectList}>
              <MenuItem
                key={`select-mentor-any`}
                data-testid='select-mentor-any'
                className={cn(classes.menuItem, {
                  [classes.activeItem]: !selectMentor,
                })}
                value='Any'
                onClick={() => {
                  setSelectMentor(undefined);
                  handleChange(null);
                  handleClose();
                  setDisplayedMentors([]);
                }}>
                <Text variant='normal2'>Any {rb('mentor')}</Text>
              </MenuItem>

              {selectMentor && (
                <MenuItem
                  key={`select-mentor-current`}
                  className={cn(classes.menuItem, classes.activeItem)}
                  value={selectMentor.id}
                  onClick={() => {
                    setDisplayedMentors([]);
                    handleClose();
                  }}>
                  <Text variant='normal2'>{selectMentor.mentorName}</Text>
                </MenuItem>
              )}

              {displayedMentors &&
                displayedMentors.length > 0 &&
                displayedMentors.map((mentor: Mentor) => {
                  const fullNameAlt = `${mentor.lastName} ${mentor.firstName}`;
                  return (
                    <MenuItem
                      key={`select-mentor-${mentor.id}`}
                      data-testid='select-mentor-menu-item'
                      className={classes.menuItem}
                      value={fullNameAlt}
                      onClick={() => {
                        setSelectMentor({
                          mentorName: mentor.fullName,
                          id: fullNameAlt,
                        });
                        handleChange({
                          mentorName: mentor.fullName,
                          id: fullNameAlt,
                        });
                        setDisplayedMentors([]);
                        handleClose();
                      }}>
                      <Text variant='normal2'>{`${mentor.firstName} ${mentor.lastName}`}</Text>
                    </MenuItem>
                  );
                })}
            </ul>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default SelectMentorPopover;
