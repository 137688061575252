import { format, getDate } from 'date-fns';
import _isEqual from 'lodash/isEqual';
import { GatheringPeriod } from '../api/gatherings';
import { GoalStatus } from '../api/types/Goal';
import { GatheringPeriodType } from '../components/forms/gathering-details';
import { COLORS } from '../theme/variables';
import { getCustomGatheringDate } from './date';
import { ISSUES } from './form';

export const getRandomNumberFromString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  const random = Math.round(Math.sin(hash) * 10000);
  return Math.abs(random);
};

export const getColorByUnicode = (_str: string): string => {
  const numberFromString = getRandomNumberFromString(_str);
  const UNICODE_COLORS = [
    COLORS.COLOR_GREEN_BASE,
    COLORS.COLOR_BLUE_BASE,
    COLORS.COLOR_RED_BASE,
    COLORS.COLOR_ADDITIONAL_ORANGE,
    COLORS.COLOR_GRAY_BASE,
    COLORS.COLOR_BLUE_DARKENED_30,
    COLORS.COLOR_ADDITIONAL_PINK,
    COLORS.COLOR_ADDITIONAL_SEA_SERPENT,
    COLORS.COLOR_ADDITIONAL_DODGER_BLUE,
  ];

  const colorIndex = Math.round(numberFromString % UNICODE_COLORS.length);
  return UNICODE_COLORS[colorIndex];
};

export const formatMobilePhone = (phoneStr: string) => {
  const code = phoneStr.slice(0, 3);
  const firstPart = phoneStr.slice(3, 6);
  const secondPart = phoneStr.slice(6, 10);
  return `(${code}) ${firstPart}-${secondPart}`;
};

export const getAbbreviationByName = (firstName: string, lastName: string) => {
  const firstLetter = firstName ? firstName[0] : '';
  const secondLetter = lastName ? lastName[0] : '';

  return (firstLetter + secondLetter).toUpperCase();
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const checkSpecialSymbols = (value: string) => {
  return /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
};

export const checkNumber = (value: string) => {
  return /[0-9]/.test(value);
};

export const checkCapitalCharacters = (value: string) => {
  return /[A-Z]/.test(value);
};

export const isValidURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const validateEmailList = (emailList: string) => {
  const emailArray = emailList.replace(/\s/g, '').split(',');
  return emailArray.every(validateEmail);
};

export const humanFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${String(bytes.toFixed(dp)).replace('.', ',')} ${units[u]}`;
};

export function decodeQuery(base64str: string) {
  let obj = {};
  if (base64str) {
    try {
      obj = JSON.parse(decodeURIComponent(atob(decodeURIComponent(base64str))));
    } catch (e: any) {
      return obj;
    }
  }
  return obj;
}

export function encodeQuery(obj: any) {
  let base64str = '';
  if (obj) {
    try {
      base64str = encodeURIComponent(
        btoa(encodeURIComponent(JSON.stringify(obj))),
      );
    } catch (e: any) {
      return base64str;
    }
  }
  return base64str;
}

export const getMessangeCreateIssue = (
  typeIssue: 'NO_AGENDA' | 'NO_REPORT' | 'NO_NOTES' | 'UNCONFIRMED',
  name: string,
) => {
  const date = format(new Date(), "MMM dd, yyyy 'at' hhaaa");

  const messange =
    ISSUES.startCreateMessange
      .replace('$name', name)
      .replace('$issue', ISSUES?.issuesMessanges[typeIssue]?.type)
      .replace('$date', date) + ISSUES.issuesMessanges[typeIssue].text;

  return messange;
};

export const getMessangeResolvedIssue = (
  typeIssue: 'NO_AGENDA' | 'NO_REPORT' | 'NO_NOTES' | 'UNCONFIRMED',
  name: string,
) => {
  const date = format(new Date(), "MMM dd, yyyy 'at' hhaaa");

  const messange = ISSUES.startResolvedMessange
    .replace('$name', name)
    .replace('$issue', ISSUES?.issuesMessanges[typeIssue]?.type)
    .replace('$date', date);

  return messange;
};

export const isMobile = () =>
  window && window.matchMedia('only screen and (max-width: 699px)').matches;

export const isTablet = () =>
  window && window.matchMedia('only screen and (max-width: 1099px)').matches;

export const checkSizeTablet = (width: number) =>
  window &&
  window.matchMedia(`only screen and (max-width: ${width}px)`).matches;

export const createMarkup = (value: string) => {
  return {
    __html: value || '',
  };
};

export const getTextDescription = (
  valueArray: string[],
  titleArray: string[],
  brText: string,
) => {
  const text = valueArray.reduce((sum, value, index) => {
    if (value) {
      sum =
        sum +
        // `<b>${index + 1}.</b> ` +
        titleArray[index] +
        valueArray[index] +
        brText;
    }
    return sum;
  }, '');
  return text || '';
};

export const getTextWithoutTags = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const decodedWeekString = (value: string) => {
  switch (value) {
    case 'mon':
      return 1;
    case 'tue':
      return 2;
    case 'wen':
      return 3;
    case 'thu':
      return 4;
    case 'fri':
      return 5;
    case 'sat':
      return 6;
    default:
      return 7;
  }
};

export const getDayOfWeekString = (value: number) => {
  switch (value) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return 'Sunday';
  }
};

export const ordinalNumberToWord = (number: number) => {
  const arrayOrdinalNumber = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
  ];
  return arrayOrdinalNumber[number];
};

export const toOrdinal = (number: number) => {
  let str = String(number);
  let lastTwoDigits = Math.abs(number % 100);
  let betweenElevenAndThirteen = lastTwoDigits >= 11 && lastTwoDigits <= 13;
  let lastChar = str.charAt(str.length - 1);
  return (
    str +
    (betweenElevenAndThirteen
      ? 'th'
      : lastChar === '1'
      ? 'st'
      : lastChar === '2'
      ? 'nd'
      : lastChar === '3'
      ? 'rd'
      : 'th')
  );
};

const getStringCustopPeriod = (
  gatheringPeriodValue: GatheringPeriod,
): string => {
  if (gatheringPeriodValue.periodType === 'DAILY') {
    return 'Every day.';
  }
  if (
    gatheringPeriodValue.periodType === 'CUSTOM_WEEK_DAYS' &&
    gatheringPeriodValue.skipWeeks !== -1
  ) {
    return `Every ${getDayOfWeekString(gatheringPeriodValue.dayOfWeek)}.`;
  }
  if (
    gatheringPeriodValue.dayOfMonth &&
    gatheringPeriodValue.dayOfMonth !== -1
  ) {
    return `Every ${toOrdinal(gatheringPeriodValue.dayOfMonth)} of each month.`;
  }
  if (
    gatheringPeriodValue.dayOfWeek &&
    gatheringPeriodValue.dayOfWeek !== -1 &&
    gatheringPeriodValue.ordinalNumber &&
    gatheringPeriodValue.ordinalNumber !== -1
  ) {
    return `Every ${ordinalNumberToWord(
      gatheringPeriodValue.ordinalNumber,
    )} ${getDayOfWeekString(gatheringPeriodValue.dayOfWeek)} of Month.`;
  }
  return '';
};

export const getGatheringPeriodCustomString = (
  gatheringPeriodValue: GatheringPeriod,
): string => {
  if (!gatheringPeriodValue) {
    return '';
  }

  let stopAfter = '';
  if (
    gatheringPeriodValue.stopAfterIterations &&
    gatheringPeriodValue.stopAfterIterations !== -1
  ) {
    stopAfter = ` Stop after ${gatheringPeriodValue.stopAfterIterations} iterations`;
  } else if (gatheringPeriodValue.stopAfterDate) {
    stopAfter = ` Stop after ${getCustomGatheringDate(
      gatheringPeriodValue.stopAfterDate,
    )}`;
  }

  const gatheringPeriod = getStringCustopPeriod(gatheringPeriodValue);

  return gatheringPeriod + stopAfter;
};

export function getGatheringPeriod(
  value: GatheringPeriodType,
  date: Date,
): GatheringPeriod | null {
  const currentDay = getDate(new Date(date));
  const numberWeek = Math.ceil(currentDay / 7) || 1;

  switch (value) {
    case 'DAILY':
      return {
        periodType: 'DAILY',
        dayOfWeek: -1,
        dayOfMonth: -1,
        ordinalNumber: -1,
        skipWeeks: -1,
        skipDays: -1,
        stopAfterIterations: 100,
        stopAfterDate: null,
      };
    case 'CUSTOM_WEEK_DAYS':
      return {
        periodType: 'CUSTOM_WEEK_DAYS',
        dayOfWeek: numberWeek,
        dayOfMonth: -1,
        ordinalNumber: -1,
        skipWeeks: -1,
        skipDays: -1,
        stopAfterIterations: 100,
        stopAfterDate: null,
      };
    case 'MONTHLY':
      return {
        periodType: 'MONTHLY',
        dayOfWeek: -1,
        dayOfMonth: currentDay,
        ordinalNumber: -1,
        skipWeeks: -1,
        skipDays: -1,
        stopAfterIterations: 100,
        stopAfterDate: null,
      };
    case 'ALL_WORK_DAYS':
      return {
        periodType: 'ALL_WORK_DAYS',
        dayOfWeek: -1,
        dayOfMonth: -1,
        ordinalNumber: -1,
        skipWeeks: -1,
        skipDays: -1,
        stopAfterIterations: 100,
        stopAfterDate: null,
      };
    default:
      return null;
  }
}

export const getInitialPeriod = (
  periodString?: string | null,
): GatheringPeriod | GatheringPeriodType | undefined => {
  try {
    if (!periodString) {
      return undefined;
    }
    const gatheringPeriod: GatheringPeriod = JSON.parse(periodString);
    const periodType = parseInitialGatheringPeriod(gatheringPeriod);
    if (periodType !== 'CUSTOM_ADDITIONAL') {
      return periodType;
    }
    return gatheringPeriod;
  } catch (e: any) {
    return undefined;
  }
};

export const parseInitialGatheringPeriod = (
  gatheringPeriod: GatheringPeriod,
): GatheringPeriodType => {
  switch (gatheringPeriod.periodType) {
    case 'ALL_WORK_DAYS':
      return 'ALL_WORK_DAYS';
    case 'MONTHLY':
      return 'MONTHLY';
    case 'DAILY':
      return 'DAILY';
    case 'CUSTOM_WEEK_DAYS':
      if (
        gatheringPeriod.dayOfMonth === -1 &&
        gatheringPeriod.skipWeeks === -1 &&
        gatheringPeriod.ordinalNumber === -1 &&
        gatheringPeriod.stopAfterIterations === 100 &&
        gatheringPeriod.stopAfterDate === null
      ) {
        return 'CUSTOM_WEEK_DAYS';
      }
      return 'CUSTOM_ADDITIONAL';
    default:
      return 'CUSTOM_ADDITIONAL';
  }
};

export const parsePeriodToString = (
  periodString?: string,
): string | undefined => {
  try {
    if (!periodString) {
      return undefined;
    }
    const gatheringPeriod: GatheringPeriod = JSON.parse(periodString);
    return getGatheringPeriodCustomString(gatheringPeriod);
  } catch (e: any) {
    return undefined;
  }
};

export const isEqualPeriods = (
  prev?: string,
  next?: string | null,
): boolean => {
  if (!prev || !next) {
    return false;
  }

  try {
    const prevData = JSON.parse(prev);
    const nextData = JSON.parse(next);
    return _isEqual(prevData, nextData);
  } catch (e: any) {
    return false;
  }
};

export const getColorStatus = (
  status: string,
): 'default' | 'success' | 'warning' | 'error' => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Applicant':
      return 'warning';
    case 'Rejected':
      return 'error';
    default:
      return 'default';
  }
};

export const getColorGoalStatus = (
  status: GoalStatus,
): 'default' | 'success' | 'warning' | 'error' | 'primary' => {
  switch (status) {
    case 'ARCHIVED':
      return 'default';
    case 'AT_RISK':
      return 'warning';
    case 'COMPLETED':
      return 'success';
    case 'IN_TROUBLE':
      return 'error';
    case 'NOT_STARTED':
      return 'default';
    case 'ON_TRACK':
      return 'primary';
    default:
      return 'default';
  }
};

export function checkDynamicPath(
  inputString: string,
  testString: string,
): boolean {
  const regexString = testString.replace(/:[^/]+/g, '[^/]+');
  const regex = new RegExp(regexString + '$');
  return regex.test(inputString);
}
