import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as Api from '../../../api/CommunityMembers/Notes';
import { CommunityMemberId } from '../../../api/CommunityMembers/types/CommunityMember';
import { Note as NoteType } from '../../../api/CommunityMembers/types/Note';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import {
  NewNote,
  Note,
  PageLoader,
  SnackMessage,
} from '../../../components/common';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { getTabs } from '../common/utils';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
});

export function CommunityMemberNotesPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { id } = useParams<{ id: CommunityMemberId }>();
  const [updatingNotesIds, setUpdaingNotesIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingNotesIds, setDeletingNotesIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [notes, setNotes] = useState<NoteType[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateNote = useCallback(
    async (note: { contents: string; attachmentRefs: string }, callback) => {
      try {
        setIsCreating(true);
        const createdNotes = await Api.create({
          contents: note.contents,
          communityMemberId: id,
        });
        setNotes((prev) => [createdNotes, ...prev]);
        setIsCreating(false);
        callback();
        enqueueSnackbar('The note was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [id, enqueueSnackbar],
  );

  const handleNoteUpdate = useCallback(
    async (note: NoteType, callback) => {
      try {
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: true }));
        await Api.update(note);
        setNotes((prev) =>
          prev.map((mentorNotes) =>
            mentorNotes.id === note.id ? note : mentorNotes,
          ),
        );
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: false }));
        callback();
        enqueueSnackbar('The note was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingNotesIds((prev) => ({ ...prev, [note.id]: false }));
      }
    },
    [enqueueSnackbar],
  );

  const handleNoteRemove = useCallback(
    async (note: NoteType, callback: () => any) => {
      try {
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: true }));
        await Api.remove(id, note.id);
        setNotes((prev) =>
          prev.filter((mentorNote) => mentorNote.id !== note.id),
        );
        callback();
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: false }));
        enqueueSnackbar('The note was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the note. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingNotesIds((prev) => ({ ...prev, [note.id]: false }));
      }
    },
    [id, enqueueSnackbar],
  );

  const loadNotes = async (id: CommunityMemberId) => {
    try {
      setIsLoading(true);
      const responseNotes = await Api.getAll(id);

      const sortNotes = responseNotes.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setNotes(sortNotes);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadNotes(id);
  }, [id]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={'Edit community member'}
        backButtonLink={Pages.OH_COMMUNITY_MEMBERS}
        backButtonTitle={'Back to Community Members'}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_COMMUNITY_MEMBERS_NOTES, {
              id,
            })}
            tabs={getTabs(id)}
          />
        }>
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className={classes.container}>
            <NewNote creating={isCreating} onCreate={handleCreateNote} />
            <div>
              {notes.map((note, index) => (
                <Note<NoteType>
                  key={note.id}
                  note={note}
                  updating={updatingNotesIds[note.id]}
                  deleting={deletingNotesIds[note.id]}
                  onEdit={handleNoteUpdate}
                  onRemove={handleNoteRemove}
                  readOnly={false}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
      </WithTabs>
    </BaseLayout>
  );
}
