import { AxiosError } from 'axios';
import { isT, match } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import * as Advisors from '../../../api/Advisors';
import { AdvisorId, Update } from '../../../api/Advisors/types/Advisor';
import * as Availability from '../../../api/Advisors/types/Availability';
import { Tenant } from '../../../api/auth';
import { TenantTimeZone } from '../../../api/tenants/types/Settings';
import { unreachableError } from '../../../utils/unreachableError';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { isExistingItemId, isNewItemId } from './types/ItemId';
import * as State from './types/State';
import { ValidDate } from './types/ValidDate';

export function useAvailabilities(
  user: Tenant,
): [State.State, Dispatch<Actions.Actions>] {
  const { id } = useParams<{ id: AdvisorId }>();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    reducer,
    State.loading({
      advisorId: id,
      tenantId: user.id,
      timeZone: user.timeZone,
    }),
  );
  const state$ = useRef(new BehaviorSubject(state));

  useEffect(() => state$.current.next(state), [state]);
  useEffect(() => {
    const loading$ = state$.current
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap((s) => {
          return from(
            Advisors.getById(
              s.payload.tenantId,
              s.payload.timeZone,
              s.payload.advisorId,
            ),
          ).pipe(
            map(Actions.loadSuccess),
            catchError((e: AxiosError) =>
              of(
                Actions.loadError(
                  e.response?.data.message ?? 'Unable to load availabilities',
                ),
              ),
            ),
          );
        }),
      )
      .subscribe(dispatch);

    const update$ = state$.current
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is State.Saving => s.type === 'Saving'),
        map(
          ({
            payload: { advisor, items, tenantId, advisorId, timeZone },
          }): [Update, TenantTimeZone] => {
            return [
              {
                ...advisor,
                advisorAvailabilities: [
                  ...items.map((i) => {
                    return match(
                      [
                        isExistingItemId,
                        (): Availability.Update | undefined => {
                          const item = advisor.advisorAvailabilities.find(
                            (v) => v.id === i.id,
                          );

                          return item
                            ? {
                                ...item,
                                startTime: i.start.value as ValidDate,
                                endTime: i.end.value as ValidDate,
                                schedulingPeriod: i.period.value,
                              }
                            : undefined;
                        },
                      ],
                      [
                        isNewItemId,
                        (): Availability.Create => {
                          return {
                            advisorId: advisorId,
                            tenantId: tenantId,
                            startTime: i.start.value as ValidDate,
                            endTime: i.end.value as ValidDate,
                            schedulingPeriod: i.period.value,
                          };
                        },
                      ],
                    )(i.id);
                  }),
                ].filter(isT),
              },
              timeZone,
            ];
          },
        ),
        switchMap(([advisor, timeZone]) => {
          return from(Advisors.update(advisor, timeZone)).pipe(
            map(Actions.submitSuccess),
            catchError((e: AxiosError) => {
              return of(
                Actions.submitError(
                  e.response?.data.message ?? 'Unable to save availability',
                ),
              );
            }),
          );
        }),
      )
      .subscribe(dispatch);

    return () => {
      loading$.unsubscribe();
      update$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    switch (state.type) {
      case 'LoadError':
      case 'SaveError':
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        break;
      case 'Saving':
        enqueueSnackbar('Availabilities successfully saved', {
          variant: 'success',
        });
        break;
      case 'Loading':
      case 'Ready':
      case 'CustomPeriod':
      case 'RemoveConfirmation':
      case 'Edited':
        break;
      default:
        return unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type, enqueueSnackbar]);

  return [state, dispatch];
}
