import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';
import { Text } from './index';

export interface Props {
  rate: number | null;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    lineHeight: '18px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

export function RateBox({ rate }: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant={'h5'} className={classes.title}>
        {rate ? `$${rate}` : 'Free'}
      </Typography>
      <Text variant={'normal2'}>
        {rate ? 'per appointment' : 'appointment'}
      </Text>
    </div>
  );
}
