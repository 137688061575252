import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import announcementsAPI, { Announcement } from '../api/announcements';
import AnnouncementsModal from '../components/common/announcements-modal';

export interface AnnouncementContextProps {
  announcements: Announcement[];
  showAnnouncements: boolean;
  setShowAnnouncements: Dispatch<SetStateAction<boolean>>;
  loadAnnouncements: () => Promise<void>;
}

export const AnnouncementContext = createContext<AnnouncementContextProps>({
  announcements: [],
  showAnnouncements: false,
  setShowAnnouncements: () => {},
  loadAnnouncements: async () => {},
});

export const AnnouncementProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [showAnnouncements, setShowAnnouncements] = useState<boolean>(true);

  const loadAnnouncements = useCallback(async () => {
    try {
      const newAnnouncements = await announcementsAPI.getAnnouncements();
      setAnnouncements(newAnnouncements);
      setShowAnnouncements(!!newAnnouncements.length);
    } catch (error) {
      setAnnouncements([]);
      setShowAnnouncements(false);
    }
  }, []);

  const contextValue: AnnouncementContextProps = {
    announcements,
    showAnnouncements,
    setShowAnnouncements,
    loadAnnouncements,
  };

  return (
    <AnnouncementContext.Provider value={contextValue}>
      <AnnouncementsModal />
      {children}
    </AnnouncementContext.Provider>
  );
};

export const useAnnouncements = () => {
  return useContext(AnnouncementContext);
};
