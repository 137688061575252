import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  createTask,
  getTasks,
  removeTask,
  updateTask,
} from '../../api/goals';
import { GoalTask } from '../../api/types/Goal';
import {
  PageLoader,
  SnackMessage,
  NewTask,
  Task,
} from '../../components/common';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function GoalTasksPage() {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const { goalId } = useParams<{ goalId: string }>();
  const [updatingTasksIds, setUpdaingTasksIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [deletingTasksIds, setDeletingTasksIds] = useState<{
    [x: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [tasks, setTasks] = useState<GoalTask[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateTask = useCallback(
    async (
      task: { title: string; status: string; },
      callback,
    ) => {
      try {
        setIsCreating(true);
        if (goalId) {
          const createdTasks = await createTask({
            goalId,
            title: task.title,
            status: task.status,
          });
          setTasks((prev) => [createdTasks, ...prev]);
        }
        setIsCreating(false);
        callback();
        enqueueSnackbar('The task was successfully added.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the task. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
      }
    },
    [goalId, enqueueSnackbar],
  );

  const handleTaskUpdate = useCallback(
    async (task: GoalTask, callback) => {
      try {
        setUpdaingTasksIds((prev) => ({ ...prev, [task.id]: true }));
        if (goalId) {
          await updateTask(task);
          setTasks((prev) =>
            prev.map((prevTask) => (prevTask.id === task.id ? task : prevTask)),
          );
        }
        setUpdaingTasksIds((prev) => ({ ...prev, [task.id]: false }));
        callback && callback();
        enqueueSnackbar('The task was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while updating the task. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdaingTasksIds((prev) => ({ ...prev, [task.id]: false }));
      }
    },
    [goalId, enqueueSnackbar],
  );

  const handleTaskRemove = useCallback(
    async (task: GoalTask, callback: () => any) => {
      try {
        setDeletingTasksIds((prev) => ({ ...prev, [task.id]: true }));
        if (goalId) {
          await removeTask(task.id);
          setTasks((prev) =>
            prev.filter((prevTask) => prevTask.id !== task.id),
          );
        }
        callback();
        setDeletingTasksIds((prev) => ({ ...prev, [task.id]: false }));
        enqueueSnackbar('The task was successfully removed.', {
          variant: 'success',
        });
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while removing the task. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setDeletingTasksIds((prev) => ({ ...prev, [task.id]: false }));
      }
    },
    [goalId, enqueueSnackbar],
  );

  const loadTasks = async (goalId: string) => {
    try {
      setIsLoading(true);
      const responseTasks = await getTasks(goalId);

      const sortTasks = responseTasks.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setTasks(sortTasks);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTasks(goalId);
  }, [goalId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {hasAccessToAction('goal.tasks.create') && (
        <NewTask
          creating={isCreating}
          onCreate={handleCreateTask}
        />
      )}
      <div>
        {tasks.map((task, index) => (
          <Task<GoalTask>
            key={task.id}
            task={task}
            updating={updatingTasksIds[task.id]}
            deleting={deletingTasksIds[task.id]}
            onEdit={handleTaskUpdate}
            onRemove={handleTaskRemove}
            readOnly={!hasAccessToAction('goal.tasks.update')}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default GoalTasksPage;
