import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { interval, race, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';

const identical = <T>(a: T, b: T): boolean => a === b;

export const useDebouncedCallback = <T extends any[]>(
  callback: (...args: T) => void,
  timeout: number,
): [cb: (...args: T) => void, commit: () => void] => {
  const cbRef = useRef({ callback });

  const commit$ = useMemo(() => new Subject<void>(), []);
  const subject = useMemo(() => {
    const subject = new Subject<T>();
    subject
      .pipe(debounce(() => race(interval(timeout), commit$)))
      .subscribe((v) => cbRef.current.callback(...v));

    return subject;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);
  const subjectRef = useRef({ subject });

  cbRef.current.callback = callback;
  subjectRef.current.subject = subject;

  useEffect(
    () => () => {
      subject.complete();
    },
    [subject],
  );

  return [
    useCallback((...args: T) => subjectRef.current.subject.next(args), []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(() => commit$.next(), []),
  ];
};

export const useDebouncedOnChange = <T>(
  value: T,
  onChange: (v: T) => void,
  timeout: number,
  compare: (a: T, b: T) => boolean = identical,
): [v: T, onChange: (v: T) => void, commit: () => void] => {
  const ref = useRef<T>(value);
  const [_onChange, commit] = useDebouncedCallback(onChange, timeout);
  const cbs = { compare, onChange: _onChange };
  const cbRef = useRef(cbs);
  const [v, setV] = useState<T>(value);

  cbRef.current = cbs;

  useEffect(() => {
    if (!cbRef.current.compare(v, ref.current)) {
      cbRef.current.onChange(v);
      ref.current = v;
    }
  }, [v]);

  useEffect(() => {
    if (!cbRef.current.compare(value, ref.current)) {
      setV(value);
      ref.current = value;
    }
  }, [value]);

  return [v, setV, commit];
};
