import { CommunityMemberCreate as Create } from '../../../api/CommunityMembers/types/CommunityMember';
import { Saving, State } from './types/State';
import { Status } from '../../../api/CommunityMembers/types/Status';
import { formatDateToRFC } from '../../../utils/date';
import { mPipe } from 'fp-utilities';

export function savingToCreate({
  payload: { item, tenantId, enrollmentDate },
}: Saving): Create {
  return {
    address: item.address.value,
    country: item.country.value,
    city: item.city.value,
    state: item.state.value,
    status: item.status.value,
    email: item.email.value,
    zip: item.zip.value,
    lastName: item.lastName.value,
    tenantId,
    firstName: item.firstName.value,
    dateOfBirth: mPipe(formatDateToRFC)(item.birthDate.value),
    linkedInProfile: item.linkedIn.value,
    dateOfEnrollment: formatDateToRFC(enrollmentDate),
  };
}

export function formEdited(s: State): boolean {
  switch (s.type) {
    case 'Redirect':
    case 'SaveError':
    case 'Ready':
    case 'Saving':
      return false;
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
      return true;
  }
}

export function statusTitle(s: Status): string {
  switch (s) {
    case 'Archived':
      return 'Archived';
    case 'Active':
      return 'Active';
    case 'Applicant':
      return 'Applicant';
    case 'Rejected':
      return 'Rejected';
  }
}

export function isSubmittable(s: State): boolean {
  switch (s.type) {
    case 'Redirect':
    case 'Saving':
      return false;
    case 'Ready':
    case 'SaveError':
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
      return true;
  }
}
