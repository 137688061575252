import {
  makeStyles,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useState, useCallback, useEffect, useRef } from 'react';
import { COLORS } from '../../theme/variables';
import { TextField, Text } from '../common';

const useStyles = makeStyles({
  buttonPopover: {
    '& *': {
      cursor: 'pointer',
    },
  },
  searchInput: {
    '& input': {
      padding: '12px 14px 12px 0px',
    },
  },
  searchIconBase: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
  },
  searchIconFocused: {
    color: COLORS.COLOR_BLUE_BASE,
  },
  popover: {
    minWidth: 250,
    height: 300,
    padding: '10px 0',
    overflow: 'hidden',
  },
  searchBox: {
    padding: '0 10px 10px',
  },
  selectList: {
    maxHeight: 'calc(300px - 38px)',
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: 12,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,

      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  selectContent: {
    width: '100%',
    zIndex: 100,
    top: 55,
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
  },
  smallContent: {
    top: 38,
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  menuItem: {
    '&:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45,
    },
    '& > span': {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  activeItem: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,
  },
});

export interface SearchSelectOption {
  label: string;
  value: string;
}

export interface SearchSelectProps {
  value: SearchSelectOption;
  onChange: (v: SearchSelectOption | null) => void;
  onSearch: (
    search: string,
  ) => Promise<SearchSelectOption[]> | SearchSelectOption[];
  label?: string;
  placeholder?: string;
  className?: string;
  isActive?: boolean;
  small?: boolean;
  required?: boolean;
  readOnly?: boolean;
  error?: boolean;
  onBlur?: () => void;
}

function SearchSelect({
  value: initialValue,
  onChange: handleChange,
  onSearch,
  label,
  placeholder,
  className,
  small,
  required,
  readOnly = false,
  error,
  onBlur,
}: SearchSelectProps) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] =
    useState<SearchSelectOption | null>(initialValue);
  const [displayedOptions, setDisplayedOptions] = useState<
    SearchSelectOption[]
  >([]);
  const [searchValue, setSearchValue] = useState(initialValue?.label || '');
  const [isSearching, setIsSearching] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const open = Boolean(anchorEl);
  const inputValue = selectedOption?.label || placeholder;
  const isFocused = open || !!inputValue;
  const id = open ? 'simple-popover' : undefined;

  const handleSearch = useCallback(
    async (search: string) => {
      setSearchValue(search);

      try {
        setIsSearching(true);
        const searchedOptions = await onSearch(search);
        const unionSearchedOptions = searchedOptions.filter(
          (searchedOption) => {
            return searchedOption.value !== selectedOption?.value;
          },
        );

        setDisplayedOptions(unionSearchedOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSearching(false);
      }
    },
    [onSearch, selectedOption?.value],
  );

  const handleOpen = useCallback(
    (event: any) => {
      setSearchValue('');
      handleSearch('');
      setAnchorEl(event.currentTarget);
    },
    [handleSearch],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onBlur && onBlur();
  }, [onBlur]);

  const handleSelect = useCallback(
    (option: SearchSelectOption | null) => {
      setSelectedOption(option);
      handleChange(option);
      setDisplayedOptions([]);
      handleClose();
    },
    [handleChange, handleClose],
  );

  useEffect(() => {
    if (!initialValue) {
      setSelectedOption(null);
    }
  }, [initialValue]);

  return (
    <>
      <TextField
        data-testid='search-select-button'
        aria-describedby={id}
        variant='outlined'
        onClick={!readOnly ? handleOpen : () => {}}
        label={label}
        value={inputValue}
        className={cn(className, classes.buttonPopover)}
        error={error}
        InputProps={{
          readOnly: true,
          ref: inputRef,
          endAdornment: (
            <InputAdornment position='end'>
              <ArrowDropDownIcon
                className={cn(classes.searchIconBase, {
                  [classes.searchIconOpen]: open,
                  [classes.searchIconFocused]: isFocused,
                })}
              />
            </InputAdornment>
          ),
        }}
        focused={isFocused}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div
          className={classes.popover}
          style={{
            width: inputRef.current?.clientWidth
              ? `${inputRef.current.clientWidth}px`
              : 'auto',
          }}>
          <div className={classes.searchBox}>
            <TextField
              value={searchValue}
              data-testid='search-select-input'
              placeholder='Search...'
              small={small}
              onChange={(e) => handleSearch(e.target.value)}
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: !!isSearching && (
                  <CircularProgress color='primary' size={20} />
                ),
              }}
            />
          </div>
          <div
            className={cn(classes.selectContent, {
              [classes.smallContent]: small,
            })}>
            <ul className={classes.selectList}>
              {!required && !!placeholder && (
                <MenuItem
                  key={'search-select-any'}
                  data-testid={'search-select-any'}
                  value={undefined}
                  className={cn(classes.menuItem, {
                    [classes.activeItem]: !selectedOption?.value,
                  })}
                  onClick={() => handleSelect(null)}>
                  <Text variant='normal2'>{placeholder}</Text>
                </MenuItem>
              )}
              {selectedOption?.value && (
                <MenuItem
                  key={`search-select-option-current`}
                  value={selectedOption.value}
                  className={cn(classes.menuItem, classes.activeItem)}
                  onClick={() => {
                    setDisplayedOptions([]);
                    handleClose();
                  }}>
                  <Text variant='normal2'>{selectedOption.label}</Text>
                </MenuItem>
              )}

              {displayedOptions &&
                displayedOptions.length > 0 &&
                displayedOptions.map((option) => {
                  return (
                    <MenuItem
                      data-testid='search-select-item'
                      key={`search-select-item-${option.value}`}
                      className={classes.menuItem}
                      value={option.value}
                      onClick={() => handleSelect(option)}>
                      <Text variant='normal2'>{option.label}</Text>
                    </MenuItem>
                  );
                })}
            </ul>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default SearchSelect;
