import { AppointmentId } from '../../../api/Appointments/types/Appointment';
import { TabItem } from '../../../components/Pages/Forms/Tabs';
import { getRoutePath, Pages } from '../../../router/constants';

export const getAppointmentTabs = (
  id: AppointmentId | undefined,
): TabItem[] => [
  {
    title: 'Details',
    link: getRoutePath(Pages.OH_APPOINTMENTS_EDIT, { id }),
  },
  {
    title: 'Logs',
    link: getRoutePath(Pages.OH_APPOINTMENTS_LOGS, { id }),
  },
];
