import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Audience } from '../../api/CustomQuestion/types/Audience';
import mentorsAPI, {
  MentorAdditionalInfo,
  MentorAdditionalInfoCreate,
} from '../../api/mentors';
import { PageLoader, SnackMessage } from '../../components/common';
import CustomForm from '../../components/forms/custom-form';
import MentorAdditionalInfoForm, {
  FormAdditionalInfo,
  ValidFormAdditionalInfo,
} from '../../components/forms/mentor-additional-info';
import { MentorContext } from '../../contexts/mentor-context';
import { UserContext } from '../../contexts/user-context';
import { formatDateToRFC } from '../../utils/date';

interface MentorAdditionalInfoDefaultProps {
  hasAccessToUpdate?: boolean;
}

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

const getInitialValues = (value?: MentorAdditionalInfo): FormAdditionalInfo => {
  return {
    gender: value?.gender || '',
    race: value?.race || '',
    veteran: value?.veteran ? 'true' : 'false',
    whyBecomeMentor: value?.whyBecomeMentor || '',
    startedBusiness: value?.startedBusiness ? 'true' : 'false',
    salesChallenges: value?.salesChallenges || '',
    marketingChallenges: value?.marketingChallenges || '',
    fundraisingChallenges: value?.fundraisingChallenges || '',
    businessChallenges: value?.businessChallenges || '',
    comments: value?.comments || '',
  };
};

const getParsedValues = (value: ValidFormAdditionalInfo) => {
  return {
    gender: value.gender,
    race: value.race,
    veteran: value.veteran === 'true',
    whyBecomeMentor: value.whyBecomeMentor,
    startedBusiness: value.startedBusiness === 'true',
    salesChallenges: value.salesChallenges,
    marketingChallenges: value.marketingChallenges,
    fundraisingChallenges: value.fundraisingChallenges,
    businessChallenges: value.businessChallenges,
    comments: value.comments,
  };
};

function MentorAdditionalInfoPage() {
  const location = useLocation();
  const { mentorId } = useParams<{ mentorId: string }>();
  const { identityid, user, hasAccessToAction } = useContext(UserContext);

  const hasAccessToUpdate = useMemo(() => {
    const isExternalPage =
      location.pathname.includes('external') || identityid === mentorId;
    return isExternalPage
      ? true
      : hasAccessToAction('mentor.additionalInfo.update');
  }, [hasAccessToAction, identityid, location.pathname, mentorId]);

  return (
    <div>
      {user?.customQuestionsMentorAdditionalInfoEnabled ? (
        <CustomForm
          audience={Audience.MENTOR_ADDITIONAL_INFO}
          responderId={mentorId}
          successMessage='Additional information successfully updated.'
          hasAccessToUpdate={hasAccessToUpdate}
        />
      ) : (
        <MentorAdditionalInfoDefault hasAccessToUpdate={hasAccessToUpdate} />
      )}
    </div>
  );
}

function MentorAdditionalInfoDefault({
  hasAccessToUpdate,
}: MentorAdditionalInfoDefaultProps) {
  const classes = useStyles();
  const { mentorId } = useParams<{ mentorId: string }>();
  const { mentor, updateMentor } = useContext(MentorContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitForm = async (value: ValidFormAdditionalInfo) => {
    try {
      setIsLoading(true);
      if (mentor) {
        const parsedValues = getParsedValues(value);
        const mentorAdditionalInfo:
          | MentorAdditionalInfo
          | MentorAdditionalInfoCreate = mentor?.mentorAdditionalInfo
          ? {
              ...mentor.mentorAdditionalInfo,
              ...parsedValues,
            }
          : {
              ...parsedValues,
              creationDate: formatDateToRFC(new Date()),
            };

        const updatedMentor = await mentorsAPI.update(mentorId, {
          ...mentor,
          mentorAdditionalInfo: mentorAdditionalInfo,
        });
        updateMentor(updatedMentor);
      }
      setIsLoading(false);
      enqueueSnackbar('Additional information successfully updated.', {
        variant: 'success',
      });
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar('An error occurred while saving. Please, try again.', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const initialValues = getInitialValues(mentor?.mentorAdditionalInfo);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <MentorAdditionalInfoForm
      initialValues={initialValues}
      isLoading={isLoading}
      onSubmit={handleSubmitForm}
      hasAccessToUpdate={hasAccessToUpdate}
    />
  );
}

export default MentorAdditionalInfoPage;
