import { axiosRequest } from './instance';

export interface NoteComment {
  id: string;
  tenantId: string;
  noteId: string;
  creatorFirstName: string;
  creatorLastName: string;
  creatorRole: string;
  creatorId: string | null;
  creationDate: string;
  parentNoteId: string;
  parentType: string;
  content: string;
  creatorLogo: string | null;
  valid: boolean;
}

export interface CreateNoteComment
  extends Omit<
    NoteComment,
    | 'id'
    | 'tenantId'
    | 'noteId'
    | 'creatorFirstName'
    | 'creatorLastName'
    | 'creatorLogo'
    | 'creationDate'
    | 'valid'
  > {}

const commentsAPI = {
  async getCommentsByNoteId(noteId: string) {
    try {
      const res = await axiosRequest.get<NoteComment[]>({
        url: `/notes/${noteId}/comments`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createCommentForNote(note: CreateNoteComment) {
    const res = await axiosRequest.post<NoteComment>({
      url: '/notes/comments',
      data: note,
      credentials: true,
    });
    return res.data;
  },
  async updateComment(comment: NoteComment) {
    const res = await axiosRequest.put<NoteComment>({
      url: '/notes/comments',
      data: comment,
      credentials: true,
    });
    return res.data;
  },
  async deleteComment(commentId: string) {
    const res = await axiosRequest.delete<NoteComment>({
      url: `/notes/comments/${commentId}`,
      credentials: true,
    });
    return res.data;
  },
};

export default commentsAPI;
