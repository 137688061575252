import { createContext, useCallback, useState } from 'react';
import authAPI, { TractionUser } from '../api/auth';
import foundersAPI, { Founder, FounderId } from '../api/founders';
import { Specialization } from '../api/specializations';

export interface FounderContextProps {
  founder?: Founder;
  tenantUser: TractionUser | null;
  founderSpecializations?: Specialization['id'][];
  updateFounder: (founder: Founder) => any;
  updateSpecializations: (specializations: Specialization['id'][]) => any;
  loadFounder: (founderId: FounderId) => Promise<any>;
  loadFounderSpecializations: (founderId: FounderId) => Promise<any>;
  isFounderLoading: boolean;
  isSpecializationsLoading: boolean;
  setTenantUser: (user: TractionUser | null) => any;
}

export const FounderContext = createContext<FounderContextProps>({
  updateFounder: () => {},
  tenantUser: null,
  updateSpecializations: () => {},
  loadFounder: async () => {},
  loadFounderSpecializations: async () => {},
  isFounderLoading: true,
  isSpecializationsLoading: true,
  setTenantUser: () => {},
});

interface FounderProviderProps {
  children: React.ReactNode;
}

export const FounderProvider = ({ children }: FounderProviderProps) => {
  const [founder, setFounder] = useState<Founder>();
  const [tenantUser, setTenantUser] = useState<TractionUser | null>(null);
  const [founderSpecializations, setFounderSpecializations] =
    useState<Specialization['id'][]>();
  const [isFounderLoading, setIsFounderLoading] = useState(true);
  const [isSpecializationsLoading, setIsSpecializationsLoading] =
    useState(true);

  const updateFounder = useCallback((founder: Founder) => {
    setFounder(founder);
  }, []);

  const updateSpecializations = useCallback(
    (specializations: Specialization['id'][]) => {
      setFounderSpecializations(specializations);
    },
    [],
  );

  const loadFounder = useCallback(async (founderId: FounderId) => {
    try {
      setIsFounderLoading(true);
      const loadedFounder = await foundersAPI.getFounder(founderId);
      setFounder(loadedFounder);
      const loadedTenantUser = await authAPI.getUserByEmail(
        loadedFounder.email,
      );
      setTenantUser(loadedTenantUser);
      setIsFounderLoading(false);
    } catch (e: any) {
      setIsFounderLoading(false);
      console.error('error loadFounder', e);
    }
  }, []);

  const loadFounderSpecializations = useCallback(
    async (founderId: FounderId) => {
      try {
        setIsSpecializationsLoading(true);
        const specializations = await foundersAPI.getFounderSpecializations(
          founderId,
        );
        setFounderSpecializations(specializations.map((spec) => spec.id));
        setIsSpecializationsLoading(false);
      } catch (e: any) {
        setIsSpecializationsLoading(false);
        console.error('error loadFounderSpecializations', e);
      }
    },
    [],
  );

  const contextValue: FounderContextProps = {
    founder,
    tenantUser,
    isFounderLoading,
    loadFounder,
    updateFounder,
    founderSpecializations,
    isSpecializationsLoading,
    loadFounderSpecializations,
    updateSpecializations,
    setTenantUser,
  };

  return (
    <FounderContext.Provider value={contextValue}>
      {children}
    </FounderContext.Provider>
  );
};
