import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { identity } from 'ramda';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps, useForm } from 'react-final-form';
import { Founder, FounderDetails, FounderStatus } from '../../api/founders';
import { Specialization } from '../../api/specializations';
import { Role } from '../../api/user/Role';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { Name } from '../../types';
import { apply, Either, isLeft, parseObject, right } from '../../utils/Either';
import { Email, stringToEitherEmail } from '../../utils/String/Email';
import { Phone, stringToEitherPhone } from '../../utils/String/Phone';
import { fromString, Sentence } from '../../utils/String/Sentence';
import { stringToEitherZip, Zip } from '../../utils/String/Zip';
import { formatDateToRFC } from '../../utils/date';
import {
  PastStringDate,
  strToEitherPastStrDate,
} from '../../utils/date/PastStringDate';
import { lengthField, STATUSES_FOUNDERS } from '../../utils/form';
import {
  checkSizeTablet,
  getAbbreviationByName,
  isMobile,
} from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  FieldNames,
  FormGroup,
  StickyContent,
  UserLogo,
} from '../common';
import { hasDuplicateEmail } from '../common/field-email';
import { checkNameForDublicates } from '../common/field-names';
import {
  FormDateInput,
  FormEmail,
  FormSelect,
  FormSpecializationInput,
  TextFieldWrapper,
} from './wrappers';

interface FounderDetailsFormProps {
  founderId?: Founder['id'];
  founderDetails?: FounderDetails;
  founderSpecializations?: Specialization['id'][];
  loading?: boolean;
  onSubmit: (parsedFormValues: ParsedFormValues) => any;
  isExternal?: boolean;
}

interface FormValues {
  address: string | Sentence<1024> | null;
  firstName: string | Name;
  lastName: string | Name;
  verifiedName: boolean;
  dateOfBirth: string | PastStringDate | null;
  dateOfEnrollment: string | PastStringDate;
  specializations: Specialization['id'][];
  email: string | Email;
  verifiedEmail: boolean;
  phone: string | null | Phone;
  linkedInProfile: string | null | Sentence<250>;
  country: string | null | Sentence<250>;
  state: string | null | Sentence<250>;
  city: string | null | Sentence<250>;
  logo: string;
  zip: string | null | Zip;
  status: FounderStatus;
}

interface ValidFormValues extends FormValues {
  address: Sentence<1024> | null;
  firstName: Name;
  lastName: Name;
  verifiedName: boolean;
  dateOfBirth: PastStringDate | null;
  dateOfEnrollment: PastStringDate;
  specializations: Specialization['id'][];
  email: Email;
  verifiedEmail: boolean;
  phone: null | Phone;
  linkedInProfile: null | Sentence<250>;
  country: null | Sentence<250>;
  state: null | Sentence<250>;
  city: null | Sentence<250>;
  logo: string;
  zip: null | Zip;
}

export interface ParsedFormValues {
  values: FounderDetails;
  specializations: Specialization['id'][];
  logoFile: File | null;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '0 0 40px 0',

    [theme.breakpoints.up('xs')]: {
      margin: '65px 0 0 30px',
    },

    [theme.breakpoints.up(1000)]: {
      marginLeft: 80,
    },
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  statusField: {
    marginTop: 0,

    [theme.breakpoints.up('xs')]: {
      marginTop: 15,
    },
  },
}));

function getValidFormValue(
  formValues: FormValues,
): Either<Errors, ValidFormValues> {
  return parseObject<FormValues, ValidFormValues, Errors>(
    {
      address: (value) => (value ? fromString(1024, value) : right(null)),
      city: (value) => (value ? fromString(250, value) : right(null)),
      country: (value) => (value ? fromString(250, value) : right(null)),
      dateOfBirth: (value) =>
        value ? strToEitherPastStrDate(value) : right(null),
      lastName: (v) => fromString(256, v || ''),
      firstName: (v) => fromString(256, v || ''),
      email: (v) => stringToEitherEmail(v || ''),
      logo: (value) => right(value),
      phone: (value) => (value ? stringToEitherPhone(value) : right(null)),
      dateOfEnrollment: (value) => strToEitherPastStrDate(value),
      specializations: (value) => right(value),
      state: (value) => (value ? fromString(250, value) : right(null)),
      linkedInProfile: (value) =>
        value ? fromString(250, value) : right(null),
      status: (value) => right(value),
      zip: (value) => (value ? stringToEitherZip(value) : right(null)),
      verifiedEmail: (value) => right(value),
      verifiedName: (value) => right(value),
    },
    formValues,
  );
}

const validateForm = (values: FormValues) => {
  const errors = getValidFormValue(values);

  return isLeft(errors) ? errors.value : {};
};

function getInitialValues(
  founderDetails?: FounderDetails,
  specializations: Specialization['id'][] = [],
): FormValues {
  const country = founderDetails?.country || '';

  return {
    address: founderDetails?.address || '',
    firstName: founderDetails?.firstName || '',
    lastName: founderDetails?.lastName || '',
    verifiedName: !!(founderDetails?.firstName && founderDetails.lastName),
    dateOfBirth: founderDetails?.dateOfBirth || '',
    dateOfEnrollment:
      founderDetails?.dateOfEnrollment || formatDateToRFC(new Date()),
    specializations,
    email: founderDetails?.email || '',
    verifiedEmail: !!founderDetails?.email,
    phone: founderDetails?.phone || '',
    linkedInProfile: founderDetails?.linkedInProfile || '',
    country,
    state: founderDetails?.state || '',
    city: founderDetails?.city || '',
    logo: founderDetails?.logo || '',
    zip: founderDetails?.zip || '',
    status: founderDetails?.status || STATUSES_FOUNDERS[0].value,
  };
}

function getParsedValues(
  formValues: ValidFormValues,
  logoFile: File | null,
): ParsedFormValues {
  return {
    values: {
      address: formValues.address,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      dateOfEnrollment: formValues.dateOfEnrollment,
      email: formValues.email,
      phone: formValues.phone,
      linkedInProfile: formValues.linkedInProfile,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      logo: formValues.logo,
      zip: formValues.zip,
      status: formValues?.status,
    },
    specializations: formValues.specializations,
    logoFile,
  };
}

function FounderDetailsForm({
  founderDetails,
  founderSpecializations,
  loading = false,
  onSubmit,
  isExternal = false,
}: FounderDetailsFormProps) {
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [isAsyncValidating, setIsAsyncValidating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasAccessToAction: checkAccess, hasRole } = useContext(UserContext);
  const hasAccessToAction = useMemo(
    () => (isExternal ? () => true : checkAccess),
    [checkAccess, isExternal],
  );
  const handleSubmit = async (formValues: FormValues) => {
    if (isAsyncValidating) {
      return;
    }

    try {
      setIsAsyncValidating(true);
      const validValues = getValidFormValue(formValues);

      if (isLeft(validValues)) {
        throw new Error('Invalid form values');
      }

      const parsedValues = getParsedValues(validValues.value, logoFile);

      const isEmailDublicate =
        parsedValues.values.email !== founderDetails?.email &&
        (await hasDuplicateEmail('founder', parsedValues.values.email));

      if (isEmailDublicate) {
        throw new Error('Email is dublicate');
      }

      const nameCheckStatus =
        parsedValues.values.firstName === founderDetails?.firstName &&
        parsedValues.values.lastName === founderDetails?.lastName
          ? 'success'
          : await checkNameForDublicates(
              'founder',
              parsedValues.values.firstName,
              parsedValues.values.lastName,
            );

      if (nameCheckStatus !== 'success') {
        throw new Error('Name is dublicate');
      }

      setIsAsyncValidating(false);
    } catch (error) {
      setIsAsyncValidating(false);
      return;
    }

    return apply(
      identity,
      (validValues) => onSubmit(getParsedValues(validValues, logoFile)),
      getValidFormValue(formValues),
    );
  };

  const initialValues = useMemo(
    () => getInitialValues(founderDetails, founderSpecializations),
    [founderDetails, founderSpecializations],
  );

  const validateLogoFile = (logo: File) => {
    const fileName = logo.name;
    if (fileName.length > lengthField.logoFileName) {
      enqueueSnackbar('The maximum length of the file name is 75 characters', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formProps) => {
          setSubmitValidationFailed(
            formProps.submitFailed &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting,
          );
          return (
            <FormWrapper formProps={formProps}>
              <BeforeUnload
                when={formProps.dirty && !loading}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={loading}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      await formProps.handleSubmit();
                      onConfirm();
                    }}
                    disabled={loading || !formProps.valid}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <div className={classes.formBlocks}>
                <div className={classes.mainFormBlock}>
                  <form noValidate>
                    <div className={classes.sectionBlock}>
                      {isMobile() && (
                        <div className={classes.logoFormBlock}>
                          <Field<string>
                            name='logo'
                            parse={(value) => (!value ? '' : value)}
                            component={({ input }) => (
                              <UserLogo
                                logo={input.value}
                                name={getAbbreviationByName(
                                  formProps.values.firstName,
                                  formProps.values.lastName,
                                )}
                                onUpload={(photoFile) => {
                                  if (validateLogoFile(photoFile)) {
                                    setLogoFile(photoFile);
                                    const blobFile =
                                      URL.createObjectURL(photoFile);
                                    input.onChange(blobFile);
                                  }
                                }}
                                onRemove={() => {
                                  setLogoFile(null);
                                  input.onChange('');
                                }}
                                readOnly={
                                  !hasAccessToAction('founder.details.update')
                                }
                              />
                            )}
                          />
                        </div>
                      )}
                      {hasAccessToAction('founder.details.field.firstName') &&
                        hasAccessToAction('founder.details.field.lastName') && (
                          <FieldNames
                            entityType='founder'
                            isReadOnly={
                              !hasAccessToAction('founder.details.update')
                            }
                          />
                        )}
                      {hasAccessToAction('founder.details.field.dateOfBirth') &&
                        hasAccessToAction(
                          'founder.details.field.dateOfEnrollment',
                        ) && (
                          <FormGroup mobile={checkSizeTablet(800)}>
                            <Field<string>
                              testid='founder-details-date-birth'
                              name='dateOfBirth'
                              component={FormDateInput}
                              label='Date of birth'
                              editable
                              parse={(value) => {
                                try {
                                  if (value) {
                                    return formatDateToRFC(value);
                                  }
                                  return '';
                                } catch (e: any) {
                                  return 'invalid';
                                }
                              }}
                            />
                            <Field<string>
                              testid='founder-details-date-enrollment'
                              name='dateOfEnrollment'
                              component={FormDateInput}
                              label='Date of enrollment*'
                              maxDate={new Date()}
                              editable
                              parse={(value) => {
                                try {
                                  if (value) {
                                    return formatDateToRFC(value);
                                  }
                                  return '';
                                } catch (e: any) {
                                  return 'invalid';
                                }
                              }}
                            />
                          </FormGroup>
                        )}
                      {hasAccessToAction(
                        'founder.details.field.specializations',
                      ) && (
                        <FormGroup>
                          <Field<Specialization['id'][]>
                            name='specializations'
                            data-testid='founder-details-specializations'
                            component={FormSpecializationInput}
                            label='Specialization'
                            disabled={!founderSpecializations}
                          />
                        </FormGroup>
                      )}
                      {isMobile() && (
                        <FormGroup className={classes.statusField}>
                          <Field<string>
                            testid='founder-details-status'
                            name='status'
                            component={FormSelect}
                            label='Status'
                            options={STATUSES_FOUNDERS}
                            readOnly={!hasRole(Role.Manager)}
                          />
                        </FormGroup>
                      )}
                    </div>
                    <div className={classes.sectionBlock}>
                      <Typography className={classes.sectionTitle} variant='h3'>
                        Contacts
                      </Typography>
                      {hasAccessToAction('founder.details.field.email') && (
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-email'
                            name='email'
                            component={FormEmail}
                            label='Email*'
                            formatOnBlur
                            format={(value: string) => {
                              return value ? value.toLowerCase() : value;
                            }}
                            entityType='founder'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.email,
                                readOnly: !hasAccessToAction(
                                  'founder.details.update',
                                ),
                              },
                            }}
                            type='founder'
                          />
                        </FormGroup>
                      )}
                      {hasAccessToAction('founder.details.field.phone') && (
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-phone'
                            name='phone'
                            label='Phone'
                            component={TextFieldWrapper}
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.phone,
                                readOnly: !hasAccessToAction(
                                  'founder.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                      {hasAccessToAction(
                        'founder.details.field.linkedInProfile',
                      ) && (
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-linkedin'
                            name='linkedInProfile'
                            component={TextFieldWrapper}
                            label='LinkedIn profile'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.linkedIn,
                                readOnly: !hasAccessToAction(
                                  'founder.details.update',
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                      )}
                    </div>
                    {hasAccessToAction('founder.details.field.address') && (
                      <div className={classes.sectionBlock}>
                        <Typography
                          className={classes.sectionTitle}
                          variant='h3'>
                          Address
                        </Typography>
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-address'
                            name='address'
                            component={TextFieldWrapper}
                            label='Address'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.address,
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-city'
                            name='city'
                            component={TextFieldWrapper}
                            label='City'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.city,
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-state'
                            name='state'
                            component={TextFieldWrapper}
                            label='State'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.state,
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            name='zip'
                            data-testid='founder-details-zip'
                            component={TextFieldWrapper}
                            label='Zip code'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.zip,
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field<string>
                            data-testid='founder-details-country'
                            name='country'
                            component={TextFieldWrapper}
                            label='Country'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.country,
                              },
                            }}
                          />
                        </FormGroup>
                      </div>
                    )}
                    {hasAccessToAction('founder.details.update') && (
                      <div className={classes.actionsBlock}>
                        <StickyContent>
                          <Button
                            data-testid='founder-details-button-submit'
                            className={CLASS_TRACKING.INTERNAL_ACTION}
                            onClick={formProps.handleSubmit}
                            disabled={loading}
                            startIcon={<CheckIcon />}>
                            {loading ? (
                              <CircularProgress size={24} color='inherit' />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </StickyContent>
                      </div>
                    )}
                  </form>
                </div>
                {!isMobile() && (
                  <div className={classes.logoFormBlock}>
                    <Field<string>
                      name='logo'
                      parse={(value) => (!value ? '' : value)}
                      component={({ input }) => (
                        <UserLogo
                          logo={input.value}
                          name={getAbbreviationByName(
                            formProps.values.firstName,
                            formProps.values.lastName,
                          )}
                          onUpload={(photoFile) => {
                            if (validateLogoFile(photoFile)) {
                              setLogoFile(photoFile);
                              const blobFile = URL.createObjectURL(photoFile);
                              input.onChange(blobFile);
                            }
                          }}
                          onRemove={() => {
                            setLogoFile(null);
                            input.onChange('');
                          }}
                          readOnly={
                            !hasAccessToAction('founder.details.update')
                          }
                        />
                      )}
                    />

                    <FormGroup className={classes.statusField}>
                      <Field<string>
                        testid='founder-details-status'
                        name='status'
                        component={FormSelect}
                        label='Status'
                        options={STATUSES_FOUNDERS}
                        readOnly={!hasRole(Role.Manager)}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>
            </FormWrapper>
          );
        }}
      />
    </div>
  );
}

function FormWrapper({
  formProps,
  children,
}: {
  formProps: FormRenderProps<FormValues, FormValues>;
  children: any;
}) {
  const formAPI = useForm();

  useEffect(() => {
    formAPI.change('logo', formProps.initialValues.logo);
  }, [formAPI, formProps.initialValues.logo]);

  return children;
}

export default FounderDetailsForm;
