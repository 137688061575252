import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { getAllCustomQuestions } from '../api/CustomQuestion';
import { Audience } from '../api/CustomQuestion/types/Audience';
import { CustomQuestion } from '../api/CustomQuestion/types/CustomQuestion';
import authAPI, { ConsentType, IVentureRegistrationForm } from '../api/auth';
import { getSettings } from '../api/tenants';
import { TenantId } from '../api/tenants/Tenant';
import { Settings } from '../api/tenants/types/Settings';
import venturesAPI from '../api/ventures';
import { Venture } from '../api/ventures/types/Venture';
import { SnackMessage, Text } from '../components/common';
import {
  Attachment,
  getAttachmentFromFile,
} from '../components/common/attachment-card';
import { ValidFormValues } from '../components/common/wizard';
import VentureApplicationsWizardForm from '../components/forms/report-venture-applications-wizard';
import { COLORS } from '../theme/variables';
import { handleSubmitCustomForm } from '../utils/custom-questions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
}));

function parseFilterFromURL(search: string): any {
  return _pick(parseQuery(search), ['key']);
}

const formatValuesRequest = (
  values: ValidFormValues,
  tenantId: TenantId,
): IVentureRegistrationForm => {
  return {
    venture: {
      ventureName: values.ventureName,
      url: values.url,
      // @ts-expect-error
      numOfEmployees: values.numOfEmployees
        ? Number(values.numOfEmployees)
        : null,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      zip: values.zip,
      tenantId,
      venturesAdditionalInfo: {
        tenantId,
        howDidYouFind: values.howDidYouFind,
        businessDescription: values.businessDescription,
        comments: values.comments,
        currentProgress: values.currentProgress,
        dedication: values.dedication,
        growthRatio: values.growthRatio,
        isLegallyRegistered: values.legally === 'true',
        marketSize: values.marketSize,
        mentorshipPlans: values.mentorshipPlans,
        obstacles: values.obstacles,
        plans: values.plans,
        reasonToStartNow: values.reasonToStartNow,
        requiredSupport: values.requiredSupport,
        yearPlans: values.yearPlans,
      },
    },
    founderList: values.founders
      // @ts-expect-error
      .filter((founder) => Object.keys(founder).length)
      // @ts-expect-error
      .map((founder) => ({
        tenantId,
        firstName: founder.firstName,
        lastName: founder.lastName,
        email: founder.email,
        linkedInProfile: founder.linkedInProfile,
        phone: founder.phone,
        foundersAdditionalInfo: {
          tenantId,
          race: founder.race,
          gender: founder.gender,
          veteran: founder.veteran === 'true',
        },
      })),
    // @ts-expect-error
    ventureSpecializationList: values.specializations.map((id) => ({
      specializationId: id,
    })),
  };
};

function VentureApplicationsWizardPage() {
  const classes = useStyles();
  const location = useLocation();
  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | ''
  >('loading');
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const [consents, setConsents] = useState<ConsentType[]>([]);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [additionalInfoCustomQuestions, setAdditionalInfoCustomQuestions] =
    useState<CustomQuestion[] | null>(null);
  const [createdVenture, setCreatedVenture] = useState<Venture | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [noteAttachments, setNoteAttachments] = useState<Attachment[]>([]);

  const { key } = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );

  const getParseTenantId = (value: string): TenantId => {
    const parseData = value.split('|');
    return parseData[0] as TenantId;
  };

  const tenantId = useMemo(() => {
    return getParseTenantId(atob(key));
  }, [key]);

  const loadData = async () => {
    try {
      const [loadedConsents, settings] = await Promise.all([
        authAPI.getFounderConsents(tenantId),
        getSettings(tenantId as TenantId),
      ]);

      if (settings?.customQuestionsVentureAdditionalInfoEnabled) {
        const customQuestions = await getAllCustomQuestions(
          tenantId as TenantId,
          Audience.VENTURE_ADDITIONAL_INFO,
        );
        setAdditionalInfoCustomQuestions(customQuestions);
      }

      setSettings(settings);
      setConsents(loadedConsents);
      setStatusForm('');
    } catch (e) {
      setStatusForm('error');
    }
  };

  const handleSubmit = async (values: ValidFormValues) => {
    try {
      setIsLoadingSaveForm(true);
      const validValid = formatValuesRequest(values, tenantId);

      const venture = await authAPI.ventureRegistration(validValid);
      setCreatedVenture(venture);
      if (
        settings?.customQuestionsVentureAdditionalInfoEnabled &&
        additionalInfoCustomQuestions?.length
      ) {
        await handleSubmitCustomForm({
          values,
          questions: additionalInfoCustomQuestions,
          responderId: venture.id,
          tenantId: tenantId as TenantId,
          audience: Audience.VENTURE_ADDITIONAL_INFO,
        });
      }
      // setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';

      enqueueSnackbar('An error occurred while enrollment', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleNoteFileUpload = async (file: File) => {
    if (createdVenture?.id) {
      try {
        const fileIndex = noteAttachments.length;
        setNoteAttachments((prevAttachments) => [
          ...prevAttachments,
          getAttachmentFromFile(file),
        ]);
        const fileURL = await venturesAPI.attachPublicFileToNote(
          tenantId,
          createdVenture.id,
          file,
        );
        setNoteAttachments((prevAttachments) => {
          return prevAttachments.map((prevAttach, prevAttachIndex) => {
            if (prevAttachIndex !== fileIndex) {
              return prevAttach;
            }
            return {
              ...prevAttach,
              url: fileURL,
            };
          });
        });
      } catch (e: any) {
        console.error(e);
      }
    }
  };

  const handleNoteFileRemove = (index: number) => {
    setNoteAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  const formatNoteAttachments = (attachments: Attachment[]) => {
    try {
      if (!attachments.length) {
        return '';
      }
      const attachmentRefs = JSON.stringify(attachments);
      return attachmentRefs;
    } catch (e: any) {
      return '';
    }
  };

  const createNote = async () => {
    if (noteAttachments?.length && createdVenture?.id) {
      const attachmentRefs = formatNoteAttachments(noteAttachments);
      await venturesAPI.createPublicNote(tenantId, createdVenture.id, {
        ventureId: createdVenture.id,
        contents:
          'A deck was provided as a part of venture application process.',
        attachmentRefs: attachmentRefs,
      });
    }
  };

  const showSuccess = useCallback(() => {
    setStatusForm('success');
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='venture-wizard-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Success! Your application has been submitted, and a copy of it has
              been sent to your email address.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='wizard-scroll' className={classes.wrapper}>
      <div data-testid='report-page' className={classes.container}>
        <div className={classes.block}>
          <Typography variant='h2' className={classes.title}>
            New Venture Application Form
          </Typography>
          <VentureApplicationsWizardForm
            onSubmit={handleSubmit}
            tenantId={tenantId}
            isLoading={isLoadingSaveForm}
            consents={consents}
            showCustomAdditionalInfo={
              !!settings?.customQuestionsVentureAdditionalInfoEnabled
            }
            additionalInfoCustomQuestions={additionalInfoCustomQuestions}
            intro={settings?.ventureApplicationIntro || ''}
            venture={createdVenture}
            showSuccess={showSuccess}
            noteAttachments={noteAttachments}
            handleNoteFileUpload={handleNoteFileUpload}
            handleNoteFileRemove={handleNoteFileRemove}
            createNote={createNote}
          />
        </div>
      </div>
    </div>
  );
}

export default VentureApplicationsWizardPage;
