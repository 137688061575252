import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { updateGoal } from '../../api/goals';
import { Goal } from '../../api/types/Goal';
import { SnackMessage } from '../../components/common';
import GoalOverviewForm, {
  GoalOverviewFormValues,
} from '../../components/forms/goal-overview';
import { useGoalContext } from '../../contexts/goal-context';

function GoalOverviewPage() {
  const { goal, setGoal, founderVentures } = useGoalContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdateGoalLoading, setUpdateGoalLoading] = useState(false);

  const ventureOptions = useMemo(
    () =>
      founderVentures.map((fv) => ({ value: fv.id, label: fv.ventureName })),
    [founderVentures],
  );

  const handleUpdateGoal = useCallback(
    async (updGoalData: Goal) => {
      try {
        setUpdateGoalLoading(true);
        const updatedGoal = await updateGoal(updGoalData);
        setGoal(updatedGoal);
        setUpdateGoalLoading(false);
        enqueueSnackbar('The goal was successfully updated.', {
          variant: 'success',
        });
      } catch (e: any) {
        setUpdateGoalLoading(false);
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while updating the goal. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, setGoal],
  );

  const handleSubmit = useCallback(
    async (values: GoalOverviewFormValues) => {
      if (goal) {
        const goalData: Goal = {
          ...goal,
          title: values.title || '',
          ventureId: values.ventureId,
          startDate: values.startDate || null,
          targetDate: values.targetDate || null,
          description: values.description || '',
        };
        await handleUpdateGoal(goalData);
      }
    },
    [goal, handleUpdateGoal],
  );

  return (
    <GoalOverviewForm
      initialData={goal}
      loading={isUpdateGoalLoading}
      handleSubmit={handleSubmit}
      ventureOptions={ventureOptions}
    />
  );
}

export default GoalOverviewPage;
