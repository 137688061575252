import { ButtonProps } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { TestId } from '../../Testing/TestId';
import Button from '../button';
import { ActionsDialog } from './ActionsDialog';

interface BeforeSubmitProps extends PropsWithChildren<{}> {
  open: boolean;
  title: string;
  submitProps: ButtonProps;
  onClose: () => void;
  cancelProps?: ButtonProps;
}

function BeforeSubmit({
  title,
  open,
  submitProps,
  cancelProps,
  onClose,
  children,
}: BeforeSubmitProps) {
  return ReactDOM.createPortal(
    <ActionsDialog
      title={title}
      open={open}
      onClose={onClose}
      actions={
        <>
          {cancelProps && (
            <TestId testId={'warning-before-submit-cancel'}>
              <Button {...cancelProps}>{cancelProps.children}</Button>
            </TestId>
          )}
          <TestId testId={'warning-before-submit-ok'}>
            <Button {...submitProps}>{submitProps.children}</Button>
          </TestId>
        </>
      }>
      {children}
    </ActionsDialog>,
    document.body,
  );
}

export default BeforeSubmit;
