import { Email } from '../../../utils/String/Email';
import { AdvisorId } from '../../Advisors/types/Advisor';
import { TenantId } from '../../tenants/Tenant';
import { TenantRFCDate } from '../../types/TenantRFCDate';
import { Status } from './Status';

declare const id: unique symbol;

export type AppointmentId = string & { [id]: 'Appointment' };

export enum AppointmentPaymentStatus {
  NOT_STARTED = 'NOT_STARTED',
  INITIAL = 'INITIAL',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
}

export interface Appointment {
  id: AppointmentId;
  status: Status;
  requestorEmail: Email;
  requestorFullName: string;
  tenantId: TenantId;
  advisorId: AdvisorId;
  advisorFullName: string;
  topic: string;
  agenda: string;
  start: TenantRFCDate;
  end: TenantRFCDate;
  creationDate: string;
  valid: boolean;
  paymentStatus: AppointmentPaymentStatus;
  requestorTimeSlots?: string;
  guestEmails?: string;
  paymentUrl?: string | null;
  responseId?: string;
  amount?: number | null;
  refunded?: boolean;
  roomUrl?: string | null;
  roomCode?: string | null;
}

export interface CreateAppointment {
  topic: string;
  start: TenantRFCDate;
  end: TenantRFCDate;
  roomUrl: string | null;
  requestorFullName: string;
  requestorEmail: Email;
  status: Status;
  advisorId: AdvisorId;
  advisorFullName: string;
  agenda: string;
}

export interface CreatePrivate {
  tenantId: TenantId;
  advisorId: AdvisorId;
  topic: string;
  agenda: string;
  start?: TenantRFCDate;
  end?: TenantRFCDate;
  requestorTimeSlots?: string;
}

export interface Create extends CreatePrivate {
  requestorEmail: string;
  requestorFullName: string;
}

export interface AppointmentLog {
  id: string;
  creationDate: string;
  scheduledOfficeHoursId: string;
  contents: string;
}
