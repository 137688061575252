import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { getCommentDate } from '../../../utils/date';
import { TestId } from '../../Testing/TestId';
import Button from '../button';
import { ActionsDialog } from './ActionsDialog';

interface WarningBeforeStartProps {
  date: string;
}

function WarningBeforeStart({ date }: WarningBeforeStartProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  useEffect(() => {
    if (new Date() < new Date(date)) {
      setIsDialogOpen(true);
    }
  }, [date]);

  return ReactDOM.createPortal(
    <ActionsDialog
      title={'Future Session'}
      open={isDialogOpen}
      onClose={onClose}
      actions={
        <TestId testId={'warning-before-start-ok'}>
          <Button variant={'outlined'} onClick={onClose}>
            Ok
          </Button>
        </TestId>
      }>
      You have opened a report for a future session scheduled on{' '}
      {getCommentDate(date)}. Are you sure you want to proceed? Typically,
      reports are submitted for past sessions. Please verify that you are using
      the correct link.
    </ActionsDialog>,
    document.body,
  );
}

export default WarningBeforeStart;
