import { makeStyles, Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { memo, useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { CustomQuestionCard } from '../../components/CustomQuestions/CustomQuestionCard';
import { TestId } from '../../components/Testing/TestId';
import {
  CreateModalState,
  useCustomQuestionContext,
} from '../../contexts/custom-questions-context';
import { UserContext } from '../../contexts/user-context';
import { Text } from '../common';

const useStyles = makeStyles({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    marginTop: 20,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
});

export const CustomQuestionsList = memo(() => {
  const classes = useStyles();
  const { questions, onDragEnd, setShowCreateModal } =
    useCustomQuestionContext();
  const { hasAccessToAction } = useContext(UserContext);
  return !questions.length ? (
    <div className={classes.emptyBlock}>
      <div className={classes.emptyState}>
        <Text variant='normal' testid='custom-questions-list-no-items'>
          This panel contains a list of custom questions.
          <br />
          {hasAccessToAction('customQuestions.edit')
            ? 'We were not able to find information you requested, but feel free to add something new!'
            : ''}
        </Text>
        {hasAccessToAction('customQuestions.edit') && (
          <TestId testId='add-button'>
            <MaterialButton
              className={classes.emptyStateBtn}
              startIcon={<AddIcon />}
              variant='contained'
              onClick={() =>
                setShowCreateModal(CreateModalState.SHOW_CREATE_QUESTION_MODAL)
              }
              color='primary'>
              Add Question
            </MaterialButton>
          </TestId>
        )}
      </div>
    </div>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-list'>
        {(provided) => (
          <div
            className={classes.listContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {questions.map((item, index) => (
              <CustomQuestionCard item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
