import { axiosRequest } from './instance';
import { TenantId } from './tenants/Tenant';

export function getPublicFileLink(
  id: TenantId,
  urlCode: string,
): Promise<string> {
  return axiosRequest
    .get<string>({ url: `/public/tenants/${id}/links/${urlCode}` })
    .then((r) => r.data);
}
