import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import integrationAPI, {
  ISlackIntegrationDetails,
} from '../../api/integration';

interface SlackIntegrationButtonProps {
  className?: string;
}

function SlackIntegrationButton({ className }: SlackIntegrationButtonProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [slackIntegrationDetails, setSlackIntegrationDetails] =
    useState<ISlackIntegrationDetails>();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getSlackDetails = async () => {
      try {
        setError(false);
        setLoading(true);

        const query = new URLSearchParams(location.search);
        const code = query.get('code');

        if (code) {
          try {
            const integrationResponse =
              await integrationAPI.createSlackIntegration(code);

            if (integrationResponse.status === 200) {
              enqueueSnackbar('Slack Integration is Complete.', {
                variant: 'success',
              });
            } else {
              throw new Error('Slack integration was failed');
            }
          } catch (error) {
            enqueueSnackbar(
              'Unfortunately we were unable to enable Slack integration. Please get in touch with Traction5 support team.',
              { variant: 'error' },
            );
          }
        }

        const details = await integrationAPI.getSlackIntegrationData();
        setSlackIntegrationDetails(details);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error(error);
      }
    };

    getSlackDetails();
  }, [enqueueSnackbar, location.search]);

  return (
    <Button
      variant='outlined'
      className={className}
      disabled={isLoading || isError}
      onClick={() =>
        window.open(slackIntegrationDetails?.slackIntegrationUrl, '_blank')
      }>
      {isError ? (
        'Connection error'
      ) : isLoading ? (
        <CircularProgress size={24} color='inherit' />
      ) : (
        <>
          <SlackLogo /> Add to Slack
        </>
      )}
    </Button>
  );
}

function SlackLogo() {
  return (
    <svg
      version='1.1'
      id='slack-icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='20px'
      height='20px'
      viewBox='0 0 270 270'
      style={{ marginRight: '8px' }}>
      <g transform='matrix(2.163254, 0, 0, 2.163254, -157.152939, -157.0392)'>
        <g>
          <path
            fill='#E01E5A'
            d='M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z'
          />
          <path
            fill='#E01E5A'
            d='M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9
			s-12.9-5.8-12.9-12.9V151.2z'
          />
        </g>
        <g>
          <path
            fill='#36C5F0'
            d='M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z'
          />
          <path
            fill='#36C5F0'
            d='M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9
			s5.8-12.9,12.9-12.9H118.8z'
          />
        </g>
        <g>
          <path
            fill='#2EB67D'
            d='M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z'
          />
          <path
            fill='#2EB67D'
            d='M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9
			c7.1,0,12.9,5.8,12.9,12.9V118.8z'
          />
        </g>
        <g>
          <path
            fill='#ECB22E'
            d='M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z'
          />
          <path
            fill='#ECB22E'
            d='M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9
			c0,7.1-5.8,12.9-12.9,12.9H151.2z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SlackIntegrationButton;
