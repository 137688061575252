import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { SyntheticEvent } from 'react';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';

type VARIANT = 'normal' | 'normal2' | 'semibold' | 'upper1';

interface TextProps {
  className?: string;
  inherit?: boolean;
  variant?: VARIANT;
  bold?: boolean;
  color?: COLORS;
  truncated?: boolean;
  children: React.ReactNode;
  onClick?: (e: SyntheticEvent) => any;
  testid?: string;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: COLORS.COLOR_TEXT_BASE,
    fontFamily: INTER_FONT_FAMILY,

    '& b, & strong': {
      fontWeight: 'revert',
    },
    '& i, & u': {
      fontStyle: 'revert',
    },
  },
  colorInherit: {
    color: 'inherit',
  },
  normal: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '24px',

    [theme.breakpoints.up('xs')]: {
      fontSize: 14,
      lineHeight: '23px',
    },
  },
  normal2: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',

    [theme.breakpoints.up('xs')]: {
      fontSize: 13,
      lineHeight: '19px',
    },
  },
  upper1: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '1px',

    [theme.breakpoints.up('xs')]: {
      fontSize: 12,
      lineHeight: '15px',
    },
  },
  semibold: {
    fontWeight: 600,
  },
  truncated: {
    maxWidth: '100%',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

function Text({
  className,
  inherit,
  variant,
  bold,
  color,
  truncated,
  children,
  testid,
  onClick,
  title,
}: TextProps) {
  const classes = useStyles();

  return (
    <span
      onClick={onClick}
      data-testid={testid}
      title={title}
      className={cn(classes.text, className, {
        [classes.normal]: variant === 'normal',
        [classes.normal2]: variant === 'normal2',
        [classes.upper1]: variant === 'upper1',
        [classes.semibold]: bold,
        [classes.colorInherit]: inherit,
        [classes.truncated]: truncated,
      })}
      style={{
        color,
      }}>
      {children}
    </span>
  );
}

export default Text;
