import { useCallback, useEffect, useRef, useState } from 'react';
import foundersAPI, { Founder } from '../../api/founders';

interface InputRendererProps {
  value: string;
  onChange: (event: React.ChangeEvent<any>) => any;
  isLoading: boolean;
}

interface FounderAutocompleteProps {
  inputRenderer: (props: InputRendererProps) => React.ReactNode;
  onSearch: (founders: Founder[]) => any;
  iteration?: number;
  nonusers?: boolean;
}

function FounderAutocomplete({
  inputRenderer,
  onSearch,
  iteration,
  nonusers,
}: FounderAutocompleteProps) {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const debouncedTimer = useRef<number>();
  const currentValue = useRef<string>(value);

  const searchFounders = async (founderName: string) => {
    try {
      const foundedFounders = nonusers
        ? await foundersAPI.searchNonusers(founderName)
        : await foundersAPI.search(founderName);
      setIsLoading(false);
      if (currentValue.current === founderName) {
        onSearch(foundedFounders);
      }
    } catch (e: any) {
      setIsLoading(false);
      if (currentValue.current === founderName) {
        onSearch([]);
      }
    }
  };

  const handleChange = useCallback(
    (e) => {
      const originalValue = e.target.value;
      clearTimeout(debouncedTimer.current);
      setIsLoading(false);
      setValue(originalValue);
      currentValue.current = originalValue.trim();

      debouncedTimer.current = setTimeout(() => {
        const trimmedValue = originalValue.trim();
        if (trimmedValue.length > 2) {
          setIsLoading(true);
          searchFounders(trimmedValue);
        } else {
          onSearch([]);
        }
      }, 800) as unknown as number;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSearch],
  );

  useEffect(() => {
    if (iteration) {
      setValue('');
      currentValue.current = '';
      onSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration]);

  return <>{inputRenderer({ value, onChange: handleChange, isLoading })}</>;
}

export default FounderAutocomplete;
