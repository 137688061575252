import { useState, useContext, useRef } from 'react';
import { makeStyles, Popover, CircularProgress } from '@material-ui/core';
import { Text } from '../common';
import { COLORS } from '../../theme/variables';
import { IssueContext } from '../../contexts/issue-context';
import { useEffect } from 'react';
import eventsAPI, { EventTag } from '../../api/events';
import { isTablet } from '../../utils/functions';
import { useHistory } from 'react-router-dom';
import { getRoutePath, Pages } from '../../router/constants';

interface VentureTableInfoProps {
  sessionId: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 300,
    boxSizing: 'border-box'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    minWidth: 30,
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
    textAlign: 'center',
    borderRadius: 16,
    padding: 7,

    '& + &': {
      marginTop: 10
    }
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function IssueTableInfo({ sessionId, children }: VentureTableInfoProps) {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [value, setValue] = useState<string[]>([]);
  const [sessionIssuesFull, setSessionIssuesFull] = useState<EventTag[]>();
  const { issue, loadIssue } = useContext(IssueContext);
  const debouncedTimer = useRef<number>();

  const loadSessionIssues = async (eventId: string) => {
    try {
      const issues = await eventsAPI.getTags(eventId);

      setSessionIssuesFull(issues);
    } catch (e: any) {
      console.log('error loadSessionIssies', e);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = async (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      loadSessionIssues(sessionId);
      loadIssue();
      setAnchorEl(event.target as HTMLElement);
      setIsOpen(true);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    handleClose();
  };

  const handleClick = () => {
    if (!isTablet()) {
      history.push(
        getRoutePath(Pages.SESSIONS_DETAILS, {
          sessionId: sessionId
        })
      );
    }
  };

  useEffect(() => {
    if (sessionIssuesFull && issue) {
      const currentValue =
        sessionIssuesFull.map((sessionIssueFull) => {
          return (
            issue.find(({ id }) => id === sessionIssueFull.tagId)?.name || ''
          );
        }) || [];
      setValue(currentValue);
    }
  }, [issue, sessionIssuesFull]);

  return (
    <>
      <div
        className={classes.wrapper}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        {value && !!value.length ? (
          <div>
            {value.map((value) => (
              <div key={`issue-info-${value}`} className={classes.paragraph}>
                <Text variant='normal'>{value}</Text>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.loadingBlock}>
            <CircularProgress size={30} color='primary' />
          </div>
        )}
      </Popover>
    </>
  );
}

export default IssueTableInfo;
