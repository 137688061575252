import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Audience } from '../../api/CustomQuestion/types/Audience';
import venturesAPI from '../../api/ventures';
import { VenturesAdditionalInfo } from '../../api/ventures/types/VenturesAdditionalInfo';
import { PageLoader, SnackMessage } from '../../components/common';
import CustomForm from '../../components/forms/custom-form';
import VentureAdditionalInfoForm, {
  FormAdditionalInfo,
} from '../../components/forms/venture-additional-info';
import { UserContext } from '../../contexts/user-context';
import { VentureContext } from '../../contexts/venture-context';
import { formatDateToRFC } from '../../utils/date';

interface VentureAdditionalInfoDefaultProps {
  hasAccessToUpdate?: boolean;
}

const useStyles = makeStyles({
  table: {
    marginBottom: 32,
  },
  loader: {
    marginTop: 200,
  },
});

const getInitialValues = (
  value: VenturesAdditionalInfo | undefined,
): FormAdditionalInfo => {
  return value
    ? {
        howDidYouFind: value.howDidYouFind,
        isLegallyRegistered: value.isLegallyRegistered,
        businessDescription: value.businessDescription,
        currentProgress: value?.currentProgress,
        requiredSupport: value?.requiredSupport,
        marketSize: value?.marketSize,
        reasonToStartNow: value?.reasonToStartNow,
        plans: value?.plans,
        growthRatio: value?.growthRatio,
        dedication: value?.dedication,
        yearPlans: value?.yearPlans,
        obstacles: value?.obstacles,
        mentorshipPlans: value?.mentorshipPlans,
        comments: value?.comments,
      }
    : {
        howDidYouFind: null,
        isLegallyRegistered: false,
        businessDescription: null,
        currentProgress: null,
        requiredSupport: null,
        marketSize: null,
        reasonToStartNow: null,
        plans: null,
        growthRatio: null,
        dedication: null,
        yearPlans: null,
        obstacles: null,
        mentorshipPlans: null,
        comments: null,
      };
};

const getParsedValues = (value: FormAdditionalInfo) => {
  return {
    howDidYouFind: value.howDidYouFind,
    isLegallyRegistered: value.isLegallyRegistered,
    businessDescription: value.businessDescription,
    currentProgress: value.currentProgress,
    requiredSupport: value.requiredSupport,
    marketSize: value.marketSize,
    reasonToStartNow: value.reasonToStartNow,
    plans: value.plans,
    growthRatio: value.growthRatio,
    dedication: value.dedication,
    yearPlans: value.yearPlans,
    obstacles: value.obstacles,
    mentorshipPlans: value.mentorshipPlans,
    comments: value.comments,
  };
};

function VentureAdditionalInfoPage() {
  const location = useLocation();
  const { ventureId } = useParams<{ ventureId: string }>();
  const { user, hasAccessToAction } = useContext(UserContext);

  const hasAccessToUpdate = useMemo(() => {
    const isExternalPage = location.pathname.includes('external');
    return isExternalPage
      ? true
      : hasAccessToAction('venture.additionalInfo.update');
  }, [hasAccessToAction, location.pathname]);

  return (
    <div>
      {user?.customQuestionsVentureAdditionalInfoEnabled ? (
        <CustomForm
          audience={Audience.VENTURE_ADDITIONAL_INFO}
          responderId={ventureId}
          successMessage='Additional information successfully updated.'
          hasAccessToUpdate={hasAccessToUpdate}
        />
      ) : (
        <VentureAdditionalInfoDefault hasAccessToUpdate={hasAccessToUpdate} />
      )}
    </div>
  );
}

function VentureAdditionalInfoDefault({
  hasAccessToUpdate,
}: VentureAdditionalInfoDefaultProps) {
  const classes = useStyles();
  const { ventureId } = useParams<{ ventureId: string }>();
  const { venture, updateVenture } = useContext(VentureContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = getInitialValues(venture?.venturesAdditionalInfo);

  const handleSubmitForm = async (value: FormAdditionalInfo) => {
    try {
      setIsLoading(true);
      if (venture) {
        const parsedValues = getParsedValues(value);
        const venturesAdditionalInfo =
          venture?.venturesAdditionalInfo &&
          venture?.venturesAdditionalInfo.ventureId
            ? {
                ...venture?.venturesAdditionalInfo,
                ...parsedValues,
              }
            : {
                ...parsedValues,
                creationDate: formatDateToRFC(new Date()),
              };

        const updatedVenture = await venturesAPI.update(ventureId, {
          ...venture,
          venturesAdditionalInfo: venturesAdditionalInfo,
        });
        updateVenture(updatedVenture);
      }
      setIsLoading(false);
      enqueueSnackbar('Additional information successfully updated.', {
        variant: 'success',
      });
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar('An error occurred while saving. Please, try again.', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <VentureAdditionalInfoForm
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={handleSubmitForm}
        hasAccessToUpdate={hasAccessToUpdate}
      />
    </div>
  );
}

export default VentureAdditionalInfoPage;
