import { IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { MouseEvent, useCallback, useState } from 'react';
import EventsAPI, { Event } from '../../api/events';
import { COLORS } from '../../theme/variables';
import { ConfirmDialog, Text } from '../common';
import { Loading } from '../common/ActionIcons/Loading';

interface AcceptanceToggleProps {
  session?: Event;
  assignmentStatus?: string | null;
}

const useStyles = makeStyles({
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    width: 'auto',
  },
  tooltip: {
    userSelect: 'none',
  },
  toggleButtonGroup: {
    '& button': {
      padding: '2px 16px',
      color: COLORS.COLOR_BLUE_BASE,
      fontSize: 12,
      textTransform: 'none',
      borderColor: COLORS.COLOR_BLUE_LIGHTENED_25,

      '&:hover': {
        backgroundColor: 'rgba(37, 117, 255, 0.04)',
        borderColor: COLORS.COLOR_BLUE_BASE,
      },

      '&.Mui-selected': {
        '&[value="ACCEPTED"]': {
          color: COLORS.COLOR_ADDITIONAL_WHITE,
          backgroundColor: COLORS.COLOR_GREEN_LIGHTENED_10,
          borderColor: COLORS.COLOR_GREEN_LIGHTENED_10,
          '&:hover': {
            backgroundColor: COLORS.COLOR_GREEN_LIGHTENED_10,
            borderColor: COLORS.COLOR_GREEN_LIGHTENED_10,
          },
        },
        '&[value="DECLINED"]': {
          color: COLORS.COLOR_ADDITIONAL_WHITE,
          backgroundColor: COLORS.COLOR_RED_BASE,
          borderColor: COLORS.COLOR_RED_BASE,
          '&:hover': {
            backgroundColor: COLORS.COLOR_RED_BASE,
            borderColor: COLORS.COLOR_RED_BASE,
          },
        },
      },

      '@media (max-width: 700px)': {
        padding: '2px 8px',
      },
    },
  },
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
});

function AcceptanceToggle({
  session,
  assignmentStatus,
}: AcceptanceToggleProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState<'ACCEPTED' | 'DECLINED' | undefined>(
    assignmentStatus === 'ACCEPTED'
      ? 'ACCEPTED'
      : assignmentStatus === 'DECLINED'
      ? 'DECLINED'
      : undefined,
  );
  const [isLoading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState<'ACCEPTED' | 'DECLINED' | null>(
    null,
  );

  const handleChange = useCallback(
    async (_: MouseEvent, value: 'ACCEPTED' | 'DECLINED') => {
      setShowDialog(value);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (!session || !showDialog) {
      return;
    }

    try {
      setLoading(true);

      switch (showDialog) {
        case 'ACCEPTED':
          await EventsAPI.confirmAttendance(session.id);
          setSelected(showDialog);
          enqueueSnackbar('Thank you, your RSVP was recorded', {
            variant: 'success',
          });
          break;
        case 'DECLINED':
          await EventsAPI.rejectAttendance(session.id);
          setSelected(showDialog);
          enqueueSnackbar('Thank you, your RSVP was recorded', {
            variant: 'success',
          });
          break;
        default:
          break;
      }

      setShowDialog(null);
    } catch (error) {
      enqueueSnackbar('Failed to update attendance', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, session, showDialog]);

  if (!session) {
    return null;
  }

  return (
    <div className={classes.toggleWrapper}>
      <Tooltip
        title='You can accept or decline this session here without using your email and calendar'
        leaveTouchDelay={5000}
        enterTouchDelay={0}
        className={classes.tooltip}>
        <IconButton className={classes.buttonInfoIcon}>
          <InfoIcon className={classes.infoIcon} />
        </IconButton>
      </Tooltip>
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleChange}
        className={classes.toggleButtonGroup}>
        <ToggleButton value={'ACCEPTED'} disabled={isLoading}>
          Accept
        </ToggleButton>
        <ToggleButton value={'DECLINED'} disabled={isLoading}>
          Decline
        </ToggleButton>
      </ToggleButtonGroup>
      <ConfirmDialog
        isOpen={!!showDialog}
        title='Sending invitations'
        body={
          <Text variant='normal'>
            This action will change your session acceptance status and send a
            notification to admin. Are you sure?
          </Text>
        }
        successProps={{
          label: isLoading ? <Loading /> : 'Yes',
          'data-testid': 'confirm-acceptance',
          variant: 'contained',
          disabled: isLoading,
        }}
        cancelProps={{
          label: 'No',
          'data-testid': 'cancel-acceptance',
          variant: 'outlined',
        }}
        onCancel={() => setShowDialog(null)}
        onSuccess={handleSubmit}
      />
    </div>
  );
}

export default AcceptanceToggle;
