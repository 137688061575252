import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import React, { useMemo } from 'react';
import { pipe } from 'rxjs';
import {
  Specialization,
  SpecializationId,
} from '../../../../api/specializations';
import { ClearFilters } from '../../../../components/Pages/Listing/ClearFilters';
import { Search } from '../../../../components/Pages/Listing/Search';
import { TestId } from '../../../../components/Testing/TestId';
import { Autocomplete } from '../../../../components/common/Autocomplete';
import { Filter, none, search, specialization } from './types/Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 40px',
    alignItems: 'center',
    gap: 20,
    marginBottom: '24px',
    minHeight: 56,
    position: 'relative',

    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '100%',
    },
  },
  clear: {
    [theme.breakpoints.down(600)]: {
      position: 'absolute',
      right: '0',
      top: '0',
    },
  },
}));

export interface Props {
  filter: Filter;
  onChange: (filter: Filter) => void;
  specializations: Specialization[];
}

export function FiltersHeader({ filter, onChange, specializations }: Props) {
  const classes = useStyles();

  const specializationOptions = useMemo(
    () => specializations.map((s) => ({ value: s.id, label: s.name })),
    [specializations],
  );
  const specializationValue = useMemo(
    () => (filter.type === 'Specialization' ? filter.value : undefined),
    [filter],
  );
  const selectedSpecialization = useMemo(
    () => specializationOptions.find((s) => s.value === specializationValue),
    [specializationOptions, specializationValue],
  );

  return (
    <Card className={classes.root}>
      <TestId testId={'search-advisor'}>
        <Search
          value={filter.type === 'Search' ? filter.value : ''}
          onChange={pipe((v: string) => (v ? search(v) : none()), onChange)}
          small={false}
        />
      </TestId>
      <TestId testId={'search-by-tag'}>
        <Autocomplete<SpecializationId, false>
          multiple={false}
          value={specializationValue}
          defaultInputValue={selectedSpecialization?.label}
          options={specializationOptions}
          onChange={(id) => onChange(specialization(id))}
          testId={'specializations-select'}
          disabled={specializations.length === 0}
          textFieldProps={{ placeholder: 'Select speciality' }}
        />
      </TestId>
      {filter.type !== 'None' && (
        <ClearFilters
          onClick={() => onChange(none())}
          size={'normal'}
          className={classes.clear}
        />
      )}
    </Card>
  );
}
