import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import cn from 'classnames';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { DialogTitle } from '../modal';
import Text from '../text';

export interface Props {
  open: boolean;
  onClose: () => void;
  title: ReactElement | string;
  actions: ReactNode;
  className?: string;
}

export function ActionsDialog({
  open,
  onClose,
  title,
  children,
  actions,
  className,
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      data-testid='actions-dialog'
      PaperProps={{
        className: cn(classes.paper, className),
        elevation: 3,
      }}>
      <DialogTitle id='alert-dialog-title' onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Text variant='normal'>{children}</Text>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  paper: {
    width: 480,
  },
  content: {
    padding: '15px 32px 0',
  },
});
