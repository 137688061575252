// region Loading
export interface LoadingPayload {}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload {
  advisors: {
    total: number;
    thisWeek: number;
    nextWeek: number;
    afterNextWeek: number;
  };
  communityMembers: {
    total: number;
    joinedLastMonth: number;
    withAppointments: number;
    applicants: number;
  };
  appointments: {
    total: number;
    accepted: number;
    declinedUnconfirmed: number;
  };
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

export type State = Loading | LoadError | Ready;
