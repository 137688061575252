import { IconButton, makeStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePersonalNotes } from '../../../contexts/personal-notes-context';
import { COLORS } from '../../../theme/variables';

interface CommentCardDropdownProps {
  commentId: string;
  containerRef?: RefObject<HTMLDivElement>;
}

interface DropdownMenuProps {
  commentId: string;
  buttonRef: React.RefObject<HTMLButtonElement>;
  onClickOutside?: () => void;
}

const useStyles = makeStyles({
  moreBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dropdown: {
    minWidth: 120,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    borderRadius: 4,
    overflow: 'hidden',
  },
  dropdownItem: {
    padding: '8px 16px',
    fontSize: 14,
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      backgroundColor: COLORS.COLOR_BLUE_BASE,
      color: '#fff',
    },
  },
});

const DropdownMenu: FC<DropdownMenuProps> = ({
  commentId,
  buttonRef,
  onClickOutside,
}) => {
  const classes = useStyles();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { deleteComment } = usePersonalNotes();

  useEffect(() => {
    const button = buttonRef.current;
    const dropdown = dropdownRef.current;

    if (!button || !dropdown) {
      return;
    }

    const placeDropdown = () => {
      requestAnimationFrame(() => {
        const buttonRect = button.getBoundingClientRect();
        const top = buttonRect.bottom + 2;
        const left = buttonRect.right - dropdown.offsetWidth;

        dropdown.style.position = 'absolute';
        dropdown.style.top = `${top}px`;
        dropdown.style.left = `${left}px`;
      });
    };

    const resizeObserver = new ResizeObserver(placeDropdown);
    resizeObserver.observe(button);

    placeDropdown();

    window.addEventListener('resize', placeDropdown);

    return () => {
      window.removeEventListener('resize', placeDropdown);
      resizeObserver.unobserve(button);
    };
  }, [buttonRef, dropdownRef]);

  useEffect(() => {
    if (!onClickOutside) {
      return;
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node) &&
        !buttonRef.current?.contains(e.target as Node)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef, onClickOutside]);

  return ReactDOM.createPortal(
    <div ref={dropdownRef} className={classes.dropdown}>
      <div
        className={classes.dropdownItem}
        onClick={() => deleteComment(commentId)}>
        Delete
      </div>
    </div>,
    document.body,
  );
};

const CommentCardDropdown: FC<CommentCardDropdownProps> = ({
  commentId,
  containerRef,
}) => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const container = containerRef?.current;

    if (container && showDropdown) {
      container.onscroll = () => {
        setShowDropdown(false);
      };

      return () => {
        container.onscroll = null;
      };
    }
  }, [containerRef, showDropdown]);

  return (
    <>
      <IconButton
        ref={buttonRef}
        size='small'
        className={classes.moreBtn}
        onClick={() => setShowDropdown((prev) => !prev)}>
        <MoreHorizIcon fontSize='small' />
      </IconButton>
      {showDropdown && (
        <DropdownMenu
          commentId={commentId}
          buttonRef={buttonRef}
          onClickOutside={() => setShowDropdown(false)}
        />
      )}
    </>
  );
};

export default CommentCardDropdown;
