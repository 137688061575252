import {
  Button as MaterialButton,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { TestId } from '../../components/Testing/TestId';
import { RouteACL } from '../../components/common';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/tenant/page-tabs';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useCurrentPath } from '../../hooks/router';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { checkDynamicPath } from '../../utils/functions';
import TenantAccountPage from './tenant-account-page';
import TenantAutomationPage from './tenant-automation-page';
import TenantChannelsPage from './tenant-channels-page';
import { TenantCohortsPage } from './tenant-cohorts-page';
import TenantConsentsPage from './tenant-consents-page';
import TenantCustomQuestionsEdit from './tenant-custom-questions-edit';
import TenanCustomQuestionstPage from './tenant-custom-questions-page/index';
import TenantCustomSpecializationsPage from './tenant-custom-specializations';
import TenantEditFormPage from './tenant-edit-form-page';
import TenantFormsPage from './tenant-forms-page';
import TenantNewAdmin from './tenant-new-admin';
import { TenantNewFounderPage } from './tenant-new-founder';
import TenantNewMentor from './tenant-new-mentor';
import TenantProfilePage from './tenant-profile-page';
import { TenantQuestionsPage } from './tenant-questions-page';
import TenantSettingsPage from './tenant-settings-page';
import TenantUserManagementPage from './tenant-user-management';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  autoHeight: {
    flexGrow: 'unset',
  },
  content: {
    padding: 40,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paddingNone: {
    padding: 0,
  },
  newButton: {
    whiteSpace: 'nowrap',
    minWidth: 80,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 15,
  },
});

function TenantPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const currentPath = useCurrentPath();
  const { hasAccessToAction } = useContext(UserContext);
  const { rb } = useResourceBundles();

  const backUrl = useMemo(() => {
    const arrayTenantNew = [
      Pages.TENANT_NEW_ADMIN,
      Pages.TENANT_NEW_MENTOR,
      Pages.TENANT_NEW_FOUNDER,
    ] as string[];

    if (
      location.pathname.endsWith(Pages.TENANT_COHORTS) ||
      location.pathname.endsWith(Pages.TENANT_AUTOMATION) ||
      location.pathname.endsWith(Pages.TENANT_QUESTIONS) ||
      location.pathname.endsWith(Pages.TENANT_CUSTOM_SPECIALIZATIONS)
    ) {
      return Pages.TENANT_SETTINGS;
    }

    if (arrayTenantNew.includes(location.pathname)) {
      return Pages.TENANT_USER_MANAGEMENT;
    }

    if (
      checkDynamicPath(location.pathname, Pages.TENANT_CUSTOM_QUESTIONS_EDIT)
    ) {
      return Pages.TENANT_CUSTOM_QUESTIONS;
    }

    if (checkDynamicPath(location.pathname, Pages.TENANT_FORMS_EDIT)) {
      return Pages.TENANT_FORMS;
    }
    return prevPath || Pages.DASHBOARD;
  }, [location, prevPath]);

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title={user.programName}
        backLink={
          <TestId testId={'back-link'}>
            <Tooltip title='Back'>
              <IconButton component={Link} to={backUrl}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </TestId>
        }
        actions={
          location.pathname === Pages.TENANT_USER_MANAGEMENT
            ? [
                {
                  key: 'new-admin',
                  label: 'New Admin',
                  hidden: !hasAccessToAction('userManagement.admin'),
                  component: (
                    <MaterialButton
                      data-testid='tenant-new-admin'
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.TENANT_NEW_ADMIN),
                        state: {
                          prevPath: currentPath,
                        },
                      }}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'
                      className={classes.newButton}>
                      Admin
                    </MaterialButton>
                  ),
                },
                {
                  key: 'new-founder',
                  label: 'New Founder',
                  hidden: !hasAccessToAction('userManagement.founder'),
                  component: (
                    <MaterialButton
                      data-testid='tenant-new-founder'
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.TENANT_NEW_FOUNDER),
                        state: {
                          prevPath: currentPath,
                        },
                      }}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'
                      className={classes.newButton}>
                      Founder
                    </MaterialButton>
                  ),
                },
                {
                  key: 'new-mentor',
                  label: `New ${rb('mentor-u')}`,
                  hidden: !hasAccessToAction('userManagement.mentor'),
                  component: (
                    <MaterialButton
                      data-testid='tenant-new-mentor'
                      component={Link}
                      to={{
                        pathname: getRoutePath(Pages.TENANT_NEW_MENTOR),
                        state: {
                          prevPath: currentPath,
                        },
                      }}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'
                      className={classes.newButton}>
                      {rb('mentor-u')}
                    </MaterialButton>
                  ),
                },
              ]
            : []
        }
      />
      <div className={classes.wrapper}>
        <Paper
          className={cn(classes.container, {
            [classes.autoHeight]: currentPath === Pages.TENANT_USER_MANAGEMENT,
          })}
          elevation={1}>
          <PageTabs />
          <div
            className={cn(classes.content, {
              [classes.paddingNone]:
                location.pathname === Pages.TENANT_USER_MANAGEMENT,
            })}>
            <Switch>
              <RouteACL
                exact
                path={Pages.TENANT_PROFILE}
                component={TenantProfilePage}
                rule='edit'
                redirectUrl={Pages.DASHBOARD}
              />
              <Route
                exact
                path={Pages.TENANT_ACCOUNT}
                component={TenantAccountPage}
              />
              <Route
                exact
                path={Pages.TENANT_QUESTIONS}
                component={TenantQuestionsPage}
              />
              <RouteACL
                exact
                path={Pages.TENANT_COHORTS}
                component={TenantCohortsPage}
              />
              <RouteACL
                exact
                path={Pages.TENANT_CUSTOM_SPECIALIZATIONS}
                component={TenantCustomSpecializationsPage}
                rule='specialization.update'
              />
              <RouteACL
                exact
                path={Pages.TENANT_SETTINGS}
                component={TenantSettingsPage}
                rule='settings.view'
              />
              <RouteACL
                exact
                path={Pages.TENANT_AUTOMATION}
                component={TenantAutomationPage}
                rule='automation.view'
              />
              <RouteACL
                exact
                path={Pages.TENANT_CHANNELS}
                component={TenantChannelsPage}
                rule='channels.view'
              />
              <RouteACL
                exact
                path={Pages.TENANT_USER_MANAGEMENT}
                component={TenantUserManagementPage}
                rule='userManagement.view'
              />
              <RouteACL
                exact
                path={Pages.TENANT_FORMS}
                component={TenantFormsPage}
                // module='enrollment'
                rule='modules.enrollment.view'
                redirectUrl={Pages.TENANT_PROFILE}
              />
              <RouteACL
                exact
                path={Pages.TENANT_FORMS_EDIT}
                component={TenantEditFormPage}
                rule='modules.enrollment.update'
                redirectUrl={Pages.TENANT_PROFILE}
              />
              <RouteACL
                exact
                path={Pages.TENANT_CONSENTS}
                component={TenantConsentsPage}
                rule='consents.view'
              />
              <RouteACL
                exact
                path={Pages.TENANT_NEW_ADMIN}
                component={TenantNewAdmin}
                rule='userManagement.admin'
              />
              <RouteACL
                exact
                path={Pages.TENANT_NEW_MENTOR}
                component={TenantNewMentor}
                rule='userManagement.mentor'
              />
              <RouteACL
                exact
                path={Pages.TENANT_NEW_FOUNDER}
                component={TenantNewFounderPage}
                rule='userManagement.founder'
              />
              <RouteACL
                exact
                path={Pages.TENANT_CUSTOM_QUESTIONS}
                component={TenanCustomQuestionstPage}
                rule={'customQuestions.view'}
              />
              <RouteACL
                exact
                path={Pages.TENANT_CUSTOM_QUESTIONS_EDIT}
                component={TenantCustomQuestionsEdit}
                rule={'customQuestions.edit'}
              />
              <Redirect to={Pages.TENANT_PROFILE} />
            </Switch>
          </div>
        </Paper>
      </div>
    </BaseLayout>
  );
}

export default TenantPage;
