import { capitalize } from 'lodash';

export type Status = 'Applicant' | 'Active' | 'Archived' | 'Rejected';

export const statuses: Status[] = [
  'Applicant',
  'Active',
  'Archived',
  'Rejected',
];

export const is = (s: string): s is Status => statuses.includes(s as Status);

export function fromString(s: string): Status | undefined {
  const v = capitalize(s.trim().toLowerCase());

  if (is(v)) {
    return v;
  }

  return undefined;
}
