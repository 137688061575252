import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Radio,
} from '@material-ui/core';
import cn from 'classnames';
import React, { KeyboardEventHandler, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Specialization } from '../../api/specializations';
import { SelectVenture as SelectVentureProps } from '../../api/ventures';
import { COLORS } from '../../theme/variables';
import { lengthField } from '../../utils/form';
import {
  CustomSelect,
  DateInput,
  DateTimePicker,
  FieldNameVenture,
  FieldSummary,
  IssuesInput,
  PhoneInput,
  RadioboxGroup,
  Rating,
  SelectVenture,
  TextField,
  TextWysiwyg,
  TimePicker,
  VentureTagsInput,
} from '../common';
import FieldCheckNewEmail from '../common/field-check-new-email';
import FieldEmail from '../common/field-email';
import FieldProgramName from '../common/field-program-name';
import SearchSelect, { SearchSelectOption } from '../common/search-select';
import { SpecializationInput } from '../common/specialization-input';

const useStyles = makeStyles({
  invalid: {
    '&, &:hover': {
      color: COLORS.COLOR_RED_BASE,
    },
  },
});

function FormDateInput({
  input: { value, onChange, onBlur, onFocus, ...restInputProps },
  meta,
  maxDate,
  disablePast,
  disableFuture,
  editable,
  isReadOnly,
  testid,
  ...textFieldProps
}: FieldRenderProps<string, HTMLElement>) {
  const formattedValue = value ? new Date(value) : null;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <DateInput
      value={formattedValue}
      onChange={onChange}
      onBlur={onBlur}
      maxDate={maxDate}
      error={showError}
      disablePast={disablePast}
      disableFuture={disableFuture}
      editable={editable}
      readOnly={isReadOnly}
      textFieldProps={{
        ...textFieldProps,
        ...restInputProps,
      }}
      testid={testid}
    />
  );
}

function FormSpecializationInput({
  input: { value, onChange, onBlur, onFocus, ...restInputProps },
  meta,
  tenantId,
  readOnly = false,
  ...textFieldProps
}: FieldRenderProps<Specialization['id'][], HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <SpecializationInput
      multiple
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      tenantId={tenantId}
      textFieldProps={{
        ...textFieldProps,
        error: showError,
        InputProps: {
          ...restInputProps,
          inputProps: {
            ...(textFieldProps || {}),
          },
        },
      }}
    />
  );
}

function FormVentureTagsInput({
  input: { value, onChange, onBlur, onFocus, ...restInputProps },
  meta,
  readOnly = false,
  ...textFieldProps
}: FieldRenderProps<string[], HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <VentureTagsInput
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      textFieldProps={{
        ...textFieldProps,
        error: showError,
        InputProps: {
          ...restInputProps,
          inputProps: {
            ...(textFieldProps || {}),
          },
        },
      }}
    />
  );
}

function FormIssuesInput({
  input: { value, onChange, onBlur, onFocus, ...restInputProps },
  meta,
  readOnly = false,
  ...textFieldProps
}: FieldRenderProps<Specialization['id'][], HTMLElement>) {
  return (
    <IssuesInput
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      textFieldProps={{
        ...textFieldProps,
        InputProps: {
          ...restInputProps,
          readOnly,
          inputProps: {
            ...(textFieldProps || {}),
          },
        },
      }}
    />
  );
}

function FormPhoneInput({
  input: { value, name, onChange, onBlur, onFocus, ...restInput },
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  return (
    <PhoneInput
      {...rest}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      InputProps={{
        ...restInput,
      }}
    />
  );
}

function TextFieldWrapper({
  input: { name, onChange, value, ...restInput },
  meta,
  testid,
  InputProps = {},
  onBlur,
  onFocus,
  onChange: onChangeNative,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    restInput?.onBlur?.(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    restInput?.onFocus?.(e);
  };

  return (
    <TextField
      {...rest}
      name={name}
      error={showError}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...(InputProps?.inputProps ? InputProps.inputProps : {}),
          ...restInput,
          onBlur: handleBlur,
          onFocus: handleFocus,
          'data-testid': testid,
        },
      }}
      onChange={handleChange}
      value={value}
    />
  );
}

function NumberField({
  input: { name, onChange, value, ...restInput },
  meta,
  testid,
  InputProps = {},
  onBlur,
  onFocus,
  onChange: onChangeNative,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/^(-?)0+(\d)/, '$1$2');
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    restInput.onBlur(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    restInput.onFocus(e);
  };

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (
        !e.altKey &&
        !e.ctrlKey &&
        !e.metaKey &&
        e.key.length === 1 &&
        ![
          '-',
          '.',
          '',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '0',
        ].includes(e.key)
      ) {
        e.preventDefault();
        return false;
      }
    },
    [],
  );

  return (
    <TextField
      {...rest}
      name={name}
      error={showError}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...(InputProps?.inputProps ? InputProps.inputProps : {}),
          ...restInput,
          onBlur: handleBlur,
          onFocus: handleFocus,
          onKeyDown: handleKeyDown,
          'data-testid': testid,
        },
      }}
      onChange={handleChange}
      value={value}
    />
  );
}

function FormCheckbox({
  input: { name, onChange, value },
  meta,
  label,
  className,
  classNameLabel,
  readOnly,
  testid,
}: FieldRenderProps<string | boolean, HTMLElement>) {
  const classes = useStyles();

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;
    const value = e.target.checked ? 'true' : 'false';
    onChange(value);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value.toString() === 'true'}
          onChange={handleToggle}
          name={name}
          color='primary'
          data-testid={testid}
          className={cn(className, {
            [classes.invalid]: showError,
          })}
        />
      }
      label={label}
      className={classNameLabel}
    />
  );
}

function FormCheckboxList({
  input: { name },
  meta,
  label,
  valueNative,
  onChange: onChangeNative,
  checked,
  className,
  classNameLabel,
  testid,
  showError = false,
}: FieldRenderProps<string, HTMLElement>) {
  const classes = useStyles();

  const displayError =
    showError || (!!meta.error && (meta.submitFailed || meta.touched));

  const handleToggle = () => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(valueNative);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleToggle}
          name={name}
          color='primary'
          aria-invalid={displayError}
          className={cn(className, {
            [classes.invalid]: displayError,
          })}
        />
      }
      label={label}
      className={classNameLabel}
      data-testid={testid}
    />
  );
}

function FormRadioboxGroup({
  input: { name, value, onChange },
  meta,
  label,
  onChange: onChangeNative,
  options,
  readOnly,
  testid,
  info,
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  return (
    <RadioboxGroup
      name={name}
      options={options}
      value={value}
      label={label}
      testid={testid}
      onChange={handleChange}
      readOnly={readOnly}
      invalid={showError}
      info={info}
    />
  );
}

function FormRadioboxList({
  input: { name, value },
  label,
  onChange: onChangeNative,
  valueNative,
  className,
  classNameLabel,
  testid,
}: FieldRenderProps<string, HTMLElement>) {
  return (
    <FormControlLabel
      control={
        <Radio
          onChange={onChangeNative}
          name={name}
          color='primary'
          className={className}
        />
      }
      value={value || valueNative}
      label={label}
      className={classNameLabel}
      data-testid={testid}
    />
  );
}

function FormSelect({
  input: { onChange, onBlur, value },
  options,
  label,
  className,
  onChange: onChangeNative,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  return (
    <CustomSelect
      {...rest}
      onBlur={onBlur}
      onChange={handleChange}
      options={options}
      value={value}
      label={label}
      className={className}
      error={showError}
    />
  );
}

function FormSelectValue({
  input: { onChange, onBlur },
  options,
  label,
  className,
  onChange: onChangeNative,
  meta,
  currentValue,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  return (
    <CustomSelect
      {...rest}
      onBlur={onBlur}
      onChange={handleChange}
      options={options}
      value={currentValue}
      label={label}
      className={className}
      error={showError}
    />
  );
}

function FormTimeInput({
  input: { value, onChange, onBlur, onFocus, ...restInputProps },
  meta,
  disabled,
  ...textFieldProps
}: FieldRenderProps<string, HTMLElement>) {
  const formattedValue = value ? new Date(value) : null;

  return (
    <TimePicker
      disabled={disabled}
      value={formattedValue}
      onChange={onChange}
      textFieldProps={{
        ...textFieldProps,
        ...restInputProps,
      }}
    />
  );
}

function FormDateTimeInput({
  input: { value, onChange, ...restInputProps },
  meta,
  disablePast,
  disabled,
  onChange: onChangeNative,
  onBlur,
  onClose,
  currentValue,
  className,
  metaTouchedNone = false,
  onFocus,
  ...textFieldProps
}: FieldRenderProps<string, HTMLElement>) {
  const formattedValue = value ? new Date(value) : null;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    (meta.touched || metaTouchedNone);

  const handleChange = (e: Date | null) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  return (
    <DateTimePicker
      {...textFieldProps}
      className={className}
      value={formattedValue}
      onChange={handleChange}
      error={showError}
      onBlur={onBlur}
      onClose={onClose}
      textFieldProps={{
        ...restInputProps,
        ...textFieldProps,
      }}
      disablePast={disablePast}
      disabled={disabled}
      onFocus={onFocus}
    />
  );
}

function FormSelectVenture({
  input: { value, onChange },
  setVentureName,
}: FieldRenderProps<SelectVentureProps, HTMLElement>) {
  const handleChange = (value: SelectVentureProps) => {
    onChange(value);
    setVentureName(value?.ventureName || '');
  };

  return <SelectVenture onChange={handleChange} value={value} />;
}

function TextFieldSummary({
  input: { name, onChange, value, ...restInput },
  meta,
  testid,
  InputProps = {},
  ventureName,
  ...rest
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FieldSummary
      {...rest}
      onChange={onChange}
      name={name}
      value={value}
      error={showError}
      ventureName={ventureName}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...(InputProps?.inputProps ? InputProps.inputProps : {}),
          ...restInput,
          'data-testid': testid,
        },
      }}
    />
  );
}

function TextFieldWysiwyg({
  input: { onChange, value, onBlur },
  meta,
  placeholder,
  showEditor,
  showCount,
  maxLength,
  disabled,
  readOnly,
  testid,
  onChange: onChangeNative,
  label,
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (e: any) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(e);
    }
    onChange(e);
  };

  return (
    <TextWysiwyg
      showCount={showCount}
      onBlur={onBlur}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      showEditor={showEditor}
      maxLength={maxLength}
      disabled={disabled}
      readOnly={readOnly}
      testid={testid}
      error={showError}
      label={label}
    />
  );
}

function FormNameVenture({
  input: { name, onChange, ...restInput },
  onBlur,
  onFocus,
  meta,
  label,
  onValid,
  inputProps,
  testid,
  error,
}: FieldRenderProps<string, HTMLElement>) {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    restInput?.onBlur?.(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    restInput?.onFocus?.(e);
  };
  return (
    <FieldNameVenture
      onChange={onChange}
      value={meta.initial}
      label={label}
      name={name}
      onValid={onValid}
      InputProps={{
        inputProps: {
          maxLength: lengthField.name,
          onBlur: handleBlur,
          onFocus: handleFocus,
          ...(inputProps || {}),
        },
      }}
      testid={testid}
      error={error}
    />
  );
}

function FormProgramName({
  input: { onChange, name },
  meta,
  label,
  onValid,
  fieldType,
  ...props
}: FieldRenderProps<string, HTMLElement>) {
  return (
    <FieldProgramName
      {...props}
      onChange={onChange}
      value={meta.initial}
      label={label}
      name={name}
      onValid={onValid}
      type={fieldType}
      InputProps={{
        inputProps: {
          maxLength: lengthField.name,
        },
      }}
    />
  );
}

function FormEmail({
  input: { onChange, onBlur, name },
  meta,
  label,
  entityType,
  InputProps,
  setValid,
  ...props
}: FieldRenderProps<string, HTMLElement> & {
  setValid?: (isValid: boolean) => void;
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <FieldEmail
      onBlur={onBlur}
      onChange={onChange}
      value={meta.initial}
      label={label}
      name={name}
      type={entityType}
      valid={!showError}
      InputProps={InputProps}
      setValid={setValid}
      {...props}
    />
  );
}

function FormCheckNewEmail({
  input: { onChange, onBlur, name },
  meta,
  label,
  entityType,
  InputProps,
  onValid,
  ...props
}: FieldRenderProps<string, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FieldCheckNewEmail
      onChange={onChange}
      onBlur={onBlur}
      error={showError}
      value={meta.initial}
      label={label}
      name={name}
      type={entityType}
      valid={!meta.dirty || (meta.valid && meta.dirty)}
      InputProps={InputProps}
      onValid={onValid}
      {...props}
    />
  );
}

function FormRating({
  input: { onChange, value },
  meta: { submitFailed, invalid },
  label,
  name,
  className,
  testid,
  max,
  readOnly,
  withCaption,
}: FieldRenderProps<number, HTMLElement>) {
  const isInvalid = submitFailed && invalid;

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };
  return (
    <Rating
      onChange={handleChange}
      value={value}
      label={label}
      name={name}
      className={className}
      testid={testid}
      max={max}
      readOnly={readOnly}
      withCaption={withCaption}
      invalid={isInvalid}
    />
  );
}

function SearchSelectWrapper({
  input: { onChange, onBlur, value },
  onSearch,
  label,
  className,
  onChange: onChangeNative,
  meta,
  ...rest
}: FieldRenderProps<SearchSelectOption, HTMLElement>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (v: SearchSelectOption | null) => {
    if (typeof onChangeNative === 'function') {
      onChangeNative(v);
    }
    onChange(v);
  };

  return (
    <SearchSelect
      {...rest}
      onBlur={onBlur}
      onChange={handleChange}
      onSearch={onSearch}
      value={value}
      label={label}
      className={className}
      error={showError}
    />
  );
}

export {
  FormDateInput,
  FormSpecializationInput,
  FormPhoneInput,
  TextFieldWrapper,
  FormCheckbox,
  FormSelect,
  FormTimeInput,
  FormDateTimeInput,
  FormSelectVenture,
  TextFieldSummary,
  TextFieldWysiwyg,
  FormNameVenture,
  FormEmail,
  FormProgramName,
  FormCheckboxList,
  FormRating,
  FormIssuesInput,
  FormRadioboxList,
  FormRadioboxGroup,
  FormCheckNewEmail,
  FormSelectValue,
  FormVentureTagsInput,
  NumberField,
  SearchSelectWrapper,
};
