import { CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { concat, from, of, Subject } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { COLORS } from '../../theme/variables';
import { Either, Types } from '../../utils/Either';
import {
  initial,
  Initial,
  Invalid,
  invalid,
  Type,
  Valid,
  valid,
  verifying,
  Verifying,
} from '../../utils/FormValue';
import { unreachableError } from '../../utils/unreachableError';
import TextField from './text-field';

export interface AsyncInputProps<T extends string> {
  error?: boolean;
  label?: string;
  placeholder?: string;
  value: Initial<string | undefined> | Valid<T> | Invalid<undefined, string>;
  onChange: (v: Valid<T> | Invalid<undefined, string>) => void;
  validate: (s: string) => Promise<Either<string, T>>;
  required?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function AsyncInput<T extends string>({
  value,
  onChange,
  validate,
  label,
  placeholder,
  error,
  required,
  onBlur,
  onFocus,
}: AsyncInputProps<T>): ReactElement {
  const classes = useStyles();
  const [status, setStatus] = useState<Status<T>>(value);
  const handleOnChange = useMemo(() => {
    const subject$ = new Subject<string>();

    subject$
      .pipe(
        switchMap((v) => {
          return concat(
            of(initial(v)),
            of(v).pipe(
              delay(500),
              mergeMap((v) => {
                return v === ''
                  ? required
                    ? of(invalid(undefined, v))
                    : of(initial(v))
                  : concat(
                      of(verifying(v)),
                      from(validate(v)).pipe(
                        map((v) => {
                          switch (v.type) {
                            case Types.Left:
                              return invalid(undefined, v.value);
                            case Types.Right:
                              return valid(v.value);
                          }
                        }),
                        catchError(() => of(invalid(undefined, v))),
                      ),
                    );
              }),
              tap((v) => {
                switch (v.__typeName) {
                  case Type.valid:
                  case Type.invalid:
                    return onChange(v);
                  case Type.initial:
                  case Type.verifying:
                    return;
                  default:
                    unreachableError(v);
                }
              }),
            ),
          );
        }),
      )
      .subscribe(setStatus);

    return subject$;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEndAdornment = useCallback((): ReactElement | null => {
    switch (status.__typeName) {
      case Type.valid:
        return <CheckCircleIcon className={classes.checkIcon} />;
      case Type.invalid:
        return <CancelIcon className={classes.errorIcon} />;
      case Type.verifying:
        return <CircularProgress color='primary' size={20} />;
      case Type.initial:
        return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={status.value}
      onChange={(e) => handleOnChange.next(e.target.value)}
      error={status.__typeName === Type.invalid || error}
      InputProps={{
        endAdornment: getEndAdornment(),
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}

type Status<T> =
  | Initial<string | undefined>
  | Valid<T>
  | Verifying<string>
  | Invalid<undefined, string>;

const useStyles = makeStyles({
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
});
