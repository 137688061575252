import { AxiosError } from 'axios';
import { axiosRequest } from '../instance';
import { TenantId } from '../tenants/Tenant';
import * as Application from './types/Application';
import {
  CommunityMember,
  CommunityMemberCreate,
  CommunityMemberId,
  CommunityMemberUpdate,
} from './types/CommunityMember';
import { Log } from './types/Log';
import { Status } from './types/Status';

export function getById(id: CommunityMemberId): Promise<CommunityMember> {
  return axiosRequest
    .get<CommunityMember>({
      url: `/communityMembers/${id}`,
      credentials: true,
    })
    .then((v) => v.data);
}

export function getByPage(page: number): Promise<CommunityMember[]> {
  return axiosRequest
    .get<CommunityMember[]>({
      url: `/communityMembers/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByStatus(
  status: Status,
  page: number,
): Promise<CommunityMember[]> {
  return axiosRequest
    .get<CommunityMember[]>({
      url: `/communityMembers/statuses/${status}/pages/${page}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getLogs(id: CommunityMemberId): Promise<Log[]> {
  return axiosRequest
    .get<Log[]>({
      url: `/communityMembers/${id}/log`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function search(str: string): Promise<CommunityMember[]> {
  return axiosRequest
    .get<CommunityMember[]>({
      url: `/communityMembers/search/${str}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByLastName(lastName: string): Promise<CommunityMember[]> {
  return axiosRequest
    .get<CommunityMember[]>({
      url: `/communityMembers/lastname/${lastName}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByEmailGlobal(email: string): Promise<CommunityMemberId> {
  return axiosRequest
    .get<CommunityMemberId>({
      url: `/communityMembers/global/emails/${email}`,
      credentials: true,
    })
    .then((i) => i.data);
}
export function getByEmail(email: string): Promise<CommunityMember[]> {
  return axiosRequest
    .get<CommunityMember[]>({
      url: `/communityMembers/emails/${email}`,
      credentials: true,
    })
    .then((i) => i.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function create(data: CommunityMemberCreate): Promise<CommunityMember> {
  return axiosRequest
    .post<CommunityMember>({
      url: `/communityMembers`,
      data,
      credentials: true,
    })
    .then((i) => i.data);
}

export function update(data: CommunityMemberUpdate): Promise<CommunityMember> {
  return axiosRequest
    .put<CommunityMember>({ url: `/communityMembers`, data, credentials: true })
    .then((i) => i.data);
}

export function remove(id: CommunityMemberId): Promise<CommunityMember> {
  return axiosRequest
    .delete<CommunityMember>({
      url: `/communityMembers/${id}`,
      credentials: true,
    })
    .then((i) => i.data);
}

export function invite(id: CommunityMemberId): Promise<void> {
  return axiosRequest
    .post<void>({
      url: `/communityMembers/invites/${id}`,
      credentials: true,
    })
    .then(() => undefined);
}

export function apply(
  data: Application.Create,
): Promise<Application.Application> {
  return axiosRequest
    .post<Application.Application>({
      url: `/public/tenants/${data.tenantId}/communityMembers/applications`,
      data,
    })
    .then((data) => data.data);
}

export interface EnrollResponse {
  password: string;
  inviteKey: string;
  valid: boolean;
}

export function enroll(
  tenantId: TenantId,
  inviteKey: string,
  password: string,
): Promise<EnrollResponse> {
  return axiosRequest
    .post<EnrollResponse>({
      url: `/public/tenants/${tenantId}/communityMembers/users`,
      data: {
        password,
        inviteKey,
      },
    })
    .then((r) => r.data);
}
