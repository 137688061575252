// region Open
import { SchedulingWizard } from '../../../../api/schedulingWizard';

export interface Open {
  type: 'Open';
}

export const open = (): Open => ({
  type: 'Open',
});
// endregion

// region LoadFail
export interface LoadFail {
  type: 'LoadFail';
  payload: string;
}

export const loadFail = (payload: LoadFail['payload']): LoadFail => ({
  type: 'LoadFail',
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: 'LoadSuccess';
  payload: {
    founders: number;
    mentors: number;
    wizards: SchedulingWizard[];
  };
}

export const loadSuccess = (payload: LoadSuccess['payload']): LoadSuccess => ({
  type: 'LoadSuccess',
  payload,
});
// endregion

// region Approve
export interface Approve {
  type: 'Approve';
}

export const approve = (): Approve => ({
  type: 'Approve',
});
// endregion

// region Deny
export interface Deny {
  type: 'Deny';
}

export const deny = (): Deny => ({
  type: 'Deny',
});
// endregion

// region CancelingFail
export interface CancelingFail {
  type: 'CancelingFail';
  payload: string;
}

export const cancelingFail = (
  payload: CancelingFail['payload'],
): CancelingFail => ({
  type: 'CancelingFail',
  payload,
});
// endregion

// region CancelingSuccess
export interface CancelingSuccess {
  type: 'CancelingSuccess';
}

export const cancelingSuccess = (): CancelingSuccess => ({
  type: 'CancelingSuccess',
});
// endregion

// region CreateSuccess
export interface CreateSuccess {
  type: 'CreateSuccess';
}

export const createSuccess = (): CreateSuccess => ({
  type: 'CreateSuccess',
});
// endregion

// region CreateFail
export interface CreateFail {
  type: 'CreateFail';
  payload: string;
}

export const createFail = (payload: CreateFail['payload']): CreateFail => ({
  type: 'CreateFail',
  payload,
});
// endregion

// region Reset
export interface Reset {
  type: 'Reset';
}

export const reset = (): Reset => ({
  type: 'Reset',
});
// endregion

export type Actions =
  | Open
  | LoadFail
  | LoadSuccess
  | Approve
  | Deny
  | CancelingFail
  | CancelingSuccess
  | CreateSuccess
  | CreateFail
  | Reset;
