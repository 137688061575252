import { AxiosError } from 'axios';
import { isT } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { Dispatch, useContext, useEffect, useMemo, useReducer } from 'react';
import { from, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  createCohort,
  getActiveCohorts,
  updateCohort,
} from '../../../api/Cohort';
import { Tenant } from '../../../api/auth';
import { UserContext } from '../../../contexts/user-context';
import { errorMessage } from '../../../utils/exceptions';
import { reducer } from './reducer';
import {
  Actions,
  changeTenant,
  fetchError,
  fetchSuccess,
  removeError,
  removeSuccess,
  saveError,
  saveSuccess,
} from './types/Actions';
import { ExistingRemoving, ExistingSaving } from './types/ExistingItemState';
import { NewItemSaving } from './types/NewItemState';
import { Ready, State } from './types/State';

export function useCohortsPage(): [State, Dispatch<Actions>] {
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, {
    type: 'Loading',
    payload: {
      tenantId: (user as Tenant)?.id,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const state$ = useMemo(() => {
    const state$ = new Subject<State>();

    state$
      .pipe(
        distinctUntilChanged((a, b) => a.type === b.type),
        filter((state) => state.type === 'Loading'),
        switchMap(() => from(getActiveCohorts())),
        map(fetchSuccess),
        catchError((e: AxiosError) =>
          of(fetchError(errorMessage(e))).pipe(
            tap((v) => enqueueSnackbar(v.payload, { variant: 'error' })),
          ),
        ),
      )
      .subscribe(dispatch);

    state$
      .pipe(
        filter((state): state is Ready => state.type === 'Ready'),
        map((s) => {
          const item = s.payload.items.find(
            (i): i is NewItemSaving => i instanceof NewItemSaving,
          );

          return item ? { item, tenantId: s.payload.tenantId } : undefined;
        }),
        filter(isT),
        distinctUntilChanged(),
        switchMap(({ tenantId, item }) =>
          from(
            createCohort({
              name: item.title.value,
              status: 'ACTIVE',
              description: item.description.value || null,
              tenantId: tenantId,
            }),
          ),
        ),
        map((cohort) =>
          saveSuccess({
            id: 'new-item',
            cohort,
          }),
        ),
        catchError((e: AxiosError) =>
          of(saveError({ id: 'new-item', error: errorMessage(e) })).pipe(
            tap((v) => enqueueSnackbar(v.payload.error, { variant: 'error' })),
          ),
        ),
      )
      .subscribe(dispatch);

    state$
      .pipe(
        filter((state): state is Ready => state.type === 'Ready'),
        map((s) => {
          const item = s.payload.items.find(
            (i): i is ExistingSaving => i instanceof ExistingSaving,
          );

          return item ? { item, tenantId: s.payload.tenantId } : undefined;
        }),
        filter(isT),
        distinctUntilChanged(),
        switchMap(({ tenantId, item }) =>
          from(
            updateCohort({
              id: item.id,
              name: item.title.value,
              status: 'ACTIVE',
              description: item.description.value || null,
              tenantId: tenantId,
            }),
          ),
        ),
        map((cohort) =>
          saveSuccess({
            id: cohort.id,
            cohort,
          }),
        ),
        catchError((e: AxiosError) =>
          of(saveError({ id: 'new-item', error: errorMessage(e) })).pipe(
            tap((v) => enqueueSnackbar(v.payload.error, { variant: 'error' })),
          ),
        ),
      )
      .subscribe(dispatch);

    state$
      .pipe(
        filter((state): state is Ready => state.type === 'Ready'),
        map((s) => {
          const item = s.payload.items.find(
            (i): i is ExistingRemoving => i instanceof ExistingRemoving,
          );

          return item ? { item, tenantId: s.payload.tenantId } : undefined;
        }),
        filter(isT),
        distinctUntilChanged(),
        switchMap(({ tenantId, item }) =>
          from(
            updateCohort({
              id: item.id,
              name: item.title,
              status: 'ARCHIVED',
              description: item.description ?? null,
              tenantId: tenantId,
            }),
          ).pipe(
            map((cohort) => removeSuccess(cohort.id)),
            catchError((e: AxiosError) =>
              of(removeError({ id: item.id, error: errorMessage(e) })).pipe(
                tap((v) =>
                  enqueueSnackbar(v.payload.error, { variant: 'error' }),
                ),
              ),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    return state$;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    state$.next(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (user?.id) {
      dispatch(changeTenant(user.id));
    }
  }, [user?.id]);

  return [state, dispatch];
}
