// region Idle

export interface IdlePayload {}

export interface Idle {
  type: 'Idle';
  payload: IdlePayload;
}

export const idle = (payload: IdlePayload): Idle => ({
  type: 'Idle',
  payload,
});
// endregion

// region Open
export interface OpenPayload extends IdlePayload {}

export interface Open {
  type: 'Open';
  payload: OpenPayload;
}

export const open = (payload: OpenPayload): Open => ({
  type: 'Open',
  payload,
});
// endregion

// region Sending
export interface SendingPayload extends OpenPayload {}

export interface Sending {
  type: 'Sending';
  payload: SendingPayload;
}

export const sending = (payload: SendingPayload): Sending => ({
  type: 'Sending',
  payload,
});
// endregion

// region SendError
export interface SendErrorPayload extends OpenPayload {
  message: string;
}

export interface SendError {
  type: 'SendError';
  payload: SendErrorPayload;
}

export const sendError = (payload: SendErrorPayload): SendError => ({
  type: 'SendError',
  payload,
});
// endregion

// region SendSuccess
export interface SendSuccessPayload extends OpenPayload {
  message: string;
}

export interface SendSuccess {
  type: 'SendSuccess';
  payload: SendSuccessPayload;
}

export const sendSuccess = (payload: SendSuccessPayload): SendSuccess => ({
  type: 'SendSuccess',
  payload,
});
// endregion

export type State = Idle | Open | Sending | SendError | SendSuccess;

export function isOpen(s: State): s is Open | Sending | SendError {
  switch (s.type) {
    case 'SendSuccess':
    case 'Idle':
      return false;
    case 'Open':
    case 'Sending':
    case 'SendError':
      return true;
  }
}

export function isLoading(s: State): s is Sending {
  switch (s.type) {
    case 'SendSuccess':
    case 'Open':
    case 'Idle':
    case 'SendError':
      return false;
    case 'Sending':
      return true;
  }
}

export function isSubmittable(s: State): s is Open | SendError {
  switch (s.type) {
    case 'Open':
    case 'SendError':
      return true;
    case 'Idle':
    case 'Sending':
    case 'SendSuccess':
      return false;
  }
}
