import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { successPayment } from '../../api/Appointments';
import { Appointment } from '../../api/Appointments/types/Appointment';
import { Button, Text } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { formatDate } from '../../utils/date';

enum StateTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

interface CommonState {
  type: StateTypes;
}

interface SuccessState extends CommonState {
  type: StateTypes.SUCCESS;
  appointment: Appointment;
}

interface ErrorState extends CommonState {
  type: StateTypes.ERROR;
}

interface LoadingState extends CommonState {
  type: StateTypes.LOADING;
}

type State = SuccessState | ErrorState | LoadingState;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    rowGap: 25,
    marginTop: -25,
  },
  block: {
    width: '540px',
    maxWidth: '95%',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    boxSizing: 'border-box',
  },
  logo: {
    width: 300,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
  mainIcon: {
    fontSize: '48px',
  },
  successIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 300,
    gap: '10px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: 16,
    width: '100%',

    '& .row-title': {
      color: COLORS.COLOR_GRAY_BASE,
      fontSize: 14,
    },

    '& .row-value': {
      color: COLORS.COLOR_GRAY_BASE,
      textAlign: 'right',
      fontSize: 14,
    },
  },
  gridHeader: {
    gridColumn: '1 / -1',
  },
  boldRow: {
    color: `${COLORS.COLOR_TEXT_BASE} !important`,
    fontWeight: 'bold',
  },
}));

function parseSessionIdFromURL(search: string): any {
  return _pick(parseQuery(search), ['session_id']);
}

function ReceiptRow({
  label,
  value,
  className,
}: {
  label: string;
  value: string;
  className?: string;
}) {
  return (
    <>
      <Text className={cn('row-title', className)}>{label}</Text>
      <Text className={cn('row-value', className)}>{value}</Text>
    </>
  );
}

function PaymentSuccessPage() {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const [state, setState] = useState<State>({ type: StateTypes.LOADING });

  const sessionId = useMemo(() => {
    return parseSessionIdFromURL(location.search)?.session_id;
  }, [location]);

  useEffect(() => {
    const confirmSuccess = async () => {
      setState({ type: StateTypes.LOADING });

      try {
        if (!sessionId) throw new Error();
        const appointment = await successPayment(sessionId);
        setState({ type: StateTypes.SUCCESS, appointment });
      } catch (error) {
        console.error(error);
        setState({ type: StateTypes.ERROR });
      }
    };

    confirmSuccess();
  }, [sessionId]);

  return (
    <div
      data-testid='payment-success-page-container'
      className={classes.container}>
      <img src='/Logo.png' alt='logo' className={classes.logo} />
      {state.type === StateTypes.LOADING && (
        <div className={classes.loaderContainer}>
          <CircularProgress color='primary' size={40} />
        </div>
      )}
      {state.type === StateTypes.ERROR && (
        <>
          <div className={classes.topBlock}>
            <ErrorIcon color='error' className={classes.mainIcon} />
            <Typography variant='h5' align='center'>
              Oops.. We had a hiccup processing the payment
            </Typography>
          </div>
          <div className={classes.block}>
            <Text variant='normal'>
              Please do not worry we will still complete your request. Click on
              the button below to send a message to our team member in order to
              complete the booking process.
            </Text>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                const email = 'igor@tractionfive.com';
                const subject = 'Payment processing failure';
                const body = `We had a problem with processing a payment with referenceId ${sessionId}.`;
                window.open(`mailto:${email}?subject=${subject}&body=${body}`);
              }}>
              Send Report
            </Button>
          </div>
        </>
      )}
      {state.type === StateTypes.SUCCESS && (
        <>
          <div className={classes.topBlock}>
            <CheckCircleIcon
              className={cn(classes.mainIcon, classes.successIcon)}
            />
            <Typography variant='h5' align='center'>
              Payment Successful
            </Typography>
          </div>
          <div className={classes.block}>
            <div className={classes.grid}>
              <Typography
                variant='subtitle1'
                align='center'
                className={classes.gridHeader}>
                Your Receipt
              </Typography>
              <ReceiptRow label='Paid To' value='Traction5 LLC' />
              <ReceiptRow
                label='Date of Payment'
                value={formatDate(new Date())}
              />
              <ReceiptRow
                label='Requestor Name'
                value={state.appointment.requestorFullName}
              />
              <ReceiptRow
                label='Requestor Email'
                value={state.appointment.requestorEmail}
              />
              <ReceiptRow
                label={`${rb('advisor-u')} Name`}
                value={state.appointment.advisorFullName}
              />
              <ReceiptRow
                label='Session Date'
                value={formatDate(state.appointment.start)}
              />
              <ReceiptRow label='Topic' value={state.appointment.topic} />
              <ReceiptRow label='ReferenceID' value={sessionId} />
              <ReceiptRow
                label='Amount'
                value={`${state.appointment.amount || 0}$`}
                className={classes.boldRow}
              />
            </div>
            <Button
              variant={'contained'}
              href={getRoutePath(Pages.PUBLIC_ADVISORS, {
                tenantId: state.appointment.tenantId,
              })}>
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default PaymentSuccessPage;
