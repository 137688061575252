import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { ReadyPayload } from '../pages/public/officehours/List/types/State';

export interface CachedAdvisorsContextProps {
  cached: ReadyPayload | null;
  scrollPositionY: number;
  setCached: (data: ReadyPayload) => void;
  setScrollPositionY: (scrollPositionY: number) => void;
}

export const CachedAdvisorsContext = createContext<CachedAdvisorsContextProps>({
  cached: null,
  scrollPositionY: 0,
  setCached: () => {},
  setScrollPositionY: () => {},
});

export const CachedAdvisorsProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [cached, setCached] = useState<ReadyPayload | null>(null);
  const [scrollPositionY, setScrollPositionY] = useState<number>(0);

  return (
    <CachedAdvisorsContext.Provider
      value={{
        cached,
        scrollPositionY,
        setCached,
        setScrollPositionY,
      }}>
      {children}
    </CachedAdvisorsContext.Provider>
  );
};

export const useCachedAdvisors = () => {
  return useContext(CachedAdvisorsContext);
};
