import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import { compareAsc, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Founder } from '../../api/founders';
import { Gathering, GatheringPeriod } from '../../api/gatherings';
import { Mentor } from '../../api/mentors';
import { UserContext } from '../../contexts/user-context';
import { isValidateDate } from '../../utils/date';
import { GATHERING_STATUSES, lengthField } from '../../utils/form';
import {
  checkSizeTablet,
  decodedWeekString,
  getGatheringPeriod,
  getGatheringPeriodCustomString,
  getInitialPeriod,
  isEqualPeriods,
} from '../../utils/functions';
import {
  BeforeUnload,
  Button,
  ButtonRepeatEvent,
  FieldDatesDetails,
  FormGroup,
} from '../common';
import { FormValue as FormValueRepeatEvent } from '../common/button-repeat-event';
import {
  FormSelect,
  FormSelectValue,
  TextFieldSummary,
  TextFieldWysiwyg,
} from './wrappers';

interface GatheringDetailsFormProps {
  gatheringDetails?: Gathering;
  loading?: boolean;
  onSubmit: (
    parsedFormValues: ParsedFormValues,
    handleLeavePage?: () => {},
  ) => any;
  founders?: Founder[];
  mentors?: Mentor[];
}

interface FormValues {
  start: string;
  end: string;
  status: string;
  tenantEventChannelId: string;
  name: string;
  comments: string;
  audience: string;
  physicalLocation?: string;
}

export interface ParsedFormValues {
  tenantEventChannelId: string | null;
  physicalLocation: string;
  status: string;
  comments: string;
  start: string;
  end: string;
  audience: string;
  name: string;
  periodString: string | null;
  isChangePeriod?: boolean;
}

export type GatheringPeriodType =
  | 'NONE'
  | 'DAILY'
  | 'CUSTOM_WEEK_DAYS'
  | 'MONTHLY'
  | 'ALL_WORK_DAYS'
  | 'CUSTOM'
  | 'CUSTOM_ADDITIONAL';

type Errors = {
  [K in keyof FormValues]?: string;
};

const useStyles = makeStyles({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainFormBlock: {
    width: 560,
  },
  actionsBlock: {
    marginTop: 56,
  },
  description: {
    '& textarea': {
      minHeight: 100,
    },
  },
});

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.start) {
    errors.start = 'Required';
  }
  if (!values.end) {
    errors.end = 'Required';
  }
  if (values.start && !isValidateDate(values.start)) {
    errors.start = 'Invalid';
  }
  if (values.end && !isValidateDate(values.end)) {
    errors.end = 'Invalid';
  }
  if (!values.status) {
    errors.status = 'Required';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (values.tenantEventChannelId === undefined) {
    errors['tenantEventChannelId'] = 'Required';
  }
  if (values.tenantEventChannelId === 'physical' && !values.physicalLocation) {
    errors['physicalLocation'] = 'Required';
  }
  if (!values.audience) {
    errors.audience = 'Required';
  }
  if (
    values.start &&
    isValidateDate(values.start) &&
    values.end &&
    isValidateDate(values.end) &&
    (compareAsc(new Date(values.start), new Date(values.end)) === 1 ||
      compareAsc(new Date(values.start), new Date(values.end)) === 0)
  ) {
    errors.end = 'Invalid';
  }
  return errors;
};

function parseGatheringPeriod(value: FormValueRepeatEvent): GatheringPeriod {
  let periodType: GatheringPeriod['periodType'];
  let skipWeeks: number | undefined;
  let skipDays: number | undefined;

  switch (value.typeRepetitions) {
    case 'weeks':
      periodType = 'CUSTOM_WEEK_DAYS';
      skipWeeks = +value.numberRepetitions;
      break;
    case 'days':
      periodType = 'DAILY';
      skipDays = +value.numberRepetitions;
      break;
    default:
      if (!value.dayOfMonth) {
        periodType = 'CUSTOM_WEEK_DAYS';
      } else {
        periodType = 'MONTHLY';
      }
  }

  return {
    periodType,
    dayOfWeek: value.week ? decodedWeekString(value.week) : -1,
    dayOfMonth:
      value.typeRepetitions === 'months' && value.dayOfMonth
        ? value.dayOfMonth
        : -1,
    ordinalNumber: value.numberWeek || -1,
    skipWeeks: skipWeeks ?? -1,
    skipDays: skipDays ?? -1,
    stopAfterIterations: value.endsCount || -1,
    stopAfterDate: value.endsDate
      ? format(new Date(value.endsDate), 'yyyy-MM-dd')
      : null,
  };
}

function getInitialValues(
  channelList: { label: string; value: string }[],
  details?: Gathering,
  defaultLocation?: string,
): FormValues {
  return {
    status: details?.status || 'CREATED',
    tenantEventChannelId:
      !details?.tenantEventChannelId ||
      !channelList.find((c) => c.value === details?.tenantEventChannelId)
        ? 'physical'
        : details?.tenantEventChannelId,
    physicalLocation: details?.physicalLocation ?? defaultLocation,
    comments: details?.comments || '',
    start: details?.start || '',
    end: details?.end || '',
    audience: 'ALL',
    name: details?.name || '',
  };
}

function getParsedValues(
  formValues: FormValues,
  gatheringPeriod: GatheringPeriod | null,
): ParsedFormValues {
  return {
    start: formValues.start,
    end: formValues.end,
    status: formValues.status,
    tenantEventChannelId:
      formValues.tenantEventChannelId === 'physical'
        ? null
        : formValues.tenantEventChannelId,
    physicalLocation: formValues.physicalLocation || '',
    comments: formValues.comments || '',
    name: formValues.name || '',
    audience: 'ALL',
    periodString: gatheringPeriod ? JSON.stringify(gatheringPeriod) : null,
  };
}

function GatheringDetailsForm({
  gatheringDetails,
  loading = false,
  onSubmit,
}: GatheringDetailsFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState<Date>();
  const [isOpenModalRecurrence, setIsOpenModalRecurrence] = useState(false);
  const [selectGatheringPeriod, setSelectGatheringPeriod] =
    useState<GatheringPeriodType>('NONE');
  const [gatheringPeriodValue, setGatheringPeriodValue] =
    useState<GatheringPeriod>();
  const { channels, hasAccessToAction } = useContext(UserContext);

  const channelList = useMemo(() => {
    const noChannelOption = {
      label: 'No Virtual Channel',
      value: 'physical',
    };

    if (!channels) {
      return [noChannelOption];
    }

    return channels
      .map((channel) => ({
        label: channel.channelName,
        value: channel.id,
      }))
      .concat(noChannelOption);
  }, [channels]);

  const initialValues = useMemo(() => {
    const initialPeriod = getInitialPeriod(gatheringDetails?.periodString);
    if (typeof initialPeriod === 'string') {
      setSelectGatheringPeriod(initialPeriod);
    }
    if (typeof initialPeriod === 'object') {
      setGatheringPeriodValue(initialPeriod);
      setSelectGatheringPeriod('CUSTOM_ADDITIONAL');
    }
    if (gatheringDetails?.start) {
      setStartDate(new Date(gatheringDetails.start));
    }

    return getInitialValues(channelList, gatheringDetails);
  }, [channelList, gatheringDetails]);

  const recurrenceOptions = useMemo(() => {
    const currentDate = startDate ? new Date(startDate) : new Date();
    const options = [
      {
        value: 'NONE',
        label: 'Does not repeat',
      },
      {
        value: 'DAILY',
        label: 'Daily',
      },
      {
        value: 'CUSTOM_WEEK_DAYS',
        label: `Weekly on ${format(currentDate, 'eeee')}`,
      },
      {
        value: 'MONTHLY',
        label: 'Monthly',
      },
      {
        value: 'ALL_WORK_DAYS',
        label: 'Every weekday (Mon-Fri)',
      },
      {
        value: 'CUSTOM',
        label: 'Custom',
      },
    ];

    if (gatheringPeriodValue) {
      const gatheringPeriodCustomString =
        getGatheringPeriodCustomString(gatheringPeriodValue);
      const additionalOption = {
        value: 'CUSTOM_ADDITIONAL',
        label: gatheringPeriodCustomString,
      };

      return [additionalOption, ...options];
    }

    return options;
  }, [startDate, gatheringPeriodValue]);

  const gatheringPeriod = useMemo(() => {
    if (selectGatheringPeriod === 'CUSTOM_ADDITIONAL' && gatheringPeriodValue) {
      return gatheringPeriodValue;
    } else {
      return getGatheringPeriod(selectGatheringPeriod, startDate || new Date());
    }
  }, [selectGatheringPeriod, startDate, gatheringPeriodValue]);

  const isChangePeriod = useMemo(
    () =>
      gatheringDetails
        ? isEqualPeriods(
            JSON.stringify(gatheringPeriod),
            gatheringDetails?.periodString,
          )
        : true,
    [gatheringDetails, gatheringPeriod],
  );

  const handleSubmit = async (
    formValues: FormValues,
    handleLeavePage?: any,
  ) => {
    if (formValues.comments?.length >= lengthField.additionalInfo) {
      enqueueSnackbar(
        `Gathering description is too long. Maximum value size is 4096 characters`,
        {
          variant: 'error',
        },
      );
      throw new Error(
        'Gathering description is too long. Maximum value size is 4096 characters"',
      );
    }

    const parsedFormValues = getParsedValues(formValues, gatheringPeriod);

    return await onSubmit(
      { ...parsedFormValues, isChangePeriod },
      handleLeavePage,
    );
  };

  const handleChangeStartDate = (date?: Date) => {
    setStartDate(date);
  };

  const handleChangeRecurrence = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as GatheringPeriodType;
    if (value === 'CUSTOM') {
      setIsOpenModalRecurrence(true);
    } else {
      setSelectGatheringPeriod(value);
    }
  };

  const handleSubmitCustomGatheringPeriod = (value: FormValueRepeatEvent) => {
    const currentGatheringPeriond = parseGatheringPeriod(value);
    setGatheringPeriodValue(currentGatheringPeriond);
    setSelectGatheringPeriod('CUSTOM_ADDITIONAL');
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize={false}
        render={(formProps) => {
          const dirtyFileds = Object.keys(formProps.dirtyFields);
          const isOnlyRecurrenceDirty =
            dirtyFileds.length === 1 &&
            formProps.dirtyFields.recurrence === true;
          const isDirty = dirtyFileds.length !== 0 && !isOnlyRecurrenceDirty;
          const isPeriodDirty = gatheringPeriod
            ? JSON.stringify(gatheringPeriod) !== gatheringDetails?.periodString
            : !!gatheringDetails?.periodString;
          return (
            <>
              <BeforeUnload
                when={(isDirty || isPeriodDirty) && !loading}
                title='Leave the page'
                body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                disabled={loading}
                confirmButtonRenderer={({ onConfirm }) => (
                  <Button
                    variant='outlined'
                    onClick={async () => {
                      try {
                        await handleSubmit(formProps.values, () => onConfirm());
                      } catch (e: any) {}
                    }}
                    disabled={loading || !formProps.valid}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save the changes'
                    )}
                  </Button>
                )}
              />
              <div
                className={classes.formBlocks}
                data-testid='gathering-details-form'>
                <div className={classes.mainFormBlock}>
                  <form noValidate>
                    <div className={classes.sectionBlock}>
                      <FormGroup>
                        <Field<string>
                          name='name'
                          component={TextFieldSummary}
                          testid='gathering-details-summary'
                          label='Gathering Name*'
                          InputProps={{
                            inputProps: {
                              maxLength: lengthField.summary,
                              readOnly: !hasAccessToAction(
                                'gathering.details.update',
                              ),
                            },
                          }}
                        />
                      </FormGroup>
                      <FieldDatesDetails
                        readOnly={
                          !hasAccessToAction('gathering.details.update')
                        }
                        onChangeStartDate={handleChangeStartDate}
                        minEndDate={
                          startDate ? new Date(startDate) : new Date()
                        }
                      />
                      <FormGroup mobile={checkSizeTablet(800)}>
                        <Field<string>
                          testid='gathering-details-status'
                          name='status'
                          component={FormSelect}
                          label='Status*'
                          options={GATHERING_STATUSES}
                          readOnly={
                            !hasAccessToAction('gathering.details.update')
                          }
                        />
                        <Field<string>
                          testid='gathering-details-recurrence'
                          name='recurrence'
                          component={FormSelectValue}
                          onChange={handleChangeRecurrence}
                          disabled={!startDate}
                          label='Periods'
                          options={recurrenceOptions}
                          currentValue={selectGatheringPeriod}
                          readOnly={
                            !hasAccessToAction('gathering.details.update')
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field<string>
                          testid='gathering-details-channel-id'
                          name='tenantEventChannelId'
                          component={FormSelect}
                          label='Channel*'
                          options={channelList}
                          readOnly={
                            !hasAccessToAction('gathering.details.update')
                          }
                          tooltip='You must select either Virtual Channel or Physical Location for this event'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field<string>
                          name='physicalLocation'
                          component={TextFieldSummary}
                          testid='session-details-location'
                          label={`In-person location${
                            formProps.values.tenantEventChannelId === 'physical'
                              ? '*'
                              : ''
                          }`}
                          ventureName={formProps.values.physicalLocation}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title='You must select either Virtual Channel or Physical Location for this event'>
                                <IconButton>
                                  <HelpIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                            inputProps: {
                              readOnly: !hasAccessToAction(
                                'session.details.update',
                              ),
                            },
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field<string>
                          name='comments'
                          component={TextFieldWysiwyg}
                          testid='gathering-details-description'
                          placeholder='Description...'
                          multiline
                          className={classes.description}
                          readOnly={
                            !hasAccessToAction('gathering.details.update')
                          }
                        />
                      </FormGroup>
                    </div>
                    {hasAccessToAction('gathering.details.update') && (
                      <div className={classes.actionsBlock}>
                        <Button
                          onClick={async () => {
                            try {
                              await formProps.handleSubmit();
                            } catch (e: any) {}
                          }}
                          disabled={loading || !formProps.valid}
                          startIcon={<CheckIcon />}>
                          {loading ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </>
          );
        }}
      />
      <ButtonRepeatEvent
        selectDate={startDate}
        handleSubmit={handleSubmitCustomGatheringPeriod}
        isButton={false}
        isOpen={isOpenModalRecurrence}
        setIsOpen={setIsOpenModalRecurrence}
      />
    </div>
  );
}

export default GatheringDetailsForm;
