import { Container } from './Container';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Text } from '../common';
import { COLORS } from '../../theme/variables';
import { ReactElement, ReactNode, useMemo } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  successBlock: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
}));

export interface Props {
  type: 'simple' | 'success' | 'error';
  message: ReactNode;
}

export function Message({ message, type }: Props) {
  const classes = useStyles();
  const icon = useMemo((): ReactElement | null => {
    switch (type) {
      case 'simple':
        return null;
      case 'success':
        return <CheckCircleIcon className={classes.successIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
    }
  }, [type, classes]);

  return (
    <Container className={classes.successBlock}>
      {icon}
      <div className={classes.successText}>
        <Text variant='normal'>{message}</Text>
      </div>
    </Container>
  );
}
