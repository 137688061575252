import { ReactNode } from 'react';
import { Inline } from '../../common/Inline';
import { ClearFilters } from '../Listing/ClearFilters';

export interface Props {
  showClear: boolean;
  onClear: () => void;
  children: ReactNode;
  className?: string;
}

export function FiltersWrapper({
  children,
  onClear,
  showClear,
  className,
}: Props) {
  return (
    <Inline gap={15} align={'center'} className={className}>
      {children}
      {showClear ? <ClearFilters onClick={onClear} /> : null}
    </Inline>
  );
}
