import { Typography } from '@material-ui/core';
import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TenantId } from '../../../../../api/tenants/Tenant';
import { LoginModal } from '../../../../../components/common/LoginModal';
import { ResetPasswordModal } from '../../../../../components/common/ResetPasswordModal';
import { getRoutePath, Pages } from '../../../../../router/constants';
import { isInvalid } from '../../../../../utils/FormValue';
import * as Actions from './types/Actions';
import { useLoginButton } from './useLoginButton';

export function LoginButton(): ReactElement {
  const { tenantId } = useParams<{ tenantId: TenantId }>();
  const [s, dispatch] = useLoginButton();

  const modal = useMemo((): ReactElement | null => {
    switch (s.type) {
      case 'Ready':
      case 'LoggingIn':
      case 'LoginError':
        return (
          <LoginModal
            title='Office Hours Login'
            error={s.type === 'LoginError' ? s.payload.message : undefined}
            username={{
              disabled: s.type === 'LoggingIn',
              error: isInvalid(s.payload.username),
              onChange: (v) => dispatch(Actions.setName(v)),
              value: s.payload.username.value ?? '',
              onBlur: () => {},
            }}
            password={{
              disabled: s.type === 'LoggingIn',
              error: isInvalid(s.payload.password),
              onChange: (v) => dispatch(Actions.setPassword(v)),
              value: s.payload.password.value ?? '',
              onBlur: () => {},
            }}
            showPassword={s.payload.showPassword}
            onTogglePassword={() => dispatch(Actions.togglePassword())}
            onSubmit={() => dispatch(Actions.login())}
            loading={s.type === 'LoggingIn'}
            onCancel={() => dispatch(Actions.close())}
            signUpUrl={getRoutePath(Pages.OH_COMMUNITY_MEMBER_SIGN_UP, {
              tenantId,
            })}
            onReset={() => dispatch(Actions.goToReset())}
          />
        );
      case 'ResetPassword':
      case 'Resetting':
      case 'ResetError':
        return (
          <ResetPasswordModal
            title='Office Hours Login'
            error={s.type === 'ResetError' ? s.payload.message : undefined}
            username={{
              disabled: s.type === 'Resetting',
              error: isInvalid(s.payload.username),
              onChange: (v) => dispatch(Actions.setName(v)),
              value: s.payload.username.value ?? '',
              onBlur: () => {},
            }}
            onSubmit={() => dispatch(Actions.resetPassword())}
            loading={s.type === 'Resetting'}
            onCancel={() => dispatch(Actions.close())}
          />
        );
      case 'LoggedIn':
      case 'LoggedOut':
      case 'LoggingOut':
        return null;
    }
  }, [s, tenantId, dispatch]);
  const button = useMemo((): ReactElement => {
    switch (s.type) {
      case 'LoggingOut':
      case 'LoggedOut':
      case 'Ready':
      case 'LoginError':
      case 'LoggingIn':
      case 'ResetPassword':
      case 'Resetting':
      case 'ResetError':
        return (
          <Typography
            variant={'subtitle2'}
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(Actions.open())}>
            Log In
          </Typography>
        );

      case 'LoggedIn':
        return (
          <Typography
            variant={'subtitle2'}
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(Actions.logout())}>
            Log Out
          </Typography>
        );
    }
  }, [s.type, dispatch]);

  return (
    <>
      {modal}
      {button}
    </>
  );
}
