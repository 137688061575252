import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { mPipe } from 'fp-utilities';
import { ReactElement } from 'react';
import {
  is,
  ValidDate,
} from '../../../pages/Advisors/Availabilities/types/ValidDate';
import { Either, left, right } from '../../../utils/Either';
import { TestId } from '../../Testing/TestId';
import { DateTimePicker } from '../../common';
import { Delete } from '../../common/ActionIcons/Delete';
import { FormItem } from '../../common/Forms/types/FormItem';
import { SimpleSelect } from '../../common/SimpleSelect';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 800,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr auto',
    gridTemplateAreas: `
        'start end   period delete'
        'error error error  error'
        `,
    columnGap: '16px',
    rowGap: 5,
    alignItems: 'center',
  },
  start: { gridArea: 'start' },
  end: { gridArea: 'end' },
  period: { gridArea: 'period' },
  delete: { gridArea: 'delete' },
  errors: { gridArea: 'error' },
}));

export interface PeriodItem<T> {
  value: T;
  label: string;
}

export interface Props<T> {
  periods: PeriodItem<T>[];
  period: FormItem<T>;
  start: FormItem<Date | undefined, Either<ValidDate | undefined, ValidDate>>;
  end: FormItem<Date | undefined, Either<ValidDate | undefined, ValidDate>>;
  onRemove: () => void;
  errors: string[];
}

export function Edit<T extends string>({
  start,
  end,
  period,
  periods,
  onRemove,
  errors,
}: Props<T>): ReactElement {
  const classes = useStyles();
  return (
    <TestId testId={'edit-item'}>
      <div className={classes.root}>
        <DateTimePicker
          className={classes.start}
          testId={'start-time'}
          value={start.value ?? null}
          onChange={mPipe(validateDate, start.onChange)}
          onBlur={start.onBlur}
          onClose={start.onBlur}
          error={start.error}
          // minDate={startOfToday()}
        />
        <DateTimePicker
          className={classes.end}
          testId={'end-time'}
          value={end.value ?? null}
          onChange={mPipe(validateDate, end.onChange)}
          onBlur={end.onBlur}
          onClose={end.onBlur}
          error={end.error}
          minDate={start.value}
        />
        <TestId testId='period'>
          <SimpleSelect
            className={classes.period}
            options={periods}
            value={period.value}
            onChange={period.onChange}
            onBlur={period.onBlur}
            error={period.error}
          />
        </TestId>
        <TestId testId={'delete-item'}>
          <Delete onClick={onRemove} className={classes.delete} />
        </TestId>
        <div className={classes.errors}>
          {errors.map((msg) => (
            <Typography variant={'subtitle2'} color={'error'}>
              {msg}
            </Typography>
          ))}
        </div>
      </div>
    </TestId>
  );
}

function validateDate(
  v: Date | null,
): Either<ValidDate | undefined, ValidDate> {
  return v && is(v) ? right(v) : left(undefined);
}
