import { addMinutes, getDate, getDay, max, startOfTomorrow } from 'date-fns';
import { mPipe } from 'fp-utilities';
import { MonthDay } from '../../../api/types/MonthDay';
import { validatePeriod } from '../../../api/types/Period';
import { fromDateFnsWeekDay } from '../../../api/types/WeekDay';
import * as Either from '../../../utils/Either';
import { initial, invalid, isValid, valid } from '../../../utils/FormValue';
import { unreachableError } from '../../../utils/unreachableError';
import { Actions } from './types/Actions';
import { toPeriod } from './types/CustomPeriod';
import { EditingItem, ValidItem } from './types/EditingItem';
import { ItemError, validateItem } from './types/Item';
import { newItemId } from './types/ItemId';
import * as State from './types/State';
import { isReadyToEdit } from './types/State';
import { ValidDate } from './types/ValidDate';
import { canAddItem } from './utils';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            advisor: a.payload,
            items: a.payload.advisorAvailabilities.map((i) => ({
              id: i.id,
              end: initial(i.endTime),
              start: initial(i.startTime),
              period: initial(i.schedulingPeriod),
            })),
          })
        : s;
    case 'AddNew':
      return isReadyToEdit(s) && canAddItem(s)
        ? State.edited({
            ...s.payload,
            items: [
              ...s.payload.items,
              {
                id: newItemId(),
                start: initial(undefined),
                end: initial(undefined),
                period: initial({ periodType: '' }),
              },
            ],
          })
        : s;
    case 'CancelCustomPeriod':
      return s.type === 'CustomPeriod' ? State.edited(s.payload) : s;
    case 'SaveCustomPeriod':
      return s.type === 'CustomPeriod'
        ? State.edited({
            ...s.payload,
            items: s.payload.items.map((item) =>
              item.id === s.payload.id
                ? {
                    ...item,
                    period: validatePeriod(
                      toPeriod(
                        item.start.value ?? new Date(),
                        s.payload.period,
                      ),
                    ),
                  }
                : item,
            ),
          })
        : s;
    case 'SetStart':
      return s.type === 'Ready' || s.type === 'Edited'
        ? State.edited({
            ...s.payload,
            items: s.payload.items.map((item) => {
              return item.id === a.payload.id
                ? {
                    ...item,
                    start: Either.apply(
                      (v) =>
                        invalid(v ? ItemError.Invalid : ItemError.Required, v),
                      (v) => valid(v),
                      a.payload.value,
                    ),
                    end: Either.isRight(a.payload.value)
                      ? isValid(item.end) &&
                        item.end.value <= a.payload.value.value
                        ? invalid(ItemError.Low, item.end.value)
                        : valid(
                            addMinutes(a.payload.value.value, 30) as ValidDate,
                          )
                      : item.end,
                  }
                : item;
            }),
          })
        : s;
    case 'SetEnd':
      return s.type === 'Ready' || s.type === 'Edited'
        ? State.edited({
            ...s.payload,
            items: s.payload.items.map((item) =>
              item.id === a.payload.id
                ? {
                    ...item,
                    end:
                      isValid(item.start) && Either.isRight(a.payload.value)
                        ? a.payload.value.value > item.start.value
                          ? valid(a.payload.value.value)
                          : invalid(ItemError.Low, a.payload.value.value)
                        : item.end,
                  }
                : item,
            ),
          })
        : s;
    case 'SetPeriod': {
      if (s.type === 'Ready' || s.type === 'Edited') {
        switch (a.payload.value) {
          case '':
            return State.edited({
              ...s.payload,
              items: s.payload.items.map((item) =>
                item.id === a.payload.id
                  ? validateItem({ ...item, period: valid({ periodType: '' }) })
                  : item,
              ),
            });
          case 'DAILY':
            return State.edited({
              ...s.payload,
              items: s.payload.items.map((item) =>
                item.id === a.payload.id
                  ? validateItem({
                      ...item,
                      period: valid({
                        periodType: 'DAILY',
                        skipDays: -1,
                        stopAfterDate: valid(undefined),
                        stopAfterIterations: undefined,
                      }),
                    })
                  : item,
              ),
            });
          case 'CUSTOM_WEEK_DAYS':
            return State.edited({
              ...s.payload,
              items: s.payload.items.map((item) =>
                item.id === a.payload.id
                  ? validateItem({
                      ...item,
                      period: valid({
                        periodType: 'CUSTOM_WEEK_DAYS',
                        dayOfWeek: fromDateFnsWeekDay(
                          getDay(item.start.value ?? new Date()),
                        ),
                        skipWeeks: -1,
                        ordinalNumber: undefined,
                        stopAfterDate: valid(undefined),
                        stopAfterIterations: undefined,
                      }),
                    })
                  : item,
              ),
            });
          case 'MONTHLY':
            return State.edited({
              ...s.payload,
              items: s.payload.items.map((item) =>
                item.id === a.payload.id
                  ? validateItem({
                      ...item,
                      period: valid({
                        periodType: 'MONTHLY',
                        dayOfMonth: (item.start.value
                          ? getDate(item.start.value)
                          : 1) as MonthDay,
                        stopAfterDate: valid(undefined),
                        stopAfterIterations: undefined,
                      }),
                    })
                  : item,
              ),
            });
          case 'ALL_WORK_DAYS':
            return State.edited({
              ...s.payload,
              items: s.payload.items.map((item) =>
                item.id === a.payload.id
                  ? validateItem({
                      ...item,
                      period: valid({
                        periodType: 'ALL_WORK_DAYS',
                        stopAfterDate: valid(undefined),
                        stopAfterIterations: undefined,
                      }),
                    })
                  : item,
              ),
            });
          case 'CustomPeriod': {
            const item = s.payload.items.find(({ id }) => id === a.payload.id);
            return item !== undefined
              ? State.customPeriod({
                  ...s.payload,
                  id: item.id,
                  period: {
                    type: 'Daily',
                    every: 1,
                    endType: {
                      ends: 'hundredOccurrences',
                      date: max([
                        item.start.value ?? startOfTomorrow(),
                        startOfTomorrow(),
                      ]),
                      occurrences: 100,
                    },
                  },
                })
              : s;
          }
          default: {
            unreachableError(a.payload.value);
          }
        }
      }
      return s;
    }
    case 'SetCustomPeriod':
      return s.type === 'CustomPeriod'
        ? State.customPeriod({ ...s.payload, period: a.payload })
        : s;
    case 'Remove':
      return isReadyToEdit(s)
        ? mPipe((item: EditingItem) =>
            State.removeConfirmation({ ...s.payload, item }),
          )(s.payload.items.find((i) => i.id === a.payload)) ?? s
        : s;
    case 'RemoveAccept':
      return s.type === 'RemoveConfirmation'
        ? State.edited({
            ...s.payload,
            items: s.payload.items.filter((i) => i.id !== s.payload.item.id),
          })
        : s;
    case 'RemoveReject':
      return s.type === 'RemoveConfirmation' ? State.edited(s.payload) : s;
    case 'Submit': {
      if (State.isReadyToEdit(s)) {
        const items = s.payload.items.map(validateItem);
        return items.every(
          (i): i is ValidItem =>
            isValid(i.start) && isValid(i.end) && isValid(i.period),
        )
          ? State.saving({
              ...s.payload,
              items,
            })
          : State.edited({
              ...s.payload,
              items,
            });
      }

      return s;
    }
    case 'SubmitError':
      return s.type === 'Saving'
        ? State.saveError({ ...s.payload, message: a.payload })
        : s;
    case 'SubmitSuccess':
      return s.type === 'Saving'
        ? State.ready({
            ...s.payload,
            advisor: a.payload,
            items: a.payload.advisorAvailabilities.map((i) => ({
              id: i.id,
              end: valid(i.endTime),
              start: valid(i.startTime),
              period: validatePeriod(i.schedulingPeriod),
            })),
          })
        : s;
  }
}
