import { CircularProgress } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from '@material-ui/styles';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import { useTimer } from '../../hooks';
import { yupValidate } from '../../utils/yup';
import { Button, FormGroup, FormButtons, AlertState, Text } from '../common';
import { TextFieldWrapper } from './wrappers';

interface Values {
  secondFactorCode: string;
}

interface SecondFactorAuthFormProps {
  loading?: boolean;
  resendLoading?: boolean;
  error?: string;
  onSubmit: (credentials: { secondFactorCode: string }) => Promise<void>;
  onResendCode: () => Promise<void>;
}

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  description: {
    display: 'block',
    marginBottom: 24,
  },
  timer: {
    width: 30,
    marginLeft: 8,
  },
});

const TIMEOUT = 60;
const MAX_CODE_LENGTH = 10;

const validationSchema = yup.object().shape({
  secondFactorCode: yup.string().max(MAX_CODE_LENGTH).required('Required'),
});

function SecondFactorAuthForm({
  loading = false,
  resendLoading = false,
  error,
  onSubmit,
  onResendCode,
}: SecondFactorAuthFormProps) {
  const classes = useStyles();
  const timer = useTimer();
  const isSubmitDisabled = loading;

  const initialValues: Values = {
    secondFactorCode: '',
  };

  const handleResendCode = async () => {
    if (timer.running) {
      return;
    }

    timer.startTimer(TIMEOUT);
    await onResendCode();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={yupValidate(validationSchema)}
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <Text variant='normal' className={classes.description}>
            We have enabled a second factor verification step. An e-mail with a
            temporary code was sent. Please open your e-mail and paste the code
            you received in the box below.
          </Text>
          {!!error && <AlertState type='error'>{error}</AlertState>}
          <FormGroup>
            <Field<string>
              testid='2fa-form-code'
              name='secondFactorCode'
              component={TextFieldWrapper}
              label='Code'
              InputProps={{
                inputProps: {
                  maxLength: MAX_CODE_LENGTH,
                },
              }}
            />
          </FormGroup>
          <FormButtons>
            <Button
              onClick={handleResendCode}
              variant='outlined'
              data-testid='2fa-form-resend-code'
              endIcon={!timer.running ? <ReplayIcon /> : undefined}
              disabled={timer.running || isSubmitDisabled}>
              {resendLoading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                <>
                  Resend code
                  {timer.running && (
                    <span className={classes.timer}> ({timer.seconds}s)</span>
                  )}
                </>
              )}
            </Button>
            <Button
              data-testid='2fa-form-submit'
              type='submit'
              disabled={isSubmitDisabled}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Confirm'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default SecondFactorAuthForm;
