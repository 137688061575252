import { ResponsivePie } from '@nivo/pie';
import { makeStyles } from '@material-ui/styles';
import { Text } from '../common';
import cn from 'classnames';
import { COLORS } from '../../theme/variables';

export interface DataPieProps {
  id: 'great' | 'ok' | 'bad';
  label: string;
  value: number;
  color: string;
}

export interface Props {
  data: DataPieProps[];
  width?: number;
  height?: number;
  handleClick?: (data: DataPieProps) => any;
}

const useStyles = makeStyles({
  container: {
    marginTop: 0,
    marginBottom: -5,
    position: 'relative',

    '& text': {
      fontSize: '14px !important',
      fontWeight: 600,
      lineHeight: 23,
      color: COLORS.COLOR_TEXT_BASE,
      fontFamily:
        'Inter, Roboto, "Helvetica Neue", Arial, sans-serif !important'
    }
  },
  tooltipContainer: {
    display: 'flex',
    padding: '1px 5px',
    background: '#fff',
    columnGap: 10,
    alignItems: 'center',
    borderRadius: 5
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  tooltipColor: {
    width: 15,
    height: 15,
    borderRadius: '50%'
  },
  legendContainer: {
    position: 'absolute',
    top: 40,
    right: -120
  }
});

function ChartPie({ data, width, height, handleClick }: Props) {
  const classes = useStyles();

  const capitalizeFirstLetter = (value: string | number) => {
    if (typeof value === 'number') {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const tooltip = (color: string, value: number, label: string | number) => {
    return (
      <div className={classes.tooltipContainer}>
        <div
          className={classes.tooltipColor}
          style={{ backgroundColor: color }}
        />
        <Text variant='normal'>{`${label}: ${value}%`}</Text>
      </div>
    );
  };

  return (
    <div
      style={{
        height: height || 550,
        width: width || 850
      }}
      className={classes.container}>
      <ResponsivePie
        data={data}
        isInteractive={typeof handleClick === 'function'}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        startAngle={-180}
        activeOuterRadiusOffset={8}
        colors={(item) => item.data.color}
        borderWidth={1}
        borderColor={{ from: 'color' }}
        enableArcLinkLabels={true}
        enableArcLabels={false}
        arcLinkLabel={function (e) {
          return e.label + ' (' + e.value + '%)';
        }}
        tooltip={function (e) {
          const color = e.datum.color;
          const value = e.datum.value;
          const label = e.datum.label;

          return tooltip(color, value, label);
        }}
        arcLinkLabelsTextOffset={10}
        arcLinkLabelsTextColor='#333333'
        arcLinkLabelsOffset={0}
        arcLinkLabelsDiagonalLength={25}
        arcLinkLabelsStraightLength={15}
        arcLinkLabelsThickness={1.5}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabel={function (e) {
          return capitalizeFirstLetter(e.label) + ' (' + e.value + '%)';
        }}
        arcLabelsRadiusOffset={0.75}
        arcLabelsTextColor='#000000'
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        motionConfig='default'
        transitionMode='startAngle'
        onClick={(value) =>
          typeof handleClick === 'function' && handleClick(value.data)
        }
        onMouseEnter={(data: any, event: any) => {
          if (typeof handleClick === 'function') {
            event.currentTarget.style.cursor = 'pointer';
            event.currentTarget.style.opacity = '0.90';
          }
        }}
        onMouseLeave={(data: any, event: any) => {
          if (typeof handleClick === 'function') {
            event.currentTarget.style.cursor = 'auto';
            event.currentTarget.style.opacity = '1';
          }
        }}
      />

      <div className={classes.legendContainer} data-testid='chart-legenda'>
        {data.map((value: DataPieProps, index) => {
          return (
            <div
              className={cn(classes.tooltipContainer, {
                [classes.cursorPointer]: typeof handleClick === 'function'
              })}
              onClick={() =>
                typeof handleClick === 'function' && handleClick(value)
              }>
              <div
                className={classes.tooltipColor}
                style={{ backgroundColor: value.color }}
              />
              <Text
                testid={`chart-legenda-${index}`}
                variant='normal'
                bold>{`${value.label}: ${value.value}%`}</Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChartPie;
