import { ReactElement, useMemo } from 'react';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { PageLoader } from '../../../components/common';
import { ListLogs } from '../../../components/common/Logs/List';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { getTabs } from '../common/utils';
import { useLogs } from './useLogs';

export function Logs({ user }: ProtectedRouteProps): ReactElement {
  const [state] = useLogs();

  const content = useMemo((): ReactElement => {
    switch (state.type) {
      case 'Loading':
        return <PageLoader />;
      case 'LoadError':
        return <></>;
      case 'Ready':
        return <ListLogs logs={state.payload.logs} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={'Edit community member'}
        backButtonLink={Pages.OH_COMMUNITY_MEMBERS}
        backButtonTitle={'Back to Community Members'}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_COMMUNITY_MEMBERS_LOGS, {
              id: state.payload.id,
            })}
            tabs={getTabs(state.payload.id)}
          />
        }>
        {content}
      </WithTabs>
    </BaseLayout>
  );
}
