import { Advisor, AdvisorId } from '../../../../../api/Advisors/types/Advisor';
import { Specialization } from '../../../../../api/specializations';
import { TenantId } from '../../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../../api/tenants/types/Settings';
import { User } from './User';

// region Loading
export interface LoadingPayload {
  tenantId: TenantId;
  advisorId: AdvisorId;
  user: User;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  advisor: Advisor;
  specializations: Specialization[];
  strictOfficeHoursScheduling: boolean;
  timeZone: TenantTimeZone;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

export type State = Loading | LoadError | Ready;
