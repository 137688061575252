import { axiosRequest } from './instance';

const filesAPI = {
  async getFile(fileName: string) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/files/signedurl',
        data: fileName,
        credentials: true,
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      return res.data;
    } catch (e: any) {
      return '';
    }
  },
  async getFileLogo(fileName: string) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/files/logos/signedurl',
        data: fileName,
        credentials: true,
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      return res.data;
    } catch (e: any) {
      return '';
    }
  },
  async getFileLogos(fileNames: string[]) {
    try {
      const res = await axiosRequest.post<string[]>({
        url: '/files/logos/signedurls',
        data: fileNames.join(','),
        credentials: true,
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      return res.data;
    } catch (e: any) {
      return [];
    }
  },
  async attachFileToNote(ownerId: string, file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/generalnotes/${ownerId}/attachments`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
};

export default filesAPI;
