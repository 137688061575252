import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Button } from '../common';
import { Loading } from '../common/ActionIcons/Loading';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiButton-label': {
      display: 'grid',
      gridTemplateAreas: '"local"',
    },
  },
  transparent: {
    opacity: 0,
  },
  item: {
    gridArea: 'local',
    justifySelf: 'center',
  },
}));

export interface Props {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  className?: string;
}

export function BookButton({
  onClick,
  disabled,
  loading,
  className,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Button
      color={'primary'}
      disabled={disabled}
      onClick={onClick}
      data-testid={'book-button'}
      className={classNames(classes.root, className)}>
      {disabled && (
        <Loading
          className={classNames({
            [classes.item]: true,
          })}
        />
      )}
      <span
        className={classNames({
          [classes.item]: true,
          [classes.transparent]: disabled,
        })}>
        Book an Appointment
      </span>
    </Button>
  );
}
