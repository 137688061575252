import { useContext } from 'react';
import { KnownModule } from '../api/user/Module';
import { Role } from '../api/user/Role';
import { UserContext } from '../contexts/user-context';
import { useAuth } from '../hooks';
import { getRoutePath, Pages } from './constants';

export function useRedirectRoute(isGuest: boolean, isProtected: boolean) {
  const auth = useAuth();
  const { hasRole, hasAccessToModal, hasAccessToAction, saveLastVisitedPath } =
    useContext(UserContext);

  const hasOfficeHoursAccess =
    hasAccessToModal(KnownModule.OfficeHours) && hasRole(Role.Manager);

  if (isGuest && auth.isAuthorized) {
    switch (true) {
      case hasRole(Role.Mentor):
        return Pages.MENTOR_DASHBOARD;
      case hasRole(Role.Founder):
        return Pages.FOUNDER_DASHBOARD;
      case hasRole(Role.CommunityMember):
        return getRoutePath(Pages.PUBLIC_ADVISORS, {
          tenantId: auth.tokenData.tenantId,
        });
      case hasAccessToModal(KnownModule.Core) &&
        hasAccessToAction('dashboard.view'):
        return Pages.DASHBOARD;
      case hasOfficeHoursAccess:
        return Pages.OH_DASHBOARD;
      case hasAccessToAction('venture.view'):
        return Pages.VENTURES;
      default:
        return null;
    }
  }

  if (isProtected && !auth.isAuthorized) {
    saveLastVisitedPath();
    return Pages.LOGIN;
  }

  return null;
}
