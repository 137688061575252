import { createContext, useState, useCallback } from 'react';
import eventsAPI, { Tags } from '../api/events';

export interface IssueContextProps {
  issue?: Tags[];
  loadIssue: () => any;
}

export const IssueContext = createContext<IssueContextProps>({
  loadIssue: () => {}
});

interface IssueProviderProps {
  children: React.ReactNode;
}

export const IssueProvider = ({ children }: IssueProviderProps) => {
  const [issue, setIssue] = useState<Tags[]>();

  const loadIssue = useCallback(async () => {
    try {
      const loaded = await eventsAPI.loadListTags();
      setIssue(loaded);
    } catch (e: any) {
      console.log('error loadIssue', e);
    }
  }, []);

  const contextValue: IssueContextProps = {
    issue,
    loadIssue
  };

  return (
    <IssueContext.Provider value={contextValue}>
      {children}
    </IssueContext.Provider>
  );
};
