import { Tenant } from '../../../api/auth';
import { Advisor } from './Advisor';

// region Loading
export interface LoadingPayload {
  start: Date;
  end: Date;
  includeLogo: boolean;
  includeSummary: boolean;
  tenant: Tenant;
}

export interface Loading {
  type: 'Loading';
  payload: LoadingPayload;
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload,
});
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  message: string;
}

export interface LoadError {
  type: 'LoadError';
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError['payload']): LoadError => ({
  type: 'LoadError',
  payload,
});
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  officeHours: number;
  sessions: number;
  feedbackForms: number;
  advisors: Advisor[];
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region CustomizeEmpty
export interface CustomizeEmptyPayload extends LoadErrorPayload {}

export interface CustomizeEmpty {
  type: 'CustomizeEmpty';
  payload: CustomizeEmptyPayload;
}

export const customizeEmpty = (
  payload: CustomizeEmpty['payload'],
): CustomizeEmpty => ({
  type: 'CustomizeEmpty',
  payload,
});
// endregion

// region CustomizeReady
export interface CustomizeReadyPayload extends ReadyPayload {}

export interface CustomizeReady {
  type: 'CustomizeReady';
  payload: CustomizeReadyPayload;
}

export const customizeReady = (
  payload: CustomizeReady['payload'],
): CustomizeReady => ({
  type: 'CustomizeReady',
  payload,
});
// endregion

export type State =
  | Loading
  | LoadError
  | Ready
  | CustomizeReady
  | CustomizeEmpty;

export function isCustomize(s: State): s is CustomizeEmpty | CustomizeReady {
  switch (s.type) {
    case 'CustomizeEmpty':
    case 'CustomizeReady':
      return true;
    case 'Loading':
    case 'LoadError':
    case 'Ready':
      return false;
  }
}
