import { Paper, PaperProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useMemo } from 'react';
import { VentureTagContext } from '../../contexts/venture-tags-context';
import TextField, { TextFieldProps } from './text-field';

interface VentureTagsInputProps {
  value: string[];
  disabled?: boolean;
  textFieldProps: TextFieldProps;
  onChange: (event: any) => any;
  readOnly: boolean;
  testid?: string;
}

const useStyles = makeStyles({
  paper: {
    padding: 0,
    borderRadius: 4,
    boxShadow:
      '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)',
  },
});

function VentureTagsInput({
  value = [],
  disabled,
  textFieldProps,
  onChange,
  testid,
  readOnly = false,
}: VentureTagsInputProps) {
  const classes = useStyles();
  const { tags, loadTags } = useContext(VentureTagContext);
  const options = useMemo(() => tags || [], [tags]);

  const handleChange = (_: any, nextValue: string[]) => {
    if (readOnly) return;
    onChange(nextValue);
  };

  const getOptionLabel = (id: string) => {
    return options.find((option) => option.id === id)?.name || '';
  };

  useEffect(() => {
    if (!tags) {
      loadTags();
    }
  }, [tags, loadTags]);

  return (
    <Autocomplete
      multiple
      value={value || []}
      disableCloseOnSelect
      options={options.map((val) => val.id)}
      disableClearable
      getOptionLabel={(option) => getOptionLabel(option)}
      onChange={handleChange}
      filterSelectedOptions
      data-testid={testid}
      forcePopupIcon={readOnly ? false : undefined}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          inputProps={{ ...(params?.inputProps || {}), readOnly }}
        />
      )}
      PaperComponent={(paperProps: PaperProps) => {
        return !readOnly ? (
          <Paper {...paperProps} className={classes.paper} />
        ) : (
          <></>
        );
      }}
      disabled={disabled || !Array.isArray(tags)}
    />
  );
}

export default VentureTagsInput;
