import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ReactElement, useMemo } from 'react';
import { Traction5 } from '../../../assets/images';
import { useAdvisorSettings } from '../../../contexts/advisor-settings-context';
import { Pages } from '../../../router/constants';
import { COLORS, INTER_FONT_FAMILY } from '../../../theme/variables';
import { Url } from '../../../utils/String/Url';
import { Link } from '../../common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    padding: 42,

    [theme.breakpoints.down(600)]: {
      gap: 10,
    },
  },
  traction5: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',

    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
    },
  },
  blockLink: {
    textAlign: 'center',
    fontFamily: INTER_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '24px',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: 5,
    },
  },
  blockLine: {
    fontFamily: INTER_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_LIGHTENED_20,

    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
}));

const procWebsiteUrl = (url: Url) => {
  return url.startsWith('https') ? url : `https://${url}`;
};

export function Footer1(): ReactElement | null {
  const classes = useStyles();
  const { settings } = useAdvisorSettings();

  const items = useMemo(
    () => [
      ...(settings?.programName && settings?.websiteUrl
        ? [
            {
              title: settings.programName,
              url: procWebsiteUrl(settings.websiteUrl),
            },
          ]
        : []),
      {
        title: 'Contact Us',
        url: Pages.CONTACT_US,
      },
      {
        title: 'Terms of Service',
        url: Pages.TERMS,
      },
      {
        title: 'Privacy Policy',
        url: Pages.PRIVACY,
      },
    ],
    [settings],
  );

  return (
    <div className={classes.root}>
      <Block items={items} />
      <Link to={'/'} className={classes.traction5}>
        <Traction5 />
      </Link>
    </div>
  );
}

interface BlockProps {
  items: Array<{
    url?: string;
    title?: string;
  }>;
  className?: string;
}

function Block({ items, className }: BlockProps) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.block, className)}>
      {items.map(({ title, url }, i) => {
        return title && url ? (
          <>
            <Typography
              key={`link-${i}`}
              variant={'body2'}
              component={'a'}
              href={url}
              className={classes.blockLink}>
              {title}
            </Typography>
            {i < items.length - 1 && (
              <Typography variant={'body2'} className={classes.blockLine}>
                |
              </Typography>
            )}
          </>
        ) : null;
      })}
    </div>
  );
}
