import { useState, useRef } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Text } from '../common';
import { isTablet } from '../../utils/functions';
import { useHistory } from 'react-router-dom';
import { getRoutePath, Pages } from '../../router/constants';
import { Gathering } from '../../api/gatherings';

interface VentureTableInfoProps {
  attendanceCode: Gathering['attendanceCode'];
  gatheringId: Gathering['id'];
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 200,
    padding: 15,
    boxSizing: 'border-box'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    minWidth: 30,
    textAlign: 'center',
    '& + &': {
      marginTop: 10
    }
  }
});

function AttendanceCodeInfo({
  attendanceCode,
  gatheringId,
  children
}: VentureTableInfoProps) {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const debouncedTimer = useRef<number>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = async (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      setAnchorEl(event.target as HTMLElement);
      setIsOpen(true);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    handleClose();
  };

  const handleClick = () => {
    if (!isTablet()) {
      history.push(
        getRoutePath(Pages.GATHERINGS_DETAILS, {
          gatheringId: gatheringId
        })
      );
    }
  };

  return (
    <>
      <div
        className={classes.wrapper}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        <div>
          <div className={classes.paragraph}>
            <Text variant='normal' bold>
              Attendance code: {attendanceCode}
            </Text>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default AttendanceCodeInfo;
