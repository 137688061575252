import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  Button,
  FormButtons,
  BeforeUnload,
} from '../common';
import { FormCheckbox, TextFieldWrapper } from './wrappers';

export interface Values {
  title: string;
  status: string;
}

type Errors = {
  [K in keyof Values]?: string;
};

interface TaskFormProps {
  title: string;
  loading?: boolean;
  task?: {
    title: string;
    status: string;
  };
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  formGroup: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',

    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.title) {
    errors.title = 'Required';
  }
  return errors;
};

function TaskForm({
  title,
  task,
  loading = false,
  onSubmit,
  onCancel,
}: TaskFormProps) {
  const classes = useStyles();
  const initialValues: Values = {
    title: task?.title || '',
    status: task?.status === 'COMPLETED' ? 'true' : 'false',
  };

  const handleSubmit = (values: Values) => {
    onSubmit({
      title: values.title,
      status: values.status === 'true' ? 'COMPLETED' : 'NOT_STARTED',
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateForm}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.formGroup}>
              <Field<string>
                testid='form-status-checkbox'
                name='status'
                component={FormCheckbox}
              />
              <Field<string>
                data-testid='form-title-text'
                name='title'
                component={TextFieldWrapper}
                label='Title'
                InputProps={{
                  inputProps: {
                    maxLength: 250,
                  },
                }}
              />
            </div>
            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='form-task-submit'
                type='submit'
                disabled={loading}
                className={CLASS_TRACKING.INTERNAL_ACTION}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant='outlined' onClick={handleCancel}>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default TaskForm;
