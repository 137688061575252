// region Idle
import { TenantTimeZone } from '../../../../../api/tenants/types/Settings';
import { ZonedDate } from '../../../../../utils/date/ZonedDate';

export interface IdlePayload {
  dates: ZonedDate<TenantTimeZone>[];
}

export interface Idle {
  type: 'Idle';
  payload: IdlePayload;
}

export const idle = (payload: Idle['payload']): Idle => ({
  type: 'Idle',
  payload,
});
// endregion

// region Confirmation
export interface ConfirmationPayload<T> {
  data: T;
  dates: ZonedDate<TenantTimeZone>[];
}

export interface Confirmation<T> {
  type: 'Confirmation';
  payload: ConfirmationPayload<T>;
}

export const confirmation = <T>(
  payload: Confirmation<T>['payload'],
): Confirmation<T> => ({
  type: 'Confirmation',
  payload,
});
// endregion

// region Confirmed
export interface ConfirmedPayload<T> {
  data: T;
  dates: ZonedDate<TenantTimeZone>[];
}

export interface Confirmed<T> {
  type: 'Confirmed';
  payload: ConfirmedPayload<T>;
}

export const confirmed = <T>(
  payload: Confirmed<T>['payload'],
): Confirmed<T> => ({
  type: 'Confirmed',
  payload,
});
// endregion

export type State<T> = Idle | Confirmation<T> | Confirmed<T>;
