import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import authAPI from '../../api/auth';
import { Either, left, right } from '../../utils/Either';
import {
  Initial,
  initial,
  Invalid,
  isInvalid,
  Type,
  valid,
  Valid,
} from '../../utils/FormValue';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { yupValidate } from '../../utils/yup';
import { TestId } from '../Testing/TestId';
import { BeforeUnload, Button, FormButtons, FormGroup } from '../common';
import { AsyncInput } from '../common/AsyncInput';
import { TextFieldWrapper, TextFieldWysiwyg } from './wrappers';

// region NewChanelName
declare const _newChanelName: unique symbol;

export type NewChanelName = string & { [_newChanelName]: 'NewChanelName' };

// endregion

export interface Values {
  channelName: NewChanelName;
  channelLocation: string;
  description: string;
}

interface FormValues {
  channelName:
    | Initial<NewChanelName | undefined>
    | Valid<NewChanelName>
    | Invalid<undefined, string>;
  channelLocation: string;
  description: string;
}

interface NoteFormProps {
  title: string;
  loading?: boolean;
  channel?: Values;
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  description: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
}));

const validationSchema = yup.object().shape({
  channelName: yup
    .object()
    .shape({
      value: yup.string().trim().max(256).required(),
      __typeName: yup
        .string()
        .required()
        .oneOf([Type.initial, Type.valid, Type.invalid]),
    })
    .test('is-valid', 'Channel name is invalid', (value) => {
      return value?.__typeName !== Type.invalid;
    })
    .required(),
  channelLocation: yup.string().trim().max(1024).required(),
});

function NoteForm({
  title,
  channel,
  loading = false,
  onSubmit,
  onCancel,
}: NoteFormProps) {
  const classes = useStyles();
  const initialValues: FormValues = {
    channelName: channel?.channelName
      ? valid(channel?.channelName)
      : initial(undefined),
    channelLocation: channel?.channelLocation || '',
    description: channel?.description || '',
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values: FormValues) => {
    if (values.description.length > 4096) {
      enqueueSnackbar(
        'Channel description is too long. Maximum value size is 4096 characters',
        {
          variant: 'error',
        },
      );
    } else if (
      isInvalid(values.channelName) ||
      values.channelName.value === undefined
    ) {
      enqueueSnackbar('Please pick a valid channel name', {
        variant: 'error',
      });
    } else {
      return onSubmit({
        channelName: values.channelName.value,
        channelLocation: values.channelLocation,
        description: values.description,
      });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Form<FormValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={yupValidate(validationSchema)}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <form
            className={classes.form}
            onSubmit={formProps.handleSubmit}
            noValidate>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    try {
                      await formProps.handleSubmit();
                      onConfirm();
                    } catch (e: any) {}
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <FormGroup>
              <Field<FormValues['channelName']>
                name='channelName'
                render={(p) => {
                  return (
                    <TestId testId={'form-channel-name'}>
                      <AsyncInput
                        label='Channel Name*'
                        value={p.input.value}
                        onChange={p.input.onChange}
                        validate={validateChannelName}
                        onFocus={p.input.onFocus}
                        onBlur={p.input.onBlur}
                        required
                        error={
                          (p.meta.invalid || !p.input.value?.value) &&
                          p.meta.touched
                        }
                      />
                    </TestId>
                  );
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name='channelLocation'
                component={TextFieldWrapper}
                label='Channel Location*'
                testid='form-channel-location'
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                className={classes.description}
                name='description'
                component={TextFieldWysiwyg}
                placeholder='Description'
                testid='form-channel-description'
                multiline
              />
            </FormGroup>

            <FormButtons className={classes.formButtons}>
              <Button
                data-testid='form-channel-submit'
                type='submit'
                disabled={loading}
                className={CLASS_TRACKING.INTERNAL_ACTION}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save'
                )}
              </Button>
              <Button
                variant='outlined'
                onClick={handleCancel}
                data-testid='form-channel-cancel'>
                Cancel
              </Button>
            </FormButtons>
          </form>
        )}
      />
    </>
  );
}

export default NoteForm;

function validateChannelName(
  s: string,
): Promise<Either<string, NewChanelName>> {
  if (!s?.trim()) {
    return Promise.resolve(left(s));
  }

  return authAPI
    .getChannelByName(s)
    .then(() => left(s))
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return right(s as NewChanelName);
      }

      return left(s);
    });
}
