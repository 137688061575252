import { createContext, useState, useCallback } from 'react';
import educationAPI, { Education } from '../api/education';

export interface ReportsContextProps {
  reports?: Education;
  updateReports: (mentor: Education) => any;
  loadReports: () => Promise<any>;
  isReportsLoading: boolean;
}

export const ReportsContext = createContext<ReportsContextProps>({
  updateReports: () => {},
  loadReports: async () => {},
  isReportsLoading: true
});

interface ReportsProviderProps {
  children: React.ReactNode;
}

export const ReportsProvider = ({ children }: ReportsProviderProps) => {
  const [reports, setReports] = useState<Education>();
  const [isReportsLoading, setIsReportsLoading] = useState(true);

  const updateReports = useCallback((reports: Education) => {
    setReports(reports);
  }, []);

  const loadReports = useCallback(async () => {
    try {
      setIsReportsLoading(true);
      const loadedReports = await educationAPI.getEducation();
      setReports(loadedReports[0]);
      setIsReportsLoading(false);
    } catch (e: any) {
      setIsReportsLoading(false);
      console.log('error loadReports', e);
    }
  }, []);

  const contextValue: ReportsContextProps = {
    reports,
    updateReports,
    loadReports,
    isReportsLoading
  };

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
};
