import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import {
  Redirect,
  Switch,
  Route,
  useParams,
  useLocation,
  Link,
} from 'react-router-dom';
import { Mentor } from '../api/mentors';
import { PageLoader } from '../components/common';
import { MentorContext, MentorProvider } from '../contexts/mentor-context';
import { UserContext } from '../contexts/user-context';
import { getRoutePath, Pages } from '../router/constants';
import { COLORS } from '../theme/variables';
import MentorAdditionalInfoPage from './mentor/mentor-additional-info-page';
import MentorDetailsPage from './mentor/mentor-details-page';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
  },
  container_loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paper: {
    padding: 0,
    width: '100%',
    maxWidth: 882,
  },
  content: {
    padding: 40,
  },
  tabs: {
    padding: 0,
    boxShadow: `0px 1px 0px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    '& .MuiTab-root + .MuiTab-root': {
      marginLeft: 20,

      [theme.breakpoints.up('xs')]: {
        marginLeft: 32,
      },
    },
  },
}));

function MentorExternalPage() {
  const classes = useStyles();
  const location = useLocation();
  const { mentorId } = useParams<{ mentorId: Mentor['id'] }>();
  const { user, loadUser } = useContext(UserContext);
  const {
    mentor,
    isMentorLoading,
    mentorSpecializations,
    loadMentor,
    loadMentorSpecializations,
  } = useContext(MentorContext);

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [user, loadUser]);

  // load mentor's info
  useEffect(() => {
    if (!mentor) {
      loadMentor(mentorId);
    }
  }, [mentorId, mentor, loadMentor]);

  // load mentor's specializations
  useEffect(() => {
    if (!mentorSpecializations) {
      loadMentorSpecializations(mentorId);
    }
  }, [mentorSpecializations, mentorId, loadMentorSpecializations]);

  if (isMentorLoading) {
    return (
      <div className={classes.container_loading}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.tabs}>
          <Tabs
            value={location.pathname}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='on'>
            <Tab
              label='Details'
              component={Link}
              to={getRoutePath(Pages.MENTOR_EXTERNAL_DETAILS, { mentorId })}
              value={getRoutePath(Pages.MENTOR_EXTERNAL_DETAILS, { mentorId })}
            />
            <Tab
              label='Additional Info'
              component={Link}
              to={getRoutePath(Pages.MENTOR_EXTERNAL_ADDITIONAL, { mentorId })}
              value={getRoutePath(Pages.MENTOR_EXTERNAL_ADDITIONAL, {
                mentorId,
              })}
            />
          </Tabs>
        </div>
        <div className={classes.content}>
          <Switch>
            <Route
              exact
              path={Pages.MENTOR_EXTERNAL_DETAILS}
              component={MentorDetailsPage}
            />
            <Route
              exact
              path={Pages.MENTOR_EXTERNAL_ADDITIONAL}
              component={MentorAdditionalInfoPage}
            />
            <Redirect to={Pages.MENTOR_EXTERNAL_DETAILS} />
          </Switch>
        </div>
      </Paper>
    </div>
  );
}

function withMentorProvider(Component: React.FunctionComponent) {
  return () => (
    <MentorProvider>
      <Component />
    </MentorProvider>
  );
}

export default withMentorProvider(MentorExternalPage);
