import * as Email from './Email';

declare const _emailsList: unique symbol;

export type EmailsList = string & { [_emailsList]: 'EmailsList' };

export const isEmailsList = (value: string): value is EmailsList => {
  return value.split(',').every((email) => Email.isEmail(email));
};

export const fromString = (s: string): EmailsList | undefined => {
  const _s = s.trim();

  return isEmailsList(_s) ? (_s as EmailsList) : undefined;
};

export function fromEmails(emails: Email.Email[]): EmailsList {
  return emails.map((email) => email.toString()).join(',') as EmailsList;
}

export function toEmails(emailsList: EmailsList): Email.Email[] {
  return emailsList.split(',') as Email.Email[];
}
