import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ReactElement, useCallback, useContext, useMemo } from 'react';
import {
  Create,
  Question as APIQuestion,
  Type,
} from '../../api/tenants/QuestionTemplates/Question';
import { BeforeUnload, ConfirmDialog, Text } from '../../components/common';
import { Question } from '../../components/tenant/question';
import { QuestionEditor } from '../../components/tenant/question-editor';
import {
  TenantQuestionsContext,
  TenantQuestionsProvider,
} from '../../contexts/tenant-questions';
import { UserContext } from '../../contexts/user-context';
import * as Actions from '../../states/listing/Actions';
import { State } from '../../states/listing/State';
import { canAddNewQuestion } from '../../states/listing/utils';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  loader: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    margin: '20px 0',
  },
  toggler: {
    margin: '10px 0',
  },
  addNew: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  test: {
    maxWidth: '80%',
  },
  addNewButton: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },
  },
});

function typeTitle(t: Type): string {
  switch (t) {
    case Type.NUMBER:
      return 'Number';
    case Type.PARAGRAPH:
      return 'Paragraph';
  }
}

function getErr(s: State): string | undefined {
  switch (s.type) {
    case 'AddNewErr':
    case 'EditErr':
      return s.payload.message;
    case 'RemoveConfirmation':
    case 'Updating':
    case 'Adding':
    case 'Ready':
    case 'Fail':
    case 'Loading':
    case 'AddNew':
    case 'Edit':
    case 'Removing':
      return undefined;
  }
}

const types = Object.values(Type).map((t): { id: Type; title: string } => ({
  id: t,
  title: typeTitle(t),
}));

function TenantQuestionsPageTemplate() {
  const classes = useStyles();
  const [state, dispatch] = useContext(TenantQuestionsContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addNew = useCallback(() => dispatch(Actions.addNew()), []);

  const QuestionItem = useMemo(() => {
    return ({
      question,
      removing,
    }: {
      question: APIQuestion;
      removing: boolean;
    }): ReactElement => {
      return (
        <Question
          mandatory={question.required}
          type={typeTitle(question.type)}
          description={question.description}
          removing={removing}
          onRemove={() => dispatch(Actions.remove(question.id))}
          onEdit={() => dispatch(Actions.edit(question.id))}
        />
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Editor = useMemo(() => {
    return ({
      question,
      saving,
      error,
    }: {
      question: Create;
      saving: boolean;
      error: string | undefined;
    }): ReactElement => {
      return (
        <QuestionEditor
          mandatory={question.required}
          types={types}
          type={question.type}
          description={question.description}
          onSave={() => dispatch(Actions.save())}
          onCancel={() => dispatch(Actions.cancel())}
          setDescription={(t) => dispatch(Actions.setDescription(t))}
          setMandatory={(t) => dispatch(Actions.setRequired(t))}
          setType={(t) => dispatch(Actions.setType(t))}
          saving={saving}
          error={error}
        />
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const questions = useMemo((): ReactElement => {
    switch (state.type) {
      case 'Loading':
        return (
          <div className={classes.loader}>
            <CircularProgress size={36} color='primary' />
          </div>
        );
      case 'Fail':
        return <>Error: {state.payload.message}</>;
      case 'Ready':
        return (
          <>
            {state.payload.items.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
          </>
        );
      case 'Adding':
      case 'AddNewErr':
      case 'AddNew': {
        return (
          <>
            {state.payload.items.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
            <Editor
              question={state.payload.item}
              saving={state.type === 'Adding'}
              error={getErr(state)}
            />
          </>
        );
      }
      case 'Edit':
      case 'EditErr':
      case 'Updating': {
        return (
          <>
            {state.payload.before.map((q) => (
              <QuestionItem question={q} removing={false} />
            ))}
            <Editor
              question={state.payload.item}
              saving={state.type === 'Updating'}
              error={getErr(state)}
            />
            {state.payload.after.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
          </>
        );
      }
      case 'RemoveConfirmation':
        return (
          <>
            {state.payload.before.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
            <QuestionItem question={state.payload.item} removing={false} />
            {state.payload.after.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
            <ConfirmDialog
              isOpen={true}
              title={'Remove question'}
              body={'Do you really want to remove question?'}
              onCancel={() => dispatch(Actions.removeDeny())}
              onSuccess={() => dispatch(Actions.removeApprove())}
              cancelProps={{
                label: 'Cancel',
                variant: 'outlined',
                'data-testid': 'tenant-questions-remove-cancel',
              }}
              successProps={{
                label: 'Yes, proceed',
                variant: 'contained',
                'data-testid': 'tenant-questions-remove-button',
              }}
            />
          </>
        );
      case 'Removing': {
        return (
          <>
            {state.payload.before.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
            <QuestionItem question={state.payload.item} removing={true} />
            {state.payload.after.map((q) => (
              <QuestionItem key={q.id} question={q} removing={false} />
            ))}
          </>
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const editing = state.type === 'AddNew' || state.type === 'Edit';

  return (
    <div className={classes.container}>
      <BeforeUnload
        when={editing}
        title='Leave the page'
        body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
      />
      <div className={classes.test}>
        <Typography className={classes.addNew} variant='h4'>
          Custom Questions for Quarterly Reports
          {canAddNewQuestion(state) && (
            <IconButton
              className={classes.addNewButton}
              onClick={addNew}
              data-testid='button-new-question'>
              <AddIcon />
            </IconButton>
          )}
        </Typography>
        <Text variant='normal'>
          Quarterly report feature allows for adding / removing additional
          custom questions that are specific to your program.
        </Text>
      </div>
      {questions}
    </div>
  );
}

export const TenantQuestionsPage = (): ReactElement | null => {
  const { user: tenant } = useContext(UserContext);
  return tenant ? (
    <TenantQuestionsProvider tenantId={tenant.id}>
      <TenantQuestionsPageTemplate />
    </TenantQuestionsProvider>
  ) : null;
};
