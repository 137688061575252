import { makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import * as appointmentsAPI from '../../../api/Appointments';
import {
  AppointmentId,
  AppointmentLog,
} from '../../../api/Appointments/types/Appointment';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { PageLoader } from '../../../components/common';
import { ListLogs } from '../../../components/common/Logs/List';
import BaseLayout from '../../../components/layout/base-layout';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { getAppointmentTabs } from '../common/utils';

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function AppointmentLogsContent() {
  const classes = useStyles();
  const { id } = useParams<{ id: AppointmentId }>();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<AppointmentLog[]>([]);

  const loadLogs = async (id: AppointmentId) => {
    try {
      setIsLoading(true);
      const responseLogs = await appointmentsAPI.getLogs(id);

      const sortLogs = responseLogs.sort((a, b) => {
        const prevDate = +new Date(a.creationDate);
        const nextDate = +new Date(b.creationDate);
        return nextDate - prevDate;
      });

      setLogs(sortLogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLogs(id);
  }, [id]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return <ListLogs logs={logs} />;
}

export function AppointmentLogs({ user }: ProtectedRouteProps) {
  const { id } = useParams<{ id: AppointmentId }>();

  const tabs = useMemo(() => getAppointmentTabs(id), [id]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={'Appointment'}
        backButtonLink={Pages.OH_APPOINTMENTS}
        backButtonTitle={'Back to Appointments'}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_APPOINTMENTS_LOGS, { id })}
            tabs={tabs}
          />
        }>
        <AppointmentLogsContent />
      </WithTabs>
    </BaseLayout>
  );
}
