import { CohortId } from '../../../../api/Cohort/types/Cohort';
import { isValid } from '../../../../utils/FormValue';
import {
  ExistingCancelEditingConfirm,
  ExistingEditing,
  ExistingItemBase,
  ExistingItemState,
  ExistingRemoveConfirm,
  ExistingSaving,
} from './ExistingItemState';
import {
  NewItemBase,
  NewItemCancelEditingConfirm,
  NewItemEditing,
  NewItemSaving,
  NewItemState,
} from './NewItemState';

export type Item = NewItemState | ExistingItemState;

export const isExistingItemState = (i: Item): i is ExistingItemState =>
  i instanceof ExistingItemBase;

export const isNewItemState = (i: Item): i is NewItemState =>
  i instanceof NewItemBase;

export type ItemId = 'new-item' | CohortId;

export function validateItem(
  item: NewItemEditing | ExistingEditing,
): NewItemEditing | ExistingEditing | NewItemSaving | ExistingSaving {
  if (isValid(item.title) && isValid(item.description)) {
    if (item instanceof ExistingEditing) {
      return new ExistingSaving(
        item.id,
        item.tenantId,
        item.title,
        item.description,
        item.initialTitle,
        item.initialDescription,
      );
    }

    return new NewItemSaving(item.title, item.description);
  }

  return item;
}

export function isRemoveConfirm(item: Item): item is ExistingRemoveConfirm {
  return item instanceof ExistingRemoveConfirm;
}

export function isEditCancelConfirm(
  item: Item,
): item is ExistingCancelEditingConfirm | NewItemCancelEditingConfirm {
  return (
    item instanceof ExistingCancelEditingConfirm ||
    item instanceof NewItemCancelEditingConfirm
  );
}
