import React, { useContext, useEffect, useMemo } from 'react';
import { SpecializationId } from '../../api/specializations';
import { TenantId } from '../../api/tenants/Tenant';
import { SpecializationContext } from '../../contexts/specialization-context';
import { Autocomplete, Option } from './Autocomplete';
import { TextFieldProps } from './text-field';

interface SpecializationInputProps<
  M extends undefined | boolean,
  A extends boolean | undefined,
> {
  value?: M extends undefined | false ? SpecializationId : SpecializationId[];
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  onChange: (
    value: M extends undefined | false
      ? A extends true
        ? SpecializationId | undefined
        : SpecializationId
      : SpecializationId[],
  ) => void;
  readOnly?: boolean;
  testid?: string;
  multiple: M;
  className?: string;
  size?: 'small' | 'medium';
  disableSearch?: boolean;
  allSpecializations?: boolean;
  isActive?: boolean;
  tenantId?: TenantId;
}

export function SpecializationInput<M extends boolean, A extends boolean>({
  value,
  disabled,
  textFieldProps,
  onChange,
  testid,
  readOnly = false,
  multiple,
  className,
  size,
  disableSearch,
  allSpecializations = false,
  isActive,
  tenantId,
}: SpecializationInputProps<M, A>) {
  const { specializations, loadSpecializations } = useContext(
    SpecializationContext,
  );
  const options = useMemo((): Option<SpecializationId | ''>[] => {
    switch (allSpecializations) {
      case true:
        return (
          [
            { id: '' as const, name: 'Any specialization' },
            ...(specializations ?? []),
          ].map((val) => ({ value: val.id, label: val.name })) || []
        );
      case false:
        return (
          specializations?.map((val) => ({ value: val.id, label: val.name })) ||
          []
        );
    }
  }, [specializations, allSpecializations]);

  useEffect(() => {
    if (!specializations.length) {
      loadSpecializations(tenantId);
    }
  }, [specializations, loadSpecializations, tenantId]);

  return (
    <Autocomplete<SpecializationId | '', M>
      size={size}
      className={className}
      multiple={multiple}
      // @ts-expect-error
      value={value ?? ''}
      options={options}
      // @ts-expect-error
      onChange={(v) => !readOnly && onChange(v || undefined)}
      testId={testid}
      textFieldProps={textFieldProps}
      disabled={
        disabled ||
        !Array.isArray(specializations) ||
        specializations.length === 0
      }
      readOnly={readOnly}
      disableSearch={disableSearch}
      isActive={isActive}
    />
  );
}
