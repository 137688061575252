import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useMemo, useState } from 'react';
import { COLORS } from '../../theme/variables';
import { getCommentDate } from '../../utils/date';
import { createMarkup } from '../../utils/functions';
import { ConfirmButton, Text } from '../common';
import NoteForm, { Values as FormValues } from '../forms/note';
import AttachmentCard, { Attachment } from './attachment-card';

interface NoteProps<T> {
  note: T & {
    creationDate: string;
    contents: string;
    attachmentRefs?: string | null;
  };
  updating?: boolean;
  deleting?: boolean;
  onEdit: (updateNote: T, callback: () => any) => any;
  onUploadFile?: (file: File) => Promise<string>;
  onRemove: (note: T, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    wordBreak: 'break-all',
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  bodyText: {
    wordBreak: 'break-word',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    return JSON.parse(attachmentRefs);
  } catch (e: any) {
    return [];
  }
};

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    return JSON.stringify(attachments);
  } catch (e: any) {
    return '';
  }
};

function Note<T>({
  note,
  updating,
  deleting,
  onEdit,
  onRemove,
  onUploadFile,
  readOnly = false,
  index,
}: NoteProps<T>) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const noteAttachments = useMemo(() => {
    if (note.attachmentRefs) {
      return parseAttachments(note.attachmentRefs);
    }
    return [];
  }, [note]);

  const handleNoteEdit = () => {
    setIsEditing(true);
  };

  const handleNoteRemove = (callback: () => any) => {
    onRemove(note, callback);
  };

  const handleNoteEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitNoteForm = ({ contents, attachments }: FormValues) => {
    const formatedAttachments = formatAttachments(attachments);
    onEdit(
      { ...note, contents, attachmentRefs: formatedAttachments },
      handleNoteEditCancel,
    );
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <NoteForm
          title='Note editing'
          note={{ contents: note.contents, attachments: noteAttachments }}
          loading={updating}
          onUploadFile={onUploadFile}
          onSubmit={handleSubmitNoteForm}
          onCancel={handleNoteEditCancel}
        />
      ) : (
        <>
          <div className={classes.title} data-testid={`note-${index}`}>
            <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
              {getCommentDate(note.creationDate)}
            </Text>
            {!readOnly && (
              <div>
                <IconButton
                  onClick={handleNoteEdit}
                  data-testid={`form-note-edit-${index}`}>
                  <EditIcon />
                </IconButton>
                <ConfirmButton
                  loading={deleting}
                  title='Remove the note?'
                  body='Sure you want to remove the note? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleNoteRemove,
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`form-note-delete-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
          <div>
            <Text className={classes.body} variant='normal'>
              <div
                className={classes.bodyText}
                dangerouslySetInnerHTML={createMarkup(note.contents)}
              />
            </Text>
          </div>
          <div className={classes.attachmentsList}>
            {noteAttachments.map((attachment, attachmentIndex) => (
              <div key={attachmentIndex} className={classes.attachment}>
                <AttachmentCard attachment={attachment} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Note;
