import { useCallback, useEffect, useRef } from 'react';

export function useSafeInvoke() {
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((stateSetter: Function, ...args: Array<any>) => {
    if (mounted.current) {
      stateSetter.call(null, ...args);
    }
  }, []);
}
