import { setIn } from 'final-form';
import * as yup from 'yup';

export const yupValidate =
  (schema: yup.ObjectSchema<{}, yup.AnyObject, {}, ''>) =>
  async (values: Record<string, any>) => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (e: any) {
      return e?.inner?.reduce((errors: any, error: any) => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };
