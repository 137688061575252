import { createContext, useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import LoadingDrawer from '../components/common/loading-drawer';

interface LoadingDrawerProps {
  body?: string | React.ReactNode;
  alias?: string;
  status?: string;
  onClosed?: () => any;
  timer?: number;
}

export interface LoadingDrawerContextProps {
  aliasLoadingExport: string | undefined;
  createLoadingDrawer: (
    loadingDrawer: LoadingDrawerProps,
    operation: string
  ) => any;
  operation?: string;
}

interface LoadingDrawerProviderProps {
  children: React.ReactNode;
}

export const LoadingDrawerContext = createContext<LoadingDrawerContextProps>({
  aliasLoadingExport: '',
  createLoadingDrawer: () => {},
  operation: ''
});

export const LoadingDrawerProvider = ({
  children
}: LoadingDrawerProviderProps) => {
  const [loadingDrawer, setLoadingDrawer] = useState<LoadingDrawerProps>();
  const [aliasLoadingExport, setAliasLoadingExport] = useState<string>();
  const [operation, setOperation] = useState<string>();
  const timerRef = useRef<number>();

  const removeLoadingDrawer = useCallback(() => {
    setLoadingDrawer(undefined);
    setAliasLoadingExport('');
    setOperation(undefined);
    if (typeof loadingDrawer?.onClosed === 'function') {
      loadingDrawer.onClosed();
    }
    clearTimeout(timerRef.current);
  }, [loadingDrawer]);

  const createLoadingDrawer = useCallback(
    (loadingDrawer: LoadingDrawerProps, operation: string) => {
      setLoadingDrawer(loadingDrawer);
      setAliasLoadingExport(loadingDrawer.alias);
      setOperation(operation);
      if (loadingDrawer.timer) {
        timerRef.current = (setTimeout(() => {
          removeLoadingDrawer();
        }, loadingDrawer.timer) as unknown) as number;
      }
    },
    [removeLoadingDrawer]
  );

  const contextValue: LoadingDrawerContextProps = {
    aliasLoadingExport,
    createLoadingDrawer,
    operation
  };

  return (
    <LoadingDrawerContext.Provider value={contextValue}>
      {children}
      {!!loadingDrawer &&
        ReactDOM.createPortal(
          <LoadingDrawer {...loadingDrawer} onClosed={removeLoadingDrawer} />,
          document.body
        )}
    </LoadingDrawerContext.Provider>
  );
};
