import { Question } from '../../api/tenants/QuestionTemplates/Question';
import { TenantId } from '../../api/tenants/Tenant';
import { AddDraft, AddValid } from './AddQuestion';
import { EditDraft, EditValid } from './EditQuestion';

// region Loading
export interface Loading {
  type: 'Loading';
  payload: {
    tenantId: TenantId;
  };
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload
});
// endregion

// region Fail
export interface Fail {
  type: 'Fail';
  payload: {
    tenantId: TenantId;
    message: string;
  };
}

export const fail = (payload: Fail['payload']): Fail => ({
  type: 'Fail',
  payload
});
// endregion

// region Ready
export interface Ready {
  type: 'Ready';
  payload: {
    tenantId: TenantId;
    items: Question[];
  };
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload
});
// endregion

// region AddNew
interface AddNewPayload {
  items: Question[];
  item: AddDraft;
  tenantId: TenantId;
}

export interface AddNew {
  type: 'AddNew';
  payload: AddNewPayload;
}

export const addNew = (payload: AddNew['payload']): AddNew => ({
  type: 'AddNew',
  payload
});
// endregion

// region AddNewErr
interface AddNewErrPayload extends AddNewPayload {
  message: string;
}

export interface AddNewErr {
  type: 'AddNewErr';
  payload: AddNewErrPayload;
}

export const addNewErr = (payload: AddNewErr['payload']): AddNewErr => ({
  type: 'AddNewErr',
  payload
});
// endregion

// region Adding
export interface Adding {
  type: 'Adding';
  payload: {
    tenantId: TenantId;
    items: Question[];
    item: AddValid;
  };
}

export const adding = (payload: Adding['payload']): Adding => ({
  type: 'Adding',
  payload
});
// endregion

// region Edit
interface EditPayload {
  before: Question[];
  after: Question[];
  original: Question;
  item: EditDraft;
  tenantId: TenantId;
}

export interface Edit {
  type: 'Edit';
  payload: EditPayload;
}

export const edit = (payload: Edit['payload']): Edit => ({
  type: 'Edit',
  payload
});
// endregion

// region EditErr
interface EditErrPayload extends EditPayload {
  message: string;
}

export interface EditErr {
  type: 'EditErr';
  payload: EditErrPayload;
}

export const editErr = (payload: EditErr['payload']): EditErr => ({
  type: 'EditErr',
  payload
});
// endregion

// region Updating
export interface Updating {
  type: 'Updating';
  payload: {
    tenantId: TenantId;
    before: Question[];
    after: Question[];
    original: Question;
    item: EditValid;
  };
}

export const updating = (payload: Updating['payload']): Updating => ({
  type: 'Updating',
  payload
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: {
    tenantId: TenantId;
    before: Question[];
    after: Question[];
    item: Question;
  };
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload']
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload
});
// endregion

// region Removing
export interface Removing {
  type: 'Removing';
  payload: {
    tenantId: TenantId;
    before: Question[];
    after: Question[];
    item: Question;
  };
}

export const removing = (payload: Removing['payload']): Removing => ({
  type: 'Removing',
  payload
});
// endregion

export type State =
  | Loading
  | Fail
  | Ready
  | AddNew
  | AddNewErr
  | Adding
  | Edit
  | EditErr
  | Updating
  | RemoveConfirmation
  | Removing;
