import {
  Link as MaterialLink,
  Button as MaterialButton,
  makeStyles,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
import Bowser from 'bowser';
import cn from 'classnames';
import { format } from 'date-fns';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  useContext,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import gatheringsAPI, { Gathering } from '../../api/gatherings';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import {
  formatDateApi,
  formatTimeInterval,
  formatDateInterval,
  datesAreOnSameDay,
} from '../../utils/date';
import {
  decodeQuery,
  encodeQuery,
  isMobile,
  isTablet,
  parsePeriodToString,
} from '../../utils/functions';
import {
  StatusBadge,
  Select,
  Text,
  AlertState,
  Link,
  DateRangePickerInput,
  TextField,
} from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TableFooter,
} from '../common/table';
import AttendanceCodeInfo from '../gathering/attendance-code-info';

interface SearchParams {
  [x: string]: any;
}

interface FilterValue {
  search?: string;
  status?: string;
  type?: string;
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    minHeight: 64,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2,
    flexWrap: 'wrap',
    rowGap: 15,
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  date: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  ventureName: {
    display: 'flex',
    padding: '0 32px 0 10px',
    boxSizing: 'border-box',
  },
  summary: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  filterDateRange: {
    position: 'relative',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  iconRemoveFilter: {
    width: 20,
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  nameContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileNone: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
}));

function getPageFromURL(searchParams: SearchParams) {
  const parsedQuery = _pick(searchParams, ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

function getFilterBySearch(searchParams: SearchParams): FilterValue {
  const decodedFilter = decodeQuery(searchParams.filter || '');
  const parsedQuery = _pick(decodedFilter || {}, [
    'search',
    'status',
    'type',
    'dateRange',
  ]) as FilterValue;
  if (parsedQuery.dateRange) {
    parsedQuery.dateRange = {
      startDate: new Date(parsedQuery.dateRange.startDate),
      endDate: new Date(parsedQuery.dateRange.endDate),
    };
  }
  return parsedQuery as FilterValue;
}

function getSearchParams(search: string): SearchParams {
  return parseQuery(search);
}

function isEmptyFilter(filter: FilterValue) {
  return Object.keys(filter).length === 0;
}

async function gatheringsRequest(filter: FilterValue, page: number) {
  if (!isEmptyFilter(filter)) {
    let loadedGatherings;
    if (filter.search) {
      loadedGatherings = await gatheringsAPI.search(filter.search);
    } else {
      const gatheringFilter = {
        status: filter.status,
        audience: filter.type,
        ...(filter.dateRange
          ? {
              startDate: formatDateApi(filter.dateRange.startDate),
              endDate: formatDateApi(filter.dateRange.endDate),
            }
          : {}),
      };
      loadedGatherings = await gatheringsAPI.searchByFilter(gatheringFilter);
    }
    return loadedGatherings;
  }
  return await gatheringsAPI.getGatherings(page);
}

function formatGatheringDate(date: string) {
  return format(new Date(date), 'E, LLL dd, y');
}

function VenturesTable() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();
  const { hasAccessToAction } = useContext(UserContext);
  const debouncedTimer = useRef<number>();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const searchParams = useMemo(
    () => getSearchParams(location.search),
    [location.search],
  );

  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const filter = useMemo(() => {
    return getFilterBySearch(searchParams);
  }, [searchParams]);

  const [searchValue, setSearchValue] = useState(filter.search || '');
  const [gatherings, setGatherings] = useState<Gathering[]>([]);
  const [selectedGatheringsIds, setSelectedGatheringsIds] = useState<
    Gathering['id'][]
  >([]);

  const page = useMemo(() => getPageFromURL(searchParams), [searchParams]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const perPage = useMemo(
    () => (isEmptyFilter(filter) ? 10 : undefined),
    [filter],
  );

  const filters = useMemo(
    () => ({
      status: [
        {
          label: 'All Statuses',
          value: 'none',
        },
        {
          label: 'Created',
          value: 'CREATED',
        },
        {
          value: 'PUBLISHED',
          label: 'Published',
        },
        {
          value: 'COMPLETED',
          label: 'Completed',
        },
      ],
      type: [
        {
          label: 'Type',
          value: 'none',
        },
        {
          label: `All ${rb('mentors-u')}`,
          value: 'allMentors',
        },
        {
          label: 'All Founders',
          value: 'allFounders',
        },
        {
          label: 'All',
          value: 'all',
        },
      ],
      dateRange: [
        {
          label: 'empty',
          value: '',
        },
      ],
    }),
    [rb],
  );

  const showClearFilter = useMemo(() => {
    const isDataRangeFilter = !!filter.dateRange;

    return (
      !!searchValue || !!filter.status || !!filter.type || isDataRangeFilter
    );
  }, [searchValue, filter]);

  const prepareNewURL = useCallback(
    (nextSearchParams) => {
      return `${location.pathname}${
        Object.keys(nextSearchParams).length
          ? `?${stringifyQuery(nextSearchParams)}`
          : ''
      }`;
    },
    [location.pathname],
  );

  const loadGatherings = async (filter: FilterValue, currentPage: number) => {
    try {
      setGatherings([]);
      setSelectedGatheringsIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (!filter.search && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedGatherings = await gatheringsRequest(filter, currentPage - 1);

      if (loadedGatherings.length === 0) {
        throw new Error('404');
      }
      setGatherings(loadedGatherings);

      setIsLoading(false);

      if (loadedGatherings.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextGatherings = await gatheringsRequest(filter, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextGatherings.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const handleClearFilters = useCallback(() => {
    const paramsArray = ['search', 'status', 'type', 'dateRange'];
    const clearFilter = _omit(filter, paramsArray);
    const clearSearchParams = {
      ...searchParams,
      filter: encodeQuery(clearFilter),
    };
    history.replace(prepareNewURL(clearSearchParams));
  }, [filter, searchParams, prepareNewURL, history]);

  const handleFilterUpdate = useCallback(
    (field: string, value: any, clear?: boolean) => {
      if (!value || value === 'none') {
        const newFilter = !clear ? _omit(filter, [field, 'search']) : {};
        const { filter: prevFilter, ...restSearchParams } = searchParams;
        const nextSearchParams = {
          ...restSearchParams,
          ...(!isEmptyFilter(newFilter)
            ? { filter: encodeQuery(newFilter) }
            : {}),
        };
        history.replace(prepareNewURL(nextSearchParams));
      } else {
        const isClearStatus =
          filter?.status === 'REQUIRE_ATTENTION' ? 'status' : '';
        const newFilter = !clear
          ? _set({ ..._omit(filter, ['search', isClearStatus]) }, field, value)
          : { [field]: value };
        const nextSearchParams = {
          ...searchParams,
          filter: encodeQuery(newFilter),
        };
        history.replace(prepareNewURL(nextSearchParams));
      }
    },
    [history, filter, searchParams, prepareNewURL],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate('search', e.target.value.trim(), true);
        } else {
          handleFilterUpdate('search', null, true);
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        ...searchParams,
        page: nextPage > 1 ? nextPage : undefined,
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [searchParams, location.pathname, history],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, ventureId: Gathering['id']) => {
      if (checked) {
        return setSelectedGatheringsIds(
          _uniq([...selectedGatheringsIds, ventureId]),
        );
      }
      return setSelectedGatheringsIds(
        selectedGatheringsIds.filter((id) => id !== ventureId),
      );
    },
    [selectedGatheringsIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const ventureId = rowData.id;
      handleRowSelect(!selectedGatheringsIds.includes(ventureId), ventureId);
    },
    [selectedGatheringsIds, handleRowSelect],
  );

  const getVariantStatus = (variant: string) => {
    switch (variant.toUpperCase()) {
      case 'CREATED':
        return 'warning';
      case 'PUBLISHED':
        return 'success';
      case 'COMPLETED':
        return 'primary';
      default:
        return undefined;
    }
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const normalizeStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case 'CREATED':
        return 'CREATED';
      case 'PUBLISHED':
        return 'PUBLISHED';
      case 'COMPLETED':
        return 'COMPLETED';
      default:
        return currentStatus;
    }
  };

  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  useEffect(() => {
    loadGatherings(filter, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  return (
    <Paper className={classes.container} data-testid='gathering-table'>
      {hasAccessToAction('gathering.filters') && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'gathering-filter-search',
                !!searchValue,
              )}>
              <TextField
                className={cn(classes.searchInput)}
                value={searchValue}
                onChange={handleSearch}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                small
              />
            </div>
            {!isTabletVersion && (
              <div
                className={cn(classes.filterItem, classes.mobileNone)}
                data-testid={getDataTestId(
                  'gathering-filter-status',
                  !!filter.status,
                )}>
                <Select
                  className={cn(classes.filterSelect)}
                  value={
                    filter.status ? filter.status : filters.status[0].value
                  }
                  options={filters.status}
                  onSelect={(item) =>
                    handleFilterUpdate(
                      'status',
                      item?.value,
                      item?.value === 'REQUIRE_ATTENTION',
                    )
                  }
                  isActive={!!filter.status}
                />
              </div>
            )}
            {!isTabletVersion && (
              <div
                className={cn(classes.filterItem, classes.mobileNone)}
                data-testid={getDataTestId(
                  'gathering-filter-type',
                  !!filter.type,
                )}>
                <Select
                  className={cn(classes.filterSelect)}
                  value={filter.type ? filter.type : filters.type[0].value}
                  options={filters.type}
                  onSelect={(item) => handleFilterUpdate('type', item?.value)}
                  isActive={!!filter.type}
                />
              </div>
            )}
            {!isTabletVersion && (
              <div
                className={cn(
                  classes.filterItem,
                  classes.filterDateRange,
                  classes.mobileNone,
                )}>
                <DateRangePickerInput
                  onChange={(val: any) => {
                    handleFilterUpdate('dateRange', val);
                  }}
                  value={filter.dateRange}
                />
              </div>
            )}
            {showClearFilter && (
              <Tooltip title='Clear all filters'>
                <IconButton
                  data-testid='button-remove-filter'
                  className={classes.containerRemoveFilter}
                  onClick={() => handleClearFilters()}>
                  <img
                    src='/filter.svg'
                    alt='Remove filter'
                    className={classes.iconRemoveFilter}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div ref={containerRef}>
        {gatherings.length > 0 ? (
          <div>
            <Table
              onRowClick={
                hasAccessToAction('gathering.details.update')
                  ? handleRowClick
                  : () => {}
              }
              gridClassName={classes.table}
              headerHeight={56}
              height={
                64 * (gatherings.length > 10 ? gatherings.length : 10) + 56
              }
              rowHeight={64}
              rowCount={gatherings.length}
              rowGetter={({ index }) => gatherings[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`gathering-table-row-${rowProps.index}`}
                  selected={selectedGatheringsIds.includes(rowProps.rowData.id)}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={isTabletVersion ? 15 : 30}
                minWidth={isTabletVersion ? 15 : 30}
                cellRenderer={({ cellData }) => <></>}
              />
              <Column
                dataKey='name'
                className={cn(classes.column, classes.summary)}
                headerClassName={classes.summary}
                width={120}
                minWidth={120}
                flexGrow={1}
                headerRenderer={() => (
                  <TableHeadCell>Gathering Name</TableHeadCell>
                )}
                cellRenderer={({ cellData, rowData }) => (
                  <div className={classes.nameContainer}>
                    <TableCell truncated>
                      <MaterialLink
                        data-testid='gathering-table-name'
                        onClick={stopPropagation}
                        component={Link}
                        to={{
                          pathname: getRoutePath(Pages.GATHERINGS_DETAILS, {
                            gatheringId: rowData.id,
                            groupId: rowData.groupId,
                          }),
                          state: {
                            prevPath: currentPath,
                          },
                        }}>
                        {cellData}
                      </MaterialLink>
                    </TableCell>
                    {rowData.attendanceCode && (
                      <AttendanceCodeInfo
                        gatheringId={rowData.id}
                        attendanceCode={rowData.attendanceCode}>
                        <IconButton>
                          <Text variant='normal' bold>
                            AC
                          </Text>
                        </IconButton>
                      </AttendanceCodeInfo>
                    )}
                  </div>
                )}
              />

              <Column
                dataKey='date'
                className={cn(classes.column, classes.date)}
                headerClassName={classes.date}
                width={isTabletVersion ? 180 : 204}
                minWidth={isTabletVersion ? 180 : 150}
                maxWidth={isTabletVersion ? 180 : 230}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Date</TableHeadCell>}
                cellRenderer={({ rowData }) => {
                  const startDate = new Date(rowData.start);
                  const endDate = new Date(rowData.end);

                  const similarDates = datesAreOnSameDay(startDate, endDate);
                  const date = similarDates
                    ? formatGatheringDate(rowData.start)
                    : formatDateInterval(startDate, endDate);

                  const time = formatTimeInterval(
                    new Date(rowData.start),
                    new Date(rowData.end),
                  );

                  return (
                    <TableCell>
                      <Text bold>{date}</Text>
                      <br />
                      <Text variant='upper1' color={COLORS.COLOR_GRAY_BASE}>
                        {time}
                      </Text>
                    </TableCell>
                  );
                }}
              />
              {!isMobile() && (
                <Column
                  dataKey='periodString'
                  className={cn(classes.column, classes.ventureName)}
                  headerClassName={classes.ventureName}
                  width={isTabletVersion ? 150 : 320}
                  minWidth={isTabletVersion ? 150 : 320}
                  maxWidth={isTabletVersion ? 210 : 400}
                  headerRenderer={() => (
                    <TableHeadCell>Scheduling Frequency</TableHeadCell>
                  )}
                  cellRenderer={({ cellData }) => {
                    const value = parsePeriodToString(cellData);
                    return (
                      <TableCell truncated onClick={stopPropagation}>
                        <Tooltip title={value || ''}>
                          <Text
                            variant='normal'
                            data-testid='gathering-table-scheduling-frequency'>
                            {value || ''}
                          </Text>
                        </Tooltip>
                      </TableCell>
                    );
                  }}
                />
              )}
              {!isMobile() && (
                <Column
                  dataKey='status'
                  className={classes.column}
                  width={isTabletVersion ? 80 : 100}
                  minWidth={isTabletVersion ? 80 : 100}
                  maxWidth={140}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                  cellRenderer={({ cellData }) => {
                    const variant = getVariantStatus(cellData);
                    const statusLabel = normalizeStatus(cellData);
                    return (
                      <StatusBadge status={statusLabel} variant={variant} />
                    );
                  }}
                />
              )}
              {!isTabletVersion && (
                <Column
                  dataKey='actions'
                  className={cn(classes.column, classes.justifyContentEnd)}
                  minWidth={120}
                  width={120}
                  headerRenderer={() => <div></div>}
                  cellRenderer={({ rowData }) => (
                    <TableCell onClick={stopPropagation}>
                      <div className={classes.actionsCell}>
                        <Tooltip
                          title={
                            hasAccessToAction('gathering.details.update')
                              ? 'Edit'
                              : 'View'
                          }>
                          <IconButton
                            data-testid='icon-button-view'
                            component={Link}
                            to={{
                              pathname: getRoutePath(Pages.GATHERINGS_DETAILS, {
                                gatheringId: rowData.id,
                              }),
                              state: {
                                prevPath: currentPath,
                              },
                            }}>
                            {hasAccessToAction('gathering.details.update') ? (
                              <EditIcon />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='gathering-table-no-items'>
                    {hasAccessToAction('gathering.create') ? (
                      <>
                        This panel contains a list of gatherings.
                        <br />
                        We were not able to find information you requested, but
                        feel free to add something new!
                      </>
                    ) : (
                      <>
                        There are no gatherings scheduled yet with your
                        participation.
                      </>
                    )}
                  </Text>
                  {hasAccessToAction('gathering.create') && (
                    <MaterialButton
                      component={Link}
                      to={Pages.NEW_GATHERINGS}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      Gathering
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <TableFooter
            page={page}
            onPageChange={handlePageChange}
            disabled={isLoading}
            isLoading={isNextPageLoading}
            hasNextPage={gatherings.length > 0 && hasNextPage}
          />
        )}
      </div>
    </Paper>
  );
}

export default VenturesTable;
