import {
  IconButton,
  makeStyles,
  Typography,
  Button as MaterialButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { CustomQuestion } from '../../api/CustomQuestion/types/CustomQuestion';
import { ConsentType } from '../../api/auth';
import foundersAPI, { FounderName } from '../../api/founders';
import { TenantId } from '../../api/tenants/Tenant';
import { Venture, VentureId } from '../../api/ventures/types/Venture';
import { ExportIcon } from '../../assets/icons';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import {
  DEDICATION,
  DONE_ALREADY,
  GENDER_WIZARD,
  GROWTH_RATIO,
  lengthField,
  MARKET_SIZE,
  RACE_WIZARD,
  RADIO_SELECT,
  REASON_TO_START_NOW,
  SUPPORT_INTERESTED_RECEIVING,
} from '../../utils/form';
import { createMarkup } from '../../utils/functions';
import {
  Button,
  CheckboxListOther,
  FormGroup,
  PageLoader,
  SnackMessage,
  Text,
  Avatar,
  AttachFilesButton,
} from '../common';
import AttachmentCard, { Attachment } from '../common/attachment-card';
import RadioboxListOther from '../common/radiobox-list-other';
import Wizard from '../common/venture-wizard';
import { ValidFormValues } from '../common/wizard';
import CustomFormField from './custom-form/custom-form-field';
import {
  FormCheckbox,
  FormRadioboxGroup,
  FormSelect,
  FormSpecializationInput,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 20,
  },
  intro: {
    display: 'block',
    marginBottom: 20,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  btn_footer: {
    marginTop: 20,
  },
  semi_title: {
    margin: '20px 0',
  },
  small_title: {
    margin: '20px 0',
  },
  founderName: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteFounderIcon: {
    marginLeft: 10,
  },
  consent: {
    marginBottom: 20,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
  loader: {
    margin: '100px 0',
  },
  founderNamesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: 500,
  },
  uploadPhotoWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: 10,
    alignItems: 'center',
    width: '100%',
  },
  section: {
    width: '100%',
    marginBottom: 20,
  },
  attachmentsSection: {
    width: '100%',
    paddingBottom: 20,
    marginBottom: 0,

    [theme.breakpoints.up(800)]: {
      paddingBottom: 0,
      marginBottom: 20,
    },
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
}));

interface Props {
  onSubmit: (values: ValidFormValues) => void;
  tenantId: TenantId;
  isLoading: boolean;
  consents: ConsentType[];
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
  intro: string;
  venture: Venture | null;
  showSuccess: () => void;
  noteAttachments: Attachment[];
  handleNoteFileUpload: (file: File) => Promise<void>;
  handleNoteFileRemove: (index: number) => void;
  createNote: () => Promise<void>;
}

interface Step1Props {
  countSteps: number;
  intro: string;
  tenantId: TenantId;
}

const Step1 = ({ countSteps, intro, tenantId }: Step1Props) => {
  const classes = useStyles();

  return (
    <div>
      {intro && (
        <Text variant='normal' className={classes.intro}>
          {intro}
        </Text>
      )}
      <Typography variant='h3' className={classes.title}>
        Business Information - Section 1 out of {countSteps}
      </Typography>
      <FormGroup>
        <Field<string>
          name='ventureName'
          component={TextFieldWrapper}
          label='Venture Name*'
          testid='venture-wizard-step1-ventureName'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='numOfEmployees'
          component={TextFieldWrapper}
          type='number'
          label='Number Of Employees*'
          format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
          testid='venture-wizard-step1-numOfEmployees'
        />
      </FormGroup>
      <FormGroup>
        <Field
          name='specializations'
          component={FormSpecializationInput}
          label='Specialization*'
          data-testid='venture-wizard-step1-specializations'
          tenantId={tenantId}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='url'
          component={TextFieldWrapper}
          label='Website'
          testid='venture-wizard-step1-url'
        />
      </FormGroup>
      <Typography variant='h4' className={classes.semi_title}>
        Address
      </Typography>
      <FormGroup>
        <Field<string>
          name='address'
          component={TextFieldWrapper}
          label='Address'
          testid='venture-wizard-step1-address'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='city'
          component={TextFieldWrapper}
          label='City'
          testid='venture-wizard-step1-city'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='state'
          component={TextFieldWrapper}
          label='State'
          testid='venture-wizard-step1-state'
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='zip'
          component={TextFieldWrapper}
          label='Zip code'
          testid='venture-wizard-step1-zip'
          InputProps={{
            inputProps: {
              maxLength: lengthField.zip,
            },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field<string>
          name='country'
          component={TextFieldWrapper}
          label='Country'
          testid='venture-wizard-step1-country'
        />
      </FormGroup>
    </div>
  );
};

interface Step2Props {
  countSteps: number;
}

const Step2 = ({ countSteps }: Step2Props) => {
  const classes = useStyles();

  const formAPI = useForm();

  const handleRemoveFounder = (founderIndex: number) => () => {
    return formAPI.mutators.update('founders', founderIndex, {});
  };

  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  const renderFields = (
    fields: FieldArrayRenderProps<{ fistName: string }, any>['fields'],
  ) => {
    const indexAliases: { [x: string]: number } = {};
    let currentIndex: null | number = null;
    fields.forEach((name, index) => {
      if (Object.keys(fields.value[index]).length) {
        currentIndex = currentIndex !== null ? currentIndex + 1 : 0;
        indexAliases[name] = currentIndex;
      }
    });

    return fields.map(
      (name, index) =>
        Object.keys(fields.value[index]).length > 0 && (
          <div key={`${name}-${index}`}>
            <div className={classes.founderName}>
              <Typography variant='h5' className={classes.small_title}>
                Founder {indexAliases[name] + 1}
              </Typography>
              {index !== 0 && (
                <IconButton
                  className={classes.deleteFounderIcon}
                  onClick={handleRemoveFounder(index)}
                  data-testid={`venture-delete-founder-button-${index}`}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
            <FormGroup>
              <Field<string>
                name={`${name}.firstName`}
                InputProps={{ maxLength: 250 }}
                component={TextFieldWrapper}
                label='First name*'
                testid={`venture-wizard-step2-founders_${index}-firstName`}
              />
              <Field<string>
                name={`${name}.lastName`}
                InputProps={{ maxLength: 250 }}
                component={TextFieldWrapper}
                label='Last name*'
                testid={`venture-wizard-step2-founders_${index}-lastName`}
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name={`${name}.email`}
                component={TextFieldWrapper}
                label='Email*'
                formatOnBlur
                InputProps={{ maxLength: 250 }}
                format={(value: string) => {
                  return value ? value.toLowerCase() : value;
                }}
                testid={`venture-wizard-step2-founders_${index}-email`}
              />
              <Field<string>
                name={`${name}.emailConfirm`}
                component={TextFieldWrapper}
                label='Email (confirm)*'
                formatOnBlur
                InputProps={{ maxLength: 250 }}
                format={(value: string) => {
                  return value ? value.toLowerCase() : value;
                }}
                testid={`venture-wizard-step2-founders_${index}-emailConfirm`}
              />
            </FormGroup>
            <FormGroup>
              <Field<string>
                name={`${name}.phone`}
                label='Phone'
                InputProps={{ maxLength: 250 }}
                component={TextFieldWrapper}
                testid={`venture-wizard-step2-founders_${index}-phone`}
              />
              <Field<string>
                name={`${name}.linkedInProfile`}
                label='LinkedIn profile (link)'
                component={TextFieldWrapper}
                InputProps={{ maxLength: 250 }}
                testid={`venture-wizard-step2-founders_${index}-linkedInProfile`}
              />
            </FormGroup>
            <RadioboxListOther
              name={`${name}.gender`}
              options={GENDER_WIZARD}
              onChange={(value) =>
                handleChangeField(formAPI.change, `${name}.gender`, value)
              }
              text='Gender*'
              labelOther='Other'
              testid={`venture-wizard-step2-founders_${index}-gender`}
            />
            <RadioboxListOther
              name={`${name}.race`}
              options={RACE_WIZARD}
              onChange={(value) =>
                handleChangeField(formAPI.change, `${name}.race`, value)
              }
              text='Race*'
              labelOther='Other'
              testid={`venture-wizard-step2-founders_${index}-race`}
            />
            <FormGroup>
              <Field<string>
                name={`${name}.veteran`}
                testid={`venture-wizard-step2-founders_${index}-veteran`}
                component={FormRadioboxGroup}
                options={RADIO_SELECT}
                label='Veteran'
              />
            </FormGroup>
          </div>
        ),
    );
  };

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Founder Information - Section 2 out of {countSteps}
      </Typography>
      <FieldArray name='founders'>
        {({ fields }: FieldArrayRenderProps<{ fistName: string }, any>) =>
          renderFields(fields)
        }
      </FieldArray>
      <div className={classes.btn_footer}>
        <Button
          type='button'
          variant='outlined'
          onClick={() =>
            formAPI.mutators.push('founders', { firstName: '', active: true })
          }
          data-testid='venture-wizard-step2-addFounder'>
          Add Founder
        </Button>
      </div>
    </div>
  );
};

interface Step3Props {
  countSteps: number;
  showCustomAdditionalInfo?: boolean;
  additionalInfoCustomQuestions?: CustomQuestion[] | null;
}

const Step3 = ({
  countSteps,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
}: Step3Props) => {
  const classes = useStyles();
  const formAPI = useForm();
  const stateForm = formAPI.getState();
  const { rb } = useResourceBundles();

  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Additional Info - Section 3 out of {countSteps}
      </Typography>
      {showCustomAdditionalInfo && additionalInfoCustomQuestions?.length ? (
        additionalInfoCustomQuestions.map((question) => (
          <CustomFormField
            key={question.id}
            question={question}
            hasAccessToUpdate={true}
            onChange={(value) => formAPI.change(question.id, value)}
            value={stateForm.values[question.id] || ''}
          />
        ))
      ) : (
        <>
          <FormGroup>
            <Field<string>
              name='howDidYouFind'
              component={TextFieldWysiwyg}
              placeholder='How did you learn about our program?'
              maxLength={lengthField.additionalInfo}
              testid='venture-wizard-step3-find'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='legally'
              component={FormRadioboxGroup}
              options={RADIO_SELECT}
              label='Are you legally registered business?*'
              testid='venture-wizard-step3-legally'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='businessDescription'
              component={TextFieldWysiwyg}
              placeholder='Please describe what your business does*'
              testid='venture-wizard-step3-businessDescription'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
          <CheckboxListOther
            name='currentProgress'
            options={DONE_ALREADY}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'currentProgress', value)
            }
            text='What have you done already? (select all that apply)*'
            labelOther='Other'
            value={stateForm.values.currentProgress}
            testid='venture-wizard-step3-currentProgress'
          />
          <CheckboxListOther
            name='requiredSupport'
            options={SUPPORT_INTERESTED_RECEIVING}
            onChange={(value) =>
              handleChangeField(formAPI.change, 'requiredSupport', value)
            }
            text='What support are you seeking?*'
            labelOther='Other'
            value={stateForm.values.requiredSupport}
            testid='venture-wizard-step3-requiredSupport'
          />
          <FormGroup>
            <Field<string>
              name='marketSize'
              component={FormSelect}
              options={MARKET_SIZE}
              label='How big is the opportunity you see in the market for your business?*'
              testid='venture-wizard-step3-marketSize'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='reasonToStartNow'
              component={TextFieldWysiwyg}
              placeholder='Why now is the time to pursue this opportunity?*'
              testid='venture-wizard-step3-reasonToStartNow'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='plans'
              component={FormSelect}
              options={REASON_TO_START_NOW}
              label='What better describes your plans for this business?*'
              testid='venture-wizard-step3-plans'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='growthRatio'
              component={FormSelect}
              options={GROWTH_RATIO}
              label='How quickly do you see your business growing?*'
              testid='venture-wizard-step3-growthRatio'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='dedication'
              component={FormSelect}
              options={DEDICATION}
              label='Are you working on this business full-time?*'
              testid='venture-wizard-step3-dedication'
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='yearPlans'
              component={TextFieldWysiwyg}
              placeholder='What are you hoping to accomplish in the next 12 months?*'
              testid='venture-wizard-step3-yearPlans'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='obstacles'
              component={TextFieldWysiwyg}
              placeholder='What obstacles do you foresee in reaching these goals?*'
              testid='venture-wizard-step3-obstacles'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='mentorshipPlans'
              component={TextFieldWysiwyg}
              placeholder={`How do you think ${rb(
                'mentorship',
              )} is going to help you achieve these?*`}
              testid='venture-wizard-step3-mentorshipPlans'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
          <FormGroup>
            <Field<string>
              name='comments'
              component={TextFieldWysiwyg}
              testid='venture-wizard-step3-comments'
              placeholder='Comments'
              maxLength={lengthField.additionalInfo}
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};

interface Step4Props {
  countSteps: number;
  consents: ConsentType[];
}

const Step4 = ({ consents, countSteps }: Step4Props) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Commitment - Section 4 out of {countSteps}
      </Typography>
      {consents.map((consent) => (
        <div className={classes.consent} key={consent.id}>
          <div
            className={classes.consent}
            dangerouslySetInnerHTML={createMarkup(consent.body)}
          />
          <Field<string>
            name={`field-${consent.id}`}
            component={FormCheckbox}
            label='I accept'
            classNameLabel={classes.checkboxLabel}
            testid='venture-wizard-step4-accept'
          />
        </div>
      ))}
    </div>
  );
};

interface Step5Props {
  countSteps: number;
  tenantId: TenantId;
  ventureId: VentureId | null;
  attachments: Attachment[];
  handleFileUpload: (file: File) => Promise<void>;
  handleFileRemove: (index: number) => void;
}

const Step5 = ({
  countSteps,
  tenantId,
  ventureId,
  attachments,
  handleFileUpload,
  handleFileRemove,
}: Step5Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [founders, setFounders] = useState<FounderName[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  useEffect(() => {
    async function getFounders() {
      if (ventureId) {
        setLoading(true);
        try {
          const founders = await foundersAPI.getPublicFounderNames(
            ventureId,
            tenantId,
          );
          setFounders(founders);
        } catch (e: any) {
          const messageError =
            e.response?.data?.message || 'Internal server error';

          enqueueSnackbar('An error occurred while enrollment', {
            variant: 'error',
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            style: { whiteSpace: 'pre-line' },
          });
        } finally {
          setLoading(false);
        }
      }
    }

    getFounders();
  }, [enqueueSnackbar, tenantId, ventureId]);

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <Typography variant='h3' className={classes.title}>
        Thank you. We are Almost Done! Step {countSteps} out of {countSteps}
      </Typography>
      <Text variant='normal' className={classes.intro}>
        In this optional step, you're invited to upload photos of your founding
        team and your venture's pitch deck. Uploading photos of your founding
        team aids in completing our database, streamlining data entry.
        Additionally, your pitch deck offers valuable insights for a thorough
        assessment of your application.
      </Text>
      {founders.length > 0 && (
        <div className={classes.section}>
          <Typography variant='h4' className={classes.title}>
            Upload Founder Photos (optional)
          </Typography>
          <div className={classes.founderNamesList}>
            {founders?.map((founder, index) => (
              <UploadFounderPhoto
                key={index}
                data={founder}
                tenantId={tenantId}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
      <div className={classes.attachmentsSection}>
        <Typography variant='h4' className={classes.title}>
          Upload Your Pitch Deck (optional)
        </Typography>
        <AttachFilesButton
          text='Upload Deck'
          variant='outlined'
          onChange={handleSelectFile}
        />
        <div className={classes.attachmentsList}>
          {attachments.map((attachment, attachIndex) => (
            <div key={attachIndex} className={classes.attachment}>
              <AttachmentCard
                attachment={attachment}
                loading={!attachment.url}
                onRemove={() => handleFileRemove(attachIndex)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface UploadFounderPhotoProps {
  data: FounderName;
  tenantId: TenantId;
  index: number;
}

const UploadFounderPhoto = ({
  data,
  tenantId,
  index,
}: UploadFounderPhotoProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [logoUrl, setLogoUrl] = useState<string | null>(data.logoUrl);

  const validateLogoFile = (logo: File) => {
    const fileName = logo.name;
    if (fileName.length > lengthField.logoFileName) {
      enqueueSnackbar(
        `The maximum length of the file name is ${lengthField.logoFileName} characters`,
        {
          variant: 'error',
        },
      );
      return false;
    }
    return true;
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const photoFile = e.target.files ? e.target.files[0] : null;
    if (photoFile && validateLogoFile(photoFile)) {
      const response = await foundersAPI.updatePublicAvatar(
        tenantId,
        data.founderId,
        photoFile,
      );
      setLogoUrl(response.logoUrl);
    }
  };

  // This is a hack to validate the same file again
  const handleFileUploadClick = (e: React.MouseEvent<HTMLInputElement>) =>
    ((e.target as HTMLInputElement).value = '');

  return (
    <div className={classes.uploadPhotoWrapper}>
      <Avatar
        src={logoUrl}
        name={`${data.firstName?.[0]}${data.lastName?.[0]}`}
        size='40'></Avatar>
      <Text variant='normal'>
        {data.firstName} {data.lastName}
      </Text>
      <MaterialButton
        data-testid={`upload-photo-button-${index}`}
        variant='outlined'
        component='label'
        color='primary'
        startIcon={<ExportIcon fontSize='inherit' />}>
        Upload photo
        <input
          type='file'
          accept='image/*'
          id={`upload-photo-input-${data.founderId}`}
          hidden
          onChange={handleFileUpload}
          onClick={handleFileUploadClick}
        />
      </MaterialButton>
    </div>
  );
};

function VentureApplicationsWizardForm({
  onSubmit,
  tenantId,
  isLoading,
  consents,
  showCustomAdditionalInfo,
  additionalInfoCustomQuestions,
  intro,
  venture,
  showSuccess,
  noteAttachments,
  handleNoteFileUpload,
  handleNoteFileRemove,
  createNote,
}: Props) {
  const withConsents = consents.length > 0;
  const countSteps = withConsents ? 5 : 4;

  if (countSteps === 4) {
    return (
      <Wizard
        // @ts-expect-error
        onSubmit={onSubmit}
        tenantId={tenantId}
        isLoading={isLoading}
        showCustomAdditionalInfo={showCustomAdditionalInfo}
        additionalInfoCustomQuestions={additionalInfoCustomQuestions}
        consents={consents}
        showSuccess={showSuccess}
        onDone={createNote}>
        <Step1 countSteps={countSteps} intro={intro} tenantId={tenantId} />
        <Step2 countSteps={countSteps} />
        <Step3
          countSteps={countSteps}
          showCustomAdditionalInfo={showCustomAdditionalInfo}
          additionalInfoCustomQuestions={additionalInfoCustomQuestions}
        />
        <Step5
          countSteps={countSteps}
          tenantId={tenantId}
          ventureId={venture?.id || null}
          attachments={noteAttachments}
          handleFileUpload={handleNoteFileUpload}
          handleFileRemove={handleNoteFileRemove}
        />
      </Wizard>
    );
  }

  return (
    <Wizard
      // @ts-expect-error
      onSubmit={onSubmit}
      tenantId={tenantId}
      isLoading={isLoading}
      showCustomAdditionalInfo={showCustomAdditionalInfo}
      additionalInfoCustomQuestions={additionalInfoCustomQuestions}
      consents={consents}
      showSuccess={showSuccess}
      onDone={createNote}>
      <Step1 countSteps={countSteps} intro={intro} tenantId={tenantId} />
      <Step2 countSteps={countSteps} />
      <Step3
        countSteps={countSteps}
        showCustomAdditionalInfo={showCustomAdditionalInfo}
        additionalInfoCustomQuestions={additionalInfoCustomQuestions}
      />
      <Step4 consents={consents} countSteps={countSteps} />
      <Step5
        countSteps={countSteps}
        tenantId={tenantId}
        ventureId={venture?.id || null}
        attachments={noteAttachments}
        handleFileUpload={handleNoteFileUpload}
        handleFileRemove={handleNoteFileRemove}
      />
    </Wizard>
  );
}

export default VentureApplicationsWizardForm;
