import { Timeslot } from '../../../../../../api/Appointments/types/Timeslot';
import { TenantId } from '../../../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../../../api/tenants/types/Settings';
import { AvailablityFormValues } from '../../../../../../components/Advisors/Dialogs/Availability';
import { Item } from './Item';
import { LoggedIn } from './User';

// region Book
export interface Book {
  type: 'Book';
  payload: {
    advisor: Item;
    strictOfficeHoursScheduling: boolean;
    tenantId: TenantId;
    timeZone: TenantTimeZone;
  };
}

export const book = (payload: Book['payload']): Book => ({
  type: 'Book',
  payload,
});
// endregion

// region BookFetchSuccess
export interface BookFetchSuccess {
  type: 'BookFetchSuccess';
  payload: Timeslot[];
}

export const bookFetchSuccess = (
  payload: BookFetchSuccess['payload'],
): BookFetchSuccess => ({
  type: 'BookFetchSuccess',
  payload,
});
// endregion

// region BookFetchError
export interface BookFetchError {
  type: 'BookFetchError';
  payload: string;
}

export const bookFetchError = (
  payload: BookFetchError['payload'],
): BookFetchError => ({
  type: 'BookFetchError',
  payload,
});
// endregion

// region BookDay
export interface BookDay {
  type: 'BookDay';
  payload: Date;
}

export const bookDay = (payload: BookDay['payload']): BookDay => ({
  type: 'BookDay',
  payload,
});
// endregion

// region BookTime
export interface BookTime {
  type: 'BookTime';
  payload: number;
}

export const bookTime = (payload: BookTime['payload']): BookTime => ({
  type: 'BookTime',
  payload,
});
// endregion

// region ChooseAvailability
export interface ChooseAvailability {
  type: 'ChooseAvailability';
}

export const chooseAvailability = (): ChooseAvailability => ({
  type: 'ChooseAvailability',
});
// endregion

// region SetAvailability
export interface SetAvailability {
  type: 'SetAvailability';
  payload: AvailablityFormValues;
}

export const setAvailability = (
  payload: SetAvailability['payload'],
): SetAvailability => ({
  type: 'SetAvailability',
  payload,
});

// endregion

// region SetValue
export interface SetValue {
  type: 'SetValue';
  payload: {
    key: 'name' | 'email' | 'confirmEmail' | 'topic' | 'agenda' | 'terms';
    value: string;
  };
}

export const setValue = (payload: SetValue['payload']): SetValue => ({
  type: 'SetValue',
  payload,
});
// endregion

// region Toggle
export interface Toggle {
  type: 'Toggle';
  payload: 'name' | 'email' | 'confirmEmail' | 'topic' | 'agenda' | 'terms';
}

export const toggle = (payload: Toggle['payload']): Toggle => ({
  type: 'Toggle',
  payload,
});
// endregion

// region Back
export interface Back {
  type: 'Back';
}

export const back = (): Back => ({
  type: 'Back',
});
// endregion

// region Cancel
export interface Cancel {
  type: 'Cancel';
}

export const cancel = (): Cancel => ({
  type: 'Cancel',
});
// endregion

// region Confirm
export interface Confirm {
  type: 'Confirm';
}

export const confirm = (): Confirm => ({
  type: 'Confirm',
});
// endregion

// region Decline
export interface Decline {
  type: 'Decline';
}

export const decline = (): Decline => ({
  type: 'Decline',
});
// endregion

// region Submit
export interface Submit {
  type: 'Submit';
}

export const submit = (): Submit => ({
  type: 'Submit',
});
// endregion

// region SaveError
export interface SaveError {
  type: 'SaveError';
  payload: string;
}

export const saveError = (payload: SaveError['payload']): SaveError => ({
  type: 'SaveError',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: 'SaveSuccess';
}

export const saveSuccess = (): SaveSuccess => ({
  type: 'SaveSuccess',
});
// endregion

// region SaveSuccessWithPayment

export interface SaveSuccessWithPayment {
  type: 'SaveSuccessWithPayment';
  payload: {
    paymentUrl: string;
    responseId: string;
  };
}

export const saveSuccessWithPayment = (
  payload: SaveSuccessWithPayment['payload'],
): SaveSuccessWithPayment => ({
  type: 'SaveSuccessWithPayment',
  payload,
});
// endregion

// region cancelPayment

export interface CancelPayment {
  type: 'CancelPayment';
}

export const cancelPayment = (): CancelPayment => ({
  type: 'CancelPayment',
});
// endregion

// region SetName
export interface SetName {
  type: 'SetName';
  payload: string;
}

export const setName = (payload: SetName['payload']): SetName => ({
  type: 'SetName',
  payload,
});
// endregion

// region SetPass
export interface SetPass {
  type: 'SetPass';
  payload: string;
}

export const setPass = (payload: SetPass['payload']): SetPass => ({
  type: 'SetPass',
  payload,
});
// endregion

// region Login
export interface Login {
  type: 'Login';
}

export const login = (): Login => ({
  type: 'Login',
});
// endregion

// region LoginError
export interface LoginError {
  type: 'LoginError';
  payload: string;
}

export const loginError = (payload: LoginError['payload']): LoginError => ({
  type: 'LoginError',
  payload,
});
// endregion

// region UserLoggedIn
export interface UserLoggedIn {
  type: 'UserLoggedIn';
  payload: LoggedIn;
}

export const userLoggedIn = (
  payload: UserLoggedIn['payload'],
): UserLoggedIn => ({
  type: 'UserLoggedIn',
  payload,
});
// endregion

// region UserLoggedOut
export interface UserLoggedOut {
  type: 'UserLoggedOut';
}

export const userLoggedOut = (): UserLoggedOut => ({
  type: 'UserLoggedOut',
});
// endregion

// region TogglePassword
export interface TogglePassword {
  type: 'TogglePassword';
}

export const togglePassword = (): TogglePassword => ({
  type: 'TogglePassword',
});
// endregion

// region GoToReset
export interface GoToReset {
  type: 'GoToReset';
}

export const goToReset = (): GoToReset => ({
  type: 'GoToReset',
});
// endregion

// region ResetPassword
export interface ResetPassword {
  type: 'ResetPassword';
}

export const resetPassword = (): ResetPassword => ({
  type: 'ResetPassword',
});
// endregion

// region ResetSuccess
export interface ResetSuccess {
  type: 'ResetSuccess';
}

export const resetSuccess = (): ResetSuccess => ({
  type: 'ResetSuccess',
});
// endregion

// region ResetError
export interface ResetError {
  type: 'ResetError';
  payload: string;
}

export const resetError = (payload: ResetError['payload']): ResetError => ({
  type: 'ResetError',
  payload,
});
// endregion

export type Actions =
  | Book
  | BookFetchSuccess
  | BookFetchError
  | BookDay
  | BookTime
  | ChooseAvailability
  | SetAvailability
  | SetValue
  | Toggle
  | Back
  | SetName
  | SetPass
  | TogglePassword
  | Login
  | LoginError
  | UserLoggedIn
  | UserLoggedOut
  | Cancel
  | Confirm
  | Decline
  | Submit
  | SaveError
  | SaveSuccess
  | SaveSuccessWithPayment
  | GoToReset
  | ResetPassword
  | ResetSuccess
  | ResetError
  | CancelPayment;
