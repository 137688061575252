import * as State from './types/State';
import { Actions } from './types/Actions';
import { isNonEmptyArray } from '../../../utils/Array/NonEmptyArray';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Open':
      return s.type === 'Idle' ? State.loading(s.payload) : s;
    case 'LoadFail':
      return s.type === 'Loading'
        ? State.loadErr({
            message: a.payload,
            ventureId: s.payload.ventureId,
          })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? a.payload.founders >= 1 && a.payload.mentors >= 2
          ? isNonEmptyArray(a.payload.wizards)
            ? State.reserved({
                ...s.payload,
                wizards: a.payload.wizards,
              })
            : State.ready(s.payload)
          : State.declined(s.payload)
        : s;
    case 'Approve':
      return s.type === 'Ready'
        ? State.creating(s.payload)
        : s.type === 'Reserved'
        ? State.canceling(s.payload)
        : s;
    case 'Deny':
      return s.type === 'Ready' || s.type === 'Reserved'
        ? State.idle(s.payload)
        : s;
    case 'CancelingFail':
      return s.type === 'Canceling'
        ? State.cancelingError({ ...s.payload, message: a.payload })
        : s;
    case 'CancelingSuccess':
      return s.type === 'Canceling' ? State.ready(s.payload) : s;
    case 'CreateFail':
      return s.type === 'Creating'
        ? State.createErr({
            message: a.payload,
            ventureId: s.payload.ventureId,
          })
        : s;
    case 'CreateSuccess':
      return s.type === 'Creating' || s.type === 'Canceling'
        ? State.createSuccess(s.payload)
        : s;
    case 'Reset':
      return State.idle(s.payload);
  }
}
