import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../../../theme/variables';

const useStyles = makeStyles(() => ({
  main: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    padding: '16px 20px 16px 20px',
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  isActive: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,

    '& $icon': {
      color: COLORS.COLOR_BLUE_DARKENED_10,
    },
  },

  icon: {
    color: COLORS.COLOR_GRAY_BASE,
  },
}));

export interface Props {
  icon: ReactElement;
  active: boolean;
  label: string;
  path: string;
}

export function Item({ icon, label, path, active }: Props): ReactElement {
  const classes = useStyles();

  return (
    <NavLink
      to={path}
      className={classes.main}
      activeClassName={classes.isActive}>
      {icon}
      <span>{label}</span>
    </NavLink>
  );
}
