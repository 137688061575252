import {
  Link as MaterialLink,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../theme/variables';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Link, Text } from '../common';

interface MenuItem {
  label: string;
  count: number;
  link?: string;
  ['data-testid']?: string;
}

interface Statistic {
  total: number;
}

export interface DashboardCardProps {
  logo: React.ReactNode;
  link: string;
  title: string;
  help?: string;
  statHelp?: string;
  addLink?: string;
  menuItems: MenuItem[];
  statistic: Statistic;
  replaceableCounter?: boolean;
  cardType?: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 20px 28px 32px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 28,
    flexGrow: 1,
  },
  title: {
    display: 'inline-block',
  },
  titleText: {
    width: 'min-content',
  },
  helpIcon: {
    fontSize: 18,
    width: '0',
    height: '20px',
    cursor: 'pointer',
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    display: 'inline-block',
    marginLeft: '5px',
    verticalAlign: 'middle',
    '& > svg': {
      width: '18px',
      height: '18px',
    },
  },
  addLink: {
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: '100%',
    background: COLORS.COLOR_BLUE_BASE,
    color: '#fff',
    textDecoration: 'none',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  statistic: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: COLORS.COLOR_TEXT_BASE,
  },
  statisticCount: {
    display: 'flex',
    alignItems: 'center',
  },
  statisticCounts: {
    marginLeft: 8,
  },
  positive: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  negative: {
    color: COLORS.COLOR_RED_BASE,
  },
  menu: {
    display: 'none',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginTop: 10,

    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',

    '& + &': {
      marginTop: 8,
    },
  },
  menuCount: {
    minWidth: 35,
    marginLeft: 10,
    textAlign: 'left',
  },
  titleContainer: {
    flex: '1 1 0',
  },
}));

function DashboardCard({
  logo,
  link,
  help,
  title,
  statHelp,
  addLink,
  menuItems,
  statistic,
  replaceableCounter = false,
  cardType,
}: DashboardCardProps) {
  const classes = useStyles();

  return (
    <Paper data-testid='dashboard-card' className={classes.card}>
      {addLink && (
        <RouterLink
          className={cn(classes.addLink, CLASS_TRACKING.DASHBOARD_QUICK_CREATE)}
          data-testid={`${cardType}-create-button`}
          to={addLink}>
          <AddIcon />
        </RouterLink>
      )}
      <div className={classes.logo}>{logo}</div>
      <div className={classes.infoBlock}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant='subtitle1'>
            <span className={classes.titleText}>{title}</span>
            {!!help && (
              <Tooltip title={help}>
                <span className={classes.helpIcon}>
                  <InfoIcon />
                </span>
              </Tooltip>
            )}
          </Typography>
          <RouterLink
            to={link}
            data-testid={`${cardType}-statistic`}
            className={cn(classes.statistic, CLASS_TRACKING.DASHBOARD_LINK)}>
            <Typography variant='h1'>{statistic.total}</Typography>
          </RouterLink>
          <MaterialLink
            to={link}
            component={Link}
            variant='button'
            data-testid={`${cardType}-show-all`}
            className={CLASS_TRACKING.DASHBOARD_LINK}>
            Show all
          </MaterialLink>
        </div>
        <div className={classes.menu}>
          {menuItems.map((menuItem) => (
            <span key={menuItem.label} className={classes.menuItem}>
              {menuItem.link ? (
                <MaterialLink
                  className={CLASS_TRACKING.DASHBOARD_LINK}
                  variant='caption'
                  underline='none'
                  component={Link}
                  to={menuItem.link}
                  data-testid={menuItem['data-testid']}>
                  {menuItem.label}
                </MaterialLink>
              ) : (
                <Typography
                  className={CLASS_TRACKING.DASHBOARD_LINK}
                  variant='caption'
                  data-testid={menuItem['data-testid']}>
                  {menuItem.label}
                </Typography>
              )}
              <Text className={classes.menuCount} variant='normal2'>
                {menuItem.count}
              </Text>
            </span>
          ))}
        </div>
      </div>
    </Paper>
  );
}

export default DashboardCard;
