import { TenantId } from '../../api/tenants/Tenant';
import { Type } from '../../api/tenants/QuestionTemplates/Question';
import * as Description from './Description';

export interface AddDraft {
  type: Type;
  tenantId: TenantId;
  description: string;
  required: boolean;
}

export interface AddValid extends AddDraft {
  description: Description.Description;
}

export const isValid = (v: AddDraft): v is AddValid =>
  Description.is(v.description);
