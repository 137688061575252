import { useState, useRef } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Text } from '../common';
import { COLORS } from '../../theme/variables';
import { isTablet } from '../../utils/functions';
import { useHistory } from 'react-router-dom';
import { getRoutePath, Pages } from '../../router/constants';

interface VentureIssueTableInfoProps {
  ventureId: string;
  ventureTags: string[];
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 300,
    boxSizing: 'border-box'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    minWidth: 30,
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
    textAlign: 'center',
    borderRadius: 16,
    padding: 7,

    '& + &': {
      marginTop: 10
    }
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function VentureIssueTableInfo({
  ventureId,
  ventureTags,
  children
}: VentureIssueTableInfoProps) {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const debouncedTimer = useRef<number>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = async (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      setAnchorEl(event.target as HTMLElement);
      setIsOpen(true);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    handleClose();
  };

  const handleClick = () => {
    if (!isTablet()) {
      history.push(
        getRoutePath(Pages.VENTURE_DETAILS, {
          ventureId
        })
      );
    }
  };

  return (
    <>
      <div
        className={classes.wrapper}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        <div>
          {ventureTags.map((value) => (
            <div
              key={`venture-issue-info-${value}`}
              className={classes.paragraph}>
              <Text variant='normal'>{value}</Text>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

export default VentureIssueTableInfo;
