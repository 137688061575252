import { Specialization } from '../../../../api/specializations';
import { State } from './types/State';

export function codeMessage(code: number) {
  switch (code) {
    case 404:
      return 'Tenant cannot be found.';
    default:
      return 'Oops, something went wrong!';
  }
}

export function getSpecializations(s: State): Specialization[] {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return [];
    case 'Ready':
    case 'Fetching':
    case 'FetchError':
      return s.payload.specializations;
  }
}
