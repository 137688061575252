import { Link as MaterialLink, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Event } from '../../api/events';
import { Pages } from '../../router/constants';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Link } from '../common';
import DashboardEventList from './dashboard-event-list';

interface UpcomingEventsProps {
  events: Event[];
  title: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: 58,
    flexDirection: 'column',
    rowGap: 20,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      rowGap: 0,
    },
  },
  titleBlock: {
    maxWidth: 266,
    minWidth: 207,
    paddingLeft: 32,
    boxSizing: 'border-box',
  },
  eventList: {
    padding: '0 20px',
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.33333333%',
    },
  },
  icon: {
    fontSize: 48,
    marginBottom: 5,
  },
  title: {
    marginBottom: 12,
  },
}));

export function UpcomingEvents({ events, title }: UpcomingEventsProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <EventAvailableIcon color='primary' className={classes.icon} />
        <Typography className={classes.title} variant='h2'>
          {title}
        </Typography>
        <MaterialLink
          variant='body2'
          to={Pages.SESSIONS}
          component={Link}
          className={CLASS_TRACKING.DASHBOARD_QUICK_CREATE}>
          All Sessions
        </MaterialLink>
      </div>
      <div className={classes.eventList}>
        <DashboardEventList events={events} />
      </div>
    </div>
  );
}
