import { useCallback, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import LogForm, { Values as FormValues } from '../../components/forms/log';
import { COLORS } from '../../theme/variables';
import { Attachment } from './attachment-card';

interface NewLogProps {
  creating?: boolean;
  onUploadFile: (file: File) => Promise<string>;
  onCreate: (
    newLog: { contents: string; attachmentRefs: string },
    callback: () => any
  ) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56
  },
  main: {
    display: 'flex',
    alignItems: 'center'
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)'
    }
  }
});

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function NewLog({ creating, onUploadFile, onCreate }: NewLogProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);

  const handleOpenForm = () => {
    setIsShownForm(true);
  };

  const handleCloseForm = () => {
    setIsShownForm(false);
  };

  const handleCreateLog = useCallback(
    (values: FormValues) => {
      const attachmentRefs = formatAttachments(values.attachments);
      onCreate({ contents: values.contents, attachmentRefs }, handleCloseForm);
    },
    [onCreate]
  );

  const handleUploadFile = useCallback(
    (file: File) => {
      return onUploadFile(file);
    },
    [onUploadFile]
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, []);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <LogForm
          title='New log'
          loading={creating}
          onSubmit={handleCreateLog}
          onUploadFile={handleUploadFile}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div className={classes.main}>
          <Typography variant='h4'>New log</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={handleOpenForm}
            data-testid='button-new-log'>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default NewLog;
