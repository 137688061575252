import { AxiosError } from 'axios';
import { axiosRequest } from '../instance';
import { TenantId } from '../tenants/Tenant';
import { Audience } from './types/Audience';
import {
  CreateCustomQuestion,
  CustomQuestion,
  CustomQuestionPositionItem,
  EditCustomQuestion,
  RespondersCustomQuestions,
} from './types/CustomQuestion';

export function getAllCustomQuestions(
  tenantId: TenantId,
  audience: Audience,
): Promise<CustomQuestion[]> {
  return axiosRequest
    .get<CustomQuestion[]>({
      url: `/public/tenants/${tenantId}/customquestions/questions/${audience}`,
      credentials: true,
    })
    .then((r) => r.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }
      throw e;
    });
}

export function getRespondersCustomQuestion(
  responderId: string,
  audience: Audience,
) {
  return axiosRequest
    .get<RespondersCustomQuestions>({
      url: `/customquestions/audience/${audience}/responder/${responderId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function getRespondersCustomQuestionByTenantId(
  tenantId: TenantId,
  responderId: string,
  audience: Audience,
) {
  return axiosRequest
    .get<RespondersCustomQuestions>({
      url: `/public/tenants/${tenantId}/customquestions/audience/${audience}/responder/${responderId}`,
      credentials: true,
    })
    .then((r) => r.data);
}

export function createCustomQuestionResponse(
  tenantId: TenantId,
  data: RespondersCustomQuestions,
) {
  return axiosRequest
    .post<unknown>({
      url: `/public/tenants/${tenantId}/customquestions/responses`,
      credentials: true,
      data,
    })
    .then((r) => r.data);
}

export function createCustomQuestion(
  data: CreateCustomQuestion,
): Promise<CustomQuestion> {
  return axiosRequest
    .post<CustomQuestion>({
      url: '/customquestions/questions',
      credentials: true,
      data,
    })
    .then((r) => r.data);
}

export function updateCustomQuestion(
  data: EditCustomQuestion,
): Promise<CustomQuestion> {
  return axiosRequest
    .put<CustomQuestion>({
      url: '/customquestions/questions',
      credentials: true,
      data,
    })
    .then((r) => r.data);
}

export function updateCustomQuestionsPositions(
  data: CustomQuestionPositionItem[],
): Promise<CustomQuestionPositionItem[]> {
  return axiosRequest
    .put<CustomQuestionPositionItem[]>({
      url: '/customquestions/questions/positions',
      credentials: true,
      data,
    })
    .then((r) => r.data);
}
