import { AxiosError } from 'axios';
import { isT } from 'fp-utilities';
import { difference } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  addSpecialization,
  getByEmail,
  getById,
  getByName,
  getSpecializations,
  removeSpecialization,
  update,
  updateAvatar,
} from '../../../api/Advisors';
import { AdvisorId, Update } from '../../../api/Advisors/types/Advisor';
import { Tenant } from '../../../api/auth';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { useBeforeUnload } from '../../../hooks/useBeforeUnload';
import { isVerifying } from '../../../utils/FormValue';
import { unreachableError } from '../../../utils/unreachableError';
import { Item } from '../common/types/Item';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';
import { formEdited, savingToAdvisorUpdate, validateAdvisor } from './utils';

export function useAdvisorEdit(
  user: Tenant,
): [
  State.State,
  Dispatch<
    | Actions.Upload
    | Actions.SetValue<keyof Item>
    | Actions.Toggle
    | Actions.Save
  >,
] {
  const params = useParams<{ id: AdvisorId }>();
  const { rb } = useResourceBundles();
  const [state, dispatch] = useReducer(
    reducer,
    State.init(user.id, user.timeZone, params.id),
  );
  const { confirm } = useBeforeUnload(
    () => formEdited(state),
    () => dispatch(Actions.unload()),
  );
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const state$ = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    state$.next(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const get$ = state$
      .pipe(
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap(({ payload: { tenantId, id, timeZone } }) =>
          forkJoin({
            advisor: getById(tenantId, timeZone, id),
            specializations: getSpecializations(id).then((r) =>
              r.map((i) => i.id),
            ),
          }).pipe(
            map(Actions.loadSuccess),
            catchError(() =>
              of(Actions.loadError(`Unable to load ${rb('advisor')}`)),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    const logo$ = state$
      .pipe(
        filter((s): s is State.Uploading => s.type === 'Uploading'),
        map((s) => s.payload.logo),
        distinctUntilChanged(),
        map((file) => Actions.uploadSuccess(URL.createObjectURL(file))),
      )
      .subscribe(dispatch);

    const checkEmail$ = state$
      .pipe(
        filter(State.isEditable),
        distinctUntilChanged(
          (s1, s2) => s1.payload.item.email === s2.payload.item.email,
        ),
        switchMap((s) => {
          return of(s).pipe(
            map((s) => s.payload.item.email),
            filter(isVerifying),
            map((v) => v.value),
            filter(isT),
            distinctUntilChanged(),
            switchMap((v) => {
              return from(
                getByEmail(s.payload.advisor.tenantId, s.payload.timeZone, v),
              ).pipe(
                map((v) =>
                  v.map((i) => i.id).includes(s.payload.advisor.id)
                    ? Actions.emailValidation(true)
                    : Actions.emailValidation(false),
                ),
                catchError((e: AxiosError) => {
                  return e.response?.status === 404
                    ? of(Actions.emailValidation(true))
                    : of(Actions.emailValidationError(e.response?.data));
                }),
              );
            }),
          );
        }),
      )
      .subscribe(dispatch);

    const checkName$ = state$
      .pipe(
        filter(State.isEditable),
        distinctUntilChanged(
          (s1, s2) =>
            s1.payload.item.firstName === s2.payload.item.firstName &&
            s1.payload.item.lastName === s2.payload.item.lastName,
        ),
        switchMap((s) => {
          return of(s).pipe(
            filter(
              (s) =>
                isVerifying(s.payload.item.firstName) ||
                isVerifying(s.payload.item.lastName),
            ),
            map(
              ({
                payload: {
                  item: { firstName, lastName },
                },
              }) =>
                firstName.value && lastName.value
                  ? { firstName: firstName.value, lastName: lastName.value }
                  : undefined,
            ),
            filter(isT),
            switchMap((v) => {
              return from(
                getByName(
                  s.payload.advisor.tenantId,
                  s.payload.timeZone,
                  v.firstName,
                  v.lastName,
                ),
              ).pipe(
                map((v) =>
                  v.map((i) => i.id).includes(s.payload.advisor.id)
                    ? Actions.nameValidation(true)
                    : Actions.nameValidation(false),
                ),
                catchError((e: AxiosError) => {
                  return e.response?.status === 404
                    ? of(Actions.nameValidation(true))
                    : of(Actions.nameValidationError(e.response?.data));
                }),
              );
            }),
          );
        }),
      )
      .subscribe(dispatch);

    const save$ = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter(State.isSaving),
        switchMap((s) => {
          return from(
            Promise.all([
              update(prepareForSave(s), s.payload.timeZone),
              ...difference(
                s.payload.item.specializations.value,
                s.payload.initialSpecializations,
              ).map((i) => addSpecialization(s.payload.advisor.id, i)),
              ...difference(
                s.payload.initialSpecializations,
                s.payload.item.specializations.value,
              ).map((i) => removeSpecialization(s.payload.advisor.id, i)),
            ]),
          ).pipe(
            switchMap(([i]) =>
              s.payload.logo
                ? from(updateAvatar(i.id, s.payload.timeZone, s.payload.logo))
                : of(i),
            ),
            map((r) => r.id),
            map(Actions.saveSuccess),
            catchError(() =>
              of(Actions.saveError(`Unable to save ${rb('advisor')}`)),
            ),
          );
        }),
      )
      .subscribe(dispatch);

    return () => {
      logo$.unsubscribe();
      save$.unsubscribe();
      get$.unsubscribe();
      checkName$.unsubscribe();
      checkEmail$.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    switch (state.type) {
      case 'SaveSuccess': {
        enqueueSnackbar(`${rb('advisor-u')} saved successfully`, {
          variant: 'success',
        });
        return;
      }
      case 'LoadError':
      case 'SaveError':
      case 'UnloadError':
      case 'UnloadRemoveAnUpdateError':
      case 'RemoveAndUpdateError': {
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        return;
      }
      case 'Unloading': {
        confirm(true);
        return;
      }
      case 'InvalidAvailabilities':
      case 'UnloadInvalidAvailabilities':
      case 'RemoveAndUpdate':
      case 'UnloadRemoveAnUpdate':
      case 'Edited':
      case 'UnloadConfirm':
      case 'UnloadSubmitted':
      case 'UnloadSaving':
      case 'Ready':
      case 'Loading':
      case 'Uploading':
      case 'Submitted':
      case 'Saving':
      case 'VerificationError':
        return;
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line
  }, [state.type]);

  return [state, dispatch];
}

function prepareForSave(s: State.IsSaving): Update {
  switch (s.type) {
    case 'Saving':
    case 'UnloadSaving':
      return savingToAdvisorUpdate(s.payload.advisor, s.payload.item);
    case 'RemoveAndUpdate':
    case 'UnloadRemoveAnUpdate':
      return savingToAdvisorUpdate(
        validateAdvisor(s.payload.advisor),
        s.payload.item,
      );
  }
}
