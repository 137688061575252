import { isEqual } from 'date-fns/fp';
import { TenantTimeZone } from '../../../../api/tenants/types/Settings';
import { ZonedDate } from '../../../../utils/date/ZonedDate';
import { Actions } from './types/Actions';
import { confirmation, confirmed, idle, State } from './types/State';

export function createReducer<T>(
  getDates: (t: T) => ZonedDate<TenantTimeZone>[],
) {
  return (state: State<T>, action: Actions<T>): State<T> => {
    switch (action.type) {
      case 'Submit': {
        const dates = getDates(action.payload);

        if (dates.length === 0) {
          return confirmed({ data: action.payload, dates: dates });
        }

        if (dates.every(isWorkTime)) {
          return confirmed({ dates: dates, data: action.payload });
        }

        if (
          (state.type === 'Confirmed' || state.type === 'Idle') &&
          dates.every((date) => state.payload.dates.some(isEqual(date)))
        ) {
          return confirmed({ dates: dates, data: action.payload });
        }

        return confirmation({ data: action.payload, dates });
      }
      case 'Cancel':
        return state.type === 'Confirmation' ? idle({ dates: [] }) : state;
      case 'Confirm':
        return state.type === 'Confirmation'
          ? confirmed({
              data: state.payload.data,
              dates: getDates(state.payload.data),
            })
          : state;
    }
  };
}

function isWorkTime(date: Date): boolean {
  const day = date.getDay();
  const hours = date.getHours();
  return day !== 0 && day !== 6 && hours >= 8 && hours <= 17;
}
