import { CircularProgress, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import { startOfToday } from 'date-fns';
import {
  ChangeEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Status } from '../../../api/Advisors/types/Status';
import { SpecializationId } from '../../../api/specializations';
import { TestId } from '../../../components/Testing/TestId';
import {
  Button,
  StickyContent,
  TextWysiwyg,
  UserLogo,
} from '../../../components/common';
import {
  FormItem,
  FormItem2,
} from '../../../components/common/Forms/types/FormItem';
import { SimpleSelect } from '../../../components/common/SimpleSelect';
import { DateInput as MUIDate } from '../../../components/common/date-input';
import { SpecializationInput } from '../../../components/common/specialization-input';
import { TextField as MUIText } from '../../../components/common/text-field';
import { useDebouncedOnChange } from '../../../hooks/useDebouncedOnChange';
import { COLORS } from '../../../theme/variables';
import { lengthField } from '../../../utils/form';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gap: '45px',
    maxWidth: 700,
    gridTemplateColumns: '1fr 160px',
  },
  column: {
    display: 'grid',
    gap: '56px',
  },
  group: {
    display: 'grid',
    gap: '30px',
    alignContent: 'start',
  },
  row: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',
  },
  title: {},
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
  rateField: {
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
}));

export interface Props {
  firstName: FormItem2<string | undefined, string>;
  address: FormItem<string | undefined, string>;
  birthDate: FormItem<Date | undefined, Date | undefined>;
  zip: FormItem<string | undefined, string>;
  city: FormItem<string | undefined, string>;
  country: FormItem<string | undefined, string>;
  email: FormItem2<string | undefined, string>;
  lastName: FormItem2<string | undefined, string>;
  linkedIn: FormItem<string | undefined, string>;
  logo: FormItem<string | undefined, File | undefined>;
  long: FormItem<string | undefined, string>;
  phone: FormItem<string | undefined, string>;
  short: FormItem<string | undefined, string>;
  state: FormItem<string | undefined, string>;
  rate: FormItem<number | undefined, number>;
  specializations: FormItem<SpecializationId[] | undefined, SpecializationId[]>;
  status: FormItem<Status, Status>;
  enrollmentDate: Date;
  statuses: Array<{ label: string; value: Status }>;
  onSubmit: () => void;
  disableSubmit: boolean;
}

export function Form({
  firstName,
  lastName,
  birthDate,
  enrollmentDate,
  rate,
  status,
  statuses,
  address,
  zip,
  city,
  country,
  email,
  linkedIn,
  logo,
  long,
  phone,
  short,
  state,
  specializations,
  onSubmit,
  disableSubmit,
}: Props): ReactElement<Props> {
  const classes = useStyles();
  const [scroll, setScroll] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const handleSubmit = useCallback(() => {
    !scroll && setScroll(true);
    onSubmit();
  }, [onSubmit, scroll]);

  useEffect(() => {
    if (formRef.current && scroll) {
      const el = formRef.current?.querySelector(
        '[data-error=err]',
      ) as HTMLElement | null;

      if (el) {
        const y = el.offsetTop;

        document.getElementById('scroll-content')?.scrollTo({ top: y - 200 });

        setScroll(false);
      }
    }
  }, [scroll]);

  return (
    <div className={classes.root} ref={formRef}>
      <div className={classes.column}>
        <div className={classes.group}>
          <div className={classes.row}>
            <TestId testId={'first-name'}>
              <IconTextField item={firstName} label='First Name *' />
            </TestId>
            <TestId testId={'last-name'}>
              <IconTextField item={lastName} label='Last Name *' />
            </TestId>
          </div>
          <div className={classes.row}>
            <MUIDate
              maxDate={startOfToday()}
              value={birthDate.value ?? null}
              error={birthDate.error}
              textFieldProps={{
                label: 'Date of birth',
              }}
              onChange={(d) => birthDate.onChange(d ?? undefined)}
              data-error={birthDate.error ? 'err' : undefined}
            />
            <MUIDate
              maxDate={startOfToday()}
              value={enrollmentDate}
              textFieldProps={{
                label: 'Date of enrollment',
              }}
              onChange={empty}
              disabled
            />
          </div>
          <SpecializationInput<true, false>
            multiple
            value={specializations.value ?? []}
            onChange={specializations.onChange}
            testid={'specializations-input'}
            textFieldProps={{
              label: 'Specialization',
            }}
          />
        </div>
        <div className={classes.group}>
          <GroupTitle>Contacts</GroupTitle>
          <TestId testId={'email'}>
            <IconTextField item={email} label='Email *' />
          </TestId>
          <TextField item={phone} label='Phone' />
          <TextField item={linkedIn} label='LinkedIn profile' />
        </div>
        <div className={classes.group}>
          <GroupTitle>Address</GroupTitle>
          <TextField item={address} label='Address' />
          <TextField item={city} label='City' />
          <TextField item={country} label='Country' />
          <TextField
            item={zip}
            label='Zip'
            InputProps={{ inputProps: { maxLength: lengthField.zip } }}
          />
          <TextField item={state} label='State' />
        </div>
        <div className={classes.group}>
          <GroupTitle>Description</GroupTitle>
          <TestId testId={'short-desc'}>
            <RichTextField rows={7} item={short} label='Short description *' />
          </TestId>
          <TestId testId={'long-desc'}>
            <RichTextField rows={15} item={long} label='Long description *' />
          </TestId>
        </div>
      </div>
      <div className={classes.group}>
        <UserLogo
          bgText={'?'}
          name={'logo'}
          logo={logo.value}
          onUpload={logo.onChange}
          onRemove={() => logo.onChange(undefined)}
        />
        <SimpleSelect
          value={status.value}
          onChange={status.onChange}
          onBlur={status.onBlur}
          options={statuses}
        />
        <RateField item={rate} label='Session Rate' />
      </div>
      <div>
        <StickyContent>
          <TestId testId={'submit'}>
            <Button
              onClick={handleSubmit}
              disabled={disableSubmit}
              startIcon={<CheckIcon />}>
              Save
            </Button>
          </TestId>
        </StickyContent>
      </div>
    </div>
  );
}

interface TPr {
  item: FormItem<string | undefined, string>;
  label: string;
  InputProps?: any;
  debounceTime?: number;
}
function TextField({
  item: { value, onChange, onBlur, error, errorText },
  label,
  InputProps,
  debounceTime = 0,
}: TPr): ReactElement<TPr> {
  const [v, debounce, commit] = useDebouncedOnChange(
    value ?? '',
    onChange,
    debounceTime,
  );
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debounce(e.target.value),
    [debounce],
  );
  return (
    <MUIText
      data-error={error ? 'err' : undefined}
      value={v}
      error={error}
      errorText={errorText}
      label={label}
      onBlur={() => {
        commit();
        onBlur();
      }}
      onChange={handleOnChange}
      InputProps={InputProps}
    />
  );
}

interface T2Pr {
  item: FormItem<string | undefined, string>;
  label: string;
  rows: number;
}
function RichTextField({
  item: { value, onChange, onBlur, error },
  label,
  rows,
}: T2Pr): ReactElement<T2Pr> {
  const [v, debounce, commit] = useDebouncedOnChange(
    value ?? '',
    onChange,
    500,
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debounce(e.target.value),
    [debounce],
  );

  return (
    <div data-error={error ? 'err' : undefined}>
      <TextWysiwyg
        rows={rows}
        multiline={true}
        value={v}
        error={error}
        label={label}
        onBlur={() => {
          commit();
          onBlur();
        }}
        onChange={handleOnChange}
      />
    </div>
  );
}

interface T3Pr {
  item: FormItem<number | undefined, number>;
  label: string;
  InputProps?: any;
}
function RateField({
  item: { value, onChange, onBlur, error, errorText },
  label,
  InputProps,
}: T3Pr): ReactElement<T3Pr> {
  const classes = useStyles();
  const [v, debounce, commit] = useDebouncedOnChange(value ?? 0, onChange, 500);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const onlyNumber = value.replace(/[^0-9.]/g, '');
      const number = Number(onlyNumber) || 0;

      debounce(number);
    },
    [debounce],
  );
  return (
    <MUIText
      data-error={error ? 'err' : undefined}
      value={+v ? `$${v}` : ''}
      error={error}
      errorText={errorText}
      label={label}
      onBlur={() => {
        commit();
        onBlur();
      }}
      onChange={handleOnChange}
      InputProps={InputProps}
      className={classes.rateField}
    />
  );
}

function GroupTitle({ children }: PropsWithChildren<{}>) {
  return <Typography variant='h3'>{children}</Typography>;
}

const empty = (): void => {};

interface IconTextFieldProps {
  item: FormItem2<string | undefined, string>;
  label: string;
}
function IconTextField({
  item: { value, onChange, onBlur, status, errorText },
  label,
}: IconTextFieldProps): ReactElement<TPr> {
  const [v, debounce] = useDebouncedOnChange(value ?? '', onChange, 500);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debounce(e.target.value),
    [debounce],
  );
  const classes = useStyles();

  const icon = useMemo(() => {
    switch (status) {
      case 'normal':
        return null;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <MUIText
      data-error={status === 'error' ? 'err' : undefined}
      value={v}
      error={status === 'error'}
      errorText={errorText}
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: icon,
      }}
    />
  );
}
