import { isT } from 'fp-utilities';
import { applyR } from '../../../utils/Either';
import { initial, invalid, valid } from '../../../utils/FormValue';
import { fromString } from '../../../utils/String/Sentence';
import { Actions } from './types/Actions';
import {
  ExistingCancelEditingConfirm,
  ExistingEditing,
  ExistingRemoveConfirm,
  ExistingRemoving,
  ExistingSaving,
  ExistingView,
} from './types/ExistingItemState';
import { isNewItemState, validateItem } from './types/Item';
import {
  NewItemCancelEditingConfirm,
  NewItemEditing,
  NewItemSaving,
} from './types/NewItemState';
import { loading, ready, State } from './types/State';

export function reducer(s: State, a: Actions): State {
  switch (a.type) {
    case 'ChangeTenant': {
      if (s.payload.tenantId === a.payload) return s;

      return loading({
        tenantId: a.payload,
      });
    }
    case 'FetchError':
      return s;
    case 'FetchSuccess':
      return s.type === 'Loading'
        ? ready({
            tenantId: s.payload.tenantId,
            items: a.payload.map(
              (i) =>
                new ExistingView(
                  i.id,
                  i.tenantId,
                  i.name,
                  i.description ?? undefined,
                ),
            ),
          })
        : s;
    case 'AddNew': {
      if (s.type !== 'Ready' || s.payload.items.some((i) => isNewItemState(i)))
        return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: [
          new NewItemEditing(initial(undefined), valid(undefined)),
          ...s.payload.items,
        ],
      });
    }
    case 'Edit': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i) => i instanceof ExistingView && i.id === a.payload,
      ) as ExistingView | undefined;
      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return new ExistingEditing(
              item.id,
              item.tenantId,
              valid(item.title),
              valid(item.description),
              valid(item.title),
              valid(item.description),
            );
          }
          return i;
        }),
      });
    }
    case 'SetName': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingEditing | NewItemEditing =>
          i.id === a.payload.id &&
          (i instanceof ExistingEditing || i instanceof NewItemEditing),
      );

      if (!item) return s;

      const name = applyR(
        fromString(100, a.payload.name),
        () => invalid('', a.payload.name),
        valid,
      );
      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            if (item instanceof NewItemEditing) {
              return new NewItemEditing(name, item.description);
            } else {
              return new ExistingEditing(
                item.id,
                item.tenantId,
                name,
                item.description,
                item.initialTitle,
                item.initialDescription,
              );
            }
          }
          return i;
        }),
      });
    }
    case 'SetDescription': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingEditing | NewItemEditing =>
          i.id === a.payload.id &&
          (i instanceof ExistingEditing || i instanceof NewItemEditing),
      );

      if (!item) return s;

      const description = a.payload.description
        ? applyR(
            fromString(2048, a.payload.description),
            () => invalid('', a.payload.description),
            valid,
          )
        : valid(undefined);
      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            if (item instanceof NewItemEditing) {
              return new NewItemEditing(item.title, description);
            } else {
              return new ExistingEditing(
                item.id,
                item.tenantId,
                item.title,
                description,
                item.initialTitle,
                item.initialDescription,
              );
            }
          }
          return i;
        }),
      });
    }
    case 'CancelEdit': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingEditing | NewItemEditing =>
          i.id === a.payload &&
          (i instanceof ExistingEditing || i instanceof NewItemEditing),
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items
          .map((i) => {
            if (i.id === item.id) {
              if (item instanceof NewItemEditing) {
                if (!item.title.value && !item.description.value)
                  return undefined;

                return new NewItemCancelEditingConfirm(
                  item.title,
                  item.description,
                );
              } else {
                if (
                  item.title.value === item.initialTitle.value &&
                  item.description.value === item.initialDescription.value
                )
                  return new ExistingView(
                    item.id,
                    item.tenantId,
                    item.initialTitle.value,
                    item.initialDescription.value,
                  );

                return new ExistingCancelEditingConfirm(
                  item.id,
                  item.tenantId,
                  item.initialTitle,
                  item.initialDescription,
                  item.initialTitle,
                  item.initialDescription,
                );
              }
            }
            return i;
          })
          .filter(isT),
      });
    }
    case 'CancelEditDecline': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingCancelEditingConfirm | NewItemCancelEditingConfirm =>
          i instanceof ExistingCancelEditingConfirm ||
          i instanceof NewItemCancelEditingConfirm,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            if (item instanceof NewItemCancelEditingConfirm) {
              return new NewItemEditing(item.title, item.description);
            } else {
              return new ExistingEditing(
                item.id,
                item.tenantId,
                item.initialTitle,
                item.initialDescription,
                item.initialTitle,
                item.initialDescription,
              );
            }
          }
          return i;
        }),
      });
    }
    case 'CancelEditConfirm': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingCancelEditingConfirm | NewItemCancelEditingConfirm =>
          i instanceof ExistingCancelEditingConfirm ||
          i instanceof NewItemCancelEditingConfirm,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items
          .map((i) => {
            if (i.id === item.id) {
              if (item instanceof NewItemCancelEditingConfirm) {
                return undefined;
              } else {
                return new ExistingView(
                  item.id,
                  item.tenantId,
                  item.initialTitle.value,
                  item.initialDescription.value,
                );
              }
            }
            return i;
          })
          .filter(isT),
      });
    }
    case 'Save': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingEditing | NewItemEditing =>
          i.id === a.payload &&
          (i instanceof ExistingEditing || i instanceof NewItemEditing),
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return validateItem(item);
          }
          return i;
        }),
      });
    }
    case 'SaveSuccess': {
      if (s.type !== 'Ready') return s;

      return loading({
        tenantId: s.payload.tenantId,
      });
    }
    case 'SaveError': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingSaving | NewItemSaving =>
          i.id === a.payload.id &&
          (i instanceof ExistingSaving || i instanceof NewItemSaving),
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            if (item instanceof NewItemSaving) {
              return new NewItemEditing(item.title, item.description);
            } else {
              return new ExistingEditing(
                item.id,
                item.tenantId,
                item.title,
                item.description,
                item.initialTitle,
                item.initialDescription,
              );
            }
          }
          return i;
        }),
      });
    }
    case 'Remove': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingView =>
          i.id === a.payload && i instanceof ExistingView,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return new ExistingRemoveConfirm(
              item.id,
              item.tenantId,
              item.title,
              item.description,
            );
          }
          return i;
        }),
      });
    }
    case 'RemoveDecline': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingRemoveConfirm => i instanceof ExistingRemoveConfirm,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return new ExistingView(
              item.id,
              item.tenantId,
              item.title,
              item.description,
            );
          }
          return i;
        }),
      });
    }
    case 'RemoveConfirm': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingRemoveConfirm => i instanceof ExistingRemoveConfirm,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return new ExistingRemoving(
              item.id,
              item.tenantId,
              item.title,
              item.description,
            );
          }
          return i;
        }),
      });
    }
    case 'RemoveSuccess': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingRemoving =>
          i.id === a.payload && i instanceof ExistingRemoving,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.filter((i) => i.id !== item.id),
      });
    }
    case 'RemoveError': {
      if (s.type !== 'Ready') return s;

      const item = s.payload.items.find(
        (i): i is ExistingRemoving =>
          i.id === a.payload.id && i instanceof ExistingRemoving,
      );

      if (!item) return s;

      return ready({
        tenantId: s.payload.tenantId,
        items: s.payload.items.map((i) => {
          if (i.id === item.id) {
            return new ExistingView(
              item.id,
              item.tenantId,
              item.title,
              item.description,
            );
          }
          return i;
        }),
      });
    }
  }
}
