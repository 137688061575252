import { CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import foundersAPI, { Founder } from '../../api/founders';
import mentorsAPI, { Mentor } from '../../api/mentors';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { lengthField } from '../../utils/form';
import { isTablet } from '../../utils/functions';
import { unreachableError } from '../../utils/unreachableError';
import { TextFieldWrapper } from '../forms/wrappers';
import { FormGroup } from './form-layout';

export type FieldNamesProps = {
  entityType: 'mentor' | 'founder';
  isReadOnly?: boolean;
};

const useStyles = makeStyles({
  small: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: 14,
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputBase-input': {
      height: 38,
      paddingTop: 10,
      paddingBottom: 10,
      boxSizing: 'border-box',
    },
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.COLOR_RED_BASE,
    },
  },
  checkIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    color: COLORS.COLOR_RED_BASE,
  },
});

export const checkNameForDublicates = async (
  entityType: 'mentor' | 'founder',
  firstName: string,
  lastName: string,
): Promise<'success' | 'error' | 'none'> => {
  try {
    const searchRequest =
      entityType === 'mentor'
        ? mentorsAPI.getMentorsByLastname
        : foundersAPI.getFoundersByLastname;
    const entities: (Mentor | Founder)[] = await searchRequest(lastName);
    const names = entities.map((entity: Mentor | Founder) =>
      `${entity.firstName}_${entity.lastName}`.toLowerCase(),
    );

    return names.includes(`${firstName}_${lastName}`.toLowerCase())
      ? 'error'
      : 'success';
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return 'success';
    }

    return 'none';
  }
};

function FieldNames({ entityType, isReadOnly = false }: FieldNamesProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const [status, setStatus] = useState<
    'success' | 'error' | 'loading' | 'none'
  >('none');
  const firstNameField = useField<string>('firstName');
  const lastNameField = useField<string>('lastName');
  const form = useForm();

  const searchByName = async (firstName: string, lastName: string) => {
    try {
      setStatus('loading');
      const newStatus = await checkNameForDublicates(
        entityType,
        firstName,
        lastName,
      );

      if (newStatus === 'error') {
        const entityName = entityType === 'mentor' ? rb('mentor') : 'founder';
        enqueueSnackbar(
          `A ${entityName} with the same first and last name already exists`,
          {
            variant: 'error',
          },
        );
      }

      setStatus(newStatus);
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return setStatus('success');
      }

      return setStatus('none');
    }
  };

  const handleChange = () => {
    if (status !== 'none') {
      setStatus('none');
    }
  };

  const handleBlur = () => {
    const firstNameValue = firstNameField.input.value.trim();
    const firstNameInitialValue = firstNameField.meta.initial?.trim();
    const lastNameValue = lastNameField.input.value.trim();
    const lastNameInitialValue = lastNameField.meta.initial?.trim();
    const currentValue = `${firstNameValue} ${lastNameValue}`;
    const initialValue = `${firstNameInitialValue} ${lastNameInitialValue}`;

    if (
      firstNameValue.length > 0 &&
      lastNameValue.length > 0 &&
      currentValue !== initialValue
    ) {
      setStatus('loading');
      searchByName(firstNameValue, lastNameValue);
    } else {
      setStatus('none');
    }
  };

  const getEndAdornment = useCallback(() => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.checkIcon} />;
      case 'error':
        return <CancelIcon className={classes.errorIcon} />;
      case 'loading':
        return <CircularProgress color='primary' size={20} />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const fieldValue = form.getState().values.verifiedName;

    switch (status) {
      case 'none':
      case 'error':
      case 'loading':
        if (fieldValue === true) {
          form.change('verifiedName', false);
        }
        break;
      case 'success':
        if (fieldValue === false) {
          form.change('verifiedName', true);
        }
        break;
      default:
        unreachableError(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <FormGroup mobile={isTablet()}>
      <Field<string>
        name='firstName'
        data-testid='form-firstName'
        component={TextFieldWrapper}
        label='First name*'
        className={cn({
          [classes.error]:
            status === 'error' ||
            (firstNameField.meta.invalid && firstNameField.meta.dirty),
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: getEndAdornment(),
          inputProps: {
            maxLength: lengthField.firstName,
            readOnly: isReadOnly,
          },
        }}
      />
      <Field<string>
        name='lastName'
        data-testid='form-lastName'
        component={TextFieldWrapper}
        label='Last name*'
        className={cn({
          [classes.error]: status === 'error',
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: getEndAdornment(),
          inputProps: {
            maxLength: lengthField.lastName,
            readOnly: isReadOnly,
          },
        }}
      />
    </FormGroup>
  );
}

export default FieldNames;
