import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';

type VARIANT = 'warning';

interface BadgeProps {
  variant?: VARIANT;
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    fontFamily: INTER_FONT_FAMILY,
    fontSize: '11px',
    padding: '5px',
    width: '28px',
    height: '28px',
    boxSizing: 'border-box',
    lineHeight: '18px',
    borderRadius: '100%',
    fontWeight: 500
  },
  warning: {
    color: COLORS.COLOR_GRAY_DARKENED_15,
    background: COLORS.COLOR_ADDITIONAL_ORANGE_LIGHTENED
  }
});

function Badge({ children, variant = 'warning' }: BadgeProps) {
  const classes = useStyles();

  return (
    <div
      className={cn(classes.container, {
        [classes[variant]]: variant
      })}>
      {children}
    </div>
  );
}

export default Badge;
