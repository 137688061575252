import { Advisor, AdvisorId } from '../../../../api/Advisors/types/Advisor';
import { Status } from '../../../../api/Advisors/types/Status';

export interface Item {
  id: AdvisorId;
  initials: string;
  name: string;
  linkedIn: string | undefined;
  email: string;
  phone?: string;
  status: Status;
  selected: boolean;
  logo: string | undefined;
}

export function fromAdvisor(v: Advisor): Item {
  return {
    id: v.id,
    email: v.email,
    phone: v.phone ?? undefined,
    name: [v.firstName, v.lastName].join(' ').trim() || v.email,
    linkedIn: v.linkedInProfile ?? undefined,
    status: v.status,
    selected: false,
    initials: [v.firstName[0], v.lastName[0]].join('').trim(),
    logo: v.logo ?? undefined,
  };
}
