import { EventStatus, EventStatusOrAll } from '../../../api/events';
import { silentUnreachableVariant } from '../../../utils/unreachableError';

export function statusFromString(status: string): EventStatus | undefined {
  const s = status.toUpperCase() as EventStatus;
  switch (s) {
    case 'ARCHIVED':
    case 'COMPLETED':
    case 'REQUIRE_ATTENTION':
    case 'CREATED':
    case 'PUBLISHED':
      return s as EventStatus;
    default:
      silentUnreachableVariant(s);
      return undefined;
  }
}

export function statusToString(status: EventStatusOrAll): string {
  switch (status) {
    case 'ARCHIVED':
      return 'Archived';
    case 'COMPLETED':
      return 'Completed';
    case 'REQUIRE_ATTENTION':
      return 'Require Attention';
    case 'CREATED':
      return 'Created';
    case 'PUBLISHED':
      return 'Published';
    case 'ALL':
      return 'All Statuses';
  }
}
