import { EMPTY, from, of, Subject } from 'rxjs';
import {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import * as QuestionsApi from '../api/tenants/QuestionTemplates';
import * as State from '../states/listing/State';
import * as Actions from '../states/listing/Actions';
import { reducer } from '../states/listing/reducer';
import {
  catchError,
  distinctUntilKeyChanged,
  map,
  switchMap
} from 'rxjs/operators';
import {
  Create,
  QuestionId,
  Update
} from '../api/tenants/QuestionTemplates/Question';
import { TenantId } from '../api/tenants/Tenant';

interface DrawerProviderProps {
  children: ReactNode;
  tenantId: TenantId;
}

type PublicActions =
  | Actions.SetType
  | Actions.SetDescription
  | Actions.SetRequired
  | Actions.Remove
  | Actions.RemoveDeny
  | Actions.RemoveApprove
  | Actions.AddNew
  | Actions.Edit
  | Actions.Save
  | Actions.Cancel;

export const TenantQuestionsContext = createContext<
  [State.State, Dispatch<PublicActions>]
>([State.loading({ tenantId: '' as TenantId }), (s) => s]);

export const TenantQuestionsProvider = ({
  children,
  tenantId
}: DrawerProviderProps) => {
  const [state, dispatch] = useReducer(reducer, State.loading({ tenantId }));
  const subject = useMemo(() => new Subject<State.State>(), []);

  useEffect(() => {
    const fetchQuestions = () =>
      from(QuestionsApi.get()).pipe(
        map(Actions.loadSuccess),
        catchError(() => of(Actions.loadFail('Unable to load questions')))
      );
    const addQuestion = (v: Create) =>
      from(QuestionsApi.create(v)).pipe(
        map(Actions.saveSuccess),
        catchError(() => of(Actions.saveFail('Unable to save question')))
      );
    const updateQuestion = (v: Update) =>
      from(QuestionsApi.update(v)).pipe(
        map(Actions.saveSuccess),
        catchError(() => of(Actions.saveFail('Unable to update question')))
      );
    const removeQuestion = (v: QuestionId) =>
      from(QuestionsApi.remove(v)).pipe(
        map((r) => r.id),
        map(Actions.removeSuccess),
        catchError(() =>
          of(
            Actions.removeFail({
              id: v,
              message: 'Unable to remove question'
            })
          )
        )
      );
    const o = subject
      .pipe(
        distinctUntilKeyChanged('type'),
        switchMap((s) => {
          switch (s.type) {
            case 'Loading':
              return fetchQuestions();
            case 'Adding':
              return addQuestion(s.payload.item);
            case 'Updating':
              return updateQuestion(s.payload.item);
            case 'Removing':
              return removeQuestion(s.payload.item.id);
            case 'Fail':
            case 'Ready':
            case 'AddNew':
            case 'AddNewErr':
            case 'Edit':
            case 'EditErr':
            case 'RemoveConfirmation':
              return EMPTY;
          }
        })
      )
      .subscribe(dispatch);
    return () => {
      o.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    subject.next(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <TenantQuestionsContext.Provider value={[state, dispatch]}>
      {children}
    </TenantQuestionsContext.Provider>
  );
};
