import { VentureQuarterRecord } from '../../../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import {
  Answer,
  AnswerId
} from '../../../../api/ventures/quarterlyrecords/customanswers/Answer';

// region Loading
export interface Loading {
  type: 'Loading';
  payload: {
    record: VentureQuarterRecord;
  };
}

export const loading = (payload: Loading['payload']): Loading => ({
  type: 'Loading',
  payload
});
// endregion

// region LoadErr
export interface LoadErr {
  type: 'LoadErr';
  payload: {
    record: VentureQuarterRecord;
    message: string;
  };
}

export const loadErr = (payload: LoadErr['payload']): LoadErr => ({
  type: 'LoadErr',
  payload
});
// endregion

// region Ready
export interface Ready {
  type: 'Ready';
  payload: {
    record: VentureQuarterRecord;
    answers: Answer[];
  };
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload
});
// endregion

// region RemoveConfirmation
export interface RemoveConfirmation {
  type: 'RemoveConfirmation';
  payload: {
    record: VentureQuarterRecord;
    answers: Answer[];
    answerId: AnswerId;
  };
}

export const removeConfirmation = (
  payload: RemoveConfirmation['payload']
): RemoveConfirmation => ({
  type: 'RemoveConfirmation',
  payload
});
// endregion

// region Removing
export interface Removing {
  type: 'Removing';
  payload: {
    record: VentureQuarterRecord;
    answers: Answer[];
    answerId: AnswerId;
  };
}

export const removing = (payload: Removing['payload']): Removing => ({
  type: 'Removing',
  payload
});
// endregion

// region RemoveErr
export interface RemoveErr {
  type: 'RemoveErr';
  payload: {
    record: VentureQuarterRecord;
    answers: Answer[];
    answerId: AnswerId;
    message: string;
  };
}

export const removeErr = (payload: RemoveErr['payload']): RemoveErr => ({
  type: 'RemoveErr',
  payload
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: 'RemoveSuccess';
  payload: {
    record: VentureQuarterRecord;
    answers: Answer[];
    message: string;
  };
}

export const removeSuccess = (
  payload: RemoveSuccess['payload']
): RemoveSuccess => ({
  type: 'RemoveSuccess',
  payload
});
// endregion

export type State =
  | Loading
  | LoadErr
  | Ready
  | RemoveConfirmation
  | Removing
  | RemoveErr
  | RemoveSuccess;
