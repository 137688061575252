import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { createMarkup } from '../../utils/functions';
import { ConfirmButton, Text } from '../common';
import ConsentForm, { Values as FormValues } from '../forms/consent';

interface ConsentProps {
  consent: {
    id: string;
    type: 'Founder' | 'Mentor';
    body: string;
  };
  updating?: boolean;
  deleting?: boolean;
  onEdit: (values: FormValues & { id: string }, callback: () => any) => any;
  onRemove: (values: FormValues, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    wordBreak: 'break-all',
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  bodyText: {
    wordBreak: 'break-word',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

function Consent({
  consent,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index,
}: ConsentProps) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const { rb } = useResourceBundles();

  const handleConsentEdit = () => {
    setIsEditing(true);
  };

  const handleConsentRemove = (callback: () => any) => {
    onRemove(consent, callback);
  };

  const handleConsentEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitConsentForm = (values: FormValues) => {
    onEdit({ id: consent.id, ...values }, handleConsentEditCancel);
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <ConsentForm
          title='Commitment editing'
          consent={consent}
          loading={updating}
          onSubmit={handleSubmitConsentForm}
          onCancel={handleConsentEditCancel}
        />
      ) : (
        <>
          <div className={classes.title} data-testid={`consent-${index}`}>
            <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
              Type: {consent.type === 'Mentor' ? rb('mentor-u') : consent.type}
            </Text>
            {!readOnly && (
              <div>
                <IconButton
                  onClick={handleConsentEdit}
                  data-testid={`form-consent-edit-${index}`}>
                  <EditIcon />
                </IconButton>
                <ConfirmButton
                  loading={deleting}
                  title='Remove the commitment?'
                  body='Sure you want to remove the commitment? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleConsentRemove,
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      data-testid={`form-consent-delete-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}></ConfirmButton>
              </div>
            )}
          </div>
          <div>
            <Text className={classes.body} variant='normal'>
              <div
                className={classes.bodyText}
                dangerouslySetInnerHTML={createMarkup(consent.body)}
              />
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

export default Consent;
