import { Sidebar } from '../common/Sidebar';
import { CoreMenu } from '../sidebar/CoreMenu';

export interface Props {
  className?: string;
}

export function CoreSidebar({ className }: Props) {
  return (
    <Sidebar className={className}>
      <CoreMenu />
    </Sidebar>
  );
}
