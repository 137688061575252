import { Answer, AnswerId, Create } from './Answer';
import { axiosRequest } from '../../../instance';
import { RecordId } from '../types/VentureQuarterRecord';
import { AxiosError } from 'axios';
import { NonEmptyArray } from '../../../../utils/Array/NonEmptyArray';

export function get(id: RecordId): Promise<Answer[]> {
  return axiosRequest
    .get<Answer[]>({
      url: `/ventures/quarterlyrecords/${id}/customanswers`,
      credentials: true,
    })
    .then((r) => r.data)
    .catch((e: AxiosError) => {
      switch (e.response?.status) {
        case 404:
          return [];
        default: {
          throw e;
        }
      }
    });
}

export function create(answers: NonEmptyArray<Create>): Promise<Answer[]> {
  return axiosRequest
    .post<Answer[]>({
      url: '/ventures/quarterlyrecords/customanswers',
      data: answers,
      credentials: true,
    })
    .then((r) => r.data);
}

export function remove(
  recordId: RecordId,
  answerId: AnswerId,
): Promise<Answer> {
  return axiosRequest
    .delete<Answer>({
      url: `/ventures/quarterlyrecords/${recordId}/customanswers/${answerId}`,
      credentials: true,
    })
    .then((r) => r.data);
}
