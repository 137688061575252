import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { CopyIcon } from '../../assets/icons';
import { Text } from '../../components/common';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface Props {
  linkKey: string;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670,
  },

  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',
    marginLeft: 8,

    '&:hover': {
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  itemLink: {
    width: 300,
    paddingTop: 8,
    display: 'flex',
    alignItems: 'center',
  },

  titleAbout: {
    marginTop: 25,
    marginBottom: 8,
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE,

    '&:hover': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
  linkLabel: {
    fontSize: 16,
  },
});

function LinksScreen({ linkKey }: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const [copied, setCopied] = useState('');

  const linkList = useMemo(
    () => [
      { id: 'agenda', label: 'Agenda', href: Pages.REPORT_AGENDA_REQUEST },
      {
        id: 'lead-mentor-report',
        label: `Lead ${rb('mentor-u')} Report`,
        href: Pages.REPORT_LEAD_MENTOR,
      },
      {
        id: 'mentor-assessment',
        label: `${rb('mentor-u')} Assessment`,
        href: Pages.REPORT_MENTOR_ASSESSMENT,
      },
      {
        id: 'founder-notes',
        label: 'Founder Notes',
        href: Pages.REPORT_FOUNDER_SESSION_NOTES,
      },
    ],
    [rb],
  );

  const handleCopy = (text: string, label: string) => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + text);
    setCopied(label);
    setTimeout(() => {
      setCopied('');
    }, 4000);
  };

  return (
    <div className={classes.container}>
      <Typography variant='h3' data-testid='links-page-title'>
        Links
      </Typography>
      {linkList.map((item) => {
        const currentLink = `${item.href}?key=${linkKey}`;
        const testid = `button-link-copy-${item.id}`;

        return (
          <div className={classes.itemLink} data-testid='links-page-href'>
            <Text variant='normal' className={classes.linkLabel}>
              - {item.label}
            </Text>
            {copied !== item.label ? (
              <Tooltip title='Copy link'>
                <span
                  data-testid={testid}
                  onClick={() => handleCopy(currentLink, item.label)}>
                  <CopyIcon className={classes.icon} />
                </span>
              </Tooltip>
            ) : (
              <CheckCircleIcon
                className={cn(classes.icon, classes.successIcon)}
              />
            )}
          </div>
        );
      })}

      <Typography
        variant='h3'
        className={classes.titleAbout}
        data-testid='links-page-href'>
        About these links
      </Typography>
      <Text variant='normal'>
        These links to forms are sent out automatically. However, in case you
        need to share them manually, you can copy them from the list above. Each
        session has its own unique link.
      </Text>
    </div>
  );
}

export default LinksScreen;
