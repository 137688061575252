import { Advisor, Update } from '../../../api/Advisors/types/Advisor';
import { SpecializationId } from '../../../api/specializations';
import { TenantTimeZone } from '../../../api/tenants/types/Settings';
import { valid } from '../../../utils/FormValue';
import { Email } from '../../../utils/String/Email';
import { Sentence } from '../../../utils/String/Sentence';
import { isValidAvailability, ValidAdvisor } from '../common/ValidAdvisor';
import { Valid } from '../common/types/Item';
import { Ready, State } from './types/State';

export function advisorToReady(
  a: Advisor,
  s: SpecializationId[],
  timeZone: TenantTimeZone,
): Ready['payload'] {
  return {
    id: a.id,
    logo: undefined,
    advisor: a,
    tenantId: a.tenantId,
    timeZone,
    initialSpecializations: s,
    item: {
      address: valid((a.address as Sentence<1024>) ?? undefined),
      logo: valid((a.logo as Sentence<1024>) ?? undefined),
      long: valid((a.longDescription as Sentence<4000>) ?? undefined),
      short: valid((a.shortDescription as Sentence<512>) ?? undefined),
      email: valid(a.email as Email),
      country: valid((a.country as Sentence<250>) ?? undefined),
      phone: valid((a.phone as Sentence<250>) ?? undefined),
      birthDate: valid(a.dateOfBirth ? new Date(a.dateOfBirth) : undefined),
      city: valid((a.city as Sentence<250>) ?? undefined),
      state: valid((a.state as Sentence<250>) ?? undefined),
      linkedIn: valid((a.linkedInProfile as Sentence<250>) ?? undefined),
      lastName: valid(a.lastName as Sentence<250>),
      zip: valid((a.zip as Sentence<12>) ?? undefined),
      status: valid(a.status),
      firstName: valid(a.firstName as Sentence<250>),
      specializations: valid(s),
      rate: valid((a.rate as number) ?? undefined),
    },
  };
}

export function savingToAdvisorUpdate(
  advisor: ValidAdvisor,
  item: Valid,
): Update {
  return {
    id: advisor.id,
    address: item.address.value ?? null,
    advisorAvailabilities: advisor.advisorAvailabilities,
    city: item.city.value ?? null,
    country: item.country.value ?? null,
    dateOfBirth: item.birthDate.value ?? null,
    dateOfEnrollment: advisor.dateOfEnrollment,
    deactivationDate: advisor.deactivationDate,
    email: item.email.value,
    emailValid: advisor.emailValid,
    firstName: item.firstName.value,
    lastName: item.lastName.value,
    linkedInProfile: item.linkedIn.value ?? null,
    logo: advisor.logo,
    longDescription: item.long.value,
    phone: item.phone.value ?? null,
    shortDescription: item.short.value,
    state: item.state.value ?? null,
    status: item.status.value,
    tenantId: advisor.tenantId,
    valid: advisor.valid,
    zip: item.zip.value ?? null,
    rate: item.rate.value ?? null,
  };
}

export function formEdited(s: State): boolean {
  switch (s.type) {
    case 'Loading':
    case 'LoadError':
    case 'SaveError':
    case 'Ready':
    case 'Saving':
    case 'SaveSuccess':
    case 'UnloadSubmitted':
    case 'UnloadConfirm':
    case 'Unloading':
      return false;
    case 'InvalidAvailabilities':
    case 'Uploading':
    case 'Edited':
    case 'Submitted':
    case 'VerificationError':
    case 'UnloadError':
    case 'UnloadSaving':
    case 'UnloadInvalidAvailabilities':
    case 'UnloadRemoveAnUpdate':
    case 'UnloadRemoveAnUpdateError':
    case 'RemoveAndUpdateError':
    case 'RemoveAndUpdate':
      return true;
  }
}

export function validateAdvisor(advisor: Advisor): ValidAdvisor {
  return {
    ...advisor,
    advisorAvailabilities:
      advisor.advisorAvailabilities.filter(isValidAvailability),
  };
}
