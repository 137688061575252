import { useState, useRef } from 'react';
import cn from 'classnames';
import { CircularProgress, makeStyles, Popover } from '@material-ui/core';
import { Text } from '../common';

interface GeneralReportDetailsPopupProps {
  list?: string[];
  onLoaded?: (list: any) => any;
  loadList: () => any;
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    width: 320,
    boxSizing: 'border-box'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapper: {
    minWidth: 20
  },
  paragraph: {
    '& + &': {
      marginTop: 12
    }
  }
});

function GeneralReportDetailsPopup({
  list,
  loadList = () => {},
  onLoaded = () => {},
  children,
  className
}: GeneralReportDetailsPopupProps) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const debouncedTimer = useRef<number>();

  const handleOpenInfo = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.target as HTMLElement);
    if (!list && !isLoading) {
      try {
        setIsLoading(true);
        setIsOpen(true);
        const res = await loadList();
        onLoaded(res);
        setIsLoading(false);
      } catch (e: any) {
        console.log('error handleOpenInfo', e);
      }
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    debouncedTimer.current = setTimeout(() => {
      handleOpenInfo(event);
    }, 300) as unknown as number;
  };

  const handleMouseLeave = () => {
    clearTimeout(debouncedTimer.current);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={cn(classes.wrapper, className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Popover
        className={classes.popover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={2}
        PaperProps={{
          className: classes.paper
        }}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={24} color='primary' />
          </div>
        ) : (
          !!list &&
          !!list.length && (
            <Text variant='normal2'>
              {list.map((item, itemIndex) => (
                <p key={itemIndex} className={classes.paragraph}>
                  {item}
                </p>
              ))}
            </Text>
          )
        )}
      </Popover>
    </>
  );
}

export default GeneralReportDetailsPopup;
